export const HEADINGS = {
  main_heading_terms: 'Terms of Use',
  heading1_terms: 'Use of Content; Restrictions',
  heading2_terms: 'Disclaimers and Limitations of Liability',
  heading3: 'Additional Terms',
};

export const CONTENT = {
  paragraph1_terms:
    'These Terms of Use apply to your use of the site that links to this Terms of Use document (the “site”).  To the extent there is an executed agreement with Deloitte Consulting LLP (“we” or “us”) governing your access to and use of the application available on this site (an “Agreement”), your use and access to any such application will be further governed by such Agreement. To the extent there is a conflict between these Terms of Use and an Agreement, the terms of the Agreement shall control. By using this site, you are agreeing to these Terms of Use.  If you do not agree to these Terms of Use, then you are not allowed to use this site and should immediately terminate such usage.',
  paragraph2_terms:
    'Unless otherwise indicated in the relevant content, and on the condition that you comply with all of your obligations under these Terms of Use, (i) you are authorized only to view and print the content on this site for informational, noncommercial purposes and provided that any copy of the content that you make must include the copyright notice or other attribution associated with the content; and (ii) you are not authorized to copy or use any software, proprietary processes or technology embodied or described in this site.',
  paragraph3_terms:
    'You will comply with all applicable laws in accessing and using this site.  You acknowledge that we may use your personal information and data according to our Privacy Statement, set forth in the Privacy link on this site.',
  paragraph4_terms:
    'This site and its contents are protected by copyright, trademark, and other laws of the United States and/or other countries.  We and our licensors reserve all rights not expressly granted in these Terms of Use.  References to other parties’ trademarks on this site are for identification purposes only and do not indicate that such parties have approved this site or any of its contents, nor should they be construed as an endorsement of them or their content by us. ',
  paragraph5:
    'THIS SITE CONTAINS GENERAL INFORMATION ONLY, AND WE ARE NOT, BY MEANS OF THIS SITE, RENDERING PROFESSIONAL ADVICE OR SERVICES. BEFORE MAKING ANY DECISION OR TAKING ANY ACTION THAT MIGHT AFFECT YOUR FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR.',
  paragraph6:
    'THIS SITE IS PROVIDED AS IS, AND WE MAKE NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING IT. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT THIS SITE WILL BE SECURE, ERROR-FREE, FREE FROM VIRUSES OR MALICIOUS CODE, OR WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY. WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY.',
  paragraph7:
    'YOUR USE OF THIS SITE IS AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE, INCLUDING, WITHOUT LIMITATION, WITH RESPECT TO LOSS OF SERVICE OR DATA.  WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, RELATING TO OR ARISING OUT OF THE USE OF THIS SITE, EVEN IF WE KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY OF SUCH DAMAGES.',
  paragraph8:
    'THE ABOVE DISCLAIMERS AND LIMITATIONS OF LIABILITY ARE APPLICABLE TO THE FULLEST EXTENT PERMITTED BY LAW, WHETHER IN CONTRACT, STATUTE, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE.',
  paragraph9:
    'If any portion of these Terms of Use is invalid or unenforceable in any jurisdiction, then (i) in that jurisdiction it shall be re-construed to the maximum effect permitted by law in order to effect its intent as nearly as possible, and the remainder of these Terms of Use shall remain in full force and effect, and (ii) in every other jurisdiction, all of these Terms of Use shall remain in full force and effect.',
  paragraph10:
    'We may revise these Terms of Use at any time in our sole discretion by posting such revised Terms of Use at the Terms of Use link or elsewhere in this site. Such revisions shall be effective as to you upon posting, unless explicitly stated by us. It is your responsibility to be aware of any such revised Terms of Use by checking this webpage. Your continued use of this site following changes to these Terms of Use constitutes your agreement to the revised Terms of Use.',
};
