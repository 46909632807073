import styled from 'styled-components';
import { COOL_GRAY, GREEN, RED } from 'theme/GlobalColors';

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1rem;
  align-items: center;
  margin: 0.5rem 0rem;
`;
export const Icon = styled.div`
  fill: ${({ fillColor }) => fillColor};
`;
export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  padding-left: ${({ paddingLeft }) => paddingLeft};
`;

export const OccurrenceDetailsStyle = (
  fontSize,
  lineHeight,
  paddingTop,
  error,
) => {
  return {
    width: '8rem',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: fontSize,
    lineHeight: lineHeight,
    color: error ? RED : '#333333',
    paddingTop: paddingTop,
  };
};
export const DatePickerStyle = {
  gap: '10px',
  backgroundColor: 'blue',
};
export const DeleteIconStyle = (display) => {
  return {
    color: COOL_GRAY,
    display: display,
    cursor: 'pointer',
    paddingLeft: '4rem',
  };
};

export const RadioGroupStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;
export const DatePickerTextFieldStyle = {
  width: '9rem',
  '& .MuiInput-underline:after': { borderBottom: '0px solid white' },
  '& .Mui-error:before': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42);',
  },
};

export const TextFieldStyle = {
  width: '9rem',
  '& .MuiInput-underline:after': { borderBottom: '0px solid white' },
};
export const TextFieldErrorStyle = {
  width: '9rem',
  '& .MuiInput-underline:before': { borderBottom: '1px solid red' },
};

export const PopperProps = {
  '& .MuiPickersDay-today': {
    borderRadius: '3px',
    backgroundColor: '#fff',
    borderColor: '#D3D3D3',
  },
  '& .Mui-selected': { borderRadius: '3px' },
  '& .MuiPickersDay-root': { '&:hover': { borderRadius: '3px' } },
};

export const AvailableQuantityLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: ${({ error }) => (error ? RED : GREEN)};
  padding-left: 0.625rem;
`;
