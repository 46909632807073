import React from 'react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  ActivitiesTableHeading,
  ActivityTableData,
  CategoryWrapper,
  ActivityCategory,
  PracticeWrapper,
  PracticeListItem,
  InputFieldWrapper,
  ErrorWrapper,
} from './AddContractModal.style';
import { MODAL_CONTENT } from './AddContractModal.content';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import PropTypes from 'prop-types';
const ProjectActivityDetailsSections = ({
  totalEnrolledAcres,
  handleEnrolledAcresUpdate,
  enrolledAcresError,
  totalAcresInputProps,
  activityPracticeData,
  handlePracticeDataUpdate,
  inputStyleProps,
  uniqueIncentiveErrorList,
  year,
  projectCycleId,
}) => {
  return (
    <>
      <InputField
        value={totalEnrolledAcres}
        onUpdate={handleEnrolledAcresUpdate}
        primaryError={enrolledAcresError?.length > 0}
        primaryErrorMessage={enrolledAcresError}
        {...totalAcresInputProps}
      />
      <ActivitiesTableHeading>
        {MODAL_CONTENT.activities_table_heading}
        <InfoTooltip bottomMargin="-4px" customSx={{ marginLeft: '0.5rem' }} />
      </ActivitiesTableHeading>

      <table style={{ width: '28.5rem' }}>
        <tr>
          <ActivityTableData width="216px">
            Activities + practices
          </ActivityTableData>
          <ActivityTableData alignment="right">
            Contracted units
          </ActivityTableData>
          <ActivityTableData width="6.875rem" alignment="right">
            Incentive rate
          </ActivityTableData>
        </tr>
        <tr>
          <ActivityTableData></ActivityTableData>
          <ActivityTableData alignment="right">Acres</ActivityTableData>
          <ActivityTableData alignment="right">$ per acre</ActivityTableData>
        </tr>
      </table>

      {Object.keys(activityPracticeData)?.map((activityCategory) => {
        if (activityCategory === 'totalAcres') return null;
        const practicesCount = activityPracticeData[activityCategory]?.length;
        return (
          <CategoryWrapper key={activityCategory}>
            {practicesCount > 1 && (
              <ActivityCategory>{activityCategory}</ActivityCategory>
            )}

            {activityPracticeData[activityCategory]?.map((practice) => {
              return (
                <PracticeWrapper key={practice.projectActivityId}>
                  {practicesCount === 1 && (
                    <ActivityCategory>{activityCategory}</ActivityCategory>
                  )}
                  {practicesCount > 1 && (
                    <PracticeListItem>
                      {practice.projectActivityType}
                    </PracticeListItem>
                  )}

                  <InputFieldWrapper>
                    <InputField
                      value={practice.contractedUnits}
                      name={MODAL_CONTENT.contracted_units}
                      placeholder={MODAL_CONTENT.incentive_placeholder}
                      onUpdate={(event) =>
                        handlePracticeDataUpdate(
                          'contractedUnits',
                          event.target.value,
                          practice.projectActivityId,
                          activityCategory,
                          year,
                          projectCycleId,
                        )
                      }
                      {...inputStyleProps}
                    />
                    <InputField
                      value={practice.incentiveRate}
                      name={MODAL_CONTENT.incentive_rate}
                      placeholder={MODAL_CONTENT.number_field_placeholder}
                      onUpdate={(event) =>
                        handlePracticeDataUpdate(
                          'incentiveRate',
                          event.target.value,
                          practice.projectActivityId,
                          activityCategory,
                          year,
                          projectCycleId,
                        )
                      }
                      primaryError={practice.error?.length > 0}
                      {...inputStyleProps}
                    />
                  </InputFieldWrapper>
                </PracticeWrapper>
              );
            })}
          </CategoryWrapper>
        );
      })}
      {uniqueIncentiveErrorList.map((error) => {
        return (
          <ErrorWrapper className="contract-modal-error" key={error}>
            {error}
          </ErrorWrapper>
        );
      })}
    </>
  );
};
ProjectActivityDetailsSections.propTypes = {
  totalEnrolledAcres: PropTypes.object,
  handleEnrolledAcresUpdate: PropTypes.func,
  enrolledAcresError: PropTypes.string,
  totalAcresInputProps: PropTypes.object,
  activityPracticeData: PropTypes.object,
  handlePracticeDataUpdate: PropTypes.func,
  inputStyleProps: PropTypes.object,
  uniqueIncentiveErrorList: PropTypes.array,
  year: PropTypes.number,
  projectCycleId: PropTypes.number,
};
export default ProjectActivityDetailsSections;
