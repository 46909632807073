import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ADD_USER_CONTENT, rolesTypes } from './AddUser.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import { useForm } from 'hooks/useForm';
import {
  formModal,
  useUserDetails,
  fieldInitialState,
} from 'hooks/useUserDetails';
import { scroll, validateEmail, checkTernaryCondition } from 'utils/helper';
import {
  UserTypeWrapper,
  buttonStyle,
  DialogTitleSx,
  DialogContentSx,
  paperRootSx,
} from './AddUser.style';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import {
  ErroWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { TRUE } from 'utils/config';
import { closeIconSx } from 'theme/GlobalStyles';

const AddUserForm = ({
  isUserDataEmpty,
  isOpen,
  setIsOpen,
  fetchUserList,
  setEditRemoveToClose,
}) => {
  const [open, setOpen] = useState(false);
  const [emailSecondaryError, setEmailSecondaryError] = useState(
    ADD_USER_CONTENT.user_email_format_error,
  );
  const [isFormEdited, setIsFormEdited] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(formModal);
  const [hasError, setHasError] = useState(false);
  const [isEmailBlurred, setIsEmailBlurred] = useState(false);

  const {
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    userEmail,
    setUserEmail,
    userRoles,
    setUserRoles,
    originationRoles,
    setOriginationRoles,
    handleRolesChange,
    handleTextFieldValueChange,
    handleFormSubmit,
    formHasError,
    fetchModuleRoles,
    moduleRoles,
    checkUserEmail,
    isDuplicateEmail,
    postUserData,
    fetchUserDetails,
    fetchedUserDetails,
    setIsDuplicateEmail,
    moduleError,
    setModuleError,
    setFormHasError,
    handleEmailChange,
    checkingEmail,
  } = useUserDetails();

  const formContainsError = () => {
    const isUserFirstNameEmpty = userFirstName.value === '';
    const isUserLastNameEmpty = userLastName.value === '';
    const isUserEmailEmpty = userEmail.value === '';
    const isRoleSelectionEmpty = userRoles.value === '';
    const validEmail = userEmail.error;
    const isOriginationRolesEmpty =
      userRoles.value.toLowerCase() === 'modules' &&
      originationRoles.value === '';
    const isEmptyArray = [
      isOriginationRolesEmpty,
      isUserFirstNameEmpty,
      isUserLastNameEmpty,
      isUserEmailEmpty,
      isRoleSelectionEmpty,
      validEmail,
    ];

    setHasError(isEmptyArray.includes(true));
  };

  useEffect(() => {
    if (isOpen.flag) {
      setOpen(isOpen.flag);
      setHasError(true);
    }
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const isAdminSelected = userRoles.value === ADD_USER_CONTENT.admin;
  const isButtonDisabled =
    isDuplicateEmail ||
    (!isFormEdited && isOpen.id !== 0) ||
    moduleError ||
    hasError ||
    checkingEmail;

  const dropdownProps = {
    label: ADD_USER_CONTENT.company,
    value: ADD_USER_CONTENT.company_value,
    isDisabled: true,
    width: '14rem',
    height: '2rem',
    name: ADD_USER_CONTENT.company,
    ariaLabel: ADD_USER_CONTENT.company,
  };

  const originationDropdownProps = {
    labelMarginTop: '1rem',
    field_key: ADD_USER_CONTENT.origination_roles,
    label: ADD_USER_CONTENT.origination_role_label,
    value:
      originationRoles.value === ADD_USER_CONTENT.empty_string
        ? ADD_USER_CONTENT.select_roles
        : originationRoles.value,
    isDisabled:
      formValue.userRoles.toLowerCase() ===
        ADD_USER_CONTENT.admin.toLowerCase() && TRUE,
    stateValue: originationRoles,
    setStateValue: setOriginationRoles,
    setFormFieldValue: customFieldChangeHandler,
    width: '14rem',
    height: '2rem',
    name: ADD_USER_CONTENT.origination_role_label,
    displayEmpty: true,
    ariaLabel: ADD_USER_CONTENT.origination_role_label,
    dropdownlist: moduleRoles || ADD_USER_CONTENT.empty_array,
    dropDownPlaceholder: ADD_USER_CONTENT.select_roles,
    error: !isAdminSelected && originationRoles.error,
    errorMessage:
      !isAdminSelected && ADD_USER_CONTENT.select_role_dropdown_error,
    moduleError:
      !isAdminSelected &&
      originationRoles.errorType === ADD_USER_CONTENT.none_error,
    onUpdate: () => {
      setIsFormEdited(true);
    },
  };

  const clearFormData = () => {
    setUserEmail(fieldInitialState);
    setUserRoles(fieldInitialState);
    setOriginationRoles(fieldInitialState);
    setUserFirstName(fieldInitialState);
    setUserLastName(fieldInitialState);
    setIsFormEdited(false);
  };
  const setInvalidEmailError = () => {
    setUserEmail({
      ...userEmail,
      error: true,
      errorType: ADD_USER_CONTENT.secondary,
    });
    setIsDuplicateEmail(false);
    setEmailSecondaryError(ADD_USER_CONTENT.user_email_format_error);
  };
  const handleClose = () => {
    setIsEmailBlurred(false);
    setOpen(false);
    clearFormData();
    setIsOpen({ id: 0, flag: false });
    setEditRemoveToClose(false);
    setHasError(true);
    setIsDuplicateEmail(false);
  };

  const userType = useMemo(() => {
    const validEmailRegex =
      /^([-a-zA-Z0-9.]+)@([a-zA-Z0-9-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

    return userEmail && validEmailRegex.test(userEmail.value)
      ? checkTernaryCondition(
          userEmail.value.split('@')[1] === 'deloitte.com',
          'Internal',
          'External',
        )
      : '';
  }, [userEmail]);

  useEffect(() => {
    fetchModuleRoles();
  }, []);
  useEffect(() => {
    setOpen(isOpen.flag);
    isOpen.flag && fetchUserDetails(isOpen.id);
  }, [isOpen]);
  useEffect(() => {
    fetchedUserDetails !== ADD_USER_CONTENT.empty_string &&
      setFormValue(fetchedUserDetails);
  }, [fetchedUserDetails]);
  useEffect(() => {
    if (isDuplicateEmail) {
      setEmailSecondaryError(ADD_USER_CONTENT.duplicate_email_error);
      setUserEmail({
        ...userEmail,
        error: true,
        errorType: ADD_USER_CONTENT.secondary,
      });

      setIsDuplicateEmail(false);
    }
  }, [isDuplicateEmail]);
  useEffect(() => {
    scroll(ADD_USER_CONTENT.error_class_name);
    if (originationRoles.value !== 'None') {
      setModuleError(false);
    }
    formContainsError();
  }, [formValue]);

  useEffect(() => {
    if (open) {
      setUserEmail(fieldInitialState);
    }
  }, [open]);

  useEffect(() => {
    formContainsError();
  }, [userEmail.error]);

  useEffect(() => {
    if (formHasError === false && isDuplicateEmail === false) {
      postUserData(formValue, isOpen.flag, isOpen.id, fetchUserList);
      setOpen(false);
      clearFormData();
      setIsOpen({ id: 0, flag: false });
      setEditRemoveToClose(false);
    }
  }, [formHasError]);

  useEffect(() => {
    if (originationRoles.value === ADD_USER_CONTENT.none) {
      setFormHasError(true);
      setOriginationRoles({
        ...originationRoles,
        error: true,
        errorType: ADD_USER_CONTENT.none_error,
      });
      setModuleError(true);
    } else {
      setModuleError(false);
      originationRoles.value !== ADD_USER_CONTENT.empty_string &&
        setOriginationRoles({
          ...originationRoles,
          error: false,
          errorType: null,
        });
    }
  }, [originationRoles.value]);

  useEffect(() => {
    setOriginationRoles(fieldInitialState);
  }, [isAdminSelected]);

  return (
    <div>
      {!isUserDataEmpty && (
        <Button
          onClick={handleClickOpen}
          variant={ADD_USER_CONTENT.contained}
          sx={[buttonStyle(), primaryButtonStyle()]}>
          {ADD_USER_CONTENT.add_user}
        </Button>
      )}
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={handleClose}
        fullWidth
        sx={paperRootSx}>
        <Box display="flex" justifyContent={'space-between'}>
          <DialogTitle sx={DialogTitleSx}>
            {checkTernaryCondition(
              isOpen.flag,
              ADD_USER_CONTENT.edit_user,
              ADD_USER_CONTENT.add_user,
            )}
          </DialogTitle>
          <CloseIcon
            data-testid={ADD_USER_CONTENT.close_icon}
            onClick={() => handleClose()}
            sx={closeIconSx}
          />
        </Box>
        <DialogContent sx={DialogContentSx}>
          <InputField
            label={ADD_USER_CONTENT.first_name}
            value={userFirstName.value}
            placeholder={ADD_USER_CONTENT.first_name_placeholder}
            name={ADD_USER_CONTENT.first_name}
            width="100%"
            isRequired
            primaryError={userFirstName.error}
            primaryErrorMessage={ADD_USER_CONTENT.user_first_name_error}
            onUpdate={(event) => {
              handleTextFieldValueChange(
                userFirstName,
                setUserFirstName,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_first_name,
              );
              setIsFormEdited(true);
            }}
          />
          <InputField
            labelMarginTop="0rem"
            label={ADD_USER_CONTENT.last_name}
            value={userLastName.value}
            placeholder={ADD_USER_CONTENT.last_name_placeholder}
            name={ADD_USER_CONTENT.last_name}
            width="100%"
            isRequired
            primaryError={userLastName.error}
            primaryErrorMessage={ADD_USER_CONTENT.user_last_name_error}
            onUpdate={(event) => {
              handleTextFieldValueChange(
                userLastName,
                setUserLastName,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_last_name,
              );
              setIsFormEdited(true);
            }}
          />
          <InputField
            labelMarginTop="0rem"
            label={ADD_USER_CONTENT.email}
            value={userEmail.value}
            placeholder={ADD_USER_CONTENT.email_placeholder}
            name={ADD_USER_CONTENT.email}
            isDisabled={isOpen.flag}
            width="100%"
            isRequired
            primaryError={
              userEmail.error &&
              userEmail.errorType === ADD_USER_CONTENT.primary
            }
            primaryErrorMessage={ADD_USER_CONTENT.user_email_valid_error}
            secondaryError={
              userEmail.error &&
              userEmail.errorType === ADD_USER_CONTENT.secondary
            }
            secondaryErrorMessage={emailSecondaryError}
            onUpdate={(event) => {
              handleEmailChange(
                setUserEmail,
                customFieldChangeHandler,
                event,
                ADD_USER_CONTENT.user_email,
              );
              if (isEmailBlurred) {
                if (event.target.value.trim().length === 0) {
                  setUserEmail({
                    value: event.target.value.trim(),
                    error: true,
                    errorType: ADD_USER_CONTENT.primary,
                  });
                } else if (!validateEmail(event.target.value.trim())) {
                  setUserEmail({
                    value: event.target.value.trim(),
                    error: true,
                    errorType: ADD_USER_CONTENT.secondary,
                  });
                } else {
                  setUserEmail({
                    value: event.target.value.trim(),
                    error: false,
                    errorType: '',
                  });
                  checkUserEmail(event.target.value.trim());
                }
              }
            }}
            onBlurHandler={(e) => {
              setIsEmailBlurred(true);
              !validateEmail(userEmail.value)
                ? userEmail.value !== ADD_USER_CONTENT.empty_string &&
                  setInvalidEmailError()
                : !isOpen.flag && checkUserEmail(userEmail.value);
            }}
          />
          <UserTypeWrapper>{`${ADD_USER_CONTENT.userType}${userType}`}</UserTypeWrapper>
          <DropDown {...dropdownProps} />
          <RadioButtonsGroup
            radioBtnPadding={'0rem 0.5rem 0rem 0.563rem'}
            marginTop={'0rem'}
            list={rolesTypes}
            label={ADD_USER_CONTENT.roles_label}
            direction={ADD_USER_CONTENT.column}
            selectedValue={userRoles.value}
            primaryError={userRoles.error}
            primaryErrorMessage={ADD_USER_CONTENT.select_role_error}
            handleChange={(event) => {
              handleRolesChange(event, customFieldChangeHandler);
              setIsFormEdited(true);
            }}
          />
          <DropDown {...originationDropdownProps} />
          {moduleError && (
            <ErroWrapper>{ADD_USER_CONTENT.role_error}</ErroWrapper>
          )}
        </DialogContent>
        <DialogActions sx={DialogContentSx}>
          <Button onClick={handleClose} sx={tertiaryButtonStyle}>
            {ADD_USER_CONTENT.cancel}
          </Button>
          <Button
            disabled={isButtonDisabled}
            data-testid={ADD_USER_CONTENT.submit_button}
            onClick={() => {
              handleFormSubmit(formValue);
              formContainsError();
              setHasError(true);
              setIsEmailBlurred(false);
            }}
            variant={ADD_USER_CONTENT.contained}
            sx={[buttonStyle(isButtonDisabled), primaryButtonStyle()]}>
            {checkTernaryCondition(
              isOpen.flag,
              ADD_USER_CONTENT.save,
              ADD_USER_CONTENT.add_user,
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddUserForm;
