import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { SortColValues } from './ProjectCatalog.content';

export const MainWrapper = styled.div`
  width: 100%;
  background: #ffff;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;

export const FilterHeader = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;
export const TransactionListWrapper = styled.div`
  max-height: 6.25rem;
  display: flex;
  flex-direction: column;
  flex-warp: wrap;
  margin: 1rem;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
`;
export const TransactionListText = styled.span`
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export const CustomIconButton = styled(IconButton)(() => ({
  padding: '0rem',
  margin: '0rem',
}));

export const datagridSx = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  borderRadius: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
    whiteSpace: 'break-spaces',
    fontFamily: `'Open Sans', sans-serif`,
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },

  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
};
export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
  &:hover {
    background-color: #e3dada36;
  }
`;

export const handleArrowColor = (col, sortColumn) => {
  return {
    color:
      sortColumn === SortColValues[col] ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  };
};

export const MTCO2EWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  & div {
    color: #005927;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
`;
export const MTCO2EHeading1 = styled.div`
  line-height: 2;
`;
export const MTCO2EHeading2 = styled.div`
  line-height: 1;
`;

export const CarbonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: rgba(134, 189, 37, 0.1);
  padding: 1rem;
`;
