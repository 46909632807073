import PropTypes from 'prop-types';
import { Switch } from '@mui/material';
import {
  SwitchAndLabelWrapper,
  SwitchSx,
  TextWrapper,
} from './SwitchComponent.style';

const SwitchComponent = ({
  name,
  isChecked,
  handleChange,
  label,
  fontSize,
}) => {
  return (
    <SwitchAndLabelWrapper>
      <Switch
        data-testId={name}
        sx={SwitchSx}
        checked={isChecked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        name={name}
      />
      <TextWrapper fontSize={fontSize}>{label}</TextWrapper>
    </SwitchAndLabelWrapper>
  );
};

SwitchComponent.propTypes = {
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SwitchComponent;
