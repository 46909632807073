import {
  MainWrapper,
  BoxWrapper,
  AccessDeniedHeading,
  PermissionHeadingWrapper,
  PermissionTextWrapper,
  BackButtonWrapper,
  BackIcon,
  BackButtonLabel,
} from './AccessDeniedPage.style';
import { ReactComponent as BackArrow } from '../../assets/icons/backarrow.svg';
import { ACCESS_DENIED_PAGE_CONTENT } from './AccessDeniedPage.content';
import { checkTernaryCondition } from 'utils/helper';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { useNavigate } from 'react-router';

const AccessDeniedPage = () => {
  const { isParticipantPortalAccessDenied } = useContext(userAuthRolesContext);
  const navigate = useNavigate();
  return (
    <MainWrapper>
      <BoxWrapper>
        <AccessDeniedHeading>
          {ACCESS_DENIED_PAGE_CONTENT.access_denied_heading}
        </AccessDeniedHeading>
        <PermissionHeadingWrapper>
          {ACCESS_DENIED_PAGE_CONTENT.permission_heading}
        </PermissionHeadingWrapper>
        <PermissionTextWrapper>
          {checkTernaryCondition(
            isParticipantPortalAccessDenied,
            ACCESS_DENIED_PAGE_CONTENT.participant_portal_permission_text,
            ACCESS_DENIED_PAGE_CONTENT.permission_text,
          )}
        </PermissionTextWrapper>
        {!isParticipantPortalAccessDenied && (
          <BackButtonWrapper
            onClick={() => {
              navigate(-1);
            }}>
            <BackIcon>
              <BackArrow />
            </BackIcon>
            <BackButtonLabel>
              {ACCESS_DENIED_PAGE_CONTENT.back_button_label}
            </BackButtonLabel>
          </BackButtonWrapper>
        )}
      </BoxWrapper>
    </MainWrapper>
  );
};

export default AccessDeniedPage;
