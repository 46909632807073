import styled from 'styled-components';
import { COOL_GRAY } from 'theme/GlobalColors';

export const DialogWrapper = styled.div`
  padding: 0.5rem;
  color: #333;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid ${COOL_GRAY};
  line-height: normal;
  letter-spacing: -0.2px;
`;
