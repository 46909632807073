const { RemoveLabel } = require('./RemoveButton.style');

const RemoveButton = ({ label, clickHandler, labelColor }) => {
  return (
    <>
      <RemoveLabel color={labelColor} onClick={clickHandler}>
        {label}
      </RemoveLabel>
    </>
  );
};

export default RemoveButton;
