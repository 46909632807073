import { Regex } from './PurchaseVintageSubsection.content';
import PropTypes from 'prop-types';

const SubscriptConverter = ({ inputString, firstPart, lastPart }) => {
  return (
    <>
      <span>{inputString.substring(0, inputString.length - firstPart)}</span>
      <sub>{inputString.match(Regex.digit)}</sub>
      <span>{inputString.substring(inputString.length - lastPart)}</span>
    </>
  );
};

SubscriptConverter.propTypes = {
  inputString: PropTypes.string,
  firstPart: PropTypes.string,
  lastPart: PropTypes.string,
};
export default SubscriptConverter;
