import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import {
  AppliedFilters,
  ClearLink,
  FilterChip,
  SearchAndFilterSection,
  SearchAndFiltersFlex,
  SearchBox,
  clearIconStyle,
  SearchIconSx,
  BasicWrapper,
  TextWrapper,
  DatePickerAndFilterWrapper,
} from './SearchAndFilterComponent.style';
import { Fragment, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { uniqueId } from 'utils/uniqueIdGenerator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker.react';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calenderIcon.svg';
import { SEARCH_AND_FILTER_COMPONENT_CONTENT } from './SearchAndFilterComponent.content';
import { TYPOGRAPHY_GRAY, WHITE } from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';

const SearchAndFilterComponent = ({
  hasSeachBox,
  projectNameSearchText,
  setProjectNameSearchText,
  searchBoxPlaceholder,
  filterSections,
  hasAppliedFilters,
  removeFilter,
  appliedFiltersCount,
  updateFilterStates,
  handleFilterSectionUpdate,
  columnName,
  clearLabel,
  hasDatePicker,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  darkGreenColor,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <SearchAndFilterSection>
      <div style={SearchAndFiltersFlex}>
        {hasSeachBox && (
          <SearchBox
            placeholder={searchBoxPlaceholder}
            value={projectNameSearchText}
            onChange={(event) => setProjectNameSearchText?.(event.target.value)}
            InputProps={{
              startAdornment: (
                <Fragment>
                  <SearchIcon sx={SearchIconSx} />
                </Fragment>
              ),
              endAdornment: projectNameSearchText && (
                <ClearIcon
                  sx={{
                    ...pointerCursor,
                    color: TYPOGRAPHY_GRAY,
                    fontSize: '1rem',
                  }}
                  onClick={() => setProjectNameSearchText('')}
                />
              ),
            }}
          />
        )}
        {hasAppliedFilters && (
          <AppliedFilters>
            {filterSections.map((section, sectionIdx) => {
              return section.filters.map((filter, filterIdx) => {
                return (
                  filter.checked && (
                    <FilterChip
                      key={uniqueId()}
                      data-testid={`${section.heading}: ${filter.itemLabel}`}>
                      <div>{`${section.heading}: ${filter.itemLabel}`}</div>
                      <ClearIcon
                        onClick={() =>
                          removeFilter({
                            sectionIdx: sectionIdx,
                            filterIdx: filterIdx,
                          })
                        }
                        sx={clearIconStyle}
                      />
                    </FilterChip>
                  )
                );
              });
            })}
            {appliedFiltersCount > 1 && (
              <ClearLink
                onClick={() => {
                  updateFilterStates(columnName[0], []);
                }}>
                {clearLabel}
              </ClearLink>
            )}
          </AppliedFilters>
        )}
      </div>
      <DatePickerAndFilterWrapper>
        {hasDatePicker && (
          <BasicWrapper
            backgroundColor={WHITE}
            gap={SEARCH_AND_FILTER_COMPONENT_CONTENT.gap}
            border={SEARCH_AND_FILTER_COMPONENT_CONTENT.border}
            padding={SEARCH_AND_FILTER_COMPONENT_CONTENT.padding}
            radius={SEARCH_AND_FILTER_COMPONENT_CONTENT.radius}
            cursor={SEARCH_AND_FILTER_COMPONENT_CONTENT.center}
            data-testid={SEARCH_AND_FILTER_COMPONENT_CONTENT.date_filter}
            height="1.75rem"
            onClick={() => {
              setIsCalendarOpen(true);
            }}>
            <TextWrapper
              fontSize={SEARCH_AND_FILTER_COMPONENT_CONTENT.fontSize}
              fontWeight={SEARCH_AND_FILTER_COMPONENT_CONTENT.fontWeight}>
              {fromDate} - {toDate}
            </TextWrapper>
            <CalendarIcon />
          </BasicWrapper>
        )}
        <FiltersPopup
          filterSections={filterSections}
          updateFilters={handleFilterSectionUpdate}
          isFiltersApplied={appliedFiltersCount > 0}
          darkGreenColor={darkGreenColor}
          applyTextTransform={true}
        />
      </DatePickerAndFilterWrapper>
      <DateRangePicker
        isOpen={isCalendarOpen}
        data-testid={SEARCH_AND_FILTER_COMPONENT_CONTENT.range_picker}
        starDateSetter={setFromDate}
        endDateSetter={setToDate}
        setIsOpen={setIsCalendarOpen}
      />
    </SearchAndFilterSection>
  );
};

export default SearchAndFilterComponent;
