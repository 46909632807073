export const dateFilterList = [
  { key: 1, value: 'Past month' },
  { key: 2, value: 'Past 2 months' },
  { key: 3, value: 'Past 3 months' },
];

export const PAGE_CONTENT = {
  date_error: 'To date must come after from date, please select valid dates.',
  cancel: 'Cancel',
  apply: 'Apply',
  quick_filters: 'Quick Filters',
  column: 'column',
  primary: 'primary',
  contained: 'contained',
  green: '#26890D',
  small: 'small',
  error: 'error',
  outlined: 'outlined',
};
