import { useEffect } from 'react';

const useBeforeUnloadEvent = (
  userActivityDetected,
  customMessage = 'You have unsaved changes',
) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      const confirmationMessage = customMessage; // Most modern browsers generate their own custom message
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    if (userActivityDetected) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userActivityDetected]);
};

export default useBeforeUnloadEvent;
