import styled from 'styled-components';

export const MainWrapper = styled.div`
  background: #ffff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
export const HeaderWrapper = styled.div`
  height: 4rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const FilterBox = styled.div`
  padding: 0.4rem 0.5rem;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.8rem;
`;
export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const BasicWrapper = styled.div`
  display: flex;
  justify-content: ${({ content }) => content};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
`;

export const datagridSx = {
  width: 'auto',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  marginTop: '0.375rem',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  borderRadius: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
};
export const RowCellWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
`;

export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;
export const sortStyle = {
  height: '1rem',
  width: '1rem',
};

export const ActionWrapper = styled.div`
  max-width: 21rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;
export const TooltipWrapper = styled.div`
  white-space: nowrap;
  border: 0.5px solid #63666a;
  border-radius: 4px;
  padding: 2px 4px;
  color: #63666a;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  max-width: ${({ width }) => width};
`;
export const CellValueWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20rem;
`;
