import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {
  TransactionListWrapper,
  TransactionListText,
  deleteButtonStyle,
  editButtonStyle,
  ButtonWrapper,
  DialogWrapper,
} from './DeleteModal.style';
import { DIALOG_CONTENT } from './DeleteModal.content';
import DialogBox from 'components/DialogBox/DialogBox.react';

const DeleteModal = ({
  deleteTarget,
  targetName,
  transactionList,
  dialogSubtitle,
  isErrorDialogOpen,
  dialogTitle,
  isDialogOpen,
  setIsDialogOpen,
  onErrorDialogBoxClose,
  navigateRoute,
  deletionType,
}) => {
  return (
    <div>
      <ButtonWrapper>
        <Button
          variant={DIALOG_CONTENT.variant}
          onClick={() => setIsDialogOpen(true)}
          sx={deleteButtonStyle}>
          {DIALOG_CONTENT.delete}
        </Button>
        <Button
          variant={DIALOG_CONTENT.variant}
          onClick={() => navigateRoute()}
          sx={editButtonStyle}>
          {DIALOG_CONTENT.edit}
        </Button>
      </ButtonWrapper>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={() => deleteTarget()}
        subtitle={`${DIALOG_CONTENT.delete_message?.slice(
          0,
          32,
        )}${targetName}${DIALOG_CONTENT.delete_message?.slice(31)}`}
        onCancel={() => setIsDialogOpen(false)}
        declineCtnLabel={DIALOG_CONTENT.cancel}
        acceptCtnLabel={DIALOG_CONTENT.delete}
        title={`${DIALOG_CONTENT.delete} ${targetName}`}>
        {deletionType === 'transaction' ? (
          <DialogWrapper>{DIALOG_CONTENT.delete_message_line2}</DialogWrapper>
        ) : null}
      </DialogBox>
      <DialogBox
        deleteOperation
        isOpen={isErrorDialogOpen}
        subtitle={dialogSubtitle}
        onCancel={() => onErrorDialogBoxClose()}
        title={dialogTitle}>
        <TransactionListWrapper>
          {transactionList.map((item) => (
            <TransactionListText key={item}>{item}</TransactionListText>
          ))}
        </TransactionListWrapper>
      </DialogBox>
    </div>
  );
};

DeleteModal.propTypes = {
  deleteTarget: PropTypes.string,
  targetName: PropTypes.string,
  transactionList: PropTypes.array,
  dialogSubtitle: PropTypes.string,
  isErrorDialogOpen: PropTypes.bool,
  dialogTitle: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  onErrorDialogBoxClose: PropTypes.func,
  navigateRoute: PropTypes.string,
  deletionType: PropTypes.string,
};

export default DeleteModal;
