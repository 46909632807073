import { uniqueId } from 'utils/uniqueIdGenerator';

export const deleteModalInit = {
  open: false,
  title: '',
  subtitle: '',
  farmId: -1,
  farmName: '',
};

export const getDeleteModalTitle = (fieldName) => `Delete ${fieldName}`;
export const getDeleteModalSubTitle = (fieldName) =>
  `Are you sure you want to delete ${fieldName}? Any data entered will be lost.`;

export const getEmptyFarm = (farmName) => {
  return {
    farmId: uniqueId(),
    status: 'in progress',
    farmName: farmName,
  };
};

export const getEmptyErrorState = () => {
  return {
    farmNameRequiredError: false,
    fsaIdRequiredError: false,
    fsaIdTypeRequiredError: false,
    entityNameRequiredError: false,
    firstNameRequiredError: false,
    lastNameRequiredError: false,
    mailingAddressRequiredError: false,
    cityRequiredError: false,
    stateRequiredError: false,
    countyRequiredError: false,
    zipCodeRequiredError: false,
  };
};
