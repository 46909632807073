import styled from 'styled-components';
import { ReactComponent as CalenderIcon } from '../../../../../assets/icons/calenderIcon.svg';
export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 32.625rem;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const ButtonWrapper = styled.div`
  display: inline-flex;
  width: fit-content;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;
export const StyledCalenderIcon = styled(CalenderIcon)`
  height: 0.894rem;
`;
