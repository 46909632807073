import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  align-items: center;
  color: ${({ color }) => color};
`;
