import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetailsSx,
  AccordionSummarySx,
  AccordionSx,
  ArrowLabelWrapper,
  DataWrapper,
  Field,
  FieldLabel,
  FieldValueWrapper,
  KeyBoardArrowSx,
  LabelAndButtonWrapper,
  MainWrapper,
  PracticesDataWrapper,
  RowData,
  RowDataWrapper,
  RowWrapper,
  SecondaryLabelWrapper,
  TextWrapper,
  TitleWrapper,
  addBtnSx,
} from './AccordionDisplayBox.style';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { ReactComponent as EditIconWithHoverEffect } from 'assets/icons/EditIconWithHoverEffect.svg';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { DARK_CHARCOAL, QUILL_GRAY } from 'theme/GlobalColors';
import EmptyStateComponent from '../EmptyStateComponent.react';
import { MODAL_CONTENT as Tillage_content } from '../../BaseLineFieldInfo/components/modals/TillageModal/TillageModal.content';
import { MODAL_CONTENT as ResidueManagement_content } from '../../BaseLineFieldInfo/components/modals/ResidueManagementModal/ResidueManagementModal.content';
import {
  checkEmptyDates,
  datesLabelList,
} from 'pages/ParticipantDataCollection/BaseLineReporting/BaseLineReportingMainPage.content';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { useContext } from 'react';

const BaselineAccordionDisplayBox = ({
  modalDataArray,
  mainLabel,
  itemId,
  handleClickDelete,
  handleClickEdit,
  deleteModalHeading,
  deleteModalContent,
  fieldValueStyle,
  secondaryLabelStyle,
  fieldLabelStyle,
  fieldUnitStyle,
  hasAddBtn = false,
  addBtnLabel,
  hasRowLevelEditDelete = false,
  addNewBtnHandler = () => {},
  deleteData,
  setDeleteData = () => {},
  subText,
  hasPracticesDropdown = false,
  allPracticesData = [],
  parentId = null,
  cropType = '',
  copiedDataPresent = false,
  isInActivity = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPracticesDropdownOpen, setIsPracticesDropdownOpen] = useState(false);
  const { selectedTillagePractice, setSelectedTillagePractice } = useContext(
    baselineDropdownContext,
  );
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const mandatoryPractices =
    allPracticesData?.data?.filter((item) => item.isMandatory) || [];
  const optionalPracticesData =
    allPracticesData?.data?.filter((item) => !item.isMandatory) || [];
  const handleAccordionToggle = (event) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };
  const handleArrowIcon = (currentId) => {
    return (
      <div data-testid={currentId}>
        {checkTernaryCondition(
          expanded,
          <KeyboardArrowDownIcon />,
          <KeyboardArrowRightIcon />,
        )}
      </div>
    );
  };
  const deleteHandler = (id, isWholeModalDeleted, practiceTypeValue) => {
    setDeleteData({
      ...deleteData,
      id: id,
      isWholeModalDeleted: isWholeModalDeleted,
      practiceTypeValue: practiceTypeValue,
      modalType: modalDataArray?.modalType,
      parentId: parentId,
      cropType: cropType,
    });
    setIsDialogOpen(true);
  };
  const editHandler = (id) => {
    handleClickEdit(id, modalDataArray?.modalType, parentId);
  };

  const hasEmptyOptionalModal = useMemo(() => {
    return optionalPracticesData
      ?.map((practice) => {
        const modalTypeData = modalDataArray?.practicesModals.find(
          (item) => Object.keys(item)[0] === practice.modalList,
        )?.[practice.modalList];

        const isModalEmpty = modalTypeData?.finalData?.length === 0;
        return isModalEmpty;
      })
      .includes(true);
  }, [modalDataArray, optionalPracticesData]);

  useEffect(() => {
    setExpanded(copiedDataPresent);
  }, [copiedDataPresent]);

  useEffect(() => {
    setExpanded(itemId?.toString()?.includes('-'));
  }, [itemId]);

  useEffect(() => {
    if (modalDataArray.modalType === 'Crop') {
      setTillageValue(modalDataArray);
    }
  });
  const shouldShowDeleteIcon = () => {
    return modalDataArray.finalData.every((arr) => {
      const obj = arr.find(
        (item) => item.label === 'isImplementedInActivities',
      );
      return checkTernaryCondition(
        isInActivity,
        !(obj && obj.value === true),
        true,
      );
    });
  };

  const setTillageValue = (array) => {
    setSelectedTillagePractice(
      array?.finalData.map((el) => el[9]?.value).toString(),
    );
  };

  return (
    <>
      <MainWrapper>
        <Accordion expanded={expanded} sx={AccordionSx}>
          <AccordionSummary sx={AccordionSummarySx}>
            <RowWrapper>
              <ArrowLabelWrapper onClick={handleAccordionToggle}>
                {handleArrowIcon(itemId)}
                <TitleWrapper>
                  <TextWrapper fontSize={'1rem'} fontWeight={600}>
                    {mainLabel && mainLabel.replace('- undefined', '')}
                  </TextWrapper>
                  {subText && (
                    <TextWrapper
                      fontSize={'0.875rem'}
                      fontWeight={400}
                      color={QUILL_GRAY}>
                      {subText}
                    </TextWrapper>
                  )}
                </TitleWrapper>
              </ArrowLabelWrapper>
              <TextWrapper minWidth="300px" gap="0.5rem">
                <TextWrapper gap="0.5rem" className="HoverActionsContainer">
                  {hasRowLevelEditDelete &&
                    modalDataArray.modalType === 'Crop' && (
                      <EditIconWithHoverEffect
                        data-testid={`edit-icon`}
                        onClick={() => editHandler(itemId)}
                      />
                    )}
                  {hasRowLevelEditDelete && shouldShowDeleteIcon() && (
                    <DeleteIconWithHoverEffect
                      data-testid={`delete-icon`}
                      sx={deleteIconSx}
                      onClick={() =>
                        deleteHandler(
                          itemId,
                          modalDataArray.modalType !== 'Crop',
                          checkTernaryCondition(
                            modalDataArray.modalType === 'Crop',
                            mainLabel?.split(' - ')[1],
                            '',
                          ),
                        )
                      }
                    />
                  )}
                </TextWrapper>
                {modalDataArray?.modalType !==
                  ResidueManagement_content.heading &&
                  hasAddBtn &&
                  (!hasPracticesDropdown || hasEmptyOptionalModal) && (
                    <Button
                      id="basic-button"
                      aria-controls={
                        isPracticesDropdownOpen ? 'basic-menu' : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        isPracticesDropdownOpen ? 'true' : undefined
                      }
                      variant={'contained'}
                      size={'medium'}
                      disableElevation
                      sx={addBtnSx}
                      onClick={(event) => {
                        if (optionalPracticesData?.length > 0) {
                          setIsPracticesDropdownOpen(true);
                          setDropdownAnchorEl(event.currentTarget);
                        } else {
                          addNewBtnHandler();
                        }
                      }}>
                      {addBtnLabel}
                      {hasPracticesDropdown && (
                        <KeyboardArrowDownIcon sx={KeyBoardArrowSx} />
                      )}
                    </Button>
                  )}

                <Menu
                  disableRestoreFocus
                  id="basic-menu"
                  anchorEl={dropdownAnchorEl}
                  open={isPracticesDropdownOpen}
                  onClose={() => {
                    setIsPracticesDropdownOpen(false);
                    setDropdownAnchorEl(null);
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  {optionalPracticesData?.map((practice) => {
                    const modalTypeData = modalDataArray?.practicesModals.find(
                      (item) => Object.keys(item)[0] === practice.modalList,
                    )?.[practice.modalList];

                    const isModalEmpty = modalTypeData?.finalData?.length === 0;
                    return (
                      isModalEmpty && (
                        <MenuItem
                          key={practice?.id}
                          onClick={() => {
                            practice.clickHandler?.(itemId)();
                            setIsPracticesDropdownOpen(false);
                          }}
                          sx={{ fontSize: '0.875rem' }}>
                          {practice.label}
                        </MenuItem>
                      )
                    );
                  })}
                </Menu>
              </TextWrapper>
            </RowWrapper>
          </AccordionSummary>
          <AccordionDetails sx={AccordionDetailsSx}>
            <RowDataWrapper>
              {modalDataArray?.finalData?.map((rowData, index) => (
                <RowData key={uniqueId()}>
                  <SecondaryLabelWrapper>
                    <LabelAndButtonWrapper>
                      {modalDataArray?.secondaryLabel !== undefined && (
                        <FieldLabel fieldStyle={secondaryLabelStyle}>
                          {modalDataArray?.secondaryLabel[index]}
                        </FieldLabel>
                      )}
                      {hasRowLevelEditDelete && (
                        <TextWrapper width="10%" gap="0.5rem">
                          <EditIconWithHoverEffect
                            data-testid={`edit-icon`}
                            onClick={() => editHandler(rowData[0]?.id || null)}
                          />
                          {rowData.find(
                            (item) =>
                              item.label === 'isImplementedInActivities',
                          )?.value !== true && (
                            <DeleteIconWithHoverEffect
                              data-testid={`delete-icon`}
                              sx={deleteIconSx}
                              onClick={() =>
                                deleteHandler(
                                  rowData[0]?.id,
                                  false,
                                  modalDataArray?.secondaryLabel[index],
                                )
                              }
                            />
                          )}
                        </TextWrapper>
                      )}
                    </LabelAndButtonWrapper>
                    {modalDataArray?.secondaryLabel[index] !== 'None' &&
                      modalDataArray?.secondaryLabel[index] !==
                        Tillage_content.noTillageOption && (
                        <DataWrapper>
                          {rowData.map((item) => {
                            const implementedActivitiesAdded =
                              item.label !== 'isImplementedInActivities';
                            if (implementedActivitiesAdded) {
                              return (
                                <Field key={item.label}>
                                  <FieldLabel
                                    fieldStyle={checkEmptyDates(
                                      mainLabel,
                                      item.value,
                                      item.label,
                                      datesLabelList,
                                      fieldLabelStyle,
                                      false, // isRenderValue
                                    )}>
                                    {item.label}
                                  </FieldLabel>
                                  <FieldValueWrapper>
                                    <FieldLabel
                                      fieldStyle={checkEmptyDates(
                                        mainLabel,
                                        item.value,
                                        item.label,
                                        datesLabelList,
                                        fieldValueStyle,
                                        false, // isRenderValue
                                      )}>
                                      {checkEmptyDates(
                                        mainLabel,
                                        item.value,
                                        item.label,
                                        datesLabelList,
                                        fieldValueStyle,
                                        true, // isRenderValue
                                      )}
                                    </FieldLabel>
                                    {item?.unit !== undefined && (
                                      <FieldLabel
                                        fieldStyle={fieldUnitStyle}
                                        style={{ marginTop: '0.0625rem' }}>
                                        {item?.unit}
                                      </FieldLabel>
                                    )}
                                  </FieldValueWrapper>
                                </Field>
                              );
                            }
                          })}
                        </DataWrapper>
                      )}
                  </SecondaryLabelWrapper>
                </RowData>
              ))}
            </RowDataWrapper>
            <PracticesDataWrapper>
              {mandatoryPractices.length > 0 && (
                <PracticesDataWrapper>
                  {mandatoryPractices?.map((practice) => {
                    const modalTypeData = modalDataArray?.practicesModals.find(
                      (item) => Object.keys(item)[0] === practice.modalList,
                    )?.[practice.modalList];

                    const isModalEmpty = modalTypeData?.finalData?.length === 0;

                    return (
                      isModalEmpty && (
                        <EmptyStateComponent
                          key={practice.id}
                          type={`${practice.label} (0)`}
                          onClick={practice.clickHandler(itemId)}
                        />
                      )
                    );
                  })}
                </PracticesDataWrapper>
              )}
              {modalDataArray?.practicesModals?.map((practiceModal, index) => {
                const keyVal = index;
                const listKey = Object.keys(practiceModal)[0];
                const listData = practiceModal[listKey];
                if (listData && listData.finalData?.length > 0) {
                  return (
                    <PracticesDataWrapper key={keyVal}>
                      <BaselineAccordionDisplayBox
                        copiedDataPresent={copiedDataPresent}
                        width={'100%'}
                        modalDataArray={listData}
                        mainLabel={`${listData.modalType} (${
                          listData.secondaryLabel?.filter(
                            (item) => item !== 'None',
                          ).length
                        })`}
                        itemId={listData?.itemId[0]}
                        handleClickDelete={handleClickDelete}
                        handleClickEdit={handleClickEdit}
                        deleteModalHeading={deleteData?.deleteModalHeading}
                        deleteModalContent={deleteData?.deleteModalContent}
                        hasRowLevelEditDelete={hasRowLevelEditDelete}
                        hasAddBtn={hasAddBtn}
                        addBtnLabel={` ${listData.modalType}`}
                        secondaryLabelStyle={secondaryLabelStyle}
                        fieldLabelStyle={fieldLabelStyle}
                        fieldUnitStyle={fieldUnitStyle}
                        deleteData={deleteData}
                        setDeleteData={setDeleteData}
                        addNewBtnHandler={allPracticesData?.data
                          ?.find((item) => item.label === listData.modalType)
                          .clickHandler(itemId)}
                        parentId={itemId}
                        cropType={mainLabel?.split(' - ')[1]}
                      />
                    </PracticesDataWrapper>
                  );
                }
                return null;
              })}
            </PracticesDataWrapper>
          </AccordionDetails>
        </Accordion>
      </MainWrapper>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={() => {
          handleClickDelete(parentId);
          setIsDialogOpen(false);
        }}
        subtitle={deleteModalContent}
        onCancel={() => {
          setIsDialogOpen(false);
          setDeleteData({
            id: null,
            isWholeModalDeleted: false,
            modalType: '',
            practiceTypeValue: '',
            deleteModalHeading: '',
            deleteModalContent: '',
            parentId: null,
            cropType: '',
          });
        }}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        subtitleColor={DARK_CHARCOAL}
        isInsideBaseline
        title={deleteModalHeading}></DialogBox>
    </>
  );
};

BaselineAccordionDisplayBox.propTypes = {
  modalDataArray: PropTypes.array,
  mainLabel: PropTypes.string,
  itemId: PropTypes.string,
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  deleteModalHeading: PropTypes.string,
  deleteModalContent: PropTypes.string,
  width: PropTypes.string,
  fieldValueStyle: PropTypes.object,
  secondaryLabelStyle: PropTypes.object,
  fieldLabelStyle: PropTypes.object,
  fieldUnitStyle: PropTypes.object,
  hasAddBtn: PropTypes.bool,
  addBtnLabel: PropTypes.string,
  hasRowLevelEditDelete: PropTypes.bool,
  addNewBtnHandler: PropTypes.func,
  deleteData: PropTypes.object,
  setDeleteData: PropTypes.func,
  subText: PropTypes.string,
  hasPracticesDropdown: PropTypes.bool,
  allPracticesData: PropTypes.array,
  parentId: PropTypes.string,
  cropType: PropTypes.string,
  copiedDataPresent: PropTypes.bool,
  isInActivity: PropTypes.bool,
};

export default BaselineAccordionDisplayBox;
