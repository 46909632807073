import InputField from 'components/FormComponents/InputField/InputField.react';
import { useParticipantGeneralFieldInfo } from 'hooks/useParticipantGeneralFieldInfo';
import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  BLACK,
  COOL_GRAY,
  DARK_CHARCOAL,
  RED,
  WHITE,
} from 'theme/GlobalColors';
import {
  FIELD_INFO_CONSTANT,
  YesNoIdontknowTypes,
  YesNoTypes,
  activityListLabels,
  getNext10Years,
} from './ParticipantGeneralFieldInfoForm.content';
import {
  EDIT_GENERAL_FIELD_INF0,
  FETCH_ACTIVITY_DROPDOWN_LIST,
  FETCH_GENERAL_FIELD_INFO,
  FETCH_LAND_USE_TYPES,
  PARTICIPANT_PROFILE,
  PARTICIPANT_REPORT_FIRST_PART,
} from 'urls';
import {
  BoldTextWrapper,
  DropdownDateWrapper,
  FieldWebformDisclaimer,
  FieldWrapper,
  MainContainer,
  TextWrapper,
  addProjectActivityBtnSx,
  dividerSx,
  toastStyle,
} from './ParticipantGeneralFieldInfoForm.style';
import ActivityModal from './ActivityModal/ActivityModal.react';
import { Backdrop, Button, CircularProgress, Divider } from '@mui/material';
import axios from 'axios';
import {
  checkIfValueZero,
  checkTernaryCondition,
  checkValidEndYear,
  formatBoundaries,
  isIntersect,
  isValueEmptyNullorUndefined,
} from 'utils/helper';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { useNavigate } from 'react-router';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import UserNavigationButtons from '../UserNavigationButtons/UserNavigationButtons.react';
import {
  handleNextInProgrssSwitcher,
  checkIsEnrollmentCompleted,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  FormLabels,
  primaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import AccordionDisplayBox from 'components/AccordionDisplayBox/AccordionDisplayBox.react';
import { STYLE_CONSTANTS } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { radioBtnPadding } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { CSAFToolTipSx } from 'theme/GlobalStyles';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import DropdownDateComponent from './DropdownDateComponent/DropdownDateComponent.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';

const ParticipantGeneralFieldInfoForm = ({
  /* id, */
  participantId,
  name,
  projectId,
  /* isEditModeOn, */
  selectedFieldId,
  selectedFieldBoundaries,
  isMockFarmCsafUpdatedBySystem = false,
}) => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectIdValue = searchParams.get('projectid');
  const participantIdValue = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const [isFieldEditMode, setIsFieldEditMode] = useState(false);
  const [farmDetails, setFarmDetails] = useState([]);
  const [activityDropDownList, setActivityDropDownList] = useState([]);
  const [totalFieldAreaSum, setTotalFieldAreaSum] = useState(null);
  const [numberOfFields, setNumberOfFields] = useState(1);
  const [activityList, setActivityList] = useState([]);
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [csafImplementedFromApi, setCsafImplementedFromApi] = useState('');
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
  const [landUseTypeList, setLandUseTypeList] = useState([
    {
      label: '',
      value: 1,
    },
  ]);
  const yearDropdownList = getNext10Years();
  const {
    currentFarmId,
    setFarmInfo,
    farmInfo,
    savedFieldsForms,
    setSavedFiledForms,
    setSelectedFieldId,
    setShowFieldInfoForm,
    fieldPolygons,
    setFieldPolygons,
    addFarmClicked,
    setAddFarmClicked,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    setOpenMapView,
    fieldIdsAddedFromMap,
    setFieldIdsAddedFromMap,
    updateFarmFieldName,
    currentFarmInfo,
    setSubmitData,
    isFormUnmounted,
    submitData,
    setIsFormUnmounted,
    setRightSectionId,
    setUserActivityDetected,
    isFieldDeleted,
    setIsFieldDeleted,
    participantProfileStatus,
    setModalData,
    enrolledFieldIds,
    isFarmCsafUpdatedBySystem,
    setIsFieldSubmitted,
    fetchParticipantData,
    isParticipantContributor,
    statusList,
  } = useContext(participantInfoContext);
  const { isEmailLinkClicked, userRoles } = useContext(userAuthRolesContext);
  const {
    formValue,
    /*formFieldChangeHandler,
    customFieldChangeHandler, */
    setFormValue,
    handleTextFieldValueChange,
    error,
    setError,
  } = useParticipantGeneralFieldInfo();

  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (field.id !== selectedFieldId?.id && field.value === name.trim())
          count++;
      }
    }

    return count === 0;
  }, [name]);

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);

  const buttonRef = useRef(null);

  useEffect(() => {
    if (!open) {
      buttonRef.current?.blur();
    }
  }, [open]);

  useEffect(() => {
    if (addFarmClicked.flag) {
      handleClickSubmit(
        false,
        addFarmClicked.flag,
        formValue,
        currentFarmId,
        true,
      );
      setCurrentFarmId(addFarmClicked.id);
      setExpandedFarmId(addFarmClicked.id);
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(false);
      setOpenMapView(false);
    }
  }, [addFarmClicked]);

  useEffect(() => {
    setSubmitData({
      currentPage: 'Field',
      data: formValue,
      currentId: currentFarmId,
    });
  }, [formValue]);

  const disableAllFields = useMemo(() => {
    if (!enrolledFieldIds) return false;
    return enrolledFieldIds?.includes(selectedFieldId.id);
  }, [enrolledFieldIds, selectedFieldId.id]);

  useEffect(() => {
    const fieldDisclaimerCheck =
      (currentFarmInfo?.csafImplemented === 'No' ||
        currentFarmInfo?.csafImplemented === "I don't know") &&
      formValue.csafImplemented === 'Yes';
    if (
      (isFarmCsafUpdatedBySystem && formValue.farmCsafUpdatedByField) ||
      isMockFarmCsafUpdatedBySystem
    ) {
      setIsDisclaimerVisible(
        checkTernaryCondition(
          csafImplementedFromApi === formValue.csafImplemented &&
            formValue.csafImplemented !== '',
          true,
          fieldDisclaimerCheck,
        ),
      );
    } else {
      setIsDisclaimerVisible(fieldDisclaimerCheck);
    }
  }, [
    formValue.csafImplemented,
    currentFarmInfo?.csafImplemented,
    isFarmCsafUpdatedBySystem,
    csafImplementedFromApi,
    formValue.farmCsafUpdatedByField,
  ]);

  useEffect(() => {
    setFormValue({
      ...formValue,
      farmCsafUpdatedByField: isDisclaimerVisible,
    });
  }, [isDisclaimerVisible]);

  useEffect(() => {
    const hasHistoricallyImplementedYes = activityList.some((activity) => {
      return (
        activity.historicallyImplemented === 'Yes' &&
        (formValue.csafImplemented === 'No' ||
          formValue.csafImplemented === "I don't know" ||
          activity.fieldCsafUpdatedByActivity)
      );
    });
    const updatedFormValue = {
      ...formValue,
      csafImplemented: checkTernaryCondition(
        hasHistoricallyImplementedYes || isMockFarmCsafUpdatedBySystem,
        'Yes',
        formValue.csafImplemented,
      ),
      csafUpdatedBySystem:
        hasHistoricallyImplementedYes || isMockFarmCsafUpdatedBySystem,
    };
    setFormValue(updatedFormValue);
  }, [activityList]);

  useEffect(() => {
    if (isFormUnmounted.flag) {
      if (
        isFieldEditMode &&
        submitData.data !== null &&
        submitData.currentPage === 'Field'
      ) {
        handleClickSubmit(
          false,
          false,
          submitData.data,
          submitData.currentId,
          false,
        );
        setSubmitData({
          currentPage: null,
          data: null,
          currentId: null,
        });
      }
      setIsFormUnmounted({
        currentPage: null,
        flag: false,
        id: 0,
      });
    }
  }, [isFormUnmounted, submitData]);

  useEffect(() => {
    if (farmDetails?.length > 0 && farmDetails !== undefined) {
      setIsFieldEditMode(
        farmDetails
          .filter((farm) => farm.farmId === currentFarmId)[0]
          ?.fieldDetails?.filter(
            (field) => field.fieldId === selectedFieldId.id,
          ).length > 0,
      );
    }
  }, [farmDetails, selectedFieldId]);

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,

      totalFieldAreaGreaterThanFarmArea:
        totalFieldAreaSum > currentFarmInfo?.totalFarmAcreage,
    }));
  }, [formValue, currentFarmInfo, totalFieldAreaSum]);
  const returnErrorObject = (formValue, item) => {
    if (
      isValueEmptyNullorUndefined(getValueFromLandUseTypeId(item.landuseTypeId))
    ) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.need2HistoricLandUseTypeError,
      };
    }
    if (
      checkIfValueZero(item.startYear) ||
      checkIfValueZero(item.endYear) ||
      isValueEmptyNullorUndefined(item.startYear) ||
      isValueEmptyNullorUndefined(item.endYear)
    ) {
      return {
        isError: true,
        errorMessage:
          FIELD_INFO_CONSTANT.needStartAndEndDateForHistoricLandUseTypeError,
      };
    }
    if (isIntersect(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
      };
    }
    if (!checkValidEndYear(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
      };
    }
    return { isError: false, errorMessage: '' }; // Return null if no error
  };
  const returnErrorObjectOnChange = (formValue, item) => {
    if (isIntersect(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
      };
    }
    if (!checkValidEndYear(...formValue.fieldHistoricLanduseList)) {
      return {
        isError: true,
        errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
      };
    }
    return { isError: false, errorMessage: '' }; // Return null if no error
  };
  useEffect(() => {
    const farm = farmDetails?.find(
      (farmObj) => farmObj?.farmId === currentFarmId,
    );

    if (farm) {
      let totalFieldsArea = 0;
      setNumberOfFields(farm?.fieldDetails?.length);

      farm?.fieldDetails?.forEach((field) => {
        if (field?.fieldId === selectedFieldId?.id) {
          totalFieldsArea += Number(formValue?.totalFieldArea);
        } else {
          totalFieldsArea += field?.totalFieldArea;
        }
      });

      setTotalFieldAreaSum(totalFieldsArea);
    }
  }, [farmDetails, currentFarmId, selectedFieldId, formValue]);

  const setValueOfInput = (activities) => {
    return activities?.map((activity) => {
      return {
        fieldCropProjectActivityId: activity?.fieldCropProjectActivityId,
        projectActivityId: activity?.projectActivityId,
        date: activity?.yearPlanned,
        totalArea: activity?.plannedTotalArea,
        totalHeadCount: activity?.plannedTotalHeadcount,
        historicallyImplemented: activity?.historicallyImplemented,
        fieldCsafUpdatedByActivity: activity?.fieldCsafUpdatedByActivity,
        fieldPlannedActivityHistory: checkTernaryCondition(
          activity?.activityHistory.length > 0,
          activity?.activityHistory?.map((history) => {
            return {
              fieldProjectActivityHistoricalId:
                history?.fieldProjectActivityHistoricalId || 0,
              implementationYear:
                history?.implementationYear || Number(activity?.yearPlanned),
              totalArea: history?.totalArea,
              historicallyImplemented: history?.implementedHistorically,
            };
          }),
          [
            {
              fieldProjectActivityHistoricalId: 0,
              implementationYear: (
                Number(activity?.yearPlanned) - 3
              )?.toString(),
              totalArea: '',
              historicallyImplemented: '',
            },
            {
              fieldProjectActivityHistoricalId: 0,
              implementationYear: (
                Number(activity?.yearPlanned) - 2
              )?.toString(),
              totalArea: '',
              historicallyImplemented: '',
            },
            {
              fieldProjectActivityHistoricalId: 0,
              implementationYear: (
                Number(activity?.yearPlanned) - 1
              )?.toString(),
              totalArea: '',
              historicallyImplemented: '',
            },
          ],
        ),
      };
    });
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(FETCH_LAND_USE_TYPES)
      .then((response) => {
        const responseData = response.data;
        const mappedData = responseData.map((item) => ({
          value: item.id,
          label: item.value,
        }));
        setLandUseTypeList(mappedData);
      })
      .catch(() => {});
  }, []);
  const getValueFromLandUseTypeId = (id) => {
    return landUseTypeList.find((item) => item.value === id)?.label;
  };
  const fetchEditData = () => {
    setLoading(true);
    axios
      .get(
        `${FETCH_GENERAL_FIELD_INFO}?fieldId=${selectedFieldId.id}&projectId=${projectId}`,
      )
      .then((response) => {
        if (response.data.activityList.length > 0) {
          setActivityList(setValueOfInput(response.data.activityList));
        } else {
          setActivityList([]);
        }
        setCsafImplementedFromApi(response.data.csafImplemented);
        const historicLandUseList =
          response.data.historicLanduseList === null
            ? [{ landuseTypeId: '', startYear: '', endYear: '' }]
            : response.data.historicLanduseList.map((item) => ({
                ...item,
                startYear: item.startYear.toString(),
                endYear: item.endYear.toString(),
              }));
        setFormValue((prevFormValue) => {
          return {
            ...prevFormValue,
            fsaFieldId: response.data.fsaFieldId,
            fsaTractId: response.data.fsaTractId,
            totalFieldArea: checkTernaryCondition(
              response.data.totalFieldArea === -1.0,
              '',
              response.data.totalFieldArea,
            ),
            csafImplemented: response.data.csafImplemented,
            csafUpdatedBySystem: response.data.csafUpdatedBySystem,
            farmCsafUpdatedByField: response.data.farmCsafUpdatedByField,
            isConsistentLanduse20Yrs: response.data.isConsistentLanduse20Yrs,
            landUseTypeId:
              response.data.landuseTypeId === null
                ? -1
                : response.data.landuseTypeId,
            fieldHistoricLanduseList: historicLandUseList,
          };
        });

        if (fieldIdsAddedFromMap.includes(selectedFieldId.id)) {
          setFieldIdsAddedFromMap((prev) =>
            prev.filter((fieldId) => fieldId !== selectedFieldId.id),
          );
        } else {
          setError((prevError) => ({
            ...prevError,
            fsaTractId: !response.data.fsaTractId.trim(),
            fsaFieldId: !response.data.fsaFieldId.trim(),
            totalFieldArea: response.data.totalFieldArea === 0,
            fieldName: !response.data.fieldName.trim(),
            projectActivityDtoList: response.data.activityList.length === 0,
            csafImplementedRequiredError: isValueEmptyNullorUndefined(
              response.data.csafImplemented,
            ),
            commonLandUseTypeFor3YearsError: isValueEmptyNullorUndefined(
              getValueFromLandUseTypeId(response.data.landuseTypeId),
            ),
            isConsistentFor20YearsError: isValueEmptyNullorUndefined(
              response.data.isConsistentLanduse20Yrs,
            ),

            dropDownDateComponentError: response.data.historicLanduseList
              ?.map((item) => {
                if (
                  isValueEmptyNullorUndefined(
                    getValueFromLandUseTypeId(item.landuseTypeId),
                  )
                ) {
                  return {
                    isError: true,
                    errorMessage:
                      FIELD_INFO_CONSTANT.need2HistoricLandUseTypeError,
                  };
                }

                if (
                  checkIfValueZero(item.startYear) ||
                  checkIfValueZero(item.endYear) ||
                  isValueEmptyNullorUndefined(item.startYear.toString()) ||
                  isValueEmptyNullorUndefined(item.endYear.toString())
                ) {
                  return {
                    isError: true,
                    errorMessage:
                      FIELD_INFO_CONSTANT.needStartAndEndDateForHistoricLandUseTypeError,
                  };
                }
                if (isIntersect(...historicLandUseList)) {
                  return {
                    isError: true,
                    errorMessage: FIELD_INFO_CONSTANT.dateIntersectError,
                  };
                }
                if (!checkValidEndYear(...historicLandUseList)) {
                  return {
                    isError: true,
                    errorMessage: FIELD_INFO_CONSTANT.endYearGreaterError,
                  };
                }
                return { isError: false, errorMessage: '' }; // Return null if no error
              })
              .find((error) => error.isError) ?? {
              isError: false,
              errorMessage: '',
            },
          }));
        }

        // On initial fetch we set the user activity to false, once any value is changed, we update the flag
        setUserActivityDetected(false);
      })
      .finally(() => setLoading(false));
  };

  //to fetch updated farm data
  const fetchUpdatedData = (
    isInitialCall,
    updatedFieldId,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    axios
      .get(
        `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
      )
      .then((response) => {
        const farmIds = response.data?.farmDetails;
        const currentFarmStatus = response.data?.farmDetails?.find(
          (farm) => farm.farmId === currentFarmId,
        )?.farmStatus;
        setFarmInfo((prevFarmInfo) => {
          return prevFarmInfo.map((farm) => {
            if (farm.id === currentFarmId) {
              return {
                ...farm,
                status: currentFarmStatus,
              };
            }
            return farm;
          });
        });
        setFarmDetails(farmIds);
        // to navigate to next field section in enrollment
        if (!isInitialCall) {
          const getCurrentFarm = updatedFarmInfo.filter(
            (farm) => farm.id === currentFarmId,
          )[0];
          const currentFieldIndex = getCurrentFarm?.fields?.findIndex(
            (field) => field.id === updatedFieldId.fieldId,
          );
          const lastField =
            getCurrentFarm?.fields[getCurrentFarm?.fields.length - 1];
          if (
            getCurrentFarm?.id ===
              updatedFarmInfo[updatedFarmInfo.length - 1]?.id &&
            lastField?.id === updatedFieldId.fieldId &&
            navigateToNext
          ) {
            submitModalOpenHandler(updatedFarmInfo, response);
            return;
          }
          if (navigateToNext) {
            let inProgressItem = null;
            inProgressItem = handleNextInProgrssSwitcher(
              inProgressItem,
              updatedFarmInfo,
              getCurrentFarm,
              updatedFieldId,
              false,
            );
            formInProgressHandler(
              inProgressItem,
              currentFieldIndex,
              getCurrentFarm,
              updatedFarmInfo,
            );
          }
        }
        setIsFieldDeleted(false);
      });
  };

  const submitModalOpenHandler = (updatedFarmInfo, response) => {
    let hasInCompleteFormData = false;
    for (const farm of updatedFarmInfo) {
      if (farm.status.toLowerCase() === 'in progress') {
        hasInCompleteFormData = true;
        break;
      }
      const fieldList = farm.fields;
      for (const field of fieldList) {
        if (field.status === 'In Progress') {
          hasInCompleteFormData = true;
          break;
        }
      }
      if (hasInCompleteFormData) break;
    }

    setModalData({
      flag:
        !hasInCompleteFormData &&
        (response.data.participantStatus === FIELD_INFO_CONSTANT.completed ||
          isParticipantContributor),
      id: 0,
    });
  };

  const formInProgressHandler = (
    inProgressItem,
    currentFieldIndex,
    currentFarm,
    updatedFarmInfo,
  ) => {
    if (inProgressItem !== null) {
      if (inProgressItem.type === 'field') {
        setSelectedFieldId({
          id: inProgressItem.id,
          name: inProgressItem.value,
        });
        setCurrentFarmId(inProgressItem.farmId);
        setExpandedFarmId(inProgressItem.farmId);
        setFarmInfoCurrentTab(0);
        setShowFieldInfoForm(true);
      } else if (inProgressItem.type === 'farm') {
        setCurrentFarmId(inProgressItem.id);
        setExpandedFarmId(inProgressItem.id);
        setFarmInfoCurrentTab(0);
        setShowFieldInfoForm(false);
      }
    } else if (inProgressItem === null) {
      //go to next completed sections
      const nextField = currentFarm?.fields[currentFieldIndex + 1];
      const currentFarmIndex = updatedFarmInfo.findIndex(
        (farm) => farm.id === currentFarm.id,
      );
      if (currentFieldIndex < currentFarm.fields.length - 1) {
        // goto next field
        setSelectedFieldId({
          id: nextField.id,
          name: nextField.value,
        });
      } else if (currentFarmIndex < updatedFarmInfo.length - 1) {
        const nextFarmId = updatedFarmInfo[currentFarmIndex + 1].id;
        setCurrentFarmId(nextFarmId);
        setExpandedFarmId(nextFarmId);
        setShowFieldInfoForm(false);
        setFarmInfoCurrentTab(0);
      }
    }
  };

  const axiosCall = (status, formValue, currentFarmId) => {
    const updatedActivityList = activityList.map((item) => {
      if (item.fieldCropProjectActivityId?.toString().includes('-')) {
        item.fieldCropProjectActivityId = 0;
      }
      const updatedHistory = item.fieldPlannedActivityHistory.map(
        (historyItem) => {
          if (
            historyItem.fieldProjectActivityHistoricalId
              ?.toString()
              .includes('-')
          ) {
            historyItem.fieldProjectActivityHistoricalId = 0;
          }
          return historyItem;
        },
      );
      return {
        ...item,
        fieldPlannedActivityHistory: updatedHistory,
      };
    });
    const finalDataOnAdd = {
      fieldName: name,
      fsaTractId: formValue.fsaTractId,
      fsaFieldId: formValue.fsaFieldId,
      totalFieldArea: +formValue.totalFieldArea,
      csafImplemented: formValue.csafImplemented,
      formStatus: status,
      activityList: updatedActivityList,
      fieldBoundaries: formatBoundaries(selectedFieldBoundaries),
      csafUpdatedBySystem: formValue.csafUpdatedBySystem,
      farmCsafUpdatedByField: formValue.farmCsafUpdatedByField,
      projectCycleId: projectCycleId,
    };
    const finalDataOnEdit = {
      fieldName: name,
      fsaTractId: formValue.fsaTractId,
      fsaFieldId: formValue.fsaFieldId,
      totalFieldArea: +formValue.totalFieldArea,
      csafImplemented: formValue.csafImplemented,
      formStatus: status,
      activityList: updatedActivityList,
      fieldBoundaries: formatBoundaries(selectedFieldBoundaries),
      csafUpdatedBySystem: formValue.csafUpdatedBySystem,
      farmCsafUpdatedByField: formValue.farmCsafUpdatedByField,
      enrollmentInstanceId: +enrollmentInstanceId,
      participantId: +participantIdValue,
      isConsistentLanduse20Yrs: formValue.isConsistentLanduse20Yrs,
      landuseTypeId: +formValue.landUseTypeId,
      ...(formValue.isConsistentLanduse20Yrs === 'No' && {
        historicLanduseList: formValue.fieldHistoricLanduseList,
      }),
      projectCycleId: projectCycleId,
    };
    const url = checkTernaryCondition(
      isFieldEditMode,
      `${EDIT_GENERAL_FIELD_INF0}?fieldId=${selectedFieldId.id}&projectId=${projectId}`,
      `${PARTICIPANT_REPORT_FIRST_PART}/enroll/general-field-info?farmId=${currentFarmId}&participantId=${participantId}&projectId=${projectId}`,
    );
    if (isFieldEditMode) {
      return axios.put(url, finalDataOnEdit);
    } else {
      return axios.post(url, finalDataOnAdd);
    }
  };

  const handleClickSubmit = (
    isSaveAndCloseClicked,
    isAddFarmClicked,
    formValue,
    currentFarmId,
    navigateToNext,
  ) => {
    setLoading(true);

    const thenBlock = (responseData, updatedFarmInfo) => {
      setFarmInfo(updatedFarmInfo);
      if (!isAddFarmClicked) {
        if (isSaveAndCloseClicked) {
          if (isEmailLinkClicked) {
            navigateOnCloseHandler(
              userRoles[0] === FIELD_INFO_CONSTANT.PARTICIPANT_CONTRIBUTOR
                ? checkTernaryCondition(
                    validateForm(),
                    FIELD_INFO_CONSTANT.completed,
                    FIELD_INFO_CONSTANT.in_progress,
                  )
                : statusList,
            );
          } else {
            navigateHandler();
          }
        } else if (navigateToNext) {
          navigateToNextSectionHandler(
            responseData,
            updatedFarmInfo,
            navigateToNext,
          );
        }
      }
    };

    if (disableAllFields) {
      thenBlock(
        {
          field: {
            fieldId: selectedFieldId?.id,
            fieldName: selectedFieldId?.name,
          },
        },
        farmInfo,
      );
      setLoading(false);
      setIsEditInProgress(false);
    } else {
      setIsEditInProgress(true);
      setAddFarmClicked({ flag: false, id: 0 });
      axiosCall(
        checkTernaryCondition(
          validateForm(),
          FIELD_INFO_CONSTANT.completed,
          FIELD_INFO_CONSTANT.in_progress,
        ),
        formValue,
        currentFarmId,
      )
        .then((response) => {
          const updatedFarmInfo = farmInfo.map((farm) => {
            return checkTernaryCondition(
              farm.id === currentFarmId,
              {
                ...farm,
                status: response?.data?.farmFormStatus,
                fields: farm?.fields.map((field) => {
                  return checkTernaryCondition(
                    field.id === response.data.field?.fieldId,
                    {
                      ...field,
                      status: response.data.field?.formStatus,
                    },
                    field,
                  );
                }),
              },
              farm,
            );
          });
          setIsFieldSubmitted(true);
          fetchParticipantData(false);
          thenBlock(response.data, updatedFarmInfo);
        })
        .catch(() => {
          toast(
            <CustomSnackbar
              type="error"
              message={`Field ${name} could not be ${checkTernaryCondition(
                isFieldEditMode,
                'updated',
                'added',
              )}`}
            />,
            toastStyle,
          );
          setRightSectionId(2);
          setCurrentFarmId(currentFarmId);
          setExpandedFarmId(currentFarmId);
          setSelectedFieldId({
            id: selectedFieldId.id,
            name: name,
          });
          setFarmInfoCurrentTab(0);
          setShowFieldInfoForm(true);
        })
        .finally(() => {
          setLoading(false);
          setIsEditInProgress(false);
        });
    }
    if (isSaveAndCloseClicked && isEmailLinkClicked) {
      navigateOnCloseHandler(
        userRoles[0] === FIELD_INFO_CONSTANT.PARTICIPANT_CONTRIBUTOR
          ? checkTernaryCondition(
              validateForm(),
              FIELD_INFO_CONSTANT.completed,
              FIELD_INFO_CONSTANT.in_progress,
            )
          : statusList,
      );
    }
  };

  const navigateToNextSectionHandler = (
    fieldData,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    //filter out current farm from farmInfo
    let isEnrollmentCompleted = checkIsEnrollmentCompleted(
      updatedFarmInfo,
      validateForm(),
      participantProfileStatus,
      isParticipantContributor,
    );
    handleEnrollmentcompletion(
      isEnrollmentCompleted,
      fieldData,
      updatedFarmInfo,
      navigateToNext,
    );
  };

  const handleEnrollmentcompletion = (
    isEnrollmentCompleted,
    fieldData,
    updatedFarmInfo,
    navigateToNext,
  ) => {
    if (isEnrollmentCompleted) {
      setModalData({
        flag: isEnrollmentCompleted,
        id: 0,
      });
      fetchUpdatedData(
        false,
        {
          fieldId: fieldData.field?.fieldId,
          fieldName: fieldData.field?.fieldName,
        },
        updatedFarmInfo,
        false,
      );
    } else {
      setSavedFiledForms([...savedFieldsForms, selectedFieldId.id]);
      fetchUpdatedData(
        false,
        {
          fieldId: fieldData.field?.fieldId,
          fieldName: fieldData.field?.fieldName,
        },
        updatedFarmInfo,
        navigateToNext,
      );
      setFieldPolygons(
        fieldPolygons.map((polygon) =>
          checkTernaryCondition(
            polygon.fieldId === selectedFieldId.id,
            {
              ...polygon,
              fieldId: fieldData.field?.fieldId,
            },
            polygon,
          ),
        ),
      );
    }
  };

  useEffect(() => {
    isFieldEditMode && !isEditInProgress && fetchEditData();
  }, [currentFarmInfo, isFieldEditMode, selectedFieldId, isEditInProgress]);

  const validateForm = () => {
    const finalError = { ...error };
    finalError.fsaFieldId = formValue.fsaFieldId === '';
    finalError.fsaTractId = formValue.fsaTractId === '';
    finalError.csafImplementedRequiredError = isValueEmptyNullorUndefined(
      formValue.csafImplemented,
    );
    finalError.projectActivityDtoList = activityList?.length === 0;
    finalError.commonLandUseTypeFor3YearsError = isValueEmptyNullorUndefined(
      getValueFromLandUseTypeId(formValue.landUseTypeId),
    );
    finalError.isConsistentFor20YearsError = isValueEmptyNullorUndefined(
      formValue.isConsistentLanduse20Yrs,
    );
    if (formValue.isConsistentLanduse20Yrs === 'No') {
      finalError.dropDownDateComponentError = formValue.fieldHistoricLanduseList
        .map((item) => {
          return returnErrorObject(formValue, item);
        })
        .find((error) => error.isError) ?? { isError: false, errorMessage: '' };
    } else {
      finalError.dropDownDateComponentError = {
        isError: false,
        errorMessage: '',
      };
    }

    setError(finalError);
    const formErrorArray = Object.values(finalError);
    formErrorArray.push(finalError.dropDownDateComponentError.isError);
    return !formErrorArray.includes(true) && isFieldNameUnique;
  };

  useEffect(() => {
    isFieldEditMode && fetchEditData();
    fetchUpdatedData(true, 0, null, true);
    fetchActivityDropdownList();
  }, []);

  useEffect(() => {
    isFieldDeleted && fetchUpdatedData(true, 0, null, true);
  }, [isFieldDeleted]);

  useEffect(() => {
    if (error.projectActivityDtoList && activityList.length > 0) {
      setError({
        ...error,
        projectActivityDtoList: false,
      });
    }
  }, [activityList]);
  const onAddButtonClick = () => {
    setOpen(true);
  };

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectIdValue,
          participantid: participantIdValue,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  const fetchActivityDropdownList = () => {
    axios
      .get(FETCH_ACTIVITY_DROPDOWN_LIST, {
        params: { projectId: projectIdValue },
      })
      .then((response) => {
        setActivityDropDownList(
          response.data.map((activity) => ({
            label: activity.value,
            value: activity.id,
          })),
        );
      });
  };

  const handleClickDelete = (itemId) => {
    const filterData = activityList.filter(
      (obj) => obj.fieldCropProjectActivityId !== itemId,
    );
    setActivityList(filterData);
    makeFormDirty();
  };

  const handleClickEdit = (itemId) => {
    setIsEdit(itemId);
    setOpen(true);
  };

  const addAnotherHistoricLandUseType = () => {
    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: [
          ...prevState.fieldHistoricLanduseList,
          { landuseTypeId: -1, startYear: '', endYear: '' },
        ],
      };
    });
  };

  const handleChangeOnHistoricLandUseType = (index, key, value) => {
    const updatedHistoricLandUseType = formValue.fieldHistoricLanduseList.map(
      (item, i) => {
        if (i === index) {
          return { ...item, [key]: value };
        }
        return item;
      },
    );

    const dropdownCompErr = updatedHistoricLandUseType
      .map((item) => {
        return returnErrorObjectOnChange(
          {
            ...formValue,
            fieldHistoricLanduseList: updatedHistoricLandUseType,
          },
          item,
        );
      })
      .find((error) => error.isError) ?? { isError: false, errorMessage: '' };

    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: updatedHistoricLandUseType,
      };
    });
    setError((prevError) => ({
      ...prevError,
      dropDownDateComponentError: dropdownCompErr,
    }));
  };
  const removeHistoricLandUseType = (index) => {
    const updatedHistoricLandUseType =
      formValue.fieldHistoricLanduseList.filter((item, i) => i !== index);

    const dropdownCompErr = updatedHistoricLandUseType
      .map((item) => {
        return returnErrorObject(
          {
            ...formValue,
            fieldHistoricLanduseList: updatedHistoricLandUseType,
          },
          item,
        );
      })
      .find((error) => error.isError) ?? { isError: false, errorMessage: '' };
    setFormValue((prevState) => {
      return {
        ...prevState,
        fieldHistoricLanduseList: updatedHistoricLandUseType,
      };
    });
    setError((prevError) => ({
      ...prevError,
      dropDownDateComponentError: dropdownCompErr,
    }));
  };

  const navigateOnCloseHandler = (status) => {
    if (status === FIELD_INFO_CONSTANT.COMPLETED) {
      navigateHandler();
    } else {
      navigate(`${pathName.participant.landingPage}`);
    }
  };

  return (
    <MainContainer data-testid="field-info">
      <Backdrop
        sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color={FIELD_INFO_CONSTANT.success} />
      </Backdrop>
      <InputField
        isDisabled={disableAllFields}
        label={FIELD_INFO_CONSTANT.field_name}
        value={name}
        placeholder={FIELD_INFO_CONSTANT.field_name_placeholder}
        name={FIELD_INFO_CONSTANT.field_name}
        width="60%"
        isRequired
        primaryError={error.fieldName}
        primaryErrorMessage={FIELD_INFO_CONSTANT.field_name_error}
        secondaryError={!isFieldNameUnique}
        secondaryErrorMessage={FIELD_INFO_CONSTANT.field_name_repeated_error}
        onUpdate={(event) => {
          updateFarmFieldName({
            farmId: currentFarmId,
            fieldId: selectedFieldId.id,
            name: event.target.value,
          });
          makeFormDirty();
        }}
        customInputSx={InputSx}
        labelMarginTop={STYLE_CONSTANTS.zero_rem}
        labelMarginBottom={STYLE_CONSTANTS.zero_rem}
      />
      <InputField
        isDisabled={disableAllFields}
        label={FIELD_INFO_CONSTANT.fsa_tract_id}
        value={formValue.fsaTractId}
        placeholder={FIELD_INFO_CONSTANT.fsa_tract_id_placeholder}
        name={FIELD_INFO_CONSTANT.fsa_tract_id}
        width="8.75rem"
        isRequired
        primaryError={error.fsaTractId}
        primaryErrorMessage={FIELD_INFO_CONSTANT.fsa_tract_id_error}
        onUpdate={(event) => {
          handleTextFieldValueChange(event, null, 'fsaTractId');
          makeFormDirty();
        }}
        maxLength={100}
        labelMarginTop={STYLE_CONSTANTS.zero_rem}
        labelMarginBottom={STYLE_CONSTANTS.zero_rem}
        customInputSx={InputSx}
      />
      <InputField
        isDisabled={disableAllFields}
        label={FIELD_INFO_CONSTANT.fsa_field_id}
        value={formValue.fsaFieldId}
        placeholder={FIELD_INFO_CONSTANT.fsa_field_id_placeholder}
        name={FIELD_INFO_CONSTANT.fsa_field_id}
        isRequired
        primaryError={error.fsaFieldId}
        primaryErrorMessage={FIELD_INFO_CONSTANT.fsa_field_id_is_required}
        onUpdate={(event) => {
          handleTextFieldValueChange(event, null, 'fsaFieldId');
          makeFormDirty();
        }}
        width="8.75rem"
        labelMarginTop={STYLE_CONSTANTS.zero_rem}
        labelMarginBottom={STYLE_CONSTANTS.zero_rem}
        customInputSx={InputSx}
      />
      <div>
        <TextWrapper
          fontSize="14px"
          color={checkTernaryCondition(error.totalFieldArea, RED, BLACK)}>
          {FIELD_INFO_CONSTANT.total_field_area}
        </TextWrapper>
        <InputField
          // Acreage will always be disabled since we are validating during field addition on map.
          isDisabled={true}
          label={FIELD_INFO_CONSTANT.acres}
          value={formValue.totalFieldArea}
          placeholder={FIELD_INFO_CONSTANT.total_field_area_placeholder}
          name={FIELD_INFO_CONSTANT.total_field_area}
          width="8.75rem"
          isRequired
          labelOnRightSide={true}
          rightSideLabelFormLabelMargin="0rem"
          display="flex"
          onUpdate={(event) => {
            handleTextFieldValueChange(event, null, 'totalFieldArea', true);
            makeFormDirty();
          }}
          customInputSx={InputSx}
          labelMarginTop={STYLE_CONSTANTS.zero_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_rem}
        />
      </div>
      <RadioButtonsGroup
        isDisabled={disableAllFields || formValue.csafUpdatedBySystem}
        selectedValue={formValue.csafImplemented}
        list={YesNoIdontknowTypes}
        label={FIELD_INFO_CONSTANT.csaf_field_label}
        csafToolTipSx={CSAFToolTipSx}
        handleChange={(e) => {
          const value = e.target.value;
          setFormValue({
            ...formValue,
            csafImplemented: value,
          });
          setError({
            ...error,
            csafImplementedRequiredError: isValueEmptyNullorUndefined(value),
          });
          makeFormDirty();
        }}
        primaryError={error.csafImplementedRequiredError}
        primaryErrorMessage={
          FIELD_INFO_CONSTANT.csaf_field_primary_error_message
        }
        hasTooltipOnLabel={currentFarmInfo?.csafImplemented !== ''}
        tooltipContent={checkTernaryCondition(
          currentFarmInfo?.csafImplemented === 'Yes',
          FIELD_INFO_CONSTANT.csaf_yes_tooltip,
          FIELD_INFO_CONSTANT.csaf_no_idontknow_tooltip,
        )}
        marginTop={STYLE_CONSTANTS.one_rem}
        marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
        labelFontSize={STYLE_CONSTANTS.input_font_size}
        radioBtnPadding={radioBtnPadding}
        radioBtnGap={STYLE_CONSTANTS.zero_point_three_seven_five_rem}
      />
      {isDisclaimerVisible && (
        <FieldWebformDisclaimer>
          {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(0, 26)}
          <BoldTextWrapper fontWeight={600}>
            {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(26, 29)}
          </BoldTextWrapper>
          {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(29, 32)}
          <BoldTextWrapper fontWeight={600}>
            {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(32, 46)}
          </BoldTextWrapper>
          {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(46, -4)}
          <BoldTextWrapper fontWeight={600}>
            {FIELD_INFO_CONSTANT.field_enrollment_disclaimer.slice(-4)}
          </BoldTextWrapper>
        </FieldWebformDisclaimer>
      )}
      <DropDown
        isDisabled={disableAllFields}
        height={'2rem'}
        width={'13.75rem'}
        minWidth={'13.75rem'}
        hasNoBottomMargin={true}
        label={FIELD_INFO_CONSTANT.commonLandUseType3Years}
        dropdownlist={landUseTypeList === null ? [] : landUseTypeList}
        value={
          formValue.landUseTypeId === -1
            ? `${FIELD_INFO_CONSTANT.placeholder}`
            : formValue.landUseTypeId
        }
        error={error.commonLandUseTypeFor3YearsError}
        errorMessage={FIELD_INFO_CONSTANT.commonLandUseType3YearsError}
        onUpdate={(event) => {
          const value = event.target.value;
          setFormValue((prevState) => {
            const updatedHistoricLandUseType =
              prevState.fieldHistoricLanduseList.map((item, i) => {
                if (i === 0) {
                  return {
                    ...item,
                    landuseTypeId: value,
                    endYear: (+new Date().getFullYear() - 1).toString(),
                    startYear: (+new Date().getFullYear() - 4).toString(),
                  };
                } else {
                  return {
                    ...item,
                    startYear: '',
                    endYear: '',
                    landuseTypeId: '',
                  };
                }
              });
            return {
              ...prevState,
              landUseTypeId: value,
              fieldHistoricLanduseList: updatedHistoricLandUseType,
            };
          });
          setError((prevError) => {
            return {
              ...prevError,
              commonLandUseTypeFor3YearsError: false,
            };
          });

          makeFormDirty();
        }}
        labelMarginTop={STYLE_CONSTANTS.one_rem}
        labelMarginBottom={'0.25rem'}
        fontSize={'14px'}
        showLabelAsValue={true}
        dropDownPlaceholder="Select option"
        customPlaceholderColor={COOL_GRAY}
        setStateValue={() => {}}
      />

      <RadioButtonsGroup
        customSx={{ gap: '0.25rem' }}
        selectedValue={formValue.isConsistentLanduse20Yrs}
        isDisabled={disableAllFields}
        marginTop={STYLE_CONSTANTS.one_rem}
        radioBtnPadding={radioBtnPadding}
        radioBtnGap={STYLE_CONSTANTS.zero_point_three_seven_five_rem}
        primaryError={error.isConsistentFor20YearsError}
        primaryErrorMessage={FIELD_INFO_CONSTANT.isConsistentFor3YearsError}
        label={FIELD_INFO_CONSTANT.isConsistentFor20Years}
        marginBottom={'0rem'}
        list={YesNoTypes}
        handleChange={(e) => {
          const value = e.target.value;
          setFormValue((prevState) => {
            const updatedHistoricLandUseType =
              prevState.fieldHistoricLanduseList.map((item, i) => {
                if (i === 0) {
                  return {
                    ...item,
                    landuseTypeId: formValue.landUseTypeId,
                    endYear: (+new Date().getFullYear() - 1).toString(),
                    startYear: (+new Date().getFullYear() - 4).toString(),
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              });
            return {
              ...prevState,
              isConsistentLanduse20Yrs: value,
              fieldHistoricLanduseList: updatedHistoricLandUseType,
            };
          });

          formValue.fieldHistoricLanduseList.length < 2 &&
            addAnotherHistoricLandUseType();

          setError((prevError) => {
            return {
              ...prevError,
              isConsistentFor20YearsError: false,
            };
          });
          makeFormDirty();
        }}
      />
      {formValue.isConsistentLanduse20Yrs === 'No' && (
        <DropdownDateWrapper>
          <FormLabels
            marginBottom={'0rem'}
            marginTop={'1rem'}
            style={
              error.dropDownDateComponentError.isError
                ? { color: RED }
                : { color: DARK_CHARCOAL }
            }>
            {FIELD_INFO_CONSTANT.historicLandUseType}
          </FormLabels>
          <DropdownDateComponent
            disableFieldForRevisitingForm={disableAllFields}
            errors={error.dropDownDateComponentError}
            defaultLandUseType={getValueFromLandUseTypeId(
              formValue.landUseTypeId,
            )}
            historicLandUseType={formValue.fieldHistoricLanduseList}
            addAnotherHistoricLandUseType={addAnotherHistoricLandUseType}
            handleChangeOnHistoricLandUseType={
              handleChangeOnHistoricLandUseType
            }
            removeHistoricLandUseType={removeHistoricLandUseType}
            landUseTypeList={landUseTypeList}></DropdownDateComponent>
        </DropdownDateWrapper>
      )}
      <FieldWrapper>
        <TextWrapper
          fontSize="14px"
          fontWeight="600"
          color={COOL_GRAY}
          lineHeight="140%">
          {FIELD_INFO_CONSTANT.project_activity_title}
        </TextWrapper>
        <TextWrapper
          fontSize="14px"
          color={checkTernaryCondition(
            error.projectActivityDtoList,
            'rgb(218, 41, 28)',
            '',
          )}>
          {FIELD_INFO_CONSTANT.project_activity_info}
        </TextWrapper>
        <Button
          ref={buttonRef}
          disabled={disableAllFields}
          variant={FIELD_INFO_CONSTANT.contained}
          disableElevation
          onClick={() => onAddButtonClick()}
          size={FIELD_INFO_CONSTANT.medium}
          sx={[
            addProjectActivityBtnSx,
            primaryButtonStyle(
              FIELD_INFO_CONSTANT.add_project_activity_btn_style,
            ),
          ]}>
          {FIELD_INFO_CONSTANT.add_project_activity_btn_label}
        </Button>
        {activityList
          .filter((activity) => activity.fieldCropProjectActivityId !== 0)
          .map((activity) => {
            const currentActivityType = activityDropDownList.find(
              (item) => item.value === activity?.projectActivityId,
            )?.label;
            return (
              <AccordionDisplayBox
                width={'100%'}
                labels={activityListLabels(activity, activityDropDownList)}
                mainLabel={currentActivityType}
                key={activity.fieldCropProjectActivityId}
                itemId={activity.fieldCropProjectActivityId}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
                deleteModalHeading={`${FIELD_INFO_CONSTANT.delete_modal_heading_first_half} ${currentActivityType}`}
                deleteModalContent={`${FIELD_INFO_CONSTANT.delete_modal_content_first_half} ${currentActivityType}${FIELD_INFO_CONSTANT.delete_modal_content_second_half}`}
                disableActions={disableAllFields}
              />
            );
          })}
        {error.projectActivityDtoList && (
          <TextWrapper fontSize="11px" color={RED}>
            {FIELD_INFO_CONSTANT.activity_error}
          </TextWrapper>
        )}
        <Divider sx={dividerSx} />
      </FieldWrapper>
      <ActivityModal
        open={open}
        setOpen={setOpen}
        dropdownlist={activityDropDownList}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        remainingFieldArea={formValue.totalFieldArea}
        yearDropdownList={yearDropdownList}
        activityList={activityList}
        setActivityList={(newList) => {
          setActivityList(newList);
          makeFormDirty();
        }}
        fieldCsafImplemented={formValue.csafImplemented}
        csafUpdatedBySystem={formValue.csafUpdatedBySystem}
      />
      <UserNavigationButtons
        saveAndContinueHandler={() => {
          handleClickSubmit(false, false, formValue, currentFarmId, true);
        }}
        saveAndCloseHandler={() => {
          handleClickSubmit(true, false, formValue, currentFarmId, true);
        }}
        CancelHandler={navigateHandler}
      />
    </MainContainer>
  );
};

ParticipantGeneralFieldInfoForm.propTypes = {
  participantId: PropTypes.string,
  name: PropTypes.string,
  projectId: PropTypes.string,
  selectedFieldId: PropTypes.string,
  selectedFieldBoundaries: PropTypes.array,
  isMockFarmCsafUpdatedBySystem: PropTypes.bool,
};
export default ParticipantGeneralFieldInfoForm;
