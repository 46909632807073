import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  PageTitle,
  Chip,
  Tags,
  Verified,
  Review,
  TitleDiv,
  ReviewText,
  TagsWrapper,
  LookerWrapper,
  toastProperties,
  TurnedInNotIconStyle,
  DoneIconStyle,
  STYLE_CONST,
  spinnerStyle,
} from './ProjectDetails.style';
import { PROJECT, LOOKER_SIGNED_URL, DELETE_PROJECT } from '../../urls';
import { GREEN, WHITE, COOL_GRAY, BLUE } from 'theme/GlobalColors';
import { PROJECT_PROFILE_ID_MAP, CLIENT_ID } from 'utils/config';
import BackButton from '../../components/Backbutton/BackButton.react';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { Container, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useStyles } from '../../theme/GlobalStyles';
import axios from 'axios';
import {
  PROJECT_DETAIL_CONTENTS,
  projectTabs,
  findDate,
  findEmbedUrl,
  tabProps,
} from './ProjectDetail.content';
import DeleteModal from 'components/DeleteModal/DeleteModal.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import LightTooltip from 'components/LightTooltip';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ProjectSummaryComponent from './ProjectSummary/ProjectSummaryComponent.react';
import CircularProgress from '@mui/material/CircularProgress';
import { pathName } from 'Routes/routes.const';

const adjustHeight = (o) => {
  if (o?.contentWindow) {
    o.style.height = `${o.contentWindow.document.body.scrollHeight}px`;
  }
};

export const LookerDashboardComponent = (className, title, src) => {
  return (
    <LookerWrapper data-testid={PROJECT_DETAIL_CONTENTS.looker_dashboard}>
      {src !== PROJECT_DETAIL_CONTENTS.empty_string && (
        <iframe
          className={className}
          title={title}
          src={src}
          width={STYLE_CONST.hundred_percent}
          // eslint-disable-next-line no-script-url
          onLoad={adjustHeight}
          height={STYLE_CONST.hundred_percent}
          frameBorder="0"></iframe>
      )}
    </LookerWrapper>
  );
};

const ProjectDetails = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [projectDetails, setProjectDetails] = useState();
  const [projectRegistryDetails, setProjectRegistryDetails] = useState();
  const { TabberBox } = useStyles();
  const navigate = useNavigate();
  const origin = window.location.origin;
  const { id } = useParams();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [iframeSrcMap, setIframeSrcMap] = useState('');

  const mapEmbedURL = findEmbedUrl(
    PROJECT_PROFILE_ID_MAP,
    CLIENT_ID,
    id,
    origin,
    PROJECT_DETAIL_CONTENTS.looker_map,
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();

  const chipTags = [];

  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchLookerDashboard(mapEmbedURL, setIframeSrcMap);
  }, [currentTab]);
  useEffect(() => {
    fetchProjectDetails();
  }, []);

  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };
  const onErrorDialogBoxClose = () => {
    setTransactionList([]);
    setErrorIsDialogOpen(false);
  };
  const hasNoRegistryId =
    projectRegistryDetails?.projectId === PROJECT_DETAIL_CONTENTS.negative_one;
  const fieldTitleArray = [
    {
      title: PROJECT_DETAIL_CONTENTS.project_id,
      value:
        projectRegistryDetails?.projectId !==
        PROJECT_DETAIL_CONTENTS.negative_one
          ? projectRegistryDetails?.projectId
          : PROJECT_DETAIL_CONTENTS.empty,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.registry,
      value: projectRegistryDetails?.registry,
    },
    { title: PROJECT_DETAIL_CONTENTS.status, value: projectDetails?.status },
    {
      title: PROJECT_DETAIL_CONTENTS.developer,
      value: projectDetails?.developer,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.methodology,
      value: projectDetails?.methodology,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.type,
      value: projectDetails?.projectSector,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.credit_start_end,
      value: `${findDate(projectRegistryDetails?.creditStart)}-
      ${findDate(projectRegistryDetails?.creditEnd)}`,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.emission,
      value: projectRegistryDetails?.estAnnEmissionsReduction,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.co_benefits,
      value: projectDetails?.cobenefits,
    },
    {
      title: PROJECT_DETAIL_CONTENTS.project_description,
      value: projectDetails?.projectDescription,
    },
    { title: PROJECT_DETAIL_CONTENTS.notes, value: projectDetails?.notes },
  ];

  const fetchLookerDashboard = (embedURL, IframeSetter) => {
    axios({
      method: PROJECT_DETAIL_CONTENTS.get,
      url: `${LOOKER_SIGNED_URL}${embedURL}`,
    })
      .then((res) => {
        IframeSetter(res?.data?.url);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('looker', err);
      });
  };

  const fetchProjectDetails = () => {
    if (id) {
      setLoading(true);
      axios
        .get(PROJECT + `/${id}` + PROJECT_DETAIL_CONTENTS.detail_endpoint)
        .then((response) => {
          setProjectDetails(response.data.projectDetail);
          setProjectRegistryDetails(response.data.projectRegistryDetail);
          if (response.data.projectDetail.tags.length > 0) {
            response.data.projectDetail.tags.forEach((tag) => {
              setTags((prev) => [...prev, tag.tagName]);
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  chipTags.push(
    ...[projectDetails?.projectSector].filter(
      (chip) => chip !== PROJECT_DETAIL_CONTENTS.empty_string,
    ),
  );

  const handleClick = () => {
    navigate(`${PROJECT_DETAIL_CONTENTS.edit_project_path}${id}`, {
      state: {
        previousPath: `${PROJECT_DETAIL_CONTENTS.project_details}${id}`,
      },
    });
  };
  const setDialogContent = (title, subtitle) => {
    setDialogTitle(title);
    setDialogSubtitle(subtitle);
    setErrorIsDialogOpen(true);
  };

  const deleteProject = () => {
    setIsDialogOpen(false);
    axios
      .delete(DELETE_PROJECT, {
        params: { internalProjectId: id },
      })
      .then((response) => {
        switch (response.data.status) {
          case PROJECT_DETAIL_CONTENTS.success.toUpperCase():
            toast(
              <CustomSnackbar
                type={PROJECT_DETAIL_CONTENTS.success.toUpperCase()}
                message={`${projectDetails?.projectName} ${PROJECT_DETAIL_CONTENTS.success_message}`}
              />,
              toastProperties,
            );
            navigate(+PROJECT_DETAIL_CONTENTS.negative_one);
            break;
          case PROJECT_DETAIL_CONTENTS.failed:
            setDialogContent(
              `${
                projectDetails?.projectName
              } ${PROJECT_DETAIL_CONTENTS.failed_message.slice(0, 19)}`,
              `${projectDetails?.projectName} ${PROJECT_DETAIL_CONTENTS.delete_message}`,
            );
            break;
          case PROJECT_DETAIL_CONTENTS.denied:
            setDialogTitle(
              `${projectDetails?.projectName} ${PROJECT_DETAIL_CONTENTS.cannot_delete}`,
            );
            if (response?.data?.transactionNames.length === 1) {
              setDialogSubtitle(
                `${projectDetails?.projectName} is linked in ${response?.data?.transactionNames[0]} ${PROJECT_DETAIL_CONTENTS.denied_message_single_transaction}`,
              );
            } else {
              setDialogSubtitle(
                `${projectDetails?.projectName} ${PROJECT_DETAIL_CONTENTS.denied_message_multiple_transaction}`,
              );
              setTransactionList(response?.data?.transactionNames);
            }
            setErrorIsDialogOpen(true);
            break;
          default:
        }
      })
      .catch(() => {
        setDialogContent(
          `${
            projectDetails?.projectName
          } ${PROJECT_DETAIL_CONTENTS.failed_message.slice(0, 19)}`,
          `${projectDetails?.projectName} ${PROJECT_DETAIL_CONTENTS.failed_message}`,
        );
      });
  };
  return (
    <Container maxWidth="xl">
      <BackButton
        previouspagename={
          state?.previouspagename
            ? state?.previouspagename
            : PROJECT_DETAIL_CONTENTS.catalog
        }
        previouspath={
          state?.previousPath
            ? state?.previousPath
            : pathName.assets.projects.view
        }
        previousstate={
          state?.previousstate
            ? state?.previousstate
            : { state: PROJECT_DETAIL_CONTENTS.project_detail }
        }
      />
      {(loading && <CircularProgress color="success" sx={spinnerStyle} />) || (
        <>
          <TitleDiv margin={STYLE_CONST.one_zero_xy_cords}>
            <TitleDiv flexDirection={STYLE_CONST.row_alignment}>
              <PageTitle>{projectDetails?.projectName}</PageTitle>
              {(projectRegistryDetails?.projectId !==
                PROJECT_DETAIL_CONTENTS.empty ||
                null) &&
                projectRegistryDetails?.projectId !==
                  PROJECT_DETAIL_CONTENTS.negative_one && (
                  <LightTooltip
                    arrow
                    title={
                      <div>
                        <div
                          style={{ fontSize: '18px', marginBottom: '0.5rem' }}>
                          {`Linked to ${projectRegistryDetails?.registry}`}
                        </div>
                        <Typography variant="body2" color="text.secondary">
                          Ivy S. So, Barbara K. Haya, Micah Elias. (2023,
                          January).
                          <Link
                            href={
                              PROJECT_DETAIL_CONTENTS.berkeley_Carbon_trading_Project_link
                            }
                            color={'#0076A8'}
                            underline="none">
                            Voluntary Registry Offsets Database, Berkeley Carbon
                            Trading Project
                          </Link>
                          , University of California, Berkeley.
                        </Typography>
                      </div>
                    }>
                    <Verified>
                      <VerifiedIcon color={PROJECT_DETAIL_CONTENTS.success} />
                    </Verified>
                  </LightTooltip>
                )}
              <TagsWrapper data-testid={PROJECT_DETAIL_CONTENTS.tags}>
                {chipTags.map((item, index) => (
                  <Chip id={index} key={item}>
                    {item}
                  </Chip>
                ))}
                {tags.map((item) => (
                  <Tags key={item}>{item}</Tags>
                ))}
              </TagsWrapper>
            </TitleDiv>
            <TitleDiv gap={'0rem'}>
              <Review
                size={'0.75rem'}
                color={COOL_GRAY}
                height={'0.2px'}
                padding={'0.25rem'}>
                {PROJECT_DETAIL_CONTENTS.internal_review}
              </Review>
              {(projectDetails?.internalReviewStatus
                .toLowerCase()
                .includes(PROJECT_DETAIL_CONTENTS.internal_review_complete) && (
                <Review
                  border={`${STYLE_CONST.border} ${GREEN}`}
                  background={WHITE}
                  color={GREEN}
                  size={'0.7rem'}
                  padding={'0.25rem'}>
                  <DoneIcon
                    sx={DoneIconStyle}
                    color={PROJECT_DETAIL_CONTENTS.success}
                  />
                  <ReviewText color={GREEN}>
                    {PROJECT_DETAIL_CONTENTS.complete}
                  </ReviewText>
                </Review>
              )) || (
                <Review
                  border={`${STYLE_CONST.border} ${BLUE}`}
                  background={WHITE}
                  size={'0.7rem'}
                  padding={'0.25rem'}>
                  <TurnedInNotIcon sx={TurnedInNotIconStyle} />
                  <ReviewText color={BLUE}>
                    {PROJECT_DETAIL_CONTENTS.under_review}
                  </ReviewText>
                </Review>
              )}
            </TitleDiv>
          </TitleDiv>
          <Box>
            <TabberBox>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={PROJECT_DETAIL_CONTENTS.page_tabs}>
                {projectTabs.map((tab, index) => (
                  <Tab
                    key={tab}
                    label={tab}
                    {...tabProps(index)}
                    sx={{ textTransform: STYLE_CONST.none }}
                  />
                ))}
              </Tabs>
              <DeleteModal
                deleteTarget={deleteProject}
                targetName={projectDetails?.projectName}
                transactionList={transactionList}
                dialogSubtitle={dialogSubtitle}
                isErrorDialogOpen={isErrorDialogOpen}
                dialogTitle={dialogTitle}
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                onErrorDialogBoxClose={onErrorDialogBoxClose}
                navigateRoute={handleClick}
              />
            </TabberBox>
            {currentTab === 0 && (
              <ProjectSummaryComponent
                loading={loading}
                hasNoRegistryId={hasNoRegistryId}
                fieldTitleArray={fieldTitleArray}
                iframeSrcMap={iframeSrcMap}
                id={id}></ProjectSummaryComponent>
            )}
          </Box>
        </>
      )}
    </Container>
  );
};

export default ProjectDetails;
