import React, { useEffect, useMemo, useState } from 'react';
import {
  DropBoxStyle,
  DragAndDropTitle,
  DragAndDropText,
  Subtext,
  DialogBoxButtonStyle,
  AcceptedFileItem,
  deleteIconSx,
} from '../../../AddContractModal/AddContractModal.style';
import { FileName, FileNameText } from './UploadSoilSamplingModal.style';
import { SmallUploadWrapper, UploadHelpText } from '.././ActivitiesTab.style';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { useDropzone } from 'react-dropzone';
import uuid from 'react-uuid';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import axios from 'axios';

import {
  getError,
  maxFilesError,
} from '../../../AddContractModal/AddContractModal.content';
import {
  EMPTY_STATE_TEXT_COLOR,
  NEUTRAL_BACKGROUND_COLOR,
  RED,
} from 'theme/GlobalColors';
import { UPLOAD_SOIL_SAMPLING_DATA } from 'urls';
import {
  acceptedFilesInfo,
  MODAL_CONTENT,
} from './UploadSoilSamplingModal.content';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
const UploadSoilSamplingModal = ({
  dialogState,
  dialogStateHandler,
  projectId,
  accordionExpandHandler,
  modalLoader,
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
      multiple: false,
    }),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const isMaxFilesLimitExceeded = useMemo(
    () => uploadedFiles.length + rejectedFiles.length > 1,
    [uploadedFiles, rejectedFiles],
  );
  const clearUploadFileDetailsOnClose = () => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploadError({ flag: false, message: '' });
  };
  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler([files[files.length - 1]]);
    }
  };
  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };
  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    setRejectedFiles([]);
  }, [acceptedFiles]);
  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);
  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
    }
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [isMaxFilesLimitExceeded]);
  useEffect(() => {
    if (dialogState) {
      clearUploadFileDetailsOnClose();
    }
  }, [dialogState]);
  const onClose = () => {
    dialogStateHandler((prevState) => {
      return !prevState;
    });
  };
  const onSubmit = () => {
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append('soilSamplingReportFile', uploadedFiles[0].file);
      if (!uploadError.flag) {
        // Axios call
        modalLoader(true);
        axios
          .post(`${UPLOAD_SOIL_SAMPLING_DATA}/${projectId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            modalLoader(false);
            onClose();
            accordionExpandHandler(true);
          })
          .catch(() => {});
      }
    }
  };
  useEffect(() => {
    setIsSubmitDisabled(uploadError.flag);
  }, [uploadError]);
  return (
    <DialogBox
      isOpen={dialogState}
      title={MODAL_CONTENT.title}
      isError={uploadError.flag}
      modalTitleSx={{ color: RED, flexGrow: 1 }}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      acceptCtnLabel={MODAL_CONTENT.uploadButtonLabel}
      declineCtnLabel={MODAL_CONTENT.cancelButtonLabel}
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      btnDisabled={isSubmitDisabled || uploadedFiles.length === 0}
      buttonGap="0.5rem"
      dialogActions>
      <div>
        <DropBoxStyle>
          <DragAndDropTitle
            isError={
              uploadError.flag || isMaxFilesLimitExceeded
            }></DragAndDropTitle>
        </DropBoxStyle>

        {uploadError.flag === true ||
        isMaxFilesLimitExceeded ||
        uploadedFiles.length === 0 ? (
          <SmallUploadWrapper height={'2.625rem'} padding={'0.625rem'}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} data-testid="fileDropzone" />
              <DragAndDropText>
                {MODAL_CONTENT.dragAndDropText}
                <Subtext>{MODAL_CONTENT.browseText}</Subtext>.
              </DragAndDropText>
            </div>
          </SmallUploadWrapper>
        ) : (
          <SmallUploadWrapper
            height={'2.625rem'}
            padding={'0.625rem'}
            background={NEUTRAL_BACKGROUND_COLOR}>
            <div>
              <DragAndDropText>
                {MODAL_CONTENT.dragAndDropText}
                <Subtext color={EMPTY_STATE_TEXT_COLOR}>
                  {MODAL_CONTENT.browseText}
                </Subtext>
                .
              </DragAndDropText>
            </div>
          </SmallUploadWrapper>
        )}

        {acceptedFilesInfo.infoTextList.map((infoText) => {
          return (
            <UploadHelpText
              key={infoText}
              invisible={uploadError.flag || isMaxFilesLimitExceeded}>
              {infoText}
            </UploadHelpText>
          );
        })}
        {uploadError.flag && (
          <UploadHelpText iserror={true}>{uploadError.message}</UploadHelpText>
        )}
        {uploadedFiles.map((fileItem, index) => (
          <AcceptedFileItem key={fileItem.id}>
            <LightTooltip title={fileItem.file.name}>
              <FileName isError={isMaxFilesLimitExceeded}>
                <FileNameText>{fileItem.file.name}</FileNameText>
              </FileName>
            </LightTooltip>

            <DeleteOutlinedIcon
              data-testid="deleteIcon"
              fontSize="large"
              sx={deleteIconSx}
              onClick={() => deleteFile(index, setUploadedFiles)}
            />
          </AcceptedFileItem>
        ))}
        {rejectedFiles.map(({ file, id }, index) => (
          <AcceptedFileItem key={id}>
            <LightTooltip title={file.name}>
              <FileName isError={true}>
                <FileNameText>{file.name}</FileNameText>
              </FileName>
            </LightTooltip>

            <DeleteOutlinedIcon
              data-testid="deleteIcon"
              fontSize="medium"
              sx={deleteIconSx}
              onClick={() => deleteFile(index, setRejectedFiles)}
            />
          </AcceptedFileItem>
        ))}
      </div>
    </DialogBox>
  );
};
PropTypes.UploadSoilSamplingModal = {
  dialogState: PropTypes.bool,
  dialogStateHandler: PropTypes.func,
  projectId: PropTypes.number,
  accordionExpandHandler: PropTypes.func,
  modalLoader: PropTypes.func,
};
export default UploadSoilSamplingModal;
