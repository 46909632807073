import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const PAGE_CONTENT = {
  page_title_add: 'Origination Project',
  page_title_edit: 'Edit Origination Project',
  project_label: 'What is the name of your project?',
  project_placeholder: 'Type the project name',
  project_element_name: 'projectName',
  region_label: 'Which region is the project located in?',
  instruction_text: 'Select all that apply',
  project_type_label: 'What type of origination project is this?',
  afolu_activity_label: 'Which AFOLU activity(s) will be implemented?',
  quantification_method_label:
    'This methodology allows for the application of multiple quantification methods. How will emission reductions and removals be quantified for this project?',
  quantification_method_label_tooltip:
    'Where applicable, ClearCarbon will leverage industry standards and default emissions factors to quantify emission reductions and removals.',
  quantification_model_label: 'What model will be leveraged?',
  quantification_model_label_tooltip:
    'Refer to further details on model requirements in VMD0053',
  secondary_error_message:
    'This project name already exists. Please try entering a new project name.',
  project_primary_error_message: 'Project name is required.',
  commercialization_approach_label:
    'Which commercialization approach do you plan to pursue?',
  commercialization_approach_error:
    'A commercialization approach selection is required.',
  commercialization_approach: 'commercializationApproach',
  offSetting: 'Offsetting',
  standards_body_or_registry_label:
    'What standards body or registry will your project be registered with?',
  standards_body_or_registry_error:
    'A standards body or registry selection is required.',
  standars_body_or_registry_field: 'standardsBodyOrRegistry',
  validation_and_verification_yes_or_no_label:
    'Have you selected an independent third-party auditor that will provide validation and verification for your project?',
  validation_and_verification_yes_or_no_error:
    'A validation and verification selection is required.',
  validation_and_verification_yes_or_no_field:
    'validationAndVerificationYesOrNo',
  validation_and_verification_auditor_label:
    'Please select which independent third-party auditor that will provide validation and verification:',
  validation_and_verification_auditor_label_tooltip:
    'According to VCS standard, validation for the first round of verification can be conducted by the same VVB. Following round 1 of verification, you will need to select a different VVB and rotate at each verification thereafter',
  vvAuditor_edit_label: 'Validation and verification provided by:',
  validation_and_verification_auditor_error:
    'A validation and verification provider selection is required.',
  validation_and_verification_auditor_field: 'validationAndVerificationAuditor',
  commercialization_approach_label_tooltip:
    'Your selection will influence the workflow that follows. While insetting and offsetting will require equally rigorous abatement calculations, offsetting requires document submission and VVB review, which may extend timelines. Additionally, this selection on this page is not final, and you will maintain optionality to change your approach throughout the following workflow.',
  quantification_approach_label:
    'Which quantification approaches will your project activities align to?',
  quantification_instruction:
    'Additional quantification approaches and tools that fall within the selected approach will also be utilized, as required.',
  carbon_monitoring_label:
    'Will a soil organic carbon model be used for measuring and monitoring?',
  project_activities_label: 'What project activities will be implemented?',
  climate_program_enrollment_yes_or_no_label:
    'Will this project be enrolled in any climate programs?',
  climate_program_enrollment_yes_or_no_error:
    'A enrolled climate program selection is required.',
  climate_program_enrollment_yes_or_no_field: 'climateProgramEnrollmentYesOrNO',
  climate_programs_label: 'Which climate programs will be enrolled?',
  climate_programs_field: 'climatePrograms',
  climate_programs_placeholder: 'Type and select climate program(s)',
  climate_programs_error:
    'If yes was selected for climate programs enrolled then a climate program name is required.',
  climate_programs_delete_view_label: 'Climate Programs',
  project_activities_placeholder: 'Select each activity',
  description: 'Description',
  notes: 'Notes',
  optional: '(Optional)',
  type_here: 'Type here',
  projectActivities: 'projectActivities',
  submit_button_label: 'Add project',
  cancel_button_label: 'Cancel',
  error_class_name: '.Mui-error',
  parent_path: '/projects',
  project_region: 'projectRegion',
  region_error: 'A region selection is required.',
  column: 'column',
  activity_error: 'A project activity to be implemented is required.',
  row: 'row',
  tags: 'Add Tags',
  toast_success: 'has been added to your portfolio.',
  toast_error: 'has not been added to your portfolio. Try Again',
  success: 'Success',
  error: 'Error',
  save_button_label: 'Save & Close',
  update_message: 'has been updated in your portfolio',
  fail_message: 'has not been updated. Try Again',
  comma: ',',
  page_title_delete: 'Origination Project',
  deletedLabel: 'Deleted •',
  project_delete_label: 'Project name',
  region_delete_label: 'Region(s) where project is located',
  project_type_delete_label: 'Type of origination project',
  afolu_activities: 'afoluActivities',
  afolu_activity_delete_label: 'AFOLU activity implemented',
  afolu_activity_error: 'An AFOLU activity selection is required.',
  project_activities_delete_label: 'Project activities',
  commercialization_approach_delete_label: 'Commercialization approach',
  quantification_approach_delete_label: 'Quantification approach',
  quantification_method: 'quantificationMethod',
  quantification_model: 'quantificationModel',
  quantification_method_delete_label:
    'Quantification of emissions reductions and removals',
  quantification_method_error:
    'A emission reductions and removals selection is required.',
  quantification_method_free_text_placeholder: 'Please specify method used',
  quantification_method_free_text_field: 'quantificationMethodFreeText',
  quantification_model_delete_label:
    'Quantification of emissions reductions and removals model used',
  quantification_model_error: 'A model selection is required',
  tags_delete_label: 'Tags',
  mrv_delete_label:
    'Measure, Reporting, and Verification (MRV) technology be used',
  desc_notes_error: 'You’ve exceeded the maximum character limit.',
  desc_notes_count: ' out of 3,000 character limit ',
  empty_string: '',
  mrv_label:
    'Will a Measure, Reporting, and Verification (MRV) technology be used?',
  mrv_technology_label: 'Please select the MRV technology that will be used? ',
  habiterre: 'HabiTerre',
  edit_mrv_technology_label:
    'Measure, Reporting, and Verification (MRV) technology used.',
  yes: 'Yes',
  mvr_error: 'The use of a MRV technology selection is required.',
  toolTipBottomMargin: '-3.5px',
  countryDropdownWidth: '13.75rem',
  countryDropdownHeight: '2rem',
  hundredPercent: '100%',
  pointFiveRem: '0.5rem',
  countryIdField: 'countryId',
  country_label: 'What country is the project located in?',
  country_placeholder: 'Select country',
  country_error: 'A country selection is required',
  no: 'No',
  projects: 'Projects',
};

export const regionList = [
  {
    itemLabel: 'Northeast',
    checked: false,
  },
  {
    itemLabel: 'Midwest',
    checked: false,
  },
  {
    itemLabel: 'West',
    checked: false,
  },
  {
    itemLabel: 'South',
    checked: false,
  },
];
export const projectTypeList = [
  {
    itemLabel: 'Agriculture forestry and other land use (AFOLU)',
    selected: true,
    isDisabled: false,
  },
  {
    itemLabel: 'Non-agriculture forestry and other land use (Non-AFOLU)',
    selected: false,
    isDisabled: true,
  },
];

export const afoluActivityList = [
  {
    itemLabel: 'Improved forest management (IFM)',
    checked: false,
    isDisabled: true,
  },
  {
    itemLabel: 'Agriculture land management (ALM)',
    checked: true,
    isDisabled: true,
  },
  {
    itemLabel: 'Reducing emissions from deforestation and degradation (REDD+)',
    checked: false,
    isDisabled: true,
  },
  {
    itemLabel: 'Afforestation, reforestation, revegetation (ARR)',
    checked: false,
    isDisabled: true,
  },
];

export const quantificationMethodList = [
  {
    itemLabel: 'Direct field measurements',
    selected: true,
    isDisabled: true,
    tooltipTitle: '',
    tooltipContent:
      'An acceptable model is used to estimate GHG flux for soil organic carbon (SOC) and soil methanogenesis based on soil characteristics, implemented ALM practices, measured initial SOC stocks and climatic conditions. This model will also be leveraged to estimate emission reductions and removals from the use of nitrogen fertilizers and nitrogen-fixing species, if the selected model can support the quantification of those sources / sinks.',
  },
  {
    itemLabel: 'Direct field measurements and models',
    selected: false,
    isDisabled: false,
    tooltipTitle: '',
    tooltipContent:
      'Direct measurement is used to quantify changes in SOC stocks. This approach is relevant where models are unavailable or have not yet been validated or parameterized, or where there is a preference to use a direct measurement approach for SOC stock change. The baseline scenario is measured and remeasured directly at a baseline control site linked to one or more sample units.',
  },
  {
    itemLabel: 'Configure quantification method at field-level',
    selected: false,
    isDisabled: true,
    tooltipTitle: '',
    tooltipContent:
      'Where more than one quantification approach is allowable for a given gas and source, more than one approach may be used provided that the same approach is used for a field in both the project and baseline scenarios. Therefore, project developers may chose to configure the method of quantification at the field-level later in the process.',
  },
  {
    itemLabel: "I don't know",
    selected: false,
    isDisabled: true,
  },
];

export const quantificationModelList = [
  {
    itemLabel: 'HabiTerre',
    selected: true,
    isDisabled: false,
  },
];

export const commercializationApproachList = [
  {
    itemLabel: 'Insetting',
    checked: true,
    isDisabled: false,
    tooltipTitle: '',
    tooltipContent:
      'Insetting counts GHG abatement activities related to a company’s value chain against a company’s abatement targets. If the carbon is inset, it travels with the product down the value chain and is counted toward the carbon owner’s climate goals.',
  },
  {
    itemLabel: 'Offsetting',
    checked: false,
    isDisabled: true,
    tooltipTitle: '',
    tooltipContent:
      'Offsetting generates carbon credits for emissions reductions, given to one party that can be sold or traded to another party, leading to the credit’s retirement and the credit buyer using the credit to compensate for its emissions, Offsetting harnesses the impact of GHG abatement activities outside of a company’s direct or indirect operations.',
  },
];

export const standardsBodyOrRegistryList = [
  {
    itemLabel: 'Verified Carbon Standard(VCS)',
    selected: false,
    isDisabled: false,
  },
];

export const validationAndVerificationYesOrNoList = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
];

export const validationAndVerificationAuditorsList = [
  {
    itemLabel: 'Aster Global',
    selected: false,
    isDisabled: false,
  },
];

export const yesOrNoList = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
];

export const quantificationApproachList = [
  {
    itemLabel:
      'VCS Methodology for Improved Agricultural Land Management (VM0042)',
    checked: true,
    isDisabled: false,
    tooltipTitle: 'VM0042 - Additional quantification approaches applied ',
    tooltipContent: [
      'VCS module VMD0027: Estimation of domesticated animal populations, v1.0 ',
      'VCS module VMD0028: Estimation of emissions from domesticated animals, v1.0',
      '“Quantification Protocol” approved by the Alberta Offset System: Quantification protocol for reducing greenhouse gas emissions from fed cattle (version 3.0)',
      'ACR Methodology for Grazing Land and Livestock Management, v1.0',
    ],
  },
  {
    itemLabel:
      'VCS Methodology for the adoption of Sustainable Agricultural Land Management (VM0017)',
    checked: false,
    isDisabled: true,
  },
  {
    itemLabel:
      'VCS Methodology for Quantifying N2O Emissions Reductions in Agricultural Crops through Nitrogen Fertilizer Rate Reduction (VM0022)',
    checked: false,
    isDisabled: true,
  },
];
export const monitoringOptions = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'Not sure',
    selected: false,
    isDisabled: false,
  },
];
export const mvrTechnologyList = [
  {
    itemLabel: 'HabiTerre',
    selected: false,
    isDisabled: false,
  },
];
export const formModal = {
  projectName: '',
  projectRegion: [],
  projectActivities: [],
  projectType: 'Agriculture forestry and other land use (AFOLU)',
  afoluActivities: [1],
  quantificationMethod: '',
  quantificationMethodFreeText: '',
  quantificationModel: 'HabiTerre',
  commercializationApproach: [],
  standardsBodyOrRegistry: 2, // default value as 2 for now, this will be a "none" option
  countryId: 199, // set to united states by default
  validationAndVerificationYesOrNo: '',
  climateProgramEnrollmentYesOrNO: '',
  climatePrograms: [],
  validationAndVerificationAuditor: '',
  quantificationApproach: [
    'VCS Methodology for Improved Agricultural Land Management (VM0042)',
  ],
  modelMeasuringOption: 'No',
  description: '',
  notes: '',
  tags: [],
};
export const projectActivityList = [
  'Improved nutrient / fertilizer management',
  'Improved water management / irrigation',
  'Reduced tillage / improved residue management',
  'Improved crop planting and harvesting',
  'Improved grazing practices',
];

export const usRegionsTooltipTitle = (
  <div
    style={{
      width: '100%',
      padding: 0,
      color: DARK_CHARCOAL,
      fontWeight: 600,
      fontSize: '14px',
    }}>
    Regions of the United States
  </div>
);

const boldFont = { fontWeight: '600' };
const listItemStyle = { marginBottom: '4px' };

export const usRegionsTooltipContent = (
  <ul
    style={{
      margin: 0,
      padding: '0rem 1rem 0rem 1.5rem',
      color: DARK_CHARCOAL,
      fontSize: '14px',
    }}>
    <li style={listItemStyle}>
      <span style={boldFont}>Northeast</span> - Connecticut, Maine,
      Massachusetts, New Hampshire, New Jersey, New York, Pennsylvania, Rhode
      Island, Vermont
    </li>
    <li style={listItemStyle}>
      <span style={boldFont}>Midwest</span> - Illinois, Indiana, Iowa, Kansas,
      Michigan, Minnesota, Missouri, Nebraska, North Dakota, Ohio, South Dakota,
      Wisconsin
    </li>
    <li style={listItemStyle}>
      <span style={boldFont}>South</span> - Alabama, Arkansas, Delaware,
      District of Columbia, Florida, Georgia, Kentucky, Louisiana, Maryland,
      Mississippi, North Carolina, Oklahoma, South Carolina, Tennessee, Texas,
      Virginia, West Virginia
    </li>
    <li style={listItemStyle}>
      <span style={boldFont}>West</span> - Alaska, Arizona, California,
      Colorado, Hawaii, Idaho, Montana, New Mexico, Nevada, Oregon, Utah,
      Washington, Wyoming
    </li>
  </ul>
);
