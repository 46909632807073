import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  UploadWrapper,
  UploadText,
  UploadSubText,
  UploadHelpText,
  FileWrapper,
  TitleSection,
  TitleSectionHeading,
  CloseIcon,
  FileIconWrapper,
} from './FileUploader.style';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ReactComponent as FileIcon } from '../../assets/icons/fileIcon.svg';
import axios from 'axios';
import { FILE_UPLOAD } from '../../urls';
import { closeIconSx } from 'theme/GlobalStyles';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function FileUploader({ onCancel, isOpen, title, areaCode, refreshGrid }) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({ maxFiles: 1, accept: { 'text/csv': ['.csv'] } });
  const [fileInfo, setFileInfo] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const uploadFile = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('areaCode', areaCode);
    axios
      .post(FILE_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function () {
        setLoading(false);
        onCancel();
        refreshGrid();
      })
      .catch((error) => {
        setLoading(false);
        setUploadError({ flag: true, message: error.response.data.message });
        setButtonDisabled(false);
      });
  };

  useEffect(() => {
    acceptedFiles.map((file) => {
      if (file) {
        setUploadedFile(file);
        setUploadError({ flag: false, message: '' });
        setButtonDisabled(true);
      }
    });
    fileRejections.map((file) => {
      if (file) {
        setUploadedFile(file.file);
        setUploadError({
          flag: true,
          message: `This file isn't supported. Please upload in a .csv file
        format.`,
        });
        setButtonDisabled(false);
      }
    });
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setFileInfo(
      uploadedFile ? (
        <FileWrapper iserror={uploadError.flag}>
          {' '}
          <FileIconWrapper>
            <FileIcon />
          </FileIconWrapper>{' '}
          {uploadedFile.name + ' ' + formatBytes(uploadedFile.size)}
        </FileWrapper>
      ) : (
        ''
      ),
    );
  }, [uploadError]);

  useEffect(() => {
    setFileInfo('');
    setUploadedFile(null);
    setUploadError({ flag: false, message: '' });
    setButtonDisabled(false);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            pb: 0,
          }}>
          <TitleSection>
            <TitleSectionHeading>{title}</TitleSectionHeading>
            <CloseIcon onClick={onCancel} sx={closeIconSx}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.2792 7.97793L16.7592 1.49793C16.9048 1.32789 16.9809 1.10915 16.9722 0.885442C16.9636 0.66173 16.8709 0.449518 16.7126 0.291211C16.5542 0.132905 16.342 0.0401648 16.1183 0.0315238C15.8946 0.0228828 15.6759 0.0989775 15.5058 0.244601L9.02583 6.7246L2.54583 0.235712C2.37578 0.0900884 2.15705 0.0139946 1.93334 0.0226356C1.70962 0.0312766 1.49741 0.124016 1.33911 0.282322C1.1808 0.440629 1.08806 0.652841 1.07942 0.876553C1.07078 1.10027 1.14687 1.319 1.2925 1.48905L7.7725 7.97793L1.28361 14.4579C1.19056 14.5376 1.11498 14.6357 1.06163 14.746C1.00827 14.8562 0.978291 14.9764 0.973563 15.0988C0.968834 15.2212 0.989462 15.3433 1.03415 15.4573C1.07884 15.5714 1.14662 15.675 1.23325 15.7616C1.31988 15.8483 1.42347 15.916 1.53754 15.9607C1.65161 16.0054 1.77368 16.026 1.8961 16.0213C2.01852 16.0166 2.13863 15.9866 2.24891 15.9332C2.35919 15.8799 2.45725 15.8043 2.53694 15.7113L9.02583 9.23127L15.5058 15.7113C15.6759 15.8569 15.8946 15.933 16.1183 15.9243C16.342 15.9157 16.5542 15.823 16.7126 15.6647C16.8709 15.5064 16.9636 15.2941 16.9722 15.0704C16.9809 14.8467 16.9048 14.628 16.7592 14.4579L10.2792 7.97793Z"
                  fill="#63666A"
                />
              </svg>
            </CloseIcon>
          </TitleSection>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <div>
            <UploadWrapper>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} data-testid="fileDropzone" />
                <UploadText>
                  {fileInfo !== '' ? (
                    fileInfo
                  ) : (
                    <div>
                      Drag and drop data file here or
                      <UploadSubText>browse</UploadSubText>.
                    </div>
                  )}
                </UploadText>
              </div>
            </UploadWrapper>
            <UploadHelpText iserror={uploadError.flag}>
              Files Supported: .csv
            </UploadHelpText>
            {uploadError.flag && (
              <FileWrapper iserror={uploadError.flag} type="subtext">
                {uploadError.message}
              </FileWrapper>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            sx={{ fontSize: 12, textTransform: 'none' }}
            size="small"
            color="success">
            Cancel
          </Button>
          {(!loading && (
            <Button
              onClick={uploadFile}
              sx={{ fontSize: 12, textTransform: 'none', width: '5.5rem' }}
              size="small"
              autoFocus
              variant="contained"
              color="success"
              disabled={!buttonDisabled}>
              Upload File
            </Button>
          )) || (
            <Button
              sx={{ fontSize: 12, textTransform: 'none', width: '5.5rem' }}
              size="small"
              autoFocus
              variant="contained"
              color="success"
              disabled={!buttonDisabled}>
              <CircularProgress
                style={{
                  color: 'white',
                  paddingTop: '0.375rem',
                  paddingBottom: '0.375rem',
                }}
                size="12%"
              />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

FileUploader.propTypes = {
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  areaCode: PropTypes.string,
  refreshGrid: PropTypes.func,
};

export default FileUploader;
