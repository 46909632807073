import { DARK_GREEN, WHITE } from 'theme/GlobalColors';

export const DialogStyle = {
  zIndex: 900,
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '60%',
      maxWidth: '31.25rem',
      padding: '1.5rem',
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
  },
};

export const IconButtonSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
  zIndex: 950,
};

export const DilogTitleSx = {
  padding: '0 0 1rem 0',
  fontFamily: 'Open sans, sans serif',
  fontSize: '1.25rem',
  fontWeight: 400,
};

export const DialogContentSx = {
  padding: '0 0 1rem 0',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
};
export const DialogActionsSx = {
  padding: '0.5rem 0 0 0',
  display: 'flex',
  gap: '0.5rem',
};

export const cancelBtnSx = {
  color: DARK_GREEN,
  backgroundColor: WHITE,
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  height: '2.25rem',
  '&:hover': {
    color: DARK_GREEN,
    backgroundColor: WHITE,
  },
};

export const sendRequestBtnSx = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  borderRadius: '4px',
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  height: '2.25rem',
  '&:hover': {
    color: WHITE,
    backgroundColor: DARK_GREEN,
  },
};

export const linkBtnStyle = () => ({
  textDecoration: 'none',
  color: WHITE,
});

export const RadioGroupStyle = {
  gap: '16px',
  '& .MuiFormControlLabel-root': {
    flex: 1,
    marginLeft: '-10px',
  },
};
