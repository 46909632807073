export const PAGE_CONTENT = {
  heading: 'Edit participant',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'Phone',
  phone_placeholder: 'Phone',
  county_placeholder: 'County',
  mailing_address_label: 'Mailing address',
  city_label: 'City',
  zip_code_label: 'Zip code',
  state_label: 'State',
  state_placeholder: 'State',
  city_placeholder: 'City',
  county_label: 'County',
  communication_method_label: 'What’s your preferred communication method?',
  mailing_address_placeholder: 'Type your mailing address',
  mailing_address_required_error: 'A mailing address is required.',
  city_required_error: 'City is required.',
  zip_code_required_error: 'Zip code required.',
  zip_code_placeholder: 'Zip code',
  communication_method_helptext: 'Select all that apply',
  cancel: 'Cancel',
  save: 'Save',
  phone_required_error: 'A valid phone number is required.',
  invalid_phone_number_error:
    'A phone number must follow a valid format. e.g., (555) 555-5555 (dashes and brackets not required).',
  communication_method_error:
    'The preferred method of communication selection is required.',
  state_required_error: 'State req.',
  countyRequiredError: 'County required.',
  success: 'Success',
  error: 'Error',
};

export const communicationMethodList = [
  {
    itemLabel: 'Email',
    checked: false,
  },
  {
    itemLabel: 'Text',
    checked: false,
  },
  {
    itemLabel: 'Phone',
    checked: false,
  },
];

export const formModal = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mailingAddress: '',
  city: '',
  state: '',
  county: '',
  zipCode: '',
  communicationMethod: communicationMethodList,
};

export const errorInitialState = {
  phoneRequiredError: false,
  inValidPhoneError: false,
  mailingAddressRequiredError: false,
  cityRequiredError: false,
  stateRequiredError: false,
  countyRequiredError: false,
  zipCodeRequiredError: false,
  communicationMethodRequiredError: false,
};
