export const PAGE_CONTENT = {
  time: 'Time',
  batchId: 'Batch ID',
  object_field: 'batchId',
  phase: 'Phase',
  phase_field: 'phase',
  status_field: 'status',
  user_name: 'user',
  status: 'Status',
  acres: 'Acres',
  acres_field: 'acres',
  created_date_field: 'createdDate',
  user: 'User',
  pagination_mode: 'server',
  action: 'Action',
  cool_gray: '#63666A',
  column: 'column',
  default_pagination_options: [25, 50, 75],
  default_page_size: 25,
  label_rows_per_page: 'Items per page:',
};
export const SortColValues = {
  createdDate: 'createdDate',
  batchId: 'batchId',
  contract: 'contract',
  acres: 'acres',
};
