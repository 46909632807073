import { pathName } from 'Routes/routes.const';
import BackButton from 'components/Backbutton/BackButton.react';
import { checkTernaryCondition } from 'utils/helper';

const AddProjectBackButton = ({ editMode, statePreviousPath, isdirty }) => {
  return (
    <BackButton
      previouspath={checkTernaryCondition(
        statePreviousPath?.previousPath,
        statePreviousPath?.previousPath,
        pathName.portfolio,
      )}
      previousstate={{ state: 'edit-project' }}
      previouspagename={checkTernaryCondition(
        editMode,
        'catalog',
        checkTernaryCondition(
          statePreviousPath?.previousPath,
          statePreviousPath?.previousPath.substring(1),
          'portfolio',
        ),
      )}
      marginLeft="2rem"
      isEdited={isdirty}
    />
  );
};

export default AddProjectBackButton;
