export const PAGE_CONTENT = {
  title: 'Add pipeline acreage',
  editTitle: 'Edit pipeline acreage',
  cancel: 'Cancel',
  submit: 'Submit',
  toast_success_message: 'Pipeline acreage added successfully',
  toast_error_message: 'Failed to add pipeline acreage',
  edit_toast_success_message: 'Pipeline acreage updated successfully',
  edit_toast_error_message: 'Failed to update pipeline acreage',
  number_field_placeholder: '0.00',
  activities_table_heading:
    'Provide the corresponding extent of implementation in acres for each project activity.',
  total_enrolled_acres_label:
    'Provide the total enrolled acres that project activities will be implemented on for project cycle',
  total_enrolled_acres_placeholder: 'acres',
  enrolled_acres_required_error: 'Total acres for project cycle is required. ',
  enrolled_acres_min_limit_error: 'Acre amount must be greater than 0.',
};

export const inputStyleProps = {
  textAlignment: 'end',
  maxLength: 100,
  minWidth: '3.75rem',
  width: '3.75rem',
  noLabel: true,
  marginBottom: '0',
  rightSideLabelFormLabelMargin: '0',
  customStyles: { width: '3.75rem', marginBottom: '0' },
  customInputSx: { marginBottom: '0', textAlign: 'end' },
};

export const totalAcresInputProps = {
  minWidth: '3.75rem',
  width: '3.75rem',
  labelWidth: '28rem',
  placeholder: PAGE_CONTENT.total_enrolled_acres_placeholder,
  hasPredefinedUnit: true,
  predefinedUnit: 'total acres',
  customStyles: { width: '28rem' },
};

export const initialPipelineAcerageModalValues = {
  pipelineUnits: [],
};
