import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gridColumnGap: '2.5rem',
  width: 'fit-content',
  marginRight: '0rem',
}));

export const OptionsWrapper = styled(Container)(({ deletenotdisabled }) => ({
  height: '1.5rem',
  width: '0rem',
  padding: '0.25rem',
  cursor: checkTernaryCondition(deletenotdisabled, 'pointer', 'default'),
  fontSize: '1rem',
}));
