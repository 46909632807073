export const DIALOG_CONTENT = {
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  delete: 'Delete',
  cancel: 'Cancel',
  variant: 'contained',
  edit: 'Edit',
  delete_message_line2:
    'Any associated projects or stakeholders will not be deleted.',
};
