import React from 'react';
import {
  HeaderContainer,
  HeaderTitle,
  addBtnPadding,
  addBtnSx,
} from './ListViewHeader.style';
import AddButton from 'components/AddButton/AddButton.react';

const ListViewHeader = ({
  title = '',
  noButton = false,
  buttonName,
  addNewClickHandler,
  buttonBackgroundColor,
  buttonTextColor,
  noDropdown = true,
  options,
  toggleModal,
  setSelectedOption,
  isModalPresent,
  disabledOption,
  containerPadding,
  disableButton = false,
  hasMultipleModals = false,
  titleColor,
  marginBottom,
}) => {
  return (
    <HeaderContainer padding={containerPadding}>
      <HeaderTitle
        data-testid="header-title"
        titleColor={titleColor}
        marginBottom={marginBottom}>
        {title}
      </HeaderTitle>
      {!noButton && (
        <AddButton
          buttonName={buttonName}
          onClick={addNewClickHandler}
          backgroundColor={buttonBackgroundColor}
          textColor={buttonTextColor}
          noDropdown={noDropdown}
          options={options}
          toggleModal={toggleModal}
          setSelectedOption={setSelectedOption}
          isModalPresent={isModalPresent}
          disabledOption={disabledOption}
          disableButton={disableButton}
          customBtnSx={addBtnSx}
          addBtnPadding={addBtnPadding}
          hasMultipleModals={hasMultipleModals}
        />
      )}
    </HeaderContainer>
  );
};

export default ListViewHeader;
