import styled from 'styled-components';

const PAYMENT_COLOR = '#0076A8';
const BORDER_COLOR = '#D0D0CE';
const ERROR_MESSAGE_COLOR = '#DA291C';
const MAIN_FONT_COLOR = '#333333';

export const FormFields = styled.div`
  margin-bottom: 1rem;
  height: fit-content;

  input {
    padding-left: 10px;
  }
`;
export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) => props.iserror && ERROR_MESSAGE_COLOR};
  padding-left: ${({ paddingLeft }) => paddingLeft};
`;

export const AddPaymentWrapper = styled.div`
  cursor: pointer;
  /* margin-top:1rem; */
`;

export const AddpaymentLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${PAYMENT_COLOR};
  margin-left: 0.375rem;
`;
export const PaymentWapper = styled.div`
  border-left: 0.125rem solid ${BORDER_COLOR};
  padding-left: 0.5rem;
`;

export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  padding-left: 0.3rem;
  color: ${ERROR_MESSAGE_COLOR};
`;
export const RemoveProjectLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;
export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${MAIN_FONT_COLOR};
`;
