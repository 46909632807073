import React, { useContext } from 'react';
import {
  MainWrapper,
  datagridSx,
  DataGridWrapper,
} from '../../../ProjectCatalog/ProjectCatalog.style';
import { DataGrid } from '@mui/x-data-grid';
import { checkAccess, checkTernaryCondition } from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import {
  PARTICIPANT_PARENT_PATH,
  contributorTableColumns,
  getParticipantAccountStatus,
} from './Participants.content';
import CustomOptionsButton from 'components/SimpleOptionsButton/CustomOptionsButton.react';
import { participantCell } from 'pages/OriginationParticipantsList/OriginationParticipantsList.constants';
import { COOL_GRAY, GLOBAL_BLUE } from 'theme/GlobalColors';
import Proptypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { ADMIN } from 'utils/config';

const ParticipantContributorList = ({
  data = [],
  accountStatusMapping,
  contributorPaginationData,
  updatePaginationData,
  openEditModal,
  openUpdateAccStatusModal,
}) => {
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const { userRoles } = useContext(userAuthRolesContext);
  const columns = [
    {
      field: contributorTableColumns.participantId.field,
      hide: true,
    },
    {
      field: contributorTableColumns.participantName.field,
      headerName: contributorTableColumns.participantName.label,
      sortable: false,
      renderCell: (params) =>
        participantCell(
          params.row.participantFullName,
          params.row.participantEmail,
          GLOBAL_BLUE,
          COOL_GRAY,
          () => {},
        ),
      disableColumnMenu: true,
      flex: 4,
    },
    {
      field: contributorTableColumns.accountStatus.field,
      headerName: contributorTableColumns.accountStatus.label,
      renderCell: (params) => (
        <div>{getParticipantAccountStatus(params.row.accountStatus)}</div>
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 7,
    },
    {
      field: contributorTableColumns.options.field,
      headerName: contributorTableColumns.options.label,
      sortable: false,
      renderCell: (params) => (
        <CustomOptionsButton
          options={[
            {
              itemLabel: 'Edit',
              disabled: false,
              clickHandler: () => openEditModal(params.row.participantId),
            },
            {
              itemLabel: 'Update account status',
              disabled: !checkAccess(userRoles, ADMIN),
              clickHandler: () => openUpdateAccStatusModal(params),
            },
          ]}
        />
      ),
      disableColumnMenu: true,
      flex: 0.5,
    },
  ];

  return (
    <MainWrapper className="curved-border">
      <DataGridWrapper>
        <DataGrid
          headerHeight={40}
          disableVirtualization={true}
          rows={data}
          rowsPerPageOptions={[25, 50, 75]}
          onRowClick={(params) =>
            navigate(`${PARTICIPANT_PARENT_PATH}/${params.row.participantId}`, {
              state: { projectId: projectId },
            })
          }
          getRowId={(row) =>
            checkTernaryCondition(
              row?.participantId === undefined,
              uniqueId(),
              row?.participantId,
            )
          }
          disableSelectionOnClick
          componentsProps={{
            pagination: {
              labelRowsPerPage: 'Items per page',
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
          paginationMode="server"
          pagination
          rowCount={contributorPaginationData.totalRows}
          pageSize={contributorPaginationData.pageSize}
          page={contributorPaginationData.page - 1}
          onPageChange={(newPage) => {
            updatePaginationData({
              ...contributorPaginationData,
              page: newPage + 1,
            });
          }}
          loading={false}
          onPageSizeChange={(newPageSize) => {
            updatePaginationData({
              ...contributorPaginationData,
              pageSize: newPageSize,
            });
          }}
          columns={columns}
          sx={datagridSx}
        />
      </DataGridWrapper>
    </MainWrapper>
  );
};

// proptypes
ParticipantContributorList.propTypes = {
  data: Proptypes.array,
  accountStatusMapping: Proptypes.object,
  contributorPaginationData: Proptypes.object,
  updatePaginationData: Proptypes.func,
  openEditModal: Proptypes.func,
  openUpdateAccStatusModal: Proptypes.func,
};

export default ParticipantContributorList;
