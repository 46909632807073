import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { handleArrowColor } from './StakeholdersProfile.style';

export const STAKEHOLDERS_PROFILE_CONTENT = {
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  success: 'SUCCESS',
  success_message: 'has been deleted successfully.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  denied_message_single_transaction:
    'transaction. You must remove this stakeholder from the transaction before it can be deleted.',
  denied_message_multiple_transaction:
    'is linked in multiple transactions. You must remove this stakeholder from each transaction below before it can be deleted.',
  noDataContent: ' Looks like you don’t haven’t added any stakeholders yet.',
  addNewStakeLabel: 'Add a new stakeholder to get started!',
  addButton: 'Add Stakeholder',
};

export const SortColValues = {
  vendorName: 'Name',
  vendorType: 'Type',
  addedOn: 'Date',
};

export const SortColNames = {
  stakeHolderName: 'Stakeholder Name',
  stakeHolderType: 'Stakeholder Type',
  addedOn: 'Added On',
};

export const SortHandler = (flag, field, sortColumn) => {
  return flag === true ? (
    <ArrowUpward fontSize="small" sx={handleArrowColor(field, sortColumn)} />
  ) : (
    <ArrowDownward fontSize="small" sx={handleArrowColor(field, sortColumn)} />
  );
};
