import axios from 'axios';
import {
  ASSOCIATED_TYPE,
  AVAILABLE_QUANTITY,
  BENEFIT_SHARE_ASSUMPTION,
  CATEGORY_OF_TRANSACTION,
  COST_STRUCTURE,
  PROJECT_TYPEAHEAD,
  SALE_PROJECT_CONTAINS,
  STAKEHOLDER_CONTAINS,
  TRANSACTION_PURCHASE_SUBMIT,
  TRANSACTION_SALE_SUBMIT,
  VINTAGE_DATES,
} from 'urls';
import { useState } from 'react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { pathName } from 'Routes/routes.const';

export const useTransactionDetails = () => {
  const [categoryOfPurchase, setCategoryOfPurchase] = useState([]);
  const [shareAssumptionTypes, setShareAssumptionTypes] = useState([]);
  const [stakeholderList, setStakeholderList] = useState([]);
  const [costStructure, setCostStructure] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [associatedTypes, setAssociatedTypes] = useState([]);
  const [categoryOfSale, setCategoryOfSale] = useState([]);
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [samenameError, setSameNameError] = useState({
    flag: false,
    message: '',
  });
  const [vintageDates, setVintageDates] = useState([]);
  const [availableQty, setAvailableQty] = useState('');
  const [minDeliveryDate, setMinDeliveryDate] = useState(null);

  const fetchCategory = () => {
    axios
      .get(`${CATEGORY_OF_TRANSACTION}?contract-type=Purchase`)
      .then((response) => {
        setCategoryOfPurchase(() => [
          ...response.data.map((data) => ({
            label: data,
            value: data,
          })),
        ]);
      });
  };

  const fetchSaleCategory = () => {
    axios
      .get(`${CATEGORY_OF_TRANSACTION}?contract-type=Sale`)
      .then((response) => {
        setCategoryOfSale(() => [
          ...response.data.map((data) => ({
            label: data,
            value: data,
          })),
        ]);
      });
  };

  const fetchShareAssumptionTypes = () => {
    axios.get(BENEFIT_SHARE_ASSUMPTION).then((response) => {
      setShareAssumptionTypes(() => [
        {
          label: 'None',
          value: '',
        },
        ...response.data.map((data) => ({
          label: data,
          value: data,
        })),
      ]);
    });
  };

  const fetchCostStructure = () => {
    axios.get(COST_STRUCTURE).then((response) => {
      setCostStructure(() => [
        ...response.data.map((data) => ({
          label: data,
          value: data,
        })),
      ]);
    });
  };

  const fetchAssociatedTYpes = () => {
    axios.get(ASSOCIATED_TYPE).then((response) => {
      setAssociatedTypes(() => [
        {
          label: 'None',
          value: '',
          id: '',
        },
        ...response.data.map((data) => ({
          label: data.vendorOrganization,
          value: data.vendorOrganization,
          id: data.vendorId,
        })),
      ]);
    });
  };

  const fetchStakeholderNames = (stakeholderName, type) => {
    if (stakeholderName.length > 2) {
      axios
        .get(
          `${STAKEHOLDER_CONTAINS}?vendor-name=${stakeholderName}&contract-type=${type}`,
        )
        .then((response) => {
          setStakeholderList(() => [
            ...response.data.map((data) => ({
              label: data.vendorOrganisation,
              value: data.vendorOrganisation,
              vendorId: data.vendorId,
            })),
          ]);
        });
    }
  };

  const fetchProjectNames = (projectName) => {
    if (projectName.length > 0) {
      axios
        .get(`${PROJECT_TYPEAHEAD}?project-name=${projectName}`)
        .then((response) => {
          setProjectNames(() => [
            ...response.data.map((data) => ({
              label: data.projectName,
              value: data.projectName,
              internalProjectId: data.internalProjectId,
            })),
          ]);
        });
    }
  };

  const fetchSaleProjects = (projectName) => {
    if (projectName.length > 0) {
      axios
        .get(`${SALE_PROJECT_CONTAINS}?project-name=${projectName}`)
        .then((response) => {
          setProjectNames(() => [
            ...response.data.map((data) => ({
              label: data.projectName,
              value: data.projectName,
              internalProjectId: data.internalProjectId,
              contractProjectMapId: data.contractProjectMapId,
            })),
          ]);
        });
    }
  };

  const fetchVintageDates = (contractProjectMapId) => {
    axios.get(`${VINTAGE_DATES}/${contractProjectMapId}`).then((response) => {
      setVintageDates(() => [
        ...response.data.map((data) => ({
          label: `${data.vintageStart}-${data.vintageEnd}`,
          value: data.creditBatchId,
        })),
      ]);
    });
  };

  const fetchAvailableQty = (amountRequest) => {
    axios.post(`${AVAILABLE_QUANTITY}`, amountRequest).then((response) => {
      setAvailableQty(response.data.availableCredits);
      setMinDeliveryDate(response.data.minDeliveryDate);
    });
  };

  const toastMessage = (type, message) => {
    return toast(<CustomSnackbar type={type} message={message} />, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const submitTransaction = (submitData) => {
    setSubmitLoading(true);
    axios
      .post(`${TRANSACTION_PURCHASE_SUBMIT}`, submitData)
      .then(() => {
        toastMessage(
          'success',
          `Success! ${submitData.transactionName} has been added to your portfolio`,
        );
        navigate(pathName.assets.transactions, { state: 'transactionform' });
      })
      .catch((error) => {
        if (error.message.data?.error) {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else if (error.response.data?.message) {
          if (error.response.data?.message.includes('name')) {
            setSameNameError(() => ({
              flag: true,
              message: 'The Transaction name entered has already been added',
            }));
          } else if (
            error.response.data?.message.includes('CalculationService')
          ) {
            toastMessage(
              'error',
              `${submitData.transactionName} has been added but calculation failed`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          }
        } else {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Please Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const editTransaction = (submitData, transactionId) => {
    setSubmitLoading(true);
    axios
      .put(`${TRANSACTION_PURCHASE_SUBMIT}/${transactionId}`, submitData)
      .then(() => {
        toastMessage(
          'success',
          `${submitData.transactionName} has been updated in your portfolio`,
        );
        navigate(pathName.assets.transactions, { state: 'transactionform' });
      })
      .catch((error) => {
        if (error.message.data?.error) {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else if (error.response.data?.message) {
          if (error.response.data?.message.includes('name')) {
            setSameNameError(() => ({
              flag: true,
              message: 'The Transaction name entered has already been added',
            }));
          } else if (
            error.response.data?.message.includes('CalculationService')
          ) {
            toastMessage(
              'error',
              `${submitData.transactionName} has been updated but calculation failed`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          } else {
            toastMessage(
              'error',
              `Error ${submitData.transactionName} has not been updated. Try Again`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          }
        } else {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added.Please Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const submitSaleTransaction = (submitData) => {
    setSubmitLoading(true);
    axios
      .post(`${TRANSACTION_SALE_SUBMIT}`, submitData)
      .then(() => {
        toastMessage(
          'success',
          `Success! ${submitData.transactionName} has been added to your portfolio`,
        );
        navigate(pathName.assets.transactions, { state: 'transactionform' });
      })
      .catch((error) => {
        if (error.message.data?.error) {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else if (error.response.data?.message) {
          if (error.response.data?.message.includes('name')) {
            setSameNameError(() => ({
              flag: true,
              message: 'The Transaction name entered has already been added',
            }));
          } else if (
            error.response.data?.message.includes('CalculationService')
          ) {
            toastMessage(
              'error',
              `${submitData.transactionName} has been added but calculation failed`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          }
        } else {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Please Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const editSaleTransaction = (submitData, transactionId) => {
    setSubmitLoading(true);
    axios
      .put(`${TRANSACTION_SALE_SUBMIT}/${transactionId}`, submitData)
      .then(() => {
        toastMessage(
          'success',
          `${submitData.transactionName} has been updated in your portfolio`,
        );
        navigate(pathName.assets.transactions, { state: 'transactionform' });
      })
      .catch((error) => {
        if (error.message.data?.error) {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added. Try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else if (error.response.data?.message) {
          if (error.response.data?.message.includes('name')) {
            setSameNameError(() => ({
              flag: true,
              message: 'The Transaction name entered has already been added',
            }));
          } else if (
            error.response.data?.message.includes('CalculationService')
          ) {
            toastMessage(
              'error',
              `${submitData.transactionName} has been updated but calculation failed`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          } else {
            toastMessage(
              'error',
              `Error ${submitData.transactionName} has not been updated. Try Again`,
            );
            navigate(pathName.assets.transactions, {
              state: 'transactionform',
            });
          }
        } else {
          toastMessage(
            'error',
            `Error ${submitData.transactionName} has not been added.Please try Again`,
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return {
    categoryOfPurchase,
    stakeholderList,
    shareAssumptionTypes,
    costStructure,
    projectNames,
    associatedTypes,
    submitLoading,
    samenameError,
    categoryOfSale,
    vintageDates,
    availableQty,
    minDeliveryDate,
    fetchAvailableQty,
    setVintageDates,
    setSameNameError,
    fetchCategory,
    fetchStakeholderNames,
    fetchShareAssumptionTypes,
    fetchAssociatedTYpes,
    fetchCostStructure,
    fetchProjectNames,
    submitTransaction,
    editTransaction,
    fetchSaleCategory,
    fetchSaleProjects,
    fetchVintageDates,
    submitSaleTransaction,
    editSaleTransaction,
  };
};
