import styled from 'styled-components';

export const GridWrapper = styled.div`
  min-height: 10rem;
  margin-top: 1rem;
  width: 34.5rem;
`;
export const datagridSx = {
  boxShadow: 'none',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '140%',
    fontSize: '0.875rem',
    color: '#333333',
  },
  borderRadius: 0,
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '1.188rem',
    fontSize: '0.875rem',
    color: '#333333',
  },
  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
};
