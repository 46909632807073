import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import {
  annualYieldDropdown,
  cropDensityDropdownList,
  cropDepthDropdownList,
  cropList,
  initialCropTypeData,
  MODAL_CONTENT,
} from './CropType.content';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  closeIconSx,
} from '../Modals.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { addZeroBeforeDot, checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ReactComponent as Calendericon } from '../../../../../../assets/icons/calenderIcon.svg';
import { useEffect, useMemo, useState, useContext } from 'react';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import { useForm } from 'hooks/useForm';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  FETCH_MANAGEMENT_TYPE_DROPDOWN,
  FETCH_ALL_CROP_TYPE_DROPDOWN,
  FETCH_DISTURBANCE_TYPE,
  FETCH_TILLAGE_SYSTEM_TYPE,
  FETCH_TILLAGE_SYSTEM_TYPE_BY_ID,
  FETCH_CROP_TYPE_DROPDOWN,
} from 'urls';
import axios from 'axios';
import {
  CropTypeModalDialogActionStyle,
  CropTypeModalDialogContentStyle,
  CropTypeModalDialogStyle,
  CropTypeModalDialogTitleStyle,
  ValidationErrorStyles,
  RadioGroupStyle,
  TillageHeading,
  CropTypeModalDialogStyleForHarvestEfficiencyUom,
  CropValueInput,
  CropValueDollar,
} from './CropTypeModal.style';
import {
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
  ErroWrapper,
  FormLabels,
} from 'components/FormComponents/FormStyles';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  DISABLED_TEXT_COLOR,
  RED,
} from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { GLOBAL_STRING_CONSTANTS, DATE_FORMAT } from 'utils/config';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import { itcToDateFormatter } from 'utils/dateFormatter';
import { getProductionPurposeValue } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const CropTypeModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
  tabWiseSubmitHandler = () => {},
  totalFieldArea,
  setYearWiseCashCropList,
  yearWiseCashCropList,
  activityList,
  setActivityList,
  existingCropTypeIds,
  hasTestDefaultDate,
}) => {
  const { setSelectedTillagePractice } = useContext(baselineDropdownContext);
  const [datePickerPlantedOpen, setDatePickerPlantedOpen] = useState(false);
  const [datePickerHarvestedOpen, setDatePickerHarvestedOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dateExceedError, setDateExceedError] = useState({
    planted: false,
    harvested: false,
  });
  const [dateHarvestedError, setDateHarvestedError] = useState();
  const [datePlantedError, setDatePlantedError] = useState();
  const [totalFieldAreaError, setTotalFieldAreaError] = useState();
  const [modifiedActivityList, setModifiedActivityList] =
    useState(activityList);
  const [errorState, setErrorState] = useState({
    cropId: false,
    cropRotation: false,
    annualYield: false,
    yieldUnitOfMeasure: false,
    yieldMeasuredPer: false,
    cropPlantDate: false,
    cropHarvestDate: false,
    cropArea: false,
    cropAreaUOM: false,
    activityList: false,
    cropDensity: false,
    cropDepth: false,
    cropPlannedManagementTypeId: false,
    productionPurpose: false,
    tillageSystemId: false,
    tillagePractice: false,
    tillageSystemDisturbanceId: false,
  });
  const [cropTypeDropDown, setCropTypeDropDown] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const modalCloseHandler = () => {
    setModifiedActivityList([]);
    setCropTypeDropDown([]);
    setDisturbanceTypeList([]);
    setTillageSystem([]);
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialCropTypeData);
    setIsEdit(false);
    setInitialEditState(false);
    setErrorStatesToFalse();
  };
  const [managementTypeDropDown, setManagementTypeDropDown] = useState([]);
  const [disableAnnualField, setDisableAnnualField] = useState(false);
  const [disableAnnualFieldUom, setDisableAnnualFieldUom] = useState(false);
  const [cropValueUomPercentageHarvest, setCropValueUomPercentageHarvest] =
    useState(false);
  const [tillageSystem, setTillageSystem] = useState([]);
  const [disturbanceTypeList, setDisturbanceTypeList] = useState([]);
  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(initialCropTypeData);
  const [tillageValue, setTillageValue] = useState({
    tillageSystemId: '',
    tillageSystemDisturbanceId: '',
  });
  const [initialEditState, setInitialEditState] = useState(false);
  const [annualYield, setAnnualYield] = useState({
    annualYield: '',
    annualYieldUom: '',
  });

  let season;
  const cropPlantDate = itcToDateFormatter(formValue.cropPlantDate);

  const year = modalOpen.data?.year;

  const winterStart = itcToDateFormatter(`${year - 1}-09-01`);

  const winterEnd = itcToDateFormatter(`${year}-01-31`);

  const summerStart = itcToDateFormatter(`${year}-02-01`);

  const summerEnd = itcToDateFormatter(`${year}-08-31`);

  if (cropPlantDate >= winterStart && cropPlantDate <= winterEnd) {
    season = 'Winter';
  } else if (cropPlantDate >= summerStart && cropPlantDate <= summerEnd) {
    season = 'Summer';
  }

  const handleTextFieldValueChange = (event, eventValue, key, isNumber) => {
    const finalValue = event?.target.value.trim() ? event?.target.value : '';
    if (isNumber) {
      event?.target.value.match(/^\d*(\.\d{0,2})?$/) &&
        customFieldChangeHandler(key, addZeroBeforeDot(finalValue));
    } else {
      customFieldChangeHandler(key, finalValue);
    }
  };

  const fetchTillageSystemTypeById = (id) => {
    axios.get(FETCH_TILLAGE_SYSTEM_TYPE_BY_ID + id).then((response) => {
      const data = response.data.tillageSystemType;

      setTillageValue({
        tillageSystemId: tillageSystem.find((item) => item.label === data)
          ?.value,
        tillageSystemDisturbanceId: id,
      });
    });
  };
  const isFallowSelected = () => {
    return (
      formValue.productionPurpose.toLowerCase() ===
      MODAL_CONTENT.fallow_crop_value.toLowerCase()
    );
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      ...tillageValue,
    });
  }, [tillageValue]);

  const fetchTillageSystemType = () => {
    axios.get(FETCH_TILLAGE_SYSTEM_TYPE).then((response) => {
      setTillageSystem(
        response.data.map((item, index) => ({
          value: index,
          label: item,
        })),
      );
    });
  };

  const fetchDisturbanceList = (id) => {
    axios.get(`${FETCH_DISTURBANCE_TYPE}=${id}`).then((response) => {
      setDisturbanceTypeList(
        response.data.map((data) => ({
          value: data.tillageSystemDisturbanceId,
          label: data.surfaceDisturbanceType,
          tillageSystemType: data.tillageSystemType,
        })),
      );
    });
  };

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data?.formData?.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      if (modalData?.tillageSystemDisturbanceId) {
        fetchTillageSystemTypeById(modalData?.tillageSystemDisturbanceId);
      }
    }
  }, [isEdit]);

  const fetchCropTypeDropdown = () => {
    const url = checkTernaryCondition(
      formValue.productionPurpose === MODAL_CONTENT.fallow_crop_value,
      FETCH_CROP_TYPE_DROPDOWN,
      `${FETCH_ALL_CROP_TYPE_DROPDOWN}=${getProductionPurposeValue(
        formValue.productionPurpose,
      )}`,
    );
    axios.get(url).then((response) => {
      setCropTypeDropDown(
        response.data.map((data) => ({
          label: data.cropName,
          value: data.cropId,
        })),
      );
    });
  };

  const fetchManagementTypeDropdown = () => {
    axios.get(FETCH_MANAGEMENT_TYPE_DROPDOWN).then((response) => {
      setManagementTypeDropDown(
        response.data.map((data) => ({
          label: data.cropPlannedManagementType,
          value: data.cropPlannedManagementTypeId,
          flag: data.cropTerminationFlag,
        })),
      );
    });
  };
  const handleCheckboxValueChange = (index, formStateSetter, key) => {
    let list = [...modifiedActivityList];
    list[index].checked = !list[index].checked;
    const finalList = list
      .map((item) => {
        if (item.checked === true) {
          return item.id;
        }
      })
      .filter((item) => item !== undefined);
    const listIsEmpty = finalList.length === 0;
    setErrorState({ ...errorState, activityList: listIsEmpty });
    formStateSetter(key, finalList);
  };
  const setErrorStatesToFalse = () => {
    setDateExceedError({ harvested: false, planted: false });
    setDateHarvestedError(false);
    setDatePlantedError(false);
    setErrorState({
      cropId: false,
      cropRotation: false,
      annualYield: false,
      yieldUnitOfMeasure: false,
      yieldMeasuredPer: false,
      cropPlantDate: false,
      cropHarvestDate: false,
      cropArea: false,
      cropAreaUOM: false,
      productionPurpose: false,
      cropPlannedManagementTypeId: false,
    });
  };

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data?.formData?.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      if (modalData) {
        setIsEdit(true);
        setInitialEditState(true);
        setAnnualYield({
          annualYield: modalData?.annualYield,
          annualYieldUom: checkTernaryCondition(
            modalData?.annualYieldUom?.[
              modalData?.annualYieldUom?.length - 1
            ] === '/',
            modalData?.annualYieldUom?.substring(
              0,
              modalData?.annualYieldUom?.length - 1,
            ),
            modalData?.annualYieldUom,
          ),
        });
      }
      setFormValue(
        checkTernaryCondition(
          modalData,
          {
            ...modalData,
            cropDensity: checkTernaryCondition(
              modalData?.cropDensity === null,
              '',
              modalData?.cropDensity,
            ),
            cropDepth: checkTernaryCondition(
              modalData?.cropDepth === null,
              '',
              modalData?.cropDepth,
            ),
            tillageSystemOther: modalData?.tillageSystemOther,
          },
          initialCropTypeData,
        ),
      );
    }
  }, [modalOpen]);
  useEffect(() => {
    if (activityList.length > 0 && formValue.activityList !== undefined) {
      setModifiedActivityList(
        checkTernaryCondition(
          formValue.activityList?.length === 0,
          [...activityList],
          [...activityList].map((item) => {
            if (formValue?.activityList?.includes(+item.id)) {
              return {
                ...item,
                checked: true,
              };
            } else
              return {
                ...item,
                checked: false,
              };
          }),
        ),
      );
    }
  }, [activityList, formValue]);
  useEffect(() => {
    if (!modalOpen.flag) {
      let newList = [...activityList].map((item) => ({
        ...item,
        checked: false,
      }));
      setActivityList(newList);
      setModifiedActivityList(newList);
    }
  }, [modalOpen.flag]);
  useEffect(() => {
    fetchManagementTypeDropdown();
    fetchTillageSystemType();
  }, [modalOpen.flag]);

  useEffect(() => {
    if (formValue.tillageSystemId || formValue.tillageSystemId === 0) {
      !initialEditState &&
        customFieldChangeHandler('tillageSystemDisturbanceId', '');
      const id = tillageSystem.find(
        (item) => item.value === formValue.tillageSystemId,
      )?.label;
      if (id) {
        fetchDisturbanceList(id);
      }
      setInitialEditState(false);
    }
  }, [formValue.tillageSystemId]);

  useEffect(() => {
    if (
      cropTypeDropDown.find((item) => item.value === formValue.cropId)
        ?.label === MODAL_CONTENT.fallow ||
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.flag
    ) {
      setFormValue({ ...formValue, annualYield: 0, annualYieldUom: '' });
      setDisableAnnualField(true);
      setDisableAnnualFieldUom(true);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.silage
    ) {
      customFieldChangeHandler('annualYieldUom', 'tons/acre');
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grain &&
      cropList.includes(
        cropTypeDropDown.find((item) => item.value === formValue.cropId)?.label,
      )
    ) {
      customFieldChangeHandler('annualYieldUom', 'bushels/acre');
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grain &&
      ![...cropList, MODAL_CONTENT.otherHarvested].includes(
        cropTypeDropDown.find((item) => item.value === formValue.cropId)?.label,
      )
    ) {
      customFieldChangeHandler('annualYieldUom', 'lbs/acre');
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      managementTypeDropDown.find(
        (item) => item.value === formValue.cropPlannedManagementTypeId,
      )?.label === MODAL_CONTENT.grazing
    ) {
      customFieldChangeHandler('annualYieldUom', '% harvest efficiency');
      setDisableAnnualFieldUom(true);
      setDisableAnnualField(false);
    } else if (
      cropTypeDropDown.find((item) => item.value === formValue.cropId)
        ?.label === MODAL_CONTENT.otherHarvested
    ) {
      customFieldChangeHandler('annualYieldUom', '');
      setDisableAnnualFieldUom(false);
      setDisableAnnualField(false);
    } else {
      setDisableAnnualField(false);
      setDisableAnnualFieldUom(false);
      setFormValue({ ...formValue, annualYield: '', annualYieldUom: '' });
    }
    if (initialEditState) {
      setFormValue({ ...formValue, ...annualYield });
    }
  }, [
    formValue.cropPlannedManagementTypeId,
    formValue.cropId,
    formValue.annualYield,
  ]);

  useEffect(() => {
    setFormValue({ ...formValue, annualYield: '' });
  }, [formValue.cropPlannedManagementTypeId]);

  const datePlantedProps = {
    minDate: `${+modalOpen.data?.year - 1}-09-01`,
    maxDate: `${+modalOpen.data?.year}-08-31`,
    notShowValue: isFallowSelected(),
    calendarOpenDate: checkTernaryCondition(
      hasTestDefaultDate,
      new Date(),
      `${modalOpen.data?.year}-01-01`,
    ),
    label: MODAL_CONTENT.date_planted,
    value: checkTernaryCondition(
      formValue.cropPlantDate === '',
      MODAL_CONTENT.selected_date,
      formValue.cropPlantDate,
    ),
    inputFormat: DATE_FORMAT,
    onUpdate: (newValue) => {
      if (
        (new Date(newValue) > new Date(`${+modalOpen.data?.year}-12-31`) ||
          new Date(newValue) < new Date(`${modalOpen.data?.year - 2}-12-31`)) &&
        newValue !== null
      ) {
        setDatePlantedError(true);
      } else {
        setDatePlantedError(false);
      }
      setErrorState({
        ...errorState,
        cropPlantDate: newValue?.toDate() === '',
      });
      setDateExceedError({
        harvested: false,
        planted: new Date(newValue) >= new Date(formValue.cropTerminationDate),
      });
      if (newValue?.toDate() === null || newValue?.toDate() === undefined) {
        customFieldChangeHandler('cropPlantDate', '');
      } else {
        customFieldChangeHandler('cropPlantDate', newValue?.toDate());
      }
    },
    icon: Calendericon,
    open: datePickerPlantedOpen,
    onOpen: () =>
      isFallowSelected() ? () => {} : setDatePickerPlantedOpen(true),
    onClose: () => setDatePickerPlantedOpen(false),
    placeholder: MODAL_CONTENT.selected_date,
    width: '8rem',
    height: '2rem',
    onClickHandler: isFallowSelected()
      ? () => {}
      : () => setDatePickerPlantedOpen(true),
    minWidth: '7.5rem',
    fontSize: '14px',
    error: errorState.cropPlantDate && !isFallowSelected(),
    errorMessage: MODAL_CONTENT.date_planted_error,
    customSelectedDateColor: DARK_GREEN,
    isDisabled: isFallowSelected(),
  };
  const dateHarvestedProps = {
    minDate: `${+modalOpen.data?.year}-01-01`,
    maxDate: `${+modalOpen.data?.year}-12-31`,
    notShowValue: isFallowSelected(),
    calendarOpenDate: checkTernaryCondition(
      hasTestDefaultDate,
      new Date(),
      `${modalOpen.data?.year}-01-01`,
    ),
    label: MODAL_CONTENT.date_harvested,
    minWidth: '7.5rem',
    value: checkTernaryCondition(
      formValue.cropTerminationDate === '',
      MODAL_CONTENT.selected_date,
      formValue.cropTerminationDate,
    ),
    inputFormat: DATE_FORMAT,
    onUpdate: (newValue) => {
      const startDate = checkTernaryCondition(
        formValue.cropPlantDate === GLOBAL_STRING_CONSTANTS.empty_string,
        `${modalOpen.data?.year}-01-01`,
        formValue.cropPlantDate,
      );
      setDateHarvestedError(
        new Date(newValue) > new Date(`${+modalOpen.data?.year}-12-31`),
      );
      setDateExceedError({
        planted: false,
        harvested: new Date(newValue) <= new Date(startDate),
      });
      if (newValue?.toDate() === null || newValue?.toDate() === undefined) {
        customFieldChangeHandler('cropTerminationDate', '');
      } else {
        customFieldChangeHandler('cropTerminationDate', newValue?.toDate());
      }
      setErrorState({
        ...errorState,
        cropHarvestDate: newValue?.toDate() === '',
      });
    },
    icon: Calendericon,
    open: datePickerHarvestedOpen,
    onOpen: () =>
      isFallowSelected() ? () => {} : setDatePickerHarvestedOpen(true),
    onClose: () => setDatePickerHarvestedOpen(false),
    placeholder: MODAL_CONTENT.selected_date,
    width: '8rem',
    height: '2rem',
    fontSize: '14px',
    onClickHandler: () =>
      isFallowSelected() ? () => {} : setDatePickerHarvestedOpen(true),
    error:
      !isFallowSelected() && (errorState.cropHarvestDate || dateHarvestedError),
    customSelectedDateColor: DARK_GREEN,
    isDisabled: isFallowSelected(),
  };
  const setModalErrorState = (value, fieldName) => {
    let newErrorState = { ...errorState };
    newErrorState[fieldName] = value === '';
    setErrorState(newErrorState);
  };
  const setCropNameInList = (event) => {
    const cropName = event.target.value;
    const oldName = cropTypeDropDown.filter(
      (item) => item.value === formValue.cropId,
    )[0]?.label;
    const name = cropTypeDropDown.filter((item) => item.value === cropName)[0]
      ?.label;
    let copyFirstYearData = yearWiseCashCropList?.firstYearCrops;
    let copySecondYearData = yearWiseCashCropList?.secondYearCrops;
    let copyThirdYearData = yearWiseCashCropList?.thirdYearCrops;
    switch (+modalOpen.data?.year) {
      case 2020:
        if (copyFirstYearData?.includes(oldName)) {
          copyFirstYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copyFirstYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          firstYearCrops: copyFirstYearData,
        });
        break;
      case 2021:
        if (copySecondYearData?.includes(oldName)) {
          copySecondYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copySecondYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          secondYearCrops: copySecondYearData,
        });
        break;
      case 2022:
        if (copyThirdYearData?.includes(oldName)) {
          copyThirdYearData?.pop(oldName);
        }
        if (name !== undefined) {
          copyThirdYearData?.push(name);
        }
        setYearWiseCashCropList({
          ...yearWiseCashCropList,
          thirdYearCrops: copyThirdYearData,
        });
        break;
    }
  };
  const submitBtnHandler = () => {
    const hasError = {
      cropId: formValue.cropId === '' && !isFallowSelected(),
      cropPlantDate: formValue.cropPlantDate === '' && !isFallowSelected(),
      cropHarvestDate:
        formValue.cropTerminationDate === '' && !isFallowSelected(),
      cropArea: formValue.cropArea === '',
      cropDensity:
        formValue.plantingDensity?.length > 0 &&
        formValue.plantingDensityUom === '',
      cropDepth:
        formValue.cropDepth?.length > 0 && formValue.cropDepthUom === '',
      productionPurpose: !formValue.productionPurpose,
      cropPlannedManagementTypeId: formValue.cropPlannedManagementTypeId === '',
      tillageSystemId: formValue.tillageSystemId === '',
      tillageSystemDisturbanceId: formValue.tillageSystemDisturbanceId === '',
      tillagePractice:
        tillageSystem.find((item) => item.value === formValue.tillageSystemId)
          ?.label === MODAL_CONTENT.other &&
        formValue.tillageSystemOther === '',
    };
    setSelectedTillagePractice(tillageSystem[formValue.tillageSystemId]?.label);
    setFormValue({ ...formValue, tillageSystemId: formValue.tillageSystemId });
    setErrorState(hasError);
    if (
      !Object.values(hasError).includes(true) &&
      !dateHarvestedError &&
      !datePlantedError &&
      !totalFieldAreaError &&
      !Object.values(dateExceedError).includes(true)
    ) {
      submitHandler(
        modalOpen.id,
        {
          id: checkTernaryCondition(isEdit, formValue.id, uniqueId()),
          ...formValue,
        },
        isEdit,
        modalOpen.currentRowId,
      );
      const submitData = {
        ...formValue,
        annualYieldUom: checkTernaryCondition(
          formValue?.annualYieldUom?.[formValue?.annualYieldUom?.length - 1] ===
            '/',
          formValue?.annualYieldUom?.substring(
            0,
            formValue?.annualYieldUom?.length - 1,
          ),
          formValue?.annualYieldUom,
        ),
        annualYield: checkTernaryCondition(
          formValue.productionPurpose === MODAL_CONTENT.fallow_crop_value,
          0,
          checkTernaryCondition(
            formValue.annualYield === '',
            '',
            formValue.annualYield,
          ),
        ),
        cropSeason: season,
        plantingDensity: checkTernaryCondition(
          formValue.plantingDensity === '',
          null,
          formValue.plantingDensity,
        ),
        cropValue: checkTernaryCondition(
          formValue.cropValue === '',
          null,
          formValue.cropValue,
        ),
        cropDepth: checkTernaryCondition(
          formValue.cropDepth === '',
          null,
          formValue.cropDepth,
        ),
        cropId: checkTernaryCondition(
          formValue.productionPurpose === MODAL_CONTENT.fallow_crop_value,
          cropTypeDropDown?.find((item) => item.label === MODAL_CONTENT.fallow)
            ?.value,
          formValue.cropId,
        ),
      };
      tabWiseSubmitHandler(
        submitData,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
      );

      modalCloseHandler();
      setErrorStatesToFalse();
    }
  };
  const getProductionPurposeRadioBtnValue = (productionPurpose) => {
    productionPurpose = productionPurpose.replace('_', ' ');
    switch (productionPurpose) {
      case MODAL_CONTENT.cash_crop.toLowerCase():
        return MODAL_CONTENT.cash_crop_value;
      case MODAL_CONTENT.cover_crop.toLowerCase():
        return MODAL_CONTENT.cover_crop_value;
      case MODAL_CONTENT.fallow.toLowerCase():
        return MODAL_CONTENT.fallow_crop_value;
      default:
        return productionPurpose;
    }
  };
  useEffect(() => {
    setIsSubmitDisabled(
      Object.values(errorState).includes(true) ||
        Object.values(dateExceedError).includes(true) ||
        dateHarvestedError ||
        datePlantedError ||
        totalFieldAreaError,
    );
  }, [
    errorState,
    dateExceedError,
    dateHarvestedError,
    datePlantedError,
    totalFieldAreaError,
  ]);
  const tillageSystemPrevId = useMemo(
    () => formValue.tillageSystemId,
    [formValue.tillageSystemId],
  );

  useEffect(() => {
    if (
      tillageSystem.find((item) => item.value === formValue.tillageSystemId)
        ?.label !== MODAL_CONTENT.other &&
      formValue.tillageSystemOther !== '' &&
      tillageSystemPrevId !== undefined
    ) {
      customFieldChangeHandler('tillageSystemOther', '');
    }
  }, [formValue.tillageSystemId]);
  useEffect(() => {
    const cropValueUomAsHarvest = `${checkTernaryCondition(
      formValue.annualYieldUom === '' ||
        formValue.annualYieldUom === MODAL_CONTENT.annual_yield_uom,
      '',
      'per',
    )} ${
      checkTernaryCondition(
        formValue.annualYieldUom === '' ||
          formValue.annualYieldUom === MODAL_CONTENT.annual_yield_uom,
        MODAL_CONTENT.crop_value_empty,
        formValue.annualYieldUom,
      ).split('/')[0]
    }`;
    if (cropValueUomAsHarvest === 'per % harvest efficiency') {
      setCropValueUomPercentageHarvest(true);
    } else {
      setCropValueUomPercentageHarvest(false);
    }
  }, [formValue.annualYieldUom]);

  useEffect(() => {
    if (formValue.productionPurpose) {
      !initialEditState && customFieldChangeHandler('cropId', '');
      fetchCropTypeDropdown();
      if (formValue.productionPurpose === MODAL_CONTENT.fallow_crop_value) {
        setFormValue({
          ...formValue,
          annualYield: 0,
          cropId: '',
          plantingDensity: '',
          plantingDensityUom: '',
          cropDepth: '',
          cropDepthUom: '',
          cropPlantDate: '',
          cropTerminationDate: '',
          annualYieldUom: '',
          cropValue: '',
        });
        setErrorState({
          ...errorState,
          cropId: false,
          cropRotation: false,
          annualYield: false,
          yieldUnitOfMeasure: false,
          yieldMeasuredPer: false,
          cropPlantDate: false,
          cropHarvestDate: false,
          activityList: false,
          cropDensity: false,
          cropDepth: false,
        });
      } else {
        setFormValue({ ...formValue, annualYield: '' });
      }
    }
  }, [formValue.productionPurpose]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{
        ...DialogStyle,
        ...(cropValueUomPercentageHarvest
          ? CropTypeModalDialogStyleForHarvestEfficiencyUom
          : CropTypeModalDialogStyle),
      }}>
      <DialogTitle
        sx={{
          ...DialogTitleStyle,
          ...CropTypeModalDialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <TextWrapper
          fontSize="1rem"
          fontWeight={600}
          color={
            DARK_CHARCOAL
          }>{`${modalOpen.data?.year} ${MODAL_CONTENT.crop_type_modal_heading}`}</TextWrapper>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={{ ...DialogContentStyle, ...CropTypeModalDialogContentStyle }}>
        <FormLabels
          marginBottom="-0.3125rem"
          marginTop="0.5rem"
          iserror={errorState.productionPurpose}>
          {MODAL_CONTENT.production_purpose}
        </FormLabels>
        <RadioGroup
          sx={RadioGroupStyle}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={(e) => {
            customFieldChangeHandler('productionPurpose', e.target.value);
            setErrorState((prevError) => ({
              ...prevError,
              productionPurpose: false,
            }));
          }}
          value={getProductionPurposeRadioBtnValue(
            formValue.productionPurpose?.toLowerCase(),
          )}
          name="radio-buttons-group">
          <FormControlLabel
            sx={RadioButtonStyle('0px')}
            disabled={
              modalOpen.data?.formData[0]?.addedFromImplementedActivities ||
              false
            }
            value={MODAL_CONTENT.cash_crop_value}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="enrollment-radio"
              />
            }
            label={MODAL_CONTENT.cash_crop}
          />
          <FormControlLabel
            disabled={
              modalOpen.data?.formData[0]?.addedFromImplementedActivities ||
              false
            }
            sx={RadioButtonStyle(null)}
            value={MODAL_CONTENT.cover_crop_value}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="enrollment-radio"
              />
            }
            label={MODAL_CONTENT.cover_crop}
          />
          <FormControlLabel
            disabled={
              modalOpen.data?.formData[0]?.addedFromImplementedActivities ||
              false
            }
            sx={RadioButtonStyle(null)}
            value={MODAL_CONTENT.fallow_crop_value}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="enrollment-radio"
              />
            }
            label={MODAL_CONTENT.fallow}
          />
        </RadioGroup>
        {errorState.productionPurpose && (
          <ErroWrapper marginTop="-0.5rem">
            {MODAL_CONTENT.production_purpose_error}
          </ErroWrapper>
        )}
        <DropDown
          labelMarginTop={checkTernaryCondition(
            errorState.productionPurpose,
            '0.5rem',
            '0rem',
          )}
          customPlaceholderColor={COOL_GRAY}
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.crop_type}
          value={checkTernaryCondition(
            isFallowSelected() || formValue.cropId === '',
            MODAL_CONTENT.select_crop_type,
            formValue.cropId,
          )}
          stateValue={formValue.cropId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="cropId"
          name={MODAL_CONTENT.crop_type}
          dropdownlist={cropTypeDropDown?.filter(
            (crop) =>
              !existingCropTypeIds
                .filter((item) => item !== formValue.cropId)
                .includes(crop.value),
          )}
          dropDownPlaceholder={MODAL_CONTENT.select_crop_type}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          error={errorState.cropId && !isFallowSelected()}
          errorMessage={MODAL_CONTENT.crop_type_error}
          setModalErrorState={setModalErrorState}
          fieldName="cropId"
          showLabelAsValue={true}
          onUpdate={setCropNameInList}
          listItemFontSize={MODAL_CONTENT.dropdownFontSize}
          isDisabled={isFallowSelected()}
        />
        <FieldSectionWrapper columnGap="1rem">
          <InputField
            label={MODAL_CONTENT.crop_area}
            value={formValue.cropArea}
            placeholder={MODAL_CONTENT.type_area}
            primaryError={errorState.cropArea || totalFieldAreaError}
            primaryErrorMessage={checkTernaryCondition(
              errorState.cropArea,
              checkTernaryCondition(
                formValue.cropArea === '0',
                MODAL_CONTENT.crop_area_greater_than_zero_validation,
                MODAL_CONTENT.crop_area_error,
              ),
              `${MODAL_CONTENT.total_field_area_error}- ${totalFieldArea} acres.`,
            )}
            name="cropArea"
            onUpdate={(event) => {
              if (+event.target.value > +totalFieldArea) {
                setTotalFieldAreaError(true);
              } else {
                setTotalFieldAreaError(false);
              }
              handleTextFieldValueChange(
                event,
                event.target.value,
                'cropArea',
                true,
              );
              setErrorState({
                ...errorState,
                cropArea:
                  event.target.value === '' || +event.target.value === 0,
              });
            }}
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            predefinedUnit="acres"
            hasPredefinedUnit
          />
        </FieldSectionWrapper>
        <DropDown
          customPlaceholderColor={COOL_GRAY}
          labelMarginTop="0.5rem"
          labelMarginBottom="0.25rem"
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.management_type}
          value={checkTernaryCondition(
            formValue.cropPlannedManagementTypeId === '' ||
              managementTypeDropDown[
                formValue.cropPlannedManagementTypeId - 1
              ] == undefined,
            MODAL_CONTENT.select_management_type,
            formValue.cropPlannedManagementTypeId,
          )}
          stateValue={formValue.cropPlannedManagementTypeId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="cropPlannedManagementTypeId"
          name={MODAL_CONTENT.management_type}
          dropdownlist={managementTypeDropDown}
          dropDownPlaceholder={MODAL_CONTENT.select_management_type}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          error={errorState.cropPlannedManagementTypeId}
          errorMessage={MODAL_CONTENT.management_type_error}
          setModalErrorState={setModalErrorState}
          fieldName="cropPlannedManagementTypeId"
          showLabelAsValue={true}
        />
        <FieldSectionWrapper columnGap="1rem">
          <InputField
            isDisabled={isFallowSelected() || disableAnnualField}
            noGap
            optionalLabelStyle="0.2rem"
            label={MODAL_CONTENT.annual_yield}
            value={checkTernaryCondition(
              formValue.productionPurpose === MODAL_CONTENT.fallow_crop_value,
              0,
              formValue.annualYield,
            )}
            placeholder={MODAL_CONTENT.typeAmount}
            name="annualYield"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'annualYield',
                true,
              );
            }}
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            primaryError={errorState.annualYield}
            primaryErrorMessage={MODAL_CONTENT.annual_yield_error}
            testId={'annual-yield-test-id'}
            isOptional
            optionalOpacity={1}
          />
          <DropDown
            customPlaceholderColor={checkTernaryCondition(
              isFallowSelected(),
              DISABLED_TEXT_COLOR,
              COOL_GRAY,
            )}
            isAutoWidth
            isDisabled={
              formValue.annualYield === '' ||
              disableAnnualFieldUom ||
              isFallowSelected()
            }
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              formValue.annualYieldUom === '' ||
                formValue.annualYieldUom.startsWith(
                  MODAL_CONTENT.invalid_annual_yield_uom,
                ),
              MODAL_CONTENT.select_type,
              formValue.annualYieldUom,
            )}
            stateValue={formValue.annualYieldUom}
            setFormFieldValue={customFieldChangeHandler}
            field_key="annualYieldUom"
            setStateValue={() => {}}
            name="annualYieldUom"
            dropdownlist={
              cropTypeDropDown.find((item) => item.value === formValue.cropId)
                ?.label === MODAL_CONTENT.otherHarvested
                ? annualYieldDropdown
                : []
            }
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            height={MODAL_CONTENT.dropdownHeight}
            minWidth={MODAL_CONTENT.sixPointTwoFiveRem}
            width={MODAL_CONTENT.sevenPointFiveRem}
            fontSize={MODAL_CONTENT.dropdownFontSize}
            listItemFontSize={MODAL_CONTENT.dropdownFontSize}
            fieldName="annualYieldUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
            }}
            unsetTextFillColor
          />
          <CropValueInput>
            <CropValueDollar>{MODAL_CONTENT.crop_value_dollar}</CropValueDollar>
            <div>
              <InputField
                noGap
                optionalLabelStyle="0.2rem"
                label={MODAL_CONTENT.crop_value_input}
                value={formValue.cropValue}
                placeholder={MODAL_CONTENT.crop_value_placeholder}
                name="cropValue"
                onUpdate={(event) => {
                  handleTextFieldValueChange(
                    event,
                    event.target.value,
                    'cropValue',
                    true,
                  );
                }}
                minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
                width={MODAL_CONTENT.eightPointSevenFiveRem}
                cropValueLineHeight="1.225rem"
                isOptional
                optionalOpacity={1}
                predefinedUnit={
                  checkTernaryCondition(
                    formValue.annualYieldUom === '' ||
                      formValue.annualYieldUom.startsWith(
                        MODAL_CONTENT.invalid_annual_yield_uom,
                      ),
                    MODAL_CONTENT.crop_value_empty,
                    `${'per'} ${formValue.annualYieldUom}`,
                  ).split('/')[0]
                }
                hasPredefinedUnit
                isDisabled={isFallowSelected()}
              />
            </div>
          </CropValueInput>
        </FieldSectionWrapper>
        <FieldSectionWrapper columnGap="1.75rem">
          <CustomDatePicker {...datePlantedProps} />
          <CustomDatePicker {...dateHarvestedProps} />
        </FieldSectionWrapper>
        {errorState.cropHarvestDate && !isFallowSelected() && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.date_harvested_error}
          </TextWrapper>
        )}
        {datePlantedError && !isFallowSelected() && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.date_planted_year_error}
          </TextWrapper>
        )}
        {dateExceedError.planted && !isFallowSelected() && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.date_plant_error}
          </TextWrapper>
        )}
        {dateHarvestedError && !isFallowSelected() && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.date_harvested_year_error}
          </TextWrapper>
        )}
        {dateExceedError.harvested && !isFallowSelected() && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.date_harvest_error}
          </TextWrapper>
        )}
        <FieldSectionWrapper columnGap="0.4rem">
          <InputField
            noGap
            optionalLabelStyle="0.2rem"
            label={MODAL_CONTENT.crop_density}
            isOptional
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            value={formValue.plantingDensity}
            placeholder={MODAL_CONTENT.type_density}
            name="plantingDensity"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'plantingDensity',
                true,
              );
              if (event.target.value === '') {
                setFormValue((prev) => ({ ...prev, plantingDensityUom: '' }));
                setErrorState((prev) => ({ ...prev, cropDensity: false }));
              }
            }}
            optionalOpacity={1}
            isDisabled={isFallowSelected()}
          />
          <DropDown
            customPlaceholderColor={checkTernaryCondition(
              isFallowSelected(),
              DISABLED_TEXT_COLOR,
              COOL_GRAY,
            )}
            isDisabled={
              formValue.plantingDensity === 0 ||
              !formValue.plantingDensity ||
              isFallowSelected()
            }
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              formValue.plantingDensityUom === '' ||
                formValue.plantingDensityUom === null,
              MODAL_CONTENT.select_type,
              formValue.plantingDensityUom,
            )}
            stateValue={formValue.plantingDensityUom}
            setStateValue={() => {}}
            setFormFieldValue={customFieldChangeHandler}
            field_key="plantingDensityUom"
            name={MODAL_CONTENT.uom}
            dropdownlist={cropDensityDropdownList}
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            height={MODAL_CONTENT.dropdownHeight}
            minWidth={MODAL_CONTENT.sixPointTwoFiveRem}
            width={MODAL_CONTENT.sevenPointFiveRem}
            fontSize={MODAL_CONTENT.dropdownFontSize}
            listItemFontSize={MODAL_CONTENT.dropdownFontSize}
            fieldName="plantingDensityUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDensity: false }));
              }
            }}
            unsetTextFillColor
          />
        </FieldSectionWrapper>
        {errorState.cropDensity && !isFallowSelected() && (
          <TextWrapper {...ValidationErrorStyles}>
            {MODAL_CONTENT.crop_density_error}
          </TextWrapper>
        )}
        <FieldSectionWrapper columnGap="1.0rem">
          <InputField
            noGap
            optionalLabelStyle="0.2rem"
            label={MODAL_CONTENT.crop_depth}
            isOptional
            minWidth={MODAL_CONTENT.eightPointSevenFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            value={formValue.cropDepth}
            placeholder={MODAL_CONTENT.type_depth}
            name="cropDepth"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'cropDepth',
                true,
              );
              if (event.target.value === '') {
                setFormValue((prev) => ({ ...prev, cropDepthUom: '' }));
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
            }}
            optionalOpacity={1}
            isDisabled={isFallowSelected()}
          />
          <DropDown
            customPlaceholderColor={checkTernaryCondition(
              isFallowSelected(),
              DISABLED_TEXT_COLOR,
              COOL_GRAY,
            )}
            isDisabled={formValue.cropDepth?.length === 0 || isFallowSelected()}
            label={MODAL_CONTENT.uom}
            value={checkTernaryCondition(
              formValue.cropDepthUom === '' || formValue.cropDepthUom === null,
              MODAL_CONTENT.select_type,
              formValue.cropDepthUom,
            )}
            stateValue={formValue.cropDepthUom}
            setFormFieldValue={customFieldChangeHandler}
            field_key="cropDepthUom"
            setStateValue={() => {}}
            name="cropDepthUom"
            dropdownlist={cropDepthDropdownList}
            dropDownPlaceholder={MODAL_CONTENT.select_type}
            height={MODAL_CONTENT.dropdownHeight}
            minWidth={MODAL_CONTENT.sixPointTwoFiveRem}
            width={MODAL_CONTENT.sevenPointFiveRem}
            fontSize={MODAL_CONTENT.dropdownFontSize}
            listItemFontSize={MODAL_CONTENT.dropdownFontSize}
            fieldName="cropDepthUom"
            onUpdate={(event) => {
              if (event.target.value !== '') {
                setErrorState((prev) => ({ ...prev, cropDepth: false }));
              }
            }}
            unsetTextFillColor
          />
        </FieldSectionWrapper>
        {errorState.cropDepth && !isFallowSelected() && (
          <TextWrapper {...ValidationErrorStyles}>
            {MODAL_CONTENT.crop_depth_error}
          </TextWrapper>
        )}
        <TillageHeading>{MODAL_CONTENT.tillage_system}</TillageHeading>
        <DropDown
          customPlaceholderColor={COOL_GRAY}
          isAutoWidth
          dropDownMinWidth="13.75rem"
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.tillage_dropdown_heading}
          value={checkTernaryCondition(
            formValue.tillageSystemId === '' ||
              formValue.tillageSystemId === undefined,
            MODAL_CONTENT.select_tillage_system,
            formValue.tillageSystemId,
          )}
          stateValue={formValue.tillageSystemId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="tillageSystemId"
          name={MODAL_CONTENT.crop_type}
          dropdownlist={tillageSystem}
          dropDownPlaceholder={MODAL_CONTENT.select_tillage_system}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          error={errorState.tillageSystemId}
          errorMessage={MODAL_CONTENT.tillage_system_error}
          setModalErrorState={setModalErrorState}
          fieldName="tillageSystemId"
          showLabelAsValue={true}
          listItemFontSize={MODAL_CONTENT.dropdownFontSize}
        />
        {tillageSystem.find((item) => item.value === formValue.tillageSystemId)
          ?.label === MODAL_CONTENT.other && (
          <InputField
            label={MODAL_CONTENT.tillage_practice}
            value={formValue.tillageSystemOther}
            placeholder={MODAL_CONTENT.tillage_practice_placeholder}
            name="tillageSystemOther"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                event.target.value,
                'tillageSystemOther',
                false,
              );
              setErrorState({
                ...errorState,
                tillagePractice: event.target.value === '',
              });
            }}
            minWidth={MODAL_CONTENT.eighteenPointTwoFiveRem}
            width={MODAL_CONTENT.eighteenPointTwoFiveRem}
            primaryError={errorState.tillagePractice}
            primaryErrorMessage={MODAL_CONTENT.tillage_practice_error}
            testId={'tillage-practice-test-id'}
            rightSideLabelFormLabelMargin={0}
          />
        )}
        <DropDown
          customPlaceholderColor={COOL_GRAY}
          isAutoWidth
          dropDownMinWidth="13.75rem"
          hasNoBottomMargin={true}
          label={MODAL_CONTENT.disturbance_type}
          value={checkTernaryCondition(
            formValue.tillageSystemDisturbanceId === '',
            MODAL_CONTENT.select_type,
            formValue.tillageSystemDisturbanceId,
          )}
          stateValue={formValue.tillageSystemDisturbanceId}
          setFormFieldValue={customFieldChangeHandler}
          setStateValue={() => {}}
          field_key="tillageSystemDisturbanceId"
          name={MODAL_CONTENT.crop_type}
          dropdownlist={disturbanceTypeList}
          dropDownPlaceholder={MODAL_CONTENT.select_type}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          error={errorState.tillageSystemDisturbanceId}
          errorMessage={MODAL_CONTENT.disturbance_type_error}
          setModalErrorState={setModalErrorState}
          fieldName="tillageSystemDisturbanceId"
          showLabelAsValue={true}
          listItemFontSize={MODAL_CONTENT.dropdownFontSize}
        />
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...CropTypeModalDialogActionStyle }}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={() => {
            submitBtnHandler();
          }}>
          {MODAL_CONTENT.submit}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={() => {
            modalCloseHandler();
          }}>
          {MODAL_CONTENT.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CropTypeModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  tabWiseSubmitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
  setYearWiseCashCropList: PropTypes.func,
  yearWiseCashCropList: PropTypes.array,
  activityList: PropTypes.array,
  setActivityList: PropTypes.func,
  existingCropTypeIds: PropTypes.array,
  hasTestDefaultDate: PropTypes.bool,
};
export default CropTypeModal;
