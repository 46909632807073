import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { closeIconSx } from 'theme/GlobalStyles';
import CloseIcon from '@mui/icons-material/Close';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { MODAL_CONTENT } from './InitiateActivityReporting.content';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import {
  DialogActionStyle,
  DialogStyle,
  DialogTitleStyle,
  DialogContentStyle,
  CancelBtnStyle,
  RadioGroupStyle,
  SendRequestBtnStyle,
} from './InitiateActivityReportingModal.style';
import { PARTICIPANT_REPORT_FIRST_PART } from 'urls';
import axios from 'axios';
import { INITIATE_ACTIVITY_REPORTING_INITIALSTATE } from 'pages/Origination/Org_Project/Participants/Participants.content';
import Loader from 'components/Loader/Loader.react';
import { pathName } from 'Routes/routes.const';
import { ACCOUNT_STATUS_ACTIVATED } from 'utils/config';

const InitiateActivityReportingModal = ({
  modalState,
  modalDataSetter,
  navigateLink,
  navigateState,
  modalSubmitHandler = () => {},
  setSelectedActivityOption = () => {},
}) => {
  const [projectActivityMethod, setProjectActivityMethod] = useState('');
  const modalCloseHandler = () => {
    modalDataSetter(INITIATE_ACTIVITY_REPORTING_INITIALSTATE);
    setProjectActivityMethod('');
    setSelectedActivityOption('');
  };

  const [loading, setLoading] = useState(false);

  const reportOnBehalfHandler = () => {
    if (projectActivityMethod === MODAL_CONTENT.reportBtn) {
      const url = `${pathName.participant.participantActivity}?projectid=${modalState.projectId}&participantid=${modalState.participantId}&projectcycleid=${modalState.projectCycleId}`;
      window.open(url, '_blank');
    }
    modalCloseHandler();
  };
  const [accountStatus, setAccountStatus] = useState(null);
  useEffect(() => {
    if (modalState.flag && modalState && modalState.participantId) {
      axios
        .get(
          `${PARTICIPANT_REPORT_FIRST_PART}?participantId=${modalState.participantId}`,
        )
        .then((response) => {
          const accountStatus = response.data.accountStatus;
          setAccountStatus(accountStatus);
        });
    }
  }, [modalState.flag, modalState]);
  return (
    <Dialog
      data-testid="InitiateActivityReportingModal"
      open={modalState.flag}
      sx={DialogStyle}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
          modalCloseHandler();
        }
      }}>
      <DialogTitle sx={DialogTitleStyle}>
        {`${MODAL_CONTENT.heading}`}
        <CloseIcon sx={closeIconSx} onClick={modalCloseHandler}></CloseIcon>
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <Loader loading={loading} zIndex={99999} />
        <div>
          {`${MODAL_CONTENT.contentText}${modalState.participantName}`} ?
        </div>
        <RadioGroup
          sx={RadioGroupStyle}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={(e) => {
            setProjectActivityMethod(e.target.value);
            setSelectedActivityOption(e.target.value);
          }}
          name="radio-buttons-group">
          <FormControlLabel
            sx={RadioButtonStyle('0px')}
            value={MODAL_CONTENT.sendRequestBtn}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="enrollment-radio"
              />
            }
            label={MODAL_CONTENT.sendRequestBtn}
            disabled={
              accountStatus !== ACCOUNT_STATUS_ACTIVATED
            }></FormControlLabel>
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={MODAL_CONTENT.reportBtn}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="enrollment-radio"
              />
            }
            label={MODAL_CONTENT.reportBtn}
            disabled={false}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={() => {
            modalCloseHandler();
          }}
          sx={[CancelBtnStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancelBtn}
        </Button>
        <Button
          disabled={!projectActivityMethod}
          onClick={() => {
            modalSubmitHandler();
            reportOnBehalfHandler();
          }}
          sx={[SendRequestBtnStyle, primaryButtonStyle()]}>
          {MODAL_CONTENT.submitBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InitiateActivityReportingModal;
