import styled from 'styled-components';

export const RowCellWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
`;

export const CellValueWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
