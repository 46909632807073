import {
  CellValueWrapper,
  RowCellWrapper,
  TextWrapper,
} from './OriginationParticipantsList.style';

export const ORIGINATION_PARTICIPANTS_LIST_CONSTANTS = {
  page_title: 'Stakeholders',
  addButtonTitle: 'Add stakeholder',
  column: 'column',
  projects: 'projects',
  enterBaselineLabel: 'Establish Baseline',
  remove_subtitle: 'Are you sure you want to remove',
  remove_label: 'Select project to remove from',
  remove_placeholder: `Type and select project`,
  no_data_present_label: 'Looks like you haven’t added any stakeholders yet.',
  searchboxPlaceholder: 'Search by stakeholder name or email',
  filter_content_width: '75%',
  filter_content: 'We couldn’t find any results for the stakeholder',
  search_text_suffix: 'Please try another search.',
  no_data_heading_width: '37.65%',
  participant_owner: 'PARTICIPANT_OWNER',
};

export const listingParticipantsColumn = {
  participantId: {
    label: 'participantId',
    field: 'participantId',
  },
  participant: {
    label: 'Name',
    field: 'participantFullName',
  },
  typeOfStakeHolder: {
    label: 'Type',
    field: 'Participant',
  },
  addedOn: {
    label: 'Last updated',
    field: 'createdDate',
  },
};

export const SortColValues = {
  addedOn: listingParticipantsColumn.addedOn.field,
};

export const participantCell = (
  value1,
  value2,
  color1,
  color2,
  cellClickHandler,
) => {
  return (
    <RowCellWrapper
      direction={ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.column}
      onClick={cellClickHandler}>
      <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
        {value1}
      </CellValueWrapper>
      <TextWrapper fontSize="0.75rem" fontWeight={400} color={color2}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

export const formatParticipantType = (participantType) => {
  switch (participantType) {
    case 'PARTICIPANT_OWNER':
      return 'Participant owner';
    case 'PARTICIPANT_CONTRIBUTOR':
      return 'Participant contributor';
    default:
      return participantType;
  }
};
