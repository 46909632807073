import React, { useContext } from 'react';
import EnrollmentModal from 'pages/OriginationParticipantsList/EnrollmentModal/EnrollmentModal.react';
import { ReviewModal } from 'pages/Origination/Org_Project/Participants/ReviewTemplate';
import { ReopenCustomModal } from 'pages/Origination/Org_Project/Participants/ReOpenTemplate';
import { LoaderProvider } from 'contextAPI/loaderContext';
import { useActivityContext } from 'containers/ActivityListContainer';

export const EnrollmentActivityAction = () => {
  const {
    enrollModalData,
    setEnrollModalData,
    statusMappingList,
    setStatusMappingList,
    reviewEnrollmentProps,
    setReviewEnrollmentProps,
    reopenProps,
    setReopenProps,
    activityDataList,
  } = useActivityContext();
  if (statusMappingList.length === 0) return null;

  return (
    <>
      <EnrollmentModal
        modalData={enrollModalData}
        setModalData={setEnrollModalData}
        refreshHandler={() => {
          // fetchParticipantsList(!!participantSearchText);
          // fetchFilterData();
        }}
        statusMappingList={statusMappingList}
        data-testid="enrollment-modal"
      />
      <ReviewModal
        reviewEnrollmentProps={reviewEnrollmentProps}
        setReviewEnrollmentProps={setReviewEnrollmentProps}
        reopenProps={reopenProps}
        setReopenProps={setReopenProps}
        statusMappingList={statusMappingList}
        data-testid="review-modal"
        activityDataList={activityDataList}
      />
      <LoaderProvider>
        <ReopenCustomModal
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
          statusMappingList={statusMappingList}
          data-testid="reopen-modal"
        />
      </LoaderProvider>
    </>
  );
};
