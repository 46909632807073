import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import OptionsButton from 'components/OptionsButton/OptionsButton.react';
import {
  datagridSx,
  DataInputWrapper,
  TruncatedWrapper,
  DataGridWrapper,
  MainWrapper,
} from './DataInput.style';
import { CLIENT_ID } from '../../utils/config';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { FILE_SNAPSHOT, Run_CALCULATION } from 'urls';
import FileUploader from 'components/FileUploader/FileUploader.react';
import Status from 'components/Status/Status.react';
import { useInterval } from 'hooks/useInterval';
import { ENABLE_OPTIONS } from 'utils/config';
import { PAGE_CONTENT } from './DataInput.content';
import { covertDateToLocalTimeZone } from 'utils/helper';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';

const REPORT_REFRESH_INTERVAL = 10000;

const DataInput = () => {
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, SetTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [areaCode, setAreaCode] = useState();

  const ReplaceHandler = (name, code) => {
    SetTitle(`Replace ${name}`);
    setAreaCode(code);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useInterval(async () => {
    axios.get(FILE_SNAPSHOT).then((response) => {
      setTableRows((prev) => [
        ...prev.map((data) => {
          const item = response?.data.find(
            (o) => o.areaCode === data?.areaCode,
          );
          if (item && item.status !== data.status) {
            data = {
              ...data,
              fileName: data.fileName || '-',
              uploadedBy: data.uploadedBy || '-',
              status: item.status || '-',
              errorMessage: data.errorMessage,
            };
          }
          return data;
        }),
      ]);
    });
  }, REPORT_REFRESH_INTERVAL);

  const fetchFileSnapShot = () => {
    setLoading(true);
    axios
      .get(FILE_SNAPSHOT)
      .then((response) => {
        setTableRows(() => [
          ...response.data.map((data) => {
            return {
              areaCode: data.areaCode,
              areaName: data.areaName,
              fileName: data.fileName || '-',
              uploadedBy:
                (data?.uploadedUserLastName &&
                  data?.uploadedUserFirstName &&
                  `${data?.uploadedUserLastName}, ${data?.uploadedUserFirstName}`) ||
                '-',
              uploadedOn:
                data.uploadedOn !== null
                  ? covertDateToLocalTimeZone(data.uploadedOn).slice(0, 10)
                  : '-',
              status: data.status || '-',
              errorMessage: data.errorMessage,
              options: '',
            };
          }),
        ]);
      })
      .finally(() => setLoading(false));
  };

  const postRunCals = (areaCode) => {
    const postData = {
      areaCode: areaCode,
      clientId: CLIENT_ID,
    };

    axios.post(Run_CALCULATION, postData).then(() => {});
  };

  useEffect(() => {
    fetchFileSnapShot();
  }, []);

  const RefreshHandler = () => {
    fetchFileSnapShot();
  };

  const runCalcHandler = (areaCode) => {
    postRunCals(areaCode);
  };

  const columns = [
    {
      field: 'areaName',
      headerName: 'Area Name',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <TruncatedWrapper>{params.value}</TruncatedWrapper>
        </Tooltip>
      ),
    },
    {
      field: 'uploadedBy',
      headerName: 'Uploaded by',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'uploadedOn',
      headerName: 'Uploaded on',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Status
          status={params.row.status}
          onRunCalClick={() => runCalcHandler(params?.row?.areaCode)}
          error={params.row.errorMessage}
        />
      ),
    },
    {
      field: 'options',
      headerName: '',
      flex: 0.6,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div data-testid="button-options">
          <OptionsButton
            areaCode={params.row.areaCode}
            areaName={params.row.areaName}
            onClickHandler={ReplaceHandler}
            enabledOptions={ENABLE_OPTIONS}
          />
        </div>
      ),
    },
  ];

  return (
    <MainWrapper>
      <ListViewHeader title={PAGE_CONTENT.page_title} noButton />
      <DataInputWrapper className="curved-border">
        <DataGridWrapper>
          <DataGrid
            headerHeight={40}
            disableVirtualization={true}
            autoHeight
            disableSelectionOnClick
            loading={loading}
            sx={datagridSx}
            getRowId={(row) => row.areaCode}
            hideFooter={true}
            rows={tableRows}
            columns={columns}
          />
        </DataGridWrapper>
        <FileUploader
          isOpen={open}
          onConfirm={() => {}}
          onCancel={handleClose}
          title={title}
          areaCode={areaCode}
          refreshGrid={RefreshHandler}
        />
      </DataInputWrapper>
    </MainWrapper>
  );
};

export default DataInput;
