import { IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as HelpIcon } from '../../../assets/icons/Vector.svg';
import EnrollmentHelpModal from './EnrollmentHelpModal/EnrollmentHelpModal.react';
import { useContext, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OpenInNewTab } from './EnrollmentHelpModal/EnrollmentHelpModal.style';
import { HELP_BUTTON_CONTENT } from './EnrollmentHelpModal/EnrollmentHelpModal.content';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ADMIN,
  ORIGINATION_PM,
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  PARTICIPANT_MANAGER,
} from 'utils/config';
import { GET_USER_GUIDE_PDF_ADMIN, GET_USER_GUIDE_PDF_PARTICIPANT } from 'urls';
import axios from 'axios';

const ITEM_HEIGHT = 48;

const EnrollmentHelpButton = ({ section }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userRoles } = useContext(userAuthRolesContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openContactForm = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const openUserGuide = () => {
    let userGuideUrl = '';
    // check for user role
    if (
      userRoles.includes(ADMIN) ||
      userRoles.includes(ORIGINATION_PM) ||
      userRoles.includes(ORIGINATION_VIEWER) ||
      userRoles.includes(PARTICIPANT_MANAGER)
    ) {
      //CALL API FOR ADMIN PDF
      axios.get(GET_USER_GUIDE_PDF_ADMIN).then((response) => {
        userGuideUrl = response.data;
        window.open(userGuideUrl, '_blank');
      });
    } else if (
      userRoles.includes(PARTICIPANT_CONTRIBUTOR) ||
      userRoles.includes(PARTICIPANT)
    ) {
      //CALL API FOR PARTICIPANT PDF
      axios.get(GET_USER_GUIDE_PDF_PARTICIPANT).then((response) => {
        userGuideUrl = response.data;
        window.open(userGuideUrl, '_blank');
      });
    }
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton aria-label="help" onClick={handleClick}>
        <HelpIcon style={{ height: '1.5rem', width: '1.5rem' }} />
      </IconButton>
      <Menu
        id="contact-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        <MenuItem onClick={openUserGuide}>
          {HELP_BUTTON_CONTENT.user_guide}
          <OpenInNewTab>
            <OpenInNewIcon />
          </OpenInNewTab>
        </MenuItem>
        <MenuItem onClick={openContactForm}>
          {HELP_BUTTON_CONTENT.contact_support}
        </MenuItem>
      </Menu>
      <EnrollmentHelpModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        section={section}
      />
    </>
  );
};

export default EnrollmentHelpButton;
