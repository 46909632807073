import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { unitOfMeasurementOptions } from '..//NitrogenFertilizerModal/NitrogenFertilizerModal.content';
import CloseIcon from '@mui/icons-material/Close';
import {
  FlexWrapper,
  InfoSx,
  InfoTooltipWrapper,
  NpkFieldWrapper,
  PercentageWrapper,
  StyledDropdown,
  StyledInput,
  TooltipSx,
} from './NitrogentFertilizerModal.style';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  AddOptionWrapper,
  TextWrapper,
  closeIconSx,
} from '../Modals.style';
import {
  MODAL_CONTENT,
  formFieldNames,
  NitrogenFertilizerFormInitialData,
  fertilizerTypeOptions,
  hasInhibitorList,
  ModalInitialErrorState,
  isValueNullorUndefined,
} from './NitrogenFertilizerModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { addZeroBeforeDot, checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ReactComponent as Calendericon } from '../../../../../../assets/icons/calenderIcon.svg';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'hooks/useForm';
import AddIcon from '@mui/icons-material/Add';
import {
  COOL_GRAY,
  DARK_GREEN,
  GLOBAL_BLUE,
  RED,
  TEXT_HOVER_COLOR,
} from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import DateItemComp from './DateItem.react';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { DATE_FORMAT } from 'utils/config';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';

const NitrogentFertilizerModal = ({
  modalOpen,
  setModalOpen,
  submitHandler,
  totalFieldArea,
  hasTestDefaultDate,
  existingFertilizerTypeIds,
  isInTesting,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datesAppliedArray, setDateAppliedArray] = useState([null]);
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState('');
  const [dateError, setDateError] = useState({ flag: false, indexArray: [] });
  const [dateRequiedList, setDateRequiedList] = useState([]);
  const [errorState, setErrorState] = useState(ModalInitialErrorState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const [onHoverDateApplied, setOnHoverDateApplied] = useState(false);
  const zeroString = '0.00';
  const [isnpkDisabled, setIsnpkDisabled] = useState({
    nPercentage: false,
    pPercentage: false,
    kPercentage: false,
  });
  const [previousFertilizerCategory, setPreviousFertilizerCategory] =
    useState('');
  const [previousNPKValues, setPreviousNPKValues] = useState({
    nPercentage: '',
    pPercentage: '',
    kPercentage: '',
  });
  const [duplicatedDate, setDuplicateDate] = useState({
    flag: false,
    dupIndexArray: [],
  });
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(NitrogenFertilizerFormInitialData);
    setErrorState(ModalInitialErrorState);
    setDateAppliedArray([null]);
    setDateError({ flag: false, indexArray: [] });
    setPreviousFertilizerCategory('');
    setPreviousNPKValues({
      nPercentage: '',
      pPercentage: '',
      kPercentage: '',
    });
    setDateRequiedList([]);
    setDuplicateDate({ flag: false, dupIndexArray: [] });
  };
  const selectUom = () => {
    if (formValue.fertilizerType === 21) {
      if (formValue.manureType === 1 || formValue.manureType === 2) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.gals_acre,
          )?.label,
        );
      } else if (formValue.manureType === 3) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.lbs_acre,
          )?.label,
        );
      }
    } else if (formValue.fertilizerType === '') {
      setSelectedUnitOfMeasure('');
    } else {
      if (
        fertilizerTypeDropDown
          .find((data) => data.value === formValue.fertilizerType)
          ?.form?.includes(MODAL_CONTENT.liquid_label)
      ) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.gals_acre,
          )?.label,
        );
      } else if (
        fertilizerTypeDropDown
          .find((data) => data.value === formValue.fertilizerType)
          ?.form?.includes(MODAL_CONTENT.solid_label)
      ) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.lbs_acre,
          )?.label,
        );
      }
    }
  };

  const checkApplicationRateValues = (val) => {
    return val === '' || zeroString.indexOf(val) !== -1;
  };
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    NitrogenFertilizerFormInitialData,
  );
  const {
    fertilizerTypeDropDown,
    animalTypeDropdown,
    manureTypeDropdown,
    fertilizerApplicationMethod,
  } = useContext(baselineDropdownContext);
  const mineralPercentageArray = [
    {
      label: MODAL_CONTENT.n_percentage,
      name: formFieldNames.nPercentage,
      value: formValue.nPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error: errorState.nPercentage,
      error_message: MODAL_CONTENT.n_percentage_error,
      disabled: isnpkDisabled.nPercentage,
    },
    {
      label: MODAL_CONTENT.p_percentage,
      name: formFieldNames.pPercentage,
      value: formValue.pPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error: errorState.pPercentage,
      error_message: MODAL_CONTENT.p_percentage_error,
      disabled: isnpkDisabled.pPercentage,
    },
    {
      label: MODAL_CONTENT.k_percentage,
      name: formFieldNames.kPercentage,
      value: formValue.kPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error:
        formValue.fertilizerCategory === fertilizerTypeOptions[1].label &&
        errorState.kPercentage,
      error_message: MODAL_CONTENT.k_percentage_error,
      disabled: isnpkDisabled.kPercentage,
    },
  ];

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      setFormValue(
        checkTernaryCondition(
          modalData,
          modalData,
          NitrogenFertilizerFormInitialData,
        ),
      );
    }
  }, [modalOpen]);
  const fertilizertypeDropdownProps = {
    label: MODAL_CONTENT.fertilizer_type_label,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.fertilizerType === '',
      MODAL_CONTENT.fertilizer_type_placeholder,
      formValue.fertilizerType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.fertilizerType,
    ariaLabel: MODAL_CONTENT.fertilizer_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.fertilizer_type_placeholder,
    error: errorState.fertilizerType,
    errorMessage: MODAL_CONTENT.fertilizer_type_error,
    setModalErrorState: setErrorState,
    dropdownlist: fertilizerTypeDropDown?.filter(
      (crop) =>
        !existingFertilizerTypeIds
          ?.filter((item) => item !== formValue.fertilizerType)
          .includes(crop.value),
    ),
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.fertilizerType,
    setStateValue: () => {},
    showLabelAsValue: true,
    listItemFontSize: '0.875rem',
  };
  const animalTypeDropdownProps = {
    label: MODAL_CONTENT.animal_type_label,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.animalType === '',
      MODAL_CONTENT.animal_type_placeholder,
      formValue.animalType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.animalType,
    ariaLabel: MODAL_CONTENT.animal_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.animal_type_placeholder,
    error: errorState.animalType,
    errorMessage: MODAL_CONTENT.animal_type_error,
    setModalErrorState: setErrorState,
    dropdownlist: animalTypeDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.animalType,
    setStateValue: () => {},
    showLabelAsValue: true,
    listItemFontSize: '0.875rem',
  };
  const manureTypeDropdownProps = {
    label: MODAL_CONTENT.manure_type_label,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.manureType === '',
      MODAL_CONTENT.manure_type_placeholder,
      formValue.manureType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.manureType,
    ariaLabel: MODAL_CONTENT.manure_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.manure_type_placeholder,
    error: errorState.manureType,
    errorMessage: MODAL_CONTENT.fertilizer_type_error,
    setModalErrorState: setErrorState,
    dropdownlist: manureTypeDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.manureType,
    setStateValue: () => {},
    showLabelAsValue: true,
    listItemFontSize: '0.875rem',
  };

  const unitOfMeasurementDropdownProps = {
    label: MODAL_CONTENT.unit_of_measure_label,
    value: checkTernaryCondition(
      selectedUnitOfMeasure === '',
      MODAL_CONTENT.unit_of_measure_placeholder,
      selectedUnitOfMeasure,
    ),
    isDisabled: true,
    width: '17.875rem',
    height: '2rem',
    fontSize: '14px',
    name: formFieldNames.unitOfMeasure,
    ariaLabel: MODAL_CONTENT.unit_of_measure_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.unit_of_measure_placeholder,
    error: errorState.unitOfMeasure,
    errorMessage: MODAL_CONTENT.uom_error,
    dropdownlist: unitOfMeasurementOptions,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.unitOfMeasure,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setErrorState,
    fieldName: MODAL_CONTENT.uom_field,
    hasNoBottomMargin: true,
    labelMarginTop: '0rem',
    labelMarginBottom: '0.25rem',
  };

  const applicationMethodDropdownProps = {
    label: MODAL_CONTENT.application_method_label,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.applicationMethod === '',
      MODAL_CONTENT.application_method_placeholder,
      formValue.applicationMethod,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.applicationMethod,
    ariaLabel: MODAL_CONTENT.application_method_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.application_method_placeholder,
    error: errorState.applicationMethod,
    errorMessage: MODAL_CONTENT.application_method_error,
    setModalErrorState: setErrorState,
    dropdownlist: fertilizerApplicationMethod,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.applicationMethod,
    setStateValue: () => {},
    showLabelAsValue: true,
    minWidth: '15rem',
    listItemFontSize: '0.875rem',
  };
  const dateAppliedPickerProps = (id, item) => {
    return {
      label: MODAL_CONTENT.date_applied_label,
      dateTextColor: COOL_GRAY,
      calendarOpenDate: checkTernaryCondition(
        hasTestDefaultDate,
        new Date(),
        `${modalOpen.data?.year}-01-01`,
      ),
      value: checkTernaryCondition(
        formValue.datesApplied[id] === '' ||
          formValue.datesApplied[id] === undefined,
        MODAL_CONTENT.date_applied_placeholder,
        formValue.datesApplied[id],
      ),
      inputFormat: DATE_FORMAT,
      onUpdate: (newValue) => {
        let newDateArray = [...datesAppliedArray];
        let newValueArray = formValue.datesApplied;
        newValueArray[id] = newValue?.toDate();
        newDateArray[id] = newValueArray[id];
        customFieldChangeHandler(formFieldNames.datesApplied, newValueArray);
        setDateAppliedArray(newDateArray);
        setDateRequiedList(dateRequiedList.filter((item) => item !== id));
        const duplicatedDateIndex = formValue.datesApplied.findIndex(
          (tillageDate, i) => {
            const formattedDate = moment(newValueArray[id]).format(
              'YYYY-MM-DD',
            );
            const formattedTillageDate =
              moment(tillageDate).format('YYYY-MM-DD');
            return (
              i !== id &&
              formattedTillageDate !== MODAL_CONTENT.invalid_date &&
              formattedDate !== MODAL_CONTENT.invalid_date &&
              formattedDate === formattedTillageDate
            );
          },
        );
        if (duplicatedDateIndex !== -1) {
          setDuplicateDate({
            flag: true,
            dupIndexArray: [...duplicatedDate.dupIndexArray, id],
          });
        }
        if (
          duplicatedDateIndex === -1 &&
          duplicatedDate.dupIndexArray.includes(id)
        ) {
          setDuplicateDate({
            flag:
              duplicatedDate.dupIndexArray.filter((item) => item !== id)
                .length > 0,
            dupIndexArray: duplicatedDate.dupIndexArray.filter(
              (item) => item !== id,
            ),
          });
        }
        let indexesWithValue = formValue.datesApplied.reduce(function (
          a,
          e,
          i,
        ) {
          if (
            moment(e).format('YYYY-MM-DD') === moment(item).format('YYYY-MM-DD')
          )
            a.push(i);
          return a;
        },
        []);
        if (indexesWithValue.length >= 2) {
          indexesWithValue = [...indexesWithValue].filter(
            (item) => item !== id,
          );
        }
        if (
          duplicatedDateIndex === -1 &&
          duplicatedDate.dupIndexArray.includes(indexesWithValue[0])
        ) {
          setDuplicateDate({
            flag:
              duplicatedDate.dupIndexArray.filter(
                (item) => item !== indexesWithValue[0],
              ).length > 0,
            dupIndexArray: duplicatedDate.dupIndexArray.filter(
              (item) => item !== indexesWithValue[0],
            ),
          });
        }
      },
      icon: Calendericon,
      open: datePickerOpen.id === id && datePickerOpen.isOpen,
      onClose: () => setDatePickerOpen(false),
      placeholder: MODAL_CONTENT.date_applied_placeholder,
      width: '8.3rem',
      height: '2rem',
      onClickHandler: () => setDatePickerOpen({ id: id, isOpen: true }),
      error:
        dateError.indexArray.includes(id) ||
        dateRequiedList.includes(id) ||
        duplicatedDate.dupIndexArray.includes(id),
      errorMessage: '',
      setModalErrorState: setErrorState,
      isNotOutlined: false,
      minWidth: '0rem',
      customSelectedDateColor: DARK_GREEN,
      dateList: datesAppliedArray,
      listItemFontSize: '0.875rem',
    };
  };
  const submitBtnHandler = () => {
    const fertilizerTypeVal = formValue.fertilizerCategory;
    let newDateRequiredList = [];
    datesAppliedArray.forEach((item, index) => {
      if (item === null) {
        newDateRequiredList.push(index);
      }
    });
    setDateRequiedList(newDateRequiredList);
    let hasError = false;
    const errorValues = {
      fertilizerType: formValue.fertilizerType === '',
      unitOfMeasure: selectedUnitOfMeasure === '',
      animalType:
        formValue.fertilizerType ===
          fertilizerTypeDropDown.find(
            (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
          )?.value && formValue.animalType === '',
      manureType:
        formValue.fertilizerType ===
          fertilizerTypeDropDown.find(
            (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
          )?.value && formValue.manureType === '',
      applicationMethod: formValue.applicationMethod === '',
      applicationRate: checkApplicationRateValues(formValue.applicationRate),
      datesApplied: datesAppliedArray.includes(null) || dateError.flag,
      hasInhibitor: formValue.hasInhibitor === '',
      nPercentage:
        formValue.nPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
      pPercentage:
        formValue.pPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
      kPercentage:
        formValue.kPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        ModalInitialErrorState,
        errorValues,
      ),
    );
    if (
      formValue.fertilizerType ===
      fertilizerTypeDropDown.find(
        (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
      )?.value
    ) {
      hasError = Object.values(errorValues).slice(0, 14).includes(true);
    } else {
      switch (fertilizerTypeVal) {
        case fertilizerTypeOptions[0].label[0]:
        case fertilizerTypeOptions[0].label[1]:
          hasError = Object.values(errorValues).slice(0, 14).includes(true);
          break;
        case fertilizerTypeOptions[1].label:
          hasError =
            Object.values(errorValues).slice(0, 10).includes(true) ||
            Object.values(errorValues).slice(11).includes(true);
          break;
        default:
          if (Object.values(errorValues).slice(0, 10).includes(true)) {
            hasError = true;
          }
      }
    }
    const isAnyDateInvalid = datesAppliedArray
      .map((item) => moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    const notHasOverallError =
      hasError === false &&
      !dateError.flag &&
      !isAnyDateInvalid &&
      !duplicatedDate.flag;
    handleMainSubmit(notHasOverallError, noDataOptionSelected);
  };

  const handleMainSubmit = (notHasOverallError, noDataOptionSelected) => {
    if (notHasOverallError || noDataOptionSelected) {
      submitHandler(
        { ...formValue, unitOfMeasure: selectedUnitOfMeasure },
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.fertilizer_list,
      );
      modalCloseHandler();
    }
  };
  const setDateErrorValue = () => {
    if (formValue.datesApplied.length > 0) {
      let count = 0;
      let indexArray = [];
      formValue.datesApplied.forEach((date, index) => {
        if (
          (new Date(date) > new Date(`${+modalOpen.data?.year}-12-31`) ||
            new Date(date) < new Date(`${modalOpen.data?.year - 2}-12-31`)) &&
          date !== null
        ) {
          count = count + 1;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setDateError({ flag: true, indexArray: indexArray });
      } else {
        setDateError({ flag: false, indexArray: indexArray });
      }
    }
  };

  useEffect(() => {
    const fertilizerDetails = fertilizerTypeDropDown.find(
      (item) => item.value === formValue.fertilizerType,
    );
    setPreviousFertilizerCategory(fertilizerDetails?.category);
    setPreviousNPKValues({
      nPercentage: fertilizerDetails?.nPercentage,
      pPercentage: fertilizerDetails?.pPercentage,
      kPercentage: fertilizerDetails?.kPercentage,
    });
    const category = fertilizerDetails?.category;
    let nPercentage = '';
    let pPercentage = '';
    let kPercentage = '';
    if (category === MODAL_CONTENT.organic_label) {
      if (previousFertilizerCategory !== MODAL_CONTENT.synthetic) {
        nPercentage = checkTernaryCondition(
          modalOpen?.id !== 0,
          formValue.nPercentage,
          '',
        );
        pPercentage = checkTernaryCondition(
          modalOpen?.id !== 0,
          formValue.pPercentage,
          '',
        );
        kPercentage = checkTernaryCondition(
          modalOpen?.id !== 0,
          formValue.kPercentage,
          '',
        );
      }
    } else {
      nPercentage = checkTernaryCondition(
        fertilizerDetails?.nPercentage !== null,
        fertilizerDetails?.nPercentage,
        checkTernaryCondition(
          modalOpen?.id !== 0 &&
            isValueNullorUndefined(previousNPKValues.nPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.nPercentage,
          '',
        ),
      );
      pPercentage = checkTernaryCondition(
        fertilizerDetails?.pPercentage !== null,
        fertilizerDetails?.pPercentage,
        checkTernaryCondition(
          modalOpen?.id !== 0 &&
            isValueNullorUndefined(previousNPKValues.pPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.pPercentage,
          '',
        ),
      );
      kPercentage = checkTernaryCondition(
        fertilizerDetails?.kPercentage !== null,
        fertilizerDetails?.kPercentage,
        checkTernaryCondition(
          modalOpen?.id !== 0 &&
            isValueNullorUndefined(previousNPKValues.kPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.kPercentage,
          '',
        ),
      );
    }

    setIsnpkDisabled({
      nPercentage: fertilizerDetails?.nPercentage !== null,
      pPercentage: fertilizerDetails?.pPercentage !== null,
      kPercentage: fertilizerDetails?.kPercentage !== null,
    });

    setFormValue({
      ...formValue,
      fertilizerCategory: category,
      nPercentage: nPercentage,
      pPercentage: pPercentage,
      kPercentage: kPercentage,
    });
  }, [formValue.fertilizerType]);
  useEffect(() => {
    setDateErrorValue();
  }, [datesAppliedArray]);

  useEffect(() => {
    selectUom();
  }, [formValue.fertilizerType, formValue.manureType]);

  useEffect(() => {
    if (fertilizerTypeDropDown?.length > 0) {
      const newFertilizerTypeId = formValue.fertilizerType;
      const value = fertilizerTypeDropDown.find(
        (fertilizerType) => fertilizerType?.value === newFertilizerTypeId,
      )?.label;
      if (value === MODAL_CONTENT.noneOption) {
        setFormValue({
          ...NitrogenFertilizerFormInitialData,
          fertilizerType: newFertilizerTypeId,
        });
        setNoDataOptionSelected(true);
        setErrorState(ModalInitialErrorState);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.fertilizerType, fertilizerTypeDropDown]);

  useEffect(() => {
    const isDateInvalid = datesAppliedArray
      .map((item) => item !== null && moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    setIsSubmitDisabled(
      Object.values(errorState).includes(true) ||
        dateError.flag ||
        isDateInvalid ||
        duplicatedDate.flag,
    );
  }, [errorState, dateError, datesAppliedArray, duplicatedDate]);
  useEffect(() => {
    if (dateRequiedList.length === 0) {
      setErrorState({ ...errorState, datesApplied: false });
    }
  }, [dateRequiedList]);
  useEffect(() => {
    if (modalOpen.flag) {
      if (modalOpen.data?.formData?.length > 0) {
        if (modalOpen.data?.formData[0]?.datesApplied.length === 0) {
          setDateAppliedArray([null]);
        } else {
          setDateAppliedArray(modalOpen.data?.formData[0]?.datesApplied);
        }
      }
      if (modalOpen.data?.formData?.length === 0) {
        customFieldChangeHandler(formFieldNames.datesApplied, [null]);
      }
    }
  }, [modalOpen.flag]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <FieldSectionWrapper columnGap="1.5rem">
          <DropDown {...fertilizertypeDropdownProps} />
        </FieldSectionWrapper>
        {!noDataOptionSelected && (
          <>
            {fertilizerTypeDropDown?.filter(
              (item) => item?.value === formValue?.fertilizerType,
            )[0]?.label === MODAL_CONTENT.manure_label && (
              <>
                <FieldSectionWrapper columnGap="1.5rem">
                  <DropDown {...animalTypeDropdownProps} />
                </FieldSectionWrapper>
                <FieldSectionWrapper columnGap="1.5rem">
                  <DropDown {...manureTypeDropdownProps} />
                </FieldSectionWrapper>
              </>
            )}
            <FieldSectionWrapper direction="column" marginBottom="0rem">
              {datesAppliedArray?.map((item, index) => {
                const keyVal = index;
                return (
                  <DateItemComp
                    key={keyVal}
                    keyVal={keyVal}
                    dateAppliedPickerProps={(id) =>
                      dateAppliedPickerProps(id, item)
                    }
                    datesAppliedArray={datesAppliedArray}
                    setDateAppliedArray={setDateAppliedArray}
                    item={item}
                    index={index}
                    customFieldChangeHandler={customFieldChangeHandler}
                    dateRequiedList={dateRequiedList}
                    setDateRequiedList={setDateRequiedList}
                    isInTesting={isInTesting}
                    marginBottomForDatePicker={'0.25rem'}
                    setDuplicateDate={setDuplicateDate}
                    duplicatedDate={duplicatedDate}
                  />
                );
              })}
              <AddOptionWrapper
                onMouseEnter={() => {
                  setOnHoverDateApplied(true);
                }}
                onMouseLeave={() => {
                  setOnHoverDateApplied(false);
                }}
                onClick={() => {
                  setDateAppliedArray([...datesAppliedArray, null]);
                  customFieldChangeHandler([...formValue.datesApplied, null]);
                }}>
                <TextWrapper
                  color={checkTernaryCondition(
                    onHoverDateApplied,
                    TEXT_HOVER_COLOR,
                    GLOBAL_BLUE,
                  )}
                  fontSize="0.875rem"
                  gap="0.25rem">
                  <AddIcon
                    fontSize="small"
                    gap="0.25rem"
                    sx={{
                      '&:hover': {
                        fill: onHoverDateApplied && TEXT_HOVER_COLOR,
                      },
                    }}
                  />
                  {MODAL_CONTENT.date_applied}
                </TextWrapper>
              </AddOptionWrapper>
              {errorState.datesApplied && (
                <TextWrapper color={RED} fontSize="12px">
                  {MODAL_CONTENT.date_error}
                </TextWrapper>
              )}
              {dateError.flag && (
                <TextWrapper color={RED} fontSize="12px">
                  {MODAL_CONTENT.date_error_label}
                </TextWrapper>
              )}
              {duplicatedDate.flag && (
                <TextWrapper color={RED} position="relative" fontSize="12px">
                  {MODAL_CONTENT.duplicate_date_error}
                </TextWrapper>
              )}
            </FieldSectionWrapper>
            <RadioButtonsGroup
              list={hasInhibitorList}
              label={MODAL_CONTENT.contains_inhibitor_label}
              direction="row"
              labelFontSize="14px"
              handleChange={(event) => {
                customFieldChangeHandler(
                  formFieldNames.hasInhibitor,
                  event.target.value,
                );
              }}
              marginBottom="0.5rem"
              checkedIsDarkGreen
              selectedValue={formValue.hasInhibitor}
              primaryError={errorState.hasInhibitor}
              primaryErrorMessage={MODAL_CONTENT.inhibitor_error}
            />
            <FieldSectionWrapper columnGap="1.5rem">
              <DropDown {...applicationMethodDropdownProps} />
            </FieldSectionWrapper>
            {errorState.applicationTiming && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.application_timing_error}
              </TextWrapper>
            )}
            <FieldSectionWrapper columnGap="0.5rem">
              <InputField
                required
                inputTextFieldColor={COOL_GRAY}
                label={MODAL_CONTENT.application_rate_label}
                name={formFieldNames.applicationRate}
                value={formValue.applicationRate}
                onUpdate={(e) => {
                  if (
                    e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                    e.target.value === '' ||
                    zeroString.indexOf(e.target.value) !== -1
                  ) {
                    customFieldChangeHandler(
                      formFieldNames.applicationRate,
                      addZeroBeforeDot(e.target.value),
                    );
                    setErrorState({
                      ...errorState,
                      applicationRate: checkApplicationRateValues(
                        e.target.value,
                      ),
                    });
                  }
                }}
                placeholder={MODAL_CONTENT.application_rate_placeholder}
                primaryError={errorState.applicationRate}
                primaryErrorMessage={MODAL_CONTENT.annual_rate_error}
                maxLength={100}
                minWidth="10rem"
                width="7rem"
              />
              <StyledDropdown>
                <DropDown {...unitOfMeasurementDropdownProps} />
              </StyledDropdown>
            </FieldSectionWrapper>

            {formValue.fertilizerType !== '' &&
              formValue.fertilizerType !== 21 && (
                <div style={{ marginTop: '0.875rem' }}>
                  <TextWrapper fontSize="14px">
                    {MODAL_CONTENT.npk_label}
                    {(formValue.fertilizerType === 5 ||
                      formValue.fertilizerType === 10) && (
                      <LightTooltip
                        placement="bottom-end"
                        arrow
                        sx={TooltipSx}
                        title={
                          <InfoTooltipWrapper>
                            {MODAL_CONTENT.tooltip_content}
                          </InfoTooltipWrapper>
                        }>
                        <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
                      </LightTooltip>
                    )}
                  </TextWrapper>
                </div>
              )}
            <FlexWrapper gap={'0.5rem'}>
              <FlexWrapper>
                {formValue.fertilizerCategory === MODAL_CONTENT.synthetic &&
                  mineralPercentageArray.slice(0, 3).map((item, index) => {
                    const keyVal = index;
                    return (
                      <FieldSectionWrapper columnGap="1.5rem" key={keyVal}>
                        <NpkFieldWrapper>
                          <StyledInput>
                            <InputField
                              inputTextFieldColor={COOL_GRAY}
                              width="70%"
                              required
                              label={item.label}
                              labelOnLeftSide={true}
                              name={item.name}
                              value={item.value}
                              isDisabled={item.disabled}
                              onUpdate={(e) => {
                                if (
                                  (e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                                    e.target.value === '') &&
                                  e.target.value !== '.'
                                ) {
                                  const newErrorState = { ...errorState };
                                  newErrorState[item.name] =
                                    e.target.value === '';
                                  const value = checkTernaryCondition(
                                    e.target.value[0] === '.',
                                    `0${e.target.value}`,
                                    e.target.value,
                                  );
                                  customFieldChangeHandler(item.name, value);
                                  setErrorState(newErrorState);
                                }
                              }}
                              placeholder={selectedUnitOfMeasure.slice(0, 3)}
                              primaryError={item.error}
                              primaryErrorMessage={''}
                              maxLength={100}
                              minWidth="0rem"
                            />
                          </StyledInput>
                          <PercentageWrapper>
                            {selectedUnitOfMeasure}
                          </PercentageWrapper>
                        </NpkFieldWrapper>
                      </FieldSectionWrapper>
                    );
                  })}
              </FlexWrapper>
            </FlexWrapper>
            {formValue.fertilizerCategory === MODAL_CONTENT.synthetic &&
              mineralPercentageArray.slice(0, 3).map((item, index) => {
                const keyVal = index;
                if (item.error) {
                  return (
                    <TextWrapper key={keyVal} color={RED} fontSize="12px">
                      {item.error_message}
                    </TextWrapper>
                  );
                }
              })}
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={submitBtnHandler}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NitrogentFertilizerModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
  hasTestDefaultDate: PropTypes.bool,
  existingFertilizerTypeIds: PropTypes.array,
};
export default NitrogentFertilizerModal;
