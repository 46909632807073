// RecruitmentMenuItems.js
import { useActivityContext } from 'containers/ActivityListContainer';
import {
  AddContractButton,
  EditContractButton,
  RequestAdditionalEnrollment,
  ViewContractButton,
} from '../ActivityMenuButton/ContractButtons.react';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { PAGE_CONTENT } from 'pages/Origination/Org_Project/Participants/ParticipantActivity/ParticipantActivity.content';

const RecruitmentMenuItems = () => {
  const { status, activitySubType, area } = useActivityContext();

  if (activitySubType === REPORTING_STATUS.not_started) {
    return (
      <>{status === REPORTING_STATUS.not_started && <AddContractButton />}</>
    );
  }

  return (
    <>
      {status === REPORTING_STATUS.contract_added &&
        area &&
        area !== PAGE_CONTENT.contract_acreage && <EditContractButton />}
      {status === REPORTING_STATUS.contract_added && <ViewContractButton />}
      {status === REPORTING_STATUS.contract_added && (
        <RequestAdditionalEnrollment />
      )}
    </>
  );
};

export default RecruitmentMenuItems;
