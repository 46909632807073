import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  YesOrNoOptions,
  YesNoOrIdontknowOptions,
  PAGE_CONTENT,
  formModal,
  errorInitialState,
  FALSE,
  checkFieldIsNotEmpty,
  regexMatchForAcres,
  validateFormFields,
  convertYesNoIntoBoolean,
  formFieldsNames,
  stringToArray,
  convertBooleanToYesNo,
  csafPracticesOptions,
  livestockTypeInitialState,
} from './ParticipantGeneralFarmInfo.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  FormLabels,
  deleteFmsIconSx,
} from 'components/FormComponents/FormStyles';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useForm } from 'hooks/useForm';
import {
  AcerageWrapper,
  ErrorWrapper,
  FlexWrapper,
  FormSection,
  IconWrapper,
  InputWithDeleteIconWrapper,
  LivestockTypeLabelWrapper,
  LivestockTypeWrapper,
  MainWrapper,
  SubsectionWrapper,
  TextWrapper,
  USDAStatusLabelSx,
  disableFormStyle,
  fundingSx,
  outlinedDropdownInputSx,
  radioBtnPadding,
  OwnerDisclaimer,
  OwnerDisclaimerText,
  closeIconSx,
  CloseLink,
} from './ParticipantGeneralFarmInfo.style';
import {
  checkTernaryCondition,
  convertSquareMetersToAcres,
  ternaryBooleanHandler,
  addZeroBeforeDot,
} from 'utils/helper';
import { useNavigate } from 'react-router';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import axios from 'axios';
import {
  ADD_PARTICIPANT,
  EDIT_GENERAL_FARM_INFO,
  FETCH_GENERAL_FARM_INFO,
  FETCH_LIVESTOCK_TYPE_DROPDOWN,
  PARTICIPANT_GENERAL_FARM_INFO,
} from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { COOL_GRAY, DARK_GREEN, WHITE } from 'theme/GlobalColors';
import { getValueOrDefault } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { pathName } from 'Routes/routes.const';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import UserNavigationButtons from '../components/UserNavigationButtons/UserNavigationButtons.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  checkIsEnrollmentCompleted,
  handleNextInProgrssSwitcher,
} from '../ParticipantDataCollection.content';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { STYLE_CONSTANTS } from '../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from '../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { CSAFToolTipSx } from 'theme/GlobalStyles';
import CloseIcon from '@mui/icons-material/Close';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';

const fmsObj = {
  id: uniqueId(),
  fmsName: '',
  fmsError: FALSE,
};

const ParticipantGeneralFarmInfo = () => {
  const {
    setFarmInfo,
    setOpenMapView,
    setExpandedFarmId,
    farmLoading,
    currentFarmId,
    setCurrentFarmId,
    currentFarmInfo,
    setCurrentFarmInfo,
    /* fetchedFarmValues, */
    saveAndCloseClicked,
    setSaveAndCloseClicked,
    farmInfo,
    setSelectedFieldId,
    setShowFieldInfoForm,
    farmInfoCurrentTab,
    setFarmInfoCurrentTab,
    setFetchedFarmValues,
    setFieldAddTypeModalOpen,
    addFarmClicked,
    setAddFarmClicked,
    isTabSwitched,
    setCurrentFarmLocation,
    isFormUnmounted,
    setIsFormUnmounted,
    setSubmitData,
    submitData,
    setRightSectionId,
    setUserActivityDetected,
    setModalData,
    participantProfileStatus,
    enrolledFarmIds,
    isFarmCsafUpdatedBySystem,
    setIsFarmCsafUpdatedBySystem,
    isFieldSubmitted,
    setIsFieldSubmitted,
    setHasNotApprovedFarmOrFields,
    fieldPolygons,
    fetchParticipantData,
    isParticipantContributor,
    statusList,
  } = useContext(participantInfoContext);
  const { isEmailLinkClicked, userRoles } = useContext(userAuthRolesContext);
  const currentFarmName = farmInfo.find(
    (farm) => farm.id === currentFarmId,
  )?.value;
  const {
    formValue: generalFarmInfoForm,
    customFieldChangeHandler,
    setFormValue,
  } = useForm({
    ...formModal,
    farmName: checkTernaryCondition(
      currentFarmId === -1,
      'Farm 1',
      currentFarmName,
    ),
  });
  const [submitLoading, setSubmitLoading] = useState(FALSE);
  const [errorState, setErrorState] = useState(errorInitialState);
  const [fmsInfo, setFmsInfo] = useState([fmsObj]);
  const [isSubmit, setIsSubmit] = useState(FALSE);
  const [agronomistOption, setAgronomistOption] = useState('');
  const [fmsOption, setFmsOption] = useState('');
  const [csafExperienceOption, setCsafExperienceOption] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [saveAndContinueClicked, setSaveAndContinueClicked] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectIdValue = searchParams.get('projectid');
  const participantIdValue = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const [usedFarmId, setUsedFarmId] = useState(0);
  const [isEditable, setIsEditable] = useState(true);
  const [livestockTypes, setLivestockTypes] = useState(
    livestockTypeInitialState,
  );
  const [livestockTypeDropdownList, setLivestockTypeDropdownList] = useState(
    [],
  );
  const [enableCsafChildren, setEnableCsafChildren] = useState(false);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true);
  const [formStatus, setFormStatus] = useState('Not Started');
  const toastDisplayedRef = useRef(false);

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);

  const getPolygonArea = (polygonCoordinates) =>
    window.google.maps.geometry?.spherical?.computeArea(polygonCoordinates);

  const navigateOnCloseHandler = (status) => {
    if (status === PAGE_CONTENT.completed) {
      navigateHandler();
    } else {
      navigate(`${pathName.participant.landingPage}`);
    }
  };

  const fieldsAreaExceedsFarmArea = useMemo(() => {
    if (typeof currentFarmId === 'string') return false;

    const currentFarmFieldBoundaries = fieldPolygons
      ?.filter((polygon) => polygon.farmId === currentFarmId)
      .map((polygon) => polygon.fieldBoundaries);
    const farmArea = Number(generalFarmInfoForm.totalFarmAcreage);
    const fieldAreas = currentFarmFieldBoundaries?.map((boundary) =>
      Number(convertSquareMetersToAcres(getPolygonArea(boundary))),
    );
    const totalFieldArea = fieldAreas?.reduce((total, area) => total + area, 0);
    return totalFieldArea > farmArea;
  }, [currentFarmId, generalFarmInfoForm.totalFarmAcreage]);

  const disableAllFields = enrolledFarmIds?.includes(currentFarmId);

  useEffect(() => {
    setSubmitLoading(farmLoading);
  }, [farmLoading]);

  useEffect(() => {
    setIsEdit(
      farmInfo.find((farm) => farm.id === currentFarmId)?.isApiPopulated,
    );
  }, [currentFarmId, farmInfo]);

  useEffect(() => {
    if (addFarmClicked.flag) {
      submitHandler(
        false,
        addFarmClicked.flag,
        generalFarmInfoForm,
        currentFarmId,
      );
      setCurrentFarmId(addFarmClicked.id);
      setExpandedFarmId(addFarmClicked.id);
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(false);
      setOpenMapView(false);
      resetFormFields();
    }
  }, [addFarmClicked]);

  useEffect(() => {
    setSubmitData({
      currentPage: 'Farm',
      data: generalFarmInfoForm,
      currentId: currentFarmId,
    });
  }, [generalFarmInfoForm, currentFarmId]);

  useEffect(() => {
    if (isFormUnmounted.flag) {
      if (submitData.data !== null && submitData.currentPage === 'Farm') {
        submitHandler(
          false,
          false,
          submitData.data,
          submitData.currentId,
          false,
          true, // isFormUnmounting
        );
        setSubmitData({
          currentPage: null,
          data: null,
          currentId: null,
        });
      }
      setIsFormUnmounted({
        currentPage: null,
        flag: false,
        id: 0,
      });
    }
  }, [isFormUnmounted, submitData]);

  const resetFormFields = () => {
    setFormValue(() => ({
      ...formModal,
      fsaId: '',
    }));
    setUsedFarmId(0);
    setErrorState(errorInitialState);
    setIsEditable(true);
    setLivestockTypes(livestockTypeInitialState);
    setFmsInfo([fmsObj]);
    setFmsOption('');
    toastDisplayedRef.current = false;
  };

  /**
   * @description This function will set errors for dynamic fields inside web form, other fields error is handled inside a useEffect, can't handle this inside the same.
   * @param data will have the direct api response data.
   */
  const setDynamicFieldsErrors = (data) => {
    const organicStatus = convertBooleanToYesNo(data.farmOrganicStatus);
    const csafExperience = getValueOrDefault(data.csafImplemented);
    const csafPractices = getValueOrDefault(data.extentCsafImplemented);
    const csafFederalFunding = getValueOrDefault(data.csafFederalFunding);
    const agronomistUse = convertBooleanToYesNo(data.agronomistUse);

    setErrorState((prev) => ({
      ...prev,
      isUSDACertifiedRequiredError: checkTernaryCondition(
        organicStatus === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !convertBooleanToYesNo(data.fieldsOrganicStatus)?.trim(),
        ),
        FALSE,
      ),
      csafPracticesRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        !csafPractices.trim(),
        FALSE,
      ),
      csafFederalFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(!csafFederalFunding?.trim(), FALSE),
      ),
      csafStateFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafStateFunding)?.trim(),
          FALSE,
        ),
      ),
      csafNonProfitFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafNonProfitFunding)?.trim(),
          FALSE,
        ),
      ),
      csafMarketIncentivesRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafMarketIncentives)?.trim(),
          FALSE,
        ),
      ),
      agronomistUseOrganizationRequiredError: checkTernaryCondition(
        agronomistUse === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.agronomistUseOrganization)?.trim(),
        ),
        FALSE,
      ),
    }));
  };

  useEffect(() => {
    if (currentFarmId && isTabSwitched && currentFarmId !== -1) {
      resetFormFields();
    }
  }, [currentFarmId]);

  useEffect(() => {
    if (saveAndCloseClicked) {
      submitHandler(
        PAGE_CONTENT.in_progress,
        false,
        generalFarmInfoForm,
        currentFarmId,
      );
    }
  }, [saveAndCloseClicked]);

  useEffect(() => {
    if (farmInfoCurrentTab === 1 && !saveAndContinueClicked && isTabSwitched) {
      submitHandler(
        false,
        false,
        generalFarmInfoForm,
        currentFarmId,
        typeof currentFarmId === 'string' || currentFarmId === -1,
      );
    }
  }, [farmInfoCurrentTab]);

  useEffect(() => {
    if (isFieldSubmitted && isEdit) {
      fetchFarmData();
    }
  }, [isFieldSubmitted, isEdit]);

  useEffect(() => {
    if (
      isEdit &&
      farmInfoCurrentTab === 0 &&
      currentFarmId !== -1 &&
      typeof currentFarmId !== 'string'
    ) {
      fetchFarmData();
    }
  }, [isEdit, farmInfoCurrentTab, currentFarmInfo, currentFarmId]);

  useEffect(() => {
    if (
      isEdit &&
      generalFarmInfoForm.isApiPopulated &&
      formStatus !== PAGE_CONTENT.not_started
    ) {
      setErrorState((prev) => ({
        ...prev,
        totalFarmAcreageRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.totalFarmAcreage?.trim(),
        ),
        organicStatusRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.organicStatus?.trim(),
        ),
        csafExperienceRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.csafExperience?.trim(),
        ),
        carbonRightsAssociatedError: ternaryBooleanHandler(
          !generalFarmInfoForm.carbonRightsAssociated?.trim(),
        ),
        fmsUseRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.fmsUse?.trim(),
        ),
        fmsListRequiredError: checkTernaryCondition(
          generalFarmInfoForm.fmsUse === PAGE_CONTENT.yes,
          fmsInfo.some((item) => {
            return item.fmsError === true;
          }),
          FALSE,
        ),
        soilSamplingRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.soilSampling?.trim(),
        ),
        agronomistUseRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.agronomistUse?.trim(),
        ),
        croplandAcerageRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.croplandAcerage?.trim(),
        ),
        livestockAcerageRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.livestockAcerage?.trim(),
        ),
        forestAcerageRequiredError: ternaryBooleanHandler(
          !generalFarmInfoForm.forestAcerage?.trim(),
        ),
        croplandAcerageGreaterThanZeroError: ternaryBooleanHandler(
          Number(generalFarmInfoForm.croplandAcerage?.trim()) <= 0,
        ),
        croplandAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.croplandAcerage?.trim()),
        livestockAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.livestockAcerage?.trim()),
        forestAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.forestAcerage?.trim()),
        croplandAndForestAcerageSumGreaterThanFarmAreaError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.croplandAcerage?.trim()) +
            Number(generalFarmInfoForm.forestAcerage?.trim()),
      }));
    }
  }, [generalFarmInfoForm, isEdit, formStatus]);

  useEffect(() => {
    if (isSubmit === true) {
      fmsInfo.forEach((item) => {
        if (item.fmsName === '') {
          setErrorState({
            ...errorState,
            fmsListRequiredError: true,
          });
        }
      });
    }
    setFormValue({
      ...generalFarmInfoForm,
      fmsList: fmsInfo.map((item) => item.fmsName),
    });
  }, [fmsInfo]);

  useEffect(() => {
    fetchLivestockTypeOptions();
  }, []);

  useEffect(() => {
    if (csafExperienceOption === 'No') {
      setFormValue({
        ...generalFarmInfoForm,
        csafFederalFunding: '',
        csafStateFunding: '',
        csafNonProfitFunding: '',
        csafMarketIncentives: '',
      });
    }
    if (agronomistOption === 'No') {
      setFormValue({
        ...generalFarmInfoForm,
        agronomistUseOrganization: '',
      });
    }
    if (fmsOption === 'No') {
      setFmsInfo([fmsObj]);
    }
  }, [csafExperienceOption, agronomistOption, fmsOption]);

  useEffect(() => {
    if (usedFarmId > 0 && !isEdit) {
      setSubmitLoading(true);
      axios
        .get(`${FETCH_GENERAL_FARM_INFO}/${usedFarmId}`)
        .then((response) => {
          const data = response.data;
          setFormData(data, true /* fromTypeAhead */);
          const newLivestockTypeData = [];
          for (let i = 0; i < 3; i++) {
            const livestockTypeValue = data[`farmLivestock${i + 1}Id`];
            const headCountValue = data[`farmLivestock${i + 1}headcount`];
            if (
              (livestockTypeValue !== '' && livestockTypeValue !== null) ||
              (headCountValue !== '' && headCountValue !== null)
            ) {
              newLivestockTypeData.push({
                id: uniqueId(),
                livestockTypeValue: livestockTypeValue,
                headCountValue: getValueOrDefault(headCountValue)?.toString(),
                livestockTypeError:
                  livestockTypeValue === '' || livestockTypeValue === null,
                headCountError:
                  headCountValue === '' || headCountValue === null,
              });
            }
          }

          setLivestockTypes(
            checkTernaryCondition(
              newLivestockTypeData.length > 0,
              newLivestockTypeData,
              [
                {
                  id: uniqueId(),
                  livestockTypeValue: '',
                  headCountValue: '',
                  livestockTypeError: true,
                  headCountError: true,
                },
              ],
            ),
          );
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  }, [usedFarmId]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      croplandAndForestAcerageSumGreaterThanFarmAreaError:
        Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
        Number(generalFarmInfoForm.croplandAcerage?.trim()) +
          Number(generalFarmInfoForm.forestAcerage?.trim()),
    });
  }, [generalFarmInfoForm.croplandAcerage, generalFarmInfoForm.forestAcerage]);

  //fetch livestock type options
  const fetchLivestockTypeOptions = () => {
    axios.get(FETCH_LIVESTOCK_TYPE_DROPDOWN).then((response) => {
      setLivestockTypeDropdownList(
        response.data.map((data) => ({
          label: data.livestockType,
          value: data.livestockId,
        })),
      );
    });
  };

  const setFormData = (data, fromTypeAhead = false) => {
    if (isEdit) {
      setIsEditable(data.isDeleted !== 1);
    }

    !fromTypeAhead &&
      setFarmInfo((prev) =>
        prev.map((farm) =>
          checkTernaryCondition(
            farm.id === currentFarmId,
            {
              ...farm,
              status: data.formStatus,
              formValidationFlag: data.formValidationFlag,
            },
            farm,
          ),
        ),
      );
    const csafUpdatedBySystem = data.csafUpdatedBySystem;
    setIsFarmCsafUpdatedBySystem(csafUpdatedBySystem);
    setEnableCsafChildren(data.enableCsafChildren || false);
    const formattedData = {
      ...generalFarmInfoForm,
      farmName: getValueOrDefault(data.farmName),
      fsaId: getValueOrDefault(data.fsaId),
      totalFarmAcreage: getValueOrDefault(data.totalFarmAcreage)?.toString(),
      organicStatus: convertBooleanToYesNo(data.farmOrganicStatus),
      csafExperience: getValueOrDefault(data.csafImplemented),
      csafFederalFunding: getValueOrDefault(data.csafFederalFunding),
      csafStateFunding: getValueOrDefault(data.csafStateFunding),
      csafNonProfitFunding: getValueOrDefault(data.csafNonProfitFunding),
      csafMarketIncentives: getValueOrDefault(data.csafMarketIncentives),
      carbonRightsAssociated: convertBooleanToYesNo(
        data.carbonRightsAssociated,
      ),
      fmsUse: convertBooleanToYesNo(data.fmsUsed),
      fmsList: stringToArray(data.fmsList),
      soilSampling: convertBooleanToYesNo(data.soilSampling),
      agronomistUse: convertBooleanToYesNo(data.agronomistUse),
      agronomistUseOrganization: getValueOrDefault(
        data.agronomistUseOrganization,
      ),
      croplandAcerage: getValueOrDefault(data.cropArea)?.toString(),
      livestockAcerage: getValueOrDefault(data.livestockArea)?.toString(),
      forestAcerage: getValueOrDefault(data.forestArea)?.toString(),
      fsaIdType: getValueOrDefault(data.fsaIdType),
      entityName: getValueOrDefault(data.entityName),
      firstName: getValueOrDefault(data.firstName),
      lastName: getValueOrDefault(data.lastName),
      mailingAddress: getValueOrDefault(data.farmMailingAddress),
      city: getValueOrDefault(data.farmCity),
      county: getValueOrDefault(data.farmCounty),
      state: getValueOrDefault(data.farmState),
      zipCode: getValueOrDefault(data.farmZipcode)?.toString(),
      isUSDACertified: convertBooleanToYesNo(data.fieldsOrganicStatus),
      csafPractices: getValueOrDefault(data.extentCsafImplemented),
      csafUpdatedBySystem: csafUpdatedBySystem,
      isApiPopulated: true,
      contributorStatus: data.contributorStatus,
    };
    setFormValue(formattedData);
    setFormStatus(data.formStatus);

    if (
      validateFormFields(
        formattedData,
        livestockTypes,
        !fieldsAreaExceedsFarmArea,
      ) &&
      farmInfo.find((farm) => farm.id === currentFarmId)?.fields?.length ===
        0 &&
      !toastDisplayedRef.current
    ) {
      displayToast(
        PAGE_CONTENT.warning,
        `${data.farmName}${PAGE_CONTENT.no_field_present_warning_msg}`,
      );
      toastDisplayedRef.current = true;
    }
  };

  const fetchFarmData = () => {
    setSubmitLoading(true);
    setIsDisclaimerVisible(true);
    axios
      .get(`${FETCH_GENERAL_FARM_INFO}/${currentFarmId}`)
      .then((response) => {
        const data = response.data;
        const fmsList = stringToArray(data.fmsList);
        const newFmsListInfo = checkTernaryCondition(
          fmsList[0] === '',
          [
            {
              id: uniqueId(),
              fmsName: '',
              fmsError: true,
            },
          ],
          fmsList.map((fetchFms) => ({
            id: uniqueId(),
            fmsName: fetchFms.trim(),
            fmsError: false,
          })),
        );
        setFmsInfo([...newFmsListInfo]);
        setFormData(data);
        setDynamicFieldsErrors(data);
        if (currentFarmInfo?.farmId !== data?.farmId) {
          setCurrentFarmInfo(data);
        }
        setTimeout(() => {
          setSubmitLoading(false);
        }, 500);

        const newLivestockTypeData = [];
        for (let i = 0; i < 3; i++) {
          const livestockTypeValue = data[`farmLivestock${i + 1}Id`];
          const headCountValue = data[`farmLivestock${i + 1}headcount`];
          if (
            (livestockTypeValue !== '' && livestockTypeValue !== null) ||
            (headCountValue !== '' && headCountValue !== null)
          ) {
            newLivestockTypeData.push({
              id: uniqueId(),
              livestockTypeValue: livestockTypeValue,
              headCountValue: getValueOrDefault(headCountValue)?.toString(),
              livestockTypeError:
                livestockTypeValue === '' || livestockTypeValue === null,
              headCountError: headCountValue === '' || headCountValue === null,
            });
          }
        }

        setLivestockTypes(
          checkTernaryCondition(
            newLivestockTypeData.length > 0,
            newLivestockTypeData,
            [
              {
                id: uniqueId(),
                livestockTypeValue: '',
                headCountValue: '',
                livestockTypeError: true,
                headCountError: true,
              },
            ],
          ),
        );
        setIsFieldSubmitted(false);
        // On initial fetch we set the user activity to false, once any value is changed, we update the flag
        setUserActivityDetected(false);
      });
  };

  const handleClickDelete = (id) => {
    const currentData = livestockTypes.filter((obj) => obj.id !== id);
    setLivestockTypes(currentData);
    makeFormDirty();
  };

  const filterLivestockDropdownList = (id) => {
    const livestockTypeValues = livestockTypes
      .filter((item) => item.id !== id)
      .map((item) => item.livestockTypeValue);

    if (!livestockTypeValues.length) {
      return livestockTypeDropdownList;
    }

    const updatedList = livestockTypeDropdownList.filter(
      (item) => !livestockTypeValues.includes(item.value),
    );
    return updatedList;
  };

  const fieldHandler = (key, value) => {
    customFieldChangeHandler(key, value);
    switch (key) {
      case formFieldsNames.totalFarmAcreage:
        setErrorState({
          ...errorState,

          totalFarmAcreageRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.organicStatus:
        setErrorState({
          ...errorState,
          organicStatusRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.isUSDACertified:
        setErrorState({
          ...errorState,
          isUSDACertifiedRequiredError: checkTernaryCondition(
            generalFarmInfoForm.organicStatus === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafExperience:
        setCsafExperienceOption(value);
        setErrorState({
          ...errorState,
          csafExperienceRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.csafFederalFunding:
        setErrorState({
          ...errorState,
          csafFederalFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafStateFunding:
        setErrorState({
          ...errorState,
          csafStateFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafNonProfitFunding:
        setErrorState({
          ...errorState,
          csafNonProfitFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafMarketIncentives:
        setErrorState({
          ...errorState,
          csafMarketIncentivesRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.fmsUse:
        setFmsOption(value);
        setErrorState({
          ...errorState,
          fmsUseRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.soilSampling:
        setErrorState({
          ...errorState,
          soilSamplingRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.agronomistUse:
        setAgronomistOption(value);
        setErrorState({
          ...errorState,
          agronomistUseRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.agronomistUseOrganization:
        setErrorState({
          ...errorState,
          agronomistUseOrganizationRequiredError: checkTernaryCondition(
            generalFarmInfoForm.agronomistUse === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.croplandAcerage:
        setErrorState({
          ...errorState,
          croplandAcerageRequiredError: checkFieldIsNotEmpty(value),
          croplandAcerageGreaterThanZeroError: Number(value) <= 0,
          croplandAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break;
      case formFieldsNames.livestockAcerage:
        setErrorState({
          ...errorState,
          livestockAcerageRequiredError: checkFieldIsNotEmpty(value),
          livestockAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break;
      case formFieldsNames.forestAcerage:
        setErrorState({
          ...errorState,
          forestAcerageRequiredError: checkFieldIsNotEmpty(value),
          forestAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break;
    }
  };

  const getCurrentFarm = () => {
    return farmInfo.filter((farm) => farm.id === currentFarmId)[0];
  };

  const submitHandler = (
    isSaveAndCloseClicked,
    isAddClicked,
    generalFarmInfoForm,
    currentFarmId,
    navigateToNext = true,
    isFormUnmounting = false,
  ) => {
    setIsSubmit(true);
    setAddFarmClicked({ flag: false, id: 0 });
    setSaveAndCloseClicked(false);
    const updatedFmsInfo = fmsInfo.map((item) => {
      if (item.fmsName === '') {
        return {
          ...item,
          fmsError: true,
        };
      }
      return item;
    });
    setFmsInfo(updatedFmsInfo);
    setSubmitLoading(true);
    let formStatus = '';
    if (fieldsAreaExceedsFarmArea) {
      formStatus = PAGE_CONTENT.in_progress;
    } else {
      let isFarmCompleted =
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
        ) &&
        farmInfo
          .find((farm) => farm.id === currentFarmId)
          ?.fields?.some((field) => field.status === PAGE_CONTENT.completed);
      formStatus = checkTernaryCondition(
        isEdit,
        checkTernaryCondition(
          isFarmCompleted,
          PAGE_CONTENT.completed,
          PAGE_CONTENT.in_progress,
        ),

        PAGE_CONTENT.in_progress,
      );
    }
    if (isSaveAndCloseClicked && isEmailLinkClicked) {
      navigateOnCloseHandler(
        userRoles[0] === PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR
          ? formStatus
          : statusList,
      );
    }

    const farmUpdateBody = {
      internalProjectId: Number(projectIdValue),
      enrollmentInstanceId: Number(enrollmentInstanceId),
      farmName:
        typeof generalFarmInfoForm.farmName === 'string'
          ? generalFarmInfoForm.farmName
          : generalFarmInfoForm.farmName?.label,
      fsaId:
        typeof generalFarmInfoForm.fsaId === 'string'
          ? generalFarmInfoForm.fsaId
          : generalFarmInfoForm.fsaId?.label,
      contributorStatus: generalFarmInfoForm.contributorStatus,
      totalFarmAcreage: generalFarmInfoForm.totalFarmAcreage,
      organicStatus: convertYesNoIntoBoolean(generalFarmInfoForm.organicStatus),
      csafImplemented: generalFarmInfoForm.csafExperience,
      csafFederalFunding: generalFarmInfoForm.csafFederalFunding,
      csafStateFunding: generalFarmInfoForm.csafStateFunding,
      csafNonProfitFunding: generalFarmInfoForm.csafNonProfitFunding,
      csafMarketIncentives: generalFarmInfoForm.csafMarketIncentives,
      carbonRightsAssociated: convertYesNoIntoBoolean(
        generalFarmInfoForm.carbonRightsAssociated,
      ),
      fmsUsed: convertYesNoIntoBoolean(generalFarmInfoForm.fmsUse),
      fmsList: generalFarmInfoForm.fmsList.filter((fms) => fms.trim()),
      soilSampling: convertYesNoIntoBoolean(generalFarmInfoForm.soilSampling),
      agronomistUse: convertYesNoIntoBoolean(generalFarmInfoForm.agronomistUse),
      agronomistUseOrganization: generalFarmInfoForm.agronomistUseOrganization,
      cropArea: generalFarmInfoForm.croplandAcerage,
      livestockArea: generalFarmInfoForm.livestockAcerage,
      forestArea: generalFarmInfoForm.forestAcerage,
      formStatus: formStatus,
      formValidationFlag: checkTernaryCondition(
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
        ),
        true,
        false,
      ),
      fsaIdType: generalFarmInfoForm.fsaIdType,
      firstName: generalFarmInfoForm.firstName,
      lastName: generalFarmInfoForm.lastName,
      entityName: generalFarmInfoForm.entityName,
      mailingAddress: generalFarmInfoForm.mailingAddress,
      city: generalFarmInfoForm.city,
      county: generalFarmInfoForm.county,
      state: generalFarmInfoForm.state,
      farmZipcode: parseInt(generalFarmInfoForm.zipCode),
      fieldsOrganicStatus: convertYesNoIntoBoolean(
        generalFarmInfoForm.isUSDACertified,
      ),
      extentCsafImplemented: generalFarmInfoForm.csafPractices,
      farmLivestock1Id: checkTernaryCondition(
        livestockTypes[0]?.livestockTypeValue,
        livestockTypes[0]?.livestockTypeValue,
        '',
      ),
      farmLivestock1headcount: checkTernaryCondition(
        livestockTypes[0]?.headCountValue,
        parseInt(livestockTypes[0]?.headCountValue),
        '',
      ),
      farmLivestock2Id: checkTernaryCondition(
        livestockTypes[1]?.livestockTypeValue,
        livestockTypes[1]?.livestockTypeValue,
        '',
      ),
      farmLivestock2headcount: checkTernaryCondition(
        livestockTypes[1]?.headCountValue,
        parseInt(livestockTypes[1]?.headCountValue),
        '',
      ),
      farmLivestock3Id: checkTernaryCondition(
        livestockTypes[2]?.livestockTypeValue,
        livestockTypes[2]?.livestockTypeValue,
        '',
      ),
      farmLivestock3headcount: checkTernaryCondition(
        livestockTypes[2]?.headCountValue,
        parseInt(livestockTypes[2]?.headCountValue),
        '',
      ),
      projectCycleId: projectCycleId,
    };
    if (isEdit) {
      editFarmHandler(
        farmUpdateBody,
        isAddClicked,
        isSaveAndCloseClicked,
        currentFarmId,
        navigateToNext,
        isFormUnmounting,
      );
    } else if (currentFarmId === -1 || typeof currentFarmId === 'string') {
      addFarmHandler(
        farmUpdateBody,
        isAddClicked,
        isSaveAndCloseClicked,
        currentFarmId,
        navigateToNext,
        isFormUnmounting,
      );
    }
  };

  const editFarmHandler = (
    farmUpdateBody,
    isAddClicked,
    isSaveAndCloseClicked,
    currentFarmId,
    navigateToNext,
    isFormUnmounting,
  ) => {
    const thenBlock = (responseData, updatedFarmInfo) => {
      setCurrentFarmLocation(currentFarmId);
      if (!isAddClicked) {
        if (!isSaveAndCloseClicked) {
          setFetchedFarmValues([]);
          if (navigateToNext && !isFormUnmounting) {
            if (getCurrentFarm().fields.length === 0) {
              setFarmInfoCurrentTab(1);
            } else {
              navigateToNextSectionHandler(responseData, updatedFarmInfo);
            }
          }
        } else {
          setExpandedFarmId(responseData.farmId);
          if (isEmailLinkClicked) {
            navigateOnCloseHandler(
              userRoles[0] === PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR
                ? formStatus
                : statusList,
            );
          } else {
            navigateHandler();
          }
        }
      }
      setFarmInfo(updatedFarmInfo);
    };

    if (
      enrolledFarmIds?.includes(currentFarmId) &&
      !(isFarmCsafUpdatedBySystem || enableCsafChildren)
    ) {
      setTimeout(() => {
        thenBlock({ farmId: currentFarmId }, farmInfo);
        setSubmitLoading(FALSE);
      }, 500);
    } else {
      return axios
        .put(
          `${EDIT_GENERAL_FARM_INFO}/${currentFarmId}?participantId=${participantIdValue}`,
          farmUpdateBody,
        )
        .then((response) => {
          const updatedFormInfo = farmInfo.map((farm) => {
            return checkTernaryCondition(
              farm.id === currentFarmId,
              {
                id: response.data.farmId,
                status: response.data.formStatus,
                formValidationFlag: response.data.formValidationFlag,
                value: generalFarmInfoForm.farmName,
                fields: farm.fields,
                isApiPopulated: true,
              },
              farm,
            );
          });
          fetchParticipantData(false);
          thenBlock(response.data, updatedFormInfo);
        })
        .catch(() => {
          displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
          setRightSectionId(2);
          setFarmInfoCurrentTab(0);
          setFieldAddTypeModalOpen(false);
          setCurrentFarmId(currentFarmId);
          setExpandedFarmId(currentFarmId);
          setShowFieldInfoForm(false);
        })
        .finally(() => {
          setSubmitLoading(FALSE);
        });
    }
  };

  const addFarmHandler = (
    farmUpdateBody,
    isAddClicked,
    isSaveAndCloseClicked,
    currentFarmId,
    navigateToNext,
    isFormUnmounting,
  ) => {
    return axios
      .post(
        `${ADD_PARTICIPANT}/${participantIdValue}/${PARTICIPANT_GENERAL_FARM_INFO}`,
        {
          farmId: usedFarmId,
          internalProjectId: projectIdValue,
          ...farmUpdateBody,
        },
      )
      .then((response) => {
        setCurrentFarmLocation(response.data.farmId);
        if (!isAddClicked) {
          if (!isSaveAndCloseClicked) {
            if (navigateToNext) {
              !isFormUnmounting && setOpenMapView(true);
              setExpandedFarmId(response.data.farmId);
              setCurrentFarmId(response.data.farmId);
              setCurrentFarmInfo(response.data);
              !isFormUnmounting && setFarmInfoCurrentTab(1);
            }
            if (isFormUnmounting && isFormUnmounted.id === currentFarmId) {
              setCurrentFarmId(response.data.farmId);
              setExpandedFarmId(response.data.farmId);
              setCurrentFarmInfo(response.data);
            }
          } else {
            setFarmInfo(
              farmInfo.map((farm) => {
                return checkTernaryCondition(
                  farm.id === currentFarmId,
                  {
                    id: response.data.farmId,
                    status: PAGE_CONTENT.in_progress,
                    formValidationFlag: response.data.formValidationFlag,
                    value: response.data.farmName,
                    isApiPopulated: true,
                    fields: [],
                  },
                  farm,
                );
              }),
            );
            navigateHandler();
          }
        }
        setFarmInfo(
          farmInfo.map((farm) => {
            return checkTernaryCondition(
              farm.id === currentFarmId,
              {
                id: response.data.farmId,
                status: PAGE_CONTENT.in_progress,
                formValidationFlag: response.data.formValidationFlag,
                value: generalFarmInfoForm.farmName,
                isApiPopulated: true,
                fields: [],
              },
              farm,
            );
          }),
        );
        setSubmitData((prev) => ({
          ...prev,
          currentId: response.data.farmId,
        }));
        setIsEdit(true);
        setHasNotApprovedFarmOrFields(true);
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
        setRightSectionId(2);
        setFarmInfoCurrentTab(0);
        setFieldAddTypeModalOpen(false);
        setCurrentFarmId(currentFarmId);
        setExpandedFarmId(currentFarmId);
        setShowFieldInfoForm(false);
      })
      .finally(() => {
        setSubmitLoading(FALSE);
      });
  };

  const navigateToNextSectionHandler = (farmData, updatedFormInfo) => {
    let isEnrollmentCompleted = checkIsEnrollmentCompleted(
      updatedFormInfo,
      validateFormFields(
        generalFarmInfoForm,
        livestockTypes,
        !fieldsAreaExceedsFarmArea,
      ),
      participantProfileStatus,
      isParticipantContributor,
    );
    handleEnrollmentcompletion(isEnrollmentCompleted, farmData);
  };

  const handleEnrollmentcompletion = (isEnrollmentCompleted, farmData) => {
    if (isEnrollmentCompleted) {
      setModalData({
        flag: isEnrollmentCompleted,
        id: 0,
      });
    } else {
      setExpandedFarmId(farmData.farmId);
      let inProgressItem = null;
      inProgressItem = handleNextInProgrssSwitcher(
        inProgressItem,
        farmInfo,
        getCurrentFarm(),
        null,
        true,
      );

      formInProgressHandler(inProgressItem, getCurrentFarm());
    }
  };

  const formInProgressHandler = (inProgressItem, currentFarm) => {
    if (inProgressItem !== null) {
      if (inProgressItem.type === 'field') {
        setSelectedFieldId({
          id: inProgressItem.id,
          name: inProgressItem.value,
        });
        setCurrentFarmId(inProgressItem.farmId);
        setExpandedFarmId(inProgressItem.farmId);
        setShowFieldInfoForm(farmInfoCurrentTab === 0);
      } else if (inProgressItem.type === 'farm') {
        setCurrentFarmId(inProgressItem.id);
        setExpandedFarmId(inProgressItem.id);
        setShowFieldInfoForm(false);
      }
    } else if (inProgressItem === null) {
      //go to next completed sections
      if (currentFarm?.fields.length > 0) {
        setSelectedFieldId({
          id: currentFarm.fields[0].id,
          name: currentFarm.fields[0].value,
        });
        setShowFieldInfoForm(farmInfoCurrentTab === 0);
      } else {
        setFarmInfoCurrentTab(1);
      }
    }
  };

  const addFmsHandler = () => {
    setFmsInfo((prev) => [
      ...prev,
      {
        ...fmsObj,
        id: uniqueId(),
      },
    ]);
  };

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectIdValue,
          participantid: participantIdValue,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  const updateFmsInfo = (event, id) => {
    setFmsInfo((current) => {
      return current.map((fms) => {
        if (fms.id === id) {
          if (event.target.value !== '') {
            setErrorState({
              ...errorState,
              fmsListRequiredError: FALSE,
            });
            return {
              ...fms,
              fmsName: event.target.value,
              fmsError: FALSE,
            };
          }
          if (isSubmit === true) {
            setErrorState({
              ...errorState,
              fmsListRequiredError: true,
            });
            return {
              ...fms,
              fmsName: event.target.value,
              fmsError: true,
            };
          }
          return {
            ...fms,
            fmsName: event.target.value,
          };
        } else {
          return fms;
        }
      });
    });
  };

  const deleteFmsInfo = (id) => {
    setFmsInfo((current) => {
      return current.filter((fms) => {
        return fms.id !== id;
      });
    });
    makeFormDirty();
  };

  const csafPracticesDropdownProps = {
    label: PAGE_CONTENT.csaf_practices_label,
    value: checkTernaryCondition(
      generalFarmInfoForm.csafPractices === '',
      PAGE_CONTENT.csaf_practices_placeholder,
      generalFarmInfoForm.csafPractices,
    ),
    dropdownlist: csafPracticesOptions,
    isDisabled:
      disableAllFields && isFarmCsafUpdatedBySystem && enableCsafChildren,
    width: '30.3%',
    height: '2rem',
    onUpdate: (e) => {
      customFieldChangeHandler(formFieldsNames.csafPractices, e.target.value);
      setErrorState((prev) => ({ ...prev, csafPracticesRequiredError: FALSE }));
      makeFormDirty();
    },
    setStateValue: () => {},
    name: formFieldsNames.csafPractices,
    ariaLabel: PAGE_CONTENT.csaf_practices_label,
    error:
      errorState.csafPracticesRequiredError &&
      (!disableAllFields || isFarmCsafUpdatedBySystem || enableCsafChildren),
    errorMessage: PAGE_CONTENT.csaf_practices_required_error,
    fontSize: STYLE_CONSTANTS.input_font_size,
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: outlinedDropdownInputSx,
    isAutoWidth: true,
  };

  return (
    <div>
      <Backdrop
        sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoading}>
        <CircularProgress color={PAGE_CONTENT.success} />
      </Backdrop>
      <FormSection>
        <MainWrapper
          data-testid="Farm-section"
          style={!isEditable ? disableFormStyle : {}}>
          {isDisclaimerVisible && !isParticipantContributor && (
            <OwnerDisclaimer>
              <OwnerDisclaimerText>
                {PAGE_CONTENT.owner_disclaimer_text}
                <CloseLink
                  onClick={() => {
                    submitHandler(
                      true,
                      false,
                      generalFarmInfoForm,
                      currentFarmId,
                    );
                  }}>
                  {PAGE_CONTENT.close.slice(0, -1)}
                </CloseLink>
                {PAGE_CONTENT.close.slice(-1)}
              </OwnerDisclaimerText>
              <CloseIcon
                onClick={() => {
                  setIsDisclaimerVisible(false);
                }}
                sx={closeIconSx}
                data-testid="close-icon"
              />
            </OwnerDisclaimer>
          )}
          <InputField
            isDisabled={disableAllFields}
            value={generalFarmInfoForm.totalFarmAcreage}
            label={PAGE_CONTENT.total_farm_acreage_label}
            placeholder={PAGE_CONTENT.total_farm_acreage_placeholder}
            width={PAGE_CONTENT.acerage_width}
            hasPredefinedUnit={true}
            predefinedUnit={PAGE_CONTENT.total_farm_sub_label}
            isRequired
            name={formFieldsNames.totalFarmAcreage}
            onUpdate={(e) => {
              if (regexMatchForAcres(e.target.value)) {
                fieldHandler(
                  formFieldsNames.totalFarmAcreage,
                  addZeroBeforeDot(e.target.value),
                );
                makeFormDirty();
              }
            }}
            primaryError={
              errorState.totalFarmAcreageRequiredError && !disableAllFields
            }
            primaryErrorMessage={
              PAGE_CONTENT.total_farm_acreage_primary_error_message
            }
            secondaryError={fieldsAreaExceedsFarmArea}
            secondaryErrorMessage={
              PAGE_CONTENT.fieldsAreaExceedingFarmAreaError
            }
            labelMarginTop={STYLE_CONSTANTS.zero_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={InputSx}
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
          />
          <FormLabels
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            iserror={
              errorState.croplandAcerageRequiredError ||
              errorState.livestockAcerageRequiredError ||
              errorState.forestAcerageRequiredError ||
              errorState.croplandAcerageGreaterThanZeroError ||
              errorState.croplandAcerageGreaterThanTotalFarmError ||
              errorState.forestAcerageGreaterThanTotalFarmError ||
              errorState.livestockAcerageGreaterThanTotalFarmError ||
              errorState.croplandAndForestAcerageSumGreaterThanFarmAreaError
            }>
            {PAGE_CONTENT.total_farm_acerage_specification_label}
          </FormLabels>
          <AcerageWrapper>
            <InputField
              isDisabled={disableAllFields}
              value={generalFarmInfoForm.croplandAcerage}
              label={PAGE_CONTENT.cropland_acerage_label}
              placeholder={PAGE_CONTENT.cropland_acerage_placeholder}
              width={PAGE_CONTENT.acerage_width}
              isRequired
              name={formFieldsNames.croplandAcerage}
              onUpdate={(e) => {
                if (regexMatchForAcres(e.target.value)) {
                  fieldHandler(
                    formFieldsNames.croplandAcerage,
                    addZeroBeforeDot(e.target.value),
                  );
                  makeFormDirty();
                }
              }}
              primaryError={
                errorState.croplandAcerageRequiredError && !disableAllFields
              }
              primaryErrorMessage={
                PAGE_CONTENT.cropland_acerage_primary_error_message
              }
              display={PAGE_CONTENT.display_flex}
              labelOnRightSide={true}
              rightSideLabelFormLabelMargin={PAGE_CONTENT.zero_rem}
              secondaryError={
                errorState.croplandAcerageGreaterThanZeroError &&
                !disableAllFields
              }
              secondaryErrorMessage={
                PAGE_CONTENT.cropland_acerage_secondary_error_message
              }
              thirdError={errorState.croplandAcerageGreaterThanTotalFarmError}
              thirdErrorMessage={
                PAGE_CONTENT.cropland_acerage_third_error_message
              }
              labelMarginTop={STYLE_CONSTANTS.one_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              customInputSx={InputSx}
            />
          </AcerageWrapper>
          <AcerageWrapper>
            <InputField
              isDisabled={disableAllFields}
              value={generalFarmInfoForm.forestAcerage}
              label={PAGE_CONTENT.forest_acerage_label}
              placeholder={PAGE_CONTENT.forest_acerage_placeholder}
              width={PAGE_CONTENT.acerage_width}
              isRequired
              name={formFieldsNames.forestAcerage}
              onUpdate={(e) => {
                if (regexMatchForAcres(e.target.value)) {
                  fieldHandler(
                    formFieldsNames.forestAcerage,
                    addZeroBeforeDot(e.target.value),
                  );
                  makeFormDirty();
                }
              }}
              primaryError={
                errorState.forestAcerageRequiredError && !disableAllFields
              }
              primaryErrorMessage={
                PAGE_CONTENT.forest_acerage_primary_error_message
              }
              display={PAGE_CONTENT.display_flex}
              labelOnRightSide={true}
              rightSideLabelFormLabelMargin={PAGE_CONTENT.zero_rem}
              secondaryError={
                errorState.forestAcerageGreaterThanTotalFarmError &&
                !disableAllFields
              }
              secondaryErrorMessage={
                PAGE_CONTENT.forest_acerage_secondary_error_message
              }
              labelMarginTop={STYLE_CONSTANTS.one_rem}
              labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              customInputSx={InputSx}
            />
          </AcerageWrapper>
          <InputField
            isDisabled={disableAllFields}
            value={generalFarmInfoForm.livestockAcerage}
            label={PAGE_CONTENT.livestock_acerage_label}
            placeholder={PAGE_CONTENT.livestock_acerage_placeholder}
            width={PAGE_CONTENT.acerage_width}
            isRequired
            name={formFieldsNames.livestockAcerage}
            onUpdate={(e) => {
              if (regexMatchForAcres(e.target.value)) {
                fieldHandler(
                  formFieldsNames.livestockAcerage,
                  addZeroBeforeDot(e.target.value),
                );
                if (e.target.value === '' && e.target.value <= 0) {
                  setLivestockTypes(livestockTypeInitialState);
                }
                makeFormDirty();
              }
            }}
            primaryError={
              errorState.livestockAcerageRequiredError && !disableAllFields
            }
            primaryErrorMessage={
              PAGE_CONTENT.livestock_acerage_primary_error_message
            }
            display={PAGE_CONTENT.display_flex}
            labelOnRightSide={true}
            rightSideLabelFormLabelMargin={PAGE_CONTENT.zero_rem}
            secondaryError={
              errorState.livestockAcerageGreaterThanTotalFarmError &&
              !disableAllFields
            }
            secondaryErrorMessage={
              PAGE_CONTENT.livestock_acerage_secondary_error_message
            }
            labelMarginTop={STYLE_CONSTANTS.one_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={InputSx}
          />
          {errorState.croplandAndForestAcerageSumGreaterThanFarmAreaError && (
            <ErrorWrapper>
              {PAGE_CONTENT.total_cropland_and_forest_area_error_message}
            </ErrorWrapper>
          )}
          {generalFarmInfoForm.livestockAcerage > 0 && (
            <div>
              <LivestockTypeLabelWrapper>
                <FormLabels
                  marginTop={STYLE_CONSTANTS.zero_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  width={'17.875rem'}
                  iserror={
                    livestockTypes[0].livestockTypeError ||
                    livestockTypes.some(
                      (livestockType) => livestockType.headCountError,
                    )
                  }>
                  {PAGE_CONTENT.livestock_type_label}
                </FormLabels>
                <FormLabels
                  marginTop={STYLE_CONSTANTS.zero_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  iserror={
                    livestockTypes[0].livestockTypeError ||
                    livestockTypes.some(
                      (livestockType) => livestockType.headCountError,
                    )
                  }>
                  {PAGE_CONTENT.head_count_label}
                </FormLabels>
              </LivestockTypeLabelWrapper>
              {livestockTypes.map((type, index) => {
                return (
                  <LivestockTypeWrapper key={type.id}>
                    <DropDown
                      label={''}
                      value={checkTernaryCondition(
                        type.livestockTypeValue === '' ||
                          type.livestockTypeValue === null,
                        PAGE_CONTENT.livestock_type_placeholder,
                        type.livestockTypeValue,
                      )}
                      dropDownPlaceholder={
                        PAGE_CONTENT.livestock_type_placeholder
                      }
                      dropdownlist={filterLivestockDropdownList(type.id)}
                      isDisabled={disableAllFields}
                      width="17.875rem"
                      minWidth={'17.875rem'}
                      showLabelAsValue={true}
                      height="2rem"
                      onUpdate={(e) => {
                        setLivestockTypes(
                          livestockTypes.map((livestock) =>
                            checkTernaryCondition(
                              livestock.id === type.id,
                              {
                                ...livestock,
                                livestockTypeValue: e.target.value,
                                livestockTypeError:
                                  e.target.value !== '' && FALSE,
                              },
                              livestock,
                            ),
                          ),
                        );
                        makeFormDirty();
                      }}
                      setStateValue={() => {}}
                      name={''}
                      ariaLabel={''}
                      error={
                        type.livestockTypeError &&
                        index === 0 &&
                        !disableAllFields
                      }
                      errorMessage={PAGE_CONTENT.livestock_type_required_error}
                      fontSize={STYLE_CONSTANTS.input_font_size}
                      hasNoBottomMargin={true}
                      customSx={outlinedDropdownInputSx}
                    />
                    <InputField
                      isDisabled={disableAllFields}
                      noLabel
                      value={type.headCountValue}
                      placeholder={PAGE_CONTENT.head_count_placeholder}
                      width="100%"
                      customStyles={{ width: '8.75rem' }}
                      minWidth="0rem"
                      name={formFieldsNames.livestockAcerage}
                      onUpdate={(e) => {
                        if (
                          e.target.value.match(/^\d+$/) ||
                          e.target.value === ''
                        ) {
                          setLivestockTypes(
                            livestockTypes.map((livestock) =>
                              checkTernaryCondition(
                                livestock.id === type.id,
                                {
                                  ...livestock,
                                  headCountValue: e.target.value,
                                  headCountError: checkFieldIsNotEmpty(
                                    e.target.value,
                                  ),
                                },
                                livestock,
                              ),
                            ),
                          );
                          makeFormDirty();
                        }
                      }}
                      primaryError={type.headCountError && !disableAllFields}
                      primaryErrorMessage={
                        PAGE_CONTENT.head_count_required_error
                      }
                      rightSideLabelFormLabelMargin={
                        STYLE_CONSTANTS.zero_point_five_rem
                      }
                      customInputSx={InputSx}
                    />
                    {livestockTypes.length > 1 && (
                      <IconWrapper>
                        <DeleteIconWithHoverEffect
                          sx={{
                            color: COOL_GRAY,
                            ':hover': {
                              color: DARK_GREEN,
                            },
                          }}
                          data-testid="delete-icon"
                          onClick={() => handleClickDelete(type.id)}
                        />
                      </IconWrapper>
                    )}
                  </LivestockTypeWrapper>
                );
              })}
              {!disableAllFields && livestockTypes.length < 3 && (
                <TextWrapper
                  cursor="pointer"
                  fontSize="14px"
                  color=" #0076A8"
                  onClick={() => {
                    setLivestockTypes([
                      ...livestockTypes,
                      {
                        id: uniqueId(),
                        livestockTypeValue: '',
                        headCountValue: '',
                        livestockTypeError: false,
                        headCountError: false,
                      },
                    ]);
                  }}>
                  {PAGE_CONTENT.add_livestock_btn}
                </TextWrapper>
              )}
            </div>
          )}
          <RadioButtonsGroup
            isDisabled={disableAllFields}
            selectedValue={generalFarmInfoForm.organicStatus}
            list={YesOrNoOptions}
            csafToolTipSx={CSAFToolTipSx}
            label={PAGE_CONTENT.udsa_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              setFormValue({
                ...generalFarmInfoForm,
                organicStatus: e.target.value,
                isUSDACertified: '',
              });
              setErrorState({
                ...errorState,
                isUSDACertifiedRequiredError: FALSE,
              });
              makeFormDirty();
            }}
            primaryError={
              errorState.organicStatusRequiredError && !disableAllFields
            }
            primaryErrorMessage={PAGE_CONTENT.udsa_primary_error_message}
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            hasTooltipOnLabel
            tooltipContent={PAGE_CONTENT.udsa_tooltip}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
          />
          {generalFarmInfoForm.organicStatus === 'Yes' && (
            <RadioButtonsGroup
              isDisabled={disableAllFields}
              selectedValue={generalFarmInfoForm.isUSDACertified}
              list={YesOrNoOptions}
              label={PAGE_CONTENT.is_usda_certified_label}
              direction={PAGE_CONTENT.row}
              customSx={USDAStatusLabelSx}
              handleChange={(e) => {
                fieldHandler(formFieldsNames.isUSDACertified, e.target.value);
                makeFormDirty();
              }}
              primaryError={
                errorState.isUSDACertifiedRequiredError && !disableAllFields
              }
              primaryErrorMessage={
                PAGE_CONTENT.is_usda_certified_required_error
              }
              marginRight={PAGE_CONTENT.margin_right}
              marginTop={STYLE_CONSTANTS.one_rem}
              marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              labelFontSize={STYLE_CONSTANTS.input_font_size}
              radioBtnPadding={radioBtnPadding}
            />
          )}
          <RadioButtonsGroup
            isDisabled={
              disableAllFields || generalFarmInfoForm.csafUpdatedBySystem
            }
            selectedValue={generalFarmInfoForm.csafExperience}
            list={YesNoOrIdontknowOptions}
            csafToolTipSx={CSAFToolTipSx}
            label={PAGE_CONTENT.csaf_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              setFormValue({
                ...generalFarmInfoForm,
                csafExperience: e.target.value,
                csafPractices: '',
                csafFederalFunding: '',
                csafStateFunding: '',
                csafNonProfitFunding: '',
                csafMarketIncentives: '',
              });
              setErrorState((prev) => ({
                ...prev,
                csafPracticesRequiredError: FALSE,
                csafFederalFundingRequiredError: FALSE,
                csafStateFundingRequiredError: FALSE,
                csafNonProfitFundingRequiredError: FALSE,
                csafMarketIncentivesRequiredError: FALSE,
              }));
              makeFormDirty();
            }}
            primaryError={
              errorState.csafExperienceRequiredError && !disableAllFields
            }
            primaryErrorMessage={PAGE_CONTENT.csaf_primary_error_message}
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
            hasTooltipOnLabel
            tooltipContent={PAGE_CONTENT.csaf_tooltip}
          />
          {generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes && (
            <DropDown {...csafPracticesDropdownProps} />
          )}
          {generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes && (
            <FormLabels
              marginTop={STYLE_CONSTANTS.one_rem}
              marginBottom={STYLE_CONSTANTS.zero_point_five_rem}>
              {PAGE_CONTENT.funding_main_label}
            </FormLabels>
          )}
          {generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes && (
            <FlexWrapper>
              <RadioButtonsGroup
                isDisabled={
                  disableAllFields &&
                  isFarmCsafUpdatedBySystem &&
                  enableCsafChildren
                }
                hasCenterTooltip
                selectedValue={generalFarmInfoForm.csafFederalFunding}
                list={YesNoOrIdontknowOptions}
                label={PAGE_CONTENT.federal_funding_label}
                csafToolTipSx={CSAFToolTipSx}
                direction={PAGE_CONTENT.row}
                handleChange={(e) => {
                  fieldHandler(
                    formFieldsNames.csafFederalFunding,
                    e.target.value,
                  );
                  makeFormDirty();
                }}
                primaryError={
                  errorState.csafFederalFundingRequiredError &&
                  (!disableAllFields ||
                    isFarmCsafUpdatedBySystem ||
                    enableCsafChildren)
                }
                primaryErrorMessage={
                  PAGE_CONTENT.federal_funding_primary_error_message
                }
                marginTop={STYLE_CONSTANTS.one_rem}
                marginBottom={STYLE_CONSTANTS.zero_rem}
                marginRight={STYLE_CONSTANTS.zero_point_seven_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                customSx={fundingSx()}
                hasTooltipOnLabel
                tooltipContent={PAGE_CONTENT.federal_funding_tooltip}
              />
              <RadioButtonsGroup
                isDisabled={
                  disableAllFields &&
                  isFarmCsafUpdatedBySystem &&
                  enableCsafChildren
                }
                hasCenterTooltip
                selectedValue={generalFarmInfoForm.csafStateFunding}
                list={YesNoOrIdontknowOptions}
                label={PAGE_CONTENT.state_local_funding_label}
                csafToolTipSx={CSAFToolTipSx}
                direction={PAGE_CONTENT.row}
                handleChange={(e) => {
                  fieldHandler(
                    formFieldsNames.csafStateFunding,
                    e.target.value,
                  );
                  makeFormDirty();
                }}
                primaryError={
                  errorState.csafStateFundingRequiredError &&
                  (!disableAllFields ||
                    isFarmCsafUpdatedBySystem ||
                    enableCsafChildren)
                }
                primaryErrorMessage={
                  PAGE_CONTENT.state_local_funding_primary_error_message
                }
                marginTop={STYLE_CONSTANTS.one_rem}
                marginBottom={STYLE_CONSTANTS.zero_rem}
                marginRight={STYLE_CONSTANTS.zero_point_seven_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                customSx={fundingSx()}
                hasTooltipOnLabel
                tooltipContent={PAGE_CONTENT.state_local_funding_tooltip}
              />
              <RadioButtonsGroup
                isDisabled={
                  disableAllFields &&
                  isFarmCsafUpdatedBySystem &&
                  enableCsafChildren
                }
                hasCenterTooltip
                selectedValue={generalFarmInfoForm.csafNonProfitFunding}
                list={YesNoOrIdontknowOptions}
                label={PAGE_CONTENT.non_profit_funding_label}
                csafToolTipSx={CSAFToolTipSx}
                direction={PAGE_CONTENT.row}
                handleChange={(e) => {
                  fieldHandler(
                    formFieldsNames.csafNonProfitFunding,
                    e.target.value,
                  );
                  makeFormDirty();
                }}
                primaryError={
                  errorState.csafNonProfitFundingRequiredError &&
                  (!disableAllFields ||
                    isFarmCsafUpdatedBySystem ||
                    enableCsafChildren)
                }
                primaryErrorMessage={
                  PAGE_CONTENT.non_profit_funding_primary_error_message
                }
                marginTop={STYLE_CONSTANTS.one_rem}
                marginBottom={STYLE_CONSTANTS.zero_rem}
                marginRight={STYLE_CONSTANTS.zero_point_seven_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                customSx={fundingSx()}
                hasTooltipOnLabel
                tooltipContent={PAGE_CONTENT.non_profit_funding_tooltip}
              />
              <RadioButtonsGroup
                isDisabled={
                  disableAllFields &&
                  isFarmCsafUpdatedBySystem &&
                  enableCsafChildren
                }
                hasCenterTooltip
                selectedValue={generalFarmInfoForm.csafMarketIncentives}
                list={YesNoOrIdontknowOptions}
                label={PAGE_CONTENT.market_incentives_label}
                csafToolTipSx={CSAFToolTipSx}
                direction={PAGE_CONTENT.row}
                handleChange={(e) => {
                  fieldHandler(
                    formFieldsNames.csafMarketIncentives,
                    e.target.value,
                  );
                  makeFormDirty();
                }}
                primaryError={
                  errorState.csafMarketIncentivesRequiredError &&
                  (!disableAllFields ||
                    isFarmCsafUpdatedBySystem ||
                    enableCsafChildren)
                }
                primaryErrorMessage={
                  PAGE_CONTENT.market_incentives_primary_error_message
                }
                marginTop={STYLE_CONSTANTS.one_rem}
                marginBottom={STYLE_CONSTANTS.zero_rem}
                marginRight={STYLE_CONSTANTS.zero_point_seven_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                customSx={fundingSx()}
                hasTooltipOnLabel
                tooltipContent={PAGE_CONTENT.market_incentives_tooltip}
              />
            </FlexWrapper>
          )}
          <RadioButtonsGroup
            isDisabled={disableAllFields}
            selectedValue={generalFarmInfoForm.carbonRightsAssociated}
            list={YesOrNoOptions}
            label={PAGE_CONTENT.carbon_rights_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              fieldHandler(
                formFieldsNames.carbonRightsAssociated,
                e.target.value,
              );
              makeFormDirty();
            }}
            primaryError={
              errorState.carbonRightsAssociatedError && !disableAllFields
            }
            primaryErrorMessage={PAGE_CONTENT.carbon_rights_error}
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
          />
          <RadioButtonsGroup
            isDisabled={disableAllFields}
            selectedValue={generalFarmInfoForm.fmsUse}
            list={YesOrNoOptions}
            label={PAGE_CONTENT.fms_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              fieldHandler(formFieldsNames.fmsUse, e.target.value);
              setFmsInfo([fmsObj]);
              makeFormDirty();
            }}
            primaryError={errorState.fmsUseRequiredError && !disableAllFields}
            primaryErrorMessage={PAGE_CONTENT.fms_error_message}
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
          />
          {generalFarmInfoForm.fmsUse === PAGE_CONTENT.yes && (
            <SubsectionWrapper>
              <FormLabels
                marginTop={STYLE_CONSTANTS.zero_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                iserror={errorState.fmsListRequiredError}>
                {PAGE_CONTENT.fms_list_label}
              </FormLabels>
              {fmsInfo.length > 0 &&
                fmsInfo.map((fms, index) => {
                  let id = fms.id;
                  return (
                    <InputWithDeleteIconWrapper key={id}>
                      <InputField
                        isDisabled={disableAllFields}
                        noLabel
                        value={fms.fmsName}
                        customStyles={{
                          width: '100%',
                        }}
                        minWidth="0rem"
                        width="100%"
                        placeholder={PAGE_CONTENT.fms_list_placeholder}
                        isRequired
                        name={`${formFieldsNames.fmsList}${index}`}
                        primaryError={fms.fmsError && !disableAllFields}
                        primaryErrorMessage={
                          PAGE_CONTENT.fms_list_primary_error_message
                        }
                        onUpdate={(event) => {
                          updateFmsInfo(event, id);
                          makeFormDirty();
                        }}
                        deleteTestId={'delete-icon'}
                        rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                        customInputSx={InputSx}
                      />
                      {!disableAllFields && fmsInfo.length > 1 && (
                        <IconWrapper>
                          <DeleteIconWithHoverEffect
                            data-testid="delete-icon"
                            onClick={() => deleteFmsInfo(id)}
                            sx={deleteFmsIconSx}
                          />
                        </IconWrapper>
                      )}
                    </InputWithDeleteIconWrapper>
                  );
                })}
              {!disableAllFields && (
                <AddSectionButton
                  onClickHanlder={addFmsHandler}
                  label={PAGE_CONTENT.add_fms_button_label}
                  marginTop={STYLE_CONSTANTS.zero_point_five_rem}
                />
              )}
            </SubsectionWrapper>
          )}
          <RadioButtonsGroup
            isDisabled={disableAllFields}
            selectedValue={generalFarmInfoForm.soilSampling}
            list={YesOrNoOptions}
            label={PAGE_CONTENT.soil_sampling_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              fieldHandler(formFieldsNames.soilSampling, e.target.value);
              makeFormDirty();
            }}
            primaryError={
              errorState.soilSamplingRequiredError && !disableAllFields
            }
            primaryErrorMessage={
              PAGE_CONTENT.soil_sampling_primary_error_message
            }
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.zero_point_five_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
          />
          <RadioButtonsGroup
            isDisabled={disableAllFields}
            selectedValue={generalFarmInfoForm.agronomistUse}
            list={YesOrNoOptions}
            label={PAGE_CONTENT.agronomic_advisor_used_or_not_label}
            direction={PAGE_CONTENT.row}
            handleChange={(e) => {
              fieldHandler(formFieldsNames.agronomistUse, e.target.value);
              setErrorState((prev) => ({
                ...prev,
                agronomistUseOrganizationRequiredError: FALSE,
              }));
              makeFormDirty();
            }}
            primaryError={
              errorState.agronomistUseRequiredError && !disableAllFields
            }
            primaryErrorMessage={
              PAGE_CONTENT.agronomic_advisor_used_or_not_primary_error_message
            }
            marginRight={PAGE_CONTENT.margin_right}
            marginTop={STYLE_CONSTANTS.one_rem}
            marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            labelFontSize={STYLE_CONSTANTS.input_font_size}
            radioBtnPadding={radioBtnPadding}
          />
          {generalFarmInfoForm.agronomistUse === PAGE_CONTENT.yes && (
            <SubsectionWrapper>
              <InputField
                isDisabled={disableAllFields}
                value={generalFarmInfoForm.agronomistUseOrganization}
                label={PAGE_CONTENT.agronomist_organization_label}
                placeholder={PAGE_CONTENT.agronomist_organization_placeholder}
                width={PAGE_CONTENT.hundered_percent}
                isRequired
                name={formFieldsNames.agronomistUseOrganization}
                onUpdate={(e) => {
                  fieldHandler(
                    formFieldsNames.agronomistUseOrganization,
                    e.target.value,
                  );
                  makeFormDirty();
                }}
                primaryError={
                  errorState.agronomistUseOrganizationRequiredError &&
                  !disableAllFields
                }
                primaryErrorMessage={
                  PAGE_CONTENT.agronomist_organization_primary_error_message
                }
                customInputSx={InputSx}
                labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                rightSideLabelFormLabelMargin={STYLE_CONSTANTS.two_rem}
              />
            </SubsectionWrapper>
          )}
        </MainWrapper>
        <UserNavigationButtons
          saveAndContinueHandler={() => {
            setSaveAndContinueClicked(true);
            submitHandler(false, false, generalFarmInfoForm, currentFarmId);
          }}
          saveAndCloseHandler={() => {
            submitHandler(true, false, generalFarmInfoForm, currentFarmId);
          }}
          CancelHandler={navigateHandler}
        />
      </FormSection>
    </div>
  );
};

export default ParticipantGeneralFarmInfo;
