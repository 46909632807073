import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
  outlinedDropdownInputSx,
} from '../Modals.style';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { addZeroBeforeDot, checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { useForm } from 'hooks/useForm';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useContext, useEffect, useState } from 'react';
import {
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { RED, COOL_GRAY } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import {
  MODAL_CONTENT,
  edgeOfFieldFormInitialData,
  formFieldNames,
} from './EdgeOfFieldModal.content';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { FieldsRowWrapper, InputSx } from './EdgeOfFieldModal.style';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const empty_string = '';
const errorInitialState = {
  practiceType: false,
  area: false,
  speciesCategory: false,
  stripWidth: false,
  barrierWidth: false,
  rowCount: false,
  speciesDensity: false,
};
const EdgeOfFieldModal = ({
  modalOpen,
  setModalOpen,
  existingPracticeTypeIds,
  totalFieldArea = 10,
  tabWiseSubmitHandler = () => {},
}) => {
  const [errorState, setErrorState] = useState(errorInitialState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    edgeOfFieldFormInitialData,
  );
  const { practiceDropdown, speciesCategory } = useContext(
    baselineDropdownContext,
  );
  const [areaErrors, setAreaError] = useState({
    zeroError: false,
    largerValue: false,
  });
  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case formFieldNames.practiceType:
        setErrorState({
          ...errorState,
          practiceType: value === empty_string,
          barrierWidth: false,
          rowCount: false,
          stripWidth: false,
        });
        break;
      case formFieldNames.speciesCategory:
        setErrorState({
          ...errorState,
          speciesCategory: value === empty_string,
        });
        break;
      case formFieldNames.speciesDensity:
        setErrorState({
          ...errorState,
          speciesDensity: value === empty_string,
        });
        break;
    }
  };
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(edgeOfFieldFormInitialData);
    setErrorState(errorInitialState);
    setAreaError({
      zeroError: false,
      largerValue: false,
    });
  };
  const practiceTypeDropdownProps = {
    label: MODAL_CONTENT.practice_type_label,
    fontSize: '0.875rem',
    value: checkTernaryCondition(
      formValue.practiceType === empty_string,
      MODAL_CONTENT.practice_type_placeholder,
      formValue.practiceType,
    ),
    isDisabled: modalOpen.data?.formData[0]?.addedFromImplementedActivities,
    width: '13.75rem',
    height: '2rem',
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
    hasNoBottomMargin: true,
    customSx: outlinedDropdownInputSx,
    name: formFieldNames.practiceType,
    ariaLabel: formFieldNames.practiceType,
    dropDownPlaceholder: MODAL_CONTENT.practice_type_placeholder,
    customPlaceholderColor: COOL_GRAY,
    error: errorState.practiceType,
    errorMessage: MODAL_CONTENT.practice_type_error,
    listItemFontSize: '0.875rem',
    dropdownlist: practiceDropdown?.filter(
      (item) =>
        !existingPracticeTypeIds
          .filter((item) => item !== formValue.practiceType)
          .includes(item.value),
    ),
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.practiceType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: formFieldNames.practiceType,
  };
  const speciesCategoryDropdownProps = {
    label: MODAL_CONTENT.species_category_label,
    value: checkTernaryCondition(
      formValue.speciesCategory === empty_string,
      MODAL_CONTENT.species_category_placeholder,
      formValue.speciesCategory,
    ),
    isDisabled: false,
    minWidth: '9rem',
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
    width: '8.75rem',
    height: '2rem',
    fontSize: '0.875rem',
    listItemFontSize: '0.875rem',
    hasNoBottomMargin: true,
    name: formFieldNames.speciesCategory,
    ariaLabel: formFieldNames.speciesCategory,
    customSx: outlinedDropdownInputSx,
    customPlaceholderColor: COOL_GRAY,
    dropDownPlaceholder: MODAL_CONTENT.species_category_placeholder,
    error: errorState.speciesCategory,
    errorMessage: empty_string,
    dropdownlist: speciesCategory,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.speciesCategory,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: formFieldNames.speciesCategory,
  };

  const submitBtnHandler = () => {
    const practiceIsNone =
      practiceDropdown.filter(
        (item) => item.value === formValue.practiceType,
      )[0]?.label === 'None';
    const practiceIsEmpty = formValue.practiceType === empty_string;
    const errorValues = {
      practiceType: practiceIsEmpty,
      area: formValue.area === empty_string && !practiceIsNone,
      speciesDensity:
        MODAL_CONTENT.density_required_params.includes(
          practiceDropdown.filter(
            (item) => item.value === formValue.practiceType,
          )[0]?.label,
        ) && formValue.speciesDensity === empty_string,
      speciesCategory:
        formValue.speciesCategory === empty_string && !practiceIsEmpty,
      stripWidth:
        MODAL_CONTENT.strip_width_required_params.includes(
          practiceDropdown.filter(
            (item) => item.value === formValue.practiceType,
          )[0]?.label,
        ) && formValue.stripWidth === empty_string,
      barrierWidth:
        MODAL_CONTENT.barrier_width_required_params.includes(
          practiceDropdown.filter(
            (item) => item.value === formValue.practiceType,
          )[0]?.label,
        ) && formValue.barrierWidth === empty_string,
      rowCount:
        [MODAL_CONTENT.barrier_width_required_params[0]].includes(
          practiceDropdown.filter(
            (item) => item.value === formValue.practiceType,
          )[0]?.label,
        ) && formValue.rowCount === empty_string,
    };
    setErrorState(errorValues);
    if (
      (!Object.values(errorValues).includes(true) &&
        !Object.values(areaErrors).includes(true)) ||
      formValue.practiceType === 6
    ) {
      tabWiseSubmitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.edge_of_field_list,
      );
      modalCloseHandler();
    }
  };
  useEffect(() => {
    if (formValue.area !== empty_string && +formValue.area === 0) {
      setAreaError({ ...areaErrors, zeroError: true });
    } else {
      setAreaError({ ...areaErrors, zeroError: false });
    }
  }, [formValue.area]);
  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      setFormValue(
        checkTernaryCondition(modalData, modalData, edgeOfFieldFormInitialData),
      );
    }
  }, [modalOpen]);

  useEffect(() => {
    setIsSubmitDisabled(
      Object.values(errorState).includes(true) ||
        Object.values(areaErrors).includes(true),
    );
  }, [errorState, areaErrors]);
  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <FieldSectionWrapper marginBottom="0">
          <DropDown {...practiceTypeDropdownProps} />
        </FieldSectionWrapper>
        {practiceDropdown.filter(
          (item) => item.value === formValue.practiceType,
        )[0]?.label !== 'None' && (
          <>
            <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
              <InputField
                minWidth="8.75rem"
                width="8.75rem"
                predefinedUnit="acres"
                labelMarginTop="0.5rem"
                labelMarginBottom="0.25rem"
                rightSideLabelFormLabelMargin="0"
                hasPredefinedUnit
                required
                label={MODAL_CONTENT.area_label}
                name={formFieldNames.area}
                value={formValue.area}
                onUpdate={(e) => {
                  const value = e.target.value;
                  if (
                    value.match(/^\d*(\.\d{0,2})?$/) ||
                    value === empty_string
                  ) {
                    customFieldChangeHandler(
                      formFieldNames.area,
                      addZeroBeforeDot(e.target.value),
                    );
                    setErrorState({
                      ...errorState,
                      area: e.target.value === empty_string,
                    });
                    if (e.target.value > totalFieldArea) {
                      setAreaError({ ...areaErrors, largerValue: true });
                    } else {
                      setAreaError({ ...areaErrors, largerValue: false });
                    }
                  }
                }}
                placeholder={MODAL_CONTENT.area_placeholder}
                primaryError={
                  errorState.area || Object.values(areaErrors).includes(true)
                }
                primaryErrorMessage={checkTernaryCondition(
                  errorState.area,
                  MODAL_CONTENT.area_error_required,
                  checkTernaryCondition(
                    areaErrors.zeroError,
                    MODAL_CONTENT.area_zero_error,
                    checkTernaryCondition(
                      areaErrors.largerValue,
                      MODAL_CONTENT.area_limit_error.replace(
                        '{{field_area}}',
                        totalFieldArea,
                      ),
                      empty_string,
                    ),
                  ),
                )}
                maxLength={100}
              />
            </FieldSectionWrapper>
            <FieldsRowWrapper>
              {formValue.practiceType !== empty_string && (
                <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
                  <DropDown {...speciesCategoryDropdownProps} />
                </FieldSectionWrapper>
              )}
              {[MODAL_CONTENT.density_required_params].includes(
                practiceDropdown.filter(
                  (item) => item.value === formValue.practiceType,
                )[0]?.label,
              ) && (
                <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
                  <InputField
                    minWidth="7rem"
                    width="7rem"
                    rightSideLabelFormLabelMargin="0"
                    predefinedUnit={MODAL_CONTENT.trees_planted_unit}
                    labelMarginTop="0.5rem"
                    labelMarginBottom="0.25rem"
                    customInputSx={InputSx}
                    required
                    hasPredefinedUnit
                    label={MODAL_CONTENT.species_density_label}
                    name={formFieldNames.speciesDensity}
                    value={formValue.speciesDensity}
                    onUpdate={(e) => {
                      const value = e.target.value;
                      if (
                        value.match(/^\d*(\.\d{0,2})?$/) ||
                        value === empty_string
                      ) {
                        customFieldChangeHandler(
                          formFieldNames.speciesDensity,
                          addZeroBeforeDot(e.target.value),
                        );
                        setErrorState({
                          ...errorState,
                          speciesDensity: e.target.value === empty_string,
                        });
                      }
                    }}
                    placeholder={MODAL_CONTENT.row_count_placeholder}
                    primaryError={errorState.speciesDensity}
                    primaryErrorMessage=""
                    maxLength={100}
                  />
                </FieldSectionWrapper>
              )}
              {MODAL_CONTENT.strip_width_required_params.includes(
                practiceDropdown.filter(
                  (item) => item.value === formValue.practiceType,
                )[0]?.label,
              ) && (
                <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
                  <InputField
                    minWidth="7rem"
                    width="7rem"
                    predefinedUnit={MODAL_CONTENT.ft_unit}
                    rightSideLabelFormLabelMargin="0"
                    labelMarginTop="0.5rem"
                    labelMarginBottom="0.25rem"
                    customInputSx={InputSx}
                    hasPredefinedUnit
                    required
                    label={MODAL_CONTENT.strip_width_label}
                    name={formFieldNames.stripWidth}
                    value={formValue.stripWidth}
                    onUpdate={(e) => {
                      const value = e.target.value;
                      if (
                        value.match(/^\d*(\.\d{0,2})?$/) ||
                        value === empty_string
                      ) {
                        customFieldChangeHandler(
                          formFieldNames.stripWidth,
                          addZeroBeforeDot(e.target.value),
                        );
                        setErrorState({
                          ...errorState,
                          stripWidth: e.target.value === empty_string,
                        });
                      }
                    }}
                    placeholder={MODAL_CONTENT.strip_width_placeholder}
                    primaryError={errorState.stripWidth}
                    primaryErrorMessage=""
                    maxLength={100}
                  />
                </FieldSectionWrapper>
              )}
              {MODAL_CONTENT.barrier_width_required_params.includes(
                practiceDropdown.filter(
                  (item) => item.value === formValue.practiceType,
                )[0]?.label,
              ) && (
                <FieldSectionWrapper marginBottom="0">
                  <InputField
                    minWidth="7rem"
                    width="7rem"
                    predefinedUnit={MODAL_CONTENT.ft_unit}
                    labelMarginTop="0.5rem"
                    labelMarginBottom="0.25rem"
                    customInputSx={InputSx}
                    rightSideLabelFormLabelMargin="0"
                    hasPredefinedUnit
                    required
                    label={MODAL_CONTENT.barrier_width_label}
                    name={formFieldNames.barrierWidth}
                    value={formValue.barrierWidth}
                    onUpdate={(e) => {
                      const value = e.target.value;
                      if (
                        value.match(/^\d*(\.\d{0,2})?$/) ||
                        value === empty_string
                      ) {
                        customFieldChangeHandler(
                          formFieldNames.barrierWidth,
                          addZeroBeforeDot(e.target.value),
                        );
                        setErrorState({
                          ...errorState,
                          barrierWidth: e.target.value === empty_string,
                        });
                      }
                    }}
                    placeholder={MODAL_CONTENT.barrier_width_placeholder}
                    primaryError={errorState.barrierWidth}
                    primaryErrorMessage=""
                    maxLength={100}
                  />
                </FieldSectionWrapper>
              )}
              {[MODAL_CONTENT.barrier_width_required_params[0]].includes(
                practiceDropdown.filter(
                  (item) => item.value === formValue.practiceType,
                )[0]?.label,
              ) && (
                <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
                  <InputField
                    minWidth="7rem"
                    width="7rem"
                    rightSideLabelFormLabelMargin="0"
                    labelMarginTop="0.5rem"
                    labelMarginBottom="0.25rem"
                    customInputSx={InputSx}
                    required
                    label={MODAL_CONTENT.row_count_label}
                    name={formFieldNames.rowCount}
                    value={formValue.rowCount}
                    onUpdate={(e) => {
                      const value = e.target.value;
                      if (
                        value.match(/^\d*(\.\d{0,2})?$/) ||
                        value === empty_string
                      ) {
                        customFieldChangeHandler(
                          formFieldNames.rowCount,
                          addZeroBeforeDot(e.target.value),
                        );
                        setErrorState({
                          ...errorState,
                          rowCount: e.target.value === empty_string,
                        });
                      }
                    }}
                    placeholder={MODAL_CONTENT.row_count_placeholder}
                    primaryError={errorState.rowCount}
                    primaryErrorMessage=""
                    maxLength={100}
                  />
                </FieldSectionWrapper>
              )}
            </FieldsRowWrapper>
            {errorState.speciesCategory && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.species_category_error}
              </TextWrapper>
            )}
            {errorState.stripWidth && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.strip_width_error}
              </TextWrapper>
            )}
            {errorState.barrierWidth && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.barrier_width_error}
              </TextWrapper>
            )}
            {errorState.rowCount && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.row_count_error}
              </TextWrapper>
            )}
            {errorState.speciesDensity && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.species_density_error}
              </TextWrapper>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={() => {
            submitBtnHandler();
          }}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={() => {
            modalCloseHandler();
          }}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EdgeOfFieldModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  existingPracticeTypeIds: PropTypes.array,
  totalFieldArea: PropTypes.number,
  tabWiseSubmitHandler: PropTypes.func,
};
export default EdgeOfFieldModal;
