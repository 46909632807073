import styled from 'styled-components';
import { DARK_CHARCOAL, QUILL_GRAY, WHITE } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  border: 1px solid ${QUILL_GRAY};
  width: ${({ width }) => width};
`;
export const AccordionSx = {
  backgroundColor: WHITE,
  '.MuiAccordionSummary-root': {
    height: '44px',
    minHeight: '44px',
  },
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordion-root:before': {
    opacity: '0',
  },
};
export const AccordionSummarySx = {
  padding: '0rem 0.5rem 0rem 0.5rem',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0.5rem 0',
  },
  '&.Mui-expanded': {
    minHeight: '44px',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};
export const AccordionDetailsSx = {
  padding: '0px',
  margin: '0.5rem 0.5rem 0.5rem 2.5rem',
  display: 'flex',
  gap: '1.5rem',
  'overflow-x': 'auto',
};
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ArrowLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
export const TextWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  justify-content: end;
  align-items: center;
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ minWidth }) => minWidth};
  gap: 1rem;
`;
export const FieldLabel = styled.div`
  color: ${({ color }) => color || DARK_CHARCOAL};
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  padding: ${({ padding }) => padding};
`;
export const FieldValue = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
`;
export const FieldValueWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
