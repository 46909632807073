export const PAGE_CONTENT = {
  form_heading: 'Add new project',
  registry_label: 'Registry',
  co_benefits_lable: 'Co-Benefits',
  program_lable: 'What is the Program?',
  program_placeholder: 'Type your Program',
  registry_placeholder: 'Select Project Registry',
  co_benefits_placeholder: 'Select Co Benefits',
  projectid_label: 'Registry Project ID',
  projectid_placeholder: 'Type your registry project ID',
  projectid_checkbox_label: `I don't have a registry project ID`,
  projectname_label: 'What is the project name?',
  projectname_placeholder: "Type your project's name",
  projectdeveloper_label: 'Who is the project developer?',
  projectdeveloper_placeholder: 'Type your project developer’s name',
  projecttype_main_label: 'What is the Nature of the project?',
  project_type_1: 'Nature',
  project_type_2: 'Non-Nature',
  projectsub_label: 'What is the project type?',
  projectsub_placeholder: 'Type or select your project type',
  project_imp_status_label: 'What is the project status?',
  project_imp_status_placeholder: 'Type or select your project status',
  internal_review_label: 'Internal review status',
  internal_review_1: 'Under Review',
  internal_review_2: 'Internal Review Complete',
  project_desc_label: 'Project Description',
  project_desc_placeholder: "Type your project's description",
  project_notes_label: 'Project Notes',
  project_notes_placeholder: 'Type your project notes',
  project_methodology_label: 'What is the project methodology?',
  project_methodology_placeholder: 'Type your project methodology',
  project_country_label: 'What country is the project in?',
  project_country_placeholder: 'Type or select country',
  project_region_label: 'What region is the project in?',
  project_region_placehodler: 'Type or select region',
  project_submit_button: 'Submit',
  project_cancel_button: 'Cancel',
  project_agree_button: 'Agree',
  project_required_subtext: '(Required)',
  project_name_error_message: 'A project name is required for submission',
  project_implementation_errro_message:
    'A project implementation status is required for submission',
  project_review_status_error:
    'A internal review status is required for submission',
  project_dailogue_title: 'Please confirm you want to add ',
  project_update_dailogue_title: 'Please confirm you want to update ',
  price_assumption_type_label: 'Project type price assumption group',
  price_assumption_type_placeholder:
    'Select the project’s price assumption group',
  price_assumption_type_error:
    'A Project type price assumption group is required for submission',
  project_add_tags_label: 'Add Tags',
  project_add_tags_placeholder:
    'Type to add tags, use comma to separate multiple',
  project_registry_error: 'A registry selection is required for submission',
  project_desc_notes_error: 'You’ve exceeded the maximum character limit.',
  project_desc_notes_count: ' out of 3,000 character limit ',
  project_registry_id_error:
    'The registry project ID entered was not found in the system',
  deletedLabel: 'Deleted •',
};

export const getValueOrDefault = (value) => {
  return value === null ? '' : value;
};

export const getDefultDropdownValue = (value) => {
  return value === null || value === '' ? 'N/A' : value;
};

export const getRegionName = (countryName, countryList, regionList) => {
  const regionID = countryList.find(
    (countryObj) => countryObj.countryName === countryName,
  )?.regionId;
  const regionName = regionList.find(
    (regionObj) => regionObj.regionId === regionID,
  )?.regionName;
  return regionName;
};

export const CHARACTER_COUNT = 3000;
