import DialogBox from 'components/DialogBox/DialogBox.react';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import {
  PAGE_CONTENT,
  initialPipelineAcerageModalValues,
  inputStyleProps,
  totalAcresInputProps,
} from './PipelineAcerageModal.content';
import {
  DialogBoxButtonStyle,
  PracticeWrapper,
  PracticeListItem,
  ActivityCategory,
  ActivitiesTableHeading,
  ActivityTableData,
  CategoryWrapper,
} from '../AddContractModal/AddContractModal.style';
import { useForm } from 'hooks/useForm';
import { checkTernaryCondition, convertDateFormat } from 'utils/helper';
import axios from 'axios';
import { ADD_PIPELINE_ACREAGE, FETCH_PROJECT_CYCLES } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import InputField from 'components/FormComponents/InputField/InputField.react';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import Loader from 'components/Loader/Loader.react';
import {
  EdgeOfFieldTooltip,
  activityCategoryMapping,
} from '../AddContractModal/AddContractModal.content';

const PipelineAcerageModal = ({
  addPipelineAcresOpen,
  setAddPipelineAcresOpen,
  activityPracticeMapping,
  refreshHandler,
  projectId,
  participantId,
  projectCycleId,
  isEditMode = false,
}) => {
  const { setFormValue } = useForm(initialPipelineAcerageModalValues);
  const [activityPracticeData, setActivityPracticeData] = useState(
    activityPracticeMapping,
  );
  const [totalEnrolledAcres, setTotalEnrolledAcres] = useState(
    activityPracticeMapping.totalAcres,
  );
  const [loading, setLoading] = useState(false);
  const [projectCycles, setProjectCycles] = useState([]);

  useEffect(() => {
    let activityPracticeMapCopy = { ...activityPracticeMapping };
    delete activityPracticeMapCopy.totalAcres;
    const setPipelineUnitErrors = (parameter) => {
      {
        /* sonar lint  */
      }
    };

    setTotalEnrolledAcres(activityPracticeMapping.totalAcres);
    setActivityPracticeData(activityPracticeMapCopy);

    const incentiveRateErrorArr = Object.keys(activityPracticeMapping).flatMap(
      (activityCategory) => {
        if (Array.isArray(activityPracticeMapping[activityCategory])) {
          return activityPracticeMapping[activityCategory].map((practice) => {
            return { projectActivityId: practice.projectActivityId, error: '' };
          });
        } else {
          return [];
        }
      },
    );
    setPipelineUnitErrors(incentiveRateErrorArr);
  }, [activityPracticeMapping]);

  const onClose = () => {
    setAddPipelineAcresOpen({ isOpen: false, participantId: null });
    setFormValue({ pipelineUnits: [] });
    refreshHandler();
  };

  const onSubmit = () => {
    // Enrolled acres not provided
    const formattedActivityData = Object.keys(activityPracticeData).flatMap(
      (activityCategory) => {
        if (Array.isArray(activityPracticeMapping[activityCategory])) {
          return activityPracticeData[activityCategory].map((practice) => {
            return {
              projectActivityId: practice.projectActivityId,
              pipelineUnits: Number(practice.pipelineUnits),
            };
          });
        } else {
          return [];
        }
      },
    );
    setLoading(true);
    axios
      .post(ADD_PIPELINE_ACREAGE, formattedActivityData, {
        params: {
          participantId: participantId,
          projectId: projectId,
          projectCycleId: projectCycleId,
          totalEnrolledAcres:
            totalEnrolledAcres === undefined || totalEnrolledAcres === ''
              ? 0
              : Number(totalEnrolledAcres),
          pipelineUnits: JSON.stringify(formattedActivityData),
        },
      })
      .then(() =>
        toast(
          <CustomSnackbar
            type="success"
            message={
              isEditMode
                ? PAGE_CONTENT.edit_toast_success_message
                : PAGE_CONTENT.toast_success_message
            }
          />,
          toastStyle,
        ),
      )
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={
              isEditMode
                ? PAGE_CONTENT.edit_toast_error_message
                : PAGE_CONTENT.toast_error_message
            }
          />,
          toastStyle,
        );
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const handlePracticeDataUpdate = (
    key,
    value,
    activityId,
    activityCategory,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalNumberRegex.test(value)) {
      let pipelineError = '';
      if (key === PAGE_CONTENT.incentive_rate) {
        if (value === '') {
          pipelineError = PAGE_CONTENT.incentive_rate_required_error;
        } else if (Number(value) < 1) {
          pipelineError = PAGE_CONTENT.incentive_rate_min_limit_error;
        }
      }

      setActivityPracticeData((prev) => {
        return {
          ...prev,
          [activityCategory]: prev[activityCategory].map((practice) => {
            if (practice.projectActivityId === activityId) {
              return {
                ...practice,
                [key]: checkTernaryCondition(value === '.', '0.', value),
                error: pipelineError,
              };
            }
            return practice;
          }),
        };
      });
    } else {
      setActivityPracticeData((prev) => {
        return {
          ...prev,
          [activityCategory]: prev[activityCategory].map((practice) => {
            if (practice.projectActivityId === activityId) {
              return {
                ...practice,
                [key]: '',
              };
            }
            return practice;
          }),
        };
      });
    }
  };

  const enrolledAcresUpdate = (event) => {
    const decimalRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalRegex.test(event.target.value)) {
      setTotalEnrolledAcres(
        checkTernaryCondition(
          event.target.value === '.',
          '0.',
          event.target.value,
        ),
      );
    } else {
      setTotalEnrolledAcres('');
    }
  };

  const fetchProjectCycle = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;
        const cycleData = data.map((projectCycle) => {
          const endYear = new Date(projectCycle.cycleEndDate).getFullYear();
          return {
            label: `${endYear} (${convertDateFormat(
              projectCycle.cycleStartDate,
            )}-${convertDateFormat(projectCycle.cycleEndDate)})`,
            value: projectCycle.projectCycleId,
          };
        });

        setProjectCycles(cycleData);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const startYearLabel = useMemo(() => {
    const startCycleLabel = projectCycles.find(
      (cycle) => Number(cycle.value) === Number(projectCycleId),
    )?.label;
    return startCycleLabel ?? '';
  }, [projectCycles, projectCycleId]);

  useEffect(() => {
    fetchProjectCycle();
  }, []);

  return (
    <DialogBox
      title={isEditMode ? PAGE_CONTENT.editTitle : PAGE_CONTENT.title}
      isOpen={addPipelineAcresOpen.isOpen}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      acceptCtnLabel={PAGE_CONTENT.submit}
      declineCtnLabel={PAGE_CONTENT.cancel}
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      buttonGap="0.5rem"
      dialogActions
      darkGreenBtnColor>
      <Loader loading={loading} zIndex={99999} />

      <InputField
        value={totalEnrolledAcres}
        onUpdate={enrolledAcresUpdate}
        label={`${PAGE_CONTENT.total_enrolled_acres_label} ${startYearLabel}.`}
        {...totalAcresInputProps}
      />
      <ActivitiesTableHeading>
        {PAGE_CONTENT.activities_table_heading}
      </ActivitiesTableHeading>

      <table style={{ width: '28.5rem' }}>
        <tr>
          <ActivityTableData width="216px">
            Activities + practices
          </ActivityTableData>
          <ActivityTableData width="6.875rem" alignment="right">
            Pipeline units
          </ActivityTableData>
        </tr>
        <tr>
          <ActivityTableData></ActivityTableData>
          <ActivityTableData alignment="right">Acres</ActivityTableData>
        </tr>
      </table>
      {Object.keys(activityPracticeData)?.map((activityCategory) => {
        if (activityCategory === 'totalAcres') return null;
        const category = activityCategoryMapping[activityCategory];
        const isCoverCropOrNutrient =
          category === 'Cover crop' || category === 'Nutrient management';

        return (
          <CategoryWrapper key={activityCategory}>
            {!isCoverCropOrNutrient && (
              <ActivityCategory>
                {category}
                {category === 'Edge of field' && (
                  <InfoTooltip
                    title=""
                    bottomMargin="-4px"
                    placement="right"
                    customSx={{ marginLeft: '0.5rem' }}
                    content={<EdgeOfFieldTooltip />}
                  />
                )}
              </ActivityCategory>
            )}

            {activityPracticeData[activityCategory]?.map((practice) => {
              return (
                <PracticeWrapper key={practice.projectActivityId}>
                  {isCoverCropOrNutrient && (
                    <ActivityCategory>{category}</ActivityCategory>
                  )}
                  {!isCoverCropOrNutrient && (
                    <PracticeListItem>
                      {practice.projectActivityType}
                    </PracticeListItem>
                  )}

                  <InputField
                    value={practice.pipelineUnits}
                    placeholder={PAGE_CONTENT.number_field_placeholder}
                    onUpdate={(event) =>
                      handlePracticeDataUpdate(
                        'pipelineUnits',
                        event.target.value,
                        practice.projectActivityId,
                        activityCategory,
                      )
                    }
                    primaryError={practice.error?.length > 0}
                    {...inputStyleProps}
                  />
                </PracticeWrapper>
              );
            })}
          </CategoryWrapper>
        );
      })}
    </DialogBox>
  );
};

PipelineAcerageModal.propTypes = {
  addPipelineAcresOpen: PropTypes.bool,
  setAddPipelineAcresOpen: PropTypes.func,
  refreshHandler: PropTypes.func,
  activityPracticeMapping: PropTypes.object,
  projectId: PropTypes.number,
  participantId: PropTypes.number,
  projectCycleId: PropTypes.number,
  isEditMode: PropTypes.bool,
};

export default PipelineAcerageModal;
