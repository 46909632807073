export const MODAL_CONTENT = {
  heading: 'Livestock grazing',
  livestock_type_label: 'Livestock type',
  livestock_type_placeholder: 'Select livestock type',
  prescirbed_grazing_type_label: 'Prescribed grazing system',
  prescirbed_grazing_type_placeholder: 'Select system',
  average_weight_label: 'Average weight',
  average_weight_placeholder: 'Type amount',
  unit_of_measure_label: 'Unit of measure',
  unit_of_measure_placeholder: 'Select unit',
  livestock_rating_per_field_label: 'Stocking rate per field',
  livestock_rating_per_field_placeholder: 'Type amount',
  annual_grazing_days_label: 'Annual grazing days',
  annual_grazing_days_placeholder: 'Type days',
  manure_management_label: 'Manure management system(s)',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  type_error: 'Livestock type selection is required.',
  grazing_type_error: 'Prescribed grazing system selection is required.',
  stocking_rate_error: 'Stocking rate per field amount is required.',
  grazing_days_error: 'Annual grazing days is required.',
  annual_weight_error: 'Annual weight amount is required.',
  uom_error: 'Unit of measure selection is required.',
  manure_management_error: 'Manure management system selection is required.',
  livestock_grazing_area_label: 'Livestock grazing area',
  livestock_grazing_area_placeholder: 'Type area',
  livestock_grazing_area_unit: 'acres',
  livestock_grazing_area_required_error: 'Livestock grazing area is required.',
  livestock_grazing_area_zero_error:
    'Livestock grazing area must be greater than 0.',
  livestock_grazing_area_exceeded_error:
    'Livestock grazing area can’t exceed total area of the field - {{field_area}} acres.',
};

export const livestockTypeOptions = [
  { label: 'Cattle', value: 'Cattle' },
  { label: 'Female dairy cattle', value: 'Female dairy cattle' },
  { label: 'Swine', value: 'Swine' },
  { label: 'Breeding swine', value: 'Breeding swine' },
];

export const prescribedGrazingTypeOptions = [
  { label: 'None', value: 'None' },
  { label: 'Cell grazing', value: 'Cell grazing' },
  { label: 'Deferred rotational', value: 'Deferred rotational' },
  { label: 'Management intensive', value: 'Management intensive' },
  { label: 'Rest-rotation', value: 'Rest-rotation' },
];

export const unitOfMeasurementOptions = [
  { label: 'Animal units per acre', value: 'Animal units per acre' },
  { label: 'Carcass pounds per animal', value: 'Carcass pounds per animal' },
  { label: 'Head per acre', value: 'Head per acre' },
  {
    label: 'Hundred-weights (or pounds) per head',
    value: 'Hundred-weights (or pounds) per head',
  },
  {
    label: 'Liveweight pounds per animal',
    value: 'Liveweight pounds per animal',
  },
  {
    label: 'Liveweight lbs per head',
    value: 'Liveweight lbs per head',
  },
];

export const formFieldNames = {
  livestockType: 'livestockType',
  prescribedGrazingType: 'prescribedGrazingType',
  averageWeight: 'averageWeight',
  unitOfMeasure: 'unitOfMeasure',
  livestockStockingRatePerField: 'livestockStockingRatePerField',
  annualGrazingDays: 'annualGrazingDays',
  manureManagementSystem: 'manureManagementSystem',
  livestockGrazingArea: 'livestockGrazingArea',
};

export const liveStockTypeFormInitialData = {
  livestockType: '',
  prescribedGrazingType: '',
  averageWeight: '',
  unitOfMeasure: '',
  livestockStockingRatePerField: '',
  annualGrazingDays: '',
  manureManagementSystem: '',
  livestockGrazingArea: '',
};

export const modalErrorInitialState = {
  livestockType: false,
  prescribedGrazingType: false,
  averageWeight: false,
  unitOfMeasure: false,
  livestockStockingRatePerField: false,
  annualGrazingDays: false,
  manureManagementSystem: false,
  livestockGrazingAreaRequiredError: false,
  livestockGrazingAreqEqualToZeroError: false,
  livestockGrazingAreaExceededError: false,
};
