import styled from 'styled-components';
import { NavLink as MuiNavLink } from 'react-router-dom';
import {
  BERLIN_BLUE,
  DOWNLOAD_LINK,
  GRAYISH_BLACK,
  LIGHT_GRAY,
  QUILL_GRAY,
  ROW_FOCUSED_BG,
  ROW_HOVER_BG,
  TABS_HIGHLIGHT_BORDER,
  TEXT_HOVER_COLOR,
} from 'theme/GlobalColors';
import { Container } from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';

const sideNavTextColor = '#333333';

export const NavModuleWrapper = styled(Container)(({ hasNoBorder }) => ({
  borderTop: checkTernaryCondition(
    hasNoBorder,
    'none',
    `1px solid ${QUILL_GRAY}`,
  ),
  fontFamily: 'Open Sans, sans-serif',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '19.07px',
  padding: '0rem',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const NavTitle = styled.div`
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 700;
  color: ${sideNavTextColor};
  margin-bottom: 5px;
`;

export const NavLinksWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: '0.5rem',
}));

export const NavLink = styled(MuiNavLink)(() => ({
  padding: '0.25rem 1rem',
  fontSize: '14px',
  fontWeight: 400,
  textDecoration: 'none',
  color: DOWNLOAD_LINK,
  display: 'flex',
  justifyContent: 'baseline',
  boxSizing: 'border-box',
  alignItems: 'center',
  '&.active': {
    fontWeight: 600,
    borderLeft: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
    backgroundColor: ROW_FOCUSED_BG,
    color: BERLIN_BLUE,
    padding: '0.25rem 0.8rem',
  },
  '&.disabled': {
    border: 'none',
    fontWeight: 400,
    pointerEvents: 'none',
    color: GRAYISH_BLACK,
    cursor: 'default',
    backgroundColor: 'inherit',
  },
  '&:hover': {
    backgroundColor: ROW_HOVER_BG,
    color: TEXT_HOVER_COLOR,
  },
  '&:last-child': {
    marginTop: 'auto',
    paddingTop: '1.05rem',
    paddingBottom: '1rem',
    borderTop: `1px ${LIGHT_GRAY} solid`,
  },
  '&:not(:last-child)': {
    height: '1.75rem',
  },
}));
