import axios from 'axios';
import {
  ADD_PARTICIPANT_CONTRIBUTOR,
  FETCH_ADD_CONTRIBUTOR_FARM_AND_PROJECT,
  FETCH_ALL_CONTRIBUTORS,
  FETCH_EXISITING_PARTICIPANT_CONTRIBUTORS,
  FETCH_FARMS_FOR_PARTICIPANT,
  FETCH_PARTICIPANT_CONTRIBUTORS_DATA,
  FETCH_PROFILE_PARTICIPANT_CONTRIBUTOR,
  SEND_PARTICIPANT_ACTIVITY_EMAIL,
  SEND_PROFILE_PARTICIPANT_CONTRIBUTOR,
} from 'urls';
import {
  checkTernaryCondition,
  getCommaSeperatedStringValues,
  formatPhoneNumber,
} from 'utils/helper';
import { getCommnicationMethodList } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { errorToastMessage } from './ParticipantContributorsForm.style';
import {
  ERROR_INITIAL_STATE,
  FORM_CONTENT,
} from './ParticipantContributorsForm.content';

export const fetchExistingContributors = ({
  setterFunc,
  ownerId,
  projectId,
}) => {
  axios.get(FETCH_EXISITING_PARTICIPANT_CONTRIBUTORS).then((response) => {
    setterFunc(
      response.data.map((data) => ({
        label: `${data.participantFirstName} ${data.participantLastName} <${data.participantEmail}>`,
        value: data.participantId,
        participantContributor: true,
        participantEmail: data.participantEmail,
        participantFirstName: data.participantFirstName,
        participantId: data.participantId,
        participantLastName: data.participantLastName,
        participantOwnerId: ownerId,
        phone: data.participantPhone,
        primaryCommunication: data.primaryCommunication,
        projectId: projectId,
        status: 'Existing Contributor',
        lastInviteSent: data.activationLinkLastSentTime,
      })),
    );
  });
};

export const fetchFarmsForContributor = ({
  participantId,
  internalProjectId,
  enrollmentInstanceId,
}) => {
  const apiParams = {
    internalProjectId,
    participantId,
    enrollmentInstanceId,
  };

  return axios
    .get(FETCH_FARMS_FOR_PARTICIPANT, { params: apiParams })
    .then((response) =>
      response.data.map((data) => ({
        label: data.value,
        value: data.id,
        instanceId: data.enrollmentInstanceId,
      })),
    );
};

export const sendInvite = ({
  enrollmentInstanceId,
  contributorId,
  setLastInvite = null,
}) => {
  axios
    .post(`${SEND_PARTICIPANT_ACTIVITY_EMAIL}`, {
      enrollmentInstanceId,
      participantId: contributorId,
    })
    .then((response) => {
      if (setLastInvite && response.data.activationLinkLastSentTime) {
        const date = new Date(response.data.activationLinkLastSentTime);
        const day = String(date.getDate());
        const month = String(date.getMonth() + 1); // January is 0!
        const year = date.getFullYear();
        const formattedLastInviteSentDate = `${month}/${day}/${year}`;
        setLastInvite({
          inviteSentStatus: true,
          inviteSentDate: formattedLastInviteSentDate,
        });
      }
    })
    .catch(() => {
      toast(
        <CustomSnackbar
          type="error"
          message={FORM_CONTENT.invite_error_toast_message}
        />,
        errorToastMessage,
      );
    });
};
export const addFarmContributor = async ({
  participantForm,
  participantOwnerId,
  selectedCategories,
  selectedSubItems,
  participantId = null,
  refreshHandler,
  isFormEdited = false,
  projectCycleId,
}) => {
  const filteredPrimaryCommunicationList = participantForm.primaryCommunication
    .filter((filteredList) => filteredList.checked === true)
    .map((item) => item.itemLabel);
  const successMessage = `${participantForm.participantFirstName} ${participantForm.participantLastName} was successfully added.`;
  const successMessageForEdit = `${participantForm.participantFirstName} ${participantForm.participantLastName} was successfully updated.`;
  const errorMessage = `${participantForm.participantFirstName} ${participantForm.participantLastName} failed to be added. Please try again.`;
  const errorMessageForEdit = `${participantForm.participantFirstName} ${participantForm.participantLastName} failed to be updated. Please try again.`;
  try {
    const response = await axios.post(
      `${SEND_PROFILE_PARTICIPANT_CONTRIBUTOR}?projectCycleId=${projectCycleId}`,
      {
        participantOwnerId: Number(participantOwnerId),
        participantFirstName: participantForm.participantFirstName,
        participantLastName: participantForm.participantLastName,
        participantEmail: participantForm.participantEmail,
        participantId: participantId ? participantId : null,
        phone: formatPhoneNumber(participantForm.participantPhone),
        primaryCommunication: filteredPrimaryCommunicationList.toString(),
        farmProjectAssignment: selectedCategories.map((farmId) => {
          const projectId = Number(selectedSubItems[farmId].toString());
          return { farmId: farmId.toString(), projects: [{ projectId }] };
        }),
      },
    );
    toast(
      <CustomSnackbar
        type="success"
        message={!isFormEdited ? successMessage : successMessageForEdit}
      />,
      errorToastMessage,
      refreshHandler(),
    );
    return response.data;
  } catch {
    toast(
      <CustomSnackbar
        type="error"
        message={!isFormEdited ? errorMessage : errorMessageForEdit}
      />,
      errorToastMessage,
    );
  }
};
export const fetchAddContributorFarmAndProject = async (participantOwnerId) => {
  return axios
    .get(FETCH_ADD_CONTRIBUTOR_FARM_AND_PROJECT(participantOwnerId))
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};
export const fetchProfileParticipantContributor = async (
  participantId,
  participantOwnerId,
) => {
  return axios
    .get(
      FETCH_PROFILE_PARTICIPANT_CONTRIBUTOR(participantId, participantOwnerId),
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};
export const submitContributorData = ({
  exisitingAssignedData,
  newSubmitData,
  exisitingContributorsList,
  participantId,
  internalProjectId,
  navigateToNext,
  thenBlock,
  sentInviteClickId,
  deleteId,
  setLoading,
  isCloseClicked,
  instanceId,
  sentInviteClickIndex,
  appendContributor,
  setLastInvite,
  projectCycleId,
}) => {
  setLoading(true);
  const submitData = getSubmitDataBody({
    exisitingAssignedData,
    newSubmitData,
    exisitingContributorsList,
    participantId,
    internalProjectId,
    sentInviteClickId,
    instanceId,
    sentInviteClickIndex,
  });
  axios
    .post(
      `${ADD_PARTICIPANT_CONTRIBUTOR}?internalProjectId=${internalProjectId}&participantOwnerId=${participantId}&projectCycleId=${projectCycleId}`,
      submitData,
    )
    .then((response) => {
      thenBlock(
        response.data,
        navigateToNext,
        deleteId,
        isCloseClicked,
        sentInviteClickIndex,
        appendContributor,
        setLastInvite,
      );
    })
    .catch((err) => {
      if (err.response?.status === 404) {
        toast(
          <CustomSnackbar
            type="error"
            message={FORM_CONTENT.invite_error_toast_message}
          />,
          errorToastMessage,
        );
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getSubmitDataBody = ({
  exisitingAssignedData,
  newSubmitData,
  exisitingContributorsList,
  participantId,
  internalProjectId,
  sentInviteClickId,
  instanceId,
  sentInviteClickIndex = -1,
}) => {
  const participantContributors = Array.isArray(
    exisitingAssignedData.participantContributors,
  )
    ? exisitingAssignedData.participantContributors
    : [exisitingAssignedData.participantContributors];

  const existingParticipantData = checkTernaryCondition(
    participantContributors.length > 0,
    extractExistingParticipantSubmitData(
      participantContributors,
      exisitingContributorsList,
      exisitingAssignedData.assignedFarms,
      instanceId,
      sentInviteClickIndex,
    ),
    [],
  );
  const newParticipantData = newSubmitData
    .filter((data) => isFormFilled(data))
    .map((data) =>
      extractNewParticipantSubmitData(
        data,
        participantId,
        internalProjectId,
        sentInviteClickId,
        instanceId,
      ),
    );
  return [...existingParticipantData, ...newParticipantData];
};

export const extractExistingParticipantSubmitData = (
  existingparticipantData,
  exisitingContributorsList,
  assignedFarms,
  instanceId,
  sentInviteClickIndex,
) => {
  const structuredDataList = [];
  existingparticipantData.forEach((data) => {
    const existingData = exisitingContributorsList.find(
      (contributor) => contributor.label === data,
    );
    structuredDataList.push({
      enrollmentInstanceId: instanceId,
      farmIdList: assignedFarms,
      participantContributor: true,
      participantId: +existingData.value,
      participantEmail: existingData.participantEmail,
      participantFirstName: existingData.participantFirstName,
      participantLastName: existingData.participantLastName,
      participantOwnerId: existingData.participantOwnerId,
      phone: existingData.phone,
      primaryCommunication: existingData.primaryCommunication,
      projectId: +existingData.projectId,
      status: checkTernaryCondition(
        sentInviteClickIndex === -1,
        'New Contributor Invite Sent',
        'Existing Contributor',
      ),
    });
  });
  return structuredDataList;
};

export const getStatus = (isInviteSent, existingStatus) => {
  if (existingStatus === 'New Contributor Invite Sent') {
    return 'New Contributor Invite Sent';
  }
  return checkTernaryCondition(
    isInviteSent,
    'New Contributor Invite Sent',
    'New Contributor',
  );
};

export const extractNewParticipantSubmitData = (
  newSubmitData,
  participantId,
  internalProjectId,
  sentInviteClickId,
  instanceId,
) => {
  return {
    enrollmentInstanceId: instanceId,
    farmIdList: newSubmitData.assignedFarms,
    participantContributor: true,
    participantId: checkTernaryCondition(
      typeof newSubmitData.id === 'string',
      0,
      +newSubmitData.id,
    ),
    participantEmail: newSubmitData.email,
    participantFirstName: newSubmitData.firstName,
    participantLastName: newSubmitData.lastName,
    participantOwnerId: +participantId,
    phone: newSubmitData.phone,
    primaryCommunication: getCommaSeperatedStringValues(
      newSubmitData.communicationMethod
        .filter((method) => method.checked)
        .map((method) => method.itemLabel),
    ),
    projectId: +internalProjectId,
    status: getStatus(
      sentInviteClickId === newSubmitData.id,
      newSubmitData.status,
    ),
  };
};

export const isFormFilled = (formData) => {
  const {
    firstName,
    lastName,
    email,
    assignedFarms,
    phone,
    communicationMethod,
  } = formData;
  return (
    firstName.trim() &&
    lastName.trim() &&
    email.trim() &&
    assignedFarms.length > 0 &&
    phone.trim() &&
    communicationMethod.some((method) => method.checked)
  );
};

export const fetchContributorsData = ({
  internalProjectId,
  participantOwnerId,
  setExisitingParticipantsList,
  setAddedContributors,
  setLoading,
  setErrorList,
}) => {
  setLoading(true);
  axios
    .get(
      `${FETCH_PARTICIPANT_CONTRIBUTORS_DATA}?internalProjectId=${internalProjectId}&participantOwnerId=${participantOwnerId}`,
    )
    .then((response) => {
      const existingParticipantData = response.data.filter(
        (data) => data.status === 'Existing Contributor',
      );
      const exisitingParticipantList = [];
      existingParticipantData.forEach((data) => {
        exisitingParticipantList.push(
          `${data.participantFirstName} ${data.participantLastName} <${data.participantEmail}>`,
        );
      });
      exisitingParticipantList.length > 0 &&
        setExisitingParticipantsList({
          participantContributors: exisitingParticipantList,
          assignedFarms: existingParticipantData[0].farmIdList,
          assignedFarmsError: false,
        });
      const newParticipantData = response.data.filter(
        (data) =>
          data.status === 'New Contributor' ||
          data.status === 'New Contributor Invite Sent',
      );
      const newParticipantList = newParticipantData.map((data) => ({
        id: data.participantId,
        firstName: data.participantFirstName,
        lastName: data.participantLastName,
        email: data.participantEmail,
        phone: data.phone,
        communicationMethod: getCommnicationMethodList(
          data.primaryCommunication || '',
        ),
        assignedFarms: data.farmIdList,
        status: data.status,
      }));
      setAddedContributors(newParticipantList);
      setErrorList(
        newParticipantList.map((data) => ({
          id: data.id,
          ...ERROR_INITIAL_STATE,
        })),
      );
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getIsSendLinkDisabled = (isFormFilled, status) => {
  if (!isFormFilled || status === 'New Contributor Invite Sent') {
    return true;
  } else {
    return false;
  }
};

export const fetchAllParticipantContributors = () => {
  return axios.get(FETCH_ALL_CONTRIBUTORS).catch(() => {});
};
