export const PAGE_CONTENT = {
  terms_of_use: 'Terms of Use',
  privacy: 'Privacy',
  cookies: 'Cookies',
  copyright: 'Deloitte Development LLC. All rights reserved.',
  cookies_link: 'https://cookienotice.deloitte.com',
  privacyPolicyLink:
    'https://www2.deloitte.com/us/en/footerlinks1/deloitte-commercial-asset-privacy-notice.html',
  privacyPolicyText: 'Deloitte Privacy Policy',
};
