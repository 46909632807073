import { Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useStyles } from '../../../../theme/GlobalStyles';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { ProjectLinkNameWrapper } from '../Participants/ParticipantTabs/ParticipantSummary.style';
import {
  breadcrumbStyles,
  linkStyles,
  TabberWrapperStyle,
  TabPanelStyle,
} from '../Org_Projects.style';
import ParticipantProfile from './ParticipantSummary/ParticipantProfile.react';
import {
  GET_FARM_AND_FIELDS_FOR_PARTICIPANTS,
  GET_PARTICIPANT_INFO,
} from 'urls';
import axios from 'axios';
import {
  LABELS,
  PARTICIPANT_TABS,
  ALL_PROJECTS_PATH,
  PARTICIPANT_PARENT_PATH,
} from './Participants.content';
import ParticipantSummary from './ParticipantTabs/ParticipantSummary.react';
import ParticipantActivity from './ParticipantActivity/ParticipantActivity.react';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { breadcrumbSx } from './Participants.style';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { hasAnyRequiredAccess } from 'utils/helper';
import { PARTICIPANT, PARTICIPANT_CONTRIBUTOR } from 'utils/config';
const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `participant-page-tab-${index}`,
  };
};

const Participant_Tab = ({ disableVirtualization }) => {
  const [name, setName] = useState();
  const { TabberBox } = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [serachParams] = useSearchParams();
  const projectId = serachParams.get('projectid');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [participantInfo, setParticipantInfo] = useState([]);
  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };
  const [participantType, setParticipantType] = useState(null);
  const [projectData, setProjectData] = useState({});
  const { userRoles } = useContext(userAuthRolesContext);
  const breadcrumbs = useMemo(
    () => [
      <Link
        key="breadcrumb-1"
        to={ALL_PROJECTS_PATH}
        style={{ ...breadcrumbStyles, ...linkStyles }}>
        {LABELS.projects}
      </Link>,
      <Link
        key="breadcrumb-2"
        to={ALL_PROJECTS_PATH + '/' + projectData?.internalProjectId}
        style={{ ...breadcrumbStyles, ...linkStyles }}>
        <ProjectLinkNameWrapper title={projectData?.projectName}>
          {projectData?.projectName}
        </ProjectLinkNameWrapper>
      </Link>,
      <Link
        key="breadcrumb-3"
        to={ALL_PROJECTS_PATH + '/' + projectData?.internalProjectId}
        state={{ from: PARTICIPANT_PARENT_PATH }}
        style={{ ...breadcrumbStyles, ...linkStyles }}>
        {LABELS.projectParticipants}
      </Link>,
      <Typography sx={breadcrumbStyles} key="breadcrumb-2" color="text.primary">
        <ProjectLinkNameWrapper title={name}>{name}</ProjectLinkNameWrapper>
      </Typography>,
    ],
    [name],
  );

  let participantTabs = [
    {
      title: PARTICIPANT_TABS.summary,
      component: (
        <ParticipantSummary
          projectList={projectList}
          loading={loading}
          participantInfo={participantInfo}
          participantId={id}
        />
      ),
    },
    {
      title: PARTICIPANT_TABS.profile,
      component: <ParticipantProfile projectData={projectData} />,
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: PARTICIPANT_TABS.activity,
      component: (
        <ParticipantActivity
          disableVirtualization={disableVirtualization}
          participantName={name}
          projectList={projectList}
          participantId={id}
          projectData={projectData}
        />
      ),
    },
  ];
  if (participantType === LABELS.participant_contributor) {
    participantTabs = [participantTabs[1]];
  }

  const fetchParticipantDetails = () => {
    setLoading(true);
    axios
      .post(`${GET_PARTICIPANT_INFO}`, {
        enrolmentType: 'Baseline',
        pageNumber: 0,
        pageSize: 10,
        participantId: id,
      })
      .then((response) => {
        setName(
          `${response?.data?.content[0]?.firstName} ${response?.data?.content[0]?.lastName}`,
        );
        const finalProjectsList = [];
        response?.data?.content.map((row) =>
          finalProjectsList.push(row?.project),
        );
        setProjectList(finalProjectsList);
        setParticipantType(response?.data?.content[0]?.participantType);
        setProjectData(
          finalProjectsList.find(
            (project) => project.internalProjectId === +projectId,
          ),
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFarmAndFieldDetails = () => {
    setLoading(true);
    axios
      .post(
        `${GET_FARM_AND_FIELDS_FOR_PARTICIPANTS(id)}?projectId=${
          projectData?.internalProjectId
        }`,
      )
      .then((response) => {
        const infoRecords = [];
        // Group input data by farmId
        const farmGroups = response.data.reduce((farmObj, field) => {
          const farmId = field.farmId;
          if (!Object.hasOwnProperty.call(farmObj, farmId)) {
            farmObj[farmId] = {
              farmId: field.farmId,
              farmName: field.farmName,
              fieldDetails: [],
            };
          }
          farmObj[farmId].fieldDetails.push({
            farmId: field.farmId,
            fieldId: field.fieldId,
            fieldName: field.fieldName,
            fieldArea: field.fieldArea,
            baseline: field.baseline,
            activity: field.activity,
            delta: field.delta,
            fieldBoundaries: field.fieldBoundaries,
            activities: field.activities,
          });
          return farmObj;
        }, {});
        // Convert grouped data to array
        for (const farmId in farmGroups) {
          if (Object.hasOwnProperty.call(farmGroups, farmId)) {
            infoRecords.push(farmGroups[farmId]);
          }
        }
        setParticipantInfo(infoRecords);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchParticipantDetails();
  }, []);

  useEffect(() => {
    if (projectList.length > 0) {
      fetchFarmAndFieldDetails();
    }
  }, [projectList]);

  return (
    <div>
      <SummaryViewHeader
        gap={'8px'}
        noBackButton
        type={
          participantType !== LABELS.participant_contributor
            ? LABELS.type
            : LABELS.contributor
        }
        title={
          <Breadcrumbs
            sx={breadcrumbSx}
            separator={<NavigateNextIcon fontSize="medium" />}
            aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        }
      />
      <Box sx={TabberWrapperStyle}>
        <TabberBox>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="page-tabs">
            {participantTabs.map((tab, index) => (
              <Tab
                sx={{ textTransform: 'none', width: 'unset' }}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                disabled={tab.isDisabled}
                key={tab.title}
                label={tab.title}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
      {participantTabs.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={TabPanelStyle}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

Participant_Tab.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default Participant_Tab;
