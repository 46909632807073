import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const projectStyles = () => ({
  SearchBox: styled(Box)(() => ({
    marginLeft: '20rem',
  })),
  SearchBoxWrapper: styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
  })),
});
