import { useActivityContext } from 'containers/ActivityListContainer';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import React from 'react';
import { ViewPdfButton } from '../ActivityMenuButton/EnrollmentButtons.react';
import { DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT } from 'urls';
import {
  InitiateActivityReportingButton,
  ReviewActivityMenuItem,
} from '../ActivityMenuButton/ActivityReportingButtons.react';

export const ActivityReportingMenuItems = () => {
  const { status } = useActivityContext();

  return (
    <>
      {status === REPORTING_STATUS.not_started && (
        <InitiateActivityReportingButton
          label={'Initiate activity reporting'}
        />
      )}
      {(status === REPORTING_STATUS.in_progress ||
        status === REPORTING_STATUS.requested) && (
        <>
          <InitiateActivityReportingButton label={'Continue'} />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT}
            key={'view-activity-reporting'}
          />
        </>
      )}
      {(status === REPORTING_STATUS.submitted ||
        status === REPORTING_STATUS.in_review) && (
        <>
          <ReviewActivityMenuItem />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT}
            key={'view-activity-enrollment-report'}
          />
        </>
      )}
      {status === REPORTING_STATUS.approved && (
        <ViewPdfButton
          url={DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT}
          key={'view-approved-activity-reporting'}
        />
      )}
    </>
  );
};
