import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
} from '../Modals.style';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { addZeroBeforeDot, checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  MODAL_CONTENT,
  formFieldNames,
  irrigationTypeFormInitialData,
  modalErrorInitialState,
  uomOptions,
} from './IrrigationModal.content';
import { useForm } from 'hooks/useForm';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useEffect, useState, useContext } from 'react';
import {
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { BLACK, RED } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const IrrigationModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
}) => {
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    irrigationTypeFormInitialData,
  );

  const { irrigationMethodDropDown } = useContext(baselineDropdownContext);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(irrigationTypeFormInitialData);
    setErrorState(modalErrorInitialState);
  };

  const [errorState, setErrorState] = useState(modalErrorInitialState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      setFormValue(
        checkTernaryCondition(
          modalData,
          modalData,
          irrigationTypeFormInitialData,
        ),
      );
    }
  }, [modalOpen]);
  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'irrigationType':
        setErrorState({ ...errorState, typeError: value === '' });
        break;
      case 'uom':
        setErrorState({ ...errorState, uomError: value === '' });
        break;
    }
  };
  const irrigationTypeDropdownProps = {
    label: MODAL_CONTENT.label,
    value: checkTernaryCondition(
      formValue.irrigationType === '',
      MODAL_CONTENT.dropdown_placeholder,
      formValue.irrigationType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.irrigationType,
    ariaLabel: MODAL_CONTENT.dropdown_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.dropdown_placeholder,
    error: errorState.typeError,
    errorMessage: MODAL_CONTENT.type_error,
    dropdownlist: irrigationMethodDropDown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.irrigationType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'irrigationType',
    listItemFontSize: '14px',
    dropdownIconColor: BLACK,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };
  const uomTypeDropdownProps = {
    label: MODAL_CONTENT.uom_label,
    value: checkTernaryCondition(
      formValue.uom === '',
      MODAL_CONTENT.uom_placeholder,
      formValue.uom,
    ),
    isDisabled: false,
    width: '7rem',
    height: '2rem',
    name: formFieldNames.uom,
    ariaLabel: MODAL_CONTENT.uom_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.uom_placeholder,
    error: errorState.uomError,
    errorMessage: '',
    dropdownlist: uomOptions,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.uom,
    setStateValue: () => {},
    showLabelAsValue: true,
    minWidth: '0rem',
    setModalErrorState: setModalErrorState,
    fieldName: 'uom',
    listItemFontSize: '14px',
    dropdownIconColor: BLACK,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };
  const submitBtnHandler = () => {
    const errorValues = {
      typeError: formValue.irrigationType === '',
      rateError: formValue.irrigationRate === '',
      uomError: formValue.uom === '',
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        modalErrorInitialState,
        errorValues,
      ),
    );
    if (!Object.values(errorValues).includes(true) || noDataOptionSelected) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.irrigation_list,
      );
      modalCloseHandler();
    }
  };

  useEffect(() => {
    if (irrigationMethodDropDown?.length > 0) {
      const newIrrigationType = formValue.irrigationType;
      const value = irrigationMethodDropDown.find(
        (irrigationMethod) => irrigationMethod?.value === newIrrigationType,
      )?.label;
      if (value === MODAL_CONTENT.noneOption) {
        setFormValue({
          ...irrigationTypeFormInitialData,
          irrigationType: newIrrigationType,
        });
        setNoDataOptionSelected(true);
        setErrorState(modalErrorInitialState);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.irrigationType, irrigationMethodDropDown]);

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={{ ...DialogContentStyle, marginBottom: 0, minHeight: '4.9375rem' }}>
        <FieldSectionWrapper>
          <DropDown {...irrigationTypeDropdownProps} />
        </FieldSectionWrapper>
        {!noDataOptionSelected && (
          <>
            <FieldSectionWrapper columnGap="0.5rem" marginBottom="0">
              <InputField
                width="8.75rem"
                required
                label={MODAL_CONTENT.irrigation_rate_label}
                name={formFieldNames.irrigationRate}
                value={formValue.irrigationRate}
                onUpdate={(e) => {
                  const value = e.target.value;
                  if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
                    customFieldChangeHandler(
                      formFieldNames.irrigationRate,
                      addZeroBeforeDot(e.target.value),
                    );
                    setErrorState({
                      ...errorState,
                      rateError: e.target.value === '',
                    });
                  }
                }}
                placeholder={MODAL_CONTENT.irrigation_rate_placeholder}
                primaryError={errorState.rateError}
                primaryErrorMessage={MODAL_CONTENT.rate_error}
                maxLength={100}
                minWidth="8.75"
                labelMarginTop="0.5rem"
                labelMarginBottom="0.25rem"
                rightSideLabelFormLabelMargin="0"
              />
              <DropDown {...uomTypeDropdownProps} />
            </FieldSectionWrapper>
            {errorState.uomError && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.uom_error}
              </TextWrapper>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={submitBtnHandler}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

IrrigationModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
};
export default IrrigationModal;
