import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { Button, Tab, Tabs } from '@mui/material';
import { useStyles } from 'theme/GlobalStyles';
import {
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  TABS_HIGHLIGHT_BORDER,
} from 'theme/GlobalColors';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LoaderProvider } from 'contextAPI/loaderContext';
import { useActivityContext } from 'containers/ActivityListContainer';
import TabPanel from 'components/Tabber/TabPanel.react';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'components/FormComponents/FormStyles';

import {
  HeadingStyle,
  BackButtonStyle,
  SubHeadingStyle,
  TitleStyle,
  ResponseStyle,
  TabberWrapperStyle,
  ButtonWrapper,
} from './PdfViewerPage.style';
import { PAGE_CONTENT } from './PdfViewerPage.content';
import { ReopenCustomModal } from '../ReOpenTemplate';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/backarrow.svg';
import { enrollmentReOpen } from 'pages/Origination/Org_Project/Participants/EnrollmentReview';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { baselineReOpen } from '../BaselineReview';
import { activityReOpen } from '../ActivityReview';
import ApproveModal from '../ActivityReportingPOIReviewComp/ApproveModal';

const PdfViewerPage = ({
  activityType,
  activityDate,
  heading,
  subHeadingArray,
  currentTab,
  setCurrentTab,
  tabList,
  isApproveDisabled,
  setIsApproveDisabled,
  onClickBackButton,
  formId,
}) => {
  const { TabberBox } = useStyles();
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [projectYear, setProjectYear] = useState('');
  const {
    reopenProps,
    setReopenProps,
    participantId,
    projectId,
    activity,
    statusMappingList,
    reviewEnrollmentProps,
    setReviewEnrollmentProps,
    updateParticipantStatus,
    closeMenu,
    setLoading,
    projectCycleId,
    activityDataList,
  } = useActivityContext();
  const { approveEnrollmentOrBaseline } = useContext(LifeCycleContext);
  if (statusMappingList.length === 0) return null;

  const handleTabChange = (_, selectedTab) => {
    if (selectedTab === 1) {
      setIsApproveDisabled(true);
    } else {
      setIsApproveDisabled(false);
    }
    setCurrentTab(selectedTab);
  };
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `participant-page-tab-${index}`,
    };
  };

  const reopenActivity = (activityType, participantId, projectId) => {
    if (activityType === PAGE_CONTENT.enrollment) {
      return enrollmentReOpen(participantId, projectId);
    } else if (activityType === PAGE_CONTENT.baselineReporting) {
      return baselineReOpen(participantId, projectId);
    } else if (activityType === PAGE_CONTENT.activity_reporting) {
      return activityReOpen(participantId, projectId);
    }
  };

  const handleReopenButtonClick = () => {
    Promise.resolve(
      setReopenProps({
        ...reopenActivity(activityType, participantId, projectId),
        modalData: { flag: true },
        instanceId: activity.enrollmentId,
        actionCode: 1,
        contractId: activity.associatedContractId,
        formId: formId,
        projectCycleId: projectCycleId,
      }),
      setAreUiActionInProgress((prev) => prev + 1),
    ).then(() => {
      if (activityType === PAGE_CONTENT.enrollment) {
        if (
          getActionIdFromActionCode('ENROLL-SUBMIT', statusMappingList) ===
          activity.actionId
        ) {
          updateParticipantStatus({
            contractId: activity.associatedContractId,
            projectId,
            participantId,
            instanceId: activity.enrollmentId,
            actionId: getActionIdFromActionCode(
              'ENROLL-REVIEW',
              statusMappingList,
            ),
            projectCycleId: projectCycleId,
          });
        }
      } else if (activityType === PAGE_CONTENT.baselineReporting) {
        setReopenProps({
          ...reviewEnrollmentProps.reOpenProp,
          instanceId: reviewEnrollmentProps.instanceId,
          projectCycleId: reviewEnrollmentProps.projectCycleId,
          modalData: { flag: true },
          actionCode: reviewEnrollmentProps.actionCode,
          contractId: reviewEnrollmentProps.contractId,
          formId: formId,
          baselineActionId: reviewEnrollmentProps.baselineActionId,
        });
        setAreUiActionInProgress((prev) => prev + 1);
      } else if (activityType === PAGE_CONTENT.activity_reporting) {
        if (
          getActionIdFromActionCode('ACTIVITY-SUBMIT', statusMappingList) ===
          activity.actionId
        ) {
          reopenActivity(activityType, participantId, projectId);
        }
      }
    });

    closeMenu();
  };

  const handleApproveButtonClick = () => {
    setProjectYear(activityDate?.slice(-4));
    setIsApproveModal(true);
    const baselineReportingRowData = activityDataList
      .filter((data) => data.activityType === 'Baseline reporting')
      .reverse()[0];
    const activityReportingRowData = activityDataList
      .filter((data) => data.activityType === 'Activity reporting')
      .reverse()[0];
    approveEnrollmentOrBaseline({
      ...reviewEnrollmentProps,
      setLoading: setLoading,
      formId: formId,
      actionId: getActionIdFromActionCode('ENROLL-APPROVE', statusMappingList),
      baselineActionId: getActionIdFromActionCode(
        'BASELINE-APPROVE',
        statusMappingList,
      ),
      activityActionId: getActionIdFromActionCode(
        'ACTIVITY-APPROVE',
        statusMappingList,
      ),
      setReviewEnrollmentProps: setReviewEnrollmentProps,
      statusMappingList,
      baselineReportingRowData,
      activityReportingRowData,
    });
  };

  return (
    <>
      <HeadingStyle>
        <BackButtonStyle onClick={onClickBackButton}>
          <BackArrow fill={GLOBAL_BLUE} style={{ height: 16, width: 16 }} />{' '}
          {PAGE_CONTENT.back}
        </BackButtonStyle>
        <div>{heading}</div>
      </HeadingStyle>
      <SubHeadingStyle>
        {subHeadingArray.map((item) => (
          <div key={item.title}>
            <TitleStyle>{item.title}:</TitleStyle>
            <ResponseStyle> {item.response}</ResponseStyle>
          </div>
        ))}
      </SubHeadingStyle>
      <div>
        <Box sx={TabberWrapperStyle}>
          <TabberBox>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { backgroundColor: TABS_HIGHLIGHT_BORDER },
              }}
              aria-label="page-tabs">
              {tabList.map((tab, index) => (
                <Tab
                  sx={{
                    textTransform: 'none',
                    width: 'unset',
                    '&.active-tab': {
                      color: DARK_CHARCOAL,
                    },
                  }}
                  className={classNames({
                    'active-tab': currentTab === index,
                  })}
                  key={tab.title}
                  label={tab.title}
                  {...tabProps(index)}
                />
              ))}
            </Tabs>
            <ButtonWrapper>
              <Button
                sx={[secondaryButtonStyle]}
                onClick={handleReopenButtonClick}>
                {PAGE_CONTENT.reopen}
              </Button>
              <Button
                sx={[primaryButtonStyle('0.375rem 0.5rem')]}
                disabled={isApproveDisabled}
                onClick={handleApproveButtonClick}>
                {PAGE_CONTENT.approve}
              </Button>
            </ButtonWrapper>
          </TabberBox>
        </Box>
      </div>
      {isApproveModal && (
        <ApproveModal
          projectYear={projectYear}
          activityType={activityType}
          isDialogOpen={isApproveModal}
          setIsDialogOpen={setIsApproveModal}
        />
      )}
      {tabList.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={{ height: 'calc(100vh - 16em)' }}
          boxHeight="100%">
          {tab.component}
        </TabPanel>
      ))}
      <LoaderProvider>
        <ReopenCustomModal
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
          statusMappingList={statusMappingList}
          data-testid="reopen-modal"
        />
      </LoaderProvider>
    </>
  );
};

PdfViewerPage.propTypes = {
  heading: PropTypes.string,
  subHeadingArray: PropTypes.array,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
  isApproveDisabled: PropTypes.bool,
  onClickBackButton: PropTypes.func,
  setIsApproveDisabled: PropTypes.func,
  activityType: PropTypes.string,
  activityDate: PropTypes.string,
};

export default PdfViewerPage;
