import React from 'react';
import PropTypes from 'prop-types';
import TableRowComponent from './TableRowComponent.react';
import { TABLE_CONTENT } from './TableWithExpandableRow.content';
import {
  ColumnHeaderWrapper,
  DetailsColumnHeaderFont,
  ExpandableRowWrapperStyle,
  InfoTooltipWrapper,
  MainTableWrapper,
  Sublabel,
  TableRowWrapper,
  TooltipAndHeaderWrapper,
  InfoSx,
  TooltipSx,
} from './TableWithExpandableRow.style';
import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const TableWithExpandableRow = ({
  fieldDetails,
  noBorder = false,
  selectedRow = null,
  setSelectedRow = () => {},
}) => {
  return (
    <MainTableWrapper noBorder={noBorder}>
      <TableRowWrapper
        height={ExpandableRowWrapperStyle.height}
        margin={ExpandableRowWrapperStyle.margin}>
        <ColumnHeaderWrapper
          fontWeight={DetailsColumnHeaderFont.weight}
          width="40%"
          color={DARK_CHARCOAL}
          fontSize={DetailsColumnHeaderFont.size}>
          {TABLE_CONTENT.field_name_label}
        </ColumnHeaderWrapper>
        <ColumnHeaderWrapper
          fontWeight={DetailsColumnHeaderFont.weight}
          width="15%"
          color={DARK_CHARCOAL}
          fontSize={DetailsColumnHeaderFont.size}>
          {TABLE_CONTENT.acreage}
          <Sublabel>{TABLE_CONTENT.acres}</Sublabel>
        </ColumnHeaderWrapper>
        <TooltipAndHeaderWrapper width="calc(15% - 1rem);">
          <ColumnHeaderWrapper
            fontWeight={DetailsColumnHeaderFont.weight}
            color={DARK_CHARCOAL}
            padding={'0rem'}
            fontSize={DetailsColumnHeaderFont.size}>
            {TABLE_CONTENT.deltaLabel}
            <Sublabel>{TABLE_CONTENT.MTCO2_label}</Sublabel>
          </ColumnHeaderWrapper>
          <LightTooltip
            placement="bottom-end"
            arrow
            sx={TooltipSx}
            title={
              <InfoTooltipWrapper>
                {TABLE_CONTENT.deltaTooltipContent}
              </InfoTooltipWrapper>
            }>
            <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
          </LightTooltip>
        </TooltipAndHeaderWrapper>
        <TooltipAndHeaderWrapper width="calc(15% - 1rem);">
          <ColumnHeaderWrapper
            fontWeight={DetailsColumnHeaderFont.weight}
            color={DARK_CHARCOAL}
            padding={'0rem'}
            fontSize={DetailsColumnHeaderFont.size}>
            {TABLE_CONTENT.baselineLabel}
            <Sublabel>{TABLE_CONTENT.MTCO2_label}</Sublabel>
          </ColumnHeaderWrapper>
          <LightTooltip
            placement="bottom-end"
            arrow
            sx={TooltipSx}
            title={
              <InfoTooltipWrapper>
                {TABLE_CONTENT.baselineTooltipContent}
              </InfoTooltipWrapper>
            }>
            <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
          </LightTooltip>
        </TooltipAndHeaderWrapper>
        <TooltipAndHeaderWrapper width="calc(15% - 1rem);">
          <ColumnHeaderWrapper
            fontWeight={DetailsColumnHeaderFont.weight}
            color={DARK_CHARCOAL}
            padding={'0rem'}
            fontSize={DetailsColumnHeaderFont.size}>
            {TABLE_CONTENT.activityLabel}
            <Sublabel>{TABLE_CONTENT.MTCO2_label}</Sublabel>
          </ColumnHeaderWrapper>
          <LightTooltip
            placement="bottom-end"
            arrow
            sx={TooltipSx}
            title={
              <InfoTooltipWrapper>
                {TABLE_CONTENT.baselineTooltipContent}
              </InfoTooltipWrapper>
            }>
            <InfoOutlinedIcon sx={InfoSx}></InfoOutlinedIcon>
          </LightTooltip>
        </TooltipAndHeaderWrapper>
      </TableRowWrapper>
      <MainTableWrapper noBorder={true} overflowY={'auto'} height={'21.875rem'}>
        {fieldDetails.map((field, index) => {
          const keyVal = index;
          return (
            <TableRowComponent
              key={keyVal}
              isExpanded={selectedRow?.fieldId === field?.fieldId}
              field={field}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          );
        })}
      </MainTableWrapper>
    </MainTableWrapper>
  );
};

TableWithExpandableRow.propTypes = {
  fieldDetails: PropTypes.array,
  noBorder: PropTypes.bool,
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
};

export default TableWithExpandableRow;
