import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
  outlinedDropdownInputSx,
  InputSx,
} from '../Modals.style';
import CloseIcon from '@mui/icons-material/Close';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  MODAL_CONTENT,
  initialSoilAmendmentsErrorState,
  initialSoilAmendmentsTypeData,
  unitOfMeasureDropdownList,
} from './SoilAmendmentsModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { useForm } from 'hooks/useForm';
import {
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { RED } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  handleTextFieldValueChange,
  setCurrentRowModalData,
} from '../Modals.content';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const SoilAmendmentsModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
}) => {
  const [errorState, setErrorState] = useState(initialSoilAmendmentsErrorState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    initialSoilAmendmentsTypeData,
  );

  const { soilAmendmentsDropdown } = useContext(baselineDropdownContext);

  useEffect(() => {
    setCurrentRowModalData(
      modalOpen,
      setFormValue,
      initialSoilAmendmentsTypeData,
    );
  }, [modalOpen]);

  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialSoilAmendmentsTypeData);
    setErrorState(initialSoilAmendmentsErrorState);
  };

  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'soilAmendmentsType':
        setErrorState({ ...errorState, soilAmendmentsTypeError: value === '' });
        break;
      case 'amountApplied':
        setErrorState({ ...errorState, amountAppliedError: value === '' });
        break;
      case 'unitOfMeasure':
        setErrorState({ ...errorState, uomError: value === '' });
        break;
    }
  };

  const submitBtnHandler = () => {
    setErrorState({
      soilAmendmentsTypeError: formValue.soilAmendmentsType === '',
      amountAppliedError: formValue.amountApplied === '',
      uomError: formValue.unitOfMeasure === '',
    });
    if (
      formValue.soilAmendmentsType !== '' &&
      formValue.amountApplied !== '' &&
      formValue.unitOfMeasure !== ''
    ) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.soil_amendments_list,
      );
      modalCloseHandler();
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}
      onClose={modalCloseHandler}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <DropDown
          hasNoBottomMargin={true}
          value={checkTernaryCondition(
            formValue.soilAmendmentsType === '',
            MODAL_CONTENT.select_type,
            formValue.soilAmendmentsType,
          )}
          isDisabled={
            modalOpen.data?.formData[0]?.addedFromImplementedActivities || false
          }
          label={MODAL_CONTENT.soil_amendments_type}
          error={errorState.soilAmendmentsTypeError}
          errorMessage={MODAL_CONTENT.soil_amendments_type_error}
          setFormFieldValue={customFieldChangeHandler}
          stateValue={formValue.soilAmendmentsType}
          setStateValue={() => {}}
          name={MODAL_CONTENT.soil_amendments_type}
          field_key="soilAmendmentsType"
          dropDownPlaceholder={MODAL_CONTENT.select_type}
          dropdownlist={soilAmendmentsDropdown}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          width={MODAL_CONTENT.thirteenPointSevenFiveRem}
          height={MODAL_CONTENT.dropdownHeight}
          setModalErrorState={setModalErrorState}
          fieldName={'soilAmendmentsType'}
          showLabelAsValue={true}
          labelMarginTop={MODAL_CONTENT.zeroPointFiveRem}
          labelMarginBottom={MODAL_CONTENT.zeroPointTwoFiveRem}
          customSx={outlinedDropdownInputSx}
          listItemFontSize={MODAL_CONTENT.dropdownFontSize}
        />
        <FieldSectionWrapper
          columnGap="1.5rem"
          marginBottom={MODAL_CONTENT.zeroRem}>
          <InputField
            label={MODAL_CONTENT.amount_applied}
            placeholder={MODAL_CONTENT.type_amount}
            value={formValue.amountApplied}
            minWidth={'11.25rem'}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            name="amountApplied"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                'amountApplied',
                true,
                customFieldChangeHandler,
              );
              setModalErrorState(event.target.value, 'amountApplied');
            }}
            primaryError={errorState.amountAppliedError}
            primaryErrorMessage={MODAL_CONTENT.amount_applied_error}
            labelMarginTop={MODAL_CONTENT.zeroPointFiveRem}
            labelMarginBottom={MODAL_CONTENT.zeroPointTwoFiveRem}
            rightSideLabelFormLabelMargin={MODAL_CONTENT.zeroRem}
            customInputSx={InputSx}
          />
          <RadioButtonsGroup
            selectedValue={formValue.unitOfMeasure}
            list={unitOfMeasureDropdownList}
            label={MODAL_CONTENT.unit_of_measure}
            primaryError={errorState.uomError}
            direction="row"
            handleChange={(event) => {
              customFieldChangeHandler('unitOfMeasure', event.target.value);
              setModalErrorState(event.target.value, 'unitOfMeasure');
            }}
            checkedIsDarkGreen
            marginTop={MODAL_CONTENT.zeroPointFiveRem}
            marginBottom={MODAL_CONTENT.zeroPointSevenFiveRem}
            marginRight={'1.625rem'}
            radioBtnPadding={'0rem 0.5rem 0rem 0.563rem'}
          />
        </FieldSectionWrapper>
        {errorState.uomError && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.unit_of_measure_error}
          </TextWrapper>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          onClick={() => {
            submitBtnHandler();
          }}
          disabled={isSubmitDisabled}
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}>
          {MODAL_CONTENT.submit}
        </Button>
        <Button
          disableElevation
          onClick={() => {
            modalCloseHandler();
          }}
          sx={[CancelBtnStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SoilAmendmentsModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
};
export default SoilAmendmentsModal;
