import { useState } from 'react';
import { useForm } from './useForm';

const fieldDetailsModal = {
  fieldName: '',
  fsaTractId: '',
  fsaFieldId: '',
  totalFieldArea: '',
  csafImplemented: '',
  projectActivityDtoList: [],
  csafUpdatedBySystem: false,
  farmCsafUpdatedByField: false,
  fieldHistoricLanduseList: [{ landuseTypeId: '', startYear: '', endYear: '' }],
  landUseTypeId: -1,
  isConsistentLanduse20Yrs: '',
};

export const useParticipantGeneralFieldInfo = () => {
  const [error, setError] = useState({
    fsaTractId: false,
    fsaFieldId: false,
    csafImplementedRequiredError: false,
    projectActivityDtoList: false,
    commonLandUseTypeFor3YearsError: false,
    isConsistentFor20YearsError: false,
    dropDownDateComponentError: { isError: false, errorMessage: '' },
  });
  const {
    formValue,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm(fieldDetailsModal);

  const handleTextFieldValueChange = (event, eventValue, key, isNumber) => {
    const newErrorModal = { ...error };
    const finalValue = event?.target.value.trim() ? event?.target.value : '';
    if (finalValue === '' || eventValue === '') {
      newErrorModal[key] = true;
      setError(newErrorModal);
    } else {
      newErrorModal[key] = false;
      setError(newErrorModal);
    }
    if (isNumber) {
      event?.target.value.match(/^\d*(\.\d{0,2})?$/) &&
        customFieldChangeHandler(key, finalValue);
    } else {
      customFieldChangeHandler(key, finalValue);
    }
  };
  return {
    formValue,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
    handleTextFieldValueChange,
    error,
    setError,
  };
};
