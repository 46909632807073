import { SortColValues } from './ViewDetailedAuditLog.content';
import { COOL_GRAY, WHITE } from 'theme/GlobalColors';

export const handleArrowColor = (col, sortColumn) => {
  return {
    color: sortColumn === SortColValues[col] ? COOL_GRAY : WHITE,
    '&:hover': {
      color: COOL_GRAY,
    },
  };
};
