import styled from 'styled-components';
import { COOL_GRAY, DARK_GREEN, QUILL_GRAY } from 'theme/GlobalColors';

export const MainContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 1.5rem;
  overflow: auto;
  height: 100%;
  max-height: 35.6rem;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;
export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  line-height: ${({ lineHeight }) => lineHeight};
`;
export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;
export const DatePickersWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const submitButtonStyle = {
  background: DARK_GREEN,
  width: '5rem',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    background: DARK_GREEN,
  },
};
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const addProjectActivityBtnSx = {
  textTransform: 'none',
  height: '1.75rem',
  fontSize: '0.875rem',
  padding: '0.375rem 0.5rem',
  fontWeight: '400',
  fontFamily: 'Open sans, sans-serif',
  width: 'fit-content',
};

export const dividerSx = {
  width: '60%',
  marginTop: '0.5rem',
};

export const FieldWebformDisclaimer = styled.div`
  width: 544px;
  margin: 0.25rem 0rem 0rem 0.5rem;
  color: ${COOL_GRAY};
  font-size: 0.688rem;
  line-height: 14.98px;
`;
export const BoldTextWrapper = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
export const DropdownDateWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;
