export const PAGE_CONTENT = {
  column: 'column',
  file_thumbnail: 'fileThumbnail',
  flagged: 'Flagged',
  pending: 'Pending',
  accepted: 'Accepted',
  file: 'File',
  fileType: 'File type',
  file_type: 'fileType',
  projectActivityType: 'Project activity - practice',
  project_activity_type_field: 'projectActivityType',
  fieldFarm: 'Field + farm',
  flag: 'Flag',
  accept: 'Accept',
  label_rows_per_page: 'Items per page',
  default_pagination_options: [25, 50, 75],
  pagination_mode: 'server',
  default_page_size: 25,
  practices: 'practices',
  fields: 'fields',
  allFields: 'All fields',
  success_message: 'Flagged status updated successfully',
  file_download_error: 'File download failed',
  implemented_count:
    'Implemented on {{fields}} fields for a total of {{acres}} acres.',
  empty_text: '--',
  geo_referenced_file: 'Geo-referenced image (manual tag)',
};

export const BUTTON_CONTENT = {
  accept_all_button: 'Accept all',
  reopen_button: 'Reopen',
  approve_button: 'Approve',
  success: 'success',
  small: 'small',
};
