export const ORIGINATION_SUMMARY_TAB_CONTENT = {
  participants: 'Participants',
  add: 'Add',
  outlined: 'outlined',
  dataSources: 'Data Sources',
  details: 'Details',
  description: 'Description',
  notes: 'Notes',
  column: 'column',
  row: 'row',
  thirtyPercent: '30%',
  pointFiveRem: '0.5rem',
  seventyPercent: '70%',
  twelveRem: '12rem',
  twentyFiveRem: '25%',
  seventyThreePointFive: '73.5%',
  twelvePointSixTwoFive: '12.625',
  twelvePointOneTwoFive: '12.125rem',
  twentyFivePointTwentyFive: '25.25rem',
  fiveHundredPx: '500px',
  widgetContainerHeight: '33%',
  barChartContainerHeight: '65.6%',
  fullWidth: '100%',
  oneRem: '1rem',
  mapHeader: 'Location',
  chartTitle: 'GHG removal & reductions',
  projected: 'Projected',
  actual: 'Actual',
  noDataLabel: 'No data to report yet.',
  noDataValue: '- -',
  noDataValueContinuous: '--',
  ghgEmissionsEmpty: true,
  ghgEmissionsSectionHeader: 'GHG removal & reductions',
  ghgEmissionsEmptyLabel: 'No data to report yet.',
  metricTons: 'MT CO2e',
  baseline: 'Baseline',
  activity: 'Activity',
  delta: 'Delta',
  activityDelta: 'Activity + delta',
  recruitment_header: 'Participant recruitment',
  summary_project_reporting: 'Reporting',
  baseline_reporting: 'Baseline Reporting',
  enrollment: 'Enrollment',
  complete: 'Complete',
  not_started: 'Not started',
  in_progress: 'In progress',
  enrollment_chart_title: 'Enrollment and reporting',
  interested: 'interested',
  contracted: 'contracted',
  participant_recruitment_empty_state: 'No data to report yet.',
  enrolment_reporting_empty_state: 'No data to report yet.',
  ghg_emissions: 'GHG removal & reductions',
  ghg_impact_tooltip: 'Represents reductions and removals to date.',
  placement: 'bottom-start',
  CO2e: 'CO₂e (K MT)',
  participant_incentives: 'Participant incentives',
  progress_tracker: 'Participant tracker',
  recruitment: 'Recruitment',
  recruitment_participants: 'participants',
  project_life_cycle_placeholder: 'Select project cycle',
  project_life_cycle_label: 'Project cycle:',
  percent_to_goal: '% to goal',
  goal: 'goal',
  participant_tracker: 'contracted and interested participants',
};

export const projectSummaryPageMapControlOptions = {
  mapTypeId: 'satellite',
  mapTypeControl: false,
  streetViewControl: false,
  zoom: 4,
};

export const FIELDS = {
  type: 'Type',
  commercializationApproach: 'Commercialization Approach',
  quantificationApproach: 'Quantification Approach',
  projectActivities: 'Project Activities',
  registry: 'Registry',
  validationAndVerificationBody: 'Validation and Verification Body (VVB)',
  createdon: 'Created on',
};
export const setValueWhenNull = (value) => {
  if (value === null || value === undefined) {
    return 0;
  }
  return value;
};
export const checkIfAllValuesAreZero = (total, inProgress, complete) => {
  if (total === 0 && inProgress === 0 && complete === 0) {
    return true;
  }
  return false;
};
export const calculateBarPercentage = (value, total) => {
  const percentage = (value / total) * 100;
  return `${percentage}%`;
};
export const checkIfValuesAreUndefined = (dataObject) => {
  if (dataObject === undefined) {
    return true;
  } else if (
    dataObject?.baseline === undefined ||
    dataObject?.activity === undefined ||
    dataObject?.delta === undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const rangeNumArray = (start, stop) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

export const checkIfValuesAreNull = (value) => {
  return value || 0;
};
export const usRegionsPolygons = [
  {
    region: 'Midwest',
    polygonCoordinates: [
      { lng: -104.02192237226355, lat: 49.04423341068596 },
      { lng: -104.04786371519364, lat: 41.0046931197225 },
      { lng: -102.0641593367201, lat: 40.97887747762138 },
      { lng: -102.03602435470387, lat: 37.000724079375246 },
      { lng: -94.6229575455818, lat: 37.00072407937698 },
      { lng: -94.6093910065616, lat: 36.500700988143976 },
      { lng: -90.15956620787301, lat: 36.500700988143976 },
      { lng: -90.07816697375084, lat: 36.36972496856288 },
      { lng: -90.39019737121991, lat: 35.98645117701669 },
      { lng: -89.71187042020048, lat: 35.99742785932423 },
      { lng: -89.66877034995919, lat: 36.225291351197754 },
      { lng: -89.5331049597552, lat: 36.56382234787483 },
      { lng: -89.23464110130632, lat: 36.58561239321402 },
      { lng: -89.16680840620432, lat: 37.02012005983214 },
      { lng: -88.97687685991906, lat: 37.24724819285106 },
      { lng: -88.4749149161647, lat: 37.06343511241971 },
      { lng: -88.50204799420563, lat: 37.279639387340254 },
      { lng: -88.35281606498093, lat: 37.43061399207326 },
      { lng: -88.10861836261385, lat: 37.484459871994034 },
      { lng: -88.14931797967546, lat: 37.66724567423955 },
      { lng: -88.02721912849168, lat: 37.806720098076525 },
      { lng: -87.68805565298169, lat: 37.91382921904463 },
      { lng: -87.22679332628846, lat: 37.871004249542764 },
      { lng: -86.83336369469717, lat: 37.978019952499196 },
      { lng: -86.6434321484119, lat: 37.88171282729651 },
      { lng: -86.35853482898374, lat: 38.18092040184402 },
      { lng: -86.06007097053485, lat: 37.96732539142215 },
      { lng: -85.7073409560047, lat: 38.372622131310834 },
      { lng: -85.42244363657655, lat: 38.53198667603013 },
      { lng: -85.43601017559725, lat: 38.71217401927507 },
      { lng: -84.8526489977202, lat: 38.77566141972079 },
      { lng: -84.7983828416389, lat: 39.123832136739026 },
      { lng: -84.28288968255495, lat: 39.00796841504953 },
      { lng: -84.22862352647368, lat: 38.80738708836549 },
      { lng: -83.87589351194352, lat: 38.743927918206964 },
      { lng: -83.71309504369863, lat: 38.62743951825877 },
      { lng: -82.89910270247523, lat: 38.72276231610539 },
      { lng: -82.61420538304705, lat: 38.436413341774596 },
      { lng: -82.1800761343947, lat: 38.59563704927936 },
      { lng: -82.04441074419071, lat: 39.02904925965575 },
      { lng: -81.85447919790545, lat: 38.82852759629452 },
      { lng: -81.65098111259945, lat: 39.27102695538443 },
      { lng: -80.89125492745733, lat: 39.60630603853704 },
      { lng: -80.60635760802917, lat: 40.36510920828255 },
      { lng: -80.66062376411097, lat: 40.59213484970326 },
      { lng: -80.52495837390698, lat: 40.653917651419135 },
      { lng: -80.51139183488681, lat: 41.98856497239032 },
      { lng: -81.42034994925258, lat: 41.69546583316398 },
      { lng: -81.77307996378273, lat: 41.472219622138795 },
      { lng: -82.13937651733362, lat: 41.48238391957196 },
      { lng: -83.04833463169939, lat: 41.4213742265988 },
      { lng: -83.46890393123664, lat: 41.725849156800024 },
      { lng: -83.10260737768628, lat: 42.280321278527765 },
      { lng: -82.45141350470726, lat: 43.01870543880577 },
      { lng: -82.68204466805413, lat: 43.98305978467096 },
      { lng: -82.9940750655232, lat: 44.07085041095522 },
      { lng: -83.37393815809374, lat: 43.846238166936985 },
      { lng: -83.65883547752192, lat: 43.60113153055542 },
      { lng: -83.95729933597079, lat: 43.748315855298614 },
      { lng: -83.8487670238077, lat: 44.00258004917339 },
      { lng: -83.60456932144064, lat: 44.06110231789421 },
      { lng: -83.40107123613463, lat: 44.30432301533733 },
      { lng: -83.31967200201245, lat: 44.86472992008325 },
      { lng: -83.37393815809374, lat: 45.16204217681974 },
      { lng: -84.16079742127675, lat: 45.53388785740617 },
      { lng: -84.86625745033709, lat: 45.76150190695688 },
      { lng: -85.12402169172435, lat: 45.53388785740617 },
      { lng: -85.00192284054107, lat: 45.41021071270134 },
      { lng: -85.38178593311163, lat: 45.28626215726294 },
      { lng: -85.32751977703032, lat: 44.89356953195605 },
      { lng: -85.54458440135649, lat: 44.710674527194556 },
      { lng: -85.6124170964585, lat: 45.16204217681974 },
      { lng: -86.11437904021284, lat: 44.787753618926644 },
      { lng: -86.4806755937632, lat: 44.041601315934315 },
      { lng: -86.42640943768191, lat: 43.846238166936985 },
      { lng: -86.57564136690608, lat: 43.6992945249624 },
      { lng: -86.16864519629462, lat: 42.710449289710425 },
      { lng: -86.68417367906919, lat: 41.83713006202831 },
      { lng: -87.2132687008644, lat: 41.63465752946186 },
      { lng: -87.62026487147637, lat: 41.82702159668264 },
      { lng: -87.79662987874144, lat: 42.38061501844456 },
      { lng: -87.78306333972073, lat: 42.78019004844924 },
      { lng: -87.89159565188383, lat: 43.27605560150147 },
      { lng: -87.64739794951676, lat: 43.728712136838794 },
      { lng: -87.49816602029259, lat: 44.22660156717612 },
      { lng: -86.98263753751803, lat: 45.27671641069122 },
      { lng: -88.00012796404691, lat: 44.47882053650025 },
      { lng: -87.75593026168035, lat: 44.99919110266248 },
      { lng: -87.00977061555844, lat: 45.742569394753104 },
      { lng: -85.85662150636534, lat: 45.9976124577793 },
      { lng: -84.75773184571328, lat: 45.903287030675784 },
      { lng: -84.03870527763274, lat: 45.9976124577793 },
      { lng: -84.5271006823669, lat: 46.53220293774214 },
      { lng: -85.05619570416214, lat: 46.49485787971548 },
      { lng: -84.97479647003995, lat: 46.75573445687152 },
      { lng: -86.62991423052713, lat: 46.46683224469564 },
      { lng: -87.36250733762837, lat: 46.476175727042545 },
      { lng: -87.97300159354579, lat: 46.92277709046809 },
      { lng: -88.50209661534105, lat: 46.75573445687152 },
      { lng: -87.74237043019943, lat: 47.448321060110004 },
      { lng: -88.25789891297447, lat: 47.466666737470604 },
      { lng: -88.8683931688919, lat: 47.07082353762755 },
      { lng: -89.7502182052173, lat: 46.783610961136816 },
      { lng: -90.44211169525693, lat: 46.56952233415427 },
      { lng: -90.90337402195067, lat: 46.63476953508706 },
      { lng: -90.78127517076688, lat: 46.876428449675586 },
      { lng: -90.83554132684868, lat: 46.96908566798146 },
      { lng: -92.00226368260226, lat: 46.68132657202764 },
      { lng: -91.40534187565889, lat: 47.190808821189364 },
      { lng: -90.74058146365965, lat: 47.69544727924827 },
      { lng: -89.57385910790606, lat: 48.014061891879294 },
      { lng: -89.92658912243623, lat: 47.995908073588225 },
      { lng: -90.1572202857826, lat: 48.14095975023875 },
      { lng: -90.87624685386365, lat: 48.0956750157053 },
      { lng: -90.91694647092474, lat: 48.276574506372754 },
      { lng: -91.58170688292397, lat: 48.05941848638045 },
      { lng: -92.08366882667832, lat: 48.37579713833395 },
      { lng: -92.97906040202393, lat: 48.61852552472865 },
      { lng: -93.75235312618625, lat: 48.51078971640186 },
      { lng: -93.87445197736953, lat: 48.6543864682001 },
      { lng: -94.67487777957274, lat: 48.761816377719526 },
      { lng: -95.44817050373508, lat: 49.00269549372399 },
      { lng: -104.02192237226355, lat: 49.04423341068596 },
    ],
  },
  {
    region: 'West',
    polygonCoordinates: [
      { lng: -104.06595470566967, lat: 49.01007432176306 },
      { lng: -123.29567500521337, lat: 49.03532124200285 },
      { lng: -123.03947261331115, lat: 48.85410725130194 },
      { lng: -123.31538288151364, lat: 48.69825702267187 },
      { lng: -123.11830411851182, lat: 48.43743088439288 },
      { lng: -123.4336301393144, lat: 48.22780091210075 },
      { lng: -124.79347360402605, lat: 48.489703745788034 },
      { lng: -124.69493422252553, lat: 47.99093657252993 },
      { lng: -124.18252943872113, lat: 47.12649355759183 },
      { lng: -123.96574279941906, lat: 46.16593687975555 },
      { lng: -124.53727121212384, lat: 42.82148551097694 },
      { lng: -124.39932619666783, lat: 42.26973813082961 },
      { lng: -124.08400017586524, lat: 41.43299944799983 },
      { lng: -124.4190340729681, lat: 40.300359115454256 },
      { lng: -123.86721353656318, lat: 39.92355258052436 },
      { lng: -123.76867415506227, lat: 38.91877802170575 },
      { lng: -122.90152759785514, lat: 38.24090391763312 },
      { lng: -123.05919060825644, lat: 37.99282053311667 },
      { lng: -122.40883069035061, lat: 37.89957212309233 },
      { lng: -122.42853856665087, lat: 37.587890321703156 },
      { lng: -122.42853856665087, lat: 37.18074515831063 },
      { lng: -121.97525741174687, lat: 36.976344623873416 },
      { lng: -121.83730227764583, lat: 36.72401915200183 },
      { lng: -121.97525741174687, lat: 36.42330255462912 },
      { lng: -121.50226838054297, lat: 35.898228811920674 },
      { lng: -120.73366120483638, lat: 35.176572821267214 },
      { lng: -120.47745881293417, lat: 34.48095049790162 },
      { lng: -119.49424070157008, lat: 34.37306353366699 },
      { lng: -119.04759813338606, lat: 34.0429853147286 },
      { lng: -118.54393736500806, lat: 34.03511056644757 },
      { lng: -118.39188883115823, lat: 33.73532916194365 },
      { lng: -118.06878569672723, lat: 33.71161738136833 },
      { lng: -117.3180460608435, lat: 33.14057514291912 },
      { lng: -117.28953696074635, lat: 32.813724866439856 },
      { lng: -117.11848236016556, lat: 32.54977347273358 },
      { lng: -114.56216638481402, lat: 32.74181551257699 },
      { lng: -114.44812998442683, lat: 32.957369070818245 },
      { lng: -114.6952088519329, lat: 33.06893120628045 },
      { lng: -114.6762027852016, lat: 33.299574558856975 },
      { lng: -114.6952088519329, lat: 33.38690096610442 },
      { lng: -114.4861421178891, lat: 33.61670478966428 },
      { lng: -114.53365728471724, lat: 33.95632288876887 },
      { lng: -114.11552381663, lat: 34.27888742184939 },
      { lng: -114.61918591001978, lat: 34.86575024194116 },
      { lng: -114.57167074319165, lat: 35.145969070657316 },
      { lng: -114.66670107684789, lat: 35.51036270800434 },
      { lng: -114.73322231040733, lat: 36.065387757592475 },
      { lng: -114.55266467646034, lat: 36.13449299482048 },
      { lng: -114.20105244193253, lat: 36.01928384360113 },
      { lng: -114.02999784135137, lat: 36.188199478887555 },
      { lng: -114.04024260215834, lat: 37.00140418787174 },
      { lng: -102.05255210098802, lat: 37.01554411127239 },
      { lng: -102.07025917706073, lat: 40.99045510117281 },
      { lng: -104.0534516971952, lat: 41.01718095217234 },
      { lng: -104.06595470566967, lat: 49.01007432176306 },
    ],
  },
  {
    region: 'Southwest',
    polygonCoordinates: [
      { lng: -94.60371276644958, lat: 36.5093441116435 },
      { lng: -94.6172732053083, lat: 36.98863890529606 },
      { lng: -114.04360825512606, lat: 36.999875292696956 },
      { lng: -114.03783215683535, lat: 36.18832579443901 },
      { lng: -114.19956450876322, lat: 36.02032163680266 },
      { lng: -114.55190650447314, lat: 36.137028977910816 },
      { lng: -114.73096555147323, lat: 36.06702535828168 },
      { lng: -114.66165237198902, lat: 35.49064837415119 },
      { lng: -114.56923479934387, lat: 35.15132286437027 },
      { lng: -114.60966748737641, lat: 34.86746587282275 },
      { lng: -114.11292303440831, lat: 34.277716401898516 },
      { lng: -114.52302601302151, lat: 33.96211625910996 },
      { lng: -114.48772078904369, lat: 33.61905373009563 },
      { lng: -114.68988422920518, lat: 33.39268885782168 },
      { lng: -114.67255593433401, lat: 33.30583928704307 },
      { lng: -114.68034637506436, lat: 33.081809508785554 },
      { lng: -114.44971521171749, lat: 32.95668043503723 },
      { lng: -114.54468098486038, lat: 32.76295028511679 },
      { lng: -114.73461253114566, lat: 32.72871871967082 },
      { lng: -114.82957830428853, lat: 32.60309053458464 },
      { lng: -114.81601176526833, lat: 32.50017259300638 },
      { lng: -110.94954814445684, lat: 31.325577499672292 },
      { lng: -108.20910482767913, lat: 31.337172731533784 },
      { lng: -108.20910482767913, lat: 31.787996119072943 },
      { lng: -106.60825322327271, lat: 31.787996119072943 },
      { lng: -105.38726471143787, lat: 30.837596154993392 },
      { lng: -104.99383507984662, lat: 30.674378009048837 },
      { lng: -104.72250429943865, lat: 30.241688211005695 },
      { lng: -104.65467160433666, lat: 29.87769050639713 },
      { lng: -104.15270966058233, lat: 29.358761622108673 },
      { lng: -103.79997964605217, lat: 29.26412455490032 },
      { lng: -103.13878073787347, lat: 28.969977231906626 },
      { lng: -102.84983717640425, lat: 29.222457036430086 },
      { lng: -102.69425218176714, lat: 29.744850603433946 },
      { lng: -102.39049100176123, lat: 29.751283046890023 },
      { lng: -102.31640290907686, lat: 29.89269228340241 },
      { lng: -102.19045315151361, lat: 29.828440159092935 },
      { lng: -101.73851578613893, lat: 29.777008692773776 },
      { lng: -101.44957222467025, lat: 29.777008692773776 },
      { lng: -101.23471675588542, lat: 29.51945548237758 },
      { lng: -100.8864965779301, lat: 29.28063501151641 },
      { lng: -100.64200587207195, lat: 28.963496710954104 },
      { lng: -100.28638302718664, lat: 28.280641763679057 },
      { lng: -100.086345176939, lat: 28.16313651490637 },
      { lng: -99.86408089888583, lat: 27.783631689081304 },
      { lng: -99.53068448180663, lat: 27.606511563290084 },
      { lng: -99.51586686326942, lat: 27.310674658718852 },
      { lng: -99.44177877058502, lat: 27.020647162503536 },
      { lng: -99.11579116277414, lat: 26.405171294686866 },
      { lng: -98.81943879203656, lat: 26.358710927693764 },
      { lng: -98.686080225205, lat: 26.252445712833136 },
      { lng: -98.44899832861518, lat: 26.219217867633873 },
      { lng: -98.1156019115354, lat: 26.046280240926308 },
      { lng: -97.6562557368924, lat: 26.019651745748902 },
      { lng: -97.39694741249704, lat: 25.833083330774457 },
      { lng: -97.14504789737056, lat: 25.966376631768597 },
      { lng: -97.29322408273933, lat: 26.11282501346433 },
      { lng: -97.42658264957092, lat: 26.438345824523154 },
      { lng: -97.48585312371866, lat: 26.73648591865519 },
      { lng: -97.58216764420803, lat: 26.82247043011982 },
      { lng: -97.48585312371866, lat: 26.99424372183175 },
      { lng: -97.33026913018911, lat: 27.553975701486692 },
      { lng: -97.12282247067316, lat: 27.92773820769193 },
      { lng: -96.87833176481443, lat: 28.182729012773265 },
      { lng: -96.4115767809031, lat: 28.45014214812707 },
      { lng: -96.13004202870275, lat: 28.6193719589851 },
      { lng: -95.35211705551718, lat: 28.892167528077806 },
      { lng: -95.19653206088007, lat: 29.1189512605862 },
      { lng: -94.73718557159536, lat: 29.396888018196194 },
      { lng: -94.44083320085782, lat: 29.538795570421527 },
      { lng: -93.84812845938323, lat: 29.68050423538756 },
      { lng: -93.78144917596715, lat: 29.98899359317501 },
      { lng: -93.75181393889329, lat: 30.302924146010184 },
      { lng: -93.69254346474611, lat: 30.44993412254533 },
      { lng: -93.73699632035664, lat: 30.552071384424664 },
      { lng: -93.50732323303512, lat: 31.029405262875358 },
      { lng: -93.55177608864565, lat: 31.181650391924904 },
      { lng: -93.78885798523548, lat: 31.516991522145588 },
      { lng: -93.8407196501149, lat: 31.80707046153306 },
      { lng: -94.03391812759324, lat: 31.980914431987358 },
      { lng: -94.03391812759324, lat: 33.47590764465335 },
      { lng: -94.10175082269525, lat: 33.588994627705205 },
      { lng: -94.4680473762456, lat: 33.65677577369979 },
      { lng: -94.4273477591845, lat: 35.45548591039143 },
      { lng: -94.60371276644958, lat: 36.5093441116435 },
    ],
  },
  {
    region: 'Southeast',
    polygonCoordinates: [
      { lng: -80.52038082854025, lat: 40.65153370341244 },
      { lng: -80.65809858838331, lat: 40.595633721645214 },
      { lng: -80.60515482012396, lat: 40.35802545231198 },
      { lng: -80.89105116872408, lat: 39.60346047204706 },
      { lng: -81.65344143165822, lat: 39.26816018794469 },
      { lng: -81.85462775104392, lat: 38.832335142800105 },
      { lng: -82.04522531677704, lat: 39.01768369656551 },
      { lng: -82.17229036059913, lat: 38.60100345449649 },
      { lng: -82.61672631952004, lat: 38.43548714887265 },
      { lng: -82.90007439730785, lat: 38.72028574087287 },
      { lng: -83.71253164075983, lat: 38.62321951063197 },
      { lng: -83.87444482806684, lat: 38.74284041683214 },
      { lng: -84.22718427184327, lat: 38.80370246473845 },
      { lng: -84.28211717963384, lat: 39.00844261523281 },
      { lng: -84.80255242455, lat: 39.122931449825245 },
      { lng: -84.85170464212513, lat: 38.774400181418656 },
      { lng: -85.4328573322815, lat: 38.71125705562841 },
      { lng: -85.4241834115326, lat: 38.53280239572754 },
      { lng: -85.71305268140021, lat: 38.37329765619688 },
      { lng: -86.0600095113439, lat: 37.96641577283286 },
      { lng: -86.36070543062863, lat: 38.178095993095724 },
      { lng: -86.64405350841642, lat: 37.88202866080053 },
      { lng: -86.8377710718016, lat: 37.975532902489434 },
      { lng: -87.22479619041462, lat: 37.8695668432666 },
      { lng: -87.69029660392293, lat: 37.91520124730867 },
      { lng: -88.02857951311816, lat: 37.8056311673094 },
      { lng: -88.15001440359852, lat: 37.66843963700333 },
      { lng: -88.11242741368804, lat: 37.485122428486676 },
      { lng: -88.35430609451652, lat: 37.42746563732814 },
      { lng: -88.50176274724272, lat: 37.282674529448954 },
      { lng: -88.47574098499686, lat: 37.066118227775604 },
      { lng: -88.97593708149908, lat: 37.24815930400588 },
      { lng: -89.16965464488467, lat: 37.019962378064335 },
      { lng: -89.23615470395715, lat: 36.58937097766385 },
      { lng: -89.53151851041541, lat: 36.5629300747478 },
      { lng: -89.66740993547654, lat: 36.23012730132183 },
      { lng: -89.71077953921977, lat: 35.996552630694254 },
      { lng: -90.39023666452636, lat: 35.98485567009851 },
      { lng: -90.08086682449317, lat: 36.36528200223822 },
      { lng: -90.15893211123073, lat: 36.497878131458435 },
      { lng: -94.59998463462308, lat: 36.500201331387316 },
      { lng: -94.42650621965102, lat: 35.456700027190806 },
      { lng: -94.46917660852398, lat: 33.661771737454785 },
      { lng: -94.09976609418476, lat: 33.592454992684324 },
      { lng: -94.03212754930595, lat: 33.47969641372286 },
      { lng: -94.03408463273662, lat: 31.979295710350172 },
      { lng: -93.83829042997345, lat: 31.80778310941413 },
      { lng: -93.78545707367257, lat: 31.514155321173476 },
      { lng: -93.55236873704935, lat: 31.19036179811455 },
      { lng: -93.50885891421325, lat: 31.0307109060836 },
      { lng: -93.7381252184436, lat: 30.556105944872286 },
      { lng: -93.69536662901695, lat: 30.445580789700045 },
      { lng: -93.75034195827952, lat: 30.308566331193177 },
      { lng: -93.78393799282877, lat: 29.98635738836859 },
      { lng: -93.84807587696825, lat: 29.686982056423275 },
      { lng: -93.1961873964411, lat: 29.769513947012058 },
      { lng: -92.35525192776258, lat: 29.518031586709768 },
      { lng: -92.12015168920732, lat: 29.588824467769726 },
      { lng: -91.83079754944643, lat: 29.470808795429306 },
      { lng: -91.7675013313741, lat: 29.565232351823255 },
      { lng: -91.54144340968621, lat: 29.565232351823255 },
      { lng: -91.27921622052882, lat: 29.455062974066763 },
      { lng: -91.16166610125084, lat: 29.19490465887131 },
      { lng: -90.66433867353818, lat: 29.265922708877213 },
      { lng: -90.55583087112758, lat: 29.43143965955406 },
      { lng: -90.37498453377796, lat: 29.46293619031384 },
      { lng: -90.2574344145, lat: 29.1159379901532 },
      { lng: -90.05850344341493, lat: 29.19490465887131 },
      { lng: -90.02233417594474, lat: 29.478680789312676 },
      { lng: -89.73298003618451, lat: 29.39205525553868 },
      { lng: -89.35320272774915, lat: 29.060625221694224 },
      { lng: -89.08193322172369, lat: 29.1159379901532 },
      { lng: -89.24469492533923, lat: 29.32900848308212 },
      { lng: -89.71489540244976, lat: 29.5337676235079 },
      { lng: -89.65159918437675, lat: 29.769513947012058 },
      { lng: -89.37128736148391, lat: 29.785210717111866 },
      { lng: -89.46171053015908, lat: 30.004706740754543 },
      { lng: -89.42554126268885, lat: 30.192460649364875 },
      { lng: -88.892044567506, lat: 30.40325664877828 },
      { lng: -88.43992872413024, lat: 30.33304167708563 },
      { lng: -88.13248995063528, lat: 30.309625486503947 },
      { lng: -88.12344763376788, lat: 30.52016944781056 },
      { lng: -87.95164361328496, lat: 30.675834619514788 },
      { lng: -87.96972824701972, lat: 30.465627602742927 },
      { lng: -87.66228947352477, lat: 30.25496595179017 },
      { lng: -86.57539973833522, lat: 30.431664269170852 },
      { lng: -85.63593911811022, lat: 30.111126640449072 },
      { lng: -85.27639246098764, lat: 29.678762455158832 },
      { lng: -84.51090602969323, lat: 29.930365292777324 },
      { lng: -83.96578690437775, lat: 30.050909479438786 },
      { lng: -83.36267638275204, lat: 29.46692811418872 },
      { lng: -82.81755725743656, lat: 29.133150414188577 },
      { lng: -82.63198478924411, lat: 28.72711447063169 },
      { lng: -82.84075381596075, lat: 27.92055602119453 },
      { lng: -82.63198478924411, lat: 27.47898796514025 },
      { lng: -82.3304295284313, lat: 26.84953352894904 },
      { lng: -81.91289147499803, lat: 26.528294213695645 },
      { lng: -81.80850696163996, lat: 26.102045778022074 },
      { lng: -81.32137923263426, lat: 25.778724195345816 },
      { lng: -81.13580676444181, lat: 25.223906306705118 },
      { lng: -80.6602773146988, lat: 25.16093577142682 },
      { lng: -80.38191861240972, lat: 25.318300994467776 },
      { lng: -80.23114098200324, lat: 25.757834312256918 },
      { lng: -80.05716679307312, lat: 26.84953352894904 },
      { lng: -80.20794442347956, lat: 27.190498742316194 },
      { lng: -81.02783856142385, lat: 29.26517000134153 },
      { lng: -81.49959990557576, lat: 30.608375873524622 },
      { lng: -81.26371923350011, lat: 31.589397110176293 },
      { lng: -80.58977445614009, lat: 32.24724374212087 },
      { lng: -80.48868273953632, lat: 32.489166466187584 },
      { lng: -79.96637553708254, lat: 32.60278813238706 },
      { lng: -79.18597480892456, lat: 33.1517097567602 },
      { lng: -78.94207652507623, lat: 33.63682888622169 },
      { lng: -78.39689212588506, lat: 33.90516651742281 },
      { lng: -77.92344251606116, lat: 33.917073115449426 },
      { lng: -77.6365033585926, lat: 34.39196999884241 },
      { lng: -77.10566591727466, lat: 34.675623234297575 },
      { lng: -76.51744064446376, lat: 34.68152239404817 },
      { lng: -76.26831404035391, lat: 34.94597972069997 },
      { lng: -76.59135848778038, lat: 35.056948434765516 },
      { lng: -76.54967533327356, lat: 35.40593921014546 },
      { lng: -76.17452694271375, lat: 35.3294601356574 },
      { lng: -75.72643303176764, lat: 35.601056214853315 },
      { lng: -75.75769539764718, lat: 36.10782058270836 },
      { lng: -75.976531958807, lat: 36.49414098401064 },
      { lng: -75.96611117018047, lat: 36.87020778421794 },
      { lng: -76.29957640623343, lat: 37.01179841266125 },
      { lng: -76.49757139014024, lat: 37.31905561643714 },
      { lng: -76.2266308858471, lat: 37.32734251984954 },
      { lng: -76.35168034936677, lat: 37.616807891053185 },
      { lng: -76.2370516744736, lat: 37.83934292238588 },
      { lng: -76.62100673221259, lat: 38.14166740869496 },
      { lng: -76.93297254815711, lat: 38.211735660519224 },
      { lng: -77.04438891099396, lat: 38.398254746292 },
      { lng: -77.2672216366688, lat: 38.357494685008135 },
      { lng: -77.3192159393266, lat: 38.48552038363334 },
      { lng: -77.12609424374186, lat: 38.63653076575949 },
      { lng: -77.0592444260393, lat: 38.71191692846443 },
      { lng: -77.02210563842684, lat: 38.816166654131735 },
      { lng: -76.9106892755894, lat: 38.88558182667339 },
      { lng: -77.03696115347158, lat: 38.99535059569541 },
      { lng: -77.12609424374186, lat: 38.94337604612224 },
      { lng: -77.2672216366688, lat: 39.03574887429494 },
      { lng: -77.5346209074785, lat: 39.15680518927701 },
      { lng: -77.45291557473116, lat: 39.2201326706452 },
      { lng: -77.5643319375686, lat: 39.28915210072327 },
      { lng: -77.72031484554084, lat: 39.335127301915435 },
      { lng: -77.85401448094541, lat: 39.604621130333754 },
      { lng: -77.96543084378284, lat: 39.58745059119295 },
      { lng: -78.20311908450245, lat: 39.684694024190264 },
      { lng: -78.47051835531214, lat: 39.50726514191268 },
      { lng: -78.78991192877963, lat: 39.638949438497264 },
      { lng: -78.96075035179665, lat: 39.43846102306077 },
      { lng: -79.07216671463406, lat: 39.48433799630206 },
      { lng: -79.48812113589364, lat: 39.2201326706452 },
      { lng: -79.48069337837127, lat: 39.71898258958092 },
      { lng: -80.52038082854025, lat: 39.71854282867565 },
      { lng: -80.52038082854025, lat: 40.65153370341244 },
    ],
  },
  {
    region: 'Northeast',
    polygonCoordinates: [
      { lng: -80.51492942121112, lat: 42.011015431120285 },
      { lng: -80.51492942121112, lat: 39.711355008900256 },
      { lng: -79.47585458515302, lat: 39.69185634776679 },
      { lng: -79.47567213449118, lat: 39.23352388604903 },
      { lng: -79.07430637992144, lat: 39.4796565315896 },
      { lng: -78.96358479245396, lat: 39.43691315785162 },
      { lng: -78.78366221281922, lat: 39.629051566812535 },
      { lng: -78.47917784728321, lat: 39.51169683538578 },
      { lng: -78.2300542754812, lat: 39.67167675357078 },
      { lng: -77.98093070367922, lat: 39.58640010314963 },
      { lng: -77.87020911621174, lat: 39.60772911912943 },
      { lng: -77.73180713187773, lat: 39.34064462820291 },
      { lng: -77.5795649491095, lat: 39.27639184406172 },
      { lng: -77.468843361642, lat: 39.23352388604903 },
      { lng: -77.55188455224301, lat: 39.158441907191076 },
      { lng: -77.15051879767275, lat: 38.943480136512505 },
      { lng: -77.03979721020525, lat: 39.01879117401447 },
      { lng: -76.95675601960478, lat: 38.900409298668166 },
      { lng: -77.02595701177202, lat: 38.803404346194185 },
      { lng: -77.26124038514025, lat: 38.51159642213722 },
      { lng: -77.26124038514025, lat: 38.370672625376415 },
      { lng: -77.05363740863902, lat: 38.41406309507909 },
      { lng: -76.3754676854003, lat: 38.09889275982624 },
      { lng: -76.25090589949905, lat: 37.91350278137294 },
      { lng: -75.35129300132532, lat: 38.02261248367546 },
      { lng: -75.03296843735659, lat: 38.500765899528034 },
      { lng: -75.61425677156107, lat: 39.629051566812535 },
      { lng: -74.94992724675558, lat: 39.20135571969226 },
      { lng: -74.93608704832236, lat: 38.92194798582503 },
      { lng: -74.27175752351687, lat: 39.54372237003378 },
      { lng: -73.98111335641461, lat: 40.29734137945587 },
      { lng: -74.28559772195062, lat: 40.48708025895857 },
      { lng: -73.89376283778292, lat: 40.59429560282291 },
      { lng: -72.21656994132587, lat: 40.91488672903367 },
      { lng: -71.85605184208806, lat: 41.092324536907114 },
      { lng: -73.01597963963717, lat: 40.93857270303337 },
      { lng: -73.83106403791513, lat: 40.80819480477521 },
      { lng: -73.39217243884224, lat: 41.092324536907114 },
      { lng: -72.21656994132587, lat: 41.292842920375335 },
      { lng: -71.41716024301516, lat: 41.3987509852021 },
      { lng: -71.37013614311462, lat: 41.773917021820296 },
      { lng: -71.1350156436112, lat: 41.48100480636853 },
      { lng: -70.68044934457168, lat: 41.69203575672654 },
      { lng: -70.68044934457168, lat: 41.457514352722995 },
      { lng: -70.00643724599541, lat: 41.68032991303562 },
      { lng: -69.97508784606153, lat: 41.91404200689087 },
      { lng: -70.52370234490276, lat: 41.75053302578212 },
      { lng: -70.7431481444389, lat: 42.25140980139378 },
      { lng: -71.02529274384285, lat: 42.27461040826111 },
      { lng: -70.85287104420726, lat: 42.575441139586246 },
      { lng: -70.63342524467114, lat: 42.63312767094794 },
      { lng: -70.85287104420726, lat: 42.75984999009586 },
      { lng: -70.57072644480331, lat: 43.34399995546693 },
      { lng: -70.2102083455649, lat: 43.662342803194434 },
      { lng: -69.23837694761804, lat: 43.979006523098974 },
      { lng: -68.83083474847903, lat: 44.48441069561048 },
      { lng: -68.65841304884346, lat: 44.11420533024116 },
      { lng: -67.29471415172485, lat: 44.67421149758434 },
      { lng: -66.9052558344543, lat: 44.80261470716667 },
      { lng: -67.17172573389159, lat: 45.201610295389514 },
      { lng: -67.48521973322883, lat: 45.26783940608718 },
      { lng: -67.42252093336164, lat: 45.59782723809758 },
      { lng: -67.86141253243392, lat: 45.71833936591119 },
      { lng: -67.78303903260003, lat: 45.90407525642718 },
      { lng: -67.79871373256668, lat: 47.08026105754547 },
      { lng: -68.30030413150678, lat: 47.37829641822205 },
      { lng: -68.91161743021524, lat: 47.18689465005977 },
      { lng: -69.03701502995028, lat: 47.43134069860116 },
      { lng: -69.24078612951975, lat: 47.4631416296545 },
      { lng: -70.02452112786379, lat: 46.71610694920119 },
      { lng: -70.0558705277977, lat: 46.38192785012632 },
      { lng: -70.27531632733444, lat: 46.21948950860005 },
      { lng: -70.3536898271689, lat: 45.82767078517517 },
      { lng: -70.98067782584401, lat: 45.333991332449216 },
      { lng: -71.41956942491689, lat: 45.256806581038916 },
      { lng: -71.513617624718, lat: 45.02462197807276 },
      { lng: -74.75828051786314, lat: 44.99137556188765 },
      { lng: -75.2755456167699, lat: 44.91372554719882 },
      { lng: -76.3257505145512, lat: 44.176432089852625 },
      { lng: -76.07495531508116, lat: 44.007563200661224 },
      { lng: -76.24737701471676, lat: 43.83821217894655 },
      { lng: -76.23170231475008, lat: 43.52080068197327 },
      { lng: -76.87436501339187, lat: 43.29305033823195 },
      { lng: -77.59540121186869, lat: 43.25881393676053 },
      { lng: -78.31643741034551, lat: 43.3842532319953 },
      { lng: -79.27259410832512, lat: 43.22455827398716 },
      { lng: -78.91207600908673, lat: 42.96129177936737 },
      { lng: -78.95910010898788, lat: 42.69689393286572 },
      { lng: -79.69581100743137, lat: 42.28078449163999 },
      { lng: -80.51492942121112, lat: 42.011015431120285 },
    ],
  },
];
export const mockData = {
  participantRecruitment: {
    interested: 10,
    contracted: 20,
  },
  summaryGraphs: [
    {
      action: 'Complete',
      phase: 'Baseline Reporting',
      area: 8776,
    },
    {
      action: 'Complete',
      phase: 'Enrollment',
      area: 9975,
    },
    {
      action: 'In progress',
      phase: 'Baseline Reporting',
      area: 1043,
    },
    {
      action: 'In progress',
      phase: 'Enrollment',
      area: 766,
    },
  ],
};
export const findAcreageItem = (itemList, phaseVal, actionVal) => {
  return itemList.find(
    (item) => item.phase === phaseVal && item.action === actionVal,
  );
};
