import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
} from '../Modals.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  MODAL_CONTENT,
  initialCoverCropErrorState,
  initialCoverCropTypeData,
} from './CoverCropModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition } from 'utils/helper';
import { useForm } from 'hooks/useForm';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import { ReactComponent as Calendericon } from '../../../../../../assets/icons/calenderIcon.svg';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { DARK_GREEN, RED } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { setCurrentRowModalData } from '../Modals.content';
import { DATE_FORMAT } from 'utils/config';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const CoverCropModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
  totalFieldArea,
  hasTestDefaultDate,
}) => {
  const [datePickerPlantedOpen, setDatePickerPlantedOpen] = useState(false);
  const [datePickerTerminatedOpen, setDatePickerTerminatedOpen] =
    useState(false);
  const [dateHarvestedError, setDateHarvestedError] = useState({
    dateExceed: false,
    notInRange: false,
  });
  const [datePlantedError, setDatePlantedError] = useState({
    dateExceed: false,
    notInRange: false,
  });
  const [errorState, setErrorState] = useState(initialCoverCropErrorState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    initialCoverCropTypeData,
  );

  const { coverCropTypeDropdown, plannedUsage, terminationType } = useContext(
    baselineDropdownContext,
  );

  useEffect(() => {
    setCurrentRowModalData(modalOpen, setFormValue, initialCoverCropTypeData);
  }, [modalOpen]);

  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialCoverCropTypeData);
    setErrorState(initialCoverCropErrorState);
    setDateHarvestedError({
      dateExceed: false,
      notInRange: false,
    });
    setDatePlantedError({ dateExceed: false, notInRange: false });
  };

  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'cropId':
        setErrorState({ ...errorState, coverCropTypeError: value === '' });
        break;
      case 'plannedUsage':
        setErrorState({ ...errorState, plannedUsage: value === '' });
        break;
      case 'terminationType':
        if (
          formValue.plannedUsage ===
          plannedUsage.find((type) => type.label === 'Termination')?.value
        ) {
          setErrorState({ ...errorState, terminationType: value === '' });
        }
        break;
      case 'cropPlantDate':
        setErrorState({ ...errorState, datePlantedError: value === '' });
        break;
      case 'cropTerminateDate':
        setErrorState({ ...errorState, dateTerminatedError: value === '' });
        break;
    }
  };

  const datePlantedProps = {
    calendarOpenDate: checkTernaryCondition(
      hasTestDefaultDate,
      new Date(),
      `${modalOpen.data?.year}-01-01`,
    ),
    label: MODAL_CONTENT.date_planted,
    inputFormat: DATE_FORMAT,
    value: formValue.cropPlantDate,
    onUpdate: (newValue) => {
      if (
        new Date(newValue) > new Date(`${+modalOpen.data?.year}-12-31`) ||
        new Date(newValue) < new Date(`${modalOpen.data?.year - 2}-12-31`)
      ) {
        setDatePlantedError({
          dateExceed: false,
          notInRange: true,
        });
      } else {
        setDatePlantedError({
          ...datePlantedError,
          notInRange: false,
        });
      }
      customFieldChangeHandler('cropPlantDate', newValue?.toDate());
      setModalErrorState(newValue, 'cropPlantDate');
    },
    icon: Calendericon,
    onOpen: () => setDatePickerPlantedOpen(true),
    open: datePickerPlantedOpen,
    onClose: () => setDatePickerPlantedOpen(false),
    onClickHandler: () => setDatePickerPlantedOpen(true),
    width: '8rem',
    height: '2rem',
    placeholder: MODAL_CONTENT.select_date,
    fontSize: '14px',
    minWidth: '7.5rem',
    errorMessage: '',
    error:
      Object.values(datePlantedError).includes(true) ||
      errorState.datePlantedError,
    customSelectedDateColor: DARK_GREEN,
    hasNoBottomMargin: true,
    labelMarginTop: '0rem',
    labelMarginBottom: '0.25rem',
  };
  const dateTerminatedProps = {
    calendarOpenDate: checkTernaryCondition(
      hasTestDefaultDate,
      new Date(),
      `${modalOpen.data?.year}-01-01`,
    ),
    label: MODAL_CONTENT.date_terminated,
    inputFormat: DATE_FORMAT,
    minWidth: '7.5rem',
    onUpdate: (newValue) => {
      if (
        new Date(newValue) > new Date(`${+modalOpen.data?.year}-12-31`) ||
        new Date(newValue) <= new Date(`${modalOpen.data?.year - 1}-12-31`)
      ) {
        setDateHarvestedError({ dateExceed: false, notInRange: true });
      } else if (new Date(newValue) <= new Date(formValue.cropPlantDate)) {
        setDateHarvestedError({ dateExceed: true, notInRange: false });
      } else {
        setDateHarvestedError({ dateExceed: false, notInRange: false });
      }
      customFieldChangeHandler('cropTerminateDate', newValue?.toDate());
      setModalErrorState(newValue, 'cropTerminateDate');
    },
    value: formValue.cropTerminateDate,
    icon: Calendericon,
    open: datePickerTerminatedOpen,
    onClose: () => setDatePickerTerminatedOpen(false),
    onOpen: () => setDatePickerTerminatedOpen(true),
    placeholder: MODAL_CONTENT.select_date,
    height: '2rem',
    width: '8rem',
    fontSize: '14px',
    onClickHandler: () => setDatePickerTerminatedOpen(true),
    errorMessage: '',
    error:
      Object.values(dateHarvestedError).includes(true) ||
      errorState.dateTerminatedError,
    customSelectedDateColor: DARK_GREEN,
    hasNoBottomMargin: true,
    labelMarginTop: '0rem',
    labelMarginBottom: '0.25rem',
  };

  const submitBtnHandler = () => {
    const terminationTypeError =
      formValue.terminationType === '' &&
      plannedUsage?.filter((item) => item.value === formValue.plannedUsage)[0]
        ?.label === 'Termination';
    const errorValues = {
      coverCropTypeError: formValue.cropId === '',
      datePlantedError: formValue.cropPlantDate === '',
      dateTerminatedError: formValue.cropTerminateDate === '',
      plannedUsage: formValue.plannedUsage === '',
      terminationType: terminationTypeError,
      coverCropAreaRequiredError: !formValue.coverCropArea.trim(),
      coverCropAreqEqualToZeroError:
        formValue.coverCropArea.trim() && +formValue.coverCropArea === 0,
      coverCropAreaExceededError: formValue.coverCropArea > totalFieldArea,
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        initialCoverCropErrorState,
        errorValues,
      ),
    );
    if (
      (!Object.values(errorValues).includes(true) &&
        !Object.values(dateHarvestedError).includes(true) &&
        !Object.values(datePlantedError).includes(true)) ||
      noDataOptionSelected
    ) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.cover_crop_list,
      );
      modalCloseHandler();
    }
  };
  useEffect(() => {
    if (
      new Date(formValue.cropPlantDate) >= new Date(formValue.cropTerminateDate)
    ) {
      setDatePlantedError({
        dateExceed: true,
        notInRange: false,
      });
      setDateHarvestedError({
        dateExceed: true,
        notInRange: false,
      });
    } else {
      setDatePlantedError({
        ...datePlantedError,
        dateExceed: false,
      });
      setDateHarvestedError({
        ...dateHarvestedError,
        dateExceed: false,
      });
    }
  }, [formValue.cropPlantDate, formValue.cropTerminateDate]);

  useEffect(() => {
    if (coverCropTypeDropdown?.length > 0) {
      const newCropId = formValue.cropId;
      const value = coverCropTypeDropdown.find(
        (coverCrop) => coverCrop?.value === newCropId,
      )?.label;
      if (value === MODAL_CONTENT.noneOption) {
        setFormValue({
          ...initialCoverCropTypeData,
          cropId: newCropId,
        });
        setNoDataOptionSelected(true);
        setErrorState(initialCoverCropErrorState);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.cropId, coverCropTypeDropdown]);

  useEffect(() => {
    setIsSubmitDisabled(
      Object.values(errorState).includes(true) ||
        Object.values(dateHarvestedError).includes(true) ||
        Object.values(datePlantedError).includes(true),
    );
  }, [errorState, dateHarvestedError, datePlantedError]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}
      onClose={modalCloseHandler}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={{ ...DialogContentStyle, overflowY: 'hidden' }}>
        <FieldSectionWrapper marginBottom="0rem">
          <DropDown
            hasNoBottomMargin={true}
            value={checkTernaryCondition(
              formValue.cropId === '',
              MODAL_CONTENT.select_crop_type,
              formValue.cropId,
            )}
            label={MODAL_CONTENT.crop_type}
            error={errorState.coverCropTypeError}
            errorMessage={MODAL_CONTENT.cover_crop_type_error}
            setFormFieldValue={customFieldChangeHandler}
            stateValue={formValue.cropId}
            setStateValue={() => {}}
            name={MODAL_CONTENT.crop_type}
            field_key="cropId"
            dropDownPlaceholder={MODAL_CONTENT.select_crop_type}
            dropdownlist={coverCropTypeDropdown}
            fontSize={MODAL_CONTENT.dropdownFontSize}
            width={MODAL_CONTENT.thirteenPointSevenFiveRem}
            height={MODAL_CONTENT.dropdownHeight}
            setModalErrorState={setModalErrorState}
            fieldName={'cropId'}
            showLabelAsValue={true}
            labelMarginTop="0.5rem"
            labelMarginBottom="0.25rem"
            listItemFontSize="0.875rem"
          />
        </FieldSectionWrapper>
        {!noDataOptionSelected && (
          <>
            <FieldSectionWrapper marginBottom="0">
              <InputField
                required
                label={MODAL_CONTENT.cover_crop_area_label}
                name="coverCropArea"
                value={formValue.coverCropArea}
                rightSideLabelFormLabelMargin="0rem"
                labelMarginBottom="0.25rem"
                labelMarginTop="0.25rem"
                onUpdate={(e) => {
                  if (
                    e.target.value.match(/^\d+(\.\d{0,2})?$/) ||
                    e.target.value === ''
                  ) {
                    customFieldChangeHandler('coverCropArea', e.target.value);
                    setErrorState({
                      ...errorState,
                      coverCropAreaRequiredError: !e.target.value.trim(),
                      coverCropAreqEqualToZeroError:
                        e.target.value.trim() && +e.target.value === 0,
                      coverCropAreaExceededError:
                        e.target.value > totalFieldArea,
                    });
                  }
                }}
                placeholder={MODAL_CONTENT.cover_crop_area_placeholder}
                primaryError={errorState.coverCropAreaRequiredError}
                primaryErrorMessage={
                  MODAL_CONTENT.cover_crop_area_required_error
                }
                secondaryError={
                  errorState.coverCropAreaExceededError ||
                  errorState.coverCropAreqEqualToZeroError
                }
                secondaryErrorMessage={checkTernaryCondition(
                  errorState.coverCropAreaExceededError,
                  MODAL_CONTENT.cover_crop_area_exceed_error.replace(
                    '{{field_area}}',
                    totalFieldArea,
                  ),
                  MODAL_CONTENT.cover_crop_area_zero_error,
                )}
                maxLength={100}
                minWidth="10rem"
                width="7rem"
                hasPredefinedUnit
                predefinedUnit={MODAL_CONTENT.cover_crop_area_unit}
              />
            </FieldSectionWrapper>
            <FieldSectionWrapper
              columnGap="0.5rem"
              marginBottom="0.5rem"
              marginTop="0.25rem">
              <DropDown
                label={MODAL_CONTENT.planned_usage}
                value={checkTernaryCondition(
                  formValue.plannedUsage === '',
                  MODAL_CONTENT.planned_usage_placeholder,
                  plannedUsage?.filter(
                    (item) => item.value === formValue.plannedUsage,
                  )[0]?.label,
                )}
                setStateValue={() => {}}
                stateValue={formValue.plannedUsage}
                setFormFieldValue={customFieldChangeHandler}
                name="plannedUsage"
                field_key="plannedUsage"
                dropDownPlaceholder={MODAL_CONTENT.planned_usage_placeholder}
                dropdownlist={plannedUsage}
                fontSize={MODAL_CONTENT.dropdownFontSize}
                width={MODAL_CONTENT.thirteenPointSevenFiveRem}
                height={MODAL_CONTENT.dropdownHeight}
                error={errorState.plannedUsage}
                errorMessage={MODAL_CONTENT.planned_usage_error}
                setModalErrorState={setModalErrorState}
                fieldName="plannedUsage"
                hasNoBottomMargin
                labelMarginTop="0.5rem"
                labelMarginBottom="0.25rem"
                listItemFontSize="0.875rem"
              />
            </FieldSectionWrapper>
            {plannedUsage?.filter(
              (item) => item.value === formValue.plannedUsage,
            )[0]?.label === 'Termination' && (
              <FieldSectionWrapper marginBottom="0.5rem">
                <DropDown
                  label={MODAL_CONTENT.termination_type}
                  value={checkTernaryCondition(
                    formValue.terminationType === '',
                    MODAL_CONTENT.termination_type_placeholder,
                    terminationType?.filter(
                      (item) => item.value === formValue.terminationType,
                    )[0]?.label,
                  )}
                  name={MODAL_CONTENT.termination_type}
                  setStateValue={() => {}}
                  stateValue={formValue.terminationType}
                  setFormFieldValue={customFieldChangeHandler}
                  field_key="terminationType"
                  fontSize={MODAL_CONTENT.dropdownFontSize}
                  dropdownlist={terminationType}
                  dropDownPlaceholder={
                    MODAL_CONTENT.termination_type_placeholder
                  }
                  width={MODAL_CONTENT.thirteenPointSevenFiveRem}
                  height={MODAL_CONTENT.dropdownHeight}
                  error={errorState.terminationType}
                  errorMessage={MODAL_CONTENT.termination_type_error}
                  setModalErrorState={setModalErrorState}
                  fieldName="terminationType"
                  hasNoBottomMargin
                  labelMarginTop="0rem"
                  labelMarginBottom="0.25rem"
                  listItemFontSize="0.875rem"
                />
              </FieldSectionWrapper>
            )}
            <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
              <CustomDatePicker {...datePlantedProps} />
              <CustomDatePicker {...dateTerminatedProps} />
            </FieldSectionWrapper>
            {errorState.datePlantedError && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.date_planted_error}
              </TextWrapper>
            )}
            {errorState.dateTerminatedError && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.date_terminated_error}
              </TextWrapper>
            )}
            {dateHarvestedError.dateExceed && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.terminated_date_error}
              </TextWrapper>
            )}
            {dateHarvestedError.notInRange && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.date_terminated_year_error}
              </TextWrapper>
            )}
            {datePlantedError.notInRange && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.date_planted_year_error}
              </TextWrapper>
            )}
            {datePlantedError.dateExceed && (
              <TextWrapper color={RED} fontSize="12px">
                {MODAL_CONTENT.plant_date_error}
              </TextWrapper>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          disabled={isSubmitDisabled}
          onClick={() => {
            submitBtnHandler();
          }}
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}>
          {MODAL_CONTENT.submit}
        </Button>
        <Button
          disableElevation
          onClick={() => {
            modalCloseHandler();
          }}
          sx={[CancelBtnStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CoverCropModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
  hasTestDefaultDate: PropTypes.bool,
};
export default CoverCropModal;
