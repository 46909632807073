import UserList from 'pages/UserManagement/UserList.react';

export const SETTINGS_CONTENT = {
  setting: 'Settings',
  logoUpdateError:
    "Logo couldn't be updated due to technical error. Please, try again.",
  supportEmailUpdateError:
    "Support email couldn't be updated due to technical error. Please, try again.",
  dragAndDropText: 'Drag and drop .svg, .png, or .jpg file here or ',
  browse: 'browse.',
  generalSectionHeader: 'General',
  type: 'Type',
  content: 'Content',
  clientLogo: 'Client Logo',
  clientSupportEmail: 'Client Support Email',
  clientInformationEmail: 'Client information email (no reply)',
  emailTooltipContent:
    'Email address is only configurable by admin. Please contact your support admin if you have questions related to email setup.',
  secondSectionHeader: 'Modules',
  secondSectionColumnOne: 'Name',
  secondSectionColumnTwo: 'Active',
  saveBtnLabel: 'Save',
};

export const settingsTabs = [{ title: 'Users', component: <UserList /> }];

export const MODULE_LIST_INITIAL_STATE = [
  {
    moduleId: 1,
    moduleName: 'Carbon asset origination',
    moduleKey: 'carbon_asset_origination',
    isToggled: false,
  },
  {
    moduleId: 2,
    moduleName: 'Inset management',
    moduleKey: 'inset_management',
    isToggled: false,
  },
  {
    moduleId: 3,
    moduleName: 'Offset management',
    moduleKey: 'offset_management',
    isToggled: false,
  },
];

export const getModuleDataValue = (moduleKey, modulesList) => {
  return modulesList.find((module) => module.key === moduleKey);
};
