import { useState, useCallback, useEffect, useContext } from 'react';
import axios from 'axios';
import { MenuItem, IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuSx, menuItemSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';
import RecruitmentMenuItems from '../ActivityMenuItem/RecruitmentMenuItem.react';
import EnrollmentMenuItems from '../ActivityMenuItem/EnrollmentMenuItem.react';
import BaselineReportingMenuItems from '../ActivityMenuItem/BaselineReportingMenuItem.react';
import { COOL_GRAY } from 'theme/GlobalColors';
import { ACTIVITY_TYPE } from './ActivityOptions.constants';
import { ActivityReportingMenuItems } from '../ActivityMenuItem/ActivityReportingMenuItems.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import {
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import OutreachMenuItems from '../ActivityMenuItem/OutreachMenuItem.react';

export const ActivityOptionsButton = () => {
  const { isClosed, status, activityType, anchorEl, setAnchorEl } =
    useActivityContext();
  const open = Boolean(anchorEl);
  const { userRoles } = useContext(userAuthRolesContext);
  // Handle button click
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }, []);

  // Handle menu close
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Close menu when isClosed changes to true
  useEffect(() => {
    isClosed && handleClose();
  }, [isClosed, handleClose]);
  const disableCheck = () => {
    return checkTernaryCondition(
      (activityType === ACTIVITY_TYPE.enrollment ||
        activityType === ACTIVITY_TYPE.baseline_reporting ||
        activityType === ACTIVITY_TYPE.activity_reporting ||
        activityType === ACTIVITY_TYPE.outreach ||
        activityType === ACTIVITY_TYPE.contracting) &&
        hasAnyRequiredAccess(userRoles, [
          ORIGINATION_VIEWER,
          PARTICIPANT,
          PARTICIPANT_CONTRIBUTOR,
        ]),
      true,
      false,
    );
  };
  // Determine which menu items to render based on activityType
  const renderMenuItems = () => {
    switch (activityType) {
      case ACTIVITY_TYPE.outreach:
        return <OutreachMenuItems />;
      case ACTIVITY_TYPE.contracting:
        return <RecruitmentMenuItems />;
      case ACTIVITY_TYPE.enrollment:
        return <EnrollmentMenuItems />;
      case ACTIVITY_TYPE.baseline_reporting:
        return <BaselineReportingMenuItems />;
      case ACTIVITY_TYPE.activity_reporting:
        return <ActivityReportingMenuItems />;
      default:
        return null;
    }
  };

  return (
    <>
      <IconButton
        disabled={disableCheck()}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: 0, color: COOL_GRAY }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MenuSx}>
        {renderMenuItems()}
      </Menu>
    </>
  );
};
