import axios from 'axios';
import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { FETCH_ALL_USER_ACTIONS } from 'urls';

// Create the context
export const ParticipantActivityContext = createContext();

// Create the provider component
export const ParticipantActivityProvider = ({
  projectData: { id: projectId, projectName },
  participantName,
  activityData,
  participantId: participantId,
  children,
}) => {
  const [participantActivity, setParticipantActivity] = useState([]);

  const updateParticipantActivity = (newActivity) => {
    setParticipantActivity(newActivity);
  };

  const value = useMemo(
    () => ({
      projectId,
      projectName,
      participantActivity,
      updateParticipantActivity,
      participantId,
      participantName,
      activityData,
    }),
    [participantId, activityData],
  );

  return (
    <ParticipantActivityContext.Provider value={value}>
      {children}
    </ParticipantActivityContext.Provider>
  );
};
