import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import greenfoodsco from './../../../assets/icons/greenfoodsco.png';
import { getReportDateIssued } from 'utils/helper';
import { styles } from './FirstPage.style';
import PropTypes from 'prop-types';

const detailsList = [
  {
    heading: 'Prepared by:',
    value: 'Green Foods CO',
  },
  {
    heading: 'Date of Issue:',
    value: getReportDateIssued(new Date()),
  },
  {
    heading: 'Contact:',
    value: 'Rodney Rogerfield',
  },
  {
    heading: '',
    value: 'rodney.rogerfield@demo.com',
  },
];

const FirstPage = ({ originationProjectDetails }) => (
  <View style={styles?.container}>
    <Text style={styles?.heading}>
      {originationProjectDetails?.projectName}
    </Text>
    <Image src={greenfoodsco} style={styles?.imageStyle} />
    <View>
      {detailsList.map((item) => (
        <View style={styles?.row} key={item}>
          <Text style={styles?.description}>{`${item.heading} `}</Text>
          <Text style={styles?.value}>{`${item.value}`}</Text>
        </View>
      ))}
    </View>
  </View>
);

FirstPage.propTypes = {
  originationProjectDetails: PropTypes.object,
};
export default FirstPage;
