import { checkTernaryCondition } from 'utils/helper';
import PropTypes from 'prop-types';
import { ReactComponent as InProgressIcon } from '../../../../assets/icons/InProgressIcon.svg';
import { ReactComponent as GreenTickIcon } from '../../../../assets/icons/ActivityStatus.svg';
import { ReactComponent as YellowStatus } from '../../../../assets/icons/yellowStatus.svg';
import { ReactComponent as GreenStatus } from '../../../../assets/icons/greenStatus.svg';
import { ReactComponent as NotStartedStatus } from '../../../../assets/icons/NotStartedStatus.svg';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  FieldWrapper,
  IconWrapper,
  MainWrapper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  iconStyles,
  fullWidth,
  getFieldPadding,
  getFieldBackgroundColor,
  FieldLeftBorderDiv,
  FarmsAndFieldsWrapperPadding,
  AccordianSummaryWithMapViewLink,
  getFarmAndFieldsPadding,
  FarmHeaderWrapper,
  StatusHeaderWrapper,
  deleteIcon,
  HorizontalLine,
  addFieldsOnMapPadding,
  getAddFieldsOnMapBackgroundColor,
} from './LeftMenuFieldData.style';
import { ReactComponent as UpdatedCircleIcon } from '../../../../assets/icons/updatedCircle.svg';
import { ReactComponent as DefaultProfileIcon } from '../../../../assets/icons/personIcon.svg';
import { ReactComponent as FarmIconUnselected } from '../../../../assets/icons/UnselectedFarm.svg';
import { ReactComponent as FarmIcon } from '../../../../assets/icons/SelectedFarmIcon.svg';
import DefaultFieldIcon from '../../../../assets/icons/fieldIconDefault.svg';
import { ReactComponent as SelectedFieldIcon } from '../../../../assets/icons/fieldIconSelected.svg';
import { ReactComponent as SelectedProfileIcon } from '../../../../assets/icons/profileIconSelected.svg';
import {
  GLOBAL_BLUE_HOVER,
  GLOBAL_BLUE,
  HOVER_LIGHT_BLUE,
  BERLIN_BLUE,
} from 'theme/GlobalColors';
import { useMemo, useState } from 'react';
import ControlledInput from './ControlledInput/ControlledInput.react';
import {
  PAGE_CONTENT,
  deleteModalInit,
  deleteTypes,
  farmAndFieldTabStatus,
  getDeleteModalSubTitle,
  getDeleteModalTitle,
  getFarmTabColor,
} from './LeftMenuFieldData.content';
import DialogBox from 'components/DialogBox/DialogBox.react';
import axios from 'axios';
import { FARM_INFO_BASE, FIELD_INFO_BASE } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import {
  LEFT_MENU_SECTION_HEADERS,
  toastStyle,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { Backdrop, CircularProgress } from '@mui/material';
import { backdropSx } from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import { pointerCursor } from 'theme/GlobalStyles';

const IconStatusHandler = (status) => {
  switch (status) {
    case 'complete':
    case 'completed':
      return <GreenTickIcon data-testid="complete" style={iconStyles} />;
    case 'in progress':
      return <InProgressIcon data-testid="in progress" style={iconStyles} />;
    default:
      return <UpdatedCircleIcon style={iconStyles} />;
  }
};

const FieldIcon = ({ selected = false }) => {
  if (selected)
    return <SelectedFieldIcon data-testid="field-icon" style={iconStyles} />;
  else
    return (
      <img src={DefaultFieldIcon} style={iconStyles} data-testid="field-icon" />
    );
};

const ProfileIcon = ({ selected = false }) => {
  if (selected)
    return (
      <SelectedProfileIcon data-testid="profile-icon" style={iconStyles} />
    );
  else
    return <DefaultProfileIcon data-testid="profile-icon" style={iconStyles} />;
};

const LeftMenuFieldData = ({
  heading,
  // Note: Here fieldValue is the farm object
  fieldValue,
  hasCollapsableOptions,
  showFields,
  toggleFieldsDataView,
  setCurrentFieldId,
  insideEnrollmentForm,
  setRightSectionId,
  updateFarmFieldName,
  currentFarmId,
  setCurrentFarmId,
  setOpenMapView,
  setSelectedFieldId,
  setShowFieldInfoForm,
  setFieldAddTypeModalOpen,
  farmInfoCurrentTab,
  setFarmInfoCurrentTab,
  setExpandedFarmId,
  farmsIdsWithExistingFields,
  farmInfo,
  setFarmInfo,
  selectedFieldId,
  setFieldPolygons,
  rightSectionId,
  setIsTabSwitched,
  isInBaselinePage,
  showFieldInfoForm,
  setFarmDetails,
  isFarmSelected,
  expandedFarmId,
  setCurrentFarmLocation = () => {},
  saveDataHandler = () => {},
  setIsFormUnmounted = () => {},
  setIsFieldDeleted = () => {},
  resetFieldViewTab = () => {},
  enrolledFarmIds,
  enrolledFieldIds,
  handleAccordianSummaryDeleteClick = () => {},
  isParticipantContributor,
  handleViewMapClick,
  handleAccordianSummaryClick = () => {},
  handleAccordianItemClick = () => {},
  isInsideActivityReporting = false,
}) => {
  const [hoveredFieldId, setHoveredFeildId] = useState(-1);
  const [isFarmHovered, setIsFarmHovered] = useState(false);
  const [deleteModalContent, setDeleModalContent] = useState(deleteModalInit);
  const [farmSelectedId, setFarmSelectedId] = useState(fieldValue?.id);
  const [loading, setLoading] = useState(false);
  const totalFields = fieldValue?.fields?.length;
  const totalFieldCompleted = fieldValue?.fields?.reduce((acc, obj) => {
    if (obj.status === 'Completed') {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  const inFarmAndFieldsSection =
    heading === LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection;
  const inParticipantProfileSection =
    heading === LEFT_MENU_SECTION_HEADERS.profileSection;
  const inFarmAndContributorTab =
    heading === LEFT_MENU_SECTION_HEADERS.farmAndContributorSection;

  const changeRightSection = () => {
    if (inParticipantProfileSection) {
      setRightSectionId(1);
    } else if (inFarmAndFieldsSection) {
      setRightSectionId(2);
    } else {
      setRightSectionId(3);
    }
  };

  const disableCurrentFarm = useMemo(() => {
    if (!enrolledFarmIds || !fieldValue?.id) return false;
    return enrolledFarmIds.includes(fieldValue.id);
  }, [enrolledFarmIds, fieldValue.id]);

  const handleOnChange = (newValue, field) => {
    updateFarmFieldName({
      farmId: fieldValue.id,
      fieldId: field.id,
      name: newValue,
    });
  };

  const setStatusIcon = (isInBaselinePage, isParticipantContributor) => {
    const isCompleted = totalFields === totalFieldCompleted;
    if (isInBaselinePage) {
      return checkTernaryCondition(
        isCompleted,
        <GreenStatus />,
        <YellowStatus />,
      );
    } else if (isParticipantContributor) {
      const allNotStarted = fieldValue?.fields.every(
        (field) => field.status === farmAndFieldTabStatus.notStarted,
      );
      const anyInProgress = fieldValue?.fields.some(
        (field) =>
          field.status === farmAndFieldTabStatus.inProgress ||
          field.status === farmAndFieldTabStatus.completed,
      );
      if (allNotStarted && totalFields > 0) {
        return <NotStartedStatus />;
      } else if (isCompleted) {
        return <GreenStatus />;
      } else if (anyInProgress) {
        return <YellowStatus />;
      }
    }
  };

  const farmDeleteStateUpdater = (deletedFarmId, deletedFarmName) => {
    if (farmInfo.length === 1) {
      setFarmInfoCurrentTab(0);
      setFarmInfo((prevInfo) =>
        prevInfo.filter((farm) => farm.id !== deletedFarmId),
      );
      setRightSectionId(1);
      setShowFieldInfoForm(false);
      setExpandedFarmId(-1);
      setCurrentFarmId(-1);
    } else {
      setFarmInfo((prevInfo) =>
        prevInfo.filter((farm) => farm.id !== deletedFarmId),
      );

      if (deletedFarmId === currentFarmId) {
        const retainedFirstFarmIndex = farmInfo.findIndex(
          (farm) => farm.id !== deletedFarmId,
        );
        const retainedFirstFarmId = farmInfo[retainedFirstFarmIndex].id;
        setCurrentFarmId(retainedFirstFarmId);
        setExpandedFarmId(retainedFirstFarmId);
      }
    }
    toast(
      <CustomSnackbar
        type="success"
        message={`${deletedFarmName} ${PAGE_CONTENT.deleteSuccessSuffix}`}
      />,
      toastStyle,
    );
  };
  const handleDelete = ({ type, farmId, fieldId, farmName, fieldName }) => {
    if (type === deleteTypes[0]) {
      // This is to check if the farm is just in frontend, we will have a string value(uniqueId()) as the farmId if it is just in frontend, if so we just update the state and not make any api call
      if (typeof farmId === 'string') farmDeleteStateUpdater(farmId, farmName);
      else farmDeleteHandler(farmId, farmName);
    } else if (type === deleteTypes[1]) {
      fieldDeleteHandler(farmId, fieldId, fieldName);
    }
  };

  const farmDeleteHandler = (farmId, farmName) => {
    setLoading(true);
    axios
      .delete(`${FARM_INFO_BASE}/${farmId}`)
      .then(() => {
        handleAccordianSummaryDeleteClick();
        farmDeleteStateUpdater(farmId, farmName);
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={`${farmName} ${PAGE_CONTENT.deleteErrorSuffix}`}
          />,
          toastStyle,
        );
      })
      .finally(() => setLoading(false));
  };

  const fieldDeleteHandler = (farmId, fieldId, fieldName) => {
    setLoading(true);
    axios
      .delete(`${FIELD_INFO_BASE}/${fieldId}`)
      .then((response) => {
        toast(
          <CustomSnackbar
            type="success"
            message={`${fieldName} ${PAGE_CONTENT.deleteSuccessSuffix}`}
          />,
          toastStyle,
        );
        setIsFieldDeleted(true);

        setFieldPolygons((prev) =>
          prev.filter((polygon) => polygon.fieldId !== fieldId),
        );

        const farmIndex = farmInfo.findIndex((farm) => farm.id === farmId);
        if (farmInfo[farmIndex].fields?.length === 1) {
          setFarmInfo((prevInfo) =>
            prevInfo.map((farm) =>
              checkTernaryCondition(
                farm.id === farmId,
                {
                  ...farm,
                  fields: farm.fields?.filter((field) => field.id !== fieldId),
                },
                farm,
              ),
            ),
          );

          setShowFieldInfoForm(false);
          setFarmInfoCurrentTab(0);
          setCurrentFarmId(farmId);
        } else {
          setFarmInfo((prevInfo) =>
            prevInfo.map((farm) =>
              checkTernaryCondition(
                farm.id === farmId,
                {
                  ...farm,
                  fields: farm.fields?.filter((field) => field.id !== fieldId),
                },
                farm,
              ),
            ),
          );

          if (fieldId === selectedFieldId?.id) {
            const retainedFirstFieldIndex = farmInfo[
              farmIndex
            ].fields.findIndex((field) => field.id !== fieldId);
            const retainedFirstField =
              farmInfo[farmIndex].fields[retainedFirstFieldIndex];
            setSelectedFieldId({
              id: retainedFirstField.id,
              name: retainedFirstField.value,
            });
          }
        }

        setFarmInfo((prev) =>
          prev.map((farm) =>
            checkTernaryCondition(
              farm.id === farmId,
              {
                ...farm,
                status: response?.data?.farmFormStatus,
              },
              farm,
            ),
          ),
        );
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={`${fieldName} ${PAGE_CONTENT.deleteErrorSuffix}`}
          />,
          toastStyle,
        );
      })
      .finally(() => setLoading(false));
  };

  const currentRowTitleIcon = checkTernaryCondition(
    heading === LEFT_MENU_SECTION_HEADERS.profileSection,
    <ProfileIcon selected={rightSectionId === 1} />,
    checkTernaryCondition(
      farmSelectedId === currentFarmId,
      <FarmIcon style={iconStyles} />,
      <FarmIconUnselected style={iconStyles} />,
    ),
  );

  const getAccrodianColors = (flag) => {
    if (isFarmHovered) {
      return checkTernaryCondition(
        flag === 'bgColor',
        HOVER_LIGHT_BLUE,
        GLOBAL_BLUE_HOVER,
      );
    }
    const baselineFarmSelected =
      isInBaselinePage && currentFarmId === fieldValue.id && isFarmSelected;
    const profileSectionOpened =
      inParticipantProfileSection && rightSectionId === 1;
    const enrollmentFarmOpened =
      inFarmAndFieldsSection &&
      rightSectionId === 2 &&
      currentFarmId === fieldValue.id &&
      !showFieldInfoForm &&
      farmInfoCurrentTab === 0;
    const farmAndContributorTabOpened =
      inFarmAndContributorTab && rightSectionId === 3;
    if (
      baselineFarmSelected ||
      profileSectionOpened ||
      enrollmentFarmOpened ||
      farmAndContributorTabOpened
    ) {
      return getFarmTabColor(flag === 'bgColor');
    } else {
      return checkTernaryCondition(flag === 'bgColor', 'white', GLOBAL_BLUE);
    }
  };

  const handleFieldClick = (field, farmId) => {
    setFarmSelectedId(null);
    setCurrentFarmId(farmId);

    if (isInsideActivityReporting) {
      handleAccordianItemClick({
        targetId: field?.id,
        targetName: field?.value,
      });
    }
    if (rightSectionId === 3) {
      handleAccordianItemClick({
        targetPlace: 'Field',
        targetId: field?.id,
        targetName: field?.value,
      });
    } else {
      if (setCurrentFieldId) {
        setShowFieldInfoForm(true);
        setCurrentFieldId({
          id: field.id,
          name: field.value,
        });
      } else {
        setRightSectionId(2);
        setOpenMapView(true);
        setShowFieldInfoForm(true);
        setSelectedFieldId({
          id: field.id,
          name: field.value,
        });
      }
      /**
       * saveDataHandler(isSaveAndCloseClicked, isUnmounting, isTabSwitched, clickData)
       */
      isInBaselinePage &&
        saveDataHandler(false, true, false, {
          type: 'field',
          id: fieldValue?.id,
        });
      if (rightSectionId === 2) {
        if (!showFieldInfoForm) {
          setIsFormUnmounted({
            currentPage: 'Farm',
            flag: true,
            id: fieldValue?.id,
          });
        } else {
          setIsFormUnmounted({
            currentPage: 'Field',
            flag: true,
            id: fieldValue?.id,
          });
        }
      }
      if (rightSectionId === 1) {
        setIsFormUnmounted({
          currentPage: 'Profile',
          flag: true,
          id: fieldValue?.id,
        });
      }
    }
  };

  return (
    <Accordion expanded={showFields}>
      <AccordionSummary
        collapsable={hasCollapsableOptions}
        toggleAccordion={toggleFieldsDataView}
        backgroundColor={getAccrodianColors('bgColor')}
        onMouseEnter={() => setIsFarmHovered(true)}
        onMouseLeave={() => setIsFarmHovered(false)}>
        <AccordianSummaryWithMapViewLink>
          <FarmHeaderWrapper>
            <MainWrapper
              padding={checkTernaryCondition(
                insideEnrollmentForm,
                inFarmAndFieldsSection &&
                  getFarmAndFieldsPadding(isFarmHovered),
                FarmsAndFieldsWrapperPadding,
              )}
              width={insideEnrollmentForm && inFarmAndFieldsSection && '100%'}>
              <IconWrapper>{currentRowTitleIcon}</IconWrapper>
              <FieldWrapper
                color={getAccrodianColors('')}
                selected={
                  isFarmSelected ||
                  (fieldValue?.id === currentFarmId &&
                    !showFieldInfoForm &&
                    !isInBaselinePage) ||
                  (inParticipantProfileSection && rightSectionId === 1)
                }
                minWidth="100px"
                onClick={() => {
                  resetFieldViewTab();

                  if (isInsideActivityReporting) {
                    handleAccordianSummaryClick({
                      targetId: fieldValue?.id,
                      targetName: fieldValue?.value,
                    });
                    return;
                  }
                  if (rightSectionId === 3) {
                    handleAccordianSummaryClick({
                      targetPlace: heading,
                      targetId: fieldValue?.id,
                    });
                  } else {
                    changeRightSection();
                    if (isInBaselinePage) {
                      setFarmDetails({
                        id: fieldValue?.id,
                        name: fieldValue.value,
                      });
                      /**
                       * saveDataHandler(isSaveAndCloseClicked, isUnmounting, isTabSwitched,clickData)
                       */
                      saveDataHandler(false, true, false, {
                        type: 'farm',
                        id: fieldValue?.id,
                      });
                    }
                    if (inFarmAndContributorTab) {
                      setIsFormUnmounted({
                        currentPage:
                          LEFT_MENU_SECTION_HEADERS.farmAndContributorSection,
                        flag: true,
                        id: fieldValue?.id,
                      });
                    }

                    if (inFarmAndFieldsSection) {
                      setCurrentFarmId(fieldValue?.id);
                      setExpandedFarmId(fieldValue?.id);
                      setFarmInfoCurrentTab(0);
                      setShowFieldInfoForm(false);
                      setOpenMapView(false);
                      if (showFieldInfoForm) {
                        setIsFormUnmounted({
                          currentPage: 'Field',
                          flag: true,
                          id: fieldValue?.id,
                        });
                      } else {
                        setIsFormUnmounted({
                          currentPage: 'Farm',
                          flag: true,
                          id: fieldValue?.id,
                        });
                      }
                      if (rightSectionId === 1) {
                        setIsFormUnmounted({
                          currentPage: 'Profile',
                          flag: true,
                          id: fieldValue?.id,
                        });
                      }
                    } else if (inParticipantProfileSection) {
                      setCurrentFarmId(-1);
                      setExpandedFarmId(-1);
                      setFarmInfoCurrentTab(0);
                      setShowFieldInfoForm(false);
                      if (rightSectionId === 2) {
                        if (showFieldInfoForm) {
                          setIsFormUnmounted({
                            currentPage: 'Field',
                            flag: true,
                            id: fieldValue?.id,
                          });
                        } else {
                          setIsFormUnmounted({
                            currentPage: 'Farm',
                            flag: true,
                            id: fieldValue?.id,
                          });
                        }
                      }
                    }
                    setFarmSelectedId(fieldValue?.id);
                  }
                }}>
                {fieldValue.value}
              </FieldWrapper>
              <IconWrapper>
                {IconStatusHandler(fieldValue.status?.toLowerCase())}
              </IconWrapper>
              {!disableCurrentFarm &&
                insideEnrollmentForm &&
                inFarmAndFieldsSection &&
                !isParticipantContributor &&
                isFarmHovered && (
                  <DeleteIcon
                    data-testid="DeleteIcon"
                    width={24}
                    height={24}
                    style={{ ...pointerCursor, ...deleteIcon }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setDeleModalContent({
                        open: true,
                        title: getDeleteModalTitle(fieldValue.value),
                        subtitle: getDeleteModalSubTitle(fieldValue.value),
                        deleteProps: {
                          type: 'farm',
                          farmId: fieldValue?.id,
                          farmName: fieldValue.value,
                        },
                      });
                    }}
                  />
                )}
            </MainWrapper>
            {(isInBaselinePage || isParticipantContributor) && (
              <StatusHeaderWrapper data-testid="sub-section">
                {setStatusIcon(isInBaselinePage, isParticipantContributor)}
                {`${totalFieldCompleted} / ${totalFields} fields complete`}
              </StatusHeaderWrapper>
            )}
          </FarmHeaderWrapper>
          <div>
            {expandedFarmId === fieldValue.id &&
              fieldValue?.fields?.length > 0 && (
                <FieldLeftBorderDiv farmTab height="0%" />
              )}
          </div>
        </AccordianSummaryWithMapViewLink>
      </AccordionSummary>
      <AccordionDetails>
        {insideEnrollmentForm && (
          <MainWrapper
            isHovered
            data-testid="add-fields"
            width={fullWidth}
            padding={addFieldsOnMapPadding()}
            backgroundColor={getAddFieldsOnMapBackgroundColor(
              fieldValue?.id === currentFarmId &&
                farmInfoCurrentTab === 1 &&
                !showFieldInfoForm,
            )}
            onClick={() => {
              resetFieldViewTab();
              if (rightSectionId === 3) {
                handleAccordianItemClick({
                  targetPlace: 'Add fields',
                  targetId: fieldValue?.id,
                });
              } else {
                if (rightSectionId === 1 || rightSectionId === 3) {
                  setCurrentFarmId(fieldValue?.id);
                  setExpandedFarmId(fieldValue?.id);
                  setFarmInfoCurrentTab(1);
                  setRightSectionId(2);
                } else {
                  setCurrentFarmLocation(fieldValue.id);
                  setCurrentFarmId(fieldValue?.id);
                  setExpandedFarmId(fieldValue?.id);
                  setIsTabSwitched(fieldValue?.id === currentFarmId);
                  setShowFieldInfoForm(false);
                  setFarmInfoCurrentTab(1);
                  if (!farmsIdsWithExistingFields.includes(fieldValue.id)) {
                    setFieldAddTypeModalOpen(true);
                  }
                }
              }
            }}>
            <FieldLeftBorderDiv
              height={checkTernaryCondition(
                fieldValue?.fields?.length === 0,
                '50%',
                '100%',
              )}
              lastField={false}
            />
            <HorizontalLine isField={false} />
            <IconWrapper>
              <FieldIcon
                selected={
                  fieldValue?.id === currentFarmId && farmInfoCurrentTab === 1
                }
              />
            </IconWrapper>
            <FieldWrapper
              color={checkTernaryCondition(
                fieldValue?.id === currentFarmId && farmInfoCurrentTab === 1,
                BERLIN_BLUE,
                GLOBAL_BLUE,
              )}
              cursor={'pointer'}
              minWidth="100px">
              {PAGE_CONTENT.add_fields_on_map}
            </FieldWrapper>
          </MainWrapper>
        )}
        {fieldValue?.fields &&
          fieldValue?.fields.map((field, index) => {
            const isLastField = index === fieldValue.fields.length - 1;
            const disableField = enrolledFieldIds?.includes(field.id);
            return (
              <MainWrapper
                data-testid="field-data"
                key={field.id}
                width={fullWidth}
                padding={getFieldPadding(
                  insideEnrollmentForm && hoveredFieldId === field.id,
                )}
                backgroundColor={getFieldBackgroundColor(
                  hoveredFieldId === field.id,
                  checkTernaryCondition(
                    insideEnrollmentForm,
                    selectedFieldId?.id === field.id && showFieldInfoForm,
                    selectedFieldId?.id === field.id,
                  ),
                  isFarmSelected,
                )}
                onMouseEnter={() => setHoveredFeildId(field.id)}
                onMouseLeave={() => setHoveredFeildId(-1)}
                onClick={() => handleFieldClick(field, fieldValue.id)}>
                <FieldLeftBorderDiv
                  height={checkTernaryCondition(isLastField, '50%', '100%')}
                  lastField={false}
                />
                <HorizontalLine isField={true} />
                <ControlledInput
                  disabled={disableField}
                  fieldSelected={
                    selectedFieldId?.id === field.id &&
                    (showFieldInfoForm || (isInBaselinePage && !isFarmSelected))
                  }
                  isInBaselinePage={isInBaselinePage}
                  hoveredFieldId={checkTernaryCondition(
                    insideEnrollmentForm,
                    hoveredFieldId,
                    -1,
                  )}
                  field={field}
                  setValue={(newValue) => handleOnChange(newValue, field)}
                  farmInfo={checkTernaryCondition(
                    insideEnrollmentForm,
                    farmInfo,
                    [],
                  )}
                  isFieldInCurrentFarm={fieldValue.id === currentFarmId}
                />
                <IconWrapper>
                  {IconStatusHandler(field.status?.toLowerCase())}
                </IconWrapper>
                {!disableField &&
                  insideEnrollmentForm &&
                  hoveredFieldId === field.id && (
                    <DeleteIcon
                      data-testid="DeleteIcon"
                      width={24}
                      height={24}
                      style={{ ...pointerCursor, ...deleteIcon }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleModalContent({
                          open: true,
                          title: getDeleteModalTitle(field?.value),
                          subtitle: getDeleteModalSubTitle(field?.value),
                          deleteProps: {
                            type: 'field',
                            fieldId: field?.id,
                            farmId: fieldValue?.id,
                            fieldName: field?.value,
                          },
                        });
                      }}
                    />
                  )}
              </MainWrapper>
            );
          })}
      </AccordionDetails>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={deleteModalContent.open}
        onConfirm={() => {
          handleDelete(deleteModalContent.deleteProps);
          setDeleModalContent(deleteModalInit);
        }}
        onCancel={() => setDeleModalContent(deleteModalInit)}
        title={deleteModalContent.title}
        subtitle={deleteModalContent.subtitle}
        declineCtnLabel={PAGE_CONTENT.deleteModalCancelText}
        acceptCtnLabel={PAGE_CONTENT.deleteModalAcceptText}
      />
      <Backdrop sx={backdropSx} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
    </Accordion>
  );
};

LeftMenuFieldData.propTypes = {
  heading: PropTypes.string,
  fieldValue: PropTypes.object,
  hasCollapsableOptions: PropTypes.bool,
  showFields: PropTypes.bool,
  toggleFieldsDataView: PropTypes.func,
  setCurrentFieldId: PropTypes.func,
  insideEnrollmentForm: PropTypes.bool,
  setRightSectionId: PropTypes.func,
  updateFarmFieldName: PropTypes.func,
  currentFarmId: PropTypes.string,
  setCurrentFarmId: PropTypes.func,
  setOpenMapView: PropTypes.func,
  setSelectedFieldId: PropTypes.func,
  setShowFieldInfoForm: PropTypes.func,
  setFieldAddTypeModalOpen: PropTypes.func,
  farmInfoCurrentTab: PropTypes.number,
  setFarmInfoCurrentTab: PropTypes.func,
  setExpandedFarmId: PropTypes.func,
  farmsIdsWithExistingFields: PropTypes.array,
  farmInfo: PropTypes.array,
  setFarmInfo: PropTypes.func,
  selectedFieldId: PropTypes.string,
  setFieldPolygons: PropTypes.func,
  rightSectionId: PropTypes.string,
  setIsTabSwitched: PropTypes.func,
  isInBaselinePage: PropTypes.bool,
  showFieldInfoForm: PropTypes.bool,
  setFarmDetails: PropTypes.func,
  isFarmSelected: PropTypes.bool,
  expandedFarmId: PropTypes.string,
  setCurrentFarmLocation: PropTypes.func,
  saveDataHandler: PropTypes.func,
  setIsFormUnmounted: PropTypes.func,
  setIsFieldDeleted: PropTypes.func,
  resetFieldViewTab: PropTypes.func,
  enrolledFarmIds: PropTypes.array,
  enrolledFieldIds: PropTypes.array,
  handleAccordianSummaryDeleteClick: PropTypes.func,
  handleViewMapClick: PropTypes.func,
};
export default LeftMenuFieldData;
