export const FORM_CONTENT = {
  empty_string: '',
  error_class: 'Mui-error',
  standard_variant: 'standard',
  success: 'success',
  checkbox: 'checkbox',
  disabled: 'disabled',
  small: 'small',
  row_radio_buttons_group: 'row-radio-buttons-group',
  radio_label_group: 'demo-row-radio-buttons-group-label',
  space: ' ',
  comma: ',',
  outlined: 'outlined',
  medium: 'medium',
  contained: 'contained',
  optional: '(Optional)',
  manualErrorString: 'Invalid Date',
};
