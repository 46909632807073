export const TRANSACTION_CONTENT = {
  delete_message_line1:
    'Are you sure you want to delete and all data associated with it?',
  success: 'SUCCESS',
  success_message: 'has been deleted from your portfolio.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  delete_message_line2:
    'Any associated projects or stakeholders will not be deleted.',
  pagination_label: 'Items per page',
  denied: 'DENIED',
  noDataContent: 'Looks like you don’t have any transactions yet',
  addNewTransactionLabel: 'Add a new transaction to get started!',
  addButton: 'Add Transaction',
  sarchTransactionPlacement: 'Search by transaction or project name',
  sarchNamePlacement: 'Search by name or customer',
};
export const addNewButtonOptions = [
  {
    name: 'Purchase Transaction',
    link: '/transactions/add-purchase-transaction',
  },
  { name: 'Sale Transaction', link: '/transactions/add-sale-transaction' },
];

export const SortColValues = {
  contractName: 'Transaction Name',
  vendorName: 'Stakeholder Name',
  contractType: 'Type',
  status: 'Status',
  signedOn: 'Signed On',
};
