import styled from 'styled-components';
import { WHITE } from 'theme/GlobalColors';

export const ProjectSettingsWrapper = styled.div`
  display: flex;
  background-color: ${WHITE};
`;

export const ProjectSettingsContent = styled.div`
  width: 70%;
  padding: 1.5rem 1rem;
`;
