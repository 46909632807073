import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import {
  Field,
  FieldTitle,
  FieldValue,
  MainWrapper,
} from './DetailedCalculationPanel.style';

const DetailedCalculationPanel = ({ data }) => {
  return (
    <MainWrapper>
      {data.map((item) => (
        <Field key={item.label} minWidth={'9.85rem'}>
          <FieldTitle>{item.label}</FieldTitle>
          <FieldValue>
            {checkTernaryCondition(item.value, item.value, '--')}
          </FieldValue>
        </Field>
      ))}
    </MainWrapper>
  );
};

DetailedCalculationPanel.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DetailedCalculationPanel;
