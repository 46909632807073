import PropTypes from 'prop-types';
import { Input } from '@mui/material';
import { FORM_CONTENT } from '../FormComponents.content';
import {
  FormFields,
  FormLabels,
  ErrorWrapper,
  RightSideFormLabel,
  RightSideLabelErrorWrapper,
  DeleteWrapper,
  PredefinedUnitWrapper,
  DropdownInputSx,
  OptionalLabel,
  LabelToolTipWrapper,
  LeftSideFormLabel,
} from '../FormStyles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { checkTernaryCondition } from 'utils/helper';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import {
  BLACK,
  COOL_GRAY,
  NEUTRAL_BACKGROUND_COLOR,
  WHITE,
} from 'theme/GlobalColors';

const InputField = ({
  label,
  value,
  placeholder,
  name,
  optionalLabelStyle,
  onUpdate,
  onBlurHandler,
  onFocusHandler,
  ariaLabel,
  width,
  primaryError,
  primaryErrorMessage,
  secondaryError,
  secondaryErrorMessage,
  isOptional,
  readOnly,
  maxLength,
  isDisabled,
  labelOnRightSide,
  labelOnLeftSide,
  display,
  rightSideLabelFormLabelMargin,
  hasDeleteIcon,
  onDelete,
  deleteTestId,
  noLabel = false,
  customStyles,
  minWidth,
  hasPredefinedUnit = false,
  predefinedUnit,
  testId,
  hasTooltip = false,
  tooltipContent,
  tooltipTitle = '',
  labelWidth,
  thirdError,
  thirdErrorMessage,
  customInputSx,
  labelOnRightSideColor,
  labelMarginTop,
  labelMarginBottom,
  labelLineHeight,
  frontLabel,
  listItemName,
  listItemNameColor,
  paddingForError,
  hasNoBottomMargin,
  listItemMarginLeft,
  frontlabelMarginLeft,
  cropValueLineHeight,
  customPattern,
  noGap = false,
  inputTextFieldColor,
  textDisabledColor,
  optionalOpacity = 0.5,
  textAlignment,
  optionalLabelOnRight = false,
}) => {
  const hasError = primaryError || secondaryError || thirdError;
  return (
    <div style={customStyles}>
      <FormFields
        marginBottom={rightSideLabelFormLabelMargin}
        readOnly={readOnly}
        display={display}
        minWidth={minWidth}>
        {checkTernaryCondition(
          noLabel,
          null,
          !labelOnRightSide && !labelOnLeftSide && (
            <FormLabels
              iserror={hasError}
              className={hasError && FORM_CONTENT.error_class}
              marginTop={labelMarginTop}
              marginBottom={labelMarginBottom}
              width={labelWidth}
              lineHeight={labelLineHeight}>
              <LabelToolTipWrapper noGap={noGap} iserror={hasError}>
                {label}
                {isOptional && (
                  <OptionalLabel
                    opacity={optionalOpacity}
                    marginLeft={optionalLabelStyle}
                    lineHeight={cropValueLineHeight}>
                    {FORM_CONTENT.optional}
                  </OptionalLabel>
                )}
                {hasTooltip && tooltipContent && (
                  <InfoTooltip title={tooltipTitle} content={tooltipContent} />
                )}
              </LabelToolTipWrapper>
            </FormLabels>
          ),
        )}
        {frontLabel && (
          <OptionalLabel opacity={1} marginLeft={frontlabelMarginLeft}>
            {frontLabel}
          </OptionalLabel>
        )}
        <Input
          data-testid={testId}
          value={value}
          placeholder={placeholder}
          name={name}
          required={!isOptional}
          onChange={onUpdate}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          disabled={isDisabled}
          inputProps={{
            sx: {
              textAlign: textAlignment,
              '&.Mui-disabled': {
                '-webkit-text-fill-color': textDisabledColor,
              },
              '&::placeholder': {
                color: COOL_GRAY,
                size: '14px',
                fontSize: '14px',
                opacity: 1,
                backgroundColor: checkTernaryCondition(
                  isDisabled,
                  NEUTRAL_BACKGROUND_COLOR,
                  WHITE,
                ),
              },
            },
            pattern: customPattern,
            'data-testid': 'input-field',
          }}
          sx={[
            {
              color: inputTextFieldColor,
              width: width,
              maxWidth: '34.5rem',
              fontSize: '14px',
            },
            hasError ? {} : DropdownInputSx,
            customInputSx,
          ]}
          error={hasError}
        />
        {hasPredefinedUnit && (
          <PredefinedUnitWrapper>{predefinedUnit}</PredefinedUnitWrapper>
        )}
        {optionalLabelOnRight && (
          <OptionalLabel opacity={1} marginLeft="1rem">
            {FORM_CONTENT.optional}
          </OptionalLabel>
        )}
        {listItemName && (
          <OptionalLabel
            color={listItemNameColor}
            opacity={1}
            marginLeft={listItemMarginLeft}>
            {listItemName}
          </OptionalLabel>
        )}
        {hasDeleteIcon && (
          <DeleteWrapper data-testid={deleteTestId} onClick={onDelete}>
            <DeleteIcon color={COOL_GRAY} />
          </DeleteWrapper>
        )}
        {labelOnRightSide && (
          <RightSideFormLabel color={labelOnRightSideColor}>
            {label}
          </RightSideFormLabel>
        )}
        {labelOnLeftSide && (
          <LeftSideFormLabel color={BLACK}>{label}</LeftSideFormLabel>
        )}
        {!labelOnRightSide && primaryError && (
          <ErrorWrapper paddingLeft={paddingForError}>
            {primaryErrorMessage}
          </ErrorWrapper>
        )}
        {!labelOnRightSide && secondaryError && (
          <ErrorWrapper paddingLeft={paddingForError}>
            {secondaryErrorMessage}
          </ErrorWrapper>
        )}
        {!labelOnRightSide && thirdError && (
          <ErrorWrapper>{thirdErrorMessage}</ErrorWrapper>
        )}
      </FormFields>
      {labelOnRightSide && primaryError && (
        <RightSideLabelErrorWrapper>
          {primaryErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
      {labelOnRightSide && secondaryError && (
        <RightSideLabelErrorWrapper>
          {secondaryErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
      {labelOnRightSide && thirdError && (
        <RightSideLabelErrorWrapper>
          {thirdErrorMessage}
        </RightSideLabelErrorWrapper>
      )}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  ariaLabel: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryError: PropTypes.bool,
  primaryErrorMessage: PropTypes.string,
  secondaryError: PropTypes.bool,
  secondaryErrorMessage: PropTypes.string,
  isOptional: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  isDisabled: PropTypes.bool,
  labelOnRightSide: PropTypes.bool,
  labelOnLeftSide: PropTypes.bool,
  display: PropTypes.string,
  rightSideLabelFormLabelMargin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasDeleteIcon: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteTestId: PropTypes.string,
  noLabel: PropTypes.bool,
  customStyles: PropTypes.object,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasPredefinedUnit: PropTypes.bool,
  predefinedUnit: PropTypes.string,
  testId: PropTypes.string,
  hasTooltip: PropTypes.bool,
  textDisabledColor: PropTypes.string,
  optionalLabelStyle: PropTypes.number,
  optionalLineHeight: PropTypes.number,
  tooltipContent: PropTypes.string,
  tooltipTitle: PropTypes.string,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thirdError: PropTypes.bool,
  thirdErrorMessage: PropTypes.string,
  customInputSx: PropTypes.object,
  labelOnRightSideColor: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  frontLabel: PropTypes.string,
  listItemName: PropTypes.string,
  listItemNameColor: PropTypes.string,
  paddingForError: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNoBottomMargin: PropTypes.bool,
  noGap: PropTypes.bool,
  optionalOpacity: PropTypes.number,
  optionalLabelOnRight: PropTypes.bool,
};

export default InputField;
