import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import {
  ORIGINATION_PARTICIPANTS_LIST_CONSTANTS,
  listingParticipantsColumn,
  participantCell,
  SortColValues,
  formatParticipantType,
} from './OriginationParticipantsList.constants';
import {
  DARK_GREEN,
  COOL_GRAY,
  GLOBAL_BLUE,
  DOWNLOAD_LINK,
  LIGHT_CHARCOAL,
  DARK_CHARCOAL,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import {
  datagridSx,
  DataGridWrapper,
  editPenIconStyle,
  HoverableContainer,
  MainWrapper,
  SearchBox,
  SearchAndFilterSection,
  SearchAndFiltersFlex,
  SortArrowsWrapper,
  filterClearIconSx,
  SortWrapper,
} from './OriginationParticipantsList.style';
import { ReactComponent as EditIconWithHoverEffect } from '../../assets/icons/EditIconWithHoverEffect.svg';
import { DataGrid } from '@mui/x-data-grid';
import {
  checkAccess,
  checkTernaryCondition,
  covertDateToLocalTimeZone,
} from 'utils/helper';
import axios from 'axios';
import { FETCH_ORIGINATION_PARTICIPANT_LIST } from 'urls';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { FilterHeader } from 'pages/ProjectCatalog/ProjectCatalog.style';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';
import AddParticipants from 'pages/Origination/Org_Project/Participants/AddParticipants/AddParticipants.react';
import EnrollmentModal from './EnrollmentModal/EnrollmentModal.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import {
  ORIGINATION_PARTICIPANT_CREATE,
  ORIGINATION_PARTICIPANT_UPDATE,
} from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import AddExistingParticipantModal from './AddExistingParticipantModal/AddExistingParticipantModal.react';
import NodataComponent from 'components/NoDataComp/NodataContent';
import AddParticipantsViaFileUpload from 'pages/Origination/Org_Project/Participants/AddParticipantsViaFileUpload/AddParticipantsViaFileUpload.react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';

const OriginationParticipantsList = ({ disableVirtualization }) => {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [sortColumn, setSortColumn] = useState('');
  const [order, setOrder] = useState(false);
  const [modalOpen, setModalOpen] = useState({ flag: false, id: 0 });
  const [participantId, setParticipantId] = useState();
  const projectList = '';
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState();
  const [addExistingParticipantModal, setAddExistingParticipantModal] =
    useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [stakeholderSearchText, setStakeholderSearchText] = useState('');
  const participantName = '';

  const navigate = useNavigate();
  const [enrollModalData, setEnrollModalData] = useState({
    flag: false,
    id: 0,
    projectData: [],
    participantName: '',
  });
  const [isLastUpdatedHovered, setIsLastUpdatedHovered] = useState(false);
  const [addParticipantsFileUploadOpen, setAddParticipantsFileUploadOpen] =
    useState(false);
  const { permissions } = useContext(userAuthRolesContext);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleLastUpdatedHover = (isHovered) => {
    setIsLastUpdatedHovered(isHovered);
  };

  const fetchAllParticipants = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    axios
      .post(FETCH_ORIGINATION_PARTICIPANT_LIST, {
        addedOnOrder: checkTernaryCondition(!order, 'DESC', 'ASC'),
        pageNumber: stakeholderSearchText ? 0 : pageState.page - 1,
        pageSize: pageState.pageSize,
        searchKeyword: stakeholderSearchText,
      })
      .then((response) => {
        const participantsListData = response.data.content.map(
          (participant) => ({
            ...participant,
            participantFullName: `${participant.participantFirstName} ${participant.participantLastName}`,
            associatedProjects: participant.associatedProjects,
          }),
        );
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: participantsListData,
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      });
  };

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    fetchAllParticipants();
  }, [
    pageState.pageSize,
    pageState.page,
    order,
    sortColumn,
    stakeholderSearchText,
  ]);

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortColumn(SortColValues[params.field]);
    setOrder(!order);
  };

  const onParticipantCellClick = (participantId, tab) => {
    navigate(`${pathName.originationParticipants.view}/${participantId}`, {
      state: { activeTab: tab },
    });
  };

  const deleteParticipant = () => {
    setPageState({ ...pageState, isLoading: true });
    axios
      .delete(
        `${FETCH_ORIGINATION_PARTICIPANT_LIST.slice(
          0,
          FETCH_ORIGINATION_PARTICIPANT_LIST.length - 1,
        )}/${participantId}?internalProjectId=${selectedProject?.value}`,
        {
          data: {
            participantId: participantId,
            internalProjectId: selectedProject?.value,
          },
        },
      )
      .then(() => {
        toast(
          <CustomSnackbar
            type="success"
            message={`${participantName} was removed successfully from ${selectedProject?.label}`}
          />,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={`${participantName} failed to be removed from ${selectedProject?.label}. Please try again.`}
          />,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      })
      .finally(() => {
        fetchAllParticipants();
        setPageState({ ...pageState, isLoading: false });
      });
  };
  const editParticipant = (params) => {
    setModalOpen({ flag: true, id: params.row.participantId });
    setParticipantId(params.row.participantId);
  };

  const columns = [
    {
      field: listingParticipantsColumn.participantId.field,
      hide: true,
    },
    {
      field: listingParticipantsColumn.participant.field,
      headerName: listingParticipantsColumn.participant.label,
      renderCell: (params) =>
        participantCell(
          params.row.participantFullName,
          params.row.participantEmail,
          GLOBAL_BLUE,
          COOL_GRAY,
          () => {
            onParticipantCellClick(
              params.row.participantId,
              params.row.participantType ===
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.participant_owner
                ? 0
                : 1,
            );
          },
        ),
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: listingParticipantsColumn.typeOfStakeHolder.field,
      headerName: listingParticipantsColumn.typeOfStakeHolder.label,
      renderCell: (params) => (
        <TextWrapper fontSize="0.875rem" color={DOWNLOAD_LINK}>
          {formatParticipantType(params.row.participantType)}
        </TextWrapper>
      ),
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
    },
    {
      field: listingParticipantsColumn.addedOn.field,
      headerName: listingParticipantsColumn.addedOn.label,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <TextWrapper fontSize="14px" color={DARK_CHARCOAL}>
          {params.value !== null
            ? covertDateToLocalTimeZone(params.value).slice(0, 10)
            : '--'}
        </TextWrapper>
      ),
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <HoverableContainer
            className="bold-600"
            onMouseEnter={() => handleLastUpdatedHover(true)}
            onMouseLeave={() => handleLastUpdatedHover(false)}>
            {listingParticipantsColumn.addedOn.label}
            <SortArrowsWrapper hovered={isLastUpdatedHovered}>
              {order === true ? (
                <SortWrapper
                  data-testid="sort-participant-added-on"
                  onClick={() => {
                    handleSortClick(params);
                  }}>
                  <ArrowUpward fontSize="small" />
                </SortWrapper>
              ) : (
                <SortWrapper
                  data-testid="sort-participant-added-on"
                  onClick={() => {
                    handleSortClick(params);
                  }}>
                  <ArrowDownward fontSize="small" />
                </SortWrapper>
              )}
            </SortArrowsWrapper>
          </HoverableContainer>
        </FilterHeader>
      ),
    },
    {
      field: 'options',
      headerName: '',
      disableColumnMenu: true,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <EditIconWithHoverEffect
          style={editPenIconStyle(
            checkTernaryCondition(
              checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE),
              'pointer',
              'default',
            ),
          )}
          onClick={checkTernaryCondition(
            checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE),
            () => editParticipant(params),
            null,
          )}
          data-testid="edit-pen"
        />
      ),
    },
  ];

  const addParticipantButtonOptions = [
    {
      id: 1,
      name: 'Participant',
      modalHandler: () => setModalOpen({ flag: true, id: 0 }),
      hasDivider: false,
    },
    {
      id: 2,
      name: 'Multiple participants',
      modalHandler: () => setAddParticipantsFileUploadOpen(true),
      hasDivider: false,
    },
    {
      id: 3,
      name: 'Existing participants to project',
      modalHandler: () => setAddExistingParticipantModal(true),
      hasDivider: false,
    },
    { id: 4, name: 'Other', modalHandler: () => {}, hasDivider: true },
  ];
  return (
    <div>
      <ListViewHeader
        title={ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.page_title}
        buttonName={ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.addButtonTitle}
        buttonBackgroundColor={DARK_GREEN}
        buttonTextColor={'#fff'}
        disableButton={
          !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
        }
        noDropdown={false}
        options={addParticipantButtonOptions}
        isModalPresent={true}
        hasMultipleModals={true}
        titleColor={LIGHT_CHARCOAL}
      />
      <SearchAndFilterSection>
        <div style={SearchAndFiltersFlex}>
          <SearchBox
            variant="outlined"
            placeholder={
              ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.searchboxPlaceholder
            }
            value={stakeholderSearchText}
            onChange={(event) =>
              setStakeholderSearchText(
                event.target.value.replace(/[^a-zA-Z0-9@._-\s]/g, ''),
              )
            }
            InputProps={{
              startAdornment: (
                <>
                  <SearchIcon sx={{ color: TYPOGRAPHY_GRAY }} />
                </>
              ),
              endAdornment: stakeholderSearchText?.length > 0 && (
                <ClearIcon
                  onClick={() => {
                    setStakeholderSearchText('');
                    setIsDataFetched(false);
                  }}
                  sx={filterClearIconSx}
                />
              ),
            }}
          />
        </div>
        <FiltersPopup
          filterSections={[]}
          updateFilters={() => {}}
          isFiltersApplied={false}
        />
      </SearchAndFilterSection>
      <MainWrapper className="curved-border">
        {checkTernaryCondition(
          pageState.data.length === 0 && isDataFetched,
          checkTernaryCondition(
            stakeholderSearchText,
            <NodataComponent
              filterData={
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.filter_content
              }
              searchTermSuffix={
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.search_text_suffix
              }
              searchTerm={`"${stakeholderSearchText}".`}
              width={
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.filter_content_width
              }
            />,
            <NodataComponent
              nodata={
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.no_data_present_label
              }
              addbuttonLabel={{
                name: ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.addButtonTitle,
              }}
              buttonBackgroundColor={DARK_GREEN}
              buttonTextColor={'#fff'}
              disableButton={
                !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
              }
              noDropdown={false}
              addNewButtonOptions={addParticipantButtonOptions}
              isModalPresent={true}
              hasMultipleModals={true}
              width={
                ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.no_data_heading_width
              }
            />,
          ),
          <DataGridWrapper>
            <DataGrid
              headerHeight={40}
              disableVirtualization={disableVirtualization}
              rows={pageState.data || []}
              rowsPerPageOptions={[25, 50, 75]}
              getRowId={(row) => row.participantId}
              loading={pageState.isLoading}
              rowCount={pageState.total}
              disableSelectionOnClick
              componentsProps={{
                pagination: {
                  labelRowsPerPage: 'Items per page',
                  SelectProps: TablePaginationMenuListStyle,
                  style: { color: DARK_CHARCOAL },
                },
              }}
              paginationMode="server"
              pagination
              sx={datagridSx}
              pageSize={pageState.pageSize}
              page={pageState.page - 1}
              onPageChange={(newPage) =>
                setPageState((old) => ({ ...old, page: newPage + 1 }))
              }
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({ ...old, pageSize: newPageSize }))
              }
              columns={columns}
            />
          </DataGridWrapper>,
        )}
        <AddParticipants
          isModalOpen={modalOpen.flag}
          setIsModalOpen={setModalOpen}
          particpantId={participantId}
          inParticipantPage
          setParticipantId={setParticipantId}
          projectId={
            pageState.data.filter(
              (item) => item.participantId === participantId,
            )[0]?.associatedProjects[0]?.projectId || 0
          }
          projectName={''}
          refreshHandler={() => {
            fetchAllParticipants();
          }}
        />
        <EnrollmentModal
          modalData={enrollModalData}
          setModalData={setEnrollModalData}
        />
        <DialogBox
          dialogActions
          deleteOperation
          isOpen={isDeleteModalOpen}
          onConfirm={() => {
            deleteParticipant();
            setIsDeleteModalOpen(false);
          }}
          subtitle={`${ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.remove_subtitle} ${participantName}?`}
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          declineCtnLabel="Cancel"
          acceptCtnLabel="Remove"
          title={`Remove ${participantName}`}>
          <BasicAutoComplete
            options={projectList}
            id="disable-close-on-select"
            label={ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.remove_label}
            required
            isError={false}
            ErrorMessage={''}
            placeholder={
              ORIGINATION_PARTICIPANTS_LIST_CONSTANTS.remove_placeholder
            }
            onChangeHandler={(value) => {
              setSelectedProject(value);
            }}
            name="project"
          />
        </DialogBox>
        <AddExistingParticipantModal
          isModalOpen={addExistingParticipantModal}
          setIsModalOpen={setAddExistingParticipantModal}
          refreshHandler={() => {
            fetchAllParticipants();
          }}
        />
        <NotificationLogProvider>
          <AddParticipantsViaFileUpload
            addParticipantsFileUploadOpen={addParticipantsFileUploadOpen}
            setAddParticipantsFileUploadOpen={setAddParticipantsFileUploadOpen}
            refreshHandler={() => {
              fetchAllParticipants();
            }}
          />
        </NotificationLogProvider>
      </MainWrapper>
    </div>
  );
};

OriginationParticipantsList.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default OriginationParticipantsList;
