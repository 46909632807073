import { dateTimeFormatter } from 'utils/dateFormatter';
import { covertDateToLocalTimeZone } from 'utils/helper';

export const PAGE_CONTENT = {
  page_title: 'Audit Log',
  time: 'Time',
  object: 'Object',
  objectId: 'Object ID',
  object_field: 'objectId',
  contract: 'Contract',
  contract_field: 'contract',
  phase: 'Phase',
  phase_field: 'actionId',
  status: 'Status',
  acres: 'Acres',
  acres_field: 'acres',
  created_date_field: 'createdDate',
  user: 'User',
  pagination_mode: 'server',
  action: 'Action',
  default_pagination_options: [25, 50, 75],
  default_page_size: 25,
  label_rows_per_page: 'Items per page',
  delete: 'DELETE',
  replace: 'REPLACE',
  insert: 'INSERT',
  update: 'UPDATE',
  project: 'Projects',
  fields_array: ['Developer', 'vendor_contact_type', 'Notes', 'Description'],
  column: 'column',
  stakeholder: 'Stakeholders',
  asc: 'asc',
  desc: 'desc',
  changed: 'changed.',
  changed_to: 'changed to',
  added: 'added',
  edited: 'Edited',
  file_replaced: 'File replaced with',
  changed_from: 'changed from',
  action_field: 'action',
  date_time: 'actionDateTime',
  user_name: 'userName',
  audit_log_for: 'Audit Log For',
  project_transaction: 'Project Transaction',
  green: '#26890D',
  black: '#333333',
  white: '#FFFFFF',
  export: 'Export',
  data_input: 'Data Inputs',
  link_blue: '#0076A8',
  cool_gray: '#63666A',
  more_info: 'more info >',
  updated: 'Updated',
  deleted: 'Deleted',
  removed: 'Removed',
  created: 'Created',
  added_action: 'Added',
  application: 'Application',
  pointer: 'pointer',
  range_picker: 'range-picker',
  center: 'center',
  transaction: 'Transaction',
  date_filter: 'date-filter',
  key_one: 1,
  key_zero: 0,
  key_two: 2,
  purchseTransaction: 'Purchase Transactions',
  transact_category_array: ['Purchase Transactions', 'Sale Transactions'],
  saleTransaction: 'Sale Transactions',
  module: 'Module',
  project_payload_category: ['Project', 'Project Transaction'],
  module_field: 'module',
  originationProject: 'Projects',
  category: 'Object Type',
  category_field: 'category',
  originationModule: 'Origination',
  participants: 'Participants',
};

export const DUMMY_ORIGINATION_RESPONSE = [
  {
    auditId: 1252918551,
    userName: 'Srivastava, Atishek',
    email: 'atissrivastava@deloitte.com',
    module: 'Origination',
    table: 'internal_project_dim',
    category: 'Projects',
    recordId: 1,
    auditSubId: -1,
    object: 'Project for Soft delete of transaction',
    action: 'INSERT',
    actionDate: '2023-05-12T05:53:18.798+00:00'.slice(0, 10),
    actionTime: dateTimeFormatter(
      covertDateToLocalTimeZone('2023-05-12T05:53:18.798+00:00'),
    ),
    actionDateTime: '2023-05-12T05:53:18.798+00:00',
    auditEvents: null,
  },
  {
    auditId: 1,
    userName: 'Kakkar, Sanjana',
    module: 'Origination',
    email: 'sankakkar@deloitte.com',
    primaryTable: 'participant_dim',
    category: 'Participants',
    recordId: 1,
    auditSubId: -1,
    object:
      '{"firstName":"Alen","lastName":"Doe","projectName":"A big irrigation project bhjd"}',
    action: 'INSERT',
    actionDateTime: '2023-05-16T06:57:44.084+00:00',
    auditEvents: [],
  },
  {
    auditId: 3,
    userName: 'Kakkar, Sanjana',
    module: 'Origination',
    email: 'sankakkar@deloitte.com',
    primaryTable: 'participant_dim',
    category: 'Participants',
    recordId: 1,
    auditSubId: -1,
    object:
      '{"firstName":"Alex","lastName":"Doe","projectName":"A big irrigation project bhjd"}',
    action: 'DELETE',
    actionDateTime: '2023-05-16T06:57:44.084+00:00',
    auditEvents: [],
  },
  {
    auditId: 1252918552,
    userName: 'Srivastava, Atishek',
    email: 'atissrivastava@deloitte.com',
    module: 'Origination',
    table: 'internal_project_dim',
    category: 'Participants',
    recordId: 1921674842,
    auditSubId: -1,
    object:
      '{"firstName":"Stanley","lastName":"Glover","projectName":"Project 1"}',
    action: 'UPDATE',
    actionDate: '2023-05-02T10:05:40.368+00:00'.slice(0, 10),
    actionTime: dateTimeFormatter(
      covertDateToLocalTimeZone('2023-05-02T10:05:40.368+00:00'),
    ),
    actionDateTime: '2023-05-12T05:53:18.798+00:00',
    auditEvents: [
      {
        eventId: 1280070999,
        auditId: 1104776771,
        column: 'First Name',
        oldValue: 'Stan',
        newValue: 'Stanley',
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070910,
        auditId: 1104776773,
        column: 'Last Name',
        oldValue: 'Clover',
        newValue: 'Glover',
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070996,
        auditId: 1104776775,
        column: 'Email',
        oldValue: 'stan.clover@regenfarms.com',
        newValue: 'stanley.glover@regenfarms.com',
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
    ],
  },
  {
    id: 1252918553,
    userName: 'Srivastava, Atishek',
    email: 'atissrivastava@deloitte.com',
    module: 'Origination',
    table: 'internal_project_dim',
    category: 'Projects',
    recordId: 2,
    auditSubId: -1,
    object: 'Project for Soft delete of transactio',
    action: 'UPDATE',
    actionDate: '2023-05-02T10:05:40.368+00:00'.slice(0, 10),
    actionTime: dateTimeFormatter(
      covertDateToLocalTimeZone('2023-05-02T10:05:40.368+00:00'),
    ),
    actionDateTime: '2023-05-02T10:05:40.368+00:00',
    auditEvents: [
      {
        eventId: 1280070998,
        auditId: 1104776770,
        column: 'Project Name',
        oldValue: 'West Regen Ag',
        newValue: 'Midwest Regenerative Agriculture',
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070996,
        auditId: 1104776770,
        column: 'Description',
        oldValue: 'West Regen Ag',
        newValue: 'Midwest Regenerative Agriculture',
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070997,
        auditId: 1104776770,
        column: 'Activities',
        valueList: [
          {
            value: 'Improve water management/irrigation',
            status: 'Deleted',
          },
          {
            value: 'Improve water management/irrigation',
            status: 'Added',
          },
        ],
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070991,
        auditId: 1104776770,
        column: 'Regions',
        valueList: [
          {
            value: 'West',
            status: 'Deleted',
          },
          {
            value: 'Midwest',
            status: 'Added',
          },
        ],
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
      {
        eventId: 1280070992,
        auditId: 1104776770,
        column: 'Tags',
        valueList: [
          {
            value: 'Forestry',
            status: 'Deleted',
          },
          {
            value: 'Agroforestry',
            status: 'Added',
          },
        ],
        eventDateTime: '2023-05-02T10:05:40.368+00:00',
      },
    ],
  },
];
