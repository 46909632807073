import styled from 'styled-components';
import { QUILL_GRAY, ROW_FOCUSED_BG, WHITE } from 'theme/GlobalColors';

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 1.5rem 1rem;
  width: 20%;
  border-right: 1px solid ${QUILL_GRAY};
`;

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0rem 0.25rem;
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.225rem;
  text-align: left;

  cursor: pointer;
  transition: background-color 0.3s;
  background-color: ${({ active }) =>
    active ? ROW_FOCUSED_BG : 'transparent'};

  &:hover {
    background-color: ${ROW_FOCUSED_BG};
  }
`;
