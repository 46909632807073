import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as DisabledDownloadIcon } from '../../assets/icons/DisabledDownloadIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as GreenDownloadIcon } from '../../assets/icons/GreenDownloadIcon.svg';
import { ReactComponent as GreenDeleteIcon } from '../../assets/icons/GreenDeleteIcon.svg';
import { MainWrapper, OptionsWrapper } from './NewOptionsButton.style';
import { checkTernaryCondition } from 'utils/helper';
import { useState, useMemo } from 'react';
import { COOL_GRAY } from 'theme/GlobalColors';

const NewOptionsButton = ({
  deleteHandler,
  firstMenuItemClickHandler,
  secondMenuItemClickHandler,
  thirdMenuItemClickHandler,
  isDownloadDisabled,
  deleteNotDisabled,
  reportType,
  reportOptions,
}) => {
  const [isDownloadIconHovered, setIsDownloadIconHovered] = useState(false);
  const [isDeleteIconHovered, setIsDeleteIconHovered] = useState(false);
  const deleteClickHandler = () => {
    deleteHandler();
  };

  const handlers = {
    [reportOptions[0]?.name]: firstMenuItemClickHandler,
    [reportOptions[1]?.name]: secondMenuItemClickHandler,
    [reportOptions[2]?.name]: thirdMenuItemClickHandler,
  };

  const downloadClickHandler = () => {
    const handler = handlers[reportType];
    if (handler) {
      handler();
    }
  };

  const downloadIcon = useMemo(
    () =>
      checkTernaryCondition(
        isDownloadIconHovered,
        <GreenDownloadIcon onClick={downloadClickHandler} />,
        <DownloadIcon onClick={downloadClickHandler} />,
      ),
    [isDownloadIconHovered],
  );

  const deleteIcon = useMemo(
    () =>
      checkTernaryCondition(
        isDeleteIconHovered,
        <GreenDeleteIcon onClick={deleteClickHandler} />,
        <DeleteIcon
          aria-label="deleteFile"
          id="delete-button"
          onClick={deleteClickHandler}
          sx={{ color: COOL_GRAY }}
        />,
      ),
    [isDeleteIconHovered],
  );
  return (
    <>
      <MainWrapper>
        <OptionsWrapper
          onMouseEnter={() => setIsDownloadIconHovered(true)}
          onMouseLeave={() => setIsDownloadIconHovered(false)}
          deletenotdisabled={!isDownloadDisabled}
          data-testid="download-button">
          {checkTernaryCondition(
            isDownloadDisabled,
            <DisabledDownloadIcon />,
            downloadIcon,
          )}
        </OptionsWrapper>
        <OptionsWrapper
          onMouseEnter={() => setIsDeleteIconHovered(true)}
          onMouseLeave={() => setIsDeleteIconHovered(false)}
          deletenotdisabled={deleteNotDisabled}
          data-testid="delete-button">
          {checkTernaryCondition(
            deleteNotDisabled,
            deleteIcon,
            <DeleteIcon sx={{ color: COOL_GRAY, opacity: 0.5 }} />,
          )}
        </OptionsWrapper>
      </MainWrapper>
    </>
  );
};

export default NewOptionsButton;
