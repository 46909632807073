import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  QUILL_GRAY,
  WARNING_BOX_YELLOW,
  WHITE,
} from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  border: 1px solid ${QUILL_GRAY};
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${QUILL_GRAY};
`;
export const TabberBoxCustomStyle = {
  position: 'sticky',
  top: 0,
  marginTop: '0.263rem',
  backgroundColor: WHITE,
  border: 'none',
};
export const TextWrapper = styled.div`
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  margin-left: ${({ marginLeft }) => marginLeft};
  align-self: center;
  line-height: ${({ lineHeight }) => lineHeight ?? '19.6px'};
  font-size: ${({ fontSize }) => fontSize ?? '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap ?? '0.25rem'};
  color: ${({ color }) => color ?? DARK_CHARCOAL};
  position: ${({ position }) => position ?? 'unset'};
`;
export const ContentWrapper = styled.div`
  padding: 0.5rem 1rem;
`;
export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 2px 0px 0px 0px;
  border: 1px solid ${WARNING_BOX_YELLOW};
  align-items: center;
`;
export const ContentBox = styled.div`
  display: flex;
  width: ${({ width }) => width};
  gap: 4px;
  justify-content: ${({ content }) => content};
  cursor: ${({ cursor }) => cursor};
`;

export const StyledSpan = styled.span`
  position: absolute;
  left: 16.5em;
  top: 1.45em;
  color: ${COOL_GRAY};
`;
