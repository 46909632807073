import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { checkTernaryCondition } from 'utils/helper';
import { ReactComponent as NotStartedIcon } from '../../../../../assets/icons/smallNotStartedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../../../../assets/icons/smallInProgressIcon.svg';
import { ReactComponent as CompletedIcon } from '../../../../../assets/icons/smallSuccessIcon.svg';
import { ReactComponent as ReviewIcon } from '../../../../../assets/icons/inReviewIcon.svg';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { AcreageWrapper } from './ParticipantActivity.style';
export const list = [
  { id: 1, itemLabel: 'Requested', checked: false },
  { id: 2, itemLabel: 'In Progress', checked: false },
  { id: 3, itemLabel: 'Complete', checked: false },
];

export const PAGE_CONTENT = {
  no_data_present_label:
    '{{participant_name}} hasn’t been added to any projects yet.',
  addButtonTitle: 'Add to project',
  project_actionlabel: 'View detailed log',
  recruitment: 'Recruitment',
  baseline_reporting: 'Baseline reporting',
  activity_reporting: 'Activity reporting',
  enrollment: 'Enrollment',
  activity: 'Activity',
  detailedLog: 'detailed log',
  project_cycle_dropdown_placeholder: 'Select cycle',
  interested_acreage: 'pipeline acres not added',
  contract_acreage: 'contract acres not added',
  contract_added: 'Contract added',
  interested: 'Interested',
  not_started: 'Not started',
  outreach: 'Outreach',
  contracting: 'Contracting',
};

export const handleArrowIcon = (arrowState, expandedId) => {
  return (
    <div data-testid={expandedId} style={{ marginTop: '0.3rem' }}>
      {checkTernaryCondition(
        arrowState === expandedId,
        <KeyboardArrowDownIcon />,
        <KeyboardArrowRightIcon />,
      )}
    </div>
  );
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'Interested':
      return <NotStartedIcon />;
    case 'Not started':
      return <NotStartedIcon />;
    case 'Requested':
      return <InProgressIcon />;
    case 'In progress':
      return <InProgressIcon />;
    case 'Submitted':
      return <InProgressIcon />;
    case 'Contract added':
      return <CompletedIcon />;
    case 'Approved':
      return <CompletedIcon />;
    case 'In review':
      return <ReviewIcon />;
    default:
      return '';
  }
};

export const getStatusDateValue = (statusDate, status) => {
  if (
    status !== 'Interested' &&
    status !== 'Approved' &&
    status !== 'Contract added' &&
    status !== 'Not started'
  ) {
    return <span>{`as of ${statusDate}`}</span>;
  } else if (status === 'Not started') {
    return '';
  }
  return <span>{statusDate}</span>;
};

export const createBasicActivity = ({
  id,
  activityType,
  actionId,
  activityDate,
  statusMapList,
  associatedContractId,
  enrollmentId,
  formId,
}) => {
  return {
    id: id,
    activityType: activityType,
    activityId: '',
    area: '',
    status: statusMapList.find((status) => status.actionId === actionId)
      ?.actionStatus,
    enrollmentInstancesForBaseline: null,
    activityDate: activityDate,
    associatedContractId,
    enrollmentId,
    formId,
  };
};

export const getActivityId = (
  activityId,
  contractStartDate,
  enrollmentInstancesForBaseline,
) => {
  if (enrollmentInstancesForBaseline === null) {
    return `${new Date(contractStartDate).getFullYear()}-${activityId}`;
  } else if (enrollmentInstancesForBaseline.length > 0) {
    const activityIdList = enrollmentInstancesForBaseline
      .sort((a, b) => a - b)
      .map(
        (instance, index) =>
          `${new Date(contractStartDate).getFullYear()}-${index + 1}`,
      );
    return activityIdList.join(', ');
  }
};

export const getSortedActivities = (
  activityListData,
  activityType,
  sortKey,
) => {
  return activityListData
    .filter((activity) => activity.activityType === activityType)
    .sort((a, b) => a[sortKey] - b[sortKey]);
};

export const getRecruitmentInterestedRow = (
  statusMapList,
  interestedDate,
  area,
  status,
) => {
  return {
    id: 1,
    activityType: PAGE_CONTENT.outreach,
    activityId: '',
    area:
      typeof area === 'number' && area >= 0
        ? area
        : PAGE_CONTENT.interested_acreage,
    status: status || 'Not started',
    enrollmentInstancesForBaseline: null,
    activityDate: interestedDate,
    contractPdfUrl: null,
    activitySubType: 'Not started',
  };
};

export const getContractedInterestedRow = (
  statusMapList,
  interestedDate,
  area = PAGE_CONTENT.contract_acreage,
) => {
  return {
    id: 2,
    activityType: PAGE_CONTENT.contracting,
    activityId: '',
    area: area,
    status: 'Not started',
    enrollmentInstancesForBaseline: null,
    activityDate: interestedDate,
    contractPdfUrl: null,
    activitySubType: 'Not started',
  };
};
