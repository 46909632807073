import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontStyle: 'bold',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20%',
    rowGap: '110px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  description: {
    fontSize: '14px',
    paddingRight: 5,
    width: '100px',
    textAlign: 'right',
  },
  value: {
    fontSize: '14px',
    paddingRight: 5,
  },
  heading: {
    fontSize: '20px',
  },
  imageStyle: {
    width: '245px',
    paddingLeft: 10,
  },
});
