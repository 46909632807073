import styled from 'styled-components';
import { SortColValues } from './StakeholdersProfile.content';

export const TransactionListWrapper = styled.div`
  max-height: 6.25rem;
  display: flex;
  flex-direction: column;
  flex-warp: wrap;
  margin: 1rem;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
`;
export const TransactionListText = styled.span`
  color: #686868;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;
export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;
export const datagridSx = {
  marginTop: '0.375rem',
  backgroundColor: '#FFFFFF',
  width: 'auto',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  borderBottom: 0,
  borderRight: 0,
  borderRadius: 0,
  borderLeft: 0,
  '& .MuiDataGrid-cellContent': {
    lineHeight: '1.188rem',
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    color: '#333333',
    fontSize: '0.875rem',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#333333',
    lineHeight: '140%',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    marginLeft: '2px',
    width: 'auto',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
};
export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
  &:hover {
    background-color: #e3dada36;
  }
`;

export const handleArrowColor = (col, sortColumn) => {
  return {
    color:
      sortColumn === SortColValues[col] ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  };
};

export const TimeStampIconWrapper = styled.span`
  color: #63656a;
  font-size: 0.3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  vertical-align: middle;
`;
