import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  FETCH_PIPELINE_ACREAGE,
  FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST,
} from 'urls';
import { useActivityContext } from 'containers/ActivityListContainer';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import { getActivityPracticeMapping } from './ActivityOptions.constants';
import PipelineAcerageModal from 'pages/Origination/Org_Project/Participants/PiplelineAcerageModal/PipelineAcerageModal.react';

export const EditPipelineAcerage = () => {
  const { editPipelineAcresOpen, setEditPipelineAcresOpen, projectCycleId } =
    useActivityContext();
  const { participantId, projectId } = useContext(ParticipantActivityContext);
  const [activityPracticeMapping, setActivityPracticeMapping] = useState({});

  useEffect(() => {
    if (editPipelineAcresOpen.isOpen) {
      Promise.all([
        axios.get(
          `${FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST}?participantId=${participantId}&projectId=${projectId}`,
        ),
        axios.get(
          `${FETCH_PIPELINE_ACREAGE}?participantId=${participantId}&projectId=${projectId}&projectCycleId=${projectCycleId}`,
        ),
      ]).then(([incentiveResponse, pipelineResponse]) => {
        let activityPracticeMap = getActivityPracticeMapping(
          incentiveResponse.data,
        );

        const { pipelineAcreList } = pipelineResponse.data;
        pipelineAcreList.forEach((acre) => {
          const activity = Object.values(activityPracticeMap)
            .flat()
            .find(
              (activity) =>
                activity.projectActivityId === acre.projectActivityId,
            );
          if (activity) {
            activity.pipelineUnits = acre.pipelinePlannedAcres;
          }
        });
        activityPracticeMap.totalAcres = pipelineResponse.data.totalAcres;
        setActivityPracticeMapping(activityPracticeMap);
      });
    }
  }, [editPipelineAcresOpen]);

  if (!editPipelineAcresOpen.isOpen) {
    return null;
  }

  return (
    <div>
      <PipelineAcerageModal
        data-testid="edit-pipeline-acerage-modal"
        addPipelineAcresOpen={editPipelineAcresOpen}
        setAddPipelineAcresOpen={setEditPipelineAcresOpen}
        activityPracticeMapping={activityPracticeMapping}
        projectId={projectId}
        refreshHandler={() => {
          setEditPipelineAcresOpen({ isOpen: false });
        }}
        participantId={participantId}
        projectCycleId={projectCycleId}
        isEditMode={true}
      />
    </div>
  );
};
