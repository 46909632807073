import { useContext, useEffect, useState } from 'react';
import {
  MainWrapper,
  FormSection,
  FormHeading,
  FormFields,
  FormLabels,
  RadioButtonlabel,
  radioBtnSx,
  DropDownPlaceholder,
  OptionalLabel,
  AddProjectWrapper,
  AddprojectLabel,
  ButtonWrapper,
  ErroWrapper,
  TextArea,
} from './AddSaleTransaction.style';
import {
  PAGE_CONTENT,
  validateDeliveryDetails,
  validateVintage,
} from './AddSaleTransaction.content';
import {
  Input,
  Radio,
  Select,
  MenuItem,
  OutlinedInput,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../assets/icons/addicon.svg';
import { ReactComponent as Calendericon } from '../../assets/icons/calenderIcon.svg';
import { useForm } from 'hooks/useForm';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import BackButton from 'components/Backbutton/BackButton.react';
import { useLocation, useNavigate, useParams } from 'react-router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import axios from 'axios';
import SaleProjectSubsection from 'pages/AddSaleTransaction/components/SaleProjectSubsection/SaleProjectSubsection.react';
import { DELETE_TRANSACTION, TRANSACTION_SALE_DETAILS } from 'urls';
import { checkTernaryCondition, scroll } from 'utils/helper';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import DeleteModal from 'components/DeleteModal/DeleteModal.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  convertToDate,
  getValueOrDefault,
  RouteIncludes,
  validateProjectNames,
} from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { usDateFormatter } from '../../utils/dateFormatter';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import { pathName } from 'Routes/routes.const';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const paymentTypes = [{ label: 'payment 1', value: 'payment 1' }];

const paymentObj = {
  id: '',
  creditRequestId: 0,
  quantity: '',
  price: '',
  vintage: '',
  vintageYear: '',
  isQtyFromApi: false,
  quantityError: false,
  priceError: false,
  vintageError: false,
  vintageDuplicateError: false,
  deliveryDetails: [],
  AvailableQuantityError: false,
  deliveryDetailsError: false,
  isApiPopulated: false,
};

const projectObj = {
  id: '',
  projectName: '',
  paymentDetails: [],
  projectNameError: { flag: false, message: '' },
  existingProjectError: false,
  isApiPopulated: false,
};

const formModal = {
  transactionName: '',
  signedDate: null,
  transactionCatagory: '',
  stakeholder: null,
  transactionStatus: '',
  notes: '',
};

const AddSaleTransaction = () => {
  const [projectInfo, setProjectInfo] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [catagoryError, setCatagoryError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [stakeHolderError, setStakeHolderError] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const { state } = useLocation(); // for previous pathname
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [signedOnError, setSignedOnError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [editErrorMsg, setEditErrorMsg] = useState({
    flag: false,
    message: '',
  });
  const [existingProjects, setExistingProjects] = useState([]);
  const [onSubmit, setOnSubmit] = useState(true);
  const location = useLocation();
  const [viewMode, setViewMode] = useState(false);
  const [submitTransactionState, setSubmitTransactionState] = useState(false);
  const { dirty, markFormDirty } = useContext(
    EditPurchaseTransactionFormDirtyContext,
  );
  const [isDeletedRecord, setIsDeleted] = useState();
  const [lastUpdatedOn, setLastUpdatedon] = useState();

  const {
    stakeholderList,
    costStructure,
    submitLoading,
    samenameError,
    categoryOfSale,
    setSameNameError,
    fetchCategory,
    fetchStakeholderNames,
    fetchShareAssumptionTypes,
    fetchAssociatedTYpes,
    fetchCostStructure,
    submitSaleTransaction,
    fetchSaleCategory,
    editSaleTransaction,
  } = useTransactionDetails();

  const {
    formValue: transactionForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);

  useEffect(() => {
    if (submitTransactionState) {
      confirmTransactionSubmit();
    }
  }, [submitData, submitTransactionState]);

  const transactionStatusRadioProps = (item) => ({
    checked: transactionForm.transactionStatus === item,
    onChange: transactionStatusHandler,
    value: item,
    name: 'transactionStatus',
    inputProps: { 'aria-label': item },
  });

  const transactionStatusHandler = (e) => {
    formFieldChangeHandler(e);
    e.target.value !== '' && setStatusError(false);
  };

  const addProjectHandler = () => {
    setProjectInfo((prev) => [
      ...prev,
      {
        ...projectObj,
        id: uniqueId(),
        paymentDetails: [
          ...projectObj.paymentDetails,
          { ...paymentObj, id: uniqueId() },
        ],
      },
    ]);
    markFormDirty();
  };

  const checkProjectNames = (projectName) => {
    return (
      existingProjects.filter((project) => project.name === projectName)
        .length > 0
    );
  };

  //update contactInfo
  const updateProjectInfo = (projectObj) => {
    if (projectObj.projectName) {
      if (!checkProjectNames(projectObj.projectName.value)) {
        setExistingProjects((prev) => [
          ...prev,
          { name: projectObj.projectName.value, id: projectObj.id },
        ]);
      }
    } else {
      setExistingProjects([
        ...existingProjects.filter((project) => project.id !== projectObj.id),
      ]);
    }
    setProjectInfo((current) => {
      return current.map((project) => {
        return project.id === projectObj.id ? projectObj : project;
      });
    });
  };

  const deleteProjectInfo = (projectId) => {
    setProjectInfo((current) => {
      return current.filter((project) => {
        return project.id !== projectId;
      });
    });
  };

  const fetchEditTransactionDetails = async (transactionId) => {
    setEditLoading(true);
    let editTransactionDetails = {};
    let projectDetails = [];
    await axios
      .get(`${TRANSACTION_SALE_DETAILS}/${transactionId}`)
      .then((response) => {
        editTransactionDetails = {
          transactionName: getValueOrDefault(response.data.transactionName),
          signedDate: convertToDate(
            getValueOrDefault(response.data.transactionSignedOn),
          ),
          transactionCatagory: getValueOrDefault(response.data.categoryOfSale),
          stakeholder: {
            label: getValueOrDefault(response.data.soldToVendorName),
            value: getValueOrDefault(response.data.soldToVendorName),
            vendorId: response.data.soldToVendorId,
          },
          transactionStatus: getValueOrDefault(response.data.saleStatus),
          notes: getValueOrDefault(response.data.notes),
        };
        projectDetails = response.data.saleDeliveryResponseDTOS.map(
          (project) => ({
            id: uniqueId(),
            isApiPopulated: true,
            projectName: {
              internalProjectId: project.internalProjectId,
              value: project.projectName,
              label: project.projectName,
            },
            paymentDetails: project.vintageResponseDTOS.map((vintage) => ({
              id: uniqueId(),
              isApiPopulated: true,
              creditRequestId: vintage.creditRequestId,
              quantity: vintage.totalAllocatedSupply.toString(),
              price: vintage.salePricePerCredit.toString(),
              vintage: vintage.creditBatchId,
              vintageYear: `${vintage.vintageStart}-${vintage.vintageEnd}`,
              isQtyFromApi: vintage.totalAllocatedSupply !== '' ? true : false,
              quantityError: false,
              priceError: false,
              vintageError: false,
              vintageDuplicateError: false,
              AvailableQuantityError: false,
              deliveryDetailsError: false,
              deliveryDetails: vintage.saleDeliveryRequestDTOS.map(
                (delivery) => ({
                  ...delivery,
                  isApiPopulated: true,
                }),
              ),
            })),
            projectNameError: false,
          }),
        );
        setExistingProjects([
          ...response.data.saleDeliveryResponseDTOS.map((project) => ({
            id: project.creditRequestId,
            name: project.projectName,
          })),
        ]);
        setLastUpdatedon(
          response.data.updatedDate ? response.data.updatedDate : null,
        );
        setIsDeleted(response.data.isActive == -1 ? true : false);
      })
      .catch((error) => {
        if (error) {
          setEditErrorMsg({
            flag: true,
            message: 'No Transaction data to display',
          });
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
    setFormValue({ ...editTransactionDetails });
    setProjectInfo([...projectDetails]);
  };

  //Add minimum one project on load of the page
  useEffect(() => {
    if (id) {
      if (location.pathname.slice(1, 5) === RouteIncludes.view) {
        setViewMode(true);
        setEditMode(false);
        fetchEditTransactionDetails(id);
      } else {
        setEditMode(true);
        setViewMode(false);
        fetchEditTransactionDetails(id);
      }
    } else {
      setEditMode(false);
      setViewMode(false);
      if (projectInfo.length === 0) {
        setProjectInfo((prev) => [
          ...prev,
          {
            ...projectObj,
            id: uniqueId(),
            paymentDetails: [{ ...paymentObj, id: uniqueId() }],
          },
        ]);
      }
    }
    //To fetch dropdown values
    fetchShareAssumptionTypes();
    fetchCategory();
    fetchSaleCategory();
    fetchCostStructure();
    fetchAssociatedTYpes();
  }, [editMode, viewMode]);

  const validateProjectInfo = () => {
    let validateProjectFlag = [];
    let validatePaymentFlag = [];
    if (projectInfo.length > 0) {
      projectInfo.forEach((project) => {
        if (
          !project.projectName.value.trim() ||
          validateProjectNames(
            projectInfo.map((projectDetail) => ({
              ...projectDetail.projectName,
              id: projectDetail.id,
            })),
            { ...project.projectName, id: project.id },
          )
        ) {
          validateProjectFlag.push({ id: project.id });
        } else {
          validateProjectFlag = validateProjectFlag.filter(
            (error) => error.id !== project.id,
          );
        }
        project.paymentDetails.forEach((payment) => {
          if (
            !payment.quantity.trim() ||
            !payment.price.trim() ||
            payment.vintage === '' ||
            validateVintage(project.paymentDetails, payment) ||
            payment.AvailableQuantityError ||
            validateDeliveryDetails(payment.quantity, payment.deliveryDetails)
          ) {
            validatePaymentFlag.push({ id: project.id });
          } else {
            validatePaymentFlag = validatePaymentFlag.filter(
              (error) => error.id !== project.id,
            );
          }
        });
      });
    } else {
      validateProjectFlag = [];
      validatePaymentFlag = [];
    }
    return validateProjectFlag.length === 0 && validatePaymentFlag.length === 0;
  };

  const submitHandler = () => {
    setOnSubmit(!onSubmit);
    setNameError(
      checkTernaryCondition(
        !transactionForm.transactionName.trim(),
        true,
        false,
      ),
    );

    setCatagoryError(
      checkTernaryCondition(
        !transactionForm.transactionCatagory.trim(),
        true,
        false,
      ),
    );
    setStatusError(
      checkTernaryCondition(
        !transactionForm.transactionStatus.trim(),
        true,
        false,
      ),
    );
    setStakeHolderError(
      checkTernaryCondition(transactionForm.stakeholder === null, true, false),
    );
    setSignedOnError(
      checkTernaryCondition(transactionForm.signedDate === null, true, false),
    );

    projectInfo.forEach((project) => {
      updateProjectInfo({
        ...project,
        projectNameError:
          project.projectName === ''
            ? { flag: true, message: PAGE_CONTENT.project_name_error }
            : { flag: false, messgae: '' },
        existingProjectError: validateProjectNames(
          projectInfo.map((projectDetail) => ({
            ...projectDetail.projectName,
            id: projectDetail.id,
          })),
          { ...project.projectName, id: project.id },
        ),
        paymentDetails: [
          ...project.paymentDetails.map((payment) => ({
            ...payment,
            quantityError: !payment.quantity.trim() ? true : false,
            priceError: !payment.price.trim() ? true : false,
            vintageError: payment.vintage === '' ? true : false,
            vintageDuplicateError: validateVintage(
              project.paymentDetails,
              payment,
            ),
            deliveryDetailsError: validateDeliveryDetails(
              payment.quantity,
              payment.deliveryDetails,
            ),
          })),
        ],
      });
    });
    if (
      transactionForm.transactionName.trim() &&
      transactionForm.transactionCatagory.trim() &&
      transactionForm.transactionStatus.trim() &&
      transactionForm.stakeholder !== null &&
      validateProjectInfo() &&
      transactionForm.signedDate !== null
    ) {
      let temp_submit = {
        clientId: 100,
        createdBy: 'User',
        transactionName: transactionForm.transactionName,
        transactionSignedOn: moment(transactionForm.signedDate).format(
          'YYYY-MM-DD',
        ),
        categoryOfSale: transactionForm.transactionCatagory,
        saleStatus: transactionForm.transactionStatus,
        soldToVendorId: transactionForm.stakeholder.vendorId,
        saleContractProjectDTOS: [
          ...projectInfo.map((project) => ({
            internalProjectId: project.projectName.internalProjectId,
            projectName: project.projectName.value,
            vintageRequestDTOS: project.paymentDetails.map(
              (paymentDetails) => ({
                creditRequestId: paymentDetails.creditRequestId,
                creditBatchId: paymentDetails.vintage,
                quantityMTCO2e: parseFloat(paymentDetails.quantity),
                pricePerMTCO2e: parseFloat(paymentDetails.price),
                saleDeliveryRequestDTOS: paymentDetails.deliveryDetails || [],
              }),
            ),
          })),
        ],
        notes: transactionForm.notes,
      };
      setSubmitData({ ...temp_submit });
      setSubmitTransactionState(true);
    }
  };

  const confirmTransactionSubmit = () => {
    if (submitData) {
      editMode
        ? editSaleTransaction(submitData, id)
        : submitSaleTransaction(submitData);
    }
  };

  const transactionHeading = () => {
    if (editMode) {
      return PAGE_CONTENT.edit_transaction_heading;
    } else if (viewMode) {
      return PAGE_CONTENT.view_transaction_heading;
    } else {
      return PAGE_CONTENT.transaction_sale_heading;
    }
  };

  const deleteTransaction = () => {
    setIsDialogOpen(false);
    setDeleteLoading(true);
    axios
      .delete(DELETE_TRANSACTION, {
        data: {
          contractId: id,
          type: PAGE_CONTENT.view_transaction_heading.slice(0, 4),
        },
      })
      .then((response) => {
        if (response?.data?.status === PAGE_CONTENT.success) {
          toast(
            <CustomSnackbar
              type="success"
              message={`${transactionForm?.transactionName} ${PAGE_CONTENT.success_message}`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else {
          setDialogTitle(
            `${transactionForm?.transactionName} couldn't be deleted`,
          );
          setDialogSubtitle(
            `${transactionForm?.transactionName} ${PAGE_CONTENT.failed_message}`,
          );
          setErrorIsDialogOpen(true);
        }
      })
      .catch(() => {
        setDialogTitle(
          `${transactionForm?.transactionName} couldn't be deleted`,
        );
        setDialogSubtitle(
          `${transactionForm?.transactionName} ${PAGE_CONTENT.failed_message}`,
        );
        setErrorIsDialogOpen(true);
        setDeleteLoading(false);
      });
  };

  const onErrorDialogBoxClose = () => {
    setErrorIsDialogOpen(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
    navigate(`${pathName.transaction.sale.edit}/${id}`, {
      state: { previousPath: location.pathname },
    });
  };

  //on submit scroll to error field
  useEffect(() => {
    if (onSubmit || !onSubmit || samenameError.flag) scroll('.Mui-error');
  }, [onSubmit, samenameError.flag]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoading || editLoading || deleteLoading}>
        <CircularProgress color="success" />
      </Backdrop>
      {
        <BackButton
          previouspath={
            state?.previousPath
              ? state?.previousPath
              : pathName.assets.transactions
          }
          // previousstate={{ state: 'transactionform' }}
          // previouspagename={previousTabName.title}
          marginLeft="1.6875rem"
          isEdited={dirty}
        />
      }
      {editErrorMsg.flag ? (
        editErrorMsg.message
      ) : (
        <MainWrapper>
          <FormSection onChange={markFormDirty}>
            <FormHeading>
              {transactionHeading()}
              {isDeletedRecord ? (
                <div className="deletedStatus">{` Deleted •
                  ${
                    lastUpdatedOn !== null ? usDateFormatter(lastUpdatedOn) : ''
                  }`}</div>
              ) : (
                viewMode && (
                  <DeleteModal
                    deleteTarget={deleteTransaction}
                    targetName={transactionForm?.transactionName}
                    transactionList={[]}
                    dialogSubtitle={dialogSubtitle}
                    isErrorDialogOpen={isErrorDialogOpen}
                    dialogTitle={dialogTitle}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    onErrorDialogBoxClose={onErrorDialogBoxClose}
                    navigateRoute={handleEditClick}
                    deletionType={'transaction'}
                  />
                )
              )}
            </FormHeading>
            <FormFields>
              <FormLabels iserror={nameError || samenameError.flag}>
                {PAGE_CONTENT.transaction_name_label}
              </FormLabels>
              <Input
                disabled={viewMode}
                value={transactionForm.transactionName}
                placeholder={PAGE_CONTENT.transaction_name_placeholder}
                name="transactionName"
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setNameError(false);
                  e.target.value !== '' &&
                    setSameNameError({ flag: false, message: '' });
                }}
                inputProps={{ 'aria-label': 'Transaction name' }}
                fullWidth
                error={nameError || samenameError.flag}
              />
              {nameError && (
                <ErroWrapper>{PAGE_CONTENT.transaction_name_error}</ErroWrapper>
              )}
              {samenameError.flag && (
                <ErroWrapper>{samenameError.message}</ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={signedOnError}>
                {PAGE_CONTENT.date_signed_label}
              </FormLabels>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={viewMode}
                  value={transactionForm.signedDate}
                  inputFormat="MM/DD/YYYY"
                  onChange={(newValue) => {
                    customFieldChangeHandler('signedDate', newValue.toDate());
                    newValue !== null && setSignedOnError(false);
                    markFormDirty();
                  }}
                  components={{
                    OpenPickerIcon: Calendericon,
                  }}
                  open={datepickerOpen}
                  InputAdornmentProps={{
                    sx: {
                      '& .MuiIconButton-root': {
                        paddingRight: '1rem',
                      },
                    },
                  }}
                  onOpen={() => setDatepickerOpen(true)}
                  onClose={() => setDatepickerOpen(false)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      error={signedOnError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select Date',
                      }}
                      sx={{
                        width: '9.375rem',
                      }}
                      onClick={() => setDatepickerOpen(true)}
                    />
                  )}
                />
              </LocalizationProvider>
              {signedOnError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_signed_error}
                </ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={catagoryError}>
                {PAGE_CONTENT.transaction_sale_category_label}
              </FormLabels>
              <Select
                disabled={viewMode}
                value={transactionForm.transactionCatagory}
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setCatagoryError(false);
                  markFormDirty();
                }}
                sx={{ width: '14rem', height: '2rem' }}
                name="transactionCatagory"
                displayEmpty
                error={catagoryError}
                inputProps={{ 'aria-label': 'transactionCatagory' }}
                input={<OutlinedInput />}
                renderValue={
                  transactionForm.transactionCatagory !== '' || null
                    ? undefined
                    : () => (
                        <DropDownPlaceholder>
                          {PAGE_CONTENT.transaction_sale_category_placeholder}
                        </DropDownPlaceholder>
                      )
                }>
                {categoryOfSale.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    sx={getMenuItemStyles}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {catagoryError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_category_error}
                </ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={stakeHolderError}>
                {PAGE_CONTENT.sold_to_label}
              </FormLabels>
              <Autocomplete
                disabled={viewMode}
                options={stakeholderList}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(e, newValue) => {
                  customFieldChangeHandler('stakeholder', newValue);
                  setStakeHolderError(false);
                  markFormDirty();
                }}
                filterSelectedOptions
                sx={{ '& ..MuiAutocomplete-tag': { border: 'green' } }}
                onInputChange={(e, newValue) => {
                  fetchStakeholderNames(newValue, 'Sale');
                }}
                data-testid="stakeholder"
                value={transactionForm.stakeholder}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={stakeHolderError}
                    inputProps={{
                      style: { paddingLeft: '0.625rem' },
                      ...params.inputProps,
                    }}
                    placeholder={PAGE_CONTENT.sold_to_placeholder}
                  />
                )}
              />
              {stakeHolderError && (
                <ErroWrapper>{PAGE_CONTENT.stakeholder_error}</ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels
                iserror={statusError}
                className={statusError ? 'Mui-error' : ''}>
                {PAGE_CONTENT.transaction_sale_status_label}
              </FormLabels>
              <Radio
                disabled={viewMode}
                color="success"
                {...transactionStatusRadioProps(
                  PAGE_CONTENT.transaction_sale_status_radio_1,
                )}
                sx={radioBtnSx}
              />
              <RadioButtonlabel>
                {PAGE_CONTENT.transaction_sale_status_radio_1}
              </RadioButtonlabel>
              <Radio
                disabled={viewMode}
                color="success"
                {...transactionStatusRadioProps(
                  PAGE_CONTENT.transaction_sale_status_radio_2,
                )}
                sx={radioBtnSx}
              />
              <RadioButtonlabel>
                {PAGE_CONTENT.transaction_sale_status_radio_2}
              </RadioButtonlabel>
              {statusError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_status_error}
                </ErroWrapper>
              )}
            </FormFields>
            {/* Project section */}
            {projectInfo.length > 0 &&
              projectInfo.map((project, index) => {
                return (
                  <SaleProjectSubsection
                    key={project.id}
                    paymentTypes={paymentTypes}
                    costStructure={costStructure}
                    onUpdate={updateProjectInfo}
                    onDelete={deleteProjectInfo}
                    projectObj={project}
                    projectno={index}
                    isEdit={editMode}
                    existingProjects={existingProjects}
                    checkProjects={checkProjectNames}
                    viewMode={viewMode}
                    paymentObj={paymentObj}
                    projectList={projectInfo}
                  />
                );
              })}
            {/* <ProjectSubsection/> */}

            <AddProjectWrapper onClick={addProjectHandler} hidden={viewMode}>
              <AddIcon />
              <AddprojectLabel>{PAGE_CONTENT.add_project_text}</AddprojectLabel>
            </AddProjectWrapper>

            <FormFields>
              <FormLabels>
                {PAGE_CONTENT.notes_label}
                <OptionalLabel>{PAGE_CONTENT.option_field}</OptionalLabel>
              </FormLabels>
              <TextArea
                disabled={viewMode}
                placeholder={PAGE_CONTENT.notes_placeholder}
                name="notes"
                onChange={formFieldChangeHandler}
                className="text_area"
                value={transactionForm.notes}></TextArea>
            </FormFields>

            {!viewMode && (
              <ButtonWrapper>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ textTransform: 'none' }}
                  color="success"
                  onClick={submitHandler}
                  disableElevation>
                  {PAGE_CONTENT.submit_button_label}
                </Button>
                <Button
                  size="medium"
                  color="success"
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    navigate(pathName.assets.transactions, {
                      state: 'transactionform',
                    });
                  }}>
                  {PAGE_CONTENT.cancel_button_label}
                </Button>
              </ButtonWrapper>
            )}
          </FormSection>
        </MainWrapper>
      )}
    </>
  );
};

export default AddSaleTransaction;
