import styled from 'styled-components';
import { COOL_GRAY, DARK_CHARCOAL, RED } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const FieldNameInput = styled.input`
  font-size: 0.875rem;
  padding: 0.25rem;
  width: 90%;
  margin-bottom: 0.5rem;
  border-radius: 2px;
  border: none;
  outline: ${({ isError }) =>
    checkTernaryCondition(
      isError,
      `1px solid ${RED}`,
      `1px solid ${COOL_GRAY}`,
    )};
`;

export const ContentWrapper = styled.div`
  margin-bottom: 0.25rem;
  line-height: 1.02rem;
  color: ${(props) => props.color ?? DARK_CHARCOAL};
`;

export const ErrorWrapper = styled.div`
  color: RED;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
`;
