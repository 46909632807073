import { StyleSheet } from '@react-pdf/renderer';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    fontStyle: 'bold',
    justifyContent: 'space-between',
    width: '100%',
  },
  description: {
    color: DARK_CHARCOAL,
    fontSize: '14px',
    paddingRight: 5,
    marginLeft: '7px',
  },
  section: {
    margin: 25,
    marginTop: 0,
    padding: 10,
    paddingTop: 0,
  },
  pageHeading: {
    fontSize: '20px',
    margin: '10px 0 24px',
  },
  subHeading: {
    fontSize: '14px',
    color: COOL_GRAY,
    marginBottom: '7px',
    fontWeight: 600,
  },
  subHeading2: {
    fontSize: '14px',
    color: COOL_GRAY,
    marginBottom: '7px',
    fontWeight: 600,
    marginTop: '16px',
  },
});
