export const MODAL_CONTENT = {
  enrollment_heading: 'Initiate enrollment',
  conitnue_enrollment_status: 'Continue Participant Enrollment for',
  project_field_label: 'Select project to initiate enrollment process for ',
  project_field_placeholder: 'Type and select project ',
  select_new_project_label: 'Enroll Participant in New Project',
  button_selection_label: 'How would you like to enroll ?',
  cancelBtn: 'Cancel',
  enrollBtn: 'Enroll on behalf',
  sendRequestBtn: 'Send request',
  success: [200, 201, 204],
  email_success_message: 'Email sent successfully',
  email_error_message: 'Unable to send email due to technical error',
  submit: 'Submit',
  project_name: 'Project name',
  project_name_placeholder: 'Type and select project name',
  project_life_cycle_label:
    'Please select which project cycle they will start on.',
  project_life_cycle_placeholder: 'Select project cycle',
  two_rem: '2rem',
  project_cycle_required_error: 'Project cycle selection is required.',
  project_not_present_error:
    'Project entered does not exist. Please select a project from the list as you type.',
  participant_name: 'Participant name',
  participant_name_placeholder: 'Type and select participant name',
  participant_not_present_error:
    'Participant entered does not exist. Please select a participant from the list as you type.',
};
