import React from 'react';
import {
  CustomPopupBox,
  Wrapper,
  TitleWrapper,
} from '../ValueTable/ValueTable.style';

export const notesComponent = (oldValue, newValue, notesComponentType) => {
  return (
    <CustomPopupBox direction="column" padding="0.5rem 0.5rem 0rem 0.5rem">
      <TitleWrapper>{`Old ${notesComponentType}`}</TitleWrapper>
      <Wrapper>{oldValue}</Wrapper>
      <TitleWrapper>{`New ${notesComponentType}`}</TitleWrapper>
      <Wrapper>{newValue}</Wrapper>
    </CustomPopupBox>
  );
};
