import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Container } from '@mui/system';

export const MainWrapper = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gridColumnGap: '2.5rem',
  width: 'fit-content',
  marginRight: '0rem',
}));

export const DownloadIconWrapper = styled(Container)(() => ({
  height: '1.5rem',
  width: '0rem',
  padding: '0.25rem',
  cursor: 'pointer',
}));

export const CustomIconButton = styled(IconButton)(() => ({
  padding: '0rem',
  margin: '0rem',
}));

export const OptionsWrapper = styled('div')(({ isEnabled }) => ({
  visibility: isEnabled ? '' : 'hidden',
}));
