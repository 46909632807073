import { StyleSheet } from '@react-pdf/renderer';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 400,
    fontSize: 14,
    color: DARK_CHARCOAL,
    marginBottom: '8px',
  },
  bullet: {
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontWeight: 400,
    fontSize: 14,
    color: DARK_CHARCOAL,
  },
  subheading: {
    fontSize: '14px',
    color: COOL_GRAY,
    marginBottom: '5px',
  },
  section: {
    margin: '0 0 12px',
  },
  para: {
    fontWeight: 400,
    fontSize: 14,
    color: DARK_CHARCOAL,
    paddingRight: 5,
    marginTop: '4px',
    marginBottom: '2px',
  },
});
