import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import { IconHandler } from './StatusBox.content';
import {
  StatusBoxWrapper,
  StatusMainWrapper,
  TabWrapper,
} from './StatusBox.style';

const StatusBox = ({
  disabled,
  statusLabel,
  status,
  onClickHandler,
  icon,
  disableOutline,
  isBaselineMenu,
  disabledIcon,
}) => {
  return (
    <StatusMainWrapper isBaselineMenu={isBaselineMenu}>
      <StatusBoxWrapper
        disabled={disabled}
        onClick={onClickHandler}
        disableOutline={disableOutline}>
        <TabWrapper disabled={disabled}>
          {checkTernaryCondition(disabled, disabledIcon, icon)}
          {statusLabel}
        </TabWrapper>
        {IconHandler(status, disabled)}
      </StatusBoxWrapper>
    </StatusMainWrapper>
  );
};

StatusBox.propTypes = {
  disabled: PropTypes.bool,
  statusLabel: PropTypes.string,
  status: PropTypes.string,
  onClickHandler: PropTypes.func,
  icon: PropTypes.node,
  disableOutline: PropTypes.bool,
  isBaselineMenu: PropTypes.bool,
  disabledIcon: PropTypes.node,
};
export default StatusBox;
