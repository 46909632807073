import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { COOL_GRAY } from 'theme/GlobalColors';

const BACKGROUND_COLOR = 'white';

export const StyledChip = styled(Chip)(() => ({
  borderRadius: '0.25rem',
  color: COOL_GRAY,
  backgroundColor: BACKGROUND_COLOR,
  fontSize: '0.688rem',
  border: `0.5px solid ${COOL_GRAY}`,
  height: '1.25rem',
  '& .MuiChip-deleteIcon': {
    fill: BACKGROUND_COLOR,
    color: COOL_GRAY,
    margin: 0,
  },
}));
