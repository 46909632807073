import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  InputSx,
  ParticipantNameSection,
  communicationCheckboxPadding,
  disabledStyle,
} from '../../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import {
  FORM_CONTENT,
  NEW_CONTRIBUTOR_STATUS_VALUES,
} from '../ParticipantContributorsForm.content';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { Button, Box } from '@mui/material';
import { saveAndContinuebuttonStyle } from '../../UserNavigationButtons/UserNavigationButtons.style';
import {
  FormLabels,
  primaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import {
  IconWrapper,
  InviteSentHelpText,
  SectionLabelWrapper,
  deleteIconSx,
} from '../ParticipantContributorsForm.style';
import {
  checkTernaryCondition,
  formatPhoneNumber,
  replaceSpecialCharactersWithSingleCharacter,
  validateEmail,
  validatePhone,
} from 'utils/helper';
import FarmAssignmentDropdown from '../FarmAssignmentDropdown/FarmAssignmentDropdown.react';
import {
  getIsSendLinkDisabled,
  isFormFilled,
} from '../ParticipantContributorsForm.funtions';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InstructionWrapper } from 'components/FormComponents/FormStyles';

const ParticipantContributorSubsection = ({
  assignToFarmProps,
  label,
  deleteHandler,
  contributor,
  updateHandler,
  farmsList,
  updateErrorList,
  error,
  sendInviteClickHandler,
  lastInviteSent,
  activatedContributorIds = [],
}) => {
  const [searchParams] = useSearchParams();
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const [sentInvite, setSentInvite] = useState({
    inviteSentStatus: lastInviteSent !== undefined && lastInviteSent,
    inviteSentDate: lastInviteSent,
  });

  useEffect(() => {
    setSentInvite({
      inviteSentStatus: lastInviteSent !== undefined && lastInviteSent,
      inviteSentDate: lastInviteSent,
    });
  }, [lastInviteSent]);

  const isFormDisabled =
    contributor.status === NEW_CONTRIBUTOR_STATUS_VALUES[1];
  return (
    <>
      <FormLabels flexDirection="row" gap="1rem">
        <SectionLabelWrapper data-testid={label}>{label}</SectionLabelWrapper>
        <IconWrapper>
          <DeleteIconWithHoverEffect
            sx={deleteIconSx}
            data-testid="delete-icon"
            onClick={() => deleteHandler(contributor.id)}
          />
        </IconWrapper>
      </FormLabels>
      <ParticipantNameSection>
        <InputField
          rightSideLabelFormLabelMargin="0rem"
          customInputSx={InputSx}
          minWidth="0rem"
          width="100%"
          isDisabled={isFormDisabled}
          customStyles={{ width: '49.3%' }}
          required
          label={FORM_CONTENT.first_name_label}
          name="firstName"
          value={contributor.firstName}
          placeholder={FORM_CONTENT.first_name_placeholder}
          primaryError={false}
          primaryErrorMessage={''}
          maxLength={100}
          labelMarginTop="0rem"
          labelMarginBottom="0.25rem"
          onUpdate={(e) => {
            updateHandler(contributor.id, 'firstName', e.target.value);
          }}
        />
        <InputField
          rightSideLabelFormLabelMargin="0rem"
          customInputSx={InputSx}
          minWidth="0rem"
          customStyles={{ width: '49.3%' }}
          width="100%"
          required
          isDisabled={isFormDisabled}
          label={FORM_CONTENT.last_name_label}
          name="lastName"
          value={contributor.lastName}
          placeholder={FORM_CONTENT.last_name_placeholder}
          primaryError={false}
          primaryErrorMessage={''}
          maxLength={100}
          labelMarginTop="0rem"
          labelMarginBottom="0.25rem"
          onUpdate={(e) => {
            updateHandler(contributor.id, 'lastName', e.target.value);
          }}
        />
      </ParticipantNameSection>
      <InputField
        customInputSx={InputSx}
        minWidth="0rem"
        width="100%"
        required
        isDisabled={isFormDisabled}
        label={FORM_CONTENT.email_label}
        name="email"
        value={contributor.email}
        placeholder={FORM_CONTENT.email_placeholder}
        primaryError={false}
        primaryErrorMessage={''}
        onBlurHandler={(event) => {
          if (event.target.value !== '') {
            updateErrorList(
              contributor.id,
              'invalidEmailError',
              !validateEmail(event.target.value),
            );
          }
        }}
        secondaryError={error?.invalidEmailError}
        secondaryErrorMessage={FORM_CONTENT.email_invalid_domain_error}
        maxLength={100}
        labelMarginTop="1rem"
        labelMarginBottom="0.25rem"
        onUpdate={(e) => {
          updateHandler(contributor.id, 'email', e.target.value);
        }}
      />
      <InputField
        customInputSx={InputSx}
        customStyles={{ width: '100%' }}
        minWidth="0rem"
        width="32%"
        required
        isDisabled={isFormDisabled}
        label={FORM_CONTENT.phone_label}
        name="phone"
        value={contributor.phone}
        onUpdate={(e) => {
          const value = formatPhoneNumber(e.target.value);
          updateHandler(contributor.id, 'phone', value);
        }}
        // makeFormDirty();
        onBlurHandler={(event) => {
          if (event.target.value !== '') {
            const value = replaceSpecialCharactersWithSingleCharacter(
              /[()\-\s]/g,
              event.target.value,
              '',
            );
            updateErrorList(
              contributor.id,
              'invalidPhoneError',
              !validatePhone(value),
            );
          }
        }}
        placeholder={FORM_CONTENT.phone_placeholder}
        primaryError={false}
        primaryErrorMessage={''}
        maxLength={100}
        secondaryError={error?.invalidPhoneError}
        secondaryErrorMessage={FORM_CONTENT.phone_invalid_error}
        labelMarginTop="0rem"
        labelMarginBottom="0.25rem"
        customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
      />
      <CheckboxFormGroup
        disableAllOptions={isFormDisabled}
        list={contributor.communicationMethod}
        label={FORM_CONTENT.communication_method_label}
        instructionText={FORM_CONTENT.communication_method_helptext}
        primaryError={false}
        errorMessage={''}
        onClickHandler={(methodIndex) => {
          updateHandler(
            contributor.id,
            'communicationMethod',
            contributor.communicationMethod.map((method, index) => ({
              ...method,
              checked: checkTernaryCondition(
                methodIndex === index,
                !method.checked,
                method.checked,
              ),
            })),
          );
          //   makeFormDirty();
        }}
        gap="1.625rem"
        name="communicationMethod"
        customStyles={disabledStyle(true)}
        labelMarginBottom="0.25rem"
        checkboxPadding={communicationCheckboxPadding}
        titleAndInfoGap="0.25rem"
      />
      <FarmAssignmentDropdown
        value={contributor.assignedFarms}
        onUpdateHandler={(value) => {
          updateHandler(contributor.id, 'assignedFarms', value);
          updateErrorList(
            contributor.id,
            'assignedFarmsError',
            value.length === 0,
          );
        }}
        deleteHandler={(value) => {
          const updatedAssignedFarms = contributor.assignedFarms.filter(
            (item) => item !== value,
          );
          updateHandler(contributor.id, 'assignedFarms', updatedAssignedFarms);
          updateErrorList(
            contributor.id,
            'assignedFarmsError',
            updatedAssignedFarms.length === 0,
          );
        }}
        dropdownList={farmsList}
        currentInstanceId={Number(enrollmentInstanceId)}
        error={error?.assignedFarmsError}
        errorMessage={FORM_CONTENT.farm_assignment_required_error}
      />
      <Box display="flex" gap={1} alignItems={'center'}>
        <Button
          disabled={!activatedContributorIds.includes(contributor.id)}
          variant="contained"
          size="medium"
          sx={[saveAndContinuebuttonStyle, primaryButtonStyle()]}
          color="success"
          onClick={() => sendInviteClickHandler(setSentInvite)}>
          {!sentInvite.inviteSentStatus
            ? FORM_CONTENT.send_invite_btn_label
            : FORM_CONTENT.resend_invite_btn_label}
        </Button>
        {sentInvite.inviteSentStatus && (
          <InstructionWrapper>
            {'Invite sent ' + sentInvite.inviteSentDate}
          </InstructionWrapper>
        )}
      </Box>
      {NEW_CONTRIBUTOR_STATUS_VALUES[1] === contributor.status && (
        <InviteSentHelpText>
          {FORM_CONTENT.invite_sent_help_text}
        </InviteSentHelpText>
      )}
    </>
  );
};

export default ParticipantContributorSubsection;
