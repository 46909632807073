import styled from 'styled-components';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const NpkFieldWrapper = styled.div`
  display: flex;
  grid-column-gap: 0.2rem;
`;

export const PercentageWrapper = styled.div`
  padding-top: 0.5rem;
  font-size: 14px;
  color: ${COOL_GRAY};
`;
export const DateItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-item: baseline;
`;
export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
export const OptionalLabel = styled.span`
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${({ color }) => checkTernaryCondition(color, color, COOL_GRAY)};
  opacity: ${({ opacity }) => checkTernaryCondition(opacity, opacity, 0.5)};
  margin-left: ${({ marginLeft }) => marginLeft || '0rem'};
  margin-top: 0.875rem;
`;
export const TooltipSx = {
  '& .MuiTooltip-tooltip': {
    padding: 0,
    borderRadius: 0,
  },
};
export const InfoTooltipWrapper = styled.div`
  font-size: 0.75rem;
  width: 284px;
  padding: 0.5rem;
  border-radius: 0rem;
`;
export const InfoSx = {
  color: COOL_GRAY,
  fontSize: '1rem',
  position: 'relative',
  left: 74,
};
export const FlexWrapper = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '1rem'};
  flexdirection: row;
`;

export const StyledDropdown = styled.div`
  position: relative;
  left: -34px;
  top: 17px;
`;

export const StyledInput = styled.div`
  position: relative;
  left: 15px;
  margin-bottom: 10px;
`;

export const DeleteIconStyle = (index, isDeleteIconVisible, isInTesting) => {
  return {
    position: 'relative',
    top: checkTernaryCondition(index === 0, 50, 7),
    height: '16px',
    width: '16px',
    color: DARK_CHARCOAL,
    visibility: checkTernaryCondition(
      isDeleteIconVisible || isInTesting,
      'visible',
      'hidden',
    ),
  };
};
