import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OverviewWrapper } from '../PortfolioOverview/PortfolioOverview.style';
import {
  LOOKER_SIGNED_URL,
  VENDOR_PROJECT_DETAILS,
  VENDOR_PROJECT_TABLE_COLUMN,
  DELETE_STAKEHOLDER,
  VENDOR_BY_ID,
} from 'urls';
import { useEffect, useState } from 'react';
import { CLIENT_ID, PAGENAME } from 'utils/config';
import axios from 'axios';
import BackButton from 'components/Backbutton/BackButton.react';
import {
  ContentWrapper,
  dataGridSx,
  FilterHeader,
  HeadingSubtext,
  HeadingWrapper,
  NumericCell,
  OverviewTable,
  ProjectWrapper,
  SortWrapper,
  StakeholderTypeTag,
  TagContent,
  TruncatedWrapper,
} from './StakeholderProfile.style';
import { DataGrid } from '@mui/x-data-grid';
import {
  DEFAULT_PAGINATION_OPTIONS,
  DEFAULT_PAGE_SIZE,
  LABEL_ROWS_PER_PAGES,
  PAGINATION_MODE,
} from '../../utils/config';
import uuid from 'react-uuid';
import {
  SortColValues,
  STAKEHOLDERS_PROFILE_CONTENT,
} from './StakeholderProfile.content';
import DeleteModal from 'components/DeleteModal/DeleteModal.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import FilterComponent from 'components/FilterComponent/FilterComponent.react';
import { Tooltip } from '@mui/material';
import { SortHandler } from 'pages/StakeholdersProfile/StakeholdersProfile.content';
import { previousPathHandler } from 'utils/helper';
import { pathName } from 'Routes/routes.const';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';

const StakeholderProfile = ({ disableVirtualization }) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [iframeSrc, setIframeSrc] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [stakeholderName, setStakeholderName] = useState();
  const [embedUrl, setEmbedUrl] = useState('');

  const commonColumns = [
    {
      field: 'projectName',
      headerName: 'Project Name',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Project Name</b>
          <FilterComponent
            isFilterApplied={
              projectNameSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.PROJECT_NAME.length > 4 ? true : false}
            isSearchEnabled={
              modifiedList.PROJECT_NAME.length > 9 ? true : false
            }
            list={modifiedList.PROJECT_NAME}
            setFilterList={setProjectNameSelectedFilter}
          />
          <SortWrapper
            data-testid="project-name"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.nameFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) =>
        params.value ? (
          <Tooltip title={params.value}>
            <TruncatedWrapper>{params.value}</TruncatedWrapper>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Project Type</b>
          <FilterComponent
            isFilterApplied={
              projectTypeSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.TYPE.length > 4 ? true : false}
            isSearchEnabled={modifiedList.TYPE.length > 9 ? true : false}
            list={modifiedList.TYPE}
            setFilterList={setProjectTypeSelectedFilter}
          />
          <SortWrapper
            data-testid="project-type"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.typeFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) =>
        params.value ? (
          <Tooltip title={params.value}>
            <TruncatedWrapper>{params.value}</TruncatedWrapper>
          </Tooltip>
        ) : (
          <span>-</span>
        ),
    },
    {
      field: 'creditSupply',
      headerName: 'Credit Supply (CO2e MT)',
      flex: 1,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          {STAKEHOLDERS_PROFILE_CONTENT.credit_supply_co2_MT}
          <SortWrapper
            data-testid="credit-supply"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.supplyFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <NumericCell>{params.value || '-'}</NumericCell>,
    },
  ];

  const projectCustomerColumns = [
    ...commonColumns,
    {
      field: 'perCredit',
      headerName: 'Sale Price per Credit',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b data-testid="Sale Price per Credit">Sale Price per Credit</b>
          <SortWrapper
            data-testid="sale-price-per-credit"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.creditFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <NumericCell>{params.value ? `$${params.value}` : '-'}</NumericCell>
      ),
    },
    {
      field: 'status',
      headerName: 'Sale status',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Sale status</b>
          <FilterComponent
            isFilterApplied={
              projectStatusSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.STATUS.length > 4 ? true : false}
            isSearchEnabled={modifiedList.STATUS.length > 9 ? true : false}
            list={modifiedList.STATUS}
            setFilterList={setProjectStatusFilter}
          />
          <SortWrapper
            data-testid="sale-status"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.statusFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
  ];

  const projectOtherColumns = [
    ...commonColumns,
    {
      field: 'perCredit',
      headerName: 'Cost per Credit',
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Cost per Credit</b>
          <SortWrapper
            data-testid="cost-per-credit"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.creditFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <NumericCell>{params.value ? `$${params.value}` : '-'}</NumericCell>
      ),
    },
    {
      field: 'status',
      headerName: 'Purchase status',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Purchase status</b>
          <FilterComponent
            isFilterApplied={
              projectStatusSelectedFilter?.length > 0 ? true : false
            }
            isMultiSelect={modifiedList.STATUS.length > 4 ? true : false}
            isSearchEnabled={modifiedList.STATUS.length > 9 ? true : false}
            list={modifiedList.STATUS}
            setFilterList={setProjectStatusFilter}
          />
          <SortWrapper
            data-testid="purchase-status"
            onClick={() => {
              handleSortClick(params);
            }}>
            {SortHandler(sortOrder.statusFlag, params.field, sortColumn)}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
  ];
  const [stakeholderType, setStakeholderType] = useState('');
  const [projectDetails, setProjectDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState();
  const origin = window.location.origin;
  const [sortOrder, setSortOrder] = useState({
    nameFlag: false,
    typeFlag: false,
    supplyFlag: false,
    creditFlag: false,
    statusFlag: false,
  });
  const [order, setOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState('');

  const [filterData, setFilterData] = useState({
    PROJECT_NAME: [],
    TYPE: [],
    STATUS: [],
  });
  const [modifiedList, setModifiedList] = useState({
    PROJECT_NAME: [],
    TYPE: [],
    STATUS: [],
  });
  const [projectNameSelectedFilter, setProjectNameSelectedFilter] = useState(
    [],
  );
  const [projectTypeSelectedFilter, setProjectTypeSelectedFilter] = useState(
    [],
  );
  const [projectStatusSelectedFilter, setProjectStatusFilter] = useState([]);
  const [loading, setLoading] = useState([]);

  const stakeholderLookerMapping = {
    Customer: 'stakeholder_profile_customer',
    Supplier: 'stakeholder_profile_supplier',
    Investor: 'stakeholder_profile_financer_investor',
    Financer: 'stakeholder_profile_financer_investor',
  };

  const fetchStakeholderData = async () => {
    if (id) {
      await axios.get(VENDOR_BY_ID + `/${id}`).then((response) => {
        setStakeholderName(response.data?.vendorOrganization);
        setStakeholderType(response.data?.vendorType);
        setEmbedUrl(
          `?src=/embed/dashboards/aspiration::${
            stakeholderLookerMapping[response.data?.vendorType]
          }?client_id=${CLIENT_ID}%26vendor_id=${id}%26embed_domain=${origin}&sdk=2`,
        );
      });
    }
  };

  const fetchProjectDetails = (stakeholderType) => {
    setLoading(true);
    axios
      .post(VENDOR_PROJECT_DETAILS, {
        vendorId: id,
        type: stakeholderType,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filter: {
          projectName: projectNameSelectedFilter,
          type: projectTypeSelectedFilter,
          status: projectStatusSelectedFilter,
        },
        sortFilter: {
          newestToOldest: order,
          sortType: sortColumn,
        },
      })
      .then((response) => {
        setProjectDetails(
          response.data.content.map((data) => ({ id: uuid(), ...data })),
        );
        setTotalElements(response.data.totalElements);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFilterOptions = () => {
    axios
      .all(
        [
          `${VENDOR_PROJECT_TABLE_COLUMN}?columnType=PROJECT_NAME&stakeHolderType=${stakeholderType.toUpperCase()}&vendorId=${id}`,
          `${VENDOR_PROJECT_TABLE_COLUMN}?columnType=TYPE&stakeHolderType=${stakeholderType.toUpperCase()}&vendorId=${id}`,
          `${VENDOR_PROJECT_TABLE_COLUMN}?columnType=STATUS&stakeHolderType=${stakeholderType.toUpperCase()}&vendorId=${id}`,
        ].map((promise) => axios.get(promise)),
      )
      .then(
        axios.spread((...responses) => {
          setFilterData({
            ...filterData,
            PROJECT_NAME: responses[0].data !== '' ? responses[0].data : [],
            TYPE: responses[1].data !== '' ? responses[1].data : [],
            STATUS: responses[2].data !== '' ? responses[2].data : [],
          });
        }),
      )
      .catch(() => {});
  };

  const handelFilterClick = () => {
    let projectNameList = filterData.PROJECT_NAME?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let projectTypeList = filterData.TYPE?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    let projectStatusList = filterData.STATUS?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    setModifiedList(() => ({
      ...modifiedList,
      PROJECT_NAME: [...projectNameList],
      TYPE: [...projectTypeList],
      STATUS: [...projectStatusList],
    }));
  };

  const columnsSort = {
    nameFlag: false,
    typeFlag: false,
    supplyFlag: false,
    creditFlag: false,
    statusFlag: false,
  };

  const handleSorting = (flagValue, flagName) => {
    let obj = { ...columnsSort };
    obj[flagName] = !flagValue;
    setSortOrder(obj);
    setOrder(!flagValue);
  };

  const handleSortClick = (params) => {
    setSortColumn(SortColValues[params.field]);
    switch (SortColValues[params.field]) {
      case SortColValues.projectName:
        handleSorting(sortOrder.nameFlag, 'nameFlag');
        break;
      case SortColValues.type:
        handleSorting(sortOrder.typeFlag, 'typeFlag');
        break;
      case SortColValues.creditSupply:
        handleSorting(sortOrder.supplyFlag, 'supplyFlag');
        break;
      case SortColValues.perCredit:
        handleSorting(sortOrder.creditFlag, 'creditFlag');
        break;
      case SortColValues.status:
        handleSorting(sortOrder.statusFlag, 'statusFlag');
        break;
    }
  };

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);

  useEffect(() => {
    if (stakeholderType !== '') {
      fetchFilterOptions();
    }
  }, [stakeholderType]);

  useEffect(() => {
    if (stakeholderType !== '') {
      fetchProjectDetails(stakeholderType);
    }
  }, [pageNumber, pageSize, stakeholderType]);

  useEffect(() => {
    if (stakeholderType !== '') {
      fetchProjectDetails(stakeholderType);
    }
  }, [stakeholderType, sortOrder, sortColumn]);

  useEffect(() => {
    fetchProjectDetails(stakeholderType);
  }, [
    projectNameSelectedFilter,
    projectTypeSelectedFilter,
    projectStatusSelectedFilter,
  ]);

  const onErrorDialogBoxClose = () => {
    setTransactionList([]);
    setErrorIsDialogOpen(false);
  };
  const navigateRoute = () => {
    navigate(`${pathName.assets.stakeholders.edit}/${id}`, {
      state: { previousPath: location.pathname },
    });
  };

  useEffect(() => {
    fetchStakeholderData();
  }, []);

  useEffect(() => {
    if (embedUrl !== '') {
      axios({
        method: 'get',
        url: `${LOOKER_SIGNED_URL}${embedUrl}`,
      })
        .then((res) => {
          setIframeSrc(res?.data?.url);
        })
        .catch(() => {});
    }
  }, [embedUrl]);

  const setDialogContent = (title, subtitle) => {
    setDialogTitle(title);
    setDialogSubtitle(subtitle);
    setErrorIsDialogOpen(true);
  };
  const deleteStakeholder = () => {
    setIsDialogOpen(false);
    axios
      .delete(DELETE_STAKEHOLDER, {
        params: { vendorId: id },
      })
      .then((response) => {
        switch (response.data.status) {
          case STAKEHOLDERS_PROFILE_CONTENT.success:
            toast(
              <CustomSnackbar
                type="success"
                message={`${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.success_message}`}
              />,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              },
            );
            navigate(-1);
            break;
          case STAKEHOLDERS_PROFILE_CONTENT.failed:
            setDialogContent(
              `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message.slice(
                0,
                19,
              )}`,
              `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.delete_message}`,
            );
            break;
          case STAKEHOLDERS_PROFILE_CONTENT.denied:
            setDialogTitle(
              `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.cannot_delete}`,
            );
            if (response?.data?.transactionNames.length === 1) {
              setDialogSubtitle(
                `${stakeholderName} is linked in ${response?.data?.transactionNames[0]} ${STAKEHOLDERS_PROFILE_CONTENT.denied_message_single_transaction}`,
              );
            } else {
              setDialogSubtitle(
                `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.denied_message_multiple_transaction}`,
              );
              setTransactionList(response?.data?.transactionNames);
            }
            setErrorIsDialogOpen(true);
            break;
          default:
          //console.log(response.data.status)
        }
      })
      .catch(() => {
        setDialogContent(
          `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message.slice(
            0,
            19,
          )}`,
          `${stakeholderName} ${STAKEHOLDERS_PROFILE_CONTENT.failed_message}`,
        );
      });
  };
  return (
    <OverviewWrapper>
      <BackButton
        previouspath={previousPathHandler(state?.previousPath)}
        marginLeft="2rem"
        previouspagename={PAGENAME.STAKEHOLDERS_LIST}
      />
      <HeadingWrapper>
        <HeadingSubtext>
          {stakeholderName}
          <StakeholderTypeTag>
            <TagContent>{stakeholderType}</TagContent>
          </StakeholderTypeTag>
        </HeadingSubtext>

        <DeleteModal
          deleteTarget={deleteStakeholder}
          targetName={stakeholderName}
          transactionList={transactionList}
          dialogSubtitle={dialogSubtitle}
          isErrorDialogOpen={isErrorDialogOpen}
          dialogTitle={dialogTitle}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          onErrorDialogBoxClose={onErrorDialogBoxClose}
          navigateRoute={navigateRoute}
        />
      </HeadingWrapper>

      <OverviewTable>
        {iframeSrc !== '' && (
          <iframe
            className="stakeholder-profile"
            title="Looker Dashboard"
            src={iframeSrc}
            width="100%"
            height="100%"
            frameBorder="0"></iframe>
        )}
      </OverviewTable>

      <ContentWrapper>
        <ProjectWrapper>
          <DataGrid
            disableVirtualization={disableVirtualization}
            rows={projectDetails}
            sx={dataGridSx}
            rowCount={totalElements}
            columns={
              stakeholderType === 'Customer'
                ? projectCustomerColumns
                : projectOtherColumns
            }
            paginationMode={PAGINATION_MODE}
            page={pageNumber}
            pageSize={pageSize}
            onPageChange={(newPage) => setPageNumber(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
            pagination
            componentsProps={{
              pagination: {
                labelRowsPerPage: LABEL_ROWS_PER_PAGES,
                SelectProps: TablePaginationMenuListStyle,
              },
            }}
            disableColumnMenu
            loading={loading}
            disableSelectionOnClick
          />
        </ProjectWrapper>
      </ContentWrapper>
    </OverviewWrapper>
  );
};

StakeholderProfile.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default StakeholderProfile;
