import styled from 'styled-components';

const MAIN_FONT_COLOR = '#333333';
const BORDER_COLOR = '#D0D0CE';
const SUBTEXT_COLOR = `#63666A`;
const REMOVE_PAYMENT_COLOR = '#0076A8';
const ERROR_MESSAGE_COLOR = '#DA291C';
const BACKGROUND_COLOR = '#F5F5F5;';

export const FormFields = styled.div`
  margin-bottom: ${(marginBottom) => (marginBottom ? marginBottom : '1rem')};
  height: fit-content;

  input {
    padding-left: 0.625rem;
  }
`;

export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) => props.iserror && ERROR_MESSAGE_COLOR};
`;

export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${MAIN_FONT_COLOR};
`;

export const RemovePaymentLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${REMOVE_PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;
export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  padding-left: 0.3rem;
  color: ${ERROR_MESSAGE_COLOR};
`;

export const RadioButtonlabel = styled.span`
  margin-left: 0.5rem;
  margin-right: 5.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
`;
export const radioBtnSx = {
  width: '1.125rem',
  height: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
};

export const OptionalLabel = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${SUBTEXT_COLOR};
  opacity: 0.5;
  margin-left: 0.5rem;
`;

export const VintageSection = styled.div`
  border-left: 0.125rem solid ${BORDER_COLOR};
  padding-left: 0.5rem;
  margin-top: 1rem;
  background-color: ${BACKGROUND_COLOR};
  padding-bottom: 0.5rem;
`;

export const VariableDropdownSx = {
  width: '14rem',
  height: '2rem',
};
