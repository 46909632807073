import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { ReactComponent as NotStartedIcon } from '../../../../assets/icons/smallNotStartedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../../../assets/icons/smallInProgressIcon.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/smallSuccessIcon.svg';
import { ReactComponent as ReviewIcon } from '../../../../assets/icons/inReviewIcon.svg';
import {
  TextWrapper,
  acreLabelSx,
  activityStatusSx,
} from './Participants.style';
import { DOWNLOAD_LINK } from 'theme/GlobalColors';

export const LIST_CONSTANTS = {
  expires: 'Expires: ',
};

export const PARTICIPANT_TYPES = {
  owner: 'PARTICIPANT_OWNER',
  contributor: 'PARTICIPANT_CONTRIBUTOR',
};

export const listingParticipantsColumn = {
  participantId: {
    label: 'participantId',
    field: 'participantId',
  },
  participant: {
    label: 'Name',
    field: 'participantfullname',
  },
  status: {
    label: 'Activity - status',
    field: 'status',
  },
  contact: {
    label: 'Contact',
    field: 'participantEmail',
  },
  addedOn: {
    label: 'Added On',
    field: 'createdDate',
  },
  lastUpdated: {
    label: 'Last updated',
    field: 'lastUpdatedDate',
  },
  contracts: {
    label: 'Contract',
    field: 'contracts',
  },
  accountStatus: {
    label: 'Account status',
    field: 'accountStatus',
  },
};
export const SortColValues = {
  participantFirstName: listingParticipantsColumn.participant.field,
  participantEmail: listingParticipantsColumn.contact.field,
  addedOn: listingParticipantsColumn.addedOn.field,
};

export const LABELS = {
  delete_message: 'Are you sure you want to remove from ?',
  addNewParticipant: 'Add participant',
  noDataContent: 'Looks like you haven’t added any participants yet.',
  addNewProjectLabel: 'Add a new participant to get started!',
  enrolledStatus: 'Enrolled',
  projectParticipants: 'Project participants',
  enterBaselineLabel: 'Initiate baseline reporting',
  stakeholder: 'Stakeholders',
  projects: 'Projects',
  type: 'Participant Owner',
  requestEmailSuccessMsg: 'Email sent successfully',
  requestEmailErrorMsg:
    'Unable to send email due to technical error. Please try again.',
  addContact: 'Add contract',
  reviewEnrollmentLabel: 'Review enrollment',
  reviewBaselineLabel: 'Review baseline reporting',
  reviewActivityLabel: 'Review activity reporting',
  searchBoxPlaceholder: 'Search by participant name or email',
  filter_content_width: '75%',
  filter_content: 'We couldn’t find any results for the participant',
  search_text_suffix: 'Please try another search.',
  baseline_pdf_title: 'Baseline reporting review',
  enrollment_pdf_title: 'Enrollment review',
  pdf_project_title: 'Project',
  pdf_participant_owner_name: 'Owner',
  enrollment_review_tab: 'Enrollment responses',
  baseline_review_tab: 'Operations data',
  activity_review_tab: 'Proof of implementation',
  reopen_and_approve_enrollment: 'Reopen on behalf and approve enrollment',
  reopen_and_approve_activity:
    'Reopen on behalf and approve activity reporting',
  contributor: 'Participant Contributor',
  participant_contributor: 'PARTICIPANT_CONTRIBUTOR',
  activity_reporting_review_title: 'Review activity reporting',
  account_status: 'Account Status',
  activity_status: 'Activity Status',
};

export const org_ProjectDetails = {
  projectName: 'project p',
  lastUpdatedOn: '2023-05-03T13:26:38.893+00:00',
  projectTags: [
    {
      projectTagId: 1,
      internalProjectId: 0,
      tagName: 'Tag1',
      createdDate: null,
      updatedDate: null,
    },
    {
      projectTagId: 2,
      internalProjectId: 0,
      tagName: 'Tag2',
      createdDate: null,
      updatedDate: null,
    },
  ],
  participantList: {
    content: [
      {
        participantId: '39',
        farmId: 0,
        participantFirstName: 'Ria',
        participantLastName: 'Cho',
        participantEmail: 'test2@com',
        clientId: 100,
        createdDate: '2023-05-04T10:46:38.339+00:00',
        updatedDate: null,
        participantfullname: 'Ria Cho',
      },

      {
        participantId: '38',
        farmId: 0,
        participantFirstName: 'Johnss',
        participantLastName: 'Doe',
        participantEmail: 'test1@com',
        clientId: 100,
        createdDate: '2023-05-04T07:27:12.683+00:00',
        updatedDate: null,
        participantfullname: 'Johnss Doe',
      },
    ],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageSize: 5,
      pageNumber: 0,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 1,
    totalElements: 2000,
    last: true,
    numberOfElements: 2,
    size: 5,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    number: 0,
    first: true,
    empty: false,
  },
};
export const PARTICIPANT_TABS = {
  summary: 'Summary',
  profile: 'Profile',
  activity: 'Activity',
};
export const PARTICIPANT_PATH = '/participant/';
export const PARTICIPANT_PARENT_PATH = '/stakeholders';
export const ALL_PROJECTS_PATH = '/projects';
export const latLongArray = [
  { lat: 33.7485, lng: -84.389302 },
  { lat: 33.748569, lng: -84.391621 },
  { lat: 33.750273, lng: -84.391803 },
  { lat: 33.750788, lng: -84.389882 },
];

export const baselineModalPropsInit = {
  open: false,
  navigateLink: null,
  navigateState: null,
  participantName: null,
};

export const displayToast = (type, message) => {
  return toast(<CustomSnackbar type={type} message={message} />, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const initialReviewEnrollmentProp = {
  title: 'Review enrollment',
  contentText:
    'Please access the enrollment responses to review and make your approval decision once complete.',
  firstSubmitBtnLabel: 'Approve enrollment',
  secondSubmitBtnLabel: 'Reopen enrollment',
  cancelBtnLabel: 'Cancel',
  firstSubmitBtnHandler: () => {},
  secondSubmitBtnHandler: () => {},
  cancelHandler: () => {},
  modalCloseHandler: () => {},
  modalData: { flag: false },
};

export const getActionIdFromActionCode = (actionCode, statusMappingList) => {
  return statusMappingList.find((data) => data.actionCode === actionCode)
    ?.actionId;
};

export const INITIATE_ACTIVITY_REPORTING_INITIALSTATE = {
  flag: false,
  id: 0,
  enrollmentInstanceId: null,
  projectId: 0,
  participantId: 0,
  participantName: '',
  navigateLink: null,
  navigateState: null,
};

export const enrollmentApproveAndReopenBaselineContent = {
  title: 'Reopen baseline reporting and approve enrollment',
  emailPlaceholder: (companyName, projectName) =>
    `Your baseline reporting for ${companyName}'s ${projectName} project has been reopened. This was done in order to amend the baseline reporting with the latest approved enrollment instance. You may access your baseline reporting with the link below and submit once you’ve completed adding the additional required information amended from the new enrollment instance.`,
  additionalContextText:
    'You currently have baseline reporting in review. In order to approve this enrollment instance you must reopen baseline reporting. This will amend the baseline reporting with the new enrollment instance.',
};

export const getActivityId = (id, activityData) => {
  return activityData?.[0]?.activityList.find((item) => item.formId === id)
    ?.enrollmentId;
};

export const getActivityType = (id, activityData) => {
  return activityData?.[0]?.activityList.find((item) => item.formId === id)
    ?.activityType;
};

export const getActivityDate = (id, activityData) => {
  return activityData?.[0]?.activityList.find((item) => item.formId === id)
    ?.activityDate;
};

export const getReviewTabName = (title) => {
  if (title === LABELS.reviewEnrollmentLabel)
    return LABELS.enrollment_review_tab;
  else if (title === LABELS.reviewBaselineLabel)
    return LABELS.baseline_review_tab;
  else if (title === LABELS.reviewActivityLabel)
    return LABELS.baseline_review_tab;
};

export const contributorTableColumns = {
  participantId: {
    label: 'participantId',
    field: 'participantId',
  },
  participantName: {
    label: 'Name',
    field: 'participantfullname',
  },
  accountStatus: {
    label: 'Account status',
    field: 'accountStatus',
  },
  options: {
    label: '',
    field: 'options',
  },
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'Interested':
      return <NotStartedIcon />;
    case 'Not started':
      return <NotStartedIcon />;
    case 'Requested':
      return <InProgressIcon />;
    case 'In progress':
      return <InProgressIcon />;
    case 'Submitted':
      return <InProgressIcon />;
    case 'Contract added':
      return <CompletedIcon />;
    case 'Approved':
      return <CompletedIcon />;
    case 'In review':
      return <ReviewIcon />;
    default:
      return '';
  }
};

export const getParticipantStatus = (statusMappingList, instanceData) => {
  const enrollmentStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.enrollmentActionId,
  );
  const baselineStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.baselineActionId,
  );
  const activityStatusValue = statusMappingList.find(
    (data) => data.actionId === instanceData.activityActionId,
  );

  return (
    <div className={activityStatusSx}>
      {enrollmentStatusValue && instanceData.enrollmentActionId != 12 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
          {getStatusIcon(enrollmentStatusValue?.actionStatus)}
          {`${enrollmentStatusValue?.phase}-${enrollmentStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>
            {instanceData.enrollmentActionId <= 3
              ? ''
              : `  ${
                  instanceData.enrollmentArea === null
                    ? 0
                    : instanceData.enrollmentArea
                } acres`}
          </span>
        </TextWrapper>
      )}
      {instanceData.enrollmentActionId === 12 &&
        instanceData.baselineActionId <= 12 && (
          <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
            {getStatusIcon('Interested')}
            {`Baseline Reporting-Not Started`}
            <span style={acreLabelSx}>{` ${
              instanceData.baselineArea === null ? 0 : instanceData.baselineArea
            } acres`}</span>
          </TextWrapper>
        )}
      {baselineStatusValue && instanceData.baselineActionId != 22 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
          {getStatusIcon(baselineStatusValue?.actionStatus)}
          {`${baselineStatusValue?.phase}-${baselineStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.baselineArea === null ? 0 : instanceData.baselineArea
          } acres`}</span>
        </TextWrapper>
      )}
      {instanceData.baselineActionId === 22 &&
        instanceData.activityActionId <= 22 && (
          <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
            {getStatusIcon('Interested')}
            {`Activity Reporting-Not Started`}
            <span style={acreLabelSx}>{` ${
              instanceData.activityArea === null ? 0 : instanceData.activityArea
            } acres`}</span>
          </TextWrapper>
        )}
      {activityStatusValue && instanceData.activityActionId != 32 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
          {getStatusIcon(activityStatusValue?.actionStatus)}
          {`${activityStatusValue?.phase}-${activityStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.activityArea === null ? 0 : instanceData.activityArea
          } acres`}</span>
        </TextWrapper>
      )}
      {activityStatusValue && instanceData.activityActionId === 32 && (
        <TextWrapper fontSize="0.9rem" fontWeight={400} color={DOWNLOAD_LINK}>
          {getStatusIcon(activityStatusValue?.actionStatus)}
          {`${activityStatusValue?.phase}-${activityStatusValue?.actionStatus}`}
          <span style={acreLabelSx}>{` ${
            instanceData.activityArea === null ? 0 : instanceData.activityArea
          } acres`}</span>
        </TextWrapper>
      )}
    </div>
  );
};

export const getParticipantAccountStatus = (status) => {
  switch (status) {
    case 'NOT_STARTED':
      return 'Not started';
    case 'REQUEST_ACTIVATION':
      return 'Request for activation';
    case 'ACTIVATED':
      return 'Activated';
    case 'REQUEST_DEACTIVATION':
      return 'Request for deactivation';
    case 'DEACTIVATED':
      return 'Deactivated';
    default:
      return '';
  }
};

export const getParticipantAccountStatusCode = (statusString) => {
  switch (statusString) {
    case 'Not started':
      return 'NOT_STARTED';
    case 'Request for activation':
      return 'REQUEST_ACTIVATION';
    case 'Activated':
      return 'ACTIVATED';
    case 'Request for deactivation':
      return 'REQUEST_DEACTIVATION';
    case 'Deactivated':
      return 'DEACTIVATED';
    default:
      return '';
  }
};
