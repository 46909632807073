import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  QUILL_GRAY,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-basis: ${({ flexBasis }) => flexBasis};
  height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ hasBoxShadow }) =>
    checkTernaryCondition(
      hasBoxShadow,
      '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
      'none',
    )};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap || '1rem'};
  border-radius: ${({ borderRadius }) => borderRadius};
  justify-content: ${({ justifyContent }) => justifyContent};
  border-bottom: ${({ hasBorder }) => hasBorder && `1px solid ${QUILL_GRAY}`};
`;
export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  color: ${({ color }) => color || DARK_CHARCOAL};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ width }) => width};
`;
export const ToolTipIconSx = (verticalAlign, marginLeft) => {
  return {
    height: '1rem',
    width: '1rem',
    cursor: 'pointer',
    color: COOL_GRAY,
    marginLeft: marginLeft,
    verticalAlign: verticalAlign,
  };
};

export const InfoWindowHeader = styled.div`
  color: ${GLOBAL_BLUE};
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0.25rem;
  max-width: 13.125rem;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const InfoWindowContent = styled.div`
  display: inline-flex;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  width: 18.5rem;
  & > :nth-child(2) {
    font-weight: 600;
    width: 40%;
  }
`;

export const mapStyle = { width: '100%', height: '36.25rem' };
