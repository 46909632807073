import React, { useEffect, useState, useMemo, useContext } from 'react';
import _ from 'lodash';
import { COOL_GRAY, GRAYISH_BLUE, WHITE } from 'theme/GlobalColors';
import {
  PAGE_CONTENT,
  createBasicActivity,
  getActivityId,
  getContractedInterestedRow,
  getRecruitmentInterestedRow,
  getSortedActivities,
  getStatusDateValue,
  getStatusIcon,
} from './ParticipantActivity.content';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  AcreageWrapper,
  ActivityMainWrapper,
  BackPage,
  ProjectName,
  StatusDate,
  StatusIcon,
  StatusText,
  StatusWrapper,
  dataGridSx,
} from './ParticipantActivity.style';
import propTypes from 'prop-types';
import { Box } from '@mui/material';
import axios from 'axios';
import {
  FETCH_ACTIVITY_TAB_DATA,
  FETCH_ALL_USER_ACTIONS,
  FETCH_PROJECT_LIFECYCLES,
} from '../../../../../urls';
import { checkTernaryCondition, omit } from 'utils/helper';
import { convertDateFormat } from '../../../../../utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useInterval } from 'hooks/useInterval';
import ViewDetailedAuditLog from '../../../../../components/ViewDetailedAuditLog/ViewDetailedAuditLog.react';
import { TextWrapper } from '../Participants.style';
import { ParticipantActivityProvider } from 'contextAPI/participantActivityContext';
import { getActionIdFromActionCode } from '../Participants.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import ActivityProjectHeader from './ActivityProjectHeader.react';
import { ActivityListContainer } from 'containers/ActivityListContainer';
import { DataGrid } from '@mui/x-data-grid';
import { ConfigurationContext } from 'contextAPI/configurationContext';

const ParticipantActivity = ({
  participantName = '',
  participantId,
  projectData,
  disableVirtualization = false,
}) => {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailedLog, setDetailedLog] = useState({
    projectId: 0,
    projectOpen: false,
    projectName: '',
  });
  const [statusMapList, setStatusMapList] = useState([]);
  const [projectCycleList, setProjectCycleList] = useState([]);
  const [projectCycleId, setProjectCycleId] = useState(-1);
  const { areUiActionInProgress } = useContext(ConfigurationContext);
  const [shouldNotRefresh, setShouldNotRefresh] = useState(false);

  const fetchProjectCycles = () => {
    axios
      .get(FETCH_PROJECT_LIFECYCLES, {
        params: {
          internalProjectId: projectData?.internalProjectId,
          participantId: participantId,
        },
      })
      .then((response) => {
        const projectCycleList = [response.data[0]];
        setProjectCycleList(
          projectCycleList.map((data) => {
            const endYear = new Date(data.cycleEndDate).getFullYear();
            return {
              label: `${endYear} (${convertDateFormat(
                data.cycleStartDate,
              )} - ${convertDateFormat(data.cycleEndDate)})`,
              value: data.projectCycleId,
            };
          }),
        );
        setProjectCycleId(response.data[0].projectCycleId);
      });
  };

  const fetchAllUserActions = () => {
    statusMapList.length === 0 &&
      axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
        setStatusMapList(response.data);
      });
  };

  const breadcrumbs = useMemo(
    () => [
      <BackPage
        key="breadcrumb-1"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          setDetailedLog({
            projectId: 0,
            projectOpen: false,
            projectName: '',
          })
        }>
        {PAGE_CONTENT.activity}
      </BackPage>,
      <ProjectName key="breadcrumb-2">
        {`${detailedLog?.projectName} ${PAGE_CONTENT.detailedLog}`}
      </ProjectName>,
    ],
    [detailedLog?.projectName],
  );

  const getBaselineActivityId = (
    activityMainData,
    activityId,
    contractStartDate,
  ) => {
    let uniqueBaselineListIds = [];
    activityMainData.forEach((activity) => {
      if (activity.enrollmentInstancesForBaseline) {
        activity.enrollmentInstancesForBaseline.forEach((instance) => {
          !uniqueBaselineListIds.find((ids) => ids === instance) &&
            uniqueBaselineListIds.push({
              id: activity.activityId,
              updatedEnrollmentInstancesForBaseline: instance,
            });
        });
      }
    });
    uniqueBaselineListIds = uniqueBaselineListIds
      .sort(
        (a, b) =>
          a.updatedEnrollmentInstancesForBaseline -
          b.updatedEnrollmentInstancesForBaseline,
      )
      .map((data, index) => ({
        id: data.id,
        updatedEnrollmentInstancesForBaseline: index + 1,
      }));

    const year = new Date(contractStartDate).getFullYear();
    uniqueBaselineListIds = uniqueBaselineListIds
      .filter((data) => data.id === activityId)
      .map(
        (instance) =>
          `${year}-${instance.updatedEnrollmentInstancesForBaseline}`,
      );
    return uniqueBaselineListIds.join(', ');
  };

  const createActivityDataObject = (activityMainData) => {
    const enrollmentActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.enrollment,
      'activityId',
    );

    const baselineActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.baseline_reporting,
      'activityId',
    );
    const activityActivities = getSortedActivities(
      activityMainData.activityTabActivitiesList,
      PAGE_CONTENT.activity_reporting,
      'activityId',
    );
    const acitivtiesList = [
      ...enrollmentActivities,
      ...baselineActivities,
      ...activityActivities,
    ];
    let additionalEmptyActivity = [];
    if (
      !acitivtiesList.some(
        (activity) => activity.activityType === PAGE_CONTENT.baseline_reporting,
      )
    ) {
      const activityId = acitivtiesList.find(
        (activity) => activity.activityType === PAGE_CONTENT.baseline_reporting,
      )?.activityId;

      additionalEmptyActivity.push(
        createBasicActivity({
          id: uniqueId(),
          activityType: PAGE_CONTENT.baseline_reporting,
          actionId: 13,
          activityDate: '',
          associatedContractId: activityMainData.contractId,
          enrollmentId: null,
          formId: activityId,
          statusMapList: statusMapList,
        }),
      );
    }
    if (
      !acitivtiesList.some(
        (activity) => activity.activityType === PAGE_CONTENT.activity_reporting,
      )
    ) {
      const activityId = acitivtiesList.find(
        (activity) => activity.activityType === PAGE_CONTENT.activity_reporting,
      )?.activityId;

      additionalEmptyActivity.push(
        createBasicActivity({
          id: uniqueId(),
          activityType: PAGE_CONTENT.activity_reporting,
          actionId: 23,
          activityDate: '',
          statusMapList: statusMapList,
          associatedContractId: activityMainData.contractId,
          enrollmentId: null,
          formId: activityId,
        }),
      );
    }

    return [
      ...acitivtiesList.map((activityData, index) => ({
        id: uniqueId(),
        activityType: activityData.activityType,
        activityId: checkTernaryCondition(
          activityData.activityType === 'Baseline reporting' ||
            activityData.activityType === 'Activity reporting',
          getBaselineActivityId(
            checkTernaryCondition(
              activityData.activityType === 'Baseline reporting',
              activityMainData.activityTabActivitiesList.filter(
                (activity) => activity.activityType === 'Baseline reporting',
              ),
              activityMainData.activityTabActivitiesList.filter(
                (activity) => activity.activityType === 'Activity reporting',
              ),
            ),
            activityData.activityId,
            activityMainData.contractStartDate,
          ),
          getActivityId(
            index + 1,
            activityMainData.contractStartDate,
            activityData.enrollmentInstancesForBaseline,
          ),
        ),
        associatedContractId: activityMainData.contractId,
        enrollmentId: activityData.activityId,
        formId: activityData.activityId,
        area: checkTernaryCondition(
          activityData.area === null,
          '-',
          `${activityData.area} acres`,
        ),
        status: statusMapList.find(
          (status) => status.actionId === activityData.actionId,
        )?.actionStatus,
        actionId: activityData.actionId,
        enrollmentInstancesForBaseline: null,
        activityDate: convertDateFormat(activityData.activityDate),
      })),
      ...additionalEmptyActivity,
    ];
  };

  const fetchActivityData = (projectId, isCalledOverIntervals = false) => {
    !isCalledOverIntervals && setLoading(true);
    areUiActionInProgress === 0 &&
      axios
        .get(FETCH_ACTIVITY_TAB_DATA, {
          params: {
            internalProjectId: projectId,
            participantId: participantId,
          },
        })
        .then((res) => {
          const data = res.data.activityTabContractLevelList || [];
          let newActivityData = [];
          const isRequestEnrollmentEnabled = data[0]?.activityTabActivitiesList
            ?.filter((data) => data.activityType === PAGE_CONTENT.enrollment)
            .every(
              (activitydata) =>
                activitydata.actionId ===
                getActionIdFromActionCode('ENROLL-APPROVE', statusMapList),
            );
          if (data.length === 0) {
            newActivityData = [
              {
                id: uniqueId(),
                contractId: null,
                contractStartDate: '',
                contractEndDate: '',
                activityList: [
                  getRecruitmentInterestedRow(
                    statusMapList,
                    convertDateFormat(res.data.participantInterestedDate),
                    res.data.totalPlannedAcres,
                    checkTernaryCondition(
                      res.data.totalPlannedAcres !== null &&
                        res.data.totalPlannedAcres >= 0,
                      PAGE_CONTENT.interested,
                      null,
                    ),
                  ),
                ],
              },
              {
                id: uniqueId(),
                contractId: null,
                contractStartDate: '',
                contractEndDate: '',
                activityList: [
                  getContractedInterestedRow(
                    statusMapList,
                    convertDateFormat(res.data.participantInterestedDate),
                  ),
                ],
              },
            ];
          } else {
            newActivityData = data.map((activity) => ({
              contractId: activity.contractId,
              contractStartDate: convertDateFormat(activity.contractStartDate),
              contractEndDate: convertDateFormat(activity.contractEndDate),
              activityList: [
                getRecruitmentInterestedRow(
                  statusMapList,
                  convertDateFormat(res.data.participantInterestedDate),
                  res.data.totalPlannedAcres,
                  checkTernaryCondition(
                    activity.contractAcres,
                    PAGE_CONTENT.contract_added,
                    checkTernaryCondition(
                      res.data.totalPlannedAcres,
                      PAGE_CONTENT.interested,
                      null,
                    ),
                  ),
                ),
                {
                  id: uniqueId(),
                  activityType: PAGE_CONTENT.contracting,
                  activityId: '',
                  area: activity.contractAcres,
                  enrollmentId: null,
                  formId: null,
                  status: statusMapList.find((status) => status.actionId === 2)
                    ?.actionStatus,
                  associatedContractId: activity.contractId,
                  enrollmentInstancesForBaseline: null,
                  activityDate: convertDateFormat(activity.contractCreatedDate),
                  contractPdfUrl: activity.contractPdfUrl,
                  isRequestEnrollmentEnabled: isRequestEnrollmentEnabled,
                  activitySubType: PAGE_CONTENT.contract_added,
                },
                ...createActivityDataObject(activity),
              ],
            }));
          }
          if (isActivityDifferent(newActivityData)) {
            newActivityData.map((activityData) => {
              const activityList = activityData.activityList;
              const recruitmentActivity = activityList.find(
                ({ activityType }) => {
                  return activityType === PAGE_CONTENT.contracting;
                },
              );
              if (recruitmentActivity && isRequestEnrollmentEnabled) {
                recruitmentActivity.isRequestEnrollmentEnabled =
                  isRequestEnrollmentEnabled;
              }
            });
            setActivityData(newActivityData);
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const isActivityDifferent = (newActivityData) => {
    return !_.isEqual(
      omit(_.cloneDeep(activityData), [
        'id',
        'contractPdfUrl',
        'isRequestEnrollmentEnabled',
      ]),
      omit(_.cloneDeep(newActivityData), [
        'id',
        'contractPdfUrl',
        'isRequestEnrollmentEnabled',
      ]),
    );
  };

  const isFetchFunctionCallable = statusMapList.length > 0;

  useEffect(() => {
    if (isFetchFunctionCallable) {
      fetchActivityData(projectData?.internalProjectId);
    }
  }, [statusMapList]);

  useInterval(async () => {
    if (isFetchFunctionCallable && !shouldNotRefresh) {
      fetchActivityData(projectData.internalProjectId, true);
    }
  }, 5000);

  const columns = [
    { field: 'id', headerName: '', hide: true, flex: 0 },
    {
      field: 'activityType',
      headerName: 'Phase',
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 220,
    },
    {
      field: 'activityId',
      headerName: 'Batch ID',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: 'area',
      headerName: 'Total acres',
      flex: 1,
      minWidth: 240,
      renderCell: (params) => {
        checkTernaryCondition(
          params.row.activitySubType === PAGE_CONTENT.not_started,
          checkTernaryCondition(
            params.row.activityType === PAGE_CONTENT.outreach,
            <AcreageWrapper>{PAGE_CONTENT.interested_acreage}</AcreageWrapper>,
            <AcreageWrapper>{PAGE_CONTENT.contract_acreage}</AcreageWrapper>,
          ),
          params.row.area,
        );
      },
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      minWidth: 336,
      renderCell: (params) => (
        <StatusWrapper>
          <StatusIcon>{getStatusIcon(params.row.status)}</StatusIcon>
          <StatusText>{params.row.status}</StatusText>
          <StatusDate>
            {getStatusDateValue(params.row.activityDate, params.row.status)}
          </StatusDate>
        </StatusWrapper>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'options',
      headerName: 'Action',
      flex: 0.3,
      minWidth: 70,
      align: 'center',
      renderCell: (params) => (
        <div data-testid={`options-${params.row?.id}`}>
          <ActivityListContainer
            setShouldNotRefresh={setShouldNotRefresh}
            activity={params.row}
            status={params.row.status}
            activityType={params.row.activityType}
            activityDataList={
              activityData[1]
                ? [
                    ...(activityData[0]?.activityList || []),
                    ...(activityData[1]?.activityList || []),
                  ]
                : [...(activityData[0]?.activityList || [])]
            }
            statusMappingList={statusMapList}
            activitySubType={params.row.activitySubType}
            projectCycleId={projectCycleId}
            area={params.row.area}
          />
        </div>
      ),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    fetchAllUserActions();
    fetchProjectCycles();
  }, []);

  const projectCycleDropdownProps = {
    labelMarginTop: '1rem',
    field_key: '',
    label: '',
    value:
      projectCycleId === -1
        ? PAGE_CONTENT.project_cycle_dropdown_placeholder
        : projectCycleId,
    isDisabled: false,
    stateValue: projectCycleId,
    setStateValue: () => {},
    setFormFieldValue: () => {},
    dropDownMinWidth: '200px',
    height: '1.75rem',
    name: '',
    displayEmpty: true,
    showLabelAsValue: true,
    hasNoBottomMargin: true,
    customSx: {
      backgroundColor: WHITE,
      '& .MuiSelect-select': {
        padding: '0.375rem 0.5rem',
        '&.MuiSelect-outlined': {
          paddingRight: '0px',
        },
      },
    },
    fontSize: '0.75rem',
    valueHeight: '1rem',
    ariaLabel: '',
    dropdownlist: projectCycleList,
    dropDownPlaceholder: PAGE_CONTENT.project_cycle_dropdown_placeholder,
    error: false,
    errorMessage: '',
    moduleError: false,
    onUpdate: (e) => {
      setProjectCycleId(e.target.value);
    },
    customPlaceholderColor: COOL_GRAY,
    defaultDropdownColor: GRAYISH_BLUE,
  };

  return detailedLog.projectId && detailedLog.projectOpen ? (
    <ActivityMainWrapper>
      <TextWrapper>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </TextWrapper>
      <ViewDetailedAuditLog
        statusMapList={statusMapList}
        projectCycleId={projectCycleId}
        participantId={participantId}
        projectId={detailedLog.projectId}
      />
    </ActivityMainWrapper>
  ) : (
    <ActivityMainWrapper>
      <DropDown {...projectCycleDropdownProps} />
      <ParticipantActivityProvider
        projectData={{ ...projectData, id: projectData?.internalProjectId }}
        participantName={participantName}
        activityData={activityData}
        participantId={participantId}>
        <div>
          <ActivityProjectHeader
            projectDetails={{
              ...projectData,
              projectCycleData: projectCycleList.find(
                (cycle) => cycle.value === projectCycleId,
              ),
            }}
            setDetailedLog={setDetailedLog}
          />
          <Box
            sx={{
              width: '100%',
            }}>
            <DataGrid
              rows={
                activityData[1]
                  ? [
                      ...(activityData[0]?.activityList || []),
                      ...(activityData[1]?.activityList || []),
                    ]
                  : [...(activityData[0]?.activityList || [])]
              }
              disableVirtualization={disableVirtualization}
              loading={loading}
              autoHeight
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              hideFooter
              sx={dataGridSx}
              disableSelectionOnClick
            />
          </Box>
        </div>
      </ParticipantActivityProvider>
    </ActivityMainWrapper>
  );
};

ParticipantActivity.propTypes = {
  participantName: propTypes.string,
  participantId: propTypes.string,
  projectData: propTypes.object,
  disableVirtualization: propTypes.bool,
};

export default ParticipantActivity;
