import { NewColorThemeProvider } from 'contextAPI/newColorThemeContext';

import { CONTENT } from './Reports.content';
import { HeaderWrapper } from './Reports.styles';
import OriginationProjectReportsList from 'pages/Origination/OriginationProjectReportsList/OriginationProjectReportsList.react';

const Reports = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <HeaderWrapper>{CONTENT.reports}</HeaderWrapper>
      <NewColorThemeProvider>
        <OriginationProjectReportsList />
      </NewColorThemeProvider>
    </div>
  );
};

export default Reports;
