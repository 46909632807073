import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { BRIGHT_GRAY, WHITE } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { ReactComponent as CollapsedCalculationIcon } from '../../../assets/icons/CollapsedCalculation.svg';
import { ReactComponent as ExpandedCalculationIcon } from '../../../assets/icons/ExpandedCalculation.svg';
import {
  AccordionDetailsSx,
  AccordionSummarySx,
  AccordionSx,
  ArrowIconWrapper,
  CCandMTColumnSection,
  CCandMTSection,
  CalculationWrapper,
  ColumnHeaderWrapper,
  ExpandCalcViewWrapper,
  ExpandCalcWrapper,
  NestedAccordionWrapper,
  RowValueSection,
  RowValueWrapper,
  TablePaginationBoxSx,
  TableRowWrapper,
  ToolTipIconSx,
} from './CalculationReport.style';
import {
  PROJECT_CALC_REPORT_CONTENT,
  PROJECT_CALC_REPORT_LIST_COLUMN,
  getDetailedCalculationPanelValues,
} from './CalculationReport.content';
import LightTooltip from 'components/LightTooltip';
import { customTitle } from 'components/FormComponents/InfoTooltip/InfoToolTip.content';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularLoader from 'components/CircularLoader/CircularLoader.react';
import DetailedCalculationPanel from 'components/DetailedCalculationPanel/DetailedCalculationPanel.react';
import { DEFAULT_PAGINATION_OPTIONS, LABEL_ROWS_PER_PAGES } from 'utils/config';
import { projectCalculationContext } from 'contextAPI/projectCalculationContext';
import CalculationNoResultScreen from 'components/CalculationNoResultScreen/CalculationNoResultScreen.react';

const NestedAccordions = ({
  data,
  accordionColor,
  currentId,
  deltatotal,
  baselinetotal,
  projecttotal,
  outputLabel,
  extractTotalMT = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [expandCalc, setExpandCalc] = useState(false);
  const { calculationToggle, setTotalMT } = useContext(
    projectCalculationContext,
  );

  const handleNestedAccordionToggle = (event) => {
    setExpanded(!expanded);
    if (expandCalc) setExpandCalc(!expandCalc);
    event.stopPropagation();
    if (extractTotalMT) {
      setTotalMT(displayCalcValue());
    }
  };

  const handleNestedCalcToggle = (event) => {
    setExpandCalc(!expandCalc);
    setExpanded(true);
    event.stopPropagation();
    if (extractTotalMT) {
      setTotalMT(displayCalcValue());
    }
  };

  const displayCalcValue = () => {
    if (calculationToggle.baseline && calculationToggle.project) {
      return deltatotal;
    } else if (calculationToggle.baseline && !calculationToggle.project) {
      return baselinetotal;
    } else if (!calculationToggle.baseline && calculationToggle.project) {
      return projecttotal;
    }
  };

  const handleArrowIcon = (arrowState, currentId) => {
    return (
      <div
        onClick={handleNestedAccordionToggle}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleNestedAccordionToggle(event);
          }
        }}
        data-testid={`${PROJECT_CALC_REPORT_CONTENT.arrow_icon}${currentId}`}>
        {checkTernaryCondition(
          arrowState,
          <KeyboardArrowDownIcon />,
          <KeyboardArrowRightIcon />,
        )}
      </div>
    );
  };

  const handleCalculationIcon = (calcState, currentId) => {
    return (
      <div
        onClick={handleNestedCalcToggle}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleNestedCalcToggle(event);
          }
        }}
        data-testid={`${PROJECT_CALC_REPORT_CONTENT.calc_icon}${currentId}`}>
        {checkTernaryCondition(
          calcState,
          <ExpandedCalculationIcon />,
          <CollapsedCalculationIcon />,
        )}
      </div>
    );
  };

  return (
    <Accordion expanded={expanded} sx={AccordionSx(accordionColor)}>
      <AccordionSummary sx={AccordionSummarySx}>
        <RowValueSection>
          <CalculationWrapper>
            <ArrowIconWrapper>
              {data !== undefined && handleArrowIcon(expanded, currentId)}
            </ArrowIconWrapper>
            {calculationToggle.baseline && calculationToggle.project && (
              <ExpandCalcWrapper>
                {handleCalculationIcon(expandCalc, currentId)}
              </ExpandCalcWrapper>
            )}
            <RowValueWrapper>{outputLabel}</RowValueWrapper>
          </CalculationWrapper>
          <CCandMTSection>
            <RowValueWrapper>
              {Number(displayCalcValue()?.toFixed(2))}
            </RowValueWrapper>
          </CCandMTSection>
        </RowValueSection>
      </AccordionSummary>
      <AccordionDetails sx={AccordionDetailsSx(data)}>
        {expandCalc &&
          calculationToggle.baseline &&
          calculationToggle.project && (
            <ExpandCalcViewWrapper>
              <DetailedCalculationPanel
                data={getDetailedCalculationPanelValues(
                  baselinetotal,
                  projecttotal,
                  deltatotal,
                )}
              />
            </ExpandCalcViewWrapper>
          )}
        {data?.map((item) => {
          return (
            <NestedAccordions
              key={item?.childid}
              accordionColor={BRIGHT_GRAY}
              data={item?.children}
              currentId={item?.childid}
              deltatotal={item?.deltatotal}
              baselinetotal={item?.baselinetotal}
              projecttotal={item?.projecttotal}
              outputLabel={item?.outputlabel}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const NewCalculationReport = ({ calculationColumnTooltip, data }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    calcLoading,
    page,
    pageSize,
    totalElements,
    handleChangePage,
    handleChangeRowsPerPage,
    calculationLabels,
    calculationToggle,
  } = useContext(projectCalculationContext);

  useEffect(() => {
    if (data && calculationLabels?.length > 0) {
      setRows(data?.content);
    }
    setLoading(calcLoading);
  }, [data, calcLoading, calculationLabels]);

  return (
    <div>
      <TableRowWrapper
        background={WHITE}
        height={PROJECT_CALC_REPORT_CONTENT.three_point_five}>
        <ColumnHeaderWrapper
          marginLeft={PROJECT_CALC_REPORT_CONTENT.three_point_five}
          display={PROJECT_CALC_REPORT_CONTENT.flex}
          gap={PROJECT_CALC_REPORT_CONTENT.zero_point_five}>
          {PROJECT_CALC_REPORT_LIST_COLUMN.calculations}
          {calculationColumnTooltip && (
            <LightTooltip
              title={customTitle(
                calculationColumnTooltip?.tooltipTitle,
                calculationColumnTooltip?.tooltipContent,
              )}
              placement={PROJECT_CALC_REPORT_CONTENT.placement}
              arrow>
              <InfoOutlinedIcon
                sx={ToolTipIconSx}
                color={PROJECT_CALC_REPORT_CONTENT.disabled}
                data-testid={PROJECT_CALC_REPORT_CONTENT.tooltip}
              />
            </LightTooltip>
          )}
        </ColumnHeaderWrapper>
        <CCandMTColumnSection width={'auto'}>
          <ColumnHeaderWrapper>
            {PROJECT_CALC_REPORT_LIST_COLUMN.mtco2e}
          </ColumnHeaderWrapper>
        </CCandMTColumnSection>
      </TableRowWrapper>
      {checkTernaryCondition(
        !calculationToggle.baseline && !calculationToggle.project,
        <CalculationNoResultScreen
          noCalcDataTitle={PROJECT_CALC_REPORT_CONTENT.no_results_title}
          noCalcDataSubTitle={PROJECT_CALC_REPORT_CONTENT.no_results_content}
        />,
        <div>
          <NestedAccordionWrapper>
            {checkTernaryCondition(
              !loading,
              <>
                {rows.map((item) => {
                  const currentRow = JSON.parse(
                    item?.report?.replace(/\\/g, ''),
                  );
                  return (
                    <NestedAccordions
                      key={currentRow?.id}
                      data={currentRow.children}
                      currentId={currentRow?.id}
                      deltatotal={item?.deltaSum}
                      baselinetotal={item?.baselineSum}
                      projecttotal={item?.projectSum}
                      outputLabel={
                        calculationLabels.find(
                          (item) =>
                            item?.childApiLinkId === currentRow?.item_id,
                        )?.label
                      }
                      extractTotalMT={true}
                    />
                  );
                })}
              </>,
              <CircularLoader />,
            )}
          </NestedAccordionWrapper>
          <Box sx={TablePaginationBoxSx}>
            <TablePagination
              component={PROJECT_CALC_REPORT_CONTENT.div}
              count={totalElements}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={LABEL_ROWS_PER_PAGES}
            />
          </Box>
        </div>,
      )}
    </div>
  );
};

export default NewCalculationReport;
