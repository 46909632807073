import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  GREEN,
} from 'theme/GlobalColors';

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: 1rem;
`;
export const MainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.1rem;
`;
export const LastUpdatedDiv = styled.div`
  font-size: 1rem;
  color: ${COOL_GRAY};
`;
export const PageTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #313233;
`;

export const Chip = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.25rem;
  gap: 0.25rem;
  /* Text properties */
  color: #26890d;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 15px;
  align-items: center;
  background: white;
  border: 1px solid #26890d;
  border-radius: 4px;
  white-space: nowrap;
  /* Code optimization required */
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 5rem;
  height: 1rem;
`;
export const Tags = styled.div`
  background: #ffffff;
  border: 0.5px solid #63666a;
  border-radius: 0.25rem;
  color: #63666a;
  padding: 0.1rem 0.25rem;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 15px;
  max-width: 5rem;
  height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.3rem;
`;

export const CountWrpper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 4px;
  width: 21px;
  height: 16px;
  background: #ffffff;
  /* Primary/Jet - default body copy */
  border: 1px solid #333333;
  border-radius: 12px;
`;

export const FormBtnWrpper = styled.div`
  display: flex;
  justify-content: flex-end;

  :first-child {
    margin-right: 24px;
  }
`;

export const CustomCountBadge = styled.span`
  border: 1px solid #333333;
  padding: 0 4px;
  border-radius: 8px;
  background: #ffffff;
  align-items: 'center';
  margin-left: 0.25rem;
  font-size: 0.7rem;
  color: ${DARK_CHARCOAL};
`;
export const editButtonStyle = {
  textTransform: 'none',
  backgroundColor: GREEN,
  color: 'white',
  '&:hover': {
    backgroundColor: '#045206',
  },
};
export const generateReportButtonStyle = {
  textTransform: 'none',
  border: '1px solid #26890D',
  background: 'white',
  color: '#26890D',
  marginRight: '0.4rem',
};

export const ButtonDiv = styled.div`
  display: flex;
`;

export const breadcrumbStyles = {
  fontSize: '1.25rem',
  color: DARK_CHARCOAL,
  fontWeight: '400',
};

export const linkStyles = {
  textDecoration: 'none',
  color: GLOBAL_BLUE,
  fontFamily: 'Open Sans',
  fontSize: '20px',
  fontWeight: '400',
  lineHeight: '28px',
  textAlign: 'left',
};

export const projectNameStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '55rem',
};

export const TabPanelStyle = {
  'min-height': '70vh',
};

export const TabberWrapperStyle = { marginBottom: '1rem' };

export const breadcrumbSx = {
  '& .MuiBreadcrumbs-separator': {
    margin: 0,
  },
};
