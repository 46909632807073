import React from 'react';
import AuditTrailComp from 'containers/AuditTrailComp/AuditTrailComp.react';
import { PAGE_CONTENT, tabs } from './ApplicationAuditTrail.content';
import BackButton from 'components/Backbutton/BackButton.react';
import { useLocation } from 'react-router';
import { MainWrapper } from './ApplicationAuditTrail.style';

const ApplicationAuditTrail = () => {
  const { state } = useLocation();
  return (
    <MainWrapper data-testid={PAGE_CONTENT.audit_log}>
      <BackButton
        previouspagename={
          state?.previouspagename ? state?.previouspagename : tabs.portfolio
        }
        previouspath={
          state?.previousPath
            ? state?.previousPath
            : `/${tabs.portfolio.toLowerCase()}`
        }
        previousstate={
          state?.previousstate
            ? state?.previousstate
            : { state: `${tabs.portfolio.toLowerCase()}` }
        }
      />
      <AuditTrailComp categoryType={PAGE_CONTENT.application} recordId={-1} />
    </MainWrapper>
  );
};

export default ApplicationAuditTrail;
