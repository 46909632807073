import { checkAccess } from 'utils/helper';
import { useContext } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import AccessDeniedPage from 'pages/AccessDeniedPage/AccessDeniedPage.react';
import PropTypes from 'prop-types';
import {
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  contributorAccessRoutes,
} from 'utils/config';
import { Navigate } from 'react-router';

const ProtectedRoute = ({
  userPermissions,
  requiredPermissions,
  element,
  url,
}) => {
  const { setIsAccessDenied, isDataFetched, userRoles } =
    useContext(userAuthRolesContext);
  const hasRequiredPermission = requiredPermissions.some((permission) =>
    checkAccess(userPermissions, permission),
  );

  if (isDataFetched) {
    if (
      (userRoles.includes(PARTICIPANT_CONTRIBUTOR) ||
        userRoles.includes(PARTICIPANT)) &&
      !contributorAccessRoutes.includes(url)
    ) {
      return <Navigate replace to={'/stakeholders/landing-page'} />;
    }

    if (hasRequiredPermission) {
      setIsAccessDenied(false);
      return element;
    } else {
      setIsAccessDenied(true);
      return <AccessDeniedPage />;
    }
  }
};

ProtectedRoute.propTypes = {
  userPermissions: PropTypes.array,
  requiredPermissions: PropTypes.array,
  element: PropTypes.element,
  url: PropTypes.string,
};

export default ProtectedRoute;
