import React, { useContext } from 'react';
import { SideNavWrapper, NavContainer } from './Sidenav.style';
import NavModule from 'components/NavModule/NavModule.react';
import { navLinks } from './Sidenav.constants';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkAccess } from 'utils/helper';
import { ADMIN, ModulesEnableMapper, getIsModuleEnabled } from 'utils/config';

const Sidenav = () => {
  const { userRoles, modulesListData } = useContext(userAuthRolesContext);

  return (
    <SideNavWrapper data-testid="sidenav">
      <NavContainer>
        <NavModule
          navLinks={navLinks.originationModuleLinks.map((link) => ({
            ...link,
            disabled:
              userRoles.length !== 0 &&
              !checkAccess(userRoles, ADMIN) &&
              (link.title === 'Audit Log' || link.title === 'Settings'),
            isModuleEnabled: getIsModuleEnabled(
              ModulesEnableMapper[link.moduleKey],
              modulesListData,
            ),
          }))}
          hasNoBorder={true}
        />
      </NavContainer>
    </SideNavWrapper>
  );
};

export default Sidenav;
