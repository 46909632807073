export const MODAL_CONTENT = {
  heading: 'Tillage',
  tillagePracticeField: 'tillagePractice',
  tillagePracticePlaceholder: 'Select tillage practice',
  tillagePracticeLabel: 'Tillage practice',
  tillagePracticeErrorMsg: 'A tillage method selection is required.',
  customTillagePracticeField: 'customTillagePractice',
  customTillagePracticePlaceholder: 'Please specify tillage practice',
  customTillagePracticeErrorMsg:
    'Please specify a tillage type if other selected for tillage practice.',
  tillageDatesField: 'tillageDates',
  noTillageOption: 'No tillage',
  duplicate_date_error:
    'Date selected has already been applied. Please select a different date.',
  tillageDatePickerLabel: 'Tillage date(s)',
  tillageDatePickerPlaceholder: 'Select Date',
  tillageDatePickerErrorMsg: 'At least one tillage date is required.',
  addTillageDateLinkText: ' Tillage date',
  surfaceDisturbanceField: 'surfaceDisturbance',
  surfaceDisturbanceLabel: 'Surface disturbance',
  surfaceDisturbancePlaceholder: 'Type percentage',
  surfaceDisturbanceErrorMsg: 'An annual yield amount is required.',
  depthOfTillageField: 'depthOfTillage',
  depthOfTillageLabel: 'Depth of tillage',
  depthOfTillagePlaceholder: 'Type depth',
  depthOfTillageErrorMsg: 'Depth of tillage is required.',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  inch_unit: 'in',
  tillage_date_error:
    'Tillage date can only occur within the baseline year and up to 1 year prior.',
  invalid_date: 'Invalid date',
};

export const initialTillageModalData = {
  tillagePractice: '',
  customTillagePractice: '',
  tillageDates: [null],
  surfaceDisturbance: '',
  depthOfTillage: '',
};

export const initialTillageModalErrors = {
  tillagePractice: false,
  customTillagePractice: false,
  tillageDates: { flag: false, indexes: [] },
  surfaceDisturbance: false,
  depthOfTillage: false,
};
