import { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_PARTICIPANT_INFO } from 'urls';
import {
  checkTernaryCondition,
  validatePhone,
  onlyCharsRegex,
  getMailingAddress,
  extractAddrFromGoogleMapComponents,
  getCommaSeperatedStringValues,
  replaceSpecialCharactersWithSingleCharacter,
  formatPhoneNumber,
} from 'utils/helper';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  DialogStyle,
  DialogTitleStyle,
  TitleWrapper,
  IconButtonStyle,
  DialogActionStyle,
  ButtonStyle,
  DialogContentStyle,
  LocationFieldWrapper,
  InputWrapper,
  borderSx,
  communicationCheckboxPadding,
} from './EditParticipantProfile.style';
import { COOL_GRAY } from 'theme/GlobalColors';
import { useForm } from 'hooks/useForm';
import {
  PAGE_CONTENT,
  formModal,
  errorInitialState,
} from './EditParticipantProfile.content';
import { closeIconSx, modalActionButtonDefaultStyle } from 'theme/GlobalStyles';
import CloseIcon from '@mui/icons-material/Close';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import InputField from 'components/FormComponents/InputField/InputField.react';
import GoogleMapSearchAutocomplete from 'components/FormComponents/GoogleMapSearchAutocomplete/GoogleMapSearchAutocomplete.react';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { InputSx } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { dropdownInputSx } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import {
  STYLE_CONSTANTS,
  FALSE,
  getCommnicationMethodList,
  validateCommunicationMethod,
} from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { FETCH_COUNTY_LIST, FETCH_STATE_LIST, UPDATE_PARTICIPANT } from 'urls';
import PropTypes from 'prop-types';

const EditParticipantProfile = ({
  isModalOpen,
  modalCloseHandler,
  participantId,
  refreshHandler,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(errorInitialState);
  const [countyList, setCountyList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isFormEdited, setIsFormEdited] = useState(false);

  const hasErrors = Object.values(errorState).includes(true);

  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(formModal);

  useEffect(() => {
    if (formValue?.state !== '') {
      updateCountyList(
        stateList.find((state) => state.value === formValue?.state)?.stateId,
      );
    }
  }, [formValue.state]);

  useEffect(() => {
    if (isFormEdited) {
      setErrorState((prevError) => ({
        ...prevError,
        cityRequiredError: formValue.city.trim() === '',
        zipCodeRequiredError: formValue.zipCode.trim() === '',
        stateRequiredError: formValue.state.trim() === '',
        countyRequiredError: formValue.county.trim() === '',
      }));
    }
  }, [formValue.city, formValue.zipCode, formValue.state, formValue.county]);

  useEffect(() => {
    if (formValue.county !== '' && countyList.length > 0) {
      const countyValueNotMatching =
        countyList.findIndex((county) => county.label === formValue.county) ===
        -1;
      if (countyValueNotMatching) {
        setFormValue((prev) => ({ ...prev, county: '' }));
      }
    }
  }, [countyList]);

  useEffect(() => {
    if (isFormEdited) {
      setErrorState({
        ...errorState,
        communicationMethodRequiredError: !validateCommunicationMethod(
          formValue.communicationMethod,
        ),
      });
    }
  }, [formValue.communicationMethod]);

  useEffect(() => {
    fetchStateList().then((stateList) => setStateList(stateList));
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      fetchParticipantDetails();
    }

    return () => {
      setErrorState(errorInitialState);
      setIsFormEdited(false);
    };
  }, [isModalOpen]);

  const fieldHandler = (key, value) => {
    customFieldChangeHandler(key, value);
    setIsFormEdited(true);

    //this switch case is used to clear the error on typing valid data in the field
    switch (key) {
      case 'phone':
        setErrorState({
          ...errorState,
          phoneRequiredError: value.trim() === '',
          inValidPhoneError:
            !validatePhone(
              replaceSpecialCharactersWithSingleCharacter(
                /[()\-\s]/g,
                value,
                '',
              ),
            ) && FALSE,
        });
        break;
      case 'mailingAddress':
        setErrorState({
          ...errorState,
          mailingAddressRequiredError: value.trim() === '',
        });
        break;
      case 'city':
        setErrorState({
          ...errorState,
          cityRequiredError: value.trim() === '',
        });
        break;
      case 'zipCode':
        setErrorState({
          ...errorState,
          zipCodeRequiredError: value.trim() === '',
        });
        break;
    }
  };

  const fetchParticipantDetails = () => {
    setLoading(true);
    axios
      .post(`${GET_PARTICIPANT_INFO}`, {
        enrolmentType: 'Baseline',
        pageNumber: 0,
        pageSize: 10,
        participantId: participantId,
      })
      .then((response) => {
        if (response.data) {
          const {
            firstName,
            lastName,
            email,
            phone,
            mailingAddress1,
            preferredCommunicationMethod,
            city,
            state,
            zip,
            county,
          } = response?.data?.content[0] || [];
          setFormValue({
            ...formValue,
            firstName,
            lastName,
            email,
            phone: formatPhoneNumber(phone ?? ''),
            mailingAddress: mailingAddress1 ?? '',
            communicationMethod: getCommnicationMethodList(
              preferredCommunicationMethod,
            ),
            city: city ?? '',
            state: state ?? '',
            zipCode: zip ?? '',
            county: county ?? '',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const updateCountyList = (stateId) => {
    if (stateId)
      fetchCountyList(stateId).then((countyList) => setCountyList(countyList));
  };

  const onSearchLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const fetchStateList = async () => {
    return axios
      .get(FETCH_STATE_LIST)
      .then((response) => {
        return response.data.map((state) => ({
          stateId: state.stateId,
          label: state.stateName,
          value: state.stateName,
        }));
      })
      .catch(() => {});
  };

  const fetchCountyList = async (stateId) => {
    return axios
      .get(FETCH_COUNTY_LIST, { params: { stateId: stateId } })
      .then((response) => {
        const countyList = response.data.map((county) => ({
          countyId: county.countyId,
          label: county.countyName,
          value: county.countyName,
        }));
        return countyList;
      })
      .catch(() => {});
  };

  const onPlaceChanged = () => {
    if (autocomplete === null) return;

    const place = autocomplete.getPlace();
    fieldHandler(
      'mailingAddress',
      `${place.name}${getMailingAddress(place.address_components)}`,
    );

    if (place.address_components) {
      const address = extractAddrFromGoogleMapComponents(
        place.address_components,
      );
      const { city, state, zipCode, county } = address;

      if (formValue?.state === state) {
        updateCountyList(
          stateList.find((state) => state.value === formValue?.state)?.stateId,
        );
      }

      setFormValue((prev) => ({
        ...prev,
        city,
        state,
        county,
        zipCode,
      }));
    }
  };

  const submitHandler = () => {
    setErrorState((prevError) => ({
      ...prevError,
      phoneRequiredError: formValue.phone?.trim() === '',
      inValidPhoneError:
        !validatePhone(
          replaceSpecialCharactersWithSingleCharacter(
            /[()\-\s]/g,
            formValue.phone,
            '',
          ),
        ) && FALSE,
      zipCodeRequiredError: formValue.zipCode?.trim() === '',
      cityRequiredError: formValue.city?.trim() === '',
      mailingAddressRequiredError: formValue.mailingAddress?.trim() === '',
      communicationMethodRequiredError: !validateCommunicationMethod(
        formValue.communicationMethod,
      ),
      stateRequiredError: formValue.state?.trim() === '',
      countyRequiredError: formValue.county?.trim() === '',
    }));

    const errorStatus = {
      phoneRequiredError: formValue.phone?.trim() === '',
      inValidPhoneError:
        !validatePhone(
          replaceSpecialCharactersWithSingleCharacter(
            /[()\-\s]/g,
            formValue.phone,
            '',
          ),
        ) && FALSE,
      zipCodeRequiredError: formValue.zipCode?.trim() === '',
      cityRequiredError: formValue.city?.trim() === '',
      mailingAddressRequiredError: formValue.mailingAddress?.trim() === '',
      communicationMethodRequiredError: !validateCommunicationMethod(
        formValue.communicationMethod,
      ),
      stateRequiredError: formValue.state?.trim() === '',
      countyRequiredError: formValue.county?.trim() === '',
    };

    if (!Object.values(errorStatus).includes(true)) {
      const payloadData = {
        participantId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        phone: replaceSpecialCharactersWithSingleCharacter(
          /[()\-\s]/g,
          formValue.phone,
          '',
        ),
        mailingAddress1: formValue.mailingAddress,
        preferredCommunicationMethod: getCommaSeperatedStringValues(
          formValue.communicationMethod
            .filter((method) => method.checked)
            .map((method) => method.itemLabel),
        ),
        city: formValue.city,
        state: formValue.state,
        zip: formValue.zipCode,
        county: formValue.county,
      };

      axios
        .put(UPDATE_PARTICIPANT, payloadData)
        .then(() => {
          modalCloseHandler();
          refreshHandler();
        })
        .catch(() => {});
    }
  };

  const stateDropdownProps = {
    label: PAGE_CONTENT.state_label,
    value: checkTernaryCondition(
      formValue.state === '',
      PAGE_CONTENT.state_placeholder,
      formValue.state,
    ),
    width: '100%',
    minWidth: '0rem',
    height: '2rem',
    name: 'state',
    ariaLabel: PAGE_CONTENT.state_label,
    dropDownPlaceholder: PAGE_CONTENT.state_placeholder,
    error: errorState.stateRequiredError,
    errorMessage: PAGE_CONTENT.state_required_error,
    dropdownlist: stateList,
    isNotOutlined: true,
    onUpdate: (e) => {
      setFormValue({
        ...formValue,
        state: e.target.value,
        county: '',
      });
    },
    field_key: 'state',
    setStateValue: () => {},
    showLabelAsValue: true,
    customPlaceholderColor: checkTernaryCondition(
      formValue.state === '',
      COOL_GRAY,
      null,
    ),
    fontSize: STYLE_CONSTANTS.input_font_size,
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: dropdownInputSx,
    listItemFontSize: STYLE_CONSTANTS.input_font_size,
  };

  const countyDropdownProps = {
    label: PAGE_CONTENT.county_label,
    value: checkTernaryCondition(
      formValue.county === '',
      PAGE_CONTENT.county_placeholder,
      formValue.county,
    ),
    width: '100%',
    minWidth: '0rem',
    height: '2rem',
    name: 'county',
    ariaLabel: PAGE_CONTENT.county_label,
    dropDownPlaceholder: PAGE_CONTENT.county_placeholder,
    error: errorState.countyRequiredError,
    errorMessage: PAGE_CONTENT.countyRequiredError,
    dropdownlist: countyList,
    isNotOutlined: true,
    onUpdate: (e) => {
      fieldHandler('county', e.target.value);
    },
    field_key: 'county',
    setStateValue: () => {},
    showLabelAsValue: true,
    customPlaceholderColor: checkTernaryCondition(
      formValue.county === '',
      COOL_GRAY,
      null,
    ),
    fontSize: STYLE_CONSTANTS.input_font_size,
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: dropdownInputSx,
    listItemFontSize: STYLE_CONSTANTS.input_font_size,
  };
  return (
    <Dialog
      disableRestoreFocus
      open={isModalOpen}
      onClose={modalCloseHandler}
      sx={DialogStyle}>
      <TitleWrapper>
        <DialogTitle sx={DialogTitleStyle}>{PAGE_CONTENT.heading}</DialogTitle>
        <IconButton sx={IconButtonStyle} onClick={modalCloseHandler}>
          <CloseIcon data-testid="close-icon" sx={closeIconSx} />
        </IconButton>
      </TitleWrapper>

      <DialogContent sx={DialogContentStyle}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
        <InputWrapper>
          <InputField
            width="100%"
            value={formValue.firstName}
            label={PAGE_CONTENT.firstName}
            name="firstName"
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={{ ...InputSx, ...borderSx }}
            isDisabled
          />
          <InputField
            width="100%"
            isRequired
            label={PAGE_CONTENT.lastName}
            name="lastName"
            value={formValue.lastName}
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={{ ...InputSx, ...borderSx }}
            isDisabled
          />
        </InputWrapper>
        <InputField
          width="100%"
          label={PAGE_CONTENT.email}
          isDisabled
          name="email"
          value={formValue.email}
          labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customInputSx={{ ...InputSx, ...borderSx, maxWidth: '100%' }}
        />
        <InputField
          customInputSx={InputSx}
          customStyles={{ width: '100%' }}
          minWidth={STYLE_CONSTANTS.zero_rem}
          width="13.75rem"
          required
          label={PAGE_CONTENT.phone}
          name="phone"
          value={formValue.phone}
          onUpdate={(e) => {
            const phone = formatPhoneNumber(e.target.value ?? '');
            fieldHandler('phone', phone);
          }}
          onBlurHandler={(event) => {
            if (event.target.value !== '') {
              const value = replaceSpecialCharactersWithSingleCharacter(
                /[()\-\s]/g,
                event.target.value,
                '',
              );
              setErrorState({
                ...errorState,
                inValidPhoneError: !validatePhone(value),
              });
            }
          }}
          placeholder={PAGE_CONTENT.phone_placeholder}
          primaryError={errorState.phoneRequiredError}
          primaryErrorMessage={PAGE_CONTENT.phone_required_error}
          maxLength={100}
          secondaryError={errorState.inValidPhoneError}
          secondaryErrorMessage={PAGE_CONTENT.invalid_phone_number_error}
          labelMarginTop={STYLE_CONSTANTS.one_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        />
        <GoogleMapSearchAutocomplete
          label={PAGE_CONTENT.mailing_address_label}
          value={formValue.mailingAddress}
          valueChangeHandler={(e) => {
            fieldHandler('mailingAddress', e.target.value);
          }}
          placeholder={PAGE_CONTENT.mailing_address_label}
          onPlaceChanged={onPlaceChanged}
          onSearchLoad={onSearchLoad}
          hasError={errorState.mailingAddressRequiredError}
          errorMsg={PAGE_CONTENT.mailing_address_required_error}
          labelMarginTop={STYLE_CONSTANTS.one_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
        />
        <LocationFieldWrapper>
          <InputField
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
            customInputSx={InputSx}
            width="93%"
            minWidth={STYLE_CONSTANTS.zero_rem}
            required
            label={PAGE_CONTENT.city_label}
            name="city"
            value={formValue.city}
            onUpdate={(e) => {
              if (onlyCharsRegex.test(e.target.value)) {
                fieldHandler('city', e.target.value);
              }
            }}
            placeholder={PAGE_CONTENT.city_placeholder}
            primaryError={errorState.cityRequiredError}
            primaryErrorMessage={PAGE_CONTENT.city_required_error}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.one_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
          <DropDown {...stateDropdownProps} />
          <DropDown {...countyDropdownProps} />
          <InputField
            rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
            customInputSx={InputSx}
            width="100%"
            minWidth={STYLE_CONSTANTS.zero_rem}
            required
            label={PAGE_CONTENT.zip_code_label}
            name="zipCode"
            value={formValue.zipCode}
            onUpdate={(e) => {
              if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                fieldHandler('zipCode', e.target.value);
              }
            }}
            placeholder={PAGE_CONTENT.zip_code_placeholder}
            primaryError={errorState.zipCodeRequiredError}
            primaryErrorMessage={PAGE_CONTENT.zip_code_required_error}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.one_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          />
        </LocationFieldWrapper>
        <CheckboxFormGroup
          list={formValue.communicationMethod}
          label={PAGE_CONTENT.communication_method_label}
          instructionText={PAGE_CONTENT.communication_method_helptext}
          primaryError={errorState.communicationMethodRequiredError}
          errorMessage={PAGE_CONTENT.communication_method_error}
          onClickHandler={(methodIndex) => {
            setIsFormEdited(true);
            customFieldChangeHandler(
              'communicationMethod',
              formValue.communicationMethod.map((method, index) => ({
                ...method,
                checked: checkTernaryCondition(
                  methodIndex === index,
                  !method.checked,
                  method.checked,
                ),
              })),
            );
          }}
          gap="1.625rem"
          name="communicationMethod"
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          checkboxPadding={communicationCheckboxPadding}
          titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
        />
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={modalCloseHandler}
          sx={[tertiaryButtonStyle, modalActionButtonDefaultStyle]}>
          {PAGE_CONTENT.cancel}
        </Button>
        <Button
          sx={[ButtonStyle, primaryButtonStyle('4.5px 15px')]}
          onClick={submitHandler}
          disabled={hasErrors || loading || !isFormEdited}>
          {PAGE_CONTENT.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditParticipantProfile.propTypes = {
  isModalOpen: PropTypes.bool,
  modalCloseHandler: PropTypes.func,
  participantId: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default EditParticipantProfile;
