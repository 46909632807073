import styled from 'styled-components';

const PAYMENT_COLOR = '#0076A8';
const MAIN_FONT_COLOR = '#333333';
const BORDER_COLOR = '#D0D0CE';
const SUBTEXT_COLOR = `#63666A`;
const REMOVE_PAYMENT_COLOR = '#0076A8';
const ERROR_MESSAGE_COLOR = '#DA291C';
const BACKGROUND_COLOR = '#F5F5F5';

export const FormFields = styled.div`
  margin-bottom: 1rem;
  height: fit-content;

  input {
    padding-left: 0.625rem;
  }
`;

export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) => props.iserror && ERROR_MESSAGE_COLOR};
`;

export const AddPaymentWrapper = styled.div`
  cursor: pointer;
  /* margin-top:1rem; */
`;

export const AddpaymentLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${PAYMENT_COLOR};
  margin-left: 0.375rem;
`;
export const PaymentWapper = styled.div`
  border-left: 0.125rem solid ${BORDER_COLOR};
  padding: 0.1rem 0 1rem 0.5rem;
  background-color: ${BACKGROUND_COLOR};
`;

export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${ERROR_MESSAGE_COLOR};
`;
export const RemoveProjectLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;
export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${MAIN_FONT_COLOR};
`;

export const InputSubtext = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${SUBTEXT_COLOR};
  opacity: 0.5;
  margin-left: 1rem;
`;

export const RemovePaymentLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${REMOVE_PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;

export const RadioButtonlabel = styled.span`
  margin-left: 0.5rem;
  margin-right: 5.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
`;
export const radioBtnSx = {
  width: '1.125rem',
  height: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
};

export const OptionalLabel = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${SUBTEXT_COLOR};
  opacity: 0.5;
  margin-left: 0.5rem;
`;
export const AddDepositsWrapper = styled.div`
  cursor: pointer;
  margin-top: 1rem;
`;
export const AddDepositsLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #0076a8;
  margin-left: 0.375rem;
  cursor: pointer;
`;

export const inputNumberSx = {
  width: '8.125rem',
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
