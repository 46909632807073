import styled from 'styled-components';
import { BLACK, COOL_GRAY } from 'theme/GlobalColors';

const PAGE_BACKGROUND_COLOR = '#F1f1f1';
const FORM_BACKGROUND_COLOR = 'white';
const MAIN_FONT_COLOR = '#333333';
const SUBTEXT_COLOR = '#63666A';
const ADD_PAYMENT_COLOR = '#0076A8';
const ERROR_MESSAGE_COLOR = '#DA291C';
const TEXTAREA_BORDER = '97999B';
const BORDER_COLOR = '#D0D0CE';
const PAYMENT_COLOR = '#0076A8';

export const VintageWrapper = styled.div`
  border-top: ${(props) =>
    props.isFirstChild ? `1px solid ${BORDER_COLOR}` : ''};
  margin-bottom: 1rem;
`;

export const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 2rem;
`;

export const RemoveVintageLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;

export const MainWrapper = styled.div`
  background-color: ${PAGE_BACKGROUND_COLOR};
  display: flex;
  justify-content: center;
`;

export const FormSection = styled.div`
  max-width: 37.5rem;
  width: 47%;
  height: fit-content;
  margin-top: 3.5rem;
  background-color: ${FORM_BACKGROUND_COLOR};
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0px 0.125rem 0.188rem rgba(0, 0, 0, 0.1);
`;
export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-weight: 400;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  color: ${(props) => (props.iserror ? ERROR_MESSAGE_COLOR : MAIN_FONT_COLOR)};
  line-height: 1.188rem;
`;
export const TextArea = styled.textarea`
  resize: none;
  border: 1px solid ${TEXTAREA_BORDER};
  width: 98%;
  font-style: normal;
  height: 200px;
  padding: 5px 0 0 10px;
  font-family: 'Open Sans';
  &::placeholder {
    color: ${SUBTEXT_COLOR};
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.5;
    line-height: 19px;
  }
`;
export const FormHeading = styled.div`
  margin-bottom: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  color: ${MAIN_FONT_COLOR};
`;
export const FormFields = styled.div`
  height: fit-content;
  margin-bottom: 1rem;
  input {
    padding-left: 10px;
  }
`;
export const inputNumberSx = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    margin: 0,
    WebkitAppearance: 'none',
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  width: '8.125rem',
  '&.MuiInputBase-root': {
    backgroundColor: 'white',
  },
};
export const RadioButtonlabel = styled.span`
  margin-right: 5.25rem;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
`;
export const InputSubtext = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: 400;
  color: ${SUBTEXT_COLOR};
  line-height: 1.188rem;
  margin-left: 1rem;
  opacity: 0.5;
`;
export const DropDownPlaceholder = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${MAIN_FONT_COLOR};
`;
export const radioBtnSx = {
  width: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
  height: '1.125rem',
};
export const OptionalLabel = styled.span`
  color: ${SUBTEXT_COLOR};
  line-height: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.013rem;
  margin-left: 0.5rem;
  opacity: 0.5;
`;
export const ErroWrapper = styled.div`
  letter-spacing: 0.013rem;
  font-size: 0.75rem;
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: 400;
  color: ${ERROR_MESSAGE_COLOR};
  line-height: 1rem;
`;
export const AddProjectWrapper = styled.div`
  margin-top: 1rem;
  cursor: pointer;
`;

export const AddprojectLabel = styled.span`
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  color: ${ADD_PAYMENT_COLOR};
  font-weight: 400;
  margin-left: 0.375rem;
  line-height: 1.188rem;
`;
export const FormPriceFields = styled.div`
  margin-bottom: 1rem;
  height: fit-content;

  input {
    padding-left: ${(props) => (props.isEmpty ? '0rem' : '0.625rem')};
  }

  .MuiInputAdornment-root {
    margin-right: 0px;
    margin-left: ${(props) => (!props.isEmpty ? '0rem' : '0.625rem')};
  }
`;
export const ButtonWrapper = styled.div`
  margin-top: 24px;
  justify-content: flex-start;
  display: flex;
  :first-child {
    margin-right: 24px;
  }
`;
export const AddDepositsWrapper = styled.div`
  cursor: pointer;
  margin-top: 1rem;
`;
export const AddDepositsLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #0076a8;
  margin-left: 0.375rem;
  cursor: pointer;
`;

export const AvailableQuantityLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: ${COOL_GRAY};
  padding-left: 0.625rem;
`;
export const VintageLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${BLACK};
  padding: 0.5rem 0rem;
`;
