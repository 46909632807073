import styled from 'styled-components';

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: end;
  height: 1.75rem;
  margin-bottom: 1rem;
`;

export const PDFViewerSx = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
`;
