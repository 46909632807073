import {
  AcreageInput,
  ActivitiesWrapper,
  ActivityItem,
  SectionHead,
  SectionSubtitle,
  SectionTitle,
  SectionWrapper,
  TitleText,
  TooltipSx,
  edgeofFieldTolltipCustomSx,
  errorIconStyle,
} from './ImplementedActivities.style';
import {
  EdgeOfFieldDialog,
  PAGE_CONSTANTS,
  getActivityTitle,
  getActivityAreaError,
  shouldDisableField,
  toolTipPlacement,
} from './ImplementedActivities.content';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { RED } from 'theme/GlobalColors';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { useState } from 'react';
import { checkTernaryCondition } from 'utils/helper';

const ActivitiesSection = ({
  sectionTitle,
  activities = [],
  updateAcreage,
  totalFieldArea,
}) => {
  const [edgeOfFieldInfoModalOpen, setEdgeOfFieldInfoModalOpen] =
    useState(false);

  const edgeOfFieldSection = sectionTitle === 'Edge of field';
  const toggleEdgeOfFieldModalOpenState = () => {
    setEdgeOfFieldInfoModalOpen((prev) => !prev);
  };
  const hasError = (activity, error) => {
    return (
      (activity?.acresInputRequired && activity?.area === 0) ||
      (activity?.acresInputRequired && activity?.area === '') ||
      error
    );
  };
  return (
    <SectionWrapper>
      <SectionHead>
        {checkTernaryCondition(
          sectionTitle.length <= 15,
          <SectionTitle>
            <TitleText>{sectionTitle}</TitleText>
            {edgeOfFieldSection && (
              <InfoTooltip
                data-testid="edge-of-field-tooltip"
                handleClick={toggleEdgeOfFieldModalOpenState}
                customSx={edgeofFieldTolltipCustomSx}
              />
            )}
          </SectionTitle>,
          <LightTooltip
            title={sectionTitle}
            placement={toolTipPlacement}
            arrow
            sx={TooltipSx}>
            <SectionTitle>
              <TitleText>{sectionTitle}</TitleText>
              {edgeOfFieldSection && (
                <InfoTooltip
                  data-testid="edge-of-field-tooltip"
                  handleClick={toggleEdgeOfFieldModalOpenState}
                  customSx={edgeofFieldTolltipCustomSx}
                />
              )}
            </SectionTitle>
          </LightTooltip>,
        )}
        <SectionSubtitle>{PAGE_CONSTANTS.acreageText}</SectionSubtitle>
      </SectionHead>
      <ActivitiesWrapper>
        {activities?.map((activity, activityIndex) => {
          const { error, errorMessage } = getActivityAreaError(
            activity?.area,
            totalFieldArea,
          );

          const disabledField = shouldDisableField(
            activities,
            activity,
            sectionTitle,
            activityIndex,
          );

          return (
            <ActivityItem key={activity?.projectActivityType}>
              <SectionTitle>
                {getActivityTitle(activity?.projectActivityType)}
              </SectionTitle>
              <AcreageInput
                placeholder="--"
                data-testid="acreage-input"
                disabled={disabledField}
                error={hasError(activity, error)}
                startAdornment={
                  hasError(activity, error) && (
                    <LightTooltip
                      title={
                        <span style={{ color: RED }}>
                          {checkTernaryCondition(
                            error,
                            errorMessage,
                            PAGE_CONSTANTS.area_error_required,
                          )}
                        </span>
                      }
                      arrow>
                      <div>
                        <AlertIcon
                          style={errorIconStyle}
                          data-testid="error-icon"
                        />
                      </div>
                    </LightTooltip>
                  )
                }
                value={checkTernaryCondition(
                  activity?.area === 0.0 && activity?.acresInputRequired,
                  '',
                  activity?.area,
                )}
                onChange={(event) =>
                  updateAcreage(
                    sectionTitle,
                    activity?.activityId,
                    event.target.value,
                  )
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'acreage',
                  style: { textAlign: 'right' },
                }}
              />
            </ActivityItem>
          );
        })}
      </ActivitiesWrapper>

      <EdgeOfFieldDialog
        open={edgeOfFieldInfoModalOpen}
        handleClose={toggleEdgeOfFieldModalOpenState}
      />
    </SectionWrapper>
  );
};

ActivitiesSection.propTypes = {
  sectionTitle: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      acreage: PropTypes.number,
      id: PropTypes.number,
    }),
  ),
  updateAcreage: PropTypes.func,
  totalFieldArea: PropTypes.number,
};

export default ActivitiesSection;
