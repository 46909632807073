export const MODAL_CONTENT = {
  modal_heading: 'Contact support',
  issue_type_label: 'Please identify the type of issue you are encountering.​',
  issue_type_placeholder: 'Select issue',
  level_of_importance_placeholder: 'Select level of importance',
  issue_type_required_error: 'A selection for the type of issue is required.',
  specific_issue_type_label: 'Please specify the type of issue.​',
  specific_feedback_type_label: 'Please specify the type of feedback.​',
  specific_issue_type_placeholder: 'Type issue',
  colon: ': ',
  specific_feedback_type_placeholder: 'Type feedback',
  specific_issue_type_required_error:
    'If other was selected then a specified type of issue is required.',
  describe_issue_label: `Please provide a detailed description of the issue you're encountering.`,
  level_of_importance_label: `What is the level of importance?`,
  describe_issue_placeholder: 'Type here',
  level_of_importance_label_heading: 'Levels of importance',
  describe_issue_tooltip_label_heading:
    'Try to describe your issue through the following:',
  describe_issue_required_error:
    'A description of the issue you experienced is required.',
  screenshot_upload_label:
    'If you can, please include screenshots related to the issue.',
  screenshot_drop_file_label: 'Drag and drop a .jpg or .png image file here',
  optional_label: '(Optional)',
  cancel_btn_label: 'Cancel',
  submit_btn_label: 'Submit',
  message_sent_heading: 'Message sent',
  email_key: 'support-email',
  message_sent_content:
    'Thank you for your help request. You’ll receive an email from {{support_email}} confirming we received your message. Please expect a response via email within 3 business days. We appreciate your feedback!',
};

export const ISSUE_TYPE_LIST = [
  { label: 'Account Access', value: 'Account Access' },
  { label: 'Data Submission', value: 'Data Submission' },
  { label: 'Navigation', value: 'Navigation' },
  { label: 'Performance', value: 'Performance' },
  { label: 'Enhancement', value: 'Enhancement' },
  { label: 'Feedback', value: 'Feedback' },
  { label: 'Other', value: 'Other' },
];
export const IMPORTANCE_LIST = [
  { label: 'Critical', value: 'Critical' },
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
];

export const FILE_UPLOAD_CONFIG = {
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  maxNoOfFiles: 1,
  maxFileSize: 1024 * 1024 * 8, //8MB
  fileNotSupportedErrorText:
    'File isn’t supported. Please upload only a .jpg or .png file.',
};

export const formInitialState = {
  issueType: '',
  specificIssueType: '',
  levelOfImportance: 'Medium',
  images: [],
  description: '',
};

export const fieldNames = {
  issueType: 'issueType',
  specificIssueType: 'specificIssueType',
  specificFeedbackType: 'specificFeedbackType',
  levelOfImportance: 'levelOfImportance',
  images: 'images',
  description: 'description',
};

export const describe_issue_tooltip_label_content = [
  { data: 'What were you trying to do when the issue occurred?' },
  { data: 'What steps led to the issue?' },
  { data: 'Are you able to reproduce the issue, and if so, how?' },
  {
    data: 'Did you receive any error messages? If yes, please provide the exact text or a screenshot if possible.',
  },
  {
    data: 'How is this issue affecting your work or usage of our application?',
  },
];

export const level_of_importance_tooltip_label_content = [
  {
    level: 'Critical',
    data: 'Issue causing significant business disruption. Software is inaccessible or non-functioning. Choose this if you cant use the software at all.',
  },
  {
    level: 'High',
    data: 'Major functionality or performance issues. Choose this if the software works, but performance or functionality is seriously impacted.',
  },
  {
    level: 'Medium',
    data: 'Noticeable performance issues, but software continues to be functional and accessible. Choose this if the software is slower than usual or some features are not working properly.',
  },
  {
    level: 'Low',
    data: 'Minor errors affecting ease of operation or display of information, or if you need information or assistance on the softwares capabilities, configurations, or operation. Choose this for non-urgent issues or questions.',
  },
];

export const errorInitialState = {
  issueTypeError: false,
  specificIssueTypeError: false,
  specificFeedbackTypeError: false,
  descriptionError: false,
  imageError: false,
};

export const HELP_BUTTON_CONTENT = {
  user_guide: 'User guide',
  contact_support: 'Contact support',
};
