export const acceptedFilesInfo = {
  fileNotSupportedErrorText:
    'File not supported. Please upload a file in .csv format.',
  infoTextList: ['Files Supported: .csv'],
  mimeType: 'csv',
  extensions: ['.csv'],
  maxFiles: 1,
  maxFileSize: 1024 * 1024 * 8, //8MB
  maxFileSizeError: 'File size exceeds maximum of 8 MB allowed.',
};
export const MODAL_CONTENT = {
  title: 'Upload Soil Sampling Data ',
  uploadButtonLabel: 'Upload',
  cancelButtonLabel: 'Cancel',
  dragAndDropText: 'Drag and drop shape file here or ',
  browseText: ' browse',
};
