import styled from 'styled-components';
import { COOL_GRAY, RED, GRAYISH_BLUE, GLOBAL_BLUE } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const DragAndDropTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  color: ${(props) =>
    checkTernaryCondition(
      props.isError,
      RED,
      checkTernaryCondition(props.color, props.color, 'inherit'),
    )};
  line-height: 140%;
  font-weight: 400;
`;
export const SmallUploadWrapper = styled.div`
  width: ${({ width }) => checkTernaryCondition(width, width, '34.5rem')};
  background: #fff;
  cursor: ${({ isDisabled }) =>
    checkTernaryCondition(isDisabled, 'text', 'pointer')};
  padding: 1.5rem 0.625rem;
  border-radius: 2px;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%50' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
export const DragAndDropText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;
`;
export const UploadHelpText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || '0.688rem'};
  line-height: 1.125rem;
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
  display: ${({ invisible }) =>
    checkTernaryCondition(invisible, 'none', 'block')};
  margin-top: ${({ marginTop }) =>
    checkTernaryCondition(marginTop, marginTop, '0.25rem')};
  margin-bottom: ${({ marginBottom }) =>
    checkTernaryCondition(marginBottom, marginBottom, '0.25rem')};
`;
export const AcceptedFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  height: 1.75rem;
`;
export const FileName = styled.div`
  padding: 0.25rem 0.5rem;
  border: ${(props) =>
    checkTernaryCondition(
      props.isError,
      `1px solid ${RED}`,
      `1px solid ${GRAYISH_BLUE}`,
    )};
  border-radius: 2px;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  display: flex;
  line-height: 18.2px;
  align-items: center;
`;
export const Subtext = styled.div`
  color: ${({ color }) => color || GLOBAL_BLUE};
  font-weight: 600;
  display: inline;
`;
export const cursorPointerSx = {
  cursor: 'pointer',
};
export const DropBoxStyle = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  margin-bottom: 1rem;
`;
export const DialogBoxSx = {
  '& .MuiDialog-paper': { width: '34.5rem' },
  '& .MuiDialogTitle-root': { lineHeight: '140%', fontWeight: 400 },
  '& .MuiDialogActions-root': { marginTop: '0.5rem' },
};
