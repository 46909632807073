import styled from 'styled-components';
import { GRAY, GRAYISH_BLACK, GREEN, WHITE } from 'theme/GlobalColors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  max-height: ${({ maxHeight }) => maxHeight};
  gap: ${({ gap }) => gap};
  align-items: baseline;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  border-bottom: ${({ border }) => border};
  overflow: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;
export const Icon = styled.div`
  fill: ${({ fillColor }) => fillColor};
`;
export const Text = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding-left: ${({ paddingLeft }) => paddingLeft};
`;
export const AddOccurrenceLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #0076a8;
  margin-left: 0.375rem;
`;

export const ButtonStyle = (isdisabled) => {
  return {
    textTransform: 'none',
    backgroundColor: isdisabled ? GRAY : GREEN,
    color: isdisabled ? GRAYISH_BLACK : WHITE,
    '&:hover': {
      backgroundColor: isdisabled ? GRAY : GREEN,
    },
  };
};

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const DialogActionStyle = {
  justifyContent: 'flex-start',
};
