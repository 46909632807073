import styled from 'styled-components';
import {
  BLACK,
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  WHITE,
} from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';

export const ModalHeading = styled.div`
  color: ${BLACK};
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '31.25rem',
      padding: ' 0.5rem 1.5rem',
      maxHeight: '31.25rem',
      overflowY: 'auto',

      '*': {
        fontFamily: 'Open sans, sans-serif',
      },
    },
  },
};

export const DialogContentStyle = {
  marginBottom: '1rem',
  padding: '0rem',
  overflow: 'auto',

  '&.MuiDialogContent-root': {
    overflowX: 'hidden',
  },
};

export const IconButtonStyle = { ml: 'auto' };
export const DialogTitleStyle = {
  fontSize: '1rem',
  padding: '0rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  color: DARK_CHARCOAL,
  fontWeight: 600,
  lineHeight: '140%',
  alignItems: 'center',
};
export const DialogActionStyle = {
  justifyContent: 'flex-start',
  padding: 0,
  gap: '0.5rem',
};
export const ModalDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '29.5rem',
      maxHeight: '31.25rem',
      overflowY: 'auto',
      padding: '1.5rem',
      position: 'relative',

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
};
export const ModalIconButtonStyle = {
  ml: 'auto',
  position: 'absolute',
  right: 4,
  top: 4,
};

export const FieldSectionWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.marginBottom || '-1rem'};
  grid-column-gap: ${(props) => props.columnGap};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  align-items: ${(props) => props.alignItems};
  margin-top: ${(props) => props.marginTop};
`;

export const SubmitBtnStyle = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  textTransform: 'none',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1rem',
  padding: '0.625rem 1rem',
};

export const CancelBtnStyle = {
  color: DARK_GREEN,
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '1rem',
};

export const AddOptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  margin-top: 0.625rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  // word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
`;

export const closeIconSx = {
  ...pointerCursor,
  alignSelf: 'flex-start',
  color: COOL_GRAY,
};

export const outlinedDropdownInputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '0.25rem 1.5rem 0.25rem 0.5rem',
  },
};

export const checkboxPadding = '0rem 0.5rem 0rem 0.563rem';

export const InputSx = {
  fontSize: '0.875rem',
  height: '2rem',
  '& input': {
    padding: '0.125rem 0 0.125rem 0.5rem',
    lineHeight: 'normal',
  },
};
