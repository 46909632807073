export const REPORTING_STATUS = {
  not_started: 'Not started',
  in_progress: 'In progress',
  requested: 'Requested',
  submitted: 'Submitted',
  approved: 'Approved',
  contract_added: 'Contract added',
  contracted: 'Contracted',
  interested: 'Interested',
  in_review: 'In review',
};
