import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  cancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  firstSubmitBtnStyle,
  secondSubmitBtnStyle,
} from './EnrollmentCompleteModal.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { closeIconSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import { flexSpaceBetween } from 'theme/GlobalStyles';

const EnrollmentCompleteModal = ({
  title,
  contentText,
  firstSubmitBtnLabel,
  secondSubmitBtnLabel,
  cancelBtnLabel,
  firstSubmitBtnHandler,
  secondSubmitBtnHandler,
  cancelHandler,
  modalCloseHandler,
  modalData,
}) => {
  return (
    <Dialog open={modalData.flag} onClose={modalCloseHandler} sx={DialogStyle}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>{title}</div>
        <CloseIcon onClick={modalCloseHandler} sx={closeIconSx} />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>{contentText}</DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          sx={[cancelBtnStyle, tertiaryButtonStyle]}
          onClick={cancelHandler}>
          {cancelBtnLabel}
        </Button>
        <Button
          disabled={modalData.disableFirstSubmitBtn}
          sx={[firstSubmitBtnStyle, secondaryButtonStyle]}
          onClick={firstSubmitBtnHandler}>
          {firstSubmitBtnLabel}
        </Button>
        <Button
          disabled={modalData.disableSecondSubmitBtn}
          sx={[secondSubmitBtnStyle, primaryButtonStyle()]}
          onClick={secondSubmitBtnHandler}>
          {secondSubmitBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EnrollmentCompleteModal.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  firstSubmitBtnLabel: PropTypes.string,
  secondSubmitBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  firstSubmitBtnHandler: PropTypes.func,
  secondSubmitBtnHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  modalCloseHandler: PropTypes.func,
  modalData: PropTypes.object,
};
export default EnrollmentCompleteModal;
