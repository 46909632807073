import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  MODAL_CONTENT,
  errorInitialState,
} from './AddExistingParticipantModal.content';
import {
  OptionNoBoldStyle,
  closeIconSx,
  flexSpaceBetween,
} from 'theme/GlobalStyles';
import CloseIcon from '@mui/icons-material/Close';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import { ReactComponent as ChipCancelIcon } from '../../../assets/icons/chipcloseicon.svg';
import { ReactComponent as RedCancelIcon } from '../../../assets/icons/redCancelIcon.svg';
import {
  CancelBtnStyle,
  SubmitBtnStyle,
  chipStyle,
  DialogStyle,
  DialogTitleStyle,
  DialogContentStyle,
  DialogActionStyle,
  OptionItemsStyle,
} from './AddExistingParticipantModal.style';
import { useEffect, useState } from 'react';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { checkTernaryCondition } from 'utils/helper';
import {
  ADD_MULTIPLE_PARTICIPANTS,
  CHECK_LINKED_PARTICIPANTS,
  FETCH_APPROVED_PARTICIPANTS,
  GET_PROJECT_FOR_PARTICIPANTS,
} from 'urls';
import axios from 'axios';
import { FALSE } from 'utils/config';
import { OptionListStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';

const AddExistingParticipantModal = ({
  isModalOpen,
  setIsModalOpen,
  refreshHandler,
}) => {
  const modalCloseHandler = () => {
    setIsModalOpen(false);
    setParticipantValue([]);
    setProjectValue(null);
    setProjectList([]);
    setParticipantList([]);
    setErrorState(errorInitialState);
    setExistingParticipantList([]);
  };
  const [participantValue, setParticipantValue] = useState([]);
  const [projectValue, setProjectValue] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  const [errorState, setErrorState] = useState(errorInitialState);
  const [loading, setLoading] = useState(false);
  const isSubmitDisabled = Object?.values(errorState)?.includes(true);
  const [existingParticipantList, setExistingParticipantList] = useState([]);
  const [filterText, setFilterText] = useState('');

  const fetchProjects = (value) => {
    if (typeof value === 'string' && value.trim() && value.length > 2) {
      axios
        .get(GET_PROJECT_FOR_PARTICIPANTS, { params: { projectName: value } })
        .then((response) => {
          setProjectList(
            response.data.map((item) => ({
              value: item.internalProjectId,
              label: item.projectName,
            })),
          );
        });
    }
  };

  const checkAreParticipantLinkedToProject = () => {
    setLoading(true);
    axios
      .post(`${CHECK_LINKED_PARTICIPANTS}?projectId=${projectValue.value}`, {
        participantIds: participantValue.map(
          (participant) => participant.value,
        ),
      })
      .then((response) => {
        setErrorState((prev) => ({
          ...prev,
          participantExistsInprojectError: response.data.isParticipantLinked,
        }));
        if (response.data.isParticipantLinked) {
          setExistingParticipantList(response.data.participantIds);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  //fetch approved participants list
  const fetchParticipantsList = (value) => {
    if (typeof value === 'string' && value.trim() && value.length > 2) {
      axios
        .get(FETCH_APPROVED_PARTICIPANTS, {
          params: { name: value, projectId: 0 },
        })
        .then((response) => {
          setParticipantList(
            response.data.map((item) => ({
              value: item.participantId,
              label: `${item.participantFirstName} ${item.participantLastName}`,
              firstName: item.participantFirstName,
              lastName: item.participantLastName,
              email: item.participantEmail,
            })),
          );
        });
    }
  };

  useEffect(() => {
    if (projectValue && participantValue.length > 0) {
      checkAreParticipantLinkedToProject();
    } else {
      setErrorState((prev) => ({
        ...prev,
        participantExistsInprojectError: false,
      }));
      setExistingParticipantList([]);
    }
  }, [projectValue, participantValue]);

  const submitHanlder = () => {
    const errorValues = {
      ...errorState,
      participantRequiredError: participantValue.length === 0,
      projectRequiredError: projectValue === null,
    };
    setErrorState((prev) => errorValues);
    if (!Object?.values(errorValues)?.includes(true)) {
      setLoading(true);
      axios
        .post(`${ADD_MULTIPLE_PARTICIPANTS}?projectId=${projectValue.value}`, {
          participantIds: participantValue.map(
            (participant) => participant.value,
          ),
        })
        .then(() => {
          refreshHandler();
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
      modalCloseHandler();
    }
  };
  return (
    <Dialog open={isModalOpen} onClose={modalCloseHandler} sx={DialogStyle}>
      <DialogTitle
        sx={{
          ...DialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <div>{MODAL_CONTENT.modal_title}</div>
        <CloseIcon onClick={modalCloseHandler} sx={closeIconSx} />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
        <BasicAutoComplete
          options={participantList}
          filterSelectedOptions={true}
          customIsOptionEqualToValue={(option, value) =>
            option.label === value.label
          }
          id="disable-close-on-select"
          label={MODAL_CONTENT.first_field_label}
          required
          isError={errorState.participantRequiredError}
          ErrorMessage={MODAL_CONTENT.first_field_required_error}
          value={participantValue}
          placeholder={checkTernaryCondition(
            participantValue.length > 0,
            '',
            MODAL_CONTENT.first_field_placeholder,
          )}
          freeSolo={false}
          getOptionLabel={(option) => option.label}
          onChangeHandler={(value) => {
            typeof value !== 'string' && setParticipantValue(value);
            setFilterText('');
            setErrorState((prev) => ({
              ...prev,
              participantRequiredError: participantValue.length === 0 && FALSE,
            }));
          }}
          inputHandler={(e) => {
            e && fetchParticipantsList(e.target.value);
            setFilterText(e?.target.value);
          }}
          renderCustomOptions={(props, option) => {
            const text = `${option?.firstName} ${option?.lastName} <${option?.email}>`;
            const index = text.toLowerCase().indexOf(filterText?.toLowerCase());
            const beforeStr = text.slice(0, index);
            const boldStr = text.slice(index, index + filterText?.length);
            const afterStr = text.slice(index + filterText?.length);
            return (
              <li {...props} key={option.participantId}>
                <span style={OptionItemsStyle}>{beforeStr}</span>
                <span style={OptionNoBoldStyle}>{boldStr}</span>
                <span style={OptionItemsStyle}>{afterStr}</span>
              </li>
            );
          }}
          isMultiSelect={true}
          loadingText={MODAL_CONTENT.first_field_placeholder}
          name="project"
          renderCustomTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const isError = existingParticipantList.includes(option.value);
              const keyVal = index;
              return (
                <Chip
                  data-testid="autocomplete-chip"
                  key={keyVal}
                  deleteIcon={checkTernaryCondition(
                    isError,
                    <RedCancelIcon />,
                    <ChipCancelIcon />,
                  )}
                  label={option.label}
                  {...getTagProps({ index })}
                  sx={chipStyle(isError)}
                />
              );
            })
          }
          width="34.5rem"
        />
        <BasicAutoComplete
          options={projectList}
          id="disable-close-on-select"
          label={MODAL_CONTENT.second_field_label}
          required
          isError={errorState.projectRequiredError}
          ErrorMessage={MODAL_CONTENT.second_field_required_error}
          secondaryError={errorState.participantExistsInprojectError}
          loadingText={MODAL_CONTENT.second_field_placeholder}
          secondaryErrorMessage={
            MODAL_CONTENT.participant_exists_in_project_error
          }
          value={projectValue}
          freeSolo={false}
          placeholder={MODAL_CONTENT.second_field_placeholder}
          getOptionLabel={(option) => option.label}
          onChangeHandler={(value) => {
            setProjectValue(value);
            setErrorState((prev) => ({
              ...prev,
              projectRequiredError: !value?.label?.trim() && FALSE,
            }));
          }}
          inputHandler={(e) => {
            e && fetchProjects(e.target.value);
            setFilterText(e?.target.value);
          }}
          name="project"
          width="34.5rem"
          filterSelectedOptions={true}
          customIsOptionEqualToValue={(option, value) =>
            option.label === value.label
          }
          renderCustomOptions={(props, option) => {
            const lowerTextCase = filterText?.toLowerCase();
            const startIdx = option?.label.toLowerCase().indexOf(lowerTextCase);
            if (startIdx === -1) return null;

            return (
              <li {...props} key={option.value}>
                <span style={OptionListStyle}>
                  {option?.label.substr(0, startIdx)}
                </span>
                <span style={OptionNoBoldStyle}>
                  {option?.label.substr(startIdx, filterText.length)}
                </span>
                <span style={OptionListStyle}>
                  {option?.label.substr(startIdx + filterText.length)}
                </span>
              </li>
            );
          }}
        />
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={modalCloseHandler}
          sx={[CancelBtnStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
        <Button
          onClick={submitHanlder}
          disabled={isSubmitDisabled}
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddExistingParticipantModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  refreshHandler: PropTypes.func,
};
export default AddExistingParticipantModal;
