import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionButtonsSx,
  FilterActions,
  FilterSection,
  FilterSectionHeading,
  Filters,
  filterIconStyle,
} from './FiltersPopup.style';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Popover } from '@mui/material';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { DARK_GREEN } from 'theme/GlobalColors';
import { TEXT_CONTENT } from './FilterPopup.content';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { pageButtonDefaultStyle } from 'theme/GlobalStyles';
import { ConfigurationContext } from 'contextAPI/configurationContext';

const FiltersPopup = ({
  filterSections,
  updateFilters,
  isFiltersApplied,
  darkGreenColor,
  applyTextTransform = false,
}) => {
  const [localFilterSections, setLocalFilterSections] =
    useState(filterSections);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAreUiActionInProgress((prev) => prev + 1);
  };

  const handleClose = () => {
    setLocalFilterSections(filterSections);
    setAnchorEl(null);
    setAreUiActionInProgress((prev) => prev - 1);
  };

  const handleFilterUpdate = ({ sectionIdx, filterIdx }) => {
    setLocalFilterSections((prevSections) => {
      return prevSections.map((section, index) => {
        if (index !== sectionIdx) {
          return section;
        }
        const updatedFilters = section.filters.map((filter, idx) => {
          if (idx !== filterIdx) {
            return filter;
          }
          return {
            ...filter,
            checked: !filter.checked,
          };
        });
        return {
          ...section,
          filters: updatedFilters,
        };
      });
    });
  };

  useEffect(() => {
    setLocalFilterSections(filterSections);
  }, [filterSections]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className="flex-center">
      <FilterAltIcon
        sx={filterIconStyle(isFiltersApplied)}
        onClick={handleClick}
        data-testid="filter-popup-icon"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Filters>
          {localFilterSections.map((section, sectionIdx) => {
            return (
              <FilterSection key={uniqueId()}>
                <FilterSectionHeading>{section.heading}</FilterSectionHeading>
                <CheckboxFormGroup
                  checkBoxFillColor={darkGreenColor}
                  list={section.filters}
                  stackedView={true}
                  customStyles={{ paddingLeft: '0.5rem' }}
                  onClickHandler={(filterIndex) =>
                    handleFilterUpdate({
                      sectionIdx: sectionIdx,
                      filterIdx: filterIndex,
                    })
                  }
                  applyTextTransform={applyTextTransform}
                />
              </FilterSection>
            );
          })}
        </Filters>
        <FilterActions>
          <Button
            variant="text"
            onClick={() => {
              updateFilters(localFilterSections);
              handleClose();
            }}
            sx={[
              {
                ...ActionButtonsSx,
                ...pageButtonDefaultStyle,
                backgroundColor: DARK_GREEN,
                color: '#fff',
                '&:hover': {
                  backgroundColor: DARK_GREEN,
                },
              },
              primaryButtonStyle('6.5px'),
            ]}>
            {TEXT_CONTENT.apply}
          </Button>
          <Button
            onClick={handleClose}
            variant="text"
            sx={[
              {
                ...ActionButtonsSx,
                ...pageButtonDefaultStyle,
                color: DARK_GREEN,
              },
              tertiaryButtonStyle,
            ]}>
            {TEXT_CONTENT.cancel}
          </Button>
        </FilterActions>
      </Popover>
    </div>
  );
};

FiltersPopup.propTypes = {
  filterSections: PropTypes.array,
  updateFilters: PropTypes.func,
  isFiltersApplied: PropTypes.bool,
  darkGreenColor: PropTypes.string,
  applyTextTransform: PropTypes.bool,
};
export default FiltersPopup;
