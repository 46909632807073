import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router';
import { PAGE_CONTENT } from './Footer.content';
import { landingPageFooterStyles, useStyles } from './Footer.style';
import { pathName } from 'Routes/routes.const';
import {
  baselineAndLandingPages,
  participantEnrolmentRoutes,
} from 'layout/Layout.constants';

const Footer = ({
  isParticipantLanding,
  isParticipantMenu,
  isInActivityPage,
}) => {
  const { MainContainer, FooterLink, FooterDiv, IconSpan } = useStyles();
  const navigate = useNavigate();
  const date = new Date();
  const location = useLocation();

  const insideBaselineReportingPage =
    location.pathname === pathName.participant.participantFieldBaseline;

  const insideLandingPages = [
    pathName.participant.landingPage,
    pathName.participant.baselineLandingPage,
    pathName.participant.activityLandingPage,
    pathName.participant.contributorLandingPage,
  ].includes(location.pathname);

  return (
    <Box sx={insideLandingPages && landingPageFooterStyles}>
      <MainContainer
        margin={insideBaselineReportingPage && '0px'}
        maxWidth={'100%'}
        isParticipantLanding={isParticipantLanding}
        isParticipantMenu={isParticipantMenu}
        isInActivityPage={isInActivityPage}>
        <FooterDiv
          isParticipantLanding={isParticipantLanding}
          isEBAC={
            baselineAndLandingPages.includes(location?.pathname) ||
            participantEnrolmentRoutes.includes(location?.pathname)
          }>
          <div>
            <FooterLink
              isParticipantLanding={isParticipantLanding}
              target={'_blank'}
              onClick={() => {
                navigate(pathName.termsOfUse);
              }}>
              {PAGE_CONTENT.terms_of_use}
            </FooterLink>
            <IconSpan isParticipantLanding={isParticipantLanding}> | </IconSpan>
            <FooterLink
              isParticipantLanding={isParticipantLanding}
              target={'_blank'}
              href={PAGE_CONTENT.privacyPolicyLink}
              rel="noreferrer">
              {PAGE_CONTENT.privacyPolicyText}
            </FooterLink>
            <IconSpan isParticipantLanding={isParticipantLanding}> | </IconSpan>
            <FooterLink
              isParticipantLanding={isParticipantLanding}
              href={PAGE_CONTENT.cookies_link}
              target={''}>
              {PAGE_CONTENT.cookies}
            </FooterLink>
          </div>
          <div data-testid="copyright-test">
            <span>
              {' '}
              &copy; {date.getFullYear()} {PAGE_CONTENT.copyright}
            </span>
          </div>
        </FooterDiv>
      </MainContainer>
    </Box>
  );
};

Footer.propTypes = {
  isParticipantLanding: PropTypes.bool,
  isParticipantMenu: PropTypes.bool,
  isInActivityPage: PropTypes.bool,
};

export default Footer;
