import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { BLUISH_CYAN, DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';

export const PAGE_CONSTANTS = {
  instructionsText:
    'Please provide the acreage for each  project activity on which practices were implemented.',
  acreageText: 'Total acreage',
  area_error_required: 'Acreage is required',
};

const sectionHeadingMapping = {
  'Reduce tillage': 'Residue + tillage management',
  'Improve crop planting and harvesting': 'Cover crop',
  'Edge of field practices': 'Edge of field',
  'Improve residue management': 'Nutrient management',
  'Improve soil amendments application': 'Soil carbon amendment',
};

const reverseSectionHeadingMapping = {
  'Residue + tillage management': 'Residue + tillage management',
  'Cover crop': 'Cover crop',
  'Edge of field': 'Edge of field practices',
  'Nutrient management': 'Nutrient management',
  'Soil carbon amendment': 'Soil carbon amendment',
};

export const implementedActivitiesSortOrder = [
  'Cover crop',
  'Residue + tillage management',
  'Nutrient management',
  'Edge of field',
  'Soil carbon amendment',
];

export const getSectionHeading = (sectionName) =>
  sectionHeadingMapping[sectionName] ?? sectionName;

export const getReverseMappingOfSections = (sectionName) =>
  reverseSectionHeadingMapping[sectionName] ?? sectionName;

const noErrorData = { error: false, errorMessage: '' };
const areaExceedsErrorData = {
  error: true,
  errorMessage:
    'Acreage should be less than the field area entered during enrollment',
};

const areaValueZeroErrorData = {
  error: true,
  errorMessage: 'Acreage should be greater than zero required',
};

export const getActivityAreaError = (area, totalFieldArea) => {
  if (area !== '' && Number(area) === 0) {
    return areaValueZeroErrorData;
  }
  if (area > totalFieldArea) {
    return areaExceedsErrorData;
  }
  return noErrorData;
};

export const getActivityTitle = (title) => {
  if (title === 'Cover crop' || title === 'Nutrient management') return '--';
  return title;
};

export const shouldDisableField = (
  activities,
  activity,
  section,
  activityIndex,
) => {
  if (section !== 'Residue + tillage management' || Number(activity?.area) > 0)
    return false;
  if (activityIndex === 0) {
    return activities[1]?.area > 0;
  } else {
    return activities[0]?.area > 0;
  }
};

export const toolTipPlacement = 'top';

export const EdgeOfFieldDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ '.MuiPaper-root': { padding: '1.5rem', maxWidth: '31.25rem' } }}>
      <DialogTitle
        sx={{
          color: DARK_CHARCOAL,
          fontSize: '1.25rem',
          fontWeight: 400,
          padding: 0,
          marginBottom: '1rem',
          lineHeight: '28px',
        }}>
        Edge of field area guidance
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '0.875rem',
          padding: 0,
          marginBottom: '1.5rem',
          lineHeight: '19.6px',
        }}>
        <div>
          Edge of Field practice activities must be submitted in acres. Use the
          following equation to calculate the acreage for each Edge of Field
          practice:
        </div>
        <br></br>
        <div>
          Acreage = ((Length in Ft. x Width in Ft.) * # of
          strips/borders/barriers/buffers) / 43,560 ft.
        </div>
        <br></br>
        <div>
          Please check NRCS State Guidelines to ensure that the length and width
          of your planned practices align with the recommendations in your
          state.
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button
          sx={{
            backgroundColor: BLUISH_CYAN,
            color: WHITE,
            textTransform: 'none',

            '&:hover': {
              backgroundColor: BLUISH_CYAN,
              color: WHITE,
            },
          }}
          onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
