import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_OPTIONS,
  LABEL_ROWS_PER_PAGES,
  ORIGINATION_PROJECT_CREATE,
  ORIGINATION_PROJECT_DELETE,
  ORIGINATION_PROJECT_UPDATE,
  PAGINATION_MODE,
} from 'utils/config';
import {
  ORIGINATION_LIST_CONTENT,
  fields,
  displayToast,
  SortColValues,
  commercialPurposeMapping,
} from './OriginationProjectList.content';
import {
  DataGridWrapper,
  DefaultFontWrapper,
  FilterHeader,
  MainWrapper,
  AccessibleLinkStyle,
  datagridSx,
  SearchAndFilterSection,
  FilterChip,
  ClearLink,
  AppliedFilters,
  cursorPointerStyle,
  SearchAndFiltersFlex,
  SearchBox,
  OptionsWrapper,
  filterClearIconSx,
  ProjectNameWrapper,
  SortWrapper,
  handleArrowColor,
  modalTitleSx,
  TooltipSx,
} from './OriginationProjectList.style';
import {
  covertDateToLocalTimeZone,
  checkAccess,
  debounceFunc,
} from 'utils/helper';
import NodataComponent from 'components/NoDataComp/NodataContent';
import { DataGrid } from '@mui/x-data-grid';
import LightTooltip from 'components/LightTooltip';
import { DELETE_ORIGINATION_PROJECT, FETCH_PROJECT_LIST } from 'urls';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import {
  BLUISH_CYAN,
  DARK_CHARCOAL,
  DARK_GREEN,
  LIGHT_CHARCOAL,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import ClearIcon from '@mui/icons-material/Clear';
import FiltersPopup from './components/FiltersPopup/FiltersPopup.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import SearchIcon from '@mui/icons-material/Search';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const OriginationProjectList = ({ disableVirtualization }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: Number(sessionStorage.getItem('currentPage')) || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [commercialPurposeFilterList, setCommercialPurposeFilterList] =
    useState([]);
  const [projectNameSearchText, setProjectNameSearchText] = useState('');
  const { permissions } = useContext(userAuthRolesContext);
  const [hasNoProject, setHasNoProject] = useState(false);
  const [sortFilter, setSortFilter] = useState({
    column: SortColValues.updatedDate,
    order: 'DESC',
  });
  const debounceTimerRef = useRef(null);

  const filterSections = useMemo(
    () => [
      {
        heading: 'Commercial purpose',
        filters: [
          {
            id: 'None',
            itemLabel: 'None',
            checked: commercialPurposeFilterList.includes('None'),
          },
          {
            id: 'Insetting',
            itemLabel: 'Insetting',
            checked: commercialPurposeFilterList.includes('Insetting'),
          },
          {
            id: 'Offsetting',
            itemLabel: 'Offsetting',
            checked: commercialPurposeFilterList.includes('Offsetting'),
          },
        ],
      },
    ],
    [commercialPurposeFilterList],
  );

  const updateFilterStates = (sectionHeading, checkedFields) => {
    switch (sectionHeading) {
      case 'Commercial purpose':
        setCommercialPurposeFilterList(checkedFields);
        break;
    }
  };
  const handleFilterSectionUpdate = (sections) => {
    sections.forEach((section) => {
      const checkedFields = section.filters
        .filter((filter) => filter.checked === true)
        .map((filter) => filter.itemLabel);

      updateFilterStates(section.heading, checkedFields);
    });
  };

  const removeFilter = ({ sectionIdx, filterIdx }) => {
    const filterLabelToBeRemoved =
      filterSections[sectionIdx].filters[filterIdx].itemLabel;
    const checkedFields = filterSections[sectionIdx].filters
      .filter(
        (filter) =>
          filter.checked === true &&
          filter.itemLabel !== filterLabelToBeRemoved,
      )
      .map((filter) => filter.itemLabel);
    updateFilterStates(filterSections[sectionIdx].heading, checkedFields);
  };

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortFilter((prevState) => ({
      column: SortColValues[params.field],
      order: prevState.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };

  useEffect(() => {
    debouncedFetchOriginationProjectList();
  }, [sortFilter, projectNameSearchText, commercialPurposeFilterList]);

  // onPage load fetch project list
  useEffect(() => {
    fetchOriginationProjectList(true);
    sessionStorage.setItem('currentPage', pageState.page);
  }, [pageState.page]);

  //Pagination fetch data
  useEffect(() => {
    fetchOriginationProjectList();
  }, [pageState.pageSize, pageState.page]);

  const currentRowSetter = (row) => {
    setCurrentRow(row);
  };

  const rowClickHandler = (params) => {
    sessionStorage.setItem('currentPage', pageState.page);
    navigate(`${pathName.originationProject.view}/${params.row.id}`);
  };

  const editHandler = (params) => {
    navigate(`${pathName.originationProject.edit}/${params.row.id}`);
  };

  const deleteClickHandler = () => {
    setIsDialogOpen(true);
  };

  const deleteProject = () => {
    setIsDialogOpen(false);
    axios
      .delete(`${DELETE_ORIGINATION_PROJECT}/${currentRow?.id}`)
      .then((response) => {
        if (ORIGINATION_LIST_CONTENT.success.includes(response?.status)) {
          displayToast(
            'success',
            `${currentRow?.projectName} ${ORIGINATION_LIST_CONTENT.success_message}`,
          );
          fetchOriginationProjectList();
        } else {
          displayToast(
            'error',
            `${currentRow?.projectName} ${ORIGINATION_LIST_CONTENT.error_message}`,
          );
        }
      })
      .catch(() => {
        displayToast(
          'error',
          `${currentRow?.projectName} ${ORIGINATION_LIST_CONTENT.error_message}`,
        );
      });
  };

  const columns = [
    {
      field: fields.projectName,
      headerName: ORIGINATION_LIST_CONTENT.origination_project_name,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper padding="0px 0px 0px 0.5rem">
            {ORIGINATION_LIST_CONTENT.origination_project_name}
          </TextWrapper>
        </FilterHeader>
      ),
      disableColumnMenu: true,
      width: 400,
      renderCell: (params) => (
        <ProjectNameWrapper>
          <div data-testId="green-icon">
            <LightTooltip title={ORIGINATION_LIST_CONTENT.tooltip_text}>
              <Brightness1Icon
                sx={{
                  color: BLUISH_CYAN,
                  fontSize: 'small',
                  height: '0.5rem',
                  width: '0.5rem',
                }}
              />
            </LightTooltip>
          </div>
          <AccessibleLinkStyle>
            {params.value !== null ? (
              <LightTooltip title={params.value} sx={TooltipSx}>
                <div>{params.value}</div>
              </LightTooltip>
            ) : (
              '--'
            )}
          </AccessibleLinkStyle>
        </ProjectNameWrapper>
      ),
    },
    {
      field: fields.commercializationApproach,
      headerName: ORIGINATION_LIST_CONTENT.commercial_purpose,
      disableColumnMenu: true,
      width: 350,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper>
            {ORIGINATION_LIST_CONTENT.commercial_purpose}
          </TextWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <TextWrapper color={DARK_CHARCOAL}>{params.value}</TextWrapper>
      ),
    },
    {
      field: fields.updatedDate,
      headerName: ORIGINATION_LIST_CONTENT.last_updated,
      disableColumnMenu: true,
      width: 300,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>
            {ORIGINATION_LIST_CONTENT.last_updated}
          </TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <DefaultFontWrapper color={DARK_CHARCOAL}>
          {params.value !== null
            ? covertDateToLocalTimeZone(params.value).slice(0, 10)
            : '--'}
        </DefaultFontWrapper>
      ),
    },
    {
      field: 'options',
      headerName: '',
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      align: ORIGINATION_LIST_CONTENT.right,
      renderCell: (params) => (
        <OptionsWrapper
          onClick={() => currentRowSetter(params?.row)}
          data-testid={`options-${params.row?.id}`}>
          <SimpleOptionsButton
            editId={params.row.id}
            onEdit={() => editHandler(params)}
            onDelete={() => deleteClickHandler()}
            editOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PROJECT_UPDATE)
            }
            deleteOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PROJECT_DELETE)
            }
          />
        </OptionsWrapper>
      ),
    },
  ];

  const fetchOriginationProjectList = (isInitialCall = false) => {
    setIsDataFetched(false);
    setPageState((old) => ({ ...old, isLoading: true }));
    // to fetch data with pagesize, pagebody
    axios
      .post(FETCH_PROJECT_LIST, {
        pageNumber: projectNameSearchText ? 0 : pageState.page - 1,
        pageSize: pageState.pageSize,
        commercialPurpose: commercialPurposeFilterList.map(
          (item) => commercialPurposeMapping[item],
        ),
        projectName: projectNameSearchText,
        sortFilter,
      })
      .then((response) => {
        if (isInitialCall && response.data.content.length === 0) {
          setHasNoProject(true);
        }
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content,
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      });
  };

  const debouncedFetchOriginationProjectList = debounceFunc(
    fetchOriginationProjectList,
    500,
    debounceTimerRef,
  );

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    filterSections.forEach((section) => {
      section.filters.map((filter) => {
        if (filter.checked) count++;
      });
    });

    return count;
  }, [filterSections]);

  const getDataComponent = () => {
    if (
      isDataFetched &&
      pageState.data.length === 0 &&
      projectNameSearchText === '' &&
      hasNoProject
    )
      return (
        <NodataComponent
          width={ORIGINATION_LIST_CONTENT.no_data_heading_width}
          nodata={ORIGINATION_LIST_CONTENT.noDataContent}
          addbuttonLabel={{
            name: ORIGINATION_LIST_CONTENT.addButton,
            link: pathName.originationProject.add,
          }}
          disableButton={!checkAccess(permissions, ORIGINATION_PROJECT_CREATE)}
        />
      );

    if (
      isDataFetched &&
      pageState.data.length === 0 &&
      projectNameSearchText?.trim()?.length > 0
    )
      return (
        <NodataComponent
          filterData={ORIGINATION_LIST_CONTENT.filter_content}
          searchTermSuffix={ORIGINATION_LIST_CONTENT.search_text_suffix}
          searchTerm={`"${projectNameSearchText}".`}
          width={ORIGINATION_LIST_CONTENT.filter_content_width}
        />
      );

    return (
      <MainWrapper>
        <DataGridWrapper>
          <DataGrid
            headerHeight={40}
            disableVirtualization={disableVirtualization}
            rows={pageState.data || []}
            rowCount={pageState.total}
            loading={pageState.isLoading || !isDataFetched}
            onCellClick={rowClickHandler}
            rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
            disableSelectionOnClick
            pagination
            componentsProps={{
              pagination: {
                labelRowsPerPage: LABEL_ROWS_PER_PAGES,
                SelectProps: TablePaginationMenuListStyle,
                style: { color: DARK_CHARCOAL },
              },
            }}
            sx={datagridSx}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode={PAGINATION_MODE}
            onPageChange={(newPage) =>
              setPageState((old) => ({ ...old, page: newPage + 1 }))
            }
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({ ...old, pageSize: newPageSize }))
            }
            columns={columns}
          />
        </DataGridWrapper>
        <DialogBox
          dialogActions
          deleteOperation
          isOpen={isDialogOpen}
          onConfirm={() => deleteProject()}
          modalTitleSx={modalTitleSx}
          subtitle={`${ORIGINATION_LIST_CONTENT.delete_message?.slice(0, 32)}${
            currentRow?.projectName
          }${ORIGINATION_LIST_CONTENT.delete_message?.slice(31)}`}
          onCancel={() => setIsDialogOpen(false)}
          declineCtnLabel="Cancel"
          acceptCtnLabel="Delete"
          title={`Delete ${currentRow?.projectName}`}></DialogBox>
      </MainWrapper>
    );
  };

  return (
    <>
      <ListViewHeader
        title={ORIGINATION_LIST_CONTENT.pageTitle}
        buttonName={ORIGINATION_LIST_CONTENT.addButton}
        addNewClickHandler={() => navigate(pathName.originationProject.add)}
        buttonBackgroundColor={DARK_GREEN}
        disableButton={!checkAccess(permissions, ORIGINATION_PROJECT_CREATE)}
        containerPadding={'0 0 1rem 0'}
        titleColor={LIGHT_CHARCOAL}
      />
      <SearchAndFilterSection>
        <div style={SearchAndFiltersFlex}>
          <SearchBox
            variant="outlined"
            placeholder={ORIGINATION_LIST_CONTENT.searchboxPlaceholder}
            value={projectNameSearchText}
            onChange={(event) => {
              setProjectNameSearchText(
                event.target.value.replace(/[^a-zA-Z0-9@._-\s]/g, ''),
              );
            }}
            InputProps={{
              startAdornment: (
                <Fragment>
                  <SearchIcon sx={{ color: TYPOGRAPHY_GRAY }} />
                </Fragment>
              ),
              endAdornment: projectNameSearchText?.length > 0 && (
                <ClearIcon
                  onClick={() => {
                    setIsDataFetched(false);
                    setProjectNameSearchText('');
                  }}
                  sx={filterClearIconSx}
                />
              ),
            }}
          />
          <AppliedFilters>
            {filterSections.map((section, sectionIdx) => {
              return section.filters.map((filter, filterIdx) => {
                return (
                  filter.checked && (
                    <FilterChip
                      key={uniqueId()}
                      data-testid={`${section.heading}: ${filter.itemLabel}`}>
                      <div>{`${section.heading}: ${filter.itemLabel}`}</div>
                      <ClearIcon
                        onClick={() =>
                          removeFilter({
                            sectionIdx: sectionIdx,
                            filterIdx: filterIdx,
                          })
                        }
                        sx={{ fontSize: '11px', ...cursorPointerStyle }}
                      />
                    </FilterChip>
                  )
                );
              });
            })}
            {appliedFiltersCount > 1 && (
              <ClearLink
                onClick={() => {
                  updateFilterStates('Commercial purpose', []);
                }}>
                {ORIGINATION_LIST_CONTENT.clearAll}
              </ClearLink>
            )}
          </AppliedFilters>
        </div>
        <FiltersPopup
          filterSections={filterSections}
          updateFilters={handleFilterSectionUpdate}
          isFiltersApplied={appliedFiltersCount > 0}
        />
      </SearchAndFilterSection>

      {getDataComponent()}
    </>
  );
};

OriginationProjectList.propTypes = {
  disableVirtualization: PropTypes.bool,
};
export default OriginationProjectList;
