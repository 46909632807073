import styled from 'styled-components';

const FORM_BACKGROUND_COLOR = 'white';
const PAGE_BACKGROUND_COLOR = '#F1f1f1';
const TEXTAREA_BORDER = '97999B';
const SUBTEXT_COLOR = '#63666A';
const MAIN_FONT_COLOR = '#333333';
const ERROR_MESSAGE_COLOR = '#DA291C';
const ADD_PAYMENT_COLOR = '#0076A8';

export const FormHeading = styled.div`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-family: 'Open Sans';
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  line-height: 140%;
  font-style: normal;
  color: ${MAIN_FONT_COLOR};
`;
export const FormSection = styled.div`
  max-width: 37.5rem;
  height: fit-content;
  width: 47%;
  background-color: ${FORM_BACKGROUND_COLOR};
  margin-top: 3.5rem;
  box-shadow: 0px 0.125rem 0.188rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
`;
export const MainWrapper = styled.div`
  background-color: ${PAGE_BACKGROUND_COLOR};
  justify-content: center;
  display: flex;
`;
export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-style: normal;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => (props.iserror ? ERROR_MESSAGE_COLOR : MAIN_FONT_COLOR)};
  line-height: 1.188rem;
`;
export const FormFields = styled.div`
  height: fit-content;
  margin-bottom: 1rem;

  input {
    padding-left: 10px;
  }
`;
export const radioBtnSx = {
  height: '1.125rem',
  width: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
};
export const RadioButtonlabel = styled.span`
  margin-left: 0.5rem;
  font-weight: 400;
  margin-right: 5.25rem;
  line-height: 1.188rem;
  font-size: 0.875rem;
`;
export const OptionalLabel = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0.013rem;
  margin-left: 0.5rem;
  line-height: 1rem;
  opacity: 0.5;
  color: ${SUBTEXT_COLOR};
`;
export const AddProjectWrapper = styled.div`
  margin-top: 1rem;
  cursor: pointer;
`;
export const inputNumberSx = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  width: '8.125rem',
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  color: ${MAIN_FONT_COLOR};
  font-weight: 400;
`;
export const InputSubtext = styled.span`
  font-family: 'Open Sans';
  margin-left: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.188rem;
  font-size: 0.875rem;
  opacity: 0.5;
  color: ${SUBTEXT_COLOR};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
  :first-child {
    margin-right: 24px;
  }
`;
export const AddprojectLabel = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  line-height: 1.188rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  color: ${ADD_PAYMENT_COLOR};
`;
export const TextArea = styled.textarea`
  resize: none;
  height: 200px;
  width: 98%;
  font-family: 'Open Sans';
  border: 1px solid ${TEXTAREA_BORDER};
  padding: 5px 0 0 10px;
  font-style: normal;
  &::placeholder {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
    font-size: 14px;
    opacity: 0.5;
    color: ${SUBTEXT_COLOR};
  }
`;
export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  line-height: 1rem;
  font-size: 0.75rem;
  padding-left: 0.3rem;
  letter-spacing: 0.013rem;
  color: ${ERROR_MESSAGE_COLOR};
`;

export const ProjectsLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
`;
