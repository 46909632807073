import styled from 'styled-components';
import {
  BLUISH_CYAN,
  DARK_CHARCOAL,
  DOWNLOAD_LINK,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';

export const AccordionHeaderText = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  color: ${DARK_CHARCOAL};
  font-family: 'Open Sans', sans-serif;
  padding: 6px 8px 6px 8px;
`;
export const SmallUploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 'fit-content')};
  width: ${({ width }) => (width ? width : '28.25rem')};
  background: ${({ background }) => (background ? background : WHITE)};
  cursor: pointer;
  padding: ${({ padding }) => (padding ? padding : '1.5rem 0.625rem')};
  border-radius: 2px;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%50' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const UploadHelpText = styled.div`
  margin-top: 0.25rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1.125rem;
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
  display: ${({ invisible }) => (invisible ? 'none' : 'block')};
`;
export const DownloadButton = styled.button`
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  font: inherit;
  text-align: inherit;
  cursor: pointer;
  color: ${DOWNLOAD_LINK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
export const AccordionSummarySx = {
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  padding: '8px 0px 8px 0px',
  border: '0px 0px 1px 0px',
  borderColor: `${QUILL_GRAY}`,
  borderStyle: 'solid',
  borderBottomWidth: '1px',
  margin: '0px',
  '.MuiAccordionSummary-content': {
    margin: '0px',
  },
  '.Mui-expanded': {
    margin: '0px',
  },
  '&$expanded': {
    margin: '0px',
    height: '3rem',
  },
};
export const AddIconSx = {
  margin: '0px',
  width: '0.5831rem',
  height: '0.5831rem',
  padding: '0px',
};
export const AddSoilSamplingButtonSx = {
  '& .MuiButton-startIcon': { marginRight: '0px' },
  '&.MuiButtonBase-root:hover': {
    backgroundColor: 'transparent',
    border: `1px solid ${BLUISH_CYAN}`,
  },
  color: BLUISH_CYAN,
  border: `1px solid ${BLUISH_CYAN}`,
  width: '7.6875rem',
  height: '1.75rem',
  textTransform: 'none',
  padding: '0px',
  gap: '4px',
};
export const AddSoilSamplingButtonLabelSx = {
  fontWeight: 400,
  fontSize: '0.875rem',
  margin: '0px',
};
export const AccordionDetailsSx = {
  display: 'flex',
  justifyContent: 'center',
  padding: '0px',
};
export const TableHeadRowSx = {
  height: '2.5rem',
  border: '0px 0px 1px 0x',
  borderColor: `${QUILL_GRAY}`,
};
export const TableRowSx = {
  border: '0px',
  height: '3rem',
};
export const TableCellHeaderSx = {
  textAlign: 'left',
  margin: '0px ',

  padding: '0px 8px 0px 8px',
  width: '41.57%',
};
export const TableCellSx = {
  textAlign: 'left',
  border: '0px',
  margin: '0px ',
  padding: '0px 48px 0px 48px',
  width: '41.57%',
  color: DOWNLOAD_LINK,
};
export const TableCellEmptySx = {
  textAlign: 'center',
  margin: '0px ',
  fontWeight: 700,
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  padding: '0px 48px 0px 48px',
  width: '100%',
  border: '0px 0px 1px 0px',
  borderColor: `${QUILL_GRAY}`,
  color: `${DARK_CHARCOAL}`,
};
export const TableRowEmptySx = {
  height: '2.5rem',
  border: '0px 0px 1px 0x',
  borderColor: `${QUILL_GRAY}`,
};
export const TableSx = {
  width: '100%',
  tableLayout: 'fixed',
};
export const TableContainerSx = {
  display: 'flex',
  justifyContent: 'center',
};
export const AccordionHeaderWrapperSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
