import { StyleSheet } from '@react-pdf/renderer';
import styled from 'styled-components';
import { WHITE } from 'theme/GlobalColors';

export const styles = StyleSheet.create({
  pdfContainer: {
    height: '100vh',
    width: '100%',
  },
  firstPage: {
    flexDirection: 'column',
    backgroundColor: WHITE,
    gap: '20px',
  },
  page: {
    flexDirection: 'column',
    backgroundColor: WHITE,
    gap: '20px',
    paddingBottom: '90px',
    paddingTop: '80px',
  },
  section: {
    margin: '0px 25px',
    padding: '0 10px',
  },
  mainheading: {
    margin: '12px 0 20px',
    textTransform: 'uppercase',
  },
});

export const MainDiv = styled.div`
  margin-top: -1rem;
`;
