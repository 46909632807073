import styled from 'styled-components';

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #0076a8;
  cursor: pointer;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0rem')};
  margin-bottom: ${(props) => props.marginBottom ?? '0rem'};
  width: fit-content;
`;

export const BackIcon = styled.div`
  padding-top: 0.031rem;
  stroke: #0076a8;
`;

export const BackButtonLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4875rem;
  margin-left: 0.375rem;
`;
