import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  NEUTRAL_BACKGROUND_COLOR,
  QUILL_GRAY,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: baseline;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const submitButtonStyle = {
  background: DARK_GREEN,
  color: 'white',
  textTransform: 'none',
  '&.Mui-disabled': {
    color: COOL_GRAY,
    backgroundColor: QUILL_GRAY,
  },
};
export const cancelButtonStyle = {
  color: DARK_GREEN,
  textTransform: 'none',
  '&:hover': {
    background: '#efefef',
  },
};
export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color || DARK_CHARCOAL};
  cursor: ${({ cursor }) => cursor};
  margin-bottom: 0.625rem;
  font-style: normal;
  width: fit-content;
`;
export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '31.25rem',
      maxHeight: '31.25rem',
      overflowX: 'hidden',
      overflowY: 'auto',

      '.MuiDialogContent-root': {
        overflowX: 'hidden',
      },
    },
  },
};

export const DialogTitleStyle = {
  fontSize: '1rem',
  padding: '1rem 0rem 0rem 1.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  color: DARK_CHARCOAL,
  fontWeight: 600,
};

export const CloseIconButtonSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
  zIndex: 950,
};

export const DilogTitleSx = {
  padding: '1rem 0 0 0',
  fontFamily: 'Open sans, sans serif',
  fontSize: '1rem',
  fontWeight: 600,
};

export const DialogContentSx = {
  marginRight: '1rem',
};

export const ActivityTypeDropdownStyleProps = {
  width: '18.75rem',
  minWidth: '18.75rem',
  fontSize: '0.875rem',
  height: '2rem',
};

export const YearDropdownStyleProps = {
  width: '5rem',
  minWidth: '5rem',
  fontSize: '0.875rem',
  height: '2rem',
};

export const DatePickerStyleProps = {
  minWidth: '8.125rem',
  width: '8.125rem',
  height: '2rem',
  fontSize: '0.875rem',
};

export const SubsectionWrapper = styled.div`
  margin-left: 0.75rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const historicallySx = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      marginTop: '0.65rem',
      width: '11%',
    },
  };
};

export const disabledInputSx = {
  backgroundColor: NEUTRAL_BACKGROUND_COLOR,
  borderBottom: `1px solid ${COOL_GRAY}`,
  '&.Mui-disabled': {
    color: TYPOGRAPHY_GRAY,
  },
  fontSize: '0.875rem',
  fontWeight: 400,
};

export const InputSx = {
  fontSize: '0.875rem',
  fontWeight: 400,
  '& ::placeholder': {
    color: COOL_GRAY,
    opacity: 1,
  },
};

export const flexDisplay = {
  display: 'flex',
  alignItems: 'center',
};

export const instructionTextStyles = { margin: '0 0 0.5rem 0' };

export const ActivityDisclaimer = styled.div`
  width: 444px;
  margin: 0.25rem 0rem 0.5rem 0.5rem;
  color: ${COOL_GRAY};
  font-size: 0.688rem;
  line-height: 14.98px;
`;
export const BoldTextWrapper = styled.span`
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
