import { StyleSheet } from '@react-pdf/renderer';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  NEUTRAL_BACKGROUND_COLOR,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderColor: QUILL_GRAY,
    borderWidth: 1,
    alignItems: 'center',
    height: 32,
    fontStyle: 'bold',
    backgroundColor: NEUTRAL_BACKGROUND_COLOR,
  },
  participantName: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 8,
    width: '50%',
    borderRightColor: QUILL_GRAY,
    borderRightWidth: 1,
    fontWeight: 400,
    fontSize: 14,
  },
  field: {
    width: '20%',
    textAlign: 'left',
    borderRightColor: QUILL_GRAY,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
  },
  date: {
    width: '25%',
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
  },
  subheading: {
    fontSize: '14px',
    color: COOL_GRAY,
    margin: '0 0 16px',
  },
  totalParticipants: {
    borderColor: DARK_CHARCOAL,
    borderWidth: 1,
    padding: '2px 2px 0px 3px',
    borderRadius: '12px',
    backgroundColor: WHITE,
    marginLeft: '8px',
    fontSize: '11px',
  },
});
