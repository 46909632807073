import PropTypes from 'prop-types';
import { ReactComponent as AddIcon } from '../../assets/icons/addicon.svg';
import { AddButtonLabel, AddButtonWrapper } from './AddSection.style';

const AddSectionButton = ({
  onClickHanlder,
  hiddenFlag,
  label,
  marginTop,
  hasBorder = false,
  width = '100%',
}) => {
  return (
    <>
      <AddButtonWrapper
        marginTop={marginTop}
        onClick={onClickHanlder}
        hidden={hiddenFlag}
        hasBorder={hasBorder}
        width={width}>
        <AddIcon />
        <AddButtonLabel>{label}</AddButtonLabel>
      </AddButtonWrapper>
    </>
  );
};

AddSectionButton.propTypes = {
  onClickHanlder: PropTypes.func,
  hiddenFlag: PropTypes.bool,
  label: PropTypes.string,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasBorder: PropTypes.bool,
};

export default AddSectionButton;
