import BaselineModal from 'pages/Origination/Org_Project/Participants/BaselineModal/BaselineModal.react';
import React from 'react';
import { useActivityContext } from 'containers/ActivityListContainer';

export const BaselineActivityAction = () => {
  const {
    baselineModalProps,
    setBaselineModalProps,
    handleBaselineSendRequest,
    statusMappingList,
    setLoading,
    selectedBaselineOption,
    setSelectedBaselineOption,
  } = useActivityContext();

  return (
    <BaselineModal
      open={baselineModalProps.open}
      handleClose={() => {
        setBaselineModalProps((prev) => ({ ...prev, open: false }));
        setSelectedBaselineOption(null);
      }}
      formId={baselineModalProps.formId}
      handleSendRequest={handleBaselineSendRequest}
      handleOnBehalf={handleBaselineSendRequest}
      navigateLink={baselineModalProps.navigateLink}
      navigateState={baselineModalProps.navigateState}
      participantName={baselineModalProps.participantName}
      actionId={baselineModalProps.actionId}
      selectedBaselineOption={selectedBaselineOption}
      setSelectedBaselineOption={setSelectedBaselineOption}
      statusMappingList={statusMappingList}
      setLoading={setLoading}
      actionCode={baselineModalProps.actionCode}
    />
  );
};
