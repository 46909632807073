import React from 'react';
import PropTypes from 'prop-types';
import Tabber from 'components/Tabber/Tabber.react';

const FieldInfoWithTabber = ({
  currentTab,
  setCurrentTab,
  tabList,
  tabChangeCallBack,
}) => {
  const handleTabChange = (tab) => {
    if (tab === 1) {
      tabChangeCallBack();
    }
  };

  return (
    <Tabber
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      tabList={tabList}
      onTabChange={handleTabChange}
    />
  );
};

FieldInfoWithTabber.propTypes = {
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
};

export default FieldInfoWithTabber;
