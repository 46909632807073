import styled from 'styled-components';
import { COOL_GRAY, WHITE } from 'theme/GlobalColors';
import { SortColValues } from './Transaction.content';

export const DialogWrapper = styled.div`
  max-height: 6.25rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  flex-warp: wrap;
  overflow: hidden;
  color: #686868;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const datagridSx = {
  width: 'auto',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  marginTop: '0.375rem',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  borderRadius: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
};

export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 50%;
  &:hover {
    background-color: #e3dada36;
  }
`;

export const handleArrowColor = (col, sortColumn) => {
  return {
    color: sortColumn === SortColValues[col] ? COOL_GRAY : WHITE,
    '&:hover': {
      color: COOL_GRAY,
    },
  };
};

export const SearchLayout = styled.div`
  display: flex;
  justify-content: center;
`;

export const addBtnSx = {
  padding: '6px 8px',
  height: '1.75rem',
};
