import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  TextWrapper,
  closeIconSx,
  DialogContentStyle,
} from '../Modals.style';
import {
  MODAL_CONTENT,
  initialTillageModalData,
  initialTillageModalErrors,
} from './TillageModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { useForm } from 'hooks/useForm';
import {
  DatePickerWithDelete,
  deleteIconStyles,
  dropdownStyleProps,
  PercentageWrapper,
  pickerWithDeleteMargin,
  tillageDepthUnitStyles,
  AddOptionWrapper,
} from './TillageModal.style';
import {
  checkTernaryCondition,
  convertTimeStampFormat,
  verifyDateFormat,
} from 'utils/helper';
import { handleTextFieldValueChange } from '../Modals.content';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { ReactComponent as Calendericon } from '../../../../../../assets/icons/calenderIcon.svg';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import AddIcon from '@mui/icons-material/Add';
import {
  DARK_CHARCOAL,
  DARK_GREEN,
  GLOBAL_BLUE,
  RED,
  TEXT_HOVER_COLOR,
} from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { DATE_FORMAT } from 'utils/config';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import moment from 'moment';

const TillageModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler = () => null,
  hasTestDefaultDate,
}) => {
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    initialTillageModalData,
  );

  const { tillageTypeDropdown, tillIds, selectedTillagePractice } = useContext(
    baselineDropdownContext,
  );

  const [errorState, setErrorState] = useState(initialTillageModalErrors);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [openDatePickersIndex, setOpenDatePickersIndex] = useState([]);
  const [onHoverDateApplied, setOnHoverDateApplied] = useState(false);
  const [tillageDateError, setTillageDateError] = useState({
    flag: false,
    indexArray: [],
  });
  const [duplicatedDate, setDuplicateDate] = useState({
    flag: false,
    dupIndexArray: [],
  });
  const [hoveredPickerIndex, setHoveredPickerIndex] = useState(-1);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const tillageDatePickerProps = (index) => {
    return {
      label: MODAL_CONTENT.tillageDatePickerLabel,
      inputFormat: DATE_FORMAT,
      icon: Calendericon,
      width: '8rem',
      height: '2rem',
      placeholder: MODAL_CONTENT.tillageDatePickerPlaceholder,
      fontSize: '0.875rem',
      minWidth: '7.5rem',
      errorMessage: '',
      error:
        errorState.tillageDates.indexes.includes(index) ||
        tillageDateError.indexArray.includes(index) ||
        duplicatedDate.dupIndexArray.includes(index),
      customSelectedDateColor: DARK_GREEN,
    };
  };

  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialTillageModalData);
    setIsSubmitDisabled(false);
    setIsSubmitClicked(false);
    setTillageDateError({ flag: false, indexArray: [] });
    setDuplicateDate({ flag: false, dupIndexArray: [] });
  };

  const setModalErrorState = (value, fieldName, indexVal) => {
    switch (fieldName) {
      case 'tillagePractice':
        setErrorState({ ...errorState, tillagePractice: value === '' });
        break;
      case 'customTillagePractice':
        setErrorState({ ...errorState, customTillagePractice: value === '' });
        break;
      case 'tillageDates':
        setErrorState({
          ...errorState,
          tillageDates: {
            flag:
              [...errorState.tillageDates.indexes].filter(
                (index) => index !== indexVal,
              ).length > 0 || value === '',
            indexes: [...errorState.tillageDates.indexes].filter(
              (index) => index !== indexVal,
            ),
          },
        });
        break;
      case 'surfaceDisturbance':
        setErrorState({
          ...errorState,
          surfaceDisturbance:
            value === '' && tillIds.includes(formValue.tillagePractice),
        });
        break;
      case 'depthOfTillage':
        setErrorState({
          ...errorState,
          depthOfTillage: value === '',
        });
        break;
    }
  };

  const handleTillageDateDelete = (index) => {
    const positionOfIndex = errorState.tillageDates.indexes.indexOf(index);
    setErrorState({
      ...errorState,
      tillageDates: {
        flag:
          [...errorState.tillageDates.indexes].filter((item) => item !== index)
            .length > 0,
        indexes: [...errorState.tillageDates.indexes]
          .filter((item) => item !== index)
          .map((dateVal, elementIndex) =>
            dateVal === 0
              ? dateVal
              : checkTernaryCondition(
                  elementIndex >= positionOfIndex,
                  dateVal - 1,
                  dateVal,
                ),
          ),
      },
    });
    const existingDates = formValue?.tillageDates;
    const filteredDates = existingDates.filter(
      (_, dateIndex) => dateIndex !== index,
    );
    customFieldChangeHandler('tillageDates', filteredDates);
    const filteredDuplicateArray = [...duplicatedDate.dupIndexArray].filter(
      (item) => item !== index,
    );
    setDuplicateDate({
      flag: filteredDuplicateArray.length > 0,
      dupIndexArray: filteredDuplicateArray,
    });
  };

  const updateTillageDate = (newDate, dateIndex) => {
    const existingDates = formValue?.tillageDates;
    if (newDate === null) {
      customFieldChangeHandler('tillageDates', [...existingDates, newDate]);
    } else if (newDate === undefined) {
      customFieldChangeHandler(
        'tillageDates',
        existingDates.map((date, index) =>
          checkTernaryCondition(
            index === dateIndex,
            MODAL_CONTENT.tillageDatePickerPlaceholder,
            date,
          ),
        ),
      );
    } else {
      customFieldChangeHandler(
        'tillageDates',
        existingDates.map((date, index) =>
          checkTernaryCondition(index === dateIndex, newDate, date),
        ),
      );
    }
  };

  const datesAreNotFilledOrInvalid = () => {
    return (
      formValue.tillageDates.includes(null) ||
      formValue.tillageDates
        .map((item) => moment(item).format('YYYY-MM-DD'))
        .includes(MODAL_CONTENT.invalid_date)
    );
  };
  //  datesAreNotFilled() || tillageDateError.flag || findRequiredArray()
  const handleSubmit = () => {
    setIsSubmitClicked(true);
    findRequiredArray();
    const currentErrorStates = {
      tillagePractice: formValue.tillagePractice === '',
      customTillagePractice:
        formValue.tillagePractice ===
          tillageTypeDropdown.find((type) => type.label === 'Other')?.value &&
        formValue.customTillagePractice === '',
      tillageDates: findRequiredArray(),
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        initialTillageModalErrors,
        currentErrorStates,
      ),
    );
    const formHasError = Object.values({
      ...currentErrorStates,
      tillageDates:
        currentErrorStates.tillageDates.flag ||
        datesAreNotFilledOrInvalid() ||
        tillageDateError.flag,
    }).includes(true);
    if (!formHasError || noDataOptionSelected) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.tillage_list,
      );
      modalCloseHandler();
    }
  };
  const findRequiredArray = () => {
    if (formValue.tillageDates.length > 0) {
      let count = 0;
      let indexArray = [];
      formValue.tillageDates.forEach((date, index) => {
        if (date === null) {
          count++;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setErrorState({
          ...errorState,
          tillageDates: { flag: true, indexes: indexArray },
        });
        return {
          flag: true,
          indexes: indexArray,
        };
      } else {
        setErrorState({
          ...errorState,
          tillageDates: { flag: false, indexes: indexArray },
        });
        return {
          flag: false,
          indexes: indexArray,
        };
      }
    }
  };
  const tillagePracticeOtherOptionValue = useMemo(() => {
    const otherOption = tillageTypeDropdown.find(
      (tillageType) => tillageType.label === 'Other',
    );
    if (otherOption) return otherOption.value;
    return -1;
  }, [tillageTypeDropdown]);

  useEffect(() => {
    setErrorState(initialTillageModalErrors);
    if (modalOpen.flag && modalOpen.data !== null) {
      let modalData = modalOpen.data.formData?.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      if (modalData?.tillageDates?.length === 0) {
        modalData = {
          ...modalData,
          tillageDates: [null],
        };
      }
      setFormValue(
        checkTernaryCondition(modalData, modalData, initialTillageModalData),
      );
    }
  }, [modalOpen]);
  useEffect(() => {
    if (formValue.tillageDates.length > 0) {
      let count = 0;
      let indexArray = [];
      formValue.tillageDates.forEach((date, index) => {
        if (
          date !== null &&
          (new Date(date) > new Date(`${+modalOpen.data?.year}-12-31`) ||
            new Date(date) < new Date(`${modalOpen.data?.year - 2}-12-31`))
        ) {
          count++;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setTillageDateError({ flag: true, indexArray: indexArray });
      } else {
        setTillageDateError({ flag: false, indexArray: indexArray });
      }
    }
  }, [formValue.tillageDates]);

  useEffect(() => {
    if (tillageTypeDropdown?.length > 0) {
      const newTillageType = formValue.tillagePractice;
      const value = tillageTypeDropdown.find(
        (tillageType) => tillageType?.value === newTillageType,
      )?.label;
      if (value === MODAL_CONTENT.noTillageOption) {
        setFormValue({
          ...initialTillageModalData,
          tillagePractice: newTillageType,
        });
        setNoDataOptionSelected(true);
        setErrorState(initialTillageModalErrors);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.tillagePractice, tillageTypeDropdown]);

  useEffect(() => {
    const isAnyDateInvalid = formValue.tillageDates
      .map((item) => moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    if (isSubmitClicked) {
      setIsSubmitDisabled(
        Object.values(errorState).includes(true) ||
          formValue.tillageDates.includes(null) ||
          isAnyDateInvalid ||
          tillageDateError.flag ||
          duplicatedDate.flag,
      );
    } else {
      setIsSubmitDisabled(
        Object.values(errorState).includes(true) ||
          tillageDateError.flag ||
          duplicatedDate.flag,
      );
    }
  }, [errorState, tillageDateError, isSubmitClicked, formValue.tillageDates]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={
          selectedTillagePractice === MODAL_CONTENT.noTillageOption
            ? {
                padding: '0rem',
                marginBottom: '1rem',
                '&.MuiDialogContent-root': {
                  overflowY: 'clip',
                },
              }
            : DialogContentStyle
        }>
        <DropDown
          isDisabled={
            modalOpen.data?.formData[0]?.addedFromImplementedActivities || false
          }
          customPlaceholderColor={DARK_CHARCOAL}
          hasNoBottomMargin={true}
          value={checkTernaryCondition(
            formValue.tillagePractice === '',
            MODAL_CONTENT.tillagePracticePlaceholder,
            formValue.tillagePractice,
          )}
          label={MODAL_CONTENT.tillagePracticeLabel}
          fontSize={'0.875rem'}
          listItemFontSize={'0.875rem'}
          error={errorState.tillagePractice}
          errorMessage={MODAL_CONTENT.tillagePracticeErrorMsg}
          setFormFieldValue={customFieldChangeHandler}
          stateValue={formValue.tillagePractice}
          setStateValue={() => {}}
          name={MODAL_CONTENT.tillagePracticeField}
          field_key={MODAL_CONTENT.tillagePracticeField}
          dropDownPlaceholder={MODAL_CONTENT.tillagePracticePlaceholder}
          dropdownlist={
            selectedTillagePractice === MODAL_CONTENT.noTillageOption
              ? tillageTypeDropdown.filter(
                  (x) => x.label === selectedTillagePractice,
                )
              : tillageTypeDropdown.filter(
                  (x) => x.label !== MODAL_CONTENT.noTillageOption,
                )
          }
          onUpdate={() => setIsSubmitClicked(false)}
          setModalErrorState={setModalErrorState}
          fieldName={MODAL_CONTENT.tillagePracticeField}
          showLabelAsValue={true}
          {...dropdownStyleProps}
        />
        {!noDataOptionSelected && (
          <>
            {tillagePracticeOtherOptionValue === formValue?.tillagePractice && (
              <InputField
                width="100%"
                required
                noLabel
                name={MODAL_CONTENT.customTillagePracticeField}
                value={formValue.customTillagePractice}
                onUpdate={(event) => {
                  handleTextFieldValueChange(
                    event,
                    MODAL_CONTENT.customTillagePracticeField,
                    false,
                    customFieldChangeHandler,
                  );
                  setIsSubmitClicked(false);
                  setModalErrorState(
                    event.target.value,
                    MODAL_CONTENT.customTillagePracticeField,
                  );
                }}
                placeholder={MODAL_CONTENT.customTillagePracticePlaceholder}
                primaryError={errorState.customTillagePractice}
                primaryErrorMessage={
                  MODAL_CONTENT.customTillagePracticeErrorMsg
                }
                maxLength={100}
                minWidth="10rem"
                customStyles={{ marginTop: '0.5rem' }}
              />
            )}
            {formValue?.tillageDates?.map((tillageDate, index) => {
              const keyVal = index;
              return (
                <DatePickerWithDelete
                  key={keyVal}
                  margin={pickerWithDeleteMargin(index === 0)}
                  onMouseEnter={() => setHoveredPickerIndex(index)}
                  onMouseLeave={() => setHoveredPickerIndex(-1)}>
                  <CustomDatePicker
                    calendarOpenDate={checkTernaryCondition(
                      hasTestDefaultDate,
                      new Date(),
                      `${modalOpen.data?.year}-01-01`,
                    )}
                    hasNoLabel={index > 0}
                    value={tillageDate}
                    open={openDatePickersIndex === index}
                    onUpdate={(newValue) => {
                      const date = newValue?.toDate();
                      updateTillageDate(date, index);
                      setIsSubmitClicked(false);
                      setModalErrorState(
                        newValue,
                        MODAL_CONTENT.tillageDatesField,
                        keyVal,
                      );
                      // Check for duplicate date
                      const duplicatedDateIndex =
                        formValue.tillageDates.findIndex((tillageDate, i) => {
                          const formattedDate =
                            moment(date).format('YYYY-MM-DD');
                          const formattedTillageDate =
                            moment(tillageDate).format('YYYY-MM-DD');
                          return (
                            i !== index &&
                            formattedTillageDate !==
                              MODAL_CONTENT.invalid_date &&
                            formattedDate !== MODAL_CONTENT.invalid_date &&
                            formattedDate === formattedTillageDate
                          );
                        });

                      if (duplicatedDateIndex !== -1) {
                        setDuplicateDate({
                          flag: true,
                          dupIndexArray: [
                            ...duplicatedDate.dupIndexArray,
                            index,
                          ],
                        });
                      }
                      if (
                        duplicatedDateIndex === -1 &&
                        duplicatedDate.dupIndexArray.includes(index)
                      ) {
                        setDuplicateDate({
                          flag:
                            duplicatedDate.dupIndexArray.filter(
                              (item) => item !== index,
                            ).length > 0,
                          dupIndexArray: duplicatedDate.dupIndexArray.filter(
                            (item) => item !== index,
                          ),
                        });
                      }
                      let indexesWithValue = formValue.tillageDates.reduce(
                        function (a, e, i) {
                          if (
                            moment(e).format('YYYY-MM-DD') ===
                            moment(tillageDate).format('YYYY-MM-DD')
                          )
                            a.push(i);
                          return a;
                        },
                        [],
                      );
                      if (indexesWithValue.length >= 2) {
                        indexesWithValue = [...indexesWithValue].filter(
                          (item) => item !== index,
                        );
                      }
                      if (
                        duplicatedDateIndex === -1 &&
                        duplicatedDate.dupIndexArray.includes(
                          indexesWithValue[0],
                        )
                      ) {
                        setDuplicateDate({
                          flag:
                            duplicatedDate.dupIndexArray.filter(
                              (item) => item !== indexesWithValue[0],
                            ).length > 0,
                          dupIndexArray: duplicatedDate.dupIndexArray.filter(
                            (item) => item !== indexesWithValue[0],
                          ),
                        });
                      }
                    }}
                    onClickHandler={() => setOpenDatePickersIndex(index)}
                    onClose={() => setOpenDatePickersIndex(-1)}
                    {...tillageDatePickerProps(index)}
                    hasNoBottomMargin
                  />
                  {formValue.tillageDates.length > 1 && (
                    <DeleteIcon
                      data-testid="DeleteIcon"
                      style={deleteIconStyles(
                        index === 0,
                        !verifyDateFormat(convertTimeStampFormat(tillageDate)),
                        index === hoveredPickerIndex,
                      )}
                      onClick={() => handleTillageDateDelete(index)}
                    />
                  )}
                </DatePickerWithDelete>
              );
            })}
            {errorState.tillageDates.flag && (
              <TextWrapper
                color={RED}
                position="relative"
                top="-19px"
                fontSize="12px">
                {checkTernaryCondition(
                  formValue.tillageDates.length === 1 &&
                    formValue.tillageDates[0] === null,
                  MODAL_CONTENT.tillageDatePickerErrorMsg,
                  MODAL_CONTENT.tillageDatePickerErrorMsg
                    .slice(13, 14)[0]
                    .toUpperCase() +
                    MODAL_CONTENT.tillageDatePickerErrorMsg.slice(14, -1),
                )}
              </TextWrapper>
            )}
            {tillageDateError.flag && (
              <TextWrapper
                color={RED}
                fontSize="12px"
                position="relative"
                top="-19px">
                {MODAL_CONTENT.tillage_date_error}
              </TextWrapper>
            )}
            {duplicatedDate.flag && (
              <TextWrapper
                color={RED}
                position="relative"
                top="-19px"
                fontSize="12px">
                {MODAL_CONTENT.duplicate_date_error}
              </TextWrapper>
            )}
            <AddOptionWrapper
              onMouseEnter={() => {
                setOnHoverDateApplied(true);
              }}
              onMouseLeave={() => {
                setOnHoverDateApplied(false);
              }}
              onClick={() => {
                updateTillageDate(null, formValue.tillageDates.length);
              }}>
              <TextWrapper
                color={checkTernaryCondition(
                  onHoverDateApplied,
                  TEXT_HOVER_COLOR,
                  GLOBAL_BLUE,
                )}
                fontSize="0.875rem"
                gap="0.25rem">
                <AddIcon
                  fontSize="small"
                  sx={{
                    '&:hover': {
                      fill: onHoverDateApplied ? TEXT_HOVER_COLOR : GLOBAL_BLUE,
                    },
                  }}
                />
                {MODAL_CONTENT.addTillageDateLinkText}
              </TextWrapper>
            </AddOptionWrapper>
            <FieldSectionWrapper
              gap="1.5rem"
              marginBottom={checkTernaryCondition(
                tillIds.includes(formValue.tillagePractice),
                '0.25rem',
                '0rem',
              )}>
              <FieldSectionWrapper gap="0.5rem" alignItems="flex-end">
                <InputField
                  label={MODAL_CONTENT.surfaceDisturbanceLabel}
                  name={MODAL_CONTENT.surfaceDisturbanceField}
                  value={formValue.surfaceDisturbance}
                  optionalLabelStyle="-0.7rem"
                  onUpdate={(event) => {
                    const value = parseFloat(event?.target.value);
                    if (
                      event?.target.value === '' ||
                      (event?.target.value !== '100.' &&
                        !isNaN(value) &&
                        value <= 100)
                    ) {
                      if (event?.target.value !== '.') {
                        handleTextFieldValueChange(
                          event,
                          MODAL_CONTENT.surfaceDisturbanceField,
                          true,
                          customFieldChangeHandler,
                        );
                      }
                    }
                  }}
                  isOptional={true}
                  placeholder={MODAL_CONTENT.surfaceDisturbancePlaceholder}
                  maxLength={100}
                  width="9rem"
                />
                <PercentageWrapper>%</PercentageWrapper>
              </FieldSectionWrapper>
            </FieldSectionWrapper>
            <FieldSectionWrapper
              alignItems="flex-end"
              gap="0.5rem"
              marginTop="-0.2rem">
              <InputField
                width="8.9rem"
                isOptional={true}
                optionalLabelStyle="-0.7rem"
                label={MODAL_CONTENT.depthOfTillageLabel}
                name={MODAL_CONTENT.depthOfTillageField}
                value={formValue.depthOfTillage}
                onUpdate={(event) => {
                  handleTextFieldValueChange(
                    event,
                    MODAL_CONTENT.depthOfTillageField,
                    true,
                    customFieldChangeHandler,
                  );
                }}
                placeholder={MODAL_CONTENT.depthOfTillagePlaceholder}
                maxLength={100}
                minWidth="12rem"
              />
              <div style={tillageDepthUnitStyles(errorState.depthOfTillage)}>
                {MODAL_CONTENT.inch_unit}
              </div>
            </FieldSectionWrapper>
          </>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={handleSubmit}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TillageModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
  hasTestDefaultDate: PropTypes.bool,
};

export default TillageModal;
