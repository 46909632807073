import { RED } from 'theme/GlobalColors';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { stringToArray } from './ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.content';
import {
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
} from 'utils/helper';

export const PARTICAPNT_INFO = [
  {
    id: 1,
    status: '',
    value: '',
  },
];

export const FARM_INFO = [
  {
    id: -1,
    status: 'in progress',
    value: 'Farm 1',
    fields: [],
    isApiPopulated: false,
  },
];

export const FARM_AND_CONTRIBUTOR_INFO = [
  {
    id: 1,
    status: 'Not Started',
    value: 'Add farms',
  },
];

export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const PAGE_CONTENT = {
  save_and_close_btn: 'Save & Close',
  enrollmentLabel: ' Enrollment',
  cancel_btn: 'Cancel',
  review_and_enroll_btn: 'Review & Enroll',
  fieldAddTypeInstructionText: 'Add fields using selection method. ',
  enrollmentFormPath: '/stakeholders/participant-data-collection',
  unsavedChangesPromptMsg:
    "You've made updates that haven't been saved yet. Would you like to save before closing?",
  participant: 'Participant',
  submit_complete_modal_title: 'All enrollment forms are complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to add more fields to enroll.',
  cancel_btn_label: 'Cancel',
  continue_btn_label: 'Continue to add fields',
  review_btn_label: 'Review and submit',
  farm_and_contributor_title: 'Farm and contributor',
  noFarmsDefaultText:
    'Farms added during farm setup will be listed here for you or your contributors to continue adding farm and field info.',
  completed: 'Completed',
  in_progress: 'In Progress',
};

export const SHAPE_FILE_UPLOAD_CONTENT = {
  modalTitle: 'Upload field boundary shape file(s)',
  dragAndDropSectionTitle:
    'Would you like to upload any farm or field boundary shape files?',
  dragAndDropInsideText: 'Drag and drop shape file here',
  cancelText: 'Cancel',
  uploadText: 'Upload',
  acceptedMimeType: 'application/zip',
  acceptedExtensions: ['.zip'],
  maxNoOfFiles: 1,
  maxFileSize: 1024 * 1024 * 6, //6MB
  minFileSize: 1024, //1KB
  infoText:
    'Please upload one file of each format .shp, .prj, and .dbf  in a .zip file.',
  infoTextList: [
    'Please upload one file of each format .shp, .prj, and .dbf  in a .zip file.',
    'Please note any duplicate or overlapping fields already added to map will be replaced.',
  ],
  fileNotSupportedError:
    'File not supported. Please upload a .zip file containing .shp, .prj, .dbf, .xml files',
  fieldOverlappingInfoText:
    'Please note any duplicate or overlapping fields already added to map will be replaced.',
};

export const breadcrumbData = [
  { id: uniqueId(), title: 'Home' },
  { id: uniqueId(), title: 'Participant profile' },
  { id: uniqueId(), title: 'Farm enrollment' },
  { id: uniqueId(), title: 'Field selection' },
  { id: uniqueId(), title: 'Field enrollment' },
];

export const fieldAddTypes = ['Boundary file', 'Draw boundary', 'Drop pin'];

export const convertStatus = (status) => {
  if (status === 'Completed') return 'complete';
};

export const mapControlOptions = {
  mapTypeId: 'satellite',
  zoom: 10,
  labels: true,
};

export const errorPolygonOptions = {
  fillColor: RED,
  strokeColor: RED,
};

const isFieldWebformCheck = (getCurrentFarm, farm, isFarmWebform) => {
  return getCurrentFarm.id === farm.id && !isFarmWebform;
};

export const handleNextInProgrssSwitcher = (
  inProgressItem,
  updatedFarmInfo,
  getCurrentFarm,
  updatedFieldId,
  isFarmWebform,
) => {
  const currentFarmIndex = updatedFarmInfo.findIndex(
    (farm) => farm.id === getCurrentFarm.id,
  );
  const farms = updatedFarmInfo.filter((_, index) => index >= currentFarmIndex);
  for (const farm of farms) {
    let currentFieldIndex = -1;
    if (isFieldWebformCheck(getCurrentFarm, farm, isFarmWebform)) {
      currentFieldIndex = farm.fields.findIndex(
        (field) => field.id === updatedFieldId.fieldId,
      );
    }
    if (!farm.formValidationFlag && getCurrentFarm.id !== farm.id) {
      inProgressItem = { type: 'farm', id: farm.id };
      break;
    }
    if (inProgressItem !== null) break;

    let fields = farm.fields.filter((_, index) => index > currentFieldIndex);
    for (const field of fields) {
      if (field.status.toLowerCase() === 'in progress') {
        inProgressItem = {
          type: 'field',
          id: field.id,
          value: field.value,
          farmId: farm.id,
        };
        break;
      }
    }

    if (inProgressItem !== null) break;
  }
  return inProgressItem;
};

export const checkIsEnrollmentCompleted = (
  farmData,
  formValidationStatus,
  profileStatus,
  isParticipantContributor,
) => {
  return (
    farmData.every(
      (farm) =>
        (farm.status.toLowerCase() === 'completed' ||
          farm.status.toLowerCase() === 'complete') &&
        farm.fields &&
        farm.fields.every(
          (field) =>
            field?.status?.toLowerCase() === 'completed' ||
            field?.status?.toLowerCase() === 'complete',
        ),
    ) &&
    formValidationStatus &&
    (profileStatus === 'complete' ||
      profileStatus === 'Completed' ||
      profileStatus === 'CP' ||
      isParticipantContributor)
  );
};

export const enrollmentInstanceStatus = {
  approved: 'APPROVED',
  notApproved: 'NOT_APPROVED',
};

export const getFieldAreaExceedingError = (fieldAreaSum, farmArea) => {
  return `Total sum of field area uploaded of ${fieldAreaSum.toFixed(
    2,
  )} acres is greater than the total farm area entered of ${farmArea} acres. Please update the farm area entered.`;
};

const getFmsList = (data) => {
  if (typeof data === 'string') {
    return stringToArray(data);
  }
  return [];
};

export const formatBulkFarmSubmitData = (
  farmsData,
  projectId,
  enrollmentInstanceId,
  projectCycleId,
) => {
  return farmsData.map((farm) => {
    return {
      projectCycleId: Number(projectCycleId),
      agronomistUse: farm.agronomistUse ?? '',
      agronomistUseOrganization: farm.agronomistUseOrganization ?? '',
      carbonRightsAssociated: farm.carbonRightsAssociated,
      city: farm.farmCity,
      contributorStatus: checkTernaryCondition(
        farmHasErrors(farm),
        farmAndContributorTabStatus.inProgress,
        farmAndContributorTabStatus.completed,
      ),
      county: farm.farmCounty,
      cropArea: farm.cropArea,
      csafFederalFunding: farm.csafFederalFunding,
      csafImplemented: farm.csafImplemented ?? '',
      csafMarketIncentives: farm.csafImplemented,
      csafNonProfitFunding: farm.csafImplemented,
      csafStateFunding: farm.csafImplemented,
      enrollmentInstanceId: Number(enrollmentInstanceId),
      entityName: farm.entityName,
      extentCsafImplemented: farm.extentCsafImplemented,
      farmId: checkTernaryCondition(
        typeof farm.farmId === 'string',
        0,
        farm.farmId,
      ),
      farmLivestock1Id: farm.farmLivestock1Id ?? '',
      farmLivestock1headcount: farm.farmLivestock1headcount ?? '',
      farmLivestock2Id: farm.farmLivestock2Id ?? '',
      farmLivestock2headcount: farm.farmLivestock2headcount ?? '',
      farmLivestock3Id: farm.farmLivestock3Id ?? '',
      farmLivestock3headcount: farm.farmLivestock3headcount ?? '',
      farmName: farm.farmName ?? '',
      farmZipcode: farm.farmZipcode,
      fieldsOrganicStatus: farm.fieldsOrganicStatus,
      firstName: farm.firstName,
      fmsList: getFmsList(farm.fmsList),
      fmsUsed: farm.fmsUsed,
      forestArea: farm.forestArea,
      formStatus: farm.formStatus ?? 'Not Started',
      formValidationFlag: farm.formValidationFlag ?? false,
      fsaId: farm.fsaId,
      fsaIdType: farm.fsaIdType,
      internalProjectId: Number(projectId),
      lastName: farm.lastName ?? '',
      livestockArea: farm.livestockArea,
      mailingAddress: farm.farmMailingAddress,
      organicStatus: farm.farmOrganicStatus,
      soilSampling: farm.soilSampling,
      state: farm.farmState,
      totalFarmAcreage: farm.totalFarmAcreage,
    };
  });
};

export const farmAndContributorTabStatus = {
  inProgress: 'In Progress',
  completed: 'Completed',
  notStarted: 'Not Started',
};

// Note: Refers to add farm and contributor page
export const getFarmErrors = (farm) => {
  return {
    farmNameRequiredError: isValueEmptyNullorUndefined(farm.farmName),
    fsaIdRequiredError: isValueEmptyNullorUndefined(farm.fsaId),
    fsaIdTypeRequiredError: isValueEmptyNullorUndefined(farm.fsaIdType),
    entityNameRequiredError:
      farm.fsaIdType === 'Entity' &&
      isValueEmptyNullorUndefined(farm.entityName),
    firstNameRequiredError:
      farm.fsaIdType === 'Individual' &&
      isValueEmptyNullorUndefined(farm.firstName),
    lastNameRequiredError:
      farm.fsaIdType === 'Individual' &&
      isValueEmptyNullorUndefined(farm.lastName),
    mailingAddressRequiredError: isValueEmptyNullorUndefined(
      farm.farmMailingAddress,
    ),
    cityRequiredError: isValueEmptyNullorUndefined(farm.farmCity),
    stateRequiredError: isValueEmptyNullorUndefined(farm.farmState),
    countyRequiredError: isValueEmptyNullorUndefined(farm.farmCounty),
    zipCodeRequiredError: isValueEmptyNullorUndefined(farm.farmZipcode),
  };
};

export const farmHasErrors = (farm) => {
  const farmErrorObj = getFarmErrors(farm);
  return Object.values(farmErrorObj).includes(true);
};

export const LEFT_MENU_SECTION_HEADERS = {
  profileSection: 'Participant',
  farmAndContributorSection: 'Farm and contributor',
  farmAndFieldsSection: 'Farm and fields',
};
