export const MODAL_CONTENT = {
  label: 'Irrigation method',
  dropdown_placeholder: 'Select irrigation method',
  irrigation_rate_label: 'Irrigation rate',
  irrigation_rate_placeholder: 'Type amount',
  uom_label: 'Unit of measure',
  uom_placeholder: 'Select type',
  frequency_placeholder: 'Select time',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  heading: 'Irrigation',
  type_error: 'Irrigation method selection is required.',
  rate_error: 'Irrigation rate is required.',
  uom_error: 'Unit of measure selection is required.',
  freq_error: 'Frequency selection is required.',
  noneOption: 'None',
};

export const irrigationOptions = [
  { label: 'Center Pivot', value: 'Center Pivot' },
  { label: 'Drip-Subsurface', value: 'Drip-Subsurface' },
  { label: 'Drip-Surface', value: 'Drip-Surface' },
  { label: 'Flood/Border', value: 'Flood/Border' },
  { label: 'Furrow/Ditch', value: 'Furrow/Ditch' },
  { label: 'Lateral/Linear Sprinkler', value: 'Lateral/Linear Sprinkler' },
  { label: 'Miro-sprinklers', value: 'Miro-sprinklers' },
  { label: 'Seepage', value: 'Seepage' },
  { label: 'Side Roll', value: 'Side Roll' },
  { label: 'Solid Set Sprinklers', value: 'Solid Set Sprinklers' },
  { label: 'Supplemental', value: 'Supplemental' },
  { label: 'Surface', value: 'Surface' },
  { label: 'Traveling Gun/Towline', value: 'Traveling Gun/Towline' },
  { label: 'Wheel Line', value: 'Wheel Line' },
];

export const uomOptions = [
  { label: 'mm', value: 'mm' },
  { label: 'cm', value: 'cm' },
  { label: 'in', value: 'in' },
  { label: 'ft', value: 'ft' },
];

export const irrigationTypeFormInitialData = {
  irrigationType: '',
  uom: '',
  irrigationRate: '',
};

export const formFieldNames = {
  irrigationType: 'irrigationType',
  irrigationRate: 'irrigationRate',
  uom: 'uom',
  frequency: 'frequency',
  irrigationArea: 'irrigationArea',
};

export const modalErrorInitialState = {
  typeError: false,
  rateError: false,
  uomError: false,
};
