import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tableHeadings } from '../GenerateOriginationPDFReport.content';
import { styles } from './Tableheaders.style';
import PropTypes from 'prop-types';

const TableHeader = ({ subheading, totalNumberOfParticipants }) => {
  return (
    <View>
      {subheading && <Text style={styles?.subheading}>{subheading}</Text>}
      <View style={styles?.row}>
        <View style={styles?.participantName}>
          <Text>{tableHeadings.participant}</Text>
          <Text style={styles?.totalParticipants}>
            {totalNumberOfParticipants}
          </Text>
        </View>
        <Text style={styles?.field}>{tableHeadings.fields}</Text>
        <Text style={styles?.date}>{tableHeadings.addedOn}</Text>
      </View>
    </View>
  );
};

TableHeader.propTypes = {
  subheading: PropTypes.string,
  totalNumberOfParticipants: PropTypes.number,
};
export default TableHeader;
