export const contentMainHeadings = [
  '1.0 Project Details',
  '2.0 Application of Quantification Approaches',
];

export const projectDetailsHeadings = [
  '1.1 Description',
  '1.2 Project Type',
  '1.3 Commercialization Approach',
  '1.4 Quantification Approach',
  '1.5 Project Location',
  '1.6 Created on',
  '1.7 Project Activities',
  '1.8 Project Participants',
];

export const applicationQuantApproachHeadings = [
  '2.1 Title',
  '2.2 Applicability',
];

export const pageHeadings = {
  contentPage: 'Contents',
};

export const tableHeadings = {
  participant: 'Participant',
  fields: 'Fields',
  addedOn: 'Added on',
};

export const PAGE_CONTENT = {
  pageSize: 'A4',
};
