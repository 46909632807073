export const ADD_USER_CONTENT = {
  first_name: 'First Name',
  first_name_placeholder: "Type user's first name",
  last_name: 'Last Name',
  last_name_placeholder: "Type user's last name",
  email: 'Email',
  email_placeholder: "Type user's email address",
  userType: 'User Type: ',
  company: 'Company',
  company_value: 'Green foods CO',
  roles_label: 'Select Role(s)',
  origination_role_label: 'Origination Role',
  assets_role_label: 'Assets Role',
  column: 'column',
  roles_subtypes: [
    { value: 'Viewer', label: 'Viewer' },
    { value: 'Project Manager', label: 'Project Manager' },
  ],
  select_roles: 'Select Role',
  user_first_name: 'userFirstName',
  user_last_name: 'userLastName',
  user_email: 'userEmail',
  user_roles: 'userRoles',
  origination_roles: 'originationRoles',
  asset_roles: 'assetRoles',
  error_class_name: '.Mui-error',
  user_first_name_error: 'A first name is required to add a user.',
  user_last_name_error: 'A last name is required to add a user.',
  user_email_valid_error: 'A valid email address is required to add a user.',
  user_email_format_error:
    'An email address must follow a valid format. e.g., name@domain.com',
  duplicate_email_error:
    'Email entered already exists. Please try using a different email.',
  select_role_error: 'A role selection is required to add a user.',
  select_role_dropdown_error: 'A role selection is required to add a user.',
  add_user: 'Add user',
  outlined: 'outlined',
  primary: 'primary',
  secondary: 'secondary',
  submit_button: 'submit-button',
  contained: 'contained',
  cancel: 'Cancel',
  close_icon: 'close-icon',
  empty_string: '',
  admin: 'Admin',
  success_toast_message: ' was successfully added.',
  error_toast_message: ' failed to be added. Please try again.',
  success_toast_message_edit: ' was successfully updated.',
  error_toast_message_edit: ' failed to be updated. Please try again.',
  success: 'success',
  error: 'error',
  empty_array: [],
  edit_user: 'Edit User',
  save: 'Save',
  role_error: 'Please select at least one module role',
  none_error: 'noneError',
  none: 'None',
};
export const rolesTypes = [
  {
    itemLabel: 'Admin',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'Modules',
    selected: false,
    isDisabled: false,
  },
];

const originationRoleMapping = {
  Viewer: 'ORIGINATION_VIEWER',
  'Project Manager': 'ORIGINATION_PM',
  'Participant Manager': 'PARTICIPANT_MANAGER',
};

export const modifyRolesName = (type, value) => {
  if (value !== 'None') {
    switch (type) {
      case 'asset':
        return value !== 'Viewer' ? 'ASSET_PM' : 'ASSET_VIEWER';
      case 'origination':
        return originationRoleMapping[value];
    }
  } else {
    return 'None';
  }
};
export const changeRoleNames = (roleName) => {
  if (roleName === 'ASSET_VIEWER' || roleName === 'ORIGINATION_VIEWER') {
    return 'Viewer';
  } else if (roleName === 'ASSET_PM' || roleName === 'ORIGINATION_PM') {
    return 'Project Manager';
  } else if (roleName === 'PARTICIPANT_MANAGER') {
    return 'Participant Manager';
  } else {
    return 'None';
  }
};
