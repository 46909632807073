export const PAGE_CONTENT = {
  cancel: 'Cancel',
  clear_all: 'Clear All',
  select_all: 'Select All',
  apply: 'Apply',
  simple_popover: 'simple-popover',
  black: '#63666A',
  green: '#008239',
  white: 'white',
};
