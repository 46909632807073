export const NO_NOTIFICATIONS = 'No notifications available.';
export const DISMISS_BUTTON = 'Dismiss all';
export const SUCCESS_MSG = 'Successful upload of';
export const ERROR_MSG = 'Error in upload of';

export const NOTIFICATION_LOG_CONTENT = {
  success: 'SUCCESS',
  failed: 'FAILED',
  initiated: 'INITIATED',
  file_name: 'error-file.csv',
  error_file: 'Error file',
  medium: 'medium',
  dismiss: 'Dismiss',
  of: 'of',
  upload_failed_text:
    'participants failed to meet validation requirements. Please download the CSV file to view the participants that need to be corrected and re-upload your file.',
  upload_initiated_text:
    'We will notify you when the file upload has finished processing.',
  timestamp_dot: ' • ',
  upload_failed_technical_error_text:
    'couldn’t be uploaded due to a technical error. Please try uploading again.',
};

export const getNotificationHeading = (
  status,
  totalSubmission,
  successfulSubmission,
) => {
  if (status === NOTIFICATION_LOG_CONTENT.success) {
    return `${successfulSubmission} of ${totalSubmission} participants were successfully added.`;
  } else if (status === NOTIFICATION_LOG_CONTENT.failed) {
    if (totalSubmission !== null && successfulSubmission !== null) {
      return 'Participants failed to be added';
    } else {
      return 'File failed to upload';
    }
  } else if (status === NOTIFICATION_LOG_CONTENT.initiated) {
    return 'File is processing...';
  }
};

export const getNotificationFailedContent = (
  failedSubmission,
  totalSubmission,
  fileName = '',
) => {
  if (failedSubmission !== null && totalSubmission !== null) {
    return `${failedSubmission} ${NOTIFICATION_LOG_CONTENT.of} ${totalSubmission} ${NOTIFICATION_LOG_CONTENT.upload_failed_text}`;
  } else {
    return `${fileName} ${NOTIFICATION_LOG_CONTENT.upload_failed_technical_error_text}`;
  }
};
