import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { ReactComponent as BackArrow } from '../../assets/icons/backarrow.svg';
import {
  BackButtonWrapper,
  BackIcon,
  BackButtonLabel,
} from './BackButton.style';
import WarningModal from './../../layout/header/WarningModal.react';
const BackButton = ({
  previouspath,
  previousstate,
  previouspagename,
  marginLeft,
  marginBottom,
  isEdited,
}) => {
  const navigate = useNavigate();
  return (
    <WarningModal
      name={previouspagename}
      link={previouspath}
      previousstate={previousstate}
      explicitRedirect
      isEdited={isEdited}>
      <BackButtonWrapper
        marginLeft={marginLeft}
        marginBottom={marginBottom}
        data-testid="back-arrow"
        onClick={() => {
          navigate(previouspath, previousstate || {});
        }}>
        <BackIcon>
          <BackArrow />
        </BackIcon>
        <BackButtonLabel>Back</BackButtonLabel>
      </BackButtonWrapper>
    </WarningModal>
  );
};

BackButton.propTypes = {
  previouspath: PropTypes.string,
  previousstate: PropTypes.object,
  previouspagename: PropTypes.string,
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEdited: PropTypes.bool,
};

export default BackButton;
