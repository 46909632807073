import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SearchDropdown } from './SearchDropdown.react';
import { SearchContext } from 'containers/ProjectSearchBox.react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { CustomListBoxComponent } from './CustomListBox.react';

export default function SearchBox() {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const {
    fetchFunction,
    searchResult,
    clearFunction,
    loading,
    placeholder,
    navigationFunction,
  } = React.useContext(SearchContext);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.fieldValue,
  });
  return (
    <Grid container alignItems="center" xs={{ border: '1px solid black' }}>
      <Grid item alignItems="center" sx={{ marginTop: 1 }}>
        <SearchDropdown />
      </Grid>
      <Grid item>
        <Autocomplete
          id="search-box"
          sx={{
            width: 600,
          }}
          size="small"
          freeSolo
          loading={loading}
          loadingText={
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size={25} sx={{ marginRight: 1 }} /> Loading
              search results
            </Box>
          }
          open
          options={searchResult}
          filterOptions={filterOptions}
          getOptionLabel={(option) => {
            if (option && (option.projectName || option.name)) {
              return option?.projectName || option?.name;
            } else {
              return option;
            }
          }}
          onInputChange={(_, newInputValue) => {
            setSearchKeyword(newInputValue);
            if (newInputValue === '') {
              clearFunction();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              clearFunction();
              fetchFunction(searchKeyword);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={placeholder} margin="normal" />
          )}
          ListboxComponent={CustomListBoxComponent}
          renderOption={(props, option, { inputValue }) => {
            const fieldValue =
              option.fieldValue.length > 50
                ? option.fieldValue.slice(0, 50) + '...'
                : option.fieldValue;
            const matches = match(fieldValue, inputValue, {
              insideWords: true,
            });
            const parts = parse(fieldValue, matches);
            return (
              <li
                {...props}
                key={option.projectId}
                style={{ font: '15px Open Sans' }} // sx is not property of list changed to style
                onClick={() => navigationFunction(option)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    navigationFunction(option);
                  }
                }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    {option.projectName}
                    <Typography variant="body2" color="text.secondary">
                      <Breadcrumbs
                        separator={<ChevronRightIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        sx={{
                          '& li': {
                            margin: 0,
                          },
                        }}>
                        {[
                          <span key={option.fieldName}>
                            {option.fieldName}
                          </span>,
                          <span key={option.fieldName}>
                            {parts.map((part) => (
                              <span
                                key={part.text}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}>
                                {part.text}
                              </span>
                            ))}
                          </span>,
                        ]}
                      </Breadcrumbs>
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
