import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  Polygon,
  InfoWindowF,
  DrawingManagerF,
  InfoWindow,
} from '@react-google-maps/api';
import { mapStyle, relativePosition } from './MapComponent.style';
import {
  MAP_COMP_TEXT_CONTENT,
  defaultMapOptions,
} from './MapComponent.content';
import {
  checkTernaryCondition,
  convertSquareMetersToAcres,
  getTopMostCoordinate,
} from 'utils/helper';
import uuid from 'react-uuid';
import { mapContext } from 'contextAPI/mapContext';
import {
  NEW_TEAL_GREEN_FOCUS,
  TEAL_GREEN_HOVER,
  BLURRED_NEW_DARK_GREEN,
  BLURRED_TEAL_GREEN_HOVER,
} from 'theme/GlobalColors';

const MapSearchContainer = (props) => {
  const {
    mapCenter = null,
    showSearchBox = true,
    enablePolygon = false,
    markers = [],
    handleNewMarker = () => null,
    style = {},
    mapType,
    mapZoom,
    mapControlOptions = { mapTypeId: 'terrain' },
    polygonsJsonData,
    fieldPolygons = [],
    fieldInfoWindows = [],
    handleNewPolygon = () => null,
    drawMode = null /* can be 'marker', 'polygon', 'polyline', 'rectangle', 'circle', or null. */,
    address = '',
    boundsCoordinates = null,
    markersWithInfoWindows = [],
    mapBounds = null,
    hasOnClickFunctionality = false,
    selectedFarmField = {},
    setSelectedFarmField = () => null,
    onPolygonClick = () => null,
    handlePolygonEdit = () => null,
    showPolygonArea = false,
    clickableMarkersWithInfoWindows = [],
    markerWidth,
    markerHeight,
  } = props;
  const [searchResult, setSearchResult] = useState(null);
  const [center, setCenter] = useState({
    lat: 39.3167,
    lng: -101.88765,
  });
  const [polygons, setPolygons] = useState([]);
  const [map, setMap] = useState(null);
  const [loadedMarkers, setLoadedMarkers] = useState([]);
  // Note: This id referes to the marker id for which the info window is opened on click
  const [openInfowindowMarkerId, setOpenInfowindowMarkerId] = useState(null);
  const existingPolygons = useRef([]);
  const drawingManagerRef = useRef(null);
  const { isMapLoaded } = useContext(mapContext);
  const [focusedPolygon, setFocusedPolygon] = useState(false);

  const polygonOptions = {
    fillColor: BLURRED_NEW_DARK_GREEN,
    fillOpacity: 0.4,
    strokeColor: NEW_TEAL_GREEN_FOCUS,
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 9,
  };

  const focusedPolygonOptions = {
    fillColor: BLURRED_TEAL_GREEN_HOVER,
    fillOpacity: 1,
    strokeColor: TEAL_GREEN_HOVER,
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 9,
  };

  const drawingMangerOptions = {
    drawingMode: null,
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: [],
    },
    polygonOptions: {
      strokeOpacity: 1,
      strokeColor: '#0D8390',
      strokeWeight: 4,
    },
  };

  const handleMarkerComplete = (marker) => {
    if (marker) {
      const latitude = marker.getPosition().lat();
      const longitude = marker.getPosition().lng();
      handleNewMarker(latitude, longitude);
      marker.setMap(null);
    }
  };

  const handlePolygonComplete = (polygon) => {
    const paths = polygon.getPath();
    const fieldBoundaries = [];

    for (let i = 0; i < paths.getLength(); i++) {
      const latLng = paths.getAt(i);

      const latLngObject = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };

      fieldBoundaries.push(latLngObject);
    }

    handleNewPolygon(fieldBoundaries);
    polygon.setMap(null);
  };

  const handleDrawingManagerLoad = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
    drawingManager.setOptions({
      ...drawingMangerOptions,
      drawingMode: drawMode,
    });
    drawingManager.addListener('polygoncomplete', handlePolygonComplete);
    drawingManager.addListener('markercomplete', handleMarkerComplete);
  };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setCenter({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });
    } else {
      alert('Please enter text');
    }
  };

  const getPolygonCenter = (polygon) => {
    const totalCoordinates = polygon.coordinatesList.length;

    let sumX = 0;
    let sumY = 0;

    polygon.coordinatesList.forEach((coordinate) => {
      sumX += coordinate.lat;
      sumY += coordinate.lng;
    });

    return { lat: sumX / totalCoordinates, lng: sumY / totalCoordinates };
  };

  const formatGeoJson = () => {
    const formattedData = [];

    if (polygonsJsonData) {
      polygonsJsonData.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coordinatesArray) => {
          const newPolygon = [];
          coordinatesArray[0].forEach((coordinate) => {
            newPolygon.push({ lat: coordinate[1], lng: coordinate[0] });
          });
          formattedData.push({
            coordinatesList: newPolygon,
            name: feature.properties.name || '',
          });
        });
      });
    }

    return formattedData;
  };

  const loadPolygons = () => {
    if (polygons.length === 0) {
      const polygonData = formatGeoJson();
      setPolygons(polygonData);
    }
  };

  const onMapLoad = React.useCallback(function callback(map) {
    setMap(map);
    map.setOptions({
      ...mapControlOptions,
      ...defaultMapOptions,
    });
    if (mapCenter === null && address.length === 0) {
      map.setCenter({
        lat: 39.3167,
        lng: -101.88765,
      });
    }
    if (address?.length > 0) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          setCenter({ lat: latitude, lng: longitude });
        }
      });
    }
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const handlePolygonClickhandler = (polygon) => {
    onPolygonClick(polygon);
    if (selectedFarmField?.fieldId === polygon.fieldId) {
      setSelectedFarmField({
        farmId: null,
        fieldId: null,
      });
    } else {
      setSelectedFarmField({
        farmId: polygon.farmId,
        fieldId: polygon.fieldId,
      });
    }
  };

  const getInfoWindowContent = (polygon, areaInAcres) => {
    if (showPolygonArea)
      return `
        <div>
          <div style="margin-bottom: 4px;">${polygon.fieldName}</div>
          <div>${areaInAcres} acres</div>
        </div>
      `;

    return polygon.fieldName;
  };

  // Using this work around method to store polygon refs to add and remove polygons, since direct rendering polygons was causing issue with "delete polygon" usage
  useEffect(() => {
    if (map && fieldPolygons) {
      if (existingPolygons.current.length > 0) {
        existingPolygons.current.map((polygon) => {
          polygon.setMap(null);
          polygon = null;
        });
      }
      if (fieldPolygons && fieldPolygons.length > 0) {
        const infoWindowsFieldIds = fieldInfoWindows.map(
          (infoWindow) => infoWindow.fieldId,
        );
        existingPolygons.current = [];
        fieldPolygons.map((polygon) => {
          const newPolygon = new window.google.maps.Polygon({
            paths: polygon.fieldBoundaries,
            options: checkTernaryCondition(
              selectedFarmField?.fieldId === polygon.fieldId && focusedPolygon,
              focusedPolygonOptions,
              {
                ...polygonOptions,
                ...polygon.polygonOptions,
                editable: polygon.isEditable ?? false,
              },
            ),
          });
          const areaInAcres = convertSquareMetersToAcres(
            window.google.maps.geometry.spherical.computeArea(
              newPolygon.getPath(),
            ),
          );
          existingPolygons.current = [...existingPolygons.current, newPolygon];
          newPolygon.setMap(map);
          const infoWindow = new window.google.maps.InfoWindow({
            position: getTopMostCoordinate(polygon.fieldBoundaries),
            content: getInfoWindowContent(polygon, areaInAcres),
          });
          newPolygon.addListener('mouseover', () => {
            setFocusedPolygon(true);
            if (!infoWindowsFieldIds.includes(polygon.fieldId)) {
              infoWindow.open(map);
            }
          });
          newPolygon.addListener('mouseout', () => {
            infoWindow.close();
            setFocusedPolygon(false);
          });
          if (polygon.isEditable) {
            newPolygon.addListener('mouseup', () => {
              const newBoundaries = newPolygon
                .getPath()
                .getArray()
                .map((vertex) => ({ lat: vertex.lat(), lng: vertex.lng() }));

              handlePolygonEdit(polygon.fieldId, newBoundaries);
            });
          }
          hasOnClickFunctionality &&
            newPolygon.addListener('click', () => {
              handlePolygonClickhandler(polygon);
            });
        });
      }
    }
  }, [fieldPolygons, map, fieldInfoWindows]);

  useEffect(() => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setOptions({
        ...drawingMangerOptions,
        drawingMode: drawMode,
      });
    }
  }, [drawMode]);

  useEffect(() => {
    polygonsJsonData && loadPolygons();
  }, [polygonsJsonData]);

  useEffect(() => {
    const length = polygons.length;
    if (length > 0) {
      const mid = Math.floor(length / 2);
      setCenter(getPolygonCenter(polygons[mid]));
    }
  }, [polygons]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        ...mapControlOptions,
        ...defaultMapOptions,
      });
      if (mapCenter === null && address.length === 0) {
        map.setCenter({
          lat: 39.3167,
          lng: -101.88765,
        });
      } else if (mapCenter) {
        setCenter(mapCenter);
      } else if (address.length > 0) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            setCenter({ lat: latitude, lng: longitude });
          }
        });
      }
    }
  }, [map, address, mapCenter]);

  useEffect(() => {
    if (map && boundsCoordinates) {
      const mapBounds = new window.google.maps.LatLngBounds();
      boundsCoordinates.forEach((bound) => {
        mapBounds.extend(bound);
      });
      map.fitBounds(mapBounds);
    }
  }, [map, boundsCoordinates]);

  useEffect(() => {
    if (map && mapBounds) {
      map.fitBounds(mapBounds);
    }
  }, [map, mapBounds]);

  if (!isMapLoaded) {
    return <div data-testid="map">{MAP_COMP_TEXT_CONTENT.loading}</div>;
  }

  return (
    <div style={{ ...style, ...relativePosition }} data-testid="map">
      <div id="searchColumn" className="search-column">
        {showSearchBox && (
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <input type="text" placeholder="Search map" style={mapStyle} />
          </Autocomplete>
        )}
      </div>
      <GoogleMap
        mapContainerClassName="map-container"
        center={center}
        mapTypeId={mapType}
        zoom={mapZoom}
        onLoad={onMapLoad}
        onUnmount={onUnmount}>
        {markers.map(({ id, position }) => (
          <Marker key={id} position={position}></Marker>
        ))}

        {clickableMarkersWithInfoWindows?.map(
          ({ id, position, infoWindowContent, customMarkerUrl }) => {
            return (
              <Marker
                key={id}
                position={position}
                icon={{
                  url: customMarkerUrl,
                  scaledSize: markerHeight
                    ? new window.google.maps.Size(markerWidth, markerHeight)
                    : new window.google.maps.Size(25, 25),
                }}
                onClick={() => setOpenInfowindowMarkerId(id)}>
                {openInfowindowMarkerId === id ? (
                  <InfoWindowF
                    onCloseClick={() => setOpenInfowindowMarkerId(null)}>
                    {infoWindowContent}
                  </InfoWindowF>
                ) : null}
              </Marker>
            );
          },
        )}

        {markersWithInfoWindows.map(({ id, position, infoWindowContent }) => (
          <Marker
            key={id}
            position={position}
            onLoad={() => setLoadedMarkers((prev) => [...prev, id])}>
            {loadedMarkers.includes(id) ? (
              <InfoWindow>{infoWindowContent}</InfoWindow>
            ) : null}
          </Marker>
        ))}

        {fieldInfoWindows.map(({ id, position, onClose, content }) => {
          return (
            <InfoWindowF key={id} onCloseClick={onClose} position={position}>
              {content}
            </InfoWindowF>
          );
        })}

        <DrawingManagerF
          onLoad={handleDrawingManagerLoad}
          onUnmount={(drawingManager) => drawingManager.setMap(null)}
        />

        {enablePolygon &&
          polygons.map((polygon) => (
            <Polygon
              key={uuid()}
              paths={polygon.coordinatesList}
              options={polygonOptions}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

MapSearchContainer.propTypes = {
  mapCenter: PropTypes.object,
  showSearchBox: PropTypes.bool,
  enablePolygon: PropTypes.bool,
  markers: PropTypes.array,
  handleNewMarker: PropTypes.func,
  style: PropTypes.object,
  mapType: PropTypes.string,
  mapZoom: PropTypes.number,
  mapControlOptions: PropTypes.object,
  polygonsJsonData: PropTypes.object,
  fieldPolygons: PropTypes.array,
  fieldInfoWindows: PropTypes.array,
  handleNewPolygon: PropTypes.func,
  markerWidth: PropTypes.string,
  markerHeight: PropTypes.string,
  drawMode: PropTypes.string,
  address: PropTypes.string,
  boundsCoordinates: PropTypes.array,
  markersWithInfoWindows: PropTypes.array,
  mapBounds: PropTypes.object,
  hasOnClickFunctionality: PropTypes.bool,
  selectedFarmField: PropTypes.object,
  setSelectedFarmField: PropTypes.func,
  onPolygonClick: PropTypes.func,
};

export default MapSearchContainer;
