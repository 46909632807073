import React from 'react';
import TabPanel from './TabPanel.react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabBox, TabsWrapper } from './Tabber.style';

const Tabber = ({
  currentTab,
  setCurrentTab,
  tabList,
  onTabChange,
  customTabberStyles,
}) => {
  const handleTabChange = (_, tab) => {
    setCurrentTab(tab);
    onTabChange(tab);
  };

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `portfolio-page-tab-${index}`,
    };
  };

  return (
    <div style={{ position: 'relative' }}>
      <TabsWrapper
        sx={customTabberStyles}
        value={currentTab}
        onChange={handleTabChange}
        aria-label="page-tabs">
        {tabList.map((tab, index) => (
          <TabBox
            firstTab={index === 0}
            className={classNames({ 'active-tab': currentTab === index })}
            key={tab.title}
            label={tab.title}
            {...tabProps(index)}
          />
        ))}
      </TabsWrapper>
      <div>
        {tabList.map((tab, index) => (
          <TabPanel
            key={tab.title}
            value={currentTab}
            index={index}
            customType="table">
            {tab.component}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

Tabber.propTypes = {
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  onTabChange: PropTypes.func,
  customTabberStyles: PropTypes.object,
};

Tabber.defaultProps = {
  onTabChange: () => {},
};

export default Tabber;
