import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  ActivityDisclaimer,
  ActivityTypeDropdownStyleProps,
  BoldTextWrapper,
  ButtonWrapper,
  cancelButtonStyle,
  CloseIconButtonSx,
  DialogContentSx,
  DialogStyle,
  DialogTitleStyle,
  disabledInputSx,
  FieldWrapper,
  flexDisplay,
  InputSx,
  instructionTextStyles,
  submitButtonStyle,
  SubsectionWrapper,
  TextWrapper,
  YearDropdownStyleProps,
} from './ActivityModal.style';
import { useState, useEffect } from 'react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  activityModalInitialState,
  FIELD_ACTIVITY_COLS,
  initialActivityErrorState,
  MODAL_CONTENT,
  YesOrNoOptions,
} from './ActivityModal.content';
import {
  addZeroBeforeDot,
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
} from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { COOL_GRAY, RED, TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { useForm } from 'hooks/useForm';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  FormLabels,
  InstructionWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { closeIconSx } from 'theme/GlobalStyles';

const ActivityModal = ({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  dropdownlist,
  remainingFieldArea,
  yearDropdownList,
  activityList,
  setActivityList,
  fieldCsafImplemented,
  csafUpdatedBySystem,
}) => {
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    activityModalInitialState,
  );
  const [errorState, setErrorState] = useState(initialActivityErrorState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [year1Option, setYear1Option] = useState([]);
  const [year2Option, setYear2Option] = useState([]);
  const [year3Option, setYear3Option] = useState([]);

  const [filteredDropdownList, setFilteredDropdownList] = useState([]);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
  const [initialCsafImplemented, setInitialCsafImplemented] = useState('');
  const [isImproveGrazingManagement, setIsImproveGrazingManagement] =
    useState(false);

  useEffect(() => {
    if (formValue?.date !== '' && activityList?.length > 0) {
      const filteredList = dropdownlist.filter((item) => {
        return !activityList.some((activity) => {
          return (
            activity.date === formValue.date &&
            activity.projectActivityId === item.value &&
            activity.fieldCropProjectActivityId !==
              formValue.fieldCropProjectActivityId
          );
        });
      });

      setFilteredDropdownList(filteredList);
    } else {
      setFilteredDropdownList(dropdownlist);
    }
  }, [
    dropdownlist,
    formValue?.date,
    formValue?.fieldCropProjectActivityId,
    activityList,
  ]);

  useEffect(() => {
    const activityDisclaimerCheck =
      (fieldCsafImplemented === 'No' ||
        fieldCsafImplemented === "I don't know") &&
      formValue.historicallyImplemented === 'Yes';
    if (csafUpdatedBySystem && formValue.fieldCsafUpdatedByActivity) {
      if (
        initialCsafImplemented === formValue.historicallyImplemented &&
        formValue.historicallyImplemented === 'Yes' &&
        formValue.historicallyImplemented !== ''
      ) {
        setIsDisclaimerVisible(true);
      } else {
        setIsDisclaimerVisible(activityDisclaimerCheck);
      }
    } else {
      setIsDisclaimerVisible(activityDisclaimerCheck);
    }
  }, [
    fieldCsafImplemented,
    formValue.historicallyImplemented,
    initialCsafImplemented,
    csafUpdatedBySystem,
    formValue.fieldCsafUpdatedByActivity,
  ]);

  useEffect(() => {
    if (formValue?.projectActivityId !== '') {
      setIsImproveGrazingManagement(
        dropdownlist?.find(
          (item) => item.value === formValue?.projectActivityId,
        )?.label === MODAL_CONTENT.improve_grazing_management,
      );
    }
  }, [formValue?.projectActivityId]);

  const clearData = () => {
    setFormValue(activityModalInitialState);
    setErrorState(initialActivityErrorState);
  };
  const handleClose = () => {
    setOpen(false);
    clearData();
    setIsEdit(null);
  };

  const fieldHandler = (key, value) => {
    customFieldChangeHandler(key, value);
    switch (key) {
      case FIELD_ACTIVITY_COLS.totalArea:
        setErrorState({
          ...errorState,
          totalAreaError: value === '',
          isAreaExceedingError: Number(value) > remainingFieldArea,
          isAreaZeroError: value !== '' && Number(value) === 0,
        });
        break;
      case FIELD_ACTIVITY_COLS.historicallyImplemented:
        setErrorState({
          ...errorState,
          historicallyImplementedError: value === '',
        });
        break;
    }
  };

  const historicallyTotalArea1Props = {
    label: MODAL_CONTENT.acres,
    value: formValue?.totalArea1,
    isDisabled: !year1Option[0]?.checked,
    customInputSx: checkTernaryCondition(
      !year1Option[0]?.checked,
      disabledInputSx,
      InputSx,
    ),
    placeholder: MODAL_CONTENT.total_acreage,
    name: MODAL_CONTENT.acres,
    width: '40%',
    labelOnRightSide: true,
    labelOnRightSideColor: !year1Option[0]?.checked && TYPOGRAPHY_GRAY,
    rightSideLabelFormLabelMargin: '0rem',
    display: 'flex',
    customStyles: { width: '50%' },
    onUpdate: (event) => {
      const value = event.target.value;
      if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
        fieldHandler(FIELD_ACTIVITY_COLS.totalArea1, addZeroBeforeDot(value));
      }
      setErrorState((prev) => ({
        ...prev,
        isActivityHistory1EmptyError: value === '',
        isActivityHistoryEmptyError: value === '',
      }));
    },
  };

  const historicallyTotalArea2Props = {
    label: MODAL_CONTENT.acres,
    value: formValue?.totalArea2,
    isDisabled: !year2Option[0]?.checked,
    customInputSx: checkTernaryCondition(
      !year2Option[0]?.checked,
      disabledInputSx,
      InputSx,
    ),
    placeholder: MODAL_CONTENT.total_acreage,
    name: MODAL_CONTENT.acres,
    width: '40%',
    labelOnRightSide: true,
    labelOnRightSideColor: !year2Option[0]?.checked && TYPOGRAPHY_GRAY,
    rightSideLabelFormLabelMargin: '0rem',
    display: 'flex',
    customStyles: { width: '50%' },
    onUpdate: (event) => {
      const value = event.target.value;
      if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
        fieldHandler(FIELD_ACTIVITY_COLS.totalArea2, addZeroBeforeDot(value));
      }
      setErrorState((prev) => ({
        ...prev,
        isActivityHistory2EmptyError: value === '',
        isActivityHistoryEmptyError: value === '',
      }));
    },
  };

  const historicallyTotalArea3Props = {
    label: MODAL_CONTENT.acres,
    value: formValue?.totalArea3,
    isDisabled: !year3Option[0]?.checked,
    customInputSx: checkTernaryCondition(
      !year3Option[0]?.checked,
      disabledInputSx,
      InputSx,
    ),
    placeholder: MODAL_CONTENT.total_acreage,
    name: MODAL_CONTENT.acres,
    width: '40%',
    labelOnRightSide: true,
    labelOnRightSideColor: !year3Option[0]?.checked && TYPOGRAPHY_GRAY,
    rightSideLabelFormLabelMargin: '0rem',
    display: 'flex',
    customStyles: { width: '50%' },
    onUpdate: (event) => {
      const value = event.target.value;
      if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
        fieldHandler(FIELD_ACTIVITY_COLS.totalArea3, addZeroBeforeDot(value));
      }
      setErrorState((prev) => ({
        ...prev,
        isActivityHistory3EmptyError: value === '',
        isActivityHistoryEmptyError: value === '',
      }));
    },
  };

  const validateForm = () => {
    const updatedErrorStates = {
      projectActivityIdError: isValueEmptyNullorUndefined(
        formValue?.projectActivityId?.toString(),
      ),
      dateError: isValueEmptyNullorUndefined(formValue?.date),
      totalAreaError: isValueEmptyNullorUndefined(
        formValue?.totalArea?.toString(),
      ),
      isAreaExceedingError: Number(formValue?.totalArea) > remainingFieldArea,
      isAreaZeroError:
        formValue?.totalArea !== '' && Number(formValue?.totalArea) === 0,
      historicallyImplementedError: isValueEmptyNullorUndefined(
        formValue?.historicallyImplemented,
      ),
      activityHistoryError:
        formValue?.historicallyImplemented === 'Yes' &&
        formValue.historicallyImplemented1 === 'No' &&
        formValue.historicallyImplemented2 === 'No' &&
        formValue.historicallyImplemented3 === 'No',
      isActivityHistoryExceedingError:
        (formValue?.historicallyImplemented === 'Yes' &&
          Number(formValue.totalArea1) > remainingFieldArea) ||
        Number(formValue.totalArea2) > remainingFieldArea ||
        Number(formValue.totalArea3) > remainingFieldArea,
      isActivityHistoryZeroError:
        (formValue?.historicallyImplemented1 === 'Yes' &&
          Number(formValue.totalArea1) === 0 &&
          formValue.totalArea1 !== '') ||
        (formValue?.historicallyImplemented2 === 'Yes' &&
          Number(formValue.totalArea2) === 0 &&
          formValue.totalArea2 !== '') ||
        (formValue?.historicallyImplemented3 === 'Yes' &&
          Number(formValue.totalArea3) === 0 &&
          formValue.totalArea3 !== ''),
      isActivityHistoryEmptyError:
        (formValue?.historicallyImplemented1 === 'Yes' &&
          formValue.totalArea1 === '') ||
        (formValue?.historicallyImplemented2 === 'Yes' &&
          formValue.totalArea2 === '') ||
        (formValue?.historicallyImplemented3 === 'Yes' &&
          formValue.totalArea3 === ''),
    };
    setErrorState(updatedErrorStates);
    return !Object.values(updatedErrorStates).some((value) => value === true);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (isEdit) {
        let newData = [...activityList];
        const index = newData.findIndex(
          (item) => item.fieldCropProjectActivityId === isEdit,
        );
        const newDataItem = {
          fieldCropProjectActivityId: isEdit,
          projectActivityId: formValue?.projectActivityId,
          date: formValue?.date,
          totalArea: checkTernaryCondition(
            isImproveGrazingManagement,
            0,
            formValue?.totalArea,
          ),
          totalHeadCount: checkTernaryCondition(
            isImproveGrazingManagement,
            formValue?.totalArea,
            '',
          ),
          historicallyImplemented: formValue?.historicallyImplemented,
          fieldCsafUpdatedByActivity: isDisclaimerVisible,
        };
        const history1 = {
          fieldProjectActivityHistoricalId:
            newData[index].fieldPlannedActivityHistory[0]
              ?.fieldProjectActivityHistoricalId,
          implementationYear: formValue.implementationYear1,
          totalArea: checkTernaryCondition(
            isImproveGrazingManagement,
            formValue.totalArea1?.toString()?.trim() || 0,
            formValue.totalArea1,
          ),
          historicallyImplemented: formValue.historicallyImplemented1,
        };
        const history2 = {
          fieldProjectActivityHistoricalId:
            newData[index].fieldPlannedActivityHistory[1]
              ?.fieldProjectActivityHistoricalId,
          implementationYear: formValue.implementationYear2,
          totalArea: checkTernaryCondition(
            isImproveGrazingManagement,
            formValue.totalArea2?.toString()?.trim() || 0,
            formValue.totalArea2,
          ),
          historicallyImplemented: formValue.historicallyImplemented2,
        };
        const history3 = {
          fieldProjectActivityHistoricalId:
            newData[index].fieldPlannedActivityHistory[2]
              ?.fieldProjectActivityHistoricalId,
          implementationYear: formValue.implementationYear3,
          totalArea: checkTernaryCondition(
            isImproveGrazingManagement,
            formValue.totalArea3?.toString()?.trim() || 0,
            formValue.totalArea3,
          ),
          historicallyImplemented: formValue.historicallyImplemented3,
        };
        newDataItem.fieldPlannedActivityHistory = [
          history1,
          history2,
          history3,
        ];

        newData[index] = newDataItem;

        setActivityList(newData);
      } else {
        const newActivity = {
          fieldCropProjectActivityId: uniqueId(),
          projectActivityId: formValue?.projectActivityId,
          date: formValue?.date,
          totalArea: checkTernaryCondition(
            isImproveGrazingManagement,
            0,
            formValue?.totalArea,
          ),
          totalHeadCount: checkTernaryCondition(
            isImproveGrazingManagement,
            formValue?.totalArea,
            '',
          ),
          historicallyImplemented: formValue?.historicallyImplemented,
          fieldCsafUpdatedByActivity: isDisclaimerVisible,
          fieldPlannedActivityHistory: [
            {
              fieldProjectActivityHistoricalId: uniqueId(),
              implementationYear: formValue?.implementationYear1,
              totalArea: formValue?.totalArea1,
              historicallyImplemented: formValue?.historicallyImplemented1,
            },
            {
              fieldProjectActivityHistoricalId: uniqueId(),
              implementationYear: formValue?.implementationYear2,
              totalArea: formValue?.totalArea2,
              historicallyImplemented: formValue?.historicallyImplemented2,
            },
            {
              fieldProjectActivityHistoricalId: uniqueId(),
              implementationYear: formValue?.implementationYear3,
              totalArea: formValue?.totalArea3,
              historicallyImplemented: formValue?.historicallyImplemented3,
            },
          ],
        };
        setActivityList([...activityList, newActivity]);
      }
      setIsEdit(null);
      setIsSubmit(true);
      clearData();
    }
  };
  useEffect(() => {
    if (isEdit !== null) {
      const currentModalVal = activityList?.find(
        (item) => item.fieldCropProjectActivityId === isEdit,
      );
      setInitialCsafImplemented(currentModalVal?.historicallyImplemented);
      setFormValue({
        fieldCropProjectActivityId: currentModalVal?.fieldCropProjectActivityId,
        fieldCsafUpdatedByActivity: currentModalVal?.fieldCsafUpdatedByActivity,
        projectActivityId: currentModalVal?.projectActivityId,
        date: currentModalVal?.date,
        totalArea: checkTernaryCondition(
          dropdownlist?.find(
            (item) => item.value === currentModalVal?.projectActivityId,
          )?.label === MODAL_CONTENT.improve_grazing_management,
          currentModalVal?.totalHeadCount,
          currentModalVal?.totalArea,
        ),
        historicallyImplemented: currentModalVal?.historicallyImplemented,
        fieldProjectActivityHistoricalId1:
          currentModalVal?.fieldPlannedActivityHistory[0]
            ?.fieldProjectActivityHistoricalId,
        implementationYear1:
          currentModalVal?.fieldPlannedActivityHistory[0]?.implementationYear,
        totalArea1: currentModalVal?.fieldPlannedActivityHistory[0]?.totalArea,
        historicallyImplemented1:
          currentModalVal?.fieldPlannedActivityHistory[0]
            ?.historicallyImplemented,
        fieldProjectActivityHistoricalId2:
          currentModalVal?.fieldPlannedActivityHistory[1]
            ?.fieldProjectActivityHistoricalId,
        implementationYear2:
          currentModalVal?.fieldPlannedActivityHistory[1]?.implementationYear,
        totalArea2: currentModalVal?.fieldPlannedActivityHistory[1]?.totalArea,
        historicallyImplemented2:
          currentModalVal?.fieldPlannedActivityHistory[1]
            ?.historicallyImplemented,
        fieldProjectActivityHistoricalId3:
          currentModalVal?.fieldPlannedActivityHistory[2]
            ?.fieldProjectActivityHistoricalId,
        implementationYear3:
          currentModalVal?.fieldPlannedActivityHistory[2]?.implementationYear,
        totalArea3: currentModalVal?.fieldPlannedActivityHistory[2]?.totalArea,
        historicallyImplemented3:
          currentModalVal?.fieldPlannedActivityHistory[2]
            ?.historicallyImplemented,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    isSubmit && handleClose();
    setIsSubmit(false);
  }, [isSubmit]);

  useEffect(() => {
    if (
      formValue.historicallyImplemented1 !== '' ||
      formValue.historicallyImplemented2 !== '' ||
      formValue.historicallyImplemented3 !== ''
    )
      setErrorState({
        ...errorState,
        activityHistoryError: false,
      });
  }, [
    formValue.historicallyImplemented1,
    formValue.historicallyImplemented2,
    formValue.historicallyImplemented3,
  ]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      isActivityHistoryExceedingError:
        Number(formValue.totalArea1) > remainingFieldArea ||
        Number(formValue.totalArea2) > remainingFieldArea ||
        Number(formValue.totalArea3) > remainingFieldArea,
      isActivityHistoryZeroError:
        (formValue?.historicallyImplemented1 === 'Yes' &&
          Number(formValue.totalArea1) === 0 &&
          formValue.totalArea1 !== '') ||
        (formValue?.historicallyImplemented2 === 'Yes' &&
          Number(formValue.totalArea2) === 0 &&
          formValue.totalArea2 !== '') ||
        (formValue?.historicallyImplemented3 === 'Yes' &&
          Number(formValue.totalArea3) === 0 &&
          formValue.totalArea3 !== ''),
    });
  }, [formValue.totalArea1, formValue.totalArea2, formValue.totalArea3]);

  useEffect(() => {
    setFormValue({
      ...formValue,
      implementationYear1: (Number(formValue.date) - 3)?.toString(),
      implementationYear2: (Number(formValue.date) - 2)?.toString(),
      implementationYear3: (Number(formValue.date) - 1)?.toString(),
    });
  }, [formValue.date]);

  useEffect(() => {
    if (formValue?.date === '') {
      setFormValue({
        ...formValue,
        date: yearDropdownList[0]?.value,
      });
    }
  }, [formValue]);

  useEffect(() => {
    if (formValue?.historicallyImplemented === 'No') {
      setFormValue({
        ...formValue,
        totalArea1: '',
        historicallyImplemented1: '',
        totalArea2: '',
        historicallyImplemented2: '',
        totalArea3: '',
        historicallyImplemented3: '',
      });
    }
  }, [formValue?.historicallyImplemented]);

  useEffect(() => {
    if (formValue?.historicallyImplemented1 === 'No') {
      setFormValue({
        ...formValue,
        totalArea1: '',
      });
    }
  }, [formValue?.historicallyImplemented1]);

  useEffect(() => {
    if (formValue?.historicallyImplemented2 === 'No') {
      setFormValue({
        ...formValue,
        totalArea2: '',
      });
    }
  }, [formValue?.historicallyImplemented2]);

  useEffect(() => {
    if (formValue?.historicallyImplemented3 === 'No') {
      setFormValue({
        ...formValue,
        totalArea3: '',
      });
    }
  }, [formValue?.historicallyImplemented3]);

  useEffect(() => {
    setYear1Option([
      {
        itemLabel: formValue.implementationYear1,
        checked: formValue.historicallyImplemented1 === 'Yes',
      },
    ]);
  }, [formValue.implementationYear1, formValue.historicallyImplemented1]);

  useEffect(() => {
    setYear2Option([
      {
        itemLabel: formValue.implementationYear2,
        checked: formValue.historicallyImplemented2 === 'Yes',
      },
    ]);
  }, [formValue.implementationYear2, formValue.historicallyImplemented2]);

  useEffect(() => {
    setYear3Option([
      {
        itemLabel: formValue.implementationYear3,
        checked: formValue.historicallyImplemented3 === 'Yes',
      },
    ]);
  }, [formValue.implementationYear3, formValue.historicallyImplemented3]);

  useEffect(() => {
    if (formValue.historicallyImplemented === 'No') {
      setYear1Option((prev) =>
        prev.map((option) => ({ ...option, checked: false })),
      );
      setYear2Option((prev) =>
        prev.map((option) => ({ ...option, checked: false })),
      );
      setYear3Option((prev) =>
        prev.map((option) => ({ ...option, checked: false })),
      );

      setFormValue((prev) => ({
        ...prev,
        totalArea1: '',
        totalArea2: '',
        totalArea3: '',
        historicallyImplemented1: 'No',
        historicallyImplemented2: 'No',
        historicallyImplemented3: 'No',
      }));

      setErrorState((error) => ({
        ...error,
        activityHistoryError: false,
        isActivityHistoryExceedingError: false,
        isActivityHistoryZeroError: false,
        isActivityHistoryEmptyError: false,
      }));
    }
  }, [formValue?.historicallyImplemented]);

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  return (
    <Dialog
      sx={DialogStyle}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={CloseIconButtonSx}>
        <CloseIcon sx={closeIconSx} />
      </IconButton>
      <DialogTitle sx={DialogTitleStyle} id="alert-dialog-title">
        {MODAL_CONTENT.title}
      </DialogTitle>
      <DialogContent sx={DialogContentSx}>
        <DropDown
          {...ActivityTypeDropdownStyleProps}
          label={MODAL_CONTENT.activity_type}
          value={checkTernaryCondition(
            formValue?.projectActivityId === '',
            MODAL_CONTENT.select_option,
            formValue?.projectActivityId,
          )}
          setFormFieldValue={(key, value) => {
            customFieldChangeHandler(key, value);
            setErrorState((error) => ({
              ...error,
              projectActivityIdError: isValueEmptyNullorUndefined(
                value?.toString(),
              ),
            }));
          }}
          field_key={FIELD_ACTIVITY_COLS.projectActivityId}
          setStateValue={() => {}}
          name={MODAL_CONTENT.activity_type}
          dropDownPlaceholder={MODAL_CONTENT.select_option}
          dropdownlist={filteredDropdownList}
          error={errorState.projectActivityIdError}
          errorMessage={MODAL_CONTENT.activity_type_error}
          showLabelAsValue={true}
          hasNoBottomMargin={true}
          customPlaceholderColor={COOL_GRAY}
        />
        <FieldWrapper gap="1rem">
          <DropDown
            {...YearDropdownStyleProps}
            label={MODAL_CONTENT.implmentation_year}
            value={formValue?.date}
            setFormFieldValue={customFieldChangeHandler}
            field_key={FIELD_ACTIVITY_COLS.date}
            setStateValue={() => {}}
            dropdownlist={yearDropdownList}
            error={errorState.dateError}
            errorMessage={MODAL_CONTENT.year_planned_error}
            showLabelAsValue={true}
            hasNoBottomMargin={true}
          />
          <div style={{ width: '50%' }}>
            <TextWrapper fontSize={'0.875rem'} fontWeight={400}>
              {checkTernaryCondition(
                isImproveGrazingManagement,
                MODAL_CONTENT.total_head_count,
                MODAL_CONTENT.total_area,
              )}
            </TextWrapper>
            <InputField
              label={checkTernaryCondition(
                isImproveGrazingManagement,
                MODAL_CONTENT.unit,
                MODAL_CONTENT.acres,
              )}
              value={formValue?.totalArea}
              placeholder={checkTernaryCondition(
                isImproveGrazingManagement,
                MODAL_CONTENT.unit,
                MODAL_CONTENT.area,
              )}
              name={checkTernaryCondition(
                isImproveGrazingManagement,
                MODAL_CONTENT.unit,
                MODAL_CONTENT.acres,
              )}
              width="40%"
              labelOnRightSide={true}
              rightSideLabelFormLabelMargin="0rem"
              display="flex"
              primaryError={errorState.totalAreaError}
              primaryErrorMessage={checkTernaryCondition(
                isImproveGrazingManagement,
                MODAL_CONTENT.head_count_error,
                MODAL_CONTENT.total_area_error,
              )}
              secondaryError={errorState.isAreaExceedingError}
              secondaryErrorMessage={MODAL_CONTENT.field_area_exceeding_error}
              thirdError={errorState.isAreaZeroError}
              thirdErrorMessage={MODAL_CONTENT.field_area_zero_error}
              onUpdate={(event) => {
                const value = event.target.value;
                if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
                  fieldHandler(
                    FIELD_ACTIVITY_COLS.totalArea,
                    addZeroBeforeDot(value),
                  );
                }
              }}
              customInputSx={InputSx}
            />
          </div>
        </FieldWrapper>
        <RadioButtonsGroup
          checkedIsDarkGreen={true}
          selectedValue={formValue.historicallyImplemented}
          list={YesOrNoOptions}
          label={MODAL_CONTENT.historically_implemented}
          direction={MODAL_CONTENT.row}
          handleChange={(e) => {
            fieldHandler(
              FIELD_ACTIVITY_COLS.historicallyImplemented,
              e.target.value,
            );
          }}
          primaryError={errorState.historicallyImplementedError}
          primaryErrorMessage={MODAL_CONTENT.historically_implemented_error}
          marginRight={MODAL_CONTENT.margin_right}
          marginBottom={MODAL_CONTENT.zero_rem}
        />
        {isDisclaimerVisible && (
          <ActivityDisclaimer>
            {MODAL_CONTENT.activity_disclaimer.slice(0, 26)}
            <BoldTextWrapper fontWeight={600}>
              {MODAL_CONTENT.activity_disclaimer.slice(26, 29)}
            </BoldTextWrapper>
            {MODAL_CONTENT.activity_disclaimer.slice(29, 32)}
            <BoldTextWrapper fontWeight={600}>
              {MODAL_CONTENT.activity_disclaimer.slice(32, 46)}
            </BoldTextWrapper>
            {MODAL_CONTENT.activity_disclaimer.slice(46, -4)}
            <BoldTextWrapper fontWeight={600}>
              {MODAL_CONTENT.activity_disclaimer.slice(-4)}
            </BoldTextWrapper>
          </ActivityDisclaimer>
        )}
        {formValue?.historicallyImplemented === MODAL_CONTENT.yes && (
          <>
            <FormLabels marginBottom={'0.4rem'}>
              {MODAL_CONTENT.activity_history_label}
            </FormLabels>
            <InstructionWrapper {...instructionTextStyles}>
              {MODAL_CONTENT.instructionText}
            </InstructionWrapper>
          </>
        )}
        {formValue?.historicallyImplemented === MODAL_CONTENT.yes && (
          <SubsectionWrapper>
            <CheckboxFormGroup
              customStyles={flexDisplay}
              list={year1Option}
              noLabel
              minWidth="5rem"
              onClickHandler={() => {
                fieldHandler(
                  FIELD_ACTIVITY_COLS.historicallyImplemented1,
                  checkTernaryCondition(year1Option[0]?.checked, 'No', 'Yes'),
                );
                setYear1Option((prev) =>
                  prev.map((option) => ({
                    ...option,
                    checked: !option.checked,
                  })),
                );
              }}
              showFreeTextField
              freeTextProps={historicallyTotalArea1Props}
            />
            <CheckboxFormGroup
              customStyles={flexDisplay}
              list={year2Option}
              noLabel
              minWidth="5rem"
              onClickHandler={() => {
                fieldHandler(
                  FIELD_ACTIVITY_COLS.historicallyImplemented2,
                  checkTernaryCondition(year2Option[0]?.checked, 'No', 'Yes'),
                );
                setYear2Option((prev) =>
                  prev.map((option) => ({
                    ...option,
                    checked: !option.checked,
                  })),
                );
              }}
              showFreeTextField
              freeTextProps={historicallyTotalArea2Props}
            />
            <CheckboxFormGroup
              customStyles={flexDisplay}
              list={year3Option}
              noLabel
              minWidth="5rem"
              onClickHandler={() => {
                fieldHandler(
                  FIELD_ACTIVITY_COLS.historicallyImplemented3,
                  checkTernaryCondition(year3Option[0]?.checked, 'No', 'Yes'),
                );
                setYear3Option((prev) =>
                  prev.map((option) => ({
                    ...option,
                    checked: !option.checked,
                  })),
                );
              }}
              showFreeTextField
              freeTextProps={historicallyTotalArea3Props}
            />
            {errorState?.activityHistoryError && (
              <TextWrapper color={RED} fontSize={'0.75rem'}>
                {MODAL_CONTENT.activity_history_error}
              </TextWrapper>
            )}
            {errorState?.isActivityHistoryExceedingError && (
              <TextWrapper color={RED} fontSize={'0.75rem'}>
                {MODAL_CONTENT.activity_history_area_error}
              </TextWrapper>
            )}
            {(errorState?.isActivityHistoryEmptyError ||
              errorState?.isActivityHistory1EmptyError ||
              errorState?.isActivityHistory2EmptyError ||
              errorState?.isActivityHistory3EmptyError) && (
              <TextWrapper color={RED} fontSize={'0.75rem'}>
                {MODAL_CONTENT.activity_history_required_error}
              </TextWrapper>
            )}
            {errorState?.isActivityHistoryZeroError && (
              <TextWrapper color={RED} fontSize={'0.75rem'}>
                {MODAL_CONTENT.activity_history_zero_val_error}
              </TextWrapper>
            )}
          </SubsectionWrapper>
        )}
      </DialogContent>
      <ButtonWrapper>
        <Button
          onClick={handleSubmit}
          sx={[submitButtonStyle, primaryButtonStyle('0.25rem 0.469rem')]}
          disabled={isSubmitDisabled}>
          {MODAL_CONTENT.submit}
        </Button>
        <Button
          onClick={handleClose}
          sx={[cancelButtonStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancel}
        </Button>
      </ButtonWrapper>
    </Dialog>
  );
};

ActivityModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  dropdownlist: PropTypes.array,
  remainingFieldArea: PropTypes.number,
  yearDropdownList: PropTypes.array,
  activityList: PropTypes.array,
  setActivityList: PropTypes.func,
  fieldCsafImplemented: PropTypes.bool,
  csafUpdatedBySystem: PropTypes.bool,
};
export default ActivityModal;
