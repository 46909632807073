import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { FORM_CONTENT } from '../ParticipantContributorsForm.content';
import { DropDownPlaceholder } from 'components/FormComponents/FormStyles';
import { ChipWrapper } from '../ParticipantContributorsForm.style';
import { Chip } from '@mui/material';
import { ReactComponent as ChipCancelIcon } from '../../../../../assets/icons/chipcloseicon.svg';
import { checkTernaryCondition } from 'utils/helper';
import { COOL_GRAY } from 'theme/GlobalColors';
import { chipStyle } from 'components/FormComponents/AutoComplete/AutoComplete.style';
import PropTypes from 'prop-types';

const FarmAssignmentDropdown = ({
  value,
  onUpdateHandler,
  dropdownList,
  deleteHandler,
  currentInstanceId,
  error = false,
  errorMessage = '',
}) => {
  const assignToFarmProps = {
    field_key: 'assignedFarms',
    label: FORM_CONTENT.assign_to_farms_label,
    value: checkTernaryCondition(value.length > 0, value, [
      `${FORM_CONTENT.assign_to_farms_placeholder}`,
    ]),
    isDisabled: false,
    stateValue: value,
    onUpdate: (event) => {
      onUpdateHandler(
        event.target.value.filter(
          (item) => item !== FORM_CONTENT.assign_to_farms_placeholder,
        ),
      );
    },
    setStateValue: () => {},
    setFormFieldValue: () => {},
    width: '100%',
    height: '2rem',
    name: 'assignedFarms',
    displayEmpty: true,
    ariaLabel: FORM_CONTENT.assign_to_farms_label,
    dropdownlist: dropdownList.map((farm) => ({
      ...farm,
      hidden: Number(farm.instanceId) !== currentInstanceId,
    })),
    dropDownPlaceholder: FORM_CONTENT.assign_to_farms_placeholder,
    error: error,
    errorMessage: errorMessage,
    moduleError: false,
    isMultiple: true,
    customRenderValues: (selectedValues, dropdownList) => {
      if (selectedValues[0] === FORM_CONTENT.assign_to_farms_placeholder) {
        return (
          <DropDownPlaceholder
            customPlaceholderColor={COOL_GRAY}
            data-testId="options-label">
            {FORM_CONTENT.assign_to_farms_placeholder}
          </DropDownPlaceholder>
        );
      } else if (
        typeof selectedValues !== 'string' &&
        selectedValues.length > 0
      ) {
        return (
          <ChipWrapper>
            {selectedValues.map((selectedValue, index) => {
              return (
                <Chip
                  key={index}
                  label={
                    dropdownList.find((data) => data.value === selectedValue)
                      ?.label
                  }
                  onDelete={(event) => {
                    deleteHandler(selectedValue);
                    event.stopPropagation();
                  }}
                  deleteIcon={
                    <ChipCancelIcon
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  }
                  sx={chipStyle}
                />
              );
            })}{' '}
          </ChipWrapper>
        );
      }
    },
  };
  return <DropDown {...assignToFarmProps} />;
};

FarmAssignmentDropdown.propTypes = {
  value: PropTypes.array,
  onUpdateHandler: PropTypes.func,
  dropdownList: PropTypes.array,
  deleteHandler: PropTypes.func,
  currentInstanceId: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FarmAssignmentDropdown;
