import styled from 'styled-components';
import { TYPOGRAPHY_GRAY } from 'theme/GlobalColors';

export const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: '400',
  padding: '0rem',
};

export const AddedDeletedList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;

export const WhiteChip = styled.div`
  background: #ffffff;
  border: 0.5px solid #63666a;
  border-radius: 0.25rem;
  color: #63666a;
  padding: 0.1rem 0.25rem;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 15px;
  max-width: 5rem;
  height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StrikeOutText = styled.span`
  font-weight: 400;
  text-decoration-line: line-through;
  color: ${TYPOGRAPHY_GRAY};
  padding-right: 10px;
  font-size: 0.875rem;
`;

export const NonStrikeOutText = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
`;
