import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  DIM_BLUE_BACKGROUND,
  RED,
  RED_BACKGROUND,
  WHITE,
} from 'theme/GlobalColors';

export const chipStyle = (isError) => {
  return {
    color: isError ? RED : COOL_GRAY,
    border: `0.5px solid ${isError ? RED : COOL_GRAY}`,
    background: isError ? RED_BACKGROUND : WHITE,
    borderRadius: '4px',
    height: '19px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '4px',
    '&.MuiAutocomplete-tag': {
      margin: '0.125rem',
    },
    '& .MuiChip-label': {
      padding: '0rem',
      fontSize: '0.688rem',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    '& .MuiChip-deleteIcon': {
      margin: 0,
      color: isError ? RED : COOL_GRAY,
    },
  };
};

export const SubmitBtnStyle = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  textTransform: 'none',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1rem',
  padding: '0.625rem 1rem',
};

export const CancelBtnStyle = {
  color: DARK_GREEN,
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: '1rem',
};

export const listOptionStyle = {
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.25rem',
  '&:hover': {
    backgroundColor: DIM_BLUE_BACKGROUND,
  },
};

export const listOptionBoldStyle = {
  fontWeight: 600,
};

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      padding: '24px',
    },
  },
};

export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  color: DARK_CHARCOAL,
};

export const DialogContentStyle = {
  marginBottom: '1rem',
  padding: '0rem',
  overflow: 'hidden',
};

export const DialogActionStyle = {
  justifyContent: 'flex-end',
  gridColumnGap: '0.5rem',
};

export const OptionItemsStyle = {
  fontWeight: 'bold',
  whiteSpace: 'pre',
};
