import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import {
  applicationQuantApproachHeadings,
  contentMainHeadings,
  pageHeadings,
  projectDetailsHeadings,
} from '../GenerateOriginationPDFReport.content';
import { styles } from './ContentsPage.style';

const ContentsPage = () => {
  return (
    <View style={styles?.section}>
      <Text style={styles?.pageHeading}>{pageHeadings.contentPage}</Text>
      <Text style={styles?.subHeading}>{contentMainHeadings[0]}</Text>
      {projectDetailsHeadings.map((item) => (
        <View style={styles?.container} key={item}>
          <Text style={styles?.description}>{item}</Text>
        </View>
      ))}
      <Text style={styles?.subHeading2}>{contentMainHeadings[1]}</Text>
      {applicationQuantApproachHeadings.map((item) => (
        <View style={styles?.container} key={item}>
          <Text style={styles?.description}>{item}</Text>
        </View>
      ))}
    </View>
  );
};

export default ContentsPage;
