import styled from 'styled-components';
import {
  DARK_CHARCOAL,
  COOL_GRAY,
  BORDER_GRAY_COLOR,
} from 'theme/GlobalColors';

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  gap: 1rem;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-right: -31px;
`;
export const ContributorMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  margin-top: -0.3rem;
  justify-content: space-between;
  align-items: center;
`;
export const ContributorHeaderWrapper = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ParentWrapper = styled.div`
  background-color: white;
  padding: 1rem;
`;

export const datagridSx = {
  backgroundColor: '#FFFFFF',
  width: 'auto',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  '& .MuiDataGrid-cell': {
    minHeight: '100% !important',
    maxHeight: '100% !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: 'none',
  },
  '& .MuiTablePagination-root': {
    color: DARK_CHARCOAL,
  },
  '& .MuiDataGrid-row': {
    minHeight: '100% !important',
    maxHeight: '100% !important',
    borderBottom: `1px solid ${BORDER_GRAY_COLOR}`,
  },
  marginTop: '0.375rem',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
  },
  borderBottom: 0,
  borderRadius: 0,
  borderRight: 0,
  borderLeft: 0,
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
};

export const contributorDataGridSx = {
  backgroundColor: '#FFFFFF',
  width: 'auto',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  '&.MuiDataGrid-root': {
    boxShadow: 'none',
    height: '97%',
  },
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },

    '& div div div div >.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
  },
  '& .MuiDataGrid-cell': {
    minHeight: '100% !important',
    maxHeight: '100% !important',
  },
  '& .MuiTablePagination-root': {
    color: DARK_CHARCOAL,
    borderRadius: 0,
    border: 'none',
  },
  '& .MuiDataGrid-row': {
    minHeight: '100% !important',
    maxHeight: '100% !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '140%',
    color: '#333333',
    borderBottom: 'none',
  },
  borderBottom: 0,
  borderRadius: 0,
  borderRight: 0,
  borderLeft: 0,
  borderTop: 0,
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
};

export const customArrowStyle = {
  color: COOL_GRAY,
};
export const FarmCardHeaderStyle = {
  margin: 0,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '140%',
  color: DARK_CHARCOAL,
  letterSpacing: '0px',
};
export const ContributorFarmCardHeaderStyle = {
  ...FarmCardHeaderStyle,
  paddingLeft: '0.625rem',
};
export const OptionsWrapper = styled.div`
  padding-right: ${({ fontSize }) => fontSize || '0.875rem'};
  align-self: flex-end;
`;

export const FarmWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  margin-top: 2rem;
`;

export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
  margin-left: ${({ marginLeft }) => marginLeft || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
`;
export const ContributorDataGridWrapper = styled.div`
  height: 82vh,
  width: 100%,
`;

export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  margin-left: ${({ marginLeft }) => marginLeft};
  color: ${DARK_CHARCOAL};
  font-family: Open Sans;
  width: ${({ width }) => width};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SecondaryTextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  font-family: Open Sans;
  width: ${({ width }) => width};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const LinkTextWrapper = styled.div`
  display: flex;
  font-size: 0.875rem;
  margin-top: ${({ marginTop }) => marginTop};
  align-self: ${({ alignSelf }) => alignSelf};
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${({ color }) => color};
  font-family: Open Sans;
  width: ${({ width }) => width};
  align-items: ${({ alignment }) => alignment};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;
export const AddressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  margin-bottom: 1rem;
  margin-top: 0.25rem;
`;

export const deleteToastMessageStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
