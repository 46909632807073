import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  OccurrenceDetailsStyle,
  DatePickerStyle,
  DeleteIconStyle,
  PopperProps,
  RadioGroupStyle,
  DatePickerTextFieldStyle,
  AvailableQuantityLabel,
} from './OccurrenceDetails.style';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import calendarIcon from '../../../assets/icons/calendar.png';
import { DELIVERY_DETAILS } from '../DeliveryDetails.content';
import { PAGE_CONTENT } from './OccurrenceDetails.content';
import axios from 'axios';
import { ROW_LEVEL_VALIDATION } from 'urls';
import { itcToDateFormatter } from 'utils/dateFormatter';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';

const OccurrenceDetails = ({
  viewMode,
  isRadioButton,
  setDate,
  setMtAmount,
  setRadioButtonValue,
  setDeliveryDetails,
  index,
  deliveryDetails,
  isEditable,
  item,
  activeDeliveryDetails,
  pastDeliveryDetails,
  className,
  minDeliveryDate,
  projectObj,
  vintages,
  combinedDetails,
  setRowValidationError,
  amountValidate,
  setAmountValidate,
  isDisabled,
  disableRemove,
}) => {
  const [display, setDisplay] = useState('none');
  const [daysDisabled, setDaysDisabled] = useState([]);
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);
  const disabled = viewMode || isDisabled;

  const updateDeliveryDetails = (updatedDeliveryObject) => {
    setDeliveryDetails((current) => {
      return current.map((deliveryDetail) => {
        return deliveryDetail.id === updatedDeliveryObject.id
          ? updatedDeliveryObject
          : deliveryDetail;
      });
    });
  };

  useEffect(() => {
    if (
      amountValidate.maxAmount !== '' &&
      !item.isApiPopulated &&
      item.mtAmount !== '' &&
      deliveryDetails.length - 1 === index
    ) {
      updateDeliveryDetails({
        ...item,
        rowValid: validateMaxAmount(item.mtAmount),
      });
      setRowValidationError(
        item.mtAmount > amountValidate.maxAmount &&
          amountValidate.maxAmount !== '',
      );
      setAmountValidate((prev) => ({
        maxAmount: prev.maxAmount,
        rowError: item.mtAmount > amountValidate.maxAmount,
      }));
    }
  }, [amountValidate.maxAmount, item.mtAmount]);

  const validateMaxAmount = (amount) => {
    if (item.isApiPopulated) {
      return true;
    } else if (
      deliveryDetails.length - 1 === index &&
      amountValidate.maxAmount !== ''
    ) {
      return amount <= amountValidate.maxAmount && amount !== '';
    } else {
      return true;
    }
  };

  const handleTextChange = (event) => {
    if (
      event.target.value.match(/^\d*(\.\d{0,2})?$/) ||
      event.target.value === ''
    ) {
      updateDeliveryDetails({
        ...item,
        mtAmount: event.target.value,
      });
      setMtAmount(event.target.value);
    }
  };
  const handleRadioButtonChange = (event) => {
    updateDeliveryDetails({ ...item, deliveryStatus: event.target.value });
    setRadioButtonValue(event.target.value);
  };
  const handleDateChange = (newDate) => {
    updateDeliveryDetails({ ...item, occursOn: newDate });
    setDate(newDate);
    handleMaxAmount(newDate, deliveryDetails.length - 1 === index);
    markFormDirty();
  };

  const handleMaxAmount = (dateValue, isValid) => {
    if (isRadioButton && isValid && !item.isApiPopulated) {
      axios
        .post(ROW_LEVEL_VALIDATION, {
          internalProjectId: projectObj.projectName.internalProjectId,
          vintageStart: vintages.vintageStart,
          vintageEnd: vintages.vintageEnd,
          currentDeliveryDate: itcToDateFormatter(dateValue),
          deliveryDetails: combinedDetails
            .filter((detail) => detail.id !== item.id)
            .filter((detail) => !detail.isApiPopulated)
            .map((detail) => ({
              allocatedSupply: detail.mtAmount,
              deliveryDate: itcToDateFormatter(detail.occursOn),
              deliveryStatus: detail.deliveryStatus,
            })),
        })
        .then((response) => {
          setAmountValidate((prev) => ({
            maxAmount: parseFloat(
              response.data.availableAllocatedSupply !== null
                ? response.data.availableAllocatedSupply
                : 0,
            ),
            rowError: prev.rowError,
          }));
        });
    }
  };

  const handleClickDelete = () => {
    let itemToDelete = deliveryDetails[index];
    let deliveryDetailsArray = deliveryDetails.filter(
      (item) => item.id !== itemToDelete.id,
    );
    setDeliveryDetails(deliveryDetailsArray);
    setAmountValidate({ rowError: false, maxAmount: '' });
    setRowValidationError(false);
    markFormDirty();
  };

  const calendar = () => {
    return <img src={calendarIcon}></img>;
  };

  const filterDates = (dateArray) => {
    if (dateArray?.length > 0 && dateArray !== undefined) {
      return dateArray.map((item) => {
        if (item.occursOn !== null) {
          return new Date(item.occursOn);
        }
      });
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (pastDeliveryDetails?.length > 0 && pastDeliveryDetails !== undefined) {
      setDaysDisabled(
        filterDates(deliveryDetails).concat(filterDates(pastDeliveryDetails)),
      );
    } else if (
      activeDeliveryDetails?.length > 0 &&
      activeDeliveryDetails !== undefined
    ) {
      setDaysDisabled(
        filterDates(deliveryDetails).concat(filterDates(activeDeliveryDetails)),
      );
    } else {
      setDaysDisabled(filterDates(deliveryDetails));
    }
  }, [deliveryDetails, pastDeliveryDetails, activeDeliveryDetails]);

  const disabledDays = (date) => {
    let count = 0;
    const filterDatesArray = daysDisabled.filter((item) => item !== undefined);
    if (filterDatesArray?.length > 0 && filterDatesArray[0] !== undefined) {
      filterDatesArray?.forEach((item) => {
        if (
          date.month() === item?.getMonth() &&
          date?.year() === item?.getFullYear() &&
          date?.date() === item?.getDate()
        ) {
          count = count + 1;
        }
      });
    }
    let countResult = false;
    if (count > 0) {
      countResult = true;
    }
    return countResult;
  };

  return (
    <Box
      className={className}
      onMouseOver={() => setDisplay(true)}
      onMouseLeave={() => setDisplay('none')}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DesktopDatePicker
            disabled={disabled}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            readOnly={!isEditable}
            className={DatePickerStyle}
            shouldDisableDate={disabledDays}
            components={{
              OpenPickerIcon: calendar,
            }}
            minDate={minDeliveryDate}
            value={item.occursOn}
            onChange={(newValue) => handleDateChange(newValue.toDate())}
            PopperProps={{ sx: PopperProps }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                variant={PAGE_CONTENT.textfield_variant}
                sx={DatePickerTextFieldStyle}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
      <div>
        <TextField
          disabled={disabled}
          error={
            amountValidate.rowError &&
            isRadioButton &&
            amountValidate.maxAmount !== '' &&
            deliveryDetails.length - 1 === index
          }
          InputProps={{
            readOnly: !isEditable,
            style: OccurrenceDetailsStyle(
              '0.95rem',
              '1rem',
              isRadioButton &&
                amountValidate.maxAmount !== '' &&
                deliveryDetails.length - 1 === index
                ? '1rem'
                : '0rem',
              amountValidate.rowError &&
                isRadioButton &&
                amountValidate.maxAmount !== '' &&
                deliveryDetails.length - 1 === index,
            ),
            required: true,
          }}
          id="standard-basic"
          placeholder={PAGE_CONTENT.amount_placeholder}
          variant={PAGE_CONTENT.textfield_variant}
          value={item.mtAmount}
          onChange={handleTextChange}
        />
        {isRadioButton &&
          amountValidate.maxAmount !== '' &&
          deliveryDetails.length - 1 === index && (
            <AvailableQuantityLabel error={amountValidate.rowError}>
              {amountValidate.maxAmount} MT Available
            </AvailableQuantityLabel>
          )}
      </div>
      {(isRadioButton && (
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={item.deliveryStatus}
            onChange={handleRadioButtonChange}>
            <RadioGroupStyle>
              <FormControlLabel
                disabled={disabled}
                sx={OccurrenceDetailsStyle('0.9rem', '2rem')}
                value={PAGE_CONTENT.transferred}
                control={<Radio />}
                label={PAGE_CONTENT.transferred}
                data-testid={PAGE_CONTENT.transferred}
              />
              <FormControlLabel
                disabled={disabled}
                sx={OccurrenceDetailsStyle('0.9rem', '2rem')}
                value={PAGE_CONTENT.retired}
                control={<Radio />}
                label={PAGE_CONTENT.retired}
              />
            </RadioGroupStyle>
          </RadioGroup>
        </FormControl>
      )) || <Text paddingLeft={'2rem'}>{DELIVERY_DETAILS.deposited}</Text>}

      {!viewMode && !disableRemove && (
        <DeleteIcon sx={DeleteIconStyle(display)} onClick={handleClickDelete} />
      )}
    </Box>
  );
};

OccurrenceDetails.propTypes = {
  viewMode: PropTypes.bool,
  isRadioButton: PropTypes.bool,
  setDate: PropTypes.func,
  setMtAmount: PropTypes.func,
  setRadioButtonValue: PropTypes.func,
  setDeliveryDetails: PropTypes.func,
  index: PropTypes.number,
  deliveryDetails: PropTypes.array,
  isEditable: PropTypes.bool,
  item: PropTypes.object,
  activeDeliveryDetails: PropTypes.array,
  pastDeliveryDetails: PropTypes.array,
  className: PropTypes.string,
  minDeliveryDate: PropTypes.instanceOf(Date),
  projectObj: PropTypes.object,
  vintages: PropTypes.array,
  combinedDetails: PropTypes.array,
  setRowValidationError: PropTypes.func,
  amountValidate: PropTypes.bool,
  setAmountValidate: PropTypes.func,
  isDisabled: PropTypes.bool,
  disableRemove: PropTypes.bool,
};

export default OccurrenceDetails;
