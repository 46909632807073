export const PAGE_CONTENT = {
  quantity_label: 'Quantity (MT CO2e)',
  quantity_placeholder: 'Type quantity',
  quantity_subtext: 'metric tons of CO2e',
  price_label: 'Price per MT CO2e',
  price_placeholder: 'Type USD price',
  price_subtext: 'per metric tons of CO2e',
  quantity_error: 'A quantity is required for submission',
  vantage_start_label: 'Vintage Start',
  vantage_end_label: 'Vintage End',
  vantage_start_placeholder: 'Select Date',
  vantage_end_placeholder: 'Select Date',
  price_error: 'A price is required for submission',
  vintage_start_error: 'A vintage start selection is required for submission',
  vintage_end_error: 'A vintage end selection is required for submission',
  add_deposit_label: 'Add deposits',
  vintage_year_error: 'Please select valid vintage start and end',
  add_delivery_details_label: 'Add delivery details for',
  add_delivery_details_warning: 'Data entry changes will not be saved',
  add_delivery_details_warning_subtitle:
    'All data entry changes will be lost by closing the delivery details without saving .',
  view_edit_delivery_details: 'View/Edit delivery details',
  view_delivery_details: 'View delivery details',
  view_delivery_details_label: 'Delivery details for',
  delivery_details: 'Delivery Details',
  vintage_duplicate_error:
    'These vintage start and end dates have already been applied to this variable cost structure.',
  inventory_allocated_heading: 'Inventory allocated in one or more sales',
  inventory_allocated_content:
    'This inventory has been allocated in one or more sales. Updates made to this transaction will result in updates to the associated sales. Please confirm to continue.',
  delivery_details_error:
    'Delivery Details are required and must match the quantity entered for submission.',
  vintage_label: 'Vintage',
  remove_vintage_label: 'Remove vintage',
  decrease_quantity_error:
    'Quantity can’t be decreased since this project-vintage are used in a sale transaction.',
};

export const Regex = {
  decimalValues: /^\d*(\.\d{0,2})?$/,
  digit: /\d+/,
};

export const VintageType = {
  start: 'start',
  end: 'end',
};
