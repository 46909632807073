import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import NewClearCarbonLogo from './../../../assets/NewClearCarbonLogo.png';
import { styles } from './PageFooter.style';

const PageFooter = () => (
  <View style={styles?.container} fixed>
    <View style={styles?.brandName}>
      <Text style={styles?.pagenumber}>Powered by</Text>
      <Image src={NewClearCarbonLogo} style={styles?.image} />
    </View>
    <Text
      style={styles?.pagenumber}
      render={({ pageNumber }) => `${pageNumber}`}
      fixed
    />
  </View>
);

export default PageFooter;
