import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  MainWrapper,
  datagridSx,
  FilterHeader,
  DataGridWrapper,
} from '../../../ProjectCatalog/ProjectCatalog.style';
import {
  ParticipantButtonWrapper,
  ParticipantListTabberStyle,
  TextWrapper,
  FieldWrapper,
  addBtnSx,
  backdropSx,
  acreLabelSx,
  activityStatusSx,
  contactEmail,
} from './Participants.style';
import { DataGrid } from '@mui/x-data-grid';
import { useInterval } from 'hooks/useInterval';
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import {
  listingParticipantsColumn,
  SortColValues,
  LABELS,
  PARTICIPANT_PARENT_PATH,
  baselineModalPropsInit,
  initialReviewEnrollmentProp,
  getActionIdFromActionCode,
  INITIATE_ACTIVITY_REPORTING_INITIALSTATE,
  getStatusIcon,
  getParticipantStatus,
  getParticipantAccountStatus,
  getParticipantAccountStatusCode,
  PARTICIPANT_TYPES,
} from './Participants.content';
import AddButton from 'components/AddButton/AddButton.react';
import {
  DELETE_PARTICIPANT,
  FETCH_ALL_USER_ACTIONS,
  FETCH_CONTRACT_PDF,
  FETCH_PARTICIPANTS,
  FETCH_POSSIBLE_ACTIVITY_STATUS,
  FETCH_FILTER_ACCOUNT_STATUS,
  FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST,
  FETCH_PROJECT_PARTICIPANT_CONTRIBUTORS,
  GET_PROJECT_LIFE_CYCLE_LIST,
  PARTICIPANTS_LIST,
} from 'urls';
import NodataComponent from 'components/NoDataComp/NodataContent';
import AddParticipants from './AddParticipants/AddParticipants.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import {
  checkAccess,
  checkTernaryCondition,
  getLatestDate,
  openPDFFromLink,
  debounceFunc,
} from 'utils/helper';
import { pathName } from 'Routes/routes.const';
import EditParticipantProfile from './EditParticipantProfile/EditParticipantProfile.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ADMIN,
  ORIGINATION_PARTICIPANT_CREATE,
  ORIGINATION_PARTICIPANT_DELETE,
  ORIGINATION_PARTICIPANT_UPDATE,
} from 'utils/config';
import {
  DARK_CHARCOAL,
  DARK_GREEN,
  DOWNLOAD_LINK,
  TYPOGRAPHY_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import {
  TablePaginationMenuListStyle,
  pointerCursor,
} from 'theme/GlobalStyles';
import { Backdrop, CircularProgress } from '@mui/material';
import { enrollmentReview } from './EnrollmentReview';
import { baselineReview } from './BaselineReview';
import { ReopenCustomModal } from './ReOpenTemplate';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import {
  AppliedFilters,
  ClearLink,
  FilterChip,
  SearchAndFilterSection,
  SearchAndFiltersFlex,
  SearchBox,
} from 'pages/OriginationProjectList/OriginationProjectList.style';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { LoaderProvider } from 'contextAPI/loaderContext';
import AddParticipantsViaFileUpload from './AddParticipantsViaFileUpload/AddParticipantsViaFileUpload.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import { UpdateAccount } from './ParticipantAccount/UpdateAccountModal';
import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import { PAGE_CONTENT } from 'components/FilterComponent/FilterComponent.content';
import { clearIconStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';
import Tabber from 'components/Tabber/Tabber.react';
import ParticipantContributorList from './ParticipantContributorList.react';
import moment from 'moment';
import { ConfigurationContext } from 'contextAPI/configurationContext';
const LIST_REFRESH_INTERVAL = 5000;

const ListParticipants = ({
  refreshHandler,
  disableVirtualization,
  projectName,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userRoles } = useContext(userAuthRolesContext);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [contributorPaginationData, setContributorPaginationData] = useState({
    totalRows: 0,
    page: 1,
    pageSize: 25,
  });
  const [payload, setPayload] = useState({
    filter: {},
    projectId: null,
    pageNumber: null,
    pageSize: null,
    newestToOldest: null,
  });
  const [newDataSet, setNewDataSet] = useState([]);
  const [sortColumn, setSortColumn] = useState('');
  const [currentRow, setCurrentRow] = useState();
  const [modifiedList, setModifiedList] = useState({
    PARTICIPANT_NAME: [],
    PARTICIPANT_EMAIL: [],
  });
  const [participantsNameSelectedFilter, setParticipantsNameSelectedFilter] =
    useState([]);
  const [
    participantsNameIdSelectedFilter,
    setParticipantsNameIdSelectedFilter,
  ] = useState([]);
  const [participantsEmailSelectedFilter, setParticipantsEmailSelectedFilter] =
    useState([]);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [filterData, setFilterData] = useState({
    participantsList: [],
    contactList: [],
    contractsList: [],
  });
  const [participantNameIdFilter, setParticipantNameIdFilters] = useState([]);
  const [participantDialogOpen, setParticipantDialogOpen] = useState({
    flag: false,
    id: 0,
  });
  const [isUpdateAccountModalOpen, setUpdateAccountModalOpen] = useState({
    open: false,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [enrollModalData, setEnrollModalData] = useState({
    flag: false,
    id: 0,
    projectData: [],
    projectId: 0,
    participantName: '',
    instanceId: -1,
    shouldGenerateNewInstance: false,
  });
  const [baselineModalProps, setBaselineModalProps] = useState(
    baselineModalPropsInit,
  );
  const [reviewEnrollmentProps, setReviewEnrollmentProps] = useState(
    initialReviewEnrollmentProp,
  );
  const [reopenProps, setReopenProps] = useState(initialReviewEnrollmentProp);
  const [addContractOpen, setAddContractOpen] = useState({
    isOpen: false,
    participantId: null,
    projectId: null,
  });
  const { permissions, contractPdfBucketId } = useContext(userAuthRolesContext);
  const [participantSearchText, setParticipantSearchText] = useState('');
  const [incentiveList, setIncentiveList] = useState([]);
  const [
    multipleParticipantsFileUploadOpen,
    setMultipleParticipantsFileUploadOpen,
  ] = useState(false);

  const [hasNoParticipant, setHasNoParticipant] = useState(false);
  const debounceTimerRef = useRef(null);
  const contributorDebounceTimerRef = useRef(null);
  const [statusMappingList, setStatusMappingList] = useState([]);
  const [fetchedProjectLifeCycleList, setFetchedProjectLifeCycleList] =
    useState([]);
  const [participantAccount, setParticipantAccount] = useState({});
  const [accountStatusFilterList, setAccountStatusFilterList] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [contributorList, setContributorList] = useState([]);
  const [participantActivity, setParticipantActivity] = useState([]);
  const [activityStatusFilterList, setActivityStatusFilterList] = useState([]);
  const { areUiActionInProgress } = useContext(ConfigurationContext);

  const handleTabChange = (newTab) => {
    setParticipantSearchText('');
    setAccountStatusFilterList([]);
    setActivityStatusFilterList([]);
    if (newTab === 1) {
      fetchContributorList('', []).then(() => setCurrentTab(newTab));
      fetchAccountStatus(PARTICIPANT_TYPES.contributor);
    } else {
      setCurrentTab(newTab);
      fetchAccountStatus(PARTICIPANT_TYPES.owner);
    }
  };

  const fetchContributorList = async (
    searchText = participantSearchText,
    participantAccountStatuses = accountStatusFilterList,
    paginationProps = contributorPaginationData,
  ) => {
    const { page, pageSize } = paginationProps;

    const apiPayload = {
      filter: {
        contact: [],
        participant: [],
        participantAccountStatuses: participantAccountStatuses,
        participantNameOrEmailSearch: searchText,
      },
      newestToOldest: true,
      pageNumber: checkTernaryCondition(searchText.length > 0, 0, page - 1),
      pageSize: pageSize,
      projectId: id,
    };

    setLoading(true);
    return axios
      .post(FETCH_PROJECT_PARTICIPANT_CONTRIBUTORS, apiPayload)
      .then((response) => {
        const data = response.data.content;
        const formattedData = data?.map((participant) => ({
          ...participant,
          participantFullName: `${participant.participantFirstName} ${participant.participantLastName}`,
        }));
        setContributorList(formattedData);
        setContributorPaginationData((prev) => ({
          ...prev,
          totalRows: data.length,
        }));
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const debouncedFetchContributorList = debounceFunc(
    fetchContributorList,
    500,
    contributorDebounceTimerRef,
  );

  const getLatestEnrollmentInstanceId = (enrollmentInstances = []) => {
    let latestDate = getLatestDate(
      enrollmentInstances?.map((instance) => instance.createdDate),
    );
    return (
      enrollmentInstances.find(
        (instance) => instance.createdDate === latestDate,
      )?.enrollmentInstanceId ?? null
    );
  };

  const currentRowSetter = (row) => {
    setCurrentRow(row);
  };
  const fetchIncentiveListForParticipant = () => {
    axios
      .get(
        `${FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST}?participantId=${addContractOpen?.participantId}&projectId=${addContractOpen?.projectId}`,
      )
      .then((response) => {
        setIncentiveList(response.data);
      });
  };

  // Note: This function cleans up the applied "Account status" filter in case any filter is applied which has no participant/contributor, upon updating account status of a participant, applied filter might have redundant data, we are cleaning the filter states here
  const removeUnwantedFilters = (accountStatusList) => {
    const statusCodesList = accountStatusList?.map((status) =>
      getParticipantAccountStatusCode(status),
    );
    setAccountStatusFilterList((prev) => {
      return prev.filter((filterLabel) =>
        statusCodesList.includes(filterLabel),
      );
    });

    if (currentTab === 1) {
      debouncedFetchContributorList(
        participantSearchText,
        accountStatusFilterList?.filter((filterLabel) =>
          statusCodesList.includes(filterLabel),
        ),
      );
    }
  };

  const fetchAccountStatus = (participantType = PARTICIPANT_TYPES.owner) => {
    axios
      .get(FETCH_FILTER_ACCOUNT_STATUS, {
        params: { projectId: id, participantType: participantType },
      })
      .then((response) => {
        const accountStatus = response.data?.map(getParticipantAccountStatus);
        removeUnwantedFilters(accountStatus);
        setParticipantAccount(accountStatus);
      })
      .catch(() => {});
  };

  const fetchActivityStatus = () => {
    axios
      .get(FETCH_POSSIBLE_ACTIVITY_STATUS, { params: { projectId: id } })
      .then((response) => {
        const statusObject = response.data;
        const activityMapping = Object.entries(statusObject).flatMap(
          ([status, actionIds]) => {
            return {
              actionId: actionIds,
              status: status,
            };
          },
        );
        setParticipantActivity(activityMapping);
      });
  };

  useEffect(() => {
    fetchAccountStatus();
  }, []);

  const fetchAllUserActions = () => {
    axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
      setStatusMappingList(response.data);
    });
  };

  useEffect(() => {
    fetchParticipantsList(!!participantSearchText, true);
    fetchFilterData();
    fetchAllUserActions();
    fetchProjectCycleList();
    return () => {
      setParticipantsNameSelectedFilter([]);
      setParticipantsEmailSelectedFilter([]);
    };
  }, []);

  useEffect(() => {
    payload.projectId !== null &&
      debouncedFetchParticipantsList(!!participantSearchText);
  }, [participantSearchText]);

  useEffect(() => {
    fetchActivityStatus();
  }, [statusMappingList]);

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);

  useEffect(() => {
    payload.projectId !== null &&
      debouncedFetchParticipantsList(!!participantSearchText);
  }, [
    participantsNameSelectedFilter,
    participantsNameIdSelectedFilter,
    participantsEmailSelectedFilter,
    sortColumn,
    order,
    pageState.pageSize,
    pageState.page,
  ]);

  useEffect(() => {
    if (currentTab === 1) return;

    const timer = setTimeout(() => {
      payload.projectId !== null &&
        fetchParticipantsList(!!participantSearchText);
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    baselineModalProps.open,
    reviewEnrollmentProps.modalData.flag,
    reopenProps.modalData.flag,
    enrollModalData.flag,
    addContractOpen.isOpen,
    accountStatusFilterList,
    activityStatusFilterList,
  ]);
  useEffect(() => {
    if (addContractOpen.isOpen) {
      fetchIncentiveListForParticipant();
    }
  }, [addContractOpen]);
  useEffect(() => {
    let participantIds = [];
    participantNameIdFilter.forEach((data) => {
      if (participantsNameSelectedFilter?.includes(data?.label)) {
        participantIds.push(data?.id);
      }
    });
    setParticipantsNameIdSelectedFilter(participantIds);
  }, [participantsNameSelectedFilter]);

  const {
    updateParticipantStatus,
    generateBaselineId,
    updateParticipantBaselineStatus,
  } = useContext(LifeCycleContext);

  const fetchParticipantsList = (
    isSearch = false,
    isInitialCall = false,
    enableLoader = true, // Property mainly used to avoid loading spinner on polling request to fetch data and change status frequently. ESGCC-14539 & ESGCC-14602
  ) => {
    if (currentTab === 1) return;

    const filterObject = {
      participant: participantsNameIdSelectedFilter,
      contact: participantsEmailSelectedFilter,
      participantNameOrEmailSearch: participantSearchText,
      participantAccountStatuses: accountStatusFilterList,
      participantAccountActivity: activityStatusFilterList?.flatMap(
        (item) => item.actionId,
      ),
    };
    const projectIdToNewestToOldest = {
      projectId: id,
      pageNumber: isSearch ? 0 : pageState.page - 1,
      pageSize: pageState.pageSize,
      newestToOldest: order,
    };
    const newPayload = {
      filter: filterObject,
      ...projectIdToNewestToOldest,
    };
    const payloadOthersProps = { ...payload };
    delete payloadOthersProps.filter;
    // isDataFetched should not set during polling request to avoid flickering of data.
    enableLoader && setIsDataFetched(false);
    setPageState((old) => ({ ...old, isLoading: enableLoader }));
    // to fetch data with pagesize, pagebody  and filter
    axios
      .post(PARTICIPANTS_LIST, newPayload)
      .then((response) => {
        if (isInitialCall && response.data?.content?.length === 0) {
          setHasNoParticipant(true);
        }
        if (response.data?.content?.length > 0) setHasNoParticipant(false);
        let participants = [];
        const enrollmentApprovedParticipantIdList = [];
        const baselineEnrolledParticipantIdList = [];
        const baselineApprovedParticipantIdList = [];
        response.data?.content?.forEach((participant) => {
          participants.push({
            id: participant.participantId,
            participantfullname:
              participant.participantFirstName +
              ' ' +
              participant.participantLastName,
            actionId: participant?.actionId,
            baselineActionId: participant?.baselineActionId,
            isBaselineInititated:
              participant?.actionId === 12 &&
              participant?.baselineActionId !== null,
            status: '',
            formId: participant?.formId,
            ...participant,
          });

          if (participant.participantProjectStatus === 'Approved') {
            enrollmentApprovedParticipantIdList.push(
              Number(participant.participantId),
            );
          }
          if (participant.participantBaselineStatus === 'Enrolled') {
            baselineEnrolledParticipantIdList.push(
              Number(participant.participantId),
            );
          }
          if (participant.baselineActionId === 22) {
            // baseLineActionId 22 stands for BASELINE_APPROVED
            baselineApprovedParticipantIdList.push(
              Number(participant.participantId),
            );
          }
        });
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: participants,
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      })
      .finally(() => {
        setPayload(newPayload);
      });
  };

  useInterval(async () => {
    if (currentTab === 1) return;
    payload.projectId !== null &&
      areUiActionInProgress === 0 &&
      fetchListAndFilterData();
  }, LIST_REFRESH_INTERVAL);

  const fetchListAndFilterData = () => {
    fetchParticipantsList(!!participantSearchText, false, false);
    fetchFilterData();
  };

  const debouncedFetchParticipantsList = debounceFunc(
    fetchParticipantsList,
    500,
    debounceTimerRef,
  );

  // Need to change based on Project ID
  const fetchFilterData = () => {
    axios
      .get(FETCH_PARTICIPANTS, {
        params: {
          projectId: id,
          name: '',
          isLinked: true,
        },
      })
      .then((response) => {
        let participants = [];
        let contacts = [];
        let participantName_Id = [];
        let contractsList = [];
        response.data.forEach((participant) => {
          participants.push({
            id:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
            label:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
          });
          participantName_Id.push({
            id: participant?.participantId,
            label:
              participant?.participantFirstName +
              ' ' +
              participant?.participantLastName,
          });
          contacts.push({
            id: participant?.participantEmail,
            label: participant?.participantEmail,
          });
          contractsList.push({
            id: participant?.participantId,
            contracts: participant?.contracts,
          });
        });
        setFilterData({
          ...filterData,
          participantsList: participants,
          contactList: contacts,
          contractsList: contractsList,
        });
        setParticipantNameIdFilters(participantName_Id);
      })
      .catch(() => {
        // react on errors.
      });
  };

  const addEditParticipantsHandler = (id) => {
    if (id === 0) {
      setParticipantDialogOpen({ flag: true, id: 0 });
    } else {
      setParticipantDialogOpen({ flag: true, id: id });
    }
  };

  const deleteParticipant = () => {
    setIsDeleteDialogOpen(false);
    const successMessage = `${currentRow?.participantFirstName} ${currentRow?.participantLastName} was successfully removed from ${projectName}.`;
    const errorMessage = `${currentRow?.participantFirstName} ${currentRow?.participantLastName} failed to be removed from ${projectName}. Please try again.`;
    axios
      .delete(
        `${DELETE_PARTICIPANT}/${currentRow?.participantId}?internalProjectId=${id}`,
      )
      .then(() => {
        toast(<CustomSnackbar type="success" message={successMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchParticipantsList(!!participantSearchText);
        refreshHandler();
      })
      .catch(() => {
        toast(<CustomSnackbar type="error" message={errorMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortColumn(SortColValues[params.field]);
    setOrder(!order);
  };
  const handelFilterClick = () => {
    const participantNameList = filterData.participantsList?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    const participantEmailList = filterData.contactList?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });

    setModifiedList(() => ({
      ...modifiedList,
      PARTICIPANT_NAME: [...participantNameList],
      PARTICIPANT_EMAIL: [...participantEmailList],
    }));
  };

  const fetchAndOpenPDF = (pdfName) => {
    if (pdfName?.trim()) {
      axios
        .get(`${FETCH_CONTRACT_PDF}${contractPdfBucketId}&pdfName=${pdfName}`)
        .then((response) => {
          openPDFFromLink(response.data);
        });
    }
  };

  const transformAccountMapToList = (map) => {
    return Object.entries(map)?.map(([id, itemLabel]) => ({
      id,
      itemLabel,
      checked: accountStatusFilterList.includes(
        getParticipantAccountStatusCode(itemLabel),
      ),
    }));
  };

  const transformActivityMapToList = (activityStatus) => {
    const obj = activityStatus?.map(({ actionId, status }, index) => ({
      id: index,
      itemLabel: status,
      checked: activityStatusFilterList.some((filter) => filter.id === index),
      actionId: actionId,
    }));
    return obj;
  };

  let filterSections = [
    ...(currentTab !== 1
      ? [
          {
            heading: LABELS.activity_status,
            filters: transformActivityMapToList(participantActivity),
          },
        ]
      : []),
    {
      heading: LABELS.account_status,
      filters: transformAccountMapToList(participantAccount),
    },
  ];

  const updateFilterStates = (sectionHeading, checkedFields) => {
    switch (sectionHeading) {
      case LABELS.account_status: {
        setAccountStatusFilterList(checkedFields);
        if (currentTab === 1) {
          debouncedFetchContributorList(participantSearchText, checkedFields);
        }
        break;
      }
      case LABELS.activity_status: {
        setActivityStatusFilterList(checkedFields);
      }
    }
  };

  const handleFilterSectionUpdate = (sections) => {
    sections.forEach((section) => {
      const checkedFields = section.filters
        .filter((filter) => filter.checked === true)
        ?.map((filter) => {
          if (section.heading === LABELS.activity_status) {
            return {
              id: filter.id,
              actionId: filter.actionId,
              itemLabel: filter.itemLabel,
            };
          } else {
            return getParticipantAccountStatusCode(filter.itemLabel);
          }
        });
      updateFilterStates(section.heading, checkedFields);
    });
  };

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    filterSections.forEach((section) => {
      section.filters?.map((filter) => {
        if (filter.checked) count++;
      });
    });

    return count;
  }, [filterSections]);

  const generateBaselineInstanceAndUpdateStatus = async (
    participantId,
    projectId,
  ) => {
    return generateBaselineId({
      projectId: projectId,
      participantId: participantId,
      enrollmentType: 'Baseline',
    }).then((response) => {
      const formId = response.data;
      return updateParticipantBaselineStatus({
        formId: response.data,
        actionId: getActionIdFromActionCode(
          'BASELINE_DEFAULT',
          statusMappingList,
        ),
      }).then(() => formId);
    });
  };

  const fetchProjectCycleList = () => {
    axios.get(`${GET_PROJECT_LIFE_CYCLE_LIST}/${id}`).then((response) => {
      setFetchedProjectLifeCycleList(
        response.data?.map((item) => ({
          label: `${moment(item.cycleEndDate).format('YYYY')} (${moment(
            item.cycleStartDate,
          ).format('MM/DD/YYYY')} - ${moment(item.cycleEndDate).format(
            'MM/DD/YYYY',
          )})`,
          value: item.projectCycleId,
        })),
      );
    });
  };

  const columns = [
    {
      field: listingParticipantsColumn.participantId.field,
      hide: true,
    },
    {
      field: listingParticipantsColumn.participant.field,
      headerName: listingParticipantsColumn.participant.label,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <span className="bold-600">
            {listingParticipantsColumn.participant.label}
          </span>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <div>
          <TextWrapper color={DOWNLOAD_LINK}>{params.value}</TextWrapper>
        </div>
      ),
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: listingParticipantsColumn.status.field,
      headerName: listingParticipantsColumn.status.label,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => {
        if (
          params.row.enrollmentInstanceStatus?.length === 0 &&
          params.row.contracts?.length === 0
        ) {
          return (
            <TextWrapper
              fontSize="0.9rem"
              fontWeight={400}
              color={DOWNLOAD_LINK}>
              {getStatusIcon('Interested')}
              {`Recruitment-Interested`}
            </TextWrapper>
          );
        }
        return (
          <div>
            <FieldWrapper>
              {params.row.enrollmentInstanceStatus?.map((instanceData) =>
                getParticipantStatus(statusMappingList, instanceData),
              )}
            </FieldWrapper>
          </div>
        );
      },
    },
    {
      field: listingParticipantsColumn.accountStatus.field,
      headerName: listingParticipantsColumn.accountStatus.label,
      sortable: false,
      renderHeader: () => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <span className="bold-600">
            {listingParticipantsColumn.accountStatus.label}
          </span>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <div>
          <TextWrapper>{getParticipantAccountStatus(params.value)}</TextWrapper>
        </div>
      ),
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'options',
      headerName: '',
      disableColumnMenu: true,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => {
            currentRowSetter(params?.row);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              currentRowSetter(params?.row);
            }
          }}
          data-testid={`options-${params.row?.id}`}>
          <SimpleOptionsButton
            hasDeleteOption={false}
            updateStatusAccountOptionDisabled={!checkAccess(userRoles, ADMIN)}
            hasContract
            addContactText={LABELS.addContact}
            onContractClick={() => {
              setAddContractOpen({
                isOpen: true,
                participantId: params.row.participantId,
                projectId: id,
              });
            }}
            editId={params.row.participantId}
            editOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            onEdit={addEditParticipantsHandler}
            onDelete={() => {
              setIsDeleteDialogOpen(true);
            }}
            deleteOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_DELETE) ||
              params.row.participantBaselineStatus === 'Enrolled'
            }
            showEnrollOption={true}
            enrollOptionDisabled={
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            onEnroll={() => {
              setEnrollModalData({
                ...params.row,
                flag: true,
                id: params.row.participantId,
                projectData: [],
                projectId: id,
                participantName: `${params.row.participantfullname}`,
                contractId: params.row.contracts[0].contractId,
                instanceId: getLatestEnrollmentInstanceId(
                  params.row.contracts[0]?.enrolmentInstances,
                ),
                shouldGenerateNewInstance: false,
                actionCode: statusMappingList.find(
                  (data) => data.actionId === params.row.actionId,
                ).actionCode,
              });
            }}
            showEnterBaselineOption={true}
            // Note: Currently participant manager will be blocked to enter baseline, this check is temporary and will be removed later.
            enterBaselineOptionDisabled={
              userRoles.includes('PARTICIPANT_MANAGER') ||
              !checkAccess(permissions, ORIGINATION_PARTICIPANT_UPDATE)
            }
            reviewBaselineDisabled={userRoles.includes('PARTICIPANT_MANAGER')}
            onEnterBaselineClick={async () => {
              setLoading(true);
              let formId = params.row.formId;
              if (
                getActionIdFromActionCode(
                  'ENROLL-APPROVE',
                  statusMappingList,
                ) === params.row.actionId &&
                params.row.baselineActionId === null
              ) {
                formId = await generateBaselineInstanceAndUpdateStatus(
                  params.row.participantId,
                  id,
                );
              }
              setBaselineModalProps({
                open: true,
                actionId: params.row.actionId,
                formId: formId,
                participantName: `${params.row.participantFirstName} ${params.row.participantLastName}`,
                navigateLink: `${pathName.participant.baselineMenuPage}?projectid=${id}&participantid=${params.row.participantId}`,
                navigateState: {
                  projectId: id,
                  projectName: projectName,
                  participantId: params.row.participantId,
                  participantFirstName: params.row.participantFirstName,
                  participantLastName: params.row.participantLastName,
                  participantEmail: params.row.participantEmail,
                },
                actionCode: statusMappingList.find(
                  (data) => data.actionId === params.row.baselineActionId,
                )?.actionCode,
              });
              setLoading(false);
            }}
            enterBaselineLabel={LABELS.enterBaselineLabel}
            reviewEnrollmentLabel={LABELS.reviewEnrollmentLabel}
            onClickReviewEnrollment={() => {
              Promise.resolve(
                setReviewEnrollmentProps({
                  ...enrollmentReview(params.row.participantId, id),
                  modalData: { flag: true },
                  instanceId: getLatestEnrollmentInstanceId(
                    params.row.contracts[0].enrolmentInstances,
                  ),
                  actionCode: statusMappingList.find(
                    (data) => data.actionId === params.row.actionId,
                  )?.actionCode,
                  contractId: params.row.contracts[0].contractId,
                }),
              ).then(
                getActionIdFromActionCode(
                  'ENROLL-SUBMIT',
                  statusMappingList,
                ) === params.row.actionId &&
                  updateParticipantStatus({
                    contractId: params.row.contracts[0].contractId,
                    projectId: id,
                    participantId: params.row.participantId,
                    instanceId: getLatestEnrollmentInstanceId(
                      params.row.contracts[0]?.enrolmentInstances,
                    ),
                    actionId: getActionIdFromActionCode(
                      'ENROLL-REVIEW',
                      statusMappingList,
                    ),
                  }),
              );
            }}
            reviewBaselineLabel={LABELS.reviewBaselineLabel}
            onClickReviewBaseline={() => {
              setReviewEnrollmentProps({
                ...baselineReview(params.row.participantId, id),
                modalData: { flag: true },
                formId: params.row.formId,
                baselineActionId: params.row.baselineActionId,
              });
              getActionIdFromActionCode(
                'BASELINE-SUBMIT',
                statusMappingList,
              ) === params.row.baselineActionId &&
                updateParticipantBaselineStatus({
                  formId: params.row.formId,
                  actionId: getActionIdFromActionCode(
                    'BASELINE-REVIEW',
                    statusMappingList,
                  ),
                });
            }}
            hasUpdateStatusAccount={true}
            onUpdateAccountStatus={() => {
              setUpdateAccountModalOpen({ params, open: true });
            }}
          />
        </div>
      ),
    },
  ];

  const addButtonOptions = [
    {
      id: 1,
      name: 'Add participant',
      modalHandler: () => addEditParticipantsHandler(0),
      hasDivider: false,
    },
    {
      id: 2,
      name: 'Add multiple participants',
      modalHandler: () => setMultipleParticipantsFileUploadOpen(true),
      hasDivider: false,
    },
  ];

  useEffect(() => {
    if (filterData?.contractsList?.length > 0) {
      const newData = pageState.data?.map((obj) => {
        if (
          filterData?.contractsList.find(
            (item) => item.id === obj.participantId,
          )
        ) {
          return {
            ...obj,
            contracts: filterData?.contractsList.find(
              (item) => item.id === obj.participantId,
            ).contracts,
          };
        }
      });
      setNewDataSet(newData);
    }
  }, [filterData, pageState]);

  const getDataComponent = () => {
    if (
      isDataFetched &&
      pageState.data?.length === 0 &&
      participantSearchText === '' &&
      hasNoParticipant
    )
      return (
        <NodataComponent
          nodata={LABELS.noDataContent}
          addNewLabel={LABELS.addNewProjectLabel}
          addbuttonLabel={{
            name: LABELS.addNewParticipant,
            link: '',
          }}
          hasMultipleModals={true}
          addNewButtonOptions={addButtonOptions}
          disableButton={
            !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
          }
          isModalPresent={true}
        />
      );

    if (
      isDataFetched &&
      pageState.data?.length === 0 &&
      participantSearchText?.trim()?.length > 0
    )
      return (
        <NodataComponent
          filterData={LABELS.filter_content}
          searchTermSuffix={LABELS.search_text_suffix}
          searchTerm={`"${participantSearchText}".`}
          width={LABELS.filter_content_width}
        />
      );

    return (
      <MainWrapper className="curved-border">
        <DataGridWrapper>
          <DataGrid
            getRowHeight={() => 72}
            headerHeight={40}
            disableVirtualization={disableVirtualization}
            rows={newDataSet || []}
            rowsPerPageOptions={[25, 50, 75]}
            onRowClick={(params) =>
              navigate(
                `${PARTICIPANT_PARENT_PATH}/${params.row.participantId}?projectid=${id}`,
                {
                  state: { projectId: id },
                },
              )
            }
            getRowId={(row) =>
              checkTernaryCondition(
                row?.participantId === undefined,
                uniqueId(),
                row?.participantId,
              )
            }
            disableSelectionOnClick
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Items per page',
                SelectProps: TablePaginationMenuListStyle,
              },
            }}
            paginationMode="server"
            pagination
            rowCount={pageState.total}
            pageSize={pageState.pageSize}
            page={pageState.page - 1}
            onPageChange={(newPage) =>
              setPageState((old) => ({ ...old, page: newPage + 1 }))
            }
            loading={pageState.isLoading || !isDataFetched}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({ ...old, pageSize: newPageSize }))
            }
            columns={columns}
            sx={datagridSx}
          />
        </DataGridWrapper>
      </MainWrapper>
    );
  };

  return (
    <>
      <ParticipantButtonWrapper>
        <SearchAndFilterSection width={'90%'} marginBottom={'0rem'}>
          <div
            style={{
              ...SearchAndFiltersFlex,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <SearchBox
              variant="outlined"
              placeholder={LABELS.searchBoxPlaceholder}
              value={participantSearchText}
              onChange={(event) => {
                const searchText = event.target.value.replace(
                  /[^a-zA-Z0-9@._-\s]/g,
                  '',
                );
                setParticipantSearchText(searchText);

                if (currentTab === 1) {
                  debouncedFetchContributorList(searchText);
                }
              }}
              InputProps={{
                startAdornment: (
                  <Fragment>
                    <SearchIcon sx={{ color: TYPOGRAPHY_GRAY }} />
                  </Fragment>
                ),
                endAdornment: participantSearchText?.length > 0 && (
                  <ClearIcon
                    sx={{
                      ...pointerCursor,
                      color: TYPOGRAPHY_GRAY,
                      fontSize: '1rem',
                    }}
                    onClick={() => {
                      setIsDataFetched(false);
                      setParticipantSearchText('');
                      debouncedFetchContributorList(
                        '',
                        accountStatusFilterList,
                      );
                    }}
                  />
                ),
              }}
            />
            <AppliedFilters style={{ flex: 1 }}>
              {accountStatusFilterList?.map((status, id) => {
                return (
                  <FilterChip
                    key={id}
                    data-testid={`${
                      LABELS.account_status
                    }: ${getParticipantAccountStatus(status)}`}>
                    <div>{`${
                      LABELS.account_status
                    }: ${getParticipantAccountStatus(status)}`}</div>
                    <ClearIcon
                      onClick={() => {
                        if (currentTab === 1) {
                          debouncedFetchContributorList(
                            participantSearchText,
                            accountStatusFilterList.filter(
                              (item) => item !== status,
                            ),
                          );
                        }
                        setAccountStatusFilterList(
                          accountStatusFilterList.filter(
                            (item) => item !== status,
                          ),
                        );
                      }}
                      sx={clearIconStyle}
                    />
                  </FilterChip>
                );
              })}
              {activityStatusFilterList?.map((status, id) => {
                return (
                  <FilterChip
                    key={id}
                    data-testid={`${LABELS.activity_status}: ${status.itemLabel}`}>
                    <div>{`${LABELS.activity_status}: ${status.itemLabel}`}</div>
                    <ClearIcon
                      onClick={() => {
                        setActivityStatusFilterList(
                          activityStatusFilterList.filter(
                            (item) => item.id !== status.id,
                          ),
                        );
                      }}
                      sx={clearIconStyle}
                    />
                  </FilterChip>
                );
              })}
              {appliedFiltersCount > 1 && (
                <ClearLink
                  onClick={() => {
                    setAccountStatusFilterList([]);
                    setActivityStatusFilterList([]);
                    if (currentTab === 1) {
                      debouncedFetchContributorList(participantSearchText, []);
                    }
                  }}>
                  {PAGE_CONTENT.clear_all}
                </ClearLink>
              )}
            </AppliedFilters>
          </div>
        </SearchAndFilterSection>
        <FiltersPopup
          filterSections={filterSections}
          updateFilters={handleFilterSectionUpdate}
          isFiltersApplied={appliedFiltersCount > 0}
        />
        <AddButton
          buttonName={LABELS.addNewParticipant}
          disableButton={
            permissions.length !== 0 &&
            !checkAccess(permissions, ORIGINATION_PARTICIPANT_CREATE)
          }
          backgroundColor={DARK_GREEN}
          textColor={WHITE}
          customBtnSx={addBtnSx}
          addBtnPadding={'0.406rem'}
          isModalPresent={true}
          hasMultipleModals={true}
          options={addButtonOptions}
        />
      </ParticipantButtonWrapper>

      <Tabber
        customTabberStyles={ParticipantListTabberStyle}
        currentTab={currentTab}
        setCurrentTab={() => {}}
        tabList={[
          { title: 'Owners', component: getDataComponent() },
          {
            title: 'Contributor',
            component: (
              <ParticipantContributorList
                data={contributorList}
                accountStatusMapping={participantAccount}
                contributorPaginationData={contributorPaginationData}
                updatePaginationData={(newData) => {
                  debouncedFetchContributorList(
                    participantSearchText,
                    accountStatusFilterList,
                    newData,
                  );
                  setContributorPaginationData(newData);
                }}
                openEditModal={(id) => addEditParticipantsHandler(id)}
                openUpdateAccStatusModal={(params) =>
                  setUpdateAccountModalOpen({ params, open: true })
                }
              />
            ),
          },
        ]}
        onTabChange={handleTabChange}
      />

      {checkTernaryCondition(
        participantDialogOpen.id === 0,
        <AddParticipants
          refreshHandler={() => {
            payload.projectId !== null &&
              debouncedFetchParticipantsList(!!participantSearchText);
            refreshHandler();
          }}
          isModalOpen={participantDialogOpen.flag}
          setIsModalOpen={setParticipantDialogOpen}
          projectId={id}
          projectName={projectName}
          particpantId={participantDialogOpen.id}
          projectLifeCycleList={fetchedProjectLifeCycleList}
        />,
        <EditParticipantProfile
          isModalOpen={participantDialogOpen.flag}
          modalCloseHandler={() =>
            setParticipantDialogOpen({ flag: false, id: 0 })
          }
          participantId={participantDialogOpen.id}
          refreshHandler={() => {
            payload.projectId !== null &&
              debouncedFetchParticipantsList(!!participantSearchText);
            refreshHandler();
            if (currentTab === 1) {
              debouncedFetchContributorList();
            }
          }}
        />,
      )}

      <LoaderProvider>
        <UpdateAccount
          isUpdateAccountModalOpen={isUpdateAccountModalOpen}
          setUpdateAccountModalOpen={setUpdateAccountModalOpen}
          refreshHandler={() => {
            if (currentTab === 1) {
              debouncedFetchContributorList();
              fetchAccountStatus(PARTICIPANT_TYPES.contributor);
            } else if (currentTab === 0) {
              fetchAccountStatus(PARTICIPANT_TYPES.owner);
            }
          }}
        />
      </LoaderProvider>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDeleteDialogOpen}
        onConfirm={deleteParticipant}
        subtitle={`${LABELS.delete_message?.slice(0, 31)} ${
          currentRow?.participantFirstName
        } ${currentRow?.participantLastName} ${LABELS.delete_message?.slice(
          32,
          36,
        )} ${projectName}${LABELS.delete_message?.slice(36)}`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Remove"
        title={`Remove ${currentRow?.participantFirstName} ${currentRow?.participantLastName}`}></DialogBox>
      <LoaderProvider>
        <ReopenCustomModal
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
          statusMappingList={statusMappingList}
        />
      </LoaderProvider>
      <NotificationLogProvider>
        <AddParticipantsViaFileUpload
          addParticipantsFileUploadOpen={multipleParticipantsFileUploadOpen}
          setAddParticipantsFileUploadOpen={
            setMultipleParticipantsFileUploadOpen
          }
          refreshHandler={() => {
            refreshHandler();
            fetchFilterData();
          }}
          projectName={{ value: id, label: projectName }}
          isNotProjectParticipantsPage={false}
          projectLifeCycleList={fetchedProjectLifeCycleList}
        />
      </NotificationLogProvider>
      <Backdrop open={loading} sx={backdropSx}>
        <CircularProgress color="success" />
      </Backdrop>
    </>
  );
};

ListParticipants.propTypes = {
  refreshHandler: PropTypes.func,
  disableVirtualization: PropTypes.bool,
  projectName: PropTypes.string,
};
export default ListParticipants;
