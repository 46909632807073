import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import Tabber from 'components/Tabber/Tabber.react';

const FarmWithMapTabs = ({ currentTab, setCurrentTab, tabList }) => {
  const {
    addFarmClicked,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    setShowFieldInfoForm,
    farmInfoCurrentTab,
    setAddFarmClicked,
    setIsTabSwitched,
  } = useContext(participantInfoContext);

  useEffect(() => {
    if (farmInfoCurrentTab === 1 && addFarmClicked.flag) {
      setCurrentFarmId(addFarmClicked.id);
      setExpandedFarmId(addFarmClicked.id);
      setFarmInfoCurrentTab(0);
      setShowFieldInfoForm(false);
      setAddFarmClicked({ id: 0, flag: false });
    }
  }, [addFarmClicked]);

  return (
    <Tabber
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      tabList={tabList}
      onTabChange={(tab) => setIsTabSwitched(tab === 1)}
    />
  );
};

FarmWithMapTabs.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.arrayOf(PropTypes.string),
};
export default FarmWithMapTabs;
