export const PAGE_CONTENT = {
  rofr_status_label: 'ROFR Status',
  rofr_status_placeholder: 'Select ROFR status',
  rofr_status_label_1: 'Under consideration',
  rofr_status_label_2: 'Selected',
  variable_cost_label: 'Variable Cost Structure',
  variable_cost_placeholder: 'Select Cost Structure',
  option_field: '(optional)',
  variable_cost_error:
    'A variable cost structure selection is required for submission',
  rofr_error: 'A ROFR status selection is required for submission',
  add_vintage_button: 'Add vintage',
  variable_cost_duplicate_error: 'This variable cost was already added.',
  remove_variable_cost_label: 'Remove variable cost structure',
};
