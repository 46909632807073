import { PAGE_CONTENT } from './ParticipantActivity.content';
import {
  ActvityHeaderWrapper,
  ProjectActionWrapper,
  ProjectDataWrapper,
} from './ParticipantActivity.style';

const ActivityProjectHeader = ({ projectDetails, setDetailedLog }) => {
  return (
    <ActvityHeaderWrapper>
      <ProjectDataWrapper title={projectDetails.projectName}>
        {projectDetails.projectName}:
        <span> {projectDetails.projectCycleData?.label}</span>
      </ProjectDataWrapper>
      <ProjectActionWrapper
        onClick={() =>
          setDetailedLog({
            projectId: projectDetails.internalProjectId,
            projectOpen: true,
            projectName: projectDetails.projectName,
          })
        }>
        {PAGE_CONTENT.project_actionlabel}
      </ProjectActionWrapper>
    </ActvityHeaderWrapper>
  );
};

export default ActivityProjectHeader;
