import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { GRAYISH_BLUE, PAGE_BACKGROUND_COLOR, WHITE } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { FilterPanelContent } from './FilterPanel.content';

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: baseline;
`;

const FilterPanel = ({
  dropDownList,
  farm,
  setFarm,
  allStatus,
  setAllStatus,
  projectActivity,
  setProjectActivity,
  flag,
  setFlag,
}) => {
  const [mappedDropDownList, setMappedDropdownList] = useState({
    projectActivityDropDownsList: [],
    reviewStatusDropDownsList: [],
    projectFarmDropDownsList: [],
  });

  useEffect(() => {
    const projectActivityDropDownsList =
      dropDownList?.projectActivityDropDowns?.map((item) => {
        return {
          value: item.id,
          label: item.value,
        };
      });
    const uniqueFarmId = [
      ...new Set(dropDownList?.projectFarmDropDowns.map((item) => item.id)),
    ];
    const projectFarmDropDownsList = uniqueFarmId.map((farmId) => {
      const farm = dropDownList?.projectFarmDropDowns.find(
        (item) => item.id === farmId,
      );
      return {
        value: farm.id,
        label: farm.value,
      };
    });
    const reviewStatusDropDownsList = dropDownList?.reviewStatusDropDowns?.map(
      (item, index) => {
        return {
          value: item,
          label: item,
        };
      },
    );
    // adding default values
    projectActivityDropDownsList?.push({
      value: FilterPanelContent.activities_placeholder,
      label: FilterPanelContent.activities_placeholder,
    });
    projectFarmDropDownsList?.push({
      value: FilterPanelContent.farms_placeholder,
      label: FilterPanelContent.farms_placeholder,
    });
    reviewStatusDropDownsList?.push({
      value: FilterPanelContent.status_placeholder,
      label: FilterPanelContent.status_placeholder,
    });
    setMappedDropdownList({
      projectActivityDropDownsList: projectActivityDropDownsList,
      reviewStatusDropDownsList: reviewStatusDropDownsList,
      projectFarmDropDownsList: projectFarmDropDownsList,
    });
  }, [dropDownList]);

  return (
    <RowDiv>
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="5.5rem"
        minWidth="5.5rem"
        hasNoBottomMargin={true}
        value={farm}
        dropDownPlaceholder={FilterPanelContent.farms_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.projectFarmDropDownsList}
        fontSize="0.875rem"
        listItemFontSize="0.875rem"
        onUpdate={(e) => {
          setFarm(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="12.625rem"
        minWidth="12.625rem"
        hasNoBottomMargin={true}
        value={projectActivity}
        dropDownPlaceholder={FilterPanelContent.activities_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.projectActivityDropDownsList}
        fontSize={'0.875rem'}
        listItemFontSize={'0.875rem'}
        onUpdate={(e) => {
          setProjectActivity(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="7.5rem"
        minWidth={'7.5rem'}
        hasNoBottomMargin={true}
        value={allStatus}
        dropDownPlaceholder={FilterPanelContent.status_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.reviewStatusDropDownsList}
        fontSize={'0.875rem'}
        listItemFontSize={'0.875rem'}
        onUpdate={(e) => {
          setAllStatus(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <CheckboxFormGroup
        list={[
          {
            itemLabel: FilterPanelContent.show_flag_label,
            checked: flag,
          },
        ]}
        label={''}
        instructionText={''}
        onClickHandler={() => {
          setFlag(!flag);
        }}
        checkboxPadding={''}
        gap={'0.5rem'}
        labelMarginTop={'-0.7rem'}
        labelMarginBottom={''}
        backgroundColor={PAGE_BACKGROUND_COLOR}
      />
    </RowDiv>
  );
};

export default FilterPanel;
