import styled from 'styled-components';

export const CustomPopupBox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  background-color: white;
  max-height: 15rem;
  gap: 1rem;
  border-radius: 5px;
  padding: ${({ padding }) => padding};
`;
export const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 45rem;
`;
export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: ${({ borderBottom }) => borderBottom};
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  word-break: break-word;
`;
export const BoxStyle = {
  background: 'white',
};
export const IconStyle = {
  left: '93%',
};
export const EditModalWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const SubWrapper = styled.div`
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const ValueWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  width: ${({ width }) => width};
  padding: 0.5rem;
  word-break: break-word;
`;
export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 14.5rem;
  overflow: auto;
`;
export const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: '400',
  padding: '0rem',
};
export const subTitleStyle = {
  color: 'text.secondary',
  fontSize: '0.875rem',
  fontWeight: '600',
  padding: '0rem',
};

export const projectHeadingStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};
