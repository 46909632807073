import axios from 'axios';
import { getValueOrDefault } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition, getDefaultValue } from 'utils/helper';
import {
  FETCH_MANAGEMENT_TYPE_DROPDOWN,
  FETCH_TILLAGE_SYSTEM_TYPE_BY_ID,
} from 'urls';

export const initialDeleteState = {
  id: null,
  isWholeModalDeleted: false,
  modalType: '',
  practiceTypeValue: '',
  deleteModalHeading: '',
  deleteModalContent: '',
  parentId: null,
  cropType: '',
};
export const onClickEmptyState = (year, setterMethod, parentId = null) => {
  if (parentId !== null) {
    setterMethod({
      flag: true,
      id: 0,
      data: { id: uniqueId(), year: year, formData: [] },
      currentRowId: 0,
      parentId: parentId,
    });
  } else {
    setterMethod({
      flag: true,
      id: 0,
      data: { id: uniqueId(), year: year, formData: [] },
      currentRowId: 0,
    });
  }
};

export const getIsPracticeModalMandatory = (activityList, cropActivityList) => {
  const practiceModalArray = cropActivityList.map((obj) => {
    return activityList?.find((item) => item.id === obj)?.itemLabel;
  });
  return practiceModalArray;
};
export const editStateSetter = (
  setIsInitialLoad,
  year,
  id,
  setterMethod,
  data,
  parentId = null,
) => {
  setIsInitialLoad(false);
  if (parentId !== null) {
    setterMethod({
      flag: true,
      id: id,
      data: { id: id, year: year, formData: [data] },
      currentRowId: id,
      parentId: parentId,
    });
  } else {
    setterMethod({
      flag: true,
      id: id,
      data: { id: id, year: year, formData: [data] },
      currentRowId: id,
    });
  }
};
export const findDeleteAndPresentPracticeList = (
  practiceModalsData,
  deletePracticeModalsData,
  modalPracticeArray,
  editDeleteModalTypeMap,
  currentId,
) => {
  let tempPracticeList = {
    ...practiceModalsData,
  };
  let tempDeletePracticeList = {
    ...deletePracticeModalsData,
  };
  modalPracticeArray.forEach((item) => {
    const targetModal = editDeleteModalTypeMap[item.type];
    const modalTypePracticeList = targetModal?.practiceList;
    const modalDeleteList = targetModal?.deletedPracticeList;
    if (modalTypePracticeList) {
      let filterData;
      let removedObjects = tempDeletePracticeList[modalDeleteList];
      filterData = tempPracticeList[modalTypePracticeList].filter((obj) => {
        if (obj.parentId === item.parentId && item.parentId === currentId) {
          removedObjects.push({ id: obj.id, parentId: obj.parentId });
          return false;
        }
        return true;
      });
      tempPracticeList = {
        ...tempPracticeList,
        [modalTypePracticeList]: filterData,
      };
      tempDeletePracticeList = {
        ...tempDeletePracticeList,
        [modalDeleteList]: removedObjects,
      };
    }
  });
  return [tempPracticeList, tempDeletePracticeList];
};
export const findDataForSingleModalDelete = (
  deleteData,
  practiceModalsData,
  modalTypePracticeList,
) => {
  const removedObjects = [
    {
      id: deleteData?.id,
      parentId: practiceModalsData?.[modalTypePracticeList]?.find(
        (obj) => obj.id === deleteData?.id,
      )?.parentId,
    },
  ];
  const filterData = practiceModalsData?.[modalTypePracticeList].filter(
    (obj) => obj.id !== deleteData?.id,
  );
  return [removedObjects, filterData];
};
export const extractBaselineFieldData = (
  baselineData,
  animalTypeDropdown,
  fertilizerTypeDropDown,
) => {
  let cashCropList = [];
  let sampleCropList = [];
  baselineData.baselineFieldCrop.forEach((yearWiseData) => {
    const sampleCrop = yearWiseData.fieldSampleCrops[0];
    sampleCropList.push(sampleCrop);
    const cashCropData = {
      addedFromImplementedActivities: sampleCrop.addedFromImplementedActivities,
      id: sampleCrop.cropSampleId,
      isCopied: sampleCrop.isCopied,
      cropSampleId: sampleCrop.cropSampleId,
      sampleId: sampleCrop.sampleId,
      cropId: checkTernaryCondition(
        sampleCrop.addedFromImplementedActivities &&
          sampleCrop.cropArea === null,
        '',
        sampleCrop.cropId,
      ),
      cropArea: sampleCrop.cropArea,
      cropAreaUom: sampleCrop.cropAreaUom,
      annualYield: sampleCrop.annualYield,
      annualYieldUom: sampleCrop.annualYieldUom,
      plantingDensity: sampleCrop.plantingDensity,
      cropValue: sampleCrop.cropValue,
      plantingDensityUom: sampleCrop.plantingDensityUom,
      cropDepth: sampleCrop.seedingDepth,
      cropDepthUom: sampleCrop.seedingDepthUom,
      cropPlantDate: getValueOrDefault(sampleCrop.cropPlantDate),
      cropTerminationDate: getValueOrDefault(sampleCrop.cropTerminationDate),
      activityList: sampleCrop.cropActivityList?.map(
        (activity) => activity?.projectActivityId,
      ),
      cropRotation: sampleCrop.cropRotationsImplemented,
      productionPurpose: sampleCrop.productionPurpose,
      cropPlannedManagementTypeId: checkTernaryCondition(
        sampleCrop.addedFromImplementedActivities &&
          sampleCrop.cropArea === null,
        '',
        sampleCrop.cropPlannedManagementTypeId,
      ),
      tillageSystemOther: checkTernaryCondition(
        sampleCrop.tillageSystemOther === '' ||
          sampleCrop.tillageSystemOther === null,
        '',
        sampleCrop.tillageSystemOther,
      ),
      tillageSystemDisturbanceId: checkTernaryCondition(
        sampleCrop.tillageSystemDisturbanceId === '' ||
          sampleCrop.tillageSystemDisturbanceId === null,
        '',
        sampleCrop.tillageSystemDisturbanceId,
      ),
    };

    cashCropList.push(cashCropData);
  });

  let nitrogenList = [];
  let irrigationTypeList = [];
  let livestockList = [];
  let soilAmendmentList = [];
  let coverCropList = [];
  let tillageTypeDtoList = [];
  let residueManagementTypeList = [];
  let edgeOfFieldList = [];

  if (baselineData.edgeOfFieldDimList) {
    edgeOfFieldList.push(
      ...baselineData.edgeOfFieldDimList.map((obj) => ({
        id: obj.edgeOfFieldSampleId,
        data: {
          addedFromImplementedActivities:
            obj?.addedFromImplementedActivities || false,
          practiceType: getValueOrDefault(obj.edgeOfFieldPracticeId),
          area: getValueOrDefault(obj.edgeOfFieldArea),
          speciesCategory: getValueOrDefault(obj.speciesCategoryId),
          stripWidth: getValueOrDefault(obj.stripWidth),
          barrierWidth: getValueOrDefault(obj.barrierWidth),
          rowCount: getValueOrDefault(obj.numOfRows),
          id: obj.edgeOfFieldSampleId,
          speciesDensity: getValueOrDefault(obj.speciesDensity),
        },
      })),
    );
  }

  sampleCropList.forEach((sampleCrop) => {
    residueManagementTypeList.push(
      ...sampleCrop.agresidueDimList.map((obj) => ({
        id: obj.agresidueSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.agresidueSampleId,
          agresidueSampleId: obj.agresidueSampleId,
          agricultureResiduesManaged:
            obj?.cropResidueManagementRemovalMethod
              ?.cropResidueManagementRemovalMethodId,
          cropResidueManagementRemovalMethod:
            obj?.cropResidueManagementRemovalMethod
              ?.cropResidueManagementRemovalMethod,
          cropResidueRemoved: getValueOrDefault(obj.agresidueRemovalpct),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );

    tillageTypeDtoList.push(
      ...sampleCrop.tillageDimList.map((obj) => ({
        id: obj.tillageSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.tillageSampleId,
          tillagePractice: getValueOrDefault(obj.tillagePracticeId),
          customTillagePractice: getValueOrDefault(obj.tillageType),
          tillageDates: obj.tillageEventDims.map(
            (date) => new Date(date.datePerformed),
          ),
          surfaceDisturbance: getValueOrDefault(obj.percOfSoilAreaDisturbed),
          depthOfTillage: getValueOrDefault(obj.tillageDepth),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
    soilAmendmentList.push(
      ...sampleCrop.soilAmendmentsList.map((obj) => ({
        id: obj.soilAmendSampleId,
        data: {
          addedFromImplementedActivities: obj?.addedFromImplementedActivities,
          id: obj.soilAmendSampleId,
          soilAmendmentsType: getValueOrDefault(obj.soilAmendId),
          amountApplied: getValueOrDefault(obj.soilAmendAmountApplied),
          unitOfMeasure: getValueOrDefault(obj.soilAmendAmountAppliedUom),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
    irrigationTypeList.push(
      ...sampleCrop.irrigationList.map((obj) => ({
        id: obj.irrigationSampleId,
        data: {
          id: obj.irrigationSampleId,
          irrigationType: getValueOrDefault(obj.irrigationMethodId),
          uom: getValueOrDefault(obj.irrigationRateUom.split(' ')[0]),
          frequency: getValueOrDefault(
            `${obj.irrigationRateUom.split(' ')[1]} ${
              obj.irrigationRateUom.split(' ')[2]
            }`,
          ),
          irrigationRate: getValueOrDefault(obj.irrigationRate),
          irrigationArea: getValueOrDefault(obj.irrigationArea)?.toString(),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
    nitrogenList.push(
      ...sampleCrop.fertilizerList.map((obj) => ({
        id: obj.fertilizerSampleId,
        data: {
          id: obj.fertilizerSampleId,
          fertilizerType: getValueOrDefault(obj.fertilizerId),
          applicationMethod: getValueOrDefault(
            obj.fertilizerApplicationMethodId,
          ),
          applicationRate: getValueOrDefault(
            obj.fertilizerApplicationRate,
          )?.toString(),
          datesApplied: obj.fieldSampleFertilizerApplicationEvents.map(
            (dateValue) => new Date(dateValue?.applicationDate?.trim()),
          ),
          nPercentage: getValueOrDefault(obj.fertilizerNRate),
          pPercentage: getValueOrDefault(obj.fertilizerPRate),
          kPercentage: getValueOrDefault(obj.fertilizerKRate),
          unitOfMeasure: obj.fertilizerApplicationRateUom,
          hasInhibitor: checkTernaryCondition(
            obj.containsInhibitor,
            'Yes',
            'No',
          ),
          animalType: getDefaultValue(
            animalTypeDropdown?.find(
              (animal) => animal.value === obj.fertilizerId,
            )?.value,
          ),
          manureType: getValueOrDefault(
            checkTernaryCondition(obj.manureTypeId === 0, '', obj.manureTypeId),
          ),
          fertilizerCategory: fertilizerTypeDropDown.filter(
            (category) => category.value === obj.fertilizerId,
          )[0]?.category,
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );

    livestockList.push(
      ...sampleCrop.livestockDimList.map((obj) => ({
        id: obj.livestockSampleId,
        data: {
          id: obj.livestockSampleId,
          livestockType: getValueOrDefault(obj.livestockId),
          prescribedGrazingType: getValueOrDefault(obj.prescribedGrazingId),
          averageWeight: getValueOrDefault(obj.livestockAvgWeight),
          unitOfMeasure: getValueOrDefault(obj.livestockAvgWeightUom),
          livestockStockingRatePerField: getValueOrDefault(
            obj.livestockStockingRate,
          ),
          annualGrazingDays: getValueOrDefault(obj.livestockHeadgrazingdays),
          manureManagementSystem: getValueOrDefault(obj.awmsId),
          livestockGrazingArea: getValueOrDefault(
            obj.livestockGrazingArea,
          )?.toString(),
        },
        parentId: sampleCrop.cropSampleId,
      })),
    );
  });

  return {
    practicesData: {
      residueManagementTypeList: residueManagementTypeList,
      edgeOfFieldList: edgeOfFieldList,
      tillageTypeDtoList: tillageTypeDtoList,
      soilAmendmentList: soilAmendmentList,
      irrigationTypeList: irrigationTypeList,
      nitrogenList: nitrogenList,
      livestockList: livestockList,
      coverCropList: coverCropList,
    },
    deletedPracticesData: {
      deletedLiveStockIdList: [],
      deletedSoilAmendmentList: [],
      deletedFertilizerList: [],
      deletedFossilFuelList: [],
      deletedResidueManagementList: [],
      deletedCoverCropList: [],
      deletedIrrigationList: [],
      deletedTillageList: [],
      deletedEdgeOfFieldList: [],
    },
    editMode:
      baselineData?.baselineFieldCrop?.length > 0 ||
      baselineData?.edgeOfFieldDimList?.length > 0,
    cashCropList: cashCropList,
  };
};
export const copyFossilFuelRepeatedItems = (
  newList,
  parentState,
  combinations,
) => {
  let uniqueItems = [],
    repeatedItems = [];
  for (const fossilFuel of newList) {
    const isUnique =
      parentState?.findIndex(
        (fuelData) => fuelData?.fossilFuelType === fossilFuel?.fossilFuelType,
      ) === -1;

    if (isUnique) uniqueItems.push(fossilFuel);
    else {
      const repeatingCombination = combinations.find(
        (combination) =>
          combination.dropdown3Value === fossilFuel.fossilFuelType,
      );
      const modalTitle = `${repeatingCombination?.dropdown1Label} - ${repeatingCombination?.dropdown2Label} - ${repeatingCombination?.dropdown3Label}`;
      repeatedItems.push({
        data: fossilFuel,
        titlePart: modalTitle,
        replaceItemIdKey: 'fossilFuelType',
      });
    }
  }
  return [uniqueItems, repeatedItems];
};
export const getPracticeCount = (
  practiceModalsData,
  parentId,
  practiceList,
  noDataOptionKey = null,
  noDataOptionValue = null,
) => {
  return checkTernaryCondition(
    practiceModalsData[practiceList]?.find(
      (item) =>
        item?.parentId === parentId &&
        ((noDataOptionKey === null && noDataOptionValue === null) ||
          item?.data?.[noDataOptionKey] !== noDataOptionValue),
    ),
    1,
    0,
  );
};
export const cleanDatesAndIds = (
  practiceModalsData,
  newParentId,
  dateFieldKeys = [],
  dateFieldIsArray = false,
) => {
  return practiceModalsData?.map((practiceData) => {
    const newPracticeId = uniqueId();
    let updatedData = { ...practiceData.data, id: newPracticeId };
    dateFieldKeys.forEach((key) => {
      updatedData = {
        ...updatedData,
        [key]: checkTernaryCondition(dateFieldIsArray, [], ''),
      };
    });
    return {
      ...practiceData,
      parentId: newParentId,
      id: newPracticeId,
      data: updatedData,
    };
  });
};

export const fetchTillageSystemTypeById = async (id) => {
  const response = await axios.get(FETCH_TILLAGE_SYSTEM_TYPE_BY_ID + id);
  const data = response.data;
  return data;
};

export const fetchManagementTypeDropdown = async () => {
  const response = await axios.get(FETCH_MANAGEMENT_TYPE_DROPDOWN);
  const data = response.data;
  return data;
};
