// import { checkTernaryCondition } from 'utils/helper';
// import { useUserData } from './Username.react';
import { getAuth } from 'firebase/auth';

// function stringAvatar(name) {
//   return {
//     children: checkTernaryCondition(
//       name === undefined || name === null || name === '',
//       '',
//       `${name?.[0]?.[0]}`,
//     ),
//   };
// }

// export function useProfileContent() {
//   const graphData = useUserData();
//   const username = graphData?.givenName ? graphData.givenName : '';
//   return stringAvatar(username);
// }

// export function getUsername() {
//   const graphData = useUserData();

//   return checkTernaryCondition(
//     graphData != null &&
//       graphData?.givenName !== undefined &&
//       graphData?.surname !== undefined,
//     `${graphData?.givenName} ${graphData?.surname}`,
//     '',
//   );
// }

export const getCurrentUserData = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  return currentUser;
};

export function getDisplayName() {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  return `${currentUser?.email}`;
}
