export const MODAL_CONTENT = {
  area: 'Type amount',
  unit: 'unit',
  total_area: 'Total area',
  date_format: 'MM/DD/YYYY',
  year_planned_label: 'Year planned',
  year_planned_placeholder: 'Select year',
  activity_type: 'Activity type',
  activity: 'Activity',
  select_option: 'Select type',
  title: 'Planned project activity',
  acres: 'acres',
  submit: 'Submit',
  cancel: 'Cancel',
  total_head_count: 'Total head count',
  activity_type_error: 'Activity type selection is required.',
  year_planned_error: 'Year planned is required.',
  total_area_error: 'Total area is required.',
  head_count_error: 'Total head count is required.',
  field_area_exceeding_error:
    "Total area for planned project activity can't exceed total field area.",
  field_area_zero_error: 'Total area must be greater than 0.',
  implmentation_year: 'Implementation year',
  historically_implemented:
    'Have you implemented this project activity historically?',
  row: 'row',
  historically_implemented_error:
    'A selection for implementing this practice historically is required.',
  margin_right: '1.625rem',
  zero_rem: '0rem',
  yes: 'Yes',
  activity_history_label:
    'Please indicate the year(s) this project activity was implemented historically.',
  activity_history_error:
    'If yes was selected for implementing this practice historically then at least 1 year is required.',
  activity_history_area_error:
    "Total area for historical project activity can't exceed total field area.",
  activity_history_zero_val_error: 'Total acreage must be greater than 0.',
  activity_history_required_error: 'Total acreage is required.',
  total_acreage: 'Total acreage',
  instructionText: 'Select all that apply',
  activity_disclaimer:
    'Your previous selection of No or I don’t know to the implementation of CSAF practices during field enrollment will be updated to Yes.',
  improve_grazing_management: 'Improve  grazing management',
};

export const FIELD_ACTIVITY_COLS = {
  fieldCropProjectActivityId: 'fieldCropProjectActivityId',
  projectActivityId: 'projectActivityId',
  date: 'date',
  totalArea: 'totalArea',
  historicallyImplemented: 'historicallyImplemented',
  fieldProjectActivityHistoricalId1: 'fieldProjectActivityHistoricalId1',
  implementationYear1: 'implementationYear1',
  totalArea1: 'totalArea1',
  historicallyImplemented1: 'historicallyImplemented1',
  fieldProjectActivityHistoricalId2: 'fieldProjectActivityHistoricalId2',
  implementationYear2: 'implementationYear2',
  totalArea2: 'totalArea2',
  historicallyImplemented2: 'historicallyImplemented2',
  fieldProjectActivityHistoricalId3: 'fieldProjectActivityHistoricalId3',
  implementationYear3: 'implementationYear3',
  totalArea3: 'totalArea3',
  historicallyImplemented3: 'historicallyImplemented3',
};

export const activityModalInitialState = {
  fieldCropProjectActivityId: 0,
  projectActivityId: '',
  date: '',
  totalArea: '',
  historicallyImplemented: '',
  fieldCsafUpdatedByActivity: false,
  fieldProjectActivityHistoricalId1: 0,
  implementationYear1: '',
  totalArea1: '',
  historicallyImplemented1: 'No',
  fieldProjectActivityHistoricalId2: 0,
  implementationYear2: '',
  totalArea2: '',
  historicallyImplemented2: 'No',
  fieldProjectActivityHistoricalId3: 0,
  implementationYear3: '',
  totalArea3: '',
  historicallyImplemented3: 'No',
};

export const YesOrNoOptions = [
  {
    itemLabel: 'Yes',
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    isDisabled: false,
  },
];

export const initialActivityErrorState = {
  projectActivityIdError: false,
  dateError: false,
  totalAreaError: false,
  isAreaExceedingError: false,
  isAreaZeroError: false,
  historicallyImplementedError: false,
  activityHistoryError: false,
  isActivityHistoryExceedingError: false,
  isActivityHistoryZeroError: false,
  isActivityHistoryEmptyError: false,
  isActivityHistory1EmptyError: false,
  isActivityHistory2EmptyError: false,
  isActivityHistory3EmptyError: false,
};
