import {
  COOL_GRAY,
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
  ORANGE,
  WHITE,
} from 'theme/GlobalColors';
import {
  Container,
  TextWrapper,
  ToolTipIconSx,
  mapStyle,
} from './PortfolioOriginationTab.style';
import {
  PORTFOLIO_ORIGINATION_TAB_CONTENT,
  getMarkerInfoWindow,
  mapPolygonOptions,
  northAmericaBounds,
  portfolioPageMapOptions,
  rangeNumArray,
} from './PortfolioOriginationTab.content';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import {
  FETCH_GHG_INSIGHTS,
  FETCH_PROJECT_MAP_DATA,
  PORTFOLIO_KPI,
} from 'urls';
import { useEffect, useState } from 'react';
import { backdropSx } from 'theme/GlobalStyles';
import CustomLineChart from 'components/Charts/CustomLineChart/CustomLineChart.react';
import {
  checkTernaryCondition,
  getUniqueElements,
  parseStringArray,
} from 'utils/helper';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import geojson from 'assets/regions_us.json';
import { uniqueId } from 'utils/uniqueIdGenerator';
import customMarker from 'assets/icons/googleMapMarkerBlue.svg';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';

const PortfolioOriginationTab = () => {
  const [portfolioKPI, setPortfolioKPI] = useState({});
  const [isLoading, setIsLoading] = useState({
    KPILoading: false,
    GHGLoading: false,
    projectMapData: false,
  });
  const [ghgData, setGHGData] = useState({});
  const [projects, setProjects] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [regionPolygons, setRegionPolygons] = useState([]);
  const navigate = useNavigate();
  const years = rangeNumArray(20, 30);
  const labels = ghgData?.ghgInsights?.map((insight) => {
    const year = new Date(insight.abatementDate)
      .getFullYear()
      .toString()
      .slice(-2);
    return parseInt(year, 10);
  });
  const projectedData = [];
  const actualData = [];
  const [formattedProjects, setFormattedProjects] = useState([]);
  const mapping = {
    latitude: { longitude: 0, count: 0 },
  };
  years.forEach((year) => {
    if (labels?.length > 0) {
      const index = labels?.indexOf(year);
      if (index !== -1) {
        projectedData.push(ghgData?.ghgInsights[index].projectedAbatement);
        actualData.push(ghgData?.ghgInsights[index].actualAbatement);
      } else {
        // Year not found in labels, push null values
        projectedData.push(null);
        actualData.push(null);
      }
    }
  });
  const chartData = {
    labels: years,
    datasets: [
      {
        label: PORTFOLIO_ORIGINATION_TAB_CONTENT.projected,
        data: projectedData,
        borderColor: NEW_DARK_GREEN,
        backgroundColor: NEW_DARK_GREEN,
        pointRadius: 1,
        borderDash: [10, 10],
      },
      {
        label: PORTFOLIO_ORIGINATION_TAB_CONTENT.actual,
        data: actualData,
        borderColor: ORANGE,
        backgroundColor: ORANGE,
        pointRadius: 1,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        min: 20,
        max: 30,
        ticks: {
          padding: 9,
          stepSize: 1,
          color: DARK_CHARCOAL,
          callback: function (value) {
            return "'" + (value + 20);
          },
        },
        title: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        min: 0, // Start from 0
        max: 600, // End at 600
        ticks: {
          stepSize: 150, // Show values in multiples of 150
          color: DARK_CHARCOAL,
        },
        title: {
          display: true,
          text: PORTFOLIO_ORIGINATION_TAB_CONTENT.CO2e,
          color: COOL_GRAY,
        },
      },
    },
  };
  const fetchGHGInsights = () => {
    setIsLoading((prev) => ({ ...prev, GHGLoading: true }));
    axios
      .get(FETCH_GHG_INSIGHTS)
      .then((response) => {
        setGHGData(response.data);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, GHGLoading: false }));
      });
  };
  const fetchPortfolioKPI = () => {
    setIsLoading((prev) => ({ ...prev, KPILoading: true }));
    axios
      .get(PORTFOLIO_KPI)
      .then((response) => {
        setPortfolioKPI(response.data);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, KPILoading: false }));
      });
  };

  const constructMapRegions = (regions = []) => {
    regions = regions.map((region) => region.toLowerCase());
    const polygons = [];
    geojson.features.forEach((feature) => {
      const regionName = feature.properties.NAME;

      if (regions?.includes(regionName.toLowerCase())) {
        feature.geometry.coordinates.forEach((coordinate) => {
          coordinate.forEach((polygon) => {
            const polygonCoordinates = polygon.map((points) => {
              return { lat: points[1], lng: points[0] };
            });
            polygons.push({
              fieldId: uniqueId(),
              fieldName: regionName,
              fieldBoundaries: polygonCoordinates,
              polygonOptions: mapPolygonOptions,
            });
          });
        });
      }
    });

    setRegionPolygons(polygons);
  };

  const getProjectData = () => {
    setIsLoading((prev) => ({ ...prev, projectMapData: true }));
    axios
      .get(FETCH_PROJECT_MAP_DATA)
      .then((response) => {
        if (response.data) {
          setProjects(response.data);
          let regions = response.data.flatMap((project) =>
            parseStringArray(project.region),
          );
          constructMapRegions(getUniqueElements(regions));
        }
      })
      .catch(() => {})
      .finally(() =>
        setIsLoading((prev) => ({ ...prev, projectMapData: false })),
      );
  };

  const markersData = formattedProjects.map((project) => {
    const position = {
      lat: project?.centroidLocation?.latitude,
      lng: project?.centroidLocation?.longitude,
    };
    return {
      id: uniqueId(),
      position: position,
      customMarkerUrl: customMarker,
      infoWindowContent: getMarkerInfoWindow({
        projectName: project.projectName,
        acreage: checkTernaryCondition(project?.acreage, project?.acreage, 0),
        participantsCount: project.participants,
        ghgData: `${project.ghgAbatement}${PORTFOLIO_ORIGINATION_TAB_CONTENT.ghg_data_unit}`,
        navigateHandler: () =>
          navigate(`${pathName.originationProject.view}/${project.projectId}`),
      }),
    };
  });

  const redirectListHandler = () => {
    navigate(`${pathName.originationProject.view}`);
  };

  const applyDirection = (directionChangeCount, long, lat, count) => {
    const symbolArray = [];
    switch (directionChangeCount % 12) {
      case 0:
        symbolArray.push(long + mapping[lat].count, lat + mapping[lat].count);
        break;
      case 1:
        symbolArray.push(long + mapping[lat].count, lat);
        break;
      case 2:
        symbolArray.push(long, lat + mapping[lat].count);
        break;
      case 3:
        symbolArray.push(long + mapping[lat].count, lat - mapping[lat].count);
        break;
      case 4:
        symbolArray.push(long + mapping[lat].count, lat);
        break;
      case 5:
        symbolArray.push(long, lat - mapping[lat].count);
        break;
      case 6:
        symbolArray.push(long - mapping[lat].count, lat + mapping[lat].count);
        break;
      case 7:
        symbolArray.push(long - mapping[lat].count, lat);
        break;
      case 8:
        symbolArray.push(long, lat + mapping[lat].count);
        break;
      case 9:
        symbolArray.push(long - mapping[lat].count, lat - mapping[lat].count);
        break;
      case 10:
        symbolArray.push(long - mapping[lat].count, lat);
        break;
      case 11:
        symbolArray.push(long, lat - mapping[lat].count);
        break;
    }
    return symbolArray;
  };
  useEffect(() => {
    fetchPortfolioKPI();
    fetchGHGInsights();
    getProjectData();
  }, []);

  useEffect(() => {
    const calculateContainerWidth = () => {
      const container = document.getElementById('ghg-container');
      if (container) {
        const width = container.offsetWidth - 32;
        setContainerWidth(width);
      }
    };
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);
    return () => {
      window.removeEventListener('resize', calculateContainerWidth);
    };
  }, [projects]);

  useEffect(() => {
    const finalProjects = [];
    let directionChange = 0;
    let symbolFinalArray = [];
    projects.forEach((coordinate, index) => {
      if (
        mapping[coordinate?.centroidLocation?.latitude] &&
        mapping[coordinate?.centroidLocation?.latitude].longitude ===
          coordinate?.centroidLocation?.longitude
      ) {
        directionChange++;
        mapping[coordinate?.centroidLocation?.latitude].count -= 0.03;
        symbolFinalArray = applyDirection(
          directionChange,
          coordinate?.centroidLocation?.longitude,
          coordinate?.centroidLocation?.latitude,
          mapping[coordinate?.centroidLocation?.latitude].count,
        );
        finalProjects.push({
          projectId: coordinate?.projectId,
          projectName: coordinate?.projectName,
          region: coordinate?.region,
          participants: coordinate?.participants,
          acreage: coordinate?.acreage,
          newProject: coordinate?.newProject,
          ghgAbatement: coordinate?.ghgAbatement,
          centroidLocation: {
            longitude: symbolFinalArray[0],
            latitude: symbolFinalArray[1],
          },
        });
      } else {
        mapping[coordinate?.centroidLocation?.latitude] = {
          longitude: coordinate?.centroidLocation?.longitude,
          count: 1,
        };
        finalProjects.push(coordinate);
      }
    });
    setFormattedProjects(finalProjects);
  }, [projects]);

  const roundedAcreage = Number(Math.round(portfolioKPI?.acreage));

  return (
    <>
      <Backdrop sx={backdropSx} open={Object.values(isLoading).includes(true)}>
        <CircularProgress color="success" />
      </Backdrop>
      <Container
        margin={'1rem 0 1.06rem 0'}
        height={'auto'}
        direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.row}
        width={'100%'}>
        <Container
          width={'30.625rem'}
          flexBasis={'100%'}
          className="curved-border"
          backgroundColor={WHITE}
          borderRadius={'0.125rem'}
          hasBoxShadow={true}>
          <Container
            margin={'1rem'}
            width={'94%'}
            direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.column}>
            <Container
              direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.row}
              width={'inherit'}>
              <Container
                width={'28%'}
                gap="0.5rem"
                direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.column}>
                <TextWrapper fontWeight={600} lineHeight={'1.4rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.projects}
                </TextWrapper>
                <TextWrapper
                  fontSize={'1.5rem'}
                  color={GLOBAL_BLUE}
                  fontWeight={600}
                  style={{ cursor: 'pointer' }}
                  onClick={redirectListHandler}
                  lineHeight={'2.125rem'}>
                  {portfolioKPI?.projectCount}
                </TextWrapper>
              </Container>
              <Container
                width={'28%'}
                gap="0.5rem"
                direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.column}>
                <TextWrapper fontWeight={600} lineHeight={'1.4rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.participants}
                </TextWrapper>
                <TextWrapper
                  fontSize={'1.5rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  lineHeight={'2.125rem'}>
                  {portfolioKPI?.participantCount}
                </TextWrapper>
              </Container>
              <Container
                width={'38%'}
                gap="0.5rem"
                direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.column}>
                <TextWrapper fontWeight={600} lineHeight={'1.4rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.acerage}
                  <LightTooltip
                    title={PORTFOLIO_ORIGINATION_TAB_CONTENT.acerage_tooltip}
                    placement={PORTFOLIO_ORIGINATION_TAB_CONTENT.placement}
                    arrow>
                    <InfoOutlinedIcon
                      sx={ToolTipIconSx('text-bottom', '0.5rem')}
                    />
                  </LightTooltip>
                </TextWrapper>
                <TextWrapper
                  fontSize={'1.5rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  lineHeight={'2.125rem'}>
                  {isNaN(roundedAcreage)
                    ? '-'
                    : roundedAcreage.toLocaleString()}
                </TextWrapper>
              </Container>
            </Container>
            <Container
              direction={PORTFOLIO_ORIGINATION_TAB_CONTENT.column}
              width={'inherit'}
              gap={'0rem'}>
              <Container
                height={'2rem'}
                hasBorder
                alignItems={'center'}
                width={'inherit'}>
                <TextWrapper
                  fontSize={'0.75rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  width={'37%'}
                  lineHeight={'1.021rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.types}
                </TextWrapper>
                <TextWrapper
                  fontSize={'0.75rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  width={'15%'}
                  lineHeight={'1.021rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.projects}
                </TextWrapper>
                <TextWrapper
                  fontSize={'0.75rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  width={'15%'}
                  lineHeight={'1.021rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.participants}
                </TextWrapper>
                <TextWrapper
                  fontSize={'0.75rem'}
                  color={COOL_GRAY}
                  fontWeight={600}
                  width={'27%'}
                  lineHeight={'1.021rem'}>
                  {PORTFOLIO_ORIGINATION_TAB_CONTENT.ghg_impact}
                  <LightTooltip
                    title={PORTFOLIO_ORIGINATION_TAB_CONTENT.ghg_impact_tooltip}
                    placement={PORTFOLIO_ORIGINATION_TAB_CONTENT.placement}
                    arrow>
                    <InfoOutlinedIcon sx={ToolTipIconSx('sub', '0.25rem')} />
                  </LightTooltip>
                </TextWrapper>
              </Container>
              {portfolioKPI?.projectTypeListDtoList?.map((item, index) => {
                const idx = index;
                return (
                  <Container height={'2rem'} alignItems={'center'} key={idx}>
                    <TextWrapper
                      fontSize={'0.875rem'}
                      fontWeight={400}
                      width={'37%'}
                      lineHeight={'1.021rem'}>
                      <LightTooltip
                        title={item?.projectType}
                        placement={PORTFOLIO_ORIGINATION_TAB_CONTENT.placement}
                        arrow>
                        <Typography
                          sx={{ fontSize: '0.875rem', lineHeight: '1.021rem' }}
                          noWrap>
                          {item?.projectType}
                        </Typography>
                      </LightTooltip>
                    </TextWrapper>
                    <TextWrapper
                      fontSize={'0.875rem'}
                      color={GLOBAL_BLUE}
                      fontWeight={400}
                      width={'15%'}
                      onClick={redirectListHandler}
                      style={{ cursor: 'pointer' }}
                      lineHeight={'1.021rem'}>
                      {item?.projectCount}
                    </TextWrapper>
                    <TextWrapper
                      fontSize={'0.875rem'}
                      color={DARK_CHARCOAL}
                      fontWeight={400}
                      width={'15%'}
                      lineHeight={'1.021rem'}>
                      {portfolioKPI?.participantCount}
                    </TextWrapper>
                    <TextWrapper
                      fontSize={'0.875rem'}
                      width={'27%'}
                      fontWeight={400}
                      lineHeight={'1.021rem'}>
                      {`${item?.ghgEmission} MT`}
                    </TextWrapper>
                  </Container>
                );
              })}
            </Container>
          </Container>
        </Container>
        <Container
          width={'30.625rem'}
          id="ghg-container"
          className="curved-border"
          flexBasis={'100%'}
          backgroundColor={WHITE}
          borderRadius={'0.125rem'}
          hasBoxShadow={true}>
          <CustomLineChart
            style={{ flexBasis: '100%' }}
            options={options}
            data={chartData}
            width={containerWidth}
            title={PORTFOLIO_ORIGINATION_TAB_CONTENT.ghg_emissions}
            ToolTipIconSx={ToolTipIconSx('bottom', '0rem')}
            showLegends={false}
            tooltipTitle={PORTFOLIO_ORIGINATION_TAB_CONTENT.ghg_impact_tooltip}
            showLegendsAtBottom={true}
            currentValue={checkTernaryCondition(
              ghgData?.currentYearAbatement,
              `${ghgData?.currentYearAbatement}K`,
              '- -',
            )}
            placement={PORTFOLIO_ORIGINATION_TAB_CONTENT.placement}
            currentValueLabel={PORTFOLIO_ORIGINATION_TAB_CONTENT.actual}
          />
        </Container>
      </Container>
      <Container
        width={'30.625rem'}
        className="curved-border"
        hasBoxShadow={true}>
        <MapSearchContainer
          markerWidth={'10.24'}
          markerHeight={'12.8'}
          showSearchBox={false}
          style={mapStyle}
          fieldPolygons={regionPolygons}
          mapControlOptions={portfolioPageMapOptions}
          boundsCoordinates={northAmericaBounds}
          clickableMarkersWithInfoWindows={markersData}
        />
      </Container>
    </>
  );
};
export default PortfolioOriginationTab;
