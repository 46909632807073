import React from 'react';
import PropTypes from 'prop-types';
import { EmptyStateWrapper, IconStyle } from './ReportingEmptyComponent.style';
import { ReactComponent as YellowStatus } from 'assets/icons/yellowStatus.svg';
import { TextWrapper } from 'components/FormComponents/FormStyles';

const EmptyStateComponent = ({ type, onClick }) => {
  return (
    <EmptyStateWrapper onClick={onClick} data-testid="empty-state">
      <TextWrapper padding="0.5rem" style={IconStyle}>
        <YellowStatus />
      </TextWrapper>
      <TextWrapper fontSize="16px" fontWeight="600" lineHeight="140%">
        {type}
      </TextWrapper>
    </EmptyStateWrapper>
  );
};

EmptyStateComponent.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
};
export default EmptyStateComponent;
