import React, { createContext, useState } from 'react';

// To create TransactionSearch global state context
export const transactionSearchContext = createContext({});
export const TransactionSearchProvider = ({ children }) => {
  const [transactionSearchString, setTransactionSearchString] = useState(null);
  const [transactionSearchField, setTransactionSearchField] = useState(null);

  const value = React.useMemo(
    () => ({
      transactionSearchString,
      setTransactionSearchString,
      transactionSearchField,
      setTransactionSearchField,
    }),
    [transactionSearchString, transactionSearchField],
  );

  return (
    <transactionSearchContext.Provider value={value}>
      {children}
    </transactionSearchContext.Provider>
  );
};
