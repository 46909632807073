import { useMemo, useState, createContext, useEffect } from 'react';
import axios from 'axios';
import {
  FETCH_COVER_CROP_PLANNED_USAGE__DROPDOWN,
  FETCH_COVER_CROP_TYPE_DROPDOWN,
  FETCH_CROP_TYPE_DROPDOWN,
  FETCH_FERTILIZER_APPLICATION_METHOD,
  FETCH_FERTILIZER_TYPE_DROPDOWN,
  FETCH_FOSSIL_FUEL_TYPE_DROPDOWN,
  FETCH_GRAZING_TYPE_DROPDOWN,
  FETCH_IRRIGATION_METHOD_DROPDOWN,
  FETCH_LIVESTOCK_TYPE_DROPDOWN,
  FETCH_MANURE_MANAGEMENT_DROPDOWN,
  FETCH_MANURE_TYPE_DROPDOWN,
  FETCH_SOIL_AMENDMENTS_DROPDOWN,
  FETCH_TILLAGE_TYPE_DROPDOWN,
  FETCH_EDGE_OF_FIELD_PRACTICE_TYPE,
  FETCH_EDGE_OF_FIELD_SPICES_TYPE,
  FETCH_RESIDUE_MANAGEMENT_REMOVAL_METHOD,
} from 'urls';
import { getUniqueObjectsByKey } from 'utils/helper';
import {
  fetchManagementTypeDropdown,
  fetchTillageSystemTypeById,
} from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.functions';
import { useSearchParams } from 'react-router-dom';

export const baselineDropdownContext = createContext({});
export const BaselineDropdownProvider = ({ children }) => {
  const [fossilFuelTypeDropdown, setFossilFuelTypeDropdown] = useState([]);
  const [residueTypeDropdown, setResidueTypeDropdown] = useState([]);
  const [livestockTypeDropdown, setLivestockTypeDropdown] = useState([]);
  const [grazingTypeDropDown, setGrazingTypeDropDown] = useState([]);
  const [fertilizerTypeDropDown, setFertilizerTypeDropDown] = useState([]);
  const [statusList, setStatusList] = useState('');
  const [activityStatusList, setActivityStatusList] = useState('');
  const [irrigationMethodDropDown, setIrrigationMethodDropDown] = useState([]);
  const [manureManagementSystemDropdown, setManureManagementSystemDropdown] =
    useState([]);
  const [soilAmendmentsDropdown, setSoilAmendmentsDropdown] = useState([]);
  const [tillageTypeDropdown, setTillageTypeDropdown] = useState([]);
  const [selectedTillagePractice, setSelectedTillagePractice] = useState('');
  const [coverCropTypeDropdown, setCoverCropTypeDropdown] = useState([]);
  const [animalTypeDropdown, setAnimalTypeDropdown] = useState([]);
  const [manureTypeDropdown, setManureTypeDropdown] = useState([]);
  const [plannedUsage, setPlannedUsage] = useState([]);
  const [tillIds, setTillIds] = useState([]);
  const [cropTypeDropDown, setCropTypeDropDown] = useState([]);
  const [fertilizerApplicationMethod, setFertilizerApplicationMethod] =
    useState([]);
  const [terminationType, setTerminationType] = useState([]);
  const [loaderCounter, setLoaderCounter] = useState(0);
  const [practiceDropdown, setPracticeDropdown] = useState([]);
  const [speciesCategory, setSpeciesCategory] = useState([]);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const fetchCropTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_CROP_TYPE_DROPDOWN)
      .then((response) => {
        setCropTypeDropDown(
          response.data.map((data) => ({
            label: data.cropName,
            value: data.cropId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchFossilFuelTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_FOSSIL_FUEL_TYPE_DROPDOWN)
      .then((response) => {
        setFossilFuelTypeDropdown(
          response.data.map((data) => ({
            label: data.ffName,
            value: data.ffId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchResidueTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_RESIDUE_MANAGEMENT_REMOVAL_METHOD)
      .then((response) => {
        setResidueTypeDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchGrazingTypeDropDown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_GRAZING_TYPE_DROPDOWN)
      .then((response) => {
        setGrazingTypeDropDown(
          response.data.map((data) => ({
            label: data.prescribedGrazingSystem,
            value: data.prescribedGrazingSystemId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchManureManagementSystemDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_MANURE_MANAGEMENT_DROPDOWN)
      .then((response) => {
        setManureManagementSystemDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchLivestockTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_LIVESTOCK_TYPE_DROPDOWN)
      .then((response) => {
        setLivestockTypeDropdown(
          response.data.map((data) => ({
            label: data.livestockType,
            value: data.livestockId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchFertilizerTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_TYPE_DROPDOWN)
      .then((response) => {
        const dataList = response.data.map((data) => ({
          label: data.fertilizerName.replace(/^./, (x) => x.toUpperCase()),
          value: data.fertilizerId,
          form: data.fertilizerForm,
          category: data.fertilizerCategory,
          fertilizerSampleId: data.fertilizerId,
          nPercentage: data.npct,
          pPercentage: data.ppct,
          kPercentage: data.kpct,
        }));
        setFertilizerTypeDropDown(getUniqueObjectsByKey(dataList, 'label'));
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchAnimalTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_TYPE_DROPDOWN)
      .then((response) => {
        setAnimalTypeDropdown(
          response.data
            .filter(
              (item) =>
                item.fertilizerName.replace(/^./, (x) => x.toUpperCase()) ===
                'Manure',
            )
            .map((data) => ({
              label: data.manureLivestockType.replace(/^./, (x) =>
                x.toUpperCase(),
              ),
              value: data.fertilizerId,
              category: data.fertilizerCategory,
              fertilizerSampleId: data.fertilizerId,
            })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchManureTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_MANURE_TYPE_DROPDOWN)
      .then((response) => {
        setManureTypeDropdown(
          response.data.map((data) => ({
            label: data.manureType.replace(/^./, (x) => x.toUpperCase()),
            value: data.manureTypeId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchFertilizerApplicationMethod = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_APPLICATION_METHOD)
      .then((response) => {
        setFertilizerApplicationMethod(
          response.data.map((data) => ({
            label: data.fertilizerApplicationMethod,
            value: data.fertilizerApplicationMethodId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchIrrigationMethodDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_IRRIGATION_METHOD_DROPDOWN)
      .then((response) => {
        setIrrigationMethodDropDown(
          response.data.map((data) => ({
            label: data.irrigationMethod,
            value: data.irrigationMethodId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchSoilAmendmentsDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_SOIL_AMENDMENTS_DROPDOWN, { params: { projectId: projectId } })
      .then((response) => {
        setSoilAmendmentsDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchTillageTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    let idList = [];
    return axios
      .get(FETCH_TILLAGE_TYPE_DROPDOWN)
      .then((response) => {
        setTillageTypeDropdown(
          response.data.map((data) => ({
            label: data.tillagePracticeType,
            value: data.tillagePracticeId,
          })),
        );
        response?.data?.map((item) => {
          if (item.tillagePracticeType.includes('Reduced till')) {
            idList.push(item.tillagePracticeId);
          }
          setTillIds(idList);
        });
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchCoverCropTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_COVER_CROP_TYPE_DROPDOWN)
      .then((response) => {
        setCoverCropTypeDropdown(
          response.data.map((data) => ({
            label: data.coverCropType,
            value: data.coverCropTypeId,
            coverCropSampleId: data.coverCropSampleId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchPlannedUsageTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_COVER_CROP_PLANNED_USAGE__DROPDOWN)
      .then((response) => {
        setPlannedUsage(
          response.data.map((data) => ({
            label: data.coverCropPlannedUsage,
            value: data.coverCropPlannedUsageId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchEdgeOfFiledPracticeTypeDropDown = () => {
    axios
      .get(FETCH_EDGE_OF_FIELD_PRACTICE_TYPE, {
        params: { projectId: projectId },
      })
      .then((response) => {
        setPracticeDropdown(
          response.data.map((item) => ({
            label: item.edgeOfFieldPractice,
            value: item.edgeOfFieldPracticeId,
          })),
        );
      });
  };
  const fetchEdgeOfFiledSpeciesTypeDropDown = () => {
    axios.get(FETCH_EDGE_OF_FIELD_SPICES_TYPE).then((response) => {
      setSpeciesCategory(
        response.data.map((item) => ({
          label: item.speciesCategoryName,
          value: item.speciesCategoryId,
        })),
      );
    });
  };

  const getUpdatedParentState = async (parentState) => {
    const updatedData = await Promise.all(
      parentState?.map(async (item) => {
        if (item?.tillageSystemDisturbanceId) {
          const response = await fetchTillageSystemTypeById(
            item.tillageSystemDisturbanceId,
          );
          const managementList = await fetchManagementTypeDropdown();
          const managementType = managementList.find(
            (management) =>
              management.cropPlannedManagementTypeId ===
              item?.cropPlannedManagementTypeId,
          )?.cropPlannedManagementType;
          const disturbanceType = response?.surfaceDisturbanceType;
          const tillageSystem = response?.tillageSystemType;
          return { ...item, tillageSystem, disturbanceType, managementType };
        } else
          return {
            ...item,
            tillageSystem: '',
            disturbanceType: '',
            managementType: '',
          };
      }),
    );
    return updatedData;
  };

  useEffect(() => {
    fetchSoilAmendmentsDropdown();
    fetchTillageTypeDropdown();
    fetchFossilFuelTypeDropdown();
    fetchResidueTypeDropdown();
    fetchLivestockTypeDropdown();
    fetchGrazingTypeDropDown();
    fetchManureManagementSystemDropdown();
    fetchFertilizerTypeDropdown();
    fetchIrrigationMethodDropdown();
    fetchCoverCropTypeDropdown();
    fetchAnimalTypeDropdown();
    fetchManureTypeDropdown();
    fetchTillageTypeDropdown();
    fetchFertilizerApplicationMethod();
    fetchCropTypeDropdown();
    fetchEdgeOfFiledPracticeTypeDropDown();
    fetchEdgeOfFiledSpeciesTypeDropDown();
  }, []);

  const value = useMemo(
    () => ({
      fossilFuelTypeDropdown,
      livestockTypeDropdown,
      residueTypeDropdown,
      grazingTypeDropDown,
      manureManagementSystemDropdown,
      fertilizerTypeDropDown,
      irrigationMethodDropDown,
      soilAmendmentsDropdown,
      tillageTypeDropdown,
      coverCropTypeDropdown,
      animalTypeDropdown,
      manureTypeDropdown,
      plannedUsage,
      terminationType,
      fertilizerApplicationMethod,
      tillIds,
      cropTypeDropDown,
      loaderCounter,
      practiceDropdown,
      speciesCategory,
      selectedTillagePractice,
      setSelectedTillagePractice,
      getUpdatedParentState,
      statusList,
      setStatusList,
      activityStatusList,
      setActivityStatusList,
    }),
    [
      fossilFuelTypeDropdown,
      residueTypeDropdown,
      livestockTypeDropdown,
      grazingTypeDropDown,
      manureManagementSystemDropdown,
      fertilizerTypeDropDown,
      irrigationMethodDropDown,
      soilAmendmentsDropdown,
      tillageTypeDropdown,
      coverCropTypeDropdown,
      animalTypeDropdown,
      manureTypeDropdown,
      plannedUsage,
      terminationType,
      fertilizerApplicationMethod,
      tillIds,
      cropTypeDropDown,
      loaderCounter,
      practiceDropdown,
      speciesCategory,
      selectedTillagePractice,
      setSelectedTillagePractice,
      getUpdatedParentState,
      statusList,
      setStatusList,
      activityStatusList,
      setActivityStatusList,
    ],
  );

  return (
    <baselineDropdownContext.Provider value={value}>
      {children}
    </baselineDropdownContext.Provider>
  );
};
