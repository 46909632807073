import { TooltipWrapper } from './InfoTooltip.style';

export const customTitle = (title, content) => {
  return (
    <TooltipWrapper flexWrap={'wrap'} fontWeight="600" fontSize="14px">
      {title}
      <TooltipWrapper fontWeight="400" fontSize="12px">
        {(content instanceof Array && (
          <ul>
            {content.map((item, index) => {
              const keyVal = index;
              return <li key={keyVal}>{item}</li>;
            })}
          </ul>
        )) ||
          content}
      </TooltipWrapper>
    </TooltipWrapper>
  );
};
