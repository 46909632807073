import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';
import { backdropSx } from 'theme/GlobalStyles';

export const loaderContext = createContext({});
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading],
  );

  return (
    <loaderContext.Provider value={value}>
      <Backdrop sx={backdropSx} open={isLoading}>
        <CircularProgress color="success" />
      </Backdrop>
      {children}
    </loaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
