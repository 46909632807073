import axios from 'axios';
import { initailCalcMockData } from 'pages/Origination/Org_Project/Org_Projects.content';
import { calculationSummaryData } from 'pages/Origination/ProjectCalculationReport/ProjectCalculationReport.content';
import React, { createContext, useEffect, useState } from 'react';
import {
  FETCH_ALL_CALCULATIONS_LABEL,
  FETCH_PROJECT_CALCULATION_REPORT,
  FETCH_PROJECT_CALCULATION_REPORT_SUMMARY,
} from 'urls';
import { DEFAULT_PAGE_SIZE } from 'utils/config';
import { checkTernaryCondition } from 'utils/helper';

export const projectCalculationContext = createContext({});
export const ProjectCalculationProvider = ({ children }) => {
  const [projectId, setProjectId] = useState(null);
  const [calculationReportRows, setCalculationReportRows] =
    useState(initailCalcMockData);
  const [calculationToggle, setCalculationToggle] = useState({
    baseline: true,
    project: true,
  });
  const [totalMT, setTotalMT] = useState(null);
  const [fetchedSummaryData, setFetchedSummaryData] = useState({
    totalAcres: null,
    baselineExecutedDate: null,
    projectExecutedDate: null,
    participantCount: null,
  });
  const [calculationSummary, setCalculationSummary] = useState([]);
  const [calculationLabels, setCalculationLabels] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const [emptyState, setEmptyState] = useState(true);
  const [calcLoading, setCalcLoading] = useState(false);
  const handleChange = (event) => {
    setCalculationToggle({
      ...calculationToggle,
      [event.target.name]: event.target.checked,
    });
    setTotalMT(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchProjectCalcualtionReport = () => {
    setCalcLoading(true);
    axios
      .post(FETCH_PROJECT_CALCULATION_REPORT, {
        pageNumber: page,
        pageSize: pageSize,
        internalProjectId: projectId,
      })
      .then((response) => {
        const reportData = response?.data;
        if (reportData?.content?.length === 0) {
          setEmptyState(true);
        } else {
          setCalculationReportRows(reportData);
          setTotalElements(reportData?.totalElements);
          setEmptyState(false);
        }
      })
      .finally(() => setCalcLoading(false));
  };
  const fetchProjectCalculationSummary = () => {
    axios
      .get(
        `${FETCH_PROJECT_CALCULATION_REPORT_SUMMARY}?internalProjectId=${projectId}`,
      )
      .then((response) => {
        const data = response.data;
        setFetchedSummaryData({
          totalAcres: data?.totalAcres,
          baselineExecutedDate: data?.baselineExecutedDate,
          projectExecutedDate: data?.projectExecutedDate,
          participantCount: data?.participantCount,
        });
      })
      .catch(() => {});
  };
  const fetchCalculationsLabels = () => {
    axios
      .get(FETCH_ALL_CALCULATIONS_LABEL)
      .then((response) => {
        const data = response.data;
        setCalculationLabels(data);
      })
      .catch(() => {})
      .finally(() => setCalcLoading(false));
  };
  useEffect(() => {
    if (projectId !== null) {
      fetchProjectCalcualtionReport();
      fetchProjectCalculationSummary();
    }
  }, [projectId, page, pageSize]);
  useEffect(() => {
    if (calculationReportRows?.content?.length > 0) {
      fetchCalculationsLabels();
    }
  }, [calculationReportRows]);
  useEffect(() => {
    setCalculationSummary(
      calculationSummaryData(
        checkTernaryCondition(totalMT, Number(totalMT)?.toFixed(2), totalMT),
        fetchedSummaryData.totalAcres,
        fetchedSummaryData.participantCount,
        fetchedSummaryData.baselineExecutedDate,
        fetchedSummaryData.projectExecutedDate,
      ),
    );
  }, [totalMT, fetchedSummaryData]);
  const value = React.useMemo(
    () => ({
      calculationReportRows,
      setCalculationReportRows,
      calcLoading,
      setCalcLoading,
      calculationToggle,
      setCalculationToggle,
      projectId,
      setProjectId,
      totalMT,
      setTotalMT,
      calculationSummary,
      setCalculationSummary,
      fetchedSummaryData,
      setFetchedSummaryData,
      page,
      setPage,
      pageSize,
      setPageSize,
      totalElements,
      setTotalElements,
      emptyState,
      setEmptyState,
      calculationLabels,
      setCalculationLabels,
      handleChange,
      handleChangePage,
      handleChangeRowsPerPage,
    }),
    [
      calculationReportRows,
      calcLoading,
      calculationToggle,
      projectId,
      totalMT,
      calculationSummary,
      fetchedSummaryData,
      page,
      pageSize,
      totalElements,
      emptyState,
      calculationLabels,
    ],
  );

  return (
    <projectCalculationContext.Provider value={value}>
      {children}
    </projectCalculationContext.Provider>
  );
};
