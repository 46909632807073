import moment from 'moment';

export const PROJECT_SCHEDULE_CONTENT = {
  heading: 'Project cycle',
  subHeading: ({ noOfCycles, period, startMonth }) => {
    return (
      <>
        {' '}
        This project cycle repeats for <span>{noOfCycles}</span> cycles{' '}
        <span>{period}</span>, starting <span>{startMonth}</span>.
      </>
    );
  },
  addBtnLabel: 'Project cycle',
  info_content:
    'Once submitted you may no longer update or delete the project cycle schedule. Please make sure final edits are made prior to submittal.',
};
