import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderColor: '#D0D0CE',
    borderWidth: 1,
    alignItems: 'center',
    height: 36,
    fontStyle: 'bold',
  },
  participantCell: {
    flexDirection: 'column',
    paddingLeft: 8,
    width: '50%',
    borderRightColor: '#D0D0CE',
    borderRightWidth: 1,
  },
  participantName: {
    flexDirection: 'column',
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
  },
  participantEmail: {
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: 12,
    color: '#63666A',
  },
  qty: {
    width: '20%',
    textAlign: 'left',
    borderRightColor: '#D0D0CE',
    borderRightWidth: 1,
    paddingLeft: 8,
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
  },
  date: {
    width: '25%',
    textAlign: 'left',
    paddingLeft: 8,
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
  },
});
