import axios from 'axios';
import SearchBox from 'components/ComboSearchbar/Searchbox.react';
import { createContext, useEffect, useState } from 'react';
import { PROJECT_SEARCH, PROJECT_SEARCH_FIELDS } from 'urls';
import { useNavigate } from 'react-router';
import { pathName } from 'Routes/routes.const';

export const SearchContext = createContext({});
export const SearchProvider = SearchContext.Provider;

export function ProjectSearchBox() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedField, setSelectedField] = useState();
  const searchKeyword = '';
  const [loading, setLoading] = useState(false);
  const placeholder = 'Search for Projects';
  const isSeeMoreOptionEnabled = false;

  useEffect(() => {
    axios
      .get(PROJECT_SEARCH_FIELDS)
      .then((res) => res.data)
      .then((res) => {
        setDropdown(res);
        return res;
      })
      .then((res) =>
        setSelectedField(res.find((d) => d.orderId === 1).userFriendlyName),
      );
  }, []);

  const navigationFunction = ({ metaData, projectId }) => {
    navigate(`${pathName.assets.projects.view}/${projectId}`);
  };

  const fetchFunction = (keyword) => {
    setLoading(true);
    axios
      .get(`${PROJECT_SEARCH}?field=${selectedField}&keyword=${keyword}`)
      .then((res) => res.data)
      .then((res) => setSearchResult(res))
      .finally(() => setLoading(false));
  };

  const clearFunction = () => {
    setSearchResult([]);
  };

  const fetchMoreResultsInTableMode = () => {
    throw new Error('Method not implemented for project');
  };

  return (
    <SearchProvider
      value={{
        searchKeyword,
        dropdown,
        setDropdown,
        searchResult,
        fetchFunction,
        clearFunction,
        selectedField,
        setSelectedField,
        loading,
        navigationFunction,
        placeholder,
        isSeeMoreOptionEnabled,
        fetchMoreResultsInTableMode,
      }}>
      <SearchBox />
    </SearchProvider>
  );
}
