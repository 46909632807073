export const VALUE_CONTENT = {
  description: 'Description',
  notes: 'Notes',
  column: 'column',
  edits_made_to: 'Edits made to ',
  pre_edited: 'Pre Edited ',
  edited: 'Edited',
  changes_made: 'Changes made to',
  added: 'added',
  changed: 'changed from',
  empty: '--',
  old_notes: 'Old Notes',
  new_notes: 'New Notes',
  updated: 'Updated',
};

export const Table_Column_Header = {
  fields: { name: 'Field(s)', width: '14rem' },
  old_value: { name: 'Old Value', width: '14rem' },
  new_value: { name: 'New Value', width: '14rem' },
};
