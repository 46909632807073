import styled from 'styled-components';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  background-color: ${WHITE};
  width: 100%;
  height: 70vh;
  position: relative;
  display: flex;
  justify-content: center;
`;
export const ContentWrapper = styled.div`
  position: absolute;
  top: 7.5%;
  width: 35%;
`;
export const TextWrapper = styled.div`
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${DARK_CHARCOAL};
  margin-bottom: 0.15rem;
`;
