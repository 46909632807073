import styled from 'styled-components';
import { DARK_GREEN, WHITE } from 'theme/GlobalColors';
import { modalActionButtonDefaultStyle } from 'theme/GlobalStyles';

export const ButtonsWrapper = styled.div`
  position: ${({ position }) => position};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  display: flex;
  grid-column-gap: 1rem;
  margin-top: 1rem;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const saveAndContinuebuttonStyle = {
  textTransform: 'none',
  backgroundColor: DARK_GREEN,
  ...modalActionButtonDefaultStyle,
  '&:hover': {
    backgroundColor: DARK_GREEN,
  },
};

export const saveAndCloseBtnStyle = {
  textTransform: 'none',
  border: `1px solid ${DARK_GREEN}`,
  background: WHITE,
  color: DARK_GREEN,
  padding: '0.344rem 1rem',
  ...modalActionButtonDefaultStyle,
  '&:hover': {
    color: DARK_GREEN,
    backgroundColor: WHITE,
  },
};

export const cancelBtnStyle = {
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: DARK_GREEN,
  boxShadow: 'none',
  ...modalActionButtonDefaultStyle,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};
