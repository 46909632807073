import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { GET_ROLE_AND_PERMISSIONS } from 'urls';
import { MODULE_KEY_NAME_MAP, MODULE_KEY_VALUES } from 'utils/config';

export const userAuthRolesContext = createContext({});
export const UserAuthRolesProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [contractPdfBucketId, setContractPdfBucketId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [isEmailLinkClicked, setIsEmailLinkClicked] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isParticipantPortalAccessDenied, setIsParticipantPortalAccessDenied] =
    useState(false);
  const [clientConfigs, setClientConfigs] = useState([]);
  const [modulesListData, setModulesListData] = useState([]);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const fetchRolesAndPermissions = () => {
    setLoading(true);
    axios
      .get(GET_ROLE_AND_PERMISSIONS)
      .then((response) => {
        const moduleList = response.data.clientConfigs
          .filter((values) => MODULE_KEY_VALUES.includes(values.key))
          .map((config) => ({
            key: config.key,
            value: config.value,
            name: MODULE_KEY_NAME_MAP[config.key],
            clientId: config.clientId,
          }));
        setPermissions(response.data?.functionality);
        setUserRoles(
          response.data?.roledefinitions.map((role) => role.role_name),
        );
        setClientConfigs(response.data?.clientConfigs);
        setModulesListData(moduleList);
        setIsDataFetched(true);
        setContractPdfBucketId(response.data?.contractPdfBucketId);
        setUserDetails({
          firstName: response.data?.first_name,
          lastName: response.data?.last_name,
          email: response.data?.email,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRolesAndPermissions();
  }, []);

  const getConfigByKey = (key) => {
    return clientConfigs.find((config) => config.key === key)?.value;
  };

  const value = React.useMemo(
    () => ({
      permissions,
      setPermissions,
      userRoles,
      setUserRoles,
      loading,
      setLoading,
      isAccessDenied,
      setIsAccessDenied,
      isDataFetched,
      setIsDataFetched,
      isParticipantPortalAccessDenied,
      setIsParticipantPortalAccessDenied,
      clientConfigs,
      setClientConfigs,
      contractPdfBucketId,
      setContractPdfBucketId,
      modulesListData,
      fetchRolesAndPermissions,
      getConfigByKey,
      userDetails,
      isEmailLinkClicked,
      setIsEmailLinkClicked,
    }),
    [
      permissions,
      userRoles,
      loading,
      isAccessDenied,
      isDataFetched,
      isParticipantPortalAccessDenied,
      clientConfigs,
      contractPdfBucketId,
      modulesListData,
      userDetails,
      isEmailLinkClicked,
      setIsEmailLinkClicked,
    ],
  );

  return (
    <userAuthRolesContext.Provider value={value}>
      {children}
    </userAuthRolesContext.Provider>
  );
};
