import styled from 'styled-components';
import { WHITE, IMAGE_REVIEW_COLOR } from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';

export const DialogContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  '&.MuiDialogContent-root': {
    overflowX: 'hidden',
    overflowY: 'hidden',
    maxWidth: '1712px',
    maxHeight: '1283px',
  },
};
export const DialogTitleStyle = {
  fontSize: '1rem',
  padding: '0rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  color: WHITE,
  fontWeight: 600,
  lineHeight: '140%',
  alignItems: 'center',
};
export const ModalDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '856px',
      paddingBottom: '2.5rem',
      maxHeight: '509.5px',
      backgroundColor: IMAGE_REVIEW_COLOR,

      '*': {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
};

export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
`;

export const ButtonWrapper = styled.div`
  display: inline-grid;
  align-self: flex-end;
  padding-right: 1rem;
`;

export const closeIconSx = {
  ...pointerCursor,
  alignSelf: 'flex-start',
  color: WHITE,
};
