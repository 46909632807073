import { createTheme } from '@mui/material';
import {
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  WHITE,
  EMPTY_STATE_TEXT_COLOR,
  NEUTRAL_BACKGROUND_COLOR,
} from './GlobalColors';

const AppBarColor = WHITE;

const theme = createTheme({
  palette: {
    primary: {
      main: '#86BD25',
      contrastText: '#FFF',
    },
    darkPrimary: {
      main: '#26890D',
      contrastText: '#FFF',
    },
    whiteColor: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: AppBarColor,
          borderBottom: `2px solid ${NEW_DARK_GREEN}`,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            color: DARK_CHARCOAL,
            fontWeight: '600',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.error-border-color': {
            '&.Mui-error': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'unset',
              },
            },
          },
          '&.Mui-disabled': {
            color: EMPTY_STATE_TEXT_COLOR,
            backgroundColor: NEUTRAL_BACKGROUND_COLOR,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default theme;
