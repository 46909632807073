import { Box } from '@mui/system';
import ProjectCatalog from 'pages/ProjectCatalog/ProjectCatalog.react';
import { ProjectSearchBox } from 'containers/ProjectSearchBox.react';
import { projectStyles } from './Projects.style';

const Projects = () => {
  const { SearchBoxWrapper } = projectStyles();
  return (
    <Box>
      <SearchBoxWrapper>
        <Box>
          <ProjectSearchBox />
        </Box>
      </SearchBoxWrapper>
      <Box>
        <ProjectCatalog disableVirtualization={false} />
      </Box>
    </Box>
  );
};

export default Projects;
