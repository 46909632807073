export const MODAL_CONTENT = {
  heading: 'Soil amendments',
  soil_amendments_type: 'Soil amendments type',
  select_type: 'Select type',
  amount_applied: 'Amount applied',
  type_amount: 'Type amount',
  unit_of_measure: 'Unit of measure',
  amount_applied_error: 'Amount applied is required',
  soil_amendments_type_error: 'Soil amendments type selection is required.',
  unit_of_measure_error: 'Unit of measure selection is required.',
  submit: 'Submit',
  cancel: 'Cancel',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  sixPointTwoFiveRem: '6.25rem',
  tenRem: '10rem',
  hundred_precent: '100%',
  dropdownFontSize: '14px',
  dropdownHeight: '2rem',
  zeroPointFiveRem: '0.5rem',
  zeroPointTwoFiveRem: '0.25rem',
  zeroPointSevenFiveRem: '0.75rem',
  zeroRem: '0rem',
};

export const initialSoilAmendmentsTypeData = {
  sampleId: 0,
  soilAmendmentsType: '',
  amountApplied: '',
  unitOfMeasure: '',
};

export const soilAmendmentsTypeDropdownList = [
  { label: 'Limestone', value: 'Limestone' },
  { label: 'Dolomite', value: 'Dolomite' },
];

export const initialSoilAmendmentsErrorState = {
  soilAmendmentsTypeError: false,
  amountAppliedError: false,
  uomError: false,
};

export const unitOfMeasureDropdownList = [
  { itemLabel: 'kg', value: 'kg' },
  { itemLabel: 'lbs', value: 'lbs' },
];
