import React, { useContext, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import {
  AccordionDetailsSx,
  AccordionHeaderText,
  AccordionHeaderWrapperSx,
  AccordionSummarySx,
  AddIconSx,
  AddSoilSamplingButtonLabelSx,
  AddSoilSamplingButtonSx,
  DownloadButton,
  TableCellEmptySx,
  TableCellHeaderSx,
  TableCellSx,
  TableContainerSx,
  TableHeadRowSx,
  TableRowEmptySx,
  TableRowSx,
  TableSx,
} from './ActivitiesTab.style';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

import UploadSoilSamplingModal from './UploadSoilSamplingModal/UploadSoilSamplingModal.react';
import { DOWNLOAD_SOIL_SAMPLING_DATA, FETCH_SOIL_SAMPLING_DATA } from 'urls';
import { TAB_CONTENT } from './ActivitesTab.content';
import LightTooltip from 'components/LightTooltip';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { hasAnyRequiredAccess } from 'utils/helper';
import {
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
const ActivitiesTab = ({ projectId }) => {
  const [expanded, setExpanded] = useState(true);
  const [soilSamplingDialog, setSoilSamplingDialog] = useState(false);
  const [rows, setRows] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [uploadSoilDataLoader, setUploadSoilDataLoader] = useState(false);
  const { userRoles } = useContext(userAuthRolesContext);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  const handleIconClick = (e) => {
    setLoading(true);
    axios.get(`${FETCH_SOIL_SAMPLING_DATA}/${projectId}`).then((response) => {
      setLoading(false);
      setRows(() => {
        return response.data;
      });
    });
    e.stopPropagation();
    handleExpand();
  };
  useEffect(() => {
    setLoading(true);
    axios.get(`${FETCH_SOIL_SAMPLING_DATA}/${projectId}`).then((response) => {
      setLoading(false);
      setRows(() => {
        return response.data;
      });
    });
  }, [soilSamplingDialog]);
  const toggleAddSoilSamplingDialog = () => {
    setSoilSamplingDialog((prevState) => {
      return !prevState;
    });
  };

  const downloadCSV = async (soilSampleDataId, filename) => {
    await axios
      .get(`${DOWNLOAD_SOIL_SAMPLING_DATA}/${soilSampleDataId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const csvBlob = new Blob([response.data], { type: 'application/csv' });

        const url = window.URL.createObjectURL(csvBlob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', filename);
        tempLink.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {});
  };
  return (
    <>
      <UploadSoilSamplingModal
        modalLoader={setUploadSoilDataLoader}
        accordionExpandHandler={setExpanded}
        projectId={projectId}
        dialogStateHandler={setSoilSamplingDialog}
        dialogState={soilSamplingDialog}></UploadSoilSamplingModal>
      <Accordion expanded={expanded} disableGutters>
        <AccordionSummary sx={AccordionSummarySx}>
          {expanded ? (
            <KeyboardArrowDownIcon
              onClick={handleIconClick}
              sx={{ padding: '4px 8px 4px 8px' }}
            />
          ) : (
            <KeyboardArrowRightIcon
              onClick={handleIconClick}
              sx={{ padding: '4px 8px 4px 8px' }}
            />
          )}
          <div
            style={{
              ...AccordionHeaderWrapperSx,
            }}>
            <AccordionHeaderText>Soil sampling</AccordionHeaderText>
            <div style={{ padding: '0px 16px 0px 8px' }}>
              <Button
                onClick={() => toggleAddSoilSamplingDialog()}
                variant="outlined"
                size="small"
                disabled={hasAnyRequiredAccess(userRoles, [
                  PARTICIPANT,
                  PARTICIPANT_CONTRIBUTOR,
                  ORIGINATION_VIEWER,
                ])}
                startIcon={<AddIcon sx={AddIconSx} />}
                sx={AddSoilSamplingButtonSx}>
                <p style={AddSoilSamplingButtonLabelSx}>
                  {TAB_CONTENT.btnText}
                </p>
              </Button>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={AccordionDetailsSx}>
          {loading || uploadSoilDataLoader ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: '1rem 0rem',
              }}>
              <CircularProgress data-testid="loader" />
            </Box>
          ) : rows.length === 0 ? (
            <TableContainer
              sx={{
                ...TableContainerSx,
              }}>
              <Table
                sx={{
                  ...TableSx,
                }}
                aria-label="simple table">
                <TableHead sx={{}}>
                  <TableRow
                    sx={{
                      ...TableRowEmptySx,
                    }}>
                    <TableCell
                      sx={{
                        ...TableCellEmptySx,
                      }}
                      align="center">
                      {TAB_CONTENT.emptyStateHeading}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer sx={{ ...TableContainerSx }}>
              <Table
                sx={{
                  ...TableSx,
                }}
                aria-label="simple table">
                <TableHead sx={{}}>
                  <TableRow sx={TableHeadRowSx}>
                    <TableCell
                      sx={{
                        ...TableCellHeaderSx,
                        padding: '0px 48px 0px 48px',
                      }}
                      align="center">
                      {TAB_CONTENT.fileNameHeading}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...TableCellHeaderSx,
                        padding: '0px 8px 0px 8px',
                      }}
                      align="center">
                      {TAB_CONTENT.uploadedByHeading}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...TableCellHeaderSx,
                        padding: '0px 8px 0px 8px',
                        width: '16.86%',
                      }}
                      align="center">
                      {TAB_CONTENT.uploadedOnHeading}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.soilSamplingDataId}
                      sx={{
                        ...TableRowSx,
                      }}>
                      <TableCell
                        sx={{
                          ...TableCellSx,
                        }}
                        align="center">
                        <DownloadButton
                          onClick={() => {
                            downloadCSV(row.soilSamplingDataId, row.fileName);
                          }}>
                          <LightTooltip title={row.fileName}>
                            {row.fileName}
                          </LightTooltip>
                        </DownloadButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...TableCellSx,
                          padding: '0px 8px 0px 8px',
                          color: DARK_CHARCOAL,
                        }}
                        align="center">
                        {row.uploadedBy}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...TableCellSx,
                          padding: '0px 8px 0px 8px',
                          width: '16.86%',
                          color: DARK_CHARCOAL,
                        }}
                        align="center">
                        {row.uploadedOn}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
ActivitiesTab.propTypes = {
  projectId: PropTypes.number,
};
export default ActivitiesTab;
