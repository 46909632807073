import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { covertDateToLocalTimeZone } from 'utils/helper';
import { styles } from './RowComponent.style';
import PropTypes from 'prop-types';

const RowComponent = ({ participants }) => {
  const rows = participants.map((item) => (
    <View style={styles?.row} key={item?.id}>
      <View style={styles?.participantCell}>
        <Text style={styles?.participantName}>{item?.participantfullname}</Text>
        <Text style={styles?.participantEmail}>{item?.participantEmail}</Text>
      </View>
      <Text style={styles?.qty}>{0}</Text>
      <Text style={styles?.date}>
        {covertDateToLocalTimeZone(item?.createdDate).slice(0, 10)}
      </Text>
    </View>
  ));
  return <div data-testid="table-rows">{rows}</div>;
};

RowComponent.propTypes = {
  participants: PropTypes.array,
};
export default RowComponent;
