import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabber from 'components/Tabber/Tabber.react';
import { participantInfoContext } from 'contextAPI/participantInfoContext';

const FarmsAndContributorTabs = ({ currentTab, setCurrentTab, tabList }) => {
  const { setShowFieldInfoForm } = useContext(participantInfoContext);
  useEffect(() => {
    setShowFieldInfoForm(false);
  }, []);
  return (
    <Tabber
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      tabList={tabList}
    />
  );
};

FarmsAndContributorTabs.propTypes = {
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
};

export default FarmsAndContributorTabs;
