import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary } from '@mui/material';
import {
  DialogStyle,
  DialogTitleStyle,
  DialogContentStyle,
  ValueWrapper,
  ItemsWrapper,
  titleStyle,
  accordionSx,
} from './ActionModal.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  PAGE_CONTENT,
  displayValue,
  extractAuditEventsBasedOnType,
  addedDeletedFields,
  getValueList,
  extractOriginationAuditEventsBasedOnType,
  addedDeletedTagsField,
} from './ActionModal.content';
import ValueTable from 'containers/AuditTrailComp/TransactionAudit/TransactionModal/ValueTable/ValueTable.react';
import Dialog from '@mui/material/Dialog';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddedDeletedTable from './Components/AddedDeletedTable/AddedDeletedTable.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition } from 'utils/helper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { flexSpaceBetween, pointerCursor } from 'theme/GlobalStyles';

const ActionModal = ({
  eventDetails,
  isModalOpen,
  setIsModalOpen,
  title,
  category,
  moduleType,
}) => {
  const notes = checkTernaryCondition(
    moduleType === PAGE_CONTENT.origination,
    extractOriginationAuditEventsBasedOnType(eventDetails, PAGE_CONTENT.notes),
    extractAuditEventsBasedOnType(eventDetails, PAGE_CONTENT.notes),
  );
  const descriptionList = checkTernaryCondition(
    moduleType === PAGE_CONTENT.origination,
    extractOriginationAuditEventsBasedOnType(
      eventDetails,
      PAGE_CONTENT.description,
    ),
    extractAuditEventsBasedOnType(eventDetails, PAGE_CONTENT.description),
  );
  const addedDeletedList = checkTernaryCondition(
    moduleType === PAGE_CONTENT.origination,
    eventDetails.filter((detail) =>
      addedDeletedFields.includes(detail.targetColumn),
    ),
    [],
  );

  const addedDeletedTagsList = checkTernaryCondition(
    moduleType === PAGE_CONTENT.origination,
    eventDetails.filter((detail) =>
      addedDeletedTagsField.includes(detail.targetColumn),
    ),
    [],
  );

  const fieldsList = checkTernaryCondition(
    moduleType === PAGE_CONTENT.origination,
    extractOriginationAuditEventsBasedOnType(eventDetails),
    extractAuditEventsBasedOnType(eventDetails),
  );

  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={DialogStyle}
        scroll={PAGE_CONTENT.paper}
        aria-labelledby={PAGE_CONTENT.aria_labelledby}
        aria-describedby={PAGE_CONTENT.aria_describedby}>
        <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
          <div style={{ maxWidth: '95%' }}>{title}</div>
          <CloseIcon
            sx={pointerCursor}
            onClick={() => setIsModalOpen(false)}
            data-testid="close-icon"
          />
        </DialogTitle>
        <DialogContent sx={DialogContentStyle}>
          {fieldsList.length > 0 && (
            <Accordion defaultExpanded sx={accordionSx}>
              <AccordionSummary
                expandIcon={
                  <KeyboardArrowRightIcon sx={{ color: DARK_CHARCOAL }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <Typography sx={titleStyle}>
                    {checkTernaryCondition(
                      category !== undefined,
                      `${category} Details`,
                      'Project Details',
                    )}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ItemsWrapper borderBottom="1px solid #D0D0CE">
                  <ValueWrapper fontWeight="600" width="7.5rem">
                    {PAGE_CONTENT.fields}
                  </ValueWrapper>
                  <ValueWrapper fontWeight="600" width="12.5rem">
                    {PAGE_CONTENT.old_value}
                  </ValueWrapper>
                  <ValueWrapper fontWeight="600" width="12.5rem">
                    {PAGE_CONTENT.new_value}
                  </ValueWrapper>
                </ItemsWrapper>

                {fieldsList.map((item) => (
                  <ItemsWrapper key={uniqueId()}>
                    <ValueWrapper width="7.5rem">
                      {checkTernaryCondition(
                        moduleType === PAGE_CONTENT.origination,
                        item.targetColumn,
                        item.column,
                      )}
                    </ValueWrapper>
                    <ValueWrapper width="12.5rem">
                      {displayValue(item.oldValue)}
                    </ValueWrapper>
                    <ValueWrapper width="12.5rem">
                      {displayValue(item.newValue)}
                    </ValueWrapper>
                  </ItemsWrapper>
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {addedDeletedList.length > 0 &&
            addedDeletedList.map((list) => (
              <AddedDeletedTable
                key={uniqueId()}
                title={list.targetColumn}
                addedDeletedList={checkTernaryCondition(
                  list.targetColumn === PAGE_CONTENT.regions,
                  getValueList(
                    list.oldValue.slice(1, -1),
                    list.newValue.slice(1, -1),
                  ),
                  getValueList(list.oldValue, list.newValue),
                )}
              />
            ))}
          {descriptionList.map((item) => (
            <ValueTable
              key={uniqueId()}
              title={checkTernaryCondition(
                moduleType === PAGE_CONTENT.origination,
                item.targetColumn,
                item.column,
              )}
              widthProp={'100%'}
              notesComponentType={PAGE_CONTENT.description}
              hasNotes={true}
              oldValue={item.oldValue || PAGE_CONTENT.empty}
              newValue={item.newValue || PAGE_CONTENT.empty}
            />
          ))}
          {notes.map((item) => (
            <ValueTable
              key={uniqueId()}
              title={checkTernaryCondition(
                moduleType === PAGE_CONTENT.origination,
                item.targetColumn,
                item.column,
              )}
              widthProp={'100%'}
              hasNotes={true}
              oldValue={item.oldValue || PAGE_CONTENT.empty}
              newValue={item.newValue || PAGE_CONTENT.empty}
            />
          ))}
          {addedDeletedTagsList.length > 0 &&
            addedDeletedTagsList.map((list) => (
              <AddedDeletedTable
                key={uniqueId()}
                title={list.targetColumn}
                addedDeletedList={getValueList(list.oldValue, list.newValue)}
              />
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

ActionModal.propTypes = {
  eventDetails: PropTypes.object,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  title: PropTypes.string,
  category: PropTypes.string,
  moduleType: PropTypes.string,
};

export default ActionModal;
