import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  pagenumber: {
    color: 'gray',
    fontSize: '14px',
    paddingRight: 5,
  },
  container: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 30,
    right: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
  },
  brandName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  image: {
    width: '100px',
  },
});
