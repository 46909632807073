import { Container, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useState } from 'react';
import { useStyles } from '../../theme/GlobalStyles';
import TabPanel from '../../components/Tabber/TabPanel.react';
import { SETTINGS_CONTENT, settingsTabs } from './SettingsPage.content';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';

const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `portfolio-page-tab-${index}`,
  };
};

const SettingsPage = () => {
  const { TabberBox } = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return (
    <>
      <Container maxWidth={false}>
        <ListViewHeader title={SETTINGS_CONTENT.setting} noButton />
        <Box sx={{ marginBottom: '1rem' }}>
          <TabberBox>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="page-tabs">
              {settingsTabs.map((tab, index) => (
                <Tab
                  sx={{ textTransform: 'none' }}
                  className={classNames({ 'active-tab': currentTab === index })}
                  key={tab.title}
                  label={tab.title}
                  {...tabProps(index)}
                />
              ))}
            </Tabs>
          </TabberBox>
        </Box>
        {settingsTabs.map((tab, index) => (
          <TabPanel
            key={tab.title}
            value={currentTab}
            index={index}
            customType="table">
            {tab.component}
          </TabPanel>
        ))}
      </Container>
    </>
  );
};

export default SettingsPage;
