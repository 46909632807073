import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionDetailsSx,
  AccordionSummarySx,
  AccordionSx,
  ArrowLabelWrapper,
  Field,
  FieldLabel,
  FieldValue,
  FieldValueWrapper,
  MainWrapper,
  RowWrapper,
  TextWrapper,
} from './AccordionDisplayBox.style';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { ReactComponent as EditIconWithHoverEffect } from 'assets/icons/EditIconWithHoverEffect.svg';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { deleteIconSx } from 'components/FormComponents/FormStyles';

const AccordionDisplayBox = ({
  labels,
  mainLabel,
  itemId,
  handleClickDelete,
  handleClickEdit,
  deleteModalHeading,
  deleteModalContent,
  width,
  disableActions = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleAccordionToggle = (event) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };
  const handleArrowIcon = (arrowState, currentId) => {
    return (
      <div data-testid={currentId} style={{ marginTop: '0.3rem' }}>
        {checkTernaryCondition(
          expanded,
          <KeyboardArrowDownIcon />,
          <KeyboardArrowRightIcon />,
        )}
      </div>
    );
  };
  const deleteHandler = () => {
    setIsDialogOpen(true);
  };
  const editHandler = () => {
    handleClickEdit(itemId);
  };
  return (
    <>
      <MainWrapper width={width}>
        <Accordion expanded={expanded} sx={AccordionSx}>
          <AccordionSummary sx={AccordionSummarySx}>
            <RowWrapper>
              <ArrowLabelWrapper onClick={handleAccordionToggle}>
                {handleArrowIcon(expanded, itemId)}
                <TextWrapper fontSize={'1rem'} fontWeight={600}>
                  {mainLabel}
                </TextWrapper>
              </ArrowLabelWrapper>
              {!disableActions && (
                <TextWrapper width="10%" gap="0.5rem">
                  <EditIconWithHoverEffect
                    data-testid={`edit-icon-${itemId}`}
                    onClick={() => editHandler()}
                  />
                  <DeleteIconWithHoverEffect
                    data-testid={`delete-icon-${itemId}`}
                    sx={deleteIconSx}
                    onClick={() => deleteHandler()}
                  />
                </TextWrapper>
              )}
            </RowWrapper>
          </AccordionSummary>
          <AccordionDetails sx={AccordionDetailsSx}>
            {labels.map((item) => (
              <Field key={item.label}>
                <FieldLabel>{item.label}</FieldLabel>
                <FieldValueWrapper>
                  <FieldValue>{item.value}</FieldValue>
                  <FieldLabel>{item?.unit}</FieldLabel>
                </FieldValueWrapper>
              </Field>
            ))}
          </AccordionDetails>
        </Accordion>
      </MainWrapper>
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={() => handleClickDelete(itemId)}
        subtitle={deleteModalContent}
        onCancel={() => setIsDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        title={deleteModalHeading}></DialogBox>
    </>
  );
};

AccordionDisplayBox.propTypes = {
  labels: PropTypes.array,
  mainLabel: PropTypes.string,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClickDelete: PropTypes.func,
  handleClickEdit: PropTypes.func,
  deleteModalHeading: PropTypes.string,
  deleteModalContent: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableActions: PropTypes.bool,
};

export default AccordionDisplayBox;
