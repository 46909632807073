export const PAGE_CONTENT = {
  noDataContent: 'Looks like you haven’t added any users yet.',
  addNewUserLabel: 'Add a new user to get started!',
  addButton: 'Add user',
  remove: 'Remove',
  remove_confirmation_message: 'Are you sure you want to remove',
  cancel: 'Cancel',
  toast_success_message: 'was successfully removed.',
  toast_error_message: 'failed to be removed. Please try again.',
  roles: 'roles',
  pending: 'Pending',
  pending_user_activation_error_message:
    'is in pending state. Please activate the user before removing.',
  filter_content: 'We couldn’t find any results for the user',
  search_text_suffix: 'Please try another search.',
  please_try_again: 'Please try again.',
  filter_content_width: '75%',
  search_placeholder: 'Search by name or email',
  clear_all: 'Clear All',
  participant: 'PARTICIPANT',
};

export const listingUserColumn = {
  userId: {
    label: 'userId',
    field: 'userId',
  },
  userName: {
    label: 'Name',
    field: 'userName',
  },
  userEmail: {
    label: 'Contact',
    field: 'userEmail',
  },
  company: {
    label: 'Company',
    field: 'company',
  },
  role: {
    label: 'Role',
    field: 'role',
  },
  createdOn: {
    label: 'Added on',
    field: 'createdDate',
  },
  lastActivity: {
    label: 'Last activity',
    field: 'lastActivity',
  },
};

export const getRoleLabel = (role) => {
  switch (role) {
    case 'ASSET_VIEWER':
      return 'Asset - Viewer';
    case 'ASSET_PM':
      return 'Asset - Project Manager';
    case 'ORIGINATION_VIEWER':
      return 'Origination - Viewer';
    case 'ORIGINATION_PM':
      return 'Origination - Project Manager';
    case 'ADMIN':
      return 'Admin';
    case 'PARTICIPANT_MANAGER':
      return 'Participant Manager';
    case 'PARTICIPANT_CONTRIBUTOR':
      return 'Participant Contributor';
    default:
      return role;
  }
};
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const roleMapping = (role) => {
  switch (role) {
    case 'Asset - Viewer':
      return 'ASSET_VIEWER';
    case 'Asset - Project Manager':
      return 'ASSET_PM';
    case 'Origination - Viewer':
      return 'ORIGINATION_VIEWER';
    case 'Origination - Project Manager':
      return 'ORIGINATION_PM';
    case 'Admin':
      return 'ADMIN';
    case 'Participant Manager':
      return 'PARTICIPANT_MANAGER';
    case 'Participant Contributor':
      return 'PARTICIPANT_CONTRIBUTOR';
    default:
      return role;
  }
};
