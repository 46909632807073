// Please add all routing link here
export const pathName = {
  portfolio: '/portfolio',
  privacy: '/privacy',
  termsOfUse: '/terms-of-use',
  auditLog: '/audit-log',
  users: ' /users',
  dataLibrary: '/data-library',
  stakeholder: {
    edit: '/edit-stakeholder',
  },
  project: {
    edit: '/edit-project',
  },
  assets: {
    projects: {
      view: '/assets/projects',
      edit: '/assets/projects/edit',
    },
    portfolio: '/assets/portfolio',
    stakeholders: {
      view: '/assets/stakeholders',
      edit: '/assets/stakeholders/edit',
    },
    transactions: '/transactions',
  },
  transaction: {
    purchase: {
      edit: '/transactions/purchase-transaction/edit',
      view: '/transactions/purchase-transaction',
      add: '/transactions/add-purchase-transaction',
    },
    sale: {
      view: '/transactions/sale-transaction',
      edit: '/transactions/sale-transaction/edit',
    },
  },
  origination: '/origination',
  originationProject: {
    view: '/projects',
    add: '/projects/add',
    edit: '/projects/edit',
    generateReport: '/projects/generate-report',
  },
  originationParticipants: {
    view: '/stakeholders',
  },
  settings: '/settings',
  participant: {
    participantDataCollection: '/stakeholders/participant-data-collection',
    participantFieldBaseline: '/stakeholders/participant-field-baseline',
    landingPage: '/stakeholders/landing-page',
    menuPage: '/stakeholders/enrollment-menu-page',
    baselineMenuPage: '/stakeholders/baseline-menu-page',
    activityMenuPage: '/stakeholders/activity-reporting-menu-page',
    contributorMenuPage: '/stakeholders/contributor-reporting-menu-page',
    participantFieldActivity: '/stakeholders/participant-field-activity',
    participantActivity: '/stakeholders/activity-reporting-menu-page',
  },

  reports: '/reports',
};
