import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { secondaryButtonStyle } from 'components/FormComponents/FormStyles';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/icons/downloadiconblue.svg';
import { PAGE_CONTENT } from '../../PdfViewerPage/PdfViewerPage.content';
import { HeaderStyle, PDFViewerSx } from './PdfComponent.style';

const PdfComponent = ({ pdfUrl }) => {
  const handleDownloadClick = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <HeaderStyle>
        <Button sx={[secondaryButtonStyle]} onClick={handleDownloadClick}>
          <DownloadIcon />
          {PAGE_CONTENT.downloadPdf}
        </Button>
      </HeaderStyle>
      <PDFViewerSx>
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}#toolbar=0`}
            width="40%"
            style={{
              border: 'none',
            }}
          />
        )}
      </PDFViewerSx>
    </>
  );
};

PdfComponent.PropTypes = {
  pdfUrl: PropTypes.string,
};

export default PdfComponent;
