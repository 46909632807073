import React from 'react';
import PropTypes from 'prop-types';
import {
  NavModuleWrapper,
  NavTitle,
  NavLink,
  NavLinksWrapper,
} from './NavModule.style';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition } from 'utils/helper';

const NavModule = ({ moduleName = '', navLinks = [], hasNoBorder = false }) => {
  return (
    <NavModuleWrapper hasNoBorder={hasNoBorder}>
      {moduleName.length > 0 && <NavTitle>{moduleName}</NavTitle>}
      <NavLinksWrapper>
        {navLinks.map((link) => {
          const linkProps = link.disabled
            ? { onClick: (e) => e.preventDefault() }
            : {
                to: link.path ?? '/',
                additionalPath: link.additionalPath ?? '/',
              };
          const isActive = [linkProps.to, linkProps.additionalPath].some(
            (item) => item === '/' + window.location.pathname.split('/')[1],
          );
          return (
            link.isModuleEnabled && (
              <NavLink
                key={uniqueId()}
                className={`${link.disabled ? 'disabled' : ''} ${
                  isActive ? 'active' : ''
                }`}
                {...linkProps}>
                <span style={{ paddingRight: '10px', display: 'flex' }}>
                  {checkTernaryCondition(
                    isActive,
                    link.focusedIcon && (
                      <link.focusedIcon
                        style={{
                          height: '24px',
                          ...(link.title === 'Reports' && {
                            height: '18px',
                            width: '24px',
                          }),
                        }}
                      />
                    ),
                    link.defaultIcon && (
                      <link.defaultIcon
                        style={{
                          height: '24px',
                          ...(link.title === 'Reports' && {
                            height: '18px',
                            width: '24px',
                          }),
                        }}
                      />
                    ),
                  )}
                </span>
                {link.title ?? ''}
              </NavLink>
            )
          );
        })}
      </NavLinksWrapper>
    </NavModuleWrapper>
  );
};

NavModule.propTypes = {
  moduleName: PropTypes.string,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  hasNoBorder: PropTypes.bool,
};

export default NavModule;
