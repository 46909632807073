import React from 'react';
import PropTypes from 'prop-types';
import {
  MainContainer,
  TextWrapper,
} from '../FieldSectionComp/FieldSectionComp.style';
import { COOL_GRAY } from 'theme/GlobalColors';

const BaselineHeaderComponent = ({
  title,
  label,
  marginBottom,
  color,
  isOptional,
  direction,
  gap,
}) => {
  return (
    <MainContainer
      gap={gap}
      marginBottom={marginBottom}
      color={color || COOL_GRAY}
      direction={direction}>
      <TextWrapper fontSize="14px">{title}</TextWrapper>
      {label && <TextWrapper>{label}</TextWrapper>}
      {isOptional && (
        <TextWrapper fontSize="0.75rem" color={COOL_GRAY}>
          {`(Optional)`}
        </TextWrapper>
      )}
    </MainContainer>
  );
};

BaselineHeaderComponent.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  marginBottom: PropTypes.string,
  color: PropTypes.string,
  isOptional: PropTypes.bool,
  direction: PropTypes.string,
  gap: PropTypes.string,
};
export default BaselineHeaderComponent;
