export const PAGE_CONTENT = {
  time: 'Time',
  user: 'User',
  category: 'Category',
  object: 'Object',
  action: 'Action',
  pagination_mode: 'server',
  default_page_size: 25,
  default_pagination_options: [25, 50, 75],
  label_rows_per_page: 'Items per page',
  update: 'UPDATE',
  insert: 'INSERT',
  delete: 'DELETE',
  replace: 'REPLACE',
  fields_array: ['Developer', 'vendor_contact_type', 'Notes', 'Description'],
  project: 'Project',
  stakeholder: 'Stakeholder',
  column: 'column',
  desc: 'desc',
  asc: 'asc',
  changed_to: 'changed to',
  changed: 'changed.',
  edited: 'Edited',
  added: 'added',
  changed_from: 'changed from',
  file_replaced: 'File replaced with',
  user_name: 'userName',
  action_field: 'action',
  date_time: 'actionDateTime',
  object_field: 'object',
  category_field: 'category',
  project_transaction: 'Project Transaction',
  audit_log_for: 'Audit Log For',
  white: '#FFFFFF',
  green: '#26890D',
  black: '#333333',
  export: 'Export',
  data_input: 'Data Inputs',
  cool_gray: '#63666A',
  link_blue: '#0076A8',
  more_info: 'more info >',
  created: 'Created',
  deleted: 'Deleted',
  updated: 'Updated',
  application: 'Application',
  range_picker: 'range-picker',
  pointer: 'pointer',
  center: 'center',
  date_filter: 'date-filter',
  transaction: 'Transaction',
  key_zero: 0,
  key_one: 1,
  key_two: 2,
  purchseTransaction: 'Purchase Transactions',
  saleTransaction: 'Sale Transactions',
  transact_category_array: ['Purchase Transactions', 'Sale Transactions'],
  project_payload_category: ['Project', 'Project Transaction'],
};

export const projectCategoryList = [
  { key: 1, value: 'All' },
  { key: 2, value: 'Projects' },
  { key: 3, value: 'Transactions' },
];
export const appCategoryList = [
  { key: 1, value: 'All' },
  { key: 2, value: 'Projects' },
  { key: 3, value: 'Stakeholders' },
  { key: 4, value: 'Data Library' },
  { key: 5, value: 'Transactions' },
];
