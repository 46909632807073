import { uniqueId } from 'utils/uniqueIdGenerator';

export const DELIVERY_DETAILS = {
  occurs_on: 'Occurs on',
  delivery_status: 'Delivery Status',
  add_occurrence_label: 'Add occurrence',
  amount: 'Amount',
  show_past_occurrences: 'Show past occurrences',
  hide_past_occurrences: 'Hide past occurrences',
  info: 'All the fields are required.Please verify and fill up all the fields.',
  warning: 'Total amount for occurrences must be equal to',
  message: 'units has been scheduled',
  deposited: 'Deposited',
  button_variant: 'contained',
  primary_color: 'primary',
  sale_max_amount_error: 'Amount delivered exceeds credits available.',
  row_total_submit_error:
    'Changes to amounts scheduled are inconsistent with credits available. Please cancel and try again.',
  mtCo2: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
};

export const initialDeliveryDetailState = (isSale) => {
  const { deposited } = DELIVERY_DETAILS;
  return [
    {
      id: uniqueId(),
      scheduleId: 0,
      occursOn: null,
      mtAmount: '',
      deliveryStatus: isSale ? '' : deposited,
      rowValid: !isSale,
      isApiPopulated: false,
    },
  ];
};

export const setRowDisabled = (editMode, indexVal, deliveryDetails, item) => {
  if (item.isApiPopulated) {
    return true;
  } else {
    return indexVal !== deliveryDetails?.length - 1;
  }
};
