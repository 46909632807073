import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calenderIcon.svg';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker.react';
import axios from 'axios';
import {
  AUDIT_LOG_FILTER_ACTION,
  AUDIT_LOG_FILTER_CATEGORY,
  AUDIT_LOG_FILTER_MODULE,
  AUDIT_LOG_FILTER_OBJECT,
  AUDIT_LOG_FILTER_USER,
  MAIN_AUDIT_LOG,
} from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ActionModal from 'components/ActionModal/ActionModal.react';
import { generateTitle } from 'components/ActionModal/ActionModal.content';
import {
  dateFormatter,
  usToUkDateFormatter,
  findPastMonthsDate,
  dateTimeFormatter,
} from 'utils/dateFormatter';
import {
  checkTernaryCondition,
  covertDateToLocalTimeZone,
  isValidJSON,
} from '../../utils/helper';
import { pathName } from '../../Routes/routes.const';
import {
  BasicWrapper,
  CellValueWrapper,
  DataGridWrapper,
  FilterHeader,
  HeaderWrapper,
  MainWrapper,
  RowCellWrapper,
  TextWrapper,
  datagridSx,
  sortStyle,
} from './NewApplicationAuditLogComp.style';
import { PAGE_CONTENT } from './NewApplicationAuditLogComp.content';
import { GLOBAL_BLUE, NEW_DARK_GREEN } from 'theme/GlobalColors';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';

const pastOneMonth = findPastMonthsDate(1);

const customCell = (value1, value2, color1, color2, cellClickHandler) => {
  return (
    <RowCellWrapper direction={PAGE_CONTENT.column} onClick={cellClickHandler}>
      <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
        {value1}
      </CellValueWrapper>
      <TextWrapper fontSize="0.75rem" fontWeight={400} color={color2}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

const NewApplicationAuditLogComp = ({ disableVirtualization }) => {
  const location = useLocation();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    dateFormatter(pastOneMonth.toJSON()),
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date().toJSON()));
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_CONTENT.default_page_size);
  const [totalElements, setTotalElements] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEvents, setModalEvents] = useState([]);
  const [actionModalTitle, setActionModalTitle] = useState('');
  const [actionModalCategory, setActionModalCategory] = useState('');
  const [sortValue, setSortValue] = useState(false);
  const [filterData, setFilterData] = useState({
    AUDIT_LOG_MODULE: [],
    AUDIT_LOG_OBJECT_TYPE: [],
    AUDIT_LOG_OBJECT: [],
    AUDIT_LOG_ACTION: [],
    AUDIT_LOG_USER: [],
  });
  const [modifiedList, setModifiedList] = useState({
    AUDIT_LOG_MODULE: [],
    AUDIT_LOG_OBJECT_TYPE: [],
    AUDIT_LOG_OBJECT: [],
    AUDIT_LOG_ACTION: [],
    AUDIT_LOG_USER: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchAuditData();
  }, []);
  useEffect(() => {
    fetchAuditData();
  }, [sortValue, fromDate, toDate, pageNumber, pageSize]);

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    handelFilterClick();
  }, [filterData]);

  const apiCall = () => {
    axios
      .all(
        [
          AUDIT_LOG_FILTER_USER,
          AUDIT_LOG_FILTER_MODULE,
          AUDIT_LOG_FILTER_ACTION,
          AUDIT_LOG_FILTER_CATEGORY,
          AUDIT_LOG_FILTER_OBJECT,
        ].map((promise) => axios.get(promise)),
      )
      .then(
        axios.spread((...responses) => {
          setFilterData({
            ...filterData,
            AUDIT_LOG_MODULE: responses[1].data,
            AUDIT_LOG_OBJECT_TYPE: responses[3].data,
            AUDIT_LOG_OBJECT: responses[4].data,
            AUDIT_LOG_ACTION: responses[2].data,
            AUDIT_LOG_USER: responses[0].data,
          });
        }),
      )
      .catch(() => {
        // react on errors.
      });
  };

  const handelFilterClick = () => {
    let moduleList = filterData.AUDIT_LOG_MODULE?.map((data) => {
      return {
        id: data,
        itemLabel: data,
        checked: false,
      };
    });
    let obejctTypeList = filterData.AUDIT_LOG_OBJECT_TYPE?.map((data) => {
      return {
        id: data,
        itemLabel: data,
        checked: false,
      };
    });
    let objectList = filterData.AUDIT_LOG_OBJECT?.map((data) => {
      const checkedData = isValidJSON(data) ? JSON.parse(data) : data;
      return {
        id: checkTernaryCondition(
          isValidJSON(data),
          `${checkedData?.firstName} ${checkedData?.lastName}`,
          checkedData,
        ),
        itemLabel: checkTernaryCondition(
          isValidJSON(data),
          `${checkedData?.firstName} ${checkedData?.lastName}`,
          checkedData,
        ),
        checked: false,
      };
    });
    const filteredObjectList = [];
    const encounteredItemLabels = {};

    objectList.forEach((item) => {
      if (!encounteredItemLabels[item.itemLabel]) {
        filteredObjectList.push(item);
        encounteredItemLabels[item.itemLabel] = true;
      }
    });
    let actionList = filterData.AUDIT_LOG_ACTION?.map((data) => {
      return {
        id: data,
        itemLabel: data,
        checked: false,
      };
    });
    let userList = filterData.AUDIT_LOG_USER?.map((data) => {
      return {
        id: data,
        itemLabel: data,
        checked: false,
      };
    });
    setModifiedList(() => ({
      ...modifiedList,
      AUDIT_LOG_MODULE: [...moduleList],
      AUDIT_LOG_OBJECT_TYPE: [...obejctTypeList],
      AUDIT_LOG_OBJECT: [...filteredObjectList],
      AUDIT_LOG_ACTION: [...actionList],
      AUDIT_LOG_USER: [...userList],
    }));
  };

  const extractParticipantData = (participantObj) => {
    if (participantObj !== undefined) {
      const [firstName, lastName, projectName] = participantObj.split(',');
      if (firstName !== undefined && lastName !== undefined) {
        return {
          firstName: firstName?.split(':')[1].replace(/"/g, ''),
          lastName: lastName?.split(':')[1].replace(/"/g, '').replace('}', ''),
          projectName: projectName
            ?.split(':')[1]
            .replace('}', '')
            .replace(/"/g, ''),
        };
      }
    }
  };

  const setModalValues = (event, title, category) => {
    setIsModalOpen(true);
    setModalEvents(event);
    setActionModalTitle(title);
    if (category === 'Participants' || category === 'Projects') {
      setActionModalCategory(category.slice(0, -1));
    }
  };

  const fetchAuditData = () => {
    setLoading(true);
    let postData = {
      startDate: usToUkDateFormatter(fromDate),
      endDate: usToUkDateFormatter(toDate),
      filter: {
        action: [],
        module: [],
        objectType: [],
        users: [],
        object: [],
      },
      newestToOldest: sortValue,
      recordId: -1,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    axios
      .post(MAIN_AUDIT_LOG, postData)
      .then((response) => {
        setAuditData(
          response.data.content.map((item) => {
            return {
              auditEvents: item?.auditEvents,
              action: item?.action,
              id: uniqueId(),
              actionTime: dateTimeFormatter(
                covertDateToLocalTimeZone(item?.actionDateTime),
              ),
              category: item?.category,
              actionDate: item?.actionDateTime.slice(0, 10),
              auditId: item?.auditId,
              recordId: item?.recordId,
              module: item?.module,
              userName: item?.userName,
              email: item?.email,
              object: checkTernaryCondition(
                item?.category === 'Participants',
                extractParticipantData(item?.object),
                item?.object,
              ),
            };
          }),
        );
        setTotalElements(response.data.totalElements);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const customHeader = (columnName, hasSort, hasFilter, key) => {
    return (
      <FilterHeader>
        <span className="bold-600">{columnName}</span>

        {hasSort && (
          <>
            {(sortValue === false && (
              <ArrowDownwardIcon
                sx={sortStyle}
                onClick={() => setSortValue(!sortValue)}
                data-testid={`${columnName}-down-arrow`}
              />
            )) || (
              <ArrowUpwardIcon
                sx={sortStyle}
                data-testid={`${columnName}-up-arrow`}
                onClick={() => setSortValue(!sortValue)}
              />
            )}
          </>
        )}
        {/* div is a placeholder for filter component */}
        {hasFilter && <div></div>}
      </FilterHeader>
    );
  };

  const handleMoreInfoClick = (
    eventArray,
    object,
    user,
    category,
    date,
    time,
  ) => {
    const title = generateTitle(category, object, date, time, user);
    setModalValues(eventArray, title, category);
  };

  const actionCell = ({
    action,
    category,
    auditEvents,
    object,
    userName,
    actionDate,
    actionTime,
  }) => {
    if (action === PAGE_CONTENT.update) {
      return (
        <div
          onClick={() =>
            handleMoreInfoClick(
              auditEvents,
              object,
              userName,
              category,
              actionDate,
              actionTime,
            )
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              handleMoreInfoClick(
                auditEvents,
                object,
                userName,
                category,
                actionDate,
                actionTime,
              );
            }
          }}>
          {customCell(
            PAGE_CONTENT.updated,
            PAGE_CONTENT.more_info,
            PAGE_CONTENT.black,
            PAGE_CONTENT.link_blue,
          )}
        </div>
      );
    } else if (action === PAGE_CONTENT.insert) {
      return customCell(
        checkTernaryCondition(
          category === 'Participants',
          PAGE_CONTENT.added_action,
          PAGE_CONTENT.created,
        ),
        '',
        PAGE_CONTENT.black,
        PAGE_CONTENT.link_blue,
      );
    } else {
      return customCell(
        checkTernaryCondition(
          category === 'Participants',
          PAGE_CONTENT.removed,
          PAGE_CONTENT.deleted,
        ),
        '',
        PAGE_CONTENT.black,
        PAGE_CONTENT.link_blue,
      );
    }
  };

  const columns = [
    {
      field: PAGE_CONTENT.module_field,
      headerName: PAGE_CONTENT.module,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.module),
    },
    {
      field: PAGE_CONTENT.category_field,
      headerName: PAGE_CONTENT.category,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.category),
    },
    {
      field: PAGE_CONTENT.object_field,
      headerName: PAGE_CONTENT.object,
      disableColumnMenu: true,
      flex: 2,
      sortable: false,
      renderCell: (params) =>
        customCell(
          checkTernaryCondition(
            params.row?.category === 'Participants',
            `${params.row?.object?.firstName} ${params.row?.object?.lastName}`,
            params.row?.object,
          ),
          checkTernaryCondition(
            params.row?.category === 'Participants',
            params.row?.object?.projectName,
            '',
          ),
          GLOBAL_BLUE,
          PAGE_CONTENT.cool_gray,
          () => {
            cellClickHandler(params);
          },
        ),
    },
    {
      field: PAGE_CONTENT.action_field,
      headerName: PAGE_CONTENT.action,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: '',
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        actionCell(
          checkTernaryCondition(
            params.row?.category === 'Participants',
            {
              ...params.row,
              object: `${params.row?.object?.firstName} ${params.row?.object?.lastName}`,
            },
            params.row,
          ),
        ),
    },
    {
      field: PAGE_CONTENT.date_time,
      headerName: PAGE_CONTENT.time,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderHeader: (params) =>
        customHeader(PAGE_CONTENT.time, true, false, PAGE_CONTENT.key_zero),
      renderCell: (params) =>
        customCell(
          covertDateToLocalTimeZone(params?.row?.actionDate).slice(0, 10),
          params?.row?.actionTime,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },

    {
      field: PAGE_CONTENT.user_name,
      headerName: PAGE_CONTENT.user,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      renderCell: (params) =>
        customCell(
          params?.row?.userName,
          params?.row?.email,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
  ];

  const cellClickHandler = (params) => {
    switch (params.row?.category) {
      case PAGE_CONTENT.originationProject:
        if (
          params.row?.module === PAGE_CONTENT.originationModule &&
          params.row?.action !== PAGE_CONTENT.delete
        )
          navigate(
            `${pathName.originationProject.view}/${params.row.recordId}`,
            { state: { previousPath: location.pathname } },
          );
        else {
          navigate(
            `${pathName.originationProject.edit}/${params.row.recordId}`,
            { state: { previousPath: location.pathname } },
          );
        }
        break;
      case PAGE_CONTENT.participants:
        navigate(
          `${pathName.originationParticipants.view}/${params.row.recordId}`,
        );
        break;
    }
  };
  return (
    <MainWrapper>
      <ListViewHeader
        title={PAGE_CONTENT.page_title}
        noButton
        containerPadding={'0'}
        marginBottom={'1rem'}
      />
      <HeaderWrapper>
        <BasicWrapper gap={'1rem'}>
          <BasicWrapper
            backgroundColor={'#fff'}
            gap={'1.5rem'}
            border={'1px solid #97999B'}
            padding={'0.125rem 0.5rem'}
            radius={'2px'}
            height={'fit-content'}
            cursor={PAGE_CONTENT.center}
            data-testid={PAGE_CONTENT.date_filter}
            onClick={() => {
              setIsCalendarOpen(true);
            }}>
            <TextWrapper
              fontSize={'0.75rem'}
              fontWeight={400}
              alignItems={'center'}
              lineHeight={'normal'}>
              {fromDate} - {toDate}
            </TextWrapper>
            <CalendarIcon height={'1.375rem'} />
          </BasicWrapper>
          <BasicWrapper
            backgroundColor={'#fff'}
            gap={''}
            border={`1px solid ${NEW_DARK_GREEN}`}
            hasHoverFocusStyle={true}
            padding={'0.375rem'}
            radius={'0.25rem'}
            cursor={PAGE_CONTENT.pointer}
            alignment={PAGE_CONTENT.center}>
            <TextWrapper
              fontSize={'0.75rem'}
              fontWeight={400}
              color={PAGE_CONTENT.green}></TextWrapper>
            <ExportIcon height={'0.875rem'} />
          </BasicWrapper>
        </BasicWrapper>
      </HeaderWrapper>
      <DataGridWrapper>
        <DataGrid
          headerHeight={40}
          disableVirtualization={disableVirtualization || false}
          disableSelectionOnClick
          loading={loading}
          sx={datagridSx}
          rowCount={totalElements}
          columns={columns}
          rows={auditData}
          page={pageNumber}
          paginationMode={PAGE_CONTENT.pagination_mode}
          onPageChange={(newPage) => setPageNumber(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rowsPerPageOptions={PAGE_CONTENT.default_pagination_options}
          componentsProps={{
            pagination: {
              labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
        />
      </DataGridWrapper>
      <DateRangePicker
        isOpen={isCalendarOpen}
        data-testid={PAGE_CONTENT.range_picker}
        starDateSetter={setFromDate}
        endDateSetter={setToDate}
        setIsOpen={setIsCalendarOpen}
      />
      {isModalOpen && (
        <ActionModal
          eventDetails={modalEvents}
          title={actionModalTitle}
          isModalOpen={isModalOpen}
          category={actionModalCategory}
          setIsModalOpen={setIsModalOpen}
          moduleType="Origination"
        />
      )}
    </MainWrapper>
  );
};

NewApplicationAuditLogComp.propTypes = {
  disableVirtualization: PropTypes.bool,
};

export default NewApplicationAuditLogComp;
