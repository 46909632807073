// EnrollmentMenuItems.js
import { useActivityContext } from 'containers/ActivityListContainer';
import {
  InitiateEnrollmentButton,
  ReviewEnrollmentButton,
  ViewPdfButton,
} from '../ActivityMenuButton/EnrollmentButtons.react';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { DOWNLOAD_ENROLLMENT_REPORT } from 'urls';

const EnrollmentMenuItems = () => {
  const { status } = useActivityContext();

  return (
    <>
      {status === REPORTING_STATUS.not_started && (
        <InitiateEnrollmentButton label={'Initiate enrollment'} />
      )}
      {(status === REPORTING_STATUS.in_progress ||
        status === REPORTING_STATUS.requested) && (
        <>
          <InitiateEnrollmentButton
            label={'Continue'}
            key={'enrollment-continue'}
          />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_REPORT}
            key={'view-enrollment'}
          />
        </>
      )}
      {(status === REPORTING_STATUS.submitted ||
        status === REPORTING_STATUS.in_review) && (
        <>
          <ReviewEnrollmentButton key={'review-enrollment'} />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_REPORT}
            key={'view-enrollment'}
          />
        </>
      )}
      {status === REPORTING_STATUS.approved && (
        <ViewPdfButton
          url={DOWNLOAD_ENROLLMENT_REPORT}
          key={'view-enrollment'}
        />
      )}
    </>
  );
};

export default EnrollmentMenuItems;
