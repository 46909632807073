import axios from 'axios';
import { FILE_DOWNLOAD } from '../urls';
import { DEFAULT_CLIENT_ID } from '../utils/config';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { dbExtractionNeeded, getFileName } from 'utils/helper';

export const useDownloadFiles = () => {
  const errorMessage = 'No data available.';

  const downloadCSVFile = async (areaCode) => {
    const body = {
      clientId: DEFAULT_CLIENT_ID,
      areaCode: areaCode,
      dbExtraction: dbExtractionNeeded(areaCode),
      fileName: getFileName(areaCode),
    };
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios
      .post(FILE_DOWNLOAD, body, options)
      .then((response) => {
        let fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const href = `data:text/csv;charset=utf-8,${response.data}`;
        const link = document.createElement('a');
        link.download = `${fileName}.csv`;
        link.href = href;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(() => {
        toast(<CustomSnackbar type="error" message={errorMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return {
    downloadCSVFile,
  };
};
