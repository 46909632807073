export const addNewButtonOptions = [{ name: 'Project', link: '/add-project' }];
export const LABELS = {
  addNew: 'Add New',
  edit: 'Edit',
  generateReport: 'Generate report',
};
export const VIEW_ORIGINATION_PROJECT_CONTENT = {
  status: 'Active',
  lastUpdated: 'Last updated: ',
  projects: 'Projects',
};

export const TABS_TITLES = {
  summary: 'Summary',
  participants: 'Participants',
  reports: 'Reports',
  calculations: 'Calculations',
  soilSampling: 'Soil sampling',
  settings: 'Settings',
};

export const initailCalcMockData = {
  content: [],
  pageable: {
    sort: { sorted: false, unsorted: true, empty: true },
    pageNumber: 0,
    pageSize: 1,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 26,
  totalElements: 26,
  last: false,
  numberOfElements: 1,
  first: true,
  sort: { sorted: false, unsorted: true, empty: true },
  size: 1,
  number: 0,
  empty: false,
};
