import styled from 'styled-components';
import {
  COOL_GRAY,
  RED,
  QUILL_GRAY,
  GLOBAL_BLUE,
  TABS_HIGHLIGHT_BORDER,
  DOWNLOAD_LINK,
} from 'theme/GlobalColors';
import theme from 'theme/Theme';
import { checkTernaryCondition } from 'utils/helper';

export const DateSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FieldSectionWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  margin-bottom: ${(props) => props.marginBottom || '-1rem'};
  grid-column-gap: ${(props) => props.columnGap};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  align-items: ${(props) => props.alignItems};
`;
export const toastStyle = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const DragAndDropTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.875rem')};
  color: ${(props) =>
    checkTernaryCondition(
      props.isError,
      RED,
      checkTernaryCondition(props.color, props.color, 'inherit'),
    )};
`;
export const SmallUploadWrapper = styled.div`
  width: ${({ width }) => (width ? width : '28.25rem')};
  background: #fff;
  cursor: pointer;
  padding: 1.5rem 0.625rem;
  border-radius: 2px;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%50' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
export const DragAndDropText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;
`;

export const UploadHelpText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1.125rem;
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
  display: ${({ invisible }) => (invisible ? 'none' : 'block')};
`;
export const AcceptedFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0rem;
`;
export const FileName = styled.div`
  padding: 0.25rem 0.5rem;
  border: ${(props) =>
    checkTernaryCondition(
      props.isError,
      `1px solid ${RED}`,
      `1px solid ${QUILL_GRAY}`,
    )};
  border-radius: 2px;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  height: 1.688rem;
  display: flex;
  align-items: center;
`;

export const Subtext = styled.div`
  color: ${({ color }) => color || GLOBAL_BLUE};
  font-weight: 600;
  display: inline;
`;
export const deleteIconSx = {
  color: { COOL_GRAY },
  height: '1.5rem',
  width: '1.5rem',
};
export const cursorPointerSx = {
  cursor: 'pointer',
};
export const DropBoxStyle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;
export const IncentiveBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const IncentiveInputBoxWithSymbol = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: baseline;
`;

export const DialogBoxButtonStyle = {
  padding: '0.625rem 1rem',
  lineHeight: '1.19rem',
  height: '2.25rem',
};

export const InputFieldWrapper = styled.div`
  display: flex;
  gap: 3.375rem;
`;

export const PracticeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 28.25rem;
`;

export const PracticeListItem = styled.div`
  font-size: 0.875rem;
  line-height: 19.6px;
`;

export const ActivityCategory = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${COOL_GRAY};
  width: 28.25rem;
`;

export const ActivitiesTableHeading = styled.div`
  font-size: 0.875rem;
  width: 28.25rem;
  line-height: 19.6px;
  margin-bottom: 0.5rem;
`;

export const ActivityTableData = styled.td`
  padding: '0.25rem 0rem';
  font-size: 0.75rem;
  line-height: 16.34px;
  font-weight: 600;
  color: ${COOL_GRAY};
  border-bottom: 1px solid ${QUILL_GRAY};
  text-align: ${(props) => props.alignment};
  width: ${(props) => props.width};
`;

export const ErrorWrapper = styled.div`
  color: ${RED};
  font-size: 0.75rem;
  line-height: 14.98px;
  margin-top: 0.25rem;
`;

export const InstructionText = styled.div`
  margin-top: 4px;
  font-size: 0.75rem;
  line-height: 16.34px;
  width: 27.75rem;
  color: ${COOL_GRAY};
`;

export const CategoryWrapper = styled.div`
  width: 28.25rem;
  padding: 0 8px 0px 0;
  box-sizing: border-box;
`;
export const TabSx = {
  textTransform: 'none',
  width: 'unset',
  color: '#0071A0',
  fontWeight: '400',
  fontSize: '14px',
  textAlign: 'center',

  '&.Mui-selected': {
    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
    color: '#333333',
  },
  '&. MuiTabs-indicator': {
    backgroundColor: TABS_HIGHLIGHT_BORDER,
    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
  },
  '&.MuiTab-root.Mui-disabled': {
    color: DOWNLOAD_LINK,
  },
};
export const CircularProgressSx = {
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
};
