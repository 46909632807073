export const PAGE_CONTENT = {
  description: 'Description',
  notes: 'Notes',
  column: 'column',
  edits_made_to: 'Edits made to ',
  pre_edited: 'Pre Edited ',
  edited: 'Edited',
  changes_made: 'Changes made to',
  added: 'added',
  changed: 'changed from',
  fields: 'Field(s)',
  old_value: 'Old Value',
  new_value: 'New Value',
  old_notes: 'Old Notes',
  new_notes: 'New Notes',
  empty: '--',
  aria_labelledby: 'scroll-dialog-title',
  aria_describedby: 'scroll-dialog-description',
  regions: 'Regions',
  addedValue: 'Added',
  deletedValue: 'Deleted',
  origination: 'Origination',
};
export const generateTitle = (category, object, date, time, user) => {
  return `${category} - ${object} - Updated on ${date.slice(5, 7)}/${date.slice(
    8,
  )}/${date.slice(0, 4)} at ${time} by ${user}`;
};
export const displayValue = (value) => {
  return value === null || !value.trim() ? PAGE_CONTENT.empty : value;
};
export const extractAuditEventsBasedOnType = (arr, type) => {
  if (type !== undefined) return arr.filter((obj) => obj.column === type);
  else
    return arr.filter(
      (obj) =>
        obj.column != PAGE_CONTENT.description &&
        obj.column != PAGE_CONTENT.notes,
    );
};

export const extractOriginationAuditEventsBasedOnType = (arr, type) => {
  if (type !== undefined) return arr.filter((obj) => obj.targetColumn === type);
  else
    return arr.filter(
      (obj) =>
        obj.targetColumn != PAGE_CONTENT.description &&
        obj.targetColumn != PAGE_CONTENT.notes &&
        !addedDeletedFields.includes(obj.targetColumn) &&
        !addedDeletedTagsField.includes(obj.targetColumn),
    );
};

export const addedDeletedFields = ['Activities', 'Regions'];

export const addedDeletedTagsField = ['Tags'];

export const getValueList = (oldValue, newValue) => {
  let oldValueArray = oldValue.split(',');
  let newValueArray = newValue.split(',');
  oldValueArray = oldValueArray.map((item) => item.trim());
  newValueArray = newValueArray.map((item) => item.trim());
  let valueList = [];
  oldValueArray.map((item) => {
    if (!newValueArray.includes(item) && item !== '') {
      valueList.push({
        value: item,
        status: PAGE_CONTENT.deletedValue,
      });
    }
  });
  newValueArray.map((item) => {
    if (!oldValueArray.includes(item) && item !== '') {
      valueList.push({
        value: item,
        status: PAGE_CONTENT.addedValue,
      });
    }
  });
  return valueList;
};
