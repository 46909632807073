export const MODAL_CONTENT = {
  heading: 'Cover crop',
  crop_type: 'Cover crop type',
  select_crop_type: 'Select cover crop type',
  annual_yield: 'Annual Yield',
  type_amount: 'Type amount',
  unitOfMeasure: 'Unit of Measure',
  select_unit: 'Select unit',
  measured_per: 'Measured Per',
  acre: 'acre',
  date_planted: 'Date planted',
  date_terminated: 'Date terminated',
  select_date: 'Select Date',
  submit: 'Submit',
  cancel: 'Cancel',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  sixPointTwoFiveRem: '6.25rem',
  tenRem: '10rem',
  hundred_precent: '100%',
  dropdownFontSize: '14px',
  dropdownHeight: '2rem',
  cover_crop_type_error: 'Cover crop selection is required.',
  annual_yield_error: 'Annual yield amount is required.',
  unit_of_measure_error: 'Unit of measure selection is required.',
  date_planted_error: 'Date planted is required.',
  date_terminated_error: 'Date terminated is required.',
  planned_usage: 'Planned usage',
  termination_type: 'Termination type',
  planned_usage_placeholder: 'Select usage type',
  termination_type_placeholder: 'Select termination type',
  planned_usage_error: 'Planned usage selection is required.',
  termination_type_error: 'Planned usage selection is required.',
  plant_date_error: 'Date planted must occur before date terminated.',
  terminated_date_error: 'Date terminated must occur after date planted.',
  date_planted_year_error:
    'Date planted can only occur within the baseline year and up to 1 year prior.',
  date_terminated_year_error:
    'Date terminated can only occur within the baseline year.',
  cover_crop_area_label: 'Cover crop area',
  cover_crop_area_placeholder: 'Type area',
  cover_crop_area_unit: 'acres',
  cover_crop_area_required_error: 'Cover crop area is required.',
  cover_crop_area_exceed_error:
    'Cover crop area can’t exceed total area of the field - {{field_area}} acres.',
  cover_crop_area_zero_error: 'Cover crop area must be greater than 0.',
  noneOption: 'None',
};

export const initialCoverCropTypeData = {
  sampleId: 0,
  cropId: '',
  cropPlantDate: '',
  cropTerminateDate: '',
  plannedUsage: '',
  terminationType: '',
  coverCropArea: '',
};

export const coverCropTypeDropdownList = [
  { label: 'Brazzicas', value: 'Brazzicas' },
  { label: 'Forbs', value: 'Forbs' },
  { label: 'Grasses', value: 'Grasses' },
  { label: 'Legume', value: 'Legume' },
  { label: 'Non-legume broad leaves', value: 'Non-legume broad leaves' },
];

export const cropYieldUnitOfMeasureDropdownList = [
  { label: 'bushels', value: 'bushels' },
  { label: 'tons', value: 'tons' },
  { label: 'pounds', value: 'pounds' },
  { label: 'liner feet', value: 'linear feet' },
];

export const coverCropYieldMeasuredDropdownList = [
  { label: 'acre', value: 'acre' },
  { label: 'hectare', value: 'hectare' },
  { label: 'square meters', value: 'square meters' },
];

export const initialCoverCropErrorState = {
  coverCropTypeError: false,
  annualYieldError: false,
  uomError: false,
  datePlantedError: false,
  dateTerminatedError: false,
  plannedUsage: false,
  terminationType: false,
  coverCropAreaRequiredError: false,
  coverCropAreqEqualToZeroError: false,
  coverCropAreaExceededError: false,
};
