import DialogBox from 'components/DialogBox/DialogBox.react';
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MODAL_CONTENT,
  initialContractModalValues,
  acceptedFilesInfo,
  maxFilesError,
  getError,
  checkErrorArrayLength,
  inputStyleProps,
  totalAcresInputProps,
  dropdownStyleProps,
} from './AddContractModal.content';
import {
  DateSectionWrapper,
  DragAndDropText,
  DragAndDropTitle,
  FieldSectionWrapper,
  FileName,
  SmallUploadWrapper,
  Subtext,
  UploadHelpText,
  AcceptedFileItem,
  DropBoxStyle,
  DialogBoxButtonStyle,
  ErrorWrapper,
  InstructionText,
  TabSx,
} from './AddContractModal.style';
import FieldValueComponent from 'components/FormComponents/FieldValueComponent/FieldValueComponent.react';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  RED,
  TABS_HIGHLIGHT_BORDER,
} from 'theme/GlobalColors';
import { useForm } from 'hooks/useForm';
import { checkTernaryCondition, convertDateFormat, scroll } from 'utils/helper';
import {
  TextWrapper,
  deleteIconSx,
} from 'components/FormComponents/FormStyles';
import axios from 'axios';
import { ADD_CONTRACT, FETCH_PROJECT_CYCLES, GET_CONTRACT_DETAILS } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import uuid from 'react-uuid';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import Loader from 'components/Loader/Loader.react';
import ProjectActivityDetailsSections from './ProjectActivityDetailsSections';
import { Box, Tab, Tabs } from '@mui/material';
import { useStyles } from 'theme/GlobalStyles';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';

const AddContractModal = ({
  addContractOpen,
  setAddContractOpen,
  refreshHandler,
  incentiveList,
  activityPracticeMapping,
  projectId,
  participantId,
  interestedCycleId,
  contractId,
}) => {
  const { formValue, setFormValue } = useForm(initialContractModalValues);
  const [error, setError] = useState({
    endDate: false,
    startDate: false,
    invalidStartDate: false,
    invalidEndDate: false,
  });
  const [dateExceedError, setDateExceedError] = useState({
    endDate: false,
  });
  const { addContract } = useContext(LifeCycleContext);
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [incentiveErrors, setIncentiveErrors] = useState({
    isRequired: [],
    limitExceed: [],
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [projectCycles, setProjectCycles] = useState([]);
  const [endProjectCycleId, setEndProjectCycleId] = useState(null);
  const [projectEndCycleError, setProjectEndCycleError] = useState('');
  const [loading, setLoading] = useState(false);
  const [projectCycleYears, setProjectCycleYears] = useState([
    {
      year: '',
      projectCycleId: '',
    },
  ]);
  const [tabData, setTabData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { TabberBox } = useStyles();
  const [totalAcresPerYear, setTotalAcresPerYear] = useState([
    {
      projectCycleId: interestedCycleId,
      totalContractedAcres: '',
      totalContractedAcresError: '',
    },
  ]);
  const [activityDataInfo, setActivityDataInfo] = useState([
    {
      projectCycleId: interestedCycleId,
      activityDetails: activityPracticeMapping,
      incentiveErrorListUnique: [],
    },
  ]);
  const startYearLabel = useMemo(() => {
    const startCycleLabel = projectCycles.find(
      (cycle) => Number(cycle.value) === Number(interestedCycleId),
    )?.label;
    return startCycleLabel ?? '';
  }, [projectCycles, interestedCycleId]);
  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
    };
  };
  /**
   *
   * @returns true if the selected cycle(end date) is less than the interested cycle(start date)
   */
  const hasProjectCycleError = (selectedCycleId) => {
    const currentCycleYear = projectCycles.find(
      (cycle) => cycle.value === selectedCycleId,
    )?.cycleYear;
    const interestedCycleYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;

    return new Date(currentCycleYear) < new Date(interestedCycleYear);
  };

  const updateActivityDetailsFromGetApiRawData = (rawData) => {
    return rawData.projectActivityResponseDtoList.map((item) => {
      const activityDetails = {};
      item.contractIncentiveResponseDtoList.forEach((activity) => {
        const activityWithData = {
          projectActivityId: activity.projectActivityId,
          activityCategory: activity.activityCategory,
          projectActivityType: activity.projectActivityType,
          contractedUnits: activity.contractedUnits,
          incentiveRate: activity.incentiveRate,
          contractActivityIncentiveId: activity.contractActivityIncentiveId,
          error: null,
        };
        if (activityDetails[activity.activityCategory]) {
          activityDetails[activity.activityCategory].push(activityWithData);
        } else {
          activityDetails[activity.activityCategory] = [activityWithData];
        }
      });
      return {
        projectCycleId: item.projectCycleId,
        activityDetails: activityDetails,
        incentiveErrorListUnique: [],
      };
    });
  };

  const clearUploadFileDetailsOnClose = () => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploadError({ flag: false, message: '' });
  };
  // need to get the values for activityDataInfo and totalAcresPerYear from the get api cal when the modal is opened in edit mode and populate data accordingly
  useEffect(() => {
    if (addContractOpen.editMode) {
      // reconstruct activityDataInfo and totalContractedAcresPerYear from the api response
      if (contractId && projectCycles.length > 0) {
        setLoading(true);
        axios
          .get(`${GET_CONTRACT_DETAILS}?contractId=${contractId}`)
          .then((response) => {
            const rawData = response.data;
            // set the start and end date

            const projectCycleStartYear =
              +rawData.projectCycleStartDate.substring(0, 4);
            const projectCycleEndYear = +rawData.projectCycleEndDate.substring(
              0,
              4,
            );

            const projectCycleEndId = projectCycles.find(
              (cycle) => cycle.cycleYear === projectCycleEndYear,
            )?.value;
            let years = [];
            if (projectCycleStartYear && projectCycleEndYear) {
              for (
                let i = projectCycleStartYear;
                i <= projectCycleEndYear;
                i++
              ) {
                //get the projectCycleId from the year
                const projectCycleId = projectCycles.find(
                  (cycle) => cycle.cycleYear === i,
                )?.value;
                if (projectCycleId)
                  years.push({ year: i, projectCycleId: projectCycleId });
              }
            }

            setProjectCycleYears(years);
            setEndProjectCycleId(projectCycleEndId);
            // PDF HANDLING
            if (rawData.signedPdfUrl !== null && rawData.pdfFileName !== null) {
              setUploadedFiles([
                {
                  file: {
                    name: rawData.pdfFileName,
                    path: rawData.signedPdfUrl,
                    type: 'application/pdf',
                  },
                  id: uuid(),
                },
              ]);
            }

            // to update the totalAcresPerYear loop on each object under projectActivityResponseDtoList get the contractProjectCycleId,projectCycleId and totalContractedAcres also add a new key totalContractedAcresError and set it to '' and take all these into object and inside one array
            const totalAcresPerYearUpdated =
              rawData.projectActivityResponseDtoList.map((item) => {
                return {
                  projectCycleId: item.projectCycleId,
                  totalContractedAcres: item.totalContractedAcres,
                  contractProjectCycleId: item.contractProjectCycleId,
                  totalContractedAcresError: '',
                };
              });

            const activityDataInfoUpdated =
              updateActivityDetailsFromGetApiRawData(rawData);

            setActivityDataInfo(activityDataInfoUpdated);

            setTotalAcresPerYear(totalAcresPerYearUpdated);
            setLoading(false);
          });
      }
    }
  }, [addContractOpen, contractId, projectCycles]);

  useEffect(() => {
    const incentiveRateErrorArr = Object.keys(activityPracticeMapping)
      .filter((category) => category !== 'totalAcres')
      .flatMap((activityCategory) => {
        return activityPracticeMapping[activityCategory].map((practice) => {
          return { projectActivityId: practice.projectActivityId, error: '' };
        });
      });
    setActivityDataInfo([
      {
        projectCycleId: interestedCycleId,
        activityDetails: activityPracticeMapping,
        incentiveErrorListUnique: incentiveRateErrorArr,
      },
    ]);
  }, [activityPracticeMapping]);

  useEffect(() => {
    if (projectCycleYears) {
      //loop over ProjectCycleYears and create tabData with title as the year and content as the ProjectActivityDetailsSections
      let tabData = [];
      projectCycleYears.map((cycleInfo) => {
        return tabData.push({
          title: cycleInfo.year,
          content: (
            <ProjectActivityDetailsSections
              projectCycleId={cycleInfo.projectCycleId}
              key={cycleInfo.projectCycleId}
              year={cycleInfo.year}
              totalEnrolledAcres={
                totalAcresPerYear.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.totalContractedAcres
              }
              handleEnrolledAcresUpdate={(event) =>
                handleEnrolledAcresUpdate(
                  event,
                  cycleInfo.year,
                  cycleInfo.projectCycleId,
                )
              }
              enrolledAcresError={
                totalAcresPerYear.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.totalContractedAcresError
              }
              totalAcresInputProps={{
                ...totalAcresInputProps,
                label: `${MODAL_CONTENT.total_enrolled_acres_label}${startYearLabel}.`,
                type: 'number',
              }}
              activityPracticeData={
                activityDataInfo.find(
                  (item) => item.projectCycleId === cycleInfo.projectCycleId,
                )?.activityDetails ?? activityPracticeMapping
              }
              handlePracticeDataUpdate={handlePracticeDataUpdate}
              inputStyleProps={inputStyleProps}
              uniqueIncentiveErrorList={
                // get the incentiveErrorListUnique for a particular projectCycle id but get only the error message and only the unique ones
                Array.from(
                  new Set(
                    activityDataInfo
                      .find(
                        (item) =>
                          item.projectCycleId === cycleInfo.projectCycleId,
                      )
                      ?.incentiveErrorListUnique.map((item) => item.error),
                  ),
                )
              }
            />
          ),
        });
      });
      setTabData(tabData);
    }
  }, [projectCycleYears, activityDataInfo, totalAcresPerYear]);

  useEffect(() => {
    const incentiveRateErrorArr = Object.keys(activityPracticeMapping).flatMap(
      (activityCategory) => {
        if (Array.isArray(activityPracticeMapping[activityCategory])) {
          return activityPracticeMapping[activityCategory].map((practice) => {
            return { projectActivityId: practice.projectActivityId, error: '' };
          });
        } else {
          return [];
        }
      },
    );
    setActivityDataInfo([
      {
        projectCycleId: interestedCycleId,
        activityDetails: activityPracticeMapping,
        incentiveErrorListUnique: incentiveRateErrorArr,
      },
    ]);
  }, [activityPracticeMapping]);
  const returnInitialErrorList = (activityPracticeMapping) => {
    return Object.keys(activityPracticeMapping)
      .filter((category) => category !== 'totalAcres')
      .flatMap((activityCategory) => {
        return activityPracticeMapping[activityCategory].map((practice) => {
          return {
            projectActivityId: practice.projectActivityId,
            error: '',
          };
        });
      });
  };

  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);
  const isMaxFilesLimitExceeded = useMemo(
    () =>
      uploadedFiles.length + rejectedFiles.length > acceptedFilesInfo.maxFiles,
    [uploadedFiles, rejectedFiles],
  );

  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler([files[files.length - 1]]);
    }
  };

  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };

  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    setRejectedFiles([]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else
      setUploadError({
        flag: acceptedFilesInfo.minFileSize && acceptedFilesInfo.maxFileSize,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [isMaxFilesLimitExceeded]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  useEffect(() => {
    if (addContractOpen.isOpen) {
      clearUploadFileDetailsOnClose();
    }
  }, [addContractOpen.isOpen]);

  const onClose = () => {
    setAddContractOpen({ isOpen: false, participantId: null });
    setFormValue({ startDate: '', endDate: '', incentiveList: [] });
    setError({
      startDate: false,
      endDate: false,
      invalidEndDate: false,
      invalidStartDate: false,
    });
    setDateExceedError({ endDate: false });
    setIncentiveErrors({
      isRequired: [],
      limitExceed: [],
    });
    refreshHandler();
  };

  const getExistingData = (prevData, year) => {
    return prevData.find((data) => data.projectCycleId === year.projectCycleId);
  };

  useEffect(() => {
    const projectCycleStartYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;
    let years = [];
    if (projectCycleStartYear)
      years.push({
        year: projectCycleStartYear,
        projectCycleId: interestedCycleId,
      });
    setTotalAcresPerYear((prevData) => {
      return years.map((year) => {
        const existingData = getExistingData(prevData, year);
        return (
          existingData || {
            projectCycleId: year.projectCycleId,
            totalContractedAcres: activityPracticeMapping.totalAcres ?? '',
            totalContractedAcresError: '',
          }
        );
      });
    });
    setProjectCycleYears(years);
  }, [projectCycles, interestedCycleId]);

  const setProjectCycleYearsList = (endYearId) => {
    const projectCycleStartYear = projectCycles.find(
      (cycle) => cycle.value === interestedCycleId,
    )?.cycleYear;
    const projectCycleEndYear = projectCycles.find(
      (cycle) => cycle.value === endYearId,
    )?.cycleYear;
    //now loop from start to end and get all years if start and end year is not null or undefined
    let years = [];
    if (projectCycleStartYear)
      years.push({
        year: projectCycleStartYear,
        projectCycleId: interestedCycleId,
      });

    if (projectCycleStartYear && projectCycleEndYear) {
      for (let i = projectCycleStartYear + 1; i <= projectCycleEndYear; i++) {
        //get the projectCycleId from the year
        const projectCycleId = projectCycles.find(
          (cycle) => cycle.cycleYear === i,
        )?.value;
        if (projectCycleId)
          years.push({ year: i, projectCycleId: projectCycleId });
      }
    }
    const updateActivityPracticeMapping = (data) => {
      // Create a new object to return
      const newData = {};

      // Iterate over each key in the original data
      Object.keys(data).forEach((key) => {
        if (key !== 'totalAcres') {
          // Create a new array for the current key
          newData[key] = data[key]?.map((activity) => {
            // Clone the original activity object and change contractedUnits and incentiveRate to ''
            return {
              ...activity,
              contractedUnits: '',
              incentiveRate: '',
            };
          });
        }
      });

      return newData;
    };
    //update activityDataInfo with the new years and also the totalContractedAcresPerYear keeping the prev data intact
    setActivityDataInfo((prevData) => {
      return years.map((year, index) => {
        const existingData = getExistingData(prevData, year);
        return (
          existingData || {
            projectCycleId: year.projectCycleId,
            activityDetails:
              year.projectCycleId === interestedCycleId
                ? activityPracticeMapping
                : updateActivityPracticeMapping(activityPracticeMapping),
            incentiveErrorListUnique: returnInitialErrorList(
              activityPracticeMapping,
            ),
          }
        );
      });
    });
    // now for the totalContractedAcresPerYear
    setTotalAcresPerYear((prevData) => {
      return years.map((year, index) => {
        const existingData = getExistingData(prevData, year);
        return (
          existingData || {
            projectCycleId: year.projectCycleId,
            totalContractedAcres:
              index === 0 ? activityPracticeMapping.totalAcres ?? '' : '',
            totalContractedAcresError: '',
          }
        );
      });
    });

    setProjectCycleYears(years);
  };
  function checkIncentiveError(practice) {
    let incentiveError = '';
    if (practice.incentiveRate === '') {
      incentiveError = MODAL_CONTENT.incentive_rate_required_error;
    } else if (Number(practice.incentiveRate) < 1) {
      incentiveError = MODAL_CONTENT.incentive_rate_min_limit_error;
    }
    return incentiveError;
  }
  const onSubmit = () => {
    let hasError = false;
    //check for error in totalEnrolledAcres from the state object : totalAcresPerYear
    totalAcresPerYear.forEach((item) => {
      if (item.totalContractedAcres === '') {
        hasError = true;
        item.totalContractedAcresError =
          MODAL_CONTENT.enrolled_acres_required_error;
      } else if (
        Number(item.totalContractedAcres) === 0 ||
        item.totalContractedAcres === '.'
      ) {
        hasError = true;
        item.totalContractedAcresError =
          MODAL_CONTENT.enrolled_acres_min_limit_error;
      }
    });
    //check for error in incentiveRate from the state object : activtiyDateInfo loop for the incentiveRate value
    activityDataInfo.forEach((item) => {
      // Iterate over each category in activityDetails
      Object.keys(item.activityDetails).forEach((category) => {
        if (category !== 'totalAcres') {
          item.activityDetails[category].forEach((practice) => {
            // Check and set the incentive error for each practice
            const incentiveError = checkIncentiveError(practice);
            if (incentiveError !== '') {
              hasError = true;
            }
            // Find the corresponding error object in incentiveErrorListUnique
            const errorObj = item.incentiveErrorListUnique.find(
              (errorItem) =>
                errorItem.projectActivityId === practice.projectActivityId,
            );

            if (errorObj) {
              // Update the error message in the incentiveErrorListUnique
              errorObj.error = incentiveError;
            } else {
              // If no error object exists for this ID, add a new one

              item.incentiveErrorListUnique.push({
                projectActivityId: practice.projectActivityId,
                error: incentiveError,
              });
            }

            // Optionally, update the practice object itself if needed
            practice.error = incentiveError;
          });
        }
      });
    });
    // Cycle dropdown data not provided
    if (endProjectCycleId === null) {
      hasError = true;
      setProjectEndCycleError(MODAL_CONTENT.project_cycle_required_error);
    }

    // End date is less than start date
    if (hasProjectCycleError(endProjectCycleId)) {
      hasError = true;
      setProjectEndCycleError(
        MODAL_CONTENT.project_cycle_less_than_start_error,
      );
    }

    scroll('.contract-modal-error');
    if (hasError) {
      setActivityDataInfo([...activityDataInfo]);

      setTotalAcresPerYear([...totalAcresPerYear]);
      return;
    }

    // THE API PART
    // FIRST RESTRUCTURE THE DATA TO BE SENT
    // for totalContractedAcresPerYear remove the error key from each of the object in the array and make a new object
    const totalContractedAcresPerYearUpdated = totalAcresPerYear.map((item) => {
      const { totalContractedAcresError, ...rest } = item;
      return rest;
    });

    const getFormattedObject = (item, category) => {
      return item.activityDetails[category].map((practice) => {
        return {
          projectCycleId: item.projectCycleId,
          projectActivityId: practice.projectActivityId,
          contractedUnits: Number(practice.contractedUnits),
          incentiveRate: Number(practice.incentiveRate),
        };
      });
    };
    // for activityDataInfo remove the error key and loop in each object and get the projectCycleId ,do and inner loop on activityDetails on array item under a key under that object and get the projectActivityId , contractedUnits and incentiveRate and incentive rate now store this 4 keys in an object accordingly , these objects will be stored in an array
    const formattedActivityData = activityDataInfo.flatMap((item) => {
      return Object.keys(item.activityDetails)
        .filter((category) => category !== 'totalAcres')
        .flatMap((category) => {
          return getFormattedObject(item, category);
        });
    });

    const formData = new FormData();
    if (uploadedFiles.length > 0) {
      formData.append('pdf', uploadedFiles[0].file);
    }

    const getFormattedActivityObjectForPutApi = (item, category) => {
      return item.activityDetails[category].map((practice) => {
        return {
          projectCycleId: item.projectCycleId,
          projectActivityId: practice.projectActivityId,
          contractedUnits: Number(practice.contractedUnits),
          incentiveRate: Number(practice.incentiveRate),
          contractActivityIncentiveId: practice.contractActivityIncentiveId,
        };
      });
    };

    if (addContractOpen.editMode) {
      //CALL PUT REQUEST  IF MODAL OPENED In EDIT MODE
      setLoading(true);
      //FORMAT THE DATA TO BE SENT
      const formattedActivityData = activityDataInfo.flatMap((item) => {
        return Object.keys(item.activityDetails).flatMap((category) => {
          return getFormattedActivityObjectForPutApi(item, category);
        });
      });
      axios
        .put(ADD_CONTRACT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            participantId: participantId,
            projectId: projectId,
            projectCycleEndId: endProjectCycleId,
            contractedArea: JSON.stringify(totalContractedAcresPerYearUpdated),
            incentives: JSON.stringify(formattedActivityData),
          },
        })
        .then(
          addContract(addContractOpen.projectId, addContractOpen.participantId),
        )
        .then(() => {
          setLoading(false);
          toast(
            <CustomSnackbar
              type="success"
              message={MODAL_CONTENT.toast_success_message}
            />,
            toastStyle,
          );
        })
        .catch(() => {
          toast(
            <CustomSnackbar
              type="error"
              message={MODAL_CONTENT.toast_error_message}
            />,
            toastStyle,
          );
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      setLoading(true);

      axios
        .post(ADD_CONTRACT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            participantId: participantId,
            projectId: projectId,
            projectCycleEndId: endProjectCycleId,
            contractedArea: JSON.stringify(totalContractedAcresPerYearUpdated),
            incentives: JSON.stringify(formattedActivityData),
          },
        })
        .then(
          addContract(addContractOpen.projectId, addContractOpen.participantId),
        )
        .then(() =>
          toast(
            <CustomSnackbar
              type="success"
              message={MODAL_CONTENT.toast_success_message}
            />,
            toastStyle,
          ),
        )
        .catch(() => {
          toast(
            <CustomSnackbar
              type="error"
              message={MODAL_CONTENT.toast_error_message}
            />,
            toastStyle,
          );
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  const handlePracticeDataUpdate = (
    key,
    value,
    activityId,
    activityCategory,
    year,
    projectCycleId,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalNumberRegex.test(value)) {
      let incentiveError = '';
      if (key === MODAL_CONTENT.incentive_rate) {
        if (value === '') {
          incentiveError = MODAL_CONTENT.incentive_rate_required_error;
        } else if (Number(value) < 1) {
          incentiveError = MODAL_CONTENT.incentive_rate_min_limit_error;
        }
      }
      const updateActivityData = (data) => {
        return {
          ...data.activityDetails,
          [activityCategory]: data.activityDetails[activityCategory].map(
            (practice) => {
              if (practice.projectActivityId === activityId) {
                return {
                  ...practice,
                  [key]: checkTernaryCondition(value === '.', '0.', value),
                  error: incentiveError,
                };
              }
              return practice;
            },
          ),
        };
      };
      const updateErrorInfo = (data) => {
        return data.incentiveErrorListUnique.map((errorItem) => {
          if (errorItem.projectActivityId === activityId) {
            return {
              ...errorItem,
              error: incentiveError,
            };
          }
          return errorItem;
        });
      };
      setActivityDataInfo((prevData) => {
        // Check if the projectCycleId exists in the array
        const index = prevData.findIndex(
          (data) => data.projectCycleId === projectCycleId,
        );

        if (index !== -1) {
          // If found, update the existing object
          return prevData.map((data) => {
            if (data.projectCycleId === projectCycleId) {
              return {
                ...data,
                activityDetails: updateActivityData(data),
                incentiveErrorListUnique: updateErrorInfo(data),
              };
            }
            return data;
          });
        } else {
          // If not found, create a new object with the initial structure
          const newActivityDetails = {
            ...activityPracticeMapping,
            [activityCategory]: activityPracticeMapping[activityCategory].map(
              (practice) => {
                if (practice.projectActivityId === activityId) {
                  return {
                    ...practice,
                    [key]: checkTernaryCondition(value === '.', '0.', value),
                    error: incentiveError,
                  };
                }
                return practice;
              },
            ),
          };
          const processObject = (practice) => {
            return {
              projectActivityId: practice.projectActivityId,
              error: '',
            };
          };
          const objectToBEReturned = (activityCategory) => {
            return activityPracticeMapping[activityCategory].map(processObject);
          };
          return [
            ...prevData,
            {
              projectCycleId: projectCycleId,
              activityDetails: newActivityDetails,
              incentiveErrorListUnique: Object.keys(
                activityPracticeMapping,
              ).flatMap((activityCategory) => {
                return objectToBEReturned(activityCategory);
              }),
            },
          ];
        }
      });
    }
  };

  const handleEnrolledAcresUpdate = (event, year, projectCycleId) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalNumberRegex.test(event.target.value)) {
      if (event.target.value === '') {
        // update the enrolled Acres
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            acres.projectCycleId === projectCycleId
              ? {
                  ...acres,
                  totalContractedAcresError:
                    MODAL_CONTENT.enrolled_acres_required_error,
                }
              : acres,
          );
        });
      } else if (
        Number(event.target.value) === 0 ||
        event.target.value === '.'
      ) {
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            acres.projectCycleId === projectCycleId
              ? {
                  ...acres,
                  totalContractedAcresError:
                    MODAL_CONTENT.enrolled_acres_min_limit_error,
                }
              : acres,
          );
        });
      } else {
        setTotalAcresPerYear((currentAcres) => {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            acres.projectCycleId === projectCycleId
              ? {
                  ...acres,
                  totalContractedAcresError: '',
                }
              : acres,
          );
        });
      }
      setTotalAcresPerYear((currentAcres) => {
        // Check if the projectCycleId already exists in the array
        const index = currentAcres.findIndex(
          (acres) => acres.projectCycleId === projectCycleId,
        );

        if (index !== -1) {
          // If found, update the existing object
          return currentAcres.map((acres) =>
            acres.projectCycleId === projectCycleId
              ? {
                  ...acres,
                  totalContractedAcres: checkTernaryCondition(
                    event.target.value === '.',
                    '0.',
                    event.target.value,
                  ),
                }
              : acres,
          );
        } else {
          // If not found, add a new object
          return [
            ...currentAcres,
            {
              projectCycleId: projectCycleId,
              totalContractedAcres: checkTernaryCondition(
                event.target.value === '.',
                '0.',
                event.target.value,
              ),
            },
          ];
        }
      });
    }
  };

  const fetchProjectCycles = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;

        const cyclesData = data.map((cycle) => {
          const endYear = new Date(cycle.cycleEndDate).getFullYear();
          return {
            label: `${endYear} (${convertDateFormat(
              cycle.cycleStartDate,
            )}-${convertDateFormat(cycle.cycleEndDate)})`,
            value: cycle.projectCycleId,
            cycleYear: endYear,
          };
        });

        setProjectCycles(cyclesData);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const handleProjectCycleEndUpdate = (event) => {
    setEndProjectCycleId(event.target.value);
    setProjectCycleYearsList(event.target.value);
    if (hasProjectCycleError(event.target.value)) {
      setProjectEndCycleError(
        MODAL_CONTENT.project_cycle_less_than_start_error,
      );
    } else {
      setProjectEndCycleError('');
    }
  };

  useEffect(() => {
    fetchProjectCycles();
  }, []);

  useEffect(() => {
    setIsSubmitDisabled(
      Object.values(error).includes(true) ||
        Object.values(dateExceedError).includes(true) ||
        uploadError.flag ||
        checkErrorArrayLength(
          incentiveErrors,
          formValue.incentiveList,
          incentiveList,
          true,
        ),
    );
  }, [error, dateExceedError, uploadError, incentiveErrors]);

  return (
    <DialogBox
      title={addContractOpen.editMode ? 'Edit contract' : 'Add Contract'}
      isOpen={addContractOpen.isOpen}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      acceptCtnLabel={MODAL_CONTENT.submit}
      btnDisabled={isSubmitDisabled}
      declineCtnLabel={MODAL_CONTENT.cancel}
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      buttonGap="0.5rem"
      dialogActions
      darkGreenBtnColor>
      <Loader loading={loading} zIndex={99999} />
      <DateSectionWrapper>
        <FieldValueComponent
          label={MODAL_CONTENT.project_cycle_dropdown_title}
          fontWeight="400"
          color={DARK_CHARCOAL}
          labelMarginTop="0"
          labelFontSize="14px"
          fieldMarginBottom="0"
          labelMarginBottom="0"
        />
        <InstructionText>
          {MODAL_CONTENT.project_cycle_instruction}
        </InstructionText>
        <FieldSectionWrapper columnGap="0.75rem">
          <DropDown
            label="Start project cycle"
            isDisabled
            value={interestedCycleId}
            dropdownlist={projectCycles}
            {...dropdownStyleProps}
          />
          <DropDown
            isDisabled={addContractOpen.editMode}
            label="End project cycle"
            value={checkTernaryCondition(
              endProjectCycleId === null,
              MODAL_CONTENT.project_cycle_dropdown_placeholder,
              endProjectCycleId,
            )}
            dropDownPlaceholder={
              MODAL_CONTENT.project_cycle_dropdown_placeholder
            }
            dropdownlist={projectCycles}
            onUpdate={handleProjectCycleEndUpdate}
            error={projectEndCycleError?.length > 0}
            {...dropdownStyleProps}
          />
        </FieldSectionWrapper>
        <ErrorWrapper
          className={checkTernaryCondition(
            projectEndCycleError?.length > 0,
            'contract-modal-error',
            '',
          )}>
          {projectEndCycleError}
        </ErrorWrapper>
        <DateSectionWrapper>
          {error.startDate && (
            <TextWrapper color={RED} fontSize="12px">
              {MODAL_CONTENT.start_primary_error}
            </TextWrapper>
          )}
          {(error.invalidStartDate || error.invalidEndDate) && (
            <TextWrapper color={RED} fontSize="12px">
              {MODAL_CONTENT.invalid_date}
            </TextWrapper>
          )}
          {error.endDate && (
            <TextWrapper color={RED} fontSize="12px">
              {MODAL_CONTENT.end_primary_error}
            </TextWrapper>
          )}
          {dateExceedError.endDate && (
            <TextWrapper color={RED} fontSize="12px">
              {MODAL_CONTENT.end_secondary_error}
            </TextWrapper>
          )}
        </DateSectionWrapper>
      </DateSectionWrapper>
      <div>
        <DropBoxStyle>
          <DragAndDropTitle
            isError={uploadError.flag || isMaxFilesLimitExceeded}>
            {MODAL_CONTENT.upload_title}
          </DragAndDropTitle>
          <DragAndDropTitle color={COOL_GRAY} fontSize="0.75rem">
            {MODAL_CONTENT.optional}
          </DragAndDropTitle>
        </DropBoxStyle>
        <SmallUploadWrapper>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} data-testid="fileDropzone" />
            <DragAndDropText>
              {MODAL_CONTENT.dropzone_info_text}
              <Subtext>{MODAL_CONTENT.browse}</Subtext>.
            </DragAndDropText>
          </div>
        </SmallUploadWrapper>
        {acceptedFilesInfo.infoTextList?.map((infoText) => (
          <UploadHelpText
            key={infoText}
            invisible={uploadError.flag || isMaxFilesLimitExceeded}>
            {infoText}
          </UploadHelpText>
        ))}

        {uploadError.flag && (
          <UploadHelpText iserror={true}>{uploadError.message}</UploadHelpText>
        )}
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((fileItem, index) => (
            <AcceptedFileItem key={fileItem.id}>
              <FileName isError={isMaxFilesLimitExceeded}>
                {fileItem.file.name.slice(0, 55)}...
              </FileName>
              <DeleteOutlinedIcon
                data-testid="deleteIcon"
                fontSize="large"
                sx={deleteIconSx}
                onClick={() => deleteFile(index, setUploadedFiles)}
              />
            </AcceptedFileItem>
          ))}
        {rejectedFiles.map(({ file, id }, index) => (
          <AcceptedFileItem key={id}>
            <FileName isError={true}>{file.name}</FileName>
            <DeleteOutlinedIcon
              data-testid="deleteIcon"
              fontSize="medium"
              sx={deleteIconSx}
              onClick={() => deleteFile(index, setRejectedFiles)}
            />
          </AcceptedFileItem>
        ))}
      </div>
      <Box sx={{ marginBottom: '0.5rem', width: '28.25rem', height: '2.5rem' }}>
        <TabberBox>
          <Tabs
            visibleScrollbar={false}
            selectionFollowsFocus
            variant="scrollable"
            scrollButtons={false}
            value={currentTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: TABS_HIGHLIGHT_BORDER,
              },
            }}
            onChange={handleTabChange}>
            {tabData.map((tab, index) => (
              <Tab
                role="tab"
                style={{
                  '&. MuiTabs-indicator': {
                    backgroundColor: TABS_HIGHLIGHT_BORDER,
                    borderBottom: `3px solid ${TABS_HIGHLIGHT_BORDER}`,
                  },
                }}
                sx={TabSx}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={tab.title}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
      {tabData.map((tab, index) => (
        <TabPanel key={tab.title} value={currentTab} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </DialogBox>
  );
};

AddContractModal.propTypes = {
  addContractOpen: PropTypes.bool,
  setAddContractOpen: PropTypes.func,
  refreshHandler: PropTypes.func,
  incentiveList: PropTypes.array,
  activityPracticeMapping: PropTypes.object,
  projectId: PropTypes.number,
  interestedCycleId: PropTypes.number,
  participantId: PropTypes.number,
  contractId: PropTypes.number,
};

export default AddContractModal;
