import { BLUISH_CYAN, DARK_CHARCOAL, ROW_HOVER_BG } from 'theme/GlobalColors';

export const menuItemSx = {
  minWidth: '9rem',
  height: '1.75rem',
  padding: '6px 8px',
  fontSize: '0.75rem',
  color: DARK_CHARCOAL,
  ':hover': { backgroundColor: ROW_HOVER_BG },
};
export const MenuSx = {
  padding: '0px',
  '& .MuiPaper-root': {
    borderRadius: '0.125rem',
  },
  '& .MuiMenu-list': {
    padding: '0.5rem 0 ',
  },
};
export const zeroPaddingSx = {
  padding: 0,
  ':hover': { color: BLUISH_CYAN },
};
