import React, { useEffect, useState, useContext } from 'react';
import { Instruction, SectionListWrapper } from './ImplementedActivities.style';
import {
  PAGE_CONSTANTS,
  getSectionHeading,
  implementedActivitiesSortOrder,
} from './ImplementedActivities.content';
import PropTypes from 'prop-types';
import UserNavigationButtons from 'pages/ParticipantDataCollection/components/UserNavigationButtons/UserNavigationButtons.react';
import { FETCH_ACTIVITY_ACREAGE, FETCH_PROJECT_ACTIVITIES } from 'urls';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import Loader from 'components/Loader/Loader.react';
import ActivitiesSection from './ActivitiesSection.react';
import { pathName } from 'Routes/routes.const';
import { checkTernaryCondition } from 'utils/helper';
import { REPORTING_STATUS } from 'utils/config';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';

const ImplementedActivities = ({
  fieldId,
  totalFieldArea,
  fetchFieldDetails,
  setActivityTabData,
  updateFieldLevelStatus,
  sectionsMap,
  setSectionsMap,
  submitData,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { activityStatusList } = useContext(baselineDropdownContext);
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');

  const gotoMenuPage = () =>
    navigate(
      {
        pathname: pathName.participant.activityMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );

  const updateAcreage = (sectionName, activityId, acreage) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalNumberRegex.test(acreage)) {
      setSectionsMap((prevMap) => {
        const entries = Array.from(prevMap.entries());

        const sectionIndex = entries.findIndex(
          ([section]) => section === sectionName,
        );

        if (sectionIndex !== -1) {
          const [section, activities] = entries[sectionIndex];

          const updatedActivities = activities.map((activity) => {
            if (activity?.activityId !== activityId) return activity;
            const area = checkTernaryCondition(acreage === '.', '0.', acreage);
            return {
              ...activity,
              area: area,
            };
          });

          entries[sectionIndex] = [section, updatedActivities];
        }
        setActivityTabData((prev) => ({
          ...prev,
          fieldImplementedActivitiesStatus: REPORTING_STATUS.in_progress,
        }));
        updateFieldLevelStatus();
        return new Map(entries);
      });
    }
  };

  const fetchProjectActivities = async () => {
    const url = FETCH_PROJECT_ACTIVITIES(projectId);

    setLoading(true);
    return axios
      .get(url)
      .then((response) =>
        response.data?.map((activity) => ({
          section: getSectionHeading(activity.activityCategory),
          title: activity.projectActivityType,
          id: activity.projectActivityId,
        })),
      )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchActivityAcreage = async () => {
    const url = `${FETCH_ACTIVITY_ACREAGE}?fieldId=${fieldId}&projectId=${projectId}`;

    setLoading(true);
    return axios
      .get(url)
      .then((response) => response.data)
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const createSections = (activities, acreageList) => {
    let activityAcreageData = [];
    if (acreageList.length === 0) {
      activityAcreageData = activities?.map((activity) => ({
        fieldProjectActivityId: 0,
        activityId: activity.id,
        area: '',
        activityCategory: activity.section,
        projectActivityType: activity.title,
      }));
    } else
      activityAcreageData = acreageList.map((activity) => ({
        ...activity,
        area: checkTernaryCondition(activity.area === null, '', activity.area),
        activityCategory: getSectionHeading(activity.activityCategory),
      }));

    const activityToData = new Map(
      activityAcreageData.map((activityData) => [
        activityData.projectActivityType,
        activityData,
      ]),
    );

    const sectionsMap = new Map();

    activities?.forEach(({ section, title: activity }) => {
      const activityData = activityToData.get(activity);

      if (sectionsMap.has(section)) {
        sectionsMap.get(section).push(activityData);
      } else {
        sectionsMap.set(section, [activityData]);
      }
    });
    setSectionsMap(sectionsMap);
  };

  const handleClose = () => {
    if (isEmailLinkClicked) {
      if (activityStatusList === REPORTING_STATUS.completed) {
        submitData().then(gotoMenuPage);
      } else {
        navigate(`${pathName.participant.landingPage}`);
      }
    } else {
      submitData().then(gotoMenuPage);
    }
  };

  useEffect(() => {
    if (projectId && fieldId) {
      fetchProjectActivities().then((activities) => {
        fetchActivityAcreage().then((acreageList) =>
          createSections(activities, acreageList),
        );
      });
    }
  }, [projectId, fieldId]);

  return (
    <div>
      <Loader loading={loading} zIndex={999999} />
      <Instruction>{PAGE_CONSTANTS.instructionsText}</Instruction>
      <SectionListWrapper>
        {[...sectionsMap.entries()]
          .sort(
            ([a], [b]) =>
              implementedActivitiesSortOrder.indexOf(a) -
              implementedActivitiesSortOrder.indexOf(b),
          )
          ?.map(([section, activities]) => (
            <ActivitiesSection
              key={section}
              sectionTitle={section}
              activities={activities}
              updateAcreage={updateAcreage}
              totalFieldArea={totalFieldArea}
            />
          ))}
      </SectionListWrapper>

      <UserNavigationButtons
        saveAndContinueHandler={() => {
          submitData();
        }}
        saveAndCloseHandler={handleClose}
        CancelHandler={gotoMenuPage}
        hideCancelBtn={true}
      />
    </div>
  );
};

ImplementedActivities.propTypes = {
  fieldId: PropTypes.number,
  totalFieldArea: PropTypes.number,
  fetchFieldDetails: PropTypes.func,
  setActivityTabData: PropTypes.func,
  updateFieldLevelStatus: PropTypes.func,
  sectionsMap: PropTypes.object,
  setSectionsMap: PropTypes.func,
  submitData: PropTypes.func,
};

export default ImplementedActivities;
