export const MODAL_CONTENT = {
  heading: 'Residue management',
  tillage_implemented: 'Were tillage practices implemented?',
  tillage_implemented_error: 'Tillage implementation selection is required.',
  tillage_type: 'Tillage type',
  empty_string: '',
  select_tillage_type: 'Select tillage type',
  select_tillage_type_error: 'A tillage type selection is required.',
  performed_on: 'Performed on',
  select_date: 'Select Date',
  performed_on_error: 'Performed on date is required.',
  specify_tillage_type: 'Please specify tillage type',
  specify_tillage_type_error:
    'Please specify a tillage type if other is selected.',
  tillage_depth: 'Depth of tillage',
  type_depth: 'Type depth',
  cm: 'cm',
  tillage_depth_error: 'Depth of tillage is required.',
  tillage_frequency: 'Frequency of tillage',
  type_amount: 'Type amount',
  tillage_frequency_error: 'Frequency of tillage amount is required.',
  times_per: 'Times per',
  select_time: 'Select time',
  times_per_error: 'Times per selection is required.',
  surface_disturbance: 'Surface disturbance',
  percentage: 'Percentage',
  percent: '%',
  surface_disturbance_error: 'An annual yield amount is required.',
  disturbance_type: 'Disturbance type',
  select_type: 'Select type',
  disturbance_type_error: 'A unit of measure selection is required.',
  agriculture_residues_managed: 'How were agricultural residues managed?',
  select_all_that_apply: 'Select all that apply',
  agriculture_residues_managed_error:
    'Management of agricultural residues selection is required.',
  agriculture_residue_mass_burned: 'Mass of residues burned',
  agriculture_residue_mass_burned_error:
    'Mass of residues burned amount is required.',
  unit_of_measure: 'Unit of measure',
  unit_of_measure_error: 'Unit of measure selection is required.',
  annual_dry_matter_returned_to_soil: 'Annual dry matter returned to soil',
  annual_dry_matter_returned_to_soil_error:
    'Annual dry matter returned to soil amount is required.',
  crop_residue_removed: 'Crop residues removed',
  crop_residue_removed_error: 'Crop residue removed percentage is required.',
  submit: 'Submit',
  cancel: 'Cancel',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  sixPointTwoFiveRem: '6.25rem',
  tenRem: '10rem',
  hundred_precent: '100%',
  dropdownFontSize: '14px',
  dropdownHeight: '2rem',
  elevenPointTwoFiveRem: '11.25rem',
  zeroPointFiveRem: '0.5rem',
  zeroPointTwoFiveRem: '0.25rem',
  zeroPointSevenFiveRem: '0.75rem',
  zeroRem: '0rem',
  false: false,
  removed_biomass_residue_label:
    'What application purposes was the removed biomass residue used for?',
  application_purpose_error:
    'How removed biomass residue was used selection is required.',
  residue_management_area_label: 'Residue management area',
  residue_management_area_placeholder: 'Type area',
  residue_management_area_unit: 'acres',
  residue_management_required_error: 'Residue management area is required.',
  residue_management_exceed_error:
    'Residue management area can’t exceed total area of the field - {{field_area}} acres.',
  residue_management_zero_error:
    'Residue management area must be greater than 0.',
  agriculture_residues_managed_placeholder: 'Select option',
};

export const initialResidueManagementTypeData = {
  agricultureResiduesManaged: '',
  cropResidueRemoved: '',
};

export const initialResidueManagementErrorState = {
  agricultureResiduesManagedError: false,
  cropResidueRemovedError: false,
};

export const YesOrNoList = [
  { itemLabel: 'Yes', value: 'Yes' },
  { itemLabel: 'No', value: 'No' },
];

export const unitOfMeasureDropdownList = [
  { itemLabel: 'kg', value: 'kg' },
  { itemLabel: 'lbs', value: 'lbs' },
];
export const applicationPurpose = [
  { itemLabel: 'Energy', value: 'Energy' },
  { itemLabel: 'Non-energy', value: 'Non-energy' },
];

export const tillageTypeDropdownList = [
  { label: 'Conventional, inversion', value: 'Conventional, inversion' },
  { label: 'Conventional, vertical', value: 'Conventional, vertical' },
  { label: 'No tillage', value: 'No tillage' },
  { label: 'Reduced till, inversion', value: 'Reduced till, inversion' },
  { label: 'Reduced till, vertical', value: 'Reduced till, vertical' },
  { label: 'Strip till', value: 'Strip till' },
  { label: 'Other', value: 'Other' },
];

export const timesPerDropdownList = [
  { label: 'day', value: 'day' },
  { label: 'week', value: 'week' },
  { label: 'month', value: 'month' },
  { label: 'quarter', value: 'quarter' },
  { label: 'year', value: 'year' },
];

export const formFieldNames = {
  agricultureResiduesManaged: 'agricultureResiduesManaged',
};
