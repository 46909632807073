import { Button } from '@mui/material';
import {
  FormSection,
  MainWrapper,
  autoCompleteSx,
} from './ParticipantContributorsForm.style';
import {
  ADD_CONTRIBUTORS_INITIAL_STATE,
  FORM_CONTENT,
  INITIAL_FORM_STATE,
  ERROR_INITIAL_STATE,
  NEW_CONTRIBUTOR_STATUS_VALUES,
} from './ParticipantContributorsForm.content';
import AutoCompleteForm from 'components/FormComponents/AutoComplete/AutoComplete.react';
import { useForm } from 'hooks/useForm';
import { checkTernaryCondition } from 'utils/helper';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import { saveAndContinuebuttonStyle } from '../UserNavigationButtons/UserNavigationButtons.style';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import ParticipantContributorSubsection from './ParticipantContributorSubsection/ParticipantContributorSubsection.react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import FarmAssignmentDropdown from './FarmAssignmentDropdown/FarmAssignmentDropdown.react';
import UserNavigationButtons from '../UserNavigationButtons/UserNavigationButtons.react';
import {
  fetchContributorsData,
  fetchExistingContributors,
  fetchFarmsForContributor,
  getSubmitDataBody,
  submitContributorData,
  sendInvite,
  fetchAllParticipantContributors,
} from './ParticipantContributorsForm.funtions';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { OptionItemsStyle } from 'pages/OriginationParticipantsList/AddExistingParticipantModal/AddExistingParticipantModal.style';
import { OptionNoBoldStyle } from 'theme/GlobalStyles';
import Loader from 'components/Loader/Loader.react';
import { pathName } from 'Routes/routes.const';
import axios from 'axios';
import { FETCH_ACTIVATED_CONTRIBUTORS } from 'urls';

const ParticipantContributorsForm = () => {
  const { formValue, setFormValue } = useForm(INITIAL_FORM_STATE);
  const [addedContributors, setAddedContributors] = useState([]);
  const [exisitingContributorsList, setExisitingContributorsList] = useState(
    [],
  );
  const [farmList, setFarmList] = useState([]);
  const [searchParams] = useSearchParams();
  const internalProjectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const [errorList, setErrorList] = useState([]);
  const [isContributorListNotEmpty, setIsContributorListNotEmpty] =
    useState(null);
  const {
    setRightSectionId,
    farmInfo,
    setExpandedFarmId,
    setCurrentFarmId,
    setFarmAndContributorTab,
    setSubmitData,
  } = useContext(participantInfoContext);
  const [contributorAutoCompletevalue, setContributorAutoCompleteValue] =
    useState('');
  const [activatedContributorIds, setActivatedContributorIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addContributorSectionHandler = () => {
    const newContributorId = uniqueId();
    setErrorList((prev) => [
      ...prev,
      { id: newContributorId, ERROR_INITIAL_STATE },
    ]);
    setAddedContributors((prev) => [
      ...prev,
      { id: newContributorId, ...ADD_CONTRIBUTORS_INITIAL_STATE },
    ]);
  };

  const deleteContributorSectionHandler = (id) => {
    if (typeof id === 'number') {
      submitHandler(false, null, -1, id, false);
    } else {
      setAddedContributors((prev) => prev.filter((data) => data.id !== id));
    }
  };

  const updateContributorSectionHandler = (id, key, value) => {
    setAddedContributors((prev) =>
      prev.map((data) => {
        if (data.id === id) {
          return { ...data, [key]: value };
        }
        return data;
      }),
    );
  };

  const updateErrorList = (contributorId, key, value) => {
    setErrorList((prev) =>
      prev.map((data) => {
        if (data.id === contributorId) {
          return { ...data, [key]: value };
        }
        return data;
      }),
    );
  };

  const updateErrorStateInList = (contributorId, newErrorState) => {
    setErrorList((prev) =>
      prev.map((data) => {
        if (data.id === contributorId) {
          return { ...data, [key]: newErrorState };
        }
        return data;
      }),
    );
  };

  const fetchActivatedContributors = () => {
    const url = `${FETCH_ACTIVATED_CONTRIBUTORS}?instanceId=${enrollmentInstanceId}&participantId=${participantId}`;
    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setActivatedContributorIds(response.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (exisitingContributorsList.length > 0) {
      const participantContributors = Array.isArray(
        formValue.participantContributors,
      )
        ? formValue.participantContributors
        : [formValue.participantContributors];
      setSubmitData({
        currentPage: 'Farms and contributors',
        data: getSubmitDataBody({
          exisitingAssignedData: { ...formValue, participantContributors },
          newSubmitData: addedContributors,
          exisitingContributorsList: exisitingContributorsList,
          participantId,
          internalProjectId,
          sentInviteClickId: null,
          instanceId: enrollmentInstanceId,
          sentInviteClickIndex: -2,
        }),
        currentId: 0,
      });
    }
  }, [
    formValue,
    addedContributors,
    exisitingContributorsList,
    enrollmentInstanceId,
  ]);

  useEffect(() => {
    fetchFarmsForContributor({
      internalProjectId,
      participantId,
      enrollmentInstanceId,
    }).then((data) => setFarmList(data));
    fetchContributorsData({
      internalProjectId,
      participantOwnerId: participantId,
      setExisitingParticipantsList: setFormValue,
      setAddedContributors: setAddedContributors,
      setLoading: setLoading,
      setErrorList: setErrorList,
    });
    fetchExistingContributors({
      setterFunc: setExisitingContributorsList,
      ownerId: participantId,
      projectId: internalProjectId,
    });
    fetchAllParticipantContributors().then((response) => {
      setIsContributorListNotEmpty(response.data.length !== 0);
    });
    fetchActivatedContributors();
  }, []);

  const gotoMenuPage = () =>
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: internalProjectId,
          participantid: participantId,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: false } },
    );

  const thenBlock = (
    responseData,
    navigateToNext,
    deleteId,
    isCloseClicked,
    sentInviteClickIndex,
    appendContributor = false,
    setLastInvite = null,
  ) => {
    const newContributorData = responseData.filter((data) =>
      NEW_CONTRIBUTOR_STATUS_VALUES.includes(data.farmEnrollmentStatus),
    );
    if (appendContributor && newContributorData.length > 0) {
      const contributor = exisitingContributorsList.find(
        (item) => item.participantId === newContributorData[0].participantId,
      );
      if (contributor) {
        const primaryCommunications = ['Email', 'Phone', 'Text'];
        const contributorCommunications = contributor?.primaryCommunication
          .split(',')
          .map((method) => method.trim());
        const communicationMethod = primaryCommunications.map((method) => ({
          itemLabel: method,
          checked: contributorCommunications.includes(method),
        }));

        const newContributor = {
          assignedFarms: formValue?.assignedFarms,
          communicationMethod: communicationMethod,
          email: contributor.participantEmail,
          firstName: contributor.participantFirstName,
          id: contributor.participantId,
          lastName: contributor.participantLastName,
          phone: contributor.phone,
          status: contributor.status,
        };
        setAddedContributors((prev) => {
          const updatedContributors = [newContributor, ...prev]
            .filter((data) => data.id !== deleteId)
            .map((data, index) => ({
              ...data,
              id: newContributorData[index]?.participantId,
              status: newContributorData[index]?.farmEnrollmentStatus,
            }));
          return updatedContributors;
        });
      }
    }
    if (!appendContributor) {
      const updatedContributorsData = checkTernaryCondition(
        newContributorData.length > 0,
        addedContributors
          .filter((data) => data.id !== deleteId)
          .map((data, index) => ({
            ...data,
            id: newContributorData[index]?.participantId,
            status: newContributorData[index]?.farmEnrollmentStatus,
          })),
        [],
      );
      setAddedContributors(updatedContributorsData);
    }
    setErrorList((prev) =>
      prev.map((data, index) => ({
        ...data,
        id: newContributorData[index]?.participantId,
      })),
    );
    if (
      sentInviteClickIndex != -1 &&
      sentInviteClickIndex !== -2 &&
      responseData.length > 0
    ) {
      sendInvite({
        enrollmentInstanceId,
        contributorId: responseData[sentInviteClickIndex]?.participantId,
        setLastInvite: setLastInvite,
      });
    }
    if (navigateToNext) {
      setRightSectionId(2);
      setExpandedFarmId(farmInfo[0].id);
      setCurrentFarmId(farmInfo[0].id);
      setFarmAndContributorTab(0);
    }
    if (isCloseClicked) {
      gotoMenuPage();
    }
  };

  const submitHandler = (
    navigateToNext,
    sentInviteClickId,
    sentInviteClickIndex = -1,
    deleteId = 0,
    isCloseClicked = false,
    appendContributor = false,
    setLastInvite = null,
  ) => {
    submitContributorData({
      exisitingAssignedData: formValue,
      newSubmitData: addedContributors.filter((data) => data.id !== deleteId),
      exisitingContributorsList: exisitingContributorsList,
      participantId,
      internalProjectId,
      setRightSectionId,
      farmInfo,
      setExpandedFarmId,
      setCurrentFarmId,
      navigateToNext: navigateToNext,
      thenBlock: thenBlock,
      sentInviteClickId: sentInviteClickId,
      deleteId: deleteId,
      setLoading: setLoading,
      isCloseClicked: isCloseClicked,
      instanceId: enrollmentInstanceId,
      sentInviteClickIndex: sentInviteClickIndex,
      appendContributor: appendContributor,
      setLastInvite: setLastInvite,
      projectCycleId: projectCycleId,
    });
  };

  const sendInviteClickHandler = () => {
    let contributorId;
    const contributor = exisitingContributorsList.find(
      (item) => item.label === formValue.participantContributors[0],
    );
    if (contributor) {
      contributorId = contributor.participantId;
    }
    sendInvite({
      enrollmentInstanceId,
      contributorId,
    });
    setFormValue(INITIAL_FORM_STATE);
  };

  /**
   * Disable send invite button if the contributor is not already activated, we check this by comparing the contributor id with the activatedContributorIds and disable the button if the activated contributorIds array doesn't include the contributor id.
   */
  const disableExistingContributorSendInvite = useMemo(() => {
    if (formValue.participantContributors.length === 0) return true;

    const contributor = exisitingContributorsList.find(
      (item) => item.label === formValue.participantContributors[0],
    );
    if (contributor) {
      return activatedContributorIds.includes(contributor.participantId);
    }
    return false;
  }, [formValue.participantContributors, exisitingContributorsList]);

  return (
    <>
      <Loader loading={loading} zIndex={99999} />
      <FormSection>
        <MainWrapper>
          {isContributorListNotEmpty && (
            <>
              <AutoCompleteForm
                id="participantContributors"
                hasNoBottomMargin={true}
                isOptional={true}
                label={FORM_CONTENT.add_participant_contributors_label}
                instructionText={
                  FORM_CONTENT.add_participant_contributors_instruction_text
                }
                value={formValue.participantContributors}
                valueSetter={(value) => {
                  setContributorAutoCompleteValue((prev) => '');
                  setFormValue((prev) => ({
                    ...prev,
                    participantContributors: checkTernaryCondition(
                      value,
                      [value],
                      [],
                    ),
                  }));
                }}
                list={exisitingContributorsList.map((data) => data.label)}
                primaryError={false}
                placeholder={
                  formValue.participantContributors?.length === 0 &&
                  FORM_CONTENT.add_participant_contributors_placeholder
                }
                helperText={''}
                formValue={''}
                onClickHandler={() => {}}
                customSx={autoCompleteSx(
                  formValue.participantContributors?.length > 0,
                )}
                marginTop="0rem"
                labelGap="0.5rem"
                marginBottom="0rem"
                hasNewStyles={true}
                onBlurHandler={(e) => {
                  setContributorAutoCompleteValue((prev) => '');
                }}
                onInputChangeHandler={(e) => {
                  setContributorAutoCompleteValue(e?.target?.value);
                }}
                isMultiple={false}
                disableCloseOnSelect={false}
                customOptionFormatter={(props, option) => {
                  if (
                    contributorAutoCompletevalue &&
                    !contributorAutoCompletevalue.trim()
                  )
                    return (
                      <li {...props} key={option.participantId}>
                        {option}
                      </li>
                    );
                  const text = option;
                  const index = text
                    .toLowerCase()
                    .indexOf(contributorAutoCompletevalue?.toLowerCase());
                  const beforeStr = text.slice(0, index);
                  const boldStr = text.slice(
                    index,
                    index + contributorAutoCompletevalue?.length,
                  );
                  const afterStr = text.slice(
                    index + contributorAutoCompletevalue?.length,
                  );
                  return (
                    <li {...props} key={option.participantId}>
                      <span style={OptionItemsStyle}>{beforeStr}</span>
                      <span style={OptionNoBoldStyle}>{boldStr}</span>
                      <span style={OptionItemsStyle}>{afterStr}</span>
                    </li>
                  );
                }}
              />
              <FarmAssignmentDropdown
                value={formValue.assignedFarms}
                onUpdateHandler={(value) => {
                  setFormValue((prev) => ({
                    ...prev,
                    assignedFarms: value,
                    assignedFarmsError: value.length === 0,
                  }));
                }}
                dropdownList={farmList}
                deleteHandler={(value) => {
                  const updatedAssignedFarms = formValue.assignedFarms.filter(
                    (item) => item !== value,
                  );
                  setFormValue((prev) => ({
                    ...prev,
                    assignedFarms: updatedAssignedFarms,
                    assignedFarmsError: updatedAssignedFarms.length === 0,
                  }));
                }}
                currentInstanceId={Number(enrollmentInstanceId)}
                error={formValue.assignedFarmsError}
                errorMessage={FORM_CONTENT.farm_assignment_required_error}
              />
              <Button
                variant="contained"
                size="medium"
                sx={[saveAndContinuebuttonStyle, primaryButtonStyle()]}
                color="success"
                onClick={() => {
                  submitHandler(false, null, -1, null, false, true);
                  sendInviteClickHandler();
                }}
                data-testid="send-invite-button"
                disabled={
                  (formValue.assignedFarms.length === 0 ||
                    formValue.participantContributors?.length === 0) &&
                  !disableExistingContributorSendInvite
                }
                disableElevation>
                {FORM_CONTENT.send_invite_btn_label}
              </Button>
            </>
          )}
          {addedContributors.map((contributor, index) => {
            const existingContributor = exisitingContributorsList.find(
              (item) => item.participantId === contributor.id,
            );
            const lastInviteSent = existingContributor?.lastInviteSent;
            let formattedLastInviteSent = null;
            if (lastInviteSent) {
              const date = new Date(lastInviteSent);
              const day = String(date.getDate());
              const month = String(date.getMonth() + 1);
              const year = date.getFullYear();
              formattedLastInviteSent = `${month}/${day}/${year}`;
            }
            return (
              <ParticipantContributorSubsection
                updateErrorList={updateErrorList}
                contributor={contributor}
                error={errorList.find((data) => data.id === contributor.id)}
                key={index}
                index={index}
                deleteHandler={deleteContributorSectionHandler}
                updateHandler={updateContributorSectionHandler}
                sendInviteClickHandler={(setLastInvite) => {
                  submitHandler(
                    false,
                    contributor?.id,
                    index,
                    0,
                    false,
                    false,
                    setLastInvite,
                  ); // false is for not navigating to next section
                }}
                label={`${
                  FORM_CONTENT.participant_contributor_section_heading
                } ${checkTernaryCondition(
                  addedContributors.length > 1,
                  index + 1,
                  '',
                )}`}
                farmsList={farmList}
                lastInviteSent={formattedLastInviteSent}
                activatedContributorIds={activatedContributorIds}
              />
            );
          })}
          {isContributorListNotEmpty !== null && (
            <AddSectionButton
              label={FORM_CONTENT.add_participant_contributors_btn_label}
              onClickHanlder={addContributorSectionHandler}
              marginTop={
                isContributorListNotEmpty || addedContributors.length !== 0
                  ? '1rem'
                  : '0rem'
              }
              hasBorder={true}
              width="fit-content"
            />
          )}
          <UserNavigationButtons
            saveAndContinueHandler={() => {
              submitHandler(true, null);
            }}
            saveAndCloseHandler={() => {
              submitHandler(false, null, -2, null, true);
            }}
            CancelHandler={gotoMenuPage}
          />
        </MainWrapper>
      </FormSection>
    </>
  );
};
export default ParticipantContributorsForm;
