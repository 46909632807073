import { PROJECT_SCHEDULE_CONTENT } from './ProjectSchedule.content';
import {
  Heading,
  IconWrapper,
  InfoContent,
  InfoWrapper,
  ProjectScheduleWrapper,
  SubHeading,
} from './ProjectSchedule.style';
import { useEffect, useState } from 'react';
import ProjectCycleTable from '../ProjectCycleTable/ProjectCycleTable.react';
import axios from 'axios';
import { FETCH_PROJECT_LIFE_CYCLE } from 'urls';
import { useParams } from 'react-router-dom';
import { constructCycleData } from './ProjectSchedule.functions';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/infoIcon.svg';

const ProjectSchedule = () => {
  const [cycleData, setCycleData] = useState([]);
  const { id } = useParams();
  const [durationData, setDurationData] = useState({
    noOfCycles: 0,
    period: '__________',
    startMonth: '__________',
  });

  const fetchProjectLifeCycle = () => {
    axios.get(`${FETCH_PROJECT_LIFE_CYCLE}/${id}`).then((response) => {
      if (response.data) {
        setCycleData(constructCycleData(response.data));
        const getStartDate = new Date(
          response.data.projectStartYear + 1,
          response.data.cycleStartMonth - 1,
        );
        const getMonth = getStartDate?.toLocaleString('en-US', {
          month: 'long',
        });
        const getYear = getStartDate?.getFullYear();
        setDurationData({
          noOfCycles: response.data.totalProjectCycles,
          period: 'annually',
          startMonth: `${getMonth} ${response.data.projectStartYear}`,
        });
      }
    });
  };

  useEffect(() => {
    fetchProjectLifeCycle();
  }, []);
  return (
    <ProjectScheduleWrapper>
      <Heading>{PROJECT_SCHEDULE_CONTENT.heading}</Heading>
      <SubHeading>
        {PROJECT_SCHEDULE_CONTENT.subHeading(durationData)}
      </SubHeading>
      <ProjectCycleTable rowData={cycleData} />
      <InfoWrapper>
        <IconWrapper>
          <InfoIcon />
        </IconWrapper>
        <InfoContent>{PROJECT_SCHEDULE_CONTENT.info_content}</InfoContent>
      </InfoWrapper>
    </ProjectScheduleWrapper>
  );
};

export default ProjectSchedule;
