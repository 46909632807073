import {
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  GRAYISH_BLUE,
  GREEN,
  NEUTRAL_BACKGROUND_COLOR,
} from 'theme/GlobalColors';

import styled from 'styled-components';
import { checkTernaryCondition } from 'utils/helper';

export const RadioButtonStyle = (marginRight) => {
  return {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: DARK_CHARCOAL,
      marginRight: checkTernaryCondition(marginRight, marginRight, '2.625rem'),
    },
  };
};

export const RadioIconStyle = (isDarkGreen, radioBtnPadding) => {
  return {
    padding: radioBtnPadding,
    color: GRAYISH_BLUE,
    '&:hover': {
      color: DARK_CHARCOAL,
      backgroundColor: NEUTRAL_BACKGROUND_COLOR,
    },
    '&.Mui-checked': {
      color: checkTernaryCondition(isDarkGreen, NEW_DARK_GREEN, GREEN),
    },
  };
};

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ radioBtnGap }) => radioBtnGap};
`;
export const LabelWrapper = styled.div`
  display: inline-block;
  color: ${({ color }) => color};
`;
