import {
  MainWrapper,
  FormSection,
  FormHeading,
  FormFields,
  FormLabels,
  RadioButtonlabel,
  radioBtnSx,
  DropDownPlaceholder,
  OptionalLabel,
  InputSubtext,
  ButtonWrapper,
  ErroWrapper,
  inputNumberSx,
  TextArea,
  ProjectsLabel,
} from './AddPurchaseTransaction.style';
import {
  ConditionConstants,
  convertToDate,
  PAGE_CONTENT,
  validateProjectNames,
  validateVariableCost,
  validateVintageYears,
  getValueOrDefault,
  validateDeliveryDetails,
} from './AddPurchaseTransaction.content';
import {
  Input,
  Radio,
  Select,
  MenuItem,
  OutlinedInput,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ProjectSubsection from 'pages/AddPurchaseTransaction/components/ProjectSubsection/ProjectSubsection.react';
import { ReactComponent as Calendericon } from '../../assets/icons/calenderIcon.svg';
import { useForm } from 'hooks/useForm';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import DialogBox from 'components/DialogBox/DialogBox.react';
import BackButton from 'components/Backbutton/BackButton.react';
import { useLocation, useNavigate, useParams } from 'react-router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import axios from 'axios';
import { DELETE_TRANSACTION, TRANSACTION_PURCHASE_DETAILS } from 'urls';
import {
  checkTernaryCondition,
  classNameHandler,
  scroll,
  setErrorStateHandler,
  ternaryBooleanHandler,
} from 'utils/helper';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import DeleteModal from 'components/DeleteModal/DeleteModal.react';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { CLIENT_ID } from 'utils/config';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import { usDateFormatter } from '../../utils/dateFormatter';
import { pathName } from 'Routes/routes.const';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const paymentTypes = [{ label: 'payment 1', value: 'payment 1' }];

const vintageObj = {
  id: '',
  creditBatchId: 0,
  quantity: '',
  price: '',
  vintageStart: '',
  vintageEnd: '',
  quantityError: false,
  priceError: false,
  vintageStartError: false,
  vintageEndError: false,
  creditDemandDimFlag: false,
  vintageYearError: false,
  vintageDuplicateError: false,
  purchaseDeliveryRequestDTOS: [],
  deliveryDetailsError: false,
  decreaseQuantityError: false,
  saleContractNames: [],
  isApiPopulated: false,
};

const paymentObj = {
  id: '',
  variableCost: '',
  rofrStatus: '',
  contractProjectMapId: 0,
  variableCostError: false,
  variableCostDuplicateError: false,
  rofrError: false,
  vintageDetails: [],
  saleContractNames: [],
  isApiPopulated: false,
};

const projectObj = {
  id: '',
  projectName: '',
  paymentDetails: [],
  projectNameError: false,
  detailsError: false,
  saleContractNames: [],
  isApiPopulated: false,
};

const formModal = {
  transactionName: '',
  signedDate: null,
  transactionCatagory: '',
  stakeholder: null,
  transactionStatus: '',
  assumptionType: '',
  sharePercentage: '',
  costStructure: '',
  transactionCost: '',
  associatedType: '',
  notes: '',
};

const AddPurchaseTransaction = () => {
  const [projectInfo, setProjectInfo] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [catagoryError, setCatagoryError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [stakeHolderError, setStakeHolderError] = useState(false);
  const [costStructureError, setCostStructureError] = useState(false);
  const [sharePercentageError, setSharPercentageError] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const { state } = useLocation(); // for previous pathname
  const navigate = useNavigate();
  const [signedOnError, setSignedOnError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editErrorMsg, setEditErrorMsg] = useState({
    flag: false,
    message: '',
  });
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [dialogTitle, setDialogTitle] = useState();
  const [deleteTransactionError, setDeleteTransactionError] = useState({
    flag: false,
    message: '',
    title: '',
  });
  const [deleteTransactionImpactedList, setDeleteTransactionImpactedList] =
    useState([]);
  const [onSubmit, setOnSubmit] = useState(true);
  const location = useLocation();
  const [viewMode, setViewMode] = useState(false);
  const [isDeletedRecord, setIsDeleted] = useState();
  const [lastUpdatedOn, setLastUpdatedon] = useState();

  const [submitTransactionState, setSubmitTransactionState] = useState(false);
  const { dirty, markFormDirty } = useContext(
    EditPurchaseTransactionFormDirtyContext,
  );
  const {
    categoryOfPurchase,
    stakeholderList,
    shareAssumptionTypes,
    costStructure,
    associatedTypes,
    submitLoading,
    samenameError,
    setSameNameError,
    fetchCategory,
    fetchStakeholderNames,
    fetchShareAssumptionTypes,
    fetchAssociatedTYpes,
    fetchCostStructure,
    submitTransaction,
    editTransaction,
  } = useTransactionDetails();

  //Add minimum one project on load of the page
  useEffect(() => {
    if (id) {
      if (location.pathname.includes('edit')) {
        fetchEditTransactionDetails(id);
        setViewMode(false);
        setEditMode(true);
      } else {
        fetchEditTransactionDetails(id);
        setEditMode(false);
        setViewMode(true);
      }
    } else {
      setViewMode(false);
      setEditMode(false);
      if (projectInfo.length === 0) {
        setProjectInfo((prev) => [
          ...prev,
          {
            ...projectObj,
            id: uniqueId(),
            paymentDetails: [
              {
                ...paymentObj,
                id: uniqueId(),
                vintageDetails: [{ ...vintageObj, id: uniqueId() }],
              },
            ],
          },
        ]);
      }
    }
    //To fetch dropdown values
    fetchShareAssumptionTypes();
    fetchCategory();
    fetchCostStructure();
    fetchAssociatedTYpes();
  }, [editMode, viewMode]);

  //on submit scroll to error field
  useEffect(() => {
    if (onSubmit || !onSubmit || samenameError.flag) scroll('.Mui-error');
  }, [onSubmit, samenameError.flag]);

  useEffect(() => {
    if (submitTransactionState) {
      confirmTransactionSubmit();
    }
  }, [submitData, submitTransactionState]);

  const addProjectHandler = () => {
    setProjectInfo((prev) => [
      ...prev,
      {
        ...projectObj,
        id: uniqueId(),
        paymentDetails: [
          ...projectObj.paymentDetails,
          {
            ...paymentObj,
            id: uniqueId(),
            vintageDetails: [{ ...vintageObj, id: uniqueId() }],
          },
        ],
      },
    ]);
    markFormDirty();
  };

  const transactionStatusHandler = (e) => {
    formFieldChangeHandler(e);
    e.target.value !== '' && setStatusError(false);
  };

  const updateProjectInfo = (projectObj) => {
    setProjectInfo((current) => {
      return current.map((project) => {
        return project.id === projectObj.id ? projectObj : project;
      });
    });
  };

  const transactionStatusRadioProps = (item) => ({
    checked: transactionForm.transactionStatus === item,
    onChange: transactionStatusHandler,
    value: item,
    name: 'transactionStatus',
    inputProps: { 'aria-label': item },
  });

  const deleteProjectInfo = (projectId) => {
    setProjectInfo((current) => {
      return current.filter((project) => {
        return project.id !== projectId;
      });
    });
  };

  const {
    formValue: transactionForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);

  const fetchEditTransactionDetails = async (transactionId) => {
    setEditLoading(true);
    let projectDetails = [];
    let editTransactionDetails = {};
    await axios
      .get(`${TRANSACTION_PURCHASE_DETAILS}/${transactionId}`)
      .then((response) => {
        editTransactionDetails = {
          transactionName: getValueOrDefault(response.data.transactionName),
          signedDate: convertToDate(
            getValueOrDefault(response.data.transactionSignedOn),
          ),
          transactionCatagory: getValueOrDefault(
            response.data.categoryOfPurchase,
          ),
          stakeholder: {
            label: getValueOrDefault(response.data.purchasedFrom),
            value: getValueOrDefault(response.data.purchasedFrom),
            vendorId: response.data.purchasedFromVendorId,
          },
          transactionStatus: getValueOrDefault(response.data.purchaseStatus),
          assumptionType: getValueOrDefault(
            response.data.benefitShareAssumptionType === 'None'
              ? ''
              : response.data.benefitShareAssumptionType,
          ),
          sharePercentage: getValueOrDefault(
            response.data.benefitSharePercentage,
          ).toString(),
          costStructure: getValueOrDefault(response.data.fixedCostStructure),
          transactionCost: getValueOrDefault(
            response.data.totalFixedContractCost,
          ).toString(),
          associatedType:
            response.data.investorOrFinancerVendorId === -1
              ? ''
              : response.data.investorOrFinancerVendorId,
          notes: getValueOrDefault(response.data.notes),
        };
        projectDetails = response.data.contractProjectDTOS.map((project) => ({
          id: project.internalProjectId,
          projectName: {
            internalProjectId: project.internalProjectId,
            value: project.projectName,
            label: project.projectName,
          },
          paymentDetails: project.variableCostDTOS.map((variableCost) => ({
            id: uniqueId(),
            contractProjectMapId: variableCost.contractProjectMapId,
            variableCost: variableCost.variableCostStructure,
            rofrStatus: variableCost.rofrStatus,
            variableCostError: false,
            variableCostDuplicateError: false,
            rofrError: false,
            saleContractNames: variableCost.saleContractNames,
            isApiPopulated: true,
            vintageDetails: variableCost.vintageDTOS.map((vintage) => ({
              id: vintage.creditBatchId,
              creditBatchId: vintage.creditBatchId,
              quantity: vintage.quantityMTCO2e.toString(),
              price: vintage.pricePerMTCO2e.toString(),
              vintageStart: parseInt(vintage.vintageStart),
              vintageEnd: parseInt(vintage.vintageEnd),
              quantityError: false,
              priceError: false,
              vintageStartError: false,
              vintageEndError: false,
              vintageYearError: false,
              vintageDuplicateError: false,
              depositsError: false,
              creditDemandDimFlag: vintage.creditDemandDimFlag,
              purchaseDeliveryRequestDTOS:
                vintage.purchaseDeliveryRequestDTOS.map((delivery) => ({
                  ...delivery,
                  isApiPopulated: true,
                })),
              deliveryDetailsError: false,
              decreaseQuantityError: false,
              saleContractNames: vintage.saleContractNames,
              isApiPopulated: true,
            })),
          })),
          saleContractNames: project.saleContractNames,
          projectNameError: false,
          detailsError: false,
          isApiPopulated: true,
        }));
        setLastUpdatedon(
          response.data.updatedDate ? response.data.updatedDate : null,
        );
        setIsDeleted(response.data.isActive == -1 ? true : false);
      })
      .catch((error) => {
        if (error) {
          setEditErrorMsg({
            flag: true,
            message: 'No Transaction data to display',
          });
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
    setFormValue({ ...editTransactionDetails });
    setProjectInfo([...projectDetails]);
  };

  const checkProjectSubsection = (condition, validateFlag, validateObject) => {
    let temp_validate = [];
    if (condition) {
      temp_validate = validateFlag.push({ id: validateObject.id });
    } else {
      temp_validate = validateFlag.filter(
        (error) => error.id !== validateObject.id,
      );
    }
    return temp_validate;
  };

  const validateProjectInfo = () => {
    let validateProjectFlag = [];
    let validatePaymentFlag = [];
    let validateVintageFlag = [];
    if (projectInfo.length > 0) {
      projectInfo.forEach((project) => {
        validateProjectFlag = checkProjectSubsection(
          project.projectName === '' ||
            validateProjectNames(
              projectInfo.map((projectDetail) => ({
                ...projectDetail.projectName,
                id: projectDetail.id,
              })),
              { ...project.projectName, id: project.id },
            ),
          validateProjectFlag,
          project,
        );
        project.paymentDetails.forEach((payment) => {
          validatePaymentFlag = checkProjectSubsection(
            payment.variableCost === '' ||
              validateVariableCost(project, payment),
            validatePaymentFlag,
            payment,
          );
          payment.vintageDetails.forEach((vintage) => {
            if (
              !vintage.quantity.trim() ||
              !vintage.price.trim() ||
              vintage.vintageStart === '' ||
              vintage.vintageEnd === '' ||
              vintage.vintageYearError ||
              vintage.decreaseQuantityError ||
              validateVintageYears(payment, vintage) ||
              validateDeliveryDetails(
                vintage.quantity,
                vintage.purchaseDeliveryRequestDTOS,
              )
            ) {
              validateVintageFlag.push({ id: vintage.id });
            } else {
              validateVintageFlag = validateVintageFlag.filter(
                (error) => error.id !== vintage.id,
              );
            }
          });
        });
      });
    } else {
      validateProjectFlag = [];
      validatePaymentFlag = [];
      validateVintageFlag = [];
    }
    return (
      validateProjectFlag.length === 0 &&
      validatePaymentFlag.length === 0 &&
      validateVintageFlag.length === 0
    );
  };

  const validateProjectRofr = () => {
    let validateRofr = [];
    projectInfo.forEach((project) => {
      // in case of Variable cost is ROFR rofr status field is required
      project.paymentDetails.forEach((payment) => {
        if (
          payment.variableCost === ConditionConstants.ROFR &&
          payment.rofrStatus !== ''
        ) {
          validateRofr = validateRofr.filter(
            (error) => error.id !== payment.id,
          );
        } else if (
          payment.variableCost !== ConditionConstants.ROFR &&
          payment.rofrStatus === ''
        ) {
          if (payment.variableCost !== '') {
            validateRofr = validateRofr.filter(
              (error) => error.id !== payment.id,
            );
          }
        } else {
          validateRofr.push({ id: payment.id });
        }
      });
    });

    return validateRofr.length === 0;
  };

  const validateSharepercentage = () => {
    if (transactionForm.assumptionType !== '') {
      return !!transactionForm.sharePercentage.trim();
    } else {
      return true;
    }
  };

  const investorHandler = (stakeholderType) => {
    return stakeholderType === '' ? -1 : stakeholderType;
  };

  const submitHandler = () => {
    setOnSubmit(!onSubmit);
    setErrorStateHandler(!transactionForm.transactionName.trim(), setNameError);
    setErrorStateHandler(
      transactionForm.transactionCatagory === '',
      setCatagoryError,
    );
    setErrorStateHandler(
      transactionForm.transactionStatus === '',
      setStatusError,
    );
    setErrorStateHandler(
      transactionForm.stakeholder === null,
      setStakeHolderError,
    );
    setErrorStateHandler(
      transactionForm.costStructure === '',
      setCostStructureError,
    );
    setErrorStateHandler(
      !transactionForm.sharePercentage.trim() &&
        transactionForm.assumptionType !== '',
      setSharPercentageError,
    );
    setErrorStateHandler(transactionForm.signedDate === null, setSignedOnError);

    projectInfo.forEach((project) => {
      updateProjectInfo({
        ...project,
        projectNameError: ternaryBooleanHandler(project.projectName === ''),
        detailsError: validateProjectNames(
          projectInfo.map((projectDetail) => ({
            ...projectDetail.projectName,
            id: projectDetail.id,
          })),
          { ...project.projectName, id: project.id },
        ),
        paymentDetails: project.paymentDetails.map((payment) => ({
          ...payment,
          variableCostError: ternaryBooleanHandler(payment.variableCost === ''),
          variableCostDuplicateError: validateVariableCost(project, payment),
          rofrError: ternaryBooleanHandler(
            payment.variableCost === ConditionConstants.ROFR &&
              payment.rofrStatus === '',
          ),
          vintageDetails: payment.vintageDetails.map((vintage) => ({
            ...vintage,
            vintageStartError: ternaryBooleanHandler(
              vintage.vintageStart === '',
            ),
            vintageEndError: ternaryBooleanHandler(vintage.vintageEnd === ''),
            quantityError: ternaryBooleanHandler(!vintage.quantity.trim()),
            priceError: ternaryBooleanHandler(!vintage.price.trim()),
            vintageDuplicateError: validateVintageYears(payment, vintage),
            deliveryDetailsError: validateDeliveryDetails(
              vintage.quantity,
              vintage.purchaseDeliveryRequestDTOS,
            ),
          })),
        })),
      });
    });
    if (
      transactionForm.transactionName.trim() &&
      transactionForm.catagory !== '' &&
      transactionForm.transactionCatagory !== '' &&
      transactionForm.transactionStatus !== '' &&
      transactionForm.stakeholder !== null &&
      transactionForm.costStructure !== '' &&
      validateSharepercentage() &&
      validateProjectInfo() &&
      validateProjectRofr() &&
      transactionForm.signedDate !== null
    ) {
      const temp_submit = {
        clientId: CLIENT_ID,
        createdBy: 'User',
        transactionName: transactionForm.transactionName,
        transactionSignedOn: moment(transactionForm.signedDate).format(
          'YYYY-MM-DD',
        ),
        categoryOfPurchase: transactionForm.transactionCatagory,
        purchaseStatus: transactionForm.transactionStatus,
        purchasedFromVendorId: transactionForm.stakeholder.vendorId,
        totalFixedTransactionCost: parseFloat(transactionForm.transactionCost),
        benefitShareAssumptionType: transactionForm.assumptionType,
        benefitSharePercentage: parseFloat(transactionForm.sharePercentage),
        fixedCostStructure: transactionForm.costStructure,
        contractProjectDTOS: projectInfo.map((project) => ({
          internalProjectId: project.projectName.internalProjectId,
          variableCostDTOS: [
            ...project.paymentDetails.map((payment) => ({
              variableCostStructure: payment.variableCost,
              contractProjectMapId: payment.contractProjectMapId,
              rofrStatus: payment.rofrStatus,
              vintageDTOS: [
                ...payment.vintageDetails.map((vintage) => ({
                  creditBatchId: vintage.creditBatchId,
                  vintageStart: vintage.vintageStart,
                  vintageEnd: vintage.vintageEnd,
                  quantityMTCO2e: parseFloat(vintage.quantity),
                  pricePerMTCO2e: parseFloat(vintage.price),
                  purchaseDeliveryRequestDTOS:
                    vintage.purchaseDeliveryRequestDTOS,
                })),
              ],
            })),
          ],
        })),
        investorOrFinancerVendorId: investorHandler(
          transactionForm.associatedType,
        ),
        notes: transactionForm.notes,
      };
      setSubmitData({ ...temp_submit });
      setSubmitTransactionState(true);
    }
  };

  const confirmTransactionSubmit = () => {
    if (submitData) {
      editMode
        ? editTransaction(submitData, id)
        : submitTransaction(submitData);
    }
  };

  const transactionHeading = () => {
    if (editMode) {
      return PAGE_CONTENT.edit_transaction_heading;
    } else if (viewMode) {
      return PAGE_CONTENT.view_transaction_heading;
    } else {
      return PAGE_CONTENT.transaction_purchase_heading;
    }
  };

  const deleteTransactionErrorHandler = () => {
    setDeleteTransactionError({ flag: false, message: '', title: '' });
    setDeleteTransactionImpactedList([]);
  };

  const deleteTransaction = () => {
    setIsDialogOpen(false);
    setDeleteLoading(true);
    axios
      .delete(DELETE_TRANSACTION, {
        data: {
          contractId: id,
          type: PAGE_CONTENT.view_transaction_heading.slice(0, 8),
        },
      })
      .then((response) => {
        if (response?.data?.status === PAGE_CONTENT.success) {
          toast(
            <CustomSnackbar
              type="success"
              message={`${transactionForm?.transactionName} ${PAGE_CONTENT.success_message}`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          navigate(pathName.assets.transactions, { state: 'transactionform' });
        } else if (response?.data?.status === PAGE_CONTENT.denied) {
          setDeleteLoading(false);
          const msg = response?.data?.message.split(',');
          const [title, message] = msg;
          setDeleteTransactionError({
            flag: true,
            message: message,
            title: title,
          });
          setDeleteTransactionImpactedList(response?.data?.transactionNames);
        } else {
          setErrorIsDialogOpen(true);
          setDialogSubtitle(
            `${transactionForm?.transactionName} ${PAGE_CONTENT.failed_message}`,
          );
          setDialogTitle(
            `${transactionForm?.transactionName} couldn't be deleted`,
          );
        }
      })
      .catch(() => {
        setDeleteLoading(false);
        setErrorIsDialogOpen(true);
        setDialogSubtitle(
          `${transactionForm?.transactionName} ${PAGE_CONTENT.failed_message}`,
        );
        setDialogTitle(
          `${transactionForm?.transactionName} couldn't be deleted`,
        );
      });
  };

  const handleEditClick = () => {
    setEditMode(true);
    navigate(`${pathName.transaction.purchase.edit}/${id}`, {
      state: { previousPath: location.pathname },
    });
  };

  const onErrorDialogBoxClose = () => {
    setErrorIsDialogOpen(false);
  };

  return (
    <>
      <Backdrop
        open={submitLoading || editLoading || deleteLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="success" />
      </Backdrop>
      {
        <BackButton
          previouspagename={'transactions'}
          previouspath={state?.previousPath ?? pathName.assets.transactions}
          marginLeft="1.6875rem"
          previousstate={{ state: 'transactionform' }}
          isEdited={dirty}
        />
      }

      {editErrorMsg.flag ? (
        editErrorMsg.message
      ) : (
        <MainWrapper>
          <FormSection onChange={markFormDirty}>
            <FormHeading>
              {transactionHeading()}
              {isDeletedRecord ? (
                <div className="deletedStatus">{` Deleted •
                  ${checkTernaryCondition(
                    lastUpdatedOn !== null,
                    usDateFormatter(lastUpdatedOn),
                    '',
                  )}`}</div>
              ) : (
                viewMode && (
                  <DeleteModal
                    targetName={transactionForm?.transactionName}
                    deleteTarget={deleteTransaction}
                    dialogSubtitle={dialogSubtitle}
                    transactionList={[]}
                    dialogTitle={dialogTitle}
                    deletionType={'transaction'}
                    isErrorDialogOpen={isErrorDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    isDialogOpen={isDialogOpen}
                    navigateRoute={handleEditClick}
                    onErrorDialogBoxClose={onErrorDialogBoxClose}
                  />
                )
              )}
            </FormHeading>
            <FormFields>
              <FormLabels iserror={nameError || samenameError.flag}>
                {PAGE_CONTENT.transaction_name_label}
              </FormLabels>
              <Input
                value={transactionForm.transactionName}
                disabled={viewMode}
                name="transactionName"
                placeholder={PAGE_CONTENT.transaction_name_placeholder}
                inputProps={{ 'aria-label': 'Transaction name' }}
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setNameError(false);
                  e.target.value !== '' &&
                    setSameNameError({ flag: false, message: '' });
                }}
                error={nameError || samenameError.flag}
                fullWidth
              />
              {nameError && (
                <ErroWrapper>{PAGE_CONTENT.transaction_name_error}</ErroWrapper>
              )}
              {samenameError.flag && (
                <ErroWrapper>{samenameError.message}</ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={signedOnError}>
                {PAGE_CONTENT.date_signed_label}
              </FormLabels>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={transactionForm.signedDate}
                  disabled={viewMode}
                  components={{
                    OpenPickerIcon: Calendericon,
                  }}
                  inputFormat="MM/DD/YYYY"
                  onChange={(newValue) => {
                    customFieldChangeHandler('signedDate', newValue.toDate());
                    newValue !== null && setSignedOnError(false);
                    markFormDirty();
                  }}
                  InputAdornmentProps={{
                    sx: {
                      '& .MuiIconButton-root': {
                        paddingRight: '1rem',
                      },
                    },
                  }}
                  open={datepickerOpen}
                  onClose={() => setDatepickerOpen(false)}
                  onOpen={() => setDatepickerOpen(true)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      error={signedOnError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select Date',
                      }}
                      sx={{
                        width: '9.375rem',
                      }}
                      onClick={() => setDatepickerOpen(true)}
                    />
                  )}
                />
              </LocalizationProvider>
              {signedOnError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_signed_error}
                </ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={catagoryError}>
                {PAGE_CONTENT.transaction_purchase_category_label}
              </FormLabels>
              <Select
                value={transactionForm.transactionCatagory}
                disabled={viewMode}
                sx={{ width: '14rem', height: '2rem' }}
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setCatagoryError(false);
                  markFormDirty();
                }}
                displayEmpty
                name="transactionCatagory"
                inputProps={{ 'aria-label': 'transactionCatagory' }}
                error={catagoryError}
                input={<OutlinedInput />}
                renderValue={
                  transactionForm.transactionCatagory !== '' || null
                    ? undefined
                    : () => (
                        <DropDownPlaceholder>
                          {
                            PAGE_CONTENT.transaction_purchase_category_placeholder
                          }
                        </DropDownPlaceholder>
                      )
                }>
                {categoryOfPurchase.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    sx={getMenuItemStyles}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {catagoryError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_category_error}
                </ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels iserror={stakeHolderError}>
                {PAGE_CONTENT.purchased_from_label}
              </FormLabels>
              <Autocomplete
                disabled={viewMode}
                options={stakeholderList}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(e, newValue) => {
                  customFieldChangeHandler('stakeholder', newValue);
                  setStakeHolderError(false);
                  markFormDirty();
                }}
                value={transactionForm.stakeholder}
                filterSelectedOptions
                sx={{ '& ..MuiAutocomplete-tag': { border: 'green' } }}
                onInputChange={(e, newValue) => {
                  fetchStakeholderNames(newValue, 'Purchase');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={stakeHolderError}
                    inputProps={{
                      style: { paddingLeft: '0.625rem' },
                      ...params.inputProps,
                    }}
                    placeholder={PAGE_CONTENT.purchased_from_placeholder}
                  />
                )}
              />
              {stakeHolderError && (
                <ErroWrapper>{PAGE_CONTENT.stakeholder_error}</ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels
                iserror={statusError}
                className={classNameHandler(statusError, 'Mui-error', '')}>
                {PAGE_CONTENT.transaction_purchase_status_label}
              </FormLabels>
              <Radio
                disabled={viewMode}
                color="success"
                {...transactionStatusRadioProps(
                  PAGE_CONTENT.transaction_purchase_status_radio_1,
                )}
                sx={radioBtnSx}
              />
              <RadioButtonlabel>
                {PAGE_CONTENT.transaction_purchase_status_radio_1}
              </RadioButtonlabel>
              <Radio
                disabled={viewMode}
                color="success"
                {...transactionStatusRadioProps(
                  PAGE_CONTENT.transaction_purchase_status_radio_2,
                )}
                sx={radioBtnSx}
              />
              <RadioButtonlabel>
                {PAGE_CONTENT.transaction_purchase_status_radio_2}
              </RadioButtonlabel>
              {statusError && (
                <ErroWrapper>
                  {PAGE_CONTENT.transaction_status_error}
                </ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels>
                {PAGE_CONTENT.share_type_label}
                <OptionalLabel>{PAGE_CONTENT.option_field}</OptionalLabel>
              </FormLabels>
              <Select
                disabled={viewMode}
                value={transactionForm.assumptionType}
                onChange={(e) => {
                  e.target.value !== '' && setSharPercentageError(false);
                  setFormValue({
                    ...transactionForm,
                    assumptionType: e.target.value,
                    sharePercentage:
                      e.target.value === ''
                        ? ''
                        : transactionForm.sharePercentage,
                  });
                  markFormDirty();
                }}
                sx={{ width: '14rem', height: '2rem' }}
                name="assumptionType"
                displayEmpty
                inputProps={{ 'aria-label': 'assumptionType' }}
                input={<OutlinedInput />}
                renderValue={
                  transactionForm.assumptionType !== '' || null
                    ? undefined
                    : () => (
                        <DropDownPlaceholder>
                          {PAGE_CONTENT.share_type_placeholder}
                        </DropDownPlaceholder>
                      )
                }>
                {shareAssumptionTypes.map((type) => (
                  <MenuItem
                    key={type.label}
                    value={type.value}
                    sx={getMenuItemStyles}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormFields>
            {transactionForm.assumptionType !== '' && (
              <FormFields>
                <FormLabels iserror={sharePercentageError}>
                  {PAGE_CONTENT.profit_share_percentage_label}
                </FormLabels>
                <Input
                  disabled={viewMode}
                  value={transactionForm.sharePercentage}
                  placeholder={PAGE_CONTENT.profit_share_percentage_placeholder}
                  name="sharePercentage"
                  type="text"
                  error={sharePercentageError}
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                      e.target.value === ''
                    ) {
                      formFieldChangeHandler(e);
                      e.target.value !== '' && setSharPercentageError(false);
                    }
                  }}
                  inputProps={{ 'aria-label': 'sharePercentage' }}
                  sx={inputNumberSx}
                />
                <InputSubtext>{PAGE_CONTENT.percentage_subtext}</InputSubtext>
                {sharePercentageError && (
                  <ErroWrapper>
                    {PAGE_CONTENT.share_percentage_error}
                  </ErroWrapper>
                )}
              </FormFields>
            )}
            <FormFields>
              <FormLabels iserror={costStructureError}>
                {PAGE_CONTENT.cost_structure_label}
              </FormLabels>
              <Select
                disabled={viewMode}
                value={transactionForm.costStructure}
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  e.target.value !== '' && setCostStructureError(false);
                  markFormDirty();
                }}
                sx={{ width: '14rem', height: '2rem' }}
                name="costStructure"
                displayEmpty
                error={costStructureError}
                inputProps={{ 'aria-label': 'costStructure' }}
                input={<OutlinedInput />}
                renderValue={
                  transactionForm.costStructure !== '' || null
                    ? undefined
                    : () => (
                        <DropDownPlaceholder>
                          {PAGE_CONTENT.cost_structure_placeholder}
                        </DropDownPlaceholder>
                      )
                }>
                {costStructure.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    sx={getMenuItemStyles}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {costStructureError && (
                <ErroWrapper>{PAGE_CONTENT.cost_structure_error}</ErroWrapper>
              )}
            </FormFields>
            <FormFields>
              <FormLabels>
                {PAGE_CONTENT.transaction_cost_label}
                <OptionalLabel>{PAGE_CONTENT.option_field}</OptionalLabel>
              </FormLabels>
              <Input
                disabled={viewMode}
                value={transactionForm.transactionCost}
                placeholder={PAGE_CONTENT.type_amount_placeholder}
                name="transactionCost"
                onChange={(e) => {
                  if (
                    e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                    e.target.value === ''
                  ) {
                    formFieldChangeHandler(e);
                  }
                }}
                type="text"
                inputProps={{ 'aria-label': 'transactionCost' }}
                sx={inputNumberSx}
              />
            </FormFields>
            {/* Project section */}
            <ProjectsLabel>{PAGE_CONTENT.projects_label}</ProjectsLabel>
            {projectInfo.length > 0 &&
              projectInfo.map((project, index) => {
                let id = index;
                return (
                  <ProjectSubsection
                    key={id}
                    paymentTypes={paymentTypes}
                    costStructure={costStructure}
                    onUpdate={updateProjectInfo}
                    onDelete={deleteProjectInfo}
                    projectList={projectInfo}
                    projectObj={project}
                    paymentObj={paymentObj}
                    vintageObj={vintageObj}
                    projectno={index}
                    editMode={editMode}
                    viewMode={viewMode}
                  />
                );
              })}
            {/* <ProjectSubsection/> */}

            {/* <AddProjectWrapper onClick={addProjectHandler} hidden={viewMode}>
            <AddIcon />
            <AddprojectLabel>{PAGE_CONTENT.add_project_text}</AddprojectLabel>
          </AddProjectWrapper> */}
            <AddSectionButton
              onClickHanlder={addProjectHandler}
              hiddenFlag={viewMode}
              label={PAGE_CONTENT.add_project_text}
            />

            <FormFields>
              <FormLabels>
                {PAGE_CONTENT.associated_type_label}
                <OptionalLabel>{PAGE_CONTENT.option_field}</OptionalLabel>
              </FormLabels>
              <Select
                disabled={viewMode}
                value={transactionForm.associatedType}
                onChange={(e) => {
                  formFieldChangeHandler(e);
                  markFormDirty();
                }}
                sx={{ width: '14rem', height: '2rem' }}
                name="associatedType"
                // error={paymentObj.typeError}
                inputProps={{ 'aria-label': 'associatedType' }}
                displayEmpty
                input={<OutlinedInput />}
                renderValue={
                  transactionForm.associatedType !== ''
                    ? undefined
                    : () => (
                        <DropDownPlaceholder>
                          {PAGE_CONTENT.associated_type_placeholder}
                        </DropDownPlaceholder>
                      )
                }>
                {associatedTypes.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    sx={getMenuItemStyles}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormFields>
            <FormFields>
              <FormLabels>
                {PAGE_CONTENT.notes_label}
                <OptionalLabel>{PAGE_CONTENT.option_field}</OptionalLabel>
              </FormLabels>
              <TextArea
                placeholder={PAGE_CONTENT.notes_placeholder}
                disabled={viewMode}
                onChange={formFieldChangeHandler}
                name="notes"
                value={transactionForm.notes}
                className="text_area"></TextArea>
            </FormFields>

            {!viewMode && (
              <ButtonWrapper>
                <Button
                  size="medium"
                  variant="contained"
                  color="success"
                  sx={{ textTransform: 'none' }}
                  disableElevation
                  onClick={submitHandler}>
                  {PAGE_CONTENT.submit_button_label}
                </Button>
                <Button
                  color="success"
                  size="medium"
                  onClick={() => {
                    navigate(pathName.assets.transactions, {
                      state: 'transactionform',
                    });
                  }}
                  sx={{ textTransform: 'none' }}>
                  {PAGE_CONTENT.cancel_button_label}
                </Button>
              </ButtonWrapper>
            )}
          </FormSection>
          <DialogBox
            dialogActions
            deleteOperation
            isOpen={deleteTransactionError.flag}
            title={deleteTransactionError.title}
            transactionList={deleteTransactionImpactedList}
            onConfirm={deleteTransactionErrorHandler}
            onCancel={deleteTransactionErrorHandler}
            acceptCtnLabel="Close">
            {deleteTransactionError.message}{' '}
          </DialogBox>
        </MainWrapper>
      )}
    </>
  );
};

export default AddPurchaseTransaction;
