import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordianDetailsSx,
  AccordianSummarySx,
  AccordianSx,
  ChartsWrapper,
  EmptyDataContent,
  EmptyDataWrapper,
  HeaderWrapper,
  ProjectNameWrapper,
  RegnFarmParentWrapper,
  RegnFarmWrapper,
  SubContainer,
  SubHeaderWrapper,
  TagsContainer,
  TagsWrapper,
  TextWrapper,
  TitleWrapper,
  StyledSpan,
  StyledDiv,
  StyledAcre,
  ParticipantBarChartContainer,
  ToolTipIconSx,
  NoDataLabel,
} from './ParticipantSummary.style';
import {
  ACTIVE_TAG_BG,
  ACTIVE_TAG_TEXT_COLOR,
  LIGHT_CHARCOAL,
  BLUISH_CYAN,
  COOL_GRAY,
  DARK_CHARCOAL,
  WHITE,
  SILVER,
  BRIGHT_GREEN,
  ORANGE,
  NEW_DARK_GREEN,
  EMPTY_STATE_COLOR,
  EMPTY_STATE_TEXT_COLOR,
} from 'theme/GlobalColors';
import TableWithExpandableRow from 'components/TableWithExpandableRow/TableWithExpandableRow.react';
import { Backdrop, CircularProgress } from '@mui/material';
import {
  PARTICIPANT_SUMMARY_CONTENT,
  Status,
} from './ParticipantSummary.content';
import {
  FETCH_INCENTIVES_OF_A_PARTICIPANT,
  FETCH_PARTICIPANT_GHG_INSIGHTS,
  PARTICIPANT_SUMMARY_GRAPH,
} from 'urls';
import axios from 'axios';
import { checkTernaryCondition } from 'utils/helper';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import {
  BarStyle,
  BarSection,
} from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.style';
import { calculateBarPercentage } from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.content';
import { ReactComponent as BlueCyanIcon } from 'assets/icons/BlueCyan.svg';
import { ReactComponent as GreyIcon } from 'assets/icons/GreyIcon.svg';
import { ReactComponent as GreenIcon } from 'assets/icons/GreenIcon.svg';
import { ReactComponent as emptyStateIcon } from 'assets/icons/emptyStateIcon.svg';
import CustomLineChart from 'components/Charts/CustomLineChart/CustomLineChart.react';
import { rangeNumArray } from 'pages/PortfolioOriginationTab/PortfolioOriginationTab.content';
import StakeholderParticipantIncentiveCard from '../StakeholderParticipantIncenticeCard/StakeholderParticipantIncentiveCard.react';
const extractFieldDetails = (fieldDetails) => {
  return fieldDetails.map((obj) => {
    const { fieldId, fieldName, farmId, fieldBoundaries } = obj;

    const convertedObject = {
      fieldId: fieldId,
      fieldName,
      farmId: farmId,
      fieldBoundaries: fieldBoundaries.map(
        ({ latitude: lat, longitude: lng }) => ({ lat, lng }),
      ),
    };

    return convertedObject;
  });
};

const ParticipantSummary = ({
  projectList,
  loading,
  participantInfo,
  participantId,
}) => {
  const [expanded, setExpanded] = useState(['panel1']);
  const [ghgData, setGhgData] = useState(null);
  const [reportingData, setReportingData] = useState(null);
  const [selectedFarmField, setSelectedFarmField] = useState({
    farmId: null,
    fieldId: null,
  });
  const [containerWidth, setContainerWidth] = useState(0);
  const [isLoading, setIsLoading] = useState({
    GHGLoading: false,
  });
  const [incentiveObject, setIncentiveObject] = useState({
    internalProjectId: null,
    averageProjectedIncentive: 0.0,
    averageActualIncentive: 0.0,
    totalProjectedIncentive: 0.0,
    totalActualIncentive: 0.0,
  });
  useEffect(() => {
    axios
      .get(
        `${FETCH_INCENTIVES_OF_A_PARTICIPANT}/${participantId}/participant-incentives`,
      )
      .then((res) => {
        setIncentiveObject(res.data);
      });
  }, []);
  const farmBoundaries = participantInfo?.map((farm) => {
    const boundaries = farm?.fieldDetails?.reduce((accumulator, field) => {
      field?.fieldBoundaries.forEach((boundary) => {
        accumulator.push({ lat: boundary?.latitude, lng: boundary?.longitude });
      });
      return accumulator;
    }, []);
    return boundaries;
  });

  const enrolledStatusCodes = ['5a', '5b', '6', '7', '7a', '7b'];

  const handleChange = (panel) => () => {
    setExpanded(
      expanded.includes(panel)
        ? expanded.filter((item) => item !== panel)
        : [...expanded, panel],
    );
  };
  const setGHGDataFunc = () => {
    setIsLoading((prev) => ({ ...prev, GHGLoading: true }));
    axios
      .get(`${FETCH_PARTICIPANT_GHG_INSIGHTS}/${participantId}`)
      .then((res) => {
        setGhgData(res.data);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, GHGLoading: false }));
      });
  };
  const yearRange = rangeNumArray(20, 30);
  const mapLabel = ghgData?.ghgInsights?.map((insight) => {
    const year = new Date(insight.abatementDate)
      .getFullYear()
      .toString()
      .slice(-2);
    return parseInt(year, 10);
  });
  const projectedValues = [];
  const actualValues = [];

  yearRange.forEach((year) => {
    if (mapLabel?.length > 0) {
      const index = mapLabel?.indexOf(year);
      if (index !== -1) {
        actualValues.push(ghgData?.ghgInsights[index].actualAbatement);
        projectedValues.push(ghgData?.ghgInsights[index].projectedAbatement);
      } else {
        actualValues.push(null);
        projectedValues.push(null);
      }
    }
  });
  const chartData = {
    labels: yearRange,
    datasets: [
      {
        label: PARTICIPANT_SUMMARY_CONTENT.projected,
        data: projectedValues,
        borderColor: checkTernaryCondition(
          ghgData?.ghgInsights?.length === 0 || isLoading.GHGLoading,
          EMPTY_STATE_COLOR,
          NEW_DARK_GREEN,
        ),
        pointRadius: 1,
        backgroundColor: checkTernaryCondition(
          ghgData?.ghgInsights?.length === 0 || isLoading.GHGLoading,
          EMPTY_STATE_COLOR,
          NEW_DARK_GREEN,
        ),
        borderDash: [10, 10],
      },
      {
        label: PARTICIPANT_SUMMARY_CONTENT.actual,
        data: actualValues,
        borderColor: checkTernaryCondition(
          ghgData?.ghgInsights?.length === 0 || isLoading.GHGLoading,
          EMPTY_STATE_COLOR,
          ORANGE,
        ),
        pointRadius: 1,
        backgroundColor: checkTernaryCondition(
          ghgData?.ghgInsights?.length === 0 || isLoading.GHGLoading,
          EMPTY_STATE_COLOR,
          ORANGE,
        ),
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        max: 30,
        min: 20,
        ticks: {
          padding: 5,
          color: DARK_CHARCOAL,
          stepSize: 1,
          callback: function (value) {
            return "'" + (value + 20);
          },
        },
        title: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          stepSize: 150,
          color: DARK_CHARCOAL,
        },
        max: 600,
        min: 0,
        title: {
          display: true,
          color: COOL_GRAY,
          text: PARTICIPANT_SUMMARY_CONTENT.CO2e,
        },
      },
    },
  };
  useEffect(() => {
    if (projectList.length > 0) setGHGDataFunc();
  }, [projectList]);

  useEffect(() => {
    axios.get(`${PARTICIPANT_SUMMARY_GRAPH}${participantId}`).then((res) => {
      setReportingData(res.data);
    });
  }, [participantId]);

  useEffect(() => {
    const calculateContainerWidth = () => {
      const container = document.getElementById('ghg-container');
      if (container) {
        const width = container.offsetWidth - 32;
        setContainerWidth(width);
      }
    };
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);
    return () => {
      window.removeEventListener('resize', calculateContainerWidth);
    };
  }, [projectList]);

  const calculateTotalValue = (string) => {
    let finalValue = 0;
    reportingData
      ?.filter((data) => data.phase?.includes(string))
      ?.forEach((data) => (finalValue = finalValue + data.area));
    return finalValue;
  };
  const LegendIconList = [
    { icon: GreyIcon, label: 'Not started' },
    { icon: GreenIcon, label: 'In progress' },
    { icon: BlueCyanIcon, label: 'Complete' },
    { icon: emptyStateIcon, label: 'Not started' },
    { icon: emptyStateIcon, label: 'In progress' },
    { icon: emptyStateIcon, label: 'Complete' },
  ];
  return (
    <>
      <Backdrop
        open={loading || Object.values(isLoading).includes(true)}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="success" />
      </Backdrop>
      {projectList.map((project, index) => {
        return (
          <Accordion
            sx={AccordianSx}
            key={`${project.projectName + index}`}
            onChange={handleChange(`panel${index + 1}`)}
            expanded={expanded?.includes(`panel${index + 1}`)}>
            <AccordionSummary
              onClick={() => {
                setGHGDataFunc();
              }}
              sx={AccordianSummarySx}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <HeaderWrapper padding="0rem">
                <SubHeaderWrapper gap="0.5rem">
                  <TextWrapper
                    fontWeight="400"
                    fontSize="1.25rem"
                    color={LIGHT_CHARCOAL}>
                    <ProjectNameWrapper title={project.projectName}>
                      {project.projectName}
                    </ProjectNameWrapper>
                  </TextWrapper>
                  <TagsContainer>
                    <TagsWrapper
                      backgroundColor={ACTIVE_TAG_BG}
                      color={ACTIVE_TAG_TEXT_COLOR}>
                      {PARTICIPANT_SUMMARY_CONTENT.activeTag}
                    </TagsWrapper>
                  </TagsContainer>
                </SubHeaderWrapper>
              </HeaderWrapper>
            </AccordionSummary>
            <AccordionDetails sx={AccordianDetailsSx}>
              {project.participantProjectStatus === Status.interested &&
                enrolledStatusCodes?.includes(
                  project?.participantEnrolmentStatus?.split(':')[0],
                ) && (
                  <EmptyDataWrapper className="curved-border">
                    <EmptyDataContent>
                      {
                        PARTICIPANT_SUMMARY_CONTENT.interestedParticipantDisplayText
                      }
                    </EmptyDataContent>
                  </EmptyDataWrapper>
                )}
              <StakeholderParticipantIncentiveCard data={incentiveObject} />

              <ChartsWrapper className="curved-border">
                {reportingData?.length > 0 && (
                  <SubContainer
                    width="49.35%"
                    className="curved-border"
                    backgroundColor={WHITE}
                    hasBoxShadow={true}>
                    <ParticipantBarChartContainer>
                      <BarSection direction="column" gap="4px">
                        <TextWrapper
                          color={DARK_CHARCOAL}
                          fontSize="1rem"
                          fontWeight={600}>
                          {PARTICIPANT_SUMMARY_CONTENT.enrollmentAndReporting}
                        </TextWrapper>
                      </BarSection>
                      <BarSection
                        direction="column"
                        gap="4px"
                        style={{ display: 'flex' }}>
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.enrollment}
                            {calculateTotalValue(
                              PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                            )}
                          </TextWrapper>
                          <StyledSpan>
                            {'  ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.InProgress,
                              ),
                          ) && (
                            <BarSection
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                    ),
                                  )
                                ]?.area,
                              )}
                              direction="row">
                              <BarStyle
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                bgColor={BRIGHT_GREEN}
                                color={WHITE}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Complete,
                              ),
                          ) && (
                            <BarSection
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Complete,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                    ),
                                  )
                                ]?.area,
                              )}
                              direction="row"
                              gap="4px">
                              <BarStyle
                                height="1.25rem"
                                borderRadius="2px 2px 2px 2px"
                                fontWeight={600}
                                bgColor={BLUISH_CYAN}
                                color={WHITE}
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                borderWidth="2px 2px 2px 0px">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Complete,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="column" gap="4px">
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.baselineReporting}
                            {reportingData?.some((data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ),
                            )
                              ? calculateTotalValue(
                                  PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                )
                              : '--'}
                          </TextWrapper>
                          <StyledSpan>
                            {'  ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.InProgress,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                    ),
                                  )
                                ]?.area,
                              )}>
                              <BarStyle
                                bgColor={BRIGHT_GREEN}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Complete,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              gap="4px"
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Complete,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                    ),
                                  )
                                ]?.area,
                              )}>
                              <BarStyle
                                bgColor={BLUISH_CYAN}
                                color={WHITE}
                                height="1.25rem"
                                borderRadius="2px 2px 2px 2px"
                                fontWeight={600}
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                borderWidth="2px 2px 2px 0px">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Complete,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.every(
                            (data) =>
                              !data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={SILVER}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>{'--'}</StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.NotStarted,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={SILVER}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Baseline,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.NotStarted,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="column" gap="4px">
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.activityReporting}
                            {reportingData?.some((data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ),
                            )
                              ? calculateTotalValue(
                                  PARTICIPANT_SUMMARY_CONTENT.Activity,
                                )
                              : '--'}
                          </TextWrapper>
                          <StyledSpan>
                            {'  ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.InProgress,
                              ),
                          ) && (
                            <BarSection
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Activity,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Activity,
                                    ),
                                  )
                                ]?.area,
                              )}
                              direction="row">
                              <BarStyle
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                bgColor={BRIGHT_GREEN}
                                color={WHITE}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Activity,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.InProgress,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Complete,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              gap="4px"
                              width={calculateBarPercentage(
                                reportingData[
                                  reportingData?.findIndex(
                                    (data) =>
                                      data.phase?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Activity,
                                      ) &&
                                      data.action?.includes(
                                        PARTICIPANT_SUMMARY_CONTENT.Complete,
                                      ),
                                  )
                                ]?.area,
                                reportingData[
                                  reportingData?.findIndex((data) =>
                                    data.phase?.includes(
                                      PARTICIPANT_SUMMARY_CONTENT.Activity,
                                    ),
                                  )
                                ]?.area,
                              )}>
                              <BarStyle
                                bgColor={BLUISH_CYAN}
                                color={WHITE}
                                height="1.25rem"
                                borderRadius="2px 2px 2px 2px"
                                fontWeight={600}
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                borderWidth="2px 2px 2px 0px">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Activity,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Complete,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.every(
                            (data) =>
                              !data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={SILVER}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>{'--'}</StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                          {reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.NotStarted,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={SILVER}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem">
                                <StyledAcre>
                                  {
                                    reportingData[
                                      reportingData?.findIndex(
                                        (data) =>
                                          data.phase?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.Activity,
                                          ) &&
                                          data.action?.includes(
                                            PARTICIPANT_SUMMARY_CONTENT.NotStarted,
                                          ),
                                      )
                                    ]?.area
                                  }
                                </StyledAcre>
                              </BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="row" gap="1rem">
                        {LegendIconList.slice(0, 3).map((item, index) => {
                          const keyVal = index;
                          return (
                            <BarSection
                              direction="row"
                              gap="4px"
                              alignItems="baseline"
                              key={keyVal}>
                              <item.icon />
                              <TextWrapper
                                color={DARK_CHARCOAL}
                                fontSize="12px"
                                fontWeight={400}>
                                {item.label}
                              </TextWrapper>
                            </BarSection>
                          );
                        })}
                      </BarSection>
                    </ParticipantBarChartContainer>
                  </SubContainer>
                )}
                {reportingData?.length === 0 && (
                  <SubContainer
                    width="49.35%"
                    className="curved-border"
                    backgroundColor={WHITE}
                    hasBoxShadow={true}>
                    <ParticipantBarChartContainer>
                      <BarSection direction="column" gap="4px">
                        <TextWrapper
                          color={DARK_CHARCOAL}
                          fontSize="1rem"
                          fontWeight={600}>
                          {PARTICIPANT_SUMMARY_CONTENT.enrollmentAndReporting}
                        </TextWrapper>
                      </BarSection>
                      <BarSection
                        direction="column"
                        gap="4px"
                        style={{ display: 'flex' }}>
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.enrollment}
                          </TextWrapper>
                          <StyledSpan>
                            {' - - ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {!reportingData?.some(
                            (data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Enrollment,
                              ) &&
                              data.action?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.InProgress,
                              ),
                          ) && (
                            <BarSection width="100%" direction="row">
                              <BarStyle
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                bgColor={EMPTY_STATE_COLOR}
                                color={WHITE}
                                width="100%"
                                border="1px solid white"
                                height="1.25rem"></BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="column" gap="4px">
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.baselineReporting}
                          </TextWrapper>
                          <StyledSpan>
                            {' - - ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {reportingData?.every(
                            (data) =>
                              !data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Baseline,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={EMPTY_STATE_COLOR}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                justifyContent="center"
                                height="1.25rem">
                                <TextWrapper
                                  color={EMPTY_STATE_TEXT_COLOR}
                                  fontSize="12px"
                                  fontStyle="italic">
                                  {
                                    PARTICIPANT_SUMMARY_CONTENT.interestedParticipantDisplayText
                                  }
                                </TextWrapper>
                              </BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="column" gap="4px">
                        <StyledDiv>
                          <TextWrapper
                            color={DARK_CHARCOAL}
                            fontSize="12px"
                            fontWeight={600}>
                            {PARTICIPANT_SUMMARY_CONTENT.activityReporting}
                            {reportingData?.some((data) =>
                              data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ),
                            )
                              ? calculateTotalValue(
                                  PARTICIPANT_SUMMARY_CONTENT.Activity,
                                )
                              : ''}
                          </TextWrapper>
                          <StyledSpan>
                            {' - - ' + PARTICIPANT_SUMMARY_CONTENT.acres}
                          </StyledSpan>
                        </StyledDiv>
                        <StyledDiv>
                          {reportingData?.every(
                            (data) =>
                              !data.phase?.includes(
                                PARTICIPANT_SUMMARY_CONTENT.Activity,
                              ),
                          ) && (
                            <BarSection
                              direction="row"
                              width={calculateBarPercentage(100, 100)}>
                              <BarStyle
                                bgColor={EMPTY_STATE_COLOR}
                                color={WHITE}
                                borderRadius="2px 2px 2px 2px"
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                }}
                                border="1px solid white"
                                height="1.25rem"></BarStyle>
                            </BarSection>
                          )}
                        </StyledDiv>
                      </BarSection>
                      <BarSection direction="row" gap="1rem">
                        {LegendIconList.slice(3).map((item, index) => {
                          const keyVal = index;
                          return (
                            <BarSection
                              direction="row"
                              gap="4px"
                              alignItems="baseline"
                              key={keyVal}>
                              <item.icon />
                              <TextWrapper
                                color={DARK_CHARCOAL}
                                fontSize="12px"
                                fontWeight={400}>
                                {item.label}
                              </TextWrapper>
                            </BarSection>
                          );
                        })}
                      </BarSection>
                    </ParticipantBarChartContainer>
                  </SubContainer>
                )}
                <SubContainer
                  id="ghg-container"
                  width="49.35%"
                  className="curved-border"
                  backgroundColor={WHITE}
                  hasBoxShadow={true}>
                  {checkTernaryCondition(
                    ghgData?.ghgInsights?.length === 0 || isLoading.GHGLoading,
                    <CustomLineChart
                      options={options}
                      data={chartData}
                      title={PARTICIPANT_SUMMARY_CONTENT.ghg_emissions}
                      height={'156px'}
                      width={containerWidth}
                      ToolTipIconSx={ToolTipIconSx('bottom', '0rem')}
                      showLegends={false}
                      noDataLabel={
                        <NoDataLabel>
                          {PARTICIPANT_SUMMARY_CONTENT.ghgEmissionsEmptyLabel}
                        </NoDataLabel>
                      }
                      tooltipTitle={
                        PARTICIPANT_SUMMARY_CONTENT.ghg_impact_tooltip
                      }
                      placement={PARTICIPANT_SUMMARY_CONTENT.placement}
                      showLegendsAtBottom={true}
                      currentValue={checkTernaryCondition(
                        ghgData?.currentYearAbatement,
                        `${ghgData?.currentYearAbatement}K`,
                        '- -',
                      )}
                      currentValueLabel={PARTICIPANT_SUMMARY_CONTENT.actual}
                    />,
                    <CustomLineChart
                      options={options}
                      data={chartData}
                      height={'156px'}
                      width={containerWidth}
                      title={PARTICIPANT_SUMMARY_CONTENT.ghg_emissions}
                      ToolTipIconSx={ToolTipIconSx('bottom', '0rem')}
                      showLegends={false}
                      tooltipTitle={
                        PARTICIPANT_SUMMARY_CONTENT.ghg_impact_tooltip
                      }
                      showLegendsAtBottom={true}
                      currentValue={checkTernaryCondition(
                        ghgData?.currentYearAbatement,
                        `${ghgData?.currentYearAbatement}K`,
                        '- -',
                      )}
                      placement={PARTICIPANT_SUMMARY_CONTENT.placement}
                      currentValueLabel={PARTICIPANT_SUMMARY_CONTENT.actual}
                    />,
                  )}
                </SubContainer>
              </ChartsWrapper>
              {participantInfo.map((farm, index) => (
                <RegnFarmParentWrapper
                  key={farm?.farmId}
                  className="curved-border">
                  <HeaderWrapper paddingLeft="2rem">
                    <TitleWrapper>{farm?.farmName}</TitleWrapper>
                  </HeaderWrapper>
                  <RegnFarmWrapper>
                    <SubContainer
                      width={'33.4%'}
                      minWidth={'20.125rem'}
                      noBorder={true}
                      onlyBorderRight={true}>
                      <MapSearchContainer
                        fieldPolygons={extractFieldDetails(farm?.fieldDetails)}
                        mapCenter={farmBoundaries[index][0]}
                        showSearchBox={false}
                        fieldInfoWindows={[]}
                        markersWithInfoWindows={[]}
                        mapControlOptions={{
                          mapTypeId: 'satellite',
                          zoom: 17,
                          labels: true,
                        }}
                        drawMode={null}
                        style={{ height: '25rem', width: '100%' }}
                        selectedFarmField={selectedFarmField}
                        setSelectedFarmField={setSelectedFarmField}
                        hasOnClickFunctionality={true}
                      />
                    </SubContainer>
                    <SubContainer width={'66.6%'} noBorder={true}>
                      <TableWithExpandableRow
                        fieldDetails={farm?.fieldDetails}
                        noBorder={true}
                        selectedRow={selectedFarmField}
                        setSelectedRow={setSelectedFarmField}
                      />
                    </SubContainer>
                  </RegnFarmWrapper>
                </RegnFarmParentWrapper>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

ParticipantSummary.propTypes = {
  projectList: PropTypes.array,
  loading: PropTypes.bool,
  participantInfo: PropTypes.object,
  participantId: PropTypes.number,
};
export default ParticipantSummary;
