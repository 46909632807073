import styled from 'styled-components';
import { DIM_BLUE_BACKGROUND } from 'theme/GlobalColors';

export const CustomPopupBox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  background-color: white;
  max-height: 40rem;
  gap: 1rem;
  border-radius: 5px;
  padding: ${({ padding }) => padding};
  overflow: auto;
  margin-bottom: 2rem;
`;
export const Wrapper = styled.div`
  width: 17rem;
  max-height: 13rem;
  border-left: ${({ borderLeft }) => borderLeft};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: ${({ borderBottom }) => borderBottom};
`;

export const TitleWrapper = styled.div`
  font-size: 20px;
  font-weight: 400;
  width: 95%;
`;
export const BoxStyle = {
  background: 'white',
  width: '50%',
};
export const IconStyle = {
  left: '93%',
};
export const EditModalWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const SubWrapper = styled.div`
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const ValueWrapper = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-size: 14px;
  line-height: normal;
  color: #333333;
  width: ${({ width }) => width};
  padding: 0.5rem;
  overflow-wrap: break-word;
`;

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '48rem',
    },
  },
};
export const titleStyle = {
  fontSize: '1.25rem',
  fontWeight: '400',
  padding: '0rem',
  lineHeight: '140%',
};
export const IconButtonStyle = { ml: 'auto' };
export const DialogTitleStyle = {
  fontSize: '20px',
  margin: '1.5rem 1.5rem 0rem 1.5rem',
  padding: '0rem',
  alignItems: 'flex-start',
};
export const DialogContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  boxSizing: 'border-box',
  margin: '1rem 1.5rem 1.5rem 1.5rem',
  padding: '0',
  overflowY: 'auto',
  overflowX: 'hidden',
};

export const accordionSx = {
  ':before': {
    height: '0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    minHeight: '0',
    margin: '0',
  },
  '& .MuiAccordionSummary-root': {
    flexDirection: 'row-reverse',
    minHeight: '0',
    padding: '0',
    gap: '4px',
    '&:hover': {
      background: DIM_BLUE_BACKGROUND,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '0',
    margin: '0',
  },
  '&.MuiPaper-root.Mui-expanded': {
    margin: '0',
  },
  '& .MuiAccordionDetails-root': {
    paddingBottom: '0',
  },
};
