import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useEffect, useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@mui/material';
import ValueTable from 'containers/AuditTrailComp/TransactionAudit/TransactionModal/ValueTable/ValueTable.react';
import {
  DialogStyle,
  IconButtonStyle,
  DialogTitleStyle,
  DialogContentStyle,
  AccordionDetailsSx,
} from './TransactionModal.style';
import {
  MODAL_CONTENT,
  generateSubTitle,
  generateTitle,
  validFieldComparator,
} from './Transaction.content';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import ProjectAddedOrDeletedSection from './ProjectAddedOrDeletedSection/ProjectAddedOrDeletedSection.react';
import { titleStyle } from 'components/ActionModal/ActionModal.style';
import { closeIconSx } from 'theme/GlobalStyles';

const extractAuditEventsBasedOnType = (arr, type) => {
  return type === MODAL_CONTENT.notes
    ? arr.filter((obj) => obj.column === MODAL_CONTENT.notes)
    : arr.filter((obj) => obj.column !== MODAL_CONTENT.notes);
};

export default function TransactionModal({
  title,
  auditEvents,
  transactionType,
  isOpen,
  isOpenSetter,
}) {
  const projectEvents = auditEvents.projectDtoList || MODAL_CONTENT.empty_array;

  const generalEvents = extractAuditEventsBasedOnType(
    auditEvents.transactionAudits || MODAL_CONTENT.empty_array,
    MODAL_CONTENT.general,
  );
  const notesEvent = extractAuditEventsBasedOnType(
    auditEvents.transactionAudits || MODAL_CONTENT.empty_array,
    MODAL_CONTENT.notes,
  );

  const handleClose = () => {
    isOpenSetter(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={DialogStyle}
        scroll={MODAL_CONTENT.paper}
        aria-labelledby={MODAL_CONTENT.aria_labelledby}
        aria-describedby={MODAL_CONTENT.aria_describedby}>
        <IconButton sx={IconButtonStyle}>
          <CloseIcon
            onClick={handleClose}
            data-testid="close-icon"
            sx={closeIconSx}
          />
        </IconButton>
        <DialogTitle sx={DialogTitleStyle}>{title}</DialogTitle>
        <DialogContent sx={DialogContentStyle}>
          {generalEvents.length > 0 && (
            <ValueTable
              title={MODAL_CONTENT.general}
              hasNotes={false}
              eventDetails={generalEvents || MODAL_CONTENT.empty_array}
            />
          )}
          {notesEvent.length > 0 &&
            validFieldComparator(
              notesEvent[0].oldValue,
              notesEvent[0].newValue,
            ) && (
              <ValueTable
                title={MODAL_CONTENT.notes}
                hasNotes={true}
                oldValue={notesEvent[0].oldValue || MODAL_CONTENT.empty}
                newValue={notesEvent[0].newValue || MODAL_CONTENT.empty}
              />
            )}
          {projectEvents.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <Typography sx={titleStyle}>
                    {MODAL_CONTENT.projects_label}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={AccordionDetailsSx}>
                {projectEvents.map((item) => {
                  if (item.auditVintageDto.auditVintageList.length > 0) {
                    if (
                      item.auditVintageDto.auditVintageList[0].action ===
                        MODAL_CONTENT.insert ||
                      item.auditVintageDto.auditVintageList[0].action ===
                        MODAL_CONTENT.delete
                    ) {
                      return (
                        <ProjectAddedOrDeletedSection
                          action={
                            item.auditVintageDto.auditVintageList[0].action
                          }
                          title={generateTitle(item)}
                          subtitle={generateSubTitle(transactionType)}
                          key={item.auditId}
                        />
                      );
                    }
                  }
                  return (
                    <>
                      <ValueTable
                        title={generateTitle(item)}
                        subtitle={generateSubTitle(transactionType)}
                        hasNotes={false}
                        eventDetails={
                          item?.auditVintageDto?.auditVintageList ||
                          MODAL_CONTENT.empty_array
                        }
                        deliveryDetails={
                          item?.auditVintageDto?.auditDeliveryDto
                        }
                        totalAmount={item?.totalAmount || null}
                        hasProjectDetails={true}
                      />
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
