import { checkTernaryCondition } from 'utils/helper';
import { BASELINE_MODAL_CONTENT } from './ReportingComponents/TabBodyComponent.content';
import moment from 'moment';
import _ from 'lodash';
import { REPORTING_STATUS } from 'utils/config';

export const getStatus = (farmList) => {
  const allFieldDetails = farmList?.flatMap((farm) => farm?.fieldDetails) || [];
  const status = checkTernaryCondition(
    allFieldDetails
      .filter((fields) => fields.fieldId)
      .every((field) => field.fieldStatus === 'Completed'),
    'complete',
    'in progress',
  );

  return status;
};

export const filterListByParentId = (submitData, parentId, propertyName) => {
  return submitData?.practiceModalsData[propertyName]?.filter(
    (obj) => obj.parentId === parentId,
  );
};

const getDynamicDeletedList = (submitData, item, propertyName) => {
  return (
    submitData.deletePracticeModalsData?.[propertyName]
      ?.filter(
        (obj) =>
          obj.parentId === item.cropSampleId &&
          !obj.id.toString().includes('-'),
      )
      .map((obj) => obj.id) || []
  );
};

const getDynamicEofDeletedList = (submitData, propertyName) => {
  return submitData.deletePracticeModalsData?.[propertyName];
};

export const validateFieldYear = (
  submitData,
  fertilizerTypeDropDown,
  coverCropTypeDropdown,
  tillageTypeDropdown,
) => {
  let validatedList = [];
  let isDatesValidated = false;
  const { nitrogenList, coverCropList, tillageTypeDtoList } =
    submitData.practiceModalsData;
  isDatesValidated =
    nitrogenList.every(
      (nitrogenData) =>
        nitrogenData.data.fertilizerType ===
          fertilizerTypeDropDown.find((type) => type.label === 'None').value ||
        nitrogenData.data.datesApplied.length > 0,
    ) &&
    coverCropList.every(
      (coverCropData) =>
        coverCropData.data.cropId ===
          coverCropTypeDropdown.find((type) => type.label === 'None').value ||
        (coverCropData.data.cropPlantDate !== '' &&
          coverCropData.data.cropTerminateDate !== ''),
    ) &&
    tillageTypeDtoList.every(
      (tillageTypeData) =>
        tillageTypeData.data.tillagePractice ===
          tillageTypeDropdown.find((type) => type.label === 'No tillage')
            .value || tillageTypeData.data.tillageDates.length > 0,
    ) &&
    submitData.parentState.every(
      (cashCropData) =>
        cashCropData.productionPurpose.toUpperCase() === 'FALLOW' ||
        (cashCropData.cropPlantDate !== '' &&
          cashCropData.cropHarvestDate !== ''),
    );
  if (submitData.parentState.length !== 0) {
    const mandatoryModals = submitData.practicesData.map((practices) => ({
      parentId: practices.parentId,
      modalList: practices.data
        .filter((item) => item.isMandatory)
        .map((filteredItem) => {
          return {
            data: submitData.practiceModalsData[filteredItem.modalList].filter(
              (practice) => practice.parentId === practices.parentId,
            ),
          };
        }),
    }));
    mandatoryModals.forEach((modal) => {
      validatedList.push(
        checkTernaryCondition(
          modal.modalList.every((modalData) => modalData.data.length > 0),
          'Completed',
          'In Progress',
        ),
      );
    });
    return checkTernaryCondition(
      validatedList.every((item) => item === 'Completed') && isDatesValidated,
      'Completed',
      'In Progress',
    );
  }
  return 'Not Started';
};

const getProductionPurposeValue = (productionPurpose) => {
  if (
    productionPurpose === BASELINE_MODAL_CONTENT.cash_crop_value ||
    productionPurpose === BASELINE_MODAL_CONTENT.cover_crop_value ||
    productionPurpose === BASELINE_MODAL_CONTENT.fallow_crop_value
  ) {
    return productionPurpose;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cash_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cash_crop_value;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cover_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cover_crop_value;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.fallow.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.fallow_crop_value;
  }
};

export const getFormattedCashcropList = (parentState, submitData, editMode) => {
  return parentState?.map((item) => {
    const filteredResidueManagementTypeList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.residue_mgmt_list,
    );
    const filteredIrrigationTypeList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.irrigation_list,
    );
    const filteredTillageTypeList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.tillage_list,
    );
    const filteredSoilAmendmentsList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.soil_amendments_list,
    );
    const filteredNitrogenList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.fertilizer_list,
    );
    const filteredLivestockList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.livestock_list,
    );
    const filteredCoverCropList = filterListByParentId(
      submitData,
      item.id,
      BASELINE_MODAL_CONTENT.cover_crop_list,
    );
    const cropPlantDate = checkTernaryCondition(
      item.cropPlantDate?.toString().length === 0,
      '',
      moment(item.cropPlantDate).format('YYYY-MM-DD'),
    );
    const cropHarvestDate = checkTernaryCondition(
      item.cropTerminationDate?.toString().length === 0,
      '',
      moment(item.cropTerminationDate).format('YYYY-MM-DD'),
    );
    return {
      cropSeason: item.cropSeason,
      yieldUnitOfMeasure: checkTernaryCondition(
        item.annualYield,
        item.annualYieldUom,
        null,
      ),
      unitOfMeasure: '',
      yieldMeasuredPer: '',
      cropId: item.cropId,
      cropArea: Number(item.cropArea),
      cropAreaUom: item.cropAreaUom,
      annualYield: item.annualYield,
      plantingDensity: item.plantingDensity,
      cropValue: item.cropValue,
      plantingDensityUom: item.plantingDensityUom,
      seedingDepth: item.cropDepth,
      seedingDepthUom: item.cropDepthUom,
      cropPlantDate: cropPlantDate,
      cropTerminationDate: cropHarvestDate,
      addedFromImplementedActivities: Boolean(
        item.addedFromImplementedActivities,
      ),
      productionPurpose: getProductionPurposeValue(item.productionPurpose),
      cropPlannedManagementTypeId: item.cropPlannedManagementTypeId,
      projectActivities: item.activityList || [],
      cropRotation: false,
      isCopied: item.isCopied,
      tillageSystemOther: item.tillageSystemOther,
      tillageSystemDisturbanceId: item.tillageSystemDisturbanceId,
      nitrogenList: filteredNitrogenList.map((obj) => {
        return {
          applicationDate: obj?.data?.datesApplied
            ?.filter((date) => date?.toString().trim()?.length > 0)
            .map((date) => {
              return moment(date).format('YYYY-MM-DD');
            }),
          applicationMethodId: Number(obj?.data?.applicationMethod),
          containsInhibitor: obj?.data?.hasInhibitor === 'Yes',
          fertilizerApplicationRate: Number(obj?.data?.applicationRate),
          fertilizerApplicationUom: obj?.data?.unitOfMeasure,
          fertilizerId: obj?.data?.fertilizerType,
          fertilizerSampleId: checkTernaryCondition(
            obj.id?.toString().includes('-'),
            0,
            obj.id,
          ),
          kpercentage: checkTernaryCondition(
            obj?.data?.kPercentage === '',
            null,
            Number(obj?.data?.kPercentage),
          ),
          manureTypeId: checkTernaryCondition(
            obj?.data?.manureType === '',
            0,
            obj?.data?.manureType,
          ),
          measuredPer: 'acre',
          npercentage: checkTernaryCondition(
            obj?.data?.nPercentage === '',
            null,
            Number(obj?.data?.nPercentage),
          ),
          ppercentage: checkTernaryCondition(
            obj?.data?.pPercentage === '',
            null,
            Number(obj?.data?.pPercentage),
          ),
        };
      }),
      fossilFuelList: [],
      irrigationTypeList: filteredIrrigationTypeList.map((obj) => {
        return {
          irrigationMethodId: Number(obj?.data?.irrigationType),
          irrigationRate: Number(obj?.data?.irrigationRate),
          irrigationRateUom: obj?.data?.uom,
          irrigationSampleId: checkTernaryCondition(
            obj.id?.toString().includes('-'),
            0,
            obj.id,
          ),
        };
      }),
      livestockList: filteredLivestockList.map((obj) => {
        return {
          averageWeight: Number(obj?.data?.averageWeight),
          averageWeightUom: obj?.data?.unitOfMeasure,
          grazingArea: Number(obj?.data?.livestockGrazingArea),
          grazingDays: Number(obj?.data?.annualGrazingDays),
          grazingId: obj?.data?.prescribedGrazingType,
          livestockId: obj?.data?.livestockType,
          manureManagementId: obj?.data?.manureManagementSystem,
          stockingRate: Number(obj?.data?.livestockStockingRatePerField),
          livestockSampleId: checkTernaryCondition(
            obj.id?.toString().includes('-'),
            0,
            obj.id,
          ),
        };
      }),
      soilAmendmentList: filteredSoilAmendmentsList.map((obj) => {
        return {
          amountApplied: Number(obj?.data?.amountApplied),
          soilAmendmentTypeId: Number(obj?.data?.soilAmendmentsType),
          unitOfMeasure: obj?.data?.unitOfMeasure,
          addedFromImplementedActivities: Boolean(
            obj?.data?.addedFromImplementedActivities,
          ),
          soilAmendSampleId: checkTernaryCondition(
            obj.id?.toString().includes('-'),
            0,
            obj.id,
          ),
        };
      }),

      tillageTypeDtoList: filteredTillageTypeList.map((obj) => {
        return {
          tillagePracticeId: Number(obj?.data?.tillagePractice),
          addedFromImplementedActivities: Boolean(
            obj?.data?.addedFromImplementedActivities,
          ),
          specificTillageType: checkTernaryCondition(
            Number(obj?.data?.tillagePractice) === 7,
            obj?.data?.customTillagePractice,
            '',
          ),
          performedOn: obj?.data?.tillageDates
            ?.filter((date) => date?.toString().trim().length > 0)
            .map((date) => {
              return moment(date).format('YYYY-MM-DD');
            }),
          surfaceDisturbance: obj?.data?.surfaceDisturbance,
          tillageDepth: obj?.data?.depthOfTillage ?? '',
          tillageSampleId: checkTernaryCondition(
            obj.id?.toString().includes('-'),
            0,
            obj.id,
          ),
        };
      }),
      residueManagementTypeList: filteredResidueManagementTypeList.map(
        (obj) => {
          return {
            agresidueSampleId: obj?.data?.agresidueSampleId,
            cropResidueManagementRemovalMethodId:
              obj?.data?.agricultureResiduesManaged,
            cropResidueRemoved: obj?.data?.cropResidueRemoved,
          };
        },
      ),
      ...(editMode && {
        cropSampleId: checkTernaryCondition(
          item.cropSampleId && !item.cropSampleId.toString().includes('-'),
          item.cropSampleId,
          0,
        ),
        deletedLiveStockIdList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.livestock_deleted_list,
        ),
        deletedSoilAmendmentList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.soil_amendments_deleted_list,
        ),
        deletedFertilizerList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.fertilizer_deleted_list,
        ),
        deletedFossilFuelList: [],
        deletedResidueManagementList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.residue_mgmt_deleted_list,
        ),

        deletedIrrigationList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.irrigation_deleted_list,
        ),
        deletedTillageList: getDynamicDeletedList(
          submitData,
          item,
          BASELINE_MODAL_CONTENT.tillage_deleted_list,
        ),
      }),
    };
  });
};
export const getFormattedEdgeOfFieldList = (submitData, editMode) => {
  return submitData.practiceModalsData.edgeOfFieldList.map((item) => {
    return {
      barrierWidth: item.data.barrierWidth,
      addedFromImplementedActivities: Boolean(
        item.data.addedFromImplementedActivities,
      ),
      edgeOfFieldArea: item.data.area,
      edgeOfFieldPracticeId: item.data.practiceType,
      edgeOfFieldSampleId: checkTernaryCondition(
        item.id?.toString().includes('-'),
        0,
        item.id,
      ),
      numberOfRows: item.data.rowCount,
      speciesCategoryId: item.data.speciesCategory,
      stripWidth: item.data.stripWidth,
      speciesDensity: item.data.speciesDensity,
      ...(editMode && {
        cropSampleId: checkTernaryCondition(
          item.cropSampleId && !item.cropSampleId.toString().includes('-'),
          item.cropSampleId,
          0,
        ),
        deletedEdgeOfFieldList: getDynamicEofDeletedList(
          submitData,
          BASELINE_MODAL_CONTENT.edge_of_field_deleted_list,
        ),
      }),
    };
  });
};

export const getcashCropSubmitBody = ({
  fieldId,
  projectId,
  participantId,
  sampleId,
  cashCropList,
  eofList,
  farmId,
  yearStatus,
  reportingStatus,
  operationsDataTabStatus,
  projectCycleId,
}) => {
  return {
    addBaselineDto: {
      activityReportingStatus: checkTernaryCondition(
        reportingStatus,
        'Enrollment In Progress',
        'Interested',
      ),
      fieldId: fieldId,
      projectId: projectId,
      participantId: participantId,
      participantBaselineStatus: 'Enrollment In Progress',
      farmId: farmId,
      sampleId: sampleId,
      projectCycleId: projectCycleId,
    },
    baselineFieldDto: {
      fieldStatus: checkTernaryCondition(
        cashCropList?.length > 0,
        'Completed',
        'In Progress',
      ),
      cropList: cashCropList,
      edgeOfFieldList: eofList,
      yearStatus: yearStatus,
      fieldOperationsDataStatus: operationsDataTabStatus,
    },
  };
};

/**
 * Formats the reporting years data according to the state management requirements.
 *
 * @param {api data: Array} data
 * @param {"farm" or "field"} yearType
 * @returns {Array} The formatted years data.
 */
export const formatReportingYearsData = (data, yearType, type) => {
  const dataToReturn = data
    .map((year) => ({
      yearValue: year.dateId.toString(),
      sampleId: year.sampleId,
      type: yearType,
      status: year.formStatus,
      previousStatus: year.formStatus,
      phase: year.phase,
    }))
    .sort((year1, year2) => year2.yearValue - year1.yearValue);
  if (type === 'activity') {
    const filteredObject = [...dataToReturn].find(
      (year) => year.phase.toLowerCase() === 'project',
    );
    const newEnrolmentYearObjectOperations = {
      ...filteredObject,
      displayTitle: 'Operations data',
    };
    return [newEnrolmentYearObjectOperations];
  } else {
    return dataToReturn;
  }
};

export const getFormattedFarmData = (fossilFuels = []) => {
  const fossiFuelFormatted = fossilFuels?.map((data) => ({
    ffSampleId: checkTernaryCondition(typeof data.id === 'string', 0, data.id),
    ffId: data.fossilFuelType,
    consumption: parseFloat(data.consumption),
    unitOfMeasure: data.unitOfMeasure,
    isCopied: data.isCopied,
  }));
  const yearStatus = checkTernaryCondition(
    fossilFuels.length === 0,
    'Not Started',
    'Completed',
  );

  return { yearStatus, fossiFuelFormatted };
};
function getDeletedItemList(submitData) {
  let finalDeletedValues = [];
  submitData?.deletePracticeModalsData?.deletedEdgeOfFieldList.map((item) =>
    finalDeletedValues.push(item.id),
  );

  return finalDeletedValues;
}
export const getFieldEditApiData = (
  formData,
  submitData,
  fieldId,
  operationsDataTabStatus = 'Not Started',
) => {
  const fieldStatus = checkTernaryCondition(
    formData.baselineFieldDto.fieldStatus === 'Completed',
    'CP',
    'IP',
  );

  const apiParams = {
    fieldId: fieldId,
    fieldStatus: fieldStatus,
    sampleId: submitData?.sampleId,
    fieldOperationsDataStatus: operationsDataTabStatus,
    projectCycleId: formData.addBaselineDto.projectCycleId,
  };
  const finalArray = _.map(
    formData.baselineFieldDto.edgeOfFieldList,
    (object) => _.omit(object, ['cropSampleId', 'deletedEdgeOfFieldList']),
  );

  const reqBody = {
    fieldSampleCrops: formData.baselineFieldDto.cropList,
    yearStatus: formData.baselineFieldDto.yearStatus,
    edgeOfFieldList: finalArray,
    deletedEdgeOfFieldList: getDeletedItemList(submitData),
  };

  return { apiParams, reqBody };
};

export const validateMappedPractices = (
  parentState,
  practiceList,
  submitData,
) => {
  for (let i = 0; i < parentState.length; i++) {
    const item = parentState[i];
    for (let j = 0; j < practiceList.length; j++) {
      const practice = practiceList[j];
      const filteredList = filterListByParentId(
        submitData,
        item.id,
        practice.practiceType,
      );
      let allInvalid = false;
      for (let k = 0; k < filteredList.length; k++) {
        const { data } = filteredList[k];
        if (
          data &&
          data.addedFromImplementedActivities &&
          !practice.isValid(data[practice.valueKey]) &&
          !practice.canIgnore(data[practice.ignoreKey])
        ) {
          allInvalid = true;
          break;
        }
      }
      if (allInvalid) {
        return REPORTING_STATUS.in_progress;
      }
    }
  }
  return REPORTING_STATUS.completed;
};

//to validate mapped edge of field data
export const validateMappedEof = (eofList) => {
  if (eofList.length === 0) return REPORTING_STATUS.completed;
  else if (
    eofList.some(
      (eof) =>
        eof.data.addedFromImplementedActivities &&
        (parseFloat(eof.data.area) < 0 || eof.data.area === ''),
    )
  ) {
    return REPORTING_STATUS.in_progress;
  } else return REPORTING_STATUS.completed;
};
