import LightTooltip from 'components/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { customTitle } from './InfoToolTip.content';
import { IconStyle } from './InfoTooltip.style';
import { FORM_CONTENT } from '../FormComponents.content';
import PropTypes from 'prop-types';

const InfoTooltip = ({
  title,
  content,
  bottomMargin,
  maxWidth = '18.75rem',
  csafToolTipSx,
  handleClick = () => {},
  customSx,
  placement = 'bottom',
}) => {
  const displayTooltip = title !== undefined && content !== undefined;
  return (
    <LightTooltip
      sx={{ maxWidth: maxWidth }}
      title={displayTooltip && customTitle(title, content)}
      placement={placement}
      arrow>
      <InfoOutlinedIcon
        sx={{ ...IconStyle(bottomMargin), ...csafToolTipSx, ...customSx }}
        color={FORM_CONTENT.disabled}
        data-testid="tooltip"
        onClick={handleClick}
      />
    </LightTooltip>
  );
};

InfoTooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  bottomMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
  csafToolTipSx: PropTypes.object,
  handleClick: PropTypes.func,
  customSx: PropTypes.object,
  placement: PropTypes.string,
};

export default InfoTooltip;
