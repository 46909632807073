import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST } from 'urls';
import { useActivityContext } from 'containers/ActivityListContainer';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import { getActivityPracticeMapping } from './ActivityOptions.constants';
import PipelineAcerageModal from 'pages/Origination/Org_Project/Participants/PiplelineAcerageModal/PipelineAcerageModal.react';
export const PipelineAcerage = () => {
  const { addPipelineAcresOpen, setAddPipelineAcresOpen, projectCycleId } =
    useActivityContext();
  const { participantId, projectId } = useContext(ParticipantActivityContext);
  const [activityPracticeMapping, setActivityPracticeMapping] = useState({});

  const fetchIncentiveListForParticipant = (participantId, projectId) => {
    axios
      .get(
        `${FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST}?participantId=${participantId}&projectId=${projectId}`,
      )
      .then((response) => {
        const activityPracticeMap = getActivityPracticeMapping(response.data);
        setActivityPracticeMapping(activityPracticeMap);
      });
  };

  useEffect(() => {
    if (addPipelineAcresOpen.isOpen) {
      fetchIncentiveListForParticipant(participantId, projectId);
    }
  }, [addPipelineAcresOpen]);

  if (!addPipelineAcresOpen.isOpen) {
    return null;
  }

  return (
    <div>
      <PipelineAcerageModal
        data-testid="add-pipeline-acerage-modal"
        addPipelineAcresOpen={addPipelineAcresOpen}
        setAddPipelineAcresOpen={setAddPipelineAcresOpen}
        activityPracticeMapping={activityPracticeMapping}
        projectId={projectId}
        refreshHandler={() => {
          setAddPipelineAcresOpen({ isOpen: false });
        }}
        participantId={participantId}
        projectCycleId={projectCycleId}
      />
    </div>
  );
};
