import React from 'react';
import PropTypes from 'prop-types';
import { FormLabels, FormFields } from '../FormStyles';
import { DiabledChip } from './TagFieldValueComponent.style';

const TagFieldValueComponent = ({ label, value }) => {
  return (
    <FormFields>
      <FormLabels>{label}</FormLabels>
      {value?.map((item) => (
        <DiabledChip key={item} label={item} />
      ))}
    </FormFields>
  );
};

TagFieldValueComponent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default TagFieldValueComponent;
