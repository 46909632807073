import styled from 'styled-components';

export const MainContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: ${({ gap }) => gap || '0.5rem'};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  color: ${({ color }) => color};
`;
export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  //   word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
`;
export const LineItemWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
export const AddOptionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  cursor: pointer;
`;
