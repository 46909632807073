import { useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

/**
 * Interceptor component for attaching the axios interceptor
 */
export const RequestInterceptor = ({ children }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    attachAxiosInterceptors();
  }, [currentUser]);

  function attachAxiosInterceptors() {
    axios.interceptors.request.use(
      async (config) => {
        if (currentUser) {
          await attachFirebaseTokens(config);
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    // Response interceptor for API calls
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error?.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          await currentUser?.getIdToken().then((token) => {
            axios.defaults.headers.common['Authorization'] = token;
          });

          return axios(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * This function takes care of attaching the access token for every request in the
   * authorization header. This also internally takes care of refreshing the tokens
   * when expired using refresh_token.
   * This would only redirect to login screen when the refresh_token is slao expired
   * @param { axios config} config
   */
  async function attachFirebaseTokens(config) {
    await currentUser.getIdToken().then((token) => {
      config.headers['Authorization'] = `${token}`;
    });
  }
  return children;
};
