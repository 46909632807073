import React from 'react';
import { useActivityContext } from 'containers/ActivityListContainer';
import InitiateActivityReportingModal from 'pages/OriginationParticipantsList/InitiateActivityReportingModal/InitiateActivityReportingModal.react';
import { pathName } from 'Routes/routes.const';

export const ActivityReportingAction = () => {
  const {
    initiateActivityReportingModalData,
    setInitiateActivityReportingModalData,
    handleActivityReportingSendRequest,
    projectId,
    participantId,
    projectCycleId,
    setSelectedActivityOption,
  } = useActivityContext();

  return (
    <InitiateActivityReportingModal
      modalDataSetter={setInitiateActivityReportingModalData}
      modalState={initiateActivityReportingModalData}
      modalSubmitHandler={handleActivityReportingSendRequest}
      setSelectedActivityOption={setSelectedActivityOption}
      navigateLink={`${pathName.participant.participantActivity}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`}
    />
  );
};
