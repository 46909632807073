export const MODAL_CONTENT = {
  titlePrefix: 'Copy',
  copyBtnLabel: 'Copy',
  cancelBtnLabel: 'Cancel',
  noDataText: 'There are no results to display for the filtered selection.',
  tractorVehicleCopyError:
    'Copy of farm tractor / vehicle failed to complete due to a technical error. Please try again.',
  fieldCropCopyError:
    'Copy of field cash crop(s) failed to complete due to a technical error. Please try again.',
};

export const farmsDataProps = { labelKey: 'farmName', valueKey: 'farmId' };
export const farmyearsDataProps = {
  name: 'yearsList',
  labelKey: 'dateId',
  valueKey: 'sampleId',
};
export const fuelsDataProps = {
  name: 'fuelDtoList',
  labelKey: 'fuelName',
  valueKey: 'ffId',
};
export const fieldsDataProps = { labelKey: 'fieldName', valueKey: 'fieldId' };
export const fieldYearsDataProps = {
  name: 'listOfYears',
  labelKey: 'yearId',
  valueKey: 'sampleId',
};
export const cropsDataProps = {
  name: 'crops',
  labelKey: 'cropName',
  valueKey: 'cropId',
};

export const allFieldsOption = { label: 'All fields', value: 'All fields' };
export const allFarmsOption = { label: 'All farms', value: 'All farms' };
export const allYearsOption = { label: 'All years', value: 'All years' };
export const allCropsOption = { label: 'All crops', value: 'All crops' };
export const allFuelsOption = { label: 'All fuels', value: 'All fuels' };
