import React, { useState } from 'react';
import {
  ButtonWrapper,
  ComponentWrapper,
  MainContainer,
  StyledCalenderIcon,
} from './DropdownDateComponent.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import PropTypes from 'prop-types';
import CustomDatePicker from 'components/FormComponents/CustomDatePicker/CustomDatePicker.react';
import { YEAR_FORMAT } from 'utils/config';
import { ReactComponent as CalenderIcon } from '../../../../../assets/icons/calenderIcon.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  BLUISH_CYAN,
  COOL_GRAY,
  DARK_GREEN,
  GLOBAL_BLUE,
} from 'theme/GlobalColors';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { ErrorWrapper, FormLabels } from 'components/FormComponents/FormStyles';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { FIELD_INFO_CONSTANT } from '../ParticipantGeneralFieldInfoForm.content';
const DropdownDateComponent = ({
  disableFieldForRevisitingForm,
  defaultLandUseType,
  landUseTypeList,
  historicLandUseType,
  addAnotherHistoricLandUseType,
  handleChangeOnHistoricLandUseType,
  removeHistoricLandUseType,
  errors, // {isError, errorMessage}
}) => {
  return (
    <MainContainer>
      {historicLandUseType.map((item, index) => {
        let disableDropdown = index == 0;
        let startYear = (+new Date().getFullYear() - 20).toString();
        let endYear =
          index == 0
            ? (+new Date().getFullYear() - 4).toString()
            : (+new Date().getFullYear()).toString();
        return (
          <ComponentWrapper key={uniqueId()}>
            <DropDown
              fontSize={'0.875rem'}
              valueWidth={'6.125rem'}
              valueHeight={'1.188rem'}
              data-testid="landUseType"
              key={uniqueId()}
              customPlaceholderColor={COOL_GRAY}
              isDisabled={disableFieldForRevisitingForm || disableDropdown}
              setStateValue={() => {}}
              dropdownlist={
                index === 1
                  ? landUseTypeList.filter(
                      (obj) => obj.label !== defaultLandUseType,
                    )
                  : landUseTypeList
              }
              onUpdate={(event) => {
                const value = event.target.value;
                handleChangeOnHistoricLandUseType(
                  index,
                  'landuseTypeId',
                  value,
                );
              }}
              name="landUseType"
              showLabelAsValue={
                !(item.landuseTypeId === -1 || item.landuseTypeId === '')
              }
              value={
                item.landuseTypeId === -1 || item.landuseTypeId === ''
                  ? `${FIELD_INFO_CONSTANT.landUsePlaceHolder}`
                  : item.landuseTypeId
              }
              dropDownPlaceholder="Land Use Type"
              hasNoBottomMargin={true}
              minWidth={'13.75rem'}
              width={'13.75rem'}
              height={'2rem'}></DropDown>
            {/*Start Date will be current year - 20 enabled*/}
            <CustomDatePicker
              isDisabled={disableFieldForRevisitingForm}
              notShowValue={false}
              data-testid="startYear"
              placeholder={FIELD_INFO_CONSTANT.yearPlaceholder}
              customSelectedDateColor={DARK_GREEN}
              hasSideLabels="true"
              name="startYear"
              labelColor={COOL_GRAY}
              hasNoBottomMargin={true}
              alignItems="center"
              width={'5rem'}
              minWidth={'7.25rem'}
              labelHeight={'1.25rem'}
              labelWidth={'2rem'}
              height={'2rem'}
              display="flex"
              gap={'0.25rem'}
              icon={StyledCalenderIcon}
              label="Start"
              fontSize={'0.875rem'}
              value={item.startYear === '0' ? '' : item.startYear}
              setDisabledYears={true}
              startYear={startYear}
              endYear={endYear}
              showOnlyYears={true}
              onUpdate={(date) => {
                const year = new Date(date).getFullYear().toString();
                handleChangeOnHistoricLandUseType(index, 'startYear', year);
              }}
              inputFormat={YEAR_FORMAT}></CustomDatePicker>
            {/*End Date will be current year - 1 disabled*/}
            <CustomDatePicker
              notShowValue={false}
              placeholder={FIELD_INFO_CONSTANT.yearPlaceholder}
              onUpdate={(date) => {
                const year = new Date(date).getFullYear().toString();
                handleChangeOnHistoricLandUseType(index, 'endYear', year);
              }}
              setDisabledYears={true}
              customSelectedDateColor={DARK_GREEN}
              startYear={startYear}
              endYear={endYear}
              isDisabled={disableFieldForRevisitingForm || disableDropdown}
              name="endYear"
              labelColor={COOL_GRAY}
              hasNoBottomMargin={true}
              alignItems="center"
              justifyContent="center"
              width={'5rem'}
              labelHeight={'1.25rem'}
              labelWidth={'1.563rem'}
              minWidth={'7.25rem'}
              height={'2rem'}
              display="flex"
              gap={'0.25rem'}
              icon={StyledCalenderIcon}
              label="End"
              fontSize={'0.875rem'}
              value={item.endYear === '0' ? '' : item.endYear}
              showOnlyYears={true}
              inputFormat={YEAR_FORMAT}></CustomDatePicker>

            {index != 0 && index != 1 && (
              <IconButton
                disabled={disableFieldForRevisitingForm}
                disableFocusRipple
                disableRipple
                aria-label="delete"
                onClick={() => {
                  if (historicLandUseType.length > 2) {
                    removeHistoricLandUseType(index);
                  }
                }}>
                <DeleteOutlineIcon
                  data-testid="delete"
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    color: COOL_GRAY,
                    '&:hover': {
                      color: BLUISH_CYAN,
                    },
                  }}></DeleteOutlineIcon>
              </IconButton>
            )}
          </ComponentWrapper>
        );
      })}

      <ButtonWrapper
        onClick={
          disableFieldForRevisitingForm
            ? () => {}
            : () => addAnotherHistoricLandUseType()
        }>
        <AddIcon
          sx={{
            color: disableFieldForRevisitingForm ? COOL_GRAY : GLOBAL_BLUE,
            width: '0.667rem',
            height: '0.667rem',
          }}
        />
        <FormLabels
          color={disableFieldForRevisitingForm ? COOL_GRAY : GLOBAL_BLUE}
          fontSize={'0.875rem'}
          marginTop={'0rem'}
          style={{ width: '5.313rem', height: '1.25rem' }}
          marginBottom={'0rem'}>
          Add land use
        </FormLabels>
      </ButtonWrapper>
      {errors.isError && <ErrorWrapper>{errors.errorMessage}</ErrorWrapper>}
    </MainContainer>
  );
};
DropdownDateComponent.propTypes = {
  defaultLandUseType: PropTypes.string,
  landUseTypeList: PropTypes.array,
  historicLandUseType: PropTypes.array,
  addAnotherHistoricLandUseType: PropTypes.func,
  handleChangeOnHistoricLandUseType: PropTypes.func,
  removeHistoricLandUseType: PropTypes.func,
  errors: PropTypes.object,
};
export default DropdownDateComponent;
