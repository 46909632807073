import axios from 'axios';
import { Dialog, DialogContent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FETCH_REVIEW_PDF } from 'urls';
import EnrollmentCompleteModal from 'pages/ParticipantDataCollection/components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import {
  PdfDownloadButton,
  ReviewText,
  ReviewPDFSx,
  DialogStyleSx,
} from './Participants.style';
import Loader from '../../../../components/Loader/Loader.react';
import { baselineReOpen } from './BaselineReview';
import {
  LABELS,
  enrollmentApproveAndReopenBaselineContent,
  getActionIdFromActionCode,
  getActivityDate,
  getActivityId,
  getActivityType,
  getReviewTabName,
} from './Participants.content';
import PdfViewerPage from './PdfViewerPage/pdfViewerPage.react';
import PdfComponent from './PdfViewerPage/components/PdfComponent.react';
import { ReactComponent as PdfLinkIcon } from '../../../../assets/icons/LinktoPdf.svg';
import ActivityReportingPOIReviewComp from './ActivityReportingPOIReviewComp/ActivityReportingPOIReviewComp.react';
import { useActivityContext } from 'containers/ActivityListContainer';
import { ConfigurationContext } from 'contextAPI/configurationContext';

export const Review = ({
  reviewEnrollmentProps: {
    reviewComponent,
    reviewButtonText,
    reviewButtonText2,
    fetchInitialDataCb,
  },
  setShowPdfViewerPage,
  closeEnrollmentModal,
  setCurrentTab,
  setAreUiActionInProgress,
}) => {
  const handlePdfClick = () => {
    closeEnrollmentModal();
    setAreUiActionInProgress((prev) => prev + 1);
    setShowPdfViewerPage(true);
  };

  return (
    <>
      <ReviewText>{reviewComponent}</ReviewText>
      <PdfDownloadButton
        onClick={() => {
          setCurrentTab(0);
          handlePdfClick();
        }}>
        {reviewButtonText}
        <PdfLinkIcon />
      </PdfDownloadButton>
      {reviewButtonText2 && (
        <PdfDownloadButton
          onClick={() => {
            setCurrentTab(1);
            handlePdfClick();
          }}>
          {reviewButtonText2}
          <PdfLinkIcon />
        </PdfDownloadButton>
      )}
    </>
  );
};

export const ReviewModal = ({
  reviewEnrollmentProps,
  setReviewEnrollmentProps,
  reopenProps,
  setReopenProps,
  statusMappingList,
  activityDataList = [],
  disableVirtualization,
}) => {
  const [showPdfViewerPage, setShowPdfViewerPage] = useState(false);
  const [pdfCurrentTab, setPdfCurrentTab] = useState(0);
  const {
    reopenEnrollmentOrBaseline,
    approveEnrollmentOrBaseline,
    updateParticipantStatus,
    generateBaselineId,
    updateParticipantBaselineStatus,
  } = useContext(LifeCycleContext);
  const [loading, setLoading] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const participantActivityContext = useContext(ParticipantActivityContext);
  const activityData = participantActivityContext?.activityData;
  const [pdfData, setPdfData] = useState();
  const activityId = getActivityId(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );
  const activityType = getActivityType(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );
  const activityDate = getActivityDate(
    reviewEnrollmentProps?.formId ?? reviewEnrollmentProps?.instanceId,
    activityData,
  );

  const { participantId, projectId } = useActivityContext();
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);

  useEffect(() => {
    if (activityId && activityType) {
      axios
        .get(`${FETCH_REVIEW_PDF}/${activityId}/${activityType}`)
        .then((response) => {
          setPdfData(response?.data);
        });
    }
  }, [reviewEnrollmentProps?.modalData?.flag]);

  const closeEnrollmentModal = () => {
    reviewEnrollmentProps.modalCloseCb();
    setReviewEnrollmentProps((previous) => ({
      ...previous,
      modalData: { flag: false },
    }));
    setAreUiActionInProgress((prev) => prev - 1);
  };

  const onClickBackButton = () => {
    setShowPdfViewerPage(false);
    setAreUiActionInProgress((prev) => prev - 1);
  };

  const handleApprove = () => {
    const baselineReportingRowData = activityDataList
      .filter((data) => data.activityType === 'Baseline reporting')
      .reverse()[0];
    const activityReportingRowData = activityDataList
      .filter((data) => data.activityType === 'Activity reporting')
      .reverse()[0];
    approveEnrollmentOrBaseline({
      ...reviewEnrollmentProps,
      setLoading: setLoading,
      actionId: getActionIdFromActionCode('ENROLL-APPROVE', statusMappingList),
      baselineActionId: getActionIdFromActionCode(
        'BASELINE-APPROVE',
        statusMappingList,
      ),
      activityActionId: getActionIdFromActionCode(
        'ACTIVITY-APPROVE',
        statusMappingList,
      ),
      setReviewEnrollmentProps: setReviewEnrollmentProps,
      statusMappingList,
      baselineReportingRowData,
      activityReportingRowData,
    });
  };

  return (
    <>
      <Loader loading={loading} zIndex={99999} />

      <EnrollmentCompleteModal
        title={reviewEnrollmentProps.title}
        contentText={
          <Review
            reviewEnrollmentProps={reviewEnrollmentProps}
            setShowPdfViewerPage={setShowPdfViewerPage}
            closeEnrollmentModal={closeEnrollmentModal}
            setCurrentTab={setPdfCurrentTab}
            setAreUiActionInProgress={setAreUiActionInProgress}
          />
        }
        firstSubmitBtnLabel={reviewEnrollmentProps.secondSubmitBtnLabel}
        secondSubmitBtnLabel={reviewEnrollmentProps.firstSubmitBtnLabel}
        cancelBtnLabel={reviewEnrollmentProps.cancelBtnLabel}
        firstSubmitBtnHandler={() => {
          reopenEnrollmentOrBaseline(reviewEnrollmentProps);
          reviewEnrollmentProps.firstSubmitBtnCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: { flag: false },
          }));
          setAreUiActionInProgress((prev) => prev - 1);
          setReopenProps({
            ...reviewEnrollmentProps.reOpenProp,
            instanceId: reviewEnrollmentProps.instanceId,
            projectCycleId: reviewEnrollmentProps.projectCycleId,
            modalData: { flag: true },
            actionCode: reviewEnrollmentProps.actionCode,
            contractId: reviewEnrollmentProps.contractId,
            formId: reviewEnrollmentProps.formId,
            baselineActionId: reviewEnrollmentProps.baselineActionId,
          });
          setAreUiActionInProgress((prev) => prev + 1);
        }}
        secondSubmitBtnHandler={() => {
          const baselineReportingSubmittedData = activityDataList
            .filter((data) => data.activityType === 'Baseline reporting')
            .find(
              (data) =>
                getActionIdFromActionCode(
                  'BASELINE-SUBMIT',
                  statusMappingList,
                ) === data.actionId ||
                getActionIdFromActionCode(
                  'BASELINE-REVIEW',
                  statusMappingList,
                ) === data.actionId,
            );
          if (
            baselineReportingSubmittedData &&
            reviewEnrollmentProps.title === LABELS.reviewEnrollmentLabel
          ) {
            reopenEnrollmentOrBaseline(reviewEnrollmentProps);
            reviewEnrollmentProps.firstSubmitBtnCb();
            setReviewEnrollmentProps((previous) => ({
              ...previous,
              modalData: { flag: false },
            }));
            setAreUiActionInProgress((prev) => prev - 1);
            setReopenProps({
              ...baselineReOpen(
                +reviewEnrollmentProps.participantId,
                reviewEnrollmentProps.projectId,
              ),
              projectCycleId: reviewEnrollmentProps.projectCycleId,
              ishandleApprove: true,
              instanceId: 0,
              handleApprove: handleApprove,
              ...enrollmentApproveAndReopenBaselineContent,
              modalData: { flag: true },
              contractId: reviewEnrollmentProps.contractId,
              formId: baselineReportingSubmittedData.formId,
              customReopenRadioLabel: LABELS.reopen_and_approve_enrollment,
            });
            setAreUiActionInProgress((prev) => prev + 1);
          } else {
            handleApprove();
          }
        }}
        cancelHandler={() => {
          reviewEnrollmentProps.cancelCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: { flag: false },
          }));
          setAreUiActionInProgress((prev) => prev - 1);
        }}
        modalCloseHandler={() => {
          reviewEnrollmentProps.modalCloseCb();
          setReviewEnrollmentProps((previous) => ({
            ...previous,
            modalData: { flag: false },
          }));
          setAreUiActionInProgress((prev) => prev - 1);
        }}
        modalData={reviewEnrollmentProps.modalData}
      />
      {showPdfViewerPage && (
        <Dialog
          fullScreen
          open={showPdfViewerPage}
          sx={{ ...DialogStyleSx, top: 50 }}>
          <DialogContent sx={ReviewPDFSx}>
            <PdfViewerPage
              activityType={activityType}
              activityDate={activityDate}
              formId={reviewEnrollmentProps.formId}
              isApproveDisabled={isApproveDisabled}
              setIsApproveDisabled={setIsApproveDisabled}
              heading={pdfData?.activityReviewName}
              subHeadingArray={[
                {
                  title: LABELS.pdf_project_title,
                  response: pdfData?.projectName,
                },
                {
                  title: LABELS.pdf_participant_owner_name,
                  response: pdfData?.participantOwnerName,
                },
              ]}
              currentTab={pdfCurrentTab}
              setCurrentTab={setPdfCurrentTab}
              tabList={[
                {
                  title: getReviewTabName(reviewEnrollmentProps.title),
                  component: <PdfComponent pdfUrl={pdfData?.pdfObjectUrl} />,
                },
                reviewEnrollmentProps.title ===
                LABELS.activity_reporting_review_title
                  ? {
                      title: LABELS.activity_review_tab,
                      component: (
                        <ActivityReportingPOIReviewComp
                          setIsApproveDisabled={setIsApproveDisabled}
                          participantId={participantId}
                          projectId={projectId}
                        />
                      ),
                    }
                  : {},
              ]}
              onClickBackButton={onClickBackButton}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
