export const PROJECT_CALC_REPORT_LIST_COLUMN = {
  mtco2e: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
  cc_id: 'ClearCarbon ID',
  calculations: 'Calculations',
};

export const PROJECT_CALC_REPORT_CONTENT = {
  success: 'success',
  div: 'div',
  arrow_icon: 'arrow-icon-',
  calc_icon: 'calc-icon-',
  three_point_five: '3.5rem',
  flex: 'flex',
  zero_point_five: '0.5rem',
  placement: 'bottom-start',
  disabled: 'disabled',
  tooltip: 'tooltip',
  no_results_title: 'No results.',
  no_results_content:
    'Please adjust the view toggle filters above to display calculations.',
};

export const getDetailedCalculationPanelValues = (
  baselinetotal,
  projecttotal,
  deltatotal,
) => {
  return [
    {
      label: 'Baseline',
      value: (
        <span>
          {Number(baselinetotal)?.toFixed(2)} MT CO<sub>2</sub>e
        </span>
      ),
    },
    {
      label: 'Project',
      value: (
        <span>
          {Number(projecttotal)?.toFixed(2)} MT CO<sub>2</sub>e
        </span>
      ),
    },
    {
      label: 'Delta',
      value: (
        <span>
          {Number(deltatotal)?.toFixed(2)} MT CO<sub>2</sub>e
        </span>
      ),
    },
  ];
};

export const test = [
  {
    id: 1,
    error: null,
    input: {
      input: [
        {
          FieldArea: [
            {
              name: 'FieldArea',
              unit: '',
              child: null,
              label: 'FieldArea',
              value: 19876.0,
              source: 'ClearCarbon',
            },
          ],
          MethaneGlobalWarmingPotential: [
            {
              name: 'MethaneGlobalWarmingPotential',
              unit: '',
              child: null,
              label: 'MethaneGlobalWarmingPotential',
              value: 73300.0,
              source: 'HabiTerre',
            },
          ],
          AgriculturalResidueTypeBiomassBurningMass: [
            {
              name: 'AgriculturalResidueTypeBiomassBurningMass',
              unit: '',
              child: null,
              label: 'AgriculturalResidueTypeBiomassBurningMass',
              value: 304.0,
              source: 'ClearCarbon',
            },
          ],
          AgriculturalResidueTypeBiomassBurnedCombustionFactor: [
            {
              name: 'AgriculturalResidueTypeBiomassBurnedCombustionFactor',
              unit: '',
              child: null,
              label: 'AgriculturalResidueTypeBiomassBurnedCombustionFactor',
              value: 600.0,
              source: 'ClearCarbon',
            },
          ],
          AgriculturalResidueTypeBiomassBurningMethaneEmissionFactor: [
            {
              name: 'AgriculturalResidueTypeBiomassBurningMethaneEmissionFactor',
              unit: '',
              child: null,
              label:
                'AgriculturalResidueTypeBiomassBurningMethaneEmissionFactor',
              value: 73300.0,
              source: 'ClearCarbon',
            },
          ],
        },
      ],
      level: 'Project',
      fieldId: 3001,
      equation: 'CC_138',
      sampleId: 1,
      responseLabel: 'output',
    },
    phase: 'Project',
    job_id: 1,
    output: {
      level: 'Project',
      output: [
        {
          AgriculturalResidueTypeBiomassBurningMethaneEmissions: {
            name: 'AgriculturalResidueTypeBiomassBurningMethaneEmissions',
            unit: 'MT CO2e / unit area',
            child: null,
            label: 'AgriculturalResidueTypeBiomassBurningMethaneEmissions',
            value: 49306.4568,
            source: 'HabiTerre',
          },
        },
      ],
      fieldId: 3001,
      equation: 'CC_138',
      sampleId: 1,
      responseLabel: 'output',
    },
    status: 'Success',
    child_id: 138,
    children: [
      {
        id: 2,
        error: null,
        input: {
          input: [
            {
              AgricultureResidueType: [
                {
                  name: 'AgricultureResidueType',
                  unit: '',
                  child: [
                    {
                      name: 'AgriculturalResidueTypeBiomassBurningMethaneEmissions',
                      unit: 'MT CO2e / unit area',
                      child: null,
                      label:
                        'AgriculturalResidueTypeBiomassBurningMethaneEmissions',
                      value: 49306.4568,
                      source: 'HabiTerre',
                    },
                  ],
                  label: 'AgricultureResidueType',
                  value: null,
                  source: 'ClearCarbon',
                },
              ],
            },
          ],
          level: 'Project',
          fieldId: 3001,
          equation: 'CC_10',
          sampleId: 1,
          responseLabel: 'output',
        },
        phase: 'Project',
        job_id: 1,
        output: {
          level: 'Project',
          output: [
            {
              AgriculturalResidueBiomassBurningMethaneEmissions: {
                name: 'AgriculturalResidueBiomassBurningMethaneEmissions',
                unit: 'MT CO2e',
                child: null,
                label: 'AgriculturalResidueBiomassBurningMethaneEmissions',
                value: 49306.4568,
                source: 'HabiTerre',
              },
            },
          ],
          fieldId: 3001,
          equation: 'CC_10',
          sampleId: 1,
          responseLabel: 'output',
        },
        status: 'Success',
        child_id: 10,
        children: [],
        parent_id: 138,
        template_id: null,
        date_time_executed: '2023-07-28T14:10:56.185',
      },
    ],
    parent_id: null,
    template_id: null,
    date_time_executed: '2023-07-28T14:09:19.505',
  },
];

export const dummyData = [
  {
    id: 1,
    calculation: 'Net estimated GHG emissions reductions and removals',
    cc_id: 'CC_35',
    MT: '5000',
    calculations: false,
    children: [
      {
        id: 2,
        calculation: 'Net estimated GHG emissions removals',
        cc_id: 'CC_37',
        MT: '2500',
        calculations: true,
        children: [
          {
            id: 3,
            calculation: 'Gross estimated GHG emissions reductions',
            cc_id: 'CC_33',
            MT: '2500',
            calculations: true,
            children: [
              {
                id: 4,
                calculation: 'Carbon dioxide reductions from fuel combustion',
                cc_id: 'CC_40',
                MT: '50',
                calculations: true,
                children: [
                  {
                    id: 5,
                    calculation:
                      'Carbon dioxide emissions from fossil fuel combustion - baseline',
                    cc_id: 'CC_4',
                    MT: '80',
                    calculations: true,
                    children: [
                      {
                        id: 6,
                        calculation:
                          'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                        cc_id: 'CC_5',
                        MT: '40',
                        calculations: true,
                        children: [
                          {
                            id: 7,
                            calculation:
                              'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                            cc_id: 'CC_5',
                            MT: '40',
                            calculations: true,
                            children: [
                              {
                                id: 8,
                                calculation:
                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                cc_id: 'CC_5',
                                MT: '40',
                                calculations: true,
                                children: [
                                  {
                                    id: 9,
                                    calculation:
                                      'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                    cc_id: 'CC_5',
                                    MT: '40',
                                    calculations: true,
                                    children: [
                                      {
                                        id: 10,
                                        calculation:
                                          'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                        cc_id: 'CC_5',
                                        MT: '40',
                                        calculations: true,
                                        children: [
                                          {
                                            id: 11,
                                            calculation:
                                              'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                            cc_id: 'CC_5',
                                            MT: '40',
                                            calculations: true,
                                            children: [
                                              {
                                                id: 12,
                                                calculation:
                                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                                cc_id: 'CC_5',
                                                MT: '40',
                                                calculations: true,
                                                children: [
                                                  {
                                                    id: 13,
                                                    calculation:
                                                      'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                                    cc_id: 'CC_5',
                                                    MT: '40',
                                                    calculations: true,
                                                    children: [],
                                                  },
                                                  {
                                                    id: 14,
                                                    calculation:
                                                      'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                                    cc_id: 'CC_5',
                                                    MT: '40',
                                                    calculations: true,
                                                    children: [],
                                                  },
                                                ],
                                              },
                                              {
                                                id: 15,
                                                calculation:
                                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                                cc_id: 'CC_5',
                                                MT: '40',
                                                calculations: true,
                                                children: [],
                                              },
                                            ],
                                          },
                                          {
                                            id: 16,
                                            calculation:
                                              'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                            cc_id: 'CC_5',
                                            MT: '40',
                                            calculations: true,
                                            children: [],
                                          },
                                        ],
                                      },
                                      {
                                        id: 17,
                                        calculation:
                                          'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                        cc_id: 'CC_5',
                                        MT: '40',
                                        calculations: true,
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 18,
                                    calculation:
                                      'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                    cc_id: 'CC_5',
                                    MT: '40',
                                    calculations: true,
                                    children: [
                                      {
                                        id: 19,
                                        calculation:
                                          'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                        cc_id: 'CC_67',
                                        MT: '70',
                                        calculations: true,
                                        children: [],
                                      },
                                      {
                                        id: 20,
                                        calculation:
                                          'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                        cc_id: 'CC_34',
                                        MT: '80',
                                        calculations: true,
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                id: 21,
                                calculation:
                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                cc_id: 'CC_5',
                                MT: '40',
                                calculations: true,
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 22,
                            calculation:
                              'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                            cc_id: 'CC_5',
                            MT: '40',
                            calculations: true,
                            children: [
                              {
                                id: 23,
                                calculation:
                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
                                cc_id: 'CC_78',
                                MT: '90',
                                calculations: true,
                                children: [],
                              },
                              {
                                id: 24,
                                calculation:
                                  'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                                cc_id: 'CC_23',
                                MT: '20',
                                calculations: true,
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 25,
                        calculation:
                          'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
                        cc_id: 'CC_5',
                        MT: '40',
                        calculations: true,
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 26,
                    calculation: 'Areal average methane emissions reductions',
                    cc_id: 'CC_43',
                    MT: '750',
                    calculations: true,
                    children: [],
                  },
                ],
              },
              {
                id: 27,
                calculation: 'Nitrous oxide reductions from de/nitrification',
                cc_id: 'CC_49',
                MT: '1000',
                calculations: true,
                children: [],
              },
            ],
          },
          {
            id: 28,
            calculation:
              'Nitrous oxide reductions from avoided biomass burning',
            cc_id: 'CC_50',
            MT: '2500',
            calculations: true,
            children: [],
          },
        ],
      },
      {
        id: 29,
        calculation: 'Net estimated GHG emissions reductions',
        cc_id: 'CC_36',
        MT: '2500',
        calculations: true,
        children: [],
      },
    ],
  },
  {
    id: 30,
    calculation: 'Gross estimated GHG emissions reductions and removals',
    cc_id: 'CC_32',
    MT: '000,000,000',
    calculations: false,
    children: [
      {
        id: 31,
        calculation:
          'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
        cc_id: 'CC_35',
        MT: '500000',
        calculations: true,
        children: [],
      },
      {
        id: 32,
        calculation:
          'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
        cc_id: 'CC_38',
        MT: '1210000',
        calculations: true,
        children: [
          {
            id: 34,
            calculation:
              'Carbon dioxide emissions from fossil fuel combustion in equipment - gasoline ',
            cc_id: 'CC_27',
            MT: '3400',
            calculations: true,
            children: [],
          },
          {
            id: 35,
            calculation:
              'Carbon dioxide emissions from fossil fuel combustion in equipment - diesel',
            cc_id: 'CC_29',
            MT: '8980',
            calculations: true,
            children: [],
          },
        ],
      },
    ],
  },
];
