const InsetPage = () => {
  return (
    <>
      <iframe
        data-testid="portfolio-figma-embed"
        style={{
          height: '110vh',
          width: '100%',
          'margin-top': '-6%',
          'margin-left': '-7%',
        }}
        allowFullScreen
        frameBorder="0"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FixkZDjmazj429bchpNdXbV%2FClearCarbon---Portfolio-Management%3Fpage-id%3D23604%253A237354%26type%3Ddesign%26node-id%3D55437-72811%26viewport%3D222%252C146%252C0.15%26t%3DzhreZGv1QKzguSHW-1%26scaling%3Dcontain%26starting-point-node-id%3D55437%253A72811%26hotspot-hints%3D0%26mode%3Ddesign&hide-ui=1"></iframe>
    </>
  );
};

export default InsetPage;
