import styled from 'styled-components';
import { DIM_BLUE_BACKGROUND, QUILL_GRAY } from 'theme/GlobalColors';

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${QUILL_GRAY};
  padding: 8px;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  height: 2.75rem;
  box-sizing: border-box;
  border-radius: 2px;
  &:hover {
    background-color: ${DIM_BLUE_BACKGROUND};
  }
`;

export const IconStyle = {
  display: 'flex',
  alignItems: 'center',
};
