import React, { useContext, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { Box } from '@mui/material';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import { useStyles } from 'theme/GlobalStyles';
import Header from './header/Header.react';
import Footer from './footer/Footer.react';
import Sidenav from './sidenav/Sidenav.react';
import AddButton from 'components/AddButton/AddButton.react';
import { checkTernaryCondition, ternaryBooleanHandler } from 'utils/helper';
import {
  assetsButtonOption,
  LABELS,
  originationAddNewButtonOptions,
} from './Layout.constants';
import { navLinks } from './sidenav/Sidenav.constants';
import { AddNewButtonSx } from './Layout.style';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';

const MainLayout = () => {
  const { MainContainer, MainContent } = useStyles();
  const { isAccessDenied /* ,loading */ } = useContext(userAuthRolesContext);
  const location = useLocation();
  const navigate = useNavigate();

  const currentModule = useMemo(() => {
    const currentPath = location.pathname;
    return checkTernaryCondition(
      currentPath.includes('/assets/'),
      'assets',
      checkTernaryCondition(
        currentPath.includes('/origination/'),
        'origination',
        '',
      ),
    );
  }, [location.pathname]);

  const showAddNewButton = useMemo(() => {
    const currentPath = location.pathname;
    return navLinks.assetsModuleLinks
      .map((link) => link.path)
      .includes(currentPath);
  }, [location.pathname]);

  return (
    <>
      <NotificationLogProvider>
        <Header />
      </NotificationLogProvider>
      <MainContainer disableGutters maxWidth="xl">
        {!isAccessDenied && <Sidenav />}
        <MainContent
          marginLeft={isAccessDenied && '0rem'}
          fullWidth={isAccessDenied}>
          <Outlet />
        </MainContent>
      </MainContainer>
      <Footer />
    </>
  );
};

export default MainLayout;
