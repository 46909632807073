import { ErrorWrapper, FormLabels } from '../FormStyles';
import { Autocomplete } from '@react-google-maps/api';
import { AutocompleteInput } from './GoogleMapSearchAutocomplete.style';
import { useContext } from 'react';
import { mapContext } from 'contextAPI/mapContext';
import PropTypes from 'prop-types';

const GoogleMapSearchAutocomplete = ({
  hasError,
  label,
  onPlaceChanged,
  onSearchLoad,
  value,
  valueChangeHandler,
  errorMsg,
  placeholder,
  labelMarginTop,
  labelMarginBottom,
  isDisabled = false,
}) => {
  const { isMapLoaded } = useContext(mapContext);

  return (
    isMapLoaded && (
      <div>
        <FormLabels
          marginTop={labelMarginTop}
          marginBottom={labelMarginBottom}
          iserror={hasError}>
          {label}
        </FormLabels>
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onSearchLoad}
          options={{ componentRestrictions: { country: 'us' } }}>
          <AutocompleteInput
            hasError={hasError}
            isDisabled={isDisabled}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={valueChangeHandler}
          />
        </Autocomplete>
        {hasError && <ErrorWrapper>{errorMsg}</ErrorWrapper>}
      </div>
    )
  );
};

GoogleMapSearchAutocomplete.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  onPlaceChanged: PropTypes.func,
  onSearchLoad: PropTypes.func,
  value: PropTypes.string,
  valueChangeHandler: PropTypes.func,
  errorMsg: PropTypes.string,
  placeholder: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
};

export default GoogleMapSearchAutocomplete;
