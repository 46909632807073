import styled from 'styled-components';

export const SubScriptWrapper = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #63666a;
`;

export const StrikeOutText = styled.span`
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: line-through;
  color: ${({ color }) => color};
  padding-right: 10px;
`;
export const ValueWrapper = styled.div`
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  width: ${({ width }) => width};
  padding: 0.5rem;
  word-break: break-all;
`;
export const FinalStatment = styled.div`
  color: #26890d;
  font-size: 14px;
  padding: 0rem 0.5rem;
  margin-top: 12px;
`;
