import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  padding: 0rem;
  background-color: ${WHITE};
`;

export const FormHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  color: ${COOL_GRAY};
  margin-top: 1.5rem;
`;

export const LocationFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
`;

export const ProducerIdFieldWrapper = styled.div`
  padding-left: 0.5rem;
`;

export const disabledStyle = (isEditable) => {
  return {
    opacity: !isEditable ? 0.5 : 1,
    pointerEvents: !isEditable ? 'none' : '',
  };
};

export const ParticipantNameSection = styled.div`
  display: flex;
  width: 100%;
  grid-column-gap: 1rem;
  margin-top: 1.5rem;
`;

export const MainHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  overflow: hidden;
  min-height: 48px;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  background-color: #ffffff;
  max-width: 90%;
  top: 0;
  z-index: 20;
  padding-left: 1.5rem;
`;

export const motivationSx = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    gridColumnGap: '1.75rem',
    '& p': {
      marginTop: '0.5rem',
      width: '40%',
    },
    '& .MuiFormGroup-root': {
      gridColumnGap: '1.75rem',
    },
    '& label': {
      marginRight: '0rem',
      marginLeft: '0rem',
      '& .MuiTypography-root': {
        marginRight: '0.5rem',
      },
    },
  };
};

export const MotivationHelpText = styled.div`
  display: flex;
  width: 80%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  grid-column-gap: 1.75rem;
  & div {
    width: 13rem;
    & p {
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
  & div {
    display: flex;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
    & span {
      margin-right: 2rem;
      width: 1rem;
    }
  }
`;
export const InputSx = {
  fontSize: '0.875rem',
  height: '2rem',
  '& input': {
    padding: '0.25rem 0 0.25rem 0.5rem',
  },
  '& input::placeholder': {
    color: COOL_GRAY,
  },
};
export const autoCompleteSx = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.125rem',
    '& input': {
      fontSize: '0.875rem',
    },
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.688rem',
    margin: 0,
  },
};
export const checkBoxSx = {
  '& .MuiFormControlLabel-root': {
    margin: 0,
  },
};
export const communicationCheckboxPadding = '0rem 0.5rem 0rem 0.563rem';
export const motivationCheckboxPadding = '0rem';
export const ContentWrapper = styled.div`
  width: 552px;
`;
export const ScrollWrapper = styled.div`
  padding: 0rem 1.5rem 1.5rem;
  overflow: auto;
  background-color: ${WHITE};
  max-height: 37.5rem;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;
