import styled from 'styled-components';
import { GLOBAL_BLUE } from 'theme/GlobalColors';

export const AddButtonWrapper = styled.div`
  cursor: pointer;
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
  border: ${({ hasBorder }) =>
    hasBorder ? `1px solid ${GLOBAL_BLUE}` : 'none'};
  border-radius: ${({ hasBorder }) => (hasBorder ? '0.25rem' : '0')};
  padding: ${({ hasBorder }) => (hasBorder ? ' 0.2rem 0.5rem' : '0')};
  width: ${({ width }) => (width ? 'fit-content' : 'auto')};
`;

export const AddButtonLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${GLOBAL_BLUE};
  margin-left: 0.375rem;
`;
