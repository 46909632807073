import PropTypes from 'prop-types';
import {
  ContentWrapper,
  MainWrapper,
  TextWrapper,
  btnSx,
} from './CalculationEmptyStateComp.style';
import AddButton from 'components/AddButton/AddButton.react';
import { NEW_DARK_GREEN, WHITE } from 'theme/GlobalColors';

const CalculationEmptyStateComp = ({ noCalcDataTitle, buttonLabel }) => {
  return (
    <MainWrapper className="curved-border">
      <ContentWrapper>
        <TextWrapper>{noCalcDataTitle}</TextWrapper>
        <AddButton
          buttonName={buttonLabel}
          noDropdown={true}
          backgroundColor={NEW_DARK_GREEN}
          textColor={WHITE}
          disableButton={true}
          customBtnSx={btnSx}
        />
      </ContentWrapper>
    </MainWrapper>
  );
};

CalculationEmptyStateComp.propTypes = {
  noCalcDataTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
};

export default CalculationEmptyStateComp;
