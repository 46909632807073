import { SubScriptWrapper } from './DeliveryDetails.style';

// const
export const LABELS = {
  amount: 'Amount',
  ocuursOn: 'Occurs on',
  deliveryStatus: 'Delivery Status',
  mtCo2: (
    <SubScriptWrapper>
      {' MT CO'} <sub>2</sub>e
    </SubScriptWrapper>
  ),
};
