import { DARK_CHARCOAL, DARK_GREEN, WHITE } from 'theme/GlobalColors';

export const DialogActionStyle = {
  padding: '0rem',
  marginTop: '0.5rem',
  justifyContent: 'flex-end',
  gridColumnGap: '1rem',
};
export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      borderRadius: '0.125rem',
      width: '60%',
      maxWidth: '34%',
      padding: '1.5rem',
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
  },
};
export const DialogTitleStyle = {
  padding: '0rem',
  fontSize: '1.25rem',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: '400',
  lineHeight: '1.75rem',
  display: 'flex',
  justifyContent: 'space-between',
  color: DARK_CHARCOAL,
  marginBottom: '1rem',
};
export const DialogContentStyle = {
  padding: '0rem',
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '0.875rem',
  fontWeight: '400',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  boxSizing: 'border-box',
  marginBottom: '1rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  color: DARK_CHARCOAL,
};
export const DialogSubHeaderStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '0.875rem',
  fontWeight: '400',
  color: DARK_CHARCOAL,
};
export const linkBtnStyle = () => ({
  textDecoration: 'none',
  color: WHITE,
});
export const CancelBtnStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
  padding: '0.625rem 1rem',
};
export const RadioGroupStyle = {
  gap: '1rem',
  '& .MuiFormControlLabel-root': {
    flex: 1,
    marginLeft: '-0.625rem',
  },
};
export const SendRequestBtnStyle = {
  color: WHITE,
  backgroundColor: DARK_GREEN,
  borderRadius: '0.25rem',
  textTransform: 'none',
  padding: '0.625rem 1rem',
  fontFamily: 'Open sans, sans serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  height: '2.25rem',
  '&:hover': {
    color: WHITE,
    backgroundColor: DARK_GREEN,
  },
};
