import { StyleSheet } from '@react-pdf/renderer';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';

export const styles = StyleSheet.create({
  row: {
    fontWeight: 400,
    fontSize: 14,
    color: DARK_CHARCOAL,
  },
  bullet: {
    height: '100%',
  },
  para: {
    fontWeight: 400,
    fontSize: 14,
    color: DARK_CHARCOAL,
    paddingRight: 5,
    marginTop: '7px',
  },
  subheading: {
    fontSize: '14px',
    color: COOL_GRAY,
  },
});
