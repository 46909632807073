export const STATUS_CONTENT = {
  loading: 'Loading',
  upload_failed: 'Upload Failed',
  run_cals: 'Run Calcs',
  success: 'Success',
  cals_failed: 'Calcs Failed',
  running: 'Running',
  uploading: 'Uploading',
  not_uploaded: 'Not Uploaded',
  run_calcs_error_title: 'Calculation failed to complete',
  run_calcs_error_message:
    'An error was encountered running the calculations, please upload the file and run the calculations again.',
  upload_failed_technical_error:
    'We experienced a technical issue and the file replacement could not be completed. Please try uploading the file again.',
  upload_failed_invalid_data:
    'Invalid data, the value of some fields is not valid. Please make the necessary corrections and try uploading the file again.',
  upload_failed_error_title: 'Unable to replace data',
};
