import { VENDOR_SUBMIT } from '../urls';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { pathName } from 'Routes/routes.const';

export const useVendorDetails = () => {
  const [postSuccess, setPostSuccess] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const postVendorDetail = async (postVendorData, editMode) => {
    setLoading(true);
    let successMessage = !editMode
      ? `Success! ${postVendorData.vendorOrganization} has been added to your portfolio.`
      : `Success! ${postVendorData.vendorOrganization} has been updated.`;
    let errorMessage = !editMode
      ? `Error ${postVendorData.vendorOrganization} has not been added to your portfolio. Try Again`
      : `Error ${postVendorData.vendorOrganization} has not been updated. Try Again`;
    await axios
      .post(VENDOR_SUBMIT, postVendorData)
      .then(() => {
        toast(<CustomSnackbar type="success" message={successMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast(<CustomSnackbar type="error" message={errorMessage} />, {
          position: 'top-center',
          autoClose: 20000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        if (editMode) {
          navigate(pathName.assets.stakeholders.view);
        } else {
          navigate(-1);
        }
        setLoading(false);
      });
  };

  return {
    postVendorDetail,
    setPostSuccess,
    postSuccess,
    loading,
  };
};
