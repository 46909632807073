import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import EmptyStateComponent from './EmptyStateComponent.react';
import { checkTernaryCondition } from 'utils/helper';
import CropTypeModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/CropTypeModal/CropTypeModal.react';
import FossilFuelTypeModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/FossilFuelTypeModal/FossilFuelTypeModal.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { PAGE_CONTENT } from '../BaseLineReporting/BaseLineReportingMainPage.content';
import { Button } from '@mui/material';
import {
  ButtonsWrapper,
  buttonStyleClose,
  fieldLabelStyle,
  fieldUnitStyle,
  secondaryLabelStyle,
  CashCropBox,
  CopyDisclaimer,
  smallBtn,
  iconWithBtnStyle,
} from './TabBodyComponent.style';
import {
  cropList,
  BASELINE_MODAL_CONTENT,
  tractorVehicleList,
  deletedModalsInitialData,
  edgeOfFiledList,
  getProductionPurposeValue,
  HeadersComp,
} from './TabBodyComponent.content';
import BaselineAccordionDisplayBox from './AccordionDisplayBox/AccordionDisplayBox.react';
import UserNavigationButtons from 'pages/ParticipantDataCollection/components/UserNavigationButtons/UserNavigationButtons.react';
import {
  FETCH_ACTIVITY_LIST,
  FETCH_BASELINE_FARM_DATA,
  GET_BASELINE_FIELD_DATA,
} from 'urls';
import { useSearchParams } from 'react-router-dom';
import NitrogentFertilizerModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/NitrogenFertilizerModal/NitrogenFertilizerModal.react';
import ResidueManagementModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/ResidueManagementModal/ResidueManagementModal.react';
import CoverCropModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/CoverCropModal/CoverCropModal.react';
import IrrigationModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/IrrigationModal/IrrigationModal.react';
import TillageModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/TillageModal/TillageModal.react';
import SoilAmendmentsModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/SoilAmendmentsModal/SoilAmendmentsModal.react';
import LivestockTypeModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/LivestockTypeModal/LivestockTypeModal.react';
import EdgeOfFieldModal from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/EdgeOfFieldModal/EdgeOfFieldModal.react';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import CopyModal from './CopyModal/CopyModal.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import {
  cleanDatesAndIds,
  copyFossilFuelRepeatedItems,
  editStateSetter,
  extractBaselineFieldData,
  findDataForSingleModalDelete,
  findDeleteAndPresentPracticeList,
  getIsPracticeModalMandatory,
  getPracticeCount,
  initialDeleteState,
  onClickEmptyState,
} from './TabBodyComponent.functions';
import { useReportingApis } from 'hooks/useReportingApis';
import { ReactComponent as AddIcon } from 'assets/icons/plusIcon.svg';
import BaselineHeaderComponent from '../components/BaselineHeaderComponent/BaselineHeaderComponent.react';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const modalOpenState = { flag: false, id: 0, data: null, currentRowId: 0 };

const TabBodyComponent = ({
  year,
  type,
  sampleId,
  setLoading,
  fieldType,
  fieldId,
  farmId,
  totalFieldArea,
  enrollmentYearData,
  setFieldDetails,
  setSubmitData,
  saveDataHandler,
  setIsInitialLoad,
  fetchLastSectionData,
  setFetchLastSectionData,
  setUserActivityDetected,
  setEnrollmentYears,
  typeOfReporting,
  isInActivity = false,
  fetchActivityFieldData = false,
  baselineApprovedFarmIds = [],
  baselineApprovedFieldIds = [],
}) => {
  const {
    fossilFuelTypeDropdown,
    cropTypeDropDown,
    practiceDropdown,
    irrigationMethodDropDown,
    speciesCategory,
    tillageTypeDropdown,
    tillIds,
    soilAmendmentsDropdown,
    fertilizerTypeDropDown,
    animalTypeDropdown,
    manureTypeDropdown,
    fertilizerApplicationMethod,
    livestockTypeDropdown,
    residueTypeDropdown,
    grazingTypeDropDown,
    manureManagementSystemDropdown,
    coverCropTypeDropdown,
    plannedUsage,
    terminationType,
    getUpdatedParentState,
  } = useContext(baselineDropdownContext);
  const practiceModalDropdowns = {
    irrigationMethodDropDown: irrigationMethodDropDown,
    practiceDropdown: practiceDropdown,
    speciesCategory: speciesCategory,
    tillageTypeDropdown: tillageTypeDropdown,
    tillIds: tillIds,
    soilAmendmentsDropdown: soilAmendmentsDropdown,
    fertilizerTypeDropDown: fertilizerTypeDropDown,
    animalTypeDropdown: animalTypeDropdown,
    manureTypeDropdown: manureTypeDropdown,
    fertilizerApplicationMethod: fertilizerApplicationMethod,
    livestockTypeDropdown: livestockTypeDropdown,
    grazingTypeDropDown: grazingTypeDropDown,
    manureManagementSystemDropdown: manureManagementSystemDropdown,
    coverCropTypeDropdown: coverCropTypeDropdown,
    plannedUsage: plannedUsage,
    terminationType: terminationType,
    residueTypeDropdown: residueTypeDropdown,
  };
  const { reportingFetchAPI } = useReportingApis();
  const [parentState, setParentState] = useState([]);
  const [eofState, setEofState] = useState([
    {
      id: '',
      data: {
        area: '',
        barrierWidth: '',
        id: '',
        practiceType: 0,
        rowCount: '',
        speciesCategory: 0,
        speciesDensity: '',
        stripWidth: '',
      },
    },
  ]);

  const [practiceModalsData, setPracticeModalsData] = useState({
    nitrogenList: [],
    fossilFuelList: [],
    irrigationTypeList: [],
    livestockList: [],
    soilAmendmentList: [],
    coverCropList: [],
    tillageTypeDtoList: [],
    residueManagementTypeList: [],
    edgeOfFieldList: [],
  });
  const [deletePracticeModalsData, setDeletePracticeModalsData] = useState(
    deletedModalsInitialData,
  );
  const [isCropTypeModalOpen, setIsCropTypeModalOpen] =
    useState(modalOpenState);
  const [isTractorModalOpen, setIsTractorModalOpen] = useState(modalOpenState);
  const [isResidueManagementModalOpen, setIsResidueManagementModalOpen] =
    useState(modalOpenState);
  const [isNitrogenModalOpen, setIsNitrogenModalOpen] =
    useState(modalOpenState);
  const [isCoverCropModalOpen, setIsCoverCropModalOpen] =
    useState(modalOpenState);
  const [isIrrigationModalOpen, setIsIrrigationModalOpen] =
    useState(modalOpenState);
  const [isTillageModalOpen, setIsTillageModalOpen] = useState(modalOpenState);
  const [isSoilAmendmentsModalOpen, setIsSoilAmendmentsModalOpen] =
    useState(modalOpenState);
  const [isLivestockTypeModalOpen, setIsLivestockTypeModalOpen] =
    useState(modalOpenState);
  const [isEdgeOfFieldModalOpen, setIsEdgeOfFieldModalOpen] =
    useState(modalOpenState);
  const [activityList, setActivityList] = useState([]);
  const [deleteData, setDeleteData] = useState(initialDeleteState);
  const [editMode, setEditMode] = useState(false);
  const [edgeOfFieldSelectedPracticeIds, setEdgeOfFieldSelectedPracticeIds] =
    useState([]);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [isReplacePromptOpen, setIsReplacePromptOpen] = useState(false);
  const [replacePromptItems, setReplacePromptItems] = useState([]);
  const [replacePromptIndex, setReplacePromptIndex] = useState(-1);
  const [currentId, setCurrentId] = useState(null);
  const [updatedParentState, setUpdatedParentState] = useState([]);
  const initialPracticesData = [
    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.fertilizer,
      modalList: BASELINE_MODAL_CONTENT.fertilizer_list,
      isMandatory: true,
      isAddedThroughCashCrop: false,
      activityListValue: 'Improve nutrient/fertilizer management',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsNitrogenModalOpen, itemId),
    },

    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.irrigation,
      modalList: BASELINE_MODAL_CONTENT.irrigation_list,
      isMandatory: true,
      activityListValue: 'Improve water management/irrigation',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsIrrigationModalOpen, itemId),
    },
    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.tillage,
      modalList: BASELINE_MODAL_CONTENT.tillage_list,
      isMandatory: true,
      activityListValue: 'Reduce tillage/improved residue management',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsTillageModalOpen, itemId),
    },
    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.residue_mgmt,
      modalList: BASELINE_MODAL_CONTENT.residue_mgmt_list,
      isMandatory: true,
      activityListValue: 'Reduce tillage/improved residue management',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsResidueManagementModalOpen, itemId),
    },
    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.soil_amendments,
      modalList: BASELINE_MODAL_CONTENT.soil_amendments_list,
      isMandatory: false,
      activityListValue: '',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsSoilAmendmentsModalOpen, itemId),
    },
    {
      id: uniqueId(),
      label: BASELINE_MODAL_CONTENT.livestock,
      modalList: BASELINE_MODAL_CONTENT.livestock_list,
      isMandatory: false,
      activityListValue: 'Improve  grazing management',
      clickHandler: (itemId) => () =>
        onClickEmptyState(year, setIsLivestockTypeModalOpen, itemId),
    },
  ];
  const [practicesData, setPracticesData] = useState([]);
  const disableAllFarmActions = baselineApprovedFarmIds?.includes(farmId);
  const disableAllFieldActions = baselineApprovedFieldIds?.includes(fieldId);

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makePageDirty = () => setUserActivityDetected(true);

  useEffect(() => {
    if (fieldType === PAGE_CONTENT.crop) {
      const practiceDataArray = [];
      parentState?.forEach((item) => {
        const cropId = item?.id;
        practiceDataArray.push({
          parentId: cropId,
          data: initialPracticesData.map((obj) => {
            const isMappedActivity =
              obj.label === BASELINE_MODAL_CONTENT.livestock;
            return {
              ...obj,
              isMandatory: checkTernaryCondition(
                isMappedActivity,
                getIsPracticeModalMandatory(
                  activityList,
                  item.activityList || [],
                ).includes(obj.activityListValue),
                obj.isMandatory,
              ),
            };
          }),
        });
      });
      setPracticesData(practiceDataArray);
    }
  }, [parentState]);

  useEffect(() => {
    if (
      activityList?.length > 0 &&
      fieldType === PAGE_CONTENT.crop &&
      currentId !== null
    ) {
      const uncheckedPracticesArray = [];
      practicesData?.forEach((item) => {
        item.data?.forEach((obj) => {
          if (
            !obj.isMandatory &&
            (obj.label === BASELINE_MODAL_CONTENT.residue_mgmt ||
              obj.label === BASELINE_MODAL_CONTENT.livestock)
          ) {
            uncheckedPracticesArray.push({
              parentId: item.parentId,
              type: obj.label,
            });
          }
        });
      });
      handleClickUncheckDelete(uncheckedPracticesArray);
    }
  }, [parentState, practicesData]);

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const editDeleteModalTypeMap = {
    Fertilizer: {
      practiceList: BASELINE_MODAL_CONTENT.fertilizer_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.fertilizer_deleted_list,
      stateSetter: setIsNitrogenModalOpen,
    },
    Irrigation: {
      practiceList: BASELINE_MODAL_CONTENT.irrigation_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.irrigation_deleted_list,
      stateSetter: setIsIrrigationModalOpen,
    },
    'Livestock grazing': {
      practiceList: BASELINE_MODAL_CONTENT.livestock_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.livestock_deleted_list,
      stateSetter: setIsLivestockTypeModalOpen,
    },
    'Soil amendments': {
      practiceList: BASELINE_MODAL_CONTENT.soil_amendments_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.soil_amendments_deleted_list,
      stateSetter: setIsSoilAmendmentsModalOpen,
    },

    Tillage: {
      practiceList: BASELINE_MODAL_CONTENT.tillage_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.tillage_deleted_list,
      stateSetter: setIsTillageModalOpen,
    },
    'Residue management': {
      practiceList: BASELINE_MODAL_CONTENT.residue_mgmt_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.residue_mgmt_deleted_list,
      stateSetter: setIsResidueManagementModalOpen,
    },
    'Edge of field': {
      practiceList: BASELINE_MODAL_CONTENT.edge_of_field_list,
      deletedPracticeList: BASELINE_MODAL_CONTENT.edge_of_field_deleted_list,
      stateSetter: setIsEdgeOfFieldModalOpen,
    },
  };
  const toggleCopyModalOpen = () => {
    setCopyModalOpen((prev) => !prev);
  };
  const submitHandler = (data, id) => {
    const updatedData = checkTernaryCondition(
      parentState.some((item) => item.id === id),
      parentState.map((item) =>
        checkTernaryCondition(item.id === id, data, item),
      ),
      [...parentState, { ...data, id: id, isCopied: false }],
    );
    setParentState(updatedData);
    setIsInitialLoad(false);
    makePageDirty();
  };
  const submitPracticeHandler = (data, id, parentId, modalType) => {
    setIsInitialLoad(false);
    setPracticeModalsData((prevState) => {
      const updatedData = { ...prevState };
      const modalTypeMap = {
        nitrogenList: BASELINE_MODAL_CONTENT.fertilizer_list,
        irrigationTypeList: BASELINE_MODAL_CONTENT.irrigation_list,
        livestockList: BASELINE_MODAL_CONTENT.livestock_list,
        soilAmendmentList: BASELINE_MODAL_CONTENT.soil_amendments_list,
        coverCropList: BASELINE_MODAL_CONTENT.cover_crop_list,
        tillageTypeDtoList: BASELINE_MODAL_CONTENT.tillage_list,
        residueManagementTypeList: BASELINE_MODAL_CONTENT.residue_mgmt_list,
        edgeOfFieldList: BASELINE_MODAL_CONTENT.edge_of_field_list,
      };
      const listToUpdate = modalTypeMap[modalType];
      if (listToUpdate && prevState[listToUpdate]) {
        const newList = prevState[listToUpdate].slice();
        const existingIndex = newList.findIndex((item) => item.id === id);

        if (existingIndex !== -1) {
          newList[existingIndex] = parentId
            ? {
                id: id,
                data: { ...data, id: id },
                parentId: parentId,
              }
            : {
                id: id,
                data: { ...data, id: id },
              };
        } else {
          newList.push(
            parentId
              ? {
                  id: id,
                  data: { ...data, id: id },
                  parentId: parentId,
                }
              : {
                  id: id,
                  data: { ...data, id: id },
                },
          );
        }
        updatedData[listToUpdate] = newList;
      }
      return updatedData;
    });
    makePageDirty();
  };

  const handleClickEdit = (id, modalType, parentId) => {
    setIsInitialLoad(false);
    if (parentId !== null) {
      const targetModal = editDeleteModalTypeMap[modalType];
      const data = practiceModalsData?.[targetModal?.practiceList].find(
        (item) => item.id === id,
      );
      editStateSetter(
        setIsInitialLoad,
        year,
        id,
        targetModal?.stateSetter,
        data?.data,
        parentId,
      );
    } else if (modalType === PAGE_CONTENT.edge_of_field_label) {
      setCurrentId(id);
      const data = eofState.find((item) => item.id === id)?.data;
      editStateSetter(
        setIsInitialLoad,
        year,
        id,
        setIsEdgeOfFieldModalOpen,
        data,
      );
    } else {
      setCurrentId(id);
      const data = parentState.find((item) => item.id === id);
      if (modalType === PAGE_CONTENT.tractor_and_vehicle) {
        editStateSetter(
          setIsInitialLoad,
          year,
          id,
          setIsTractorModalOpen,
          data,
        );
      } else {
        editStateSetter(
          setIsInitialLoad,
          year,
          id,
          setIsCropTypeModalOpen,
          data,
        );
      }
    }
  };
  const handleClickUncheckDelete = (modalPracticeArray) => {
    setIsInitialLoad(false);
    const listValues = findDeleteAndPresentPracticeList(
      practiceModalsData,
      deletePracticeModalsData,
      modalPracticeArray,
      editDeleteModalTypeMap,
      currentId,
    );
    setPracticeModalsData({
      ...practiceModalsData,
      ...listValues[0],
    });
    setDeletePracticeModalsData((prevDeletePracticeModalsData) => {
      const updatedDeletePracticeModalsData = {
        ...prevDeletePracticeModalsData,
        ...listValues[1],
      };
      return updatedDeletePracticeModalsData;
    });
  };
  const handleWholeModalDeletion = (
    parentId,
    modalTypePracticeList,
    modalDeleteList,
  ) => {
    let removedObjects = [];
    const filterData = practiceModalsData?.[modalTypePracticeList].filter(
      (obj) => {
        if (obj.parentId === parentId) {
          removedObjects.push({ id: obj.id, parentId: obj.parentId });
          return false;
        }
        return true;
      },
    );
    setPracticeModalsData({
      ...practiceModalsData,
      [modalTypePracticeList]: filterData,
    });
    updateDeletePracticeModalsData(removedObjects, modalDeleteList);
  };

  const handleWholeEofModalDeletion = (
    modalTypePracticeList,
    modalDeleteList,
  ) => {
    let removedObjects = [];
    practiceModalsData?.[modalTypePracticeList].map((obj) => {
      removedObjects.push({ id: obj.id });
    });

    setPracticeModalsData({
      ...practiceModalsData,
      [modalTypePracticeList]: [],
    });
    setEofState([]);
    updateDeletePracticeModalsData(removedObjects, modalDeleteList);
  };
  const updateDeletePracticeModalsData = (removedObjects, modalDeleteList) => {
    setDeletePracticeModalsData((prevDeletePracticeModalsData) => ({
      ...prevDeletePracticeModalsData,
      [modalDeleteList]: [
        ...prevDeletePracticeModalsData[modalDeleteList],
        ...removedObjects,
      ],
    }));
  };

  const handleSinglePracticeDeletion = (
    deleteData,
    modalTypePracticeList,
    modalDeleteList,
  ) => {
    setPracticeModalsData({
      ...practiceModalsData,
      [modalTypePracticeList]: findDataForSingleModalDelete(
        deleteData,
        practiceModalsData,
        modalTypePracticeList,
      )[1],
    });
    updateDeletePracticeModalsData(
      findDataForSingleModalDelete(
        deleteData,
        practiceModalsData,
        modalTypePracticeList,
      )[0],
      modalDeleteList,
    );
  };

  const handleClickDelete = (parentId = null) => {
    makePageDirty();
    setIsInitialLoad(false);

    if (parentId !== null) {
      const targetModal = editDeleteModalTypeMap[deleteData?.modalType];
      const modalTypePracticeList = targetModal?.practiceList;
      const modalDeleteList = targetModal?.deletedPracticeList;
      if (modalTypePracticeList) {
        if (deleteData?.isWholeModalDeleted) {
          handleWholeModalDeletion(
            parentId,
            modalTypePracticeList,
            modalDeleteList,
          );
        } else {
          handleSinglePracticeDeletion(
            deleteData,
            modalTypePracticeList,
            modalDeleteList,
          );
        }
        setIsInitialLoad(false);
      }
    } else if (deleteData?.isWholeModalDeleted) {
      setParentState([]);
    } else {
      const filterData = parentState.filter((obj) => obj.id !== deleteData?.id);
      setParentState(filterData);
    }

    setDeleteData({
      id: null,
      isWholeModalDeleted: false,
      modalType: '',
      practiceTypeValue: '',
      deleteModalHeading: '',
      deleteModalContent: '',
      parentId: null,
      cropType: '',
    });
  };
  const handleClickEofDelete = (parentId = null) => {
    makePageDirty();
    setIsInitialLoad(false);

    if (deleteData?.isWholeModalDeleted) {
      const targetModal = editDeleteModalTypeMap[deleteData?.modalType];
      const modalTypePracticeList = targetModal?.practiceList;
      const modalDeleteList = targetModal?.deletedPracticeList;
      if (modalTypePracticeList) {
        handleWholeEofModalDeletion(modalTypePracticeList, modalDeleteList);

        setIsInitialLoad(false);
      }
    } else {
      const targetModal = editDeleteModalTypeMap[deleteData?.modalType];
      const modalTypePracticeList = targetModal?.practiceList;
      const modalDeleteList = targetModal?.deletedPracticeList;
      if (modalTypePracticeList) {
        if (deleteData?.isWholeModalDeleted) {
          handleWholeModalDeletion(
            parentId,
            modalTypePracticeList,
            modalDeleteList,
          );
        } else {
          handleSinglePracticeDeletion(
            deleteData,
            modalTypePracticeList,
            modalDeleteList,
          );
        }
        setIsInitialLoad(false);
      }
      const filterData = eofState.filter((item) => item.id !== deleteData?.id);
      setEofState(filterData);
    }

    setDeleteData({
      id: null,
      isWholeModalDeleted: false,
      modalType: '',
      practiceTypeValue: '',
      deleteModalHeading: '',
      deleteModalContent: '',
      parentId: null,
      cropType: '',
    });
  };
  const handleDeleteData = (
    isWholeModalDeleted,
    modalType,
    practiceTypeValue,
    cropType,
  ) => {
    if (isWholeModalDeleted && modalType !== PAGE_CONTENT.cash_crop_label) {
      if (cropType !== '') {
        return {
          deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${cropType} - ${modalType}`,
          deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${cropType} - ${modalType}${PAGE_CONTENT.delete_content_second_part}`,
        };
      } else {
        return {
          deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${modalType}`,
          deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${modalType}${PAGE_CONTENT.delete_content_second_part}`,
        };
      }
    } else if (cropType !== '') {
      return {
        deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${cropType} - ${modalType} - ${practiceTypeValue}`,
        deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${cropType} - ${modalType} - ${practiceTypeValue}${PAGE_CONTENT.delete_content_second_part}`,
      };
    } else {
      return {
        deleteModalHeading: `${PAGE_CONTENT.delete_heading_first_part} ${year} - ${modalType} - ${practiceTypeValue}`,
        deleteModalContent: `${PAGE_CONTENT.delete_content_firt_part} ${year} - ${modalType} - ${practiceTypeValue}${PAGE_CONTENT.delete_content_second_part}`,
      };
    }
  };
  const fetchCropName = (cropId) => {
    return cropTypeDropDown.filter((item) => item.value == cropId)[0]?.label;
  };
  useEffect(() => {
    if (deleteData?.id !== null) {
      const { deleteModalHeading, deleteModalContent } = handleDeleteData(
        deleteData?.isWholeModalDeleted,
        deleteData?.modalType,
        deleteData?.practiceTypeValue,
        deleteData?.cropType,
      );
      setDeleteData({
        ...deleteData,
        deleteModalHeading: deleteModalHeading,
        deleteModalContent: deleteModalContent,
      });
    }
  }, [
    deleteData?.id,
    deleteData?.isWholeModalDeleted,
    deleteData.practiceTypeValue,
    deleteData?.cropType,
  ]);
  useEffect(() => {
    setParentState([]);
    if ((fieldType && sampleId) || fetchLastSectionData) {
      fetchFarmAndFieldsData();
      setCurrentId(null);
      setIsInitialLoad(true);
    }
  }, [
    fieldType,
    sampleId,
    fetchLastSectionData,
    enrollmentYearData.type,
    fetchActivityFieldData,
  ]);

  const additonOfPracticesCount = (parentId) => {
    return (
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.irrigation_list,
        BASELINE_MODAL_CONTENT.irrigation_nodata_option_key,
        BASELINE_MODAL_CONTENT.irrigation_nodata_option_value,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.residue_mgmt_list,
        BASELINE_MODAL_CONTENT.residue_mgmt_nodata_option_key,
        BASELINE_MODAL_CONTENT.residue_mgmt_nodata_option_value,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.fertilizer_list,
        BASELINE_MODAL_CONTENT.fertilizer_nodata_option_key,
        BASELINE_MODAL_CONTENT.fertilizer_nodata_option_value,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.cover_crop_list,
        BASELINE_MODAL_CONTENT.cover_crop_nodata_option_key,
        BASELINE_MODAL_CONTENT.cover_crop_nodata_option_value,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.tillage_list,
        BASELINE_MODAL_CONTENT.tillage_type_nodata_option_key,
        BASELINE_MODAL_CONTENT.tillage_type_nodata_option_value,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.soil_amendments_list,
      ) +
      getPracticeCount(
        practiceModalsData,
        parentId,
        BASELINE_MODAL_CONTENT.livestock_list,
      )
    );
  };

  const fetchFarmAndFieldsData = () => {
    if (
      fieldType === PAGE_CONTENT.farm &&
      enrollmentYearData?.type === 'farm'
    ) {
      setLoading(true);
      reportingFetchAPI(
        checkTernaryCondition(
          typeOfReporting === 'Baseline',
          FETCH_BASELINE_FARM_DATA,
          '',
        ),
        {
          params: { farmSampleId: sampleId },
        },
      )
        .then((response) => {
          setParentState(
            response.data.fossilFuels?.map((data) => ({
              id: data.farmSampleFfId,
              fossilFuelType: data.ffId,
              consumption: data.ffConsumption,
              unitOfMeasure: data.ffConsumptionUom,
              isCopied: data.isCopied,
            })),
          );
          setUserActivityDetected(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (
      fieldType === PAGE_CONTENT.crop &&
      enrollmentYearData?.type === 'field'
    ) {
      fetchBaseLineFieldData();
    }
    setFetchLastSectionData(false);
  };

  const fetchBaseLineFieldData = () => {
    setLoading(true);
    reportingFetchAPI(
      checkTernaryCondition(
        typeOfReporting === 'Baseline',
        `${GET_BASELINE_FIELD_DATA}?fieldId=${fieldId}&projectId=${projectId}&sampleId=${sampleId}`,
        '',
      ),
    )
      .then((response) => {
        if (
          response.data?.baselineFieldCrop?.length > 0 ||
          response.data?.edgeOfFieldDimList?.length > 0
        ) {
          const {
            practicesData,
            editMode,
            deletedPracticesData,
            cashCropList,
          } = extractBaselineFieldData(
            response.data,
            animalTypeDropdown,
            fertilizerTypeDropDown,
          );
          setPracticeModalsData({
            ...practiceModalsData,
            ...practicesData,
          });

          setDeletePracticeModalsData(deletedPracticesData);
          setEditMode(editMode);
          setParentState(cashCropList);
          setUserActivityDetected(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchActivityList = () => {
    reportingFetchAPI(
      checkTernaryCondition(
        typeOfReporting === 'Baseline',
        `${FETCH_ACTIVITY_LIST}?fieldId=${fieldId}&year=${year}`,
        '',
      ),
    ).then((response) => {
      if (response.data.length > 0) {
        setActivityList(
          response.data.map((item) => ({
            itemLabel: item.activityType,
            checked: false,
            isDisabled: false,
            id: item.activityId,
          })),
        );
      } else {
        setActivityList([]);
      }
    });
  };

  const saveClickHandler = (isSaveAndCloseClicked) => {
    saveDataHandler(isSaveAndCloseClicked, false, false);
  };

  useEffect(() => {
    if (fieldId !== 0 && year !== undefined) {
      fetchActivityList();
    }
  }, [fieldId, year]);

  useEffect(() => {
    setSubmitData({
      sampleId: sampleId,
      parentState: parentState,
      editMode: editMode,
      practiceModalsData: practiceModalsData,
      deletePracticeModalsData: deletePracticeModalsData,
      practicesData: practicesData,
    });
  }, [
    sampleId,
    parentState,
    editMode,
    practiceModalsData,
    deletePracticeModalsData,
    practicesData,
  ]);

  useEffect(() => {
    setEdgeOfFieldSelectedPracticeIds(
      practiceModalsData.edgeOfFieldList
        ?.filter((item) => item.parentId === isEdgeOfFieldModalOpen.parentId)
        .map((item) => item.data.practiceType),
    );
  }, [isEdgeOfFieldModalOpen]);

  const copyCashCrops = ({ data: cashCropList, combinations }) => {
    makePageDirty();

    const newCashcropDataList = cashCropList?.map((cashCropData) => {
      let { practicesData, cashCropList, deletedPracticesData } =
        extractBaselineFieldData(
          cashCropData,
          animalTypeDropdown,
          fertilizerTypeDropDown,
        );
      const newCropSampleId = uniqueId();

      cashCropList = cashCropList.filter((_, index) => index === 0);
      cashCropList[0].id = newCropSampleId;
      cashCropList[0].cropSampleId = newCropSampleId;
      cashCropList[0].cropTerminationDate = '';
      cashCropList[0].cropPlantDate = '';

      practicesData.coverCropList = cleanDatesAndIds(
        practicesData?.coverCropList,
        newCropSampleId,
        ['cropPlantDate', 'cropTerminateDate'],
      );
      practicesData.edgeOfFieldList = cleanDatesAndIds(
        practicesData?.edgeOfFieldList,
        newCropSampleId,
      );
      practicesData.irrigationTypeList = cleanDatesAndIds(
        practicesData?.irrigationTypeList,
        newCropSampleId,
      );
      practicesData.livestockList = cleanDatesAndIds(
        practicesData?.livestockList,
        newCropSampleId,
      );
      practicesData.nitrogenList = cleanDatesAndIds(
        practicesData?.nitrogenList,
        newCropSampleId,
        ['datesApplied'],
        true,
      );
      practicesData.residueManagementTypeList = cleanDatesAndIds(
        practicesData?.residueManagementTypeList,
        newCropSampleId,
      );
      practicesData.soilAmendmentList = cleanDatesAndIds(
        practicesData?.soilAmendmentList,
        newCropSampleId,
      );
      practicesData.tillageTypeDtoList = cleanDatesAndIds(
        practicesData?.tillageTypeDtoList,
        newCropSampleId,
        ['tillageDates'],
        true,
      );
      return {
        practicesData,
        cashCrop: { ...cashCropList[0], isCopied: true },
        deletedPracticesData,
      };
    });

    let uniqueItems = [],
      repeatedItems = [];

    for (const cashCropData of newCashcropDataList) {
      const isUnique =
        parentState?.findIndex(
          (cashcrop) => cashcrop.cropId === cashCropData.cashCrop?.cropId,
        ) === -1;

      if (isUnique) uniqueItems.push(cashCropData);
      else {
        const repeatingCombination = combinations.find(
          (combination) =>
            +combination.dropdown3Value === +cashCropData.cashCrop?.cropId,
        );
        const modalTitle = `${repeatingCombination?.dropdown1Label} - ${repeatingCombination?.dropdown2Label} - ${repeatingCombination?.dropdown3Label}`;
        repeatedItems.push({
          data: cashCropData,
          titlePart: modalTitle,
          replaceItemIdKey: 'cropId',
        });
      }
    }
    setParentState((prev) => [
      ...prev,
      ...uniqueItems.map((item) => item.cashCrop),
    ]);

    for (const uniqueItem of uniqueItems) {
      const practiceItem = uniqueItem.practicesData;
      setPracticeModalsData((prev) => ({
        ...prev,
        coverCropList: [...prev.coverCropList, ...practiceItem.coverCropList],
        edgeOfFieldList: [
          ...prev.edgeOfFieldList,
          ...practiceItem.edgeOfFieldList,
        ],
        irrigationTypeList: [
          ...prev.irrigationTypeList,
          ...practiceItem.irrigationTypeList,
        ],
        livestockList: [...prev.livestockList, ...practiceItem.livestockList],
        nitrogenList: [...prev.nitrogenList, ...practiceItem.nitrogenList],
        residueManagementTypeList: [
          ...prev.residueManagementTypeList,
          ...practiceItem.residueManagementTypeList,
        ],
        soilAmendmentList: [
          ...prev.soilAmendmentList,
          ...practiceItem.soilAmendmentList,
        ],
        tillageTypeDtoList: [
          ...prev.tillageTypeDtoList,
          ...practiceItem.tillageTypeDtoList,
        ],
      }));
    }

    setReplacePromptItems(repeatedItems);
    setReplacePromptIndex(0);
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === farmId,
            {
              ...farm,
              mainStatus: 'in progress',
              fieldDetails: farm.fieldDetails.map((field) => {
                return checkTernaryCondition(
                  field.fieldId === fieldId,
                  { ...field, mainStatus: 'in progress' },
                  field,
                );
              }),
            },
            farm,
          );
        }),
      };
    });

    setEnrollmentYears((prev) =>
      prev.map((yearData) => {
        return checkTernaryCondition(
          yearData.sampleId === sampleId,
          {
            ...yearData,
            status: 'In Progress',
          },
          yearData,
        );
      }),
    );
  };

  const copyFossilFuels = ({ data: fossilFuelsList, combinations }) => {
    makePageDirty();
    const newList = fossilFuelsList.map((data) => ({
      id: uniqueId(),
      fossilFuelType: data.ffId,
      consumption: data.ffConsumption,
      unitOfMeasure: data.ffConsumptionUom,
      isCopied: true,
    }));
    setParentState((prev) => [
      ...prev,
      ...copyFossilFuelRepeatedItems(newList, parentState, combinations)[0],
    ]);
    setReplacePromptItems(
      copyFossilFuelRepeatedItems(newList, parentState, combinations)[1],
    );
    setReplacePromptIndex(0);
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === farmId,
            {
              ...farm,
              mainStatus: 'in progress',
            },
            farm,
          );
        }),
      };
    });
    setEnrollmentYears((prev) =>
      prev.map((yearData) => {
        return checkTernaryCondition(
          yearData.sampleId === sampleId,
          {
            ...yearData,
            status: 'In Progress',
          },
          yearData,
        );
      }),
    );
  };

  useEffect(() => {
    if (
      replacePromptIndex >= 0 &&
      replacePromptIndex < replacePromptItems?.length
    ) {
      setIsReplacePromptOpen(true);
    }
  }, [replacePromptIndex, replacePromptItems]);

  const acceptReplace = (replaceItem, replaceItemIdKey) => {
    if (type === PAGE_CONTENT.empty_farm_label) {
      setParentState((prev) => [
        ...prev.filter(
          (item) => item[replaceItemIdKey] !== replaceItem[replaceItemIdKey],
        ),
        replaceItem,
      ]);
    } else if (type === PAGE_CONTENT.empty_crop_label) {
      const cropIdToBeReplaced = replaceItem?.cashCrop?.cropId;
      const cropSampleIdToBeReplaced = parentState.find(
        (cashCrop) => cashCrop.cropId === cropIdToBeReplaced,
      )?.id;

      setParentState((prev) => [
        ...prev.filter((cashCrop) => cashCrop.cropId !== cropIdToBeReplaced),
        replaceItem.cashCrop,
      ]);
      setPracticeModalsData((prev) => {
        return {
          ...prev,
          coverCropList: [
            ...prev.coverCropList.filter(
              (covercrop) => covercrop.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.coverCropList,
          ],
          irrigationTypeList: [
            ...prev.irrigationTypeList.filter(
              (irrigationTypeData) =>
                irrigationTypeData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.irrigationTypeList,
          ],
          livestockList: [
            ...prev.livestockList.filter(
              (livestockData) =>
                livestockData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.livestockList,
          ],
          nitrogenList: [
            ...prev.nitrogenList.filter(
              (nitrogenData) =>
                nitrogenData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.nitrogenList,
          ],
          residueManagementTypeList: [
            ...prev.residueManagementTypeList.filter(
              (residueManagementData) =>
                residueManagementData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.residueManagementTypeList,
          ],
          soilAmendmentList: [
            ...prev.soilAmendmentList.filter(
              (soilAmendmentData) =>
                soilAmendmentData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.soilAmendmentList,
          ],
          tillageTypeDtoList: [
            ...prev.tillageTypeDtoList.filter(
              (tillageData) =>
                tillageData.parentId !== cropSampleIdToBeReplaced,
            ),
            ...replaceItem.practicesData.tillageTypeDtoList,
          ],
        };
      });
    }
    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
  };

  const declineReplace = () => {
    setIsReplacePromptOpen(false);
    setReplacePromptIndex((prev) => prev + 1);
  };

  // Checking if we have an item(cash crop / Tractor_vehicle) which is copied from some other place(We are tracking the copied status using "isCopied" flag)
  const copiedDataPresent = useMemo(
    () => parentState?.some((item) => item.isCopied === true),
    [parentState],
  );

  useEffect(() => {
    if (practiceModalsData?.edgeOfFieldList?.length > 0) {
      let finalArray = [];
      practiceModalsData.edgeOfFieldList.map((eofData, index) => {
        let Id = 0;
        let area = '';
        let barrierWidth = '';
        let id = '';
        let practiceType = 0;
        let rowCount = '';
        let speciesCategory = 0;
        let speciesDensity = '';
        let stripWidth = '';
        let addedFromImplementedActivities = false;
        let currentData = {
          area,
          barrierWidth,
          id,
          practiceType,
          rowCount,
          speciesCategory,
          speciesDensity,
          stripWidth,
          addedFromImplementedActivities,
        };
        Id = practiceModalsData?.edgeOfFieldList[index]?.id;
        currentData.area =
          practiceModalsData?.edgeOfFieldList[index]?.data?.area;
        currentData.barrierWidth =
          practiceModalsData?.edgeOfFieldList[index]?.data?.barrierWidth;
        currentData.id = practiceModalsData?.edgeOfFieldList[index]?.data?.id;
        currentData.practiceType =
          practiceModalsData?.edgeOfFieldList[index]?.data?.practiceType;
        currentData.rowCount =
          practiceModalsData?.edgeOfFieldList[index]?.data?.rowCount;
        currentData.speciesCategory =
          practiceModalsData?.edgeOfFieldList[index]?.data?.speciesCategory;
        currentData.speciesDensity =
          practiceModalsData?.edgeOfFieldList[index]?.data?.speciesDensity;
        currentData.stripWidth =
          practiceModalsData?.edgeOfFieldList[index]?.data?.stripWidth;
        currentData.addedFromImplementedActivities =
          practiceModalsData?.edgeOfFieldList[
            index
          ]?.data?.addedFromImplementedActivities;
        finalArray.push({ id: Id, data: currentData });
      });
      setEofState(
        Array.from(new Set(finalArray.map(JSON.stringify))).map(JSON.parse),
      );
    } else {
      setEofState([]);
    }
  }, [practiceModalsData.edgeOfFieldList]);

  useEffect(() => {
    setUpdatedParentState([]);
    if (parentState.length > 0 && enrollmentYearData?.type === 'field') {
      setLoading(true);
      (async () => {
        const data = await getUpdatedParentState(parentState);
        setUpdatedParentState(data);
        setLoading(false);
      })();
    }
  }, [parentState]);

  useEffect(() => {
    if (
      (updatedParentState[0]?.tillageSystem !==
        BASELINE_MODAL_CONTENT.no_tillage &&
        updatedParentState[0]?.tillageSystem !== undefined &&
        practiceModalsData?.tillageTypeDtoList[0]?.data?.tillagePractice ===
          7) ||
      (updatedParentState[0]?.tillageSystem ===
        BASELINE_MODAL_CONTENT.no_tillage &&
        updatedParentState[0]?.tillageSystem !== undefined &&
        practiceModalsData?.tillageTypeDtoList[0]?.data?.tillagePractice !== 7)
    ) {
      practiceModalsData.tillageTypeDtoList = [];
    }
  }, [updatedParentState]);

  return (
    <div>
      {(parentState?.length === 0 &&
        practiceModalsData.edgeOfFieldList.length === 0 && (
          <div>
            <ButtonsWrapper>
              {type === PAGE_CONTENT.empty_farm_label && (
                <Button
                  sx={[
                    buttonStyleClose(false),
                    primaryButtonStyle('4.5px 15px'),
                    smallBtn,
                  ]}
                  disabled={disableAllFarmActions}
                  onClick={() => {
                    onClickEmptyState(year, setIsTractorModalOpen);
                  }}>
                  {PAGE_CONTENT.addTractorVehicleBtn}
                </Button>
              )}
              <Button
                sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
                disabled={checkTernaryCondition(
                  type === PAGE_CONTENT.empty_farm_label,
                  disableAllFarmActions,
                  disableAllFieldActions,
                )}
                onClick={toggleCopyModalOpen}>
                {checkTernaryCondition(
                  type === PAGE_CONTENT.empty_farm_label,
                  PAGE_CONTENT.copy_button_label_tractors,
                  PAGE_CONTENT.copy_button_label_crop,
                )}
              </Button>
              {type === PAGE_CONTENT.empty_crop_label && (
                <Button
                  sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
                  disabled={disableAllFieldActions}
                  onClick={() => {
                    onClickEmptyState(year, setIsEdgeOfFieldModalOpen);
                  }}>
                  {PAGE_CONTENT.edge_of_field}
                </Button>
              )}
            </ButtonsWrapper>
            {isInActivity &&
              HeadersComp(year, type, type === PAGE_CONTENT.empty_farm_label)}
            {type === PAGE_CONTENT.empty_crop_label && (
              <EmptyStateComponent
                type={type}
                onClick={() => onClickEmptyState(year, setIsCropTypeModalOpen)}
              />
            )}
          </div>
        )) || (
        <div>
          <ButtonsWrapper>
            {type === PAGE_CONTENT.empty_crop_label && (
              <Button
                sx={[
                  buttonStyleClose(false),
                  primaryButtonStyle('4.5px 15px'),
                  smallBtn,
                  iconWithBtnStyle,
                ]}
                disabled={disableAllFieldActions}
                onClick={() => {
                  onClickEmptyState(year, setIsCropTypeModalOpen);
                }}>
                <AddIcon data-testid="add-icon" />
                {PAGE_CONTENT.add_cash_crop_button}
              </Button>
            )}
            {type === PAGE_CONTENT.empty_farm_label && (
              <Button
                sx={[
                  buttonStyleClose(false),
                  primaryButtonStyle('4.5px 15px'),
                  smallBtn,
                ]}
                onClick={() => {
                  onClickEmptyState(year, setIsTractorModalOpen);
                }}
                disabled={disableAllFarmActions}>
                {PAGE_CONTENT.addTractorVehicleBtn}
              </Button>
            )}
            <Button
              disabled={checkTernaryCondition(
                type === PAGE_CONTENT.empty_farm_label,
                disableAllFarmActions,
                disableAllFieldActions,
              )}
              sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
              onClick={toggleCopyModalOpen}>
              {checkTernaryCondition(
                type === PAGE_CONTENT.empty_farm_label,
                PAGE_CONTENT.copy_button_label_tractors,
                PAGE_CONTENT.copy_button_label_crop,
              )}
            </Button>
            {type === PAGE_CONTENT.empty_crop_label && (
              <Button
                disabled={disableAllFieldActions}
                sx={[buttonStyleClose(), secondaryButtonStyle, smallBtn]}
                onClick={() => {
                  onClickEmptyState(year, setIsEdgeOfFieldModalOpen);
                }}>
                {PAGE_CONTENT.edge_of_field}
              </Button>
            )}
          </ButtonsWrapper>
          {!isInActivity && (
            <BaselineHeaderComponent
              gap={'0.2rem'}
              isOptional={type === PAGE_CONTENT.empty_farm_label}
              direction="row"
              marginBottom="15px"
              color={DARK_CHARCOAL}
              title={checkTernaryCondition(
                type === PAGE_CONTENT.empty_farm_label,
                BASELINE_MODAL_CONTENT.baseline_header_farm,
                BASELINE_MODAL_CONTENT.baseline_header_field,
              )}
            />
          )}
          {isInActivity &&
            HeadersComp(year, type, type === PAGE_CONTENT.empty_farm_label)}
          {copiedDataPresent && (
            <CopyDisclaimer>
              {checkTernaryCondition(
                type === PAGE_CONTENT.empty_crop_label,
                PAGE_CONTENT.cashCropCopyDisclaimer,
                PAGE_CONTENT.farmCopyDisclaimer,
              )}
            </CopyDisclaimer>
          )}
          {(type === PAGE_CONTENT.empty_crop_label && (
            <CashCropBox border={updatedParentState.length > 1}>
              {updatedParentState?.map((crop, index) => {
                const keyVal = index;
                return (
                  <BaselineAccordionDisplayBox
                    copiedDataPresent={copiedDataPresent}
                    key={keyVal}
                    width={'100%'}
                    modalDataArray={cropList(
                      [updatedParentState[index]],
                      practiceModalsData,
                      practiceModalDropdowns,
                    )}
                    mainLabel={`${getProductionPurposeValue(
                      crop.productionPurpose,
                    )} - ${checkTernaryCondition(
                      crop.addedFromImplementedActivities &&
                        crop.cropArea === null,
                      '',
                      fetchCropName(crop.cropId),
                    )}`}
                    itemId={updatedParentState[keyVal]?.id}
                    handleClickDelete={handleClickDelete}
                    handleClickEdit={handleClickEdit}
                    deleteModalHeading={deleteData?.deleteModalHeading}
                    deleteModalContent={deleteData?.deleteModalContent}
                    hasAddBtn={!disableAllFieldActions}
                    hasRowLevelEditDelete={!disableAllFieldActions}
                    addBtnLabel={PAGE_CONTENT.cash_crop_add_btn}
                    secondaryLabelStyle={secondaryLabelStyle}
                    fieldLabelStyle={fieldLabelStyle}
                    fieldUnitStyle={fieldUnitStyle}
                    deleteData={deleteData}
                    setDeleteData={setDeleteData}
                    addNewBtnHandler={() => {}}
                    subText={`${additonOfPracticesCount(
                      updatedParentState[keyVal]?.id,
                    )} ${PAGE_CONTENT.practices_count}`}
                    allPracticesData={practicesData?.find(
                      (item) =>
                        item.parentId === updatedParentState[keyVal]?.id,
                    )}
                    hasPracticesDropdown={true}
                    isInActivity={isInActivity}
                  />
                );
              })}

              {practiceModalsData?.edgeOfFieldList?.length > 0 && (
                <BaselineAccordionDisplayBox
                  mainLabel={`${BASELINE_MODAL_CONTENT.edge_of_field} (${eofState.length})`}
                  modalDataArray={edgeOfFiledList(
                    eofState,
                    practiceDropdown,
                    speciesCategory,
                  )}
                  hasRowLevelEditDelete={!disableAllFieldActions}
                  hasAddBtn={!disableAllFieldActions}
                  addBtnLabel={PAGE_CONTENT.edge_of_field}
                  handleClickDelete={handleClickEofDelete}
                  handleClickEdit={handleClickEdit}
                  deleteModalHeading={deleteData?.deleteModalHeading}
                  deleteModalContent={deleteData?.deleteModalContent}
                  secondaryLabelStyle={secondaryLabelStyle}
                  fieldLabelStyle={fieldLabelStyle}
                  fieldUnitStyle={fieldUnitStyle}
                  deleteData={deleteData}
                  addNewBtnHandler={() => {
                    onClickEmptyState(year, setIsEdgeOfFieldModalOpen);
                  }}
                  setDeleteData={setDeleteData}
                  isInActivity={isInActivity}
                />
              )}
            </CashCropBox>
          )) || (
            <BaselineAccordionDisplayBox
              copiedDataPresent={copiedDataPresent}
              width={'100%'}
              modalDataArray={tractorVehicleList(
                parentState,
                fossilFuelTypeDropdown,
              )}
              mainLabel={`${PAGE_CONTENT.tractor_and_vehicle} (${parentState.length})`}
              itemId={parentState[0]?.id}
              handleClickDelete={handleClickDelete}
              handleClickEdit={handleClickEdit}
              deleteModalHeading={deleteData?.deleteModalHeading}
              deleteModalContent={deleteData?.deleteModalContent}
              hasRowLevelEditDelete={!disableAllFarmActions}
              hasAddBtn={!disableAllFarmActions}
              addBtnLabel={PAGE_CONTENT.tractor_and_vehicle_add_btn}
              secondaryLabelStyle={secondaryLabelStyle}
              fieldLabelStyle={fieldLabelStyle}
              fieldUnitStyle={fieldUnitStyle}
              deleteData={deleteData}
              setDeleteData={setDeleteData}
              addNewBtnHandler={() =>
                onClickEmptyState(year, setIsTractorModalOpen)
              }
              isInActivity={isInActivity}
            />
          )}
        </div>
      )}
      <UserNavigationButtons
        saveAndContinueHandler={() => {
          saveClickHandler(false);
        }}
        saveAndCloseHandler={() => {
          saveClickHandler(true);
        }}
        hideCancelBtn
      />
      <CropTypeModal
        modalOpen={isCropTypeModalOpen}
        setModalOpen={setIsCropTypeModalOpen}
        submitHandler={() => {}}
        id={isCropTypeModalOpen.id}
        setYearWiseCashCropList={() => {}}
        yearWiseCashCropList={[]}
        tabWiseSubmitHandler={submitHandler}
        activityList={activityList}
        setActivityList={setActivityList}
        totalFieldArea={totalFieldArea}
        existingCropTypeIds={parentState?.map((crop) => crop.cropId) || []}
      />
      <FossilFuelTypeModal
        modalOpen={isTractorModalOpen}
        setModalOpen={setIsTractorModalOpen}
        submitHandler={() => {}}
        tabWiseSubmitHandler={submitHandler}
        existingFuelTypeIds={
          parentState?.map(
            (tractorOrVehicle) => tractorOrVehicle.fossilFuelType,
          ) || []
        }
      />
      <NitrogentFertilizerModal
        modalOpen={isNitrogenModalOpen}
        setModalOpen={setIsNitrogenModalOpen}
        submitHandler={submitPracticeHandler}
        totalFieldArea={totalFieldArea}
        existingFertilizerTypeIds={
          practiceModalsData?.nitrogenList?.map(
            (item) => item.data.fertilizerType,
          ) || []
        }
      />
      <ResidueManagementModal
        modalOpen={isResidueManagementModalOpen}
        setModalOpen={setIsResidueManagementModalOpen}
        submitHandler={submitPracticeHandler}
        totalFieldArea={totalFieldArea}
      />
      <CoverCropModal
        modalOpen={isCoverCropModalOpen}
        setModalOpen={setIsCoverCropModalOpen}
        submitHandler={submitPracticeHandler}
        totalFieldArea={totalFieldArea}
      />
      <IrrigationModal
        modalOpen={isIrrigationModalOpen}
        setModalOpen={setIsIrrigationModalOpen}
        totalFieldArea={totalFieldArea}
        submitHandler={submitPracticeHandler}
      />
      <TillageModal
        modalOpen={isTillageModalOpen}
        setModalOpen={setIsTillageModalOpen}
        submitHandler={submitPracticeHandler}
        totalFieldArea={totalFieldArea}
      />
      <SoilAmendmentsModal
        modalOpen={isSoilAmendmentsModalOpen}
        setModalOpen={setIsSoilAmendmentsModalOpen}
        submitHandler={submitPracticeHandler}
      />
      <LivestockTypeModal
        modalOpen={isLivestockTypeModalOpen}
        setModalOpen={setIsLivestockTypeModalOpen}
        submitHandler={submitPracticeHandler}
        totalFieldArea={totalFieldArea}
      />
      <EdgeOfFieldModal
        totalFieldArea={totalFieldArea}
        modalOpen={isEdgeOfFieldModalOpen}
        id={isCropTypeModalOpen.id}
        tabWiseSubmitHandler={submitPracticeHandler}
        setModalOpen={setIsEdgeOfFieldModalOpen}
        existingPracticeTypeIds={edgeOfFieldSelectedPracticeIds || []}
      />
      <CopyModal
        open={copyModalOpen}
        modalCloseHandler={toggleCopyModalOpen}
        title={checkTernaryCondition(
          type === PAGE_CONTENT.empty_farm_label,
          PAGE_CONTENT.tractorOrVehicleCopyModalTitle,
          PAGE_CONTENT.fieldCropCopyModalTitle,
        )}
        apiParams={{
          sampleId: sampleId,
          participantId: participantId,
          farmId: farmId,
          fieldId: fieldId,
          projectCycleId: projectCycleId,
        }}
        copyHandler={checkTernaryCondition(
          type === PAGE_CONTENT.empty_farm_label,
          copyFossilFuels,
          copyCashCrops,
        )}
      />

      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isReplacePromptOpen}
        onConfirm={() => {
          acceptReplace(
            replacePromptItems[replacePromptIndex]?.data,
            replacePromptItems[replacePromptIndex]?.replaceItemIdKey,
          );
        }}
        subtitle={`${PAGE_CONTENT.replaceModalContentPrefix} ${replacePromptItems[replacePromptIndex]?.titlePart}${PAGE_CONTENT.replaceModalContentSuffix}`}
        onCancel={declineReplace}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Replace"
        isInsideBaseline
        title={`${PAGE_CONTENT.copy} ${replacePromptItems[replacePromptIndex]?.titlePart}`}></DialogBox>
    </div>
  );
};

export default TabBodyComponent;

TabBodyComponent.propTypes = {
  year: PropTypes.number,
  type: PropTypes.string,
  sampleId: PropTypes.string,
  setLoading: PropTypes.func,
  fieldType: PropTypes.string,
  fieldId: PropTypes.string,
  farmId: PropTypes.string,
  totalFieldArea: PropTypes.number,
  enrollmentYearData: PropTypes.array,
  setFieldDetails: PropTypes.func,
  setSubmitData: PropTypes.func,
  saveDataHandler: PropTypes.func,
  setIsInitialLoad: PropTypes.func,
  fetchLastSectionData: PropTypes.func,
  setFetchLastSectionData: PropTypes.func,
  setUserActivityDetected: PropTypes.func,
  setEnrollmentYears: PropTypes.func,
  isInActivity: PropTypes.bool,
  baselineApprovedFarmIds: PropTypes.array,
  baselineApprovedFieldIds: PropTypes.array,
};
