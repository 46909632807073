import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import {
  ErroWrapper,
  FormFields,
  FormLabels,
  InputSx,
  LabelToolTipWrapper,
  OptionalLabel,
} from '../FormStyles';
import { FORM_CONTENT } from '../FormComponents.content';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';
import { COOL_GRAY } from 'theme/GlobalColors';

const BasicAutoComplete = ({
  label,
  options,
  inputHandler,
  placeholder,
  loadingText,
  value,
  id,
  onChangeHandler,
  readOnly,
  isError,
  name,
  onInputChangeHandler,
  renderCustomOptions,
  customGetOptionsLabel,
  ErrorMessage,
  isOptional,
  forcePopupIcon = true,
  width = '100%',
  secondaryError = false,
  secondaryErrorMessage,
  labelMarginTop,
  labelMarginBottom,
  inputFontSize,
  tooltipProps = null,
  isMultiSelect = false,
  filterSelectedOptions = false,
  customIsOptionEqualToValue = () => {},
  renderCustomTags = () => {},
  freeSolo = true,
  optionalLabelMarginLeft,
  marginBottom,
  isDisabled = false,
  disableClearable = false,
  hideArrowIcon = false,
}) => {
  const hasError = isError || secondaryError;

  return (
    <FormFields marginBottom={marginBottom} readOnly={readOnly}>
      <FormLabels
        iserror={hasError}
        marginTop={labelMarginTop}
        marginBottom={labelMarginBottom}>
        <LabelToolTipWrapper>
          {label}
          {isOptional && (
            <OptionalLabel marginLeft={optionalLabelMarginLeft}>
              {FORM_CONTENT.optional}
            </OptionalLabel>
          )}
          {tooltipProps && (
            <InfoTooltip
              title={tooltipProps.title}
              content={tooltipProps.content}
            />
          )}
        </LabelToolTipWrapper>
      </FormLabels>
      <Autocomplete
        {...(hideArrowIcon ? { popupIcon: '' } : {})}
        disabled={isDisabled}
        options={options}
        id={id}
        value={value}
        loading={true}
        multiple={isMultiSelect}
        loadingText={loadingText}
        forcePopupIcon={forcePopupIcon}
        onChange={(e, value, reason) => onChangeHandler(value, reason)}
        onInputChange={inputHandler}
        name={name}
        freeSolo={freeSolo}
        renderTags={renderCustomTags}
        filterSelectedOptions={filterSelectedOptions}
        renderInput={(params) => (
          <div>
            <TextField
              data-testid="autocomplete-input"
              {...params}
              className="auto_text"
              onInputChange={onInputChangeHandler}
              error={hasError}
              inputProps={{
                style: {
                  padding: '0.25rem 0 0.25rem 0.5rem',
                  fontSize: inputFontSize,
                  height: '1.4375rem',
                },
                sx: {
                  '&::placeholder': {
                    color: COOL_GRAY,
                    opacity: 1, // otherwise firefox shows a lighter color
                    size: '0.875rem',
                    fontSize: '0.875rem',
                  },
                },
                ...params.inputProps,
              }}
              placeholder={placeholder}
              variant="standard"
              sx={{
                width: width,
                ...(disableClearable && {
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                }),
              }}
            />
          </div>
        )}
        sx={hasError ? {} : InputSx}
        renderOption={renderCustomOptions}
        getOptionLabel={customGetOptionsLabel}
        isOptionEqualToValue={customIsOptionEqualToValue}
      />
      {isError && <ErroWrapper>{ErrorMessage}</ErroWrapper>}
      {secondaryError && <ErroWrapper>{secondaryErrorMessage}</ErroWrapper>}
    </FormFields>
  );
};

BasicAutoComplete.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  inputHandler: PropTypes.func,
  placeholder: PropTypes.string,
  loadingText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  isError: PropTypes.bool,
  name: PropTypes.string,
  onInputChangeHandler: PropTypes.func,
  renderCustomOptions: PropTypes.func,
  customGetOptionsLabel: PropTypes.func,
  ErrorMessage: PropTypes.node,
  isOptional: PropTypes.bool,
  forcePopupIcon: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryError: PropTypes.bool,
  secondaryErrorMessage: PropTypes.string,
  labelMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipProps: PropTypes.object,
  isMultiSelect: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  customIsOptionEqualToValue: PropTypes.func,
  renderCustomTags: PropTypes.func,
  freeSolo: PropTypes.bool,
  optionalLabelMarginLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  hideArrowIcon: PropTypes.bool,
};

export default BasicAutoComplete;
