import moment from 'moment';

export const constructCycleData = (projectCycleSettings) => {
  const cycles = [];

  for (let i = 0; i < projectCycleSettings.totalProjectCycles; i++) {
    const cycle = {};
    cycle.id = i + 1;
    cycle.startDate = moment(
      new Date(
        projectCycleSettings.projectStartYear + i,
        projectCycleSettings.cycleStartMonth - 1,
      ),
    ).format('MM/DD/YYYY');
    cycle.endDate = moment(cycle.startDate)
      .add(projectCycleSettings.cycleDuration, 'months')
      .format('MM/DD/YYYY');
    cycle.cycleYear = cycle.endDate.split('/')[2];
    cycles.push(cycle);
  }

  return cycles;
};
