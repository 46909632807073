import styled from 'styled-components';
import { COOL_GRAY } from 'theme/GlobalColors';

export const TitleContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
`;

export const Legendscontainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 1.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 0.5rem;
`;

export const LegendBoxWrapper = styled.div`
  padding: 0.25rem 0 0.25rem 0;
`;

export const LegendBox = styled.div`
  float: left;
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.backgroundColor};
`;

export const LegendText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #53565a;
`;

export const WrapperDefaultStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const CustomXAxisStyle = {
  fontFamily: 'Open Sans',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: COOL_GRAY,
  textAlign: 'center',
};
