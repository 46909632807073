import { checkTernaryCondition } from 'utils/helper';

export const handleTextFieldValueChange = (
  event,
  key,
  isNumber,
  fieldChangHandler,
) => {
  const updatedValue = event?.target.value.trim() ? event?.target.value : '';
  if (isNumber) {
    const numberValue = checkTernaryCondition(
      updatedValue[0] === '.',
      `0${updatedValue}`,
      updatedValue,
    );
    event?.target.value.match(/^\d*(\.\d{0,2})?$/) &&
      fieldChangHandler(key, numberValue);
  } else {
    fieldChangHandler(key, updatedValue);
  }
};

export const getUpdatedPracticeTypeArray = (
  practiceTypeArray,
  id,
  selectedType,
) => {
  const updatedPracticeTypeArray = [...practiceTypeArray];
  const indexOfItem = updatedPracticeTypeArray.findIndex(
    (item) => item.id === id,
  );
  updatedPracticeTypeArray[indexOfItem] = {
    id: id,
    types: updatedPracticeTypeArray[indexOfItem]?.types?.filter(
      (item) => item !== selectedType,
    ),
  };
  return updatedPracticeTypeArray;
};

export const setCurrentRowModalData = (
  modalOpen,
  setFormValue,
  initialModalData,
) => {
  if (modalOpen.flag && modalOpen.data !== null) {
    const modalData = modalOpen.data.formData?.filter(
      (data) => data.id === modalOpen.currentRowId,
    )[0];
    setFormValue(checkTernaryCondition(modalData, modalData, initialModalData));
  }
};
