import styled from 'styled-components';

export const AddNewButtonSx = {
  textAlign: 'right',
  marginBottom: '1rem',
};

export const participantLayoutContentPadding = '0.5rem 0rem 2rem 0rem';

export const ContentWithFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
`;
