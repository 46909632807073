import { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { MenuSx, menuItemSx } from './CustomOptionsButton.style';
import { Divider } from '@mui/material';
import { COOL_GRAY } from 'theme/GlobalColors';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { ReactComponent as EditIconWithHoverEffect } from '../../assets/icons/EditIconWithHoverEffect.svg';
import { checkTernaryCondition } from 'utils/helper';

const SimpleOptionsButton = ({
  editId,
  onEdit,
  onUpdateAccountStatus = () => null,
  onDelete,
  removeOrDelete,
  removeButtonRetain,
  isClosed,
  showEnrollOption = false,
  enrollOptionDisabled = false,
  onEnroll = () => null,
  showEnterBaselineOption = false,
  enterBaselineOptionDisabled,
  onEnterBaselineClick,
  enterBaselineLabel,
  separatorLine = false,
  editOptionDisabled = false,
  updateStatusAccountOptionDisabled = false,
  deleteOptionDisabled = false,
  hasContract,
  addContactText,
  onContractClick,
  reviewEnrollmentLabel,
  onClickReviewEnrollment,
  reviewBaselineLabel,
  onClickReviewBaseline,
  isInitiateEnrollmentEnabled,
  isReviewEnrollmentEnabled,
  isReviewBaselineReportingEnabled,
  isInitiateBaselineReportingEnabled,
  isAddContractEnabled,
  reviewBaselineDisabled = false,
  customOptions = [],
  hasEditOption = true,
  hasUpdateStatusAccount = false,
  hasDeleteOption = true,
  editIcon = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    isClosed && handleClose();
  }, [isClosed]);
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: 0, color: COOL_GRAY }}>
        {checkTernaryCondition(
          editIcon,
          <EditIconWithHoverEffect />,
          <MoreVertIcon />,
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MenuSx}>
        {hasEditOption && (
          <MenuItem
            disabled={editOptionDisabled}
            sx={menuItemSx}
            onClick={() => {
              onEdit(editId);
            }}>
            Edit
          </MenuItem>
        )}
        {hasUpdateStatusAccount && (
          <MenuItem
            disabled={updateStatusAccountOptionDisabled}
            sx={menuItemSx}
            onClick={() => {
              onUpdateAccountStatus();
            }}>
            Update account status
          </MenuItem>
        )}
        {hasDeleteOption && (
          <MenuItem
            disabled={deleteOptionDisabled}
            sx={menuItemSx}
            onClick={() => {
              onDelete();
              !removeButtonRetain && setAnchorEl(false);
            }}>
            {removeOrDelete ? 'Remove' : 'Delete'}
          </MenuItem>
        )}
        {separatorLine && <Divider />}
        {isInitiateEnrollmentEnabled && (
          <MenuItem
            disabled={enrollOptionDisabled}
            sx={menuItemSx}
            onClick={() => {
              onEnroll();
              setAnchorEl(false);
            }}>
            Initiate enrollment
          </MenuItem>
        )}
        {isReviewEnrollmentEnabled && (
          <MenuItem
            sx={menuItemSx}
            onClick={() => {
              onClickReviewEnrollment();
              setAnchorEl(false);
            }}>
            {reviewEnrollmentLabel}
          </MenuItem>
        )}
        {isReviewBaselineReportingEnabled && (
          <MenuItem
            disabled={reviewBaselineDisabled}
            sx={menuItemSx}
            onClick={() => {
              onClickReviewBaseline();
              setAnchorEl(false);
            }}>
            {reviewBaselineLabel}
          </MenuItem>
        )}
        {isInitiateBaselineReportingEnabled && (
          <MenuItem
            disabled={enterBaselineOptionDisabled}
            sx={menuItemSx}
            onClick={() => {
              onEnterBaselineClick();
              setAnchorEl(false);
            }}>
            {enterBaselineLabel}
          </MenuItem>
        )}
        {hasContract && isAddContractEnabled && (
          <MenuItem
            sx={menuItemSx}
            onClick={() => {
              onContractClick();
              setAnchorEl(false);
            }}>
            {addContactText}
          </MenuItem>
        )}

        {customOptions.map((option) => {
          return (
            !option.hidden && (
              <MenuItem
                key={uniqueId()}
                disabled={option.disabled ?? false}
                sx={menuItemSx}
                onClick={() => {
                  option.clickHandler();
                  setAnchorEl(false);
                }}>
                {option.itemLabel}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default SimpleOptionsButton;
