import React from 'react';
import { Container } from './ColorIcon.style';
import { ACTUAL, PROJECTED } from 'utils/config';
import PropTypes from 'prop-types';
import {
  BLUISH_CYAN,
  BLUISH_WHITE,
  DARK_GREEN,
  ORANGE,
} from 'theme/GlobalColors';

const ColorIcon = ({ type }) => {
  const getBackground = () => {
    if (type === PROJECTED) {
      return `${BLUISH_CYAN}`;
    } else if (type === ACTUAL) {
      return `${ORANGE}`;
    } else {
      return `${BLUISH_WHITE}`;
    }
  };
  const getBorder = () => {
    if (type === PROJECTED) {
      return `0px solid ${DARK_GREEN}`;
    } else if (type === ACTUAL) {
      return `0px solid ${ORANGE}`;
    } else {
      return `0px solid ${DARK_GREEN}`;
    }
  };
  return (
    <Container
      width="0.5rem"
      height="0.5rem"
      borderRadius="0.125rem"
      backgroundColor={getBackground()}
      border={getBorder()}
    />
  );
};
ColorIcon.propTypes = {
  type: PropTypes.string,
};
export default ColorIcon;
