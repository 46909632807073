import PropTypes from 'prop-types';
import {
  ContentWrapper,
  MainWrapper,
  TextWrapper,
} from './CalculationNoResultScreen.style';

const CalculationNoResultScreen = ({ noCalcDataTitle, noCalcDataSubTitle }) => {
  return (
    <MainWrapper>
      <ContentWrapper>
        <TextWrapper fontWeight={700}>{noCalcDataTitle}</TextWrapper>
        <TextWrapper fontWeight={400}>{noCalcDataSubTitle}</TextWrapper>
      </ContentWrapper>
    </MainWrapper>
  );
};

CalculationNoResultScreen.propTypes = {
  noCalcDataTitle: PropTypes.string,
  noCalcDataSubTitle: PropTypes.string,
};

export default CalculationNoResultScreen;
