import './App.css';
import { ThemeProvider } from '@mui/material';
import theme from './theme/Theme';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './Routes';
import { useStyles } from 'theme/GlobalStyles';

function App() {
  const { ToastContainer } = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer />
        <Routing />
      </div>
    </ThemeProvider>
  );
}

export default App;
