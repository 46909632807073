import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_OPTIONS,
  LABEL_ROWS_PER_PAGES,
  ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
  ORIGINATION_PROJECT_SUMMARY_REPORT_CREATE,
  PAGINATION_MODE,
} from 'utils/config';
import {
  FILTER_COLUMNS,
  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT,
  REPORT_TYPE_FILTER,
  errorInitialState,
  listingProjectReportsColumn,
} from './OriginationProjectReportsList.content';
import NodataComponent from 'components/NoDataComp/NodataContent';
import {
  CellValueWrapper,
  DataGridWrapper,
  FilterHeader,
  HeaderWrapper,
  MainWrapper,
  RowCellWrapper,
  SearchAndFilterHeader,
  TextWrapper,
  datagridSx,
  sortStyle,
} from './OriginationProjectReportsList.style';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import NewOptionsButton from 'components/NewOptionsButton/NewOptionsButton.react';
import ListViewHeader from 'components/PageHeaders/ListViewHeader/ListViewHeader.react';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';

import { DataGrid } from '@mui/x-data-grid';
import {
  BLACK,
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
} from 'theme/GlobalColors';
import {
  checkAccess,
  checkTernaryCondition,
  covertDateToLocalTimeZone,
  getCurrentDate,
  openPDFFromLink,
  ternaryBooleanHandler,
} from 'utils/helper';
import DialogBox from 'components/DialogBox/DialogBox.react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  DELETE_ORIGINATION_REPORT,
  FETCH_ALL_PROJECT_REPORTS,
  FETCH_PARTICIPANTS_FOR_REPORT,
  GENERATE_ORIGINATION_PARTICIPANT_PROJECT_ENROLLMENT_REPORT,
  GENERATE_ORIGINATION_PROJECT_REPORT,
  GET_PROJECT_FOR_PARTICIPANTS,
  GET_PROJECT_LIFE_CYCLE_LIST,
  REPORT_EXCEL_EXPORT_FIRST_PART,
  REPORT_EXCEL_EXPORT_SECOND_PART,
} from 'urls';
import axios from 'axios';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  dateFormatter,
  dateTimeFormatter,
  findPastMonthsDate,
  usToUkDateFormatter,
} from 'utils/dateFormatter';
import { checkFieldIsNotEmpty } from '../Org_Project/Participants/ParticipantReport/ParticipantReportList.content';
import { getDisplayName } from 'security/components/Profile.react';
import { Backdrop, CircularProgress } from '@mui/material';
import SearchAndFilterComponent from 'components/SearchAndFilterComponent/SearchAndFilterComponent.react';
import { newColorThemeContext } from 'contextAPI/newColorThemeContext';
import LightTooltip from 'components/LightTooltip';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import { MODAL_CONTENT } from 'pages/OriginationParticipantsList/EnrollmentModal/EnrollmentModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';

const customCell = (value1, value2, color1, color2, cellClickHandler) => {
  return (
    <RowCellWrapper
      direction={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.column}
      onClick={cellClickHandler}>
      <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
        {value1}
      </CellValueWrapper>
      <TextWrapper fontSize="0.8rem" fontWeight={400} color={color2}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

const pastOneMonth = findPastMonthsDate(1);

const OriginationProjectReportsList = ({
  disableVirtualization = false,
  projectId,
  projectName,
  checkEmptyCase = false,
  isTesting = false,
  projectLifeCycleList = [],
}) => {
  const { setNewColorTheme } = useContext(newColorThemeContext);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false);
  const [sortValue, setSortValue] = useState(
    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.desc,
  );
  const [selectedReportOption, setSelectedReportOption] = useState('');
  const [errorState, setErrorState] = useState(errorInitialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const username = getDisplayName();
  const [reportName, setReportName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [projectValue, setProjectValue] = useState('');
  const [participantValue, setParticipantValue] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [projectError, setProjectError] = useState(false);
  const { permissions } = useContext(userAuthRolesContext);

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [reportTypeSelectedFilter, setReportTypeSelectedFilter] = useState([]);
  const [columnSearchText, setColumnSearchText] = useState('');
  const [fromDate, setFromDate] = useState(
    dateFormatter(pastOneMonth.toJSON()),
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date().toJSON()));
  const [fetchedProjectLifeCycleList, setFetchedProjectLifeCycleList] =
    useState([]);
  const [projectCycleValue, setProjectCycleValue] = useState('');

  const [participantList, setParticipantList] = useState([]);
  const [participantIdError, setParticipantIdError] = useState(false);

  const generateReportButtonOptions = [
    {
      id: 1,
      name: 'Project Summary',
      isDisabled: !checkAccess(
        permissions,
        ORIGINATION_PROJECT_SUMMARY_REPORT_CREATE,
      ),
    },
    {
      id: 4,
      name: 'Participant Project Summary',
      isDisabled: !checkAccess(
        permissions,
        ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
      ),
    },
    // This code will be used in future
    // {
    //   id: 5,
    //   name: 'Audit Log',
    //   isDisabled: !checkAccess(
    //     permissions,
    //     ORIGINATION_PROJECT_ENROLLMENT_REPORT_CREATE,
    //   ),
    // },
  ];

  useEffect(() => {
    fetchOriginationProjectReportList();
    setNewColorTheme(true);
  }, []);

  useEffect(() => {
    fetchOriginationProjectReportList();
  }, [
    pageState.pageSize,
    pageState.pageNumber,
    columnSearchText,
    reportTypeSelectedFilter,
    sortValue,
    fromDate,
    toDate,
    username,
  ]);

  useEffect(() => {
    setDefaultReportName();
  }, [selectedReportOption]);

  useEffect(() => {
    const isAnyError =
      Object.values(errorState).includes(true) ||
      Object.values(errorState).includes('');
    const isParticipantProjectSummarySelected =
      selectedReportOption ===
      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.participantProjectSummary;
    const isParticipantValueOrIdError =
      isParticipantProjectSummarySelected &&
      (!participantValue || participantIdError);

    setBtnDisabled(isAnyError || projectError || isParticipantValueOrIdError);
  }, [errorState, participantValue, participantIdError, projectError]);

  const fetchParticipantList = (name, projectId) => {
    if (name !== null && name !== undefined) {
      if (name.length > 2) {
        axios
          .get(FETCH_PARTICIPANTS_FOR_REPORT, {
            params: {
              projectId: projectId,
              name: name,
              projectCycleId: projectCycleValue,
            },
          })
          .then((response) => {
            setParticipantIdError(
              ternaryBooleanHandler(response.data.length === 0),
            );
            setParticipantList(
              response.data.map((data) => ({
                participantId: data.participantId,
                participantFirstName: data.participantFirstName,
                participantLastName: data.participantLastName,
                participantEmail: data.participantEmail,
              })),
            );
          });
      } else {
        setParticipantList([]);
        setParticipantIdError(false);
      }
    }
  };

  const fetchProjectList = (name) => {
    if (name !== null && name !== undefined) {
      if (name.length > 2) {
        axios
          .get(GET_PROJECT_FOR_PARTICIPANTS, { params: { projectName: name } })
          .then((response) => {
            setProjectError(response.data.length === 0);
            setProjectList(
              response.data.map((item) => ({
                label: item.projectName,
                value: item.internalProjectId,
              })),
            );
          });
      } else {
        setProjectList([]);
      }
    }
  };

  const filterSections = useMemo(
    () => [
      {
        heading: listingProjectReportsColumn.reportType.label,
        filters: [
          {
            id: REPORT_TYPE_FILTER[0],
            itemLabel: REPORT_TYPE_FILTER[0],
            checked: reportTypeSelectedFilter.includes(REPORT_TYPE_FILTER[0]),
            isDisabled: false,
          },
          {
            id: REPORT_TYPE_FILTER[2],
            itemLabel: REPORT_TYPE_FILTER[2],
            checked: reportTypeSelectedFilter.includes(REPORT_TYPE_FILTER[2]),
            isDisabled: false,
          },
          // This code will be used in future
          // {
          //   id: REPORT_TYPE_FILTER[3],
          //   itemLabel: REPORT_TYPE_FILTER[3],
          //   checked: reportTypeSelectedFilter.includes(REPORT_TYPE_FILTER[3]),
          //   isDisabled: false,
          // },
        ],
      },
    ],
    [reportTypeSelectedFilter],
  );

  const updateFilterStates = (sectionHeading, checkedFields) => {
    if (sectionHeading === listingProjectReportsColumn.reportType.label) {
      setReportTypeSelectedFilter(checkedFields);
    }
  };

  const removeFilter = ({ sectionIdx, filterIdx }) => {
    const filterLabelToBeRemoved =
      filterSections[sectionIdx].filters[filterIdx].itemLabel;
    const checkedFields = filterSections[sectionIdx].filters
      .filter(
        (filter) =>
          filter.checked === true &&
          filter.itemLabel !== filterLabelToBeRemoved,
      )
      .map((filter) => filter.itemLabel);
    updateFilterStates(filterSections[sectionIdx].heading, checkedFields);
  };

  const handleFilterSectionUpdate = (sections) => {
    sections.forEach((section) => {
      const checkedFields = section.filters
        .filter((filter) => filter.checked === true)
        .map((filter) => filter.itemLabel);

      updateFilterStates(section.heading, checkedFields);
    });
  };

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    filterSections.forEach((section) => {
      section.filters.forEach((filter) => {
        if (filter.checked) count++;
      });
    });

    return count;
  }, [filterSections]);

  const setDefaultReportName = () => {
    setReportName(
      `${selectedReportOption?.replace?.(
        / /g,
        ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.underscore,
      )}-${getCurrentDate()}`,
    );
  };

  const reportNameClickHandler = (reportName, reportLink, reportType) => {
    if (
      reportType === generateReportButtonOptions[0].name ||
      reportType === generateReportButtonOptions[1].name
    ) {
      return checkTernaryCondition(reportLink === null, null, () =>
        downloadPDFHandler(reportLink),
      );
    }
    // This code will be used in future

    //  else if (reportType === generateReportButtonOptions[1].name) {
    //   return () =>
    //     downloadExcelHandler(
    //       `${
    //         reportName?.split(ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf)[0]
    //       }.xlsx`,
    //     );
    // }
  };

  useEffect(() => {
    if (projectValue?.value) {
      fetchProjectCycleList();
    }
  }, [projectValue]);

  useEffect(() => {
    if (
      selectedReportOption ===
      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.participantProjectSummary
    ) {
      setErrorState((prev) => ({ ...prev, participantNameError: true }));
    } else {
      setErrorState((prev) => ({ ...prev, participantNameError: false }));
    }
  }, [selectedReportOption]);

  const fetchProjectCycleList = () => {
    axios
      .get(`${GET_PROJECT_LIFE_CYCLE_LIST}/${projectValue.value}`)
      .then((response) => {
        setFetchedProjectLifeCycleList(
          response.data.map((item) => ({
            label: `${moment(item.cycleEndDate).format('YYYY')} (${moment(
              item.cycleStartDate,
            ).format('MM/DD/YYYY')} - ${moment(item.cycleEndDate).format(
              'MM/DD/YYYY',
            )})`,
            value: item.projectCycleId,
          })),
        );
      });
  };

  const fetchOriginationProjectReportList = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    axios
      .post(`${FETCH_ALL_PROJECT_REPORTS}`, {
        pageNumber: pageState.pageNumber - 1,
        pageSize: pageState.pageSize,
        startDate: usToUkDateFormatter(fromDate),
        endDate: usToUkDateFormatter(toDate),
        searchField: columnSearchText,
        reportType: reportTypeSelectedFilter,
        sortFilter: {
          column: listingProjectReportsColumn.generatedOn.field,
          order: sortValue,
        },
      })
      .then((response) => {
        const fetchedData = response.data.content.map((report) => ({
          id: report?.id,
          reportName: report.reportName,
          reportLink: report?.reportLink,
          reportType: report.reportType,
          generatedBy: report.generatedBy,
          generatedOn: report.generatedOn,
          startDate: report.startDate,
          endDate: report.endDate,
        }));
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: fetchedData,
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      })
      .finally(() => {
        setIsDataFetched(true);
        setPageState((old) => ({
          ...old,
          isLoading: false,
        }));
      });
  };

  const customHeader = (columnName, hasSort, widthSize) => {
    return (
      <FilterHeader style={{ minWidth: widthSize }}>
        <span className="bold-600">{columnName}</span>

        {hasSort && (
          <>
            {(sortValue === ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.desc && (
              <ArrowDownward
                sx={sortStyle}
                onClick={() =>
                  setSortValue(ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.asc)
                }
                data-testid={`${columnName}-down-arrow`}
              />
            )) || (
              <ArrowUpward
                sx={sortStyle}
                data-testid={`${columnName}-up-arrow`}
                onClick={() =>
                  setSortValue(ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.desc)
                }
              />
            )}
          </>
        )}
      </FilterHeader>
    );
  };

  const currentRowSetter = (row) => {
    setCurrentRow(row);
  };

  const checkReportName = (value) => {
    const reg = new RegExp(/^[a-zA-Z0-9@_-]+$/gm);
    return reg.test(value);
  };

  const columns = [
    {
      field: listingProjectReportsColumn.reportName.field,
      headerName: listingProjectReportsColumn.reportName.label,
      sortable: false,
      renderHeader: () =>
        customHeader(listingProjectReportsColumn.reportName.label, false, 300),
      disableColumnMenu: true,
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <LightTooltip
          title={
            params.row?.reportName?.split(
              ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
            )[0]
          }>
          {customCell(
            params.row?.reportName?.split(
              ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
            )[0],
            '',
            GLOBAL_BLUE,
            '',
            reportNameClickHandler(
              params.row?.reportName,
              params.row?.reportLink,
              params.row?.reportType,
            ),
          )}
        </LightTooltip>
      ),
    },
    {
      field: listingProjectReportsColumn.reportType.field,
      headerName: listingProjectReportsColumn.reportType.label,
      sortable: false,
      renderHeader: () =>
        customHeader(listingProjectReportsColumn.reportType.label, false, 220),
      disableColumnMenu: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: listingProjectReportsColumn.projectCycle.field,
      headerName: listingProjectReportsColumn.projectCycle.label,
      sortable: false,
      renderHeader: () =>
        customHeader(
          listingProjectReportsColumn.projectCycle.label,
          false,
          220,
        ),
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: DARK_CHARCOAL }}>
              {params?.row?.endDate?.split('-')[0]}
            </span>
            <span style={{ color: COOL_GRAY, fontSize: '12px' }}>
              {dateFormatter(params.row.startDate)} -{' '}
              {dateFormatter(params.row.endDate)}
            </span>
          </div>
        );
      },
      disableColumnMenu: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: listingProjectReportsColumn.generatedBy.field,
      headerName: listingProjectReportsColumn.generatedBy.label,
      sortable: false,
      renderHeader: () =>
        customHeader(listingProjectReportsColumn.generatedBy.label, false, 250),
      disableColumnMenu: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: listingProjectReportsColumn.generatedOn.field,
      headerName: listingProjectReportsColumn.generatedOn.label,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 138,
      sortable: false,
      renderHeader: () =>
        customHeader(listingProjectReportsColumn.generatedOn.label, true, 138),
      renderCell: (params) =>
        customCell(
          covertDateToLocalTimeZone(params?.row?.generatedOn).slice(0, 10),
          dateTimeFormatter(
            covertDateToLocalTimeZone(params?.row?.generatedOn),
          ),
          BLACK,
          COOL_GRAY,
        ),
    },
    {
      field: listingProjectReportsColumn.options.field,
      headerName: listingProjectReportsColumn.options.label,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
      sortable: false,
      align: ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.center,
      renderCell: (params) => (
        <div
          onClick={() => currentRowSetter(params?.row)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              currentRowSetter(params?.row);
            }
          }}
          data-testid={`${listingProjectReportsColumn.options.field}-${params.row?.id}`}>
          <NewOptionsButton
            deleteHandler={deleteHandler}
            firstMenuItemClickHandler={() =>
              downloadPDFHandler(params.row?.reportLink)
            }
            secondMenuItemClickHandler={() =>
              downloadPDFHandler(params.row?.reportLink)
            }
            isDownloadDisabled={params.row?.reportLink === null}
            deleteNotDisabled={true}
            reportType={params.row?.reportType}
            reportOptions={generateReportButtonOptions}
          />
        </div>
      ),
    },
  ];

  const generateReport = () => {
    setPageState({
      ...pageState,
      isLoading: true,
    });
    setIsGenerateReportModalOpen(false);
    let reportGenerationUrl = `${GENERATE_ORIGINATION_PROJECT_REPORT}/${projectValue.value}`;
    let payload = {
      reportName: `${reportName.trim()}${
        ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf
      }`,
      reportType: selectedReportOption,
      projectCycleId: projectCycleValue,
    };
    if (
      selectedReportOption ===
      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.participantProjectSummary
    ) {
      payload = {
        reportName: `${reportName.trim()}${
          ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf
        }`,
        reportType: selectedReportOption,
        internalProjectId: projectValue.value,
        projectCycleId: projectCycleValue,
      };
      reportGenerationUrl =
        GENERATE_ORIGINATION_PARTICIPANT_PROJECT_ENROLLMENT_REPORT;
      reportGenerationUrl = `${reportGenerationUrl}/${participantValue.participantId}/report`;
    }
    axios
      .post(reportGenerationUrl, payload)
      .then(() => {
        displayToast(
          ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success,
          `${reportName} ${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success_message}`,
        );
      })
      .catch(() => {
        displayToast(
          ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error,
          `${reportName} ${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error_message}`,
        );
      })
      .finally(() => {
        setDefaultReportName();
        fetchOriginationProjectReportList();
        setPageState({
          ...pageState,
          isLoading: false,
        });
      });
  };

  const submitHandler = () => {
    setErrorState({
      reportNameRequiredError: ternaryBooleanHandler(!reportName.trim()),
      reportNameInvalidError: reportName.trim() && !checkReportName(reportName),
    });
    if (reportName.trim() && Object.values(errorState).includes(false)) {
      generateReport();
    } else {
      setBtnDisabled(true);
    }
    handleClose();
  };

  const handleClose = () => {
    setIsGenerateReportModalOpen(false);
    setDefaultReportName();
    setErrorState(errorInitialState);
    setProjectValue('');
    setParticipantValue('');
    setProjectCycleValue('');
    setParticipantIdError('');
    setParticipantList([]);
  };

  const deleteHandler = () => {
    setIsDialogOpen(true);
  };

  const deleteProjectReportList = () => {
    setIsDialogOpen(false);
    axios
      .delete(`${DELETE_ORIGINATION_REPORT}/${currentRow?.id}`)
      .then((response) => {
        if (
          ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success_codes.includes(
            response?.status,
          )
        ) {
          displayToast(
            ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success,
            `${
              currentRow?.reportName?.split(
                ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
              )[0]
            } ${
              ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success_deletion_message
            }`,
          );
          fetchOriginationProjectReportList();
        } else {
          displayToast(
            ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error,
            `${
              currentRow?.reportName?.split(
                ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
              )[0]
            } ${
              ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error_deletion_message
            }`,
          );
        }
      })
      .catch(() => {
        displayToast(
          ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error,
          `${
            currentRow?.reportName?.split(
              ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
            )[0]
          } ${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.error_deletion_message}`,
        );
      });
  };

  const downloadPDFHandler = (reportLink) => {
    openPDFFromLink(reportLink);
  };

  const downloadExcelHandler = async (fileName) => {
    setPageState({
      ...pageState,
      isLoading: true,
    });
    await axios
      .post(
        `${REPORT_EXCEL_EXPORT_FIRST_PART}/${projectId}/${REPORT_EXCEL_EXPORT_SECOND_PART}?fileName=${fileName}`,
        null,
        {
          headers: {
            'Content-Disposition': `attachment; filename=${fileName}`,
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        displayToast(
          'error',
          `${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.excel_error_message}`,
        );
      })
      .finally(() => {
        setPageState({
          ...pageState,
          isLoading: false,
        });
      });
  };

  if (
    (isDataFetched && pageState.data.length == 0 && columnSearchText === '') ||
    checkEmptyCase
  ) {
    return (
      <>
        <Backdrop
          open={pageState.isLoading}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress
            color={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success}
          />
        </Backdrop>
        {!pageState.isLoading && (
          <>
            <HeaderWrapper>
              <SearchAndFilterHeader>
                <SearchAndFilterComponent
                  hasSeachBox={true}
                  projectNameSearchText={columnSearchText}
                  setProjectNameSearchText={setColumnSearchText}
                  searchBoxPlaceholder={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.searchBoxPlaceholder
                  }
                  filterSections={filterSections}
                  hasAppliedFilters={true}
                  removeFilter={removeFilter}
                  appliedFiltersCount={appliedFiltersCount}
                  updateFilterStates={updateFilterStates}
                  handleFilterSectionUpdate={handleFilterSectionUpdate}
                  columnName={FILTER_COLUMNS}
                  clearLabel={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.clearAllLabel
                  }
                  hasDatePicker={true}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  darkGreenColor={NEW_DARK_GREEN}
                />
              </SearchAndFilterHeader>
              <ListViewHeader
                buttonName={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generateBtn
                }
                buttonBackgroundColor={DARK_GREEN}
                noDropdown={false}
                options={generateReportButtonOptions}
                setSelectedOption={setSelectedReportOption}
                toggleModal={setIsGenerateReportModalOpen}
                isModalPresent={true}
                disabledOption={true}
                containerPadding={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.genrateReportBtnPadding
                }
              />
            </HeaderWrapper>
            <NodataComponent
              nodata={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.noDataContent}
              addNewLabel={
                ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.addNewUserLabel
              }
              addbuttonLabel={{
                name: ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generateBtn,
                link: '',
              }}
              addNewButtonOptions={generateReportButtonOptions}
              setSelectedOption={setSelectedReportOption}
              toggleModal={setIsGenerateReportModalOpen}
              isModalPresent={true}
              noDropdown={false}
              buttonBackgroundColor={DARK_GREEN}
              disabledOption={true}
            />
            <DialogBox
              title={`${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generate} ${selectedReportOption} ${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report}`}
              dialogActions
              darkGreenBtnColor
              isOpen={isGenerateReportModalOpen}
              onConfirm={() => submitHandler()}
              onCancel={() => handleClose()}
              declineCtnLabel={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.cancel}
              acceptCtnLabel={
                ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generateReportModalBtn
              }
              dialogWidth={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.dialogWidth}
              btnDisabled={btnDisabled}>
              <div>
                <BasicAutoComplete
                  width="92.5%"
                  options={projectList}
                  id="disable-close-on-select"
                  label={MODAL_CONTENT.project_name}
                  value={projectValue}
                  loading={true}
                  isError={projectError}
                  ErrorMessage={MODAL_CONTENT.project_not_present_error}
                  placeholder={MODAL_CONTENT.project_name_placeholder}
                  loadingText={MODAL_CONTENT.project_name_placeholder}
                  onChangeHandler={(value, reason) => {
                    if (reason === 'clear') {
                      setProjectValue('');
                      setProjectCycleValue('');
                      setFetchedProjectLifeCycleList([]);
                      setParticipantList([]);
                      setParticipantValue(null);
                      setErrorState({
                        ...errorState,
                        projectNameError: true,
                        projectCycleError: true,
                      });
                      return;
                    }
                    if (reason === 'createOption') {
                      setProjectValue('');
                      setProjectCycleValue('');
                      setFetchedProjectLifeCycleList([]);
                      setParticipantList([]);
                      setProjectError(true);
                      setParticipantValue(null);
                      setErrorState({
                        ...errorState,
                        projectNameError: true,
                        projectCycleError: true,
                      });
                      return;
                    }
                    if (!value?.value) {
                      setProjectValue('');
                      setErrorState({
                        ...errorState,
                        projectNameError: true,
                      });
                      return;
                    }
                    setProjectError(false);
                    setProjectCycleValue('');
                    setParticipantList([]);
                    setParticipantValue(null);
                    setErrorState((prev) => ({
                      ...prev,
                      projectNameError: false,
                      projectCycleError: true,
                    }));
                    setProjectValue(value);
                  }}
                  inputHandler={(e, newValue) => {
                    if (newValue?.split(' ')?.length <= 2) {
                      fetchProjectList(newValue);
                    }
                  }}
                  name="project"
                />
                <DropDown
                  dropDownMinWidth={'13.75rem'}
                  label={MODAL_CONTENT.project_life_cycle_label}
                  ariaLabel={MODAL_CONTENT.project_life_cycle_label}
                  dropDownPlaceholder={
                    MODAL_CONTENT.project_life_cycle_placeholder
                  }
                  value={checkTernaryCondition(
                    projectCycleValue === '',
                    MODAL_CONTENT.project_life_cycle_placeholder,
                    projectCycleValue,
                  )}
                  dropdownlist={
                    isTesting
                      ? projectLifeCycleList
                      : fetchedProjectLifeCycleList
                  }
                  setStateValue={() => {}}
                  setDropDownValInParent={(newVal) => {
                    setParticipantList([]);
                    setParticipantValue(null);
                    setProjectCycleValue(newVal);
                    newVal !== '' &&
                      setErrorState({
                        ...errorState,
                        projectCycleError: false,
                      });
                  }}
                  setFormFieldValue={() => {}}
                  height={MODAL_CONTENT.two_rem}
                  error={errorState.projectCycleIdError}
                  errorMessage={MODAL_CONTENT.project_cycle_required_error}
                  showLabelAsValue={true}
                  labelMarginTop={'0.5rem'}
                  labelMarginBottom={'0.25rem'}
                  customPlaceholderColor={COOL_GRAY}
                />
                {selectedReportOption ===
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.participantProjectSummary && (
                  <BasicAutoComplete
                    width="92.5%"
                    options={participantList}
                    id="disable-close-on-select"
                    label={MODAL_CONTENT.participant_name}
                    value={checkTernaryCondition(
                      participantValue?.participantId,
                      participantValue,
                      '',
                    )}
                    loading={true}
                    isError={participantIdError}
                    ErrorMessage={MODAL_CONTENT.participant_not_present_error}
                    placeholder={MODAL_CONTENT.participant_name_placeholder}
                    loadingText={MODAL_CONTENT.participant_name_placeholder}
                    onChangeHandler={(value, reason) => {
                      const initialValue = {
                        participantId: '',
                        participantFirstName: '',
                        participantLastName: '',
                        participantEmail: '',
                      };
                      if (reason === 'clear') {
                        setParticipantList([]);
                        setErrorState({
                          ...errorState,
                          participantNameError: true,
                        });
                        setParticipantValue(initialValue);
                        return;
                      }
                      if (reason === 'createOption') {
                        setParticipantIdError(true);
                        setParticipantList([]);
                        setParticipantValue(initialValue);
                        setErrorState({
                          ...errorState,
                          participantNameError: true,
                        });
                        return;
                      }

                      setParticipantIdError(false);
                      setParticipantValue(value);
                      setErrorState({
                        ...errorState,
                        participantNameError: false,
                      });
                    }}
                    inputHandler={(e, newValue) => {
                      if (newValue?.split(' ')?.length <= 2) {
                        fetchParticipantList(newValue, projectValue.value);
                      }
                    }}
                    name="participant"
                    customGetOptionsLabel={(option) => {
                      if (
                        option.participantFirstName !== undefined &&
                        option.participantLastName !== undefined
                      ) {
                        return `${option.participantFirstName} ${option.participantLastName}`;
                      }
                      return '';
                    }}
                  />
                )}
                <InputField
                  label={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldLabel
                  }
                  value={reportName}
                  width={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldWidth
                  }
                  onUpdate={(e) => {
                    setReportName(e.target.value);
                    setErrorState({
                      ...errorState,
                      reportNameRequiredError: checkFieldIsNotEmpty(
                        e.target.value,
                      ),
                      reportNameInvalidError:
                        e.target.value.trim() &&
                        !checkReportName(e.target.value),
                    });
                  }}
                  placeholder={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldPlaceholder
                  }
                  primaryError={errorState.reportNameRequiredError}
                  primaryErrorMessage={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report_name_required_error
                  }
                  secondaryError={errorState.reportNameInvalidError}
                  secondaryErrorMessage={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report_name_invalid_error
                  }
                />
              </div>
            </DialogBox>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <Backdrop
          open={!isDataFetched}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress
            color={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.success}
          />
        </Backdrop>
        {isDataFetched && (
          <>
            <HeaderWrapper>
              <SearchAndFilterHeader>
                <SearchAndFilterComponent
                  hasSeachBox={true}
                  projectNameSearchText={columnSearchText}
                  setProjectNameSearchText={setColumnSearchText}
                  searchBoxPlaceholder={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.searchBoxPlaceholder
                  }
                  filterSections={filterSections}
                  hasAppliedFilters={true}
                  removeFilter={removeFilter}
                  appliedFiltersCount={appliedFiltersCount}
                  updateFilterStates={updateFilterStates}
                  handleFilterSectionUpdate={handleFilterSectionUpdate}
                  columnName={FILTER_COLUMNS}
                  clearLabel={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.clearAllLabel
                  }
                  hasDatePicker={true}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  darkGreenColor={NEW_DARK_GREEN}
                />
              </SearchAndFilterHeader>
              <ListViewHeader
                buttonName={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generateBtn
                }
                buttonBackgroundColor={DARK_GREEN}
                noDropdown={false}
                options={generateReportButtonOptions}
                setSelectedOption={setSelectedReportOption}
                toggleModal={setIsGenerateReportModalOpen}
                isModalPresent={true}
                disabledOption={true}
                containerPadding={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.genrateReportBtnPadding
                }
              />
            </HeaderWrapper>
            <MainWrapper className="curved-border">
              {checkTernaryCondition(
                columnSearchText && pageState.data.length === 0,
                <NodataComponent
                  filterData={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.filter_content
                  }
                  searchTermSuffix={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.search_text_suffix
                  }
                  searchTerm={`"${columnSearchText}".`}
                  width={
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.filter_content_width
                  }
                />,
                <DataGridWrapper>
                  <DataGrid
                    headerHeight={40}
                    disableVirtualization={disableVirtualization}
                    rows={pageState.data || []}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
                    disableSelectionOnClick
                    pagination
                    componentsProps={{
                      pagination: {
                        labelRowsPerPage: LABEL_ROWS_PER_PAGES,
                        SelectProps: TablePaginationMenuListStyle,
                      },
                    }}
                    sx={datagridSx}
                    page={pageState.pageNumber - 1}
                    pageSize={pageState.pageSize}
                    paginationMode={PAGINATION_MODE}
                    onPageChange={(newPage) =>
                      setPageState((old) => ({
                        ...old,
                        pageNumber: newPage + 1,
                      }))
                    }
                    onPageSizeChange={(newPageSize) =>
                      setPageState((old) => ({ ...old, pageSize: newPageSize }))
                    }
                    columns={columns}
                  />
                </DataGridWrapper>,
              )}
              <DialogBox
                title={`${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generate} ${selectedReportOption} ${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report}`}
                dialogActions
                darkGreenBtnColor
                isOpen={isGenerateReportModalOpen}
                onConfirm={() => submitHandler()}
                onCancel={() => handleClose()}
                declineCtnLabel={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.cancel
                }
                acceptCtnLabel={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.generateReportModalBtn
                }
                dialogWidth={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.dialogWidth
                }
                btnDisabled={btnDisabled}>
                <div>
                  <BasicAutoComplete
                    width="92.5%"
                    options={projectList}
                    id="disable-close-on-select"
                    label={MODAL_CONTENT.project_name}
                    value={projectValue}
                    loading={true}
                    isError={projectError}
                    ErrorMessage={MODAL_CONTENT.project_not_present_error}
                    placeholder={MODAL_CONTENT.project_name_placeholder}
                    loadingText={MODAL_CONTENT.project_name_placeholder}
                    onChangeHandler={(value, reason) => {
                      if (reason === 'clear') {
                        setProjectValue('');
                        setProjectCycleValue('');
                        setFetchedProjectLifeCycleList([]);
                        setParticipantList([]);
                        setParticipantValue(null);
                        setErrorState({
                          ...errorState,
                          projectNameError: true,
                          projectCycleError: true,
                        });
                        return;
                      }
                      if (reason === 'createOption') {
                        setProjectValue('');
                        setProjectCycleValue('');
                        setFetchedProjectLifeCycleList([]);
                        setParticipantList([]);
                        setProjectError(true);
                        setParticipantValue(null);
                        setErrorState({
                          ...errorState,
                          projectNameError: true,
                          projectCycleError: true,
                        });
                        return;
                      }
                      if (!value?.value) {
                        setProjectValue('');
                        setErrorState({
                          ...errorState,
                          projectNameError: true,
                        });
                        return;
                      }
                      setProjectError(false);
                      setProjectCycleValue('');
                      setParticipantList([]);
                      setParticipantValue(null);
                      setErrorState((prev) => ({
                        ...prev,
                        projectNameError: false,
                        projectCycleError: true,
                      }));
                      setProjectValue(value);
                    }}
                    inputHandler={(e, newValue) => {
                      if (newValue?.split(' ')?.length <= 2) {
                        fetchProjectList(newValue);
                      }
                    }}
                    name="project"
                  />
                  <DropDown
                    dropDownMinWidth={'13.75rem'}
                    label={MODAL_CONTENT.project_life_cycle_label}
                    ariaLabel={MODAL_CONTENT.project_life_cycle_label}
                    dropDownPlaceholder={
                      MODAL_CONTENT.project_life_cycle_placeholder
                    }
                    value={checkTernaryCondition(
                      projectCycleValue === '',
                      MODAL_CONTENT.project_life_cycle_placeholder,
                      projectCycleValue,
                    )}
                    dropdownlist={
                      isTesting
                        ? projectLifeCycleList
                        : fetchedProjectLifeCycleList
                    }
                    setStateValue={() => {}}
                    setDropDownValInParent={(newVal) => {
                      setParticipantList([]);
                      setParticipantValue(null);
                      setProjectCycleValue(newVal);
                      newVal !== '' &&
                        setErrorState({
                          ...errorState,
                          projectCycleError: false,
                        });
                    }}
                    setFormFieldValue={() => {}}
                    height={MODAL_CONTENT.two_rem}
                    error={errorState.projectCycleIdError}
                    errorMessage={MODAL_CONTENT.project_cycle_required_error}
                    showLabelAsValue={true}
                    labelMarginTop={'0.5rem'}
                    labelMarginBottom={'0.25rem'}
                    customPlaceholderColor={COOL_GRAY}
                  />
                  {selectedReportOption ===
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.participantProjectSummary && (
                    <BasicAutoComplete
                      width="92.5%"
                      options={participantList}
                      id="disable-close-on-select"
                      label={MODAL_CONTENT.participant_name}
                      value={checkTernaryCondition(
                        participantValue?.participantId,
                        participantValue,
                        '',
                      )}
                      loading={true}
                      isError={participantIdError}
                      ErrorMessage={MODAL_CONTENT.participant_not_present_error}
                      placeholder={MODAL_CONTENT.participant_name_placeholder}
                      loadingText={MODAL_CONTENT.participant_name_placeholder}
                      onChangeHandler={(value, reason) => {
                        const initialValue = {
                          participantId: '',
                          participantFirstName: '',
                          participantLastName: '',
                          participantEmail: '',
                        };
                        if (reason === 'clear') {
                          setParticipantList([]);
                          setErrorState({
                            ...errorState,
                            participantNameError: true,
                          });
                          setParticipantValue(initialValue);
                          return;
                        }
                        if (reason === 'createOption') {
                          setParticipantIdError(true);
                          setParticipantList([]);
                          setParticipantValue(initialValue);
                          setErrorState({
                            ...errorState,
                            participantNameError: true,
                          });
                          return;
                        }

                        setParticipantIdError(false);
                        setParticipantValue(value);
                        setErrorState({
                          ...errorState,
                          participantNameError: false,
                        });
                      }}
                      inputHandler={(e, newValue) => {
                        if (newValue?.split(' ')?.length <= 2) {
                          fetchParticipantList(newValue, projectValue.value);
                        }
                      }}
                      name="participant"
                      customGetOptionsLabel={(option) => {
                        if (
                          option.participantFirstName !== undefined &&
                          option.participantLastName !== undefined
                        ) {
                          return `${option.participantFirstName} ${option.participantLastName}`;
                        }
                        return '';
                      }}
                    />
                  )}
                  <InputField
                    label={
                      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldLabel
                    }
                    value={reportName}
                    width={
                      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldWidth
                    }
                    onUpdate={(e) => {
                      setReportName(e.target.value);
                      setErrorState({
                        ...errorState,
                        reportNameRequiredError: checkFieldIsNotEmpty(
                          e.target.value,
                        ),
                        reportNameInvalidError:
                          e.target.value.trim() &&
                          !checkReportName(e.target.value),
                      });
                    }}
                    placeholder={
                      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.inputFieldPlaceholder
                    }
                    primaryError={errorState.reportNameRequiredError}
                    primaryErrorMessage={
                      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report_name_required_error
                    }
                    secondaryError={errorState.reportNameInvalidError}
                    secondaryErrorMessage={
                      ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.report_name_invalid_error
                    }
                  />
                </div>
              </DialogBox>
              <DialogBox
                disabled={true}
                dialogActions
                deleteOperation
                isOpen={isDialogOpen}
                onConfirm={() => deleteProjectReportList()}
                subtitle={`${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.delete_message?.slice(
                  0,
                  31,
                )} ${
                  currentRow?.reportName?.split(
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
                  )[0]
                }${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.delete_message?.slice(
                  31,
                )}`}
                onCancel={() => setIsDialogOpen(false)}
                declineCtnLabel={
                  ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.cancel
                }
                acceptCtnLabel={ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.delete}
                title={`${ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.delete} ${
                  currentRow?.reportName?.split(
                    ORIGINATION_PROJECT_REPORTS_LIST_CONTENT.pdf,
                  )[0]
                }`}></DialogBox>
            </MainWrapper>
          </>
        )}
      </>
    );
  }
};

OriginationProjectReportsList.propTypes = {
  disableVirtualization: PropTypes.bool,
  projectId: PropTypes.number,
  projectName: PropTypes.string,
  checkEmptyCase: PropTypes.bool,
};

export default OriginationProjectReportsList;
