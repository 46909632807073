import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
  InputSx,
  outlinedDropdownInputSx,
} from '../Modals.style';
import CloseIcon from '@mui/icons-material/Close';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { useForm } from 'hooks/useForm';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { COOL_GRAY } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import {
  handleTextFieldValueChange,
  setCurrentRowModalData,
} from '../Modals.content';
import {
  MODAL_CONTENT,
  formFieldNames,
  initialResidueManagementErrorState,
  initialResidueManagementTypeData,
} from './ResidueManagementModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const ResidueManagementModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
}) => {
  const [errorState, setErrorState] = useState(
    initialResidueManagementErrorState,
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    initialResidueManagementTypeData,
  );
  const { residueTypeDropdown } = useContext(baselineDropdownContext);
  useEffect(() => {
    setCurrentRowModalData(
      modalOpen,
      setFormValue,
      initialResidueManagementTypeData,
    );
  }, [modalOpen]);

  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialResidueManagementTypeData);
    setErrorState(initialResidueManagementErrorState);
  };

  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'cropResidueRemoved':
        setErrorState({ ...errorState, cropResidueRemovedError: value === '' });
        break;
      case 'agricultureResiduesManaged':
        setErrorState({
          ...errorState,
          agricultureResiduesManagedError: value === '',
        });
        break;
    }
  };
  const agricultureResiduesDropdownProps = {
    label: MODAL_CONTENT.agriculture_residues_managed,
    fontSize: '0.875rem',
    value: checkTernaryCondition(
      formValue.agricultureResiduesManaged === '',
      MODAL_CONTENT.agriculture_residues_managed_placeholder,
      formValue.agricultureResiduesManaged,
    ),
    isDisabled:
      modalOpen.data?.formData[0]?.addedFromImplementedActivities || false,
    width: MODAL_CONTENT.thirteenPointSevenFiveRem,
    height: MODAL_CONTENT.dropdownHeight,
    labelMarginTop: MODAL_CONTENT.zeroPointFiveRem,
    labelMarginBottom: MODAL_CONTENT.zeroPointTwoFiveRem,
    hasNoBottomMargin: true,
    customSx: outlinedDropdownInputSx,
    name: formFieldNames.agricultureResiduesManaged,
    ariaLabel: MODAL_CONTENT.agriculture_residues_managed_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.agriculture_residues_managed_placeholder,
    customPlaceholderColor: COOL_GRAY,
    error: errorState.agricultureResiduesManagedError,
    errorMessage: MODAL_CONTENT.agriculture_residues_managed_error,
    listItemFontSize: '0.875rem',
    isNotOutlined: false,
    dropdownlist: residueTypeDropdown,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.agricultureResiduesManaged,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: formFieldNames.agricultureResiduesManaged,
  };
  const submitBtnHandler = () => {
    const cropResidueIsNone =
      residueTypeDropdown.filter(
        (item) => item.value === formValue.agricultureResiduesManaged,
      )[0]?.label === 'None';
    const residueIsEmpty =
      formValue.agricultureResiduesManaged === MODAL_CONTENT.empty_string;
    const updatedErrorStates = {
      agricultureResiduesManagedError: residueIsEmpty,
      cropResidueRemovedError:
        formValue.cropResidueRemoved === '' && !cropResidueIsNone,
    };
    setErrorState(updatedErrorStates);
    if (!Object.values(updatedErrorStates).some((value) => value === true)) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.residue_mgmt_list,
      );
      modalCloseHandler();
    }
  };

  useEffect(() => {
    setErrorState(initialResidueManagementErrorState);
  }, [formValue.agricultureResiduesManaged]);

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}
      onClose={modalCloseHandler}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <FieldSectionWrapper marginBottom="0">
          <DropDown {...agricultureResiduesDropdownProps} />
        </FieldSectionWrapper>
        {residueTypeDropdown.filter(
          (item) => item.value === formValue.agricultureResiduesManaged,
        )[0]?.label !== 'None' && (
          <InputField
            label={MODAL_CONTENT.crop_residue_removed}
            placeholder={MODAL_CONTENT.percentage}
            value={formValue.cropResidueRemoved}
            minWidth={MODAL_CONTENT.elevenPointTwoFiveRem}
            width={MODAL_CONTENT.eightPointSevenFiveRem}
            name="cropResidueRemoved"
            required
            onUpdate={(event) => {
              const value = event?.target.value;
              if (
                value === '' ||
                (value !== '.' && parseFloat(value) <= 100 && value !== '100.')
              ) {
                handleTextFieldValueChange(
                  event,
                  'cropResidueRemoved',
                  true,
                  customFieldChangeHandler,
                );
                setModalErrorState(event.target.value, 'cropResidueRemoved');
              }
            }}
            primaryError={errorState.cropResidueRemovedError}
            primaryErrorMessage={MODAL_CONTENT.crop_residue_removed_error}
            hasPredefinedUnit={true}
            predefinedUnit={MODAL_CONTENT.percent}
            labelMarginTop={MODAL_CONTENT.zeroPointFiveRem}
            labelMarginBottom={MODAL_CONTENT.zeroPointTwoFiveRem}
            rightSideLabelFormLabelMargin={MODAL_CONTENT.zeroRem}
            customInputSx={InputSx}
          />
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          onClick={() => {
            submitBtnHandler();
          }}
          disabled={isSubmitDisabled}
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}>
          {MODAL_CONTENT.submit}
        </Button>
        <Button
          disableElevation
          onClick={() => {
            modalCloseHandler();
          }}
          sx={[CancelBtnStyle, tertiaryButtonStyle]}>
          {MODAL_CONTENT.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResidueManagementModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
};
export default ResidueManagementModal;
