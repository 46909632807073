import React, { useContext, useEffect, useState } from 'react';
import { RequestInterceptor } from './RequestInterceptor.react';
import { SettingsContext } from '../../contextAPI/settings';
import { Backdrop, CircularProgress } from '@mui/material';
import { getAuth, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { useFirebaseSignIn } from './SignInButton.react';

/**
 * A custom security provider for redirect to Microsoft Authentication
 */

export const SecurityProvider = ({ children }) => {
  const { app, handleLogin } = useFirebaseSignIn();

  const [user, setUser] = useState(null);
  const auth = getAuth(app);

  const login = async () => {
    let updateUser = null;
    onAuthStateChanged(auth, (user) => {
      updateUser = user;
    });

    const result = await getRedirectResult(auth).catch(() => null);

    if (!result && !updateUser) {
      const { user } = await handleLogin();
      setUser(user);
    } else {
      setUser(updateUser);
    }
  };

  useEffect(() => {
    login();
  }, [user]);

  if (user == null) {
    return (
      <RequestInterceptor>
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="success" />
        </Backdrop>
      </RequestInterceptor>
    );
  } else {
    return <RequestInterceptor>{children}</RequestInterceptor>;
  }
};
