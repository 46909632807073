import styled from 'styled-components';
export const STYLE_CONST = {
  one_zero_xy_cords: '1rem 0rem',
  zero_one_xy_cords: '0rem 1rem',
  row_alignment: 'row',
  none: 'none',
  fifty_percent: '50%',
  hundred_percent: '100%',
  seventy_eight_percent: '78%',
  one_rem: '1rem',
  weight_xl: 600,
  shadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  border: '0.5px solid',
  forty_nine_point_five: '49.5%',
};

export const MainContainer = styled.div`
  gap: 1rem;
  justify-content: ${({ space }) => space};
  width: 100%;
`;

export const MapContainer = styled.div`
  display: flex;
  margin: 1rem 0rem;
  width: 27%;
  background: white;
`;

export const DetailsContainer = styled.div`
  display: flex;
  padding: 1rem 0rem;
  flex-direction: column;
  gap: 1rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  gap: ${({ noGap }) => (noGap ? '' : '1rem')};
  padding: ${({ padding }) => padding};
  box-shadow: ${({ shadow }) => shadow};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
export const Description = styled.div`
  display: flex;
  background: ${({ background }) => (background ? background : 'white')};
  gap: 1rem;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
`;

export const SubDetails = styled.div`
  display: flex;
  background: white;
  padding: 0rem 1rem;
  justify-content: between;
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.5rem;
`;
export const FieldTitle = styled.div`
  color: ${({ color }) => (color ? color : '#63666A')};
  font-weight: ${({ weight }) => weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: ${({ padding }) => padding};
`;
export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  word-wrap: break-word;
  max-width: 20rem;
`;

export const SubDescription = styled.div`
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #333333;
  height: 17rem;
  word-wrap: break-word;
`;

export const TabWrapper = styled.div`
  color: black;
  text-transform: none;
  font-size: 0.875rem;
`;

export const PageTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #313233;
`;

export const Chip = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  gap: 0.25rem;
  /* Text properties */
  color: white;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 15px;
  align-items: center;
  background: #26890d;
  border-radius: 4px;
  white-space: nowrap;
  /* Code optimization required */
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 5rem;
  height: 1rem;
`;
export const Verified = styled.div`
  fill: #26890d;
`;

export const Review = styled.div`
  font-weight: 400;
  font-size: ${({ size }) => size};
  line-height: ${({ height }) => height};
  letter-spacing: 0.2px;
  display: flex;
  height: 1.2rem;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: 4px;
  background: ${({ background }) => background};
`;
export const TitleDiv = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  gap: ${({ gap }) => (gap ? gap : '1rem')};
`;
export const ReviewText = styled.span`
  color: ${({ color }) => color};
`;

export const ChangeLogContainer = styled.div`
  display: flex;
  padding: 1rem 0rem;
  width: 50%;
  flex-direction: column;
  gap: 1rem;
`;
export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
export const Tags = styled.div`
  background: #ffffff;
  border: 0.5px solid #63666a;
  border-radius: 0.25rem;
  color: #63666a;
  padding: 0.25rem;
  font-weight: 400;
  font-size: 0.813rem;
  max-width: 5rem;
  height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MapAndDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;
export const LookerWrapper = styled.div`
  width: 100%;
`;
export const toastProperties = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const TurnedInNotIconStyle = {
  height: '1rem',
  width: '1rem',
  color: '#0076A8',
};
export const DoneIconStyle = { height: '1rem', width: '1rem' };
export const SeeMoreWrapper = styled.span`
  color: #0076a8;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  height: 17rem;
  cursor: pointer;
`;
export const TextModalContentWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #333333;
`;
export const spinnerStyle = { position: 'absolute', top: '40%', left: '50%' };
