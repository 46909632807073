import React, { useEffect, useState, useContext } from 'react';
import {
  CustomBreadCrumbItem,
  CustomBreadCrumbLink,
  LeftSectionWrapper,
  MainContainer,
  MainWrapper,
  ParentContainer,
  ProjectName,
  TabberBoxCustomStyle,
  TextWrapper,
  DividerStyles,
} from './BaseLineReportingMainPage.style';
import {
  Backdrop,
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Tabs,
  Tooltip,
} from '@mui/material';
import LeftMenuSubsection from '../components/LeftMenuSubsection/LeftMenuSubsection.react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FETCH_PROJECT_LIFECYCLES, PARTICIPANT_PROFILE } from 'urls';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import {
  PAGE_CONTENT,
  baselineInstanceStatus,
  handleNextInProgrssSwitcher,
  tabsTitle,
} from './BaseLineReportingMainPage.content';
import { checkTernaryCondition, ternaryBooleanHandler } from 'utils/helper';
import Tab from '@mui/material/Tab';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import TabBodyComponent from '../ReportingComponents/TabBodyComponent.react';
import EnrollmentCompleteModal from '../components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { pathName } from 'Routes/routes.const';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { ProjectNameWrapper } from '../ParticipantDataCollection.style';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import { useReportingApis } from 'hooks/useReportingApis';
import {
  getFieldEditApiData,
  getFormattedCashcropList,
  getFormattedFarmData,
  getStatus,
  getcashCropSubmitBody,
  getFormattedEdgeOfFieldList,
  validateFieldYear,
} from '../ReportingUtilFunctions';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { TabPanelStyle } from 'pages/Origination/Org_Project/Org_Projects.style';
import moment from 'moment';
import axios from 'axios';

const BaseLineReportingMainPage = ({ isInTest = false }) => {
  const {
    tillageTypeDropdown,
    fertilizerTypeDropDown,
    coverCropTypeDropdown,
    setStatusList,
    statusList,
  } = useContext(baselineDropdownContext);
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const { loaderCounter } = useContext(baselineDropdownContext);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [fieldDetails, setFieldDetails] = useState({});
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [farmDetails, setFarmDetails] = useState();
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const [type, setType] = useState(PAGE_CONTENT.farm);
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [enrollmentYears, setEnrollmentYears] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [modalData, setModalData] = useState({ id: 0, flag: false });
  const [cycleYear, setCycleYear] = useState('');
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [currentFieldId, setCurrentFieldId] = useState({
    id: 0,
    name: '',
  });
  const [preventAutoSave, setPreventAutoSave] = useState(false);
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchLastSectionData, setFetchLastSectionData] = useState(false);
  const [baselineApprovedFarmIds, setBaselineApprovedFarmIds] = useState([]);
  const [baselineApprovedFieldIds, setBaselineApprovedFieldIds] = useState([]);
  const [
    hasNotApprovedBaselineFarmOrFields,
    setHasNotApprovedBaselineFarmOrFields,
  ] = useState(false);
  // Used to check if user has made any changes in the page, based on this we prompt user when they try to leave the page with unsaved changes
  const [userActivityDetected, setUserActivityDetected] = useState(false);

  /**
   * @description Using the flag 'userActivityDetected' we are tracking if there was a change in the web forms, if there is a change we add a 'beforeunload' event which gets triggered when user tries to close the browser window/tab, it provides a warning to the user to save changes before closing the browser.
   */
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );
  const {
    loading: apiLoading,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchFieldArea,
    fetchProjectData,
    submitBaselineFarm,
    submitBaselineField,
    editBaselineField,
  } = useReportingApis();

  const navigate = useNavigate();
  const { TabberBox } = useStyles();
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `baseline-page-tab-${index}`,
    };
  };
  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const updateStatus = (currentFieldId, newFieldStatus, fieldDetails) => {
    const updatedFieldDetails = { ...fieldDetails };
    let allFarmsCompleted = true;
    for (const farm of updatedFieldDetails.farmDetails) {
      for (const field of farm.fieldDetails) {
        if (field.fieldId === currentFieldId) {
          field.fieldStatus = newFieldStatus;
        }
        if (field.fieldStatus !== 'Completed') {
          allFarmsCompleted = false;
        }
      }
      farm.farmStatus = allFarmsCompleted ? 'complete' : 'in progress';
      allFarmsCompleted = true;
    }
    setFieldDetails(updatedFieldDetails);
  };

  const filterApprovedBaselineData = (farmsList) => {
    const baselineApprovedFarms = [],
      baselineApprovedFields = [];

    for (const farm of farmsList) {
      if (
        farm.baselineStatus === baselineInstanceStatus.approved &&
        farm.fieldDetails.every(
          (field) => field.baselineStatus === baselineInstanceStatus.approved,
        )
      ) {
        baselineApprovedFarms.push(farm.farmId);
      } else {
        setHasNotApprovedBaselineFarmOrFields(true);
      }

      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        if (field.baselineStatus === baselineInstanceStatus.approved) {
          baselineApprovedFields.push(field.fieldId);
        } else {
          setHasNotApprovedBaselineFarmOrFields(true);
        }
      }
    }
    setBaselineApprovedFarmIds(baselineApprovedFarms);
    setBaselineApprovedFieldIds(baselineApprovedFields);
  };

  const fetchFieldDetails = (isInitial, isSaveClicked) => {
    const URL = `${PARTICIPANT_PROFILE}?enrollmentType=BASELINE&projectId=${projectId}&participantId=${participantId}`;
    fetchBaselineFieldDetails(URL).then((data) => {
      const farmId = data?.farmDetails[0]?.farmId;
      const farmName = data?.farmDetails[0]?.farmName;
      const farmList = data?.farmDetails;
      filterApprovedBaselineData(farmList);
      setFieldDetails(data);
      if (isInitial) {
        setFieldDetails(data);
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);

        const baselineStatus = getStatus(farmList);
        updateStatus(currentFieldId.id, baselineStatus, data);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
      }
      if (isSaveClicked) {
        saveAndContinueClickHandler(farmList);
      }
    });
  };

  const axiosCall = (formData) => {
    if (submitData.editMode) {
      const fieldId = currentFieldId?.id;
      const { apiParams, reqBody } = getFieldEditApiData(
        formData,
        submitData,
        fieldId,
      );
      return editBaselineField(apiParams, reqBody);
    } else {
      return submitBaselineField(formData);
    }
  };

  const navigateOnCloseHandler = (status) => {
    if (status === PAGE_CONTENT.completed) {
      navigateHandler();
    } else {
      navigate(`${pathName.participant.landingPage}`);
    }
  };

  const saveDataHandler = (
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched = false,
    clickdata = { type: 'none', id: null },
  ) => {
    setPreventAutoSave(false);

    const { sampleId, parentState, editMode } = submitData;
    if (sampleId === null) return;

    if (isSaveAndCloseClicked && isEmailLinkClicked) {
      let finalStatus = '';
      if (finalList.every((element) => element === PAGE_CONTENT.completed)) {
        finalStatus = PAGE_CONTENT.completed;
        setStatusList(finalStatus);
      } else {
        finalStatus = PAGE_CONTENT.in_progress;
        setStatusList(finalStatus);
      }
      navigateOnCloseHandler(finalStatus);
    }
    if (type === PAGE_CONTENT.farm) {
      handleFarmSubmit({
        isSaveAndCloseClicked,
        isUnmounting,
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
      });
    } else if (type === PAGE_CONTENT.crop) {
      handleFieldSubmit({
        isSaveAndCloseClicked,
        isUnmounting,
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
      });
    }
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      const cycleYear = moment(cycleObject[0]?.cycleEndDate)
        .format('MM/DD/YYYY')
        ?.slice(6);
      if (cycleObject?.length > 0) {
        setCycleYear(cycleYear);
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  };

  const handleFarmSubmit = ({
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched,
    clickdata,
    parentState,
    sampleId,
  }) => {
    if (baselineApprovedFarmIds.includes(currentFarmId)) {
      if (isUnmounting) return;
      saveAndContinueClickHandler(fieldDetails.farmDetails);
      return;
    }
    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const submitData = {
      activityReportingStatus: 'Interested',
      enrolmentType: 'Baseline',
      farmId: currentFarmId,
      farmSampleId: sampleId,
      projectId: projectId,
      participantId: participantId,
      farmOperationsDataStatus: 'Not Started',
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };

    submitBaselineFarm(submitData).then(() => {
      fetchFieldDetails(false, !isSaveAndCloseClicked && !isUnmounting);

      const shouldFetchBaselineYears =
        isTabSwitched ||
        (clickdata.type === 'farm' &&
          clickdata.id === currentFarmId &&
          type === PAGE_CONTENT.farm);
      if (shouldFetchBaselineYears) {
        fetchBaselineYears(PAGE_CONTENT.farm, currentFarmId);
      }
    });
  };

  const handleFieldSubmit = ({
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched,
    clickdata,
    parentState,
    editMode,
  }) => {
    if (baselineApprovedFieldIds.includes(currentFieldId.id)) {
      if (isUnmounting) return;
      saveAndContinueClickHandler(fieldDetails.farmDetails);
      return;
    }
    const cashCropList = getFormattedCashcropList(
      parentState,
      submitData,
      editMode,
    );
    const eofList = getFormattedEdgeOfFieldList(submitData, editMode);

    const yearStatus = validateFieldYear(
      submitData,
      fertilizerTypeDropDown,
      coverCropTypeDropdown,
      tillageTypeDropdown,
    );
    const formData = getcashCropSubmitBody({
      fieldId: currentFieldId.id,
      projectId,
      participantId,
      sampleId: submitData.sampleId,
      cashCropList,
      eofList,
      farmId: currentFarmId,
      yearStatus,
      projectCycleId: Number(projectCycleId),
    });

    axiosCall(formData).then(() => {
      const shouldFetchBaselineYears =
        isTabSwitched ||
        (clickdata.type === 'field' &&
          clickdata.id === currentFieldId.id &&
          type === PAGE_CONTENT.crop);

      fetchFieldDetails(false, !isSaveAndCloseClicked && !isUnmounting);

      if (shouldFetchBaselineYears) {
        fetchBaselineYears(PAGE_CONTENT.crop, currentFieldId.id);
      } else {
        !isUnmounting &&
          setSubmitData((prev) => ({
            ...prev,
            editMode: formData.baselineFieldDto.cropList.length > 0,
          }));
      }
    });
  };

  const baselineTabs = enrollmentYears?.map((year) => {
    return {
      title: tabsTitle(year?.yearValue, year?.status),
      component: (
        <TabBodyComponent
          year={year?.yearValue}
          type={checkTernaryCondition(
            type === PAGE_CONTENT.farm,
            PAGE_CONTENT.empty_farm_label,
            PAGE_CONTENT.empty_crop_label,
          )}
          fieldType={type}
          sampleId={year?.sampleId}
          setLoading={setLoading}
          fieldId={currentFieldId.id}
          farmId={currentFarmId}
          totalFieldArea={totalFieldArea}
          enrollmentYearData={year}
          setFieldDetails={setFieldDetails}
          setEnrollmentYears={setEnrollmentYears}
          fetchFieldDetails={fetchFieldDetails}
          setSubmitData={setSubmitData}
          saveDataHandler={saveDataHandler}
          setIsInitialLoad={setIsInitialLoad}
          fetchLastSectionData={fetchLastSectionData}
          setFetchLastSectionData={setFetchLastSectionData}
          setUserActivityDetected={setUserActivityDetected}
          typeOfReporting={PAGE_CONTENT.baselineReportingType}
          baselineApprovedFarmIds={baselineApprovedFarmIds}
          baselineApprovedFieldIds={baselineApprovedFieldIds}
        />
      ),
    };
  });

  const fetchBaselineYears = (type, id) => {
    if (type === PAGE_CONTENT.farm) {
      const apiParams = { farmId: id, projectCycleId: Number(projectCycleId) };
      fetchBaselineFarmYears(apiParams).then((data) => {
        const enrollmentYear = data.filter((year) => year.phase === 'baseline');
        setEnrollmentYears(enrollmentYear);
      });
    } else {
      const apiParams = {
        fieldId: currentFieldId?.id,
        projectId: projectId,
        projectCycleId: Number(projectCycleId),
      };
      fetchBaselineFieldYears(apiParams).then((data) => {
        const enrollmentYear = data.filter((year) => year.phase === 'baseline');
        setEnrollmentYears(enrollmentYear);
      });
    }
  };
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentFieldId.id };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };

  const fetchProjectDetails = () => {
    const apiPath = `/${projectId}`;
    const errorHandler = () => setProjectDetails({ projectName: '' });
    fetchProjectData(apiPath, errorHandler).then((projectData) =>
      setProjectDetails(projectData),
    );
  };

  useEffect(() => {
    fetchFieldDetails(true, false);
    fetchProjectDetails();
    fetchLifeCycleDates();
  }, []);
  useEffect(() => {
    if (currentFieldId.id > 0) {
      setType(PAGE_CONTENT.crop);
      fetchBaselineYears(PAGE_CONTENT.crop, currentFieldId.id);
      fetchTotalFieldArea();
    }
  }, [currentFieldId]);
  useEffect(() => {
    if (farmDetails) {
      setType(PAGE_CONTENT.farm);
      fetchBaselineYears(PAGE_CONTENT.farm, farmDetails.id);
    }
  }, [farmDetails]);

  useEffect(() => {
    if (enrollmentYears && !isInitialLoad) {
      setEnrollmentYears((prev) =>
        prev.map((yearData) => {
          return checkTernaryCondition(
            yearData.sampleId === submitData.sampleId,
            {
              ...yearData,
              status: checkTernaryCondition(
                submitData.parentState?.length > 0,
                'In Progress',
                'Not Started',
              ),
            },
            yearData,
          );
        }),
      );
    }
    if (type === PAGE_CONTENT.farm && !isInitialLoad) {
      setFieldDetails((prev) => {
        return {
          ...prev,
          farmDetails: prev.farmDetails?.map((farm) => {
            return checkTernaryCondition(
              farm.farmId === currentFarmId,
              {
                ...farm,
                mainStatus: 'in progress',
              },
              farm,
            );
          }),
        };
      });
    }
    if (type === PAGE_CONTENT.crop && !isInitialLoad) {
      setFieldDetails((prev) => {
        return {
          ...prev,
          farmDetails: prev.farmDetails?.map((farm) => {
            return checkTernaryCondition(
              farm.farmId === currentFarmId,
              {
                ...farm,
                mainStatus: 'in progress',
                fieldDetails: farm.fieldDetails.map((field) => {
                  return checkTernaryCondition(
                    field.fieldId === currentFieldId.id,
                    { ...field, mainStatus: 'in progress' },
                    field,
                  );
                }),
              },
              farm,
            );
          }),
        };
      });
    }
  }, [submitData]);

  const saveAndContinueClickHandler = (farmDetails) => {
    let inProgressItem = null;
    inProgressItem = handleNextInProgrssSwitcher(
      inProgressItem,
      farmDetails,
      getCurrentFarm(),
      checkTernaryCondition(type === PAGE_CONTENT.farm, null, currentFieldId),
      ternaryBooleanHandler(type === PAGE_CONTENT.farm),
      currentTab,
    );
    formInProgressHandler(inProgressItem, farmDetails);
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.fieldDetails?.every(
              (field) => field.fieldStatus === 'Completed',
            ),
            { ...farm, farmStatus: 'complete' },
            farm,
          );
        }),
      };
    });
  };

  const getCurrentFarm = () => {
    return fieldDetails.farmDetails.find(
      (farm) => farm.farmId === currentFarmId,
    );
  };

  const formInProgressHandler = (inProgressItem, updatedFarmDetails) => {
    if (inProgressItem !== null) {
      setPreventAutoSave(true);
      if (inProgressItem.type === 'field') {
        setType(PAGE_CONTENT.crop);
        setCurrentFieldId({ id: inProgressItem.id, name: inProgressItem.name });
        setCurrentFarmId(inProgressItem.farmId);
        setExpandedFarmId(inProgressItem.farmId);
        setCurrentTab(inProgressItem.yearIndex);
      } else if (inProgressItem.type === 'farm') {
        setType(PAGE_CONTENT.farm);
        setFarmDetails({
          id: inProgressItem.id,
          name: inProgressItem.name,
        });
        setCurrentFarmId(inProgressItem.id);
        setExpandedFarmId(inProgressItem.id);
        setCurrentTab(inProgressItem.yearIndex);
      }
    } else if (inProgressItem === null) {
      setModalData({
        flag: updatedFarmDetails.every((farm) =>
          farm.fieldDetails.every(
            (field) => field?.mainStatus?.toLowerCase() === 'completed',
          ),
        ),
        id: 0,
      });
      fetchBaselineYears(type, currentFarmId);
      setFetchLastSectionData(true);
    }
  };

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.baselineMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  useEffect(() => {
    if (!preventAutoSave) {
      saveDataHandler(false, true, true);
    }
    setPreventAutoSave(false);
  }, [currentTab]);

  useEffect(() => {
    let finalData = [];
    fieldDetails?.farmDetails?.map((farm) => finalData.push(farm?.mainStatus));
    setFinalList(finalData);
  }, [fieldDetails]);

  return (
    <MainWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}>
        <CustomBreadCrumbLink
          to={`/stakeholders/baseline-menu-page?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`}>
          {PAGE_CONTENT.home}
        </CustomBreadCrumbLink>
        <CustomBreadCrumbItem>
          {PAGE_CONTENT.baseline_reporting}
        </CustomBreadCrumbItem>
      </Breadcrumbs>
      <Tooltip title={projectDetails?.projectName}>
        <ProjectNameWrapper>
          <ProjectName>
            {projectDetails?.projectName + ' - ' + cycleYear || ''}
          </ProjectName>
          <ProjectName>{` (${projectCycleData?.cycleStartDate}`}</ProjectName>
          <ProjectName>{` - ${projectCycleData?.cycleEndDate})`}</ProjectName>
        </ProjectNameWrapper>
      </Tooltip>
      <Divider style={DividerStyles} />
      <ParentContainer style={TabPanelStyle}>
        <Backdrop
          sx={{
            color: WHITE,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: checkTernaryCondition(
              isInTest,
              'inherit',
              'white',
            ),
          }}
          open={
            loading ||
            loaderCounter > 0 ||
            apiLoading ||
            (!isInTest && enrollmentYears.length < 1)
          }>
          <CircularProgress color="success" />
        </Backdrop>
        <LeftSectionWrapper>
          <LeftMenuSubsection
            heading={PAGE_CONTENT.left_section_header}
            fieldData={fieldDetails?.farmDetails?.map((data) => ({
              id: data.farmId,
              status: data.mainStatus,
              value: data.farmName,
              fields: data.fieldDetails?.map((item) => ({
                value: item.fieldName,
                status: item.mainStatus,
                id: item.fieldId,
              })),
            }))}
            showFields={false}
            setCurrentFieldId={setCurrentFieldId}
            isBaselineFlow={true}
            currentFarmId={currentFarmId}
            setCurrentFarmId={setCurrentFarmId}
            setExpandedFarmId={setExpandedFarmId}
            expandedFarmId={expandedFarmId}
            selectedFieldId={currentFieldId}
            setFarmDetails={setFarmDetails}
            isInBaselinePage
            isFarmSelected={type === PAGE_CONTENT.farm}
            saveDataHandler={saveDataHandler}
          />
        </LeftSectionWrapper>
        <MainContainer padding="1.5rem" style={TabPanelStyle}>
          <TextWrapper fontSize="16px" fontWeight="600" color={DARK_CHARCOAL}>
            {`${
              checkTernaryCondition(
                type === PAGE_CONTENT.farm,
                farmDetails?.name,
                currentFieldId?.name,
              ) || ''
            } ${PAGE_CONTENT.baseline_heading}`}
          </TextWrapper>
          <Box
            sx={{
              ...flexColumn,
              ...yAxisAutoScroll,
            }}>
            <TabberBox style={TabberBoxCustomStyle}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="page-tabs">
                {baselineTabs?.map((tab, index) => (
                  <Tab
                    sx={{ textTransform: 'none', width: 'unset' }}
                    className={classNames({
                      'active-tab': currentTab === index,
                    })}
                    key={tab?.title}
                    label={tab?.title}
                    {...tabProps(index)}
                  />
                ))}
              </Tabs>
            </TabberBox>
            {baselineTabs?.map((tab, index) => (
              <TabPanel key={tab.title} value={currentTab} index={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Box>
          <EnrollmentCompleteModal
            modalData={modalData}
            modalCloseHandler={() => {
              setModalData({ flag: false, id: 0 });
            }}
            title={PAGE_CONTENT.submit_complete_modal_title}
            contentText={PAGE_CONTENT.submit_complete_modal_content}
            firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
            secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
            cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
            firstSubmitBtnHandler={() => {
              setModalData({ flag: false, id: 0 });
            }}
            secondSubmitBtnHandler={navigateHandler}
            cancelHandler={() => {
              setModalData({ flag: false, id: 0 });
            }}
          />
        </MainContainer>
      </ParentContainer>
    </MainWrapper>
  );
};

export default BaseLineReportingMainPage;
