export const PAGE_CONTENT = {
  project_name_label: 'Project Name',
  project_name_placeholder: 'Type to add project name',
  project_name_error: 'A project name is required for submission',
  remove_project_text: 'Remove project',
  add_variable_cost_structure: 'Add variable cost structure',
  existing_project_details_error:
    'This project was already added with matching data entered. To add the project again the project data must not be an exact match of a previous addition.',
  inventory_modal_error_title: 'Inventory allocated in one or more sales',
  inventory_modal_error_message:
    'This project inventory has been allocated in one or more sales. Please remove the associated sales inventory prior to deleting this project.',
};
