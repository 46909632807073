import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import greenfoodsco from './../../../assets/icons/greenfoodsco.png';
import { getReportDateIssued } from 'utils/helper';
import { styles } from './PageHeader.style';

const PageHeader = () => (
  <View style={styles?.container} fixed>
    <Image src={greenfoodsco} style={styles?.companyLogo} />
    <Text style={styles?.description}>{getReportDateIssued(new Date())}</Text>
  </View>
);

export default PageHeader;
