import styled from 'styled-components';
import { NEW_DARK_GREEN } from 'theme/GlobalColors';

export const filterIconStyle = (filtersApplied) => {
  return {
    color: filtersApplied ? NEW_DARK_GREEN : 'white',
    cursor: 'pointer',
    stroke: filtersApplied ? NEW_DARK_GREEN : '#63666A',
    height: '1.5rem',
    width: '1.5rem',
    strokeWidth: 2,
    '&:hover': {
      stroke: NEW_DARK_GREEN,
    },
  };
};

export const Filters = styled.div`
  height: fit-content;
  max-height: 31.25rem;
  min-width: 12.5rem;
  overflow-y: auto;
  padding: 0.5rem;

  p {
    display: none;
  }

  .MuiCheckbox-root {
    padding: 0.25rem 0.5rem;
  }
`;

export const FilterSection = styled.div`
  margin-bottom: 0.5rem;

  &.MuiSvgIcon-root {
    font-size: 0.625rem;
  }
`;

export const FilterSectionHeading = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const FilterActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.5rem;
`;

export const ActionButtonsSx = {
  fontSize: '0.78rem',
  padding: '0.5rem',
  textTransform: 'none',
  borderRadius: '4px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1rem',
  minWidth: 'fit-content',
};
