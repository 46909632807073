import { OutlinedInput } from '@mui/material';
import styled from 'styled-components';
import { DARK_GREEN, GRAYISH_BLUE, QUILL_GRAY, RED } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const Instruction = styled.div`
  font-size: 0.875rem;
  line-height: 19.6px;
  margin-bottom: 1rem;
`;

export const SectionHead = styled.div`
  padding: 1rem;
  width: 10rem;
  box-sizing: border-box;
`;

export const SectionTitle = styled.div`
  box-sizing: border-box;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 19.6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
`;

export const TitleText = styled.span`
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SectionWrapper = styled.div`
  display: flex;
  border: 1px solid ${QUILL_GRAY};
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  overflow-x: auto;
`;

export const ActivityItem = styled.div`
  width: 7.5rem;
`;

export const AcreageInput = styled(OutlinedInput)(({ error, disabled }) => ({
  width: '6.5rem',
  height: '2rem',

  '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: checkTernaryCondition(
      error,
      `1px solid ${RED}`,
      `1px solid ${GRAYISH_BLUE}`,
    ),
    outline: 'none',
    borderRadius: '2px',
  },

  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border:
      !disabled &&
      checkTernaryCondition(
        error,
        `1px solid ${RED}`,
        `2px solid ${DARK_GREEN}`,
      ),
    outline: 'none',
  },
  '&.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
    border: checkTernaryCondition(
      error,
      `1px solid ${RED}`,
      `2px solid ${DARK_GREEN}`,
    ),
    outline: 'none',
  },
}));

export const errorIconStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const edgeofFieldTolltipCustomSx = { marginLeft: '0.5rem' };

export const SectionListWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const SectionSubtitle = styled.div`
  font-size: 0.875rem;
`;
export const TooltipSx = {
  '& .MuiTooltip-tooltip': {
    position: 'relative',
    maxWidth: '20rem',
    top: '0.6rem',
    left: '-0.5rem',
  },
};
