import { TextModalContentWrapper } from './ProjectDetails.style';
export const PROJECT_DETAIL_CONTENTS = {
  internal_review_complete: 'internal review complete',
  internal_review: 'Internal Review',
  complete: 'Complete',
  under_review: 'Under Review',
  project_id: 'Project Id',
  registry: 'Registry',
  status: 'Status',
  developer: 'Developer',
  methodology: 'Methodology',
  type: 'Type',
  credit_start_end: 'Credit Start/End',
  emission: 'Estimated Annual Reduction Emissions',
  co_benefits: 'Co-Benefits',
  project_description: 'Description',
  notes: 'Notes',
  negative_one: '-1',
  empty: '--',
  empty_string: '',
  get: 'get',
  detail_endpoint: '/detail',
  edit_project_path: '/assets/projects/edit/',
  success: 'success',
  catalog: 'Catalog',
  tags: 'tags',
  project_detail: 'project-detail',
  project_details: '/assets/projects/',
  details: 'Details',
  page_tabs: 'page-tabs',
  last_updated_on: 'Last updated on ',
  looker_dashboard: 'looker-dashboard',
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  success_message: 'has been deleted successfully.',
  failed: 'FAILED',
  looker_dashboard_map: 'Looker Dashboard Map',
  looker_dashboard_main: 'Looker Dashboard Main',
  looker_map: 'looker_map',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  denied_message_single_transaction:
    'transaction. You must remove this project from the transaction before it can be deleted.',
  denied_message_multiple_transaction:
    'is linked in multiple transactions. You must remove this project from each transaction below before it can be deleted.',
  denied: 'DENIED',
  cannot_delete: "can't be deleted",
  project: 'Project',
  berkeley_Carbon_trading_Project_link:
    'https://gspp.berkeley.edu/research-and-impact/centers/cepp/projects/berkeley-carbon-trading-project ',
  max_text_length: 1200,
  see_more: '...see more',
};
export const projectTabs = ['Summary', 'Inventory'];

export const findDate = (field) => {
  return field !== null
    ? field?.month + '/' + field?.dayOfMonth + '/' + field?.year
    : '';
};
export const findEmbedUrl = (
  projectProfileId,
  clientId,
  id,
  location,
  looker_type,
) => {
  if (looker_type === 'looker_map') {
    return `?src=/embed/dashboards/${projectProfileId}?client_id=${clientId}%26internal_project_id=${id}%26theme%3DClearCarbonNoHeader%26embed_domain=${location}&sdk=2`;
  } else {
    return `?src=/embed/dashboards/${projectProfileId}?client_id=${clientId}%26internal_project_id=${id}%26_theme={"show_filters_bar":false}%26embed_domain=${location}&sdk=2`;
  }
};
export const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    key: index,
    'aria-controls': `portfolio-page-tab-${index}`,
  };
};

export const TextModalContent = ({ value }) => {
  return <TextModalContentWrapper>{value}</TextModalContentWrapper>;
};
export const SubDetailsArray = [
  { start: 3, end: 5 },
  { start: 0, end: 3 },
  { start: 3, end: 6 },
  { start: 6, end: 9 },
];
