import React from 'react';
import { SeeAllResultButton } from './SeeMoreButton.react';

export const CustomListBoxComponent = (props) => {
  return (
    <>
      <ul {...props} />
      <SeeAllResultButton />
    </>
  );
};
