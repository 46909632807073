export const PAGE_CONTENT = {
  vendor_form_heading: 'Add new stakeholder profile',
  vendor_type_label: 'What type of stakeholder is this?',
  vendor_type_placeholder: 'Select stakeholder',
  vendor_type_error: 'A stakeholder type is required for submission',
  required_field_subtext: '(Required)',
  vendor_organization_label: 'Organization',
  vendor_organization_placeholder: 'Type your stakeholders organization',
  vendor_organization_error:
    'A stakeholder organization is required for submission',
  vendor_duplicate_error_1: 'This organization already exists as a ',
  vendor_duplicate_error_2:
    'The organization can’t be added again with the same stakeholder type.',
  vendor_website_label: 'Website',
  vendor_website_placeholder: 'Type your stakeholders website',
  vendor_notes_label: 'Notes',
  vendor_notes_placeholder: 'Type your stakeholder notes',
  vendor_website_error: 'Please enter a valid website url',
  add_contact_button: 'Add contact',
  vendor_submit_button: 'Submit',
  vendor_cancel_button: 'Cancel',
  remove_contact_dialogue_heading_1:
    'Please confirm you want to remove the contact from',
  remove_contact_dialogue_heading_2: 'from',
  clear_details_label:
    'Are you sure you want to change the stakeholder type ? the form fields will be cleared',
  dialogue_confirm: 'Confirm',
  dialogue_cancel: 'Cancel',
  deletedLabel: 'Deleted •',
};
