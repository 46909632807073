import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapperDiv = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft};
  border-top: ${({ borderTop }) => borderTop};
  background: #ffff;
  height: 82vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;
export const ContentWrapper = styled.div`
  position: absolute;
  top: ${({ type }) => checkTernaryCondition(type, '50%', '40%')};
  width: ${({ width }) => width || '25%'};
  left: ${({ left }) => left};
`;
export const TextWrapper = styled.div`
  font-style: normal;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #333333;
  margin-bottom: 8px;
`;
export const SubTextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #333333;
`;
export const FilterTextWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 0.125rem;
`;
export const SearchedTextWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  font-size: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  white-space-collapse: preserve;
  overflow-wrap: break-word;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  margin-top: ${({ marginTop }) => marginTop || '32px'};
`;

export const addButtonStyle = {
  textTransform: 'none',
  backgroundColor: '#26890D',
  color: 'white',
  '&:hover': {
    backgroundColor: '#045206',
  },
};
