import { PAGE_CONTENT } from './PaymentSubsection.content';
import PropTypes from 'prop-types';
import {
  FormFields,
  FormLabels,
  DropDownPlaceholder,
  ErroWrapper,
  radioBtnSx,
  RadioButtonlabel,
  VintageSection,
  VariableDropdownSx,
} from './PaymentSubsection.style';
import { Select, MenuItem, OutlinedInput, Radio } from '@mui/material';
import { uniqueId } from 'utils/uniqueIdGenerator';
import PurchaseVintageSubsection from 'pages/AddPurchaseTransaction/components/PurchaseVintageSubsection/PurchaseVintageSubsection.react';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { ConditionConstants } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { useContext } from 'react';
import { EditPurchaseTransactionFormDirtyContext } from 'containers/EditPurchaseTransactionFormDirty.react';
import RemoveButton from 'components/RemoveButton/RemoveButton.react';
import { BLUE, GRAYISH_BLACK } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { getMenuItemStyles } from 'theme/GlobalStyles';

const PaymentSubsection = ({
  paymentObj,
  onUpdate,
  costStructure,
  viewMode,
  projectObj,
  editMode,
  vintageObj,
  onDelete,
  paymentNo,
  setLinkedErrorOpen,
}) => {
  const falseValue = false;
  const { markFormDirty } = useContext(EditPurchaseTransactionFormDirtyContext);

  const rofrHandler = (e) => {
    const value = e.target.value;
    onUpdate({
      ...paymentObj,
      rofrStatus: value,
      rofrError: value !== '' && falseValue,
    });
  };

  const rofrRadioButtonHandler = (item) => ({
    checked: paymentObj.rofrStatus === item,
    onChange: rofrHandler,
    value: item,
    name: 'rofrType',
    inputProps: { 'aria-label': item },
  });

  const variablePricehandler = (e) => {
    const value = e.target.value;
    onUpdate({
      ...paymentObj,
      variableCost: value,
      rofrStatus:
        value !== ConditionConstants.ROFR ? '' : paymentObj.rofrStatus,
      variableCostError: value !== '' && falseValue,
      variableCostDuplicateError: false,
      rofrError: value !== ConditionConstants.ROFR && falseValue,
    });
    markFormDirty();
  };

  const vintageUpdateHandler = (vintageObj) => {
    onUpdate({
      ...paymentObj,
      vintageDetails: paymentObj.vintageDetails.map((vintage) =>
        vintage.id === vintageObj.id ? vintageObj : vintage,
      ),
    });
  };

  const addVintageHandler = () => {
    onUpdate({
      ...paymentObj,
      vintageDetails: [
        ...paymentObj.vintageDetails,
        { ...vintageObj, id: uniqueId() },
      ],
    });
    markFormDirty();
  };

  const deleteVintageHandler = (id) => {
    onUpdate({
      ...paymentObj,
      vintageDetails: paymentObj.vintageDetails.filter(
        (vintage) => vintage.id !== id,
      ),
    });
  };

  return (
    <>
      <div>
        <FormFields marginBottom="0rem">
          <FormLabels
            iserror={
              paymentObj.variableCostError ||
              paymentObj.variableCostDuplicateError
            }>
            {`${PAGE_CONTENT.variable_cost_label} ${paymentNo + 1}`}
          </FormLabels>
          <div>
            <Select
              disabled={viewMode || paymentObj.isApiPopulated}
              value={paymentObj.variableCost}
              onChange={variablePricehandler}
              error={
                paymentObj.variableCostError ||
                paymentObj.variableCostDuplicateError
              }
              sx={VariableDropdownSx}
              name="variable_cost"
              inputProps={{ 'aria-label': 'variable_cost' }}
              displayEmpty
              input={<OutlinedInput />}
              renderValue={
                paymentObj.variableCost !== ''
                  ? undefined
                  : () => (
                      <DropDownPlaceholder>
                        {PAGE_CONTENT.variable_cost_placeholder}
                      </DropDownPlaceholder>
                    )
              }>
              {costStructure.map((type) => (
                <MenuItem
                  key={type.value}
                  value={type.value}
                  sx={getMenuItemStyles}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {projectObj.paymentDetails.length > 1 && !viewMode && (
              <RemoveButton
                label={PAGE_CONTENT.remove_variable_cost_label}
                clickHandler={() => {
                  if (paymentObj.saleContractNames.length > 0) {
                    setLinkedErrorOpen({
                      flag: true,
                      list: paymentObj.saleContractNames,
                    });
                  } else {
                    onDelete(paymentObj.id);
                    markFormDirty();
                  }
                }}
                labelColor={checkTernaryCondition(
                  paymentObj.saleContractNames.length > 0,
                  GRAYISH_BLACK,
                  BLUE,
                )}
              />
            )}
          </div>
          {paymentObj.variableCostError && (
            <ErroWrapper>{PAGE_CONTENT.variable_cost_error}</ErroWrapper>
          )}
        </FormFields>
        {paymentObj.variableCostDuplicateError && (
          <ErroWrapper>
            {PAGE_CONTENT.variable_cost_duplicate_error}
          </ErroWrapper>
        )}
        {paymentObj.variableCost === ConditionConstants.ROFR && (
          <FormFields>
            <FormLabels
              iserror={paymentObj.rofrError}
              className={paymentObj.rofrError ? 'Mui-error' : ''}>
              {PAGE_CONTENT.rofr_status_label}
            </FormLabels>
            <Radio
              disabled={viewMode}
              color="success"
              {...rofrRadioButtonHandler(PAGE_CONTENT.rofr_status_label_1)}
              sx={radioBtnSx}
            />
            <RadioButtonlabel>
              {PAGE_CONTENT.rofr_status_label_1}
            </RadioButtonlabel>
            <Radio
              disabled={viewMode}
              color="success"
              {...rofrRadioButtonHandler(PAGE_CONTENT.rofr_status_label_2)}
              sx={radioBtnSx}
            />
            <RadioButtonlabel>
              {PAGE_CONTENT.rofr_status_label_2}
            </RadioButtonlabel>
            {paymentObj.rofrError && (
              <ErroWrapper>{PAGE_CONTENT.rofr_error}</ErroWrapper>
            )}
          </FormFields>
        )}
        <VintageSection>
          {paymentObj.vintageDetails.map((vintageDetail, index) => {
            const id = index;
            return (
              <PurchaseVintageSubsection
                key={id}
                vintageNo={index}
                vintageObj={vintageDetail}
                viewMode={viewMode}
                editMode={editMode}
                projectObj={projectObj}
                onUpdate={vintageUpdateHandler}
                vintageList={paymentObj.vintageDetails}
                onDelete={deleteVintageHandler}
                setLinkedErrorOpen={setLinkedErrorOpen}
              />
            );
          })}
          <AddSectionButton
            label={PAGE_CONTENT.add_vintage_button}
            onClickHanlder={addVintageHandler}
            hiddenFlag={viewMode}
          />
        </VintageSection>
      </div>
    </>
  );
};

PaymentSubsection.propTypes = {
  paymentObj: PropTypes.object,
  onUpdate: PropTypes.func,
  costStructure: PropTypes.object,
  viewMode: PropTypes.string,
  projectObj: PropTypes.object,
  editMode: PropTypes.bool,
  vintageObj: PropTypes.object,
  onDelete: PropTypes.func,
  paymentNo: PropTypes.number,
  setLinkedErrorOpen: PropTypes.func,
};

export default PaymentSubsection;
