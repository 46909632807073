import styled from 'styled-components';
import {
  INPUT_ERROR_RED,
  DARK_GREEN,
  COOL_GRAY,
  GRAYISH_BLUE,
} from 'theme/GlobalColors';

export const AutocompleteInput = styled.input`
  padding: 0.25rem 0 0.25rem 0.5rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  border: none;
  border-bottom: ${({ hasError, isDisabled }) =>
    getBottomBorder(hasError, isDisabled)};
  &:focus {
    border-bottom: ${({ hasError }) =>
      hasError ? `2px solid ${INPUT_ERROR_RED}` : `2px solid ${DARK_GREEN}`};
  }
  outline: none;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : '')};
  height: 2rem;
  ::placeholder {
    color: ${COOL_GRAY};
    opacity: 0.7;
  },
`;

export const getBottomBorder = (hasError, isDisabled) => {
  if (isDisabled) return `1px dotted ${DARK_GREEN}`;
  else if (hasError) return `1px solid ${INPUT_ERROR_RED}`;
  else return `1px solid ${GRAYISH_BLUE}`;
};
