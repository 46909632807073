import styled from 'styled-components';
import { SortColValues } from './StakeholderProfile.content';

export const HeadingWrapper = styled.div`
  display: flex;
  height: 1.75rem;
  /* width: 100%; */
  margin: 0 0 0 0;
  padding: 0 2rem 0 2rem;
  grid-column-gap: 1rem;
  justify-content: space-between;
`;
export const HeadingSubtext = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #313233;
  display: flex;
  flex-direction: row;
`;

export const StakeholderTypeTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.25rem;
  gap: 0.25rem;
  width: 3.688rem;
  height: 1.188rem;
  background: #d0d0ce;
  border-radius: 0.25rem;
  margin-top: 0.188rem;
  margin-left: 1rem;
`;
export const TagContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.938rem;
  display: flex;
  align-items: center;
  color: #63666a;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  padding: 0 2rem 0 2rem;
  grid-row-gap: 1rem;
`;
export const ContentSubwrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 1.063rem;
`;
export const NotesWrapper = styled.div`
  width: 50%;
  height: 12.5rem;
  padding: 1rem 1.5rem 0 1.5rem;
  background-color: #ffff;
`;
export const NotesHeading = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  color: #333333;
  margin-bottom: 1rem;
`;
export const NotesContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  letter-spacing: -0.2px;
  color: #333333;
  margin-left: 0.5rem;
  height: 5.938rem;
  overflow-y: auto;
`;
export const ContactWrapper = styled.div`
  width: 50%;
  /* height: 12.5rem; */
  background-color: #ffff;
`;
export const ContactHeading = styled.div`
  padding: 0.625rem 0 0.625rem 1rem;
  border-bottom: 1px solid #d0d0ce;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #333333;
`;
export const dataGridSx = {
  border: 0,
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: '1px solid #FFFF',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-iconButtonContainer': {
    marginLeft: '2px',
    visibility: 'visible',
    width: 'auto',
  },
};

export const PrimaryContactWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.25rem;
  gap: 0.25rem;
  width: 3.063rem;
  height: 1.188rem;
  background: #ffffff;
  border: 0.5px solid #26890d;
  border-radius: 0.25rem;
`;

export const PrimaryText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.938;
  display: flex;
  align-items: center;
  color: #26890d;
`;
export const ContactSubWrapper = styled.div`
  height: 10.5rem;
`;

export const ProjectWrapper = styled.div`
  width: 100%;
  background-color: #ffff;
  height: 35rem;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
  &:hover {
    background-color: #e3dada36;
  }
`;

export const handleArrowColor = (col, sortColumn) => {
  return {
    color:
      sortColumn === SortColValues[col] ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  };
};
export const FilterHeader = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;
export const NumericCell = styled.span`
  margin-right: 2.1rem;
`;
export const TruncatedWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const OverviewTable = styled.div`
  .stakeholder-profile {
    height: 57rem;
  }
`;
