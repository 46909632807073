import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import { Toolbar, Box } from '@mui/material';
import ProfileSection from './ProfileSection.react';
import {
  useStyles,
  BoxStyle,
  logoStyle,
  logoWrapper,
  logoImgStyle,
} from './style';
import NotificationLog from './NotificationLog.react';
import { checkTernaryCondition } from 'utils/helper';
import { useContext, useMemo } from 'react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { CLIENT_LOGO_BASE64_KEY } from 'utils/config';
import { useLocation } from 'react-router';
import {
  landingPath,
  participantActivityPaths,
  participantBaselinePaths,
  participantContributorPaths,
  participantEnrollmentPaths,
} from './Header.content';
import EnrollmentHelpButton from './EnrollmentHelpButton/EnrollmentHelpButton.react';
import ClearCarbonLogo from '../../assets/Deloitte.png';

const Header = () => {
  const { HeaderContainer, LogoBox } = useStyles();
  const { clientConfigs } = useContext(userAuthRolesContext);
  const location = useLocation();

  const logoBase64 = useMemo(() => {
    for (const config of clientConfigs) {
      if (config['key'] === CLIENT_LOGO_BASE64_KEY) return config['value'];
    }
  }, [clientConfigs]);

  const inEnrollmentOrBaselineFlow = useMemo(
    () =>
      participantEnrollmentPaths.includes(location.pathname) ||
      participantBaselinePaths.includes(location.pathname) ||
      participantActivityPaths.includes(location.pathname) ||
      participantContributorPaths.includes(location.pathname) ||
      location.pathname === landingPath,
    [location],
  );

  const isSvg = logoBase64?.startsWith('PHN2Z');
  const logoImageType = isSvg ? 'svg+xml' : 'png';

  return (
    location.pathname !== '/login' && (
      <AppBar position="fixed" elevation={0}>
        <HeaderContainer maxWidth="xxl">
          <Box>
            <LogoBox>
              <Toolbar variant="dense" disableGutters sx={logoWrapper}>
                <img
                  src={ClearCarbonLogo}
                  alt="Clear Carbon"
                  style={logoStyle}
                />
              </Toolbar>
            </LogoBox>
          </Box>
          <Box sx={BoxStyle}>
            <img
              src={`data:image/${logoImageType};base64,${logoBase64}`}
              alt="Client logo"
              style={logoImgStyle}
            />
            {checkTernaryCondition(
              !inEnrollmentOrBaselineFlow,
              <>
                <EnrollmentHelpButton section="global-help-button" />
                <NotificationLog />
                <ProfileSection />
              </>,
              <EnrollmentHelpButton section="participant-help-button" />,
            )}
          </Box>
        </HeaderContainer>
      </AppBar>
    )
  );
};

export default Header;
