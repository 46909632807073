import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import GoogleMapSearchAutocomplete from 'components/FormComponents/GoogleMapSearchAutocomplete/GoogleMapSearchAutocomplete.react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { useContext, useEffect, useState } from 'react';
import {
  AddFarmWrapper,
  FarmName,
  FarmTitleWrapper,
  FarmWrapper,
  FormFieldsWrapper,
  FormSection,
  MainWrapper,
  NameSectionWrapper,
  TextWrapper,
  deleteIconSx,
  fsaIndividualNameWrapperStyle,
  radioBtnPadding,
} from './FarmInfoList.style';
import axios from 'axios';
import {
  checkTernaryCondition,
  extractAddrFromGoogleMapComponents,
  getMailingAddress,
  getNextName,
  isNullOrEmpty,
  onlyCharsRegex,
} from 'utils/helper';
import { FARM_INFO_BASE, FETCH_AVAILABLE_FARMS } from 'urls';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { OptionListStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';
import {
  OptionNoBoldStyle,
  toastStyle,
  pointerCursor,
} from 'theme/GlobalStyles';
import {
  FsaIdTypes,
  PAGE_CONTENT,
  formFieldsNames,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.content';
import { STYLE_CONSTANTS } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import { InputSx } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import {
  LocationSectionWrapper,
  dropdownInputSx,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import { COOL_GRAY, WHITE } from 'theme/GlobalColors';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  deleteModalInit,
  getDeleteModalSubTitle,
  getDeleteModalTitle,
  getEmptyErrorState,
  getEmptyFarm,
} from './FarmInfoList.content';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { FALSE, GLOBAL_STRING_CONSTANTS } from 'utils/config';
import { ReactComponent as AddIconWithHoverEffect } from 'assets/icons/AddIconWithHoverEffect.svg';
import UserNavigationButtons from '../UserNavigationButtons/UserNavigationButtons.react';
import { pathName } from 'Routes/routes.const';
import {
  enrollmentInstanceStatus,
  getFarmErrors,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
const FarmInfoList = () => {
  const [searchParams] = useSearchParams();
  const participantId = searchParams.get('participantid');
  const projectId = searchParams.get('projectid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');

  const {
    setUserActivityDetected,
    fetchCountyList,
    fetchStateList,
    fetchParticipantData,
    bulkFarmSubmit,
    farmList,
    updateAddFarmAndContributorForm,
    setFarmList,
    farmAndContributorTabErrorList: errorList,
    setFarmAndContributorTabErrorList: setErrorList,
    loading,
    setLoading,
    setFarmAndContributorTab,
    statusList,
  } = useContext(participantInfoContext);
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const [farmNameList, setFarmNameList] = useState([]);
  const [fsaIdList, setFsaIdList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [countyList, setCountyList] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [deleteModalContent, setDeleteModalContent] = useState(deleteModalInit);
  const navigate = useNavigate();
  const hasMultipleFarms = farmList?.length > 1;

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);
  const gotoMenuPage = () =>
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: false } },
    );

  // Note: Function to update values inside farmList using key value pair
  const handleInputChange = (inputKey, inputValue, index) => {
    setFarmList((prev) =>
      prev.map((farm, farmIndex) =>
        checkTernaryCondition(
          farmIndex === index,
          { ...farm, [inputKey]: inputValue },
          farm,
        ),
      ),
    );
  };

  const handleErrorStateUpdate = (errorKey, errorValue, farmId) => {
    setErrorList((prev) => ({
      ...prev,
      [farmId]: { ...prev[farmId], [errorKey]: errorValue },
    }));
  };

  // Note: Function to update values directly inside list
  const updateFarmData = (newData, index) => {
    setFarmList((prev) =>
      prev.map((farm, farmIndex) =>
        checkTernaryCondition(farmIndex === index, newData, farm),
      ),
    );
  };

  const updateErrorState = (newErrorState, farmId) => {
    setErrorList((prev) => ({
      ...prev,
      [farmId]: newErrorState,
    }));
  };

  const fetchAutocompleteFarmList = (type, searchInput) => {
    if (isNullOrEmpty(searchInput)) {
      if (type === 'name') setFarmNameList([]);
      else setFsaIdList([]);

      return;
    }

    const URL = `${FETCH_AVAILABLE_FARMS}/${participantId}`;
    axios
      .get(URL, {
        params: {
          farmAttribute: type,
          search: searchInput,
        },
      })
      .then((response) => {
        if (type === 'name') {
          setFarmNameList(
            response.data.map((data) => ({
              label: data.farmName,
              value: data.fsaId,
              id: data.farmId,
            })),
          );
        } else {
          setFsaIdList(
            response.data.map((data) => ({
              label: data.fsaId,
              value: data.farmName,
              id: data.farmId,
            })),
          );
        }
      });
  };

  const removeFarm = (farmId) => {
    setFarmList((prev) => prev.filter((farm) => farm.farmId !== farmId));
  };

  const handleDelete = ({ farmId, farmName }) => {
    if (typeof farmId === 'string') {
      removeFarm(farmId);
      return;
    }

    setLoading(true);
    axios
      .delete(`${FARM_INFO_BASE}/${farmId}`)
      .then(() => {
        removeFarm(farmId);
        fetchFarmList();
        fetchParticipantData(false);
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={`${farmName} ${PAGE_CONTENT.deleteErrorSuffix}`}
          />,
          toastStyle,
        );
      })
      .finally(() => setLoading(false));
  };

  const onSearchLoad = (autocomplete, farmId) => {
    setAutocomplete((prev) => ({ ...prev, [farmId]: autocomplete }));
  };

  const getStateId = (stateList, stateName) => {
    return stateList?.find((state) => state.value === stateName)?.stateId;
  };

  const updateCountyList = (stateId, farmId) => {
    fetchCountyList(stateId).then((newCountyList) => {
      setCountyList((prev) => ({ ...prev, [farmId]: newCountyList }));
    });
  };

  const populateCountyList = async (stateList, farms) => {
    for (const farm of farms) {
      const stateId = getStateId(stateList, farm.farmState);
      const newCountyList = await fetchCountyList(stateId);

      setCountyList((prev) => ({
        ...prev,
        [farm.farmId]: newCountyList,
      }));
    }
  };

  const onPlaceChanged = (farm, farmIndex) => {
    const farmId = farm.farmId;
    const searchComp = autocomplete?.[farmId];
    if (searchComp === null || !searchComp) return;

    const place = searchComp.getPlace();

    const newName = `${place.name}${getMailingAddress(
      place.address_components,
    )}`;
    if (place.address_components) {
      const address = extractAddrFromGoogleMapComponents(
        place.address_components,
      );
      const { city, state, zipCode, county } = address;

      const stateId = getStateId(stateList, state);
      updateCountyList(stateId, farm.farmId);

      updateFarmData(
        {
          ...farm,
          farmMailingAddress: newName,
          farmCity: city,
          farmState: state,
          farmZipcode: zipCode,
          farmCounty: county,
        },
        farmIndex,
      );

      const errorState = errorList[farm.farmId];
      updateErrorState(
        {
          ...errorState,
          mailingAddressRequiredError: FALSE,
          cityRequiredError: FALSE,
          stateRequiredError: FALSE,
          countyRequiredError: FALSE,
          zipCodeRequiredError: FALSE,
        },
        farm.farmId,
      );
    }
  };

  const addNewFarm = (event) => {
    event.currentTarget.blur();
    let existingFarmNames = farmList.map((farm) => farm.farmName);
    const newFieldName = getNextName(
      existingFarmNames,
      GLOBAL_STRING_CONSTANTS.default_farm_name_prefix,
    );

    const newFarm = getEmptyFarm(newFieldName);
    setErrorList((prev) => ({
      ...prev,
      [newFarm.farmId]: getEmptyErrorState(),
    }));
    setFarmList((prev) => [...prev, newFarm]);
  };

  const submitData = (event) => {
    event?.currentTarget.blur();
    let farmData;
    farmData = bulkFarmSubmit(farmList).then(() => {
      fetchFarmList();
      setFarmAndContributorTab(1);
    });
    return farmData;
  };

  const fetchFarmList = async () => {
    updateAddFarmAndContributorForm().then(async (farms) => {
      const stateList = await fetchStateList();
      setStateList(stateList);
      populateCountyList(stateList, farms);
    });
  };

  const handleSaveAndClose = (event) => {
    if (isEmailLinkClicked) {
      if (statusList === PAGE_CONTENT.COMPLETED) {
        submitData(event).then(gotoMenuPage);
      } else {
        navigate(`${pathName.participant.landingPage}`);
      }
    } else {
      submitData(event).then(gotoMenuPage);
    }
  };

  useEffect(() => {
    fetchFarmList();

    return () => {
      setCountyList(null);
      setStateList([]);
    };
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color={PAGE_CONTENT.success} />
      </Backdrop>
      <FormSection>
        {farmList?.map((farm, index) => {
          const countyOptions = countyList?.[farm.farmId] ?? [];
          const errorState = errorList?.[farm.farmId] ?? null;
          const disableAllFields =
            farm.enrollmentStatus === enrollmentInstanceStatus.approved;

          return (
            <MainWrapper key={farm.farmId}>
              <FarmTitleWrapper>
                <FarmName>{farm.farmName}</FarmName>
                {hasMultipleFarms && (
                  <div>
                    <DeleteIcon
                      width={16}
                      height={16}
                      sx={deleteIconSx(disableAllFields)}
                      style={pointerCursor}
                      onClick={() => {
                        setDeleteModalContent({
                          open: true,
                          title: getDeleteModalTitle(farm.farmName),
                          subtitle: getDeleteModalSubTitle(farm.farmName),
                          farmId: farm?.farmId,
                          farmName: farm.farmName,
                        });
                      }}
                    />
                  </div>
                )}
              </FarmTitleWrapper>
              <FormFieldsWrapper>
                <BasicAutoComplete
                  options={farmNameList}
                  id="disable-close-on-select"
                  label={PAGE_CONTENT.farm_name_label}
                  value={farm.farmName || ''}
                  loading={true}
                  required
                  forcePopupIcon={farmNameList.length > 0}
                  isError={errorState?.farmNameRequiredError}
                  width={PAGE_CONTENT.hundered_percent}
                  ErrorMessage={PAGE_CONTENT.farm_name_primary_error_message}
                  secondaryErrorMessage={
                    PAGE_CONTENT.farm_name_repeated_error_msg
                  }
                  placeholder={PAGE_CONTENT.farm_name_placeholder}
                  loadingText={PAGE_CONTENT.farm_name_placeholder}
                  onChangeHandler={(value) => {
                    if (farmNameList.length > 0 && value !== null) {
                      handleInputChange('farmName', value.label, index);
                      handleErrorStateUpdate(
                        'farmNameRequiredError',
                        value.label.length === 0,
                        farm.farmId,
                      );
                    }
                  }}
                  inputHandler={(event, newValue, reason) => {
                    setFilterText(event?.target?.value);
                    handleInputChange('farmName', newValue, index);
                    fetchAutocompleteFarmList('name', newValue);
                    if (reason === 'input') makeFormDirty();
                  }}
                  renderCustomOptions={(props, option) => {
                    const lowerText = filterText?.toLowerCase();
                    const beginIndex = option?.label
                      .toLowerCase()
                      .indexOf(lowerText);
                    if (beginIndex === -1) return null;
                    return (
                      <li {...props} key={option.value}>
                        <span style={OptionListStyle}>
                          {option?.label.substr(0, beginIndex)}
                        </span>
                        <span style={OptionNoBoldStyle}>
                          {option?.label.substr(beginIndex, filterText.length)}
                        </span>
                        <span style={OptionListStyle}>
                          {option?.label.substr(beginIndex + filterText.length)}
                        </span>
                      </li>
                    );
                  }}
                  name={formFieldsNames.farmName}
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  inputFontSize={STYLE_CONSTANTS.input_font_size}
                  isDisabled={disableAllFields}
                />
                <BasicAutoComplete
                  options={fsaIdList}
                  id="disable-close-on-select"
                  label={PAGE_CONTENT.FSA_farm_ID_label}
                  value={farm.fsaId || ''}
                  loading={true}
                  required
                  isError={errorState?.fsaIdRequiredError}
                  width={PAGE_CONTENT.fsa_id_width}
                  tooltipProps={{
                    title: null,
                    content: PAGE_CONTENT.FSA_farm_ID_tooltip,
                  }}
                  forcePopupIcon={fsaIdList.length > 0}
                  ErrorMessage={PAGE_CONTENT.FSA_farm_ID_primary_error_message}
                  placeholder={PAGE_CONTENT.FSA_farm_placeholder}
                  loadingText={PAGE_CONTENT.FSA_farm_placeholder}
                  onChangeHandler={(value) => {
                    if (fsaIdList.length > 0 && value !== null) {
                      handleInputChange(formFieldsNames.fsaId, value, index);
                      handleErrorStateUpdate(
                        'fsaIdRequiredError',
                        value.length === 0,
                        farm.farmId,
                      );
                    }
                  }}
                  inputHandler={(e, newValue, reason) => {
                    setFilterText(e?.target.value);
                    handleInputChange(formFieldsNames.fsaId, newValue, index);
                    handleErrorStateUpdate(
                      'fsaIdRequiredError',
                      newValue.length === 0,
                      farm.farmId,
                    );
                    fetchAutocompleteFarmList('id', newValue);
                    if (reason === 'input') makeFormDirty();
                  }}
                  renderCustomOptions={(props, option) => {
                    const smallCaseFilterText = filterText?.toLowerCase();
                    const stIdx = option?.label
                      .toLowerCase()
                      .indexOf(smallCaseFilterText);
                    if (stIdx === -1) return null;
                    return (
                      <li {...props} key={option.value}>
                        <span style={OptionListStyle}>
                          {option?.label.substr(0, stIdx)}
                        </span>
                        <span style={OptionNoBoldStyle}>
                          {option?.label.substr(stIdx, filterText.length)}
                        </span>
                        <span style={OptionListStyle}>
                          {option?.label.substr(stIdx + filterText.length)}
                        </span>
                      </li>
                    );
                  }}
                  name={formFieldsNames.fsaId}
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  inputFontSize={STYLE_CONSTANTS.input_font_size}
                  isDisabled={disableAllFields}
                />
                <RadioButtonsGroup
                  selectedValue={farm.fsaIdType}
                  list={FsaIdTypes}
                  label={PAGE_CONTENT.fsaId_type_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    updateFarmData(
                      {
                        ...farm,
                        fsaIdType: e.target.value,
                        entityName: '',
                        firstName: '',
                        lastName: '',
                      },
                      index,
                    );
                    makeFormDirty();
                    updateErrorState(
                      {
                        ...errorState,
                        fsaIdTypeRequiredError: FALSE,
                        firstNameRequiredError: FALSE,
                        lastNameRequiredError: FALSE,
                        entityNameRequiredError: FALSE,
                      },
                      farm.farmId,
                    );
                  }}
                  primaryError={errorState?.fsaIdTypeRequiredError}
                  primaryErrorMessage={PAGE_CONTENT.fsaId_type_required_error}
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.zero_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  hasTooltipOnLabel
                  tooltipContent={PAGE_CONTENT.fsaId_type_tooltip}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                  isDisabled={disableAllFields}
                />
                {farm.fsaIdType === 'Entity' && (
                  <InputField
                    value={farm.entityName}
                    label={PAGE_CONTENT.entity_name_label}
                    placeholder={PAGE_CONTENT.entity_name_placeholder}
                    width={PAGE_CONTENT.hundered_percent}
                    name={formFieldsNames.entityName}
                    onUpdate={(e) => {
                      const newVal = e.target.value;
                      handleInputChange(
                        formFieldsNames.entityName,
                        newVal,
                        index,
                      );
                      handleErrorStateUpdate(
                        'entityNameRequiredError',
                        newVal.length === 0,
                        farm.farmId,
                      );
                      makeFormDirty();
                    }}
                    primaryError={errorState?.entityNameRequiredError}
                    primaryErrorMessage={
                      PAGE_CONTENT.entity_name_required_error
                    }
                    hasTooltip
                    tooltipContent={PAGE_CONTENT.entity_name_tooltip}
                    labelMarginTop={STYLE_CONSTANTS.one_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    isDisabled={disableAllFields}
                  />
                )}
                {farm.fsaIdType === 'Individual' && (
                  <NameSectionWrapper>
                    <InputField
                      value={farm.firstName}
                      label={PAGE_CONTENT.first_name_label}
                      placeholder={PAGE_CONTENT.first_name_placeholder}
                      customStyles={fsaIndividualNameWrapperStyle}
                      name={formFieldsNames.firstName}
                      onUpdate={(e) => {
                        const newVal = e.target.value;
                        handleInputChange(
                          formFieldsNames.firstName,
                          newVal,
                          index,
                        );
                        handleErrorStateUpdate(
                          'firstNameRequiredError',
                          newVal.length === 0,
                          farm.farmId,
                        );
                        makeFormDirty();
                      }}
                      width="100%"
                      primaryError={errorState?.firstNameRequiredError}
                      primaryErrorMessage={
                        PAGE_CONTENT.first_name_required_error
                      }
                      hasTooltip
                      tooltipContent={PAGE_CONTENT.first_name_tooltip}
                      labelMarginTop={STYLE_CONSTANTS.one_rem}
                      labelMarginBottom={
                        STYLE_CONSTANTS.zero_point_two_five_rem
                      }
                      rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                      customInputSx={InputSx}
                      minWidth={STYLE_CONSTANTS.zero_rem}
                      isDisabled={disableAllFields}
                    />
                    <InputField
                      value={farm.lastName}
                      label={PAGE_CONTENT.last_name_label}
                      width="100%"
                      placeholder={PAGE_CONTENT.last_name_placeholder}
                      customStyles={fsaIndividualNameWrapperStyle}
                      name={formFieldsNames.lastName}
                      onUpdate={(e) => {
                        const newVal = e.target.value;
                        handleInputChange(
                          formFieldsNames.lastName,
                          newVal,
                          index,
                        );
                        handleErrorStateUpdate(
                          'lastNameRequiredError',
                          newVal.length === 0,
                          farm.farmId,
                        );
                        makeFormDirty();
                      }}
                      primaryError={errorState?.lastNameRequiredError}
                      primaryErrorMessage={
                        PAGE_CONTENT.last_name_required_error
                      }
                      labelMarginTop={STYLE_CONSTANTS.one_rem}
                      labelMarginBottom={
                        STYLE_CONSTANTS.zero_point_two_five_rem
                      }
                      rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                      customInputSx={InputSx}
                      minWidth={STYLE_CONSTANTS.zero_rem}
                      isDisabled={disableAllFields}
                    />
                  </NameSectionWrapper>
                )}

                <GoogleMapSearchAutocomplete
                  label={PAGE_CONTENT.mailing_address_label}
                  value={farm.farmMailingAddress}
                  valueChangeHandler={(e) => {
                    const newVal = e.target.value;
                    handleInputChange('farmMailingAddress', newVal, index);
                    handleErrorStateUpdate(
                      'mailingAddressRequiredError',
                      newVal.length === 0,
                      farm.farmId,
                    );
                    makeFormDirty();
                  }}
                  placeholder={PAGE_CONTENT.mailing_address_placeholder}
                  onPlaceChanged={() => onPlaceChanged(farm, index)}
                  onSearchLoad={(autocomplete) =>
                    onSearchLoad(autocomplete, farm.farmId)
                  }
                  hasError={errorState?.mailingAddressRequiredError}
                  errorMsg={PAGE_CONTENT.mailing_address_required_error}
                  labelMarginTop={STYLE_CONSTANTS.one_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  isDisabled={disableAllFields}
                />

                <LocationSectionWrapper>
                  <InputField
                    value={farm.farmCity}
                    label={PAGE_CONTENT.city_label}
                    placeholder={PAGE_CONTENT.city_placeholder}
                    customStyles={{ width: '100%' }}
                    minWidth="0rem"
                    width="100%"
                    name={'farmCity'}
                    onUpdate={(e) => {
                      if (onlyCharsRegex.test(e.target.value)) {
                        const newVal = e.target.value;
                        handleInputChange('farmCity', newVal, index);
                        handleErrorStateUpdate(
                          'cityRequiredError',
                          newVal.length === 0,
                          farm.farmId,
                        );
                        makeFormDirty();
                      }
                    }}
                    primaryError={errorState?.cityRequiredError}
                    primaryErrorMessage={PAGE_CONTENT.city_required_error}
                    labelMarginTop={STYLE_CONSTANTS.one_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                    isDisabled={disableAllFields}
                  />
                  <DropDown
                    label={PAGE_CONTENT.state_label}
                    value={checkTernaryCondition(
                      farm.farmState === '' ||
                        farm.farmState === null ||
                        farm.farmState === undefined,
                      PAGE_CONTENT.state_placeholder,
                      farm.farmState,
                    )}
                    dropDownPlaceholder={PAGE_CONTENT.state_placeholder}
                    dropdownlist={stateList}
                    width="100%"
                    isNotOutlined={true}
                    minWidth="6.25rem"
                    showLabelAsValue={true}
                    height="2rem"
                    onUpdate={(e) => {
                      const newState = e.target.value;
                      const newStateId = getStateId(stateList, newState);
                      updateFarmData(
                        {
                          ...farm,
                          farmState: newState,
                          farmCounty: '',
                        },
                        index,
                      );
                      handleErrorStateUpdate(
                        'stateRequiredError',
                        FALSE,
                        farm.farmId,
                      );
                      updateCountyList(newStateId, farm.farmId);
                      makeFormDirty();
                    }}
                    setStateValue={() => {}}
                    name={''}
                    ariaLabel={''}
                    error={errorState?.stateRequiredError}
                    errorMessage={PAGE_CONTENT.state_required_error}
                    customPlaceholderColor={checkTernaryCondition(
                      farm.farmState === '' ||
                        farm.farmState === null ||
                        farm.farmState === undefined,
                      COOL_GRAY,
                      null,
                    )}
                    fontSize={STYLE_CONSTANTS.input_font_size}
                    hasNoBottomMargin={true}
                    labelMarginTop={STYLE_CONSTANTS.one_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customSx={dropdownInputSx}
                    isDisabled={disableAllFields}
                  />
                  <DropDown
                    label={PAGE_CONTENT.county_label}
                    value={checkTernaryCondition(
                      farm.farmCounty === '' ||
                        farm.farmCounty === null ||
                        farm.farmCounty === undefined,
                      PAGE_CONTENT.county_placeholder,
                      farm.farmCounty,
                    )}
                    dropDownPlaceholder={PAGE_CONTENT.county_placeholder}
                    dropdownlist={countyOptions}
                    width="100%"
                    isNotOutlined={true}
                    minWidth="6.25rem"
                    showLabelAsValue={true}
                    height="2rem"
                    onUpdate={(e) => {
                      handleInputChange('farmCounty', e.target.value, index);
                      handleErrorStateUpdate(
                        'countyRequiredError',
                        FALSE,
                        farm.farmId,
                      );
                      makeFormDirty();
                    }}
                    setStateValue={() => {}}
                    name={''}
                    ariaLabel={''}
                    error={errorState?.countyRequiredError}
                    errorMessage={PAGE_CONTENT.county_required_error}
                    customPlaceholderColor={checkTernaryCondition(
                      farm.farmCounty === '' ||
                        farm.farmCounty === null ||
                        farm.farmCounty === undefined,
                      COOL_GRAY,
                      null,
                    )}
                    fontSize={STYLE_CONSTANTS.input_font_size}
                    hasNoBottomMargin={true}
                    labelMarginTop={STYLE_CONSTANTS.one_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customSx={dropdownInputSx}
                    isDisabled={disableAllFields}
                  />
                  <InputField
                    value={farm.farmZipcode ?? ''}
                    label={PAGE_CONTENT.zip_code_label}
                    placeholder={PAGE_CONTENT.zip_code_placeholder}
                    customStyles={{ minWidth: '6.25rem' }}
                    minWidth="6.25rem"
                    name="farmZipcode"
                    onUpdate={(e) => {
                      if (e.target.value.match(/^\d*$/)) {
                        const newVal = e.target.value;
                        handleInputChange('farmZipcode', newVal, index);
                        handleErrorStateUpdate(
                          'zipCodeRequiredError',
                          newVal.toString().trim().length === 0,
                          farm.farmId,
                        );
                        makeFormDirty();
                      }
                    }}
                    primaryError={errorState?.zipCodeRequiredError}
                    primaryErrorMessage={PAGE_CONTENT.zip_code_required_error}
                    labelMarginTop={STYLE_CONSTANTS.one_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                    isDisabled={disableAllFields}
                  />
                </LocationSectionWrapper>
              </FormFieldsWrapper>
            </MainWrapper>
          );
        })}
        <div>
          <Button
            variant="contained"
            data-testid="add-farm"
            size="medium"
            sx={AddFarmWrapper}
            onClick={addNewFarm}
            disableElevation
            className="focus-effect">
            <FarmWrapper>
              <AddIconWithHoverEffect /> <TextWrapper>Farm</TextWrapper>
            </FarmWrapper>
          </Button>
        </div>
        <div>
          <UserNavigationButtons
            saveAndContinueHandler={submitData}
            saveAndCloseHandler={handleSaveAndClose}
            CancelHandler={gotoMenuPage}
          />
        </div>
      </FormSection>

      <DialogBox
        dialogActions
        deleteOperation
        isOpen={deleteModalContent.open}
        onConfirm={() => {
          handleDelete(deleteModalContent);
          setDeleteModalContent(deleteModalInit);
        }}
        onCancel={() => setDeleteModalContent(deleteModalInit)}
        title={deleteModalContent.title}
        subtitle={deleteModalContent.subtitle}
        declineCtnLabel={PAGE_CONTENT.deleteModalCancelText}
        acceptCtnLabel={PAGE_CONTENT.deleteModalAcceptText}
      />
    </div>
  );
};

export default FarmInfoList;
