import styled from 'styled-components';
import { COOL_GRAY, TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { listItemFontSize } from 'theme/GlobalStyles';

export const dropdownStyleProps = {
  fontSize: '0.875rem',
  width: '13.75rem',
  height: '2rem',
  ...listItemFontSize,
};

export const DatePickerWithDelete = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: ${({ margin }) => margin};
  margin-bottom: 1rem;
`;

export const PercentageWrapper = styled.div`
  position: relative;
  left: -175px;
  color: COOL_GRAY;
  fontsize: 14px;
  margin-bottom: 1rem;
`;

export const AddOptionWrapper = styled.div`
  display: flex;
  width: 100px;
  align-items: baseline;
  cursor: pointer;
`;

export const tillageDepthUnitStyles = (isError) => ({
  marginBottom: checkTernaryCondition(isError, '1.9rem', '1rem'),
  color: COOL_GRAY,
  position: 'relative',
  left: '-50px',
  fontSize: '14px',
});

export const deleteIconStyles = (firstIcon, parentFormatError, isHovered) => ({
  color: TYPOGRAPHY_GRAY,
  position: 'relative',
  top: checkTernaryCondition(firstIcon, 12, -10),
  cursor: 'pointer',
  height: '1rem',
  width: '1rem',
  marginTop: checkTernaryCondition(parentFormatError, '0rem', '1rem'),
  visibility: checkTernaryCondition(isHovered, 'visible', 'hidden'),
});

export const pickerWithDeleteMargin = (firstItem) =>
  checkTernaryCondition(firstItem, null, '-10px 0 0 0');
