import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';

export const FormSection = styled.div`
  height: fit-content;
  padding: 1.5rem;
  box-sizing: border-box;
  overflow: auto;
  max-height: 39rem;
  background-color: ${WHITE};
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;

export const MainWrapper = styled.div`
  width: 552px;
  margin-bottom: 1rem;
`;

export const NameSectionWrapper = styled.div`
  display: flex;
  grid-column-gap: 1rem;
  width: 100%;
`;

export const radioBtnPadding = '0rem 0.5rem 0rem 0.563rem';

export const AddFarmWrapper = {
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  backgroundColor: WHITE,
  padding: '6px 8px',
  color: NEW_DARK_GREEN,
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '5.25px 7.25px',
  },
};

export const FarmWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  height: 16px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1px;
`;

export const FarmTitleWrapper = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  gap: 1rem;
`;

export const FarmName = styled.div`
  font-size: 0.875rem;
  line-height: 19.6px;
  color: ${DARK_CHARCOAL};
`;

export const FormFieldsWrapper = styled.div`
  padding-left: 8px;
  margin-bottom: 1rem;
`;

export const fsaIndividualNameWrapperStyle = { width: '49.3%' };
export const deleteIconSx = (disabled = false) => ({
  width: '1rem',
  height: '1rem',
  color: COOL_GRAY,
  pointerEvents: disabled && 'none',
});
