import {
  WHITE,
  NEW_DARK_GREEN,
  TABS_HIGHLIGHT_BORDER,
  NEW_HOVER_GREEN_BACKGROUND,
} from 'theme/GlobalColors';

export const dayIsBetweenStyle = (newColorTheme) => {
  return {
    borderRadius: '2px',
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: '#333333',
    '&:hover, &:focus': {
      backgroundColor: NEW_DARK_GREEN,
      color: WHITE,
    },
  };
};
export const dayStyle = (newColorTheme) => {
  return {
    borderRadius: '2px',
    backgroundColor: NEW_DARK_GREEN,
    '&.Mui-selected:hover, &.Mui-selected, &.MuiPickersDay-root:focus': {
      backgroundColor: NEW_DARK_GREEN,
    },
  };
};

export const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#004f59',
    },
  },
};

export const staticDatePickerStyles = {
  '&& .PrivatePickersYear-yearButton.Mui-selected': {
    backgroundColor: NEW_DARK_GREEN,
  },
  '&& .PrivatePickersYear-yearButton.Mui-selected:hover': {
    backgroundColor: TABS_HIGHLIGHT_BORDER,
  },
};
