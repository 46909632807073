import styled from 'styled-components';

export const FieldsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;
export const InputSx = {
  '& .MuiInput-input': {
    padding: '0.4063rem 0.3125rem 0.3438rem 0.5rem',
  },
};
