import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { SecurityProvider } from 'security';
import { SettingsContext } from './contextAPI/settings';
import axios from 'axios';
import { SETTINGS } from 'urls';
import { loadJS } from 'utils/helper';
import { UserAuthRolesProvider } from 'contextAPI/userAuthRolesContext';
import { MapProvider } from 'contextAPI/mapContext';
import { LifeCycleProvider } from 'contextAPI/statusLifecycleContext';
import { ConfigurationProvider } from 'contextAPI/configurationContext';

function bootStrapApplication() {
  axios
    .get(SETTINGS)
    .then((res) => res.data)
    .then((settings) => initilizeApp(settings));
}

function initilizeApp(settings) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  //loading cookie script
  loadJS(
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    '2c0a40e5-b387-4755-b268-44a200578a02-test',
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <SettingsContext.Provider value={settings}>
          <ConfigurationProvider>
            <SecurityProvider>
              <UserAuthRolesProvider>
                <MapProvider>
                  <LifeCycleProvider>
                    <App />
                  </LifeCycleProvider>
                </MapProvider>
              </UserAuthRolesProvider>
            </SecurityProvider>
          </ConfigurationProvider>
        </SettingsContext.Provider>
      </BrowserRouter>
    </React.StrictMode>,
  );
}

bootStrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
