import { DARK_CHARCOAL, DARK_GREEN, WHITE } from 'theme/GlobalColors';

export const DialogTitleStyle = {
  fontSize: '20px',
  padding: '0rem',
  marginBottom: '1rem',
  color: DARK_CHARCOAL,
  fontWeight: 400,
};
export const IconButtonStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
  zIndex: 950,
};

export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '60%',
      maxWidth: '35%',
      padding: '24px',
    },
  },
};
export const DialogActionStyle = {
  justifyContent: 'flex-end',
  padding: '0rem',
};

export const DialogContentStyle = {
  marginBottom: '1rem',
  padding: '0rem',
  fontSize: '0.875rem',
  color: DARK_CHARCOAL,
};

export const cancelBtnStyle = {
  textTransform: 'none',
  backgroundColor: WHITE,
  color: DARK_GREEN,
};

export const firstSubmitBtnStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
  backgroundColor: WHITE,
  border: `1px solid ${DARK_GREEN}`,
  padding: '0.344rem 1rem',
};

export const secondSubmitBtnStyle = {
  textTransform: 'none',
  color: WHITE,
  backgroundColor: DARK_GREEN,
  padding: '0.375rem 1rem',
  '&:hover': {
    color: WHITE,
    backgroundColor: DARK_GREEN,
  },
};
