export const MODAL_CONTENT = {
  general: 'Transaction Details',
  notes: 'Notes',
  empty: '_',
  paper: 'paper',
  aria_labelledby: 'scroll-dialog-title',
  aria_describedby: 'scroll-dialog-description',
  empty_array: [],
  insert: 'INSERT',
  delete: 'DELETE',
  projects_label: 'Projects',
};
export const generateTitle = (projectEvent) => {
  // eslint-disable-next-line no-prototype-builtins
  return projectEvent.hasOwnProperty('costStructure') &&
    projectEvent.costStructure !== null
    ? `${projectEvent.projectName} • ${projectEvent.costStructure} • ${projectEvent.vintageStart} -  ${projectEvent.vintageEnd}`
    : `${projectEvent.projectName} • ${projectEvent.vintageStart} -  ${projectEvent.vintageEnd}`;
};
export const generateSubTitle = (transactionType) => {
  return transactionType.toLowerCase().includes('purchase')
    ? 'Project • Variable Cost Structure • Vintage'
    : 'Project • Vintage';
};
export const validFieldComparator = (oldValue, newValue) => {
  return oldValue === newValue || (!oldValue.trim() && !newValue.trim())
    ? false
    : true;
};
