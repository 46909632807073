import styled from 'styled-components';
import {
  NEW_DARK_GREEN,
  TEAL_GREEN_HOVER,
  NEW_HOVER_GREEN_BACKGROUND,
  TYPOGRAPHY_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const StatusBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(prop) =>
    prop.disableOutline ? '' : `1px solid ${NEW_DARK_GREEN}`};
  border-radius: 4px;
  border-color: ${(prop) => (prop.disabled ? TYPOGRAPHY_GRAY : NEW_DARK_GREEN)};
  background-color: ${(prop) => (prop.disableOutline ? '' : WHITE)};
  grid-column-gap: 0.5rem;
  padding: 0.5rem 2.25rem;
  cursor: ${(prop) =>
    prop.disabled || prop.disableOutline ? 'none' : 'pointer'};
  pointer-events: ${(prop) =>
    prop.disabled || prop.disableOutline ? 'none' : ''};
  &:hover {
    background-color: ${(prop) =>
      prop.disableOutline ? '' : NEW_HOVER_GREEN_BACKGROUND};
  }
  &:focus {
    color: ${WHITE};
    background-color: ${TEAL_GREEN_HOVER};
    border: 2px solid ${NEW_DARK_GREEN};
  }
`;

export const StatusMainWrapper = styled.div`
  grid-column-gap: 1rem;
  width: ${({ isBaselineMenu }) =>
    checkTernaryCondition(isBaselineMenu, '19.375rem', '12.5rem')};
`;

export const CompletedLabelWrapper = styled.div`
  align-self: center;
  color: var(--grays-cool-gray-10, #63666a);
  /* Body Copy - standard */
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 0.5rem;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${({ disabled }) =>
    checkTernaryCondition(disabled, TYPOGRAPHY_GRAY, NEW_DARK_GREEN)};
  flex: 1 0 0;
`;
