import * as React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { ReactComponent as ChipCancelIcon } from '../../../assets/icons/chipcloseicon.svg';
import {
  CheckboxSx,
  FormFields,
  FormLabels,
  InputSx,
  InstructionWrapper,
  LabelToolTipWrapper,
  OptionalLabel,
  OutlinedInputSx,
} from '../FormStyles';
import { CustomPaper, chipStyle } from './AutoComplete.style';
import { FORM_CONTENT } from '../FormComponents.content';
import { checkTernaryCondition } from 'utils/helper';
import { useState, useEffect } from 'react';
import InfoTooltip from '../InfoTooltip/InfoTooltip.react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteForm = ({
  hasCheckbox,
  label,
  instructionText,
  primaryError,
  secondaryError,
  placeholder,
  valueSetter,
  isOptional,
  helperText,
  list,
  value,
  formValue,
  isOutlined = false,
  hasLimit,
  limitValue,
  customSx,
  marginTop,
  labelGap,
  marginBottom,
  hasNewStyles = false,
  isDisabled = false,
  tooltipProps = null,
  optionalLabelMarginLeft = '0rem',
  hasNoBottomMargin = false,
  onInputChangeHandler = () => {},
  chipOptionFormatter = (option) => option,
  customOptionFormatter = (props, option) => option,
  onBlurHandler = () => {},
  isMultiple = true,
  disableCloseOnSelect = true,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [disableClose, setDisableClose] = useState(disableCloseOnSelect);

  const handleOptionSelect = (newValue) => {
    if (hasLimit && newValue.length <= limitValue) {
      if (newValue.length === limitValue - 1) {
        setDisableClose(false);
      } else {
        setDisableClose(true);
      }
      setSelectedOptions(newValue);
      valueSetter(newValue);
    } else {
      valueSetter(newValue);
    }
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <FormFields hasNoBottomMargin={hasNoBottomMargin}>
      <FormLabels
        iserror={primaryError || secondaryError}
        marginTop={marginTop}
        marginBottom={marginBottom}
        gap={labelGap}>
        <LabelToolTipWrapper>
          {label}
          {isOptional && (
            <OptionalLabel marginLeft={optionalLabelMarginLeft}>
              {FORM_CONTENT.optional}
            </OptionalLabel>
          )}
          {tooltipProps && (
            <InfoTooltip
              title={tooltipProps.title}
              content={tooltipProps.content}
            />
          )}
        </LabelToolTipWrapper>
        <InstructionWrapper>{instructionText}</InstructionWrapper>
      </FormLabels>
      <Autocomplete
        disabled={isDisabled}
        PaperComponent={hasNewStyles && CustomPaper}
        multiple={isMultiple}
        id="checkboxes-tags-demo"
        options={list}
        disableCloseOnSelect={disableClose}
        onChange={(event, newValue) => {
          handleOptionSelect(newValue);
        }}
        onBlur={onBlurHandler}
        getOptionLabel={(option) => option}
        onInputChange={onInputChangeHandler}
        value={checkTernaryCondition(hasLimit, selectedOptions, value)}
        renderTags={checkTernaryCondition(
          isMultiple,
          (tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const keyVal = index;
              return (
                <Chip
                  data-testid="autocomplete-chip"
                  key={keyVal}
                  deleteIcon={<ChipCancelIcon />}
                  label={chipOptionFormatter(option)}
                  {...getTagProps({ index })}
                  sx={chipStyle}
                />
              );
            }),
          null,
        )}
        sx={{
          ...(primaryError ? {} : { ...InputSx, ...OutlinedInputSx }),
        }}
        renderOption={(props, option, { selected }, state) => (
          <li
            {...props}
            aria-disabled={
              hasLimit && !selected && selectedOptions.length >= limitValue
            }>
            {hasCheckbox && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{
                  marginRight: 8,
                }}
                checked={selected}
                disabled={
                  hasLimit && !selected && selectedOptions.length >= limitValue
                }
                sx={CheckboxSx}
              />
            )}
            {customOptionFormatter(props, option)}
          </li>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="autocomplete-input-field"
            placeholder={formValue?.length > 0 ? '' : placeholder}
            variant={checkTernaryCondition(
              isOutlined,
              FORM_CONTENT.outlined,
              FORM_CONTENT.standard_variant,
            )}
            error={primaryError}
            helperText={primaryError && helperText}
            sx={customSx}
          />
        )}
      />
    </FormFields>
  );
};

AutoCompleteForm.propTypes = {
  hasCheckbox: PropTypes.bool,
  label: PropTypes.string,
  instructionText: PropTypes.string,
  primaryError: PropTypes.string,
  secondaryError: PropTypes.string,
  placeholder: PropTypes.string,
  valueSetter: PropTypes.func,
  isOptional: PropTypes.bool,
  helperText: PropTypes.string,
  list: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOutlined: PropTypes.bool,
  hasLimit: PropTypes.bool,
  limitValue: PropTypes.number,
  customSx: PropTypes.object,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasNewStyles: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tooltipProps: PropTypes.object,
  optionalLabelMarginLeft: PropTypes.string,
  hasNoBottomMargin: PropTypes.bool,
  onInputChangeHandler: PropTypes.func,
  isMultiple: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
};

export default AutoCompleteForm;
