import { ReactComponent as CompletedIcon } from '../../../../assets/icons/updatedCompletedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../../../assets/icons/inProgressStatus.svg';
import { ReactComponent as CircleIcon } from '../../../../assets/icons/circle.svg';
import { ReactComponent as UpdatedCircleIcon } from '../../../../assets/icons/updatedCircle.svg';
import { checkTernaryCondition } from 'utils/helper';

export const IconHandler = (status, disabled) => {
  switch (status) {
    case 'In Progress':
      return <InProgressIcon />;
    case 'Completed':
      return <CompletedIcon />;
    default:
      return checkTernaryCondition(
        disabled,
        <CircleIcon />,
        <UpdatedCircleIcon />,
      );
  }
};
