import PropTypes from 'prop-types';
import { PROJECT_DETAIL_CONTENTS } from '../ProjectDetail.content';
import {
  Field,
  FieldTitle,
  FieldValue,
  STYLE_CONST,
  SubDetails,
} from '../ProjectDetails.style';

const SubDetailsComp = ({ fieldTitleArray, start, end }) => {
  return (
    <SubDetails>
      {fieldTitleArray.slice(start, end).map((item) => (
        <Field key={item.value}>
          <FieldTitle weight={STYLE_CONST.weight_xl}>{item.title}</FieldTitle>
          <FieldValue>{item.value || PROJECT_DETAIL_CONTENTS.empty}</FieldValue>
        </Field>
      ))}
    </SubDetails>
  );
};

SubDetailsComp.propTypes = {
  fieldTitleArray: PropTypes.array,
  start: PropTypes.number,
  end: PropTypes.number,
};
export default SubDetailsComp;
