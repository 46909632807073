import DialogBox from 'components/DialogBox/DialogBox.react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COOL_GRAY } from 'theme/GlobalColors';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { dropdownStyleProps } from 'layout/header/EnrollmentHelpButton/EnrollmentHelpModal/EnrollmentHelpModal.style';
import { listItemFontSize } from 'theme/GlobalStyles';
import styled from 'styled-components';
import axios from 'axios';
import {
  FETCH_PARTICIPANT_DETAILS,
  FETCH_POSSIBLE_ACCOUNT_STATUS,
  UPDATE_ACCOUNT_STATUS,
} from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import PropTypes from 'prop-types';
import { loaderContext } from 'contextAPI/loaderContext';

export const AccountContext = createContext();

export const useAccountContext = () => useContext(AccountContext);

export const UpdateAccount = ({
  isUpdateAccountModalOpen,
  setUpdateAccountModalOpen,
  refreshHandler,
}) => {
  const { setIsLoading } = useContext(loaderContext);
  const [status, setStatus] = useState([]);
  const [participant, setParticipant] = useState();
  const [currentStatus, setCurrentStatus] = useState('');

  function getAccountStatus(statusList, currentStatus = 'NOT_STARTED') {
    if (statusList.length === 0) {
      return 'NOT_STARTED';
    }
    const statusObject = statusList?.find(
      (state) => state?.participantAccountStatus === currentStatus,
    );
    const nextIndex = statusObject?.nextStateOrdinal;
    return statusList[nextIndex]?.value;
  }

  const fetchStatus = async () => {
    setIsLoading(true);
    try {
      const {
        params: {
          row: { participantId },
        },
      } = isUpdateAccountModalOpen;
      const [participantResponse, accountStatusResponse] = await Promise.all([
        axios.get(
          `${FETCH_PARTICIPANT_DETAILS}?participantId=${participantId}`,
        ),
        axios.get(`${FETCH_POSSIBLE_ACCOUNT_STATUS}`),
      ]);
      setParticipant(participantResponse.data);
      setCurrentStatus(participantResponse.data.accountStatus);
      const formattedData = accountStatusResponse.data.map((state) => ({
        ...state,
        label: state.displayValue,
        value: state.participantAccountStatus,
      }));
      setStatus(formattedData);
    } catch (error) {
      // Handle error here
    } finally {
      setIsLoading(false);
    }
  };

  const updateAccountStatusSelection = async (e) => {
    setSelectedStatus(e.target.value);
  };

  const saveAccountStatus = async () => {
    setIsLoading(true);
    try {
      const {
        params: {
          row: { participantId },
        },
      } = isUpdateAccountModalOpen;
      await axios.put(
        `${UPDATE_ACCOUNT_STATUS}/${selectedStatus}/${participantId}`,
      );
      closeUpdateAccountModal();
      displayToast('success', `Account status updated successfully`);
      refreshHandler();
    } catch (error) {
      displayToast('error', `Failed to updated account status`);
    } finally {
      setIsLoading(false);
    }
  };

  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    setSelectedStatus(getAccountStatus(status, currentStatus));
  }, [status]);

  const suggestedNextStatus = getAccountStatus(status, currentStatus);

  const closeUpdateAccountModal = () => {
    setUpdateAccountModalOpen((pre) => ({ ...pre, open: false }));
  };

  const isModalOpen = isUpdateAccountModalOpen.open;

  const contextValue = useMemo(
    () => ({
      closeUpdateAccountModal,
      currentStatus,
      setSelectedStatus,
      suggestedNextStatus,
      isModalOpen,
      participant,
      fetchStatus,
      status,
      saveAccountStatus,
      updateAccountStatusSelection,
    }),
    [
      closeUpdateAccountModal,
      currentStatus,
      setSelectedStatus,
      suggestedNextStatus,
      isModalOpen,
      participant,
      fetchStatus,
      status,
      saveAccountStatus,
      updateAccountStatusSelection,
    ],
  );

  if (!isUpdateAccountModalOpen.open) {
    return null;
  }

  return (
    <AccountContext.Provider value={contextValue}>
      <UpdateAccountModal />
    </AccountContext.Provider>
  );
};

const UpdateAccountModal = () => {
  const {
    isModalOpen,
    closeUpdateAccountModal,
    participant,
    fetchStatus,
    saveAccountStatus,
  } = useAccountContext();

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <div>
      <DialogBox
        customSx={{ width: 600, margin: 'auto', height: 290 }}
        dialogActions
        isOpen={isModalOpen}
        onConfirm={saveAccountStatus}
        onCancel={closeUpdateAccountModal}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Save"
        title={`Update account status for ${participant?.participantFirstName} ${participant?.participantLastName}`}>
        <AccountStatus />
      </DialogBox>
    </div>
  );
};

export const StatusWrapper = styled.div`
  font-size: 14px;
`;

const AccountStatus = () => {
  const {
    status,
    suggestedNextStatus,
    updateAccountStatusSelection,
    currentStatus,
  } = useAccountContext();

  return (
    <div>
      <StatusWrapper>
        <b>Current status: </b>
        {status?.find((state) => state.value === currentStatus)?.label || ''}
      </StatusWrapper>
      <DropDown
        value={suggestedNextStatus}
        onUpdate={updateAccountStatusSelection}
        label={'Update Status'}
        customPlaceholderColor={COOL_GRAY}
        dropdownlist={status}
        showLabelAsValue={true}
        listItemFontSize={listItemFontSize}
        {...dropdownStyleProps}
      />
    </div>
  );
};

export default UpdateAccountModal;

UpdateAccount.propTypes = {
  isUpdateAccountModalOpen: PropTypes.shape({
    params: PropTypes.shape({
      row: PropTypes.shape({
        participantId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

UpdateAccountModal.propTypes = {
  isUpdateAccountModalOpen: PropTypes.shape({
    open: PropTypes.bool.isRequired,
  }).isRequired,
};
