import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import {
  ErrorWrapper,
  ErroWrapper,
  FormFields,
  FormLabels,
  MaxCharErrorWrapper,
  OptionalLabel,
  TextArea,
} from '../FormStyles';

const TextAreaField = ({
  label,
  OptionalLabelText,
  viewMode,
  placeholder,
  name,
  onUpdate,
  value,
  hasMaxCharError,
  displayCountMessage,
  errorMessage,
  primaryError,
  primaryErrorMessage,
  flexDirection,
  gap,
  marginBottom,
  marginTop,
}) => {
  return (
    <>
      <FormFields>
        <FormLabels
          gap={gap}
          flexDirection={flexDirection}
          iserror={primaryError}
          marginBottom={marginBottom}
          marginTop={marginTop}>
          {label}
          {!viewMode && <OptionalLabel>{OptionalLabelText}</OptionalLabel>}
        </FormLabels>
        {(viewMode &&
          checkTernaryCondition(
            value === '',
            '',
            <TextArea
              disabled={viewMode}
              placeholder={placeholder}
              name={name}
              onChange={onUpdate}
              value={value}></TextArea>,
          )) ||
          (!viewMode && (
            <TextArea
              placeholder={placeholder}
              name={name}
              onChange={onUpdate}
              value={value}></TextArea>
          ))}
        {hasMaxCharError && (
          <MaxCharErrorWrapper>
            <ErroWrapper>{errorMessage}</ErroWrapper>
            <ErroWrapper>{displayCountMessage}</ErroWrapper>
          </MaxCharErrorWrapper>
        )}
        {primaryError && <ErrorWrapper>{primaryErrorMessage}</ErrorWrapper>}
      </FormFields>
    </>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string,
  OptionalLabelText: PropTypes.string,
  viewMode: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  value: PropTypes.string,
  hasMaxCharError: PropTypes.bool,
  displayCountMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  primaryError: PropTypes.bool,
  primaryErrorMessage: PropTypes.string,
  flexDirection: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextAreaField;
