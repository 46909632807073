import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const SnacBarWrapper = styled.div`
  width: 37.75rem;
  min-height: 2.688rem;
  height: fit-content;
  background-color: ${(props) => props.styleValues?.backgroundColor};
  display: flex;
  border: 2px solid ${(props) => props.styleValues?.border};
  flex-direction: row;
`;

export const SnackBarIcon = styled.div`
  margin: 0.875rem 0.563rem 0.875rem 1.063rem;
`;

export const SnackBarMessage = styled.div`
  margin: 0.75rem 0.5rem 0.75rem 0rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #333333;
  flex: 2;
`;

export const CloseSnackBar = styled.div`
  margin: 0.875rem 1rem 0.875rem 0rem;
  stroke: ${(props) => props.styleValues?.colorStroke};
`;

export const IconStyle = {
  color: DARK_CHARCOAL,
  height: '1rem',
  width: '1rem',
};
