import styled from 'styled-components';
import {
  DARK_GREEN,
  HOVER_LIGHT_BLUE,
  NEW_DARK_GREEN,
} from 'theme/GlobalColors';

export const BasicWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ content }) => content};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
  width: ${({ width }) => width};
  align-content: ${({ alignContent }) => alignContent};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  border-right: ${({ borderRight }) => borderRight};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
`;
export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const FilterBox = styled.div`
  padding: 0.4rem 0.5rem;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.8rem;
  &:hover {
    background-color: ${HOVER_LIGHT_BLUE};
  }
`;
export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 1rem;
`;
export const inputStyle = { height: 32, width: '10rem' };
export const cancelButtonStyle = {
  textTransform: 'none',
  color: NEW_DARK_GREEN,
};
export const applyButtonStyle = {
  textTransform: 'none',
  background: NEW_DARK_GREEN,
  '&:hover': {
    color: 'white',
    backgroundColor: DARK_GREEN,
  },
};
export const newThemeCancelButtonStyle = {
  textTransform: 'none',
  color: DARK_GREEN,
};
export const newThemeApplyButtonStyle = {
  textTransform: 'none',
  background: DARK_GREEN,
  '&:hover': {
    color: 'white',
    backgroundColor: DARK_GREEN,
  },
};
