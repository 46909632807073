import {
  AcceptBtn,
  AcceptanceBtnWrapper,
  CustomInfoWindow,
  DeleteBtn,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { INFO_WINDOW_CONTENT } from './InfoWindow.content';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import {
  ErrorWrapper,
  FieldNameInput,
  ContentWrapper,
} from './InfoWindow.style';
import { checkTernaryCondition, getNextName } from 'utils/helper';
import { RED } from 'theme/GlobalColors';
import { GLOBAL_STRING_CONSTANTS } from 'utils/config';

const InfoWindowContent = ({
  onAccept,
  onDelete,
  fieldArea,
  totalFarmArea,
}) => {
  const [name, setName] = useState('');
  const { farmInfo, currentFarmId } = useContext(participantInfoContext);
  const fieldAreaExceedingFarmArea = fieldArea > totalFarmArea;

  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (field.value === name.trim()) count++;
      }
    }

    return count === 0;
  }, [name]);

  useEffect(() => {
    const currentFarmIndex = farmInfo.findIndex(
      (farm) => farm.id === currentFarmId,
    );
    if (currentFarmIndex >= 0) {
      const existingFieldNames =
        farmInfo?.flatMap((farm) => farm.fields?.map((field) => field.value)) ??
        [];
      const newFieldName = getNextName(
        existingFieldNames,
        GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
      );
      setName(newFieldName);
    } else {
      setName(GLOBAL_STRING_CONSTANTS.default_field_name_prefix);
    }
  }, [farmInfo]);

  return (
    <CustomInfoWindow>
      <div>
        {fieldAreaExceedingFarmArea ? (
          <ContentWrapper>{name}</ContentWrapper>
        ) : (
          <FieldNameInput
            value={name}
            onChange={(event) => setName(event.target.value)}
            isError={!isFieldNameUnique}
            data-testid="name-input"
          />
        )}
        {!isFieldNameUnique && (
          <ErrorWrapper>
            {INFO_WINDOW_CONTENT.fieldNameExistsErrorMsg}
          </ErrorWrapper>
        )}
        <ContentWrapper
          color={
            fieldAreaExceedingFarmArea && RED
          }>{`${fieldArea}${INFO_WINDOW_CONTENT.fieldAreaUnit}`}</ContentWrapper>

        {fieldAreaExceedingFarmArea && (
          <ContentWrapper color={RED}>
            {INFO_WINDOW_CONTENT.fieldAreaExceedingFarmAreaErrorMsg}
          </ContentWrapper>
        )}

        <AcceptanceBtnWrapper>
          {!fieldAreaExceedingFarmArea && (
            <AcceptBtn
              onClick={checkTernaryCondition(
                isFieldNameUnique,
                () => onAccept(name),
                () => {},
              )}
              disabled={!isFieldNameUnique}>
              {INFO_WINDOW_CONTENT.acceptBtnText}
            </AcceptBtn>
          )}
          <DeleteBtn
            onClick={onDelete}
            color={fieldAreaExceedingFarmArea && RED}>
            {INFO_WINDOW_CONTENT.deleteBtnText}
          </DeleteBtn>
        </AcceptanceBtnWrapper>
      </div>
    </CustomInfoWindow>
  );
};

InfoWindowContent.propTypes = {
  onAccept: PropTypes.func,
  onDelete: PropTypes.func,
  fieldArea: PropTypes.number,
  totalFarmArea: PropTypes.number,
};

InfoWindowContent.defaultProps = {
  onAccept: () => {},
  onDelete: () => {},
  fieldArea: 0,
  totalFarmArea: 0,
};

export default InfoWindowContent;
