import { styled } from '@mui/material/styles';
import { Container, Link } from '@mui/material';
import { checkTernaryCondition } from 'utils/helper';

// eslint-disable-next-line no-unused-vars
export const useStyles = (theme) => ({
  MainContainer: styled(Container)(
    ({
      isParticipantLanding,
      isParticipantMenu,
      margin,
      isInActivityPage,
    }) => ({
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: checkTernaryCondition(isInActivityPage, '0px', '24.5px'),
      margin: margin,
      height: '50px',
      fontSize: '14px',
      fontFamily: 'Open Sans, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      borderTop:
        isParticipantLanding || isParticipantMenu ? '' : ' 1px #bdbdbd solid',
    }),
  ),
  // EBAC - Enrolment Baseline Activity Contributor Landing ages
  FooterDiv: styled('div')(({ isEBAC, isParticipantLanding }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    'z-index': 1,
    width: checkTernaryCondition(isEBAC, '55vw', '80.5vw'),
    color: isParticipantLanding ? 'white' : '#3a4245',
  })),
  IconSpan: styled('span')(({ isParticipantLanding }) => ({
    color: isParticipantLanding ? 'white' : '#bdbdbd',
    fontSize: '20px',
    fontWeight: '100',
  })),
  FooterLink: styled(Link)(({ isParticipantLanding }) => ({
    textDecoration: 'none',
    cursor: 'pointer',
    color: isParticipantLanding ? 'white' : '#3a4245',
  })),
});

export const landingPageFooterStyles = {
  position: 'absolute',
  bottom: 0,
  right: 0,
};
