import { Text, View } from '@react-pdf/renderer';
import { styles } from './Paragraph.style';
import PropTypes from 'prop-types';

const Paragraph = ({ subheading, textValue }) => {
  const getMarginBottom = (text) => {
    const characterCount = text?.length;
    const marginBottomIncrement = 0.5;
    const charactersPerIncrement = 85;
    const incrementCount = Math.floor(characterCount / charactersPerIncrement);
    return incrementCount * marginBottomIncrement;
  };

  const marginBottom = getMarginBottom(textValue);

  return (
    <View style={styles?.row}>
      {subheading && <Text style={styles?.subheading}>{subheading}</Text>}
      <Text style={[styles?.para, { marginBottom: `${marginBottom + 15}px` }]}>
        {textValue !== null ? textValue : ''}
      </Text>
    </View>
  );
};

Paragraph.propTypes = {
  subheading: PropTypes.string,
  textValue: PropTypes.string,
};

export default Paragraph;
