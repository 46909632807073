import styled from 'styled-components';

const BLOCK_BACKGROUND_COLOR = 'white';
const MAIN_FONT_COLOR = '#000000';
const PAGE_BACKGROUND_COLOR = '#F1f1f1';

export const MainWrapper = styled.div`
  background-color: ${PAGE_BACKGROUND_COLOR};
  display: flex;
  justify-content: center;
  font-size: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
`;
export const SectionWrapper = styled.div`
  width: 38rem;
  height: fit-content;
  margin-top: 3.5rem;
  background-color: ${BLOCK_BACKGROUND_COLOR};
  padding: 2.5rem;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
`;
export const MainHeadingDiv = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.188rem;
  text-align: center;
  color: ${MAIN_FONT_COLOR};
  margin-bottom: 1rem;
`;
export const PageHeadingDiv = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-decoration-line: underline;
`;
export const ContentDiv = styled.div`
  margin-bottom: 1rem;
`;
