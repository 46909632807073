export const CATALOG_CONTENT = {
  country: 'COUNTRY',
  project_name: 'PROJECT_NAME',
  project_developer: 'PROJECT_DEVELOPER',
  project_type: 'PROJECT_TYPE',
  internal_review_status: 'INTERNAL_REVIEW_STATUS',
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  success: 'SUCCESS',
  success_message: 'has been deleted successfully.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  denied_message_single_transaction:
    'transaction. You must remove this project from the transaction before it can be deleted.',
  denied_message_multiple_transaction:
    'is linked in multiple transactions. You must remove this project from each transaction below before it can be deleted.',

  noDataContent:
    ' Looks like you haven’t added any projects on your portfolio.',
  addNewProjectLabel: 'Add a new project to get started!',
  addButton: 'Add Project',
  cool_gray: '#63666A',
  available_credit_suply: 'Available Credit Supply',
  MTCO2_label: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
};

export const SortColValues = {
  projectName: 'Name',
  projectDeveloper: 'Developer',
  projectType: 'Type',
  country: 'Country',
  internalReviewStatus: 'Status',
  addedOn: 'Date',
};
