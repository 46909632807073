import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import NewClearCarbonLogo from './../../../assets/NewClearCarbonLogo.png';
import { getReportDateIssued } from 'utils/helper';
import { styles } from './FrontPageFooter.style';

const FrontPageFooter = () => (
  <View style={styles?.container} fixed>
    <View style={styles?.brandName}>
      <Text style={styles?.pagenumber}>Powered by</Text>
      <Image src={NewClearCarbonLogo} style={styles?.image} />
    </View>
    <Text style={styles?.pagenumber}>{getReportDateIssued(new Date())}</Text>
  </View>
);

export default FrontPageFooter;
