import React from 'react';
import AddOriginationProject from 'pages/AddOriginationProject/AddOriginationProject.react';
import { useParams } from 'react-router';
import { useOriginationProject } from 'hooks/useOriginationProjectDetails';
import { Backdrop, CircularProgress } from '@mui/material';

const EditOriginationProject = () => {
  const { id } = useParams();
  const {
    /* fetchOriginationProjectDetails, */
    originationProjectDetails,
    fetchDataLoading,
  } = useOriginationProject();

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetchDataLoading}>
        <CircularProgress color="success" />
      </Backdrop>
      <AddOriginationProject
        id={id}
        isEdit={true}
        fetchOriginationProjectData={originationProjectDetails}
      />
    </>
  );
};

export default EditOriginationProject;
