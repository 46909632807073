import { Box, Tab, Tabs } from '@mui/material';
import {
  PAGE_CONTENT,
  aggregateObjects,
  createFieldActivityMapping,
  findIfAllFieldsAndActivitiesMapped,
  formatPoiData,
  formatSubmitData,
  geoReferenceImageAcceptedFilesInfo,
  getActivityCategory,
  getFieldAcresText,
  possibleFileTypes,
  projectActivitiesLabels,
  recieptsInvoiceAndMgmtPlanAcceptedFilesInfo,
  tabProps,
} from './ProofOfImplementationMainComp.content';
import {
  ContentBox,
  ContentWrapper,
  HeaderWrapper,
  InfoBox,
  MainWrapper,
  StyledSpan,
  TabberBoxCustomStyle,
  TextWrapper,
} from './ProofOfImplementationMainComp.style';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import { TabStylesSx } from '../../ActivityReporting.style';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useEffect, useMemo, useState, useContext } from 'react';
import UserNavigationButtons from 'pages/ParticipantDataCollection/components/UserNavigationButtons/UserNavigationButtons.react';
import FileUploadCompPOI from '../FileUploadCompPOI/FileUploadCompPOI.react';
import {
  checkTernaryCondition,
  convertCommaseperatedStringToArray,
  getUniqueObjectsByKey,
  sortWithAKeyInSpecificOrder,
} from 'utils/helper';
import { getSectionHeading } from '../ImplementedActivities/ImplementedActivities.content';
import {
  DELETE_POI_FILE,
  FETCH_POI_ACTIVITIES,
  FETCH_POI_TAB_DATA,
  POI_INIT_FILE_UPLOAD,
  SUBMIT_POI_TAB_DATA,
} from 'urls';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import Loader from 'components/Loader/Loader.react';
import PropTypes from 'prop-types';
import { pathName } from 'Routes/routes.const';
import { ReactComponent as PoiAlertIcon } from 'assets/icons/poiAlertIcon.svg';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';

const ProofOfImplementationMainComp = ({
  farmId,
  setActivityTabData,
  fetchFieldDetails,
  poiStatusList,
  updateFarmLevelStatus,
  currentTab = 0,
  setCurrentTab,
  projectActivities = [],
  setProjectActivities = () => {},
  currentTabData = [],
  setCurrentTabData = () => {},
  currentTabStatus = 'Not Started',
  setCurrentTabStatus = () => {},
  updateReportingStatus = () => {},
  gotoNextInprogressPage = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const { activityStatusList } = useContext(baselineDropdownContext);
  const [loaderCount, setLoaderCount] = useState(0);
  const [showWarning, setShowWarning] = useState(true);
  const [annualImplementationCost, setAnnualImplementationCost] = useState('');
  const [fieldIdWithActivityMapping, setFieldIdWithActivityMapping] =
    useState(null);
  const [isAllFieldsAndActivitiesMapped, setIsAllFieldsAndActivitiesMapped] =
    useState(false);
  const { TabberBox } = useStyles();
  const existingfileIds = useMemo(() => {
    return currentTabData?.map((data) => data.fileId);
  }, [currentTabData]);

  const [fieldDetails, setFieldDetails] = useState([]);

  const aggregateAndMap = (fieldDetails, currentTab) => {
    const aggregatedData = aggregateObjects(fieldDetails);
    const projectActivitiesData = aggregatedData.map((item) => {
      const fieldsOfActivity = getUniqueObjectsByKey(
        item.fieldsOfActivity,
        'value',
      );
      return {
        activityId: item.projectActivityId,
        title: getSectionHeading(item.projectActivityCategory),
        implementedOn: fieldsOfActivity.length,
        implementedAcres: Number(item.implementedAcres).toFixed(2),
        fieldsOfActivity: fieldsOfActivity,
        practiceTypeList: item.practiceTypeList,
      };
    });

    const tabSortOrder = projectActivitiesLabels.map(
      (activity) => activity.title,
    );
    setProjectActivities(
      sortWithAKeyInSpecificOrder(projectActivitiesData, 'title', tabSortOrder),
    );

    const currentTabTitle = projectActivitiesData[currentTab]?.title;
    const fieldWithActivityIdsMapping = createFieldActivityMapping(
      fieldDetails.filter(
        (item) =>
          getSectionHeading(item.projectActivityCategory) === currentTabTitle,
      ),
    );
    setFieldIdWithActivityMapping(fieldWithActivityIdsMapping);
  };

  const navigateToNextTab = (fieldData) => {
    const tabLength = projectActivities.length;
    if (currentTab < tabLength - 1) {
      handleTabChange(null, currentTab + 1);
    } else if (currentTab === tabLength - 1) {
      handleTabChange(null, currentTab);
      gotoNextInprogressPage(fieldData);
    }

    if (tabLength === 0) {
      gotoNextInprogressPage(fieldData);
    }
  };

  const gotoMenuPage = () =>
    navigate(
      {
        pathname: pathName.participant.activityMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );

  const validateTabData = (tabData) => {
    setIsAllFieldsAndActivitiesMapped(
      findIfAllFieldsAndActivitiesMapped(fieldIdWithActivityMapping, tabData),
    );
  };

  const fetchPoiActivities = () => {
    setLoaderCount((prev) => prev + 1);

    axios
      .get(FETCH_POI_ACTIVITIES, {
        params: {
          participantId: participantId,
          projectId: projectId,
          farmId: farmId,
        },
      })
      .then((resp) => {
        const formattedData = formatPoiData(resp.data);
        setFieldDetails(formattedData);
        aggregateAndMap(formattedData, currentTab);
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchActivityTabData = async (tab) => {
    const currentTabTitle = projectActivities[tab]?.title;
    const activityIds = projectActivities
      .find((item) => item.title === currentTabTitle)
      ?.practiceTypeList.map((practiceType) => practiceType.value)
      .join(',')
      .toString();
    const activityCategory = getActivityCategory(currentTabTitle);

    setLoaderCount((prev) => prev + 1);
    return axios
      .get(FETCH_POI_TAB_DATA, {
        params: {
          farmId: farmId,
          projectId: projectId,
          activityIds: checkTernaryCondition(
            activityCategory === projectActivitiesLabels[0].title ||
              activityCategory === projectActivitiesLabels[2].title,
            activityIds,
            '',
          ),
          activityCategory: activityCategory,
        },
      })
      .then((resp) => {
        const data = resp.data;

        const filesData = data?.map((file) => ({
          fileId: file.poiFileId,
          file: file.fileThumbnail,
          fields: convertCommaseperatedStringToArray(file.fieldId),
          practice: checkTernaryCondition(
            file.practiceType === '0',
            [],
            convertCommaseperatedStringToArray(file.practiceType),
          ),
          fileType: file.fileType,
          fileName: file.fileName,
          fileStatus: file.fileStatus,
        }));
        setCurrentTabData(filesData);
        const totalCost =
          data[0]?.estimatedActivityImplementationCost?.toString() ?? '';
        setAnnualImplementationCost(totalCost);
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const addFilesToTabData = (
    files,
    fileType,
    activityIds,
    hasPracticeDropdown = true,
  ) => {
    const formattedActivityIds = activityIds.join(',').toString();

    const formData = new FormData();
    for (const file of files) {
      formData.append('images', file);
    }

    setLoaderCount((prev) => prev + 1);
    axios
      .post(POI_INIT_FILE_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          projectId: projectId,
          farmId: farmId,
          fileType: fileType,
          activityIds: checkTernaryCondition(
            hasPracticeDropdown,
            '',
            formattedActivityIds,
          ),
          activityCategory: getActivityCategory(
            projectActivities[currentTab].title,
          ),
          activityStatus: currentTabStatus,
          participantId: participantId,
        },
      })
      .then((resp) => {
        const fileData = resp.data;
        const filesData = fileData
          .filter((file) => !existingfileIds.includes(file.poiFileId))
          .map((file) => ({
            fileId: file.poiFileId,
            file: file.fileThumbnail,
            fields: convertCommaseperatedStringToArray(file.fieldId),
            practice: checkTernaryCondition(
              hasPracticeDropdown,
              [],
              activityIds,
            ),
            fileType: file.fileType,
            fileName: file.fileName,
            fileStatus: file.fileStatus,
          }));
        setCurrentTabData((prev) => [...prev, ...filesData]);
        setActivityTabData((prev) => ({
          ...prev,
          farmProofOfImplementationStatus: PAGE_CONTENT.in_progress,
        }));
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const handleFileDelete = (fileId) => {
    setLoaderCount((prev) => prev + 1);
    axios
      .delete(`${DELETE_POI_FILE}/${fileId}`)
      .then(() => {
        setCurrentTabData((prevFiles) =>
          prevFiles.filter((item) => item.fileId !== fileId),
        );
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const handleTabChange = (_, selectedTab) => {
    fetchActivityTabData(selectedTab).then(() => {
      setCurrentTab(selectedTab);
      aggregateAndMap(fieldDetails, selectedTab);
    });
    setAnnualImplementationCost('');
  };

  const submitTabData = async () => {
    const currentTabTitle = projectActivities[currentTab]?.title;
    const activityIds = projectActivities
      .find((item) => item.title === currentTabTitle)
      ?.practiceTypeList.map((practiceType) => practiceType.value);

    const submitData = formatSubmitData(
      currentTabData,
      projectId,
      farmId,
      activityIds,
      getActivityCategory(projectActivities[currentTab]?.title),
      currentTabStatus,
      participantId,
      annualImplementationCost,
    );

    setLoaderCount((prev) => prev + 1);
    return axios
      .post(
        `${SUBMIT_POI_TAB_DATA}?internalProjectId=${projectId}&participantId=${participantId}`,
        submitData,
      )
      .then(() => {
        updateReportingStatus('Farm', farmId);
      })
      .catch(() => {})
      .finally(() => {
        setAnnualImplementationCost('');
        setLoaderCount((prev) => prev - 1);
      });
  };

  const continueHandler = () => {
    submitTabData().then(() => {
      fetchFieldDetails().then((fieldData) => {
        navigateToNextTab(fieldData);
      });
    });
  };

  const closeHandler = () => {
    if (isEmailLinkClicked) {
      if (activityStatusList === PAGE_CONTENT.completed) {
        submitTabData().then(gotoMenuPage);
      } else {
        navigate(`${pathName.participant.landingPage}`);
      }
    } else {
      submitTabData().then(gotoMenuPage);
    }
  };

  const handleDropdownValueUpdate = (
    newList,
    fileId,
    dropdownType,
    isMultiSelect,
    isGeorefenceType = false,
  ) => {
    const length = newList.length;

    setCurrentTabData((prev) =>
      prev.map((fileData) => {
        if (fileData.fileId === fileId) {
          if (dropdownType === PAGE_CONTENT.fieldDropdown) {
            return {
              ...fileData,
              fields: checkTernaryCondition(isMultiSelect, newList, [
                newList[length - 1],
              ]),
              fileType: checkTernaryCondition(
                isGeorefenceType,
                possibleFileTypes[4].name,
                fileData.fileType,
              ),
            };
          } else if (dropdownType === PAGE_CONTENT.practiceTypeDropdown) {
            return {
              ...fileData,
              practice: newList,
            };
          }
        }
        return fileData;
      }),
    );
  };

  const handleDropdownValueRemove = (
    fileId,
    dropdownValue,
    dropdownType,
    isGeorefenceType,
  ) => {
    const getFilteredFields = (fields, dropdownValue) =>
      fields.filter((fieldId) => fieldId !== dropdownValue);

    setCurrentTabData((prev) =>
      prev.map((fileData) => {
        if (fileData.fileId === fileId) {
          if (dropdownType === PAGE_CONTENT.fieldDropdown) {
            return {
              ...fileData,
              fields: checkTernaryCondition(
                getFilteredFields(fileData.fields, dropdownValue).length === 0,
                checkTernaryCondition(isGeorefenceType, [-1], [0]),
                getFilteredFields(fileData.fields, dropdownValue),
              ),
              fileType: checkTernaryCondition(
                isGeorefenceType,
                possibleFileTypes[4].name,
                fileData.fileType,
              ),
            };
          } else if (dropdownType === PAGE_CONTENT.practiceTypeDropdown) {
            return {
              ...fileData,
              practice: getFilteredFields(fileData.practice, dropdownValue),
            };
          }
        }
        return fileData;
      }),
    );
  };

  const setPOIDropzones = () => {
    const currentTabTitle = projectActivities[currentTab].title;
    const fieldList = projectActivities?.find(
      (item) => item.title === currentTabTitle,
    )?.fieldsOfActivity;
    const practiceTypeList = projectActivities?.find(
      (item) => item.title === currentTabTitle,
    )?.practiceTypeList;
    const activityIds = practiceTypeList?.map(
      (practiceType) => practiceType.value,
    );

    switch (currentTabTitle) {
      case projectActivitiesLabels[0].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone1_label}
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            currentDropzoneData={currentTabData?.filter((data) =>
              data.fileType.includes(possibleFileTypes[0].name),
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            isGeorefenceType
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[1].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[1].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[1].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.cover_crop_dropzone3_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
          />,
        ];
      case projectActivitiesLabels[1].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.residue_dropzone1_label}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            currentDropzoneData={currentTabData?.filter((data) =>
              data.fileType.includes(possibleFileTypes[0].name),
            )}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            maxFiles={fieldList.length}
            canMultipleFilesBeUploaded={true}
            handleDropdownValueRemove={handleDropdownValueRemove}
            practiceTypeList={practiceTypeList}
            hasPracticeTypeDropdown
            isGeorefenceType
          />,
        ];
      case projectActivitiesLabels[2].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[3].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.nutrient_dropzone1_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[3].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[3].name,
                activityIds,
                false,
              )
            }
            fieldDetails={fieldList}
            canMultipleFilesBeUploaded={true}
            maxFiles={fieldList.length}
            handleDropdownValueRemove={handleDropdownValueRemove}
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.nutrient_dropzone2_label}
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            canMultipleFilesBeUploaded={true}
            maxFiles={fieldList.length}
          />,
        ];
      case projectActivitiesLabels[3].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[0].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.edge_of_field_dropzone1_label}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                false,
                true,
              )
            }
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text1}
            currentDropzoneData={currentTabData?.filter((data) =>
              data.fileType.includes(possibleFileTypes[0].name),
            )}
            acceptedFilesInfo={geoReferenceImageAcceptedFilesInfo}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[0].name,
                activityIds,
              )
            }
            canMultipleFilesBeUploaded={true}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            maxFiles={fieldList.length}
            practiceTypeList={practiceTypeList}
            hasPracticeTypeDropdown
            isGeorefenceType
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.edge_of_field_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            dropzoneInfoText={PAGE_CONTENT.dropzone_info_text2}
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
              )
            }
            canMultipleFilesBeUploaded={true}
            fieldDetails={fieldList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            maxFiles={fieldList.length}
            hasPracticeTypeDropdown
            practiceTypeList={practiceTypeList}
          />,
        ];
      case projectActivitiesLabels[4].title:
        return [
          <FileUploadCompPOI
            key={possibleFileTypes[1].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.soil_carbon_dropzone1_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[1].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[1].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            practiceTypeList={practiceTypeList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            hasPracticeTypeDropdown
          />,
          <FileUploadCompPOI
            key={possibleFileTypes[2].type}
            handleFileDelete={(fileId) => handleFileDelete(fileId)}
            label={PAGE_CONTENT.soil_carbon_dropzone2_label}
            acceptedFilesInfo={recieptsInvoiceAndMgmtPlanAcceptedFilesInfo}
            dropzoneInfoText={
              PAGE_CONTENT.reciepts_invoice_mgmt_plan_dropzone_info_text
            }
            handleDropdownValueUpdate={(newList, fileId, dropdownType) =>
              handleDropdownValueUpdate(
                newList,
                fileId,
                dropdownType,
                true,
                false,
              )
            }
            currentDropzoneData={currentTabData?.filter(
              (data) => data.fileType === possibleFileTypes[2].name,
            )}
            addFilesToTabData={(newFiles) =>
              addFilesToTabData(
                newFiles,
                possibleFileTypes[2].name,
                activityIds,
              )
            }
            fieldDetails={fieldList}
            practiceTypeList={practiceTypeList}
            handleDropdownValueRemove={handleDropdownValueRemove}
            hasPracticeTypeDropdown
          />,
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (projectActivities.length !== 0) {
      fetchActivityTabData(currentTab);
    }
    setShowWarning(projectActivities.length === 0);
  }, [projectActivities]);

  useEffect(() => {
    validateTabData(currentTabData);
  }, [currentTabData]);

  useEffect(() => {
    fetchPoiActivities();
  }, [farmId]);

  useEffect(() => {
    if (isAllFieldsAndActivitiesMapped === true) {
      setShowWarning(true);
    }
  }, [isAllFieldsAndActivitiesMapped]);

  useEffect(() => {
    const areAllFilesMapped = currentTabData.every((item) => {
      const hasInvalidField =
        item.fields.includes(-1) || item.fields.includes(0);
      const hasEmptyPractice = item.practice.length === 0;
      return !hasInvalidField && !hasEmptyPractice;
    });
    const isTabValidated = isAllFieldsAndActivitiesMapped && areAllFilesMapped;
    const poiTabStatus = checkTernaryCondition(
      isTabValidated,
      PAGE_CONTENT.completed,
      PAGE_CONTENT.in_progress,
    );
    setCurrentTabStatus(
      checkTernaryCondition(
        currentTabData.length === 0,
        PAGE_CONTENT.not_started,
        poiTabStatus,
      ),
    );
  }, [currentTabData, isAllFieldsAndActivitiesMapped, poiStatusList]);

  useEffect(() => {
    if (currentTabStatus === PAGE_CONTENT.not_started) {
      if (annualImplementationCost) {
        setCurrentTabStatus(PAGE_CONTENT.in_progress);
      }
    }
  }, [annualImplementationCost]);

  return (
    <>
      <Loader loading={loaderCount > 0} zIndex={999999} />
      {showWarning && (
        <InfoBox>
          <ContentBox width="95%">
            <InfoTooltip />
            <TextWrapper color={DARK_CHARCOAL}>
              {PAGE_CONTENT.info_test_implemented_activities}
            </TextWrapper>
          </ContentBox>
          <ContentBox
            width="5%"
            content="flex-end"
            cursor="pointer"
            data-testid="closeWarningIcon"
            onClick={() => {
              setShowWarning(false);
            }}>
            <CloseIcon />
          </ContentBox>
        </InfoBox>
      )}
      <MainWrapper>
        <HeaderWrapper>
          <TextWrapper width={'10rem'} marginLeft={'1rem'} fontWeight={600}>
            {PAGE_CONTENT.project_activities}
          </TextWrapper>
          <Box
            sx={{
              ...flexColumn,
              ...yAxisAutoScroll,
              width: '100%',
            }}>
            <TabberBox style={TabberBoxCustomStyle}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="page-tabs">
                {projectActivities?.map((tab, index) => (
                  <Tab
                    disabled={tab?.isDisabled}
                    sx={TabStylesSx}
                    className={classNames({
                      'active-tab': currentTab === index,
                    })}
                    key={tab?.title}
                    label={tab?.title}
                    {...tabProps(index)}
                  />
                ))}
              </Tabs>
            </TabberBox>
          </Box>
        </HeaderWrapper>
        <ContentWrapper>
          {projectActivities?.map((tab, index) => (
            <TabPanel key={tab.title} value={currentTab} index={index}>
              <TextWrapper marginBottom={'0.25rem'}>
                {getFieldAcresText(!isAllFieldsAndActivitiesMapped, tab)}
              </TextWrapper>
              {!isAllFieldsAndActivitiesMapped && (
                <TextWrapper lineHeight={'0.963rem'} fontSize={'0.688rem'}>
                  <PoiAlertIcon />
                  {PAGE_CONTENT.field_and_practice_mapping_error_msg}
                </TextWrapper>
              )}
              <TextWrapper
                gap="0.5rem"
                marginBottom="0.5rem"
                position="relative">
                <span style={{ alignSelf: 'center' }}>
                  {PAGE_CONTENT.annual_cost_per_acre}
                </span>
                {annualImplementationCost && <StyledSpan>$</StyledSpan>}
                <InputField
                  value={`${annualImplementationCost}`}
                  width="3.75rem"
                  labelMarginTop="0.375rem"
                  predefinedUnit={PAGE_CONTENT.per_acre}
                  hasPredefinedUnit
                  optionalLabelOnRight
                  onUpdate={(e) => {
                    const value = e.target.value.trim().replace('$', '');
                    if (isNaN(value)) {
                      return;
                    }
                    if (value.trim() === '') {
                      setAnnualImplementationCost('');
                      return;
                    }
                    setAnnualImplementationCost(
                      ` ${value.trim().replace(/[^0-9.]/g, '')}`,
                    );
                  }}
                />
              </TextWrapper>
              <TextWrapper marginBottom={'1rem'}>
                {checkTernaryCondition(
                  projectActivities[currentTab].title ===
                    projectActivitiesLabels[1].title,
                  PAGE_CONTENT.list_proof_of_implementation_label_type2,
                  PAGE_CONTENT.list_proof_of_implementation_label_type1,
                )}

                <InfoTooltip
                  placement="bottom-end"
                  title=""
                  content={PAGE_CONTENT.poiImageOptionTooltipText}
                />
              </TextWrapper>
              {setPOIDropzones()}
            </TabPanel>
          ))}
        </ContentWrapper>
      </MainWrapper>
      <UserNavigationButtons
        saveAndContinueHandler={continueHandler}
        saveAndCloseHandler={closeHandler}
        hideCancelBtn
      />
    </>
  );
};

ProofOfImplementationMainComp.propTypes = {
  farmId: PropTypes.number,
  poiStatusList: PropTypes.array,
  setActivityTabData: PropTypes.func,
  fetchFieldDetails: PropTypes.func,
  updateFarmLevelStatus: PropTypes.func,
  currentTab: PropTypes.number,
  setCurrentTab: PropTypes.func,
  projectActivities: PropTypes.array,
  setProjectActivities: PropTypes.func,
  currentTabData: PropTypes.array,
  setCurrentTabData: PropTypes.func,
  currentTabStatus: PropTypes.string,
  setCurrentTabStatus: PropTypes.func,
  updateReportingStatus: PropTypes.func,
  gotoNextInprogressPage: PropTypes.func,
};

export default ProofOfImplementationMainComp;
