import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import {
  BasicWrapper,
  TextWrapper,
  inputStyle,
} from '../DateRangePicker.style';
import CustomDay from './CustomDatePicker.react';
import { PAGE_CONTENT } from '../DateRangePicker.content';
import dayjs from 'dayjs';
import { textFieldStyle } from './CustomDatePicker.style';

const CalendarElement = ({
  date,
  text,
  border,
  otherDate,
  isFromDate,
  setFromDate,
  setToDate,
  fromDate,
  toDate,
}) => {
  return (
    <BasicWrapper direction={PAGE_CONTENT.column} gap="1rem">
      <BasicWrapper alignment="center" gap="0.5rem">
        <TextWrapper fontSize="0.9rem" fontWeight="400">
          {text}
        </TextWrapper>
        <TextField
          data-testid="date-field"
          variant={PAGE_CONTENT.outlined}
          id="input-with-icon-adornment"
          value={`${date.slice(5, 7)}/${date.slice(8)}/${date.slice(0, 4)}`}
          InputProps={{
            sx: inputStyle,
          }}
          sx={textFieldStyle}
        />
      </BasicWrapper>
      <div style={{ borderRight: border }}>
        <CustomDay
          data-testid="calendar"
          date={dayjs(otherDate)}
          isFromDate={isFromDate}
          dateSetter={isFromDate ? setFromDate : setToDate}
          fromDate={fromDate}
          toDate={toDate}
        />
      </div>
    </BasicWrapper>
  );
};

CalendarElement.propTypes = {
  date: PropTypes.instanceOf(Date),
  text: PropTypes.string,
  border: PropTypes.bool,
  otherDate: PropTypes.instanceOf(Date),
  isFromDate: PropTypes.bool,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
};

export default CalendarElement;
