import { pathName } from 'Routes/routes.const';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import { useLocation } from 'react-router';
import React from 'react';
import { ORIGINATION_PROJECT_CALCULATIONS_REPORT_PAGE_CONTENT } from './Org_ProjectCalculationsReport.constants';

const Org_ProjectCalculationsReport = () => {
  const { state } = useLocation();

  return (
    <div>
      <SummaryViewHeader
        title={`${ORIGINATION_PROJECT_CALCULATIONS_REPORT_PAGE_CONTENT.page_title_static_value} ${ORIGINATION_PROJECT_CALCULATIONS_REPORT_PAGE_CONTENT.calculationsReport}`}
        previouspath={
          state?.previousPath
            ? state.previousPath
            : pathName.originationProject.view
        }
      />
    </div>
  );
};

export default Org_ProjectCalculationsReport;
