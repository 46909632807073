import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from 'react-router';
import { checkTernaryCondition } from 'utils/helper';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import {
  DARK_CHARCOAL,
  HOVER_LIGHT_BLUE,
  ROW_FOCUSED_BG,
} from 'theme/GlobalColors';
import { Divider } from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    disableRestoreFocus
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.125rem',
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0.5rem 0',
    },
    '& .MuiMenuItem-root': {
      minWidth: '9.375rem',
      height: '1.75rem',
      padding: '6px 8px',
      fontSize: '0.875rem',
      color: DARK_CHARCOAL,
      ':hover': { backgroundColor: HOVER_LIGHT_BLUE },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: ROW_FOCUSED_BG,
      },
    },
  },
}));

AddButton.propTypes = {
  buttonName: PropTypes.string,
  noDropdown: PropTypes.bool,
  options: PropTypes.array,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  toggleModal: PropTypes.func,
  setSelectedOption: PropTypes.func,
  isModalPresent: PropTypes.bool,
  disabledOption: PropTypes.bool,
  disableButton: PropTypes.bool,
  customBtnSx: PropTypes.object,
  addBtnPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasMultipleModals: PropTypes.bool,
};

export default function AddButton({
  buttonName,
  noDropdown,
  options,
  onClick,
  backgroundColor,
  textColor,
  toggleModal,
  setSelectedOption,
  isModalPresent,
  disabledOption = false,
  disableButton = false,
  customBtnSx = {},
  addBtnPadding,
  hasMultipleModals = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openModal = (name) => {
    if (hasMultipleModals) {
      options.find((option) => option.name === name).modalHandler();
    } else {
      toggleModal(true);
      setSelectedOption(name);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        disabled={disableButton}
        id="add-new-button"
        aria-controls={open ? 'add-new-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={onClick ? onClick : handleClick}
        sx={[customBtnSx, primaryButtonStyle(addBtnPadding)]}
        endIcon={!noDropdown ? <KeyboardArrowDownIcon /> : ''}>
        {buttonName ? buttonName : 'Add New'}
      </Button>
      {!noDropdown && (
        <StyledMenu
          id="add-new-menu"
          MenuListProps={{
            'aria-labelledby': 'add-new-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {options
            ? options?.map((option) =>
                isModalPresent ? (
                  <>
                    {option?.hasDivider && <Divider />}{' '}
                    <MenuItem
                      onClick={() => openModal(option.name)}
                      disableRipple
                      disabled={
                        option?.isDisabled ||
                        checkTernaryCondition(
                          option.id !== 2 && disabledOption,
                          false,
                          disabledOption,
                        )
                      }
                      key={option.name}>
                      {option.name}
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    onClick={() =>
                      navigation(option.link, {
                        state: { previousPath: pathname },
                      })
                    }
                    disableRipple
                    key={option.name}>
                    {option.name}
                  </MenuItem>
                ),
              )
            : null}
          {/* <MenuItem
            onClick={() =>
              navigation("/projects/add-project", {
                state: { previousPath: pathname },
              })
            }
            disableRipple
          >
            Project
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple>
            Contract
          </MenuItem>
          <MenuItem
            onClick={() =>
              navigation("/stakeholders/add-stakeholders", {
                state: { previousPath: pathname },
              })
            }
            disableRipple
          >
            Stakeholder Profile
          </MenuItem> */}
        </StyledMenu>
      )}
    </div>
  );
}
