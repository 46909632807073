import React from 'react';
import {
  TermsOfAttestationWrapper,
  TermsOfAttestationHeading,
  ContentRestrictionHeading,
  AttestationDetails,
  AttestationSections,
} from './TermsOfAttestation.style';
import {
  ADDITIONAL_TERMS,
  DISCLAIMERS,
  TERMS_OF_ATTESTATION,
  USE_OF_CONTENT,
} from './TermsOfAttestation.content';

export const TermsOfAttestation = ({ setTermsAndConditionsFullyRead }) => {
  const handleScroll = (e) => {
    const bottom =
      e.target.clientHeight - (e.target.scrollHeight - e.target.scrollTop) > -1;
    if (bottom) {
      setTermsAndConditionsFullyRead(true);
    }
  };

  return (
    <TermsOfAttestationWrapper
      onScroll={handleScroll}
      data-testid="terms-of-attestation">
      <TermsOfAttestationHeading>
        Terms of Attestation
      </TermsOfAttestationHeading>
      <AttestationDetails>{TERMS_OF_ATTESTATION}</AttestationDetails>
      <AttestationSections>
        <ContentRestrictionHeading>
          Use of Content; Restrictions
        </ContentRestrictionHeading>
        <AttestationDetails>{USE_OF_CONTENT}</AttestationDetails>
      </AttestationSections>
      <AttestationSections>
        <ContentRestrictionHeading>
          Disclaimers and Limitations of Liability
        </ContentRestrictionHeading>
        <AttestationDetails>{DISCLAIMERS}</AttestationDetails>
      </AttestationSections>
      <AttestationSections>
        <ContentRestrictionHeading>Additional Terms</ContentRestrictionHeading>
        <AttestationDetails>{ADDITIONAL_TERMS}</AttestationDetails>
      </AttestationSections>
    </TermsOfAttestationWrapper>
  );
};
