export const STAKEHOLDERS_PROFILE_CONTENT = {
  delete_message:
    'Are you sure you want to delete and all data associated with it?',
  success: 'SUCCESS',
  success_message: 'has been deleted successfully.',
  failed: 'FAILED',
  failed_message:
    'couldn’t be deleted due to a technical error. Please, try again.',
  denied_message_single_transaction:
    'transaction. You must remove this stakeholder from the transaction before it can be deleted.',
  denied_message_multiple_transaction:
    'is linked in multiple transactions. You must remove this stakeholder from each transaction below before it can be deleted.',
  denied: 'DENIED',
  cannot_delete: "can't be deleted",
  credit_supply_co2_MT: (
    <b data-testid="supply">
      Credit Supply (CO<sub>2</sub>e MT)
    </b>
  ),
};

export const SortColValues = {
  projectName: 'Name',
  type: 'Type',
  creditSupply: 'creditSupply',
  perCredit: 'perCredit',
  status: 'Status',
};
