import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { FETCH_PARTICIPANT_NOTIFICATIONS } from 'urls';

export const NotificationLogContext = createContext({});

export const NotificationLogProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const [newNotification, setNewNotification] = useState(false);

  const fetchNotificationLogs = () => {
    axios.get(FETCH_PARTICIPANT_NOTIFICATIONS).then((response) => {
      setLogs(response.data);
    });
  };
  const value = React.useMemo(
    () => ({
      logs,
      setLogs,
      newNotification,
      setNewNotification,
      fetchNotificationLogs,
    }),
    [logs, newNotification],
  );

  useEffect(() => {
    fetchNotificationLogs();
  }, []);

  return (
    <NotificationLogContext.Provider value={value}>
      {children}
    </NotificationLogContext.Provider>
  );
};
