import PropTypes from 'prop-types';
import { PAGE_CONTENT } from './CustomCell.content';
import {
  CellValueWrapper,
  RowCellWrapper,
  TextWrapper,
} from './CustomCell.style';

const CustomCell = ({ value1, value2, color }) => {
  return (
    <RowCellWrapper direction={PAGE_CONTENT.column}>
      <CellValueWrapper fontSize="0.9rem" fontWeight={400}>
        {value1}
      </CellValueWrapper>
      <TextWrapper fontSize="0.8rem" fontWeight={400} color={color}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

CustomCell.propTypes = {
  value1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default CustomCell;
