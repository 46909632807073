import { Fragment, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useStyles } from './style';
import { getCurrentUserData } from 'security/components/Profile.react';

const ProfileSection = () => {
  const currentUser = getCurrentUserData();

  const [avatarProps, setAvatarProps] = useState({ children: '' });
  const { AvatarIcon, UsernameDiv, UsernameTooltip } = useStyles();

  useEffect(() => {
    currentUser &&
      setAvatarProps({ children: currentUser.email[0].toUpperCase() || '' });
  }, []);
  return (
    <Fragment>
      {avatarProps.children && (
        <UsernameTooltip
          data-testid="profile-icon"
          arrow
          title={<UsernameDiv>{currentUser.email}</UsernameDiv>}>
          <IconButton
            sx={{ p: 0 }}
            aria-controls="menu-appbar"
            aria-haspopup="true">
            <AvatarIcon {...avatarProps} />
          </IconButton>
        </UsernameTooltip>
      )}
    </Fragment>
  );
};

export default ProfileSection;
