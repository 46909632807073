import { SettingsContext } from 'contextAPI/settings';
import { initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import { useContext } from 'react';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const useFirebaseSignIn = () => {
  const settings = useContext(SettingsContext);
  const firebaseConfig = {
    apiKey: settings.firebaseApiKey,
    authDomain: settings.firebaseAuthDomain,
    authProvider: settings.authProvider,
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new SAMLAuthProvider(`saml.${firebaseConfig.authProvider}`);

  const handleLogin = () => {
    return signInWithPopup(auth, provider);
  };
  return { handleLogin, app };
};
