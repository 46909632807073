import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ControlledInputWrapper,
  FieldErrorWrapper,
  IconWrapper,
  InputWithAdornment,
  TextInput,
  tickIconStyle,
} from '../LeftMenuFieldData.style';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/EditIconWithHoverEffect.svg';
import { ReactComponent as TickIcon } from '../../../../../assets/icons/tickIconWithHoverEffect.svg';
import { checkTernaryCondition } from 'utils/helper';
import { PAGE_CONTENT } from '../LeftMenuFieldData.content';

const ControlledInput = ({
  hoveredFieldId,
  field,
  setValue,
  farmInfo = [],
  isInBaselinePage,
  isFieldInCurrentFarm,
  fieldSelected = false,
  disabled = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [textValue, setTextValue] = useState(field.value);

  const focusInput = (event) => {
    event.stopPropagation();
    setEditMode(true);
  };

  const updateInputValue = (event) => {
    event.stopPropagation();
    setValue(textValue);
    setEditMode(false);
  };

  const isFieldNameUnique = useMemo(() => {
    let count = 0;
    let currentFieldId = field.id;
    for (const farm of farmInfo) {
      const fields = farm.fields;
      for (const field of fields) {
        if (currentFieldId !== field.id && field.value === textValue.trim()) {
          count++;
        }
      }
    }

    return count === 0;
  }, [textValue]);

  useEffect(() => {
    setTextValue(field.value);
  }, [field]);

  useEffect(() => {
    if (!isFieldInCurrentFarm) {
      setEditMode(false);
    }
  }, [isFieldInCurrentFarm]);

  return (
    <ControlledInputWrapper>
      <InputWithAdornment isFocused={editMode}>
        <TextInput
          isInBaselinePage={isInBaselinePage}
          selected={fieldSelected}
          autoFocus={editMode}
          dimmed={field.value.length === 0}
          value={textValue}
          placeholder={`${Number(field.lat).toFixed(5)}, ${Number(
            field.lng,
          ).toFixed(5)} (add name)`}
          readOnly={!editMode}
          onChange={(event) => setTextValue(event.target.value)}
        />
        {!disabled && hoveredFieldId === field.id && (
          <IconWrapper data-testid="icon-wrapper">
            {checkTernaryCondition(
              editMode,
              <TickIcon
                style={tickIconStyle}
                onClick={updateInputValue}
                data-testid="tick-icon"
              />,
              <EditIcon
                width={24}
                height={24}
                onClick={focusInput}
                data-testid="edit-pen"
              />,
            )}
          </IconWrapper>
        )}
      </InputWithAdornment>
      {!isFieldNameUnique && editMode && (
        <FieldErrorWrapper>
          {PAGE_CONTENT.fieldNameExistsError}
        </FieldErrorWrapper>
      )}
    </ControlledInputWrapper>
  );
};

ControlledInput.propTypes = {
  hoveredFieldId: PropTypes.string,
  field: PropTypes.object,
  setValue: PropTypes.func,
  farmInfo: PropTypes.array,
  isInBaselinePage: PropTypes.bool,
  isFieldInCurrentFarm: PropTypes.bool,
  fieldSelected: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default ControlledInput;
