import axios from 'axios';
import { useState } from 'react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import {
  ADD_USER_CONTENT,
  changeRoleNames,
} from 'components/UserManagementModal/AddUser.content';
import {
  MODULE_ROLES,
  USER_CHECK_EMAIL,
  ADD_NEW_USER,
  GET_USER_DETAILS,
  EDIT_USER,
} from 'urls';
import { modifyRolesName } from '../components/UserManagementModal/AddUser.content';
import { checkTernaryCondition } from 'utils/helper';

export const formModal = {
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  company: 'Green foods CO',
  userRoles: '',
  originationRoles: '',
};
export const fieldInitialState = { value: '', error: false, errorType: '' };

export const useUserDetails = () => {
  const [userFirstName, setUserFirstName] = useState(fieldInitialState);
  const [userLastName, setUserLastName] = useState(fieldInitialState);
  const [userEmail, setUserEmail] = useState(fieldInitialState);
  const [userRoles, setUserRoles] = useState(fieldInitialState);
  const [originationRoles, setOriginationRoles] = useState(fieldInitialState);
  const [formHasError, setFormHasError] = useState(null);
  const [moduleRoles, setModuleRoles] = useState(null);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [fetchedUserDetails, setFetchedUserDetails] = useState('');
  const [moduleError, setModuleError] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const handleEmailChange = (
    nativeStateSetter,
    formStateSetter,
    event,
    key,
  ) => {
    const finalValue = event.target.value.trim() ? event.target.value : '';
    nativeStateSetter({
      value: finalValue,
      error: false,
      errorType: '',
    });
    formStateSetter(key, finalValue);
  };

  const handleTextFieldValueChange = (
    nativeState,
    nativeStateSetter,
    formStateSetter,
    event,
    key,
    freeText,
  ) => {
    const finalValue = event.target.value.trim() ? event.target.value : '';
    const valueIsEmpty = finalValue === '';
    nativeStateSetter(
      freeText
        ? finalValue
        : {
            value: finalValue,
            error: valueIsEmpty,
            errorType: valueIsEmpty ? 'primary' : '',
          },
    );
    formStateSetter(key, finalValue);
  };
  const setPrimaryStateError = (isEmpty, stateSetter, state) => {
    isEmpty && stateSetter({ ...state, error: true, errorType: 'primary' });
  };
  const setModuleRolesToEmpty = () => {
    setOriginationRoles({ ...originationRoles, value: '' });
  };
  const fetchModuleRoles = () => {
    axios.get(MODULE_ROLES).then((response) => {
      const rolesArray = response.data.map((value) => ({
        value: value,
        label: value,
      }));
      setModuleRoles(rolesArray);
    });
  };
  const checkUserEmail = (email) => {
    setCheckingEmail(true);
    axios.get(`${USER_CHECK_EMAIL}${email}`).then((response) => {
      setIsDuplicateEmail(response.data);
      setCheckingEmail(false);
    });
  };
  const validateFormValue = () => {
    const isUserFirstNameEmpty = userFirstName.value === '';
    const isUserLastNameEmpty = userLastName.value === '';
    const isUserEmailEmpty = userEmail.value === '';
    const isRoleSelectionEmpty = userRoles.value === '';
    const isOriginationRolesEmpty =
      userRoles.value.toLowerCase() === 'modules' &&
      originationRoles.value === '';
    setPrimaryStateError(isUserFirstNameEmpty, setUserFirstName, userFirstName);
    setPrimaryStateError(isUserLastNameEmpty, setUserLastName, userLastName);
    setPrimaryStateError(isUserEmailEmpty, setUserEmail, userEmail);
    setPrimaryStateError(isRoleSelectionEmpty, setUserRoles, userEmail);
    setPrimaryStateError(
      isOriginationRolesEmpty,
      setOriginationRoles,
      originationRoles,
    );
    const isEmptyArray = [
      isUserFirstNameEmpty,
      isUserLastNameEmpty,
      isUserEmailEmpty,
      isRoleSelectionEmpty,
      isOriginationRolesEmpty,
    ];
    isEmptyArray.includes(true)
      ? setFormHasError(true)
      : setFormHasError(false);
    if (originationRoles.value === 'None') {
      setFormHasError(true);
      setOriginationRoles({
        ...originationRoles,
        error: true,
        errorType: 'noneError',
      });
      setModuleError(true);
    } else {
      setModuleError(false);
    }
  };
  const handleRolesChange = (event, formStateSetter) => {
    const selectedValue = event.target.value;
    setUserRoles({ value: selectedValue, error: false, errorType: '' });
    formStateSetter(ADD_USER_CONTENT.user_roles, selectedValue);
    selectedValue === 'Admin' && setModuleRolesToEmpty();
  };

  const axiosCall = (url, data, isEdit) => {
    if (isEdit) {
      return axios.put(url, data);
    } else {
      return axios.post(url, data);
    }
  };
  const postUserData = (formValue, isEdit, id, fetchUserList) => {
    const url = isEdit ? `${EDIT_USER}/${id}` : ADD_NEW_USER;
    const postData = {
      email: formValue?.userEmail,
      firstName: formValue?.userFirstName,
      lastName: formValue?.userLastName,
      role: formValue?.userRoles.toUpperCase(),
      originationRole:
        formValue?.userRoles.toLowerCase() === 'modules'
          ? modifyRolesName('origination', formValue?.originationRoles)
          : 'None',
    };
    axiosCall(url, postData, isEdit)
      .then(() => {
        toast(
          <CustomSnackbar
            type={ADD_USER_CONTENT.success}
            message={`${formValue?.userFirstName} ${
              formValue?.userLastName
            }${checkTernaryCondition(
              isEdit,
              ADD_USER_CONTENT.success_toast_message_edit,
              ADD_USER_CONTENT.success_toast_message,
            )}`}
          />,
          toastStyle,
        );
        fetchUserList();
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type={ADD_USER_CONTENT.error}
            message={`${formValue?.userFirstName} ${
              formValue?.userLastName
            }${checkTernaryCondition(
              isEdit,
              ADD_USER_CONTENT.error_toast_message_edit,
              ADD_USER_CONTENT.error_toast_message,
            )}`}
          />,
          toastStyle,
        );
      })
      .finally(() => {
        setFormHasError('');
      });
  };
  const fetchUserDetails = (id) => {
    axios.get(`${GET_USER_DETAILS}${id}`).then((response) => {
      setUserFirstName({ ...userFirstName, value: response.data.firstName });
      setUserLastName({ ...userLastName, value: response.data.lastName });
      setUserEmail({ ...userEmail, value: response.data.email });
      setUserRoles({
        ...userRoles,
        value: `${response.data.role.slice(0, 1)}${response.data.role
          .slice(1)
          .toLowerCase()}`,
      });
      setOriginationRoles({
        ...originationRoles,
        value: checkTernaryCondition(
          response.data.role === 'ADMIN',
          '',
          changeRoleNames(response.data.originationRole),
        ),
      });

      const userDetails = {
        userFirstName: response.data.firstName,
        userLastName: response.data.lastName,
        userEmail: response.data.email,
        company: 'Green foods CO',
        userRoles: response.data.role,
        originationRoles: changeRoleNames(response.data.originationRole),
      };
      setFetchedUserDetails(userDetails);
    });
  };
  const handleFormSubmit = () => {
    validateFormValue();
  };
  return {
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    userEmail,
    setUserEmail,
    userRoles,
    setUserRoles,
    originationRoles,
    setOriginationRoles,
    handleRolesChange,
    handleTextFieldValueChange,
    handleFormSubmit,
    formHasError,
    fetchModuleRoles,
    moduleRoles,
    checkUserEmail,
    isDuplicateEmail,
    postUserData,
    fetchUserDetails,
    fetchedUserDetails,
    setIsDuplicateEmail,
    moduleError,
    setModuleError,
    setFormHasError,
    handleEmailChange,
    checkingEmail,
  };
};
