import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DOWNLOAD_LINK,
  QUILL_GRAY,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const ActivityMainWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const AccordionDetailsSx = {
  padding: '0px',
  display: 'flex',
  gap: '1.5rem',
  'overflow-x': 'auto',
  width: '100%',
  '& .MuiPaper-root': {
    width: '100%',
  },
};

export const AccordionSummarySx = (padding) => {
  return {
    padding: checkTernaryCondition(padding, padding, '0.5rem 1rem 0.5rem 0rem'),
    borderBottom: `1px solid ${QUILL_GRAY}`,
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0.5rem 0',
    },
    '&.Mui-expanded': {
      minHeight: '44px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  };
};

export const AccordionSx = {
  backgroundColor: WHITE,
  '.MuiAccordionSummary-root': {
    height: '44px',
    minHeight: '44px',
  },
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordion-root:before': {
    opacity: '0',
  },
};

export const ProjectNameWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 0.375rem 0.5rem;
`;

export const ProjectActionWrapper = styled.div`
  color: ${DOWNLOAD_LINK};
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const ArrowLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0 0.5rem;
`;

export const BackPage = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #0071a0;
`;

export const ProjectName = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #63666a;
`;

export const SummaryContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ContractTitleWrapper = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: ${({ color }) => color};
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding-left: 3.5rem;
`;

export const dataGridSx = {
  boxShadow: 'none',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  borderRadius: 0,
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '1.188rem',
    fontSize: '0.875rem',
    color: '#333333',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontStyle: 'normal',
    lineHeight: '140%',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#333333',
  },
  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
  backgroundColor: WHITE,
};

export const StatusWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const StatusIcon = styled.div`
  align-self: center;
  height: 1.5rem;
  width: 1.5rem;
`;

export const StatusText = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${DARK_CHARCOAL};
`;

export const StatusDate = styled.div`
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;
  span {
    color: ${COOL_GRAY};
    font-style: italic;
  }
`;

export const ActvityHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  background-color: ${WHITE};
  border-bottom: 1px solid ${QUILL_GRAY};
`;

export const ProjectDataWrapper = styled.div`
  font-family: Open Sans;
  white-space: nowrap;
  overflow: hidden;
  max-width: 25rem;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.225rem;
  text-align: left;
  span {
    font-weight: 600;
  }
`;

export const AcreageWrapper = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
`;
