import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { BUTTON_CONTENT } from './UserNavigationButtons.content';
import {
  ButtonsWrapper,
  cancelBtnStyle,
  saveAndCloseBtnStyle,
  saveAndContinuebuttonStyle,
} from './UserNavigationButtons.style';
import {
  primaryButtonStyle,
  secondaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';

const UserNavigationButtons = ({
  saveAndContinueHandler,
  saveAndCloseHandler,
  CancelHandler,
  hideCancelBtn = false,
}) => {
  return (
    <>
      <ButtonsWrapper>
        <Button
          variant={BUTTON_CONTENT.contained}
          size={BUTTON_CONTENT.medium}
          sx={[saveAndContinuebuttonStyle, primaryButtonStyle()]}
          color={BUTTON_CONTENT.success}
          onClick={saveAndContinueHandler}
          disabled={false}
          disableElevation>
          {BUTTON_CONTENT.continue_button_label}
        </Button>
        <Button
          variant={BUTTON_CONTENT.contained}
          size={BUTTON_CONTENT.medium}
          color={BUTTON_CONTENT.success}
          sx={[saveAndCloseBtnStyle, secondaryButtonStyle]}
          onClick={saveAndCloseHandler}
          disableElevation>
          {BUTTON_CONTENT.close_button_label}
        </Button>
        {!hideCancelBtn && (
          <Button
            variant={BUTTON_CONTENT.contained}
            size={BUTTON_CONTENT.medium}
            color={BUTTON_CONTENT.success}
            sx={[cancelBtnStyle, tertiaryButtonStyle]}
            onClick={CancelHandler}
            disableElevation>
            {BUTTON_CONTENT.cancel_btn_label}
          </Button>
        )}
      </ButtonsWrapper>
    </>
  );
};

UserNavigationButtons.propTypes = {
  saveAndContinueHandler: PropTypes.func,
  saveAndCloseHandler: PropTypes.func,
  CancelHandler: PropTypes.func,
  hideCancelBtn: PropTypes.bool,
};
export default UserNavigationButtons;
