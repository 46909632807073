export const MODAL_CONTENT = {
  modal_title: 'Add participants to project',
  first_field_label: 'Participant(s) to add',
  first_field_placeholder: 'Type participants name and select to add',
  first_field_required_error: 'A participant is required to add to a project.',
  second_field_label: 'Project name',
  second_field_placeholder: 'Type project name and select to add',
  second_field_required_error:
    'A project selection is required to add participant(s).',
  participant_exists_in_project_error:
    '1 or more participant(s) are already added to the selected project. Please remove participant and try again. ',
  submit_btn_label: 'Add to project',
  cancel_btn_label: 'Cancel',
};

export const errorInitialState = {
  participantRequiredError: false,
  projectRequiredError: false,
  participantExistsInprojectError: false,
};
