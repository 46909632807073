import { useActivityContext } from 'containers/ActivityListContainer';
import {
  EditPipelineAcres,
  AddPipelineAcres,
} from '../ActivityMenuButton/ContractButtons.react';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { PAGE_CONTENT } from 'pages/Origination/Org_Project/Participants/ParticipantActivity/ParticipantActivity.content';

const OutreachMenuItems = () => {
  const { status, area } = useActivityContext();

  if (status === REPORTING_STATUS.not_started) {
    return <>{<AddPipelineAcres />}</>;
  } else if (
    status === REPORTING_STATUS.interested &&
    area >= 0 &&
    typeof area === 'number' &&
    area !== PAGE_CONTENT.interested_acreage
  ) {
    return <>{<EditPipelineAcres />}</>;
  }

  return (
    <>
      {status === REPORTING_STATUS.contract_added &&
        area &&
        area !== PAGE_CONTENT.interested_acreage && <EditPipelineAcres />}
      {status === REPORTING_STATUS.contract_added &&
        area &&
        area === PAGE_CONTENT.interested_acreage && <AddPipelineAcres />}
    </>
  );
};
export default OutreachMenuItems;
