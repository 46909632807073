import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { COOL_GRAY, WHITE } from 'theme/GlobalColors';

export const DiabledChip = styled(Chip)(() => ({
  borderRadius: '0.25rem',
  color: COOL_GRAY,
  backgroundColor: WHITE,
  fontSize: '0.688rem',
  border: `0.5px solid ${COOL_GRAY}`,
  height: '1.25rem',
  marginLeft: '0.5rem',
}));
