import * as React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText } from '@mui/material';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { useLocation, useNavigate } from 'react-router-dom';

const WarningModal = ({
  link,
  children,
  previousstate,
  explicitRedirect,
  isEdited,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const redirectURL = (e) => {
    if (location.pathname.includes('edit')) {
      if (isEdited !== undefined && !isEdited) {
        navigate(link, previousstate);
      } else {
        handleClickOpen();
        e.preventDefault();
      }
    } else if (explicitRedirect) {
      navigate(link, previousstate);
    }
  };

  const onClick = (e) => redirectURL(e);

  return (
    <>
      {React.cloneElement(children, { onClick })}
      <DialogBox
        dialogActions
        isOpen={open}
        onConfirm={() => navigate(link, previousstate)}
        onCancel={handleClose}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Confirm"
        title="The changes made to the form will not be saved">
        <DialogContentText>
          Are you sure you want to navigate away from this page ? <br />
          Any unsaved changes will be lost.
        </DialogContentText>
      </DialogBox>
    </>
  );
};

WarningModal.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node,
  previousstate: PropTypes.object,
  explicitRedirect: PropTypes.bool,
  isEdited: PropTypes.bool,
};

export default WarningModal;
