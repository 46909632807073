import React, { useEffect, useState } from 'react';
import InfoCard from './InfoCard.react';
import PropTypes from 'prop-types';
import { BoxWrapper, CardBoxStyle } from './InfoCard.style';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { CARD_CONTENT, InitialEnrolmentArray } from './CardBox.content';
import {
  checkTernaryCondition,
  getBooleanValueForNullOrUndefined,
} from 'utils/helper';

const CardBox = ({
  statusList,
  cardDetails,
  navigateHandler,
  setModuleName,
  participantType,
  setParticipantDetails,
  participantDetails,
}) => {
  const [cardDetailsArray, setCardDetailsArray] = useState([]);

  useEffect(() => {
    setCardDetailsArray(InitialEnrolmentArray(statusList, cardDetails));
  }, [cardDetails]);

  return (
    <CardBoxStyle>
      <BoxWrapper gap="0.5rem" direction="column" alignment="start">
        <TextWrapper fontSize="1.25rem" color={DARK_CHARCOAL} fontWeight={700}>
          {checkTernaryCondition(
            !getBooleanValueForNullOrUndefined(
              cardDetails.activityTabContractLevelList,
            ),
            cardDetails.activityTabContractLevelList[0]?.projectName,
            '',
          )}
        </TextWrapper>
        <TextWrapper color={DARK_CHARCOAL} fontSize="0.875rem">
          {CARD_CONTENT.reporting_date}{' '}
          {checkTernaryCondition(
            !getBooleanValueForNullOrUndefined(
              cardDetails.activityTabContractLevelList,
            ),
            cardDetails?.activityTabContractLevelList[0]?.contractStartDate,
            '',
          )}
        </TextWrapper>
      </BoxWrapper>
      <BoxWrapper gap="2.5rem">
        {cardDetailsArray.map((item, index) => {
          const keyVal = index;
          return (
            <InfoCard
              key={keyVal}
              icon={item.icon}
              title={item.title}
              content={item.content}
              status={item.status}
              hasLinkForEnrolment={item.hasLinkForEnrolment}
              navigateHandler={navigateHandler}
              participantId={item.participantId}
              setModuleName={setModuleName}
              enrolmentList={checkTernaryCondition(
                !getBooleanValueForNullOrUndefined(
                  cardDetails.activityTabContractLevelList,
                ),
                cardDetails?.activityTabContractLevelList[0]?.activityTabActivitiesList?.filter(
                  (item) => item.activityType === CARD_CONTENT.enrollment,
                ) || [],
                [],
              )}
              projectId={item.projectId}
              instanceId={item.instanceId}
              participantType={participantType}
              setParticipantDetails={setParticipantDetails}
              participantDetails={participantDetails}
              projectCycleId={item.projectCycleId}
              projectName={checkTernaryCondition(
                !getBooleanValueForNullOrUndefined(
                  cardDetails.activityTabContractLevelList,
                ),
                cardDetails.activityTabContractLevelList[0]?.projectName,
                '',
              )}
              statusList={statusList}
            />
          );
        })}
      </BoxWrapper>
    </CardBoxStyle>
  );
};

CardBox.propTypes = {
  statusList: PropTypes.array,
  cardDetails: PropTypes.object,
  navigateHandler: PropTypes.func,
};

export default CardBox;
