import styled from 'styled-components';
import { COOL_GRAY, DARK_CHARCOAL } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const NotificationIconStyle = {
  marginRight: '1.5rem',
};

export const NotificationMenu = {
  marginTop: '45px',
};

export const FailCase = {
  color: '#DA291C',
  fontSize: 'small',
  marginRight: '0.5rem',
};

export const SuccessCase = {
  color: '#86BC25',
  fontSize: 'small',
  marginRight: '0.5rem',
};

export const DismissAllButton = {
  textDecoration: 'none',
  fontSize: '0.9rem',
  float: 'right',
  color: '#26890D',
  margin: '0.5rem 0.5rem 0.2rem 0rem',
  textTransform: 'none',
};

export const MenuWrapper = styled.div`
  padding: 0rem 0.5rem;
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.125rem;
  width: 19rem;
`;

export const IconHeaderWrapper = styled.div`
  display: flex;
  gap: 0.125rem;
  align-items: center;
`;

export const IconSx = (color = COOL_GRAY) => {
  return {
    fontSize: '1rem',
    color: color,
  };
};

export const PointerSx = {
  cursor: 'pointer',
};

export const DateTimeWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.2px;
  color: ${COOL_GRAY};
`;

export const TextWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  line-height: 1rem;
  letter-spacing: 0.2px;
  color: ${({ color }) => color || DARK_CHARCOAL};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  padding-left: 1.125rem;
  width: 17.875rem;
  flex-direction: column;
`;

export const NoNotifDiv = styled.div`
  width: 442px;
  font-style: italic;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ isError }) =>
    checkTernaryCondition(isError, 'space-between', 'flex-end')};
  gap: 0.5rem;
  padding-left: 1.125rem;
`;

export const downloadBtnSx = {
  padding: '0.375rem 0.5rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1rem',
  letterSpacing: '0rem',
  display: 'flex',
  gap: '0.25rem',
  height: 'fit-content',
};
