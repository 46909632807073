import PropTypes from 'prop-types';
import { Container, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useStyles } from '../../../theme/GlobalStyles';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import {
  breadcrumbStyles,
  breadcrumbSx,
  linkStyles,
  projectNameStyle,
  TabPanelStyle,
} from './Org_Projects.style';
import ListParticipants from './Participants/ListParticipants';
import OriginationSummaryTab from '../OriginationSummaryTab/OriginationSummaryTab.react';
import axios from 'axios';
import {
  DELETE_ORIGINATION_PROJECT,
  FETCH_VIEW_ORIGINATION_PROJECT,
} from 'urls';
import {
  TABS_TITLES,
  VIEW_ORIGINATION_PROJECT_CONTENT,
} from './Org_Projects.content';
import {
  displayToast,
  ORIGINATION_LIST_CONTENT,
} from 'pages/OriginationProjectList/OriginationProjectList.content';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import {
  checkAccess,
  checkTernaryCondition,
  hasAnyRequiredAccess,
} from 'utils/helper';
import OriginationProjectReportsList from '../OriginationProjectReportsList/OriginationProjectReportsList.react';
import { NewColorThemeProvider } from 'contextAPI/newColorThemeContext';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { DIALOG_CONTENT } from 'components/DeleteModal/DeleteModal.content';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ORIGINATION_PROJECT_CALCULATIONS_READ,
  ORIGINATION_PROJECT_DELETE,
  ORIGINATION_PROJECT_UPDATE,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import ProjectCalculationReport from '../ProjectCalculationReport/ProjectCalculationReport.react';
import { ProjectCalculationProvider } from 'contextAPI/projectCalculationContext';
import ActivitiesTab from './Participants/ParticipantTabs/ActivitesTab/ActivitiesTab.react';
import { PARTICIPANT_PARENT_PATH } from './Participants/Participants.content';
import ProjectSettingsTab from './ProjectSettingsTab/ProjectSettingsTab.react';

const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `origination-page-tab-${index}`,
  };
};

const Org_Project = ({ testFlag, testDesc }) => {
  const { TabberBox } = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [originationProjectDetails, setOriginationProjectDetails] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { permissions, userRoles } = useContext(userAuthRolesContext);

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (state?.from === PARTICIPANT_PARENT_PATH) {
      setCurrentTab(1);
    }
  }, [state]);

  const fetchProjectDetails = () => {
    axios.get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${id}`).then((response) => {
      setOriginationProjectDetails(response.data);
    });
  };

  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const deleteProject = () => {
    setIsDialogOpen(false);
    axios
      .delete(`${DELETE_ORIGINATION_PROJECT}/${id}`)
      .then((response) => {
        if (ORIGINATION_LIST_CONTENT.success.includes(response?.status)) {
          displayToast(
            'success',
            `${originationProjectDetails?.projectName} ${ORIGINATION_LIST_CONTENT.success_message}`,
          );
          navigate(-1);
        } else {
          displayToast(
            'error',
            `${originationProjectDetails?.projectName} ${ORIGINATION_LIST_CONTENT.error_message}`,
          );
        }
      })
      .catch(() => {
        displayToast(
          'error',
          `${originationProjectDetails?.projectName} ${ORIGINATION_LIST_CONTENT.error_message}`,
        );
      });
  };

  const breadcrumbs = useMemo(
    () => [
      <Link
        key="breadcrumb-1"
        to={pathName.originationProject.view}
        style={{ ...breadcrumbStyles, ...linkStyles }}>
        {VIEW_ORIGINATION_PROJECT_CONTENT.projects}
      </Link>,
      <div
        key="breadcrumb-2"
        style={projectNameStyle}
        title={originationProjectDetails?.projectName}>
        {' '}
        <Typography noWrap sx={breadcrumbStyles} color="text.primary">
          {originationProjectDetails?.projectName}
        </Typography>
      </div>,
    ],
    [originationProjectDetails],
  );

  const projectsTabs = [
    {
      title: TABS_TITLES.summary,
      component: (
        <OriginationSummaryTab
          originationProjectDetails={originationProjectDetails}
          refreshHandler={fetchProjectDetails}
          projectId={id}
          testFlag={testFlag}
          testDesc={testDesc}
        />
      ),
      isDisabled: false,
    },
    {
      title: `${TABS_TITLES.participants} (${
        originationProjectDetails?.numOfParticipants || 0
      })`,
      component: (
        <ListParticipants
          refreshHandler={fetchProjectDetails}
          disableVirtualization={false}
          projectName={originationProjectDetails?.projectName}
        />
      ),
      isDisabled: false,
    },
    {
      title: TABS_TITLES.calculations,
      component: (
        <ProjectCalculationProvider>
          <ProjectCalculationReport projectId={id} />
        </ProjectCalculationProvider>
      ),
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: TABS_TITLES.soilSampling,
      component: <ActivitiesTab projectId={id}></ActivitiesTab>,
      isDisabled: hasAnyRequiredAccess(userRoles, [
        PARTICIPANT,
        PARTICIPANT_CONTRIBUTOR,
      ]),
    },
    {
      title: TABS_TITLES.settings,
      component: <ProjectSettingsTab />,
    },
  ];

  return (
    <Container maxWidth={false}>
      <SummaryViewHeader
        noBackButton
        title={
          <Breadcrumbs
            sx={breadcrumbSx}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        }
        previouspath={
          state?.previousPath
            ? state?.previousPath
            : pathName.originationProject.view
        }
        previousstate={
          state?.previousstate
            ? state?.previousstate
            : { state: pathName.originationProject.view }
        }
        status={checkTernaryCondition(
          originationProjectDetails && originationProjectDetails.isActive === 1,
          'Active',
          '',
        )}
        actions={[
          {
            itemLabel: 'Edit',
            disabled:
              permissions.length !== 0 &&
              !checkAccess(permissions, ORIGINATION_PROJECT_UPDATE),
            clickHandler: () => {
              navigate(`${pathName.originationProject.edit}/${id}`, {
                state: {
                  previousPath: `${pathName.originationProject.view}/${id}`,
                },
              });
            },
          },
          {
            itemLabel: 'Delete',
            disabled:
              permissions.length !== 0 &&
              !checkAccess(permissions, ORIGINATION_PROJECT_DELETE),
            clickHandler: () => setIsDialogOpen(true),
          },
        ]}
      />
      <Box
        sx={{
          marginBottom: checkTernaryCondition(currentTab === 0, '0px', '1rem'),
        }}>
        <TabberBox>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="page-tabs">
            {projectsTabs.map((tab, index) => (
              <Tab
                sx={{ textTransform: 'none', width: 'unset' }}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={tab.title}
                disabled={tab.isDisabled}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
      {projectsTabs.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={TabPanelStyle}>
          {tab.component}
        </TabPanel>
      ))}

      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={deleteProject}
        subtitle={`${DIALOG_CONTENT.delete_message?.slice(0, 32)}${
          originationProjectDetails?.projectName
        }${DIALOG_CONTENT.delete_message?.slice(31)}`}
        onCancel={() => setIsDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        title={`${DIALOG_CONTENT.delete} ${originationProjectDetails?.projectName}`}></DialogBox>
    </Container>
  );
};

Org_Project.propTypes = {
  testFlag: PropTypes.bool,
  testDesc: PropTypes.string,
};
export default Org_Project;
