import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GREEN,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
  QUILL_GRAY,
  RED,
  WHITE,
} from 'theme/GlobalColors';

export const FormSection = styled.div`
  height: fit-content;
  padding: 1.5rem;
  box-sizing: border-box;
  overflow: auto;
  max-height: 39rem;
  background-color: ${WHITE};
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;
export const MainWrapper = styled.div`
  width: 552px;
`;

export const FormHeading = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  display: flex;
  justify-content: space-between;
`;
export const SubsectionWrapper = styled.div`
  margin-left: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 508px;
`;
export const FlexWrapper = styled.div`
  margin-left: 0.75rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;
export const EachFundingWrapper = styled.div`
  display: block;
`;
export const AcerageWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const generalFarmButtonSx = {
  textTransform: 'none',
  backgroundColor: DARK_GREEN,
  '&:hover': {
    backgroundColor: DARK_GREEN,
  },
};
export const fundingSx = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      marginTop: '0.1rem',
      width: '40%',
      marginBottom: '-0.1rem',
    },
  };
};

export const disableFormStyle = {
  opacity: '0.5',
  pointerEvents: 'none',
};

export const NameSectionWrapper = styled.div`
  display: flex;
  grid-column-gap: 1rem;
  width: 100%;
`;

export const LocationSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
`;

export const USDAStatusLabelSx = {
  '& p': {
    width: '100%',
  },
};

export const LivestockTypeWrapper = styled.div`
  display: flex;
  grid-column-gap: 1rem;
`;

export const LivestockTypeLabelWrapper = styled.div`
  display: inline-flex;
  grid-column-gap: 1rem;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1rem;
  color: ${RED};
  letter-spacing: 0.013rem;
  margin-bottom: 0.625rem;
`;

export const TextWrapper = styled.div`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding-left: ${({ paddingLeft }) => paddingLeft};
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding-top: 0.35rem;
`;

export const InputWithDeleteIconWrapper = styled.div`
  display: flex;
  grid-column-gap: 0.5rem;
`;
export const dropdownInputSx = {
  '& .MuiInputBase-input': {
    padding: '0.25rem 2rem 0.25rem 0.5rem',
  },
};
export const outlinedDropdownInputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '0.25rem 2rem 0.25rem 0.5rem',
  },
};
export const radioBtnPadding = '0rem 0.5rem 0rem 0.563rem';
export const OwnerDisclaimer = styled.div`
  display: flex;
  border: 1px solid ${NEW_DARK_GREEN};
  padding: 0.5rem;
  gap: 0.25rem;
  border-radius: 0.125rem 0rem 0rem 0rem;
  opacity: 0px;
  margin-bottom: 1rem;
`;

export const OwnerDisclaimerText = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 19.6px;
  width: 32rem;
`;
export const closeIconSx = {
  cursor: 'pointer',
  alignSelf: 'flex-start',
  color: COOL_GRAY,
};
export const CloseLink = styled.span`
  color: ${GLOBAL_BLUE};
  cursor: pointer;
`;
