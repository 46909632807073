import DialogBox from 'components/DialogBox/DialogBox.react';
import { useState } from 'react';
import { DialogWrapper } from './ReadMoreDisplayBox.style';
import { ACCESSIBLE_BLUE_URLS } from 'theme/GlobalColors';
const DisplayContent = (props) => {
  const { content, title } = props;
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: ' 0 0.5rem',
        }}>
        <div
          className="content"
          style={{
            maxHeight: '110px',
            overflow: 'hidden',
          }}>
          <p
            style={{
              display: 'flex',
            }}>
            {content.split(' ').splice(0, 70).join(' ')}
            {content.split(' ').length > 70 ? '...' : ''}
          </p>
        </div>
        {content.length > 200 && !showContent && (
          <div
            style={{
              color: ACCESSIBLE_BLUE_URLS,
              cursor: 'pointer',
              display: 'flex',
              fontSize: '12px',
              justifyContent: 'center',
            }}
            onClick={() => setShowContent(!showContent)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                setShowContent(!showContent);
              }
            }}>
            Show more
          </div>
        )}
      </div>
      <DialogBox
        title={title}
        isOpen={showContent}
        onConfirm={() => setShowContent(false)}
        onCancel={() => setShowContent(false)}
        acceptCtnLabel="close"
        dialogActions>
        <DialogWrapper>{content}</DialogWrapper>
      </DialogBox>
    </>
  );
};
export default DisplayContent;
