import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { checkTernaryCondition } from 'utils/helper';
import { participantEnrolmentRoutes } from './Layout.constants';
import MainLayout from './MainLayout.react';
import ParticipantLayout from './ParticipantLayout.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import axios from 'axios';
import { UPDATE_LAST_ACTIVITY } from 'urls';
import { Backdrop, CircularProgress } from '@mui/material';

const Layout = () => {
  const [isParticipantRoute, setIsParticipantRoute] = useState(false);
  const location = useLocation();
  const { setIsParticipantPortalAccessDenied, isDataFetched } =
    useContext(userAuthRolesContext);
  useEffect(() => {
    const checkRoute = participantEnrolmentRoutes.includes(location.pathname);
    setIsParticipantRoute(checkRoute);
    setIsParticipantPortalAccessDenied(checkRoute);
  }, [location.pathname]);

  useEffect(() => {
    axios.post(UPDATE_LAST_ACTIVITY);
  }, []);

  if (!isDataFetched) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>
        <CircularProgress color="success" />
      </Backdrop>
    );
  } else {
    return (
      <>
        {checkTernaryCondition(
          !isParticipantRoute,
          <MainLayout />,
          <ParticipantLayout />,
        )}
      </>
    );
  }
};

export default Layout;
