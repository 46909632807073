import {
  BreadcrumbItem,
  HeaderWrapper,
  MainSectionWrapper,
  MainWrapper,
  MenuWrapper,
  NavigateIconStyle,
  ParticipantInfoWrapper,
  ProjectNameWrapper,
  SectionWrapper,
} from './ParticipantDataCollection.style';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  breadcrumbData,
  PAGE_CONTENT,
} from './ParticipantDataCollection.content';
import { pathName } from 'Routes/routes.const';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { checkTernaryCondition } from 'utils/helper';
import { TruncatedWrapper } from 'theme/GlobalStyles';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import EnrollmentCompleteModal from './components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { TabPanelStyle } from 'pages/Origination/Org_Project/Org_Projects.style';

const ParticipantDataCollection = () => {
  const {
    sectionList,
    rightSectionId,
    openMapView,
    showFieldInfoForm,
    farmInfoCurrentTab,
    setRightSectionId,
    currentFarmId,
    setCurrentFarmId,
    setExpandedFarmId,
    setFarmInfoCurrentTab,
    setShowFieldInfoForm,
    userActivityDetected,
    modalData,
    setModalData,
    setSelectedFieldId,
    setCurrentFarmLocation,
    hasNotApprovedFarmOrFields,
    setSelectedFieldAddType,
    isParticipantContributor,
    farmAndContributorInfo,
    participantProfileStatus,
    farmInfo,
    setStatusList,
    farmListStatus,
    setFarmListStatus,
  } = useContext(participantInfoContext);
  const [rightSectionForm, setRightSectionForm] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [finalList, setFinalList] = useState([]);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');

  /**
   * @description Using the flag 'userActivityDetected' we are tracking if there was a change in the web forms, if there is a change we add a 'beforeunload' event which gets triggered when user tries to close the browser window/tab, it provides a warning to the user to save changes before closing the browser.
   */
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );

  useEffect(() => {
    if (rightSectionId) {
      setRightSectionForm(
        sectionList?.filter((item) => {
          return item.id === rightSectionId;
        }),
      );
    }
  }, [sectionList, rightSectionId]);

  useEffect(() => {
    setFarmListStatus(sectionList[1]?.leftSection?.props?.fieldData[0]?.status);
  }, [sectionList]);

  useEffect(() => {
    let finalData = [participantProfileStatus, farmListStatus];
    farmInfo.map((farm) =>
      farm.fields.length === 0
        ? finalData.push('In Progress')
        : farm?.fields.map((field) => finalData.push(field?.status)),
    );
    setFinalList(finalData);
    if (finalData.every((element) => element === PAGE_CONTENT.completed)) {
      setStatusList(PAGE_CONTENT.completed);
    } else {
      setStatusList(PAGE_CONTENT.in_progress);
    }
  }, [farmInfo, participantProfileStatus, farmListStatus]);

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.menuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          enrollmentInstanceId: enrollmentInstanceId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: false } },
    );
  };

  const getHandler = (breadcrumbTitle) => {
    switch (breadcrumbTitle) {
      case breadcrumbData[0].title: {
        return () =>
          navigate(
            `${pathName.participant.menuPage}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${enrollmentInstanceId}&projectcycleid=${projectCycleId}`,
          );
      }
      case breadcrumbData[1].title: {
        return () => {
          setRightSectionId(1);
          setCurrentFarmId(-1);
          setExpandedFarmId(-1);
          setFarmInfoCurrentTab(0);
          setShowFieldInfoForm(false);
        };
      }
      case breadcrumbData[2].title: {
        return () => {
          setFarmInfoCurrentTab(0);
          setShowFieldInfoForm(false);
        };
      }
      case breadcrumbData[3].title: {
        return () => {
          setFarmInfoCurrentTab(1);
          setShowFieldInfoForm(false);
        };
      }
      case breadcrumbData[4].title: {
        return () => {};
      }
      default:
        return () => null;
    }
  };

  const constructBreadcrumbs = (breadcrumbList) => {
    const listLength = breadcrumbList?.length;
    const breadcrumbsWithHandlers = breadcrumbList.map((breadcrumb, index) => {
      const isLastItem = index === listLength - 1;
      return (
        <BreadcrumbItem
          key={breadcrumb.id}
          lastItem={isLastItem}
          onClick={checkTernaryCondition(
            isLastItem,
            null,
            getHandler(breadcrumb.title),
          )}>
          {breadcrumb.title}
        </BreadcrumbItem>
      );
    });

    setBreadcrumbs(breadcrumbsWithHandlers);
  };

  useEffect(() => {
    let filteredBreadcrumbData = breadcrumbData;
    if (isParticipantContributor) {
      filteredBreadcrumbData = filteredBreadcrumbData.filter(
        (item) => item.title !== breadcrumbData[1].title,
      );
    }
    if (rightSectionId === 1) {
      constructBreadcrumbs(filteredBreadcrumbData.slice(0, -3));
    } else if (rightSectionId === 2) {
      if (showFieldInfoForm) {
        constructBreadcrumbs(
          filteredBreadcrumbData.filter(
            (item) => item.title !== breadcrumbData[3].title,
          ),
        );
      } else if (farmInfoCurrentTab === 0) {
        constructBreadcrumbs(filteredBreadcrumbData.slice(0, -2));
      } else if (farmInfoCurrentTab === 1) {
        constructBreadcrumbs(filteredBreadcrumbData.slice(0, -1));
      }
    }
  }, [rightSectionId, showFieldInfoForm, farmInfoCurrentTab]);

  // Note: reset the modalData flag to false since hasNotApprovedFarmOrFields can be set anytime when the user adds a field/farm even though not completed, the modal may open up since modalData.flag will be true and hasNotApprovedFarmOrFields will be true
  useEffect(() => {
    if (!hasNotApprovedFarmOrFields && modalData?.flag) {
      setModalData({ flag: false, id: 0 });
    }
    if (
      !isParticipantContributor &&
      farmAndContributorInfo[0].status !== PAGE_CONTENT.completed &&
      modalData?.flag
    ) {
      setModalData({ flag: false, id: 0 });
    }
  }, [hasNotApprovedFarmOrFields, modalData]);

  /**
   * @description While user is drawing a boundary on the map, if user presses 'Delete' or 'Escape' key, we are not breaking the flow of "Draw boundary" by setting fieldAddType to be null. This state will be checked before adding a new boundary through "Draw boundary" method
   */
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' || e.key === 'Escape') {
        setSelectedFieldAddType(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const isFarmAndContributorCompleted = checkTernaryCondition(
    isParticipantContributor,
    true,
    farmAndContributorInfo[0].status === PAGE_CONTENT.completed,
  );

  return (
    <MainWrapper>
      <HeaderWrapper>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={NavigateIconStyle} />
          }
          aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <ProjectNameWrapper>
          <TruncatedWrapper title={state?.projectName} width="92%">
            {state?.projectName}
          </TruncatedWrapper>{' '}
          <span>{PAGE_CONTENT.enrollmentLabel}</span>
        </ProjectNameWrapper>
      </HeaderWrapper>
      <MainSectionWrapper>
        <ParticipantInfoWrapper>
          <MenuWrapper style={TabPanelStyle}>
            {sectionList?.map((item) => {
              return item.leftSection;
            })}
          </MenuWrapper>
          <SectionWrapper mapView={openMapView}>
            {rightSectionForm[0]?.rightSection}
          </SectionWrapper>
        </ParticipantInfoWrapper>
      </MainSectionWrapper>
      <EnrollmentCompleteModal
        modalData={{
          ...modalData,
          // show modal only if hasNotApprovedFarmOrFields is also true
          flag:
            modalData.flag &&
            hasNotApprovedFarmOrFields &&
            isFarmAndContributorCompleted,
        }}
        modalCloseHandler={() => {
          setModalData({ flag: false, id: 0 });
        }}
        title={PAGE_CONTENT.submit_complete_modal_title}
        contentText={PAGE_CONTENT.submit_complete_modal_content}
        firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
        secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
        cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
        firstSubmitBtnHandler={() => {
          setSelectedFieldId(null);
          setShowFieldInfoForm(false);
          setFarmInfoCurrentTab(1);
          setCurrentFarmLocation(currentFarmId);
          setModalData({ flag: false, id: 0 });
        }}
        secondSubmitBtnHandler={navigateHandler}
        cancelHandler={() => {
          setModalData({ flag: false, id: 0 });
        }}
      />
    </MainWrapper>
  );
};

export default ParticipantDataCollection;
