export const TABLE_CONTENT = {
  field_name_label: 'Field Name',
  acreage: 'Area',
  activities: 'Activities',
  deltaLabel: 'Delta',
  baselineLabel: 'Baseline',
  activityLabel: 'Activity',
  dummyFarmName: 'Green Acres',
  emptyNotation: '--',
  acres: 'Acres',
  MTCO2_label: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
  actualAcres: 'Actual acres',
  plannedAcres: 'Planned acres',
  deltaTooltipContent:
    'Metric tons (MT) of carbon dioxide equivalents (CO2e) represents net greenhouse gas (GHG) reductions and removals to date.',
  baselineTooltipContent:
    'Metric tons (MT) of carbon dioxide equivalents (CO2e) represents greenhouse gas (GHG) remissions.',
};
