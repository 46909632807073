import styled from 'styled-components';

const MAIN_FONT_COLOR = '#333333';
const BORDER_COLOR = '#D0D0CE';
const SUBTEXT_COLOR = `#63666A`;
const REMOVE_PAYMENT_COLOR = '#0076A8';
const ERROR_MESSAGE_COLOR = '#DA291C';

export const VintageWapper = styled.div`
  border-top: ${(props) =>
    props.isFirstChild ? `1px solid ${BORDER_COLOR}` : ''};
  margin-bottom: 1rem;
`;

export const FormFields = styled.div`
  margin-bottom: ${(marginBottom) => (marginBottom ? marginBottom : '1rem')};
  height: fit-content;

  input {
    padding-left: 0.625rem;
  }
`;
export const FormPriceFields = styled.div`
  margin-bottom: 1rem;
  height: fit-content;

  input {
    padding-left: ${(props) => (props.isEmpty ? '0rem' : '0.625rem')};
  }

  .MuiInputAdornment-root {
    margin-right: 0px;
    margin-left: ${(props) => (!props.isEmpty ? '0rem' : '0.625rem')};
  }
`;

export const FormLabels = styled.p`
  margin-bottom: 0.625rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(props) => props.iserror && ERROR_MESSAGE_COLOR};
`;

export const DropDownPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${MAIN_FONT_COLOR};
`;

export const InputSubtext = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${SUBTEXT_COLOR};
  opacity: 0.5;
  margin-left: 1rem;
`;

export const RemovePaymentLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${REMOVE_PAYMENT_COLOR};
  margin-left: 1.5rem;
  cursor: pointer;
`;
export const ErroWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  padding-left: 0.3rem;
  color: ${ERROR_MESSAGE_COLOR};
`;

export const RadioButtonlabel = styled.span`
  margin-left: 0.5rem;
  margin-right: 5.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
`;
export const radioBtnSx = {
  width: '1.125rem',
  height: '1.125rem',
  '& .MuiSvgIcon-root': { width: '1.125rem', height: '1.125rem' },
};

export const OptionalLabel = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.013rem;
  color: ${SUBTEXT_COLOR};
  opacity: 0.5;
  margin-left: 0.5rem;
`;

export const inputNumberSx = {
  width: '8.125rem',
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  '&.MuiInputBase-root': {
    backgroundColor: 'white',
  },
};

export const AddDepositsWrapper = styled.div`
  cursor: pointer;
`;
export const AddDepositsLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #0076a8;
  margin-left: 0.375rem;
  cursor: pointer;
`;
export const DeliveryLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VintageSubWrapper = styled.div`
  display: flex;
  grid-column-gap: 2rem;
`;

export const VintageDropdownSx = {
  width: '8.125rem',
  height: '2rem',
  backgroundColor: 'white',
};

export const vintageMenuSx = {
  PaperProps: {
    sx: {
      maxHeight: '9.2rem',
    },
  },
};

export const VintageLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  padding-top: 0.5rem;
`;
