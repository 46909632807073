import { checkTernaryCondition } from 'utils/helper';
import { TabsStyle } from './BaseLineReportingMainPage.style';
import { ReactComponent as YellowStatus } from '../../../assets/icons/yellowStatus.svg';
import { ReactComponent as GreenStatus } from '../../../assets/icons/greenStatus.svg';
import { ReactComponent as NotStartedStatus } from '../../../assets/icons/notStartedIcon.svg';
import { RED } from 'theme/GlobalColors';

export const PAGE_CONTENT = {
  cash_crop: 'Cash crop',
  completed: 'Completed',
  in_progress: 'In Progress',
  save_continue: 'Save and continue',
  save_close: 'Save and close',
  cancel: 'Cancel',
  baseline_field_heading:
    'Please provide details on crop production / management practices for each year of the operations data.',
  baseline_farm_heading:
    ' Please provide details on tractor / vehicle fuel consumption for each year of the operations data.',
  baseline_star_end_tooltip:
    'Baseline period determined based on planned project activity implementation dates and must be a minimum of 3 years.',
  delete_modal_first_message: 'Are you sure you want to delete',
  delete_modal_second_message: 'Any data entered will be lost.',
  EP: 'EP',
  EN: 'EN',
  edge_of_field: '+ Edge of field',
  edge_of_field_label: 'Edge of field',
  submit_complete_modal_title: 'All field baseline reporting is complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to edit fields that are complete.',
  continue_btn_label: 'Continue to edit farms / fields',
  review_btn_label: 'Review and submit',
  cancel_btn_label: 'Cancel',
  baseline_heading: '- Operations data',
  left_section_header: 'Farm and fields',
  farm: 'Farm',
  crop: 'Crop',
  empty_farm_label: 'Tractors / vehicles fuel (0)',
  empty_crop_label: 'Crop (0)',
  copy_button_label_tractors: 'Copy saved tractor / vehicle',
  copy_button_label_crop: 'Copy saved field crop',
  complete: 'Complete',
  add_cash_crop_button: 'Crop',
  tractor_and_vehicle: 'Tractor / vehicle fuel',
  cash_crop_label: 'Crop',
  tractor_and_vehicle_add_btn: '+ Tractors / vehicles fuel',
  cash_crop_add_btn: '+ Optional practice',
  delete_heading_first_part: 'Delete',
  delete_content_firt_part: 'Are you sure you want to delete',
  delete_content_second_part: '? Any data entered will be lost.',
  acre: 'acre',
  bushels_acre: 'bushels',
  practices_count: 'practices',
  field: 'field',
  baseline_reporting: 'Baseline reporting',
  baseline_reporting_for_project: 'Baseline Reporting',
  home: 'Home',
  fieldCropCopyModalTitle: 'Copy field crop',
  tractorOrVehicleCopyModalTitle: 'Copy tractors / vehicles',
  copy: 'Copy',
  replaceModalContentPrefix: 'Are you sure you want to copy ',
  replaceModalContentSuffix: '? All data entered will be replaced.',
  farmCopyDisclaimer:
    'Please review the copied tractors / vehicles fuel usage data as the copied values may require updating due to farm area variances.',
  cashCropCopyDisclaimer:
    'Please review the copied field data as the copied values may require updating due to field area variances.',
  unsavedChangesPromptMsg:
    "You've made updates that haven't been saved yet. Would you like to save before closing?",
  addTractorVehicleBtn: 'Add tractors / vehicles',
  baselineReportingType: 'Baseline',
};
export const tabsTitle = (year, status) => {
  return (
    <TabsStyle>
      {year}
      {getTabYearStatus(status)}
    </TabsStyle>
  );
};

export const getTabYearStatus = (status) => {
  switch (status) {
    case 'In Progress':
      return <YellowStatus />;
    case 'Completed':
      return <GreenStatus />;
    default:
      return <NotStartedStatus />;
  }
};

const isFieldWebformCheck = (getCurrentFarm, farm, isFarmWebform) => {
  return getCurrentFarm.farmId === farm.farmId && !isFarmWebform;
};

export const handleNextInProgrssSwitcher = (
  inProgressItem,
  updatedFarmInfo,
  getCurrentFarm,
  updatedFieldId,
  isFarmWebform,
  currentYearIndex,
) => {
  const currentFarmIndex = updatedFarmInfo.findIndex(
    (farm) => farm.farmId === getCurrentFarm.farmId,
  );
  const farms = updatedFarmInfo.filter((_, index) => index >= currentFarmIndex);
  for (const farm of farms) {
    const sortedFarmYears = farm.farmYearStatus.sort(
      (year1, year2) => year2.dateId - year1.dateId,
    );
    const yearList = checkTernaryCondition(
      getCurrentFarm.farmId === farm.farmId,
      sortedFarmYears.slice(currentYearIndex + 1),
      sortedFarmYears,
    );
    const inProgressYearIndex = yearList.findIndex(
      (year) =>
        year.formStatus?.toLowerCase() === 'not started' ||
        year.formStatus?.toLowerCase() === 'in progress',
    );
    let currentFieldIndex = -1;
    if (isFieldWebformCheck(getCurrentFarm, farm, isFarmWebform)) {
      currentFieldIndex = farm.fieldDetails.findIndex(
        (field) => field.fieldId === updatedFieldId.id,
      );
    }
    if (inProgressYearIndex !== -1 && currentFieldIndex === -1) {
      inProgressItem = {
        type: 'farm',
        id: farm.farmId,
        name: farm.farmName,
        yearIndex:
          inProgressYearIndex +
          checkTernaryCondition(
            getCurrentFarm.farmId === farm.farmId,
            currentYearIndex + 1,
            0,
          ),
      };
      break;
    }
    if (inProgressItem !== null) break;

    let fields = farm.fieldDetails.filter(
      (_, index) => index >= currentFieldIndex,
    );
    inProgressItem = handleInProgressFields(
      fields,
      farm,
      inProgressItem,
      currentYearIndex,
      updatedFieldId,
      isFarmWebform,
    );

    if (inProgressItem !== null) break;
  }
  return inProgressItem;
};

const handleInProgressFields = (
  fields,
  farm,
  inProgressItem,
  currentYearIndex,
  updatedFieldId,
  isFarmWebform,
) => {
  for (const field of fields) {
    if (field.mainStatus?.toLowerCase() === 'completed') continue;
    const sortedFieldYears = field.fieldYearStatus.sort(
      (year1, year2) => year2.dateId - year1.dateId,
    );
    const fieldYearList = checkTernaryCondition(
      updatedFieldId?.id === field.fieldId && !isFarmWebform,
      sortedFieldYears.slice(currentYearIndex + 1),
      sortedFieldYears,
    );
    const inProgressFieldYearIndex = fieldYearList.findIndex(
      (year) =>
        year.formStatus?.toLowerCase() === 'not started' ||
        year.formStatus?.toLowerCase() === 'in progress',
    );
    if (inProgressFieldYearIndex !== -1) {
      return {
        type: 'field',
        id: field.fieldId,
        name: field.fieldName,
        farmId: farm.farmId,
        farmName: farm.farmName,
        yearIndex:
          inProgressFieldYearIndex +
          checkTernaryCondition(
            updatedFieldId?.id === field.fieldId && !isFarmWebform,
            currentYearIndex + 1,
            0,
          ),
      };
    }
  }
  return inProgressItem;
};

export const datesLabelList = [
  'Date harvested',
  'Date planted',
  'Dates applied',
  'Tillage date(s)',
  'Date terminated',
];

export const dateFieldNames = [
  {
    name: 'datesApplied',
    type: 'list',
  },
  {
    name: 'cropHarvestDate',
    type: 'string',
  },
  {
    name: 'cropPlantDate',
    type: 'string',
  },
  {
    name: 'tillageDates',
    type: 'list',
  },
  {
    name: 'cropTerminateDate',
    type: 'string',
  },
];
const setValForEmptyValues = (val) => {
  if (val === null || val === undefined || val === '') {
    return '--';
  } else {
    return val;
  }
};
export const checkEmptyDates = (
  label,
  value,
  currentLabel,
  labelList,
  fieldStyle,
  isRenderForValue,
) => {
  const isDateEmpty =
    value === '' &&
    labelList.includes(currentLabel) &&
    !label.includes('Fallow');

  if (isRenderForValue) {
    return checkTernaryCondition(
      isDateEmpty,
      `${currentLabel} required.`,
      setValForEmptyValues(value),
    );
  } else {
    return checkTernaryCondition(
      isDateEmpty,
      { ...fieldStyle, color: RED },
      fieldStyle,
    );
  }
};

export const baselineInstanceStatus = {
  approved: 'APPROVED',
  notApproved: 'NOT_APPROVED',
};
