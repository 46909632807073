import React from 'react';
import { PAGE_CONTENT } from './Users.content';
import { useLocation } from 'react-router';
import BackButton from 'components/Backbutton/BackButton.react';

const Users = () => {
  const { state } = useLocation();
  return (
    <div>
      <BackButton
        previouspagename={
          state?.previouspagename
            ? state?.previouspagename
            : PAGE_CONTENT.portfolio
        }
        previouspath={
          state?.previousPath
            ? state?.previousPath
            : `/${PAGE_CONTENT.portfolio.toLowerCase()}`
        }
        previousstate={
          state?.previousstate
            ? state?.previousstate
            : { state: `${PAGE_CONTENT.portfolio.toLowerCase()}` }
        }
      />
      <b>{PAGE_CONTENT.user_page}</b>
    </div>
  );
};

export default Users;
