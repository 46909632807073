import { Typography } from '@mui/material';
import { SearchContext } from 'containers/ProjectSearchBox.react';
import React, { useContext } from 'react';

import styled from 'styled-components';

export const SeeAllResultButton = () => {
  const { fetchMoreResultsInTableMode, searchKeyword, isSeeMoreOptionEnabled } =
    useContext(SearchContext);
  return isSeeMoreOptionEnabled ? (
    <SeeMoreButton onClick={fetchMoreResultsInTableMode}>
      <Typography variant="body1" color="text.primary">
        Show all results for “<HighlightedText>{searchKeyword}</HighlightedText>
        ”
      </Typography>
    </SeeMoreButton>
  ) : null;
};

const SeeMoreButton = styled.button`
  padding: 0 1rem 1rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const HighlightedText = styled.span`
  font-weight: 700;
`;
