export const MULTIPLE_FILE_UPLOAD_CONSTANT = {
  browse_text: 'browse.',
};

export const minLimitError = (minLimit) =>
  `File size doesn't meet minimum of ${minLimit} allowed.`;

export const maxLimitError = (maxLimit) =>
  `File size exceeds maximum of ${maxLimit} allowed.`;

export const maxFilesError = (maxFiles) =>
  `No. of files can't exceed ${maxFiles}.`;

const formatBytes = (bytes) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * kilobyte;

  if (bytes < kilobyte) {
    return bytes + ' B';
  } else if (bytes < megabyte) {
    return bytes / kilobyte + ' KB';
  } else {
    return bytes / megabyte + ' MB';
  }
};

export const getError = (errorCode, maxLimit, fileNotSupportedText) => {
  switch (errorCode) {
    case 'file-too-large':
      return maxLimitError(formatBytes(maxLimit));
    case 'file-invalid-type':
      return fileNotSupportedText;
    default:
      return 'Error';
  }
};
