import {
  MainWrapper,
  BoxWrapper,
  AccessDeniedHeading,
  PermissionHeadingWrapper,
  PermissionTextWrapper,
} from './AccessDeniedPage.style';
import { ACCESS_DENIED_PAGE_CONTENT } from './AccessDeniedPage.content';

const AccessDeniedPageForParticipantContributor = ({ emailSupport }) => {
  return (
    <MainWrapper>
      <BoxWrapper>
        <AccessDeniedHeading>
          {ACCESS_DENIED_PAGE_CONTENT.access_denied_heading}
        </AccessDeniedHeading>
        <PermissionHeadingWrapper>
          {ACCESS_DENIED_PAGE_CONTENT.permission_heading}
        </PermissionHeadingWrapper>
        <PermissionTextWrapper>
          {`${ACCESS_DENIED_PAGE_CONTENT.participant_contributor_permission_text} ${emailSupport}.`}
        </PermissionTextWrapper>
      </BoxWrapper>
    </MainWrapper>
  );
};

export default AccessDeniedPageForParticipantContributor;
