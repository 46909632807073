import { useContext } from 'react';
import { CALCULATION_SUMMARY_CONTENT } from './CalculationSummaryComp.content';
import {
  DetailsContainer,
  Field,
  FieldTitle,
  FieldValue,
  HeadingText,
  MainWrapper,
} from './CalculationSummaryComp.style';
import { projectCalculationContext } from 'contextAPI/projectCalculationContext';

const CalculationSummaryComp = () => {
  const { calculationSummary } = useContext(projectCalculationContext);
  return (
    <MainWrapper>
      <HeadingText>
        {CALCULATION_SUMMARY_CONTENT.calculation_summary}
      </HeadingText>
      <DetailsContainer>
        {calculationSummary.map((item) => (
          <Field key={item.label} minWidth={'11.25rem'}>
            <FieldTitle>{item.label}</FieldTitle>
            <FieldValue>{item.value || '--'}</FieldValue>
          </Field>
        ))}
      </DetailsContainer>
    </MainWrapper>
  );
};

export default CalculationSummaryComp;
