import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  QUILL_GRAY,
  EMPTY_STATE_TEXT_COLOR,
} from 'theme/GlobalColors';

export const TextWrapper = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  line-height: 140%;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: ${({ color }) => color};
  align-self: left;
`;
export const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap};
  align-items: baseline;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  padding: ${({ padding }) => padding || '0.5rem'};
`;
export const TagsWrapper = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.25rem;
  color: ${({ color }) => color};
  padding: 0.15rem 0.25rem;
  font-weight: 400;
  font-size: 0.6875rem;
  max-width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-self: center;
`;
export const ChartsWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  height: fit-content;
`;
export const RegnFarmWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${QUILL_GRAY};
`;
export const SubContainer = styled.div`
  width: ${({ width }) => width || '49.5%'};
  height: ${({ height }) => height};
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #d0d0ce')};
  border-radius: 2px;
  min-width: ${({ minWidth }) => minWidth};
  border-right: ${({ onlyBorderRight }) =>
    onlyBorderRight && '1px solid #d0d0ce'};
`;

export const ParticipantBarChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 92%;
  padding: 1rem;
  background-color: white;
`;

export const RegnFarmParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0ce;
`;
export const TitleWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-left: 0.5rem;
`;

export const EmptyDataWrapper = styled.div`
  width: 100%;
  height: 26.063rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const EmptyDataContent = styled.div`
  max-width: 25rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${COOL_GRAY};
`;

export const EmptyDataLabel = styled.span`
  max-width: 80rem;
  text-align: center;
  position: relative;
  left: -15%;
  font-weight: 600;
  color: ${COOL_GRAY};
`;

export const AccordianSx = {
  borderRadius: '2px',
  '.MuiAccordionSummary-root': {
    '&.Mui-expanded': { minHeight: '3rem' },
  },
};

export const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  font-family: Open Sans;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
  top: -43%;
  left: 2%;
  color: ${DARK_CHARCOAL};
`;

export const AccordianSummarySx = {
  borderBottom: '1px solid #D0D0CE',
  height: '3rem',
  boxSizing: 'border-box',
  padding: '0.5rem 1rem',
};

export const AccordianDetailsSx = {
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
};

export const StyledSpan = styled.span`
  font-size: 12px;
  color: ${COOL_GRAY};
`;

export const StyledDiv = styled.div`
  display: flex;
  gap: 2px;
`;

export const StyledAcre = styled.span`
  flex: 1;
  text-align: right;
  white-space: nowrap;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
`;

export const ProjectNameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
  color: ${DARK_CHARCOAL};
`;

export const ProjectLinkNameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
`;

export const ToolTipIconSx = (verticalAlign, marginLeft) => {
  return {
    height: '1rem',
    width: '1rem',
    verticalAlign: verticalAlign,
    marginLeft: marginLeft,
    color: COOL_GRAY,
    cursor: 'pointer',
  };
};

export const NoDataLabel = styled.div`
  position: absolute;
  top: 42%;
  left: 42%;
  font-size: 12px;
  font-weigth: 400;
  font-style: italic;
  color: ${EMPTY_STATE_TEXT_COLOR};
`;
