import { Backdrop, Button, Checkbox, CircularProgress } from '@mui/material';
import { useParticipantEnrollmentDetails } from 'hooks/useParticipantEnrollmentDetails';
import StatusBox from 'pages/ParticipantDataCollection/components/StatusBox/StatusBox.react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import {
  checkAccess,
  checkTernaryCondition,
  isValueEmptyNullorUndefined,
  openPDFFromLink,
} from 'utils/helper';
import {
  FINAL_PAGE_CONTENT,
  getActivityStatus,
  getActivitySubHeading,
  getBaselineStatus,
  getBaselineSubHeading,
  getDownloadHandlerApiUrl,
  getEnrollmentType,
  getMarginTop,
  getMenuPageSubmitButtonLabel,
  getProjectNameHeading,
  getSubHeading,
  LANDING_PAGE_CONTENT,
  MENU_PAGE_CONTENT,
} from './ParticipantPages.content';
import {
  MainWrapper,
  MenuOrReviewContentWrapper,
  MenuFooter,
  MenuOrReviewSubHeading,
  MenuOrReviewPageWrapper,
  MenuOrReviewHeading,
  DownloadResponseWrapper,
  DownloadResponseText,
  buttonStyleSx,
  AttestationCheckBoxLabel,
  EnrollmentRequestLink,
  RequestEnrollmentTitle,
  EnrollmentRequestLinkWrapper,
  ReturnToHomeLink,
  LeftArrowButton,
} from './ParticipantPages.style';
import { ReactComponent as UpdatedPersonIcon } from '../../../assets/icons/updatedPersonIcon.svg';
import { ReactComponent as UpdatedFarmIcon } from '../../../assets/icons/updatedFarmIcon.svg';
import { ReactComponent as DisabledFarmIcon } from '../../../assets/icons/disabledFarmIcon.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/mapIcon.svg';
import { ReactComponent as DisabledFieldIcon } from '../../../assets/icons/disabledFieldIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/smallDownloadIcon.svg';
import { ReactComponent as DisabledDownloadIcon } from '../../../assets/icons/DisabledDownloadIcon.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/LeftArrow.svg';
import axios from 'axios';
import { REQUEST_ADDITIONAL_ENROLLMENT_FROM_BASELINE } from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import {
  ORIGINATION_PROJECT_BASELINE_REPORT_CREATE,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  REPORTING_STATUS,
} from 'utils/config';
import { TermsOfAttestation } from '../components/TermsOfAttestation/TermsOfAttestation.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toastStyle } from 'theme/GlobalStyles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader.react';
import { TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';

const RequestEnrollmentComp = ({ clickHandler }) => (
  <EnrollmentRequestLinkWrapper>
    <RequestEnrollmentTitle>
      {MENU_PAGE_CONTENT.requestEnrollmentTitle}
    </RequestEnrollmentTitle>
    <EnrollmentRequestLink onClick={clickHandler}>
      {MENU_PAGE_CONTENT.requestEnrollmentLink}
    </EnrollmentRequestLink>
  </EnrollmentRequestLinkWrapper>
);

RequestEnrollmentComp.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

const MenuPage = ({ moduleName }) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectcycleId = searchParams.get('projectcycleid');
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const { pageDetails, fetchPageDetails, loading, finishEnrollmentHandler } =
    useParticipantEnrollmentDetails();
  const navigate = useNavigate();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [showWelcomeBackText, setShowWelcomeBackText] = useState(false);
  const { state } = useLocation();
  const [fieldTab, setFieldTab] = useState({});
  const [subHeading, setSubHeading] = useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [mainHeading, setMainHeading] = useState('');
  const [projectNameHeading, setProjectNameHeading] = useState('');
  const [disableStatusOutline, setDisableStatusOutline] = useState(false);
  const [showDownloadResponse, setShowDownladResponse] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [attestationCheck, setAttestationCheck] = useState(false);
  const [isTermsAndConditionsFullyRead, setTermsAndConditionsFullyRead] =
    useState(false);
  const { userRoles, permissions, userDetails } =
    useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);
  const isParticipantOwner = userRoles?.includes(PARTICIPANT);
  const participantFirstName = checkTernaryCondition(
    isParticipantContributor,
    userDetails?.firstName,
    pageDetails?.participantFirstName,
  );
  const initialHomePageCondition =
    (moduleName === LANDING_PAGE_CONTENT.activityModuleName &&
      pageDetails?.activityReportingStatus === 'Interested') ||
    (moduleName === LANDING_PAGE_CONTENT.baselineModuleName &&
      pageDetails?.baselineEnrollmentStatus === 'Interested');

  const hasBaselineReportGenerateAccess =
    moduleName === LANDING_PAGE_CONTENT.baselineModuleName &&
    checkAccess(permissions, ORIGINATION_PROJECT_BASELINE_REPORT_CREATE);

  useEffect(() => {
    fetchPageDetails(
      projectId,
      participantId,
      getEnrollmentType(moduleName).toLocaleUpperCase(),
    );
  }, []);
  useEffect(() => {
    if (pageDetails !== null && moduleName === 'enrollment') {
      const isEnrollmentInProgress =
        pageDetails.enrollmentStatus === 'Enrollment In Progress';
      const isFarmOrFieldsNotCompleted =
        getFinalFarmStatus() !== 'Completed' ||
        getAllFieldsStatus() !== 'Completed';
      const isParticipantOrContributor =
        isParticipantOwner || isParticipantContributor;
      const isNotFromLanding = false;
      const isEnrollmentInReview =
        (pageDetails.participantProfileStatus === 'Completed' ||
          isParticipantContributor) &&
        pageDetails.farmDetails?.filter(
          (farm) => farm.farmStatus === 'In Progress',
        ).length === 0 &&
        getInProgressField(pageDetails.farmDetails, 'formStatus') !== null &&
        getInProgressField(pageDetails.farmDetails, 'formStatus').isCompleted &&
        pageDetails.enrollmentStatus !== 'Enrolled' &&
        pageDetails?.farmDetails[0]?.fieldDetails?.length > 0;

      setShowWelcomeBackText(
        isEnrollmentInProgress &&
          isFarmOrFieldsNotCompleted &&
          isParticipantOrContributor &&
          isNotFromLanding,
      );
      setFieldTab(getInProgressField(pageDetails.farmDetails, 'formStatus'));
      setSubHeading(
        getSubHeading(
          pageDetails.enrollmentStatus,
          pageDetails.projectName,
          userRoles,
        ),
      );
      if (isParticipantContributor) {
        setStatusList([
          {
            id: 2,
            status: getFarmStatus(),
            label: MENU_PAGE_CONTENT.farm_enrollment,
            isDisabled: false,
            totalSections: pageDetails.farmDetails.length,
            completedSections: checkTernaryCondition(
              pageDetails.farmDetails[0]?.farmStatus === 'Completed',
              1,
              0,
            ),
            icon: <UpdatedFarmIcon />,
            disabledIcon: <DisabledFarmIcon />,
          },
          {
            id: 3,
            status: getAllFieldsStatus(),
            label: MENU_PAGE_CONTENT.fields_enrollment,
            isDisabled:
              getInProgressField(pageDetails.farmDetails, 'formStatus') ===
                null || pageDetails.farmDetails.length === 0,
            totalSections: pageDetails.farmDetails[0]?.fieldDetails.length || 0,
            completedSections: getFieldSectionCount('Completed'),
            icon: <MapIcon />,
            disabledIcon: <DisabledFieldIcon />,
          },
        ]);
        if (isEnrollmentInReview) {
          setIsSubmitEnabled(true);
          setSubHeading(FINAL_PAGE_CONTENT.review_content);
          setMainHeading(FINAL_PAGE_CONTENT.review_heading);
        } else if (pageDetails.enrollmentStatus === 'Enrolled') {
          setMainHeading(
            `${FINAL_PAGE_CONTENT.finished_page_heading}${participantFirstName}`,
          );
        }
      } else {
        setStatusList([
          {
            id: 1,
            status: pageDetails.participantProfileStatus,
            label: MENU_PAGE_CONTENT.participant_profile,
            isDisabled: false,
            totalSections: 0,
            completedSections: 0,
            icon: <UpdatedPersonIcon />,
            disabledIcon: null,
          },
          {
            id: 2,
            status: getFinalFarmStatus(),
            label: MENU_PAGE_CONTENT.farm_enrollment,
            isDisabled: pageDetails.farmDetails.length === 0,
            totalSections: pageDetails.farmDetails.length,
            completedSections: checkTernaryCondition(
              pageDetails.farmDetails[0]?.farmStatus === 'Completed',
              1,
              0,
            ),
            icon: <UpdatedFarmIcon />,
            disabledIcon: <DisabledFarmIcon />,
          },
          {
            id: 3,
            status: getAllFieldsStatus(),
            label: MENU_PAGE_CONTENT.fields_enrollment,
            isDisabled:
              getInProgressField(pageDetails.farmDetails, 'formStatus') ===
                null || pageDetails.farmDetails.length === 0,
            totalSections: pageDetails.farmDetails[0]?.fieldDetails.length || 0,
            completedSections: getFieldSectionCount('Completed'),
            icon: <MapIcon />,
            disabledIcon: <DisabledFieldIcon />,
          },
        ]);
        if (
          isEnrollmentInReview &&
          getContributorStatus() === LANDING_PAGE_CONTENT.completed
        ) {
          setIsSubmitEnabled(true);
          setSubHeading(FINAL_PAGE_CONTENT.review_content);
          setMainHeading(FINAL_PAGE_CONTENT.review_heading);
        } else if (pageDetails.enrollmentStatus === 'Enrolled') {
          setMainHeading(
            `${FINAL_PAGE_CONTENT.finished_page_heading}${participantFirstName}`,
          );
        }
      }
      setDisableStatusOutline(pageDetails?.enrollmentStatus === 'Enrolled');
      setShowDownladResponse(pageDetails?.enrollmentStatus !== 'Interested');
      setShowSubmitBtn(pageDetails?.enrollmentStatus !== 'Enrolled');
    }
    if (
      pageDetails !== null &&
      moduleName === LANDING_PAGE_CONTENT.baselineModuleName
    ) {
      setBaselinePageDetails();
    }
    if (
      pageDetails !== null &&
      moduleName === LANDING_PAGE_CONTENT.activityModuleName
    ) {
      setActivityPageDetails();
    }
  }, [pageDetails]);

  const setBaselinePageDetails = () => {
    const inProgressField = getInProgressField(
      pageDetails?.farmDetails,
      'mainStatus',
    );
    let isOverallbaselineCompleted = checkTernaryCondition(
      inProgressField,
      inProgressField?.isCompleted,
      false,
    );
    setShowWelcomeBackText(
      pageDetails?.baselineEnrollmentStatus ===
        LANDING_PAGE_CONTENT.enrollmentProgressStatus &&
        (isParticipantOwner || isParticipantContributor) &&
        !isOverallbaselineCompleted &&
        !state?.fromNotLanding,
    );
    setProjectNameHeading(
      MENU_PAGE_CONTENT.baseline_heading.replace(
        '{{project_name}}',
        pageDetails?.projectName || '',
      ),
    );
    setSubHeading(
      getBaselineSubHeading(
        isOverallbaselineCompleted,
        pageDetails?.baselineEnrollmentStatus,
        pageDetails.projectName,
      ),
    );
    setShowDownladResponse(true);
    setDisableStatusOutline(
      pageDetails?.baselineEnrollmentStatus === 'Enrolled',
    );
    setMainHeading(getBaselineMainHeading(isOverallbaselineCompleted));
    setShowSubmitBtn(
      isOverallbaselineCompleted &&
        (pageDetails.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentProgressStatus ||
          pageDetails.baselineEnrollmentStatus === null),
    );
    setIsSubmitEnabled(isOverallbaselineCompleted);
    setStatusList([
      {
        id: 1,
        status: getBaselineStatus(
          pageDetails?.baselineEnrollmentStatus,
          getInProgressField(pageDetails?.farmDetails, 'mainStatus'),
        ),
        label: MENU_PAGE_CONTENT.baseline_reporting,
        isDisabled: false,
        totalSections: 0,
        completedSections: 0,
        icon: <MapIcon />,
        disabledIcon: null,
      },
    ]);
  };

  const setActivityPageDetails = () => {
    let isOverallActivityCompleted = pageDetails.farmDetails.every(
      (farm) => farm.farmActivityReportingStatus === REPORTING_STATUS.completed,
    );
    setShowWelcomeBackText(
      pageDetails?.activityReportingStatus ===
        LANDING_PAGE_CONTENT.activityProgressStatus &&
        (isParticipantOwner || isParticipantContributor) &&
        !isOverallActivityCompleted &&
        !state?.fromNotLanding,
    );
    setProjectNameHeading(
      MENU_PAGE_CONTENT.activity_reporting_heading.replace(
        '{{project_name}}',
        pageDetails?.projectName || '',
      ),
    );
    setSubHeading(
      getActivitySubHeading(
        isOverallActivityCompleted,
        pageDetails?.activityReportingStatus,
        pageDetails.projectName,
      ),
    );
    setShowDownladResponse(true);
    setDisableStatusOutline(
      pageDetails?.activityReportingStatus === 'Enrolled',
    );
    setMainHeading(getActivityMainHeading(isOverallActivityCompleted));
    setShowSubmitBtn(
      isOverallActivityCompleted &&
        (pageDetails.activityReportingStatus ===
          LANDING_PAGE_CONTENT.activityProgressStatus ||
          pageDetails.activityReportingStatus === null),
    );
    setIsSubmitEnabled(isOverallActivityCompleted);
    setStatusList([
      {
        id: 1,
        status: getActivityStatus(
          pageDetails?.activityReportingStatus,
          isOverallActivityCompleted,
        ),
        label: MENU_PAGE_CONTENT.activity_reporting,
        isDisabled: false,
        totalSections: 0,
        completedSections: 0,
        icon: <MapIcon />,
        disabledIcon: null,
      },
    ]);
  };

  const getBaselineMainHeading = (isOverallbaselineCompleted) => {
    if (isOverallbaselineCompleted) {
      return checkTernaryCondition(
        pageDetails?.baselineEnrollmentStatus ===
          LANDING_PAGE_CONTENT.enrollmentProgressStatus,
        FINAL_PAGE_CONTENT.review_heading,
        `${FINAL_PAGE_CONTENT.finished_page_heading}${participantFirstName}`,
      );
    }
  };
  const getActivityMainHeading = (isOverallActivityCompleted) => {
    if (isOverallActivityCompleted) {
      return checkTernaryCondition(
        pageDetails?.activityReportingStatus ===
          LANDING_PAGE_CONTENT.activityProgressStatus,
        FINAL_PAGE_CONTENT.review_heading,
        `${FINAL_PAGE_CONTENT.finished_page_heading}${participantFirstName}`,
      );
    }
  };
  const getInProgressField = (farmList, key) => {
    let firstField = null;
    for (const farm of farmList) {
      let fieldList = farm.fieldDetails;

      // Note: key is "formStatus" for Enrollment and "mainStatus" for Baseline, we are filtering only in enrollment flow
      if (key === 'formStatus') {
        fieldList = fieldList.filter(
          (field) =>
            field.enrollmentInstanceID === Number(enrollmentInstanceId),
        );
      }

      for (const field of fieldList) {
        if (firstField === null) {
          firstField = field;
        }
        if (
          field[key] === 'In Progress' ||
          field[key] === null ||
          field[key] === 'Not Started'
        ) {
          return { ...field, isCompleted: false };
        }
      }
    }

    return checkTernaryCondition(
      firstField !== null,
      { ...firstField, isCompleted: true },
      null,
    );
  };

  const getFarmStatus = () => {
    if (pageDetails.farmDetails.length > 0) {
      return checkTernaryCondition(
        pageDetails.farmDetails.filter(
          (farm) =>
            farm.farmStatus?.toLowerCase() ===
            LANDING_PAGE_CONTENT.in_progress.toLowerCase(),
        ).length > 0,
        LANDING_PAGE_CONTENT.in_progress,
        checkTernaryCondition(
          pageDetails.farmDetails.every(
            (farm) =>
              farm.farmStatus?.toLowerCase() ===
              LANDING_PAGE_CONTENT.not_started.toLowerCase(),
          ),
          LANDING_PAGE_CONTENT.not_started,
          checkTernaryCondition(
            pageDetails.farmDetails.every(
              (farm) =>
                farm.farmStatus?.toLowerCase() ===
                LANDING_PAGE_CONTENT.completed.toLowerCase(),
            ),
            LANDING_PAGE_CONTENT.completed,
            LANDING_PAGE_CONTENT.in_progress,
          ),
        ),
      );
    } else {
      return null;
    }
  };

  const getContributorStatus = () => {
    if (pageDetails.farmDetails.length > 0) {
      return checkTernaryCondition(
        pageDetails.farmDetails.filter(
          (farm) =>
            farm.contributorStatus?.toLowerCase() ===
            LANDING_PAGE_CONTENT.in_progress.toLowerCase(),
        ).length > 0,
        LANDING_PAGE_CONTENT.in_progress,
        checkTernaryCondition(
          pageDetails.farmDetails.every(
            (farm) =>
              farm.contributorStatus?.toLowerCase() ===
              LANDING_PAGE_CONTENT.not_started.toLowerCase(),
          ),
          LANDING_PAGE_CONTENT.not_started,
          checkTernaryCondition(
            pageDetails.farmDetails.every(
              (farm) =>
                farm.contributorStatus?.toLowerCase() ===
                LANDING_PAGE_CONTENT.completed.toLowerCase(),
            ),
            LANDING_PAGE_CONTENT.completed,
            LANDING_PAGE_CONTENT.in_progress,
          ),
        ),
      );
    } else {
      return null;
    }
  };

  const getFinalFarmStatus = () => {
    const farmStatus = getFarmStatus();
    const contributorStatus = getContributorStatus();
    if (
      farmStatus?.toLowerCase() ===
      LANDING_PAGE_CONTENT.not_started.toLowerCase()
    ) {
      if (
        contributorStatus?.toLowerCase() ===
        LANDING_PAGE_CONTENT.not_started.toLowerCase()
      ) {
        return null;
      } else if (contributorStatus === LANDING_PAGE_CONTENT.in_progress) {
        return LANDING_PAGE_CONTENT.in_progress;
      }
    } else if (farmStatus === LANDING_PAGE_CONTENT.in_progress) {
      return LANDING_PAGE_CONTENT.in_progress;
    } else if (farmStatus === LANDING_PAGE_CONTENT.completed) {
      if (contributorStatus === LANDING_PAGE_CONTENT.in_progress) {
        return LANDING_PAGE_CONTENT.in_progress;
      }
      if (contributorStatus === LANDING_PAGE_CONTENT.completed) {
        return LANDING_PAGE_CONTENT.completed;
      }
    }
  };

  const getFieldSectionCount = (status) => {
    return pageDetails.farmDetails[0]?.fieldDetails.filter(
      (detail) => detail.formStatus === status,
    ).length;
  };

  const getAllFieldsStatus = () => {
    if (
      getInProgressField(pageDetails.farmDetails, 'formStatus') === null ||
      pageDetails.farmDetails.length === 0
    ) {
      return null;
    } else {
      return checkTernaryCondition(
        !getInProgressField(pageDetails.farmDetails, 'formStatus').isCompleted,
        'In Progress',
        'Completed',
      );
    }
  };

  const downloadPDFHandler = () => {
    let API_URL = getDownloadHandlerApiUrl(moduleName);
    API_URL = API_URL.replace(':participnatId', participantId);
    if (API_URL.includes('baseline') || API_URL.includes('activity')) {
      API_URL = API_URL.replace(':projectId', projectId);
    }
    setDownloadLoading(true);
    axios
      .get(API_URL)
      .then((response) => {
        openPDFFromLink(response.data.reportLink);
      })
      .catch(() => {
        displayToast('error', `${MENU_PAGE_CONTENT.pdf_download_failed}`);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const navigateHandler = (id, isFieldClicked) => {
    if (
      pageDetails?.enrollmentStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.enrollmentModuleName
    ) {
      navigate(
        `${pathName.participant.participantDataCollection}?projectid=${projectId}&participantid=${participantId}&enrollmentInstanceId=${enrollmentInstanceId}&projectcycleid=${projectcycleId}`,
        {
          state: {
            id: checkTernaryCondition(isFieldClicked, 2, id),
            projectId: projectId,
            projectName: pageDetails.projectName,
            participantId: participantId,
            participantFirstName: pageDetails.participantFirstName,
            participantLastName: pageDetails.participantLastName,
            participantEmail: pageDetails.participantEmail,
            fieldData: checkTernaryCondition(
              isFieldClicked,
              {
                farmId: fieldTab?.farmId,
                ...fieldTab,
              },
              null,
            ),
          },
        },
      );
    } else if (
      pageDetails?.baselineEnrollmentStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.baselineModuleName
    ) {
      navigate(
        `${pathName.participant.participantFieldBaseline}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectcycleId}`,
      );
    } else if (
      pageDetails?.activityReportingStatus !== 'Enrolled' &&
      moduleName === LANDING_PAGE_CONTENT.activityModuleName
    ) {
      navigate(
        `${pathName.participant.participantFieldActivity}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectcycleId}`,
      );
    }
  };

  const navigateLandingPageHandler = () => {
    navigate(`${pathName.participant.landingPage}`);
  };
  /**
   * @description This function is used to send email to project developer regarding new enrollment instance request.
   */
  const handleAdditionalEnrollmentClick = () => {
    setApiLoader(true);
    const requestBody = {
      internalProjectId: Number(projectId),
      participantId: Number(participantId),
    };

    axios
      .post(REQUEST_ADDITIONAL_ENROLLMENT_FROM_BASELINE, requestBody)
      .then(() => {
        toast(
          <CustomSnackbar
            type="success"
            message={MENU_PAGE_CONTENT.requestEnrollmentToastMessage}
          />,
          toastStyle,
        );
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={MENU_PAGE_CONTENT.requestEnrollmentFailedToastMessage}
          />,
          toastStyle,
        );
      })
      .finally(() => setApiLoader(false));
  };

  const getEnrollmentIdsForBaseline = () => {
    const enrollmentIds = new Set();
    for (const farm of pageDetails.farmDetails) {
      if (
        farm.enrolmentStatus === 'APPROVED' &&
        (farm.baselineStatus === 'NOT_APPROVED' ||
          farm.baselineStatus === 'IN_PROGRESS')
      )
        enrollmentIds.add(farm.enrollmentInstanceID);
      for (const field of farm.fieldDetails) {
        if (
          field.enrolmentStatus === 'APPROVED' &&
          (field.baselineStatus === 'NOT_APPROVED' ||
            field.baselineStatus === 'IN_PROGRESS')
        )
          enrollmentIds.add(field.enrollmentInstanceID);
      }
    }
    return Array.from(enrollmentIds);
  };
  const getEnrollmentIdsForActivity = () => {
    const enrollmentIds = new Set();
    for (const farm of pageDetails.farmDetails) {
      if (
        farm.enrolmentStatus === 'APPROVED' &&
        farm.baselineStatus === 'APPROVED' &&
        (farm.activityReportingStatus === 'NOT_APPROVED' ||
          farm.activityReportingStatus === 'IN_PROGRESS')
      )
        enrollmentIds.add(farm.enrollmentInstanceID);
      for (const field of farm.fieldDetails) {
        if (
          field.enrolmentStatus === 'APPROVED' &&
          field.baselineStatus === 'APPROVED' &&
          (field.activityReportingStatus === 'NOT_APPROVED' ||
            field.activityReportingStatus === 'IN_PROGRESS')
        )
          enrollmentIds.add(field.enrollmentInstanceID);
      }
    }
    return Array.from(enrollmentIds);
  };
  const getEnrollmentIds = () => {
    switch (moduleName) {
      case LANDING_PAGE_CONTENT.enrollmentModuleName:
        return [];
      case LANDING_PAGE_CONTENT.baselineModuleName:
        return getEnrollmentIdsForBaseline();
      case LANDING_PAGE_CONTENT.activityModuleName:
        return getEnrollmentIdsForActivity();
      default:
        return [];
    }
  };
  return (
    <MainWrapper>
      <Loader loading={apiLoader} />
      {checkTernaryCondition(
        loading || pageDetails === null || downloadLoading,
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="success" />
        </Backdrop>,
        <MenuOrReviewPageWrapper
          marginTop={checkTernaryCondition(
            showWelcomeBackText || !isValueEmptyNullorUndefined(mainHeading),
            '1.5rem',
            '7.5rem',
          )}>
          {showWelcomeBackText && (
            <MenuOrReviewHeading>
              {MENU_PAGE_CONTENT.welcome_back_label}
              {participantFirstName}
            </MenuOrReviewHeading>
          )}
          {isEmailLinkClicked && !showWelcomeBackText && mainHeading && (
            <LeftArrowButton style={{ cursor: 'pointer' }}>
              <LeftArrowIcon className="left-arrow-icon"></LeftArrowIcon>
              <ReturnToHomeLink onClick={navigateLandingPageHandler}>
                {MENU_PAGE_CONTENT.return_to_home_text}
              </ReturnToHomeLink>
            </LeftArrowButton>
          )}
          {!showWelcomeBackText && (
            <MenuOrReviewHeading>{mainHeading}</MenuOrReviewHeading>
          )}
          {getProjectNameHeading(
            moduleName,
            pageDetails?.enrollmentStatus,
            pageDetails?.baselineEnrollmentStatus,
            pageDetails?.activityReportingStatus,
            pageDetails?.projectName,
            projectNameHeading,
          )}
          <MenuOrReviewSubHeading
            customFontSize={checkTernaryCondition(
              (!(moduleName === LANDING_PAGE_CONTENT.baselineModuleName) &&
                pageDetails?.enrollmentStatus === 'Enrolled') ||
                (!(moduleName === LANDING_PAGE_CONTENT.activityModuleName) &&
                  pageDetails?.baselineEnrollmentStatus === 'Enrolled') ||
                pageDetails?.activityReportingStatus === 'Enrolled',
              '1.5rem',
              '1.25rem',
            )}>
            {subHeading}
          </MenuOrReviewSubHeading>
          <MenuOrReviewContentWrapper
            marginTop={getMarginTop(moduleName)}
            isFinalPage={true}>
            {statusList.map((status) => (
              <StatusBox
                key={status.id}
                disabled={status.isDisabled}
                statusLabel={status.label}
                status={status.status}
                icon={status.icon}
                disableOutline={disableStatusOutline}
                onClickHandler={() =>
                  navigateHandler(
                    status.id,
                    status.label === MENU_PAGE_CONTENT.fields_enrollment,
                  )
                }
                isBaselineMenu={
                  moduleName === LANDING_PAGE_CONTENT.baselineModuleName ||
                  moduleName === LANDING_PAGE_CONTENT.activityModuleName
                }
                disabledIcon={status.disabledIcon}
              />
            ))}
          </MenuOrReviewContentWrapper>
          {pageDetails?.enrollmentStatus === 'Interested' &&
            !(moduleName === LANDING_PAGE_CONTENT.baselineModuleName) && (
              <MenuFooter>{MENU_PAGE_CONTENT.section_footer}</MenuFooter>
            )}
          {!showDownloadResponse &&
            moduleName === LANDING_PAGE_CONTENT.baselineModuleName && (
              <RequestEnrollmentComp
                clickHandler={handleAdditionalEnrollmentClick}
              />
            )}
          {showDownloadResponse && (
            <>
              <DownloadResponseWrapper
                disabled={checkTernaryCondition(
                  moduleName === LANDING_PAGE_CONTENT.baselineModuleName,
                  !hasBaselineReportGenerateAccess,
                  false,
                )}
                style={{
                  pointerEvents: checkTernaryCondition(
                    initialHomePageCondition,
                    'none',
                    'auto',
                  ),
                  opacity: checkTernaryCondition(
                    initialHomePageCondition,
                    0.5,
                    1,
                  ),
                  color: checkTernaryCondition(
                    initialHomePageCondition,
                    TYPOGRAPHY_GRAY,
                    '',
                  ),
                }}
                onClick={checkTernaryCondition(
                  moduleName === LANDING_PAGE_CONTENT.baselineModuleName,
                  hasBaselineReportGenerateAccess && downloadPDFHandler,
                  downloadPDFHandler,
                )}>
                {checkTernaryCondition(
                  initialHomePageCondition,
                  <DisabledDownloadIcon
                    style={{ height: '1rem', width: '1rem' }}
                  />,
                  <DownloadIcon />,
                )}
                <DownloadResponseText>
                  {MENU_PAGE_CONTENT.download_reponse_label}
                </DownloadResponseText>
              </DownloadResponseWrapper>
              {moduleName === LANDING_PAGE_CONTENT.baselineModuleName && (
                <RequestEnrollmentComp
                  clickHandler={handleAdditionalEnrollmentClick}
                />
              )}
              {showSubmitBtn && (
                <>
                  {isSubmitEnabled && (
                    <>
                      <TermsOfAttestation
                        setTermsAndConditionsFullyRead={
                          setTermsAndConditionsFullyRead
                        }
                      />
                      <div className="projectid_checkfield">
                        <Checkbox
                          onChange={(e) =>
                            setAttestationCheck(e.target.checked)
                          }
                          name="attestation"
                          inputProps={{ 'aria-label': 'registry_id_checkbox' }}
                          color="success"
                          className="projectid_checkbox"
                          checked={attestationCheck}
                          disabled={!isTermsAndConditionsFullyRead}
                        />
                        <AttestationCheckBoxLabel>
                          Please confirm you&apos;ve read our terms of
                          attestation to submit enrollment.
                        </AttestationCheckBoxLabel>
                      </div>
                    </>
                  )}
                  <Button
                    variant={MENU_PAGE_CONTENT.contained}
                    size={MENU_PAGE_CONTENT.medium}
                    color={MENU_PAGE_CONTENT.success}
                    sx={[buttonStyleSx, primaryButtonStyle('0.78rem 0.875rem')]}
                    disabled={
                      !(
                        isSubmitEnabled &&
                        attestationCheck &&
                        !isParticipantContributor
                      )
                    }
                    onClick={() => {
                      const enrollmentIds = getEnrollmentIds();
                      const enrollmentType = getEnrollmentType(moduleName);
                      finishEnrollmentHandler(
                        participantId,
                        projectId,
                        enrollmentType,
                        enrollmentIds,
                        parseInt(enrollmentInstanceId),
                        getEnrollmentType(moduleName).toLocaleUpperCase(),
                        projectcycleId,
                      );
                    }}
                    disableElevation>
                    {getMenuPageSubmitButtonLabel(moduleName)}
                  </Button>
                </>
              )}
            </>
          )}
        </MenuOrReviewPageWrapper>,
      )}
    </MainWrapper>
  );
};

MenuPage.propTypes = {
  moduleName: PropTypes.string,
};

export default MenuPage;
