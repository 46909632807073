import styled from 'styled-components';

export const RemoveLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${(prop) => prop.color};
  margin-left: 1.5rem;
  cursor: pointer;
`;
