export const DialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '48rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    },
  },
};
export const IconButtonStyle = { ml: 'auto' };
export const DialogTitleStyle = { fontSize: '20px', padding: '0rem 1.5rem' };
export const DialogContentStyle = { marginBottom: '1.5rem', padding: '0.5rem' };

export const AccordionDetailsSx = {
  paddingLeft: '0rem',
  paddingRight: '0rem',
  paddingBottom: '0rem',
};
