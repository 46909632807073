import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext } from 'react';
import { SearchContext } from 'containers/ProjectSearchBox.react';
import { getMenuItemStyles } from 'theme/GlobalStyles';

export function SearchDropdown() {
  const { dropdown, selectedField, setSelectedField } =
    useContext(SearchContext);
  return (
    <>
      {selectedField && (
        <Select
          id="search-select"
          className="search-dropdown"
          sx={{ width: 150 }}
          size="small"
          value={selectedField}
          onChange={(e) => setSelectedField(e.target.value)}>
          {dropdown.map((eachValue) => (
            <MenuItem
              value={eachValue.userFriendlyName}
              key={eachValue.orderId}
              sx={getMenuItemStyles}>
              {eachValue.userFriendlyName}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
}
