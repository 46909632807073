import { NavigationItem, NavigationWrapper } from './LeftNavigationMenu.style';

const LeftNavigationMenu = ({
  navigationMenuList,
  navSwitchHandler,
  activeTab,
}) => {
  return (
    <NavigationWrapper>
      {navigationMenuList.map((menu, index) => {
        return (
          <NavigationItem
            active={menu.id === activeTab}
            key={index}
            onClick={() => {
              navSwitchHandler(menu.id);
            }}>
            {menu.title}
          </NavigationItem>
        );
      })}
    </NavigationWrapper>
  );
};

export default LeftNavigationMenu;
