import styled from 'styled-components';

const box_shadow = '0px 0.125rem 0.188rem rgba(0, 0, 0, 0.1)';

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
`;

export const OverviewTable = styled.div`
  box-shadow: ${box_shadow};
  .portfolio-overview {
    height: 1252px;
  }
  .portfolio-overview-table {
    height: 695px;
  }
  .portfolio-schedule {
    height: 800px;
  }
  .project-overview {
    height: 840px;
  }
  .stakeholder-profile {
    height: 1320px;
  }
  .project-details {
    height: 1264px;
  }
`;
export const HeadingWrapper = styled.div`
  display: flex;
  height: 1.75rem;
  /* width: 100%; */
  margin: 0 0 0 0;
  padding: 0 2rem 0 2rem;
  grid-column-gap: 1rem;
  justify-content: space-between;
`;
export const TabberWrapperStyle = {
  margin: '0.5rem 0rem 1rem 0rem',
  position: 'relative',
};
