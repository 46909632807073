import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  DetailContainer,
  DetailContent,
  DetailHeader,
  DetailsSection,
  LegendDetails,
  LegendSection,
  TextWrapper,
} from './StakeholderParticipantIncentiveCard.style';
import ColorIcon from '../ColorIcon/ColorIcon.react';
import { ACTUAL, PROJECTED } from 'utils/config';
import { COOL_GRAY, DARK_CHARCOAL, QUILL_GRAY } from 'theme/GlobalColors';
import { CARD_DETAILS } from './StakeholderParticipantIncentiveCard.content';
import { checkTernaryCondition } from 'utils/helper';
import PropTypes from 'prop-types';

const StakeholderParticipantIncentiveCard = ({ data }) => {
  const returnHeaderColor = () => {
    return checkTernaryCondition(
      data.averageProjectedIncentive === 0 &&
        data.totalProjectedIncentive === 0 &&
        data.averageActualIncentive === 0 &&
        data.totalActualIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  const returnProjectedHeaderColor = () => {
    return checkTernaryCondition(
      data.averageProjectedIncentive === 0 &&
        data.totalProjectedIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  const returnActualHeaderColor = () => {
    return checkTernaryCondition(
      data.averageActualIncentive === 0 && data.totalActualIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle color={returnHeaderColor()}>
          {CARD_DETAILS.cardHeading}
        </CardTitle>
        <LegendSection>
          <LegendDetails>
            <ColorIcon
              type={checkTernaryCondition(
                data.averageProjectedIncentive === 0 &&
                  data.totalProjectedIncentive === 0,
                '',
                `${PROJECTED}`,
              )}
            />
            <TextWrapper
              color={returnProjectedHeaderColor()}
              fontSize="0.75rem"
              fontWeight="400"
              letterSpacing="0.013rem">
              {CARD_DETAILS.projected}
            </TextWrapper>
          </LegendDetails>
          <LegendDetails>
            <ColorIcon
              type={checkTernaryCondition(
                data.averageActualIncentive === 0 &&
                  data.totalActualIncentive === 0,
                '',
                `${ACTUAL}`,
              )}
            />
            <TextWrapper
              color={returnActualHeaderColor()}
              fontSize="0.75rem"
              fontWeight="400"
              letterSpacing="0.013rem">
              {CARD_DETAILS.actual}
            </TextWrapper>
          </LegendDetails>
        </LegendSection>
      </CardHeader>
      <CardContent>
        <DetailsSection
          padding="0rem 1.5rem 0rem 0rem"
          borderRight={`1px solid ${QUILL_GRAY}`}>
          <DetailHeader>
            <TextWrapper
              color={returnHeaderColor()}
              fontSize="1rem"
              fontWeight="600">
              {`${CARD_DETAILS.average}`}
            </TextWrapper>
            <TextWrapper
              color={`${COOL_GRAY}`}
              fontSize="0.75rem"
              fontWeight="400">
              {CARD_DETAILS.perAcre}
            </TextWrapper>
          </DetailHeader>
          <DetailContainer>
            <DetailContent>
              <ColorIcon
                type={checkTernaryCondition(
                  data.averageProjectedIncentive === 0,
                  '',
                  `${PROJECTED}`,
                )}
              />
              <TextWrapper
                color={`${COOL_GRAY}`}
                fontSize="1.5rem"
                width={checkTernaryCondition(
                  data.averageProjectedIncentive === 0,
                  '4.813rem',
                  'auto',
                )}
                fontWeight="600">
                {checkTernaryCondition(
                  data.averageProjectedIncentive === 0,
                  '--',
                  '$' + data.averageProjectedIncentive.toFixed(2),
                )}
              </TextWrapper>
            </DetailContent>
            <DetailContent>
              <ColorIcon
                type={checkTernaryCondition(
                  data.averageActualIncentive === 0,
                  '',
                  ACTUAL,
                )}
              />
              <TextWrapper
                color={`${COOL_GRAY}`}
                fontSize="1.5rem"
                fontWeight="600">
                {checkTernaryCondition(
                  data.averageActualIncentive === 0,
                  '--',
                  '$' + data.averageActualIncentive.toFixed(2),
                )}
              </TextWrapper>
            </DetailContent>
          </DetailContainer>
        </DetailsSection>
        <DetailsSection padding="0rem 1rem 0rem 1.5rem">
          <DetailHeader>
            <TextWrapper
              color={returnHeaderColor()}
              fontSize="1rem"
              fontWeight="600">
              {CARD_DETAILS.total}
            </TextWrapper>
            <TextWrapper
              color={`${COOL_GRAY}`}
              fontSize="0.75rem"
              fontWeight="400">
              ($)
            </TextWrapper>
          </DetailHeader>
          <DetailContainer>
            <DetailContent>
              <ColorIcon
                type={checkTernaryCondition(
                  data.totalProjectedIncentive === 0,
                  '',
                  PROJECTED,
                )}
              />
              <TextWrapper
                color={`${COOL_GRAY}`}
                fontSize="1.5rem"
                width={checkTernaryCondition(
                  data.totalProjectedIncentive === 0,
                  '6.875rem',
                  'auto',
                )}
                fontWeight="600">
                {checkTernaryCondition(
                  data.totalProjectedIncentive === 0,
                  '--',
                  '$' + data.totalProjectedIncentive.toFixed(2),
                )}
              </TextWrapper>
            </DetailContent>
            <DetailContent>
              <ColorIcon
                type={checkTernaryCondition(
                  data.totalActualIncentive === 0,
                  '',
                  ACTUAL,
                )}
              />
              <TextWrapper
                color={`${COOL_GRAY}`}
                fontSize="1.5rem"
                fontWeight="600">
                {checkTernaryCondition(
                  data.totalActualIncentive === 0,
                  '--',
                  '$' + data.totalActualIncentive.toFixed(2),
                )}
              </TextWrapper>
            </DetailContent>
          </DetailContainer>
        </DetailsSection>
      </CardContent>
    </Card>
  );
};
StakeholderParticipantIncentiveCard.propTypes = {
  data: PropTypes.object,
};
export default StakeholderParticipantIncentiveCard;
