import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MainContainer,
  DetailsContainer,
  Details,
  FieldTitle,
  Description,
  SubDescription,
  MapAndDetailsWrapper,
  STYLE_CONST,
  SeeMoreWrapper,
} from '../ProjectDetails.style';
import { LookerDashboardComponent } from '../ProjectDetails.react';
import {
  PROJECT_DETAIL_CONTENTS,
  TextModalContent,
  SubDetailsArray,
} from '../ProjectDetail.content';
import { BLACK } from 'theme/GlobalColors';
import SubDetailsComp from '../SubDetailsComp/SubDetailsComp.react';
import AuditTrailComp from 'containers/AuditTrailComp/AuditTrailComp.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { Box } from '@mui/system';

const ProjectSummaryComponent = ({
  hasNoRegistryId,
  fieldTitleArray,
  iframeSrcMap,
  id,
}) => {
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [textModalTitle, setTextModalTitle] = useState(
    PROJECT_DETAIL_CONTENTS.empty_string,
  );
  const [textModalContent, setTextModalContent] = useState(
    PROJECT_DETAIL_CONTENTS.empty_string,
  );
  const handleClickSeeMore = (title, content) => {
    setIsTextModalOpen(true);
    setTextModalTitle(title);
    setTextModalContent(content);
  };
  return (
    <Box>
      <MainContainer>
        <DetailsContainer>
          <MapAndDetailsWrapper>
            <Details
              padding={STYLE_CONST.one_zero_xy_cords}
              shadow={STYLE_CONST.shadow}
              width={STYLE_CONST.forty_nine_point_five}>
              <FieldTitle
                weight={STYLE_CONST.weight_xl}
                color={BLACK}
                padding={STYLE_CONST.zero_one_xy_cords}>
                {PROJECT_DETAIL_CONTENTS.details}
              </FieldTitle>
              {hasNoRegistryId && (
                <>
                  {SubDetailsArray.slice(0, 2)
                    .reverse()
                    .map((item) => (
                      <SubDetailsComp
                        key={item.start}
                        fieldTitleArray={fieldTitleArray}
                        start={item.start}
                        end={item.end}
                      />
                    ))}
                </>
              )}
              {!hasNoRegistryId && (
                <>
                  {SubDetailsArray.slice(1).map((item) => (
                    <SubDetailsComp
                      key={item.start}
                      fieldTitleArray={fieldTitleArray}
                      start={item.start}
                      end={item.end}
                    />
                  ))}
                </>
              )}
            </Details>
            <MapAndDetailsWrapper height="17rem" width="50.5%">
              {LookerDashboardComponent(
                PROJECT_DETAIL_CONTENTS.looker_dashboard_map,
                PROJECT_DETAIL_CONTENTS.looker_dashboard_map,
                iframeSrcMap,
              )}
            </MapAndDetailsWrapper>
          </MapAndDetailsWrapper>
          <Description background={STYLE_CONST.none}>
            {fieldTitleArray.slice(9).map((item) => (
              <Details noGap width={STYLE_CONST.fifty_percent} key={item.value}>
                <FieldTitle
                  weight={STYLE_CONST.weight_xl}
                  color={BLACK}
                  padding={STYLE_CONST.one_rem}>
                  {item.title}
                </FieldTitle>
                <SubDescription>
                  {item.value?.length >
                  PROJECT_DETAIL_CONTENTS.max_text_length ? (
                    <>
                      {item.value?.slice(
                        0,
                        PROJECT_DETAIL_CONTENTS.max_text_length,
                      )}
                      <SeeMoreWrapper
                        onClick={() =>
                          handleClickSeeMore(item.title, item.value)
                        }>
                        {PROJECT_DETAIL_CONTENTS.see_more}
                      </SeeMoreWrapper>
                    </>
                  ) : (
                    item.value
                  )}
                </SubDescription>
              </Details>
            ))}
          </Description>
        </DetailsContainer>
      </MainContainer>
      <MainContainer>
        <AuditTrailComp
          categoryType={PROJECT_DETAIL_CONTENTS.project}
          recordId={id}
        />
      </MainContainer>
      <DialogBox
        isOpen={isTextModalOpen}
        onCancel={() => setIsTextModalOpen(false)}
        title={textModalTitle}>
        <TextModalContent value={textModalContent}></TextModalContent>
      </DialogBox>
    </Box>
  );
};

ProjectSummaryComponent.propTypes = {
  hasNoRegistryId: PropTypes.bool,
  fieldTitleArray: PropTypes.array,
  iframeSrcMap: PropTypes.object,
  id: PropTypes.string,
};
export default ProjectSummaryComponent;
