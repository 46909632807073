export const participantEnrollmentPaths = [
  '/stakeholders/enrollment-landing-page',
  '/stakeholders/enrollment-menu-page',
  '/stakeholders/participant-data-collection',
];

export const participantBaselinePaths = [
  '/stakeholders/baseline-landing-page',
  '/stakeholders/participant-field-baseline',
  '/stakeholders/baseline-menu-page',
];

export const participantActivityPaths = [
  '/stakeholders/activity-landing-page',
  '/stakeholders/activity-reporting-menu-page',
  '/stakeholders/participant-field-activity',
];

export const participantContributorPaths = [
  '/stakeholders/contributor-landing-page',
  '/stakeholders/contributor-reporting-menu-page',
];
export const landingPath = '/stakeholders/landing-page';
