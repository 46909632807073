import styled from 'styled-components';
import {
  DARK_CHARCOAL,
  LANDING_CARD_FOCUSED_BLUE,
  LANDING_CARD_HOVER_BLUE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 12.4rem;
  padding: 24px 8px;
  gap: 10px;
  border-radius: 32px;
  border: ${(props) =>
    !props.shouldNotBeClickable &&
    checkTernaryCondition(
      props.isHovered || props.isClicked,
      '2px solid #0D8390',
      '',
    )};
  align-items: center;
  background-color: ${(props) =>
    checkTernaryCondition(
      props.isHovered && !props.shouldNotBeClickable,
      LANDING_CARD_HOVER_BLUE,
      checkTernaryCondition(
        props.isClicked && !props.shouldNotBeClickable,
        LANDING_CARD_FOCUSED_BLUE,
        '#FFFFFF',
      ),
    )};
  opacity: 1;
  cursor: pointer;
`;
export const CardBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 62.5rem;
  height: 21.375rem;
  padding: 2rem 3rem 2rem 3rem;
  gap: 1.5rem;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);
`;
export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.alignment || 'center'};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color || DARK_CHARCOAL};
`;
export const IconDimensions = (isNotStarted) => {
  return {
    width: checkTernaryCondition(isNotStarted, '.875rem', '1rem'),
    height: checkTernaryCondition(isNotStarted, '.875rem', '1rem'),
  };
};
