import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_GREEN,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
  QUILL_GRAY,
  RED,
  TYPOGRAPHY_GRAY,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const UploadWrapper = styled.div`
  width: 28.25rem;
  height: 6.25rem;
  border: 0.063rem dashed #97999b;
  border-radius: 0.125rem;
  margin: auto;
  margin-top: 0.5rem;
  cursor: pointer;
`;

export const SmallUploadWrapper = styled.div`
  width: ${({ width }) => width || '28.25rem'};
  height: 2.625rem;
  border: 0.063rem dashed #97999b;
  border-radius: 0.125rem;
  margin: auto;
  cursor: pointer;
  padding: 0.625rem;
  box-sizing: border-box;
`;

export const UploadText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: center;
  width: 27.25rem;
  padding-top: 2.338rem;
  color: #63666a;
`;

export const UploadSubText = styled.span`
  color: ${GLOBAL_BLUE};
  margin-left: 0.125rem;
`;

export const UploadHelpText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 1.125rem;
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
  display: ${({ invisible }) => (invisible ? 'none' : 'block')};
  width: 100%;
`;

export const FileWrapper = styled.div`
  display: inline-flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.2px;
  color: ${(props) => (props.iserror ? `#DA291C` : `#63666A`)};
`;
export const FileSuccess = styled.div`
  display: inline-flex;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const TitleSectionHeading = styled.div`
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
  color: ${(props) =>
    checkTernaryCondition(props.isError, RED, props.color || 'inherit')};
`;

export const CloseIcon = styled.div`
  margin-left: 2.5rem;
  float: right;
  cursor: pointer;
`;

export const FileIconWrapper = styled.div`
  padding-top: 0.125rem;
  margin-right: 0.438rem;
`;

export const cancelButtonSx = {
  fontSize: 12,
  textTransform: 'none',
  color: DARK_GREEN,

  '&:hover': {
    color: DARK_GREEN,
  },
};

export const uploadBtnSx = {
  fontSize: 12,
  textTransform: 'none',
  width: '5.5rem',
  backgroundColor: DARK_GREEN,
  height: '1.75rem',

  '&:hover': {
    backgroundColor: DARK_GREEN,
  },

  '&.Mui-disabled': {
    color: TYPOGRAPHY_GRAY,
  },
};

export const AcceptedFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0rem;
`;

export const FileName = styled.div`
  padding: 0.25rem 0.5rem;
  border: ${(props) =>
    checkTernaryCondition(
      props.isError,
      `1px solid ${RED}`,
      `1px solid ${QUILL_GRAY}`,
    )};
  border-radius: 2px;
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  height: 1.688rem;
  display: flex;
  align-items: center;
`;

export const deleteIconSx = {
  color: COOL_GRAY,
  '&:hover': {
    fill: NEW_DARK_GREEN,
  },
};

export const DragAndDropTitle = styled.div`
  margin: 1rem 0rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  color: ${(props) => checkTernaryCondition(props.isError, RED, 'inherit')};
`;

export const DragAndDropText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;
`;

export const Subtext = styled.div`
  color: ${GLOBAL_BLUE};
  font-weight: 600;
  display: inline;
`;

export const cursorPointerSx = {
  cursor: 'pointer',
};

export const dialogActionsSx = {
  padding: '0.5rem 1.5rem 1.5rem 1.5rem',
};

export const closeIconSx = {
  color: COOL_GRAY,
};

export const FileUploadContentWrapper = styled.div`
  max-width: 28.25rem;
`;
