import styled from 'styled-components';
import { DARK_CHARCOAL, TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  padding: 1rem 2.5rem 0 1rem;
  margin-bottom: 1rem;
`;

export const subTitleStyle = {
  fontSize: '10px',
  color: 'text.secondary',
  padding: '0rem',
  fontWeight: '400',
};

export const titleStyle = (isStriked) => {
  return {
    fontSize: '16px',
    fontWeight: '600',
    padding: '0rem',
    textDecorationLine: checkTernaryCondition(isStriked, 'line-through', ''),
    color: checkTernaryCondition(isStriked, TYPOGRAPHY_GRAY, DARK_CHARCOAL),
  };
};

export const ProjectAddOrDeletedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
