import React, { createContext, useState } from 'react';

export const newColorThemeContext = createContext({});
export const NewColorThemeProvider = ({ children }) => {
  const [newColorTheme, setNewColorTheme] = useState(false);
  const value = React.useMemo(
    () => ({
      newColorTheme,
      setNewColorTheme,
    }),
    [newColorTheme],
  );

  return (
    <newColorThemeContext.Provider value={value}>
      {children}
    </newColorThemeContext.Provider>
  );
};
