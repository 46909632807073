import styled from 'styled-components';

const GRID_BACKGROUND_COLOR = '#FFFFFF';
const BLACK_TEXT_COLOR = '##333333';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataInputWrapper = styled.div`
  width: '100%';
  background-color: ${GRID_BACKGROUND_COLOR};
`;
export const HeaderWrapper = styled.div`
  display: flex;
  padding: 1.3rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: ${BLACK_TEXT_COLOR};
`;

export const TruncatedWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const datagridSx = {
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderLeft: 0,
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  borderRadius: 0,
  borderRight: 0,
  borderBottom: 0,
  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    lineHeight: '140%',
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    color: '#333333',
    fontSize: '0.875rem',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
  },
  '& .MuiDataGrid-cellContent': {
    lineHeight: '1.188rem',
    fontStyle: 'normal',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    color: '#333333',
    fontSize: '0.875rem',
  },
};

export const CustomPopupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 31rem;
  height: 9rem;
  gap: 2rem;
  border-radius: 5px;
`;
export const ModalMessageWrapper = styled.div`
  color: black;
  font-size: 1.5rem;
`;
export const DataGridWrapper = styled.div`
  height: 40rem;
  width: 100%;
  position: relative;
`;
export const BackDropStyle = {
  color: '#fff',
  zIndex: (theme) => theme.zIndex.drawer + 1,
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
};
