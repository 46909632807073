import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, PDFViewer } from '@react-pdf/renderer';
import TableHeader from './TableComponents/TableHeaders.react';
import RowComponent from './TableComponents/RowComponent.react';
import PageHeader from './PageHeader/PageHeader.react';
import ListItem from './PageList/PageList.react';
import Paragraph from './Paragraph/Paragraph.react';
import PageFooter from './PageFooter/PageFooter.react';
import FirstPage from './FirstPage/FirstPage.react';
import axios from 'axios';
import { FETCH_VIEW_ORIGINATION_PROJECT, PARTICIPANTS_LIST } from 'urls';
import { useParams } from 'react-router';
import { Backdrop, CircularProgress } from '@mui/material';
import ContentsPage from './ContentsPage/ContentsPage.react';
import {
  PAGE_CONTENT,
  applicationQuantApproachHeadings,
  contentMainHeadings,
  projectDetailsHeadings,
} from './GenerateOriginationPDFReport.content';
import { covertDateToLocalTimeZone } from 'utils/helper';
import FrontPageFooter from './PageFooter/FrontPageFooter.react';
import { MainDiv, styles } from './GenerateOriginationPDFReport.style';

const GenerateOriginationPDFReport = () => {
  const [originationProjectDetails, setOriginationProjectDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 9999,
  });
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    fetchProjectDetails();
    fetchParticipantsList();
  }, []);

  const fetchProjectDetails = () => {
    axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${id}`)
      .then((response) => {
        setOriginationProjectDetails(response.data);
      })
      .finally(() => setLoading(false));
  };

  const fetchParticipantsList = () => {
    axios
      .post(PARTICIPANTS_LIST, {
        filter: {
          participant: [],
          contact: [],
          participantNameOrEmailSearch: '',
        },
        projectId: id,
        pageNumber: pageState.page - 1,
        pageSize: pageState.pageSize,
        newestToOldest: false,
      })
      .then((response) => {
        let participants = [];
        response.data.content.forEach((participant) => {
          participants.push({
            id: participant.participantId,
            participantfullname:
              participant.participantFirstName +
              ' ' +
              participant.participantLastName,
            ...participant,
          });
        });
        setPageState((old) => ({
          ...old,
          data: participants,
          total: response.data.totalElements,
        }));
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      {!loading && (
        <MainDiv data-testid="generate-pdf">
          <PDFViewer style={styles?.pdfContainer}>
            <Document title={originationProjectDetails?.projectName}>
              <Page size={PAGE_CONTENT.pageSize} style={styles?.firstPage}>
                <View>
                  <FirstPage
                    originationProjectDetails={originationProjectDetails}
                  />
                </View>
                <FrontPageFooter />
              </Page>
              <Page size={PAGE_CONTENT.pageSize} style={styles?.page}>
                <PageHeader />
                <ContentsPage />
                <View style={styles?.section} break>
                  <Text style={styles?.mainheading}>
                    {contentMainHeadings[0]}
                  </Text>
                  <Paragraph
                    subheading={projectDetailsHeadings[0]}
                    textValue={originationProjectDetails?.projectDescription}
                  />
                  <Paragraph
                    subheading={projectDetailsHeadings[1]}
                    textValue={originationProjectDetails?.originationType}
                  />
                  <ListItem
                    subheading={projectDetailsHeadings[2]}
                    textValue={originationProjectDetails?.projectBlueprintList.map(
                      (item) => item?.commercializationApproach,
                    )}
                  />
                  <ListItem
                    subheading={projectDetailsHeadings[3]}
                    textValue={originationProjectDetails?.projectBlueprintList.map(
                      (item) => item?.quantificationApproach,
                    )}
                  />
                  <ListItem
                    subheading={projectDetailsHeadings[4]}
                    textValue={originationProjectDetails?.projectRegion
                      .slice(1, -1)
                      .split(',')
                      .map((region) => region.trim())}
                  />
                  <Paragraph
                    subheading={projectDetailsHeadings[5]}
                    textValue={covertDateToLocalTimeZone(
                      originationProjectDetails?.createdDate,
                    ).slice(0, 10)}
                  />
                  <ListItem
                    subheading={projectDetailsHeadings[6]}
                    textValue={originationProjectDetails?.projectBlueprintList.map(
                      (item) => item?.projectActivity,
                    )}
                  />
                  <TableHeader
                    subheading={projectDetailsHeadings[7]}
                    totalNumberOfParticipants={pageState.total}
                  />
                  <RowComponent participants={pageState.data} />
                  <Text style={styles?.mainheading}>
                    {contentMainHeadings[1]}
                  </Text>
                  <ListItem
                    subheading={applicationQuantApproachHeadings[0]}
                    textValue={originationProjectDetails?.projectBlueprintList.map(
                      (item) => item?.quantificationApproach,
                    )}
                  />
                  <Paragraph
                    subheading={applicationQuantApproachHeadings[1]}
                    textValue=""
                    wrap={true}
                  />
                </View>
                <PageFooter />
              </Page>
            </Document>
          </PDFViewer>
        </MainDiv>
      )}
    </>
  );
};
export default GenerateOriginationPDFReport;
