import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import axios from 'axios';
import { FETCH_AUDIT_LOG_DETAILS } from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';

import { covertDateToLocalTimeZone } from '../../utils/helper';

import {
  CellValueWrapper,
  DataGridWrapper,
  FilterHeader,
  MainWrapper,
  RowCellWrapper,
  TextWrapper,
  datagridSx,
} from '../../components/NewApplicationAuditLogComp/NewApplicationAuditLogComp.style';
import { PAGE_CONTENT, SortColValues } from './ViewDetailedAuditLog.content';

import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import { SortWrapper } from 'pages/OriginationProjectList/OriginationProjectList.style';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { handleArrowColor } from './ViewDetailedAuditLog.style';

const customCell = (value1, value2, color1, color2, cellClickHandler) => {
  return (
    <RowCellWrapper direction={PAGE_CONTENT.column} onClick={cellClickHandler}>
      <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
        {value1 ? value1 : '--'}
      </CellValueWrapper>
      <TextWrapper fontSize="0.75rem" fontWeight={400} color={color2}>
        {value2}
      </TextWrapper>
    </RowCellWrapper>
  );
};

const ViewDetailedAuditLog = ({
  disableVirtualization,
  participantId,
  projectId,
  projectCycleId,
}) => {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: PAGE_CONTENT.default_page_size,
  });
  const [sortFilter, setSortFilter] = useState({
    column: SortColValues.createdDate,
    order: 'DESC',
  });
  const [auditData, setAuditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_CONTENT.default_page_size);
  const [totalElements, setTotalElements] = useState();

  useEffect(() => {
    fetchAuditData();
  }, [sortFilter, pageNumber, pageSize]);

  const fetchAuditData = () => {
    setLoading(true);
    axios
      .post(
        `${FETCH_AUDIT_LOG_DETAILS}?participantId=${participantId}&projectId=${projectId}&projectCycleId=${projectCycleId}`,
        {
          pageNumber: 1,
          pageSize: pageSize,
          sortFilter,
        },
      )
      .then((response) => {
        setAuditData(
          response.data.map((item) => {
            return {
              id: uniqueId(),
              batchId: item?.batchId,
              acres: item?.acres,
              time: item?.createdDate,
              email: item?.email,
              user: item?.user,
              phase: item?.phase,
              status: item?.status,
            };
          }),
        );
        setTotalElements(response.data.totalElements);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortFilter((prevState) => ({
      column: SortColValues[params.field],
      order: prevState.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };
  const columns = [
    {
      field: PAGE_CONTENT.object_field,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>
            {PAGE_CONTENT.batchId}
          </TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                data-testid="Object-up-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Object-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => customCell(params?.row?.batchId),
    },
    {
      field: PAGE_CONTENT.phase_field,
      headerName: PAGE_CONTENT.phase,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.phase),
    },
    {
      field: PAGE_CONTENT.acres_field,
      headerName: PAGE_CONTENT.acres,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: '',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>{PAGE_CONTENT.acres}</TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                fontSize="small"
                data-testid="Acres-up-arrow"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Acres-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => customCell(params?.row?.acres),
    },
    {
      field: PAGE_CONTENT.status_field,
      headerName: PAGE_CONTENT.status,
      disableColumnMenu: true,
      flex: 1.5,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.status),
    },
    {
      field: PAGE_CONTENT.created_date_field,
      headerName: PAGE_CONTENT.time,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>{PAGE_CONTENT.time}</TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                data-testid="Time-up-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Time-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) =>
        customCell(
          covertDateToLocalTimeZone(params?.row?.time).slice(0, 10),
          new Date(params?.row?.time).toLocaleTimeString('en-US', {
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }),
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
    {
      field: PAGE_CONTENT.user_name,
      headerName: PAGE_CONTENT.user,
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderCell: (params) =>
        customCell(
          params?.row?.user,
          params?.row?.email,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
  ];

  return (
    <MainWrapper>
      <DataGridWrapper>
        <DataGrid
          headerHeight={40}
          disableVirtualization={disableVirtualization || false}
          disableSelectionOnClick
          loading={loading}
          sx={datagridSx}
          rowCount={totalElements}
          columns={columns}
          rows={auditData}
          page={pageNumber}
          paginationMode={PAGE_CONTENT.pagination_mode}
          onPageChange={(newPage) => setPageNumber(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rowsPerPageOptions={PAGE_CONTENT.default_pagination_options}
          componentsProps={{
            pagination: {
              labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
        />
      </DataGridWrapper>
    </MainWrapper>
  );
};

ViewDetailedAuditLog.propTypes = {
  disableVirtualization: PropTypes.bool,
};

export default ViewDetailedAuditLog;
