export const MODAL_CONTENT = {
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  practice_type_label: 'Edge of field practice type',
  practice_type_placeholder: 'Select edge of field type',
  practice_type_error: 'Edge of field practice type selection is required.',
  area_label: 'Edge of field area',
  area_placeholder: 'Type area',
  area_error_required: 'Edge of field area is required.',
  species_density_error: 'Species density is required.',
  area_limit_error:
    'Edge of field area can’t exceed total area of the field - {{field_area}}.',
  area_zero_error: 'Edge of field area must be greater than 0.',
  species_category_label: 'Species category',
  species_density_label: 'Species density',
  species_category_placeholder: 'Select species',
  species_category_error: 'Species category selection is required.',
  ft_unit: 'ft',
  acres: 'acres',
  trees_planted_unit: 'trees planted per acre',
  strip_width_label: 'Strip width',
  strip_width_placeholder: 'Type amount',
  strip_width_error: 'Strip width is required.',
  barrier_width_label: 'Barrier width',
  barrier_width_placeholder: 'Type amount',
  barrier_width_error: 'Barrier width is required.',
  row_count_label: 'No.of rows',
  row_count_placeholder: 'Type amount',
  row_count_error: 'Number of rows is required.',
  strip_width_required_params: ['Contour Buffer Strips', 'Field Strips'],
  barrier_width_required_params: [
    'Herbaceous Wind Barriers',
    'Vegetative Barriers',
  ],
  density_required_params: 'Riparian Forest Buffers',
  heading: 'Edge of field',
};
export const edgeOfFieldFormInitialData = {
  practiceType: '',
  area: '',
  speciesCategory: '',
  stripWidth: '',
  barrierWidth: '',
  rowCount: '',
  speciesDensity: '',
  addedFromImplementedActivities: false,
};
export const formFieldNames = {
  practiceType: 'practiceType',
  area: 'area',
  speciesCategory: 'speciesCategory',
  stripWidth: 'stripWidth',
  barrierWidth: 'barrierWidth',
  rowCount: 'rowCount',
  speciesDensity: 'speciesDensity',
};
