export const ACCESS_DENIED_PAGE_CONTENT = {
  access_denied_heading: 'Access denied',
  permission_heading: 'This page requires permission',
  permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized please contact your administrator.',
  participant_portal_permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized contact support@clearcarbon.earth.',
  participant_contributor_permission_text:
    'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized contact',
  back_button_label: 'Go back to previous page',
};
