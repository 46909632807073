import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import {
  LANDING_PAGE_CONTENT,
  MENU_PAGE_CONTENT,
  FINAL_PAGE_CONTENT,
} from './ParticipantPages.content';
import {
  EnrollmentClosedContent,
  EnrollmentClosedHeading,
  EnrollmentClosedWrapper,
  LandingPageHeading,
  LandingPageWrapper,
  MainWrapper,
  HeadingWrapper,
} from './ParticipantPages.style';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import CardBox from 'components/InfoCard/CardBox.react';
import { FETCH_ALL_USER_ACTIONS, FETCH_LANDING_PAGE_DATA } from 'urls';
import axios from 'axios';
import { WHITE } from 'theme/GlobalColors';
import { createSearchParams } from 'react-router-dom';
import { useParticipantEnrollmentDetails } from 'hooks/useParticipantEnrollmentDetails';
import { useContext, useEffect, useState } from 'react';
import {
  checkTernaryCondition,
  getBooleanValueForNullOrUndefined,
} from 'utils/helper';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { PARTICIPANT_CONTRIBUTOR } from 'utils/config';
import AccessDeniedPageForParticipantContributor from 'pages/AccessDeniedPage/AccessDeniedPageForParticipantContributor.react';
import { ContentWrapper } from 'pages/StakeholderProfile/StakeholderProfile.style';
import { CARD_CONTENT } from 'components/InfoCard/CardBox.content';

const LandingPage = ({ moduleName, isInTest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [participantDetails, setParticipantDetails] = useState({
    projectId: null,
    participantId: null,
    projectName: null,
    participantCreatedDate: null,
    participantName: '',
    participantType: '',
    projectCycleId: '',
  });
  const [statusList, setStatusList] = useState([]);
  const [newModuleName, setNewModuleName] = useState(moduleName);
  const {
    pageDetails,
    fetchPageDetails,
    loading,
    apiError,
    supportEmail,
    apiSuccessStatus,
    getClosedPageStatus,
    getPathName,
    getCompletedHeading,
    dataEmptyError,
    setDataEmptyError,
    fetchedDataEmpty,
    setFetchedDataEmpty,
    apiMessage,
    getCompletedContent,
  } = useParticipantEnrollmentDetails();
  const navigate = useNavigate();
  const [
    enrollmentContributorReturnApiError,
    setEnrollmentContributorReturnApiError,
  ] = useState(false);
  const [showClosedPage, setShowClosedPage] = useState(false);
  const [dataForCards, setDataForCards] = useState([]);
  const { userRoles, userDetails, setIsEmailLinkClicked } =
    useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);

  const fetchUserActionsLits = () => {
    axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
      setStatusList(response.data);
    });
  };
  const fetchLandingPageData = () => {
    setIsLoading(true);
    axios
      .get(FETCH_LANDING_PAGE_DATA)
      .then((response) => {
        setDataForCards(response.data);
        const objLevelData = response.data[0]?.activityTabContractLevelList[0];
        setFetchedDataEmpty(
          objLevelData === undefined || objLevelData === null,
        );
        const isEmailTriggered = true;
        setIsEmailLinkClicked(isEmailTriggered);
        setParticipantDetails({
          projectId: objLevelData?.internalProjectId,
          participantId: objLevelData?.participantOwnerId,
          projectName: objLevelData?.projectName,
          participantCreatedDate: objLevelData?.contractCreatedDate.slice(0, 4),
          participantName: objLevelData?.participantFirstName,
          participantType: objLevelData?.participantType,
          instanceId: objLevelData?.activityId,
          projectCycleId: objLevelData?.projectCycleId,
        });
      })
      .catch((error) => {
        setDataEmptyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchLandingPageData();
    fetchUserActionsLits();
  }, []);

  useEffect(() => {
    if (
      (apiSuccessStatus === LANDING_PAGE_CONTENT.api_fail_status_401 &&
        apiError === LANDING_PAGE_CONTENT.enrollment_access_denied) ||
      apiMessage === LANDING_PAGE_CONTENT.no_participantId_with_minus_one
    ) {
      return setEnrollmentContributorReturnApiError(true);
    }
  }, [apiSuccessStatus]);

  const navigateHandler = (
    moduleFromCard,
    projectId,
    participantId,
    instanceId,
    moduleName,
    projectCycleId,
    projectName,
  ) => {
    navigate(
      {
        pathname: getPathName(
          checkTernaryCondition(moduleFromCard, moduleFromCard, newModuleName),
        ),
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          ...(moduleName === LANDING_PAGE_CONTENT.enrollmentModuleName && {
            enrollmentInstanceId: instanceId,
          }),
          projectcycleid: projectCycleId,
        }).toString(),
      },
      {
        state: {
          fromNotLanding: false,
          fieldDetails: null,
          fieldData: null,
          id: checkTernaryCondition(
            participantDetails.participantType === CARD_CONTENT.contributor,
            2,
            1,
          ),
          projectName: projectName,
          participantFirstName: participantDetails?.participantFirstName,
          participantLastName: participantDetails?.participantLastName,
          projectId: projectId,
          participantId: participantId,
        },
      },
    );
  };

  useEffect(() => {
    setShowClosedPage(
      getClosedPageStatus(
        checkTernaryCondition(isInTest, 'enrollment', newModuleName),
        navigateHandler,
      ),
    );
  }, [pageDetails, dataForCards]);

  useEffect(() => {
    if (dataForCards.length >= 0) {
      fetchPageDetails(
        participantDetails.projectId,
        participantDetails.participantId,
        checkTernaryCondition(
          newModuleName === LANDING_PAGE_CONTENT.activityModuleName,
          MENU_PAGE_CONTENT.activityUrlEnrollmentType,
          MENU_PAGE_CONTENT.baselineUrlEnrollmentType,
        ),
      );
    }
  }, [dataForCards, participantDetails, dataEmptyError, fetchedDataEmpty]);

  const getClosedLandingPageDetail = (type) => {
    const getBaselineYearEnd = participantDetails.participantCreatedDate;
    const getBaselineYearStart = getBaselineYearEnd - 3;
    const getYear = new Date().getFullYear();
    if (type === LANDING_PAGE_CONTENT.headingType) {
      return getCompletedHeading(
        checkTernaryCondition(isInTest, 'enrollment', newModuleName),
        getBaselineYearStart,
        getBaselineYearEnd,
        getYear,
      );
    } else if (type === LANDING_PAGE_CONTENT.contentType) {
      return getCompletedContent(
        checkTernaryCondition(isInTest, 'enrollment', newModuleName),
        getBaselineYearStart,
        getBaselineYearEnd,
        getYear,
      );
    }
  };

  if (enrollmentContributorReturnApiError) {
    return (
      <AccessDeniedPageForParticipantContributor emailSupport={supportEmail} />
    );
  } else {
    return (
      <MainWrapper>
        {checkTernaryCondition(
          loading ||
            getBooleanValueForNullOrUndefined(
              dataForCards[0]?.activityTabContractLevelList,
            ),
          <Backdrop
            open={loading || isLoading}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="success" />
          </Backdrop>,
          <LandingPageWrapper isEnrolled={showClosedPage}>
            {showClosedPage || isInTest ? (
              <EnrollmentClosedWrapper>
                <EnrollmentClosedHeading>
                  {getClosedLandingPageDetail(LANDING_PAGE_CONTENT.headingType)}
                </EnrollmentClosedHeading>
                <EnrollmentClosedContent>
                  {getClosedLandingPageDetail(LANDING_PAGE_CONTENT.contentType)}
                </EnrollmentClosedContent>
              </EnrollmentClosedWrapper>
            ) : (
              <>
                <HeadingWrapper>
                  <LandingPageHeading>
                    {LANDING_PAGE_CONTENT.welcome_text}
                    {checkTernaryCondition(
                      isParticipantContributor,
                      userDetails?.firstName,
                      participantDetails.participantName,
                    )}
                  </LandingPageHeading>
                  <TextWrapper
                    fontSize="1.25rem"
                    color={WHITE}
                    wrap="wrap"
                    maxWidth="700px"
                    display="flex">
                    {FINAL_PAGE_CONTENT.landing_page_sub_heading1}
                  </TextWrapper>
                  <TextWrapper
                    fontSize="1.25rem"
                    color={WHITE}
                    wrap="wrap"
                    maxWidth="700px"
                    display="flex">
                    {FINAL_PAGE_CONTENT.landing_page_sub_heading2}
                  </TextWrapper>
                </HeadingWrapper>
                <ContentWrapper>
                  {dataForCards.map((data, index) => {
                    const keyVal = index;
                    return (
                      <CardBox
                        key={keyVal}
                        statusList={statusList}
                        cardDetails={data}
                        navigateHandler={navigateHandler}
                        participantId={participantDetails.participantId}
                        participantType={participantDetails?.participantType}
                        setModuleName={setNewModuleName}
                        projectName={participantDetails.projectName}
                        projectId={participantDetails.projectId}
                        setParticipantDetails={setParticipantDetails}
                        participantDetails={participantDetails}
                      />
                    );
                  })}
                </ContentWrapper>
              </>
            )}
          </LandingPageWrapper>,
        )}
      </MainWrapper>
    );
  }
};

LandingPage.propTypes = {
  moduleName: PropTypes.string,
};

export default LandingPage;
