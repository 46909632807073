import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  datagridSx,
  DataGridWrapper,
  DialogWrapper,
  FilterHeader,
  handleArrowColor,
  SearchLayout,
  SortWrapper,
  addBtnSx,
} from './Transaction.style';
import {
  TRANSACTION_LIST_API,
  DELETE_TRANSACTION,
  TRANSACTION_NAME_COLUMN,
  TRANSACTION_STAKEHOLDER_NAME_COLUMN,
  TRANSACTION_TYPE_COLUMN,
  TRANSACTION_STATUS_COLUMN,
} from '../../urls';
import {
  DEFAULT_PAGINATION_OPTIONS,
  DEFAULT_PAGE_SIZE,
  LABEL_ROWS_PER_PAGES,
  PAGINATION_MODE,
} from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import DialogBox from 'components/DialogBox/DialogBox.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import SimpleOptionsButton from '../../components/SimpleOptionsButton/SimpleOptionsButton.react';
import axios from 'axios';
import {
  SortColValues,
  TRANSACTION_CONTENT,
  addNewButtonOptions,
} from './Transaction.content';
import FilterComponent from 'components/FilterComponent/FilterComponent.react';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { TransactionSearchBox } from 'containers/TransactionSearchBox.react';
import { Box } from '@mui/system';
import { transactionSearchContext } from 'contextAPI/transactionSearch';
import NodataComponent from 'components/NoDataComp/NodataContent';
import moment from 'moment';
import { pathName } from 'Routes/routes.const';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';

import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import TabPanel from 'components/Tabber/TabPanel.react';
import { TabberWrapperStyle } from 'pages/PortfolioOverview/PortfolioOverview.style';
import { useStyles } from 'theme/GlobalStyles';
import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import { checkTernaryCondition } from 'utils/helper';
import SearchAndFilterComponent from 'components/SearchAndFilterComponent/SearchAndFilterComponent.react';
import AddButton from 'components/AddButton/AddButton.react';
import { DARK_GREEN, WHITE } from 'theme/GlobalColors';

const formatDate = (dateObj) => {
  const dates = new Date(dateObj.year, dateObj.month - 1, dateObj.dayOfMonth);
  return moment(dates).format('MM/DD/YYYY');
};

const TransactionListPage = ({ disableVirtualization }) => {
  const { TabberBox } = useStyles();

  const [currentTab, setCurrentTab] = useState(0);
  const [currentRow, setCurrentRow] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogSubtitle, setDialogSubtitle] = useState();
  const [transactionListData, setTransactionListData] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [transactionNameSelectedFilter, setTransactionNameSelectedFilter] =
    useState([]);
  const [stakeholderNameSelectedFilter, setStakeholderNameSelectedFilter] =
    useState([]);
  const [transactionTypeSelectedFilter, setTransactionTypeSelectedFilter] =
    useState([]);

  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `portfolio-page-tab-${index}`,
    };
  };

  const [filterData, setFilterData] = useState({
    TRANSACTION_NAME: [],
    STAKEHOLDER_NAME: [],
    TYPE: [],
    STATUS: [],
  });
  const [modifiedList, setModifiedList] = useState({
    TRANSACTION_NAME: [],
    STAKEHOLDER_NAME: [],
    TYPE: [],
    STATUS: [],
  });
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState({
    transactionNameFlag: false,
    stakeholderNameFlag: false,
    typeFlag: false,
    statusFlag: false,
    dateFlag: false,
  });
  const [order, setOrder] = useState(false);
  const [deleteTransactionError, setDeleteTransactionError] = useState({
    flag: false,
    message: '',
    title: '',
  });
  const [deleteTransactionImpactedList, setDeleteTransactionImpactedList] =
    useState([]);
  const [transactionSearchString, setTransactionSearchString] = useState(null);
  const [transactionSearchField, setTransactionSearchField] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    handleFilterClick();
  }, [filterData]);
  useEffect(() => {
    apiCall();
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchTransactionList();
  }, [
    pageSize,
    pageNumber,
    deleteSuccessful,
    transactionNameSelectedFilter,
    stakeholderNameSelectedFilter,
    transactionTypeSelectedFilter,
    sortColumn,
    sortOrder,
    transactionSearchString,
    transactionSearchField,
    currentTab,
  ]);

  const navigate = useNavigate();

  const postData = {
    filter: {
      stakeholderName: stakeholderNameSelectedFilter,
      transactionName: transactionNameSelectedFilter,
      type: currentTab === 0 ? ['Purchase'] : ['Sale'],
      status: [],
    },
    sortFilter: {
      newestToOldest: order,
      sortType: sortColumn,
    },
    pageNumber: pageNumber,
    pageSize: pageSize,
    transactionSearchString,
    transactionSearchField,
  };

  const deleteTransactionErrorHandler = () => {
    setDeleteTransactionError({ flag: false, message: '', title: '' });
  };

  const deleteTransaction = () => {
    setIsDialogOpen(false);
    setLoading(true);
    axios
      .delete(DELETE_TRANSACTION, {
        data: {
          contractId: currentRow?.id,
          type: currentRow?.contractType,
        },
      })
      .then((response) => {
        if (response?.data?.status === TRANSACTION_CONTENT.success) {
          toast(
            <CustomSnackbar
              type="success"
              message={`${currentRow?.contractName} ${TRANSACTION_CONTENT.success_message}`}
            />,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          setDeleteSuccessful(true);
        } else if (response?.data?.status === TRANSACTION_CONTENT.denied) {
          setLoading(false);
          const msg = response?.data?.message.split(',');
          const [title, message] = msg;
          setDeleteTransactionError({
            flag: true,
            message: message,
            title: title,
          });
          setDeleteTransactionImpactedList(response?.data?.transactionNames);
        } else {
          setDialogTitle(`${currentRow?.contractName} couldn't be deleted`);
          setDialogSubtitle(
            `${currentRow?.contractName} ${TRANSACTION_CONTENT.failed_message}`,
          );
          setErrorIsDialogOpen(true);
        }
      })
      .catch(() => {
        setDialogTitle(`${currentRow?.contractName} couldn't be deleted`);
        setDialogSubtitle(
          `${currentRow?.contractName} ${TRANSACTION_CONTENT.failed_message}`,
        );
        setErrorIsDialogOpen(true);
        setLoading(false);
      });
  };

  const fetchTransactionList = () => {
    axios.post(TRANSACTION_LIST_API, postData).then((response) => {
      setLoading(false);
      setTransactionListData(response.data.content);
      setTotalElements(response.data.totalElements);
      setIsDataFetched(true);
    });
  };

  const editHandler = (transactionId) => {
    let [type, id] = transactionId.split('/');
    if (type === 'Purchase') {
      navigate(`${pathName.transaction.purchase.edit}/${id}`, {
        state: { previousPath: '/transactions' },
      });
    } else if (type === 'Sale') {
      navigate(`${pathName.transaction.sale.edit}/${id}`, {
        state: { previousPath: '/transactions' },
      });
    }
  };

  const apiCall = () => {
    axios
      .all(
        [
          TRANSACTION_NAME_COLUMN,
          TRANSACTION_STAKEHOLDER_NAME_COLUMN,
          TRANSACTION_TYPE_COLUMN,
          TRANSACTION_STATUS_COLUMN,
        ].map((promise) => axios.get(promise)),
      )
      .then(
        axios.spread((...responses) => {
          setFilterData({
            ...filterData,
            TRANSACTION_NAME: responses[0].data,
            STAKEHOLDER_NAME: responses[1].data,
            TYPE: responses[2].data,
            STATUS: responses[3].data,
          });
        }),
      )
      .catch(() => {});
  };

  const handleFilterClick = () => {
    const transactionNameList = filterData.TRANSACTION_NAME?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    const stakeholderNameList = filterData.STAKEHOLDER_NAME?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    const typeList = filterData.TYPE?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });
    const statusList = filterData.STATUS?.map((data) => {
      return {
        id: data?.id,
        itemLabel: data?.label,
        checked: false,
      };
    });

    setModifiedList(() => ({
      ...modifiedList,
      TRANSACTION_NAME: [...transactionNameList],
      STAKEHOLDER_NAME: [...stakeholderNameList],
      TYPE: [...typeList],
      STATUS: [...statusList],
    }));
  };

  const columnsSort = {
    transactionNameFlag: false,
    stakeholderNameFlag: false,
    typeFlag: false,
    statusFlag: false,
    dateFlag: false,
  };

  const handleSorting = (flagValue, flagName) => {
    let obj = { ...columnsSort };
    obj[flagName] = !flagValue;
    setSortOrder(obj);
    setOrder(!flagValue);
  };

  const handleSortClick = (params) => {
    setLoading(true);
    setSortColumn(SortColValues[params.field]);
    switch (SortColValues[params.field]) {
      case SortColValues.contractName:
        handleSorting(sortOrder.transactionNameFlag, 'transactionNameFlag');
        break;
      case SortColValues.vendorName:
        handleSorting(sortOrder.stakeholderNameFlag, 'stakeholderNameFlag');
        break;
      case SortColValues.contractType:
        handleSorting(sortOrder.typeFlag, 'typeFlag');
        break;
      case SortColValues.status:
        handleSorting(sortOrder.statusFlag, 'statusFlag');
        break;
      case SortColValues.signedOn:
        handleSorting(sortOrder.dateFlag, 'dateFlag');
        break;
      default:
    }
  };

  const columns = [
    {
      field: 'contractName',
      headerName: 'Name',
      disableColumnMenu: true,
      sortable: false,
      flex: 5,

      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
    {
      field: 'vendorName',
      headerName: checkTernaryCondition(
        currentTab === 0,
        'Supplier',
        'Customer',
      ),
      disableColumnMenu: true,
      sortable: false,
      flex: 5,

      renderCell: (params) => <span>{params.value || '-'}</span>,
    },

    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      sortable: false,
      flex: 3,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Status</b>

          <SortWrapper
            data-testid="sort-transaction-status"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.statusFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => <span>{params.value || '-'}</span>,
    },
    {
      field: 'signedOn',
      headerName: 'Signed on',
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <b>Last Updated</b>
          <SortWrapper
            data-testid="sort-signed-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortOrder.dateFlag === true ? (
              <ArrowUpward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            ) : (
              <ArrowDownward
                fontSize="small"
                sx={handleArrowColor(params.field, sortColumn)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => (
        <div>
          {params.value !== null ? formatDate(params?.row?.signedOn) : '-'}
        </div>
      ),
    },
    {
      field: 'propoutMenu',
      headerName: ' ',
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => setCurrentRow(params?.row)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              setCurrentRow(params?.row);
            }
          }}
          data-testid={`options-${params.row?.id}`}>
          <SimpleOptionsButton
            editIcon
            editId={`${params.row?.contractType}/${params.row?.id}`}
            onEdit={editHandler}
            onDelete={() => setIsDialogOpen(true)}
          />
        </div>
      ),
      flex: 1,
    },
  ];

  const purchaseTableComponent = () => (
    <DataGridWrapper>
      <DataGrid
        disableVirtualization={disableVirtualization}
        disableSelectionOnClick
        loading={loading}
        sx={datagridSx}
        rowCount={totalElements}
        rows={transactionListData}
        columns={columns}
        paginationMode={PAGINATION_MODE}
        page={pageNumber}
        pageSize={pageSize}
        onPageChange={(newPage) => setPageNumber(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={DEFAULT_PAGINATION_OPTIONS}
        pagination
        componentsProps={{
          pagination: {
            labelRowsPerPage: LABEL_ROWS_PER_PAGES,
            SelectProps: TablePaginationMenuListStyle,
          },
        }}
        onRowClick={(row) => handleViewTransaction(row)}
      />
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDialogOpen}
        onConfirm={() => deleteTransaction()}
        subtitle={`${TRANSACTION_CONTENT.delete_message_line1?.slice(0, 32)} ${
          currentRow?.contractName
        } ${TRANSACTION_CONTENT.delete_message_line1?.slice(31)}`}
        onCancel={() => setIsDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Delete"
        title={`Delete ${currentRow?.contractName}`}>
        <DialogWrapper>
          {TRANSACTION_CONTENT.delete_message_line2}
        </DialogWrapper>
      </DialogBox>

      <DialogBox
        deleteOperation
        isOpen={isErrorDialogOpen}
        subtitle={dialogSubtitle}
        onCancel={() => setErrorIsDialogOpen(false)}
        title={dialogTitle}
      />
      <DialogBox
        dialogActions
        deleteOperation
        isOpen={deleteTransactionError.flag}
        title={deleteTransactionError.title}
        transactionList={deleteTransactionImpactedList}
        onConfirm={deleteTransactionErrorHandler}
        onCancel={deleteTransactionErrorHandler}
        acceptCtnLabel="Close">
        {deleteTransactionError.message}{' '}
      </DialogBox>
    </DataGridWrapper>
  );

  const tabsComponent = () => (
    <div>
      <SummaryViewHeader noBackButton title={'Transactions'} />
      <Box sx={TabberWrapperStyle}>
        <TabberBox>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="page-tabs">
            {transactionsTab.map((tab, index) => (
              <Tab
                sx={{ textTransform: 'none', width: 'unset' }}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={tab.title}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
    </div>
  );

  const transactionsTab = [
    {
      title: 'Purchase',
      component: purchaseTableComponent(),
    },
    {
      title: 'Sale',
      component: purchaseTableComponent(),
    },
  ];

  const handleViewTransaction = (row) => {
    if (row.row.contractType === 'Purchase') {
      navigate(`${pathName.transaction.purchase.view}/${row.id}`, {
        state: { previousPath: pathName.assets.transactions },
      });
    } else if (row.row.contractType === 'Sale') {
      navigate(`${pathName.transaction.sale.view}/${row.id}`, {
        state: { previousPath: pathName.assets.transactions },
      });
    }
  };
  const searchProviderValue = React.useMemo(
    () => ({
      transactionSearchString,
      setTransactionSearchString,
      transactionSearchField,
      setTransactionSearchField,
    }),
    [transactionSearchString, transactionSearchField],
  );
  if (isDataFetched && transactionListData.length == 0) {
    return (
      <div>
        {tabsComponent()}
        <div style={{ display: 'flex', gap: '0.875rem' }}>
          <SearchAndFilterComponent
            hasSeachBox
            searchBoxPlaceholder={checkTernaryCondition(
              currentTab === 0,
              TRANSACTION_CONTENT.sarchTransactionPlacement,
              TRANSACTION_CONTENT.sarchNamePlacement,
            )}
            filterSections={[]}
          />
          <AddButton
            buttonName={'Add transaction'}
            backgroundColor={DARK_GREEN}
            textColor={WHITE}
            customBtnSx={addBtnSx}
            addBtnPadding={'0.406rem'}
            noDropdown
          />
        </div>
        <NodataComponent
          nodata={TRANSACTION_CONTENT.noDataContent}
          addNewLabel={TRANSACTION_CONTENT.addNewTransactionLabel}
          addbuttonLabel={{
            name: TRANSACTION_CONTENT.addButton,
            link: pathName.transaction.purchase.add,
          }}
          addNewButtonOptions={addNewButtonOptions}
        />
      </div>
    );
  } else {
    return (
      <transactionSearchContext.Provider value={searchProviderValue}>
        {tabsComponent()}
        <div style={{ display: 'flex', gap: '0.875rem' }}>
          <SearchAndFilterComponent
            hasSeachBox
            searchBoxPlaceholder={checkTernaryCondition(
              currentTab === 0,
              'Search by transaction or project name',
              'Search by name or customer',
            )}
            filterSections={[]}
          />
          <AddButton
            buttonName={'Add transaction'}
            backgroundColor={DARK_GREEN}
            textColor={WHITE}
            customBtnSx={addBtnSx}
            addBtnPadding={'0.406rem'}
            noDropdown
          />
        </div>
        {transactionsTab.map((tab, index) => (
          <TabPanel key={tab.title} value={currentTab} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </transactionSearchContext.Provider>
    );
  }
};

TransactionListPage.propTypes = {
  disableVirtualization: PropTypes.bool,
};

export default TransactionListPage;
