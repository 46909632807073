import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import DialogBox from 'components/DialogBox/DialogBox.react';
import axios from 'axios';
import CustomCell from 'components/CustomCell/CustomCell.react';
import NodataComponent from 'components/NoDataComp/NodataContent';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import AddUserForm from 'components/UserManagementModal/AddUser.react';
import FiltersPopup from 'pages/OriginationProjectList/components/FiltersPopup/FiltersPopup.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  datagridSx,
  DataGridWrapper,
  MainWrapper,
} from 'pages/ProjectCatalog/ProjectCatalog.style';
import React, { useEffect, useState, Fragment, useMemo, useRef } from 'react';
import {
  listingUserColumn,
  PAGE_CONTENT,
  toastStyle,
  getRoleLabel,
  roleMapping,
} from './UserList.content';
import { REMOVE_USER, USER_LIST, USER_ROLES } from 'urls';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { dateFormatter, dateTimeFormatter } from 'utils/dateFormatter';
import { checkTernaryCondition, covertDateToLocalTimeZone } from 'utils/helper';
import {
  clearIconStyle,
  HeaderWrapper,
  roleNumberstyle,
} from './UserList.style';
import LightTooltip from 'components/LightTooltip';
import { COOL_GRAY } from 'theme/GlobalColors';
import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import {
  SearchAndFilterSection,
  SearchAndFiltersFlex,
} from 'pages/OriginationProjectList/OriginationProjectList.style';
import {
  SearchBox,
  AppliedFilters,
  FilterChip,
  ClearLink,
  filterClearIconSx,
} from 'pages/OriginationProjectList/OriginationProjectList.style';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { debounceFunc } from 'utils/helper';

const UserList = ({ disableVirtualization }) => {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState({ id: 0, flag: false });
  const [deleteUserRow, setDeleteUserRow] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isClosed, setIsClosed] = useState('');

  const [userNameSearchText, setUserNameSearchText] = useState('');
  const [roleFilterList, setRoleFilterList] = useState([]);
  const [roles, setRoles] = useState([]);
  const debounceTimerRef = useRef(null);
  const filterSections = useMemo(
    () => [
      {
        heading: 'Role',
        filters: roles?.map((role) => ({
          id: role.replace(/\s+/g, '-'), // Replace spaces with hyphens for the id
          itemLabel: role,
          checked: roleFilterList.includes(role),
        })),
      },
    ],
    [roleFilterList, roles],
  );

  const updateFilterStates = (sectionHeading, checkedFields) => {
    if (sectionHeading === 'Role') {
      setRoleFilterList(checkedFields);
    }
  };

  const handleFilterSectionUpdate = (sections) => {
    sections.forEach((section) => {
      const checkedFields = section.filters
        .filter((filter) => filter.checked === true)
        .map((filter) => filter.itemLabel);
      updateFilterStates(section.heading, checkedFields);
    });
  };

  const removeFilter = ({ sectionIdx, filterIdx }) => {
    const filterLabelToBeRemoved =
      filterSections[sectionIdx].filters[filterIdx].itemLabel;
    const checkedFields = filterSections[sectionIdx].filters
      .filter(
        (filter) =>
          filter.checked === true &&
          filter.itemLabel !== filterLabelToBeRemoved,
      )
      .map((filter) => filter.itemLabel);
    updateFilterStates(filterSections[sectionIdx].heading, checkedFields);
  };

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    filterSections.forEach((section) => {
      section.filters.map((filter) => {
        if (filter.checked) count++;
      });
    });

    return count;
  }, [filterSections]);

  const handleOnClickEdit = (id) => {
    setIsClosed(true);
    setIsEditUserOpen({ id: id, flag: true });
  };
  const handleOnClickDelete = (row) => {
    setDeleteUserRow(row);
    setIsDeleteModalOpen(true);
    setIsClosed(true);
  };
  const handleCancelOnDelete = () => {
    setIsDeleteModalOpen(false);
    setIsClosed(true);
  };
  const deleteUser = (id, userName) => {
    setIsDeleteModalOpen(false);
    setPageState((old) => ({ ...old, isLoading: true }));
    axios
      .delete(`${REMOVE_USER}${id}`)
      .then(() => {
        toast(
          <div data-testid="success-toast">
            <CustomSnackbar
              type="success"
              message={`${userName} ${PAGE_CONTENT.toast_success_message}`}
            />
          </div>,
          toastStyle,
        );
      })
      .catch((error) => {
        if (error.response?.data?.message?.includes(PAGE_CONTENT.pending)) {
          toast(
            <CustomSnackbar
              type="error"
              message={`${userName} ${PAGE_CONTENT.pending_user_activation_error_message}`}
            />,
            toastStyle,
          );
        } else {
          toast(
            <CustomSnackbar
              type="error"
              message={`${userName} ${PAGE_CONTENT.toast_error_message}`}
            />,
            toastStyle,
          );
        }
      })
      .finally(() => {
        setPageState((old) => ({ ...old, isLoading: false }));
        fetchUserList();
      });
  };

  // Function to convert roles from string to array format
  function convertRolesToArray(roles) {
    // Check if roles is not null and not empty
    if (roles && roles.trim() !== '') {
      // Split the roles string by comma and return the array
      return roles.split(',');
    } else {
      // If roles is null or empty, return an empty array
      return [];
    }
  }

  const fetchUserList = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    axios
      .post(USER_LIST, {
        pageNumber: pageState.page - 1,
        pageSize: pageState.pageSize,
        roles: roleFilterList.map((item) => {
          return roleMapping(item);
        }),
        searchTerm: userNameSearchText,
      })
      .then((response) => {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content.map((user) => ({
            userId: user.userId,
            userName: `${user.firstName} ${user.lastName}`,
            userEmail: user.email,
            company: 'Green Foods CO',
            role: convertRolesToArray(user.userRoleList).map((role) =>
              getRoleLabel(role),
            ),
            createdDate: user.createdDate,
            lastActivity: user.lastActivity,
          })),
          total: response.data.totalElements,
        }));
        setIsDataFetched(true);
      });
  };
  const fetchRolesList = () => {
    axios.get(USER_ROLES).then((response) => {
      const userRoles = response?.data?.map((role) => getRoleLabel(role));
      setRoles(userRoles);
    });
  };
  const debouncedFetchUserList = debounceFunc(
    fetchUserList,
    500,
    debounceTimerRef,
  );
  useEffect(() => {
    setPageState((old) => ({ ...old, page: 1 }));
  }, [roleFilterList]);
  useEffect(() => {
    debouncedFetchUserList();
  }, [userNameSearchText, roleFilterList]);

  useEffect(() => {
    fetchUserList();
    fetchRolesList();
  }, []);

  useEffect(() => {
    fetchUserList();
  }, [pageState.pageSize, pageState.page]);

  const columns = [
    {
      field: listingUserColumn.userId.field,
      hide: true,
    },
    {
      field: listingUserColumn.userName.field,
      headerName: listingUserColumn.userName.label,
      sortable: false,
      disableColumnMenu: true,
      width: 280,
    },
    {
      field: listingUserColumn.userEmail.field,
      headerName: listingUserColumn.userEmail.label,
      sortable: false,
      disableColumnMenu: true,
      width: 300,
    },
    {
      field: listingUserColumn.company.field,
      headerName: listingUserColumn.company.label,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
    },
    {
      field: listingUserColumn.role.field,
      headerName: listingUserColumn.role.label,
      sortable: false,
      disableColumnMenu: true,
      width: 228,
      renderCell: (params) =>
        checkTernaryCondition(
          params.value.length === 1,
          <div {...params}>{params.value[0]}</div>,
          <LightTooltip
            title={params.value.map((role) => (
              <div key={role}>{role}</div>
            ))}>
            <div {...params} style={roleNumberstyle}>
              {params.value.length} {PAGE_CONTENT.roles}
            </div>
          </LightTooltip>,
        ),
    },
    {
      field: listingUserColumn.createdOn.field,
      headerName: listingUserColumn.createdOn.label,
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value !== null ? (
            <CustomCell
              value1={dateFormatter(params.value)}
              value2={dateTimeFormatter(
                covertDateToLocalTimeZone(params.value),
              )}
              color={COOL_GRAY}
            />
          ) : (
            '--'
          )}
        </div>
      ),
    },
    {
      field: listingUserColumn.lastActivity.field,
      headerName: listingUserColumn.lastActivity.label,
      disableColumnMenu: true,
      sortable: false,
      width: 168,
      renderCell: (params) => (
        <div>
          {params.value !== null ? (
            <CustomCell
              value1={dateFormatter(params.value)}
              value2={dateTimeFormatter(
                covertDateToLocalTimeZone(params.value),
              )}
              color={PAGE_CONTENT.cool_gray}
            />
          ) : (
            '--'
          )}
        </div>
      ),
    },
    {
      field: 'options',
      headerName: '',
      disableColumnMenu: true,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <div data-testid={`options-${params.row?.userId}`}>
          <SimpleOptionsButton
            isClosed={isClosed}
            onEdit={() => {
              handleOnClickEdit(params.row.userId);
            }}
            onDelete={() => {
              handleOnClickDelete(params.row);
            }}
            removeOrDelete
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <HeaderWrapper>
        <SearchAndFilterSection>
          <div style={SearchAndFiltersFlex}>
            <SearchBox
              variant="outlined"
              placeholder={PAGE_CONTENT.search_placeholder}
              value={userNameSearchText}
              onChange={(event) => {
                setUserNameSearchText(
                  event.target.value.replace(/[^a-zA-Z0-9@._-\s]/g, ''),
                );
              }}
              InputProps={{
                startAdornment: (
                  <Fragment>
                    <SearchIcon sx={{ color: TYPOGRAPHY_GRAY }} />
                  </Fragment>
                ),
                endAdornment: userNameSearchText?.length > 0 && (
                  <ClearIcon
                    onClick={() => {
                      setIsDataFetched(false);
                      setUserNameSearchText('');
                    }}
                    sx={filterClearIconSx}
                  />
                ),
              }}
            />
            <AppliedFilters>
              {filterSections.map((section, sectionIdx) => {
                return section.filters.map((filter, filterIdx) => {
                  return (
                    filter.checked && (
                      <FilterChip
                        key={uniqueId()}
                        data-testid={`${section.heading}: ${filter.itemLabel}`}>
                        <div>{`${section.heading}: ${filter.itemLabel}`}</div>
                        <ClearIcon
                          onClick={() =>
                            removeFilter({
                              sectionIdx: sectionIdx,
                              filterIdx: filterIdx,
                            })
                          }
                          sx={clearIconStyle}
                        />
                      </FilterChip>
                    )
                  );
                });
              })}
              {appliedFiltersCount > 1 && (
                <ClearLink
                  onClick={() => {
                    updateFilterStates('Role', []);
                  }}>
                  {PAGE_CONTENT.clear_all}
                </ClearLink>
              )}
            </AppliedFilters>
          </div>
          <FiltersPopup
            filterSections={filterSections}
            updateFilters={handleFilterSectionUpdate}
            isFiltersApplied={appliedFiltersCount > 0}
          />
        </SearchAndFilterSection>
        <AddUserForm
          isUserDataEmpty={false}
          isOpen={isEditUserOpen}
          setIsOpen={setIsEditUserOpen}
          fetchUserList={fetchUserList}
          setEditRemoveToClose={setIsClosed}
        />
      </HeaderWrapper>
      {isDataFetched &&
      pageState.data?.length === 0 &&
      userNameSearchText === '' ? (
        <>
          <NodataComponent
            nodata={PAGE_CONTENT.noDataContent}
            addNewLabel={PAGE_CONTENT.addNewUserLabel}
            addbuttonLabel={{
              name: PAGE_CONTENT.addButton,
              link: '',
            }}
            onClick={() => {
              setIsEditUserOpen({ id: 0, flag: true });
            }}
          />
          <AddUserForm
            isUserDataEmpty={true}
            isOpen={isEditUserOpen}
            setIsOpen={setIsEditUserOpen}
            fetchUserList={fetchUserList}
            setEditRemoveToClose={setIsClosed}
          />
        </>
      ) : isDataFetched &&
        pageState.data?.length === 0 &&
        userNameSearchText !== '' ? (
        <>
          <NodataComponent
            filterData={PAGE_CONTENT.filter_content}
            searchTermSuffix={PAGE_CONTENT.search_text_suffix}
            searchTerm={`"${userNameSearchText}".`}
            width={PAGE_CONTENT.filter_content_width}
          />
          <AddUserForm
            isUserDataEmpty={true}
            isOpen={isEditUserOpen}
            setIsOpen={setIsEditUserOpen}
            fetchUserList={fetchUserList}
            setEditRemoveToClose={setIsClosed}
          />
        </>
      ) : (
        <MainWrapper className="curved-border">
          <DataGridWrapper>
            <DataGrid
              headerHeight={40}
              disableVirtualization={disableVirtualization}
              rows={pageState.data || []}
              rowsPerPageOptions={[25, 50, 75]}
              getRowId={(row) => row.userId}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: 'Items per page',
                  SelectProps: TablePaginationMenuListStyle,
                },
              }}
              disableSelectionOnClick
              pagination
              paginationMode="server"
              pageSize={pageState.pageSize}
              sx={datagridSx}
              page={pageState.page - 1}
              onPageChange={(newPage) =>
                setPageState((old) => ({ ...old, page: newPage + 1 }))
              }
              columns={columns}
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({ ...old, pageSize: newPageSize }))
              }
            />
          </DataGridWrapper>
        </MainWrapper>
      )}
      <DialogBox
        title={`${PAGE_CONTENT.remove} ${deleteUserRow?.userName}`}
        subtitle={`${PAGE_CONTENT.remove_confirmation_message} ${deleteUserRow?.userName}?`}
        isOpen={isDeleteModalOpen}
        onConfirm={() => {
          deleteUser(deleteUserRow?.userId, deleteUserRow?.userName);
        }}
        onCancel={() => handleCancelOnDelete()}
        acceptCtnLabel={PAGE_CONTENT.remove}
        declineCtnLabel={PAGE_CONTENT.cancel}
        deleteOperation
        dialogActions
      />
    </>
  );
};
UserList.propTypes = {
  disableVirtualization: PropTypes.bool,
};

export default UserList;
