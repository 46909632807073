export const listingParticipantReportsColumn = {
  reportName: {
    label: 'Name',
    field: 'reportName',
  },
  reportType: {
    label: 'Type',
    field: 'reportType',
  },
  generatedBy: {
    label: 'Generated by',
    field: 'generatedBy',
  },
  generatedOn: {
    label: 'Generated on',
    field: 'generatedOn',
  },
  options: {
    label: '',
    field: 'options',
  },
};
export const SortColNames = {
  generatedOn: 'generatedOn',
};
export const ORIGINATION_PARTICIPANT_REPORTS_LIST_CONTENT = {
  generateBtn: 'Generate report',
  column: 'column',
  right: 'right',
  left: 'left',
  center: 'center',
  underscore: '_',
  dropdownPlaceholder: 'Select project',
  inputFieldPlaceholder: 'Enter report name',
  noDataContent: 'No reports have been generated for this participant.',
  addNewUserLabel: 'Generate a new report to get started!',
  error_message: 'failed to be saved. Please try again.',
  success_message_pt1: 'was successfully saved to',
  success_message_pt2: 'reports.',
  project_required_error: 'A project is required.',
  report_name_required_error: 'A report name is required',
  report_name_invalid_error:
    'There are invalid characters in the report name. Please only use letters (a-z)(A-Z), numbers (0-9), underscore (_), hyphen (-).',
  generateReportModalBtn: 'Generate report',
  dialogWidth: true,
  inputFieldLabel: 'Report Name',
  inputFieldWidth: '100%',
  success: 'success',
  error: 'error',
  downloadPDFLabel: 'Download PDF',
  downloadCSVLabel: 'Download CSV',
  pdf: '.pdf',
  generate: 'Generate',
  report: 'report',
  cancel: 'Cancel',
  dropDownWidth: '15rem',
  dropDownLabel: 'Select project',
  dropDownHeight: '2.2rem',
  delete_message: 'Are you sure you want to delete?',
  success_codes: [200, 201, 204],
  success_deletion_message: 'was successfully deleted.',
  error_deletion_message:
    'couldn’t be deleted due to a technical error. Please try again.',
  delete: 'Delete',
  genrateReportBtnPadding: '0 0 0.5rem 0',
};
export const generateReportButtonOptions = [
  { id: 1, name: 'Participant Project Summary', isDisabled: false },
  { id: 2, name: 'Participant Project Calculations', isDisabled: true },
];
export const errorInitialState = {
  projectRequiredError: false,
  reportNameRequiredError: false,
  reportNameInvalidError: false,
};
export const FALSE = false;
export const checkFieldIsNotEmpty = (value) => {
  if (value !== null && value !== undefined) {
    return value.trim() && FALSE;
  } else {
    return FALSE;
  }
};
