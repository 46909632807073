import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    justifyContent: 'space-between',
    left: 35,
    top: 40,
    right: 35,
  },
  description: {
    color: 'gray',
    fontSize: '14px',
    paddingRight: 5,
  },
  companyLogo: {
    width: '140px',
  },
});
