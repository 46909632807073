import PropTypes from 'prop-types';
import {
  formFieldNames,
  liveStockTypeFormInitialData,
  MODAL_CONTENT,
  modalErrorInitialState,
  unitOfMeasurementOptions,
} from './LivestockTypeModal.content';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
} from '../Modals.style';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { addZeroBeforeDot, checkTernaryCondition } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import { useForm } from 'hooks/useForm';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useEffect, useState, useContext } from 'react';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
  TextWrapper,
} from 'components/FormComponents/FormStyles';
import { RED } from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

const LivestockTypeModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
  totalFieldArea,
}) => {
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    liveStockTypeFormInitialData,
  );
  const [errorState, setErrorState] = useState(modalErrorInitialState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const {
    livestockTypeDropdown,
    grazingTypeDropDown,
    manureManagementSystemDropdown,
  } = useContext(baselineDropdownContext);
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(liveStockTypeFormInitialData);
    setErrorState(modalErrorInitialState);
  };
  const setModalErrorState = (value, fieldName) => {
    let newErrorState = { ...errorState };
    newErrorState[fieldName] = value === '';
    setErrorState(newErrorState);
  };

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      setFormValue(
        checkTernaryCondition(
          modalData,
          modalData,
          liveStockTypeFormInitialData,
        ),
      );
    }
  }, [modalOpen]);

  const livestockTypeDropdownProps = {
    label: MODAL_CONTENT.livestock_type_label,
    value: checkTernaryCondition(
      formValue.livestockType === '',
      MODAL_CONTENT.livestock_type_placeholder,
      formValue.livestockType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    fontSize: '0.875rem',
    listItemFontSize: '0.875rem',
    name: formFieldNames.livestockType,
    ariaLabel: MODAL_CONTENT.livestock_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.livestock_type_placeholder,
    error: errorState.livestockType,
    errorMessage: MODAL_CONTENT.type_error,
    dropdownlist: livestockTypeDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.livestockType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'livestockType',
    hasNoBottomMargin: true,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };

  const prescribedGrazingDropdownProps = {
    label: MODAL_CONTENT.prescirbed_grazing_type_label,
    value: checkTernaryCondition(
      formValue.prescribedGrazingType === '',
      MODAL_CONTENT.prescirbed_grazing_type_placeholder,
      formValue.prescribedGrazingType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    fontSize: '0.875rem',
    listItemFontSize: '0.875rem',
    name: formFieldNames.prescribedGrazingType,
    ariaLabel: MODAL_CONTENT.prescirbed_grazing_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.prescirbed_grazing_type_placeholder,
    error: errorState.prescribedGrazingType,
    errorMessage: MODAL_CONTENT.grazing_type_error,
    dropdownlist: grazingTypeDropDown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.prescribedGrazingType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'prescribedGrazingType',
    hasNoBottomMargin: true,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };

  const unitOfMeasurementDropdownProps = {
    label: MODAL_CONTENT.unit_of_measure_label,
    value: checkTernaryCondition(
      formValue.unitOfMeasure === '',
      MODAL_CONTENT.unit_of_measure_placeholder,
      formValue.unitOfMeasure,
    ),
    isDisabled: false,
    width: '17.875rem',
    height: '2rem',
    fontSize: '0.875rem',
    listItemFontSize: '0.875rem',
    name: formFieldNames.unitOfMeasure,
    ariaLabel: MODAL_CONTENT.unit_of_measure_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.unit_of_measure_placeholder,
    error: errorState.unitOfMeasure,
    errorMessage: '',
    dropdownlist: unitOfMeasurementOptions,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.unitOfMeasure,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'unitOfMeasure',
    hasNoBottomMargin: true,
    labelMarginTop: '0rem',
    labelMarginBottom: '0rem',
  };

  const manureManagementDropdownProps = {
    label: MODAL_CONTENT.manure_management_label,
    value: checkTernaryCondition(
      formValue.manureManagementSystem === '',
      MODAL_CONTENT.prescirbed_grazing_type_placeholder,
      formValue.manureManagementSystem,
    ),
    isDisabled: false,
    width: '13.75rem',
    fontSize: '0.875rem',
    listItemFontSize: '0.875rem',
    height: '2rem',
    name: formFieldNames.manureManagementSystem,
    ariaLabel: MODAL_CONTENT.prescirbed_grazing_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.prescirbed_grazing_type_placeholder,
    error: errorState.manureManagementSystem,
    errorMessage: MODAL_CONTENT.manure_management_error,
    dropdownlist: manureManagementSystemDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.manureManagementSystem,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'manureManagementSystem',
    hasNoBottomMargin: true,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };
  const submitBtnHandler = () => {
    const error = {
      livestockType: formValue.livestockType === '',
      prescribedGrazingType: formValue.prescribedGrazingType === '',
      averageWeight: formValue.averageWeight === '',
      unitOfMeasure: formValue.unitOfMeasure === '',
      livestockStockingRatePerField:
        formValue.livestockStockingRatePerField === '',
      annualGrazingDays: formValue.annualGrazingDays === '',
      manureManagementSystem: formValue.manureManagementSystem === '',
      livestockGrazingAreaRequiredError: !formValue.livestockGrazingArea.trim(),
      livestockGrazingAreqEqualToZeroError:
        formValue.livestockGrazingArea.trim() &&
        +formValue.livestockGrazingArea === 0,
      livestockGrazingAreaExceededError:
        formValue.livestockGrazingArea > totalFieldArea,
    };
    setErrorState(error);
    if (!Object.values(error).includes(true)) {
      submitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        modalOpen?.parentId,
        BASELINE_MODAL_CONTENT.livestock_list,
      );
      modalCloseHandler();
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={{ ...DialogContentStyle, marginBottom: '0.5rem' }}>
        <FieldSectionWrapper marginBottom="0.5rem">
          <DropDown {...livestockTypeDropdownProps} />
        </FieldSectionWrapper>
        <FieldSectionWrapper>
          <InputField
            required
            label={MODAL_CONTENT.livestock_grazing_area_label}
            name={formFieldNames.livestockGrazingArea}
            value={formValue.livestockGrazingArea}
            customStyles={{
              marginBottom: '0.5rem',
              marginTop: '-1rem',
            }}
            rightSideLabelFormLabelMargin="0.5rem"
            labelMarginBottom="0.25rem"
            onUpdate={(e) => {
              if (
                e.target.value.match(/^\d+(\.\d{0,2})?$/) ||
                e.target.value === ''
              ) {
                customFieldChangeHandler(
                  formFieldNames.livestockGrazingArea,
                  e.target.value,
                );
                setErrorState({
                  ...errorState,
                  livestockGrazingAreaRequiredError: !e.target.value.trim(),
                  livestockGrazingAreqEqualToZeroError:
                    e.target.value.trim() && +e.target.value === 0,
                  livestockGrazingAreaExceededError:
                    e.target.value > totalFieldArea,
                });
              }
            }}
            placeholder={MODAL_CONTENT.livestock_grazing_area_placeholder}
            primaryError={errorState.livestockGrazingAreaRequiredError}
            primaryErrorMessage={
              MODAL_CONTENT.livestock_grazing_area_required_error
            }
            secondaryError={
              errorState.livestockGrazingAreaExceededError ||
              errorState.livestockGrazingAreqEqualToZeroError
            }
            secondaryErrorMessage={checkTernaryCondition(
              errorState.livestockGrazingAreaExceededError,
              MODAL_CONTENT.livestock_grazing_area_exceeded_error.replace(
                '{{field_area}}',
                totalFieldArea,
              ),
              MODAL_CONTENT.livestock_grazing_area_zero_error,
            )}
            maxLength={100}
            minWidth="10rem"
            width="7rem"
            hasPredefinedUnit
            predefinedUnit={MODAL_CONTENT.livestock_grazing_area_unit}
          />
        </FieldSectionWrapper>
        <FieldSectionWrapper marginBottom="0.5rem">
          <DropDown {...prescribedGrazingDropdownProps} />
        </FieldSectionWrapper>
        <FieldSectionWrapper columnGap="1.5rem">
          <InputField
            width="8.75rem"
            required
            label={MODAL_CONTENT.livestock_rating_per_field_label}
            name={formFieldNames.livestockStockingRatePerField}
            value={formValue.livestockStockingRatePerField}
            customStyles={{
              marginTop: '-1rem',
              marginBottom: '1rem',
            }}
            rightSideLabelFormLabelMargin="0.5rem"
            labelMarginBottom="0.25rem"
            onUpdate={(e) => {
              if (
                e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                e.target.value === ''
              ) {
                customFieldChangeHandler(
                  formFieldNames.livestockStockingRatePerField,
                  addZeroBeforeDot(e.target.value),
                );
              }
              setErrorState({
                ...errorState,
                livestockStockingRatePerField: e.target.value === '',
              });
            }}
            placeholder={MODAL_CONTENT.livestock_rating_per_field_placeholder}
            primaryError={errorState.livestockStockingRatePerField}
            primaryErrorMessage={MODAL_CONTENT.stocking_rate_error}
            maxLength={100}
            minWidth="8.75rem"
          />
          <InputField
            width="8.75rem"
            required
            label={MODAL_CONTENT.annual_grazing_days_label}
            name={formFieldNames.annualGrazingDays}
            value={formValue.annualGrazingDays}
            rightSideLabelFormLabelMargin="0.5rem"
            labelMarginBottom="0.25rem"
            customStyles={{
              marginTop: '-1rem',
              marginBottom: '1rem',
            }}
            onUpdate={(e) => {
              const value = e.target.value;
              if ((value.match(/^\d+$/) && +value <= 365) || value === '') {
                customFieldChangeHandler(
                  formFieldNames.annualGrazingDays,
                  e.target.value,
                );
              }
              setErrorState({
                ...errorState,
                annualGrazingDays: e.target.value === '',
              });
            }}
            placeholder={MODAL_CONTENT.annual_grazing_days_placeholder}
            primaryError={errorState.annualGrazingDays}
            primaryErrorMessage={MODAL_CONTENT.grazing_days_error}
            maxLength={100}
            minWidth="8.75rem"
          />
        </FieldSectionWrapper>
        <FieldSectionWrapper columnGap="0.5rem">
          <InputField
            width="8.75rem"
            required
            label={MODAL_CONTENT.average_weight_label}
            name={formFieldNames.averageWeight}
            value={formValue.averageWeight}
            rightSideLabelFormLabelMargin="0.5rem"
            labelMarginBottom="0.25rem"
            customStyles={{
              marginBottom: '0.5rem',
              marginTop: '-1rem',
            }}
            onUpdate={(e) => {
              if (
                e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                e.target.value === ''
              ) {
                customFieldChangeHandler(
                  formFieldNames.averageWeight,
                  addZeroBeforeDot(e.target.value),
                );
              }
              setErrorState({
                ...errorState,
                averageWeight: e.target.value === '',
              });
            }}
            placeholder={MODAL_CONTENT.average_weight_placeholder}
            primaryError={errorState.averageWeight}
            primaryErrorMessage={MODAL_CONTENT.annual_weight_error}
            maxLength={100}
            minWidth="8.75rem"
          />
          <DropDown {...unitOfMeasurementDropdownProps} />
        </FieldSectionWrapper>
        {errorState.unitOfMeasure && (
          <TextWrapper color={RED} fontSize="12px">
            {MODAL_CONTENT.uom_error}
          </TextWrapper>
        )}
        <FieldSectionWrapper marginBottom="0.5rem">
          <DropDown {...manureManagementDropdownProps} />
        </FieldSectionWrapper>
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={submitBtnHandler}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LivestockTypeModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  totalFieldArea: PropTypes.number,
};
export default LivestockTypeModal;
