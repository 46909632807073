import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  AddedDeletedList,
  NonStrikeOutText,
  StrikeOutText,
  WhiteChip,
  titleStyle,
} from './AddedDeletedTable.style';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { checkTernaryCondition } from 'utils/helper';
import { accordionSx } from 'components/ActionModal/ActionModal.style';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

const AddedDeletedTable = ({ title, addedDeletedList }) => {
  return (
    <Accordion defaultExpanded sx={accordionSx}>
      <AccordionSummary
        expandIcon={<KeyboardArrowRightIcon sx={{ color: DARK_CHARCOAL }} />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <div>
          <Typography sx={titleStyle}>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {addedDeletedList.length > 0 &&
          addedDeletedList.map((list) => (
            <AddedDeletedList key={uniqueId()}>
              {checkTernaryCondition(
                list.status === 'Deleted',
                <StrikeOutText>{list.value}</StrikeOutText>,
                <NonStrikeOutText>{list.value}</NonStrikeOutText>,
              )}

              <WhiteChip>{list.status}</WhiteChip>
            </AddedDeletedList>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

AddedDeletedTable.propTypes = {
  title: PropTypes.string,
  addedDeletedList: PropTypes.array,
};

export default AddedDeletedTable;
