import React from 'react';
import { TextWrapper } from '../OriginationSummaryTab/OriginationSummaryTab.style';
import { Container } from './ParticipantIncettivesCard.style';
import { COOL_GRAY, DARK_CHARCOAL, QUILL_GRAY } from 'theme/GlobalColors';
import ColorIcon from '../Org_Project/Participants/ColorIcon/ColorIcon.react';
import { ACTUAL, PROJECTED } from 'utils/config';
import PropTypes from 'prop-types';
import { checkTernaryCondition } from 'utils/helper';
import { CARD_CONTENT } from './ParticipantIncentivesCard.content';

const ParticipantIncentivesCard = ({ data }) => {
  const returnHeaderColor = () => {
    return checkTernaryCondition(
      data.averageProjectedIncentive === 0 &&
        data.totalProjectedIncentive === 0 &&
        data.averageActualIncentive === 0 &&
        data.totalActualIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  const returnProjectedHeaderColor = () => {
    return checkTernaryCondition(
      data.averageProjectedIncentive === 0 &&
        data.totalProjectedIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  const returnActualHeaderColor = () => {
    return checkTernaryCondition(
      data.averageActualIncentive === 0 && data.totalActualIncentive === 0,
      COOL_GRAY,
      DARK_CHARCOAL,
    );
  };
  return (
    <Container
      gap="0.5rem"
      padding="1rem"
      className="curved-border"
      backgroundColor="white"
      direction="column">
      <Container direction="row" width="100%" justifyContent="space-between">
        <Container>
          <TextWrapper
            color={returnHeaderColor()}
            fontSize="1rem"
            fontWeight={600}>
            {CARD_CONTENT.cardHeader}
          </TextWrapper>
        </Container>
        <Container gap="1rem">
          <Container
            gap="0.25rem"
            direction="row"
            alignItems="center"
            justifyContent="center">
            <ColorIcon
              type={checkTernaryCondition(
                (data.averageProjectedIncentive === 0 &&
                  data.totalProjectedIncentive === 0) ||
                  (data.averageProjectedIncentive === undefined &&
                    data.totalProjectedIncentive === undefined),
                '',
                PROJECTED,
              )}></ColorIcon>
            <TextWrapper
              letterSpacing="0.013rem"
              color={returnProjectedHeaderColor()}
              fontSize="0.75rem"
              fontWeight={400}>
              {CARD_CONTENT.projected}
            </TextWrapper>
          </Container>
          <Container
            gap="0.25rem"
            direction="row"
            alignItems="center"
            justifyContent="center">
            <ColorIcon
              type={checkTernaryCondition(
                (data.totalActualIncentive === 0 &&
                  data.averageActualIncentive === 0) ||
                  (data.totalActualIncentive === undefined &&
                    data.averageActualIncentive === undefined),
                '',
                ACTUAL,
              )}></ColorIcon>
            <TextWrapper
              fontFamily="Open Sans"
              letterSpacing="0.013rem"
              color={returnActualHeaderColor()}
              fontSize="0.75rem"
              fontWeight={400}>
              {CARD_CONTENT.actual}
            </TextWrapper>
          </Container>
        </Container>
      </Container>
      {/* The cost section */}
      <Container gap="0.5rem" width="100%" justifyContent="stretch">
        <Container
          width="49.13%"
          gap="0.5rem"
          padding="0rem 0.5rem 0rem 0rem"
          direction="column"
          borderRight={`0.063rem solid ${QUILL_GRAY}`}>
          <Container alignItems="center" gap="0.125rem">
            <TextWrapper
              color={returnHeaderColor()}
              fontFamily="Open Sans"
              fontSize="1rem"
              fontWeight={600}>
              {CARD_CONTENT.averageSubHeading}
            </TextWrapper>
            <TextWrapper color={COOL_GRAY} fontSize="0.75rem" fontWeight={400}>
              {CARD_CONTENT.perAcre}
            </TextWrapper>
          </Container>
          <Container gap="0.75rem" width="100%" direction="row">
            <Container alignItems="center" gap="0.125rem">
              <ColorIcon
                type={checkTernaryCondition(
                  data.averageProjectedIncentive === 0 ||
                    data.averageProjectedIncentive === undefined,
                  '',
                  PROJECTED,
                )}></ColorIcon>
              <TextWrapper
                color={COOL_GRAY}
                fontFamily="Open Sans"
                fontSize="1.5rem"
                fontWeight={600}>
                {checkTernaryCondition(
                  data.averageProjectedIncentive === 0 ||
                    data.averageProjectedIncentive === undefined,
                  '--',
                  '$' + data.averageProjectedIncentive?.toFixed(2),
                )}
              </TextWrapper>
            </Container>
            <Container alignItems="center" gap="0.125rem">
              <ColorIcon
                type={checkTernaryCondition(
                  data.averageActualIncentive === 0 ||
                    data.averageActualIncentive === undefined,
                  '',
                  ACTUAL,
                )}></ColorIcon>
              <TextWrapper
                color={COOL_GRAY}
                fontFamily="Open Sans"
                fontSize="1.5rem"
                fontWeight={600}>
                {checkTernaryCondition(
                  data.averageActualIncentive === 0 ||
                    data.averageActualIncentive === undefined,
                  '--',
                  '$' + data.averageActualIncentive?.toFixed(2),
                )}
              </TextWrapper>
            </Container>
          </Container>
        </Container>
        {/* The Second part section */}
        <Container
          width="49.13%"
          direction="column"
          gap="0.5rem"
          alignItems="flex-start">
          <Container alignItems="center" gap="0.125rem">
            <TextWrapper
              color={returnHeaderColor()}
              fontFamily="Open Sans"
              fontSize="1rem"
              fontWeight={600}>
              {CARD_CONTENT.totalSubHeading}
            </TextWrapper>
            <TextWrapper color={COOL_GRAY} fontSize="0.75rem" fontWeight={400}>
              {CARD_CONTENT.dollar}
            </TextWrapper>
          </Container>
          <Container gap="0.75rem" width="100%" direction="row">
            <Container alignItems="center" gap="0.125rem">
              <ColorIcon
                type={checkTernaryCondition(
                  data.totalProjectedIncentive === 0 ||
                    data.totalProjectedIncentive === undefined,
                  '',
                  PROJECTED,
                )}></ColorIcon>
              <TextWrapper
                color={COOL_GRAY}
                fontFamily="Open Sans"
                fontSize="1.5rem"
                fontWeight={600}>
                {checkTernaryCondition(
                  data.totalProjectedIncentive === 0 ||
                    data.totalProjectedIncentive === undefined,
                  '--',
                  '$' + data.totalProjectedIncentive?.toFixed(2),
                )}
              </TextWrapper>
            </Container>
            <Container alignItems="center" gap="0.125rem">
              <ColorIcon
                type={checkTernaryCondition(
                  data.totalActualIncentive === 0 ||
                    data.totalActualIncentive === undefined,
                  '',
                  ACTUAL,
                )}></ColorIcon>
              <TextWrapper
                color={COOL_GRAY}
                fontFamily="Open Sans"
                fontSize="1.5rem"
                fontWeight={600}>
                {checkTernaryCondition(
                  data.totalActualIncentive === 0 ||
                    data.totalActualIncentive === undefined,
                  '--',
                  '$' + data.totalActualIncentive?.toFixed(2),
                )}
              </TextWrapper>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
ParticipantIncentivesCard.propTypes = {
  data: PropTypes.object,
};
export default ParticipantIncentivesCard;
