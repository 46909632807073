export const MODAL_CONTENT = {
  crop_type_modal_heading: 'Crop',
  crop_type: 'Crop type',
  date_planted: 'Date planted',
  date_harvested: 'Date terminated',
  selected_date: 'Select Date',
  submit: 'Submit',
  cancel: 'Cancel',
  uom: 'Unit of measure',
  select_unit_type: 'Select unit type',
  select_crop_type: 'Select crop type',
  annual_yield: 'Annual yield',
  type_amount: 'Type amount',
  measured_per: 'Measured Per',
  crop_density: 'Planting density',
  type_density: 'Type density',
  crop_depth: 'Seeding depth',
  type_depth: 'Type depth',
  acre: 'acre',
  select_type: 'Select type',
  sixPointTwoFiveRem: '6.25rem',
  sevenPointFiveRem: '7.5rem',
  dropdownFontSize: '14px',
  dropdownHeight: '2rem',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  eighteenPointTwoFiveRem: '18.25rem',
  tenRem: '10rem',
  crop_rotation_label: 'Implementation of crop rotation?',
  crop_area: 'Crop area',
  type_area: 'Type area',
  crop_type_error: 'Crop type selection is required.',
  crop_area_error: 'Crop area is required.',
  uom_error: 'Unit of measure selection is required.',
  annual_yield_error: 'Annual yield amount is required.',
  date_planted_error: 'Date planted is required.',
  date_harvested_error: 'Date terminated is required.',
  crop_rotation_error: 'Implementation of crop rotation  is required.',
  date_harvest_error: 'Date terminated must occur after date planted.',
  date_plant_error: 'Date planted must occur before date terminated.',
  date_planted_year_error:
    'Date planted can only occur within the baseline year and up to 1 year prior.',
  date_harvested_year_error:
    'Date terminated can only occur within the baseline year.',
  total_field_area_error: 'Crop area can’t exceed total area of the field',
  project_activity_label:
    'You indicated during field enrollment that the following project activities were implemented on this field. Which were applied to this cash crop?',
  project_activity_instruction: 'Select all that apply',
  project_activity_error:
    'A selection is required for any project activity implemented on the field.',
  crop_density_error:
    'Unit of measure required if planting density is provided.',
  crop_depth_error: 'Unit of measure required if seeding depth is provided.',
  cash_crop: 'Cash crop',
  cover_crop: 'Cover crop',
  cash_crop_value: 'CASH_CROP',
  cover_crop_value: 'COVER_CROP',
  fallow_crop_value: 'FALLOW',
  management_type: 'Planned management type',
  select_management_type: 'Select management type',
  management_type_error: 'Planned management type selection is required.',
  production_purpose: 'Production purpose',
  production_purpose_error: 'Production purpose selection is required.',
  silage: 'Mechanical harvest; silage',
  grain: 'Mechanical harvest; grain',
  otherHarvested: 'Other harvested crop',
  grazing: 'Grazing',
  typeAmount: 'Type amount',
  tillage_dropdown_heading:
    'How would you classify the tillage system for this crop?',
  select_tillage_system: 'Select tillage system',
  tillage_system_error: 'A tillage type selection is required.',
  tillage_practice: 'Please specify tillage practice',
  tillage_practice_error:
    'Please specify a tillage type if other selected for tillage practice.',
  disturbance_type: 'Disturbance type',
  disturbance_type_error: 'A disturbance type selection is required.',
  tillage_system: 'Tillage system',
  other: 'Other',
  tillage_practice_placeholder: 'Please specify tillage practice',
  crop_area_greater_than_zero_validation: 'Crop area must be greater than 0.',
  annual_yield_uom: 'null/null',
  invalid_annual_yield_uom: 'null',
  crop_value_input: 'Crop value ($)',
  crop_value_empty: '',
  crop_value_dollar: '$',
  crop_value_placeholder: 'Type crop value',
  fallow: 'Fallow',
};
export const initialCropTypeData = {
  sampleId: '',
  cropSampleId: '',
  cropId: '',
  annualYield: '',
  plantingDensity: '',
  cropValue: '',
  plantingDensityUom: '',
  cropDepth: '',
  cropDepthUom: '',
  cropPlantDate: '',
  cropTerminationDate: '',
  cropArea: '',
  activityList: [],
  cropPlannedManagementTypeId: '',
  productionPurpose: '',
  annualYieldUom: '',
  cropAreaUom: 'acre',
  tillageSystemId: '',
  tillageSystemOther: '',
  tillageSystemDisturbanceId: '',
};

export const cropDepthDropdownList = [
  { label: 'cm', value: 'cm' },
  { label: 'in', value: 'in' },
];

export const cropDensityDropdownList = [
  { label: 'kg/ha', value: 'kg/ha' },
  { label: 'seeds/m2', value: 'seeds/m2' },
  { label: 'lbs/acre', value: 'lbs/acre' },
];

export const cropYieldMeasuredDropdownList = [
  { label: 'acre', value: 'acre' },
  { label: 'hectare', value: 'hectare' },
  { label: 'square meters', value: 'square meters' },
];

export const cropYieldUomDropdownList = [
  { label: 'bushels', value: 'bushels' },
  { label: 'tons', value: 'tons' },
  { label: 'pounds', value: 'pounds' },
  { label: 'liner feet', value: 'linear feet' },
  { label: 'in', value: 'in' },
];
export const cropRotationList = [
  { itemLabel: 'Yes', value: 'Yes' },
  { itemLabel: 'No', value: 'No' },
];

export const annualYieldDropdown = [
  { label: 'lbs/acre', value: 'lbs/acre' },
  { label: 'tons/acre', value: 'tons/acre' },
  { label: 'bushels/acre', value: 'bushels/acre' },
  { label: '% harvest efficiency', value: '% harvest efficiency' },
];

export const cropList = ['Corn', 'Soybeans', 'Spring wheat', 'Winter wheat'];
