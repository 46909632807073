import { Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { styles } from './PageList.style';
import PropTypes from 'prop-types';

const ListItem = ({ subheading, textValue }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    const tempArray = textValue?.filter(
      (item, index) => textValue?.indexOf(item) === index,
    );
    setList(tempArray);
  }, [textValue]);

  return (
    <View style={styles?.section}>
      {subheading && <Text style={styles?.subheading}>{subheading}</Text>}
      {list?.length > 1
        ? list?.map((item) => (
            <View style={styles?.row} key={item}>
              <View style={styles?.bullet}>
                <Text>{'\u2022' + ' '}</Text>
              </View>
              <Text style={styles?.listItem}>{item}</Text>
            </View>
          ))
        : list?.map((item) => (
            <Text key={item} style={styles?.para}>
              {item}
            </Text>
          ))}
      {}
    </View>
  );
};

ListItem.propTypes = {
  subheading: PropTypes.string,
  textValue: PropTypes.string,
};
export default ListItem;
