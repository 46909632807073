import { pathName } from 'Routes/routes.const';
import axios from 'axios';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
  ADD_PARTICIPANT,
  FETCH_APPROVED_PARTICIPANTS,
  FETCH_PARTICIPANT_DETAILS,
} from 'urls';
import { checkTernaryCondition, ternaryBooleanHandler } from 'utils/helper';

export const useParticipantDetails = () => {
  const [loading, setLoading] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [participantDetails, setParticipantDetails] = useState('');
  const [participantIdError, setParticipantIdError] = useState(false);
  const navigate = useNavigate();
  const { addParticipantToProject } = useContext(LifeCycleContext);

  const fetchParticipantList = (name, projectId) => {
    if (name !== null && name !== undefined) {
      if (name.length > 2) {
        axios
          .get(FETCH_APPROVED_PARTICIPANTS, {
            params: {
              projectId: projectId,
              name: name,
            },
          })
          .then((response) => {
            setParticipantIdError(
              ternaryBooleanHandler(response.data.length === 0),
            );
            setParticipantList(
              response.data.map((data) => ({
                participantId: data.participantId,
                participantFirstName: data.participantFirstName,
                participantLastName: data.participantLastName,
                participantEmail: data.participantEmail,
              })),
            );
          });
      } else {
        setParticipantList([]);
        setParticipantIdError(false);
      }
    }
  };

  const fetchParticipantDetails = (participantId) => {
    setLoading(true);
    axios
      .get(`${FETCH_PARTICIPANT_DETAILS}?participantId=${participantId}`)
      .then((response) => {
        setParticipantDetails(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submitParticipantModal = (
    submitData,
    projectName,
    setIsModalOpen,
    isLinked,
    refreshHandler,
    navigateToEnrollPage,
    editMode,
  ) => {
    setLoading(true);
    let successMessage = checkTernaryCondition(
      submitData.participantId === 0 || isLinked,
      `${submitData.participantFirstName} ${submitData.participantLastName} was successfully added.`,
      `${submitData.participantFirstName} ${submitData.participantLastName} was successfully updated.`,
    );
    let errorMessage = checkTernaryCondition(
      submitData.participantId === 0 || isLinked,
      `${submitData.participantFirstName} ${submitData.participantLastName} failed to be add. Please try again.`,
      `${submitData.participantFirstName} ${submitData.participantLastName} failed to be update. Please try again.`,
    );
    axios
      .post(ADD_PARTICIPANT, submitData)
      .then((response) => {
        if (!editMode) {
          addParticipantToProject(
            submitData.projectId,
            response.data.participantId,
          );
        }
        return response;
      })
      .then((response) => {
        toast(<CustomSnackbar type="success" message={successMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        refreshHandler();
        if (navigateToEnrollPage) {
          navigate(
            `${pathName.participant.participantDataCollection}?projectid=${submitData.projectId}&participantid=${response.data.participantId}`,
            {
              state: {
                projectId: submitData.projectId,
                projectName: projectName,
                participantId: response.data.participantId,
                participantFirstName: submitData.participantFirstName,
                participantLastName: submitData.participantLastName,
                participantEmail: submitData.participantEmail,
                id: 1,
                fieldData: null,
              },
            },
          );
        }
      })

      .catch(() => {
        toast(<CustomSnackbar type="error" message={errorMessage} />, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
        setIsModalOpen({ flag: false, id: 0 });
      });
  };

  return {
    submitParticipantModal,
    loading,
    fetchParticipantList,
    participantList,
    fetchParticipantDetails,
    participantDetails,
    participantIdError,
    setParticipantList,
    setParticipantDetails,
    setParticipantIdError,
  };
};
