import { Button } from '@mui/material';
import {
  MainWrapperDiv,
  TextWrapper,
  ContentWrapper,
  SubTextWrapper,
  ButtonWrapper,
  FilterTextWrapper,
  SearchedTextWrapper,
} from './NodataContent.style';
import { useNavigate, useLocation } from 'react-router';
import AddButton from 'components/AddButton/AddButton.react';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';

const NodataComponent = (props) => {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  return (
    <MainWrapperDiv
      borderTop={props.borderTop}
      marginLeft={props?.marginLeft}
      className="curved-border">
      <ContentWrapper
        width={props.width}
        left={props.left}
        type={props.filterData}>
        {props.nodata && (
          <TextWrapper
            width={props.componentWidth}
            height={props.componentHeight}>
            {props.nodata}
          </TextWrapper>
        )}
        {props.addNewLabel && (
          <SubTextWrapper>{props.addNewLabel}</SubTextWrapper>
        )}
        {props.filterData && (
          <FilterTextWrapper>{props.filterData}</FilterTextWrapper>
        )}
        {props.searchTerm && (
          <SearchedTextWrapper>{props.searchTerm}</SearchedTextWrapper>
        )}
        {props.searchTermSuffix && (
          <FilterTextWrapper>{props.searchTermSuffix}</FilterTextWrapper>
        )}
        <ButtonWrapper
          marginTop={props.buttonMarginTop}
          position={props.buttonPosition}
          left={props.buttonLeft}>
          {props.addNewButtonOptions ? (
            <AddButton
              buttonName={props.addbuttonLabel.name}
              options={props.addNewButtonOptions}
              toggleModal={props.toggleModal}
              setSelectedOption={props.setSelectedOption}
              isModalPresent={props.isModalPresent}
              noDropdown={props.noDropdown}
              backgroundColor={props.buttonBackgroundColor}
              disabledOption={props.disabledOption}
              hasMultipleModals={props.hasMultipleModals}
            />
          ) : (
            props.addbuttonLabel && (
              <Button
                disabled={props.disableButton}
                sx={primaryButtonStyle()}
                variant="contained"
                onClick={() =>
                  props.addbuttonLabel.link === ''
                    ? props.modalHandler({
                        flag: true,
                        id: 0,
                      })
                    : navigation(props.addbuttonLabel.link, {
                        state: { previousPath: pathname },
                      })
                }>
                {props.addbuttonLabel.name}
              </Button>
            )
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </MainWrapperDiv>
  );
};

export default NodataComponent;
