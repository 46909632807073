import { useContext, useState, useEffect } from 'react';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import { Box, Tab, Tabs } from '@mui/material';
import { useStyles } from 'theme/GlobalStyles';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import { PORTFOLIO_TABS } from './PortfolioOverview.content';
import { TabberWrapperStyle } from './PortfolioOverview.style';
import PortfolioOriginationTab from 'pages/PortfolioOriginationTab/PortfolioOriginationTab.react';
import { ModulesEnableMapper, getIsModuleEnabled } from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import InsetPage from 'pages/InsetsPage/SettingsPage.react';

const PortfolioOverview = () => {
  const { TabberBox } = useStyles();
  const { modulesListData } = useContext(userAuthRolesContext);
  const isOriginationModuleEnabled = getIsModuleEnabled(
    ModulesEnableMapper['PORTFOLIO_ORIGINATION'],
    modulesListData,
  );
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (_, selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `portfolio-page-tab-${index}`,
    };
  };

  const portfolioTabs = [
    {
      title: PORTFOLIO_TABS.insetting,
      component: <InsetPage />,
      isModuleEnabled: getIsModuleEnabled(
        ModulesEnableMapper['PORTFOLIO_INSETTING'],
        modulesListData,
      ),
    },
    {
      title: PORTFOLIO_TABS.offsetting,
      component: <></>,
      isModuleEnabled: getIsModuleEnabled(
        ModulesEnableMapper['PORTFOLIO_OFFSETTING'],
        modulesListData,
      ),
    },
    {
      title: PORTFOLIO_TABS.origination,
      component: <PortfolioOriginationTab />,
      isModuleEnabled: isOriginationModuleEnabled,
    },
  ];

  const enabledTabs = portfolioTabs.filter((tab) => tab.isModuleEnabled);

  useEffect(() => {
    const originationTabIndex = enabledTabs.findIndex(
      (tab) => tab.title === PORTFOLIO_TABS.origination,
    );

    // Note: If origination module is enabled, then it should be the default tab, otherwise we show portfolio overview tab
    if (originationTabIndex >= 0 && isOriginationModuleEnabled)
      setCurrentTab(originationTabIndex);
    else setCurrentTab(0);
  }, []);

  return (
    <div>
      <SummaryViewHeader noBackButton title={'Portfolio'} />
      <Box sx={TabberWrapperStyle}>
        <TabberBox>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="page-tabs">
            {enabledTabs.map((tab, index) => (
              <Tab
                sx={{ textTransform: 'none', width: 'unset' }}
                className={classNames({
                  'active-tab': currentTab === index,
                })}
                key={tab.title}
                label={tab.title}
                {...tabProps(index)}
              />
            ))}
          </Tabs>
        </TabberBox>
      </Box>
      {enabledTabs.map((tab, index) => (
        <TabPanel key={tab.title} value={currentTab} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default PortfolioOverview;
