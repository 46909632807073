import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
  PAGE_CONTENT,
  regionList,
} from 'pages/AddOriginationProject/AddOriginationProject.content';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import {
  CREATE_ORIGINATION_PROJECT,
  DUPLICATE_ORIGINATION_PROJECT,
  FETCH_ALL_ACTIVITIES,
  FETCH_ALL_COMMERCIALIZATION_APPROACHES,
  FETCH_ALL_PROJECT_ACTIVITY_CATEGORIES,
  FETCH_ALL_PROJECT_TYPES,
  FETCH_ALL_STANDARDS_BODY,
  FETCH_CLIMATE_PROGRAMS,
  FETCH_VIEW_ORIGINATION_PROJECT,
  ORIGINATION_COUNTRY_LIST,
  ORIGINATION_TAG_LIST,
} from 'urls';
import { MAX_CHAR_LIMIT } from 'utils/config';
import { checkTernaryCondition } from 'utils/helper';

export const formModal = {
  projectName: '',
  projectRegion: [],
  projectType: '',
  projectActivities: [],
  commercializationApproach: [],
  quantificationApproach: [],
  modelMeasuringOption: '',
  description: '',
  notes: '',
  tags: [],
};
const fieldInitialState = { value: '', error: false, errorType: '' };

export const useOriginationProject = () => {
  const [projectName, setProjectName] = useState(fieldInitialState);
  const [projectRegion, setProjectRegion] = useState({
    ...fieldInitialState,
    value: [],
  });
  const [projectRegionList, setProjectRegionList] = useState([...regionList]);
  const [projectType, setProjectType] = useState({
    ...fieldInitialState,
    value: 'Agriculture forestry and other land use (AFOLU)',
  });
  const [projectTypeList, setProjectTypeList] = useState([{ itemLabel: '' }]);
  const [projectActivities, setProjectActivities] = useState({
    ...fieldInitialState,
    value: [],
  });
  const [commercializationApproach, setCommercializationApproach] = useState({
    ...fieldInitialState,
    value: [],
  });
  const [commercializationApproachList, setCommercializationApproachList] =
    useState([]);
  const [commercializationRadioBtn, setCommercializationRadioBtn] =
    useState('');
  const [standardsBodyOrRegistry, setStandardsBodyOrRegistry] =
    useState(fieldInitialState);
  const [standardsBodyOrRegistryList, setStandardsBodyOrRegistryList] =
    useState([]);
  const [
    validationAndVerificationYesOrNo,
    setValidationAndVerificationYesOrNo,
  ] = useState(fieldInitialState);
  const [
    validationAndVerificationAuditor,
    setValidationAndVerificationAuditor,
  ] = useState(fieldInitialState);
  const [quantificationApproach, setQuantificationApproach] = useState({
    ...fieldInitialState,
    value: [],
  });
  const [quantificationMethod, setQuantificationMethod] =
    useState(fieldInitialState);
  const [quantificationModel, setQuantificationModel] = useState({
    ...fieldInitialState,
    value: 'HabiTerre',
  });
  const [afoluActivities, setAfoluActivities] = useState({
    ...fieldInitialState,
    value: ['Agriculture land management (ALM)'],
  });
  const [climateProgramEnrollmentYesOrNo, setClimateProgramEnrollmentYesOrNo] =
    useState(fieldInitialState);
  // These are the climate programs selected by user
  const [climatePrograms, setClimatePrograms] = useState({
    ...fieldInitialState,
    value: [],
    climateProgramIds: [],
  });
  // This is the list of all climate programs
  const [climateProgramsList, setClimateProgramsList] = useState([]);
  const [modelMeasuringOption, setModelMeasuringOption] =
    useState(fieldInitialState);
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [tags, setTags] = useState([]);
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [originationProjectDetails, setOriginationProjectDetails] = useState();
  const [fetchDataLoading, setFetchDataLoading] = useState(false);
  const [fetchedProjectDetails, setFetchProjectDetails] = useState('');
  const [editProjectName, setEditProjectName] = useState();
  const [isActive, setIsActive] = useState(1);
  const [lastUpdatedOn, setLastUpdatedon] = useState();
  const [fetchedProjectRegion, setFetchedProjectRegion] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [projectCountryId, setProjectCountryId] = useState({
    ...fieldInitialState,
    value: 199, // set to united states by default
  });
  const [activitiesList, setActivitiesList] = useState([]);
  const [projectActivityCategories, setProjectActivityCategories] = useState(
    [],
  );
  // Note: Using count here to handle multiple apis being called at a single time, we increment loader by 1 whenever an api call is initiated and decrement by one after completion, we can show the loader whenever loaderCount > 0.
  const [loaderCount, setLoaderCount] = useState(0);

  const navigate = useNavigate();

  const handleTextFieldValueChange = (
    nativeStateSetter,
    formStateSetter,
    event,
    key,
    freeText,
    errorStateSetter,
    /* errorState, */
  ) => {
    let finalValue = event.target.value.trim() ? event.target.value : '';
    finalValue = finalValue.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const valueIsEmpty = finalValue === '';
    nativeStateSetter(
      freeText
        ? finalValue
        : {
            value: finalValue,
            error: valueIsEmpty,
            errorType: 'primary',
          },
    );
    freeText && finalValue.length > MAX_CHAR_LIMIT
      ? errorStateSetter({ field: key, noOfCharacters: finalValue.length })
      : errorStateSetter({ field: '', noOfCharacters: null });
    formStateSetter(key, finalValue);
    key === PAGE_CONTENT.project_element_name && setDuplicateNameError(false);
  };
  const handleCheckboxValueChange = (
    index,
    nativeState,
    nativeStateSetter,
    formStateSetter,
    key,
  ) => {
    let list = nativeState;
    list[index].checked = !list[index].checked;
    const finalList = list
      .map((item) => {
        if (item.checked === true) {
          return item.itemLabel;
        }
      })
      .filter((item) => item !== undefined);
    const listIsEmpty = finalList.length === 0;
    nativeStateSetter({
      value: finalList,
      error: listIsEmpty,
      errorType: 'primary',
    });
    formStateSetter(key, finalList);
  };

  const handleCheckboxRadioBtnChange = (
    event,
    formStateSetter,
    nativeStateSetter,
    key,
  ) => {
    setCommercializationRadioBtn(event.target.value);
    checkTernaryCondition(
      event.target.value !== '',
      nativeStateSetter({
        value: [event.target.value],
        error: true,
        errorType: '',
      }),
      nativeStateSetter({
        value: [event.target.value],
        error: false,
        errorType: '',
      }),
    );
    formStateSetter(key, [event.target.value]);
  };

  const handleAutocompleteChange = (
    nativeStateSetter,
    formStateSetter,
    field,
    value,
  ) => {
    const valueIsEmpty = value.length === 0;
    nativeStateSetter({
      value: value,
      error: valueIsEmpty,
      errorType: 'primary',
    });
    formStateSetter(field, value);
  };
  const addTagsHandler = (e, formValue, customFieldChangeHandler) => {
    if (e.target.value.length > 0) {
      if (formValue.tags.indexOf(e.target.value) === -1) {
        customFieldChangeHandler('tags', [...formValue.tags, e.target.value]);
      }
    }
  };
  const setPrimaryStateError = (isEmpty, stateSetter, state) => {
    isEmpty && stateSetter({ ...state, error: true, errorType: 'primary' });
  };
  const validateFormValue = (isEdit, formValue) => {
    const isProjectNameEmpty = projectName.value === '';
    const isCommercializationApproachNotSelected =
      commercializationApproach.value.length === 0;
    const isProjectRegionEmpty = isEdit
      ? formValue.projectRegion?.length === 0
      : projectRegion.value?.length === 0;
    const isProjectActivitiesEmpty = projectActivities.value.length === 0;
    const isMVRTechnologySelected = modelMeasuringOption.value === '';
    const isQuantificationMethodNotSelected = isEdit
      ? false
      : quantificationMethod.value === '';
    const isQuantificationModelNotSelected = quantificationModel.value === '';
    const isAfoluActivityNotSelected =
      projectType.value === 'Agriculture forestry and other land use (AFOLU)' &&
      afoluActivities.value.length === 0;
    const isStandardsAndBodyRegistryNotSelected =
      formValue.commercializationApproach[0] === 'Offsetting' &&
      formValue.standardsBodyOrRegistry === '';
    const isValidationAndVerificationYesOrNoNotSelected = isEdit
      ? false
      : validationAndVerificationYesOrNo.value === '';
    const isValidationAndVerificationAuditorNotSelected =
      validationAndVerificationYesOrNo.value === 'Yes' &&
      validationAndVerificationAuditor.value === '';
    const isCountryNotSelected = projectCountryId.value === -1;
    const isClimateProgramYesOrNoNotSelected =
      climateProgramEnrollmentYesOrNo.value === '';
    const isClimateProgramsListNotSelected =
      climateProgramEnrollmentYesOrNo.value === 'Yes' &&
      climatePrograms.value.length === 0;

    setPrimaryStateError(isProjectNameEmpty, setProjectName, projectName);
    setPrimaryStateError(
      isCommercializationApproachNotSelected,
      setCommercializationApproach,
      commercializationApproach,
    );
    setPrimaryStateError(isProjectRegionEmpty, setProjectRegion, projectRegion);
    setPrimaryStateError(
      isProjectActivitiesEmpty,
      setProjectActivities,
      projectActivities,
    );
    setPrimaryStateError(
      isMVRTechnologySelected,
      setModelMeasuringOption,
      modelMeasuringOption,
    );
    setPrimaryStateError(
      isAfoluActivityNotSelected,
      setAfoluActivities,
      afoluActivities,
    );
    setPrimaryStateError(
      isQuantificationMethodNotSelected,
      setQuantificationMethod,
      quantificationMethod,
    );
    setPrimaryStateError(
      isQuantificationModelNotSelected,
      setQuantificationModel,
      quantificationModel,
    );
    setPrimaryStateError(
      isStandardsAndBodyRegistryNotSelected,
      setStandardsBodyOrRegistry,
      standardsBodyOrRegistry,
    );
    setPrimaryStateError(
      isValidationAndVerificationYesOrNoNotSelected,
      setValidationAndVerificationYesOrNo,
      validationAndVerificationYesOrNo,
    );
    setPrimaryStateError(
      isValidationAndVerificationAuditorNotSelected,
      setValidationAndVerificationAuditor,
      validationAndVerificationAuditor,
    );
    setPrimaryStateError(
      isCountryNotSelected,
      setProjectCountryId,
      projectCountryId,
    );
    setPrimaryStateError(
      isClimateProgramYesOrNoNotSelected,
      setClimateProgramEnrollmentYesOrNo,
      climateProgramEnrollmentYesOrNo,
    );
    setPrimaryStateError(
      isClimateProgramsListNotSelected,
      setClimatePrograms,
      climatePrograms,
    );
    setFormValidated(true);
  };
  const fetchTags = () => {
    axios.get(ORIGINATION_TAG_LIST).then((response) => {
      setTags(response.data.map((item) => item.tagName));
    });
  };
  const findDuplicateOriginationProject = (value) => {
    axios
      .get(DUPLICATE_ORIGINATION_PROJECT, {
        params: {
          name: value,
        },
      })
      .then((response) => {
        setDuplicateNameError(response.data.duplicate);
      });
  };

  const axiosCall = (url, data, isEdit) => {
    if (isEdit) {
      return axios.put(url, data);
    } else {
      return axios.post(url, data);
    }
  };

  /**
   * @description This function is used to get the id of the selected item from the list, these ids are used in apis to save the data
   */
  const getValueId = (itemLabel, list) => {
    return list?.find((item) => item.itemLabel === itemLabel)?.id ?? null;
  };

  const postProjectData = (formValue, isEdit, id) => {
    const getOriginationTypeActivities = (data) => {
      if (data.every((item) => typeof item === 'number')) {
        return data;
      } else {
        return data.map((item) => getValueId(item, projectActivityCategories));
      }
    };

    const newRegionList = regionList;
    const url = isEdit
      ? `${CREATE_ORIGINATION_PROJECT}/${id}`
      : CREATE_ORIGINATION_PROJECT;
    const postData = {
      projectName: formValue.projectName,
      projectActivities: formValue.projectActivities,
      commercializationApproach: getValueId(
        formValue.commercializationApproach[0],
        commercializationApproachList,
      ),
      qualificationApproach: isEdit
        ? formValue.quantificationApproach
        : formValue.quantificationApproach[0],
      originationType: getValueId(formValue.projectType, projectTypeList),
      projectDescription: formValue.description,
      projectNotes: formValue.notes,
      region: formValue.projectRegion,
      soilCarbonModelUsedForMeasuring: formValue.modelMeasuringOption,
      mrvTechnologyUsed: checkTernaryCondition(
        formValue.modelMeasuringOption === 'Yes',
        'HabiTerre',
        '',
      ),
      tags: formValue.tags,
      originationTypeActivities: getOriginationTypeActivities(
        formValue.afoluActivities,
      ),
      methodologyQuantification: formValue.quantificationMethod,
      modelLeveraged: formValue.quantificationModel,
      countryId: formValue.countryId,
      vvAuditor: checkTernaryCondition(
        formValue.validationAndVerificationYesOrNo === 'Yes',
        formValue.validationAndVerificationAuditor,
        '',
      ),
      standardsBody: 2, // currently hard coded as 2 (NONE)
      climatePrograms: formValue.climateProgramEnrollmentYesOrNO === 'Yes',
      climateProgramsList: checkTernaryCondition(
        formValue.climateProgramEnrollmentYesOrNO === 'No',
        [],
        climateProgramsList
          .filter((climateProgram) =>
            formValue.climatePrograms.includes(
              climateProgram.climateProgramName,
            ),
          )
          .map((climateProgram) => climateProgram.climateProgramId),
      ),
      activityIds: activitiesList
        .filter((activity) =>
          formValue.projectActivities.includes(activity.label),
        )
        .map((activity) => activity.value),
    };
    setSubmitLoading(true);
    axiosCall(url, postData, isEdit)
      .then(() => {
        navigate(PAGE_CONTENT.parent_path);
        toast(
          <CustomSnackbar
            type={PAGE_CONTENT.success.toLowerCase()}
            message={`${PAGE_CONTENT.success}! ${projectName.value} ${
              isEdit ? PAGE_CONTENT.update_message : PAGE_CONTENT.toast_success
            }`}
          />,
          toastStyle,
        );
        setProjectRegionList(
          newRegionList.forEach((item) => (item.checked = false)),
        );
      })
      .catch(() => {
        toast(
          <CustomSnackbar
            type={PAGE_CONTENT.error.toLowerCase()}
            message={`${PAGE_CONTENT.error}! ${projectName.value} ${
              isEdit ? PAGE_CONTENT.fail_message : PAGE_CONTENT.toast_error
            }`}
          />,
          toastStyle,
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleFormSubmit = (isEdit, formValue) => {
    validateFormValue(isEdit, formValue);
  };
  const handleMVROptionChange = (event, formStateSetter) => {
    const selectedValue = event.target.value;
    setModelMeasuringOption({
      value: selectedValue,
      error: false,
      errorType: '',
    });
    formStateSetter('modelMeasuringOption', selectedValue);
  };

  const handleRadioBtnGroupValueChange = (
    nativeStateSetter,
    formStateSetter,
    field,
    value,
  ) => {
    nativeStateSetter({
      value: value,
      error: false,
      errorType: '',
    });
    formStateSetter(field, value);
  };

  const fetchOriginationProjectDetails = (
    id /* , customFieldChangeHandler */,
  ) => {
    setFetchDataLoading(true);
    axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${id}`)
      .then((response) => {
        const list = response.data.projectRegion
          .slice(1, -1)
          .split(PAGE_CONTENT.comma);
        let trimedList = [];
        list.forEach((item) => trimedList.push(item.trim()));
        const newArr = regionList.map((obj) => {
          if (trimedList.includes(obj.itemLabel)) {
            return { ...obj, checked: true };
          }
          return obj;
        });
        const afoluActivitiesData =
          response.data?.originationTypeActivities?.map(
            (item) => item.originationProjectActivityCategory,
          );

        setFetchProjectDetails(response.data);
        setProjectName({ ...projectName, value: response.data?.projectName });
        setEditProjectName(response.data?.projectName);
        setProjectRegionList(newArr);
        setAfoluActivities({ ...afoluActivities, value: afoluActivitiesData });
        setQuantificationMethod({
          ...quantificationMethod,
          value: response.data?.methodologyQuantification,
        });
        setQuantificationModel({
          ...quantificationModel,
          value: response.data?.modelLeveraged,
        });
        setNotes(response.data?.projectNotes);
        setDescription(response.data?.projectDescription);
        setProjectActivities({
          ...projectActivities,
          value: response.data?.activityList.map(
            (item) => item.activity.projectActivityType,
          ),
        });
        setIsActive(response.data?.isActive);
        setLastUpdatedon(response.data?.updatedDate);
        setFetchedProjectRegion(response.data?.projectRegion);
        setModelMeasuringOption({
          value: response.data.soilCarbonModelUsedForMeasuring,
          error: false,
          errorType: '',
        });
        setStandardsBodyOrRegistry({
          value: response.data?.standardsBody?.standardsBody ?? '',
          error: false,
          errorType: '',
        });
        setProjectCountryId({
          value: response.data.country.countryId,
          error: false,
          errorType: '',
        });
        setValidationAndVerificationAuditor({
          value: response.data.vvAuditor || '',
          error: false,
          errorType: '',
        });
        setValidationAndVerificationYesOrNo({
          value: checkTernaryCondition(
            response.data.vvAuditor === '',
            PAGE_CONTENT.no,
            PAGE_CONTENT.yes,
          ),
          error: false,
          errorType: '',
        });
        setClimateProgramEnrollmentYesOrNo({
          value: checkTernaryCondition(
            response.data.climatePrograms === true,
            'Yes',
            'No',
          ),
          error: false,
          errorType: '',
        });
        setClimatePrograms({
          value: [],
          climateProgramIds: response.data.climateProgramDims.map(
            (climateProgram) => climateProgram.climateProgramId,
          ),
          error: false,
          errorType: '',
        });
        setCommercializationApproach({
          value: [
            response.data.commercializationApproach.commercializationApproach,
          ],
        });
      })
      .finally(() => setFetchDataLoading(false));
  };

  const fetchCountryList = () => {
    axios
      .get(ORIGINATION_COUNTRY_LIST)
      .then((res) => {
        const data = res.data;
        if (data) {
          setCountryList(() => {
            return data.map((country) => {
              return {
                ...country,
                label: country.countryName,
                value: country.countryId,
              };
            });
          });
        }
      })
      .catch(() => {});
  };

  const fetchClimateProgramList = () => {
    axios.get(FETCH_CLIMATE_PROGRAMS).then((res) => {
      const data = res.data;
      if (data) {
        setClimateProgramsList(() => {
          return data.map((climateProgram) => {
            return {
              ...climateProgram,
              label: climateProgram.climateProgramName,
              value: climateProgram.climateProgramId,
            };
          });
        });
      }
    });
  };

  const fetchActivitiesList = () => {
    axios.get(FETCH_ALL_ACTIVITIES).then((res) => {
      const data = res.data;
      if (data) {
        setActivitiesList(() => {
          return data.map((activity) => {
            return {
              ...activity,
              label: activity.value,
              value: activity.id,
            };
          });
        });
      }
    });
  };

  const getCommercializationApproachTooltips = (approach) => {
    if (approach === 'Insetting') {
      return 'Insetting counts GHG abatement activities related to a company’s value chain against a company’s abatement targets. If the carbon is inset, it travels with the product down the value chain and is counted toward the carbon owner’s climate goals.';
    } else if (approach === 'Offsetting') {
      return 'Offsetting generates carbon credits for emissions reductions, given to one party that can be sold or traded to another party, leading to the credit’s retirement and the credit buyer using the credit to compensate for its emissions, Offsetting harnesses the impact of GHG abatement activities outside of a company’s direct or indirect operations.';
    }

    return '';
  };

  const fetchCommercializationApproaches = () => {
    setLoaderCount((prev) => prev + 1);
    axios
      .get(FETCH_ALL_COMMERCIALIZATION_APPROACHES)
      .then((res) => {
        const data = res.data;
        if (data) {
          setCommercializationApproachList(() => {
            return data
              .filter((item) => item.value !== 'None') // Ignoring this option for now, might be picked up later
              .map((approach) => {
                return {
                  ...approach,
                  itemLabel: approach.value,
                  id: approach.id,
                  checked: true,
                  isDisabled: approach.value === PAGE_CONTENT.offSetting, // Note: Currently Offsetting option will be disabled, can be updated in future
                  tooltipTitle: '',
                  tooltipContent: getCommercializationApproachTooltips(
                    approach.value,
                  ),
                };
              });
          });
        }
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchStandardBodies = () => {
    setLoaderCount((prev) => prev + 1);
    axios
      .get(FETCH_ALL_STANDARDS_BODY)
      .then((res) => {
        const data = res.data;
        if (data) {
          setStandardsBodyOrRegistryList(() => {
            return data.map((item) => {
              return {
                ...item,
                id: item.id,
                itemLabel: item.value,
                selected: false,
                isDisabled: false,
              };
            });
          });
        }
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchProjectTypes = () => {
    setLoaderCount((prev) => prev + 1);
    const AFOLU_ACTIVITY_LABEL =
      'Agriculture forestry and other land use (AFOLU)';
    const NON_AFOLU_ACTIVITY_LABEL =
      'Non-Agriculture forestry and other land use (Non-AFOLU)';

    axios
      .get(FETCH_ALL_PROJECT_TYPES)
      .then((res) => {
        const data = res.data;
        if (data) {
          const formattedData = data.map((item) => {
            return {
              id: item.id,
              itemLabel: item.value,
              // currently AFOLU activity is selected by default and Non-AFOLU is disabled, can be updated in future
              selected: item.value === AFOLU_ACTIVITY_LABEL,
              isDisabled: item.value === NON_AFOLU_ACTIVITY_LABEL,
            };
          });
          setProjectTypeList(formattedData);
        }
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  const fetchActivityCategories = (projectTypeId) => {
    setLoaderCount((prev) => prev + 1);
    const url = `${FETCH_ALL_PROJECT_ACTIVITY_CATEGORIES}?originationProjectType=${projectTypeId}`;

    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        if (data) {
          setProjectActivityCategories(() => {
            return data.map((item) => {
              return {
                ...item,
                itemLabel: item.value,
                id: item.id,
                checked: item.value === 'Agriculture land management (ALM)',
                isDisabled: true,
              };
            });
          });
        }
      })
      .catch(() => {})
      .finally(() => setLoaderCount((prev) => prev - 1));
  };

  useEffect(() => {
    if (commercializationRadioBtn === 'Insetting') {
      setStandardsBodyOrRegistry({ value: '', error: false, errorType: '' });
    }
  }, [commercializationRadioBtn]);

  return {
    projectName,
    setProjectName,
    projectRegion,
    setProjectRegion,
    projectType,
    setProjectType,
    projectActivities,
    setProjectActivities,
    commercializationApproach,
    setCommercializationApproach,
    commercializationRadioBtn,
    handleCheckboxRadioBtnChange,
    standardsBodyOrRegistry,
    setStandardsBodyOrRegistry,
    validationAndVerificationYesOrNo,
    setValidationAndVerificationYesOrNo,
    validationAndVerificationAuditor,
    setValidationAndVerificationAuditor,
    quantificationApproach,
    setQuantificationApproach,
    afoluActivities,
    setAfoluActivities,
    quantificationMethod,
    setQuantificationMethod,
    quantificationModel,
    setQuantificationModel,
    handleRadioBtnGroupValueChange,
    modelMeasuringOption,
    setModelMeasuringOption,
    description,
    setDescription,
    notes,
    setNotes,
    tags,
    setTags,
    duplicateNameError,
    setDuplicateNameError,
    handleTextFieldValueChange,
    handleCheckboxValueChange,
    handleAutocompleteChange,
    addTagsHandler,
    validateFormValue,
    handleFormSubmit,
    fetchTags,
    submitLoading,
    postProjectData,
    findDuplicateOriginationProject,
    formValidated,
    setFormValidated,
    originationProjectDetails,
    setOriginationProjectDetails,
    fetchOriginationProjectDetails,
    fetchDataLoading,
    projectRegionList,
    setProjectRegionList,
    fetchedProjectDetails,
    editProjectName,
    isActive,
    lastUpdatedOn,
    fetchedProjectRegion,
    handleMVROptionChange,
    countryList,
    fetchCountryList,
    projectCountryId,
    setProjectCountryId,
    climateProgramEnrollmentYesOrNo,
    setClimateProgramEnrollmentYesOrNo,
    climatePrograms,
    setClimatePrograms,
    climateProgramsList,
    fetchClimateProgramList,
    activitiesList,
    fetchActivitiesList,
    commercializationApproachList,
    fetchCommercializationApproaches,
    standardsBodyOrRegistryList,
    fetchStandardBodies,
    projectTypeList,
    fetchProjectTypes,
    projectActivityCategories,
    fetchActivityCategories,
    loaderCount,
  };
};
