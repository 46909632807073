export const PAGE_CONTENT = {
  project_name_label: 'Project Name',
  project_name_placeholder: 'Type to add project name',
  add_payment_type_text: 'Add payment type',
  project_name_error: 'A project name is required for submission',
  remove_project_text: 'Remove project',
  payment_type_label: 'Payment Type',
  payment_type_placeholder: 'Select payment type',
  quantity_label: 'Quantity (MT CO2e)',
  quantity_placeholder: 'Type quantity',
  quantity_subtext: 'metric tons of CO2e',
  price_label: 'Price per MT CO2e',
  price_placeholder: 'Type USD price',
  price_subtext: 'per metric tons of CO2e',
  remove_payment_type_text: 'Remove payment type',
  payment_type_error: 'A payment type is required for submission',
  quantity_error: 'A quantity is required for submission',
  vintage_label: 'Vintage',
  vintage_placeholder: 'Select Date',
  price_error: 'A price is required for submission',
  rofr_status_label: 'ROFR Status',
  rofr_status_placeholder: 'Select ROFR status',
  rofr_status_label_1: 'Under consideration',
  rofr_status_label_2: 'Selected',
  variable_cost_label: 'Variable Cost Structure',
  variable_cost_placeholder: 'Select Cost Structure',
  option_field: '(optional)',
  vintage_error: 'A vintage date selection is required for submission',
  project_duplicate_error:
    'This project was already added with matching data entered. To add the project again the project data must not be an exact match of a previous addition.',
  add_delivery_details_warning: 'Data entry changes will not be saved',
  add_delivery_details_label: 'Add delivery details for',
  add_delivery_details_warning_subtitle:
    'All data entry changes will be lost by closing the delivery details without saving .',
  view_edit_delivery_details: 'View/Edit delivery details',
  add_deposit_label: 'Add Deposits',
  delivery_details: 'Delivery Details',
  view_delivery_details: 'View deposits delivery details',
  view_delivery_details_label: 'Delivery details for',

  add_vintage_label: 'Add vintage',
};

export const clearField = 'clear';
