import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap || '1rem'};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
