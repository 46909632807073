import React, { createContext, useMemo, useState } from 'react';

export const ConfigurationContext = createContext({});

export const ConfigurationProvider = ({ children }) => {
  const [areUiActionInProgress, setAreUiActionInProgress] = useState(0);

  const value = useMemo(
    () => ({
      areUiActionInProgress,
      setAreUiActionInProgress,
    }),
    [areUiActionInProgress],
  );

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
};
