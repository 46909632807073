import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActionsSx,
  DialogContentSx,
  DialogStyle,
  DilogTitleSx,
  cancelBtnSx,
  linkBtnStyle,
  RadioGroupStyle,
  sendRequestBtnSx,
} from './BaselineModal.style';
import { Link } from 'react-router-dom';
import { PAGE_CONTENT } from './BaselineModal.content';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkAccess } from 'utils/helper';
import {
  ACCOUNT_STATUS_ACTIVATED,
  ORIGINATION_PROJECT_ENTER_BASELINE_ON_BEHALF,
} from 'utils/config';
import {
  BASELINE_REPORTING_REPORT_ON_BEHALF,
  LifeCycleContext,
} from 'contextAPI/statusLifecycleContext';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { closeIconSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import {
  RadioButtonStyle,
  RadioIconStyle,
} from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import { checkTernaryCondition } from 'utils/helper';
import { getActionIdFromActionCode } from '../Participants.content';
import axios from 'axios';
import { PARTICIPANT_REPORT_FIRST_PART } from 'urls';

const BaselineModal = ({
  open,
  handleClose,
  formId,
  handleSendRequest,
  navigateLink,
  navigateState,
  participantName,
  actionId,
  selectedBaselineOption,
  setSelectedBaselineOption,
  statusMappingList,
  actionCode,
  handleOnBehalf,
}) => {
  const { permissions } = useContext(userAuthRolesContext);
  const hasEnterOnBehalfPermission = checkAccess(
    permissions,
    ORIGINATION_PROJECT_ENTER_BASELINE_ON_BEHALF,
  );

  const {
    baselineReportingSendRequest,
    baselineReportingReportOnBehalf,
    isActionEnabled,
  } = useContext(LifeCycleContext);
  const [accountStatus, setAccountStatus] = useState(null);
  useEffect(() => {
    if (open && navigateState && navigateState.participantId) {
      axios
        .get(
          `${PARTICIPANT_REPORT_FIRST_PART}?participantId=${navigateState.participantId}`,
        )
        .then((response) => {
          const accountStatus = response.data.accountStatus;
          setAccountStatus(accountStatus);
        });
    }
  }, [open, navigateState]);
  const submitHandler = () => {
    const action =
      selectedBaselineOption === PAGE_CONTENT.reportOnBehalfBtnText
        ? baselineReportingReportOnBehalf
        : baselineReportingSendRequest;
    const sendRequestActionId = getActionIdFromActionCode(
      'BASELINE_SEND_REQ',
      statusMappingList,
    );

    if (action === baselineReportingSendRequest) {
      handleSendRequest(
        navigateState.projectId,
        navigateState.participantId,
        sendRequestActionId,
        formId,
        actionCode,
      );
    } else if (action === baselineReportingReportOnBehalf) {
      handleOnBehalf(
        navigateState.projectId,
        navigateState.participantId,
        getActionIdFromActionCode('BASELINE-ON-BEHALF', statusMappingList),
        formId,
        actionCode,
      );
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={DialogStyle}>
      <DialogTitle sx={{ ...DilogTitleSx, ...flexSpaceBetween }}>
        <div>{PAGE_CONTENT.modalTitle}</div>
        <CloseIcon
          onClick={handleClose}
          sx={closeIconSx}
          data-testid="modal-close"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentSx}>
        <div>{`${PAGE_CONTENT.modalContent}${participantName}?`}</div>
        <RadioGroup
          sx={RadioGroupStyle}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={(e) => {
            setSelectedBaselineOption(e.target.value);
          }}
          name="radio-buttons-group">
          <FormControlLabel
            sx={RadioButtonStyle('0px')}
            value={PAGE_CONTENT.sendRequestBtnText}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="baseline-radio"
              />
            }
            label={PAGE_CONTENT.sendRequestBtnText}
            disabled={accountStatus !== ACCOUNT_STATUS_ACTIVATED}
          />
          <FormControlLabel
            sx={RadioButtonStyle(null)}
            value={PAGE_CONTENT.reportOnBehalfBtnText}
            control={
              <Radio
                size={FORM_CONTENT.small}
                sx={RadioIconStyle(true)}
                role="baseline-radio"
              />
            }
            label={PAGE_CONTENT.reportOnBehalfBtnText}
            disabled={
              !hasEnterOnBehalfPermission &&
              !isActionEnabled(actionId, BASELINE_REPORTING_REPORT_ON_BEHALF)
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={DialogActionsSx}>
        <Button sx={[cancelBtnSx, tertiaryButtonStyle]} onClick={handleClose}>
          {PAGE_CONTENT.cancelBtnText}
        </Button>
        <Button
          disabled={!selectedBaselineOption}
          onClick={submitHandler}
          sx={[sendRequestBtnSx, primaryButtonStyle()]}>
          {checkTernaryCondition(
            selectedBaselineOption === PAGE_CONTENT.reportOnBehalfBtnText,
            <Link
              style={linkBtnStyle()}
              to={navigateLink}
              state={navigateState}
              target="_blank"
              rel="noreferrer">
              {PAGE_CONTENT.submit}
            </Link>,
            PAGE_CONTENT.submit,
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BaselineModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSendRequest: PropTypes.func,
  navigateLink: PropTypes.string,
  navigateState: PropTypes.object,
  participantName: PropTypes.string,
  actionId: PropTypes.string,
  selectedBaselineOption: PropTypes.string,
  setSelectedBaselineOption: PropTypes.func,
};
export default BaselineModal;
