import React, { useContext, useEffect, useState } from 'react';
import {
  CustomBreadCrumbItem,
  CustomBreadCrumbLink,
  DividerStyles,
  LeftSectionWrapper,
  MainContainer,
  MainWrapper,
  ProjectName,
  ProjectNameWrapper,
  TabberBoxCustomStyle,
  ParentContainer,
  TabStylesSx,
} from './ActivityReporting.style';
import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Box,
  Tabs,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import {
  PAGE_CONTENT,
  autoSaveTargetKeys,
  mockData,
  mockEnrolmentYears,
  practiceList,
  tabsTitle,
} from './ActivityReporting.content';
import { TextWrapper } from 'theme/GlobalStyledComps';
import LeftMenuSubsection from '../components/LeftMenuSubsection/LeftMenuSubsection.react';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import {
  checkTernaryCondition,
  sortWithAKeyInSpecificOrder,
} from 'utils/helper';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useReportingApis } from 'hooks/useReportingApis';
import {
  FETCH_ACTIVITY_ACREAGE,
  FETCH_PARTICIPANT_PROFILE_ACTIVITY,
  FETCH_PROJECT_LIFECYCLES,
} from 'urls';
import { pathName } from 'Routes/routes.const';
import TabBodyComponent from '../ReportingComponents/TabBodyComponent.react';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import {
  getFormattedCashcropList,
  getcashCropSubmitBody,
  getFormattedFarmData,
  validateFieldYear,
  getFormattedEdgeOfFieldList,
  getFieldEditApiData,
  validateMappedPractices,
  validateMappedEof,
} from '../ReportingUtilFunctions';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import ImplementedActivities from './components/ImplementedActivities/ImplementedActivities.react';
import ProofOfImplementationMainComp from './components/ProofOfImplementationMainComp/ProofOfImplementationMainComp.react';
import { REPORTING_STATUS } from 'utils/config';
import {
  getOperationsDataBody,
  submitPoiTabData,
} from './ActivityReporting.functions';
import axios from 'axios';
import EnrollmentCompleteModal from '../components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import moment from 'moment';
import { getReverseMappingOfSections } from './components/ImplementedActivities/ImplementedActivities.content';

const ActivityReporting = ({ isInTesting }) => {
  const {
    tillageTypeDropdown,
    fertilizerTypeDropDown,
    coverCropTypeDropdown,
    activityStatusList,
    setActivityStatusList,
  } = useContext(baselineDropdownContext);
  const { isEmailLinkClicked } = useContext(userAuthRolesContext);
  const [searchParams] = useSearchParams();
  const [farmDetails, setFarmDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { loaderCounter } = useContext(baselineDropdownContext);
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [currentFieldId, setCurrentFieldId] = useState({
    id: 0,
    name: '',
  });
  const [type, setType] = useState(PAGE_CONTENT.farm);
  const [fieldDetails, setFieldDetails] = useState({});
  const [enrollmentYears, setEnrollmentYears] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [activityTabData, setActivityTabData] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const [finalList, setFinalList] = useState([]);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchLastSectionData, setFetchLastSectionData] = useState(false);
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [poiStatusList, setPoiStatusList] = useState([]);
  const [fetchActivityFieldData, setFetchActivityFieldData] = useState(false);
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
  });
  const [poiCurrentTab, setPoiCurrentTab] = useState(0);
  const [poiActivities, setPoiActivities] = useState([]);
  const [poiData, setPoiData] = useState([]);
  const [poiCurrentTabStatus, setPoiCurrentTabStatus] = useState('Not Started');
  const [
    implementedActivitiesSectionsMap,
    setImplementedActivitiesSectionsMap,
  ] = useState(new Map());
  const [reportingCompleteModal, setReportingCompleteModal] = useState({
    flag: false,
  });
  const navigate = useNavigate();

  const { TabberBox } = useStyles();
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );
  const {
    loading: apiLoading,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchProjectData,
    fetchFieldArea,
    submitActivityField,
    submitBaselineFarm,
    editBaselineField,
  } = useReportingApis();

  /*================> Start of in progress handler logic <==================*/
  const constructPageList = (fieldDetails) => {
    const farmList = fieldDetails.farmDetails;
    const pageList = [];

    if (!farmList) return pageList;

    for (const farm of farmList) {
      pageList.push({
        type: PAGE_CONTENT.farm,
        page: autoSaveTargetKeys.farmOperationsData,
        id: farm.farmId,
        name: farm.farmName,
        status: farm.farmOperationsDataStatus ?? REPORTING_STATUS.not_started,
        farmId: null,
      });

      pageList.push({
        type: PAGE_CONTENT.farm,
        page: autoSaveTargetKeys.proofOfImplementation,
        id: farm.farmId,
        name: farm.farmName,
        status:
          farm.farmProofOfImplementationStatus ?? REPORTING_STATUS.not_started,
        farmId: null,
      });

      // Here since fieldActivityReportingStatusDto and fieldDetails may be out of sync wrt their ordering, we are sorting based on the order as in fieldDetails(which will be sorted based on their creation time).
      const fieldIds = farm.fieldDetails?.map((field) => field.fieldId);
      const fieldsWithStatus = sortWithAKeyInSpecificOrder(
        farm.fieldActivityReportingStatusDto,
        'fieldId',
        fieldIds,
      );
      for (const field of fieldsWithStatus) {
        const fieldName = farm.fieldDetails.find(
          (fieldData) => fieldData.fieldId === field.fieldId,
        )?.fieldName;

        pageList.push({
          type: PAGE_CONTENT.crop,
          page: autoSaveTargetKeys.fieldImplementedActivities,
          id: field.fieldId,
          name: fieldName,
          status:
            field.fieldImplementedActivitiesStatus ??
            REPORTING_STATUS.not_started,
          farmId: farm.farmId,
        });

        pageList.push({
          type: PAGE_CONTENT.crop,
          page: autoSaveTargetKeys.fieldOperationsData,
          id: field.fieldId,
          name: fieldName,
          status:
            field.fieldOperationsDataStatus ?? REPORTING_STATUS.not_started,
          farmId: farm.farmId,
        });
      }
    }

    return pageList;
  };

  const getCurrentScreenIndex = (pageList) => {
    if (type === PAGE_CONTENT.farm) {
      const currentPage = checkTernaryCondition(
        currentTab == 0,
        autoSaveTargetKeys.farmOperationsData,
        autoSaveTargetKeys.proofOfImplementation,
      );

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.farm &&
          item.page === currentPage &&
          item.id == currentFarmId,
      );
    } else if (type === PAGE_CONTENT.crop) {
      const currentPage = checkTernaryCondition(
        currentTab == 0,
        autoSaveTargetKeys.fieldImplementedActivities,
        autoSaveTargetKeys.fieldOperationsData,
      );
      const fieldId = currentFieldId.id;

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.crop &&
          item.page === currentPage &&
          item.farmId == currentFarmId &&
          item.id == fieldId,
      );
    }
  };

  const switchScreen = (pageData) => {
    if (pageData === null) return;

    if (pageData.type === PAGE_CONTENT.farm) {
      setCurrentFarmId(pageData.id);
      setExpandedFarmId(pageData.id);
      setFarmDetails({
        id: pageData.id,
        name: pageData.name,
      });

      const newTab = checkTernaryCondition(
        pageData.page === autoSaveTargetKeys.farmOperationsData,
        0,
        1,
      );
      setCurrentTab(newTab);
    } else if (pageData.type === PAGE_CONTENT.crop) {
      setCurrentFarmId(pageData.farmId);
      setExpandedFarmId(pageData.farmId);
      setCurrentFieldId({
        id: pageData.id,
        name: pageData.name,
      });

      const newTab = checkTernaryCondition(
        pageData.page === autoSaveTargetKeys.fieldImplementedActivities,
        0,
        1,
      );
      setCurrentTab(newTab);
    }
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @returns item from pageList with current farm POI data
   */
  const getCurrentFarmPoiData = (pageList, currentScreenIndex) => {
    for (let index = currentScreenIndex; index >= 0; index--) {
      if (pageList[index].page === autoSaveTargetKeys.proofOfImplementation)
        return pageList[index];
    }

    return null;
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @returns true if all the fields under current farm are completed and POI in current farm is not completed.
   */
  const canGoToPoi = (pageList, currentScreenIndex) => {
    let pointerIndex = currentScreenIndex;
    for (; pointerIndex >= 0; pointerIndex--) {
      if (
        pageList[pointerIndex].page === autoSaveTargetKeys.proofOfImplementation
      )
        break;
    }

    // If poi is completed, don't navigate here
    if (pageList[pointerIndex].status === REPORTING_STATUS.completed)
      return false;

    pointerIndex++;

    for (
      ;
      pointerIndex < pageList.length &&
      pageList[pointerIndex].type !== PAGE_CONTENT.farm;
      pointerIndex++
    ) {
      // if any field is in progress we can't navigate to POI -> return canGoToPOI as false
      if (
        pageList[pointerIndex].status === REPORTING_STATUS.in_progress ||
        pageList[pointerIndex].status === REPORTING_STATUS.not_started
      )
        return false;
    }

    // If none of the fields are "In progress"/"Not started" we return canGoToPOI as true.
    return true;
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @param fieldData is the response from getParticipantPropfile api -> has all farm/field data
   * It searches for the next in progress page from the current page and switches the screen to it, recursively calls if needed with updated currentScreenIndex
   */
  const recursiveNavigationHandler = (
    pageList,
    currentScreenIndex,
    fieldData,
  ) => {
    for (let index = currentScreenIndex + 1; index < pageList.length; index++) {
      // Searching for "In progress"/"Not started" items in pageList
      if (
        pageList[index].status === REPORTING_STATUS.not_started ||
        pageList[index].status === REPORTING_STATUS.in_progress
      ) {
        // If navigating to POI tab check if all fields under current farm are completed
        if (pageList[index].page === autoSaveTargetKeys.proofOfImplementation) {
          for (
            let fieldIndex = index + 1;
            fieldIndex < pageList.length &&
            pageList[fieldIndex].type !== PAGE_CONTENT.farm;
            fieldIndex++
          ) {
            if (
              pageList[fieldIndex].status === REPORTING_STATUS.not_started ||
              pageList[fieldIndex].status === REPORTING_STATUS.in_progress
            ) {
              //Note: index here will be POI tab index in the pageList, so next recursive call will start from POI tab and go to next in progress field
              recursiveNavigationHandler(pageList, index, fieldData);
              return;
            }
          }
        }

        switchScreen(pageList[index]);
        return;
      }
    }

    // If none of the items after current screen is "In progress"/"Not started" just switch to next screen
    if (currentScreenIndex + 1 < pageList.length) {
      switchScreen(pageList[currentScreenIndex + 1]);
    }
  };

  /**
   *
   * @param fieldData is the response from getParticipantPropfile api -> has all farm/field data
   * This is the starting point of "next in progress" handler logic, actual navigation happens inside recursiveNavigationHandler
   */
  const gotoNextInprogressPage = (fieldData = fieldDetails) => {
    const pageList = constructPageList(fieldData);
    const currentScreenIndex = getCurrentScreenIndex(pageList);

    // Note: If all pages are completed, then open review modal
    if (getActivityReportingStatus(fieldData) === REPORTING_STATUS.completed) {
      setReportingCompleteModal({ flag: true });
      return;
    }

    // If user clicks on continue from a field tab(impl activities/ops data) we check if all fields under current farm are in completed state, if so we navigate to POI directly.
    if (
      pageList[currentScreenIndex]?.type === PAGE_CONTENT.crop &&
      canGoToPoi(pageList, currentScreenIndex)
    ) {
      const currentFarmPoiData = getCurrentFarmPoiData(
        pageList,
        currentScreenIndex,
      );

      switchScreen(currentFarmPoiData);
      return;
    }

    // Call for navigating to in progress handler
    recursiveNavigationHandler(pageList, currentScreenIndex, fieldData);
  };

  /*=============> End of in progress handler logic <==================*/

  const submitImplementedActivities = async (fromAutoSave = false) => {
    setLoading(true);
    const sections = Array.from(implementedActivitiesSectionsMap.entries());

    const requestBody = sections.flatMap(([_, activities]) =>
      activities.map(
        ({ fieldProjectActivityId, activityId, area, activityCategory }) => ({
          fieldProjectActivityId,
          activityId,
          area: checkTernaryCondition(area === '', '', Number(area)),
          activityCategory: getReverseMappingOfSections(activityCategory),
        }),
      ),
    );

    let hasError = false;
    let hasData = false;
    sections.forEach(([_, activities]) => {
      activities.forEach(({ area, acresInputRequired }) => {
        if (area !== '') hasData = true;
        if (acresInputRequired && area === '') {
          hasError = true;
        }
        if ((area !== '' && Number(area) === 0) || area > totalFieldArea) {
          hasError = true;
        }
      });
    });

    let fieldImplementedActivitiesStatus = '';
    if (!hasData)
      fieldImplementedActivitiesStatus = REPORTING_STATUS.not_started;
    else if (hasError)
      fieldImplementedActivitiesStatus = REPORTING_STATUS.in_progress;
    else fieldImplementedActivitiesStatus = REPORTING_STATUS.completed;

    const url = `${FETCH_ACTIVITY_ACREAGE}?fieldId=${currentFieldId?.id}&projectId=${projectId}&fieldImplementedActivitiesStatus=${fieldImplementedActivitiesStatus}&participantId=${participantId}&projectCycleId=${projectCycleId}&farmId=${currentFarmId}`;
    return axios
      .put(url, requestBody)
      .then(() => {
        fetchFieldDetails(false).then((fieldData) => {
          setActivityTabData((prev) => ({
            ...prev,
            fieldImplementedActivitiesStatus: fieldImplementedActivitiesStatus,
          }));

          if (!fromAutoSave) {
            gotoNextInprogressPage(fieldData);
          }
        });
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `activity-page-tab-${index}`,
    };
  };
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentFieldId.id };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };

  const getOperationsDataTab = (tabData) => {
    return {
      id: 1,
      title: tabsTitle(tabData?.displayTitle, tabData.status),
      component: (
        <TabBodyComponent
          isInActivity
          year={tabData?.yearValue}
          type={checkTernaryCondition(
            type === PAGE_CONTENT.farm,
            PAGE_CONTENT.empty_farm_label,
            PAGE_CONTENT.empty_crop_label,
          )}
          fieldType={type}
          sampleId={tabData?.sampleId}
          setLoading={setLoading}
          fieldId={currentFieldId.id}
          farmId={currentFarmId}
          totalFieldArea={totalFieldArea}
          enrollmentYearData={tabData}
          setFieldDetails={setFieldDetails}
          setEnrollmentYears={setEnrollmentYears}
          fetchFieldDetails={fetchFieldDetails}
          setSubmitData={setSubmitData}
          saveDataHandler={saveDataHandler}
          setIsInitialLoad={setIsInitialLoad}
          fetchLastSectionData={fetchLastSectionData}
          setFetchLastSectionData={setFetchLastSectionData}
          setUserActivityDetected={setUserActivityDetected}
          typeOfReporting={'Baseline'}
          fetchActivityFieldData={fetchActivityFieldData}
        />
      ),
    };
  };

  const fetchBaselineYears = (fieldDetails, type, id) => {
    const getCurrentFarm = fieldDetails?.farmDetails.find(
      (farm) => farm.farmId === currentFarmId,
    );
    if (type === PAGE_CONTENT.farm) {
      const apiParams = { farmId: id, projectCycleId: Number(projectCycleId) };
      fetchBaselineFarmYears(apiParams, 'activity').then((data) => {
        setActivityTabData(
          checkTernaryCondition(isInTesting, mockEnrolmentYears, {
            ...data[0],
            farmOperationsDataStatus: getCurrentFarm?.farmOperationsDataStatus,
            farmProofOfImplementationStatus:
              getCurrentFarm?.farmProofOfImplementationStatus,
          }),
        );
        setPoiStatusList(getCurrentFarm.poiTabStatus);
      });
    } else {
      if (id === 0) return;
      const apiParams = {
        fieldId: id,
        projectId: projectId,
        projectCycleId: Number(projectCycleId),
      };
      const currentField = getCurrentFarm?.fieldActivityReportingStatusDto.find(
        (field) => field.fieldId === id,
      );

      currentField &&
        fetchBaselineFieldYears(apiParams, 'activity').then((data) =>
          setActivityTabData(
            checkTernaryCondition(isInTesting, mockEnrolmentYears, {
              ...data[0],
              fieldOperationsDataStatus:
                currentField?.fieldOperationsDataStatus,
              fieldImplementedActivitiesStatus:
                currentField?.fieldImplementedActivitiesStatus,
            }),
          ),
        );
    }
  };

  const fetchFieldDetails = async (isInitial) => {
    const URL = `${FETCH_PARTICIPANT_PROFILE_ACTIVITY}&projectId=${projectId}&participantId=${participantId}`;
    return fetchBaselineFieldDetails(URL).then((data) => {
      const fieldData = checkTernaryCondition(isInTesting, mockData, data);
      const farmId = fieldData?.farmDetails[0]?.farmId;
      const farmName = fieldData?.farmDetails[0]?.farmName;
      setFieldDetails(fieldData);
      if (isInitial) {
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
      }

      return fieldData;
    });
  };

  const updateReportingStatus = (targetType, targetId) => {
    fetchFieldDetails(false).then((fieldDetails) => {
      fetchBaselineYears(fieldDetails, targetType, targetId);
    });
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      if (cycleObject?.length > 0) {
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  };

  useEffect(() => {
    fetchLifeCycleDates();
  }, []);

  const axiosCall = (formData, mappedPracticesStatus) => {
    let response;
    if (submitData.editMode) {
      const fieldId = currentFieldId?.id;
      const { apiParams, reqBody } = getFieldEditApiData(
        formData,
        submitData,
        fieldId,
        mappedPracticesStatus,
      );
      response = editBaselineField(apiParams, reqBody);
    } else {
      response = submitActivityField(formData);
    }
    response.finally(() => {
      setFetchActivityFieldData(!fetchActivityFieldData);
    });
    return response;
  };
  const handleFarmSubmit = ({
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched,
    clickdata,
    parentState,
    sampleId,
  }) => {
    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const submitData = {
      activityReportingStatus: 'Enrollment In Progress',
      enrolmentType: 'Activity',
      farmSampleId: sampleId,
      farmId: currentFarmId,
      farmOperationsDataStatus: yearStatus,
      projectId: projectId,
      participantId: participantId,
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };
    submitBaselineFarm(submitData, type).then(() => {
      fetchFieldDetails(false).then((fieldData) => {
        const shouldFetchBaselineYears =
          isTabSwitched ||
          (clickdata.type === 'farm' &&
            clickdata.id === currentFarmId &&
            type === PAGE_CONTENT.farm);
        if (shouldFetchBaselineYears) {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, currentFarmId);
        } else {
          setActivityTabData((prev) => ({
            ...prev,
            type: 'farm',
            farmOperationsDataStatus: checkTernaryCondition(
              fossiFuelFormatted.length > 0,
              'Completed',
              'Not Started',
            ),
          }));
        }

        gotoNextInprogressPage(fieldData);
      });
    });
  };

  const getFieldStatus = () => {
    if (
      submitData.parentState.length === 0 &&
      submitData.practiceModalsData.edgeOfFieldList?.length === 0
    )
      return REPORTING_STATUS.not_started;
    const yearStatus = validateFieldYear(
      submitData,
      fertilizerTypeDropDown,
      coverCropTypeDropdown,
      tillageTypeDropdown,
    );
    let isMappedCropDataAdded = true;
    submitData.parentState.forEach((data) => {
      if (
        data.addedFromImplementedActivities &&
        parseFloat(data.cropArea) < 0
      ) {
        isMappedCropDataAdded = false;
      }
    });

    const areMappedDataAdded = validateMappedPractices(
      submitData.parentState,
      practiceList,
      submitData,
    );

    const isEofMappedDataAdded = validateMappedEof(
      submitData.practiceModalsData.edgeOfFieldList,
    );

    return checkTernaryCondition(
      areMappedDataAdded === REPORTING_STATUS.completed &&
        isEofMappedDataAdded === REPORTING_STATUS.completed &&
        isMappedCropDataAdded &&
        yearStatus === REPORTING_STATUS.completed,
      REPORTING_STATUS.completed,
      REPORTING_STATUS.in_progress,
    );
  };

  const handleFieldSubmit = async ({
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched,
    clickdata,
    parentState,
    editMode,
    fromAutosave = false,
  }) => {
    const cashCropList = getFormattedCashcropList(
      parentState,
      submitData,
      editMode,
    );
    const eofList = getFormattedEdgeOfFieldList(submitData, editMode);
    const yearStatus = validateFieldYear(
      submitData,
      fertilizerTypeDropDown,
      coverCropTypeDropdown,
      tillageTypeDropdown,
    );

    const formData = getcashCropSubmitBody({
      fieldId: currentFieldId.id,
      projectId,
      participantId,
      sampleId: submitData.sampleId,
      cashCropList,
      eofList,
      farmId: currentFarmId,
      yearStatus,
      reportingStatus: true,
      operationsDataTabStatus: getFieldStatus(),
      projectCycleId: Number(projectCycleId),
    });

    return axiosCall(formData, getFieldStatus()).then(() => {
      if (fromAutosave) return;

      const shouldFetchBaselineYears =
        isTabSwitched ||
        (clickdata.type === 'field' &&
          clickdata.id === currentFieldId.id &&
          type === PAGE_CONTENT.crop);
      fetchFieldDetails(false).then((fieldData) => {
        setActivityTabData((prev) => ({
          ...prev,
          type: 'field',
          fieldOperationsDataStatus: getFieldStatus(),
        }));
        if (shouldFetchBaselineYears) {
          fetchBaselineYears(
            fieldDetails,
            PAGE_CONTENT.crop,
            currentFieldId.id,
          );
        } else {
          !isUnmounting &&
            setSubmitData((prev) => ({
              ...prev,
              editMode: formData.baselineFieldDto.cropList.length > 0,
            }));
        }

        gotoNextInprogressPage(fieldData);
      });
    });
  };

  const navigateOnCloseHandler = (status) => {
    if (status === PAGE_CONTENT.completed) {
      navigateHandler();
    } else {
      navigate(`${pathName.participant.landingPage}`);
    }
  };

  const saveDataHandler = (
    isSaveAndCloseClicked,
    isUnmounting,
    isTabSwitched = false,
    clickdata = { type: 'none', id: null },
  ) => {
    const { sampleId, parentState, editMode } = submitData;
    if (sampleId === null) return;
    if (isSaveAndCloseClicked && isEmailLinkClicked) {
      let finalStatus = '';
      if (finalList.every((element) => element === PAGE_CONTENT.completed)) {
        finalStatus = PAGE_CONTENT.completed;
      } else {
        finalStatus = PAGE_CONTENT.in_progress;
      }
      navigateOnCloseHandler(finalStatus);
    }
    if (type === PAGE_CONTENT.farm) {
      handleFarmSubmit({
        isSaveAndCloseClicked,
        isUnmounting,
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
      });
    } else if (type === PAGE_CONTENT.crop) {
      handleFieldSubmit({
        isSaveAndCloseClicked,
        isUnmounting,
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
      });
    }
  };
  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.activityMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  const updateFieldLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.in_progress,
              fieldActivityReportingStatusDto:
                farm.fieldActivityReportingStatusDto.map((field) => {
                  return checkTernaryCondition(
                    field.fieldId === currentFieldId.id,
                    {
                      ...field,
                      fieldActivityReportingStatus:
                        REPORTING_STATUS.in_progress,
                    },
                    field,
                  );
                }),
            },
            farm,
          );
        }),
      };
    });
  };

  const updateFarmLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.in_progress,
            },
            farm,
          );
        }),
      };
    });
  };

  const proofOfImplementationTabs = [
    {
      id: 2,
      title: tabsTitle(
        PAGE_CONTENT.proof_of_implementation,
        activityTabData?.farmProofOfImplementationStatus,
      ),
      isDisabled: false,
      component: (
        <ProofOfImplementationMainComp
          farmId={currentFarmId}
          setActivityTabData={setActivityTabData}
          fetchFieldDetails={fetchFieldDetails}
          poiStatusList={poiStatusList}
          updateFarmLevelStatus={updateFarmLevelStatus}
          currentTab={poiCurrentTab}
          setCurrentTab={setPoiCurrentTab}
          projectActivities={poiActivities}
          setProjectActivities={setPoiActivities}
          currentTabData={poiData}
          setCurrentTabData={setPoiData}
          currentTabStatus={poiCurrentTabStatus}
          setCurrentTabStatus={setPoiCurrentTabStatus}
          updateReportingStatus={updateReportingStatus}
          gotoNextInprogressPage={gotoNextInprogressPage}
        />
      ),
    },
  ];

  const farmActivityTabs = [
    getOperationsDataTab({
      ...activityTabData,
      status: activityTabData?.farmOperationsDataStatus,
    }),
    ...proofOfImplementationTabs,
  ];
  const fieldActivityTabs = [
    {
      id: 3,
      title: tabsTitle(
        'Implemented activities',
        activityTabData?.fieldImplementedActivitiesStatus,
      ),
      component: (
        <ImplementedActivities
          fieldId={currentFieldId?.id}
          totalFieldArea={totalFieldArea}
          fetchFieldDetails={fetchFieldDetails}
          setActivityTabData={setActivityTabData}
          updateFieldLevelStatus={updateFieldLevelStatus}
          sectionsMap={implementedActivitiesSectionsMap}
          setSectionsMap={setImplementedActivitiesSectionsMap}
          submitData={submitImplementedActivities}
        />
      ),
    },
    getOperationsDataTab({
      ...activityTabData,
      status: activityTabData?.fieldOperationsDataStatus,
    }),
  ];

  const getActivityTabs = () => {
    if (type === PAGE_CONTENT.farm) {
      return farmActivityTabs;
    }
    return fieldActivityTabs;
  };

  const fetchProjectDetails = () => {
    const apiPath = `/${projectId}`;
    const errorHandler = () => setProjectDetails({ projectName: '' });
    fetchProjectData(apiPath, errorHandler).then((projectData) =>
      setProjectDetails(projectData),
    );
  };

  const updateCurrentTab = (newTab) => setCurrentTab(newTab);

  useEffect(() => {
    fetchFieldDetails(true);
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (currentFieldId?.id > 0) {
      setType(PAGE_CONTENT.crop);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, currentFieldId.id);
      fetchTotalFieldArea();
    }
  }, [currentFieldId]);
  useEffect(() => {
    if (farmDetails) {
      setType(PAGE_CONTENT.farm);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmDetails.id);
    }
  }, [farmDetails]);

  useEffect(() => {
    if (activityTabData && !isInitialLoad) {
      let updatedStatusData = {};
      if (type === PAGE_CONTENT.farm) {
        updatedStatusData = {
          farmOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      } else {
        updatedStatusData = {
          fieldOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0 ||
              submitData.practiceModalsData.edgeOfFieldList.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      }
      setActivityTabData((prev) => ({
        ...prev,
        ...updatedStatusData,
      }));
    }
    if (type === PAGE_CONTENT.farm && !isInitialLoad) {
      updateFarmLevelStatus();
    }
    if (type === PAGE_CONTENT.crop && !isInitialLoad) {
      updateFieldLevelStatus();
    }
  }, [submitData]);

  useEffect(() => {
    let finalData = [];
    fieldDetails?.farmDetails?.map((farm) =>
      finalData.push(farm?.farmActivityReportingStatus),
    );
    setFinalList(finalData);
    if (finalData.every((element) => element === PAGE_CONTENT.completed)) {
      setActivityStatusList(PAGE_CONTENT.completed);
    } else {
      setActivityStatusList(PAGE_CONTENT.in_progress);
    }
  }, [fieldDetails]);

  /**============> Start of autosave logic <============== **/

  /**
   * This function is used to identify the current page in which the user is in, using this data w.r.t current page is saved
   * @returns {string} representing the name of the current page based on the type prop and current tab value(page names are stored in autoSaveTargetKeys constant)
   */
  const getCurrentPage = () => {
    if (type === PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.farmOperationsData;
    if (type === PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.proofOfImplementation;
    if (type !== PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.fieldImplementedActivities;
    if (type !== PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.fieldOperationsData;
  };

  /**
   *
   * This function submits the current page and is used for the purpose of autosave feature, whenever user clicks on any other field/farm/tab, current page data is saved using this function, post which navigation to the target place is carried out.
   * @returns {Promise} -> this async promise is used by autosave function, on resolve of this Promise navigation to target place happens.
   */
  const submitCurrentPage = () => {
    const currentPage = getCurrentPage();

    switch (currentPage) {
      case autoSaveTargetKeys.farmOperationsData: {
        const apiBody = getOperationsDataBody(
          submitData.parentState,
          activityTabData?.sampleId,
          currentFarmId,
          projectId,
          participantId,
          projectCycleId,
        );

        return submitBaselineFarm(apiBody);
      }

      case autoSaveTargetKeys.proofOfImplementation: {
        return submitPoiTabData(
          poiActivities,
          poiCurrentTab,
          poiData,
          projectId,
          currentFarmId,
          poiCurrentTabStatus,
          participantId,
        );
      }

      case autoSaveTargetKeys.fieldImplementedActivities: {
        return submitImplementedActivities(true);
      }

      case autoSaveTargetKeys.fieldOperationsData: {
        return handleFieldSubmit({
          isSaveAndCloseClicked: false,
          isUnmounting: false,
          isTabSwitched: false,
          clickdata: null,
          parentState: submitData.parentState,
          editMode: submitData.editMode,
          fromAutosave: true,
        });
      }

      default:
        break;
    }
  };

  /**
   *
   * @param {string} targetPlace - This is the destination place to navigate after saving current page data.
   * @param {number} targetId - This is the id of the field/farm to navigate to.
   * @param {string} targetName - This is the name of the field/farm to navigate to.
   */
  const autoSave = ({ targetPlace, targetId, targetName }) => {
    setLoading(true);
    switch (targetPlace) {
      case autoSaveTargetKeys.proofOfImplementation:
        submitCurrentPage()
          .then(() => {
            updateCurrentTab(1);
            setPoiCurrentTab(0);
            if (targetId) {
              // targetId is present when user clicks on field/farm from left menu(we need to change current farm/field ids in this case only)
              setCurrentFarmId(targetId);
              setExpandedFarmId(targetId);
              setFarmDetails({
                id: targetId,
                name: targetName,
              });
            }
            updateReportingStatus(PAGE_CONTENT.farm, targetId ?? currentFarmId);
          })
          .finally(() => setLoading(false));
        break;

      case autoSaveTargetKeys.farmOperationsData:
        submitCurrentPage()
          .then(() => {
            updateCurrentTab(0);
            if (targetId) {
              // targetId is present when user clicks on field/farm from left menu(we need to change current farm/field ids in this case only)
              setCurrentFarmId(targetId);
              setExpandedFarmId(targetId);
              setFarmDetails({
                id: targetId,
                name: targetName,
              });
            }

            updateReportingStatus(PAGE_CONTENT.farm, targetId ?? currentFarmId);
          })
          .finally(() => setLoading(false));
        break;

      case autoSaveTargetKeys.fieldImplementedActivities:
        submitCurrentPage()
          .then(() => {
            updateCurrentTab(0);
            if (targetId) {
              // targetId is present when user clicks on field/farm from left menu(we need to change current farm/field ids in this case only)
              setCurrentFieldId({ id: targetId, name: targetName });
            }

            updateReportingStatus(
              PAGE_CONTENT.crop,
              targetId ?? currentFieldId.id,
            );
          })
          .finally(() => setLoading(false));
        break;

      case autoSaveTargetKeys.fieldOperationsData:
        submitCurrentPage()
          .then(() => {
            updateCurrentTab(1);
            if (targetId) {
              // targetId is present when user clicks on field/farm from left menu(we need to change current farm/field ids in this case only)
              setCurrentFieldId({ id: targetId, name: targetName });
            }

            updateReportingStatus(
              PAGE_CONTENT.crop,
              targetId ?? currentFieldId.id,
            );
          })
          .finally(() => setLoading(false));
        break;

      default:
        break;
    }
  };

  // Note: This is called when user switches tab inside farm/field -> based on type and tab index we identify target place and call autosave function
  const handleTabChange = (_, selectedTab) => {
    if (type === PAGE_CONTENT.farm && selectedTab === 1) {
      autoSave({ targetPlace: autoSaveTargetKeys.proofOfImplementation });
    } else if (type === PAGE_CONTENT.farm && selectedTab === 0) {
      autoSave({ targetPlace: autoSaveTargetKeys.farmOperationsData });
    } else if (type !== PAGE_CONTENT.farm && selectedTab === 0) {
      autoSave({ targetPlace: autoSaveTargetKeys.fieldImplementedActivities });
    } else if (type !== PAGE_CONTENT.farm && selectedTab === 1) {
      autoSave({ targetPlace: autoSaveTargetKeys.fieldOperationsData });
    }
  };

  // Note: This is called on clicking field name from left menu
  const handleAccordianItemClick = ({ targetId, targetName }) => {
    autoSave({
      targetPlace: autoSaveTargetKeys.fieldImplementedActivities,
      targetId: targetId,
      targetName: targetName,
    });
  };

  // Note: This is called on clicking farm name from left menu
  const handleAccordianSummaryClick = ({ targetId, targetName }) => {
    autoSave({
      targetPlace: autoSaveTargetKeys.farmOperationsData,
      targetId: targetId,
      targetName: targetName,
    });
  };

  /**===============> End of autosave logic <=============== **/

  const getActivityReportingStatus = (fieldData) => {
    const farmList = fieldData?.farmDetails;
    if (!farmList) return 'Not Started';

    for (let farm of farmList) {
      if (farm.farmActivityReportingStatus !== 'Completed') {
        return 'In Progress';
      }
    }

    return 'Completed';
  };

  const getFieldsList = () => {
    return fieldDetails?.farmDetails?.map((data) => {
      let fieldList = [];
      if (data.fieldActivityReportingStatusDto) {
        fieldList = sortWithAKeyInSpecificOrder(
          data.fieldActivityReportingStatusDto,
          'fieldId',
          data.fieldDetails.map((field) => field.fieldId),
        ).map((item) => ({
          value: data.fieldDetails.find(
            (field) => field.fieldId === item.fieldId,
          )?.fieldName,
          status: item.fieldActivityReportingStatus,
          id: item.fieldId,
        }));
      }

      return {
        id: data.farmId,
        status: data.farmActivityReportingStatus,
        value: data.farmName,
        fields: fieldList,
      };
    });
  };

  return (
    <MainWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}>
        <CustomBreadCrumbLink
          to={`${pathName.participant.activityMenuPage}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`}>
          {PAGE_CONTENT.home}
        </CustomBreadCrumbLink>
        <CustomBreadCrumbItem>
          {PAGE_CONTENT.activity_reporting}
        </CustomBreadCrumbItem>
      </Breadcrumbs>
      <ProjectNameWrapper>
        <ProjectName>{projectDetails?.projectName}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate.slice(
          6,
        )}`}</ProjectName>
        <ProjectName>{` (${projectCycleData?.cycleStartDate}`}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate})`}</ProjectName>
      </ProjectNameWrapper>
      <Divider style={DividerStyles} />
      <ParentContainer>
        {!isInTesting && (
          <Backdrop
            sx={{
              backgroundColor: WHITE,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={
              loading ||
              activityTabData === null ||
              apiLoading ||
              (!isInTesting && loaderCounter > 0)
            }>
            <CircularProgress color="success" />
          </Backdrop>
        )}
        <LeftSectionWrapper>
          <LeftMenuSubsection
            heading={PAGE_CONTENT.left_section_heading}
            fieldData={getFieldsList()}
            showFields={true}
            setCurrentFieldId={setCurrentFieldId}
            isBaselineFlow={true}
            currentFarmId={currentFarmId}
            setCurrentFarmId={setCurrentFarmId}
            setExpandedFarmId={setExpandedFarmId}
            expandedFarmId={expandedFarmId}
            selectedFieldId={currentFieldId}
            setFarmDetails={setFarmDetails}
            isInBaselinePage
            isInsideActivityReporting
            isFarmSelected={type === PAGE_CONTENT.farm}
            handleAccordianItemClick={handleAccordianItemClick}
            handleAccordianSummaryClick={handleAccordianSummaryClick}
          />
        </LeftSectionWrapper>
        <MainContainer padding="1.5rem">
          <TextWrapper fontSize="16px" fontWeight="600" color={DARK_CHARCOAL}>
            {checkTernaryCondition(
              type === PAGE_CONTENT.farm,
              <>{farmDetails?.name}</>,
              <>{`${farmDetails?.name} - ${currentFieldId?.name}`}</>,
            )}
          </TextWrapper>
          <Box
            sx={{
              ...flexColumn,
              ...yAxisAutoScroll,
              height: '30.9rem',
            }}>
            <TabberBox style={TabberBoxCustomStyle}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label="page-tabs">
                {getActivityTabs()?.map((tab, index) => {
                  const key = `Tab title ${index}`;
                  return (
                    <Tab
                      disabled={tab?.isDisabled}
                      sx={TabStylesSx}
                      className={classNames({
                        'active-tab': currentTab === index,
                      })}
                      key={key}
                      label={tab?.title}
                      {...tabProps(index)}
                    />
                  );
                })}
              </Tabs>
            </TabberBox>
            {getActivityTabs()?.map((tab, index) => {
              const key = `Tab body ${index}`;
              return (
                <TabPanel key={key} value={currentTab} index={index}>
                  {tab.component}
                </TabPanel>
              );
            })}
          </Box>
        </MainContainer>
      </ParentContainer>
      <EnrollmentCompleteModal
        modalData={reportingCompleteModal}
        modalCloseHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        title={PAGE_CONTENT.submit_complete_modal_title}
        contentText={PAGE_CONTENT.submit_complete_modal_content}
        firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
        secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
        cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
        firstSubmitBtnHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        secondSubmitBtnHandler={navigateHandler}
        cancelHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
      />
    </MainWrapper>
  );
};
export default ActivityReporting;
