import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import {
  Container,
  Text,
  FormControlLabelStyle,
  FilterAltIconStyle,
  PopoverStyle,
  checkBoxStyle,
} from './FilterComponent.style';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddButton from 'components/AddButton/AddButton.react';
import InputAdornment from '@mui/material/InputAdornment';
import { PAGE_CONTENT } from './FilterComponent.content';
import { DARK_GREEN, GREEN } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

const FilterComponent = ({
  list,
  isMultiSelect,
  isSearchEnabled,
  setFilterList,
  isFilterApplied,
  isPartOfOrigination,
}) => {
  const [columnList, setColumnList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredColumnList, setFilteredColumnList] = useState([]);
  const mainList = list;

  useEffect(() => {
    setColumnList(list);
  }, [list]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setUnselectedToFalse = (mainList, selectedList) => {
    let list = mainList.map((data) => {
      if (selectedList?.includes(data?.id)) {
        return {
          id: data?.id,
          itemLabel: data?.itemLabel,
          checked: true,
        };
      } else {
        return {
          id: data?.id,
          itemLabel: data?.itemLabel,
          checked: false,
        };
      }
    });

    return list;
  };

  const handleClose = (itemsList, apply) => {
    !isPartOfOrigination &&
      setColumnList(setUnselectedToFalse(mainList, itemsList));
    setAnchorEl(null);
    if (!apply) {
      setSelectedItems(filteredColumnList);
    }
  };

  const saveSelectedItems = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems(selectedItems.filter((event) => event !== value));
    }
  };
  const setCheckedStatus = (index) => {
    if (index !== null || index !== undefined) {
      let list = columnList;
      list[index].checked = !list[index].checked;
      setColumnList(list);
    }
  };
  const saveSearchedItems = (event) => {
    const searchedList = mainList.filter((item) =>
      item.id.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setColumnList(setUnselectedToFalse(searchedList, filteredColumnList));
  };

  const setFilter = (option) => {
    if (option === PAGE_CONTENT.select_all) {
      let allCountries = [
        ...columnList.map((data) => {
          return data?.id;
        }),
      ];
      let newList = [
        ...columnList.map((data) => {
          return {
            id: data?.id,
            itemLabel: data?.itemLabel,
            checked: true,
          };
        }),
      ];
      setColumnList(newList);
      setSelectedItems(allCountries);
    }
    if (option === PAGE_CONTENT.clear_all) {
      let newList = [
        ...columnList.map((data) => {
          return {
            id: data?.id,
            itemLabel: data?.itemLabel,
            checked: false,
          };
        }),
      ];
      setColumnList(newList);
      setSelectedItems([]);
      setFilterList([]);
      setFilteredColumnList([]);
    }
  };

  const onSubmit = () => {
    setFilterList(selectedItems);
    setFilteredColumnList(selectedItems);
    handleClose(selectedItems, true);
  };

  const open = Boolean(anchorEl);
  const id = open ? PAGE_CONTENT.simple_popover : undefined;
  return (
    <Container flexDirection="column">
      <FilterAltIcon
        data-testid="icon"
        sx={FilterAltIconStyle(isFilterApplied, isPartOfOrigination)}
        variant="outlined"
        onClick={handleClick}
      />
      <Popover
        sx={PopoverStyle}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose(filteredColumnList)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Container padding={'0.5rem'} flexDirection="column">
          {isSearchEnabled && (
            <TextField
              variant="outlined"
              id="input-with-icon-adornment"
              placeholder={'Search'}
              InputProps={{
                sx: { height: 32 },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon data-testid="search" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => saveSearchedItems(e)}
            />
          )}
          {isMultiSelect && (
            <Container flexDirection="row" content="space-between">
              <Text
                color="#0076A8"
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.25rem"
                onClick={() => {
                  setFilter(PAGE_CONTENT.select_all);
                }}>
                {PAGE_CONTENT.select_all}
              </Text>
              <Text
                data-testid="clear"
                color="#0076A8"
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.25rem"
                onClick={() => {
                  setFilter(PAGE_CONTENT.clear_all);
                }}>
                {PAGE_CONTENT.clear_all}
              </Text>
            </Container>
          )}
          <Container
            border="1px solid #97999B"
            padding="0.5rem"
            radius="2px"
            maxHeight="25rem">
            <FormGroup column>
              <Container
                flexDirection="column"
                overFlow="scroll"
                data-testid="form"
                gap="0rem">
                {columnList.map((item, index) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={item?.checked}
                        onClick={() => setCheckedStatus(index)}
                        data-testid={item.itemLabel}
                        sx={checkBoxStyle(isPartOfOrigination)}
                      />
                    }
                    label={item.itemLabel}
                    value={item.id}
                    onChange={(e) => saveSelectedItems(e)}
                    sx={FormControlLabelStyle}
                  />
                ))}
              </Container>
            </FormGroup>
          </Container>
          <Container alignItem="center">
            <AddButton
              buttonName={PAGE_CONTENT.apply}
              noDropdown
              onClick={() => onSubmit()}
              backgroundColor={isPartOfOrigination && DARK_GREEN}
            />
            <Text
              color={checkTernaryCondition(
                isPartOfOrigination,
                DARK_GREEN,
                GREEN,
              )}
              fontWeight="400"
              onClick={() => handleClose(filteredColumnList)}
              data-testid="cancel">
              {PAGE_CONTENT.cancel}
            </Text>
          </Container>
        </Container>
      </Popover>
    </Container>
  );
};

FilterComponent.propTypes = {
  list: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  isSearchEnabled: PropTypes.bool,
  setFilterList: PropTypes.func,
  isFilterApplied: PropTypes.bool,
  isPartOfOrigination: PropTypes.bool,
};

export default FilterComponent;
