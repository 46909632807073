export const PARTICIPANT_SUMMARY_CONTENT = {
  leftBarChartTitle: 'GHG Emissions',
  leftBarChartXAxisLabel: (
    <span>
      MT CO<sub>2</sub>e
    </span>
  ),
  rightBarChartTitle: 'GHG emissions by source / sink ',
  rightBarChartXAxisLabel: (
    <spa>
      MT CO<sub>2</sub>e
    </spa>
  ),
  fullHeight: '100%',
  ninetyPercent: '90%',

  enrollmentAndReporting: 'Enrollment and reporting',
  enrollmentAndReportingEmptyLabel: 'No data to report yet.',
  enrollment: 'Enrollment: ',
  Enrollment: 'Enrollment',
  Baseline: 'Baseline',
  Activity: 'Activity',
  InProgress: 'In progress',
  Complete: 'Complete',
  NotStarted: 'Not started',
  acres: 'acres',
  baselineReporting: 'Baseline reporting: ',
  activityReporting: 'Activity reporting: ',
  activityDelta: 'Activity + delta',
  noDataLabel: 'Not reported yet',
  baseline: 'Baseline',

  interestedParticipantDisplayText: 'No data to report yet.',

  activeTag: 'Active',
  noDataValue: '- -',
  ghg_emissions: 'GHG removal & reductions',
  ghg_impact_tooltip: 'Represents reductions and removals to date.',
  actual: 'Actual',
  projected: 'Projected',
  placement: 'bottom-start',
  CO2e: 'CO₂e (K MT)',
  ghgEmissionsEmptyLabel: 'No data to report yet.',
};

export const Status = {
  interested: 'Interested',
  inProgress: 'Enrollment In Progress',
  enrolled: 'Enrolled',
  approved: 'Approved',
  submitted: 'Submitted',
};
